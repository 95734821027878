import CISList from './CISList';
import DocumentList from './DocumentList';
import ProductList from './ProductList';
import ReportList from './ReportList';
import ExciseList from './ExciseList';

//import * as CISDetails from './CISDetails';
import * as ProductDetails from './ProductDetails';
import * as ParticipantDetails from './ParticipantDetails';

export {
  CISList,
  DocumentList,
  ProductList,
  ReportList,
  //CISDetails,
  ProductDetails,
  ParticipantDetails,
  ExciseList,
};
