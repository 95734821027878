import React, { useMemo } from 'react';
import Datatable from '@crpt-ui/datatable';
import { TableWrap } from '../Act/Showing.styled';
import { columns } from './AcceptGoods.constants';
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import { importStatuses } from '../../../constants/documentTypes';

export const AcceptGoodsCises = ({ selectedDocument }) => {
  const list = useMemo(() =>
    selectedDocument.products.map((item, idx) => ({ ...item, idx: idx + 1 })) || [], [selectedDocument]);

  const columnsData = useMemo(() => {
    const disabled = selectedDocument.status !== importStatuses.ACCEPTED;
    return columns(disabled);
  }, [selectedDocument]);

  return (
    <CrossborderWrap withoutPadding>
      <TableWrap>
        <Datatable columns={columnsData} useSort={false} data={list} />
      </TableWrap>
    </CrossborderWrap>
  )
}
