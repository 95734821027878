import React, { useMemo } from 'react';
import { ResultsWrap } from '../ReconciliationDialog.styled';

export const ReconciliationResults = ({ list }) => {
  const notFound = useMemo(
    () => list.filter(item => item.reconciliationStatus === 'NOT_FOUND').length,
    [list],
  );
  const found = useMemo(
    () => list.filter(item => item.reconciliationStatus === 'FOUND').length,
    [list],
  );
  const excluded = useMemo(
    () => list.filter(item => item.reconciliationStatus === 'EXCLUDED').length,
    [list],
  );
  const added = useMemo(
    () => list.filter(item => item.reconciliationStatus === 'ADDED').length,
    [list],
  );
  return (
    <ResultsWrap>
      <div className="status_black">Найдено = {found}</div>
      <div className="status_red">Не найдено = {notFound}</div>
      <div className="status_green">Добавлено = {added}</div>
      <div className="status_blue">Исключено = {excluded}</div>
    </ResultsWrap>
  );
};
