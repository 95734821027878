import { Button, Icon, MenuButton, Toolbar } from '@crpt-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@material-ui/core';
import { Translate } from '../../../../../common_components/Translate/Translate';

const ActionsRow = ({
  onOpenModal,
  multipleActionsItems,
  isMultipleActionsDisabled,
  instance,
}) => {
  return isMultipleActionsDisabled ? (
    <Box py={2} px={5}>
      <Button
        icon={<Icon name='plus' />}
        onClick={onOpenModal}
      >
        {Translate('Добавить')}
      </Button>
    </Box>
  ) : (
    <Toolbar
      onClose={() => {
        instance.current.toggleAllRowsSelected(false);
      }}
    >
      <MenuButton
        items={multipleActionsItems}
        variant="outlined"
      >
        {Translate('Действия&nbsp;с&nbsp;выбранными')}
      </MenuButton>
    </Toolbar>
  );
};

ActionsRow.propTypes = {
  onOpenModal: PropTypes.func.isRequired,
  multipleActionsItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
  isMultipleActionsDisabled: PropTypes.bool.isRequired,
  instance: PropTypes.object.isRequired,
};

export default ActionsRow;
