import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: flex-start;
  width: 100%;
  justify-content: stretch;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
`;

export const RemoveWarehouseButton = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #55b5e7;
  cursor: pointer;
`;

export const WarehouseName = styled.div`
  font-size: 16px;

  color: #52535a;
  margin-bottom: 18px;
`;
