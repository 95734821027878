import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';


const customStyle = {
  width: 'auto',
  paddingTop: '32px',
  paddingBottom: '32px',
};

const CommandBar = ({ left, right, flexBasis, style }) => (
  <Grid container justifyContent="space-between" style={{flexBasis: flexBasis, ...customStyle, ...style}}>
    <Grid item>
      <Grid container spacing={5}>
        {left.map((item, index) => (
          <Grid item key={index}>
            {item}
          </Grid>
        ))}
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={5}>
        {right.map((item, index) => (
          <Grid item key={index}>
            {item}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

CommandBar.propTypes = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
};

CommandBar.defaultProps = {
  left: [],
  right: [],
};

export default CommandBar;
