import { selector } from '../../../utils/selector';

export const cisList = state =>
  selector(state, 'ScanDialog.cisList');

export const errorInfo = state =>
  selector(state, 'ScanDialog.errorInfo');

export const isLoadingScannerCis = state =>
  selector(state, 'ScanDialog.isLoadingScannerCis');

export const comparedCodes = state =>
  selector(state, 'ScanDialog.comparedCodes');
