import { createAction } from 'redux-actions';

export const subject = '[HandAddGood]';

export const getKM = createAction(`${subject} get content`);
export const setKM = createAction(`${subject} set content list`);
export const isLoaded = createAction(`${subject} is loading`);
export const setTotal = createAction(`${subject} set total`);
export const onCloseModal = createAction(`${subject} unmount`);

