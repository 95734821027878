import { get, intersection } from 'lodash';
import { createSelector } from 'reselect';
import * as appSelectors from '../../App/App.selectors';
import { getAuthorities, getOrgRoles } from '../../../utils/userUtils';
import { getMenuItems } from '../PageMenu.utils.js';
import * as authSelectors from '../../Auth/Auth.selectors';
import { userInfo } from '../../../common_components/Auth/Auth.selectors';


const configSelector = state => appSelectors.config(state);

const authoritiesSelector = state => {
  const info = userInfo(state)
  return get(info, 'authorities', []);
}

const orgRolesSelector = createSelector(
  authoritiesSelector,
  data => data.filter(v => ~v.indexOf('ROLE_ORG_'))
    .map(v => v.replace('ROLE_ORG_', ''))
);


export const itemsSelector = createSelector(
  configSelector,
  authoritiesSelector,
  orgRolesSelector,
  (config, authorities, orgRoles) => getMenuItems(orgRoles, authorities, config.menuItems)
)

export const header = state => get(appSelectors.config(state), 'menuHeader', undefined);
