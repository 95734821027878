import React from 'react';
import DataTable from '@crpt-ui/datatable';
import TableScrollWrapper from 'src/components/TableScrollWrapper';
import {declarationColumns} from './DeclarationTable.constants';

export const DeclarationTableView = ({data, tableInstance}) => {
  return (
    <React.Fragment>
      <TableScrollWrapper>
        <DataTable
          data={data}
          columns={declarationColumns}
          ref={tableInstance}
        />
      </TableScrollWrapper>
    </React.Fragment>
  )
};