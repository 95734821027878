import { Icon } from '@crpt-ui/core';
import React from 'react';
import { historyOperationTypes } from 'src/constants';
import { documentTypes } from 'src/constants/documentTypes';
import { Link } from 'src/fragments/Link';
import { CellTooltip } from 'src/fragments/Table/Cell/CellTooltip';

import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import { getDocTypeName } from './constants';
import Layout from './Layout';
import { autoDisaggregationTypesNotForReceipt } from '../../../Documents/Documents.constants';
import { Translate } from '../../../../../common_components/Translate/Translate';

const AutoDisaggregation = ({ op }) => {
  const classes = useStyles();

  const checkedReceipt = op =>
    op.documentType === documentTypes.RECEIPT ||
    op.documentType === documentTypes.RECEIPT_RETURN;

  const isUuid = (id) => {
    const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    return Boolean(id.match(regex))
  }

  let path = checkedReceipt(op)
    ? `/documents/receipt/${encodeURIComponent(op.docId)}/basic-info`
    : `/documents/list/${encodeURIComponent(op.docId)}/basic-info`;

  if (op.type === historyOperationTypes.AUTO_DISAGGREGATION && !autoDisaggregationTypesNotForReceipt.includes(op.documentType)) {
    path = `/documents/receipt/${encodeURIComponent(op.docId)}/`;
  }

  if (op.type === historyOperationTypes.AUTO_DISAGGREGATION && isUuid(op.docId)) {
    path = `/documents/list/${encodeURIComponent(op.docId)}/`
  }

  const renderValues = () => (
    <Link className={classes.link} to={path}>
      {/*{middleDottedString(op.docId)}*/}
      {op.docId}
    </Link>
  );

  return (
    <Layout>
      <FirstCell icon={<Icon name="AutoDisaggregation" />} title={Translate('Автодезагрегация')} />
      <SecondCell op={op} />
      <ThirdCell heading={Translate(getDocTypeName(op.documentType))}>
        <CellTooltip
          alwaysDisplay
          placement="top"
          title={op.docId}
          render={renderValues}
        />
      </ThirdCell>
    </Layout>
  );
};

export default AutoDisaggregation;
