import { takeLatest, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import * as actions from './ParticipantSelect.actions';
import * as autocompleteActions from 'src/common_components/Filters/AutocompleteFilter/ducks/AutocompleteFilter.actions';
import { ParticipantService } from 'src/services/ParticipantService';

function* loadSaga(action) {
  const { payload } = action;

  const response = yield call(ParticipantService.searchByInput, payload);

  if (get(response, 'data.error', false)) {
    yield put(autocompleteActions.error, get(response, 'data.error', 'error'));
  } else {
    yield put(autocompleteActions.loaded(response.data));
  }
}

export default function* watch() {
  yield takeLatest(actions.updated, loadSaga);
}
