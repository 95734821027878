import { Icon } from '@crpt-ui/core';
import React from 'react';
import ParticipantNameBound from 'src/containers/Bounds/ParticipantNameBound';
import { Link } from 'src/fragments/Link';
import { getStatusIconByDocStatus } from 'src/fragments/Table/Cell/icons';

import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import FourthCell from './components/FourthCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const Export = ({ op }) => {
  const classes = useStyles();
  return (
    <Layout>
      <FirstCell icon={<Icon name="Export" />} title={Translate('Экспорт')} />
      <SecondCell firstHeading={Translate('Экспортер (продавец)')} op={op} />
      <ThirdCell heading={Translate('Импортер (покупатель)')}>
        <Link to={`/participants/list/${op.participant2Inn}/basic-info`}>
          <ParticipantNameBound
            id={op.participant2Id}
            name={op.participant2Name}
            inn={op.participant2Inn}
          />
        </Link>
      </ThirdCell>
      <FourthCell heading={Translate('Документ об экспорте')}>
        {op.documentStatus && (
          <div className={classes.icon}>
            {getStatusIconByDocStatus[op.documentStatus]}
          </div>
        )}
        <Link
          className={classes.link}
          to={`/documents/list/${encodeURIComponent(op.docId)}/basic-info`}
        >
          {op.docId}
        </Link>
      </FourthCell>
    </Layout>
  );
};

export default Export;
