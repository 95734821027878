import styled from 'styled-components';


const Wrapper = styled.div`
  js-display: flex;
  display: flex;
  align-items: center;
  width: 100%;
`;

export default Wrapper;
