import React, { memo } from 'react';
import PropTypes from 'prop-types';
import format from '@crpt-ui/core/lib/DatePicker/utils/format.i18n';
import { Close } from '@crpt-ui/icons';
import { useTranslation } from 'react-i18next';

const Header = memo(({ startDate, endDate, type, onClose, isFilter }) => {
  const {t} = useTranslation()

  return (
    <div style={{
      borderBottom: '1px solid #F2F2F2',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      color: '#63666A',
      lineHeight: '12px',
      fontWeight: 600,
    }}>
      <p style={{
        fontSize: 14,
      }}>
        {!startDate && !endDate &&
          t('Дата')
        }
        {startDate && !endDate &&
          format(startDate, 'dd MMMM', 'ru') + (type !== 'single' ? ' - ...' : '')
        }
        {startDate && endDate &&
          format(startDate, 'dd MMMM', 'ru') + ' - ' + format(endDate, 'dd MMMM', 'ru')
        }
      </p>
      <span>
        {!isFilter && <MemoizedCloseButton onClose={onClose} />}
      </span>
    </div>
  );
});

const MemoizedCloseButton = memo(({ onClose }) => (
  <Close
    style={{
      cursor: 'pointer',
      fontSize: 20,
    }}
    onClick={onClose}
  />
));

MemoizedCloseButton.propTypes = {
  onClose: PropTypes.func,
};

Header.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  type: PropTypes.string,
  onClose: PropTypes.func,
  isFilter: PropTypes.bool,
};

export default Header;
