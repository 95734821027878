import { authInstance } from './authInstance';
import {getPrefix} from '../utils';
import axios from 'axios';

const CancelToken = axios.CancelToken;

export class InformerService {

  static _getDataCancel;

  static getInformersData() {

    if (InformerService._getDataCancel)
      InformerService._getDataCancel('PREVENT_TOAST');

    return authInstance.request({
      method: 'get',
      withCredentials: false,
      url: `/stat/get_informers_data`,
      cancelToken: new CancelToken(function(c) {
        InformerService._getDataCancel = c;
      })
    });
  }

}
