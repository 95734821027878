import styled from 'styled-components';
import { get } from 'lodash';

export const ActiveStatus = styled.div`
  background-color: #689F38;
  border-radius: 16px;
  padding: 4px 8px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PlannedStatus = styled.div`
  background-color: rgba(0, 0, 0, 8%);
  border-radius: 16px;
  padding: 4px 8px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const StoppedStatus = styled.div`
  background-color: #ED6C02;
  border-radius: 16px;
  padding: 4px 8px;
  color: white;
  gap: 5px;
  display: flex;
  align-items: center;
`;

export const ArticleType = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
