import { Box, Grid, withStyles } from '@material-ui/core';
import React from 'react';

const InfoBlock = withStyles({
  title: {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 20 / 13,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  description: {
    fontSize: 16,
    lineHeight: 24 / 16,
  },
})(({ classes, title, description, children }) => (
  <Box py={4}>
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} md={5}>
        <div className={classes.title}>{title}</div>
        {Boolean(description) && (
          <div className={classes.description}>{
            typeof description === 'function' ? React.createElement(description) : description
          }</div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {children}
      </Grid>
    </Grid>
  </Box>
));

export default InfoBlock;
