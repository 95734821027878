import { createSelector } from 'reselect';

export const loaded = state => state.LP.GrayArea.GrayAreaAdditionalInfo.GrayAreaGtins.loaded;
export const page = state => state.LP.GrayArea.GrayAreaAdditionalInfo.GrayAreaGtins.page;
export const result = state => state.LP.GrayArea.GrayAreaAdditionalInfo.GrayAreaGtins.result;
export const total = state => state.LP.GrayArea.GrayAreaAdditionalInfo.GrayAreaGtins.total;

export const pagesCount = createSelector(
  total,
  total => Math.ceil(total / 10),
);

