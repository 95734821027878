import React from 'react';
import PropTypes from 'prop-types';
import { SIZES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { FormLine } from '@crpt/react-form';
import { required } from '../../../common_components/Form/utils/validations';
import { ErrorNotification } from '../LoginForm.styled';
import Input from '../../../common_components/Form/Fields/_refactor/Input/Input';
import SubmitButton from '../../../common_components/Form/Fields/Button/SubmitButton';
import { Translate } from '../../../common_components/Translate/Translate';


const FieldsView = ({ errors, disabled }) => (
  <React.Fragment>
    <Input
      placeholder={Translate("Пользователь")}
      name="login"
      disabled={disabled}
      validate={required}
      marginBottom="24px"
    />
    <Input
      placeholder={Translate("Пароль")}
      name="password"
      type="password"
      disabled={disabled}
      validate={required}
      marginBottom="24px"
    />
    <FormLine>
      <SubmitButton
        size={SIZES.large}
        marginBottom="0"
        color="primary"
      >
        {Translate('Вход в личный кабинет')}
      </SubmitButton>
      {errors && (
        <ErrorNotification>
          <Icon
            type="not-allow"
            fill="#FF3C21"
            spacingRight={10}
          />
          {errors}
        </ErrorNotification>
      )}
    </FormLine>
  </React.Fragment>
);

FieldsView.propTypes = {
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.string,
};

FieldsView.defaultProps = {
  errors: null,
};


export default FieldsView;
