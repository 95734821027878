import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import { checkboxState } from '../SelectionList/SelectionCheckbox';
import { prepareColumns } from '../SelectionList/SelectionList.utils';
import { FormContext } from 'src/common_components/Form/Form.constants';
import { SelectionStatus } from '../SelectionList/SelectionList.constants';
import List from '../List';
import ToggleCheckbox from '../SelectionList/Toggle/ToggleCheckbox';
import { get, uniqBy } from 'lodash';

const SelectionTable = withTheme(selectTableHoc(List));
const ThemeTable = withTheme(List);

class TreeList extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    options: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    noDataText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    isToggleBlocked: PropTypes.func,
    getCheckboxState: PropTypes.func,
    disabled: PropTypes.bool,
    showToggle: PropTypes.bool,
    customSelectAll: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    columns: [],
    options: [],
    data: [],
    noDataText: '',
    isToggleBlocked: () => false,
    getCheckboxState: () => false,
    disabled: false,
    customSelectAll: false,
    showToggle: true,
    onUpdate: () => null,
  };

  state = {
    selectedRows: [],
    selectedAllPages: [],
    data: [],
    preparedData: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        data: props.data,
        preparedData: props.data.map((item, index) => ({
          ...item,
          _id: index,
        })),
      };
    }

    return null;
  }

  toggleSelection = key => {
    const { isToggleBlocked, onUpdate } = this.props;
    const { selectedRows } = this.state;

    if (isToggleBlocked()) return;

    let selection = [...selectedRows];

    const index = selection.indexOf(key);
    if (index >= 0) {
      selection = [...selection.slice(0, index), ...selection.slice(index + 1)];
    } else {
      selection.push(key);
    }

    this.setState({ selectedRows: selection }, onUpdate);
  };

  isRowSelected = key => {
    const { selectedRows } = this.state;
    return selectedRows.includes(key);
  };

  clearSelected = () => {
    const { onUpdate } = this.props;
    if (this.innerRef && this.innerRef.parentNode) {
      const scroller = this.innerRef.parentNode.querySelector('.tablescroller');
      if (scroller) scroller.scrollLeft = 0;
    }
    this.setState({ selectedRows: [] }, onUpdate);
  };

  getStatus = () => {
    const { selectedRows } = this.state;
    if (selectedRows.length) return SelectionStatus.SELECTED;
    return SelectionStatus.NORMAL;
  };

  getCheckboxState = (checkDisabled = false) => (id, checked) => {
    const { disabled, getCheckboxState } = this.props;
    const { preparedData } = this.state;
    let status = checkboxState.NORMAL;
    const docId = id.replace('select-', '');
    const find = preparedData.find((item) => item.id === docId);

    if (find && find.hiddenCheckBox)
      return { status, disabled };

    if (checkDisabled && disabled) status = checkboxState.HIDDEN;
    if (checked || this.isRowSelected(id))
      status = checkboxState.SELECTED;
    if (getCheckboxState(id)) status = checkboxState.EDITED;

    const children = uniqBy(get(find, 'documents', []), 'id');

    if (children && children.length === 1) {
      status = checkboxState.HIDDEN;
    }

    if (find && find.hiddenToggle ) {
      status = checkboxState.HIDDEN;
    }

    return {
      status,
      disabled,
    };
  };

  onInnerRef = f => {
    this.innerRef = f;
  };

  onCheckboxTableRef = f => {
    this.checkboxTable = f;
  };

  render() {
    const {
      columns,
      formValues,
      name,
      noDataText,
      selectType = 'checkbox',
      height,
      showToggle,
    } = this.props;
    const { preparedData } = this.state;
    const preparedColumns = prepareColumns(name, columns, formValues);
    return showToggle
      ? (
        <React.Fragment>
          <SelectionTable
            minRows={5}
            highlight={true}
            striped={true}
            noDataText={noDataText}
            selectType={selectType}
            selectWidth={65}
            SelectInputComponent={ToggleCheckbox(
              this.getCheckboxState(false),
            )}
            SelectAllInputComponent={() => <span />}
            toggleSelection={this.toggleSelection}
            toggleAll={() => {}}
            isSelected={this.isRowSelected}
            {...this.props}
            data={preparedData}
            columns={preparedColumns}
            height={height}
            ref={this.onCheckboxTableRef}
            keyField='id'
          />
          <div ref={this.onInnerRef} />
        </React.Fragment>
      )
      :  (
        <ThemeTable
          minRows={5}
          highlight
          striped
          noDataText={noDataText}
          {...this.props}
          data={preparedData}
          columns={preparedColumns}
          height={height}
        />
      );
  }
}

export default props => (
  <FormContext.Consumer>
    {({ values }) => (
      <TreeList ref={props.onRef} {...props} formValues={values} />
    )}
  </FormContext.Consumer>
);
