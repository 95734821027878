import {actionTypes} from '../constants';
import {AuthService} from '../services/AuthService';
import axios from 'axios';
import {ProfileService} from '../services/ProfileService';
import {saveToken} from '../utils/cookieHelpers';
import {localStorageHelpers} from '../utils/localStorageHelpers';

function filledWithValues() {
  return {
    type: actionTypes.LOGIN_FORM.FILLED_WITH_VALUES
  };
}

function loginCleared() {
  return {
    type: actionTypes.LOGIN_FORM.LOGIN_CLEARED
  };
}

function passwordCleared() {
  return {
    type: actionTypes.LOGIN_FORM.PASSWORD_CLEARED
  };
}

function gotLoginSuccess() {
  return {
    type: actionTypes.LOGIN_FORM.GOT_SUCCESS_LOGIN
  };
}

function gotLoginError() {
  return {
    type: actionTypes.LOGIN_FORM.GOT_ERROR_LOGIN
  };
}

// function submitClicked(values, history) {
//   return dispatch => {
//     dispatch({ type: actionTypes.LOGIN_FORM.SUBMIT_CLICKED });
//
//     AuthService.login({ username: values.login[0], password: values.password[0] })
//       .then(answer => {
//           console.log('answer login', answer.data);
//           localStorage.setItem('token', answer.data.access_token);
//           dispatch(gotLoginSuccess());
//
//           history.push("/");
//
//       })
//       .catch(err => null);
//   };
// }

function submitClicked(values, history) {
  return dispatch => {
    dispatch({ type: actionTypes.LOGIN_FORM.SUBMIT_CLICKED });

    console.log('before login req');

    AuthService.login({ login: values.login[0], password: values.password[0] })
      .then(async answer => {
        console.log('i dont know what happens - it should be in console');

        const url = answer.request.responseURL || '';
        if (~url.indexOf('Error')) {
          dispatch(gotLoginError());

          console.log('Login answer error', answer);
        } else {
          saveToken(answer.data.token);
          const user = {};
          console.log('may be now?');
          user.isAdmin = answer.data.admin;
          const roles = localStorageHelpers.getRolesFromToken(answer.data.token);
          Object.assign(user, roles);
          user.participant = answer.data.participant;

          try {
            const profile = await ProfileService.getCompanyProfile();
            user.company = profile.data;
          }

          catch(e) { null; }

          try {
            const image = await ProfileService.getParticipantImage(user.id);
            const base64String = btoa(
              String.fromCharCode(...new Uint8Array(image.data))
            );
            user.company
              ? (user.company.image = 'data:image/png;base64,' + base64String)
              : (user.company = {image: 'data:image/png;base64,' + base64String});
          } catch (e) {
            console.log('cannot get participant image:', e);
          }

          localStorage.setItem('user', JSON.stringify(user));
          dispatch(gotLoginSuccess());

          console.log('login', values.login[0], 'data', answer.data);

          history.push('/');
        }
      })
      .catch(err => dispatch(gotLoginError()));
  };
}

export {
  filledWithValues,
  loginCleared,
  passwordCleared,
  submitClicked,
  gotLoginSuccess
};
