import {combineReducers} from 'redux';

import {total} from './total';
import {filterParams} from './filterParams';
import {pagesCount} from './pagesCount';
import {selectedPage} from './selectedPage';
import {CISList} from './CISList';
import {isInfinite} from './isInfinite';

export default combineReducers({
  total,
  filterParams,
  pagesCount,
  selectedPage,
  CISList,
  isInfinite
});
