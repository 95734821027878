import { selector } from 'src/utils/selector';
import { limit } from '../ImportList.constants';

export const results = state => selector(state, 'Requests.ImportList.results');
export const total = state => selector(state, 'Requests.ImportList.total');
export const last = state => selector(state, 'Requests.ImportList.last');
export const pagesCount = state => Math.ceil(total(state) / limit);
export const sorting = state => selector(state, 'Requests.ImportList.sorting');
export const selectedPage = state => selector(state, 'Requests.ImportList.selectedPage');
export const pagination = state => selector(state, 'Requests.ImportList.pagination');
export const filters = state => selector(state, 'Requests.ImportList.filters');
export const uploadErrors = state => selector(state, 'Requests.ImportList.uploadErrors');
export const offset = state => selector(state, 'Requests.ImportList.offset');
