import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';
import FileUploadModalContent from '../../../../../common_components/Modals/FileUploadModal/FileUploadModal.content';
import * as actions from '../ducks/CisRegistration.actions';
import { connect } from 'react-redux';
import { STEPS } from '../../NotificationOfEntry/Goods/Goods.constants';
import * as selectors from '../../NotificationOfEntry/Goods/ducks/Goods.selectors';

const UploadCisesModal = ({ types, onCloseModal, reset, convert, loadFile, step, errorText }) => {
  const onLoad = file => {
    convert({ file, onCloseModal });
  };

  const onDrop = files => {
    loadFile();
  };

  const onClose = e => {
    reset();
    onCloseModal();
  };

  return (
    <FileUploadModalContent
      onCloseModal={onClose}
      errorContent={<div>{errorText}</div>}
      types={types}
      onLoad={onLoad}
      onDrop={onDrop}
      isLoading={step === STEPS.LOADING}
      fileOpenParams={{
        asArrayBuffer: true,
      }}
      title={Translate('Загрузка кодов идентификации')}
      preloaderText={Translate('Загрузка файла. Пожалуйста, подождите')}
      beforeLoadText={Translate(
        'Перетащите или выберите файл формата *.csv',
      )}
    />
  )
};

const mapState = state => ({
  step: selectors.step(state),
  errorText: selectors.errorText(state),
});

const mapDispatch = dispatch => ({
  convert: (d) => dispatch(actions.convert(d)),
  reset: () => dispatch(actions.reset()),
  loadFile: () => dispatch(actions.load()),
});

export default connect(mapState, mapDispatch)(UploadCisesModal);
