import { createAction } from 'redux-actions';

export const subject = '[FilePreview]';


export const openFile = createAction(
  `${subject} open file`,
  payload => payload,
  (_, meta) => meta
);

export const openString = createAction(
  `${subject} open string`,
  payload => payload,
  (_, meta) => meta
);

export const close = createAction(`${subject} close`);

export const setContent = createAction(
  `${subject} set content`,
  payload => payload,
  (_, meta) => meta,
);

export const setCallbacks = createAction(`${subject} set callbacks`);

export const openFileDialog = createAction(`${subject} open file dialog`);
export const isUpdated = createAction(`${subject} is updated`);
