import { selector } from '../../../../../../utils/selector';

export const step = state =>
  selector(state, 'Requests.RequestList.ImportThirdCountry.Goods.step');
export const errorText = state =>
  selector(state, 'Requests.RequestList.ImportThirdCountry.Goods.errorText');
export const savedProducts = state =>
  selector(
    state,
    'Requests.RequestList.ImportThirdCountry.Goods.savedProducts',
  );
