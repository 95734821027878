import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import 'moment/locale/ru';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import lodash from 'lodash';
import * as authSelectors from '../../common_components/Auth/Auth.selectors';
import ButtonTabs from '../ButtonTabs/ButtonTabs';
import BasicInfo from './BasicInfo';
import EAESProductAdditionalInfo from './EAESProductAdditionalInfo';
import Edit from './Edit';
import { escapeUrlForRoutePath } from 'src/utils';
import {
  HeadingWrap,
  HeadingSmallTitle,
  HeadingId,
  BreadcrumbsWrap,
} from '../Details/styled';
import { ContentWrapper, WithBorder } from '../common/styled';
import ActionBar from '../common/ActionBar';
import { countryCode } from 'src/common_components/App/App.selectors';
import { Translate } from '../../common_components/Translate/Translate';

moment.locale('ru');

const propTypes = {};

const DocIdAndLabelWrap = styled.div`
  display: flex;
  max-width: calc(100vw - 401px);
  width: calc(100vw - 401px);
  justify-content: space-between;
`;

const DocIdAndLabel = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

class ProductDetails extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    const formated = decodeURIComponent(id);

    if (
      window.location.pathname === `/products/list/${id}` ||
      window.location.pathname === `/products/list/${id}/`
    )
      this.props.history.replace(`/products/list/${id}/basic-info`);

    this.props.actions.ProductDetails.ProductDetailsMounted(formated);
  }

  componentDidUpdate(prevProps) {
    const { actions, match, ProductDetails } = this.props;
    const id = match.params.id;
    const formated = decodeURIComponent(id);
    const selectedProducttID = decodeURIComponent(prevProps.match.params.id);
    if (selectedProducttID != formated) {
      actions.ProductDetails.ProductDetailsMounted(
        formated,
        ProductDetails.producerId,
      );
    }
  }

  render() {
    const { match, countryCode, roles } = this.props;
    const {
      params: { id },
    } = match;

    const formated = decodeURIComponent(id);
    const selectedProduct = this.props.ProductDetails.selectedProduct;
    const productName = lodash.get(selectedProduct, 'name', false);
    const productGroupId = +lodash.get(selectedProduct, 'productGroupId', 0);
    const productGroupMap = [8, 11, 15];

    if (countryCode === 'KZ') productGroupMap.push(2);

    const isProductGroupWithAdditionalInformation = productGroupMap.includes(
      productGroupId,
    );

    const breadcrumbs = [
      {
        href: '/products/list',
        title: Translate('Товары'),
        id: 1,
      },
      {
        href: `/products/list/${id}`,
        title: <span style={{ fontWeight: 600 }}>{productName || ''}</span>,
        id: 2,
      },
    ];

    const tabs = [
      {
        id: 0,
        title: Translate('Общая информация'),
        route: `/products/list/${id}/basic-info`,
        check: [
          `/products/list/${id}/basic-info`,
          `/products/list/${id}/basic-info/edit`,
        ],
      },
    ];

    if (isProductGroupWithAdditionalInformation) {
      tabs.push({
        id: 1,
        title: Translate('Дополнительная информация'),
        route: `/products/list/${id}/additional-info`,
        check: [`/products/list/${id}/additional-info`],
      });
    }

    const currentIndex = tabs.findIndex(
      v => ~v.check.indexOf(window.location.pathname),
    );

    const leftSideComponents = [
      {
        component: (
          <ButtonTabs
            tabs={tabs}
            currentIndex={currentIndex}
            onSelect={val => this.props.history.push(val.route)}
          />
        ),
        wrap: true,
      },
    ];

    const rightSideComponents = [
      <div key="0" id="filterButtonPlace" />,
      <div key="1" id="downloadButtonPlace" />,
    ];

    return (
      <div>
        <BreadcrumbsWrap>
          <Breadcrumbs
            withCustomLinks
            onClick={val => this.props.history.push(val.href)}
            breadcrumbs={breadcrumbs}
          />
        </BreadcrumbsWrap>

        <ContentWrapper>
          <ActionBar
            leftSideComponents={leftSideComponents}
            rightSideComponents={rightSideComponents}
          />

          <HeadingWrap>
            <HeadingSmallTitle>{Translate('Товар')}</HeadingSmallTitle>
            <DocIdAndLabelWrap>
              <DocIdAndLabel>
                <HeadingId>{productName || ''}</HeadingId>
              </DocIdAndLabel>
            </DocIdAndLabelWrap>
          </HeadingWrap>

          <WithBorder>
            <Switch>
              <Route
                render={props =>
                  selectedProduct && (
                    <BasicInfo
                      isEAESCountry={true}
                      selectedProduct={selectedProduct}
                      id={formated}
                      roles={roles}
                      countryCode={countryCode}
                      path={escapeUrlForRoutePath(
                        `/products/list/${id}/basic-info`,
                      )}
                      actions={this.props.actions}
                      {...props}
                    />
                  )
                }
                path={escapeUrlForRoutePath(`/products/list/${id}/basic-info`)}
                exact
              />

              <Route
                render={props =>
                  selectedProduct && (
                    <Edit
                      selectedProduct={selectedProduct}
                      id={formated}
                      path={escapeUrlForRoutePath(
                        `/products/list/${id}/basic-info`,
                      )}
                      actions={this.props.actions}
                      {...props}
                    />
                  )
                }
                path={escapeUrlForRoutePath(
                  `/products/list/${id}/basic-info/edit`,
                )}
                exact
              />

              <Route
                exact
                path={escapeUrlForRoutePath(
                  `/products/list/${id}/additional-info`,
                )}
                render={props =>
                  selectedProduct && (
                    <EAESProductAdditionalInfo
                      selectedProduct={selectedProduct}
                      countryCode={countryCode}
                      {...props}
                    />
                  )
                }
              />
            </Switch>
          </WithBorder>
        </ContentWrapper>
      </div>
    );
  }
}

ProductDetails.propTypes = propTypes;

const mapStateToProps = state => ({
  countryCode: countryCode(state),
  roles: authSelectors.getRoles(state),
});

export default compose(withRouter, connect(mapStateToProps))(ProductDetails);
