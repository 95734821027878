import { call, put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';

import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';

import * as grayAreaProductionLinesActions from './GrayAreaProductionLines.actions';

function* requestProductionLines() {
  const requestPayload = {
    url: `/api/v3/facade/profile/productions`,
    method: 'GET',
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  let productionLines = [];
  if (_.get(success, 'data.result')) {
    productionLines = success.data.result.reduce(
      (previousValue, currentValue) => {
        const currentProductionLines = currentValue.productionLines.map(
          productionLine => ({
            productionLine,
            ..._.omit(currentValue, ['productionLines']),
          }),
        );
        previousValue.push(...currentProductionLines);
        return previousValue;
      },
      [],
    );
  }

  yield put(
    grayAreaProductionLinesActions.loaded({
      result: productionLines,
      total: productionLines.length,
    }),
  );
}

export default function* watch() {
  yield takeLatest(
    grayAreaProductionLinesActions.requestProductionLines,
    requestProductionLines,
  );
}
