import { takeEvery, put, take, call, race, select } from 'redux-saga/effects';
import { flattenDeep, get, groupBy } from 'lodash';
import * as actions from './Goods.actions';
import {
  goodsKey,
  cisKey,
  invalidCisKey,
} from '../../NotificationOfEntry.constants';
import Api from 'src/common_components/Api/Api';
import * as fileActions from 'src/common_components/File/File.actions';
import { Toast } from 'src/utils/Toast';

function* loadSaga(action, state) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn } = payload;

  const fileType = get(file, 'type');

  if (!['XLS', 'XLSX'].includes(fileType)) {
    yield put(actions.reset());
    Toast.showError({
      content: `Загружаемый файл не соответствует установленному формату. Загрузите файл формата *.xls, *xlsx`,
    });
    return;
  }

  const data = {
    userId: +userId,
    organisationInn: inn,
  };

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(data)], { type: 'application/json' }),
  );

  formData.append(
    'file',
    new Blob([file.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    file.name,
  );

  const requestPayload = {
    url: '/api/v3/facade/marked_products/search-by-xls-cis-list',
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  // //далее надо получить список кодов

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
        console.log('error', error);
      } else {
        const data = success.data.valid.results.map((item, i) => {
          return {
            idx: i + 1,
            cis: item.cis,
            name: item.productName,
            gtin: item.gtin,
            cisPackageType: item.cisPackageType,
          };
        });

        const invalidData = success.data.invalid;
        console.log('answer from server', data);

        const productKeys = groupBy(data, 'gtin');
        const products = Object.keys(productKeys).map(key => {
          const productFirstCis = productKeys[key][0];
          return {
            name: productFirstCis.productName,
            gtin: key,
            codes: productKeys[key].map(code => code.cis),
            ownerName: productFirstCis.ownerName,
            ownerInn: productFirstCis.ownerInn,
            producerName: productFirstCis.producerName,
            producerInn: productFirstCis.producerInn,
          };
        });

        formMutators.updateField(goodsKey, products);
        formMutators.updateField(cisKey, data);
        formMutators.updateField(invalidCisKey, invalidData);
        yield call(onCloseModal);
        yield put(actions.reset());
      }
    } catch (e) {
      yield put(actions.reset());
      Toast.showError({
        content: `Ответ от сервера не соответствует ожидаемому формату.`,
      });
      console.log(e, { e });
    }
  }
  if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false);

    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
  }
}

function* loadCisSaga(action, state) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn } = payload;
  yield put(
    fileActions.read(file, { subject: actions.openFile, rawWithText: true }),
  );

  const fileLoaderData = yield race({
    success: take(`${fileActions.success} ${actions.openFile}`),
    error: take(`${fileActions.error} ${actions.openFile}`),
  });
  yield put(actions.load());
  const fileData = get(fileLoaderData, 'success.payload', {
    content: null,
    data: null,
  });

  if (fileData) {
    yield put(actions.convert({ ...payload, file: fileData }));
  } else {
    yield put(actions.error('Недопустимый формат файла!'));
  }
}

export default function* watch() {
  yield takeEvery(actions.convert, loadSaga);
  yield takeEvery(actions.convertCis, loadCisSaga);
}
