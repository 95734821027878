import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Показатель',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': <ul><li>Подробный</li></ul>,
          'col3': 'ИНН и наименование участника оборота товара, зарегистрированного в системе'
        },
        {
          'col1': [3],
          'col2': <ul>
            <li>Подробный</li>
            <li>Общий</li>
          </ul>,
          'col3': 'Тип участника оборота товара'
        },
        {
          'col1': [4],
          'col2': <ul><li>Подробный</li></ul>,
          'col3': 'Дата регистрации участника оборота товара в системе'
        },
        {
          'col1': [5],
          'col2': <ul><li>Подробный</li></ul>,
          'col3': 'Юридический адрес участника оборота товара'
        },
        {
          'col1': [6],
          'col2': <ul><li>Подробный</li></ul>,
          'col3': 'Наименование оператора фискальных данных, с которым у участника оборота товара заключён договор'
        },
        {
          'col1': [7],
          'col2': <ul><li>Общий</li></ul>,
          'col3': 'Количество участников, зарегистрированных в системе с данным типом участника оборота товара'
        },
        {
          'col1': [8],
          'col2': <ul>
            <li>Подробный</li>
            <li>Общий</li>
          </ul>,
          'col3': 'Количество ККТ, установленных у участников оборота товара'
        },
        {
          'col1': [9],
          'col2': <ul>
            <li>Подробный</li>
            <li>Общий</li>
          </ul>,
          'col3': 'Количество адресов, где установлены эти ККТ'
        },
      ]
    }
  ]
}