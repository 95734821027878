import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  root: {
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing(4)}px`,
  },
  closeWrapper: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(2.5),
    '& > svg': {
      cursor: 'pointer',
    },
  },
  devider: {
    backgroundColor: '#F2F2F2',
    height: 1,
    width: '100%',
  },
}));
