import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  compose,
  branch,
  renderNothing,
  renderComponent,
  withProps,
} from 'recompact';
import { participantValues, RegistrationSteps } from '../../constants';
import { PARTICIPANT_TYPES } from '../../../../constants';
import CurrentStep from './CurrentStep';

const getParticipantValues = orgRoles => {
  if (!orgRoles || !orgRoles.length) return participantValues;
  if (orgRoles[0] === PARTICIPANT_TYPES.OGV) {
    return participantValues.map(
      v => (v.type !== PARTICIPANT_TYPES.OGV ? { ...v, disabled: true } : v)
    );
  }

  return participantValues.map(
    v => (v.type === PARTICIPANT_TYPES.OGV ? { ...v, disabled: true } : v)
  );
};

export default compose(
  branch(({ step }) => step === RegistrationSteps.COMPLETE, renderNothing),
  withProps(({ values, mutators, errors }) => {
    return {
      orgRoles: values.orgRoles || [],
      participantValues: getParticipantValues(values.orgRoles),
      onUpdateField: mutators.updateField,
      onPushValue: mutators.push,
      onRemoveValue: mutators.remove,
      onAddUnique: mutators.addUnique,
      onToggle: mutators.toggle,
      canNext: isEmpty(errors),
    };
  }),
  connect(state => ({ errors: state.Registration.errors }))
)(CurrentStep);
