import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { MuiThemeProvider } from '@material-ui/core';

import {
  SignModalWrap,
  ModalHeading,
  ModalContentWrap,
  ButtonWrap,
} from './Cis.styled';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import { InfoFields, InfoWrap } from '../../Document/Document.styled';
import { documentNameOptions } from '../EaesImportDocument.constants';
import CertLine from './SignModalContent/CertLine';
import CommonActionButton from '../../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from '../../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import { submit } from '../ducks/EaesImportDocument.actions';
import CountrySelect from '../../../../../common_components/Form/MaterialFields/Select/CountrySelect';
import Select from '../../../../../common_components/Form/MaterialFields/Select/Select';
import { isSignButtonDisabled } from './ducks/Cis.selectors';
import { MaterialUiThemeKZ } from '../../../../../common_components/_MaterialUi/ThemeKZ';
import { Translate } from '../../../../../common_components/Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

const defaultProps = {};

class SignModalContent extends React.Component {
  onLoad = file => {
    console.log('onLoad', file);
    const { onCloseModal, formMutators, inn, userId } = this.props;
    this.props.convert({ file, onCloseModal, formMutators, inn, userId });
  };

  onError = error => {
    console.log('onError', error);
  };

  onDrop = files => {
    this.props.load();
  };

  onCancelSign = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  onClose = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  getQty = file => {
    try {
      const qty = JSON.parse(file.content);
      return qty.filter(
        ({ receipt }) => receipt.code === 3 || receipt.code === 31,
      ).length;
    } catch (e) {
      return 0;
    }
  };

  render() {
    const { onCloseModal, isSignButtonDisabled, values } = this.props;

    const accompanyingDocumentNameValue = values['accompanying_document_name'];
    const preparedDocumentNameOptions = documentNameOptions.some(
      ({ value }) => value === accompanyingDocumentNameValue,
    )
      ? documentNameOptions
      : [
          {
            value: accompanyingDocumentNameValue,
            label: accompanyingDocumentNameValue,
          },
          ...documentNameOptions,
        ];

    return (
      <MuiThemeProvider theme={MaterialUiThemeKZ}>
        <SignModalWrap>
          <ModalHeading>
            {Translate('Заявление о ввозе товаров (ЕАЭС)')}{' '}
            <Icon type={'cross'} onClick={onCloseModal} />
          </ModalHeading>
          <ModalContentWrap>
            <div>
              <InfoFields>
                <Input
                  name="applicant_inn"
                  placeholder={Translate('ИНН заявителя')}
                  disabled
                />

                <Input
                  forceValidate
                  required
                  name="accompanying_document_name"
                  placeholder={Translate('Наименование документа')}
                  disabled
                />

                <Input
                  forceValidate
                  required
                  name="sender_name"
                  placeholder={Translate('Наименование отправителя')}
                  disabled
                />

                <Input
                  required
                  forceValidate
                  name="accompanying_document_date"
                  placeholder={Translate('Дата документа')}
                  disabled
                />

                <Input
                  required
                  name="sender_inn"
                  placeholder={Translate('Инн отправителя (или его аналог)')}
                  disabled
                />

                <Input
                  required
                  name="accompanying_document_number"
                  placeholder={Translate('Номер документа')}
                  disabled
                />

                <CountrySelect
                  forceValidate
                  placeholder={Translate('Страна отправления')}
                  name="sender_country"
                  disabled
                />
              </InfoFields>
            </div>
          </ModalContentWrap>
          {/*<CertLine />*/}
          <ButtonWrap>
            <PrimaryActionLargeButton
              disabled={isSignButtonDisabled}
              onClick={e => this.props.submit(values)}
            >
              {Translate('Подписать и отправить')}
            </PrimaryActionLargeButton>
            <CommonActionButton onClick={onCloseModal}>
              {Translate('Отменить')}
            </CommonActionButton>
          </ButtonWrap>
        </SignModalWrap>
      </MuiThemeProvider>
    );
  }
}

SignModalContent.defaultProps = defaultProps;
SignModalContent.propTypes = propTypes;

const mapState = state => {
  return {
    isSignButtonDisabled: isSignButtonDisabled(state),
  };
};

const mapDispatch = dispatch => ({
  submit: values => dispatch(submit(values)),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(SignModalContent);
