import { Button } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';

import EmptyProductionLines from '../EmptyProductionLines';
import { sizedModalColumns } from '../ProductionLines.constants';
import { Translate } from 'src/common_components/Translate/Translate';

const ProductionLinesModalView = forwardRef(({
                                               onClose,
                                               isAddDisabled,
                                               onAddSelectedLines,
                                               allProductionLines,
                                               onRowSelect,
                                               initialDatatableState,
                                               setCustomIdsToRows,
                                               total,
                                             }, ref,
) => {
  return (
    <React.Fragment>
      <ActionsRow
        onClose={onClose}
        isAddDisabled={isAddDisabled}
        onAddSelectedLines={onAddSelectedLines}
      />

      {!total ? (
        <EmptyProductionLines type='all'/>
      ) : (
        <Fragment>
          <Datatable
            columns={sizedModalColumns}
            data={allProductionLines}
            ref={ref}
            useRowSelect
            onRowSelect={onRowSelect}
            useFilters
            manualFilters={false}
            useRowState
            getRowId={setCustomIdsToRows}
            initialState={initialDatatableState}
          />

          <Box px={5} py={2}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item>
                <Box color="grey.600" fontSize={12} fontWeight={600}>
                  {Translate('Всего записей:')}{' '}{total}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </React.Fragment>
  );
});

ProductionLinesModalView.propTypes = {
  onClose: PropTypes.func.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  onAddSelectedLines: PropTypes.func.isRequired,
  allProductionLines: PropTypes.array.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  initialDatatableState: PropTypes.shape({
    selectedRowIds: PropTypes.object.isRequired,
  }).isRequired,
  setCustomIdsToRows: PropTypes.func.isRequired,
  total: PropTypes.number,
};

export default ProductionLinesModalView;

const ActionsRow = ({ isAddDisabled, onAddSelectedLines, onClose }) => {
  return (
    <Box px={5} py={2}>
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Button
            onClick={onAddSelectedLines}
            disabled={isAddDisabled}
          >
            {Translate('Добавить')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onClose}>
            {Translate('Отменить')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
