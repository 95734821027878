import React from 'react';

import { addSpacesBetweenThousands } from '../../utils/index';
import {
  ElementsCountWrapper,
  ElementsCountValueWrapper,
} from '../../components/common/Table/ElementsCount/styled/index';
import { Translate } from '../../common_components/Translate/Translate';


export const AppealsCount = ({ value }) => (
  <ElementsCountWrapper>
    {Translate('Всего обращений')}:
    <ElementsCountValueWrapper>
      {addSpacesBetweenThousands(value)}
    </ElementsCountValueWrapper>
  </ElementsCountWrapper>
);

