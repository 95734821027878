import React from 'react';
import { ARTICLE_STATUSES, ARTICLE_STATUSES_NAMES } from '../../constants';
import { ActiveStatus, PlannedStatus, StoppedStatus } from './cell.styled';
import Icons from '../../../../common_components/Icons/Icons';
import { Icon } from '@material-ui/core';

export const StatusCell = ({ status }) => {
  switch (status) {
    case ARTICLE_STATUSES.ACTIVE: return (
      <ActiveStatus>
        <Icon component={Icons.CheckCircle} style={{ width: 19 }}/>
        {ARTICLE_STATUSES_NAMES[status]}
      </ActiveStatus>
    )
    case ARTICLE_STATUSES.STOPPED: return (
      <StoppedStatus>
        <Icon component={Icons.Pause} style={{ width: 19 }}/>
        {ARTICLE_STATUSES_NAMES[status]}
      </StoppedStatus>
    )
    case ARTICLE_STATUSES.PLANNED: return (
      <PlannedStatus>
        <Icon component={Icons.Clock} style={{ width: 19 }}/>
        {ARTICLE_STATUSES_NAMES[status]}
      </PlannedStatus>
    )
    default: return status;
  }
}
