import React from 'react';
import {withTheme} from 'styled-components';
import Registration from '../../components/Registration';

class RegistrationWrapper extends React.Component {
  render() {
    const { config } = this.props.App;

    const Component = config.Registration.component;

    return Component ? <Component {...this.props} /> : <Registration {...this.props} />;
  }
}

export default withTheme(RegistrationWrapper);
