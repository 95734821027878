import { Notification } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import { Modal } from 'src/fragments/Modal';

import { Divider } from '../GrayAreaCreate.styles';
import ActionsRow from './ActionsRow';
import EmptyProductionLines from './EmptyProductionLines';
import { columns } from './ProductionLines.constants';
import { styles } from './ProductionLines.styles';
import ProductionLinesModal from './ProductionLinesModal';
import { Translate } from 'src/common_components/Translate/Translate';

const ProductionLinesView = forwardRef(({
  error,
  isModalOpen,
  onOpenModal,
  onCloseModal,
  allProductionLines,
  selectedProductionLines,
  multipleActionsItems,
  rowActions,
  isMultipleActionsDisabled,
  addSelectedLines,
  onMarkToDelete,
  total,
  classes,
}, ref
) => {
  return (
    <Fragment>
      <ActionsRow
        onOpenModal={onOpenModal}
        multipleActionsItems={multipleActionsItems}
        isMultipleActionsDisabled={isMultipleActionsDisabled}
        instance={ref}
      />

      <div className={classes.contentWrapper}>
        <Modal
          title={Translate('Производственные линии')}
          isOpen={isModalOpen}
          onClose={onCloseModal}
          useHeaderDivider={true}
        >
          <ProductionLinesModal
            allProductionLines={allProductionLines}
            selectedProductionLines={selectedProductionLines}
            addSelectedLines={addSelectedLines}
            onClose={onCloseModal}
          />
        </Modal>

        {!total ? (
          <Fragment>
            <Divider />
            <EmptyProductionLines type='list' />
          </Fragment>
        ) : (
          <Fragment>
            {error && (
              <Notification
                color="error"
                disableBorderRadius
              >
                {error}
              </Notification>
            )}

            <div>
              <Datatable
                actions={rowActions}
                columns={columns}
                data={selectedProductionLines}
                ref={ref}
                useRowSelect
                onRowSelect={onMarkToDelete}
                useFilters
                manualFilters={false}
              />
            </div>
            <div className={classes.paginationWrapper}>
              <div className={classes.paginationContainer}>
                {total > 1 &&
                  <div className={classes.paginationCount}>
                    {Translate('Всего записей:')} {total}
                  </div>
                }
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
});

ProductionLinesView.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  allProductionLines: PropTypes.array.isRequired,
  selectedProductionLines: PropTypes.array.isRequired,
  multipleActionsItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    }).isRequired,
  ),
  isMultipleActionsDisabled: PropTypes.bool.isRequired,
  addSelectedLines: PropTypes.func.isRequired,
  onMarkToDelete: PropTypes.func.isRequired,
  total: PropTypes.number,
};

export default withStyles(styles)(ProductionLinesView);
