import React from 'react';
import arrayMutators from 'final-form-arrays';
import { isArray, isObject, cloneDeep } from 'lodash';


export const FormContext = React.createContext();

export const formMutators = {
  ...arrayMutators,
  updateField: ([name, value], state, { changeValue }) => changeValue(state, name, () => value),

  removeKeys: ([name, keys], state, { changeValue }) => changeValue(
    state,
    name,
    (old) => {
      if (isArray(old)) {
        return old.filter((c, index) => keys.indexOf(index) === -1);
      }
      if (isObject(old)) {
        const vals = cloneDeep(old);
        keys.forEach(key => {
          delete vals[key];
        });
        return vals;
      }
      return [];
    }
  ),

  removeKeysWith: ([name, keys, callback], state, { changeValue }) => changeValue(
    state,
    name,
    (old = []) => {
      if (isArray(old)) {
        return callback(old.filter((c, index) => keys.indexOf(index) === -1));
      }

      if (isObject(old)) {
        const vals = cloneDeep(old);

        keys.forEach(key => {
          delete vals[key];
        });

        return callback(vals);
      }

      return [];
    }
  ),

  duplicateKeys: ([name, keys], state, { changeValue }) => changeValue(
    state,
    name,
    (old = []) => {
      const forDuplicate = old.filter((c, index) => keys.indexOf(index) !== -1);
      return [
        ...old,
        ...forDuplicate.map(c => JSON.parse(JSON.stringify(c))),
      ];
    }
  ),

  updateArray: (
    [name, ids, inList, outList], state, { changeValue }
  ) => changeValue(state, name, (old = []) => {
    const selected = isArray(ids) ? ids : [];
    const founded = old.filter((c, index) => selected.indexOf(index) !== -1);
    const other = old.filter((c, index) => selected.indexOf(index) === -1);

    return [
      ...founded.map(c => ({ ...c, ...inList && inList })),
      ...other.map(c => ({ ...c, ...outList && outList })),
    ];
  }),

  toggle: ([name, value], state, { changeValue }) => (
    changeValue(
      state,
      name,
      (old = []) => {
        if (old.indexOf(value) === -1) return [...old, value];
        const values = old.filter(e => e !== value);
        if (!values.length) return undefined;
        return values;
      }
    )),
};
