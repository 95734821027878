
export class Item {
  icon = 'error';

  title = 'Error';

  value = '';

  constructor(dispatch, history, onSuccess, onFailure) {
    this.dispatch = dispatch;
    this.history = history;
    this.onSuccess = onSuccess;
    this.onFailure = onFailure;
  }

  strictTest = () => false;

  softTest = () => true;

  onClick = () => console.log('Override onClick in children');

  get = (value) => {
    this.value = value;

    if (!this.softTest()) return null;

    return {
      icon: this.icon,
      title: this.title,
      active: this.strictTest(),
      onClick: this.onClick,
    };
  };
}
