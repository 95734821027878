import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, branch, renderNothing } from 'recompact';
import { CertName, CertSource } from './styled';


const CertValue = ({ selected }) => (
  <div>
    <CertSource>{selected.source}</CertSource>
    <CertName>{selected.name}</CertName>
  </div>
);

CertValue.propTypes = {
  selected: PropTypes.shape({
    source: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};


export default compose(
  pure,
  branch(({ selected }) => !selected, renderNothing),
)(CertValue);
