/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import { get, some } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Icon } from '@crpt/react-icon';
import * as actions from './ducks/ReceiptList.actions';
import * as listActions from '../../../common_components/List/ducks/List.actions';
import * as selectors from './ducks/ReceiptList.selectors';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import * as filePreviewActions from '../../../common_components/FilePreview/ducks/FilePreview.actions';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import RequestListView from '../RequestList/RequestList.view';
import Filters from '../../../common_components/List/Filters/Filters';
import FiltersButton from '../../../common_components/Buttons/FiltersButton';
import {
  prepareFromRange,
  prepareToRange,
} from '../../../common_components/Form/Form.utils';
import { limit, columns, options } from './ReceiptList.constants';
import ReceiptListFilters from './ReceiptListFilters';
import { Translate } from '../../../common_components/Translate/Translate';
import { receiptStatus } from '../RequestList/RequestList.constants';


class ReceiptList extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    last: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pagesCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    filters: PropTypes.shape({}).isRequired,
    sorting: PropTypes.shape({}).isRequired,
    onMount: PropTypes.func.isRequired,
    onListMount: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterApply: PropTypes.func.isRequired,
    onOpenSidebar: PropTypes.func.isRequired,
    onImportFileUpload: PropTypes.func.isRequired,
    onEaesImportFileUpload: PropTypes.func.isRequired,
    onOpenDocument: PropTypes.func.isRequired,
    onOpenNewFile: PropTypes.func.isRequired,
    uploadErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClearErrors: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSetContent: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      actionBarLeft: this.getLeftActionBarItems(),
      actionBarRight: [<FiltersButton onClick={this.onOpenFiltersSidebar}/>],
      isAddMenuOpen: false,
      isUploadMenuOpen: false,
      cellEvent: null,
      changedColumns: [],
    };
  }

  componentDidMount() {
    const { history,countryCode } = this.props;
    // eslint-disable-next-line
    if (/[\?&]{1}page=/.test(location.search)) {
      history.replace('/documents/receipts');
    }
    const { onMount, onListMount } = this.props;
    onMount();
    onListMount();
    if (countryCode) {
      this.setState({
        changedColumns: countryCode === 'UZ' ? [
          ...columns,
          {
            Header: Translate('Статус'),
            id: 'status',
            accessor: ({ status }) => Translate(receiptStatus[status] || ""),
            minWidth: 150,
          },
        ] : columns,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { countryCode } = this.props;
    if (countryCode !== prevProps.countryCode) {
      this.setState({
        changedColumns: countryCode === 'UZ' ? [
          ...columns,
          {
            Header: Translate('Статус'),
            id: 'status',
            accessor: ({ status }) => Translate(receiptStatus[status] || ""),
            minWidth: 150,
          },
        ] : columns,
      });
    }
  }

  componentWillUnmount() {
    this.props.onUnMount();
  }

  // getTdProps = (state, rowInfo, cellInfo) => {
  //   const { history, onOpenDocument } = this.props;
  //   const { id } = cellInfo;
  //   if (id !== 'number') return undefined;

  //   const type = get(rowInfo, 'original.type', '');
  //   const number = get(rowInfo, 'original.number', '');

  //   const match = type.match(/.*_(XML|CSV)/);

  //   let onClick = () => null;

  //   if (match) {
  //     onClick = () => onOpenDocument(number, match[1], type);
  //   } else if (number) {
  //     onClick = () => history.push(`/documents/list/${number}`);
  //   }

  //   return {
  //     onClick,
  //   };
  // };

  getTheadThProps = (state, _, column) => {
    const { sorting } = this.props;
    const { order, orderColumn } = sorting;
    const { id } = column;

    if (id === orderColumn) {
      return {
        className: `-sort-${order.toLowerCase()}`,
      };
    }

    return {};
  };

  getLeftActionBarItems = () => {
    const { actionBarLeft } = this.props;
    return actionBarLeft;
  };

  handleMenuToggle = menuName => () => {
    this.setState({
      [`is${menuName}MenuOpen`]: !this.state[`is${menuName}MenuOpen`],
    });
  };

  handleMenuClose = () => () => {
    this.setState({ isAddMenuOpen: false, isUploadMenuOpen: false });
  };

  onClear = () => {
    const { onClear } = this.props;
    onClear();
    setTimeout(() => this.onOpenFiltersSidebar(), 0);
  };

  onOpenFiltersSidebar = () => {
    const { onOpenSidebar, filters, countryCode } = this.props;

    onOpenSidebar(
      <Filters
        onClear={this.onClear}
        data={prepareToRange(filters)}
        onSubmit={this.onFilterApply}
        filters={<ReceiptListFilters countryCode={countryCode}/>}
      />,
    );
  };

  onFilterApply = params => {
    const { onFilterApply, selectedPage } = this.props;
    onFilterApply(prepareFromRange({ ...params, page: 1 }));
  };


  onRef = el => {
    this.popoverContainer = el;
  };

  onRowMouseEnter = e => {
    this.setState({ cellEvent: e });
  };

  onRowMouseLeave = () => this.setState({ cellEvent: null });

  onDownloadClick = (type, id) => {
    this.props.downloadDoc({ type, id });
  };

  render() {
    const { results, pagesCount, uploadErrors, onClearErrors, selectedPage,
      isLoading, last, isModalOpen, onCloseModal, } = this.props;
    const { actionBarLeft, actionBarRight, changedColumns, cellEvent } = this.state;

    return (
      <RequestListView
        onRef={this.onRef}
        last={last}
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        cellEvent={cellEvent}
        results={results}
        pagesCount={pagesCount}
        selectedPage={selectedPage}
        columns={changedColumns}
        options={options}
        getTdProps={this.getTdProps}
        getTrProps={(state, rowInfo) => ({
          onMouseEnter: () => this.onRowMouseEnter(rowInfo),
          onMouseLeave: this.onRowMouseLeave,
          ['data-row-document-id']: rowInfo.original.id,
        })}
        onDownloadClick={this.onDownloadClick}
        getTheadThProps={this.getTheadThProps}
        actionBarLeft={actionBarLeft}
        actionBarRight={actionBarRight}
        notifyErrors={uploadErrors}
        // onNotifyAccept={this.onUploadClick(type)}
        onNotifyCancel={onClearErrors}
        meta={actions}
        isLoading={isLoading}
        pageCounterInvisible={true}
      />
    );
  }
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: 999999999999, //selectors.pagesCount(state),
    last: selectors.results(state).length < limit, //selectors.last(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    roles: authSelectors.roles(state),
    uploadErrors: selectors.uploadErrors(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
  };
};

const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onOpenDocument: (id, type, docType) =>
    dispatch(actions.openDocument({ id, type, docType })),
  onOpenNewFile: (obj, meta) =>
    dispatch(filePreviewActions.openFile(obj, meta)),
  onClearErrors: () => dispatch(actions.clearErrors()),
  onErrors: val => dispatch(actions.gotErrors(val)),
  onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
  downloadDoc: id => dispatch(actions.downloadDoc(id)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(ReceiptList);
