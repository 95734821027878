import React, { useEffect, useState }  from 'react';
import { ReportListContentWrapper } from '../Reports/ReportList/ReportList.styled';
import ReportTile from '../Reports/ReportTile/ReportTile';
import { ReportsService } from '../../services/ReportsService';
import Report from '../Reports/Report/Report';
import { get } from 'lodash';
import Preloader from '../../common_components/Preloader/Preloader';
import { Icon } from '@material-ui/core';
import Icons from '../../common_components/Icons/Icons';
import {
  EmptyGoodsTextWrap,
  GoodsIconWrap,
  GoodsWrap,
} from '../../motp_components/_Requests/RequestList/Document/Document.styled';
import GoodsIcon from '../../motp_components/_Requests/RequestList/EaesImportDocument/Goods/svg/GoodsIcon.svg';

const iconStyle = {
  width: '64px',
  height: '64px',
  margin: 'auto 0',
}

export const Violation = () => {
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true)
    try {
      const answer = await ReportsService.getReports('tobacco');
      const reports = get(answer, 'data', []);
      const filteredReport = (reports.filter(({category}) => category === 'violation') || []).map((item) => (
        { 
          category: item.category,
          description: item.description,
          id:item.id,
          link: item.link,
          name: item.name,
          order: item.order,
          icon: <Icon component={Icons.NoteRemove} style={iconStyle}/>,
        }
      ))

      setReportList(filteredReport)
    } catch (e) {
      console.error('Error in reports request:', { e });
    } finally {
      setLoading(false)
    }
  }, []);

  const getItem = () => {
    const itemUrl = location.pathname
    .split('/')
    .slice(-1)
    .pop();
    return reportList.find(v => v.id === itemUrl);
  }

  return (
    <div>
      {loading ? (
        <Preloader isOpened={loading} isClosed={!loading} />
      ) : reportList.length ?
        location.pathname === '/violation' ? 
        ( 
        <ReportListContentWrapper>
          {reportList.map((v, i) => (
            <ReportTile
              key={i}
              namespace={`/violation/${v.id}`}
              report={v}
            />
          ))}
        </ReportListContentWrapper>
      ) : (
        <Report item={getItem()}/>
      ) : (
        <GoodsWrap minHeight="calc(100vh - 200px)">
        <GoodsIconWrap>
          <GoodsIcon />
        </GoodsIconWrap>

        <EmptyGoodsTextWrap>
          Для участника оборота отсутствуют доступные отчеты
        </EmptyGoodsTextWrap>
      </GoodsWrap>
      )}
    </div>
  );
}
