import { handleAction, handleActions } from 'redux-actions';
import * as actions from './Preview.actions';

const content = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload || {},
  {}
);

const openPreview = handleActions(
  {
    [actions.open]: () => true,
    [actions.close]: () => false,
  },
  false
);

export default {
  openPreview,
  content,
};
