import styled from 'styled-components';
import {AvatarOverlay} from './AvatarOverlay';

export const AvatarBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50%;

  position: relative;
  width: 128px;
  min-width: 128px;
  min-height: 128px;
  height: ${props => props.noHeight ? '' : '128px'};
  margin-right: 32px;
  &:hover ${AvatarOverlay} {
    display: flex;
  }
`;

