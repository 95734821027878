import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Icon } from '@crpt/react-icon';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { getDocumentStatusIconByDocStatus } from '../../DocumentDetails.utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import { columns } from './EasBasicInfo.constants';
import moment from 'moment';

import {
  Container,
  Head,
  HeadWrap,
  LabelWrap,
  LittleHead,
  RowLabel,
  RowNoBorder,
  RowText,
  Separator,
  TooltipContent,
  WrapImportTransit,
} from '../../DocumentDetails.styled';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Translate } from '../../../../common_components/Translate/Translate';

const EasBasicInfo = ({ selectedDocument, id }) => {
  return (
    <WrapImportTransit>
      <Container>
        <HeadWrap>
          <Head> {Translate('Уведомление о ввозе товаров (ЕАЭС)')} </Head>
          <LabelWrap>
            <Button theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(documentStatusesNames[selectedDocument.status])}
            </Button>
          </LabelWrap>
        </HeadWrap>
        <Container>
          <RowNoBorder>
            <RowLabel>{Translate('Номер документа')}</RowLabel>
            <RowText>{id}</RowText>
          </RowNoBorder>
          <RowNoBorder>
            <RowLabel>{Translate('Дата документа')}</RowLabel>
            <RowText>
              {moment(selectedDocument.documentDate).format('DD.MM.YYYY')}
            </RowText>
          </RowNoBorder>
          <Separator />

          {columns.map(({ Header, cid, formater }, i) => {
            let rowText = get(selectedDocument, cid, '-');
            if (cid === 'name') {
              rowText = get(selectedDocument, 'senderInfo.name', '-');
            }
            return (
              <React.Fragment>
                {cid === 'originalDocInfo.docDate' && (
                  <React.Fragment>
                    <Separator />
                    <LittleHead>
                      {Translate('Сведения о первичном документе')}
                    </LittleHead>
                  </React.Fragment>
                )}
                <RowNoBorder key={i}>
                  <RowLabel>{Translate(Header)}</RowLabel>
                  <RowText>
                    {formater ? formater(rowText) : rowText || '-'}
                  </RowText>
                </RowNoBorder>
              </React.Fragment>
            );
          })}
          {selectedDocument.complianceDocumentInfo &&
            selectedDocument.complianceDocumentInfo.length > 0 && (
              <React.Fragment>
                <Separator />
                <LittleHead>
                  {Translate(
                    'Сведения о документах, подтверждающих соответствие товара',
                  )}
                </LittleHead>

                {selectedDocument.complianceDocumentInfo.map((item, idx) => (
                  <Box mt={1}>
                    <Grid container key={idx} style={{ font: 'inherit' }}>
                      <Grid item xs={4} zeroMinWidth m="4px">
                        <Tooltip
                          title={
                            <TooltipContent>
                              {item.complianceDocType}
                            </TooltipContent>
                          }
                          placement="top-start"
                        >
                          <Typography
                            noWrap
                            style={{ font: 'inherit', marginRight: '8px' }}
                          >
                            <Box fontWeight={600} component="span">
                              №{idx + 1}:
                            </Box>{' '}
                            {item.complianceDocType}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4} zeroMinWidth>
                        <Tooltip
                          title={
                            <TooltipContent>
                              {item.complianceDocNumber}
                            </TooltipContent>
                          }
                          placement="top-start"
                        >
                          <Typography
                            noWrap
                            style={{ font: 'inherit', marginRight: '8px' }}
                          >
                            <Box fontWeight={600} component="span">
                              {Translate('Номер')}
                              {': '}
                            </Box>
                            {item.complianceDocNumber}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                        <Box fontWeight={600} component="span">
                          {Translate('от')}
                          {': '}
                        </Box>
                        {item.complianceDocDate}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </React.Fragment>
            )}
        </Container>
      </Container>
    </WrapImportTransit>
  );
};

EasBasicInfo.propTypes = {
  selectedDocument: PropTypes.shape({}).isRequired,
};

export default EasBasicInfo;
