import * as React from 'react';
import { useModal } from '../Modals.utils';
import Modal from '../Modal/Modal';

export const withModal = Component => {

  const WrapperComponent = props => {
    const { isModalOpen, onOpenModal, onCloseModal, content, onSetContent } = useModal();

    return (
      <React.Fragment>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={onCloseModal}
        >
          {content}
        </Modal>
        <Component
          {...props}
          isModalOpen={isModalOpen}
          onOpenModal={onOpenModal}
          onSetContent={onSetContent}
          onCloseModal={onCloseModal}
        />
      </React.Fragment>
    );
  };

  return WrapperComponent;
};

export default withModal;
