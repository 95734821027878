import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Icon from '@crpt/react-icon';
import { Button } from '@crpt/react-button';

import { ButtonWrapper, Wrapper } from './styled';
import { compose } from 'redux';
import {
  buttonSelectedTabItemThemeKZ,
  buttonTabItemThemeKZ,
} from '../../theme/kz-tobaco/button';

import {
  buttonSelectedTabItemThemeUZ,
  buttonTabItemThemeUZ,
} from '../../theme/uz-tobaco/button';

class ButtonTabs extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string,
        route: PropTypes.string.isRequired,
        check: PropTypes.array.isRequired,
      }).isRequired,
    ).isRequired,
    currentIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  renderTab = (tab, i) => {
    const { currentIndex, onSelect, countryCode } = this.props;
    const selectedBtn =
      countryCode === 'KZ'
        ? buttonSelectedTabItemThemeKZ
        : buttonSelectedTabItemThemeUZ;
    const btn =
      countryCode === 'KZ' ? buttonTabItemThemeKZ : buttonTabItemThemeUZ;
    return (
      <ButtonWrapper key={i}>
        <Button
          theme={{
            Button: i === currentIndex ? selectedBtn : btn,
          }}
          onClick={() => onSelect(tab)}
        >
          {tab.icon && (
            <Icon
              type={tab.icon}
              size={16}
              spacingRight={14}
              style={i === currentIndex ? { fill: '#fff' } : { fill: '#000' }}
            />
          )}
          {tab.title}
        </Button>
      </ButtonWrapper>
    );
  };

  render() {
    const { tabs } = this.props;

    return <Wrapper>{tabs.map(this.renderTab)}</Wrapper>;
  }
}

const mapStateToProps = state => ({
  countryCode: state.config.countryCode,
});

export default compose(connect(mapStateToProps), withRouter)(ButtonTabs);
