import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { get, intersection } from 'lodash';

import Card from '@crpt/react-card';
import TwoLineLabel from '@crpt/react-label';
import { Icon } from '@crpt/react-icon';
import Button from '@crpt/react-button';
import { PARTICIPANT_TYPES } from '../constants';
import * as sidebarActions from '../common_components/Sidebar/ducks/Sidebar.actions';
import { getOrgRoles } from '../utils/userUtils';
import { Translate } from '../common_components/Translate/Translate';

const cardTheme = {
  background: '#FFFFFF',
  paddingLeft: '32px',
  paddingTop: '32px',
  paddingRight: '80px',
  paddingBottom: '40px',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

const CloseButton = styled.div`
  padding-top: 21px;
`;

const Status = styled.div`
  font-size: 14px;
  line-height: 1.14;
  font-weight: 600;
  margin-bottom: 40px;
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  white-space: normal;
  width: 100%;
`;

const ImageColumn = styled.div`
  flex: 0 0 96px;
`;

const InfoColumn = styled.div`
  flex: 0 1 auto;
`;

const LabelWrapper = styled.div`
  margin-bottom: 24px;
`;

const ParticipantName = styled.div`
  font-size: 32px;
  line-height: 1.5;
  color: #212c42;
  max-width: 400px;
`;

const Image = styled.div`
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  border: 1px solid #212c42;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  line-height: 1.14;
`;


class ParticipantSidebarContent extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    ParticipantDetails: PropTypes.shape({}).isRequired,
  };

  gotoParticipantDetails = () => {
    const { dispatch, history, id } = this.props;
    dispatch(sidebarActions.close());
    history.push(`/participants/list/${id}/`);
  };

  sidebarClose = () => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.close());
  }

  render() {
    const { ParticipantDetails } = this.props;
    const participant = ParticipantDetails.selectedParticipant;
    const shortName = get(participant, 'shortName', null);
    const fullName = get(participant, 'fullName', '-');
    const status = get(participant, 'status', '-');
    const head = get(participant, 'head', '-');
    const actualAddress = get(participant, 'actualAddress', '-');

    const orgRoles = getOrgRoles();
    const isLinkVisible = intersection([
      PARTICIPANT_TYPES.OGV,
      PARTICIPANT_TYPES.IS_MP_OPERATOR
    ], orgRoles).length !== 0;

    return (
      <Card theme={cardTheme}>
        <Header>
          <ParticipantName>{shortName || fullName}</ParticipantName>
          <CloseButton>
            <Icon
              type="cross"
              onClick={this.sidebarClose}
            />
          </CloseButton>
        </Header>
        <Status>
          <Icon type="checkmark" spacingRight={4} />
          {status}
        </Status>
        <Container>
          <ImageColumn>
            <Image />
          </ImageColumn>
          <InfoColumn>
            <LabelWrapper>
              <TwoLineLabel heading={Translate("Полное")}>{fullName}</TwoLineLabel>
            </LabelWrapper>
            <LabelWrapper>
              <TwoLineLabel heading={Translate("Руководитель")}>{head}</TwoLineLabel>
            </LabelWrapper>
            <LabelWrapper>
              <TwoLineLabel heading={Translate("Фактический адрес")}>
                {actualAddress}
              </TwoLineLabel>
            </LabelWrapper>
          </InfoColumn>
        </Container>
        {isLinkVisible && (
          <Footer>
            <Button onClick={this.gotoParticipantDetails}>
              {Translate('В карточку участника оборота')}
            </Button>
          </Footer>
        )}
      </Card>
    );
  }
}

export default withRouter(ParticipantSidebarContent);
