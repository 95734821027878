import { call, put, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';

// import { PARTICIPANT_TYPES } from '../../../../constants/participantTypes';
import Api from '../../../../common_components/Api/Api';
import { Toast } from '../../../../utils/Toast';
import { taskTypeEnum } from '../../constants';
import { isOperator } from '../../_utils/utils';

import * as actions from './UnloadServiceCreate.actions';
import { adaptBody } from './UnloadServiceCreate.adapters';

function* onMounted({ payload }) {
  const { reportId, pg, taskTypeId } = payload;
  let reportParams = {};

  if (taskTypeId === taskTypeEnum.REPORT) {
    const requestJasper = {
      url: `/jasperserver/registry/v1/captures/${reportId}`,
      method: 'GET',
      params: {
        pg,
      },
    };
    const [successJasper, errorJasper] = yield call(Api.request, requestJasper);

    if (errorJasper) {
      yield call(Toast.showError, { content: 'Нет параметров в отчёте' });
      yield put(actions.setMessage('jasper errors'));
      return;
    }

    reportParams = Object.fromEntries(
      Object.entries(_.get(successJasper, 'data', {})).filter(
        ([key, value]) => value !== '~NOTHING~',
      ),
    );
  }

  let availableOrgRoles = [];
  if (isOperator()) {
    const requestRoles = {
      url: `/api/v3/facade/profile/available-org-roles`,
      method: 'GET',
    };
    const [successRoles, errorRoles] = yield call(Api.request, requestRoles);

    if (errorRoles) {
      yield call(Toast.showError, { content: 'Нет ролей' });
      yield put(actions.setMessage('roles errors'));
      return;
    }

    availableOrgRoles = _.get(successRoles, 'data', []);
  }

  const requestUserList = {
    url: `/api/v3/facade/user/list/active`,
    method: 'GET',
  };
  const [success, error] = yield call(Api.request, requestUserList);

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  if (_.get(success, 'data')) {
    yield put(
      actions.setData({
        users: _.get(success, 'data'),
        reportParams,
        availableOrgRoles,
      }),
    );
  }

  yield put(actions.loaded());
}

function* onSubmit({ payload }) {
  yield put(actions.loadingStart());

  //TODO: получать из url код товарной группы
  const pg_num = 3;

  const requestPayload = {
    url: `/dispenser/v1/tasks`,
    method: 'POST',
    data: {
      ...adaptBody(payload),
      productGroupCode: pg_num,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  const errorMessage = _.get(error, 'message', 'error');

  if (error && errorMessage.indexOf('404') < 0) {
    yield call(Toast.showError, { content: errorMessage });
  }

  if (_.get(success, 'data')) {
    const successMessage = `Задание ${_.get(
      success,
      'data.name',
      '',
    )} успешно создано`;
    yield call(Toast.showSuccess, { content: successMessage });
    yield put(actions.setMessage('task created'));
  }

  yield put(actions.loadingEnd());
}

export default function* watch() {
  yield takeEvery(actions.mounted, onMounted);
  yield takeEvery(actions.submit, onSubmit);
}
