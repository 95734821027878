import { generateUUID, generateUUIDBig } from '../Draft/Draft.utils';
import moment from 'moment';
import fp from 'lodash/fp';
import {
  cisKey,
  goodsKey,
} from '../ImportThirdCountry/ImportThirdCountry.constants';
import { get } from 'lodash';

export const buildImportTransitDocumentBody = (values, withCount = true ) => {
  const result = {
    content: {
      // fileId: generateUUIDBig(40),
      version: '5.01',
      sending_date_time: Math.round(new Date() / 1000),
      document: {
        main_info: {
          supplier: {
            inn: values.sender_inn,
            name: values.sender_name,
            country_code: values.sender_country,
          },
          consignee: {
            inn: values.applicant_inn,
            name: values.applicant_name,
            country_code: null,
          },
          ...(values.excise_tax_document_number || values.excise_tax_document_date || values.excise_tax_sum) && {
            excise_doc: {
              number: values.excise_tax_document_number,
              date: values.excise_tax_document_date,
              amount: values.excise_tax_sum,
            },
          },
          compliance: getDocuments(values), //getProductsDocs(values),
        },
        product_list: withCount ? getProductsCises(values) : getProductsCisesStrings(values),
        product_totals: getProductTotals(values),
      },
    },
    type: values.type ? values.type : values.isChanged ? 1300 : 1200,
  };
  if (values.doc_id) {
    result.content.doc_id = values.doc_id
  }
  if (values.paper_document_number && values.paper_document_date) {
    result.content.document.main_info.origin_doc = {
      number: values.paper_document_number,
      date: values.paper_document_date,
    };
  }
  if (values.primary_doc) {
    result.content.document.primary_doc = values.primary_doc;
    result.parent_id = values.parent_id;
  }

  return result;
};

const getProductTotals = data =>
  fp.pipe(
    fp.get('products'),
    fp.map(item => ({ gtin: item.gtin, count: 0 })),
    fp.uniqBy('gtin'),
  )(data);

const getProductsCises = data =>
  fp.pipe(
    fp.get('products'),
    fp.map(item => ({
      code: item.cis,
      count: 0,
    })),
  )(data);

const getProductsCisesStrings = data =>
  fp.pipe(
    fp.get('products'),
    fp.map(item => item.cis),
  )(data);

const getDocuments = data =>
  fp.pipe(
    fp.get('documents'),
    fp.map(item => ({
      date: item.document_date,
      number: item.document_number,
      doc_type: item.document_type,
    })),
  )(data);

export const buildImportDocumentBodyKG = values => {
  const uuid = generateUUID();
  return {
    fileId: generateUUIDBig(43),
    version: '1.0',
    sendingDateTime: moment().format(),
    document: {
      type: '1',
      primaryDocId: uuid,
      primaryDocNumber: uuid,
      primaryDocDate: moment().format('DD.MM.YYYY'),
      mainInfo: {
        importerInfo: {
          importerName: values.applicant_name,
          importerCode: values.applicant_inn,
        },
        exporterInfo: {
          exporterName: values.sender_name,
          exporterCode: values.sender_inn,
        },
        exportCountry: values.sender_country,
        docInfo: {
          docType: values.accompanying_document_name,
          docNumber: values.accompanying_document_number,
          docDate: moment(
            values.accompanying_document_date,
            'DD MM YYYY',
          ).format('DD.MM.YYYY'),
        },
      },
      productItems: getProductItems(values),
    },
  };
};

const getProductItems = data =>
  fp.pipe(
    fp.get(cisKey),
    fp.map(item => {
      return {
        iCodeFromDeclaration: item.cis,
        products: getPropsForGTIN(data, item.cis),
      };
    }),
  )(data);

const getPropsForGTIN = (data, parentCis) => {
  const disaggregatedStructure = get(data, 'disaggregated.structure');
  const childCisesOfICodeFromDeclaration = disaggregatedStructure[parentCis];

  return fp.pipe(
    fp.get(goodsKey),
    fp.filter(item =>
      item.codes.find(code => childCisesOfICodeFromDeclaration.includes(code)),
    ),
    fp.map(item => ({
      tnved: item.tnved,
      gtin: item.gtin,
      originCountry: item.country,
      itemNumber: item.numberDT,
      iCodes: item.codes.filter(cis =>
        childCisesOfICodeFromDeclaration.includes(cis),
      ),
    })),
  )(data);
};

export const parseSelectedDocument = (document) => {
  if (Object.keys(document).length > 0) {
    const parent_id = get(document, 'content.document.primary_doc.id', null);
    const isChanged = document.type === 1300;
    const primary_doc = get(document, 'content.document.primary_doc', null);
    const origin_doc = get(document, 'content.document.main_info.origin_doc', null);

    return ({
      isHasPaperDocument: !!document.content.document.main_info.origin_doc,
      applicant_inn: document.content.document.main_info.consignee.inn,
      applicant_name: document.content.document.main_info.consignee.inn,
      sender_country: document.content.document.main_info.supplier.country_code,
      sender_inn: document.content.document.main_info.supplier.inn,
      sender_name: document.content.document.main_info.supplier.name,
      ...document.content.document.main_info.excise_doc ? {
        excise_tax_document_number: document.content.document.main_info.excise_doc.number,
        excise_tax_document_date: document.content.document.main_info.excise_doc.date,
        excise_tax_sum: document.content.document.main_info.excise_doc.amount,
      } : {},
      status: document.status,
      type: document.type,
      products: document.products ? Object.keys(document.products).map((el, index) => {
        return {
          ...document.products[el],
          serialNumber: index + 1,
          cisPackageType: document.products[el].packageType,
          name: document.products[el].productName,
        };
      }) : [],
      cis: document.totalInfo ? document.totalInfo : [],
      ...document.content.document.main_info.origin_doc ? {
        paper_document_number: document.content.document.main_info.origin_doc.number,
        paper_document_date: document.content.document.main_info.origin_doc.date,
      } : {},
      ...document.content.document.main_info.compliance ? {
        documents: document.content.document.main_info.compliance.map(el => ({
          document_date: el.date,
          document_number: el.number,
          document_type: el.doc_type,
        })),
      } : {},
      isChanged,
      primary_doc,
      origin_doc,
      parent_id,
    });
  } else {
    return ({});
  }
};
