import { get } from 'lodash';
import { Item } from './Item';
import { ParticipantService } from '../../../services/ParticipantService';
import { ParticipantList } from '../../../actions';
import i18next from 'i18next';
import { countryCodeFlags } from '../../../constants';
import { Translate } from '../../../common_components/Translate/Translate';

const typeChecks = {
  innUZ: /^(\d{9}|\d{14})$/,
  innKZ: /^(\d{12})$/,
};

export class ParticipantsItem extends Item {
  icon = 'menu-participant-list';

  title = 'Участники';

  strictTest = () => {
    const { isUZ } = countryCodeFlags;
    return isUZ ? !!this.value.match(typeChecks.innUZ) : !!this.value.match(typeChecks.innKZ)
  };

  onClick = async () => {
    const { isUZ } = countryCodeFlags;
    if (this.value.match(/\d/)) {
      if (!this.strictTest()) {
        this.onFailure(
          Translate(isUZ
            ? 'Неверно указан формат ИНН/ПИНФЛ (для ИНН 9 цифр, для ПИНФЛ - 14)'
            : 'Неверно указан формат ИИН/БИН (12 цифр)'
          ));
        return;
      }
    }

    try {
      this.onFailure(Translate('Идет поиск участников...'));
      const params = {};

      if (this.value.match(isUZ ? typeChecks.innUZ : typeChecks.innKZ)) {
        params.inn = this.value;
      } else {
        params.name = this.value;
      }

      const answer = await ParticipantService.searchByInputGlobal(params);
      const results = get(answer, 'data.results');
      if (results && results.length) {
        if (results.length === 1) {
          const inn = get(results, '0.inn');
          this.history.push(`/participants/list/${inn}/basic-info`);
        } else {
          this.history.push('/participants/list');
          this.dispatch(ParticipantList.onFilterApplyClick({
            ...params.inn && { inn: [params.inn] },
            ...params.name && { name: [params.name] },
          }));
        }

        this.onSuccess();
        return;
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
