import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { connect } from 'react-redux';
import logoKzSmall from './assets/logoKz_small.jpg';
import logoKzFull from './assets/logoKz.jpg';
import LogoUzRUSvg from './assets/UZ/Logo1_ru-01-01.svg';
import LogoUzUZSvg from './assets/UZ/Logo2_uz-01-01.svg';
import LogoUzENSvg from './assets/Logo_demo_EN.svg';
import {
  Logo,
  LogoFull,
  LogoFullKG,
  LogoFullUZ,
  LogoSmallUZ,
} from './logo.styled';

const HeaderLogoSmall = ({ countryCode, lg }) => {
  return (
    <Fragment>
      {countryCode === 'KZ' && <Logo src={logoKzSmall} alt="" />}
      {countryCode === 'UZ' && (
        <LogoSmallUZ>
          {lg === 'RU' ? <LogoUzRUSvg /> : <LogoUzUZSvg />}
        </LogoSmallUZ>
      )}
    </Fragment>
  );
};

HeaderLogoSmall.propTypes = {
  countryCode: PropTypes.string,
  lg: PropTypes.string,
};

const HeaderLogoFull = ({ countryCode, lg }) => {
  return (
    <Fragment>
      {countryCode === 'KZ' && <LogoFull src={logoKzFull} alt="" />}
      {countryCode === 'UZ' && (
        <LogoFullUZ>
          {lg === 'RU' ? <LogoUzRUSvg /> : lg === 'EN' ? <LogoUzENSvg/> : <LogoUzUZSvg />}
        </LogoFullUZ>
      )}
    </Fragment>
  );
};

HeaderLogoFull.propTypes = {
  countryCode: PropTypes.string,
  lg: PropTypes.string,
};

const Header = ({ expanded, countryCode }) => {
  const { i18n } = useTranslation();

  return (
    <Link to="/home/map" style={{ padding: '20px', display: 'block' }}>
      {expanded ? (
        <HeaderLogoFull countryCode={countryCode} lg={i18n.language} />
      ) : (
        <HeaderLogoSmall countryCode={countryCode} lg={i18n.language} />
      )}
    </Link>
  );
};

Header.propTypes = {
  isOpen: PropTypes.bool,
  countryCode: PropTypes.string,
};

const mapStateToProps = state => ({
  countryCode: state.config.countryCode,
});

export default compose(connect(mapStateToProps), withRouter)(Header);
