import { actionTypes } from '../../constants/';

export function selectedParticipant(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.PARTICIPANT_DETAILS_LOADED:
      return action.value;
    case actionTypes.PARTICIPANT_DETAILS.PARTICIPANT_DETAILS_UPDATED:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
}
