import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import { get, uniq, difference } from 'lodash';
import SelectionCheckbox, { checkboxState } from './SelectionCheckbox';
import { prepareColumns } from './SelectionList.utils';
import { FormContext } from 'src/common_components/Form/Form.constants';
import { SelectionStatus } from './SelectionList.constants';
import List from '../List';

const SelectionTable = withTheme(selectTableHoc(List));
const ThemeTable = withTheme(List);

class SelectionList extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    options: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    noDataText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    isToggleBlocked: PropTypes.func,
    getCheckboxState: PropTypes.func,
    disabled: PropTypes.bool,
    customSelectAll: PropTypes.bool,
    onUpdate: PropTypes.func,
    defaultPageSize: PropTypes.number,
  };

  static defaultProps = {
    columns: [],
    options: [],
    data: [],
    noDataText: '',
    isToggleBlocked: () => false,
    getCheckboxState: () => false,
    disabled: false,
    customSelectAll: false,
    onUpdate: () => null,
  };

  state = {
    selectedRows: [],
    isAllSelected: false,
    selectedAllPages: [],
    data: [],
    preparedData: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        data: props.data,
        preparedData: props.data.map((item, index) => ({
          ...item,
          _id: index,
        })),
      };
    }

    return null;
  }

  toggleAll = () => {
    const { isToggleBlocked, onUpdate } = this.props;
    const { isAllSelected, preparedData } = this.state;

    if (isToggleBlocked()) return;

    const select = !isAllSelected;
    const selection = [];

    if (select) {
      preparedData.forEach(item => {
        if (!item.hiddenCheckBox) selection.push(get(item, '_id'));
      });
    }

    this.setState({ selectedRows: selection, isAllSelected: select }, onUpdate);
  };

  toggleAllCustom = () => {
    const {
      keyField,
      isToggleBlocked,
      onUpdate,
      page,
      defaultPageSize,
    } = this.props;
    const { preparedData, selectedAllPages, selectedRows } = this.state;
    if (isToggleBlocked()) return;

    let select = false;
    if (selectedAllPages.length === 0) {
      select = true;
      this.setState({ selectedAllPages: [{ page }] });
    } else {
      const isFind = selectedAllPages.find(item => item.page === page);
      if (isFind) {
        select = false;
        this.setState({
          selectedAllPages: selectedAllPages.filter(item => item.page !== page),
        });
      } else {
        select = true;
        this.setState({ selectedAllPages: [...selectedAllPages, { page }] });
      }
    }

    let selection = [];

    let prData = preparedData.slice(
      page * defaultPageSize,
      page * defaultPageSize + defaultPageSize,
    );

    prData = prData.length ? prData : preparedData;

    if (select) {
      const list = prData;
      selection = [...selectedRows];
      list.forEach(item => {
        if (!item.hiddenCheckBox) selection.push(item[keyField]);
      });

      if (selection.length === 0) select = false;
    } else {
      const list = prData.map(item => item[keyField]);
      selection = selectedRows.filter(item => !list.includes(item));
    }

    this.setState(
      { selectedRows: uniq(selection), isAllSelected: select },
      onUpdate,
    );
  };

  isAllSelection = selection => {
    let result = true;

    for (let i = 0; i < this.state.preparedData.length; i++) {
      if (!selection.includes(i)) {
        result = false;
        break;
      }
    }

    return result;
  };

  toggleSelection = key => {
    const { isToggleBlocked, onUpdate } = this.props;
    const { selectedRows } = this.state;

    if (isToggleBlocked()) return;
    let selection = [...selectedRows];
    const prKey = +key.replace(/select-/, '');
    const index = selection.indexOf(prKey);

    let state;
    if (index >= 0) {
      selection = [...selection.slice(0, index), ...selection.slice(index + 1)];

      state = {
        selectedRows: selection,
        isAllSelected: false,
      };
    } else {
      selection.push(prKey);
      state = {
        selectedRows: selection,
        isAllSelected: this.isAllSelection(selection),
      };
    }

    this.setState(state, onUpdate);
  };

  toggleSelectionCustom = key => {
    const {
      isToggleBlocked,
      onUpdate,
      page,
      defaultPageSize,
      keyField,
    } = this.props;
    const { selectedRows, selectedAllPages, preparedData } = this.state;

    if (isToggleBlocked()) return;

    let selection = [...selectedRows];
    const prKey = key.replace(/select-/, '');
    const index = selection.indexOf(prKey);

    if (index >= 0) {
      selection = [...selection.slice(0, index), ...selection.slice(index + 1)];
      const newSelectedAllPages = selectedAllPages.filter(
        item => item.page !== page,
      );

      this.setState(
        {
          selectedRows: selection,
          isAllSelected: false,
          selectedAllPages: newSelectedAllPages,
        },
        onUpdate,
      );
    } else {
      selection.push(prKey);

      let prD = preparedData.slice(
        page * defaultPageSize,
        page * defaultPageSize + defaultPageSize,
      );

      prD = prD.length ? prD : preparedData;

      const isAllSelected = !difference(
        prD.map(item => item[keyField]),
        selection,
      ).length;

      if (isAllSelected)
        this.setState(
          {
            selectedRows: selection,
            isAllSelected,
            selectedAllPages: [...selectedAllPages, { page }],
          },
          onUpdate,
        );
      else
        this.setState(
          {
            selectedRows: selection,
            isAllSelected,
            selectedAllPages: selectedAllPages.filter(
              item => item.page !== page,
            ),
          },
          onUpdate,
        );
    }
  };

  isRowSelected = key => {
    const { data } = this.props;
    const row = get(data, key, {});
    const { selectedRows } = this.state;
    return selectedRows.indexOf(key) !== -1 || row.accepted;
  };

  clearSelected = () => {
    const { onUpdate } = this.props;
    if (this.innerRef && this.innerRef.parentNode) {
      const scroller = this.innerRef.parentNode.querySelector('.tablescroller');
      if (scroller) scroller.scrollLeft = 0;
    }
    this.setState({ selectedRows: [] }, onUpdate);
  };

  getStatus = () => {
    const { selectedRows } = this.state;
    if (selectedRows.length) return SelectionStatus.SELECTED;
    return SelectionStatus.NORMAL;
  };

  getCheckboxState = (checkDisabled = false) => (id, checked) => {
    const {
      disabled,
      getCheckboxState,
      page,
      customSelectAll,
      defaultPageSize,
      keyField,
    } = this.props;
    const {
      isAllSelected,
      selectedAllPages,
      preparedData,
      selectedRows,
    } = this.state;
    let status = checkboxState.NORMAL;

    let idDigital = id.match(/\d/g);
    idDigital = idDigital && Number(idDigital.join(''));

    if (
      idDigital !== null &&
      this.state.preparedData[idDigital] &&
      this.state.preparedData[idDigital].hiddenCheckBox
    )
      return { status, disabled };

    let keyIsAllSelected = isAllSelected;
    let selectedAll = '';
    if (customSelectAll) {
      const thereIsPage = selectedAllPages.find(item => item.page === page);
      const thereIsEditing = preparedData.find(
        item => item.hiddenCheckBox === true,
      );
      if (!thereIsPage) keyIsAllSelected = false;
      if (isAllSelected && thereIsEditing && thereIsPage) {
        selectedAll = 'select-all';
        keyIsAllSelected = false;
      }
      if (
        !checked &&
        !keyIsAllSelected &&
        selectedAll !== 'select-all' &&
        selectedRows.length
      ) {
        let prD = preparedData.slice(
          page * defaultPageSize,
          page * defaultPageSize + defaultPageSize,
        );
        prD = prD.length ? prD : preparedData;

        const isAll = !difference(
          prD.map(item => item[keyField]),
          selectedRows,
        ).length;

        if (isAll) keyIsAllSelected = true;
      }
    }

    if (checkDisabled && disabled) status = checkboxState.HIDDEN;

    if (
      checked ||
      this.isRowSelected(id) ||
      keyIsAllSelected ||
      selectedAll === id
    )
      status = checkboxState.SELECTED;

    if (getCheckboxState(id)) status = checkboxState.EDITED;

    return {
      status,
      disabled,
    };
  };

  onInnerRef = f => {
    this.innerRef = f;
  };

  onCheckboxTableRef = f => {
    this.checkboxTable = f;
  };

  render() {
    const {
      columns,
      formValues,
      name,
      noDataText,
      selectType = 'checkbox',
      height,
      customSelectAll,
    } = this.props;
    const { preparedData } = this.state;
    const preparedColumns = prepareColumns(name, columns, formValues);

    if (selectType === 'checkbox') {
      return (
        <React.Fragment>
          <SelectionTable
            minRows={5}
            highlight
            striped
            noDataText={noDataText}
            selectType={selectType}
            selectWidth={65}
            SelectInputComponent={SelectionCheckbox(
              this.getCheckboxState(false),
            )}
            SelectAllInputComponent={SelectionCheckbox(
              this.getCheckboxState(true),
            )}
            toggleSelection={
              customSelectAll
                ? this.toggleSelectionCustom
                : this.toggleSelection
            }
            toggleAll={customSelectAll ? this.toggleAllCustom : this.toggleAll}
            isSelected={this.isRowSelected}
            {...this.props}
            data={preparedData}
            columns={preparedColumns}
            height={height}
            ref={this.onCheckboxTableRef}
          />
          <div ref={this.onInnerRef} />
        </React.Fragment>
      );
    }

    return (
      <ThemeTable
        minRows={5}
        highlight
        striped
        noDataText={noDataText}
        {...this.props}
        data={preparedData}
        columns={preparedColumns}
        height={height}
      />
    );
  }
}

export default props => (
  <FormContext.Consumer>
    {({ values }) => (
      <SelectionList ref={props.onRef} {...props} formValues={values} />
    )}
  </FormContext.Consumer>
);
