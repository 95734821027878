import { takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { AuthService } from '../../../services/AuthService';
import * as actions from './LoginForm.actions';
import * as selectors from './LoginForm.selectors';
import { saveToken } from '../../../utils/cookieHelpers';
// import {getUserFromToken} from '../../../utils/userUtils';
import i18next from 'i18next';

function* loginSaga(action) {
  const { payload } = action;
  const apiUrl = yield select(selectors.getAuthURL);

  try {
    const answer = yield call(AuthService.login, payload, apiUrl);
    const url = answer.request.responseURL || '';

    if (url.indexOf('Error') !== -1) {
      yield put(actions.errors());
    } else {
      yield call(saveToken, answer.data.token);
      yield put(actions.errors());
      yield put(push('/'));
    }
  } catch (e) {
    yield put(actions.errors(i18next.t('Неверный логин или пароль')));
  }
}

export default function* watchActions() {
  yield takeEvery(actions.submit, loginSaga);
}
