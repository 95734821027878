import { get } from 'lodash';
import { Item } from './Item';
import { ProductService } from '../../../services/ProductService';
import { ProductList } from '../../../actions';
import { getCurrentParticipantId } from '../../../utils/userUtils';
import i18next from 'i18next';

export class ProductsItem extends Item {
  icon = 'menu-product-list';

  title = 'Товары';

  strictTest = () => !!this.value.match(/^\d{14}$/);

  onClick = async () => {
    this.onFailure(i18next.t('Идет поиск товаров...'));
    const strict = this.strictTest();
    const onlyOwnProducts = false;
    const participantId = getCurrentParticipantId();

    try {
      const params = {
        offset: 0,
        limit: 10,
        ...(strict && { gtin: this.value }),
        ...(!strict && { name: this.value }),
      };

      const answer = await ProductService.getList(params);
      const results = get(answer, 'data.results');
      if (results && results.length) {
        if (results.length === 1) {
          const id = get(results, '0.id');
          this.history.push(`/products/list/${id}/basic-info`);
        } else {
          this.history.push('/products/list/');
          this.dispatch(
            ProductList.onFilterApplyClick({
                gtin: [this.value],
                name: [this.value],
                ...(onlyOwnProducts && { participantId: [participantId] }),
              }),
          );
        }
        this.onSuccess();
        return;
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
