import { Button, Modal as ModalWindow, Typography } from '@crpt-ui/core';
import { CloseLarge } from '@crpt-ui/icons';
import React from 'react';

import { useStyles } from './Modal.styles';

export const Modal = ({ maxWidth, title, titleVariant = 'h4', isOpen, onClose, children, useHeaderDivider = false }) => {
  const styles = useStyles();
  return (
    <ModalWindow open={isOpen} onClose={onClose} maxWidth={maxWidth}>
      <div className={styles.wrapper}>
        <div className={`${styles.header} ${useHeaderDivider ? styles.divider : ''}`}>
          <Typography variant={titleVariant}>
            {title}
          </Typography>
          <Button onClick={onClose} size="sm" variant="text" icon={<CloseLarge />}/>
        </div>
        {children}
      </div>
    </ModalWindow>
  );
};
