import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';

import { documentStatusesNames } from '../../../constants/documentStatuses';
import { documentTypeNames } from '../../../constants/documentTypes';
import { escapeUrlForRoutePath, addLeadingZeros } from '../../../utils';

import { countryCode } from '../../../common_components/App/App.selectors';
import * as actions from './ducks/Receipt.actions';
import * as selectors from './ducks/Receipt.selectors';
import { tabs } from './Receipt.constants';
import ReceiptView from './Receipt.view';
import ReceiptBasicInfo from '../BasicInfoFabric/ReceiptBasicInfo';
import ProductList from '../ProductList';
import Codes from '../Codes/Codes';

import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../../common_components/_MaterialUi/ThemeUZ';
import { Translate } from '../../../common_components/Translate/Translate';

const Receipt = props => {
  const { match, receipt, loaded, countryCode } = props;
  const id = match.params.id;
  const isKZ = countryCode === 'KZ';
  const isUZ = countryCode === 'UZ';

  useEffect(() => {
    const { onMount, onUnmount } = props;
    onMount(encodeURIComponent(id));
    return onUnmount;
  }, [id]);

  const onTabClick = useCallback(to => {
    const id = props.match.params.id;
    props.history.push(`/documents/receipt/${id}/${to}`);
  });

  const onNavClick = useCallback(loc => {
    props.history.push(loc);
  });

  const onDownloadJSONClick = useCallback(() => {
    const { match, onDownloadJSON } = props;
    const id = match.params.id;
    onDownloadJSON(decodeURIComponent(id));
  });

  const currentTab = useCallback(
    () =>
      tabs.find(
        tab =>
          tab.to &&
          location.pathname.indexOf(`/documents/receipt/${id}/${tab.to}`) === 0,
      ),
    [location.pathname],
  )();

  const documentTypeName = documentTypeNames[receipt.type] || '—';

  const documentStatusName = documentStatusesNames[receipt.status] || '';

  let documentId = id;

  const { kktRegId, fiscalDocumentNumber, documentId: docId } = receipt;
  if (isKZ) {
    documentId = `${fiscalDocumentNumber}${kktRegId}`;
  } else if (isUZ) {
    documentId ? docId : '';
  }

  const breadcrumbs = [
    {
      href: '/documents/receipts',
      title: Translate('Документы'),
      id: 1,
    },
    {
      href: `/documents/receipt/${id}`,
      title: (
        <span style={{ fontWeight: 600 }}>
          {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
          {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
          {documentId}
        </span>
      ),
      id: 2,
    },
  ];

  const theme = useMemo(() =>
    countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ[countryCode],
  );

  return (
    <MuiThemeProvider theme={theme}>
      <ReceiptView
        currentTabIndex={currentTab ? tabs.indexOf(currentTab) : 0}
        tabs={tabs.map(tab => ({
          ...tab,
          label: Translate(tab.label),
          onClick: () => {
            onTabClick(tab.to);
          },
        }))}
        loaded={loaded}
        breadcrumbs={breadcrumbs}
        onNavClick={onNavClick}
        onDownloadJSONClick={onDownloadJSONClick}
      >
        <Switch>
          <Route
            render={() => <Codes documentId={id} selectedDocument={receipt} />}
            path={escapeUrlForRoutePath(`/documents/receipt/${id}/codes`)}
          />
          <Route
            render={() => (
              <React.Fragment>
                <ReceiptBasicInfo
                  id={documentId}
                  selectedDocument={receipt}
                  isKZ={isKZ}
                  isUZ={isUZ}
                />
                {
                  isKZ && (
                    <ProductList
                      selectedDocument={receipt}
                      countryCode={'KZ'}
                    />
                  )
                }
              </React.Fragment>
            )}
            path={`/documents/receipt/${id}`}
          />
        </Switch>
      </ReceiptView>
    </MuiThemeProvider>
  );
};

Receipt.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

const mapState = state => {
  return ({
    loaded: selectors.loaded(state),
    receipt: selectors.selectedDocument(state),
    isLoading: preloaderSelectors.loading(state),
    countryCode: countryCode(state),
  });
};

const mapDispatch = dispatch => ({
  onMount: id => dispatch(actions.mounted(id)),
  onUnmount: () => dispatch(actions.unmounted()),
  onDownloadJSON: id => dispatch(actions.downloadJSON(id)),
});

export default connect(mapState, mapDispatch)(Receipt);
