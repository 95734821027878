import styled from 'styled-components';

const PaginationWrapper = styled.div`
height: 60px;
display: flex;
align-items: center;
justify-content: center;
line-height: 0.8rem;
${props => props.withMargin ? 'margin-top: -60px' : ''};
z-index: 100;
& * {
  border-color: #55B5E7;
}
& > div > div:nth-child(2) > div {
  min-width: 24px;
  width: auto;
}
& > div > div:nth-child(2) > div > div {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
${props => props.background ? `background: ${props.background}` : ''};
`;

export default PaginationWrapper;
