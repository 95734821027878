import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authSelectors from './Auth.selectors';
import * as authActions from './Auth.actions';
import AuthView from './Auth.view';


class Auth extends Component {
  componentDidMount() {
    const { location, loaded } = this.props;
    const from = (location.state && location.state.from) || null;
    loaded(from);
  }

  render() {
    return <AuthView />;
  }
}

const mapState = state => ({
  authState: authSelectors.authState(state),
});

const mapDispatch = dispatch => ({
  loaded: from => dispatch(authActions.loaded(from)),
});

Auth.propTypes = {};

Auth.defaultProps = {};

export default connect(mapState, mapDispatch)(Auth);
