import React from 'react';
import PropTypes from 'prop-types';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { Wrap } from '../styled';
import { Title } from '../../../styled/index';
import { PARTICIPANT_TYPES } from '../../../../../constants/index';
import { Button } from '../../../Fields';
import SalesOutlet from './SalesOutlet';
import { withMountCycle, withRenderNothingNot, withItemsArray } from '../../../utils';

const newSalesOutlet = {
  address: '',
  code: '',
  kkt: [{number: ''}],
  key_id: new Date().getTime(),
};

const SalesOutlets = ({
  active,
  items,
  onAppend,
  onActivate,
  onRemove,
  onPushValue,
  onUpdateField,
}) => (
  <Wrap>
    <Title>Точки продаж и кассы</Title>
    {items.map((place, index) => (
      <SalesOutlet
        key={place.key_id}
        name={`salesOutlets[${index}]`}
        index={index}
        data={place}
        active={index === active}
        onActivate={onActivate}
        onPushValue={onPushValue}
        onRemove={onRemove}
        onUpdate={onUpdateField}
      />
    ))}
    <Button onClick={onAppend}>Добавить точку продаж</Button>
  </Wrap>
);

SalesOutlets.propTypes = {
  active: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAppend: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onPushValue: PropTypes.func.isRequired,
};


export default compose(
  withRenderNothingNot([PARTICIPANT_TYPES.RETAIL]),
  setDisplayName('SalesOutlets'),
  withItemsArray('salesOutlets', newSalesOutlet),
  withMountCycle('salesOutlets', [newSalesOutlet]),
  onlyUpdateForKeys(['items']),
)(SalesOutlets);
