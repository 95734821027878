import TableBody from '../default/TableBody';
import TableCell from './TableCell';
import TableFooter from '../default/TableFooter';
import TableHeader from '../default/TableHeader';
import TableHeaderCell from '../default/TableHeaderCell';
import TableInput from '../default/TableInput';
import TableLoading from '../default/TableLoading';
import TableNoData from '../default/TableNoData';
import TablePagination from '../default/TablePagination';
import TableResizer from '../default/TableResizer';
import TableRoot from '../default/TableRoot';
import TableRow from '../default/TableRow';
import TableRowGroup from '../default/TableRowGroup';
import TableSelect from '../default/TableSelect';
import TableWrapper from '../default/TableWrapper';

export const documentListTheme = {
  Body: TableBody,
  Cell: TableCell,
  Footer: TableFooter,
  Header: TableHeader,
  HeaderCell: TableHeaderCell,
  Input: TableInput,
  Loading: TableLoading,
  NoData: TableNoData,
  Pagination: TablePagination,
  Resizer: TableResizer,
  Root: TableRoot,
  Row: TableRow,
  RowGroup: TableRowGroup,
  Select: TableSelect,
  Wrapper: TableWrapper,
};
