import { combineReducers } from 'redux';
import { selectedProduct } from './selectedProduct';
import CISList from './CISList';
import { producerId } from './producerId';

export default combineReducers({
  selectedProduct,
  CISList,
  producerId
});
