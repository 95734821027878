export const defaultTheme = {
  InputWrap: {
    main: {
      border: '1px solid rgba(196, 196, 196, 0.25)',
    },
  },
  Placeholder: {
    main: {
      color: '#434244',
    },
  },
  Day: {
    current: {
      background: 'rgba(248, 236, 49, 0.3)',
    },
  },
};
