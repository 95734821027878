import React from 'react';
import PropTypes from 'prop-types';
import Table from '@crpt/react-table';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { get, isEmpty, isUndefined } from 'lodash';
import { Card } from '@crpt/react-card';
import { Column, Container } from '@crpt/react-page-layout';
import { Icon } from '@crpt/react-icon';
import * as actions from './ducks/Preview.actions';
import * as selectors from './ducks/Preview.selectors';
import * as authSelectors from '../../../../../common_components/Auth/Auth.selectors';
import PreviewView from './Preview.view';
// import { getPropsData } from './Preview.utils';

import {
  DataBlock,
  PreviewWrapper,
  TitleBlock,
  TitleTable,
  WrapBlock,
  TableWrap,
  IconWrap,
} from './Preview.styled';
import { getDataTable, transformChunkArray } from './Preview.utils';
import { previewType } from '../Document.constants';
// import InformationFields from '../../ImportDocument/Information/InformationFields';
// import { columns } from '../../ImportDocument/Goods/Goods.constants';
// import { columns as cisColumns } from '../../ImportDocument/Cis/Cis.constants';
// import { cisKey, goodsKey } from '../../ImportDocument/ImportDocument.constants';
import * as previewActions from './ducks/Preview.actions';
import { Translate } from '../../../../../common_components/Translate/Translate';

class Preview extends React.Component {
  static propTypes = {
    theme: PropTypes.shape({}),
    content: PropTypes.shape({}),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    reset: PropTypes.func,
    isOpen: PropTypes.bool,
    userInfo: PropTypes.shape({}),
    valuesForm: PropTypes.object,
    setPreview: PropTypes.func,
  };

  static defaultProps = {
    userInfo: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      isCisOpen: false,
      isGoodsOpen: false,
    };
  }

  onBlockerClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  onCancel = () => {
    const { onClose, reset } = this.props;
    reset();
    onClose();
  };

  onAccept = () => {
    const {
      onSubmit,
      onClose,
      reset,
      content: { values },
    } = this.props;
    onSubmit(values);
    onClose();
    reset();
  };

  toggleCis = () => this.setState({ isCisOpen: !this.state.isCisOpen });
  toggleGoods = () => this.setState({ isGoodsOpen: !this.state.isGoodsOpen });

  render() {
    const { isOpen, content, theme, userInfo, valuesForm } = this.props;
    const { isCisOpen, isGoodsOpen } = this.state;

    if (!isOpen || isEmpty(content)) return '';

    const {
      values, // храняться данные из формы (формируется по submit)
      fields, // храниться Общая информация с названием полей и данными по ним
      cisColumns, // данные для отображения таблиы на шаге Товары (загружены из файла constants)
      goodsKey,
      goodsColumns,
      cisKey,
      docName, // название окна
      type,
    } = content;

    const { number } = values;
    const typePreview = isUndefined(type)
      ? previewType.CONFIRMATION
      : previewType.CANCEL;
    const name = get(userInfo, 'full_name', '');
    const title = `${docName}`;

    const arrData = transformChunkArray(Object.keys(fields), 3);
    const [goodsColumnsPreview, goodsDataPreview] = getDataTable(
      goodsColumns,
      values,
      goodsKey,
    );
    const [cisColumnsPreview, cisDataPreview] = getDataTable(
      cisColumns,
      values,
      cisKey,
    );

    return (
      <React.Fragment>
        <PreviewView
          title={title}
          onBlockerClick={this.onBlockerClick}
          onCancel={this.onCancel}
          onAccept={this.onAccept}
          name={name}
          docNumber={number}
          type={typePreview}
        >
          <PreviewWrapper>
            <Card style={{ padding: '0 0 14px 0' }}>
              <Container fillWidth gutter={32}>
                {arrData.map((items, i) => (
                  <Column col={8} style={{ paddingLeft: '0' }} key={i}>
                    {items.map((item, j) => (
                      <WrapBlock key={j}>
                        <TitleBlock>{fields[item].title}</TitleBlock>
                        <DataBlock>
                          {!!fields[item].value === false
                            ? ' - '
                            : fields[item].value}
                        </DataBlock>
                      </WrapBlock>
                    ))}
                  </Column>
                ))}
              </Container>
            </Card>

            <TableWrap onClick={this.toggleCis}>
              <TitleTable>
                {Translate('Коды')}{' '}
                {cisDataPreview && cisDataPreview.length > 0
                  ? `(${cisDataPreview.length} шт.)`
                  : null}
              </TitleTable>
              <IconWrap>
                <Icon
                  type={isCisOpen ? 'up-arrow' : 'down-arrow'}
                  spacingLeft={6}
                />
              </IconWrap>
            </TableWrap>
            {isCisOpen && (
              <Table
                style={{ overflow: 'scroll' }}
                theme={theme}
                columns={cisColumnsPreview}
                data={cisDataPreview}
                minRows={0}
                highlight
                striped
                defaultPageSize={1000}
              />
            )}

            <TableWrap onClick={this.toggleGoods}>
              <TitleTable>
                {Translate('Товары')}{' '}
                {goodsDataPreview && goodsDataPreview.length > 0
                  ? `(${goodsDataPreview.length} шт.)`
                  : null}
              </TitleTable>
              <IconWrap>
                <Icon
                  type={isGoodsOpen ? 'up-arrow' : 'down-arrow'}
                  spacingLeft={6}
                />
              </IconWrap>
            </TableWrap>
            {isGoodsOpen && (
              <Table
                style={{ overflow: 'scroll' }}
                theme={theme}
                columns={goodsColumnsPreview}
                data={goodsDataPreview}
                minRows={0}
                highlight
                striped
                defaultPageSize={1000}
              />
            )}
          </PreviewWrapper>
        </PreviewView>
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  isOpen: selectors.isOpen(state),
  content: selectors.content(state),
  userInfo: authSelectors.userInfo(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(actions.close()),
  reset: () => dispatch(actions.setContent({})),
  setPreview: data => dispatch(previewActions.setContent(data)),
});

export default connect(mapState, mapDispatch)(withTheme(Preview));
