import React, { useCallback, useState } from 'react';

import {useModalState} from 'src/hooks';

import {Modal} from 'src/fragments/Modal';
import {Link, Button} from '@crpt-ui/core'
import { Box } from '@material-ui/core';

import {useStyles} from './NotificationList.styles';
import { Translate } from '../../../../common_components/Translate/Translate';


export const NotificationList = ({notificationsList}) => {
  const styles = useStyles();

  const [isModalOpen, openModal, closeModal] = useModalState();

  const firstEmail = notificationsList[0];
  return (
    <React.Fragment>
      <div>
        {firstEmail} {notificationsList.length > 1 && (
        <Link className={styles.notificationList} onClick={openModal} component="span">
          +{notificationsList.length - 1}
        </Link>
      )}
      </div>
      <Modal title={Translate("Получатели уведомления")} isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles.modalContent}>

          <div className={styles.listWrapper}>
            {notificationsList.map((item, index) => (
              <Box key={index} fontSize={14} fontWeight={600}>
                {item}
              </Box>
            ))}
          </div>

          <footer className={styles.footer}>
            <Button onClick={closeModal} size="lg" variant="outlined">
              {Translate('Закрыть')}
            </Button>
          </footer>

        </div>
      </Modal>
    </React.Fragment>
  );
};
