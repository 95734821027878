import {actionTypes} from '../../constants';
import {set} from 'lodash/fp';
import {get, filter} from 'lodash';


export function fields(state = {}, action = {}) {
  let temp = null;

  switch (action.type) {
    case actionTypes.FORM.DELETE_FORM:
      return set(action.path, undefined, state);

    case actionTypes.FORM.LOAD_FORM:
      return set(action.path, action.value, state);

    case actionTypes.FORM.ITEM_CHANGED:
      return set(action.path, action.value, state);

    case actionTypes.FORM.ITEM_APPEND: {
      const oldSet = get(state, action.path, null);
      if (Array.isArray(oldSet)) {
        const len = oldSet.length;
        return set(`${action.path}.${len}`, action.value, state);
      }
      if (!oldSet) return set(action.path, [].concat(action.value), state);
      return set(action.path, [].concat(oldSet, action.value), state);
    }


    case actionTypes.FORM.ITEM_REMOVE:
      temp = get(state, action.path, []);
      temp = filter(temp, (i, k) => k !== action.index);
      return set(`${action.path}`, temp, state);

    case actionTypes.FORM.CHECKBOX_TOGGLED: {
      const active = get(state, action.path) || [];
      if (~active.indexOf(action.value)) {
        return set(action.path, active.filter(c => c !== action.value), state);
      } else {
        return set(action.path, [].concat(active, action.value), state);
      }
    }

    case actionTypes.FORM.CLEAR_PATHS: {
      let oldState = state;
      for (const path of action.paths) {
        oldState = set(path, undefined, oldState);
      }
      return oldState;
    }

    default:
      return state;
  }
}
