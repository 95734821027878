import {combineReducers} from 'redux';
import {selectedParticipant} from './selectedParticipant';
import {documents} from './documents';
import Warehouse from './Warehouse';

export default combineReducers({
  selectedParticipant,
  documents,
  Warehouse,
});
