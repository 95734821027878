import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  margin-bottom: -20px;
  padding: 0 32px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 7;
`;