import React from 'react';
import { ImportantBlock } from '../../ReportHelp.styled';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  afterTable: <ImportantBlock><strong>Важно!</strong> В отчёт выводятся первые 1000 строк</ImportantBlock>,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Тип агрегации',
          'col2': <div>Возможность выбора агрегации:
            <ul>
              <li>По населённому пункту</li>
              <li>По субъекту РК</li>
              <li>По товару</li>
            </ul>
          </div>
        },
        {
          'col1': 'Субъект РК',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Населённый пункт (по ФИАС)',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Численность населённого пункта',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'GTIN',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование товара',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Производитель',
          'col2': 'Возможность фильтрации по доступным значениям'
        }
      ]
    }
  ]
}
