import React, { Component } from 'react';
import styled from 'styled-components';

const propTypes = {};

const Container = styled.div`
  position: relative;
`;

const Wrap = styled.div`
  display: ${props => (props.shouldDisplay ? 'flex' : 'none')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.1s ease;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  border: solid 1px #abadb5;
  z-index: 2;
  top: ${props => props.top || ''};
  right: ${props => props.right || ''};
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  background: #fff;
  color: black;
  transition: background 0.2s ease;
  &:hover {
    background: #ebf4fa;
  }
`;

class Popup extends Component {
  displayTimeout;
  visibleTimeout;

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      shouldDisplay: false
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  onMouseEnter(e) {
    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);
    this.setState({
      shouldDisplay: true
    });
    this.visibleTimeout = setTimeout(() => {
      this.setState({
        visible: true
      });
    }, 0);
  }

  onMouseMove(e) {
    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);
    this.setState({
      shouldDisplay: true
    });
    this.visibleTimeout = setTimeout(() => {
      this.setState({
        visible: true
      });
    }, 0);
  }

  onMouseOut(e) {
    if (this.displayTimeout) clearTimeout(this.displayTimeout);
    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);
    this.visibleTimeout = setTimeout(() => {
      this.setState({
        shouldDisplay: false
      });
    }, 100);
    this.displayTimeout = setTimeout(() => {
      this.setState({
        shouldDisplay: false
      });
    }, 500);
  }

  render() {
    const { children, menu, top, right } = this.props;

    return (
      <Container
        onMouseEnter={this.onMouseEnter}
        onMouseOut={this.onMouseOut}
        onMouseMove={this.onMouseMove}
      >
        {children}
        <Wrap
          visible={this.state.visible}
          shouldDisplay={this.state.shouldDisplay}
          top={top}
          right={right}
        >
          {menu.map((v, i) => {
            return (
              <MenuItem key={i} onClick={v.onClick}>
                {v.title}
              </MenuItem>
            );
          })}
        </Wrap>
      </Container>
    );
  }
}

Popup.propTypes = propTypes;

export default Popup;
