import { PAGINATED_LIST_CONSTANTS } from '../constants';
import { actionTypes } from '../constants';
import { get } from 'lodash';

export function getPaginatedListReducers(namespace) {
  const List = function(state = [], action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_PAGE_LOADED]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_LOADED]:
        return get(action, 'value.results', get(action, 'value', state));
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_MOUNTED]:
        return [];
      default:
        return state;
    }
  };

  const filterParams = function(state = {}, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][
        PAGINATED_LIST_CONSTANTS.FILTER_APPLY_CLICKED
      ]:
        return action.value;
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_MOUNTED]:
      case actionTypes[namespace][
        PAGINATED_LIST_CONSTANTS.FILTER_PARAMS_UPDATED
      ]:
        return Object.assign({}, state, action.value);
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.CLEAR_FILTER_PARAMS]:
        return {};
      default:
        return state;
    }
  };

  const pagesCount = function(state = 1, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_LOADED]: {
        const total = get(action, 'value.total', 1);
        const limit = get(action, 'value.limit', 10);
        return Math.ceil(total / limit);
      }
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.PAGES_COUNT_LOADED]:
        return action.value;
      default:
        return state;
    }
  };

  const selectedPage = function(state = 1, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.PAGE_SELECTED]:
        return action.value;
      case actionTypes[namespace][
        PAGINATED_LIST_CONSTANTS.FILTER_APPLY_CLICKED
      ]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_MOUNTED]:
        return 1;
      default:
        return state;
    }
  };

  const total = function(state = 0, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_LOADED]:
        return get(action, 'value.total', 0);
      default:
        return state;
    }
  };

  const last = function(state = false, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_PAGE_LOADED]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_LOADED]:
        return get(action, 'value.last', false);
      default:
        return state;
    }
  };

  const isPageLoading = function(state = false, action = {}) {
    switch (action.type) {
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_PAGE_LOADED]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_LOADED]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LOADING_FINISHED]:
        return false;
      case actionTypes[namespace][
        PAGINATED_LIST_CONSTANTS.FILTER_APPLY_CLICKED
      ]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LOADING_STARTED]:
      case actionTypes[namespace][PAGINATED_LIST_CONSTANTS.LIST_MOUNTED]:
        return true;
      default:
        return state;
    }
  };

  return {
    List,
    filterParams,
    pagesCount,
    selectedPage,
    total,
    last,
    isPageLoading,
  };
}
