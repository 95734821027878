import { manualDataKey } from '../Gtins/Gtins.constants';

class GtinsHelper {
  // payload: array of ProductFrontendDto
  add({ state, payload }) {
    return ([
      ...state,
      ...payload,
    ]);
  }

  // payload: array of gtin (string)
  delete({ state, payload }) {
    return state.filter(item => !payload.includes(item[manualDataKey]));
  }

  // payload: array of ProductFrontendDto
  update({ payload }) {
    return ([
      ...payload,
    ]);
  }
}

const ProductFrontendDto = {
  'brand': 'string',
  'gtin': 'string',
  'id': 0,
  'inn': 'string',
  'innerUnitCount': 0,
  'model': 'string',
  'name': 'string',
  'packageType': 'string',
  'producerId': 0,
  'producerName': 'string',
};

export default GtinsHelper;
