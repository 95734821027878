import React from 'react';
import {
  FilterWrapper,
  FilterHeader,
  FilterHeaderItem,
  FilterInfo,
  FilterInfoItem,
  FilterInfoHeader,
  FilterInfoDesc,
} from './Filter.styled';

const dataFilterHeader = [
  'Все',
  'Никого',
  'Инверсия',
]

const dataFilterInfo = [
  {
    header: 'Все',
    desc: <span>Выбрать весь<br/> список критериев</span>
  },
  {
    header: 'Никого',
    desc: <span>Очистить список всех<br/> выделенных критериев</span>
  },
  {
    header: 'Инверсия',
    desc: <span>Выбрать все<br/> кроме выделенных</span>
  },
]

const Filter = () => (
  <FilterWrapper>
    <FilterHeader>
    {dataFilterHeader.map((item, i) => <FilterHeaderItem key={i}>{item}</FilterHeaderItem>)}
    </FilterHeader>
    <FilterInfo>
    {dataFilterInfo.map((item, i) => (
      <FilterInfoItem key={i}>
        <FilterInfoHeader>
          {item.header}
        </FilterInfoHeader>
        <FilterInfoDesc>
          {item.desc}
        </FilterInfoDesc>
      </FilterInfoItem>
    ))}
    </FilterInfo>
  </FilterWrapper>
);

export default Filter;