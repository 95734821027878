import styled from 'styled-components';


export const ModalButtonWrapper = styled.div`
  flex-grow: 1;
  :first-child {
    padding-right: 24px;
  }
`;

