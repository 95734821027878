import React, { useMemo, useState } from 'react';
import {
  Content,
  DividerOfContent, FixedActWrap, FixedWrap, InfoFields,
  InfoWrap,
  PageWrapper,
  StepTitle,
  StepWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from '../../../../../common_components/styled/ContentWrapper';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { contentBodyConfirm, operationTypes, steps, tabsKZ } from '../ActDocument.constants';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { TitleAndWarning } from './TitleAndWarning';
import { MuiThemeProvider } from '@material-ui/core';
import { MaterialUiThemeKZ } from '../../../../../common_components/_MaterialUi/ThemeKZ';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import Select from '../../../../../common_components/Form/MaterialFields/Select/Select';
import CheckboxField from '../../../../../common_components/Form/MaterialFields/Checkbox/Checkbox';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import CommandBar from '../../../../../common_components/CommandBar/CommandBar';
import CommonActionButton from '../../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from '../../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import { Toast } from '../../../../../utils/Toast';
import { toast } from 'react-toastify';
import * as authSelectors from '../../../../../common_components/Auth/Auth.selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ParentAct } from './ParentAct';
import ConfirmModal from '../../../../../common_components/Modals/ConfirmModal/ConfirmModal';

const CorrectionInfo = ({ show, formValues, onSetStep, history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cancelDoc = () => {
    history.push('/documents/outcoming');
    Toast.showInfo({
      content: Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const toggleDialog = () =>
    setIsModalOpen(!isModalOpen);


  const onNextStep = () => {
    onSetStep(steps.goods);
  };

  const commandBarLeft = [
    <CommonActionButton onClick={toggleDialog} disabled={false}>
      {Translate('Отменить')}
    </CommonActionButton>,
  ];

  const commandBarRight = [
    <PrimaryActionButton onClick={onNextStep} disabled={false}>
      {Translate('Следующий шаг')}
    </PrimaryActionButton>,
  ];

  const tabsCurrent = useMemo(() => {
    return tabsKZ
      .map(tab =>
        tab.id === steps.info
          ? tab
          : {
            ...tab,
            onClick: () => {
              onSetStep(tab.id);
            },
          },
      )
  }, []);

  return (
    <PageWrapper hide={!show}>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onClose={toggleDialog}
        onSubmit={cancelDoc}
        content={contentBodyConfirm}
        header={Translate('Предупреждение')}
        cancelButtonText={Translate('Нет')}
        submitButtonText={Translate('Да')}
        notCancelable
      />
      <ContentWrapper>
        <StepWrapper>
          <Steps
            value={steps.info}
            items={tabsCurrent}
            variant={'fullWidth'}
          />
        </StepWrapper>
        <Content>
          <TitleAndWarning
            showLeft={false}
            showWarning={false}
            left={0}
          />
          <br/>
          <DividerOfContent />

          <MuiThemeProvider
            theme={MaterialUiThemeKZ}
          >
            <StepTitle>{Translate('Общие данные')}</StepTitle>

            <InfoWrap noMargin>
              <InfoFields noMargin>
                <FixedActWrap>
                  <CheckboxField
                    name="fixed"
                    placeholder={Translate('Исправленный')}
                    disabled
                  />
                  <ParentAct id={formValues.parent_id}/>
                </FixedActWrap>
              </InfoFields>
              <div style={{ minWidth: '400px' }} />
            </InfoWrap>
            <StepTitle noMarginTop>Продавец</StepTitle>
            <InfoWrap noMargin>
              <InfoFields noMargin fullWidth>
                <Input
                  name="sender_name_full"
                  placeholder={Translate('Наименование Организации/ИИН/БИН')}
                  disabled
                />
              </InfoFields>
              <div style={{ minWidth: '400px' }} />
            </InfoWrap>
            <StepTitle noMarginTop>Покупатель</StepTitle>
            <InfoWrap noMargin>
              <InfoFields noMargin fullWidth>
                <InfoFields noMargin fullWidth>
                  <Input
                    name="buyer"
                    placeholder={Translate('Наименование Организации/ИИН/БИН')}
                    disabled
                  />
                </InfoFields>
              </InfoFields>
              <div style={{ minWidth: '400px' }} />
            </InfoWrap>
            <StepTitle noMarginTop>{Translate('Тип операции')}</StepTitle>
            <InfoWrap noMargin>
              <InfoFields noMargin>
                <Select
                  name="operation_type"
                  placeholder={Translate('Тип операции')}
                  options={operationTypes}
                  defaultValue="realisation"
                  disabled
                />
                <div />
                <CheckboxField
                  name="isHasPaperDocument"
                  placeholder={Translate('Бумажный носитель')}
                  disabled
                />
                <div />
                {
                  formValues.isHasPaperDocument && (
                    <React.Fragment>
                      <Input
                        name="origin_number"
                        placeholder={Translate('Номер первичного документа')}
                        disabled
                      />
                      <DatePicker
                        name="origin_date"
                        placeholder={Translate('Дата первичного документа')}
                        maxDate={new Date}
                        disabled
                      />
                    </React.Fragment>
                  )
                }
              </InfoFields>
              <div style={{ minWidth: '400px' }} />
            </InfoWrap>
          </MuiThemeProvider>

          <DividerOfContent />

          <CommandBar left={commandBarLeft} right={commandBarRight} />
        </Content>
      </ContentWrapper>
    </PageWrapper>
  )
};

const mapState = state => ({
  inn: authSelectors.userInn(state),
  user: authSelectors.userInfo(state),
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState), withRouter)(CorrectionInfo);
