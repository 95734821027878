import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './Wrapper';
import { Panel } from './Panel';

export const Options = ({
  theme,
  options,
  isError,
  disabled,
  isOpen,
  noValuesText,
  isLoading,
  inputValue,
  selectValues,
  onOptionClick,
}) => {
  return (
    <Wrapper
      theme={theme}
      isError={isError}
      disabled={disabled}
      visible={isOpen}
    >
      <Panel
        selectValues={selectValues}
        inputValue={inputValue}
        theme={theme}
        options={options}
        isOpen={isOpen}
        noValuesText={noValuesText}
        isLoading={isLoading}
        disabled={disabled}
        onOptionClick={onOptionClick}
      />
    </Wrapper>
  );
};

Options.propTypes = {
  isError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      SelectOptionsPanel: PropTypes.object,
    }),
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  inputValue: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  noValuesText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
