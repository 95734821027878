import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '0.70fr 0.30fr',
    color:theme.palette.text.primary,
  },
  rightSide: {
    display:'flex',
    justifyContent: 'flex-end'
  }
}));