import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getCookie, setLanguage } from 'src/utils/cookieHelpers';
import { Chevron } from '@crpt-ui/icons';
import * as actions from '../../actions/LoginFormKep';

const DefaultLng = getCookie('language') || 'RU';
const countryCode = window.env.ELK_COUNTRY || 'RU';

const useStyles = makeStyles((theme = Theme) => ({
  button: {
    '&.MuiButton-root': {
      minWidth: '40px',
      fontSize: '11px',
      lineHeight: '1.5rem',
      padding: '0px',
      paddingLeft: '8px',
      fontWeight: '700',
      backgroundColor: '#fff',
      color: theme.palette.text.primaryDark || '#63666a',
      '& svg': {
        fill: theme.palette.text.primaryDark || '#63666a',
      },
    },
  },
  menu: {
    '& .MuiMenuItem-root': {
      minWidth: '40px',
      paddingLeft: '12px',
      paddingRight: '12px',
      fontSize: '12px',
      fontWeight: '700',
    },
  },
}));

export const LanguageMenu = ({ setLanguageInStore }) => {
  const classes = useStyles();
  const [showing, setShowing] = React.useState(null);
  const langTitle = React.useRef(DefaultLng);
  const { i18n } = useTranslation();

  useEffect(() => {
    setLanguageInStore(DefaultLng);
  }, [DefaultLng])

  const handleClick = event => {
    setShowing(event.currentTarget);
  };

  const handleSelect = lng => {
    setLanguageInStore(lng);
    setLanguage(lng);
    langTitle.current = lng;
    i18n.changeLanguage(lng);
    setShowing(null);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick} className={classes.button}>
        {langTitle.current}
        {Boolean(showing) ? (
          <Chevron
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        ) : (
          <Chevron />
        )}
      </Button>
      <Menu
        anchorEl={showing}
        autoFocus={false}
        open={Boolean(showing)}
        onClose={() => setShowing(null)}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.menu}
      >
        <MenuItem onClick={() => handleSelect('RU')}>RU</MenuItem>
        <MenuItem onClick={() => handleSelect('EN')}>EN</MenuItem>
        {countryCode === 'KZ' && (
          <React.Fragment>
            <MenuItem onClick={() => handleSelect('KZ')}>KZ</MenuItem>
          </React.Fragment>
        )}
        {countryCode === 'UZ' && (
          <MenuItem onClick={() => handleSelect('UZ')}>UZ</MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

const mapDispatch = dispatch => ({
  setLanguageInStore: data => dispatch(actions.setLanguage(data)),
});

export default compose(
  connect(null, mapDispatch)
)(LanguageMenu);
