export class HistoryHelper {
  static _history;
  static setHistory(history) {
    if (!HistoryHelper._history) {
      HistoryHelper._history = history;
    }
  }

  static getHistory() {
    return HistoryHelper._history;
  }
}
