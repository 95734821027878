import {combineReducers} from 'redux';

import {submitButtonDisabled} from './submitButtonDisabled';
import {loginDisabled} from './loginDisabled';
import {passwordDisabled} from './passwordDisabled';
import {loginError} from './loginError';
import {passwordError} from './passwordError';

export default combineReducers({
  submitButtonDisabled,
  loginDisabled,
  loginError,
  passwordError,
  passwordDisabled
});
