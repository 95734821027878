import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, branch, renderNothing } from 'recompact';
import { CertInfo, CertName } from './styled';


const CertOption = ({ value }) => (
  <div>
    <CertInfo>{value.source}</CertInfo>
    <CertInfo>{value.date}</CertInfo>
    <CertName>{value.name}</CertName>
  </div>
);

CertOption.propTypes = {
  value: PropTypes.shape({
    source: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};


export default compose(
  pure,
  branch(({ value }) => !value, renderNothing),
)(CertOption);
