export default {
  title: 'Табличное представление:',
  amountOfTable: 4,
  tables: [
// table #1
    {
      headers: [
        {
          Header: 'Тип участника',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 130,
          subheaders: []
        },
        {
          Header: 'ИНН',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
          subheaders: []
        },
        {
          Header: 'Субъект РК',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
          subheaders: []
        },
        {
          Header: 'Юридический адрес',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Наименование участника',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 190,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'МРЦ',
          accessor: 'col9',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
        }
      ]
    },
// table #2
    {
      headers: [
        {
          Header: 'Остатки товаров на начало периода',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 620,
          subheaders: [
            {
              Header: 'шт.',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: '% шт.',
              accessor: 'col11',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: 'тенге',
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: '% тенге',
              accessor: 'col13',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Остатки товаров на конец периода',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 620,
          subheaders: [
            {
              Header: 'шт.',
              accessor: 'col14',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: '% шт.',
              accessor: 'col15',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: 'тенге',
              accessor: 'col16',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
            {
              Header: '% тенге.',
              accessor: 'col17',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 155,
              subheaders: []
            },
          ]
        }
      ],
      data: [
        {
          'col10': [10],
          'col11': [11],
          'col12': [12],
          'col13': [13],
          'col14': [14],
          'col15': [15],
          'col16': [16],
          'col17': [17]
        }
      ]
    },
// table #3
    {
      preTable: [
        {
          name: 'Приход товара',
          width: 550
        },
        {
          name: 'Выбытие товара',
          width: 690
        }
      ],
      headers: [
        {
          Header: 'Всего, шт.',
          accessor: 'col18',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Закупка в России',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col19',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col20',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Импорт',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col21',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col22',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Производство, шт.',
          accessor: 'col23',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: []
        },
        {
          Header: '',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: [
            {
              Header: 'Всего, шт.',
              accessor: 'col24',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 100,
              subheaders: []
            }
          ]
        },
        {
          Header: 'Оптовые продажи',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col25',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col26',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Розничные продажи',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col27',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col28',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Повторные продажи',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col29',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col30',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 75,
              subheaders: []
            },
          ]
        },
        {
          Header: '',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
          subheaders: [
            {
              Header: 'Списание товара, шт.',
              accessor: 'col31',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col18': [18],
          'col19': [19],
          'col20': [20],
          'col21': [21],
          'col22': [22],
          'col23': [23],
          'col24': [24],
          'col25': [25],
          'col26': [26],
          'col27': [27],
          'col28': [28],
          'col29': [29],
          'col30': [30],
          'col31': [31],
        }
      ]
    },
  ]
}
