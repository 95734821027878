import React from 'react';
import { ImportantBlock } from '../../ReportHelp.styled';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  afterTable: <ImportantBlock><strong>Важно!</strong> В отчёт выводятся первые 1000 строк</ImportantBlock>,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }
      ],
      data: [
        {
          'col1': 'Период',
          'col2': 'По желанию можно выбрать любой месяц с начала эксперимента'
        },
        {
          'col1': 'География',
          'col2': <div>Возможность выбора агрегации:
            <ul>
              <li>По городу</li>
              <li>По региону</li>
            </ul>
          </div>
        },
        {
          'col1': 'Товар',
          'col2': <div>Возможность выбора агрегации:
            <ul>
              <li>По GTIN</li>
              <li>По Бренду</li>
            </ul>
          </div>
        },
        {
          'col1': 'Торговый канал',
          'col2': <div>Возможность фильтрации по значениям:
            <ul>
              <li>Опт</li>
              <li>Розница</li>
            </ul>
          </div>
        },
        {
          'col1': 'Субъект РК',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Населённый пункт (по ФИАС)',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Численность населённого пункта',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование товара',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'GTIN',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Бренд',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
      ]
    }
  ]
}
