import { withStyles } from '@material-ui/core';
import React from 'react';

const styles = () => ({
  root: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  content: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24 / 16,
    marginTop: 16,
  },
});

const EmptyList = ({ classes, children, icon }) => {
  return (
    <div className={classes.root}>
      {React.cloneElement(icon, {
        style: {
          fontSize: 88,
          color: '#b8b8b8',
        },
      })}
      {children && <div className={classes.content}>{children}</div>}
    </div>
  );
};

EmptyList.defaultProps = {
  icon: (
    <svg
      width="120"
      height="120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 48a3 3 0 1 0 0 6h46a3 3 0 0 0 0-6H37zm32 22H37a3 3 0 1 1 0-6h32a3 3 0 1 1 0 6zm-32 9a3 3 0 1 0 0 6h40.5a3 3 0 1 0 0-6H37z"
        fill="#B8B8B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.108 30.447L79.8 13.967A10 10 0 0 0 72.692 11H23a2 2 0 0 0-2 2v94a2 2 0 0 0 2 2h74a2 2 0 0 0 2-2V37.48a10 10 0 0 0-2.892-7.033zM93 40v63H27V17h44v18a5 5 0 0 0 5 5h17zm-1.817-6L77 19.667V34h14.183z"
        fill="#B8B8B8"
      />
    </svg>
  ),
};

export default withStyles(styles)(EmptyList);
