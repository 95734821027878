import { get, has, isArray, isNull, isNumber, isObject, isString, isUndefined, set, isEmpty } from 'lodash';

export const floatValidator = value => isNumber(value) && Number.isFinite(value) ? undefined : 'Error';

export const check = (values, oldValues, errors, oldErrors, mutators) => (key, ...cbs) => {
  const newVal = get(values, key);
  const oldVal = get(oldValues, key);

  if (!has(oldValues, key) || oldVal !== newVal) {
    const err = cbs.reduce((res, cur) => res || cur(newVal, oldVal, key, values, mutators), undefined);
    set(errors, key, err);
  } else set(errors, key, get(oldErrors, key));
};

export const exist = values => (key, cb) => {
  if (has(values, key) && !isNull(get(values, key))) cb(key);
};

export const each = values => (key, cb) => {
  const value = get(values, key);
  if (isArray(value)) value.forEach((_, i) => cb(`${key}.${i}`));
};

export const req = val => {
  if (isUndefined(val) || isNull(val)) return 'Error';

  if (val instanceof Blob) {
    if (val.size === 0) return 'Error';
  } else if (isObject(val) && isEmpty(val)) return 'Error';

  if (isString(val) && val === '') return 'Error';

  return undefined;
};

export const gte = check => val => {
  if (isString(val) && val.length < check) return 'Error';
  if (isNumber(val) && val < check) return 'Error';
  return undefined;
};

export const lte = check => val => {
  if (isString(val) && val.length > check) return 'Error';
  if (isNumber(val) && val > check) return 'Error';
  return undefined;
};

export const number = value => ((isUndefined(value) || isNull(value) || isNumber(value)) ? undefined : 'Error');

export const float = value => ((isUndefined(value) || isNull(value) || (isNumber(value) && Number.isFinite(value))) ? undefined : 'Error');
