/* eslint-disable react/prop-types */
import React from 'react';
import { get } from 'lodash';
import { getParentByRole } from './ListFieldCenteredPanelWrapper.utils';


const margins = { margin: '4px -10px 0 -10px' };


class ListFieldCenteredPanelWrapper extends React.Component {
  wrapRef = React.createRef();

  placeholder = null;

  flag = false;

  componentDidMount() {
    this.panel = get(this.field, 'optionsPanel');
    if (!this.panel) return;
    this.panelParent = this.panel.parentNode;

    window.addEventListener('resize', this.setPosition);
    window.addEventListener('scroll', this.setPosition);
    const scroller = getParentByRole({ elem: this.panel, role: 'tablescrooller' });
    scroller.addEventListener('resize', this.setPosition);
    scroller.addEventListener('scroll', this.setPosition);
  }

  componentWillUnmount() {
    if (!this.panel) return;

    window.removeEventListener('resize', this.setPosition);
    window.removeEventListener('scroll', this.setPosition);
    const scroller = getParentByRole({ elem: this.panel, role: 'tablescrooller' });
    scroller.removeEventListener('resize', this.setPosition);
    scroller.removeEventListener('scroll', this.setPosition);
  }

  onRef = (ref) => {
    const { onRef } = this.props;

    this.field = ref;
    if (onRef) onRef(ref);
  };

  onTogglePanel = (flag) => {
    if (flag) {
      this.flag = true;
      this.panel.style.position = 'fixed';
      this.setPosition();
    } else {
      this.flag = false;
      this.panel.style.position = '';
      this.panel.style.top = '';
      this.panel.style.left = '';
      this.panel.style.minWidth = '';
      this.panel.style.maxWidth = '';
    }
  };

  setPosition = () => {
    if (!this.flag) return;

    const parentStyle = window.getComputedStyle(this.wrapRef.current);
    const { height } = parentStyle;
    const parsedHeight = parseInt(height, 10);
    const parentRect = this.wrapRef.current.getBoundingClientRect();
    const panelRect = this.panel.getBoundingClientRect();
    const { width, top, left } = parentRect;
    const { width: panelWidth } = panelRect;

    this.panel.style.top = `${top + parsedHeight}px`;

    const newWidth = panelWidth || width;
    this.panel.style.maxWidth = `${newWidth}px`;
    this.panel.style.minWidth = `${newWidth}px`;

    let newLeft = panelWidth ? left + (width - panelWidth) / 2 : left;
    const scroller = getParentByRole({ elem: this.panel, role: 'tablescrooller' });
    if (scroller) {
      const scrollerLeft = scroller.getBoundingClientRect().left;
      if (newLeft < scrollerLeft) newLeft = scrollerLeft;
    }

    this.panel.style.left = `${newLeft}px`;
  };

  render() {
    const { children, fillWidth } = this.props;

    return (
      <div ref={this.wrapRef} style={margins}>
        {React.cloneElement(children, {
          onRef: this.onRef,
          onTogglePanel: this.onTogglePanel,
          showPointer: !fillWidth,
        })}
      </div>
    );
  }
}

export default ListFieldCenteredPanelWrapper;
