import {actionTypes, strings} from '../../constants/';

export function loginState(state = strings.AUTH.STATE.PRELOADING, action = {}) {
  switch (action.type) {
    case actionTypes.AUTH.LOGIN.GOT_INFO:
      return strings.AUTH.STATE.AUTHORIZED;

    case actionTypes.AUTH.LOGIN.NOT_AUTHORIZED:
      return strings.AUTH.STATE.NOT_AUTHORIZED;

    default:
      return state;
  }
}
