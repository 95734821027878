import { call, put, takeEvery } from "redux-saga/effects";
import { replace } from "react-router-redux";
import { get } from "lodash";
import { signUZSaga } from "../../../../../common_components/Eimzo/Eimzo.saga";
import * as actions from "./TradeInventory.actions";
import { Toast } from "../../../../../utils/Toast";
import { documentTypes } from "../../../../../constants";
import Api from "../../../../../common_components/Api/Api";

function* submitSaga(action) {
    const signature = yield call(signUZSaga, {
        payload: {data: prepareSubmitData(action.payload), file: false},
    });
    if (!signature) {
        yield put(actions.reset());
        Toast.showInfo({
            content: 'Документ не подписан.',
            autoClose: 5000,
        });
        return;
    }
    const requestPayload = {
        url: '/api/v3/facade/doc/signed',
        method: 'post',
        data: {
            registration_number: null,
            signature: signature.signature,
            document: signature.document,
            document_type: documentTypes.TRADE_INVENTORY,
        },
    };
    const [success, error] = yield call(Api.request, requestPayload)
    if (success) {
        Toast.showSuccess({
            content: 'Документ уведомления о товарных остатках успешно создан.',
        });
        yield put(replace('/documents/list'));
    }
    if (error) {
        yield call(Toast.showError, {
            content: get(error, 'response.data.message', 'error'),
        });
    }
}

export default function* watch() {
    yield takeEvery(actions.confirmDoc, submitSaga);
}

const prepareSubmitData = ({ values }) => ({
    inn: values.applicantInn,
    pg: values.pg,
    inventory_date: values.inventory_date,
    cises: values.products.map(({ cis })=> cis),
});
