import { makeStyles } from '@material-ui/core';
import React from 'react';

// use negative margin to make content full width cause modal from crpt-ui have paddings in content
export const useStyles = makeStyles(theme => ({
  wrapper: {
    display:'flex',
    flexDirection: 'column',
    margin:'-32px',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.border.light}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 24px 24px 40px',
    '& > :first-child': {
      margin: 0,
    }
  }
}));
