import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  closeWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
    '& > svg': {
      cursor: 'pointer',
    },
  },
  selectUserWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: 20,
    },
    '& > button': {
      fontSize: 0,
    },
  },
  gridWrapper: {
    '@media screen and (max-width: 600px)': {
      display: 'block',
    },
  },
  autocompleteOption: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    '& span': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  },
  textColorSecondary: {
    color: theme.palette.text.secondary,
  },
  devider: {
    backgroundColor: '#F2F2F2',
    height: 1,
    width: '100%',
  },
}));
