import { takeEvery, put, select } from 'redux-saga/effects';
import moment from 'moment';
import * as actions from './ImportList.actions';
import * as selectors from './ImportList.selectors';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import { limit } from '../ImportList.constants';
import * as qs from 'qs';

function* requestSaga(action = {}) {
  const { payload = {} } = action;
  const { page, dir } = payload;

  yield put(actions.updatePage(page));

  const newOffset = ((page || 1) - 1) * 10;
  yield put(actions.updateOffset(newOffset));

  const filters = yield select(selectors.filters);

  if (filters.dateFrom) {
    filters.dateFrom = moment(filters.dateFrom).utc().format() ;
    filters.dateTo = moment(filters.dateTo).utc().format() ;
  }
  const offset = yield select(selectors.offset);

  let paginationParams = {
    limit,
    offset,
  };

  const requestPayload = {
    url: '/api/v3/facade/documents/search',
    method: 'post',
    data: {
      excludingTypes: [
        'LK_ADD_APP_USER',
        'LK_ADD_APP_USER_XML',
        'GRAY_ZONE_DOCUMENT',
        'LP_FTS_INTRODUCE_REQUEST',
      ],
      ...{ ...filters, page: undefined },
      documentType: filters.documentType || "EAS_CROSSBORDER_GROUP",
      pagination: paginationParams,
    },
  };

  yield put(listActions.gotRequest(requestPayload, actions));
}


export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
}
