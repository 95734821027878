import styled from 'styled-components';
import { get } from 'lodash';

const ActionBarWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: ${props => get(props, 'theme.TableFilter.buttonBar.bottom', '0')};
  margin-right: ${props => get(props, 'theme.TableFilter.buttonBar.marginRight')};
  margin-left: ${props => get(props, 'theme.TableFilter.buttonBar.marginLeft')};
`;

export default ActionBarWrapper;
