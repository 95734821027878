export const TRANSLATION = {
    'The value': 'Значение',
    'Value': 'Значение',
    'cvc-complex-type': 'формат',
    'AnonType_': '',
    'cvc-pattern-valid': 'Неверный формат',
    'is not facet-valid with respect to pattern': 'не соответствует формату',
    'cvc-attribute': 'Неверный аттрибут',
    'of attribute': 'аттрибута',
    'on element': 'в элементе',
    'is not valid with respect to its type': 'не соответствует формату',
    'cvc-enumeration-valid': 'Неверное значение',
    'is not facet-valid with respect to enumeration': 'не соответствует значениям',
    'It must be a value from the enumeration': '',
    'Attribute': 'аттрибут',
    'Файл': '',
    'is not allowed to appear in element': 'не должен содержаться в элементе',
    'for type': 'для',
    'must appear ': 'должен содержаться',
    'One of': '',
    'Invalid content was found': 'Неправильное значение',
    'starting with element': 'в элементе',
    'is expected': 'обязательный элемент',
};