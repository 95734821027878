import { connect } from 'react-redux';
import {
  compose,
  withPropsOnChange,
  withProps,
  branch,
  renderComponent,
  withHandlers,
  renderNothing,
} from 'recompact';
import { RegistrationSteps } from '../../constants';
import { getCertificateValues } from './utils';
import { CadesUtil } from '../../../../utils/CadesUtil';
import FilledStep from './FilledStep';
import CurrentStep from './CurrentStep';
import LoginFormSidebarContent from '../../../LoginFormSidebarContent';
import { get } from 'lodash';
import { CertAdapter } from '../../../../actions/LoginFormKep/CertAdapter';

const getEmail = cert => {
  switch (cert.certType) {
    case 'CADES':
      return CadesUtil.getByTagFromSubject(cert.info[0], 'E');
    case 'RUTOKEN':
    case 'JAKARTA':
    case 'JAKARTA2':
      return cert.Subject.emailAddress;
    default:
      return '';
  }
};

const getName = cert => {
  switch (cert.certType) {
    case 'CADES':
      return cert.name;
    case 'RUTOKEN':
    case 'JAKARTA':
    case 'JAKARTA2':
      return `${cert.Subject.surname} ${cert.Subject.givenName}`;
    default:
      return '';
  }
};

export default compose(
  branch(({ step }) => step === RegistrationSteps.COMPLETE, renderNothing),
  connect(state => ({
    certs: state.Registration.certificates,
  })),
  withPropsOnChange('certs', ({ certs }) => ({
    certs: certs.map(CertAdapter.getSerializedCert),
  })),
  withProps(props => {
    const { values, errors, step, openSidebar } = props;
    return {
      selectedCert: values.selectedCert,
      canNext: !errors.selectedCert,
      step: step,
      openSidebar,
    };
  }),
  withHandlers({
    onNext: ({ onNext, selectedCert }) => () =>
      onNext({
        selectedCert,
        name: getName(selectedCert),
        email: getEmail(selectedCert),
      }),
    onUpdateCert: props => cert => {
      const name = getName(cert);
      const email = getEmail(cert);
      props.mutators.updateField('name', name);
      props.mutators.updateField('email', email);
    },
  })
)(CurrentStep);
