import moment from 'moment';
import styled from 'styled-components';

const productList = (documentData) => {
  return documentData.products.map(item=>({
    price: item.price,
    cis: item.cis,
    vat: item.vat_tax,
    documentList: item.documentList ? item.documentList.map(el => ({
      ...el,
      docDate: moment(el.docDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    })) : []
  }))
}

export const senderDocumentFormat = (documentData) => {
  return {
    productGroup : documentData.commodityGroup,
    documentNumber: documentData.document_number,
    documentDate: moment(documentData.dateOfOriginalDocument,'DD.MM.YYYY').format('YYYY-MM-DD'),
    transferDate: moment(documentData.actualShipmentDate,'DD.MM.YYYY').format('YYYY-MM-DD'),
    receiverCountryCode: documentData.sender_country,
    receiverCode: documentData.recipient_inn,
    receiverName: documentData.recipient_name,
    exporterCode: documentData.sender_inn,
    exporterName: documentData.sender_name,
    productList: productList(documentData),
  }
}

export const DividerOfContent = styled.div`
    height: 1px;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -32px;
    background: #f2f2f2;
    margin-bottom: 10px;
`;

export const Plug = styled.div`
    height: 48px;
    margin-bottom: 16px;
`;
