import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { MuiThemeProvider } from '@material-ui/core';
import { toast } from 'react-toastify';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import {
  cisKey,
  goodsKey,
  steps,
  tabsKZ,
  tabsKG,
  contentBodyConfirm,
  importThirdKG, importTransitFinishStatuses,
} from '../EaesImportDocument.constants';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import * as selectors from '../ducks/EaesImportDocument.selectors';
import {
  PageWrapper,
  Content,
  DividerOfContent,
  InfoFields,
  InfoWrap,
  StepTitle,
  StepWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import { TitleAndWarning } from './TitleAndWarning';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { Toast } from 'src/utils/Toast';
import LightTheme from 'src/common_components/_MaterialUi/LightTheme';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { CommonFields } from './CommonFields';
import { InformationKZFields } from './InformationKZFields';
import { InformationKGFields } from './InformationKGFields';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { Translate } from 'src/common_components/Translate/Translate';
import ChangedStatus from './ChangedStatus';

class Information extends React.Component {
  static propTypes = {
    inn: PropTypes.string.isRequired,
    history: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    correctData: PropTypes.shape({}).isRequired,
    typeDoc: PropTypes.string.isRequired,
    isDraft: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isFirstTime: true,
    };
  }

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    const data = { ...formValues };
    if (!formValues.isHasPaperDocument) {
      delete data.paper_document_date;
      delete data.paper_document_number;
    }
    onSaveDraft(data);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  errorsCount = () => {
    const { formErrors } = this.props;
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    delete prepared['excise_tax_document_date'];
    delete prepared['excise_tax_document_number'];
    delete prepared['excise_tax_sum'];

    return Object.keys(prepared).length;
  };

  isNextStepDisabled = () => {
    const { show } = this.props;
    if (!show) return true;
    return this.errorsCount() > 0;
  };

  componentDidMount() {
    const { inn, formMutators, user } = this.props;

    const companyName = get(user, 'company.shortName', '-');
    formMutators.updateField('applicant_inn', inn);
    formMutators.updateField('applicant_name', companyName);
  }

  tabsCurrent = () => {
    const { show, countryCode, formValues } = this.props;
    const tabs = countryCode === 'KZ' ? tabsKZ : tabsKG;
    if (!show) return tabs;
    const isNextStepDisabled = this.isNextStepDisabled();
    const { onSetStep } = this.props;
    return tabs
      .map(tab =>
        tab.id === steps.info
          ? tab
          : {
              ...tab,
              onClick: () => {
                onSetStep(tab.id);
              },
            },
      )
      .map(tab =>
        tab.id === steps.info || (tab.id === steps.goods && !isNextStepDisabled) || importTransitFinishStatuses.includes(formValues.status)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  onCountrySelect = () => {
    const { formMutators, formValues } = this.props;
    setTimeout(() => {
      formMutators.updateField('sender_inn', formValues.sender_inn);
    }, 100);
  };

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  addDocument = () => {
    const { formMutators } = this.props;
    formMutators.push('documents', {});
  };

  render() {
    const { isModalOpen } = this.state;
    const { show, countryCode, formValues, formMutators, typeDoc, isDraft, isCorrect } = this.props;
    const errorsCount = this.errorsCount();

    if (
      typeDoc === importThirdKG &&
      formValues['accompanying_document_name'] !==
        'Уведомление о ввозе товаров (третьи страны)'
    )
      formMutators.updateField(
        'accompanying_document_name',
        'Уведомление о ввозе товаров (третьи страны)',
      );

    return (
      <PageWrapper hide={!show}>
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.info}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <Content>
            {/*<CancelDocumentModal*/}
            {/*  isModalOpen={isModalOpen}*/}
            {/*  onClose={this.toggleDialog}*/}
            {/*  onSubmit={this.onCancel}*/}
            {/*/>*/}
            <ConfirmModal
              isModalOpen={isModalOpen}
              onClose={this.toggleDialog}
              onSubmit={this.onCancel}
              content={contentBodyConfirm}
              header={Translate('Предупреждение')}
              cancelButtonText={Translate('Нет')}
              submitButtonText={Translate('Да')}
              notCancelable
            />
            <TitleAndWarning
              showLeft={false}
              typeDoc={typeDoc}
              left={
                errorsCount > 0
                  ? Math.ceil(Math.round((errorsCount / 7) * 100) / 5) * 5
                  : 0
              }
            />
            <DividerOfContent />
            <StepTitle>{Translate('Общие данные')}</StepTitle>
            <InfoWrap noMargin>
              <MuiThemeProvider
                theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
              >
                <InfoFields>
                  {countryCode === 'KZ' && (
                    <ChangedStatus formValues={formValues} />
                  )}
                  <CommonFields
                    formValues={formValues}
                    onCountrySelect={this.onCountrySelect}
                  />
                  {countryCode === 'KZ' && (
                    <InformationKZFields
                      formValues={formValues}
                      addDocument={this.addDocument}
                    />
                  )}
                </InfoFields>
              </MuiThemeProvider>
              <div style={{ minWidth: '400px' }} />
            </InfoWrap>
            <DividerOfContent />
            <CommandBar
              left={isDraft || isCorrect || formValues.status === null ? [
                <CommonActionButton disabled={false} onClick={this.toggleDialog}>
                  {Translate('Отменить')}
                </CommonActionButton>,
              ]: []}
              right={[
                ...(isDraft || formValues.status === null) ? [
                <CommonActionButton
                  onClick={this.onSaveDraft}
                  disabled={false}
                  //disabled={this.isNextStepDisabled}
                >
                  {Translate('Сохранить как черновик')}
                </CommonActionButton>] : [],
                <PrimaryActionButton
                  disabled={this.isNextStepDisabled}
                  onClick={this.onNextStep}
                >
                  {Translate('Следующий шаг')}
                </PrimaryActionButton>,
              ]}
            />
          </Content>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  inn: authSelectors.userInn(state),
  user: authSelectors.userInfo(state),
  data: selectors.data(state),
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState, null), withRouter)(Information);
