import styled from 'styled-components';
import { get } from 'lodash';


// TODO: New part of theme
export const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: ${props => get(props, 'theme.actionBar.bar.bottom', '0')};
  margin-right: ${props => get(props, 'theme.actionBar.bar.marginRight')};
  margin-left: ${props => get(props, 'theme.actionBar.bar.marginLeft')};
`;

export const Group = styled.div`
  display: inline-flex;
`;

export const ActionWrapper = styled.div`
  margin-right: ${props => get(props, 'theme.actionBar.action.marginRight')};
  margin-left: ${props => get(props, 'theme.actionBar.action.marginLeft')};
`;
