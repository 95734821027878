import React from 'react';
import PropTypes from 'prop-types';
import Balance from '../../../../common_components/Balance';
import { Button } from '@crpt-ui/core';
import { PrimaryButtonWrap } from '../../../Buttons/Button.styled';
import { useTranslation } from 'react-i18next';

export const UserBalanceView = ({ accounts }) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Balance style={{ width: 320 }} accounts={accounts}>
        {accounts.length ? (
          <PrimaryButtonWrap>
            <Button
              style={{
                boxSizing: 'border-box',
              }}
              fullWidth
              // component="a"
              href={window.env.REACT_APP_ELK_URL + '/accounts'}
            >
              {t("Управлять счетами")}
            </Button>
          </PrimaryButtonWrap>
        ) : null}
      </Balance>
    </React.Fragment>
  );
};

UserBalanceView.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    contractId: PropTypes.number.isRequired,
    productGroupId: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired
  })).isRequired,
};

