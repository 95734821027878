export const taxationTypes = {
  COMMON: 'COMMON',
  SIMPLE_INCOME: 'SIMPLE_INCOME',
  SIMPLE_INCOME_MINUS: 'SIMPLE_INCOME_MINUS',
  UNIFIED_ON_IMPUTED_INCOME: 'UNIFIED_ON_IMPUTED_INCOME',
  UNIFIED_AGRICULTURAL: 'UNIFIED_AGRICULTURAL',
  PATENT_SYSTEM: 'PATENT_SYSTEM'
};

export const taxationFullNames = {
  COMMON: 'Общая',
  SIMPLE_INCOME: 'Упрощенная доход',
  SIMPLE_INCOME_MINUS: 'Упрощенная доход минус расход',
  UNIFIED_ON_IMPUTED_INCOME: 'Единый налог на вмененный доход',
  UNIFIED_AGRICULTURAL: 'Единый сельскохозяйственный налог',
  PATENT_SYSTEM: 'Патентная система налогообложения'
};

export const taxationShortNames = {
  COMMON: 'ОСН',
  SIMPLE_INCOME: 'УСН доход',
  SIMPLE_INCOME_MINUS: 'УСН доход - расход',
  UNIFIED_ON_IMPUTED_INCOME: 'ЕНВД',
  UNIFIED_AGRICULTURAL: 'ЕСН',
  PATENT_SYSTEM: 'Патент'
};