export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Показатель',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 584,
        }   
      ],
      data: [
        {
          'col1': 'Дата',
          'col2': 'Масштаб графика зависит от выбранного варианта отображения периода'
        },
        {
          'col1': 'Табачная продукция',
          'col2': 'Количество зарегистрированных участников за день/неделю/месяц, в зависимости от отображаемого периода'
        },
      ]
    }
  ]
}