import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../../Fields/_refactor/FieldsWrapper';
import { Button as CoreButton } from '@crpt-ui/core';

const Button = props => {
  const {
    name,
    format,
    validate,
    parse,
    visibility,
    disabled,
    ...passProps
  } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <CoreButton name={name} disabled={disabled} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

Button.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};

export default Button;
