export const okvedValues = [
  { id: 1, title: '12.00 Производство табачных изделий' },
  {
    id: 2,
    title:
      '12.00.1 Производство изделий из табака и махорки: '
      + 'сигарет, папирос, сигар, сигарилл, курительного тонкорезаного табака, '
      + 'трубочного табака, жевательного табака, сосательного табака, нюхательного '
      + 'табака, табака для кальяна, курительной и нюхательной махорки',
  },
  {
    id: 3,
    title:
      '12.00.2 Производство гомогенизированного или восстановленного табака',
  },
  {
    id: 4,
    title:
      '12.00.3 Стрипсование (удаление главной жилки) и редраинг-обработка табака',
  },
];
