import { values } from 'lodash';

const getMiddlewares = (order, middlewares) => {
  const allMiddlewares = {};

  order.forEach(project => {
    const obj = middlewares.find(m => m.id === project).data;

    obj.keys().forEach(f => {
      const middleware = obj(f).default;
      if (middleware) allMiddlewares[f] = middleware;
    });
  });

  return values(allMiddlewares);
};

export default getMiddlewares;
