import React from 'react';
import PropTypes from 'prop-types';
import { Controls, Header, HeaderText, Wrapper } from './Filters.styled';
import CloseIcon from './CloseIcon';
import Form from '../../Form/Form';
import SubmitButton from '../../Form/Fields/Button/SubmitButton';
import ClearButton from '../../Form/Fields/Button/ClearButton';
import { Translate } from '../../Translate/Translate';

const FiltersView = ({ data, onClose, filters, onSubmit, onClear }) => (
  <Wrapper>
    <Header>
      <HeaderText>{Translate('Фильтр')}</HeaderText>
      <CloseIcon onClick={onClose} />
    </Header>

    <Form data={data} onSubmit={onSubmit}>
      {filters}

      <Controls>
        <ClearButton
          onClick={() => {
            onSubmit();
            onClear && onClear();
          }}
        >
          {Translate('Сбросить')}
        </ClearButton>
        <SubmitButton onClick={onClose}>{Translate('Применить')}</SubmitButton>
      </Controls>
    </Form>
  </Wrapper>
);

FiltersView.propTypes = {
  data: PropTypes.shape({}),
  filters: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FiltersView.defaultProps = {
  data: {},
};

export default FiltersView;
