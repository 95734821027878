import React from 'react';
import moment from 'moment';
import { get } from 'lodash';

import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import { RangePicker } from '@crpt/react-datepicker';
import {
  DocumentStatusSelect,
  DocumentTypeSelect,
  ParticipantSelect,
} from '../../../components/Select';
import { addSpacesBetweenThousands } from '../../../utils';
import DocumentType from '../../../components/TableCells/Document/DocumentType';
import DocumentId from '../../../components/TableCells/Document/DocumentId';
import ProducerName from '../../../components/TableCells/Document/ProducerName';
import { TableCell } from '../../../components/common/Table/TableCellTooltip';
import DownloadReport, {
  TableCellWrapper,
} from '../../../components/TableCells/Document/DownloadReport';
import { defaultDatepickerTheme } from '../../../theme/ru-tabaco/datepicker';
import { Translate } from '../../../common_components/Translate/Translate';

export default {
  actionNamespace: 'DOCUMENT_LIST',
  selectorNamespace: 'DocumentList',
  limit: 10,
  options: [
    {
      accessor: d => d,
      id: 'type',

      render: props => {
        return <DocumentType {...props} />;
      },
    },
    {
      accessor: d => d,
      id: 'id',

      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <DocumentId {...otherProps} />
          </TableCell>
        );
      },
    },
    {
      accessor: d => d,
      id: 'producerName',

      render: props => {
        const {
          onMouseEvent,
          hoveredId,
          onDownloadClick,
          ...otherProps
        } = props;
        const id = get(otherProps, 'options.producerName._original.id');

        return (
          <TableCellWrapper onMouseEvent={onMouseEvent}>
            <TableCell>
              <ProducerName {...otherProps} />
            </TableCell>
            <DownloadReport
              hoveredId={hoveredId}
              id={id}
              onDownloadClick={onDownloadClick}
            />
          </TableCellWrapper>
        );
      },
    },
  ],
  columns: [
    {
      Header: Translate('Дата'),
      id: 'docDate',

      accessor: d => `${moment(new Date(d.docDate)).format('DD.MM.YYYY')}`,
      width: 105,
    },
    {
      Header: Translate('Тип документа'),
      id: 'type',
      accessor: 'type',
      width: 300,
    },
    {
      Header: Translate('Номер документа участника'),
      id: 'id',
      accessor: 'id',
      width: 271,
    },
    {
      Header: Translate('Сумма'),
      id: 'total',
      accessor: d => addSpacesBetweenThousands(d.total.toFixed(2) / 100),
      width: 129,
    },
    {
      Header: Translate('Отправитель'),
      id: 'producerName',
      accessor: 'producerName',
      minWidth: 300,
    },
  ],
  beforeTable: {
    showGraph: false,
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: false,
    },
    [
      {
        component: Input,
        props: {
          name: 'search',
          placeholder: 'Номер',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [
      {
        component: RangePicker,
        props: {
          inline: false,
          name: 'date',
          placeholder: 'Дата',
          format: 'DD.MM.YYYY',
          positionX: 'left',
          controls: true,
          acceptText: 'Применить',
          resetText: 'Сбросить',
          theme: { DatePicker: defaultDatepickerTheme },
        },
      },
    ],
    [
      {
        component: DocumentStatusSelect,
        props: {
          inline: false,
          name: 'documentStatus',
          placeholder: 'Статус',
        },
      },
    ],
    [
      {
        component: DocumentTypeSelect,
        props: {
          inline: false,
          name: 'documentType',
          placeholder: 'Тип документа',
        },
      },
    ],
    [
      {
        component: ParticipantSelect,
        props: {
          inline: false,
          valueKey: 'id',
          name: 'participantId',
          placeholder: 'Отправитель',
        },
      },
    ],

  ],
};
