import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import Sidebar from '../../components/Sidebar';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';
import * as selectors from '../../selectors/index';

class SidebarBound extends Component {
  render() {
    return (
      <ConnectParts
        component={Sidebar}
        actions={[actions.Sidebar]}
        selectors={[selectors.Sidebar]}
        {...this.props}
      />
    );
  }
}

export default withRouter(SidebarBound);
