import { Translate } from '../../../common_components/Translate/Translate';
import { Button } from '@crpt/react-button';
import Modal from 'react-modal';
import React from 'react';
import styled from 'styled-components';
import DatePicker from '../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { requiredWithOutputErrorMessage } from '../../../common_components/Form/utils/validations';
import Input from '../../../common_components/Form/MaterialFields/Input/Input';
import Select from '../../../common_components/Form/MaterialFields/Select/Select';
import Form from '../../../common_components/Form/Form';
import moment from 'moment';
import { Toast } from '../../../utils/Toast';

const ModalHeading = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
`;

const ModalContent = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  max-width: 460px;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: '10'
  },
  overflow: 'visible',
};


const ChangeRateModal = (props) => {
  const { onClose, onSubmit, rateType, gtin } = props;

  const handleSubmit = (values) => {
    if (values.rateUpdatedText) {
      if (values.rateUpdatedText.length > 250) {
        Toast.showError({ content: "Не более 250 символов" });
        return;
      }
    }
    onSubmit({
      ...values,
      gtin,
      rateType: values.rateType === "1" ? "Полный тариф" : "Нулевая тарифная ставка",
    })
  }

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={Translate('Изменение тарифа')}
    >
      <Form
        data={{
          rateType: rateType.toString(),
          rateStartDate: moment(new Date()).format('DD.MM.YYYY')
        }}
        onSubmit={handleSubmit}
      >
        <ModalHeading>{Translate('Изменение тарифа')}</ModalHeading>
        <ModalContent>
          <Select
            placeholder={Translate('Тариф') }
            name="rateType"
            options={[
              {
                value: '0',
                label: Translate('Нулевая тарифная ставка'),
              },
              {
                value: '1',
                label: Translate('Полный тариф'),
              },
            ]}
            forceValidate
            required
            validate={requiredWithOutputErrorMessage}
            onSelect={() => {}}
          />
          <DatePicker
            required
            forceValidate
            name="rateStartDate"
            placeholder={Translate('Дата установки тарифа')}
            validate={requiredWithOutputErrorMessage}
            minDate={new Date()}
          />
          <Input
            forceValidate
            name="rateUpdatedText"
            placeholder={Translate('Комментарий')}
          />
        </ModalContent>
        <ButtonWrap>
          <Button
            marginRight={20}
            onClick={onClose}
          >
            {Translate('Отменить')}
          </Button>
          <Button
            type="submit"
          >
            {Translate('Сохранить')}
          </Button>
        </ButtonWrap>
      </Form>
    </Modal>
  )
}
export default ChangeRateModal;
