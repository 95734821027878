import { handleActions } from 'redux-actions';
import * as actions from './Draft.actions';

import * as importActions from '../../ImportDocument/ducks/ImportDocument.actions';

const results = handleActions(
  {
    [actions.mounted]: ({ data }) => ({ data: data || [] }),
    [actions.loaded]: (state, { payload = {} }) => ({
      data: payload || [],
    }),
  },
  { data: [] },
);

const bodyDraft = handleActions(
  {
    [actions.loadBodyDraft]: (state, { payload = {} }) => payload || {},
  },
  {},
);

const total = handleActions(
  {
    [actions.mounted]: () => 0,
    [actions.loaded]: (state, { payload = {} }) => payload.total || 0,
  },
  0,
);

const selectedPage = handleActions(
  {
    [actions.updatePage]: (state, { payload }) => payload,
    [actions.unmounted]: () => 1,
  },
  1,
);

const filters = handleActions(
  {
    [actions.filters]: (state, { payload = {} }) => payload,
  },
  {},
);

const sorting = handleActions(
  {
    [actions.setSorting]: (state, { payload }) => payload || {},
  },
  {},
);

export default {
  results,
  total,
  selectedPage,
  filters,
  sorting,
  bodyDraft,
};
