import {actionTypes} from '../../constants/';

export function certificateSelectDisabled(state = true, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM_KEP.CERTIFICATES_LOADED:
      return false;

    default:
      return state;
  }
}
