import { css } from 'styled-components';

const TableRowGroup = {
  style: css`
    display: flex;
    align-items: stretch;
    flex: 0 auto;
    flex-direction: column;
  `,
};

export default TableRowGroup;
