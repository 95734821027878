import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';
import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import Sidebar from '@crpt/react-sidebar';
import FilterSidebar from './FilterSidebar';
import {Translate} from '../../../../../common_components/Translate/Translate';

class ActionBar extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    type: PropTypes.string,
    onFilterApply: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: '',
  };

  state = {
    sidebarActive: false,
  };

  onSidebarOpenClick = () => {
    this.setState({ sidebarActive: true });
  };

  onSidebarCloseClick = () => {
    this.setState({ sidebarActive: false });
  };

  onFilterApply = (value) => {
    const { onFilterApply } = this.props;

    onFilterApply(value);
  }

  render() {
    const {
      filters, type,
    } = this.props;

    const filterFields = filters.slice(1);

    const buttonTheme = { Button: get(this.props, 'theme.tab.item') };
    const { sidebarActive } = this.state;

    return (
      <div>
        <Button theme={buttonTheme} onClick={this.onSidebarOpenClick}>
          <Icon type="black-filter" spacingRight={16} />
          {Translate('Фильтр')}
        </Button>

        <Sidebar active={sidebarActive} onClose={this.onSidebarCloseClick}>
          <FilterSidebar
            type={type}
            onFilterApply={this.onFilterApply}
            onFilterClose={this.onSidebarCloseClick}
            filterFields={filterFields}
          />
        </Sidebar>
      </div>
    );
  }
}

export default withTheme(ActionBar);
