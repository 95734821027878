import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import Datatable from '@crpt-ui/datatable';
import {
  cisKey,
  steps,
  tabsKZ,
  tabsKG,
  importThirdKG,
} from '../EaesImportDocument.constants';
import {
  PageWrapper,
  Divider,
  FixedWrap,
  GoodsWrap,
  GoodsIconWrap,
  EmptyGoodsTextWrap,
  StepWrapper,
  TableWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from '../../../../../common_components/styled/ContentWrapper';
import CommandBar from '../../../../../common_components/CommandBar/CommandBar';
import CommonActionButton from '../../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from '../../../../../common_components/Buttons/MuiPrimaryActionLargeButton';

import { columns, columnsThirdCountryKG } from './Cis.constants';
import { withModal } from '../../../../../common_components/Modals/hocs/withModal';
import CisIcon from './svg/CisIcon.svg';

import { Pagination } from '@crpt-ui/core';

import SignModalContent from './SignModalContent';
import List from '../../../../../common_components/List/List';
import { Translate } from 'src/common_components/Translate/Translate';

class Cis extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    typeDoc: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      commandBarLeft: [
        <FixedWrap width={'290px'} stretch>
          <CommonActionButton onClick={this.onPreviousStep}>
            {Translate('Предыдущий шаг')}
          </CommonActionButton>
        </FixedWrap>,
        <FixedWrap width={'310px'} stretch>
          <CommonActionButton
            onClick={this.onSaveDraft}
            //disabled={this.isNextStepDisabled}
          >
            {Translate('Сохранить как черновик')}
          </CommonActionButton>
        </FixedWrap>,
      ],

      commandBarRight: [
        <FixedWrap width={'290px'} stretch>
          <PrimaryActionLargeButton
            disabled={this.isNextStepDisabled}
            onClick={this.onNextStep}
          >
            {Translate('Следующий шаг')}
          </PrimaryActionLargeButton>
        </FixedWrap>,
      ],
      activePage: 1,
      rowsPerPage: 5,
    };
  }

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
    } = this.props;

    onSetContent(
      <SignModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        values={formValues}
      />,
    );
    onOpenModal();
  };

  onPreviousStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  isNextStepDisabled = () => {
    const { show, formErrors } = this.props;
    if (!show) return true;
    const cis = this.getCis();
    return isEmpty(cis);
  };

  tabsCurrent = () => {
    const { show } = this.props;
    if (!show) return tabsKG;
    const { onSetStep } = this.props;
    return tabsKG.map(tab =>
      tab.id === steps.cis
        ? tab
        : {
            ...tab,
            onClick: () => {
              onSetStep(tab.id);
            },
          },
    );
  };

  getCis = () => {
    const { formValues } = this.props;
    const cis = formValues[cisKey] || [];
    return cis;
  };

  preparedCis = cis => {
    const { activePage, rowsPerPage } = this.state;
    const page = activePage - 1;

    return cis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  render() {
    const {
      commandBarLeft,
      commandBarRight,
      activePage,
      rowsPerPage,
    } = this.state;
    const { show } = this.props;

    const cis = this.getCis();
    const preparedCis = this.preparedCis(cis);
    const pagesCount = Math.ceil(cis.length / rowsPerPage);

    return (
      <PageWrapper hide={!show}>
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.cis}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          {isEmpty(cis) ? (
            <GoodsWrap>
              <GoodsIconWrap>
                <CisIcon />
              </GoodsIconWrap>

              <EmptyGoodsTextWrap>
                {Translate('Нет добавленных кодов')}
              </EmptyGoodsTextWrap>
            </GoodsWrap>
          ) : this.props.typeDoc === importThirdKG ? (
            <TableWrapper>
              <List
                defaultPageSize={10}
                data={cis}
                columns={columnsThirdCountryKG}
                showPagination={true}
              />
            </TableWrapper>
          ) : (
            <React.Fragment>
              <Datatable columns={columns} data={preparedCis} />
              <Pagination
                page={activePage}
                pagesCount={pagesCount}
                onPageChange={this.handlePageChange}
              />
            </React.Fragment>
          )}

          <Divider />

          <CommandBar
            left={commandBarLeft}
            style={{
              padding: '20px 40px',
            }}
            right={commandBarRight}
          />
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

export default compose(withRouter, withModal)(Cis);
