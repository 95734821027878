import { Translate } from '../../common_components/Translate/Translate';

export const tabs = [
  { id: 0, label: Translate('Общая информация'), to: '' },
  { id: 1, label: Translate('Товары'), to: 'goods' },
  { id: 2, label: Translate('Коды'), to: 'codes' },
  { id: 3, label: Translate('Итоги'), to: 'total' },
  { id: 4, label: Translate('Ошибки'), to: 'errors' }
];

export const esfTabs = [
  { id: 0, label: Translate('Общая информация'), to: '' },
  { id: 2, label: Translate('Коды'), to: 'codes' },
  { id: 1, label: Translate('Товары'), to: 'goods' },
  { id: 3, label: Translate('Ошибки обработки'), to: 'errors' },
];
