import React, { useState } from 'react';
import { Button, Icon } from '@crpt-ui/core';
import { HeaderWrap } from './header.styled';
import { CancelDialog } from '../cancel-dialog';
import { history } from '../../../../store';
import { useTranslation } from 'react-i18next';

export const Header = ({ isCreation, onSubmit, idDisableSubmit }) => {
  const [openCancel, setOpenCancel] = useState(false);
  const { t } = useTranslation();

  const handleCLose = () => {
    setOpenCancel(false);
    history.push('/mobile-content/articles')
  }

  return (
    <HeaderWrap>
      <CancelDialog
        isOpen={openCancel}
        handleCLose={handleCLose}
        handleCancel={() => setOpenCancel(false)}
        isCreation={isCreation}
      />

      <div>
        <h2>{t(isCreation ? 'Создание статьи' : 'Обновление статьи')}</h2>
      </div>
      <div>
        <Button
          type="button"
          variant="text"
          size="md"
          onClick={() => setOpenCancel(true)}
        >
          {t('Отмена')}
        </Button>
        <Button
          type="button"
          variant="contained"
          size="md"
          icon={<Icon name='send' />}
          onClick={onSubmit}
          className={"whiteColor"}
          disabled={idDisableSubmit}
        >
          {isCreation ? t('Опубликовать') : t('Сохранить')}
        </Button>
      </div>
    </HeaderWrap>
  )
}
