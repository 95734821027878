import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from './ReportList.selectors';
import * as actions from './ReportList.actions';
import { ReportListContentWrapper } from './ReportList.styled';
import ReportTile from '../ReportTile/ReportTile';
import Report from '../Report/Report';
import { Tabs } from '@crpt/react-tabs';
import { compose } from 'redux';
import { tabsReportsThemeKZ, tabsReportsThemeUZ, tabsReportsThemeKG} from '../../../theme/kz-tobaco/tabs';

class ReportList extends React.Component {
  static propTypes = {
    tab: PropTypes.object.isRequired,
    reports: PropTypes.array.isRequired,
    tabs: PropTypes.array.isRequired,
    onTabSelect: PropTypes.func.isRequired,
  };

  getBreadcrumbs(item) {
    const { tab } = this.props;

    return item
      ? [
          {
            href: `/reports/${tab.url}`,
            title: tab.title,
            id: 1,
          },
          {
            href: `/reports/${tab.url}/${item.id}`,
            title: <span style={{ fontWeight: 600 }}>{item.name}</span>,
            id: 2,
          },
        ]
      : [];
  }

  render() {
    const { tab, reports, tabs, onTabSelect, countryCode } = this.props;
    const reportList = reports.filter(r => r.category === tab.url);

    if (
      location.pathname === `/reports/${tab.url}` ||
      location.pathname === `/reports/${tab.url}/`
    ) {
      return (
        <div>
          <Tabs
            fillWidth
            tabs={tabs}
            selectedId={tab.id}
            onSelect={onTabSelect}
            theme={{ Tabs: countryCode === 'KZ' ? tabsReportsThemeKZ : countryCode === 'UZ' ? tabsReportsThemeUZ : tabsReportsThemeKG }}
          />
          <ReportListContentWrapper>
            {reportList.map((v, i) => (
              <ReportTile
                key={i}
                namespace={`/reports/${tab.url}`}
                report={v}
              />
            ))}
          </ReportListContentWrapper>
        </div>
      );
    }

    const itemUrl = location.pathname
      .split('/')
      .slice(-1)
      .pop();
    const item = reports.find(v => v.id === itemUrl);
    const breadcrumbs = this.getBreadcrumbs(item);

    return <Report item={item} breadcrumbs={breadcrumbs} />;
  }
}
const mapStateToProps = state => ({
  countryCode: state.config.countryCode,
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(ReportList);
