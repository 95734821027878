import { actionTypes, strings } from '../../constants';
import {RegistrationSteps} from '../../components/Registration/constants';

export const step = (state = RegistrationSteps.CERTIFICATE, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.REGISTER_SUCCESSFULL:
      return RegistrationSteps.COMPLETE;
    default:
      return state;
  }
};
