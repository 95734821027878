import * as _ from 'lodash';
import React, { useRef } from 'react';
import { parseFile, readFile, rowHandlerDefault } from './ReadParseFile.utils';

const ReadParseFile = ({ children, onError, onSuccess, rowHandler }) => {
  const inputRef = useRef();

  const onUploadClick = () => {
    if (inputRef) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const onFileInputChange = async event => {
    event.preventDefault();

    const file = _.get(event, 'target.files.0');
    if (file) {
      const { success, error } = await readFile(file);
      if (success) {
        const result = parseFile(success, rowHandler);
        if ( result instanceof Error) {
          onError(result);
        } else {
          onSuccess(result);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {children({ onUploadClick })}
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".xlsx, .csv, .xls"
        onChange={onFileInputChange}
      />
    </React.Fragment>
  );
};

ReadParseFile.defaultProps = {
  onError: () => {},
  onSuccess: () => {},
  rowHandler: rowHandlerDefault,
};

export default ReadParseFile;
