import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import lodash from 'lodash';
import Modal from 'react-modal';

import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import AttributeField from './AttributeField';
import { PARTICIPANT_TYPES, productGroupNames } from 'src/constants';
import { buttonRedTheme } from 'src/theme/ru-tabaco/button';
import { packTrivialTypes, EAESPackageTypes } from 'src/constants/packTypes';
import { Translate } from '../../common_components/Translate/Translate';
import ChangeRateModal from './changeRateModal/changeRateModal';
import { Toast } from '../../utils/Toast';
import TariffRateChangesHistory from './TariffRateChangesHistory/TariffRateChangesHistory';

Modal.setAppElement('body');

moment.locale('ru');

const propTypes = {};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  overflow: 'visible',
};

const CardHeading = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #212c42;
  margin-bottom: 40px;
`;

const CardHeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CISInformationWrap = styled.div`
  display: flex;
`;

const QRCodeWrap = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
`;

const Image = styled.img`
  width: 128px;
  min-width: 128px;
  height: 128px;
  display: block;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const ModalHeading = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
`;

const ModalContent = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  max-width: 460px;
`;

const VerticalLayer = styled.div`
  width: 160px;
  min-width: 160px;
`;

class BasicInfo extends Component {
  //TODO: вынести в конфигурацию
  allowedToEditTypes = [PARTICIPANT_TYPES.PRODUCER, PARTICIPANT_TYPES.IMPORTER];

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      changeRateModal: false,
      rateHistoryModal: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  openModal() {
    this.setState({
      isModalOpen: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedProduct } = this.props;
    const rateTypeChanged = lodash.get(selectedProduct, 'rateTypeChanged', false);
    if (rateTypeChanged && rateTypeChanged !== prevProps.selectedProduct.rateTypeChanged) {
      this.setState({ changeRateModal : false }, this.props.actions.ProductDetails.rateTypeChangedFalse)
      Toast.showSuccess({ content: "Тарифная ставка добавлена" });
    }
  }

  handleChangeRateModal = () => {
    const { changeRateModal } = this.state;
    this.setState({ changeRateModal : !changeRateModal })
  }

  handleChangeRateHistoryModal = () => {
    const { rateHistoryModal } = this.state;
    this.setState({ rateHistoryModal : !rateHistoryModal })
  }

  render() {
    const { selectedProduct, id, isEAESCountry, roles, countryCode } = this.props;
    const { changeRateModal, rateHistoryModal } = this.state;

    const brand = lodash.get(selectedProduct, 'brand', false);
    const name = lodash.get(selectedProduct, 'name', false);
    const shortName = lodash.get(selectedProduct, 'shortName', false);
    const gtin = lodash.get(selectedProduct, 'gtin', false);

    const mrp = lodash.get(selectedProduct, 'mrp', false);
    const image = lodash.get(selectedProduct, 'goodImage', false);
    const feacn = lodash.get(selectedProduct, 'feacn', false);
    const okpd2 = lodash.get(selectedProduct, 'okpd2', false);
    const packageType = lodash.get(selectedProduct, 'packageType', false);
    const producerName = lodash.get(selectedProduct, 'producerName', false);
    const rateTypeValue = lodash.get(selectedProduct, 'rateType', false);
    const rateTypeChanged = lodash.get(selectedProduct, 'rateTypeChanged', false);
    const rateChangesHistory = lodash.get(selectedProduct, 'rateChangesHistory.result.ratesHistory', null);
    const rateType = rateTypeValue !== false ? rateTypeValue ? "Полный тариф" : "Нулевая тарифная ставка" : null

    let innerUnitCount = lodash.get(selectedProduct, 'innerUnitCount', false);
    const quantityInInnerPack = lodash.get(
      selectedProduct,
      'quantityInInnerPack',
      false,
    );

    const quantityInPack = lodash.get(selectedProduct, 'quantityInPack', false);

    if (packageType === 'inner-pack') innerUnitCount = quantityInInnerPack;
    if (packageType === 'trade-unit') innerUnitCount = quantityInPack;

    const producerCountry =
      lodash.get(selectedProduct, 'producerCountry', false) ||
      lodash.get(selectedProduct, 'specificAttributes.13747', false);

    const productGroupId = +lodash.get(
      selectedProduct,
      'productGroupId',
      false,
    );
    const tnVedCode10 = lodash.get(selectedProduct, 'tnVedCode10', false);
    const packageNameForEAESCountries = lodash.get(
      selectedProduct,
      'packageName',
      false,
    );
    const packageName = isEAESCountry
      ? packageNameForEAESCountries
      : packTrivialTypes[packageType];

    const packageLine = (
      <AttributeField label={Translate('Упаковка')} value={Translate(packageName || '—')} />
    );
    const innerUnitCountLabel = (
      <AttributeField
        label={
          isEAESCountry
            ? Translate('Количество в упаковке')
            : Translate('В упаковке')
        }
        value={innerUnitCount}
      />
    );

    const EAESAttributesColumn = (
      <Column>
        <AttributeField
          label={Translate('Товарная группа')}
          value={Translate(productGroupNames[productGroupId])}
        />
        <AttributeField label={Translate('Код ТНВЭД')} value={tnVedCode10} />
        <AttributeField
          label={Translate('Тип упаковки')}
          value={Translate(EAESPackageTypes[packageType])}
        />
        {[packageLine, innerUnitCountLabel]}
      </Column>
    );

    return (
      <Card>
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel={Translate('Удаление товара')}
        >
          <div>
            <ModalHeading>{Translate('Удаление товара')}</ModalHeading>
            <ModalContent>
              {Translate('Вы действительно хотите удалить товар')} {name}?
            </ModalContent>
            <ButtonWrap>
              <Button
                marginRight={20}
                theme={{ Button: buttonRedTheme }}
                onClick={() => {
                  this.props.actions.ProductDetails.removeProductClicked(
                    id,
                    name || gtin,
                    this.props.history,
                  );
                  this.closeModal();
                }}
              >
                <Icon spacingRight={16} type={'not-allow'} />
                {Translate('Удалить')}
              </Button>
              <Button onClick={this.closeModal}>{Translate('Отмена')}</Button>
            </ButtonWrap>
          </div>
        </Modal>
        {changeRateModal && (
          <ChangeRateModal
            gtin={gtin}
            rateType={rateTypeValue}
            onClose={this.handleChangeRateModal}
            onSubmit={this.props.actions.ProductDetails.ChangeRate}
          />
        )}
        {rateHistoryModal && (
          <TariffRateChangesHistory
            gtin={gtin}
            history={rateChangesHistory}
            onClose={this.handleChangeRateHistoryModal}
            getHistory={this.props.actions.ProductDetails.getTariffRateChangesHistory}
            clearHistory={this.props.actions.ProductDetails.clearTariffRateChangesHistory}
          />
        )}
        <CardHeadingWrap>
          <CardHeading>{Translate('Информация о товаре')}</CardHeading>
          {/*{isUserAdmin && !!lodash.intersection(types, this.allowedToEditTypes).length ? (*/}
          {/*  <ButtonWrap>*/}
          {/*    <Button*/}
          {/*      marginRight={20}*/}
          {/*      onClick={e =>*/}
          {/*        this.props.history.push(`${this.props.path}/edit`)*/}
          {/*      }*/}
          {/*    >*/}
          {/*      <Icon spacingRight={16} type={'edit'} />Редактировать*/}
          {/*    </Button>*/}
          {/*    <Button*/}
          {/*      theme={{ Button: buttonRedTheme }}*/}
          {/*      onClick={this.openModal}*/}
          {/*    >*/}
          {/*      <Icon spacingRight={16} type={'not-allow'} />Удалить*/}
          {/*    </Button>*/}
          {/*  </ButtonWrap>*/}
          {/*) : null}*/}
        </CardHeadingWrap>
        <CISInformationWrap>
          {!!image ? (
            <Image style={{ backgroundImage: `url(${image})` }} />
          ) : (
            <QRCodeWrap />
          )}
          <Container fillWidth>
            <Column>
              <AttributeField label={Translate('Марка')} value={brand} />
              <AttributeField
                label={Translate('Полное наименование')}
                value={name}
              />
              {!!shortName && (
                <AttributeField
                  label={Translate('Краткое наименование')}
                  value={shortName}
                />
              )}
              <AttributeField label="GTIN" value={gtin} />
              {isEAESCountry ? (
                <AttributeField
                  label={Translate('Страна производства')}
                  value={producerCountry}
                />
              ) : (
                packageLine
              )}
              {!isEAESCountry && innerUnitCountLabel}
              {
                countryCode !== 'KZ' && (
                  <React.Fragment>
                    <AttributeField
                      label={Translate('Производитель')}
                      value={producerName}
                    />
                    <AttributeField
                      label={Translate('Тариф')}
                      value={rateType}
                      tariffRateChangesHistory={countryCode === 'KZ' ? undefined : this.handleChangeRateHistoryModal}
                      onChange={
                        ((roles.indexOf("ROLE_ORG_IS_MP_OPERATOR") === -1 && roles.indexOf("ROLE_HEAD_OPERATOR") === -1) || countryCode === 'KZ')
                          ? undefined
                          : this.handleChangeRateModal
                      }
                    />
                  </React.Fragment>
                )
              }
              {!!mrp && (
                <AttributeField label={Translate('МРЦ, Р')} value={mrp} />
              )}
              {!!feacn && (
                <AttributeField label={Translate('ТН ВЭД')} value={feacn} />
              )}
              {!!okpd2 && (
                <AttributeField label={Translate('ОКПД2')} value={okpd2} />
              )}
            </Column>
            <VerticalLayer />
            {isEAESCountry && EAESAttributesColumn}
            <Column />
          </Container>
        </CISInformationWrap>
      </Card>
    );
  }
}

BasicInfo.propTypes = propTypes;

export default withRouter(BasicInfo);
