import { saveAs } from 'file-saver/FileSaver';
import { DocumentService } from '../services/DocumentService';
import { getListActions } from '../utils/actionGenerators';
import DocumentList from '../projects/motp/lists/DocumentList';
import {
  defaultFilterParamSerializer,
  defaultRequestParamSerializer,
  fromURLMountFilterParamsSerializer,
  fromURLPageSerializer,
} from '../utils/filterSerializers';
import { getLimitOffsetFormatter } from '../utils/pageFormatters';
import {
  extendURLByKeyValueList,
  modifyURLByRawDataAndFiltersConfig,
} from '../utils/formEvents';
import { history } from '../store';
import { actionTypes, PAGINATED_LIST_CONSTANTS } from '../constants';
import { StoreHelper } from '../utils/StoreHelper';

const downloadListReport = type => (dispatch, getState) => {
  const filterParams = getState()[DocumentList.selectorNamespace].filterParams;

  DocumentService.getListReport(type, filterParams)
    .then(answer => saveAs(answer.data, `report.${type}`))
    .catch(err => console.log('download list error: ', err));
};

const downloadDocumentReport = (type, id) => dispatch => {
  DocumentService.getDocumentReport(type, id)
    .then(answer => saveAs(answer.data, `${id}.${type}`))
    .catch(err => console.log('download report error: ', err));
};

const onFilterLoad = data => {
  if (1 === data.results.length) {
    const { id } = data.results[0];
    history.push(`/documents/list/${id}/`);
    StoreHelper.getStore().dispatch({
      type:
        actionTypes[DocumentList.actionNamespace][
          PAGINATED_LIST_CONSTANTS.CLEAR_FILTER_PARAMS
        ],
    });
  }
};

export default {
  ...getListActions({
    actionNamespace: DocumentList.actionNamespace,
    selectorNamespace: DocumentList.selectorNamespace,
    getList: DocumentService.getList,
    getCancelListRequest: () => DocumentService._getListCancel,
    pageFormatter: getLimitOffsetFormatter(DocumentList.limit),
    pageSerializer: fromURLPageSerializer,
    filterApplyFilterParamsSerializer: defaultFilterParamSerializer,
    filterApplyRequestParamsSerializer: defaultRequestParamSerializer,
    listMountFilterParamsSerializer: fromURLMountFilterParamsSerializer,
    listMountRequestParamsSerializer: fromURLMountFilterParamsSerializer,
    onFilterApplyWithoutSerializing: data =>
      modifyURLByRawDataAndFiltersConfig(
        Object.assign(data, { page: [1] }),
        DocumentList.filters,
      ),
    onPageSelect: pageNumber => {
      extendURLByKeyValueList({ page: pageNumber });
    },
    onFilterLoad: onFilterLoad,
  }),
  downloadListReport,
  downloadDocumentReport,
};
