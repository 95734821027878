import moment from 'moment';
import {actionTypes} from '../../constants';


const initialState = moment().format();

export function date(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ORDER_FORM.DATE_FIELD_CHANGED:
      return action.value.date ? action.value.date.format() : null;
    case actionTypes.ORDER_FORM.CLEAR:
      return initialState;
    default:
      return state;
  }
}
