export default {
  title: 'Фильтры по умолчанию:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        },
      ],
      data: [
        {
          'col1': 'Период',
          'col2': 'Текущий месяц'
        },
        {
          'col1': 'География',
          'col2': 'Город'
        },
        {
          'col1': 'Товар',
          'col2': 'GTIN'
        },
      ]
    }
  ]
}