import { selector } from '../../../utils/selector';

export const content = state => selector(state, 'FilePreview.content');
export const file = state => selector(state, 'FilePreview.file');
export const data = state => selector(state, 'FilePreview.data');
export const docNumber = state => selector(state, 'FilePreview.number');
export const status = state => selector(state, 'FilePreview.status');
export const contentType = state => selector(state, 'FilePreview.contentType');
export const gotErrors = state => selector(state, 'FilePreview.gotErrors');
export const getOnAccept = state => selector(state, 'FilePreview.onAccept');
export const getOnCancel = state => selector(state, 'FilePreview.onCancel');
export const types = state => selector(state, 'FilePreview.types');
export const openFileDialog = state => selector(state, 'FilePreview.openFileDialog');
export const loaded = state => selector(state, 'FilePreview.loaded');
export const warning = state => selector(state, 'FilePreview.warning');
export const docType = state => selector(state, 'FilePreview.docType');
