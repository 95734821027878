import { actionTypes } from '../../constants/';
import { translate as defaultTranslate } from '../../translate';
import { innerMerge } from '../../utils';

export function translate(state = defaultTranslate.json, action = {}) {
  switch (action.type) {
    case actionTypes.APP.CONFIG_UPDATE: {
      const tran =
        action.value.config.translate &&
        action.value.config.translate[action.value.language]
          ? innerMerge(
              state,
              action.value.config.translate[action.value.language].json,
            )
          : state;
      return tran;
    }

    default:
      return state;
  }
}
