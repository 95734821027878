import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get, isFunction } from 'lodash';
import * as fileActions from '../File/File.actions';

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0, 0, 0, 0);
`;


class FileField extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    fileRead: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    onError: PropTypes.func,
    accept: PropTypes.string.isRequired,
    setRef: PropTypes.func,
    asText: PropTypes.bool,
    raw: PropTypes.bool,
  };

  static defaultProps = {
    children: undefined,
    setRef: undefined,
    asText: false,
    raw: false,
    onError: () => null,
  };

  fileInputRef = null;

  constructor(props) {
    super(props);

    if (props.setRef) {
      props.setRef(this);
    }
  }

  state = {
    loading: false,
  };

  onClick = () => {
    this.fileInputRef.value = '';
    return this.fileInputRef.click();
  }

  onLoad = (file) => {
    const { onLoad } = this.props;
    this.setState({ loading: false });
    onLoad(file);
  };

  onError = (error) => {
    this.setState({ loading: false });
    this.props.onError(error);
  };

  onFileInputChange = (e) => {
    const { fileRead, asText, raw } = this.props;

    e.preventDefault();
    this.setState({ loading: true });

    const file = get(e, 'target.files.0');
    fileRead(file, { subject: 'hello', success: this.onLoad, error: this.onError, asText, raw });
  };

  renderChildren = () => {
    const { children } = this.props;
    const { loading } = this.state;

    if (!children) return (null);

    if (isFunction(children)) {
      return children({ loading, onClick: this.onClick });
    }

    return React.cloneElement(children, {
      loading,
      onClick: this.onClick,
    });
  };

  render() {
    const { accept } = this.props;

    return (
      <React.Fragment>
        {this.renderChildren()}
        <FileInput
          innerRef={(ref) => { this.fileInputRef = ref; }}
          type="file"
          accept={accept}
          onChange={this.onFileInputChange}
        />
      </React.Fragment>

    );
  }
}

export default connect(null, { fileRead: fileActions.read })(FileField);
