import styled from 'styled-components';

const PointerUpOverlap = styled.div`
  width: 28px;
  height: 13px;
  background: #ffffff;
  position: absolute;
  top: 0px;
  left: calc(${props => props.position}px - 14px);
`;

export default PointerUpOverlap;
