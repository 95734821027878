import { keyBy, transform, isObject, isArray } from 'lodash';

import withMountCycle from './withMountCycle';
import withItemsArray from './withItemsArray';
import withRenderNothingNot from './withRenderNothingNot';
import withRenderOkvedOrNothing from './withRenderOkvedOrNothing';


export {
  withMountCycle,
  withItemsArray,
  withRenderNothingNot,
  withRenderOkvedOrNothing
};

export const deepOmit = (object, keysToOmit) => {
  const keysToOmitIndex = keyBy(isArray(keysToOmit) ? keysToOmit : [keysToOmit]);

  const omitFromObject = obj => transform(obj, (result, value, key) => {
    if (key in keysToOmitIndex) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    result[key] = isObject(value) ? omitFromObject(value) : value;
  });

  return omitFromObject(object);
};
