import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get, isString } from 'lodash';
import { Icon } from '@crpt/react-icon';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import { Wrapper, Header } from '../Modal/Modal.styled';
import {
  FilenameWrap,
  Description,
  CertLine,
  ButtonsLine,
  ButtonWrap,
} from './FileSignModal.styled';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import { CadesUtil } from '../../../utils/CadesUtil';
import SignatureButton from '../../../common_components/Buttons/SignatureButton';
import TabButton from '../../../common_components/Buttons/TabButton';
import { Translate } from '../../Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSign: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  file: PropTypes.object,
  description: PropTypes.string,
};

const defaultProps = {};

class FileSignModal extends React.Component {
  onDownloadClick = e => {
    const { file } = this.props;
    try {
      FileDownload(file.content, file.name);
    } catch (e) {
      console.log('file not found in props', { e });
    }
  };

  render() {
    const {
      onCloseModal,
      title,
      file,
      description,
      cert,
      onCancel,
      onSign,
    } = this.props;

    const fullName = cert
      ? CadesUtil.getByTagFromSubject(cert.info[0], 'G')
      : null;
    const signedBy = cert
      ? CadesUtil.getByTagFromSubject(cert.info[0], 'CN')
      : null;

    return (
      <Wrapper>
        <Header>
          <div>{title}</div>
          <Icon type={'cross'} onClick={onCloseModal} />
        </Header>
        {file && (
          <FilenameWrap onClick={this.onDownloadClick}>
            <Icon type={'paper-clip'} spacingRight={12} />
            {file.name}
          </FilenameWrap>
        )}
        <Description>{description}</Description>
        <CertLine>
          {Translate('Документ будет подписан сертификатом:')} {fullName},{' '}
          {signedBy}
        </CertLine>
        <ButtonsLine>
          <ButtonWrap>
            <SignatureButton
              onClick={onSign}
              color="primary"
              icon={{ fill: '#63666A' }}
              textbutton={Translate('Подписать и отправить')}
            />
          </ButtonWrap>
          <TabButton onClick={onCancel}>{Translate('Отменить')}</TabButton>
        </ButtonsLine>
      </Wrapper>
    );
  }
}

FileSignModal.defaultProps = defaultProps;
FileSignModal.propTypes = propTypes;

const mapState = state => {
  return {
    cert: authSelectors.cert(state),
  };
};

const mapDispatch = dispatch => ({});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(FileSignModal);
