import React from 'react';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 100,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 280,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Отрасль',
          'col2': 'Табачная продукция'
        },
        {
          'col1': 'Тип участника',
          'col2': <div>
              Возможность фильтрации по доступным значениям:
              <ul>
                <li>Импортёр</li>
                <li>Оператор Информационной Системы Мониторинга Товарооборота</li>
                <li>Опт</li>
                <li>Производитель</li>
                <li>Розница</li>
                <li>Розница, Опт</li>
                <li>ФОИВ</li>
              </ul>
          </div>
        },
        {
          'col1': 'Вариант отображения периода',
          'col2': <div>
            Возможность выбора отображения детализации периода:
            <ul>
              <li>День</li>
              <li>Неделя</li>
              <li>Месяц</li>
            </ul>
          </div>
        },
      ]
    }
  ]
}