export const readImageFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(e) {
      const img = new Image();

      img.src = e.target.result;

      img.onload = function() {
        resolve(this);
      };

      img.onerror = function(error) {
        reject(error);
      };
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
