import React from 'react';

export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'GTIN',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 96,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 176,
          subheaders: []
        },
        {
          Header: 'Страна',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 114,
          subheaders: []
        },
        {
          Header: 'Объём произведённого и импортированного товара',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 390,
          subheaders: [
            {
              Header: 'в штуках',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 200,
              subheaders: []
            },
            {
              Header: 'в тенге',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 190,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': '...',
          'col6': '...',
        },
      ]
    }
  ]
}
