import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import * as actions from './ducks/HandAdd.actions';
import * as selectors from './ducks/HandAdd.selectors';
import { Page } from './HandAddGood.styled';
import Button from 'src/common_components/Form/Fields/Button/Button';
import { Grid, Box } from '@material-ui/core';
import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import { columnsKZ } from './constants';
import Preloader from 'src/common_components/Preloader/Preloader';
import {
  act_DTO,
  cisKey,
  goodsKey,
  notifications_DTO,
  thirdCountry_DTO,
  exportNotificationToTheEaes_DTO
} from './HandAddGood.utils';
import { Confirmation, Close } from '@crpt-ui/icons';
import { get } from 'lodash';
import { Translate } from 'src/common_components/Translate/Translate';
import Pagination from '../../../../../common_components/List/Pagination/Pagination';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onMount: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  filterPackageTypeForGoods: PropTypes.string,
};
const defaultProps = {};
const HandAddGoodModalContent = ({
  target,
  onMount,
  onCloseModal,
  formMutators,
  list,
  isLoading,
  formValues,
  onUnmount,
  listAll,
  mode,
  productGroupIdForGoods,
  filterPackageTypeForGoods
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);

  let goodsList = useRef(null);

  useEffect(() => {
    onMount({
      page,
      target,
      productGroupIdForGoods,
      ...filterPackageTypeForGoods ? { filterPackageTypeForGoods } : {}
    });
  }, []);

  const onListRef = ref => {
    goodsList = ref;
  };

  const onUpdate = () => {
    setSelectedRows(goodsList.state.selectedRows);
  };

  const onPageChange = (pageIdx) => {
    onMount({
      page: pageIdx - 1,
      target,
      productGroupIdForGoods,
      ...filterPackageTypeForGoods ? { filterPackageTypeForGoods } : {}
    });
    setPage(pageIdx - 1);
  }

  const onSubmit = () => {
    switch (target) {
      case 'act':
        act_DTO({ formMutators, formValues, list: listAll, selectedRows, mode });
        break;
      case 'eaes':
        act_DTO({ formMutators, formValues, list: listAll, selectedRows });
        break;
      case 'MTTPS_90':
      case 'KZ_INTRODUCE_GOODS':
        notifications_DTO({ formMutators, formValues, list: listAll, selectedRows });
        break;
      case 'thirdCountry':
        thirdCountry_DTO({ formMutators, formValues, list: listAll, selectedRows });
        break;
      case 'KZ_EXPORT_NOTICE':
        exportNotificationToTheEaes_DTO({ formMutators, formValues, list: listAll, selectedRows });
        break;
    }
    closeModal();
  };

  const closeModal = () => {
    onCloseModal();
    onUnmount();
  };

  const modifyList = useMemo(() => {
    let addedCises = formValues[goodsKey]
      ? formValues[goodsKey].map(item => item.cis)
      : [];
    if (formValues[cisKey])
      addedCises = addedCises.concat(formValues[cisKey].map(item => item.cis));
    return list.map(item => {
      if (addedCises.includes(item.cis))
        return { ...item, hiddenCheckBox: true };
      return { ...item };
    });
  }, [list]);

  return (
    <Page>
      <h3>{Translate('Добавление кодов')}</h3>
      <Preloader isOpened={isLoading} />
      {/*{list.length > 0 && (*/}
        <div style={isLoading ? { height: 0 , overflow: 0} : { height: 'auto' , overflow: 'auto'}}>
          <SelectionList
            defaultPageSize={10}
            columns={columnsKZ}
            data={modifyList}
            onRef={onListRef}
            onUpdate={onUpdate}
            showPagination={false}
            keyField="id"
            customSelectAll
            page={page}
            getTdProps={(state, rowInfo) => {
              const isAddedCis = get(rowInfo, 'original.hiddenCheckBox');
              return {
                style: {
                  ...(isAddedCis && {
                    color: 'gray',
                  }),
                },
              };
            }}
          />
          <Pagination
            pageCounterInvisible={true}
            onChange={onPageChange}
            last={modifyList.length < 10}
            pagesCount={9999999}
            selected={page+1}
          />
        </div>
      {/*)}*/}
      <br />
      <Grid container justify="space-between">
        <Grid item>
          {Translate('Выбрано')} {selectedRows.length}
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Button onClick={onSubmit} disabled={selectedRows.length === 0}>
                <Confirmation />
                {Translate('Добавить')}
              </Button>
            </Grid>
            <Grid item>
              <Box ml={2} mr={2}>
                <Button disabled={!closeModal} onClick={closeModal}>
                  <Close />
                  {Translate('Закрыть')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
HandAddGoodModalContent.defaultProps = defaultProps;
HandAddGoodModalContent.propTypes = propTypes;
const mapState = state => {
  return {
    list: selectors.content(state),
    listAll: selectors.contentAll(state),
    isLoading: selectors.isLoading(state),
  };
};
const mapDispatch = dispatch => ({
  onMount: data => dispatch(actions.getKM(data)),
  onUnmount: () => dispatch(actions.onCloseModal()),
});
export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(HandAddGoodModalContent);
