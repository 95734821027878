import { Translate } from '../../../common_components/Translate/Translate';
import styled from 'styled-components';
import React from 'react';
import ComplianceDocuments from './ComplianceDocuments';
import { packTypes } from '../../../constants/packTypes';
import { Link, NoWrap } from '../../Grid/Grid.styled';
import { Value } from '../../../fragments/Table/Cell/value';


export const StepWrapper = styled.div`
  margin-top: 16px;
  & div[role='tablist'] > button[aria-selected=true] {
    color: white;
  }
`;

export const InfoWrapper = styled.div`
  padding: 34px;
  min-height: calc(100vh - 190px);
`

export const GoodsWrapper = styled.div`
  //padding: 40px 0px 40px;
  height: fit-content;
  min-height: calc(100vh - 190px);
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const Content = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

export const ComplianceDocWrap = styled.ul`
  padding-left: 15px;
`;

export const steps = {
  info: 0,
  goods: 1,
  cis: 2,
};

export const tabs = [
  { id: 0, label: Translate('Общая информация') },
  { id: 1, label: Translate('Товары') },
  { id: 2, label: Translate('Коды') },
];

export const columnsTable = [
  {
    id: 'idx',
    accessor: 'idx',
    Header: Translate('№ п/п'),
    width: 100,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Header: Translate('Код товара (GTIN)'),
  },
  {
    id: 'productName',
    accessor: 'productName',
    Header: Translate('Наименование товара'),
  },
  {
    id: 'packageType',
    accessor: row => {
      return Translate(row.packageType ? packTypes[row.packageType.toLowerCase()] : "-")
    },
    disableFilters: true,
    Header: Translate('Тип упаковки'),
  },
  {
    id: 'cis',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код идентификации'),
  },
]


export const columnsCisTable = [
  {
    id: 'idx',
    accessor: 'idx',
    Header: Translate('№ п/п'),
    width: 100,
    disableSortBy: true,
  },
  {
    id: 'cis',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код идентификации'),
    width: 270,
    disableSortBy: true,
  },
  {
    id: 'price',
    accessor: 'price',
    disableFilters: true,
    Header: Translate('Цена с НДС'),
    width: 150,
    disableSortBy: true,
  },
  {
    id: 'vat',
    accessor: 'vat',
    disableFilters: true,
    Header: Translate('НДС'),
    width: 150,
    disableSortBy: true,
  },
  {
    id: 'doc',
    Header: Translate('Документы соответствия'),
    width: 'auto',
    height: 'fit-content',
    accessor: row => {
      return (
        <ComplianceDocuments list={row.documentList} />
      )
    },
    disableSortBy: true,
  },
  {
    id: 'accepted',
    disableFilters: true,
    accessor: row => (
      <div>
        <Value>{Translate(row.accepted ? 'Принят' : 'Не принят')}</Value>
      </div>
    ),
    Header: Translate('Статус приемки'),
    sortable: true,
    width: 180,
    sortType: ((a, b, id) => {
      if (a.original[id] > b.original[id]) return -1;
      if (b.original[id] > a.original[id]) return 1;
    }),
  },
]
