import { makeInputFilter } from '@crpt-ui/datatable';
import { Translate } from 'src/common_components/Translate/Translate';

export const ProductionLinesColumns = {
  placeName: {
    Header: Translate('Производственная площадка'),
    id: 'productionPlaceName',
    accessor: 'productionPlaceName',
    Filter: makeInputFilter({ label: Translate('Площадка') }),
    width: 'auto',
  },
  countryName: {
    Header: Translate('Страна производства'),
    id: 'productionCountryName',
    accessor: 'productionCountryName',
    Filter: makeInputFilter({ label: Translate('Страна') }),
    width: 'auto',
  },
  line: {
    Header: Translate('Производственная линия'),
    id: 'productionLine',
    accessor: 'productionLine',
    Filter: makeInputFilter({ label: Translate('Линия') }),
    width: 'auto',
  },
};
