import React, { useCallback } from 'react';
import SelectCheckboxView from './SelectionCheckbox.view';

export const checkboxState = {
  NORMAL: 0,
  SELECTED: 1,
  EDITED: 2,
  HIDDEN: 3,
};

const SelectionCheckbox = getCheckboxState => props => {
  const { id, checked, row, onClick } = props;

  if (getCheckboxState(id).status === checkboxState.HIDDEN) return null;

  const state = getCheckboxState(id, checked);
  const clicked = useCallback(
    e => {
      e.stopPropagation();
      onClick(id, e.shiftKey, row);
    },
    [id, row],
  );

  if (row && row.hiddenCheckBox)
    return (
      <SelectCheckboxView
        onClick={clicked}
        state={{ ...state, disabled: true }}
      />
    );
  return <SelectCheckboxView onClick={clicked} state={state} />;
};

export default SelectionCheckbox;
