import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrap } from './Report.styled';
import i18next from 'i18next';
import { Translate } from '../../../common_components/Translate/Translate';

const ReportFrame = props => {
  const { frameLink } = props;
  return (
    <Wrap>
      {frameLink ? (
        <iframe
          style={{
            width: 'calc(100vw - 85px)',
            height: '100vh',
            position: 'absolute',
            marginTop:'-40px',
            marginLeft: '-20px'
          }}
          title={i18next.t("Отчёт")}
          frameBorder="0"
          src={frameLink}
        />
      ) : (
        <div>{Translate('Отчёт отсутствует.')}</div>
      )}
    </Wrap>
  );
};

ReportFrame.propTypes = {
  frameLink: PropTypes.string.isRequired,
};

export default ReportFrame;
