/* eslint-disable no-control-regex */
export const prepareCodesForReconciliation = (data, ownerInn) =>
  data.map(item => ({
    cis: item.cis,
    reconciliationStatus: item.reconciliationStatus
      ? item.reconciliationStatus
      : 'NOT_FOUND',
    packageType: item.cisPackageType,
    ownerInn,
    reason: item.reason || '',
  }));

export const deletingCryptoEnd = codes => {
  const newCodes = codes
    .trim()
    .replace(/\n/g, ' ')
    .split(' ')
    .map(item => {
      if (item.length === 29 && !/\x1d/.test(item)) return item.substr(0, 21);
      if (item.length === 18) return item;
      if (/\x1d((91)|(93))/.test(item)) return item.replace(/\x1d((91)|(93)).*/, '');
      if (item.length >= 20 && item.length < 74 && /^00/.test(item)) return item;
      if (/\x1d/.test(item) && !/\x1d((91)|(93))/.test(item)) return item
      return item;
    });

  return newCodes.filter(item => !!item);
};
