import moment from 'moment';
import { generateUUIDBig } from '../Draft/Draft.utils';

export const senderDocumentFormatUz = (documentData) => {
  return {
    schemaVersion: "1.0.0",
    senderTin: documentData.inn,
    sourceDocument: {
      number: documentData.number,
      date: moment(documentData.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      type: documentData.type
    },
    eliminationReason: documentData.eliminationReason,
    cises: documentData.products.map(el=> el.cis)
  }
}

export const senderDocumentFormat = (documentData) => {
  return {
    document: {
      mainInfo: {
        participantInfo: {
          participantName: documentData.applicant ? documentData.applicant : '',
          participantIdentificationCode: documentData.inn
        },
        originalDocInfo: {
          docName: documentData.permit_document_name,
          docNumber: documentData.permit_document_number,
          docDate: documentData.permit_document_date
        },
        reason: documentData.reason
      },
      productList: productList(documentData)
    },
    fileId: generateUUIDBig(44),
    version: '1.0',
    sendingDateTime: new Date().toISOString()
  }
}

const productList = (documentData) => {
  return documentData.products.map(item=>({count: '1', value: item.cis}))
}
