import React, { useCallback } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import Button from './Button';
import { FormContext } from '../../Form.constants';
import { cleanErrors } from '../../Form.utils';

const SubmitButton = props => {
  const { disabled, status, nameForm } = props;

  const getDisabled = useCallback(
    (name, values, errors) => {
      if (disabled) return true;
      return Boolean(!isUndefined(status) && status) || !isEmpty(cleanErrors(errors));
    },
    [disabled, status]
  );

  return (
    <FormContext.Consumer>
      {({ formChange }) => (
        <Button
          type="submit"
          name="__form_submit"
          marginBottom="0"
          {...props}
          onClick={() => formChange('__form__nameForm', nameForm)}
          disabled={getDisabled}
        />
      )}
    </FormContext.Consumer>
  );
};

export default SubmitButton;
