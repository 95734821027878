import { Toast } from '../../utils/Toast';
import { NCALayerConfigBuilder } from './NCALayer';
import {Translate} from '../Translate/Translate';

export class NCALayerService {
  static webSocket;
  static callback;
  static webSocketUrl = 'wss://127.0.0.1:13579/';
  static module = 'kz.gov.pki.knca.commonUtils';
  state = null;

  constructor(task=null, storageType=null, attached= false) {
    if(task && storageType && attached){
      const { method, keyType, attached } = new NCALayerConfigBuilder()
        .attachSignData()
        .useSignKeyType()
        .configure();

      this.state = { method, keyType, attached, storageType}
    } else {
      const { method, keyType, attached } = new NCALayerConfigBuilder()
        .useSignKeyType()
        .configure();

      this.state = { method, keyType, attached, storageType}
    }
  }

  signData(data) {
    if(!this.state) {
      throw new Error('===Экземпляр класса создан без необходимых параметров');
    }

    const {method, keyType, attached, storageType} = this.state;
    const args = [storageType, keyType, data, attached];

    return NCALayerService.init().then(
      () => NCALayerService.getData(method, args)
        .then(({ code, responseObject }) => {
          if (code === '200') {
            return responseObject;
          }
        })
        .catch(error => {
          Toast.showError({ content: error });
        })
        .finally(() => NCALayerService.close()),
      () => {
        throw new Error('');
      });
  }

  static getStorageData(method, args) {
    return new Promise((resolve, reject) => {
      if (NCALayerService.isConnectionAlive()) {
        NCALayerService.callback = result => resolve(result);

        NCALayerService.webSocket.send(
          JSON.stringify({
            method,
            args,
          }),
        );
      } else {
        reject();
      }
    });
  }

  /** get data via signature
   * @method - (createCMSSignatureFromBase64, getKeyInfo, ...)
   * @args array of [
   * storageType - type of storage (PKCS12, AKKaztokenStore, AKKZIDCardStore)
   * keType - type of use key (SIGNATURE, AUTHENTICATION)
   * data - source data
   * attached - if "true" attached signed data to result
   * ]
   */

  static getData(method, args) {
    return new Promise(resolve => {
      if (NCALayerService.isConnectionAlive()) {
        NCALayerService.callback = result => resolve(result);

        NCALayerService.webSocket.send(
          JSON.stringify({
            module: NCALayerService.module,
            method,
            args,
          }),
        );
      }
    });
  }

  static isConnectionAlive() {
    return NCALayerService.webSocket.readyState === 1;
  }

  static isNotAvailable() {
    return (
      NCALayerService.webSocket === undefined ||
      NCALayerService.webSocket.readyState === 0 ||
      NCALayerService.webSocket.readyState === 2 ||
      NCALayerService.webSocket.readyState === 3
    );
  }

  static isSystemMessage(message) {
    // eslint-disable-next-line no-prototype-builtins
    return message.result && message.result.hasOwnProperty('version');
  }

  static init() {
    return new Promise((resolve, reject) => {
      NCALayerService.webSocket = new WebSocket(NCALayerService.webSocketUrl);

      NCALayerService.webSocket.onopen = () => resolve();
      NCALayerService.webSocket.onerror = () => {
        if (NCALayerService.isNotAvailable()) {
          Toast.showError({ content: Translate('NCALayer не доступен') });
          reject();
        }
      };

      NCALayerService.webSocket.onmessage = event => {
        const response = JSON.parse(event.data);

        if (response != null && !NCALayerService.isSystemMessage(response)) {
          const result = {
            code: response.code,
            message: response.message,
            responseObject: response.responseObject,
            errorCode: response.errorCode,
          };

          NCALayerService.callback && NCALayerService.callback(result);
        }
      };
    });
  }

  static close() {
    NCALayerService.webSocket.close();
  }
}
