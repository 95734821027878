import * as Icons from '@crpt-ui/icons';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../ducks/CisDetails.actions';
import * as selectors from './ducks/Agregation.selectors';

const Expanded = ({ row, status, onExpand }) => {
  useEffect(() => {
    if (row.isExpanded) {
      row.toggleRowExpanded(true);

      const { id } = row;
      if (!status.has(id) || status.get(id) < 0) {
        const childs = get(row, 'original.childs', []);
        if (childs.length) {
          onExpand({ id, childs });
        }
      }
    }
  }, [row]);

  return (
    <React.Fragment>
      {row.isExpanded ? <Icons.Collapse /> : <Icons.Expand />}
    </React.Fragment>
  );
}

Expanded.propTypes = {
  row: PropTypes.shape({}).isRequired,
  onExpand: PropTypes.func,
};

const mapState = state => ({
  status: selectors.status(state),
});

const mapDispatchToProps = {
  onExpand: actions.expandedAgregation,
};

export default connect(mapState, mapDispatchToProps)(Expanded);