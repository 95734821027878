import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from './ducks/LoginForm.actions';
import LoginFormView from './LoginForm.view';
import { getTheme } from './ducks/LoginForm.selectors';

const mapState = state => ({
  theme: getTheme(state),
});

const mapDispatch = dispatch => ({
  onSubmit: data => dispatch(actions.submit(data)),
});

export default compose(
  connect(mapState, mapDispatch)
)(LoginFormView);
