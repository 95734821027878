
import { takeEvery,takeLatest, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { Toast } from '../../../utils/Toast';
import * as actions from './Appeals.actions';
import Api from '../../../common_components/Api/Api';
import { setSunToken, getSunToken } from '../../../utils/cookieHelpers';
import axios from 'axios';
import Qs from 'qs';

function* fetchAuthenticateSunSaga() {
  const requestPayload = {
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/security/mpt/authenticate`,
    method: 'post',
  };

  try {
    const [success] = yield call(Api.request, requestPayload);

    if (success.data.accessToken) {
      yield call(setSunToken, success.data.accessToken);
      yield call(fetchViolation, {
        payload: {
          page: 1,
          size: 10,
        }
      });
      yield call(fetchGoodsGroups);
      yield call(fetchTerritorialUnits);
      yield call(fetchViolationCategories);
      yield call(fetchViolationTypes);
    }
  } catch (error) {
    console.error('something went wrong on sun auth')
  }
  return Promise.resolve();
}

function* fetchGoodsGroups() {
  const requestPayload = {
    method: 'GET',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/info/goods-groups`,
    headers: {
      authorization: `Bearer ${getSunToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  };
  try {
    const { data } = yield call(axios.request, requestPayload);
    if (data) {
      yield put(actions.loadedGoodsGroups(data))
    }
  } catch (error) {
    console.error('something went wrong in fetchGoodsGroups')
  }
}

function* fetchTerritorialUnits() {
  const requestPayload = {
    method: 'GET',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/info/territorial-units`,
    headers: {
      authorization: `Bearer ${getSunToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  };
  try {
    const { data } = yield call(axios.request, requestPayload);

    if (data) {
      yield put(actions.loadedTerritorialUnits(data));
    }
  } catch (error) {
    console.error('something went wrong in fetchTerritorialUnits')
  }
}

function* fetchViolationCategories() {
  const requestPayload = {
    method: 'GET',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/info/violation-categories `,
    headers: {
      authorization: `Bearer ${getSunToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  };
  try {
    const { data }= yield call(axios.request, requestPayload);

    if (data) {
      yield put(actions.loadedViolationCategories(data));
    }
  } catch (error) {
    console.error('something went wrong in fetchViolationCategories')
  }
}

function* fetchViolationTypes() {
  const requestPayload = {
    method: 'GET',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/info/violation-types `,
    headers: {
      authorization: `Bearer ${getSunToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    }
  };
  try {
    const { data } = yield call(axios.request, requestPayload);

    if (data) {
      yield put(actions.loadedViolationTypes(data));
    }
  } catch (error) {
    console.error('something went wrong in fetchViolationTypes')
  }
}

function* fetchViolation(action) {
  const { payload } = action;
  const requestPayload = {
    method: 'GET',
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/violations`,
    headers: {
      authorization: `Bearer ${getSunToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    params: payload,
  };

  let myAxios = axios.create({
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
})

  try {
    const { data } = yield call(myAxios.request, requestPayload);
    if (data) {
      yield put(actions.loadedViolation(data));
    }
  } catch (error) {
    console.error('something went wrong in fetchViolation')
  }
}

export default function* watchActions() {
  yield takeEvery(actions.fetchViolation, fetchViolation);
  yield takeLatest(actions.fetchAuthenticateSun, fetchAuthenticateSunSaga);
  yield takeEvery(actions.fetchGoodsGroups, fetchGoodsGroups);
  yield takeEvery(actions.fetchTerritorialUnits, fetchTerritorialUnits);
  yield takeEvery(actions.fetchViolationCategories, fetchViolationCategories);
  yield takeEvery(actions.fetchViolationTypes, fetchViolationTypes);
}
