import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';
import defaultTheme from '../theme';

const StyledInput = styled.input`
  display: block;
  position: relative;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${props => props.height};
  line-height: ${props => props.height};
  font-size: ${props => props.fontSize};
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box !important;
  outline: 0;
  border: ${props => props.border};
  overflow: hidden;
  padding: 0;
  top: ${props => (props.centered ? '0' : '10px')};
  background: ${props => props.background};
  cursor: ${props => props.cursor};
  color: ${({ noCaret, color }) => (noCaret ? 'rgba(0,0,0,0)' : color)};
  text-shadow: ${({ noCaret, color }) => (noCaret ? '0 0 0 ' + color : 'none')};
  ::-webkit-input-placeholder {
    color: ${props => props.placeholderColor};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
  }
`;

export const Input = ({
  theme: themeFromProps,
  disabled,
  isError,
  centered,
  onChange,
  value,
  onFocus,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;
  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : 'main',
  );

  const mergedInputElem = innerMerge(
    {},
    defaultTheme.Select.InputElem,
    (themeFromPropsHasSelect && themeFromProps.Select.InputElem) || {},
  );

  Object.assign(
    theme,
    getThemeAsPlainObjectByKeys(
      mergedInputElem,
      disabled ? 'disabled' : isError ? 'error' : 'main',
    ),
  );

  return (
    <StyledInput
      {...theme}
      isError={isError}
      disabled={disabled}
      centered={centered}
      onChange={onChange}
      value={value}
      onFocus={onFocus}
    />
  );
};

Input.propTypes = {
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      InputElem: PropTypes.object,
    }),
  }).isRequired,
  isError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  centered: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
};
