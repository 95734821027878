import { createAction } from 'redux-actions';
export const subject = '[ArticleListPage]';

export const loadItem = createAction(`${subject} load item`);
export const setItem = createAction(`${subject} set item`);
export const loaded = createAction(`${subject} loaded`);

export const uploadFile = createAction(`${subject} upload file`);

export const getIconList = createAction(`${subject} get icon list`);
export const setIconList = createAction(`${subject} set icon list`);

export const create = createAction(`${subject} create`);
export const update = createAction(`${subject} update`);

export const unmount = createAction(`${subject} unmount`);

export const stop = createAction(`${subject} stop`);
