import { handleActions } from 'redux-actions';
import * as actions from './ModalForm.actions';
import { style as defaultStyle } from '../ModalForm.constants';


const nullFn = () => null;

const open = handleActions({
  [actions.open]: () => true,
  [actions.close]: () => false,
}, false);

const content = handleActions({
  [actions.open]: (state, { payload }) => payload || null,
  [actions.close]: () => null,
}, null);


/*eslint no-case-declarations: 0 */
const accept = (state = nullFn, action) => {
  switch (action.type) {
    case `${actions.open}`:
      const { meta = {} } = action;
      return meta.onAccept || nullFn;
    case `${actions.close}`: return nullFn;
    default: return state;
  }
};

const cancel = (state = nullFn, action) => {
  switch (action.type) {
    case `${actions.open}`:
      const { meta = {} } = action;
      return meta.onCancel || nullFn;
    case `${actions.close}`: return nullFn;
    default: return state;
  }
};

const style = handleActions({
  [actions.open]: (state, { meta = {} }) => meta.style || defaultStyle,
  [actions.close]: () => defaultStyle,
}, defaultStyle);

export default {
  open,
  content,
  accept,
  cancel,
  style,
};
