import { Checkbox } from '@crpt-ui/core';
import { withStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { AutocompleteMultiple } from 'src/fragments/Form/Fields';

import { InfoBlock } from '../InfoBlock';
import { Translate } from '../../../../../common_components/Translate/Translate';

const NoticeList = ({ isTogglePopupAutocomplete, setTogglePopupAutocomplete, users }) => {
  const renderAutocompleteOption = useCallback(
    (option, { selected }) => <AutocompleteOption option={option} selected={selected} />,
  []);

  const renderAutocompleteTags= useCallback(
    values =>
      isTogglePopupAutocomplete
        ? <AutocompleteTags values={values}/>
        : () => null,
  [isTogglePopupAutocomplete]);

  return (
    <InfoBlock title={Translate("Уведомление о готовности выгрузки")}>
      <AutocompleteMultiple
        name="information.noticeList"
        placeholder={Translate("Получатель")}
        options={users}
        onOpen={() => setTogglePopupAutocomplete(false)}
        onClose={() => setTogglePopupAutocomplete(true)}
        renderOption={renderAutocompleteOption}
        renderTags={renderAutocompleteTags}
        multiple
        disableCloseOnSelect
        clearOnEscape
      />
    </InfoBlock>
  )
}

export default NoticeList;

const AutocompleteTags = withStyles({
  renderTag: {
    height: '50px',
    lineHeight: '50px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    padding: '8px 0 0 16px',
    fontSize: 14,
    fontWeight: 600,
  },
})(({ classes, values }) => (
  <div className={classes.renderTag}>
    {values.map(({ label }) => label).join(', ')}
  </div>
));

const AutocompleteOption = withStyles({
  checkbox: {
    marginRight: 8,
    paddingTop: 10,
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
  },
  email: {
    color: '#b2b3b5',
    fontSize: 14,
  },
  separator: {
    margin: '0 8px',
  },
})(({ classes, option, selected }) => (
  <React.Fragment>
    <Checkbox className={classes.checkbox} checked={selected} />
    <span className={classes.label}>{option.label}</span>
    <span className={classes.separator}>/</span>
    <span className={classes.email}>{option.email}</span>
  </React.Fragment>
));
