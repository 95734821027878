const activeUsers = state => state.Profile.activeUsers;
const removedUsers = state => state.Profile.removedUsers;
const company = state => state.Profile.company;
const currentUser = state => state.Profile.currentUser;
const cert = state => state.Profile.cert;
const certUploadedMessage = state => state.Profile.certUploadedMessage;
const isEdited = state => state.Profile.isEdited;
const isModalOpen = state => state.Profile.isModalOpen;
const callbackModal = state => state.Profile.callbackModal;
const uploadedImage = state => state.Profile.uploadedImage;
const certificates = state => state.Profile.certificates;
const error = state => state.Profile.error;

export {
  removedUsers,
  activeUsers,
  company,
  currentUser,
  cert,
  certUploadedMessage,
  isEdited,
  isModalOpen,
  callbackModal,
  uploadedImage,
  certificates,
  error,
};
