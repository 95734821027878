import { take, takeEvery, race, put, select } from 'redux-saga/effects';
import * as actions from './FilePreview.actions';
import * as selectors from './FilePreview.selectors';
import * as fileActions from '../../File/File.actions';
import { isValidParsers } from '../FilePreview.utils';

function* fileSaga(action) {
  const { payload, meta } = action;

  if (meta) {
    yield put(actions.setCallbacks(meta));
  }

  if (payload) {
    yield put(fileActions.read(payload, { subject: actions.openFile, rawWithText: true }));
    const { success /*, error*/} = yield race({
      success: take(`${fileActions.success} ${actions.openFile}`),
      error: take(`${fileActions.error} ${actions.openFile}`),
    });

    if (success) {
      if (isValidParsers(success.payload.content, success.payload.type)) {
        yield put(actions.setContent(success.payload));
      } else {
        const gotErrors = yield select(selectors.gotErrors);
        gotErrors();
      }
    } else {
      yield put(actions.setContent());
    }
  } else {
    const openFileDialog = yield select(selectors.openFileDialog);
    if (openFileDialog) {
      yield put(actions.close());
      yield take(actions.isUpdated);
    }
    yield put(actions.openFileDialog());
  }
}

function* stringSaga(action) {
  const { payload, meta } = action;
  yield put(actions.setContent(payload, meta));
}

function* closeSaga() {
  yield put(actions.setContent());
}


export default function* watchActions() {
  yield takeEvery(actions.openFile, fileSaga);
  yield takeEvery(actions.openString, stringSaga);
  yield takeEvery(actions.close, closeSaga);
}
