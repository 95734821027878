import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as grayAreaProductionLinesActions from '../../GrayAreaAdditionalInfo/GrayAreaProductionLines/ducks/GrayAreaProductionLines.actions';
import * as grayAreaProductionLinesSelectors from '../../GrayAreaAdditionalInfo/GrayAreaProductionLines/ducks/GrayAreaProductionLines.selectors';
import { actionTypes } from '../GrayAreaCreate.constants';
import { productionLinesHelper } from '../GrayAreaCreate.reducers';
import { getSelectedRows } from './ProductionLines.constants';
import ProductionLinesView from './ProductionLines.view';
import { Translate } from '../../../../common_components/Translate/Translate';

const ProductionLines = ({
  allProductionLines,
  dispatch,
  onUnmount,
  requestProductionLines,
  selectedProductionLines,
}) => {
  useEffect(() => {
    requestProductionLines();
    return () => onUnmount;
  }, []);

  useEffect(() => {
    if (selectedProductionLines.length === 0) {
      setIsMultipleActionsDisabled(true);
    }
  }, [selectedProductionLines]);

  const instance = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = useCallback(
    () => setIsModalOpen(true),
    [],
  );

  const onCloseModal = useCallback(
    () => setIsModalOpen(false),
    [],
  );

  const [isMultipleActionsDisabled, setIsMultipleActionsDisabled] = useState(true);

  const multipleActionsItems = useMemo(
    () => [
      {
        label: Translate('Удалить'),
        onClick: rows => deleteSelectedLines(rows),
      },
    ],
    [],
  );

  const rowActions = useMemo(
    () => [
      {
        label: Translate('Удалить'),
        callback: row => deleteProductionLines([row.original]),
      },
    ],
    [],
  );

  const error = useMemo(() => productionLinesHelper.validator(selectedProductionLines), [selectedProductionLines]);

  const onMarkToDelete = selectedRows => {
    const isSelected = (Object.keys(selectedRows).length > 0);
    setIsMultipleActionsDisabled(!isSelected);
  };

  const deleteSelectedLines = useCallback(
    () => {
      const selected = getSelectedRows(instance);
      deleteProductionLines(selected);
    },
    [instance],
  );

  const deleteProductionLines = useCallback(
    values => {
      dispatch({
        payload: values,
        type: actionTypes.PRODUCTION_LINES.DELETE,
      });
    },
    [],
  );

  const addSelectedLines = useCallback(
    values => {
      onCloseModal();
      dispatch({
        payload: values,
        type: actionTypes.PRODUCTION_LINES.ADD,
      });
    },
    [],
  );

  return (
    <ProductionLinesView
      addSelectedLines={addSelectedLines}
      allProductionLines={allProductionLines}
      error={error}
      isModalOpen={isModalOpen}
      isMultipleActionsDisabled={isMultipleActionsDisabled}
      multipleActionsItems={multipleActionsItems}
      onCloseModal={onCloseModal}
      onMarkToDelete={onMarkToDelete}
      onOpenModal={onOpenModal}
      ref={instance}
      rowActions={rowActions}
      selectedProductionLines={selectedProductionLines}
      total={selectedProductionLines.length}
    />
  )
};

ProductionLines.propTypes = {
  allProductionLines: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  requestProductionLines: PropTypes.func.isRequired,
  selectedProductionLines: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  allProductionLines: grayAreaProductionLinesSelectors.result(state),
});

const mapDispatchToProps = {
  onUnmount: grayAreaProductionLinesActions.clear,
  requestProductionLines: grayAreaProductionLinesActions.requestProductionLines,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(ProductionLines);
