import { createAction } from 'redux-actions';

export const subject = '[ExportList]';
export const getRequest = createAction(`${subject} get request`);
export const mounted = createAction(`${subject} mounted`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const updatePage = createAction(`${subject} update page`);
export const updateOffset = createAction(`${subject} update offset`);
export const pagination = createAction(`${subject} pagination`);
export const filters = createAction(`${subject} filters`);
export const setSorting = createAction(`${subject} set sorting`);
export const gotErrors = createAction(`${subject} got errors`);
export const clearErrors = createAction(`${subject} clear errors`);
