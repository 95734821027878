import {actionTypes} from '../../constants';


export function company(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.PROFILE_INFO_LOADED:
      return action.value;
    case actionTypes.PROFILE.PROFILE_INFO_UPDATED:
      return action.value ? Object.assign({}, state, action.value) : state;
    default:
      return state;
  }
}
