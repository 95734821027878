import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import defaultTheme from '../theme';
import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';

const OptionsLoadingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 16px;
  :first-child {
    padding-top: 8px;
  }
  :last-child {
    padding-bottom: 8px;
  }
  :after {
    content: ${props => (props.multi ? null : '')};
    width: 40px;
  }
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  color: ${props => props.color};
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
`;

export const LoadingMessage = ({ theme: themeFromProps, disabled, multi }) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;

  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : 'main',
  );

  return (
    <OptionsLoadingMessage {...theme} disabled={disabled} multi={multi}>
      Загрузка...
    </OptionsLoadingMessage>
  );
};

LoadingMessage.propTypes = {
  theme: PropTypes.shape({
    Select: PropTypes.object,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  multi: PropTypes.bool.isRequired,
};
