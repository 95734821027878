import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from '@crpt/react-pagination';

import { PaginationWrapper } from '../../styled';
import { Translate } from '../../Translate/Translate';

const propTypes = {
  selected: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  last: PropTypes.bool,
  pageCounterInvisible: PropTypes.bool,
  background: PropTypes.string,
};

const StylesPagination = ({
  selected,
  pagesCount,
  onSelect,
  last,
  background,
  pageCounterInvisible,
  onClick,
  withMargin
}) => {
  return (
    <PaginationWrapper background={background} withMargin={withMargin}>
      <Pagination
        selected={selected}
        pagesCount={last ? selected : pagesCount}
        isUpperThenMaxDisabled
        isBelowZeroDisabled
        onSelect={onSelect}
        background={background}
        pageCounterInvisible={pageCounterInvisible}
        isSeparatedPageCounter
        withoutTotalPages
        onClick={onClick}
        leftPaginationText={
          !pageCounterInvisible ? ' ' : Translate('Предыдущая')
        }
        rightPaginationText={
          !pageCounterInvisible ? ' ' : Translate('Следующая')
        }
      />
    </PaginationWrapper>
  );
};

StylesPagination.propTypes = propTypes;
StylesPagination.defaultProps = {
  pageCounterInvisible: false,
  onClick: () => {},
};
export default StylesPagination;
