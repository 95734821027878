import React from 'react';
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import List from '../../../common_components/List/List';
import { Translate } from "../../../common_components/Translate/Translate";
import {documentTypeNames} from "../../../constants";
import HeadingWrap from '../BasicInfoFabric/HeadingWrap';

const columns = [
  {
    id: 'cis',
    accessor: 'cis',
    Header: Translate('Коды'),
  },
  {
    id: 'printQualityClass',
    accessor: 'printQualityClass',
    Header: Translate('Класс качества печати'),
  },
]

export const ValidationCodes = ({ selectedDocument }) => {
  const { documentDate, documentId } = selectedDocument;

  return (
    <React.Fragment>
      <HeadingWrap
        title={Translate('Отчет о валидации качества печати')}
        id={documentId}
        date={documentDate}
        docType={documentTypeNames.VALIDATION}
        needDocTitle={false}
        bottomId={true}
      />
      <CrossborderWrap>
        <List
          defaultPageSize={10}
          data={selectedDocument.arrayCises}
          columns={columns}
          showPagination={selectedDocument.arrayCises.length > 10}
        />
      </CrossborderWrap>
    </React.Fragment>
  );
};
