import {actionTypes} from '../../constants/';

export function certificates(state = [], action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.CERTIFICATES_LOADED:
      return action.value;

    default:
      return state;
  }
}
