import { makeInputFilter } from '@crpt-ui/datatable';
import React from 'react';
import { CodesRenderer } from 'src/components/DocumentDetails/Goods/CodesRenderer';
import { roubleFormatter } from 'src/utils/roubleFormatter';

import { Value } from '../value';
import {
  PACK_TYPE_TO_CONSTANTS,
  packTrivialTypes,
} from '../../../../constants/packTypes';
import {
  IconWrapper,
  NoWrap,
  OptionWrap,
} from '../../../../components/Grid/Grid.styled';
import { getIconNameByPackType } from '../../../../components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { countryCodes } from 'src/constants/countryCodes';
import { Translate } from '../../../../common_components/Translate/Translate';

const kopecksToRubels = val => {
  return val ? (+('' + val).replace(/,/g, '.') / 100).toFixed(2) : val;
};

export const GoodsColumns = {
  name: {
    id: 'name',
    accessor: 'name',
    Cell: ({ row }) => {
      return <Value>{row.original.name || '-'}</Value>;
    },
    Header: Translate('Наименование'),
    Filter: makeInputFilter({ label: 'Наименование' }),
    width: 'auto',
  },
  productName: {
    id: 'productName',
    accessor: 'productName',
    Cell: ({ row }) => {
      return <Value>{row.original.name || '-'}</Value>;
    },
    Header: Translate('Наименование товара'),
    Filter: makeInputFilter({ label: 'Наименование товара' }),
    width: 'auto',
  },
  producerName: {
    id: 'producerName',
    accessor: 'producerName',
    Cell: ({ row }) => {
      return <Value>{row.original.name || '-'}</Value>;
    },
    Header: Translate('Наименование товара'),
    Filter: makeInputFilter({ label: 'Наименование товара' }),
    width: 'auto',
  },
  gtin: {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.original.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    Filter: makeInputFilter({ label: 'Код товара (GTIN)' }),
    width: 'auto',
  },
  numberDT: {
    id: 'numberDT',
    accessor: 'numberDT',
    Cell: ({ row }) => {
      return <Value>{row.original.numberDT || '-'}</Value>;
    },
    Header: Translate('Номер товара в ДТ'),
    Filter: makeInputFilter({ label: 'Номер товара в ДТ' }),
    width: 'auto',
  },
  cises: {
    id: 'cises',
    accessor: 'cises',
    Cell: ({ row }) => {
      return <CodesRenderer row={row} />;
    },
    Header: Translate('Код маркировки'),
    width: 'auto',
  },
  permitNumber: {
    id: 'permit_document_number',
    accessor: 'permit_document_number',
    Cell: ({ row }) => {
      return <Value>{row.original.permit_document_number}</Value>;
    },
    Header: Translate('Номер разрешительного док-та'),
  },
  permitDate: {
    id: 'permit_document_date',
    accessor: 'permit_document_date',
    Cell: ({ row }) => {
      return <Value>{row.original.permit_document_date}</Value>;
    },
    Header: Translate('Дата разрешительного док-та'),
    width: 'auto',
  },
  price: {
    id: 'price',
    accessor: 'price',
    Cell: ({ row }) => {
      const roubleValue = kopecksToRubels(row.original.price);
      return <Value>{roubleFormatter.format(roubleValue)}</Value>;
    },
    Header: Translate('Стоимость товара') + ', ₽',
    width: 'auto',
  },
  certNumber: {
    id: 'certNumber',
    accessor: 'certNumber',
    Cell: ({ row }) => {
      return <Value>{row.original.cert_number}</Value>;
    },
    Header: Translate('Номер сертификата'),
  },
  certDate: {
    id: 'certDate',
    accessor: 'certDate',
    Cell: ({ row }) => {
      return <Value>{row.original.cert_date}</Value>;
    },
    Header: Translate('Дата сертификата'),
    width: 'auto',
  },
  tnved: {
    id: 'tnved',
    accessor: 'tnved',
    Cell: ({ row }) => {
      return <Value>{row.original.tnved}</Value>;
    },
    Header: Translate('Код ТН ВЭД'),
    width: 'auto',
  },
  country: {
    id: 'country',
    accessor: 'country',
    Cell: ({ row }) => {
      const country = countryCodes.find(
        item => item.type === row.original.country,
      );
      return <Value>{country ? country.title : ''}</Value>;
    },
    Header: Translate('Страна производства'),
    width: 'auto',
  },
  idx: {
    id: 'idx',
    accessor: 'idx',
    Cell: ({ row }) => {
      return <Value>{row.original.idx}</Value>;
    },
    Header: Translate('№ п/п'),
    width: 'auto',
  },
};
