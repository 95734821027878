import React from 'react';
import { compose, withHandlers, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { MultiSelect as CrptMultiSelect } from '@crpt/react-select';
import { Wrap } from './styled';
import { withCountedPlaceholder, withErrorProp, withFieldWrapper } from './utils';


const Element = ({ input, ...rest }) => (
  <Wrap onBlur={input && input.onBlur}>
    <CrptMultiSelect {...rest} />
  </Wrap>
);

const enchanced = compose(
  withErrorProp,
  withCountedPlaceholder,
  withHandlers({
    onSelect: ({ input, onSelect, ...other }) => (val) => {
      const newVal = (val && val.length && val.map(v => v.type)) || val;
      if (input && input.onChange) {
        input.onChange(newVal);
      } else if (onSelect) {
        onSelect(newVal);
      }
    },
  }),
  onlyUpdateForKeys(['name', 'placeholder', 'values', 'selectedIds', 'savePlaceholder']),
)(Element);


export default compose(
  setDisplayName('MultiSelect'),
  withFieldWrapper(enchanced),
)(enchanced);
