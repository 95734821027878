import React from 'react';
import PropTypes from 'prop-types';
import TwoLineLabelAdapter from './Adapters/TwoLineLabelAdapter';
import FormContextWrapper from '../FormContextWrapper';
import FieldsWrapper from './_refactor/FieldsWrapper';


const TwoLineLabel = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <TwoLineLabelAdapter name={name} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

TwoLineLabel.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

TwoLineLabel.defaultProps = {
  name: '_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};


export default TwoLineLabel;
