import _ from 'lodash';

export const environmentEnum = {
  DEMO: 'DEMO',
  DEV: 'DEV',
  PROD: 'PROD',
  QA: 'QA',
};

const environmentLinksMap = {
  elk: {
    [environmentEnum.DEMO]: 'https://markirovka.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://qa02.gismt.crpt.tech',
    [environmentEnum.PROD]: 'https://markirovka.crpt.ru',
    [environmentEnum.QA]: 'https://qa02.gismt.crpt.tech',
  },
  gov: {
    [environmentEnum.DEMO]: 'https://gov.crpt.ru',
    [environmentEnum.DEV]: 'https://gov.qa02.gismt.crpt.tech',
    [environmentEnum.PROD]: 'https://gov.crpt.ru',
    [environmentEnum.QA]: 'https://gov.qa02.gismt.crpt.tech',
  },
  tobacco: {
    [environmentEnum.DEMO]: 'https://tobacco.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://bigqa01.gismt.crpt.tech',
    [environmentEnum.PROD]: 'https://tobacco.crpt.ru',
    [environmentEnum.QA]: 'https://bigqa01.gismt.crpt.tech',
  },
  shoes: {
    [environmentEnum.DEMO]: 'https://shoes.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://shoes.preprod.crpt.tech',
    [environmentEnum.PROD]: 'https://shoes.crpt.ru',
    [environmentEnum.QA]: 'https://shoes.preprod.crpt.tech',
  },
  electronics: {
    [environmentEnum.DEMO]: 'https://electronics.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://electronics.preprod.crpt.tech',
    [environmentEnum.PROD]: 'https://electronics.crpt.ru',
    [environmentEnum.QA]: 'https://electronics.preprod.crpt.tech',
  },
  clothes: {
    [environmentEnum.DEMO]: 'https://clothes.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://clothes.preprod.crpt.tech',
    [environmentEnum.PROD]: 'https://clothes.crpt.ru',
    [environmentEnum.QA]: 'https://clothes.preprod.crpt.tech',
  },
  tires: {
    [environmentEnum.DEMO]: 'https://tires.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://tires.preprod.crpt.tech',
    [environmentEnum.PROD]: 'https://tires.crpt.ru',
    [environmentEnum.QA]: 'https://tires.preprod.crpt.tech',
  },
  milk: {
    [environmentEnum.DEMO]: 'https://milk.demo.crpt.tech',
    [environmentEnum.DEV]: 'https://milk.preprod.crpt.tech',
    [environmentEnum.PROD]: 'https://milk.crpt.ru',
    [environmentEnum.QA]: 'https://milk.preprod.crpt.tech',
  },
};

export const getCurrentEnvironment = () => {
  if (/demo|int01|int02/g.test(window.location.origin)) {
    return environmentEnum.DEMO;
  }

  if (/crpt\.tech/g.test(window.location.origin)) {
    return environmentEnum.QA;
  }

  if (/localhost/g.test(window.location.origin)) {
    return environmentEnum.DEV;
  }

  return environmentEnum.PROD;
};

export const environment = getCurrentEnvironment();

export const environmentLinks = _.chain(environmentLinksMap)
  .mapValues(environmentLink =>
    _.chain(environmentLink)
      .get(environment, '#')
      .value(),
  )
  .value();
