import styled, { css } from 'styled-components';

export const CisCodeWrap = styled.a`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  color: #55b5e7;
  cursor: pointer;
  display: block;
  text-decoration: none !important;
`;

// TODO: delete IconWrapper
// IconWrapper нужен для устранения бага, связанного с отображением иконки box
export const IconWrapper = styled.span`
  ${({type}) => type === 'box' ? css`& path { mask: none }`: null}
`