import { createAction } from 'redux-actions';

export const subject = '[ModalForm]';

export const open = createAction(
  `${subject} open`,
  payload => payload,
  (_, meta) => meta
);

export const close = createAction(`${subject} close`);
