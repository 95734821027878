import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrap } from './Showing.styled';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import { ReturnToCirculation } from '@crpt-ui/icons/lib';
import ActFields from './ActFields';
import moment from 'moment';
import { Translate } from '../../../common_components/Translate/Translate';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { steps, tabsKZ } from '../../../motp_components/_Requests/RequestList/ActDocument/ActDocument.constants';
import {
  Content,
  PageWrapper,
  StepWrapper,
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';

const Info = ({ showingData, direction, unsubscribe, id, countryCode, onSetStep, show, pgInfo }) => {
  const data = useMemo(() => (showingData ? showingData.data : null), [
    showingData,
  ]);

  let docDate = get(data, 'content.sending_date', null);
  docDate = docDate ? moment(docDate, 'X').format('DD.MM.YYYY') : '-';
  const productGroup = pgInfo ? pgInfo : -1;
  const showRefundButton = useMemo(() => {
    const dateDiff = moment().diff(moment(docDate, 'DD.MM.YYYY'), 'days');
    return (
      data &&
      direction === 'act-out' &&
      [3, 13, 8].includes(data.status) &&
      (productGroup === 3 || productGroup === 11 ? dateDiff <= 5 : dateDiff <= 20) &&
      data.type !== 1100
    );
  }, [data, direction, docDate, productGroup]);

  const tabsCurrent = tabsKZ
    .map(tab =>
      tab.id === steps.info
        ? tab
        : {
          ...tab,
          onClick: () => {
            onSetStep(tab.id);
          },
        },
    );

  return (
    <PageWrapper hide={!show}>
      <StepWrapper>
        <Steps
          value={steps.info}
          items={tabsCurrent}
          variant={'fullWidth'}
        />
      </StepWrapper>
      <Content>
        {data && (
          <ActFields
            data={data}
            id={id}
            docDate={docDate}
            countryCode={countryCode}
            direction={direction}
          />
        )}
        {showRefundButton && (
          <ButtonWrap>
            <Button
              variant="contained"
              startIcon={<ReturnToCirculation/>}
              onClick={() => unsubscribe(id)}
            >
              {Translate('Отозвать')}
            </Button>
          </ButtonWrap>
        )}
      </Content>
    </PageWrapper>
  );
};
Info.propTypes = {
  showingData: PropTypes.object,
  unsubscribe: PropTypes.func,
  direction: PropTypes.string,
  pgInfo: PropTypes.number,
};
export default Info;
