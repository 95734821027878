import React from 'react';
import moment from 'moment';
import Icon from '@crpt/react-icon';
import { get } from 'lodash';
import styled from 'styled-components';
import { OrderId } from '../../../common_components/Table/TableCells/Order/OrderId';
import { generateValuesFromDict } from '../../../common_components/Form/Fields/Select/SingleSelect.utils';
import {
  documentTypeNames,
  documentTypes as docTypes,
  KZdocumentTypes as KZdocTypes,
} from 'src/constants/documentTypes';
import { getIconNameByDocType } from '../../../common_components/Table/TableCells/Document/utils';
import { UZdocumentTypes } from '../../../constants/documentTypes';
import { Translate } from '../../../common_components/Translate/Translate';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';

export const limit = 11;

const DocTypeWrap = styled.div`
  display: flex;
`;

const ListNameWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const SenderNameWrap = styled.div`
  padding-right: 64px;
`;

export const documentName = {
  LP_INTRODUCE_GOODS: 'Ввод в оборот',
  LP_SHIP_GOODS: 'Отгрузка',
  LP_ACCEPT_GOODS: 'Уведомление о приемке товаров ЕАЭС',
  LP_INTRODUCE_GOODS_CSV: 'Ввод в оборот',
  LP_INTRODUCE_GOODS_XML: 'Ввод в оборот',
  LP_SHIP_GOODS_CSV: 'Отгрузка',
  LP_SHIP_GOODS_XML: 'Отгрузка',
  LP_ACCEPT_GOODS_XML: 'Приемка',
  LK_REMARK: 'Перемаркировка',
  LK_REMARK_CSV: 'Перемаркировка',
  LK_REMARK_XML: 'Перемаркировка',
  LK_REGISTRATION: 'Регистрация',
  LK_REGISTRATION_XML: 'Регистрация',
  LK_RESUME_ACCESS: 'Возобновление доступа в ИС МП',
  LK_RESUME_ACCESS_XML: 'Возобновление доступа в ИС МП',
  LK_CHANGE_EMAIL_XML: 'Заявка на изменение',
  LK_CHANGE_EMAIL: 'Заявка на изменение',
  LK_LOAN_RECEIPT: 'Вывод из оборота',
  LK_LOAN_RECEIPT_CSV: 'Вывод из оборота',
  LK_RECEIPT_XML: 'Вывод из оборота',
  LK_BLOCKING: 'Заявка на блокировку',
  LK_BLOCKING_XML: 'Заявка на блокировку',
  LK_RECEIPT_CSV: 'Вывод из оборота',
  LK_RECEIPT: 'Вывод из оборота',
  LK_LOAN_RECEIPT_XML: 'Вывод из оборота',
  UNIVERSAL_TRANSFER_DOCUMENT: 'Отгрузка',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'Отгрузка',
  RECEIPT: 'Вывод из оборота (ОФД)',
  IMPORT: 'Уведомление о ввозе товаров (третьи страны)',
  IMPORT_UZ: 'Уведомление о ввозе (импорт)',
  AIC_AGGREGATION: 'Формирование АИК',
  AIC_DISAGGREGATION: 'Расформирование АИК',
  INVENTORY: 'Уведомление о товарных остатках (производство)',
  TRADE_INVENTORY: 'Уведомление о товарных остатках (опт, розница)',
  CUSTOMS_DECLARATION: 'Сведения о ГТД',
  IMPORT_TRANSIT: 'Уведомление о ввозе товаров (ЕАЭС)',
  LP_GOODS_IMPORT: 'Импорт',
  LP_GOODS_IMPORT_XML: 'Импорт',
  LP_GOODS_IMPORT_CSV: 'Импорт',
  CONTRACT: 'Контракт',
  AGGREGATION_DOCUMENT: 'Агрегирование',
  COR_INFO_CM: 'Корректировка сведений о кодах маркировки',
  LK_CONTRACT_COMMISSIONING: 'Ввод в оборот (контракт)',
  LK_CONTRACT_COMMISSIONING_CSV: 'Ввод в оборот (контракт)',
  LK_CONTRACT_COMMISSIONING_XML: 'Ввод в оборот (контракт)',
  INDI: 'Физ.лицо',
  LK_INDI_COMMISSIONING: 'Ввод в оборот (физ.лицо)',
  LK_INDI_COMMISSIONING_CSV: 'Ввод в оборот (физ.лицо)',
  LK_INDI_COMMISSIONING_XML: 'Ввод в оборот (физ.лицо)',
  LK_KM_CANCELLATION: 'Списание КМ', // (ненанесенные на товар)
  LK_KM_CANCELLATION_XML: 'Списание КМ', // (ненанесенные на товар)
  LK_KM_CANCELLATION_CSV: 'Списание КМ', // (ненанесенные на товар)
  LK_APPLIED_KM_CANCELLATION: 'Списание КМ', // (нанесенные на товар)
  LK_APPLIED_KM_CANCELLATION_XML: 'Списание КМ', // (нанесенные на товар)
  LK_APPLIED_KM_CANCELLATION_CSV: 'Списание КМ', // (нанесенные на товар)
  MTTPS_90: 'Уведомление о выводе из оборота',
  KZ_INTRODUCE_GOODS: 'Уведомление о вводе в оборот',
  UPD_ACT: 'Акт приема-передачи товаров',
  1000: 'Акт приема-передачи товаров',
  KZ_UPD: 'Акт приема-передачи товаров',
  KZ_EXPORT_NOTICE: 'Уведомление об экспорте в ЕАЭС',
  UZ_ADMIT_APPLICATION: 'Заявка на регистрацию кодов ИС МДЛП',
};

export const documentTypes = Object.keys(KZdocTypes)
  .map((v, i) => {
    return {
      id: i,
      title: documentTypeNames[v],
      documentType: docTypes[v],
      type: docTypes[v],
    };
  })
  .filter(v => ![
      docTypes.PAYMENT,
      docTypes.TRANSGRAN_EXPORT,
      docTypes.TRANSGRAN_IMPORT,
      docTypes.RECEIPT,
      docTypes.RECEIPT_RETURN,
      docTypes.EAS_CROSSBORDER,
      docTypes.LP_ACCEPT_GOODS,
    ].includes(v.documentType),
  );

export const documentTypesUZ = Object.keys(UZdocumentTypes)
  .map((v, i) => {
    return {
      id: i,
      title: documentTypeNames[v],
      documentType: docTypes[v],
      type: docTypes[v],
    };
  });

export const documentNameValues = [
  {
    id: 1,
    title: documentName.LP_INTRODUCE_GOODS,
    type: 'LP_INTRODUCE_GOODS,LP_INTRODUCE_GOODS_CSV,LP_INTRODUCE_GOODS_XML',
  },
  {
    id: 2,
    title: documentName.LP_SHIP_GOODS,
    type:
      'LP_SHIP_GOODS,LP_SHIP_GOODS_CSV,LP_SHIP_GOODS_XML,UNIVERSAL_TRANSFER_DOCUMENT,UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED',
  },
  {
    id: 3,
    title: documentName.LP_ACCEPT_GOODS,
    type: 'LP_ACCEPT_GOODS,LP_ACCEPT_GOODS_XML',
  },
  {
    id: 4,
    title: documentName.LK_REMARK,
    type: 'LK_REMARK,LK_REMARK_CSV,LK_REMARK_XML',
  },
  {
    id: 5,
    title: documentName.LK_RECEIPT,
    type: 'LK_RECEIPT,LK_RECEIPT_XML,LK_RECEIPT_CSV',
  },
  {
    id: 5,
    title: 'Вывод из оборота с рассрочкой',
    type: 'LK_LOAN_RECEIPT,LK_LOAN_RECEIPT_CSV,LK_LOAN_RECEIPT_XML',
  },
  { id: 6, title: documentName.RECEIPT, type: 'RECEIPT' },
  {
    id: 7,
    title: documentName.LP_GOODS_IMPORT,
    type: 'LP_GOODS_IMPORT,LP_GOODS_IMPORT_XML,LP_GOODS_IMPORT_CSV',
  },
  {
    id: 8,
    title: documentName.CONTRACT,
    type:
      'LK_CONTRACT_COMMISSIONING,LK_CONTRACT_COMMISSIONING_XML,LK_CONTRACT_COMMISSIONING_CSV',
  },
  {
    id: 9,
    title: documentName.INDI,
    type:
      'LK_INDI_COMMISSIONING,LK_INDI_COMMISSIONING_XML,LK_INDI_COMMISSIONING_CSV',
  },
  {
    id: 10,
    title: documentName.LK_REGISTRATION,
    type: 'LK_REGISTRATION,LK_REGISTRATION_XML',
  },
  {
    id: 11,
    title: documentName.LK_KM_CANCELLATION,
    type:
      'LK_KM_CANCELLATION,LK_KM_CANCELLATION_XML,LK_KM_CANCELLATION_CSV,LK_APPLIED_KM_CANCELLATION,LK_APPLIED_KM_CANCELLATION_CSV,LK_APPLIED_KM_CANCELLATION_XML',
  },
];

const documentReceivedFrom = {
  LP_INTRODUCE_GOODS: 'Ручной ввод',
  LP_SHIP_GOODS: 'Ручной ввод',
  LP_ACCEPT_GOODS: 'Ручной ввод',
  LP_INTRODUCE_GOODS_CSV: 'CSV',
  LP_INTRODUCE_GOODS_XML: 'XML',
  LP_SHIP_GOODS_CSV: 'CSV',
  LP_SHIP_GOODS_XML: 'XML',
  LP_ACCEPT_GOODS_XML: 'XML',
  LK_REMARK: 'Ручной ввод',
  LK_REMARK_CSV: 'CSV',
  LK_REMARK_XML: 'XML',
  LK_REGISTRATION: 'Ручной ввод',
  LK_REGISTRATION_XML: 'XML',
  LK_RESUME_ACCESS: '???',
  LK_RESUME_ACCESS_XML: 'XML',
  LK_CHANGE_EMAIL_XML: 'XML',
  LK_CHANGE_EMAIL: 'Ручной ввод',
  LK_LOAN_RECEIPT: 'Ручной ввод',
  LK_LOAN_RECEIPT_CSV: 'CSV',
  LK_RECEIPT_XML: 'XML',
  LK_BLOCKING: 'Ручной ввод',
  LK_BLOCKING_XML: 'XML',
  LK_RECEIPT_CSV: 'CSV',
  LK_RECEIPT: 'Ручной ввод',
  LK_LOAN_RECEIPT_XML: 'XML',
  UNIVERSAL_TRANSFER_DOCUMENT: 'УПД',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'УПД',
  RECEIPT: 'Чек (ОФД)',
  LP_GOODS_IMPORT: 'Ручной ввод',
  LP_GOODS_IMPORT_CSV: 'CSV',
  LP_GOODS_IMPORT_XML: 'XML',
  LK_INDI_COMMISSIONING: 'Ручной ввод',
  LK_INDI_COMMISSIONING_CSV: 'CSV',
  LK_INDI_COMMISSIONING_XML: 'XML',
  LK_CONTRACT_COMMISSIONING: 'Ручной ввод',
  LK_CONTRACT_COMMISSIONING_CSV: 'CSV',
  LK_CONTRACT_COMMISSIONING_XML: 'XML',
  LK_KM_CANCELLATION_CSV: 'CSV',
  LK_KM_CANCELLATION: 'Ручной ввод',
  LK_KM_CANCELLATION_XML: 'XML',
  LK_APPLIED_KM_CANCELLATION_CSV: 'CSV',
  LK_APPLIED_KM_CANCELLATION: 'Ручной ввод',
  LK_APPLIED_KM_CANCELLATION_XML: 'XML',
};

export const docStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  CHECKED_OK: 'CHECKED_OK',
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  UNDEFINED: 'UNDEFINED',
  CANCELLED: 'CANCELLED',
  ACCEPTED: 'ACCEPTED',
  WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
  WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
};

export const receiptStatus = {
  CHECKED_OK: 'Обработан успешно',
  CHECKED_NOT_OK: 'Ошибка обработки',
};

export const documentStatus = {
  IN_PROGRESS: 'Проверяется',
  CHECKED_OK: 'Обработан успешно',
  CHECKED_NOT_OK: 'Обработан с ошибками',
  PROCESSING_ERROR: 'Ошибка при обработке',
  UNDEFINED: 'Не определен',
  CANCELLED: 'Аннулирован',
  ACCEPTED: 'Принят',
  WAIT_ACCEPTANCE: 'Ожидает приемку',
  // WAIT_PARTICIPANT_REGISTRATION: 'Ожидаем регистрации участника в ИС МП',
};

export const iconNamesByDocStatus = {
  CANCELLED: 'doc-error',
  IN_PROGRESS: 'checking',
  CHECKED_OK: 'issued',
  CHECKED_NOT_OK: 'doc-error',
  PROCESSING_ERROR: 'doc-error',
  UNDEFINED: 'checking',
  ACCEPTED: 'issued',
  WAIT_ACCEPTANCE: 'checking',
};

const documentStatusRegistration = {
  CHECKED_OK: 'Выполнена',
  CHECKED_NOT_OK: 'Отказ',
  IN_PROGRESS: 'На рассмотрении ',
};

export const documentStatusValues = generateValuesFromDict(documentStatus);

export const documentFormat = {
  MANUAL: 'ручной ввод',
  XML: 'xml',
  CSV: 'csv',
  UPD: 'упд',
};

export const documentFormatValues = generateValuesFromDict(documentFormat);

export const documentInputType = {
  true: 'Входящий',
  false: 'Исходящий',
};

export const documentInputTypeValues = generateValuesFromDict(
  documentInputType,
);

export const options = [
  {
    accessor: d => d,
    id: 'id',
    render: (props, rowInfo) => {
      const docStatus = get(rowInfo, 'original.status', '');
      const iconType = get(iconNamesByDocStatus, docStatus, 'checking');
      return <OrderId {...props} iconType={iconType}/>;
    },
  },
  {
    accessor: d => d,
    id: 'docName',
    render: (props, rowInfo) => {
      const docType = get(rowInfo, 'original.type', '');
      return <DocTypeWrap>
        <Icon type={getIconNameByDocType(docType)} spacingRight={12} fill="rgb(82, 83, 90)" size={16}/>
        <div>{docType ? Translate(documentTypeNames[docType] || docType) : '-'}</div>
      </DocTypeWrap>;
    },
  },
];

// export const columns = [
//   {
//     Header: 'Регистрационный номер',
//     id: 'id',
//     accessor: 'id',
//     width: 250,
//     style: {
//       position: 'relative',
//       overflow: 'visible',
//       padding: '0',
//     },
//   },
//   {
//     Header: 'Дата загрузки документа',
//     id: 'docDate',
//     accessor: d => `${moment(new Date(d.docDate)).format('DD.MM.YYYY')}`,
//     width: 105,
//   },
//   {
//     Header: 'Тип документа',
//     id: 'type',
//     accessor: ({ type }) => {
//       if (type === 'KZ_UPD') {
//
//       } else {
//         return '-';
//       }
//     },
//     width: 200,
//   },
//   {
//     Header: 'Наименование документа',
//     id: 'docName',
//     accessor: 'type',
//     width: 300,
//   },
//   {
//     Header: 'Способ получения',
//     id: 'deliveryType',
//     accessor: 'deliveryType',
//     width: 200,
//   },
//   {
//     Header: 'Участник(и)',
//     id: 'senderName',
//     accessor: d => (<ListNameWrap>
//       <SenderNameWrap>{d.senderName}</SenderNameWrap>
//       <SenderNameWrap>{d.receiverName}</SenderNameWrap>
//       </ListNameWrap>),
//     minWidth: 400,
//   },
//   {
//     Header: 'Статус обработки',
//     id: 'errors',
//     accessor: 'errors',
//     width: 240,
//     sortable: false,
//   },
//   {
//     Header: 'Доп.сведения',
//     id: 'errors',
//     accessor: 'errors',
//     width: 240,
//     sortable: false,
//   },
// ];

export const getColumns = (inn) => {
  return [
    {
      Header: Translate('Регистрационный номер'),
      id: 'id',
      accessor: 'id',
      width: 250,
      style: {
        position: 'relative',
        overflow: 'visible',
        padding: '0',
      },
    },
    {
      Header: Translate('Дата загрузки документа'),
      id: 'receivedDate',
      accessor: d => `${moment(new Date(d.receivedDate)).format('DD.MM.YYYY')}`,
      width: 140,
    },
    // {
    //   Header: Translate('Тип документа'),
    //   id: 'type',
    //   accessor: ({ type, senderInn, receiverInn }) => {
    //     if (type === 'KZ_UPD') {
    //       return senderInn === inn
    //         ? Translate('Исходящий')
    //         : receiverInn === inn
    //         ? Translate('Входящий')
    //         : '-';
    //     } else {
    //       return '-';
    //     }
    //   },
    //   width: 200,
    // },
    {
      Header: Translate('Наименование документа'),
      id: 'docName',
      accessor: 'type',
      width: 300,
    },
    // {
    //   Header: Translate('Способ получения'),
    //   id: 'deliveryType',
    //   accessor: d => d.deliveryType || '-',
    //   width: 200,
    // },
    {
      Header: Translate('Отправитель'),
      id: 'senderName',
      accessor: d => (<ListNameWrap>
        <SenderNameWrap>{d.senderName || '-'}</SenderNameWrap>
      </ListNameWrap>),
      minWidth: 250,
    },
    {
      Header: Translate('Получатель'),
      id: 'receiverName',
      accessor: d => (<ListNameWrap>
        <SenderNameWrap>{d.receiverName || '-'}</SenderNameWrap>
      </ListNameWrap>),
      minWidth: 250,
    },
    {
      Header: Translate('Статус обработки'),
      id: 'status',
      accessor: ({ status }) => {
        const iconType = get(iconNamesByDocStatus, status, 'checking');
        return <DocTypeWrap>
          <Icon type={iconType} spacingRight={12} fill="rgb(82, 83, 90)" size={16}/>
          <div>{Translate(documentStatus[status])}</div>
        </DocTypeWrap>;
      },
      width: 240,
      sortable: false,
    },
    {
      Header: Translate('Доп.сведения'),
      id: 'errors',
      accessor: d => {
        let gtkApprovedResult = '';
        if (d.type === docTypes.AIC_AGGREGATION && d.status === "CHECKED_OK" && !d.gtkApproved && d.gtkApproved !== null) {
          gtkApprovedResult = 'Не принят в ГТК';
        }
        if (d.type === docTypes.AIC_AGGREGATION && d.status === "CHECKED_OK" && d.gtkApproved === null) {
          gtkApprovedResult = 'Ожидание ответа ГТК';
        }
        if (d.type === docTypes.AIC_AGGREGATION && d.status === "CHECKED_OK" && d.gtkApproved) {
          gtkApprovedResult = 'Принят в ГТК';
        }
        const error = d.errors && d.errors.length > 0 ? d.errors.map(el => el.errorMessage).join(' ') : '';
        const words = error.split(' ');
        const errors = error.length > 18 ? (
          <Tooltip
            text={words.map((el, i) => `${el} ${words[i + 1]}`.length > 18 ? `${el}\n` : el).join(' ')}
            setMaxWidth={240}
            textComponent={
              <div>{error.length > 18 ? `${error.slice(0, 18)}...` : error}</div>
            }
            textColor="#55b5e7"
            interactive
            cursorPointer={false}
            noWrap
          />
        ) : error;
        return gtkApprovedResult === '' && errors === ''
            ? '-'
            : (
              <div>
                {gtkApprovedResult}
                {errors}
              </div>
            )
      },
      width: 240,
      sortable: false,
    },
  ];
};

export const COMMON_EXCLUDING_TYPES = [
  'LK_ADD_APP_USER',
  'LK_ADD_APP_USER_XML',
  'GRAY_ZONE_DOCUMENT',
  'LP_FTS_INTRODUCE_REQUEST',
];

export const KZ_EXCLUDING_TYPES = [
  ...COMMON_EXCLUDING_TYPES,
  'EAS_CROSSBORDER',
  'LP_ACCEPT_GOODS',
  'KZ_EXPORT_NOTICE',
  'KZ_EXPORT_NOTICE_ACCEPTION',
];
