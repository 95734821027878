import { makeCell } from '@crpt-ui/datatable';
import { makeInputFilter, makeRangeDatepickerFilter, makeDatepickerFilter } from '@crpt-ui/datatable/lib/Filters';
import { Box } from '@material-ui/core';
import React from 'react';
import { Link } from 'src/fragments/Link/Link';
import { Value } from 'src/fragments/Table/Cell/value';

import {
  documentStatusColorsMap,
  documentStatusIconsMap,
  documentStatusValuesMap,
  reportStatusColorsMap,
  reportStatusIconsMap,
  reportStatusValuesMap,
} from '../constants';
import { getShortDateStringFromStamp } from '../utils';
import { DocumentStatus, ReportStatus } from './filters';
// import {getLinkValue} from './GrayAreaTable.utils';
import { Translate } from '../../../common_components/Translate/Translate';

const PeriodCell = periodKey => ({ cell }) => {
  const keyValue = cell.value[periodKey];
  const [ date, time ] = keyValue
    ? getShortDateStringFromStamp(keyValue).split(',')
    : [ '', '' ];

    return (
    <Value>
      <div>{date}</div>
      <Box fontSize={12} color="text.secondary">{time}</Box>
    </Value>
  );
};

export const columns = {
  createDate: {
    accessor: 'createDate',
    Cell: ({ cell }) => {
      const [ data ] = getShortDateStringFromStamp(cell.value).split(',');
      return <Value>{data}</Value>;
    },
    Filter: makeRangeDatepickerFilter({
      maxDate: new Date()
    }),
    Header: Translate('Дата документа'),
    id: 'createDate',
    width: 220
  },
  id: {
    accessor: 'id',
    Cell: ({ cell }) => {
      const documentId = encodeURIComponent(cell.value);
      return (
        <Link to={`/gray-area/${documentId}`}>
          {cell.value}
        </Link>
      );
    },
    Filter: makeInputFilter({
      label: 'Номер документа',
    }),
    Header: 'Номер документа',
    id: 'id',
  },
  closingPeriodFrom: {
    accessor: 'closingPeriod',
    Cell: PeriodCell('from'),
    Filter: makeDatepickerFilter({
      maxDate: new Date()
    }),
    Header: Translate('Начало периода'),
    id: 'from',
    width: 220
  },
  closingPeriodTo: {
    accessor: 'closingPeriod',
    Cell: PeriodCell('to'),
    Filter: makeDatepickerFilter({
      maxDate: new Date(),
      endOfDay: true,
    }),
    Header: Translate('Окончание периода'),
    id: 'to',
    width: 220
  },
  periodClosingDocumentStatus: {
    accessor: 'periodClosingDocumentStatus',
    Cell: makeCell({
      colors: documentStatusColorsMap,
      icons: documentStatusIconsMap,
      values: documentStatusValuesMap,
    }),
    Filter: DocumentStatus,
    Header: Translate('Статус документа'),
    id: 'periodClosingDocumentStatus',
    width: 180
  },
  reportLink: {
    accessor: 'reportLink',
    Header: Translate('Файл выгрузки'),
    id: 'reportLink',
    disableSortBy: true,
  },
  reportStatus: {
    accessor: 'reportStatus',
    Cell: makeCell({
      colors: reportStatusColorsMap,
      icons: reportStatusIconsMap,
      values: reportStatusValuesMap,
    }),
    Filter: ReportStatus,
    Header: Translate('Статус выгрузки'),
    id: 'reportStatus',
    width: 180
  },
};
