import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import Breadcrumbs from '../../../../common_components/Breadcrumbs/Breadcrumbs';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import Form from '../../../../common_components/Form/Form';
import Preloader from '../../../../common_components/Preloader/Preloader';
import FormContextWrapper from '../../../../common_components/Form/FormContextWrapper';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import ContentWrapper from '../../../../common_components/styled/ContentWrapper';
import Goods from './Goods';
import Info from './Info';
import Total from './Total';
import { Content, PageWrapper, StepWrapper } from '../Document/Document.styled';
import { tabsKG, tabsKZ, steps } from './DocumentWrapperConstants';
import * as selectors from '../ActDocument/ducks/ActDocument.selectors';
import { MaterialUiThemeUZ } from '../../../../common_components/_MaterialUi/ThemeUZ';


const DocumentWrapper = (props) => {
  const { breadcrumbs, countryCode, loaded, isFixed = false, fieldsInfo, needAWarningAboutTheGoodsRecount } = props; // fieldsList
  const { isCreate, isLoading, onSubmit, onSaveDraft, codesCanBeChanged, actionSelectOptions } = props;
  const { match, title, disabledMenu, thereAreTotals, docType, isCorrection = false, productGroupIdForGoods } = props;
  const { needCancelButton, needDraftButton, data, errorsCountFunc, goodsEditComponent, thereAreCodes = true } = props;
  const { filterPackageTypeForGoods, needViewActionsOfGoods = true, additionalBottomBar, needStepWrapper = true } = props;
  const { rightDraftButton = true, finalConfirmModal = false, pageSizeInGoods = 10, emptyItemText } = props;
  const { wrapperForTableInFinalConfirmModal, changeTabsFunc, checkSignInGoodsDisabled } = props;
  const { id } = match.params;
  const isDraft = /draft-/.test(id);
  const isCorrect = id === 'correct';

  const [step, setStep] = useState(steps.info);
  // const [step, setStep] = useState('info'); // info goods total
  const [goodsChanged, setGoodsChanged] = useState(false);
  const [errorListInfo, setErrorListInfo] = useState({});
  const [errorListGoods, setErrorGoods] = useState({});
  const [errorListTotals, setErrorTotals] = useState({});

  const handleSaveDraft = useCallback(values => {
    onSaveDraft({ match, values });
  },[onSaveDraft, match]);

  const countTabsCurrent = () => {
    const isDisabled = (id) => {
      if ((tabs[id - 1] && tabs[id - 1].id === steps.info) && Object.keys(errorListInfo).length > 0) return true
      if ((tabs[id - 1] && tabs[id - 1].id === steps.goods) && errorListGoods.cisErrors !== null) return true
      return (tabs[id - 1] && tabs[id - 1].id === steps.totals) && Object.keys(errorListTotals).length > 0;
    }
    const currentTabs = countryCode === 'KZ' ? tabsKZ : tabsKG;

    const tabs = currentTabs
      .map(changeTabsFunc ? changeTabsFunc : (el) => el)
      .filter(el => {
      if (!thereAreTotals && !thereAreCodes) return el.id !== 2 && el.id !== 1;
      if (!thereAreCodes) return el.id !== 1;
      if (!thereAreTotals) return el.id !== 2;
      return el
    })
    return tabs
      .map(tab => ({
          ...tab,
          onClick: () => {
            setStep(tab.id);
          },
          disabled: isDisabled(tab.id)
        })
      )
  };

  const currentTabs = useMemo(countTabsCurrent,[errorListInfo, errorListTotals, errorListGoods])

  return (
    <MuiThemeProvider
      theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
    >
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <Form
        data={data}
        // onSubmit={onSubmit}
        disabled={!isCreate && !loaded && !isCorrection && !isFixed && !isDraft}
      >
        {isLoading && (<Preloader/>)}
        {!isLoading && (
            <PageWrapper>
              <ContentWrapper>
                {
                  needStepWrapper && (
                    <StepWrapper>
                      <Steps
                        value={step}
                        items={currentTabs}
                        variant={'fullWidth'}
                      />
                    </StepWrapper>
                  )
                }
                <Content>
                  <FormContextWrapper>
                  <Info
                    finalConfirmModal={finalConfirmModal}
                    thereAreCodes={thereAreCodes}
                    thereAreTotals={thereAreTotals}
                    title={title}
                    typeDoc={docType}
                    loaded={loaded}
                    onSetStep={setStep}
                    onSaveDraft={handleSaveDraft}
                    show={step === steps.info}
                    errorsCountFunc={errorsCountFunc}
                    FieldsInfo={fieldsInfo}
                    isDraft={isDraft}
                    isCorrect={isCorrect}
                    needDraftButton={needDraftButton}
                    handleChangeInfoErrors={setErrorListInfo}
                    needCancelButton={needCancelButton}
                    onSubmit={onSubmit}
                    rightDraftButton={rightDraftButton}
                  />
                  {thereAreCodes ? (
                    <Goods
                      WrapperForTableInFinalConfirmModal={wrapperForTableInFinalConfirmModal}
                      title={title}
                      emptyItemText={emptyItemText}
                      fieldsInfo={fieldsInfo}
                      finalConfirmModal={finalConfirmModal}
                      checkSignInGoodsDisabled={checkSignInGoodsDisabled}
                      AdditionalBottomBar={additionalBottomBar}
                      filterPackageTypeForGoods={filterPackageTypeForGoods}
                      loaded={loaded}
                      goodsChanged={goodsChanged}
                      onSetStep={setStep}
                      onSaveDraft={handleSaveDraft}
                      needDraftButton={needDraftButton}
                      show={step === steps.goods}
                      typeDoc={docType}
                      handleGoodsChange={() => setGoodsChanged(true)}
                      isDraft={isDraft}
                      isCorrect={isCorrect}
                      disabledMenu={disabledMenu}
                      needCancelButton={needCancelButton}
                      codesCanBeChanged={codesCanBeChanged}
                      needAWarningAboutTheGoodsRecount={needAWarningAboutTheGoodsRecount}
                      onSubmit={onSubmit}
                      actionSelectOptions={actionSelectOptions}
                      GoodsEditComponent={goodsEditComponent}
                      productGroupIdForGoods={productGroupIdForGoods}
                      needViewActionsOfGoods={needViewActionsOfGoods}
                      pageSizeInGoods={pageSizeInGoods}
                    />
                  ): <div />}
                  {
                    thereAreTotals ? (
                      <Total
                        title={title}
                        finalConfirmModal={finalConfirmModal}
                        data={null}
                        goodsChanged={goodsChanged}
                        loaded={loaded}
                        onSetStep={setStep}
                        onSaveDraft={onSaveDraft}
                        isCorrection={false}
                        isFixed={isFixed}
                        isDraft={isDraft}
                        direction={id}
                        show={step === steps.totals}
                        id={id}
                        status={null}
                        mode={'editedAct'}
                      />
                    ) : <div/>
                  }
                  </FormContextWrapper>
                </Content>
              </ContentWrapper>
            </PageWrapper>
        )}
      </Form>
    </MuiThemeProvider>
  );
};


DocumentWrapper.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // fieldsInfo: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number.isRequired,
  //   isCountrySelect: PropTypes.bool.isRequired,
  //   forceValidate: PropTypes.bool,
  //   placeholder: PropTypes.string,
  //   disabled: PropTypes.bool,
  //   name: PropTypes.string.isRequired,
  //   required: PropTypes.bool,
  //   eas: PropTypes.bool,
  //   validate: PropTypes.bool,
  //   onSelect: PropTypes.func,
  // })).isRequired,
  fieldsInfo: PropTypes.element,
  onSaveDraft: PropTypes.func,
  match: PropTypes.object.isRequired,
  productGroupIdForGoods: PropTypes.number,
  filterPackageTypeForGoods: PropTypes.string,
};

const mapState = (state) => ({
  countryCode: state.config.countryCode,
  isLoading: preloaderSelectors.loading(state),
  loaded: selectors.loaded(state),
});
const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(DocumentWrapper);
