import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Modal from 'react-modal';
import { withTheme } from 'styled-components';
import { get } from 'lodash';

import { Form, FormLine } from '@crpt/react-form';
import { InlineWrap } from '@crpt/react-page-layout';
import { Input } from '@crpt/react-input';
import { Button } from '@crpt/react-button';

import { ModalClose, GTINWrap } from './styled';
import { modalStyle } from './constants';
import { Translate } from '../../../common_components/Translate/Translate';

Modal.setAppElement('body');

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  productListActions: PropTypes.shape({
    createProduct: PropTypes.func,
    listMounted: PropTypes.func
  }).isRequired,
  theme: PropTypes.object.isRequired
};

const ModalWindow = ({
  isModalOpen,
  onCloseModal,
  productListActions: { createProduct, listMounted },
  theme,
  history
}) => {
  const [val, setVal] = useState('')

  const submitButtonTheme = {
    Button: get(theme, 'anyButton.modalAcceptButton', {})
  };
  const cancelButtonTheme = {
    Button: get(theme, 'anyButton.modalCancelButton', {})
  };

  function onChange (val) {
    setVal(val)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      style={modalStyle}
      contentLabel={Translate("Добавление товара")}
    >
      <FormLine>
        {Translate('Добавление товара')}
        <InlineWrap right flex>
          <ModalClose onClick={onCloseModal}>✖</ModalClose>
        </InlineWrap>
      </FormLine>

      <Form
        onSubmit={vals => {
          createProduct(vals, entity => {
            onCloseModal();
            //listMounted();
            history.push(`/products/list/${entity.id}`);
          })
        }}
      >
        <FormLine>
          <GTINWrap>
            <Input
                name="gtin"
                placeholder={val.length === 0 || val.length === 14 ? 'GTIN' : val.length > 14 ? Translate('Не должно быть больше 14 символов') : Translate('GTIN должен содержать 14 символов')}
                isError={val.length > 14}
                width="100%"
                onChange={onChange}
            />
          </GTINWrap>
        </FormLine>

        <FormLine>
          <Button marginRight={20} action="clear" theme={cancelButtonTheme}>
            {Translate('Сбросить')}
          </Button>
          <Button
              disabled={val.length !== 14}
              type="submit"
              theme={submitButtonTheme}
          >
            {Translate('Добавить')}
          </Button>
        </FormLine>
      </Form>
    </Modal>
  );
};

ModalWindow.propTypes = propTypes;

export default withTheme(withRouter(ModalWindow));
