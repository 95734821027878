import React, { Component } from 'react';
import {
  InfoBlock,
  Row,
  CircleNumberYellow,
  DetailText,
  Visual,
} from './CommonDescription.styled';
import data from './CommonDescription.data';

export default class CommonDescription extends Component {
  render() {
    return (
      <div>
        {
          data.map((item, i) => 
            <InfoBlock key={i}>
              <Row>
              <CircleNumberYellow>{i + 1}</CircleNumberYellow>
              <DetailText>
                {item.text}
              </DetailText>
              </Row>
              <Visual>
                {item.visual}
              </Visual>
            </InfoBlock>
          )
        }
      </div>
    );
  }
}
