import React from 'react';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import { Translate } from '../Translate/Translate';

const FiltersButton = props => (
  <Button variant="contained" {...props}>
    <Icon type="black-filter" spacingRight={16} /> {Translate('Фильтр')}
  </Button>
);

export default FiltersButton;
