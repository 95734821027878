import styled from 'styled-components';

export const ResetApplyWrapper = styled.div`
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 10px;
  position: relative;
  ::before {
    display: block;
    background-color: white;
    width: 100%;
    height: 9px;
    top: -5px;
    left: 0;
    content: '';
    position: absolute;
  }
`
export const ResetApplyBtns = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const ResetApplyResetBtn = styled.div`
  padding: 0 19px;
  font-size: 16px;
  font-weight: 400;
`