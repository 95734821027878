import { LightTheme } from '@crpt-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LightCrptTheme  from 'src/common_components/_MaterialUi/LightTheme';

import GrayAreaCreate from './GrayAreaCreate/GrayAreaCreate';
import { GrayAreaDocument } from './GrayAreaDocument/GrayAreaDocument';
import { GrayAreaTable } from './GrayAreaTable';
import { createMuiTheme } from '@material-ui/core/styles';

const MergedLightTheme = createMuiTheme({
  ...LightTheme, palette: {
    ...LightCrptTheme.palette,
    ...LightTheme.palette
  }
})

const GrayArea = () => {
  return (
    <MuiThemeProvider theme={MergedLightTheme}>
      <Switch>
        <Route
          exact
          path="/gray-area"
          render={(props) => <GrayAreaTable {...props}/>}
        />
        <Route
          exact
          path="/gray-area-create"
          render={(props) => <GrayAreaCreate {...props}/>}
        />
        <Route
          path="/gray-area/:id"
          render={(props) => <GrayAreaDocument {...props}/>}
        />
      </Switch>
    </MuiThemeProvider>
  );
};

export default GrayArea;
