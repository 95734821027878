import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Datatable from '@crpt-ui/datatable';
import { Box, Grid, Typography, MuiThemeProvider } from '@material-ui/core';
import { Close } from '@crpt-ui/icons';

import withForm from '../../../../../common_components/hocs/withForm';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import CommonActionButton from '../../../../../common_components/Buttons/MuiCommonActionMediumButton';
import PrimaryActionButton from '../../../../../common_components/Buttons/MuiPrimaryActionMediumButton';
import LightTheme from '../../../../../common_components/_MaterialUi/LightTheme';

import {
  validateDate,
  validateNumberAfterDotRequiredAndNotEmpty,
} from '../../ImportDocument/ImportDocument.validations';
import { goodsKey } from '../ActDocument.constants';
import useStyles from './Goods.styled';
import { MaterialUiThemeKZ } from '../../../../../common_components/_MaterialUi/ThemeKZ';
import { connect } from 'react-redux';
import { required } from '../../../../../common_components/Form/utils/validations';
import CountrySelect from '../../../../../common_components/Form/MaterialFields/Select/CountrySelect';
import { Translate } from 'src/common_components/Translate/Translate';

const GoodsEditProduct = ({
  onCloseModal,
  formValues,
  formErrors,
  selectedRows,
  // onCancel,
  onReset,
  // preparedColumns,
  title = '',
  // countryCode,
}) => {
  const classes = useStyles();
  // const [isTableOpen, setIsTableOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const index = +selectedRows[rowIndex];

  const hasErrors = () => {
    if (!formValues[goodsKey] || !formValues[goodsKey][index]) return true;
    if (
      formErrors[goodsKey] &&
      formErrors[goodsKey][index] &&
      Object.keys(formErrors[goodsKey][index]).length > 0
    )
      return true;
    return false;
  };

  // const [prevValue, setValue] = useState({ ...formValues[goodsKey][index] });
  const isError = hasErrors();

  // const selectedProducts = selectedRows.map(
  //   index => formValues[goodsKey][+index],
  // );

  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <div className={classes.content}>
        <div className={classes.iconCloseWrapper}>
          <Close
            onClick={() => {
              onReset();
              onCloseModal();
            }}
          />
        </div>
        <Typography variant="h2" color="textPrimary">
          {title}
        </Typography>

        <Box mx={-4} mt={2} mb={4}>
          <div className={classes.divider} />
        </Box>

        <Box color="text.primary" fontSize={14} mb={2}>
          {Translate(
            'Заполните уникальные параметры для выбранных товаров (Заполнено товаров:',
          ) +
            (rowIndex + 1) +
            Translate('из') +
            selectedRows.length}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              required
              name={`[products][${index}]name`}
              placeholder={Translate('Наименование товара')}
              disabled
              marginBottom="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              disabled
              name={`[products][${index}]gtin`}
              placeholder="GTIN"
              marginBottom="0"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              disabled
              name={`[products][${index}]tnved`}
              placeholder={Translate('Код ТНВЭД')}
              marginBottom="0"
            />
          </Grid>
          <Grid item xs={6}>
            <CountrySelect
              forceValidate
              placeholder={Translate('Страна отправления')}
              name={`[products][${index}]country`}
              required
              eas={false}
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required
              forceValidate
              name={`[products][${index}]cert_number`}
              placeholder={Translate('Номер сертификата')}
              marginBottom="0"
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              required
              name={`[products][${index}]cert_date`}
              placeholder={Translate('Дата сертификата')}
              maxDate={new Date()}
              validate={(...vals) => {
                return validateDate(...vals, 'DD.MM.YYYY');
              }}
              marginBottom="0"
            />
          </Grid>
        </Grid>

        {/* <DataTitleWrap>
            <DataTitleNameWrap>
              {`Товары (${selectedRows.length} шт.)`}
              <DataIcoWrap>
                <Icon
                  type={isTableOpen ? 'up-arrow' : 'down-arrow'}
                  color={'#63666a'}
                  onClick={e => {
                    setIsTableOpen(!isTableOpen);
                  }}
                />
              </DataIcoWrap>
            </DataTitleNameWrap>
          </DataTitleWrap> */}
      </div>
      {/* {isTableOpen && (
          <TableWrap>
            <Datatable columns={preparedColumns} data={selectedProducts} />
          </TableWrap>
        )} */}

      <Box mt={4}>
        <div className={classes.divider} />
      </Box>

      <div className={classes.buttonsWrapper}>
        <PrimaryActionButton
          disabled={isError}
          onClick={
            rowIndex === selectedRows.length - 1
              ? onCloseModal
              : e => {
                  setRowIndex(rowIndex + 1);
                  // setValue({
                  //   ...formValues[goodsKey][+selectedRows[rowIndex + 1]],
                  // });
                }
          }
        >
          {rowIndex === selectedRows.length - 1
            ? Translate('Сохранить и завершить')
            : Translate('Продолжить')}
        </PrimaryActionButton>
        {/* <CommonActionButton disabled={isError}>Пропустить</CommonActionButton> */}
        <CommonActionButton
          onClick={() => {
            // if (rowIndex < 1) {
            onReset();
            onCloseModal();
            // } else {
            //   onCancel(prevValue, index);
            //   setRowIndex(rowIndex - 1);
            //   setValue({ ...formValues[goodsKey][+selectedRows[rowIndex - 1]] });
            // }
          }}
        >
          {Translate('Отменить')}
        </CommonActionButton>
      </div>
    </MuiThemeProvider>
  );
};

GoodsEditProduct.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  // onCancel: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  formErrors: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  // preparedColumns: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const mapState = state => ({
  countryCode: state.config.countryCode,
});
export default compose(connect(mapState), withForm)(GoodsEditProduct);
