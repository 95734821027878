import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import LightTheme from '../../../../common_components/_MaterialUi/LightTheme';
import { Translate } from '../../../../common_components/Translate/Translate';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import { ExportNotificationToTheEaesFields } from './ExportNotificationToTheEaesFields';
import { actionSelectOptions, cisKey, commodityGroup, goodsKey, steps } from './ExportNotificationToTheEaes.constants';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as selectors from './ducks/ExportNotificationToTheEaes.selectors';
import * as actions from './ducks/ExportNotificationToTheEaes.actions';
import { APP_LINKS, documentTypes } from '../../../../constants';
import GoodsEditProduct from './GoodsEditProduct';
import { Toast } from '../../../../utils/Toast';


const ExportNotificationToTheEaes = (props) => {
  const { countryCode, loaded, isLoading, docType, onSubmit, onSaveDraft, match } = props;
  const { data, user, onLoadDraft, onMount, setDocType } = props;
  const { id } = match.params;
  const [step, setStep] = useState(steps.info);

  useEffect(()=> {
    if (match.path.includes(APP_LINKS.ExportNotificationToTheEaes)) setDocType(documentTypes.KZ_EXPORT_NOTICE);
    if (/draft-/.test(id)) {
      onLoadDraft(id.replace(/draft-/, ''));
    } else if (id !== 'create') {
      onMount(encodeURIComponent(id));
    }
  },[onMount, onLoadDraft, id])

  const errorsCount = ({ formErrors }) => {
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    return Object.keys(prepared).length;
  };

  const handleOnSubmit = (values) => {
    const approve = values.products.reduce((acc, item) => {
      if (!item.cis) return false;
      // if (!item.documentList[0].docKind || !item.documentList[0].docNumber || !item.documentList[0].docDate) return false;
      // return !(item.documentList[1] && (!item.documentList[1].docKind || !item.documentList[1].docNumber || !item.documentList[1].docDate)) && acc;
      return acc;
    }, true)
    if (approve) onSubmit(values)
    else {
      Toast.showError({
        content: "В списке присутствуют коды без информации о документах или без цены",
      });
    }
  };

  return (
    <Fragment>
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
      >
        <DocumentWrapper
          loaded={loaded}
          step={step}
          match={match}
          productGroupIdForGoods={2}
          isCreate={id === 'create'}
          actionSelectOptions={actionSelectOptions}
          goodsEditComponent={GoodsEditProduct}
          breadcrumbs={[
            {
              href: '/documents/list',
              title: Translate('Документы'),
              id: 1,
            },
            {
              title: Translate('Уведомление об экспорте в ЕАЭС'),
              id: 2,
              ...id !== 'create' ? { href: `/documents/list/${id}` } : {}
            },
          ]}
          countryCode={countryCode}
          isLoading={isLoading}
          onSaveDraft={onSaveDraft}
          data={id === 'create' ? {
            sender_inn: user.inn,
            sender_name: user.company.shortName,
            sender_country: "",
            recipient_inn: "",
            document_number: null,
            recipient_name: "",
            currencyCode: "643",
            ...commodityGroup.length === 1 && {commodityGroup: commodityGroup[0].value}
          } : /draft-/.test(id)
            ? data
            : ({})}
          onSetStep={setStep}
          docType={docType}
          codesCanBeChanged={true}
          fieldsInfo={ExportNotificationToTheEaesFields}
          errorsCountFunc={errorsCount}
          needAWarningAboutTheGoodsRecount={false}
          needCancelButton={true}
          needDraftButton={true}
          onSubmit={handleOnSubmit}
          title='Уведомление об экспорте в ЕАЭС'
          filterPackageTypeForGoods="UNIT"
        />
      </MuiThemeProvider>
    </Fragment>
  )
}
const mapState = state => ({
  data: {
    ...selectors.data(state),
  },
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  docType: selectors.docType(state),
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
  user: authSelectors.userInfo(state),
});
const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  setDocType: data => dispatch(actions.setDocType(data)),
});
export default connect(mapState, mapDispatch)(ExportNotificationToTheEaes);
