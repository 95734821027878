import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@crpt-ui/icons';
import Datatable from '@crpt-ui/datatable';
import { Box } from '@material-ui/core';


import { columnsTable } from './Production.constants';
import { ProductionEmpty, ProductionEmptyText, ProductionTableWrap } from './Production.styled';
import { ProductionLineListItemType } from './ProductionType';
import { Translate } from '../../../common_components/Translate/Translate';

export const ProductionView = ({
  onRowClick,
  productionLines,
  datatableActions,
}) => (
  <ProductionTableWrap data-role="content-wrapper">
    <Fragment>
      {productionLines && productionLines.length > 0 ? (
        <Box >
          <Datatable
            columns={columnsTable}
            data={productionLines}
            onRowClick={onRowClick}
            actions={datatableActions}
          />
        </Box>
      ) : (
        <ProductionEmpty>
          <Icons.ProductionSite style={{ fontSize: 100 }} />
          <ProductionEmptyText>
            {Translate('У данного участника нет производственных площадок')}
          </ProductionEmptyText>
        </ProductionEmpty>
      )}
    </Fragment>
  </ProductionTableWrap>
);

ProductionView.propTypes = {
  productionLines: PropTypes.arrayOf(ProductionLineListItemType).isRequired,
  datatableActions: PropTypes.array.isRequired,
};
