export const APP_LINKS = {
  NotificationOfCancellation: 'notification_cancellation',
  NotificationOfEntry: 'notification_entry',
  ImportThirdCountry: 'import',
  ImportThirdCountryKG: 'import-third-countries',
  ImportThirdCountryUZ: 'third-countries-import',
  Eaes_act: 'eaes_act',
  Eaes_import: 'eaes_import',
  Discrepancy_notice: 'discrepancy_notice',
  ExportNotificationToTheEaes: 'exportNotificationToTheEaes',
  AcceptanceNotificationOfGoodsByTheEAES: 'acceptance_notification_of_goods_by_the_EAES',
  CisRegistry: 'cis_registry',
  aik: 'aik',
  aik_disaggregation: 'aik_disaggregation',
  aggregation: 'aggregation',
  inventory: 'inventory',
  tradeInventory: 'tradeInventory',
  corInfoCm: 'corinfocm'
}
