import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as _ from 'lodash';

import ProductionLinesModalView from './ProductionLinesModal.view';
import { getSelectedRows } from '../ProductionLines.constants';

const ProductionLinesModal = ({
  allProductionLines,
  selectedProductionLines,
  addSelectedLines,
  onClose,
}) => {
  const instance = useRef();

  const [isAddDisabled, setAddDisabled] = useState(true);

  const manualDataKey = 'productionLine';
  
  const setCustomIdsToRows = row => row[manualDataKey];

  const initSelectedRowIds = Object
    .fromEntries(selectedProductionLines
      .map(item => item[manualDataKey])
      .map(id => [id, true])
    );

  const initialDatatableState = {
    selectedRowIds: initSelectedRowIds,
  };

  const onRowSelect = selectedRows => {
    const isInitialAndSelectedEqual = _.isEqual(
      selectedRows, initSelectedRowIds
    );
    setAddDisabled(isInitialAndSelectedEqual);
  };

  const onAddSelectedLines = () => {
    const selected = getSelectedRows(instance);
    addSelectedLines(selected);
  };

  return (
    <ProductionLinesModalView
      ref={instance}
      onClose={onClose}
      isAddDisabled={isAddDisabled}
      onAddSelectedLines={onAddSelectedLines}
      allProductionLines={allProductionLines}
      onRowSelect={onRowSelect}
      setCustomIdsToRows={setCustomIdsToRows}
      initialDatatableState={initialDatatableState}
      total={allProductionLines.length}
    />
  );
};

ProductionLinesModal.propTypes = {
  allProductionLines: PropTypes.array.isRequired,
  selectedProductionLines: PropTypes.array.isRequired,
  addSelectedLines: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ProductionLinesModal;
