import styled from 'styled-components';


const ButtonWrapper = styled.div`
  margin-right: 16px;
  :last-child {
    margin-right: 0px;
  };
`;

export default ButtonWrapper;
