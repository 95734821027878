import styled from 'styled-components';

export const AicGoodsTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: #232c40;
  padding-left: 2px;
  span {
    font-size: 18px;
    font-weight: 500;
    color: rgb(99, 102, 106);
  }
`;

export const TableRoot = styled.div`
  width: 100%;
  .rt-td {
    min-height: 40px;
  }
`

export const AicGoodsAic = styled.div`
  padding: 10px 0 10px;
`;
