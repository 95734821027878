/* eslint-disable */
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import { Toast } from 'src/utils/Toast';
import { replace } from 'react-router-redux';
import * as actions from './ActDocument.actions';
import * as requestListActions from '../../ducks/RequestList.actions';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import Api from 'src/common_components/Api/Api';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import { NCALayerService } from 'src/common_components/Ncalayer/NCALayerService';
import * as draftSelectors from '../../Draft/ducks/Draft.selectors';
import { history } from '../../../../../store';

function* mountSaga(action) {
  const { payload } = action;
  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id: payload },
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
        __form_document_status: success.data.status,
      }),
    );
    yield put(actions.setError(success.data.downloadDesc));
  }
  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* uploadSaga(action, state) {
  const message = 'Файл заявки на ввод товара в оборот (импорт) загружен';
  const url = '/api/v3/facade/doc/upload';
  const userInfo = yield select(authSelectors.userInfo);
  const properties = {
    document_type: 'UPD_ACT',
    user_id: userInfo.id,
    organisation_inn: userInfo.inn,
  };
  yield put(
    requestListActions.fileUpload({
      file: { ...action.payload, properties },
      message,
      url,
    }),
  );
}

function* submitSaga(action) {
  const { payload } = action;
  yield put(actions.submitWaiting(true));
  const url = '/api/v3/facade/edo-api/outgoing-documents/json/kzupd';

  const sending_date = Math.round(new Date() / 1000);
  const parent_id = payload.parent_id;
  const origin_date = parent_id ? payload.origin_date : payload.paper_document_date;

  const content = {
    version: '1.0',
    type: parent_id ? 1100 : 1000,
    origin_number: parent_id ? payload.origin_number : payload.paper_document_number,

    operation_type: payload.operation_type === 'realisation' ? 1 : 2,
    seller: {
      inn: payload.sender_inn,
      name: payload.sender_name,
    },
    buyer: {
      inn: payload.buyer_inn,
      name: payload.buyer_name,
    },
    cises: payload.products.map(item => ({
      code: item.cis,
      count: item.cisesCounts ? item.cisesCounts : 1,
    })),
    products: [
      {
        gtin: "00000000000000",
        count: 0
      }
    ],
    sending_date,
  };

  if (origin_date) {
    content.origin_date = origin_date;
  }

  const data = {
    type: parent_id ? 1100 : 1000,
    content,
    for_approval: true,
  };

  const locationHref = window.location.href;
  const draft_id = yield select(draftSelectors.getIdDocument);
  if (draft_id || /draft-/.test(locationHref)) {
    data.draft_id = draft_id || locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
  }

  if (parent_id) {
    data.parent_id = parent_id;
    data.correctionDocId = parent_id;
  }


  let requestPayload = {
    url,
    method: 'post',
    data,
  };
  const [createSuccess, createError] = yield call(Api.request, requestPayload);

  if (createSuccess) {
    Toast.showSuccess({ content: 'Документ создан (выполняется расчет Итогов)' });
  }
  else {
    yield put(actions.submitWaiting(false));
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
    return;
  }
  yield history.push('/documents/outcoming')
  // 1
  // requestPayload = {
  //   // url: `/api/v1/edo/outgoing-documents/${createSuccess.data.id}/base64`,
  //   url: `/api/v3/facade/edo-api/outgoing-documents/${createSuccess.data.id}/base64`,
  //   method: 'get',
  // };
  // // const [contentSuccess, contentError] = yield call(
  // //   Api.request,
  // //   requestPayload,
  // // );
  // if (contentSuccess) console.log('===contentSuccess', contentSuccess);
  // else {
  //   console.log('===contentError', contentError);
  //   yield put(actions.submitWaiting(false));
  //   return;
  // }
  // const convertedData = Base64.encode(contentSuccess.data);
  // const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  // const signature = yield Ncalayer.signData(contentSuccess.data);
  // // history.push(`/documents/list/act-out/${createSuccess.data.id}`)
  // if (!signature) {
  //   yield put(actions.submitWaiting(false));
  //   Toast.showInfo({
  //     content: 'Документ не подписан.',
  //     autoClose: 5000,
  //   });
  //   return;
  // }
  //
  // requestPayload = {
  //   url: `/api/v3/facade/edo-api/outgoing-documents/${createSuccess.data.id}/signature`,
  //   method: 'post',
  //   data: signature,
  // };
  //
  // const locationHref = window.location.href;
  //
  // const drafttId = yield select(draftSelectors.getIdDocument);
  //
  // if (drafttId || /draft-/.test(locationHref)) {
  //   const draft_id =
  //     drafttId ||
  //     locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
  //   requestPayload.params = {
  //     draft_id,
  //   };
  // }
  //
  // const [success, error] = yield call(Api.request, requestPayload);
  // if (success) {
  //   console.log('success')
  //   // yield call(Toast.showError, {
  //   //   content: get(error, 'response.data.message', 'error'),
  //   // });
  //   yield put(actions.submitWaiting(false));
  //   const error = get(success, 'data.errors.0', false);
  //   if (error) {
  //     Toast.showError({ content: error });
  //   } else {
  //     Toast.showSuccess({
  //       content: 'Акт успешно отправлен.',
  //     });
  //     Toast.showInfo({
  //       content: 'Создание акта может занять некоторое время.',
  //       autoClose: 5000,
  //     });
  //     yield put(listActions.mounted(undefined, requestListActions));
  //     yield put(replace(`/documents/outcoming`));
  //   }
  // }
  // if (error) {
  //   yield put(actions.submitWaiting(false));
  //   yield call(Toast.showError, {
  //     content: get(error, 'response.data.message', 'error'),
  //   });
  // }
}

function* signatureSaga (action) {
  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const signature = yield Ncalayer.signData(contentSuccess.data);
  // history.push(`/documents/list/act-out/${createSuccess.data.id}`)
  if (!signature) {
    yield put(actions.submitWaiting(false));
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  const requestPayload = {
    url: `/api/v3/facade/edo-api/outgoing-documents/${createSuccess.data.id}/signature`,
    method: 'post',
    data: signature,
  };

  const locationHref = window.location.href;

  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    const draft_id =
      drafttId ||
      locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
    requestPayload.params = {
      draft_id,
    };
  }

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    // yield call(Toast.showError, {
    //   content: get(error, 'response.data.message', 'error'),
    // });
    yield put(actions.submitWaiting(false));
    const error = get(success, 'data.errors.0', false);
    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Акт успешно отправлен.',
      });
      Toast.showInfo({
        content: 'Создание акта может занять некоторое время.',
        autoClose: 5000,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace(`/documents/outcoming`));
    }
  }
  if (error) {
    yield put(actions.submitWaiting(false));
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

function* recountSaga (action) {
  const { payload } = action;
  const url = '/api/v3/facade/edo-api/outgoing-documents/json/kzupd';

  const data = { ...payload };

  const locationHref = window.location.href;
  const draft_id = yield select(draftSelectors.getIdDocument);
  if (draft_id || /draft-/.test(locationHref)) {
    data.draft_id = draft_id || locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
  }

  const [createSuccess, createError] = yield call(Api.request, {
    url,
    method: 'put',
    data,
  });
  if (createSuccess) {
    Toast.showSuccess({ content: 'Выполняется перерасчет итогов' });
  }
  if (createError) {
    yield put(actions.submitWaiting(false));
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
  yield history.push('/documents/outcoming')
}

function* sendSaga (action) {
  const { payload } = action;
  const [contentSuccess, contentError] = yield call(
    Api.request,
    {
      // url: `/api/v1/edo/outgoing-documents/${createSuccess.data.id}/base64`,
      url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/base64`,
      method: 'get',
    },
  );
  if (contentSuccess) console.log('===contentSuccess', contentSuccess);
  else {
    console.log('===contentError', contentError);
    yield put(actions.submitWaiting(false));
    return;
  }
  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const signature = yield Ncalayer.signData(contentSuccess.data);
  // history.push(`/documents/list/act-out/${createSuccess.data.id}`)
  if (!signature) {
    yield put(actions.submitWaiting(false));
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  const requestPayload = {
    url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/signature`,
    method: 'post',
    data: signature,
  };

  const locationHref = window.location.href;

  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    const draft_id =
      drafttId ||
      locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
    requestPayload.params = {
      draft_id,
    };
  }

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    // yield call(Toast.showError, {
    //   content: get(error, 'response.data.message', 'error'),
    // });
    yield put(actions.submitWaiting(false));
    const error = get(success, 'data.errors.0', false);
    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Акт успешно отправлен.',
      });
      // Toast.showInfo({
      //   content: 'Создание акта может занять некоторое время.',
      //   autoClose: 5000,
      // });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace(`/documents/outcoming`));
    }
  }
  if (error) {
    yield put(actions.submitWaiting(false));
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

function* refundSaga({ payload }) {
  const requestPayloadForCreateEvent = {
    url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/events/withdraw`,
    method: 'post',
  };

  const [createEventSuccess, createEventError] = yield call(
    Api.request,
    requestPayloadForCreateEvent,
  );
  if (createEventSuccess) {
    let base64 = '';
    const requestPayloadForGetBase64 = {
      url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/events/withdraw/base64`,
      method: 'get',
    };
    const [base64Success, base64Error] = yield call(
      Api.request,
      requestPayloadForGetBase64,
    );

    if (base64Success) {
      base64 = base64Success.data;
    }
    if (base64Error) {
      yield call(Toast.showError, {
        content: get(
          createEventError,
          'response.data.message',
          'Невозможно выполнить операцию!',
        ),
      });
      return;
    }

    const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
    const signature = yield Ncalayer.signData(base64);

    if (!signature) {
      Toast.showInfo({
        content: 'Документ не подписан.',
        autoClose: 5000,
      });
      return;
    }

    const requestPayloadForSignature = {
      url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/events/signature`,
      method: 'post',
      data: signature,
    };

    const [success, error] = yield call(
      Api.request,
      requestPayloadForSignature,
    );
    if (success) {
      yield call(Toast.showSuccess, {
        content: `Акт успешно отозван`,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace(`/documents/outcoming`));
    }
    if (error) {
      yield call(Toast.showError, {
        content: get(
          error,
          'response.data.message',
          `При отзыве акта произошла ошибка!`,
        ),
      });
    }
  }
  if (createEventError) {
    yield call(Toast.showError, {
      content: get(
        createEventError,
        'response.data.message',
        'Невозможно выполнить операцию!',
      ),
    });
    return;
  }
}

function* rejectSaga({ payload }) {
  yield call(signDecision, payload.decision, payload.id);
}

function* acceptSaga({ payload }) {
  yield call(signDecision, payload.decision, payload.id);
}

function* signDecision(decision, documentId) {
  let base64 = '';
  const requestPayloadForCreateEvent = {
    url: `/api/v3/facade/edo-api/incoming-documents/${documentId}/events/${decision}`,
    method: 'post',
  };

  const [createEventSuccess, createEventError] = yield call(
    Api.request,
    requestPayloadForCreateEvent,
  );
  if (createEventSuccess) {
    base64 = createEventSuccess.data;
  }
  if (createEventError) {
    yield call(Toast.showError, {
      content: get(
        createEventError,
        'response.data.message',
        'Невозможно выполнить операцию!',
      ),
    });
    return;
  }

  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const signature = yield Ncalayer.signData(base64);

  if (!signature) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  const requestPayloadForSignature = {
    url: `/api/v3/facade/edo-api/incoming-documents/${documentId}/events/${decision}/signature`,
    method: 'post',
    data: signature,
    errorNotify: false,
  };

  const [success, error] = yield call(Api.request, requestPayloadForSignature);
  if (success) {
    const message = decision === 'approve' ? 'принят!' : 'отклонен!';
    yield call(Toast.showSuccess, {
      content: `Акт успешно ${message}`,
    });
    yield put(listActions.mounted(undefined, requestListActions));
    yield put(replace(`/documents/incoming`));
  }
  if (error) {
    const message = decision === 'approve' ? 'принятии' : 'отклонении';
    const errMessage = get(
      error,
      'response.data.description',
      `:При ${message} акта произошла ошибка!`,
    );
    const content = errMessage.split(':')[1];
    yield call(Toast.showError, {
      content:
        content === ' certificate inn and resident inn are not equals!'
          ? 'Подпись не действительна!'
          : content,
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.upload, uploadSaga);
  yield takeEvery(actions.submit, submitSaga);
  yield takeEvery(actions.send, sendSaga);
  yield takeEvery(actions.recount, recountSaga);
  yield takeEvery(actions.refundDocument, refundSaga);
  yield takeEvery(actions.rejectDocument, rejectSaga);
  yield takeEvery(actions.acceptDocument, acceptSaga);
}
