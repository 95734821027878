import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const TableWrap = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const CellWrap = styled.div`
  display: table-cell;
`;

const CellContentWrap = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
`;

export const PaddingTop = styled.div`
  padding-top: 20px;
`;


class TableScrollWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prepared: false,
      contentWidth: 0,
      parentWidth: 0,
    };

    this.ref = React.createRef();

    this.setParentWidth = this.setParentWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.setParentWidth);
    const parentWidth = parseInt(window.getComputedStyle(this.ref.current.parentNode).getPropertyValue('width'), 10);
    const contentWidth = parseInt(window.getComputedStyle(this.ref.current).getPropertyValue('width'), 10);
    this.setState({ prepared: true, parentWidth, contentWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setParentWidth);
  }

  setParentWidth() {
    const parentWidth = parseInt(window.getComputedStyle(this.ref.current.parentNode).getPropertyValue('width'), 10);
    this.setState({ parentWidth });
  }

  render() {
    const { prepared, parentWidth, contentWidth } = this.state;

    if (!prepared) {
      return (
        <div ref={this.ref} style={{visibility: 'hidden', position: 'fixed'}}>
          {this.props.children}
        </div>
      );
    }

    if (contentWidth >= parentWidth) {
      return (
        <div ref={this.ref}>
         <TableWrap>
           <CellWrap>
             <CellContentWrap role="tablescrooller">
               { this.props.children }
             </CellContentWrap>
           </CellWrap>
         </TableWrap>
        </div>
      )
    }

    return (
      <div ref={this.ref}>
        { this.props.children }
      </div>
    )
  }
}


TableScrollWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

TableScrollWrapper.displayName = 'TableScrollWrapper';


export default TableScrollWrapper;
