import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';

export default {
  title: Translate('Настраиваемые фильтры:'),
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: Translate('Название фильтра'),
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: Translate('Значение фильтра'),
          accessor: 'col2',
          minWidth: 560,
        }
      ],
      data: [
        {
          'col1': Translate('Тип агрегации'),
          'col2': <div>{Translate('Возможность выбора агрегации:')}
            <ul>
              <li>{Translate('Сводный отчёт')}</li>
              <li>{Translate('Детализация по часам')}</li>
              <li>{Translate('Детализация по датам')}</li>
              <li>{Translate('Детализация по товару')}</li>
            </ul>
          </div>
        },
        {
          'col1': Translate('Вариант задания периода'),
          'col2': <div>{Translate('Возможность выбора отображения детализации периода:')}
            <ul>
              <li>{Translate('Час')}</li>
              <li>{Translate('День')}</li>
              <li>{Translate('Месяц')}</li>
              <li>{Translate('Год')}</li>
            </ul>
          </div>
        },
        {
          'col1': Translate('Период'),
          'col2': <div>{Translate('Возможность выбора периода из доступных значений при соответствующем значении варианта задания периода:')}
            <ul>
              <li>{Translate('Произвольный час')}</li>
              <li>{Translate('Произвольный день')}</li>
              <li>{Translate('Месяц')}</li>
              <li>{Translate('Год')}</li>
            </ul>
          </div>
        },
        {
          'col1': Translate('ИНН'),
          'col2': Translate('Возможность фильтрации по доступным значениям')
        },
        {
          'col1': Translate('Производитель'),
          'col2': Translate('Возможность фильтрации по доступным значениям')
        },
        {
          'col1': 'GTIN',
          'col2': Translate('Возможность фильтрации по доступным значениям')
        },
        {
          'col1': Translate('Наименование'),
          'col2': Translate('Возможность фильтрации по доступным значениям')
        },
        {
          'col1': Translate('Тип упаковки'),
          'col2': <div>{Translate('Возможность фильтрации по значениям:')}
            <ul>
              <li>{Translate('Пачка')}</li>
              <li>{Translate('Блок')}</li>
            </ul>
          </div>
        },
      ]
    }
  ]
}
