import {actionTypes} from '../constants';
import {OrderService} from '../services/OrderService';

function show() {
  return {
    type: actionTypes.ORDER_FORM.ORDER_FORM_SHOW
  }
}

function close() {
  return {
    type: actionTypes.ORDER_FORM.ORDER_FORM_CLOSE
  }
}

function createOrder(data) {
  return dispatch => {
    OrderService.createOrder(0, data)
      .then(result => {
          console.log('create order data: ', result);
          dispatch(close());
          dispatch(clearForm());
        }
      )
      .catch(err => console.log('create order error,', err));
  }
}

function clearForm() {
  return {
    type: actionTypes.ORDER_FORM.CLEAR
  }
}

export {
  show,
  close,
  createOrder,
};
