import React from 'react';
import PropTypes from 'prop-types';

import { TwoLineLabel } from '@crpt/react-label';

import { Wrapper } from './styled';


const propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const HeadedLabel = ({ header, value }) => (
  <Wrapper>
    <TwoLineLabel heading={header}>
      {value}
    </TwoLineLabel>
  </Wrapper>
);

HeadedLabel.propTypes = propTypes;

export default HeadedLabel;
