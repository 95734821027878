import * as _ from 'lodash';
import i18next from 'i18next';
class ProductionLinesHelper {
  add({ payload }) {
    return [ ...payload ];
  }

  delete({ state, payload }) {
    return [
      ...state.filter(item => !payload.some(value => _.isEqual(value, item))),
    ];
  }

  validator(productionLines) {
    if (productionLines.length > 100 ) {
      return i18next.t('Количество строк для отбора по производственным линиям не может быть более 100')
    }

    return null;
  }
}

export default ProductionLinesHelper;
