import React from 'react';
import { Text } from './MaterialCertSelect.styled';

const Options = ({ name, position }) => (
  <Text setFotnSize="16px" setFontWeight="600">
    {name}
    {position ? ` · ${position}` : ''}
  </Text>
);

export default Options;
