import { createAction } from 'redux-actions';

export const subject = '[ActDocument]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const upload = createAction(`${subject} upload`);
export const submit = createAction(`${subject} submit`);
export const send = createAction(`${subject} send`);
export const recount = createAction(`${subject} recount`);
export const submitWaiting = createAction(`${subject} submit waiting`);
export const setError = createAction(`${subject} setErrorInfo`);
export const openDraft = createAction(`${subject} open draft`);
export const refundDocument = createAction(`${subject} refund document`);
export const acceptDocument = createAction(`${subject} accept document`);
export const rejectDocument = createAction(`${subject} reject document`);
