import React, { Component, Fragment } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';

import 'datejs';
import { Button } from '@crpt/react-button';
import Table from '@crpt/react-table';
import { Pagination } from '@crpt/react-pagination';

import ActionBar from '../common/ActionBar';
import TableScrollWrapper from '../TableScrollWrapper';
import { TableToolTip } from '../common/Table/TableCellTooltip';
import ElementsCount from '../common/Table/ElementsCount';
import Filter from '../common/Table/Buttons/Filter';
import DownloadButton from '../common/Table/Buttons/DownloadButton';
import ModalWindow from './ModalWindow';

import { PaginationWrapper, ContentWrapper, InputFile } from '../common/styled';
import Preloader from '../Preloader';
import { Icon } from '@crpt/react-icon';
import lodash from 'lodash';
import { Translate } from '../../common_components/Translate/Translate';
import * as authSelectors from '../../common_components/Auth/Auth.selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ConfirmModal from '../../common_components/Modals/ConfirmModal/ConfirmModal';
import { Toast } from '../../utils/Toast';

class ProductList extends Component {
  // TODO: propTypes should will be filled!
  static propTypes = {};

  constructor(props) {
    super(props);
    this.fileInput = null;
    this.setFileInput = (ref) => {
      this.fileInput = ref;
    };
  }

  state = {
    isModalOpen: false,
    isConfirmModalOpen: false,
    cellEvent: null,
    isToolTipVisible: false,
    children: <div />,
    files: null,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.ProductList.onListMount();
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.ProductList.onListUnmount();
  }

  onMouseEvent = ({ isToolTipVisible, children, cellEvent }) => {
    this.setState(() => ({ isToolTipVisible, children, cellEvent }));
  };

  onOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  toggleConfirmModal = () => {
    const { isConfirmModalOpen } = this.state;
    this.setState({ isConfirmModalOpen: !isConfirmModalOpen })
  }

  onLoaded = (errors) => {
    this.toggleConfirmModal();
    this.setState({ files: null });
    this.fileInput.value = ""
    if (errors && errors.length === 0) {
      Toast.showSuccess({ content: 'Обновление тарифных ставок успешно загружено' });
    } else {
      errors.map(el=> Toast.showError({ content: el }))
    }
  }

  onUpload = (saveUnregistred) => {
    const { actions } = this.props;
    const { files } = this.state;
    const { uploadProductListCsv } = actions.ProductList;
    uploadProductListCsv(files, saveUnregistred, this.onLoaded)
  }

  onLoadFiles = (e) => {
    if (e.target.files[0]) {
      this.setState({ files: e.target.files[0] }, this.toggleConfirmModal);
    }
  }

  render() {
    const { actions, theme, ProductList: productList, App: app, roles, countryCode } = this.props;
    const { List, total, selectedPage, pagesCount, preloaderIsOpen, filterParams, } = productList;
    const { config } = app;
    console.log('config', config)
    const { onFilterApplyClick, onPageSelect, createProduct, onListMount: listMounted, downloadProductListCsv, } = actions.ProductList;

    const { isModalOpen, isConfirmModalOpen, files } = this.state;
    const { filters, columns, options, tableTheme } = config.lists.ProductList;
    const buttonTheme = { Button: lodash.get(theme, 'tab.item') };

    const { popupMenu, showDownloadListButton } = filters[0];
    console.log('onFilterApplyClick', onFilterApplyClick)
    const rightSideComponents = [
      ...((roles.indexOf("ROLE_ORG_IS_MP_OPERATOR") === -1 && roles.indexOf("ROLE_HEAD_OPERATOR") === -1) || countryCode === 'KZ')
        ? []
        : [
        {
        component: (
          <Button
            theme={buttonTheme}
            onClick={()=> {this.fileInput.click()}}
          >
            {Translate('Загрузить')}
            <Icon type="upload" spacingLeft={16} />
          </Button>
        ),
        wrap: true,
      }],
      {
        component: (
          <Filter
            filters={filters}
            onFilterApply={onFilterApplyClick}
            filterParams={filterParams}
          />
        ),
        wrap: true,
      },
      {
        component: (
          <DownloadButton
            popupMenu={popupMenu}
            showDownloadListButton={showDownloadListButton}
          />
        ),
        wrap: true,
      },
    ];

    const { cellEvent, children, isToolTipVisible } = this.state;

    const optionsWithCallback = options.map(item => ({
      onMouseEvent: this.onMouseEvent,
      ...item,
    }));

    return (
      <ContentWrapper minHeight={preloaderIsOpen} hasMrginTop>
        <Preloader />
        <ModalWindow
          isModalOpen={isModalOpen}
          onCloseModal={this.onCloseModal}
          productListActions={{ createProduct, listMounted }}
        />
        <InputFile
          innerRef={this.setFileInput}
          onChange={this.onLoadFiles}
        />
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          onClose={this.toggleConfirmModal}
          onSubmit={() => this.onUpload(true)}
          content={(
            <div>
              {Translate('Сохранить информацию о тарифах маркировки для незарегистрированных товаров?')}
            </div>
          )}
          header={Translate('Предупреждение')}
          cancelButtonText={Translate('Нет')}
          submitButtonText={Translate('Да')}
          onCancelFunction={() => this.onUpload(false)}
        />
        <ActionBar
          leftSideComponents={[]}
          rightSideComponents={rightSideComponents}
        />
        {!preloaderIsOpen && (
          <Fragment>
            <ElementsCount value={total} />

            <TableScrollWrapper>
              <Table
                theme={tableTheme || theme}
                columns={columns}
                data={List}
                noDataText={Translate('Данных нет')}
                minRows={0}
                highlight
                striped
                options={optionsWithCallback}
              />
            </TableScrollWrapper>
          </Fragment>
        )}
        <PaginationWrapper>
          <Pagination
            selected={selectedPage}
            pagesCount={pagesCount}
            onSelect={v => onPageSelect(v)}
            pageCounterInvisible
            isBelowZeroDisabled
            isUpperThenMaxDisabled
            leftPaginationText={Translate('Предыдущая')}
            rightPaginationText={Translate('Следующая')}
          />
        </PaginationWrapper>
        <TableToolTip
          event={cellEvent}
          content={children}
          isVisible={isToolTipVisible}
        />
      </ContentWrapper>
    );
  }
}

const mapState = state => ({
  roles: authSelectors.getRoles(state),
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState), withRouter, withTheme)(ProductList);
// export default compose(withTheme(withRouter(ProductList));
