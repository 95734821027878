import { compose, withProps, withHandlers, withState } from 'recompact';


export default (key, newItem) => compose(
  withState('active', 'setActive', 0),
  withProps(({ values }) => ({
    items: values[key] || [],
  })),
  withHandlers({
    onAppend: ({ onPushValue, items, setActive }) => () => {
      onPushValue(key, {
        ...JSON.parse(JSON.stringify(newItem)),
        key_id: new Date().getTime(),
      });
      setActive(items.length);
    },
    onActivate: ({ setActive }) => index => setActive(index),
    onRemove: ({ onRemoveValue, active, setActive }) => (index) => {
      onRemoveValue(key, index);
      if (index < active) setActive(active - 1);
    },
  })
);
