import React from 'react';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import { required } from '../../../../../common_components/Form/utils/validations';
import {
  AddDocTitleWrap,
  DeleteDocumentButton,
  DocArrayWrapper,
  InfoFields,
  StepTitle,
} from '../../Document/Document.styled';
import MediumButton from '../../../../../common_components/Buttons/MuiCommonActionMediumButton';
import { FieldArray } from 'react-final-form-arrays';
import * as Icons from '@crpt-ui/icons';
import { Translate } from 'src/common_components/Translate/Translate';
import CheckboxField from '../../../../../common_components/Form/MaterialFields/Checkbox/Checkbox';

export const InformationKZFields = ({ formValues, addDocument }) => {
  const { isChanged } = formValues;

  return (
    <React.Fragment>
      <CheckboxField
        name="isHasPaperDocument"
        placeholder={Translate('Документ оформлен на бумажном носителе')}
        disabled={isChanged}
      />
      {formValues.isHasPaperDocument && (
        <React.Fragment>
          <div />
          <Input
            forceValidate
            required
            name="paper_document_number"
            placeholder={Translate('Номер первичного документа')}
            validate={required}
            disabled={isChanged}
          />
          <DatePicker
            required
            forceValidate
            name="paper_document_date"
            placeholder={Translate('Дата первичного документа')}
            maxDate={new Date()}
            validate={(...vals) => {
              return validateDate(...vals, 'DD.MM.YYYY');
            }}
            disabled={isChanged}
          />
        </React.Fragment>
      )}
      <AddDocTitleWrap>
        <StepTitle>
          {Translate('Сведения о документе, подтверждающем соответствие товара')}
        </StepTitle>
        <MediumButton
          disabled={(formValues.status === 19 || formValues.status === null) ? (formValues.documents && formValues.documents.length === 10) : true}
          onClick={addDocument}
        >
          {Translate('Добавить')}
        </MediumButton>
      </AddDocTitleWrap>
      <FieldArray name="documents">
        {({ fields }) =>
          fields.map((name, index) => (
            <DocArrayWrapper key={name}>
              <Input
                forceValidate
                required
                name={`${name}.document_type`}
                placeholder={Translate('Тип документа соответствия')}
                validate={required}
              />
              <DatePicker
                required
                forceValidate
                name={`${name}.document_date`}
                placeholder={Translate('Дата документа соответствия')}
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
              <Input
                forceValidate
                required
                name={`${name}.document_number`}
                placeholder={Translate('Номер документа соответствия')}
                validate={required}
              />
              <DeleteDocumentButton>
                <MediumButton onClick={() => fields.remove(index)}>
                  <Icons.Trash />
                </MediumButton>
              </DeleteDocumentButton>
            </DocArrayWrapper>
          ))
        }
      </FieldArray>
    </React.Fragment>
  );
};
