import { get, has } from 'lodash';

const selectors = {};

export const selector = (state, path, defaultValue) => {
  const { order } = state;

  if (!selectors[path]) {
    for (let i = 0; i < order.length; i += 1) {
      const newPath = order[i] ? `${order[i]}.${path}` : path;

      if (has(state, newPath)) {
        selectors[path] = newPath;
        break;
      }
    }
  }

  return get(state, selectors[path], defaultValue);
};
