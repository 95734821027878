import { handleAction } from 'redux-actions';
import * as actions from './Rutoken.actions';

const certificates = handleAction(
  actions.loaded,
  (state, { payload }) => payload,
  []
);

export default {
  certificates,
};
