import { isEmpty, isArray } from 'lodash';
import moment from 'moment';

export const transformInn = value => (value && value.substring(0, 2) === '00' ? value.substring(2) : value);

export const transformDate = value => moment(value).format('YYYY-MM-DD');

export const transformDateTime = value => `${moment(value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;

export const transformDateLocal = value => moment(value).format('DD.MM.YYYY');

export const checkFields = (obj, fields, transform) => {
  fields.forEach((field) => {
    if (obj.hasOwnProperty(field)) {
      obj[field] = transform(obj[field]);
    }
  });
};

export const areDocumentFieldsEmpty = (rowfields) => {
  const fields = { ...rowfields };
  delete fields.action;
  delete fields.errorInfo;
  delete fields.request_type;
  delete fields.to_not_participant;

  if (isEmpty(fields)) return true;

  for (const field in fields) {
    if (!isArray(fields[field]) && fields[field] && field.indexOf('__') === -1) {
      return false;
    }
  }
  return true;
};

export const menuOnlyStandQA = (allMenuItems, hideMenuItems) => {
  const locationHref = window.location.href;
  if ( (/qa.lp.crpt.tech/.test(locationHref)) || (/localhost/.test(locationHref)) ) return allMenuItems;

  return allMenuItems.filter(item => !(hideMenuItems.includes(item.link)));
};
