import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompact';
import { Wrap } from '../styled';
import { Title,  } from '../../../styled/index';
import { PARTICIPANT_TYPES } from '../../../../../constants';
import Warehouse from './Warehouse';
import {
  withMountCycle,
  withRenderNothingNot,
  withItemsArray,
  withRenderOkvedOrNothing,
} from '../../../utils';

const newWarehouse = {
  address: '',
  code: '',
  key_id: new Date().getTime(),
};

const Warehouses = ({
  active,
  items,
  onAppend,
  onActivate,
  onRemove,
  onUpdateField,
}) => (
  <Wrap>
    <Title>Склад</Title>
    {items.map((warehouse, index) => (
      <Warehouse
        key={warehouse.key_id}
        name={`warehouses[${index}]`}
        index={index}
        length={items.length}
        active={index === active}
        data={warehouse}
        onActivate={onActivate}
        onRemove={onRemove}
        onAppend={onAppend}
        onUpdate={onUpdateField}
      />
    ))}
  </Wrap>
);

Warehouses.propTypes = {
  active: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAppend: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
};

export default compose(
  withRenderNothingNot([
    PARTICIPANT_TYPES.PRODUCER,
    PARTICIPANT_TYPES.RETAIL,
    PARTICIPANT_TYPES.WHOLESALER,
  ]),
  withRenderOkvedOrNothing([PARTICIPANT_TYPES.PRODUCER]),
  setDisplayName('Warehouses'),
  withItemsArray('warehouses', newWarehouse),
  withMountCycle('warehouses', [newWarehouse]),
  onlyUpdateForKeys(['items'])
)(Warehouses);
