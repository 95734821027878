import React from 'react';
import { withRouter } from 'react-router-dom';

class AfterLogin extends React.Component {
  componentDidMount() {
    if (location.hostname === 'ismotp.crptech.ru') {
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      fbq('init', '2247915378605193');
      fbq('track', 'PageView');
    }



    setTimeout(() => {
      this.props.history.replace('/');
    }, 0);
  }

  render() {
    return <div />;
  }
}

export default withRouter(AfterLogin);
