import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '0.50fr 0.50fr',
    color:theme.palette.text.primary,
    paddingBottom: '10px',
    borderBottom: '1px solid #D9D9D9'
  },
  contentPart: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  titlePart: {
    fontWeight: '600'
  }
}));