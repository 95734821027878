import React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../common_components/Translate/Translate';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import {
  MARKING_CODES_INFO_TYPES_NAMES,
  MARKING_CODES_INFO_TYPES,
} from '../../../../constants';
import {
  required,
  isValidProductionSerialNumber,
} from '../../../../common_components/Form/utils/validations';
import { format, parse } from 'date-fns';

const {
  EMISSION_TYPE,
  EXPIRATION_DATE,
  PRODUCTION_DATE,
  PRODUCTION_SERIAL_NUMBER,
} = MARKING_CODES_INFO_TYPES;

const EMISSION_TYPE_OPTIONS = [
  { label: Translate('Производство'), value: 'PRODUCTION' },
  { label: Translate('Импорт'), value: 'IMPORT' },
];

const CURRENT_YEAR = new Date().getFullYear();
const YEAR_INTERVAL = 48;

export const getFieldForInfoType = ({ infoType, finalConfirm }) => {
  switch (infoType) {
    case EMISSION_TYPE:
      return (
        <SelectWrapper isFinalConfirm={finalConfirm}>
          <Select
            forceValidate
            name={EMISSION_TYPE}
            placeholder={Translate('Значение')}
            disabled={finalConfirm}
            options={EMISSION_TYPE_OPTIONS}
            required
            validate={required}
          />
        </SelectWrapper>
      );
    case PRODUCTION_DATE:
      return (
        <DatePicker
          forceValidate
          name={PRODUCTION_DATE}
          placeholder={Translate('Значение')}
          maxDate={new Date()}
          disabled={finalConfirm}
          required
          validate={required}
          startYear={CURRENT_YEAR - YEAR_INTERVAL}
          endYear={CURRENT_YEAR}
        />
      );
    case EXPIRATION_DATE:
      return (
        <DatePicker
          forceValidate
          name={EXPIRATION_DATE}
          placeholder={Translate('Значение')}
          minDate={new Date()}
          disabled={finalConfirm}
          required
          validate={required}
          startYear={CURRENT_YEAR}
          endYear={CURRENT_YEAR + YEAR_INTERVAL}
        />
      );
    case PRODUCTION_SERIAL_NUMBER:
      return (
        <Input
          forceValidate
          name={PRODUCTION_SERIAL_NUMBER}
          placeholder={Translate('Значение')}
          disabled={finalConfirm}
          InputProps={{
            inputProps: {
              maxLength: 20,
            },
          }}
          required
          validate={isValidProductionSerialNumber}
        />
      );
    default:
      return (
        <Input
          forceValidate
          placeholder={Translate('Значение')}
          disabled={finalConfirm}
          required
          validate={required}
        />
      );
  }
};

export const DATA_INFO_TYPE_OPTIONS = Object.entries(
  MARKING_CODES_INFO_TYPES_NAMES,
).map(([key, value]) => ({
  value: key,
  label: Translate(value),
}));

export const senderDocumentFormat = formValues => {
  const { infoType } = formValues;

  const infoTypeValue =
    infoType === PRODUCTION_DATE || infoType === EXPIRATION_DATE
      ? format(
          parse(formValues[infoType], 'dd.MM.yyyy', new Date()),
          "yyyy-MM-dd'T'23:59:59'Z'",
        )
      : formValues[infoType];

  const submitDocumentData = {
    inn: formValues.inn,
    documentType: formValues.docType,
    cises: formValues.products.map(product => product.cis),
    productGroup: formValues.productGroup,
    [infoType]: infoTypeValue,
  };

  return submitDocumentData;
};

export const WrapperForTable = styled.div`
  .rt-td {
    min-height: 40px;
  }
`;

export const AddCodesBarWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
  button {
    width: 200px;
  }
`;

export const AddCodesButtons = styled.div`
  display: flex;
  button {
    margin-left: 20px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  svg {
    display: ${({ isFinalConfirm }) => (isFinalConfirm ? 'none' : 'block')};
  }
`;
