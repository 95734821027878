import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Уникальный код и наименование товара'
        },
        {
          'col1': [3],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Наименование производителя товара'
        },
        {
          'col1': [4],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Месяц-год производства товара '
        },
        {
          'col1': [5],
          'col2': <ul>
              <li>По владельцу товара</li>
            </ul>,
          'col3': 'Тип участника'
        },
        {
          'col1': [6, 7],
          'col2': <ul>
              <li>По владельцу товара</li>
            </ul>,
          'col3': 'ИНН и наименование владельца товара'
        },
        {
          'col1': [8],
          'col2': <ul>
              <li>По владельцу товара</li>
            </ul>,
          'col3': 'Юридический адрес владельца товара'
        },
        {
          'col1': [9],
          'col2': <ul>
              <li>По владельцу товара</li>
            </ul>,
          'col3': 'ИД розничной торговой точки, где находится(-лся) товар из справочника розничных торговых точек'
        },
        {
          'col1': [10],
          'col2': <ul>
              <li>По владельцу товара</li>
            </ul>,
          'col3': 'Населённый пункт РК, в котором был реализован товар или находится в обороте'
        },
        {
          'col1': [11],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Актуальный статус товара за выбранный период'
        },
        {
          'col1': [12],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Диапазон количества месяцев с даты производства товара до его реализации ИЛИ до окончания выбранного периода, если товар находится в обороте'
        },
        {
          'col1': [13],
          'col2': <ul>
              <li>По владельцу товара</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Количество товара, находящегося в данном диапазоне'
        }
      ]
    }
  ]
}
