import { handleActions } from 'redux-actions';
import * as grayAreaGtinsActions from './GrayAreaGtins.actions';

const resultDefaultState = [];
const result = handleActions(
  {
    [grayAreaGtinsActions.loaded]: (state, { payload }) => payload.result || [],
    [grayAreaGtinsActions.clear]: () => resultDefaultState,
  },
  resultDefaultState
);

const pageDefaultState = 0;
const page = handleActions(
  {
    [grayAreaGtinsActions.requestGtins]: (_state, { payload }) => payload.page -1 || pageDefaultState,
    [grayAreaGtinsActions.clear]: () => pageDefaultState,
  },
  pageDefaultState,
);

const totalDefaultState = 0;
const total = handleActions(
  {
    [grayAreaGtinsActions.loaded]: (state, { payload = {} }) => payload.total || totalDefaultState,
    [grayAreaGtinsActions.clear]: () => totalDefaultState,
  },
  totalDefaultState,
);

const loadedDefaultState = false;
const loaded = handleActions(
  {
    [grayAreaGtinsActions.requestGtins]: () => loadedDefaultState,
    [grayAreaGtinsActions.loaded]: () => true,
    [grayAreaGtinsActions.clear]: () => loadedDefaultState,
  },
  loadedDefaultState,
);

export default {
  loaded,
  page,
  result,
  total,
}
