import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { MuiThemeProvider } from '@material-ui/core';
import LightTheme from '../../../../common_components/_MaterialUi/LightTheme';
import * as previewActions from '../Document/Preview/ducks/Preview.actions';
import * as actions from './ducks/EaesImportDocument.actions';
import * as selectors from './ducks/EaesImportDocument.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as actionsDocumentDetails from '../../../../components/DocumentDetails/ducks/DocumentDetails.actions';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import * as selectorsDocumentDetails from '../../../../components/DocumentDetails/ducks/DocumentDetails.selectors';
import EaesImportDocumentView from './EaesImportDocument.view';
import {
  breadcrumbs,
  breadcrumbsThirdCountry,
  importThirdKG,
  steps,
} from './EaesImportDocument.constants';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import { APP_LINKS } from 'src/constants';
import { convertData } from './convertCorrectData';
import EaesImportShow from '../../../../components/DocumentDetails/EaesImport/EaesImportShow';
import { parseSelectedDocument } from './EaesImportDocument.utils';
import memoize from "memoize-one";

class EaesImportDocument extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    onMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    setPreview: PropTypes.func.isRequired,
    openPreview: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    countryCode: PropTypes.string,
    correctData: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // step: steps.goods
      typeDoc: null,
      step: steps.info,
      dataOfCorrecting: null,
      formData: {},
    };
  }

  componentDidMount() {
    const { match, onLoadDraft, history, correctData, user } = this.props;
    const { onMountEaesImport, direction } = this.props;
    const { id, key } = match.params;
    if (match.path.includes(APP_LINKS.ImportThirdCountryKG)) {
      this.setState(prev => ({ ...prev, typeDoc: importThirdKG }));
    }
    if (/draft-/.test(id)) onLoadDraft(id.replace(/draft-/, ''));
    if (id === 'correct') {
      if (!correctData.isChanged) history.replace('/documents/incoming');
      const dataOfCorrecting = convertData(correctData);
      const applicant_inn = get(user, 'inn', '');
      const applicant_name = get(user, 'company.shortName', '');

      this.setState({
        dataOfCorrecting: {
          ...dataOfCorrecting,
          applicant_inn,
          applicant_name,
        },
      });
    }
    if (id !== 'correct' && id !== 'create' && !/draft-/.test(id)) {
      onMountEaesImport({ direction, id: key });
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  onSaveDraft = values => {
    const { match, onSaveDraft } = this.props;
    onSaveDraft({ match, values });
  };

  onSetStep = step => {
    this.setState(prev => ({ ...prev, step }));
  };

  getFormData = memoize(
    (selectedDocument, id, dataOfCorrecting, data) => {
      let formData = {};
      if (id === 'correct') {
        formData = dataOfCorrecting;
      } else if (id !== 'correct' && id !== 'create' && !/draft-/.test(id)) {
        formData = parseSelectedDocument(selectedDocument);
      } else if (id === 'create') {
        formData = {
          status: null,
        };
      } else formData = data;
      return formData
    });

  render() {
    const { countryCode, selectedDocument, direction } = this.props;
    const { loaded, isLoading, data, match, onNavClick } = this.props;
    const { id, key } = match.params;
    const { step, typeDoc, dataOfCorrecting } = this.state;

    let formData = this.getFormData(selectedDocument, id, dataOfCorrecting, data);
    const isDraft = /draft-/.test(id);
    const isCorrect = id === 'correct';

    return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
      >
        {
          formData && formData.status === 3 ? (
            <EaesImportShow
              id={key}
              direction={direction}
              breadcrumbs={breadcrumbs}
              onNavClick={onNavClick}
            />
          ) : (
            <EaesImportDocumentView
              data={formData}
              isDraft={isDraft}
              isCorrect={isCorrect}
              step={step}
              loaded={loaded}
              isCreate={['create', 'correct'].includes(id)}
              breadcrumbs={typeDoc ? breadcrumbsThirdCountry : breadcrumbs}
              onSubmit={() => {
              }}
              onSetStep={this.onSetStep}
              onPreview={this.onPreview}
              onSaveDraft={this.onSaveDraft}
              isLoading={isLoading}
              countryCode={countryCode}
              typeDoc={typeDoc}
            />
          )
        }
      </MuiThemeProvider>
    );
  }
}

const mapState = state => ({
  data: selectors.data(state),
  inn: authSelectors.userInn(state),
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  correctData: selectors.correctData(state),
  user: authSelectors.userInfo(state),
  selectedDocument: selectorsDocumentDetails.selectedDocument(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  onUnmount: () => dispatch(actions.unmounted()),
  openPreview: data => dispatch(previewActions.open(data)),
  setPreview: data => dispatch(previewActions.setContent(data)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMountEaesImport: id => dispatch(actionsDocumentDetails.mountEAES(id)),
});

export default connect(mapState, mapDispatch)(EaesImportDocument);
