import { actionTypes } from '../../../constants';


export function filters(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_APPLY_CLICKED:
      return action.value;
    default:
      return state;
  }
}
