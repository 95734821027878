import styled from 'styled-components';

export const Title = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 22px;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  min-width: 540px;
  font-size: 20px;
  color: #63666a;
`;

export const FindWrap = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const InputWrap = styled.div`
  width: 320px;
  min-width: 320px;
  margin-right: 20px;
`;

export const FindButtonWrap = styled.div`
  width: 160px;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

export const CisListWrap = styled.div`
  margin-left: 40px;
  margin-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 260px;
  width: auto;
  font-size: 14px;
  color: #63666a;
  margin-bottom: 20px;
`;

export const ButtonsWrap = styled.div`
  border-top: 1px solid #f2f2f2;
  height: 110px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const BottomButtonWrap = styled.div`
  width: 300px;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

export const ErrorZone = styled.div`
  height: 68px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: #ff4b4b;
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
`;