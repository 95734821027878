import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import * as actions from './FiscalDocument.actions';
import * as selectors from './FiscalDocument.selectors';
import Api from '../../../../../common_components/Api/Api';
import { Toast } from '../../../../../utils/Toast';
import { getFilenameWithoutExtension } from '../../../../../utils';
import * as cadesActions from '../../../../../common_components/Cades/Cades.actions';
import { replace } from 'react-router-redux';
import * as requestListActions from '../../ducks/RequestList.actions';
import * as listActions from '../../../../../common_components/List/ducks/List.actions';
import * as authActions from '../../../../../common_components/Auth/Auth.actions';
import { Translate } from '../../../../../common_components/Translate/Translate';

function* convertSaga(action, state) {
  const { payload } = action;
  const formData = new FormData();
  formData.append(
    'document_type',
    new Blob(['"FISCAL_DOCUMENT"'], { type: 'application/json' }),
  );
  formData.append('file', payload.file, payload.name);

  const requestPayload = {
    url: '/api/v3/facade/doc/transform',
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    console.log('success', success);
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
      } else {
        const json = success.data.result;
        yield put(
          actions.converted({
            name: `${getFilenameWithoutExtension(payload.name)}.json`,
            content: json,
          }),
        );
        yield put(authActions.loadCert());
      }
    } catch (e) {
      yield put(actions.reset());
      Toast.showError({
        content: Translate(
          `Ответ от сервера не соответствует ожидаемому формату.`,
        ),
      });
      console.log(e, { e });
    }
  }
  if (error) {
    yield put(actions.reset());
    const errorMessage =
      get(error, 'response.data.description') ||
      get(error, 'response.data.error_message', false);
    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
    console.log('error /api/v3/facade/doc/transform', { error });
  }
}

function* signSaga(action, state) {
  const { payload: onClose } = action;
  const json = yield select(selectors.json);
  const document = JSON.parse(json);
  console.log('document', document);

  yield put(
    cadesActions.sign({ data: document }, { subject: actions.subject }),
  );

  const signed = yield take(`${cadesActions.signed} ${actions.subject}`);

  let data = {
    signature: signed.payload.signature,
    document: signed.payload.document,
    document_type: 'RECEIPT',
  };

  const url = '/api/v3/facade/doc/signed';

  const requestPayload = {
    url,
    method: 'post',
    data,
  };

  const toastId = Toast.showInfo({
    content: 'Файл отправляется...',
    position: toast.POSITION.TOP_CENTER,
  });

  const [success, error] = yield call(Api.request, requestPayload, {
    artificialDelay: 500,
    errorNotify: false,
  });

  Toast.closeToast(toastId);

  if (success) {
    const error = get(success, 'data.errors.0', false);
    console.log('success', success);
    if (error) {
      yield put(actions.error(error));
    } else {
      Toast.showInfo({
        content: Translate('Файл подписан и отправлен'),
        position: toast.POSITION.TOP_CENTER,
      });
      yield call(onClose);
      yield put(actions.reset());
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace('/documents/list'));
    }
  }
  if (error) {
    console.log('dada error', { error });
    yield call(Toast.showError, {
      content: get(error, 'response.data.error_message', 'error'),
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.convert, convertSaga);
  yield takeEvery(actions.sign, signSaga);
}
