import { selector } from 'src/utils/selector';
import { limit } from '../ExportList.constants';

export const results = state => selector(state, 'Requests.ExportList.results');
export const total = state => selector(state, 'Requests.ExportList.total');
export const last = state => selector(state, 'Requests.ExportList.last');
export const pagesCount = state => Math.ceil(total(state) / limit);
export const sorting = state => selector(state, 'Requests.ExportList.sorting');
export const selectedPage = state => selector(state, 'Requests.ExportList.selectedPage');
export const pagination = state => selector(state, 'Requests.ExportList.pagination');
export const filters = state => selector(state, 'Requests.ExportList.filters');
export const uploadErrors = state => selector(state, 'Requests.ExportList.uploadErrors');
export const offset = state => selector(state, 'Requests.ExportList.offset');
