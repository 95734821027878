import moment from 'moment';
import 'datejs';

import { packTrivialTypes } from '../../constants/packTypes';

export function getKeysSortedByDate(keys) {
  return keys.sort((v1, v2) => moment(Date.parse(v1)) - moment(Date.parse(v2)));
}

export function getLastDateKey(keys) {
  let current = null;

  keys.forEach(v => {
    const _tmpdate = moment(Date.parse(v));

    if (!current) return (current = _tmpdate);

    if (current.isBefore(_tmpdate, 'month')) current = _tmpdate;
  });

  return current;
}

export function getRubelsAsFloatFromKopecks(kopecks) {
  return +(kopecks + '')
    .split('')
    .reverse()
    .map((v, i) => (i === 1 ? '.' + v : v))
    .reverse()
    .join('');
}

export function getAdoptedDataFromBackendAnswer(data, newData) {
  let processedAnswerData = [];

  const months = getKeysSortedByDate(Object.keys(data));

  months.forEach(v => {
    let created = false;
    newData.forEach(inner => {
      if (
        moment(Date.parse(inner.date)).isSame(moment(Date.parse(v)), 'month') &&
        moment(Date.parse(inner.date)).isSame(moment(Date.parse(v)), 'year')
      ) {
        const sameDateProcessedItem = processedAnswerData.find(
          vv =>
            moment(Date.parse(inner.date)).isSame(
              moment(vv.date ? Date.parse(vv.date) : 'error'),
              'month',
            ) &&
            moment(Date.parse(inner.date)).isSame(
              moment(vv.date ? Date.parse(vv.date) : 'error'),
              'year',
            ),
        );

        if (sameDateProcessedItem) return;

        created = true;
        processedAnswerData.push({
          value: getRubelsAsFloatFromKopecks(inner.price),
          date: inner.date,
        });
      }
    });
    if (!created) processedAnswerData.push({ value: null });
  });

  let top = null;
  let bottom = null;

  processedAnswerData.forEach(v => {
    if (!top && v.value) top = v;
    if (!bottom && v.value) bottom = v;

    if (bottom !== v) {
      if (v.date) {
        if (
          moment(Date.parse(v.date)).isAfter(
            moment(Date.parse(bottom.date)),
            'month',
          ) &&
          moment(Date.parse(v.date)).isSameOrAfter(
            moment(Date.parse(bottom.date)),
            'year',
          )
        ) {
          bottom = v;
        }
      }
    }
  });

  let topSet = false;

  processedAnswerData = processedAnswerData.map(v => {
    if (v.value === null) {
      return !topSet
        ? Object.assign({}, top, { notShow: true, date: undefined })
        : Object.assign({}, bottom, { notShow: true, date: undefined });
    } else {
      topSet = true;
      return v;
    }
  });

  let isBeforeIndice = -1;

  const potentialIndices = [];

  processedAnswerData = processedAnswerData.map((v, i) => {
    if (!v.notShow) {
      if (~isBeforeIndice) {
        if (potentialIndices.length) {
          const diff = parseFloat(
            (v.value - processedAnswerData[isBeforeIndice].value).toFixed(2),
          );
          const step = diff / (potentialIndices.length + 1);

          potentialIndices.forEach((pIndice, i) => {
            processedAnswerData[pIndice].value = parseFloat(
              processedAnswerData[isBeforeIndice].value + step * (i + 1),
            );
            processedAnswerData[pIndice].notShow = true;
          });

          potentialIndices.length = 0;
          isBeforeIndice = i;
        } else {
          isBeforeIndice = i;
        }
      } else {
        isBeforeIndice = i;
      }

      return v;
    }

    if (~isBeforeIndice) {
      potentialIndices.push(i);
    }

    return v;
  });

  return processedAnswerData;
}

export function getGeneratedBeforeForNDSAndExcise(data) {
  const _tmp = [];

  data.forEach((v, i) => {
    if (i !== data.length - 1) _tmp.push({ vertSync: i });
  });

  return _tmp;
}

export function decimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0),
  );
}

export function getIconTypeByProductPackageType(type) {
  switch (type) {
    case 'box':
      return 'box';
    case 'block':
      return 'block';
    case 'pack':
      return 'pack';
    default:
      return '';
  }
}

export function getPackageNameByPackageType(type) {
  const pack = type.toLowerCase().trim();
  if (packTrivialTypes[pack]) return packTrivialTypes[pack];
  return '';
}

export function getPackageNameByPackageTypeInParentalCase(type, lower) {
  switch (type.toLowerCase()) {
    case 'box':
      return `${lower ? 'т' : 'Т'}ранспортную`;
    case 'block':
      return `${lower ? 'г' : 'Г'}рупповую`;
    case 'pack':
      return `${lower ? 'п' : 'П'}отребительскую`;
    case 'pallet':
      return `${lower ? 'п' : 'П'}алетту`;
    default:
      return '';
  }
}
