import styled from 'styled-components';


export const TimeWrap = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #abadb6;
  margin-top: 16px;
`;