import React from 'react';

export default {
  title: 'Табличное представление при типе агрегации «Дата», варианте задания периода «Месяц»:',
  amountOfTable: 1,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'Дата',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
          subheaders: []
        },
        {
          Header: 'Биллинг',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
            Header: 'Документов, шт',
            accessor: 'col6',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 140,
            subheaders: []
            },
            {
              Header: 'Заявок, шт',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 100,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Аналитика',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
            Header: 'Документов, шт',
            accessor: 'col8',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 140,
            subheaders: []
            },
            {
              Header: 'Заявок, шт',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 100,
              subheaders: []
            },
          ]
        },
        {
          Header: <div>Разница<br/> Биллинг - Аналитика</div>,
          accessor: 'col10',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 220,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
          'col10': [10],
        },
        {
          'col1': <strong>Итого</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
      ]
    },
  ]
}