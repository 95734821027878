export const loginTheme = {
  Header: {
    background: '#283552',
  },
  Content: {
    dark: {
      background: '#283552',
      color: '#212C42',
    },
  },
};
