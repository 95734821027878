import { ProductService } from '../services/ProductService';

import { getListActions } from '../utils/actionGenerators';

import { FormSerializer } from '../utils/FormSerializer';
import {actionTypes, PAGINATED_LIST_CONSTANTS} from '../constants';
import {
  defaultFilterParamSerializer,
  ProductListRequestParamSerializer,
  fromURLMountFilterParamsSerializer,
  fromURLPageSerializer,
} from '../utils/filterSerializers';
import {
  extendURLByKeyValueList,
  modifyURLByRawDataAndFiltersConfig,
} from '../utils/formEvents';
import ProductList from '../projects/motp/lists/ProductList';
import { getPageFormatter } from '../utils/pageFormatters';

import {Toast} from '../utils/Toast';
import {history} from '../store';
import {StoreHelper} from '../utils/StoreHelper';

function productCreated() {
  return {
    type: actionTypes.PRODUCT_LIST.PRODUCT_CREATED,
  };
}

const onFilterLoad = data => {
  if (1 === data.results.length) {
    const { id } = data.results[0];
    history.push(`/products/list/${id}/`);
    StoreHelper.getStore().dispatch({
      type: actionTypes[ProductList.actionNamespace][PAGINATED_LIST_CONSTANTS.CLEAR_FILTER_PARAMS]
    });
  }
};

const createProduct = function(params, onCreate) {
  return dispatch => {
    const formed = FormSerializer.serialize(params);

    const product = {
      brand: '',
      gtin: '',
      inn: '',
      model: '',
      packageType: '',
      publicationDate: '',
      ...formed,
    };

    ProductService.createProduct(product).then(answer => {
      Toast.showSuccess({
        content: `Товар успешно создан (GTIN: ${formed.gtin}).`,
      });
      dispatch(productCreated());
      onCreate(answer.data);
    });
  };
};

const uploadProductListCsv = function(files, saveUnregistred, callback) {
  return () => {
    ProductService.upProductListCsv(files, saveUnregistred)
      .then((response) => {
        callback(response.data)
      })
      .catch((e)=> {
        callback()
        Toast.showError({ content: `Не удалось загрузить. ${e.message}` });
      })
  }
}

const downloadProductListCsv = (filterParams) => {
  return () => {
    ProductService.downloadProductListCsv(filterParams).then(({ data }) => {
      const file = new Blob([data], { type: 'text/csv' });
      const filename = 'product list';
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else {
        // Others
        const a = document.createElement('a'),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    })
  };
};

const actions = {
  ...getListActions({
    getCancelListRequest: () => ProductService._getListCancel,
    actionNamespace: ProductList.actionNamespace,
    selectorNamespace: ProductList.selectorNamespace,
    getList: ProductService.getList,
    pageFormatter: getPageFormatter(ProductList.limit),
    pageSerializer: fromURLPageSerializer,
    filterApplyFilterParamsSerializer: defaultFilterParamSerializer,
    filterApplyRequestParamsSerializer: ProductListRequestParamSerializer,
    listMountFilterParamsSerializer: fromURLMountFilterParamsSerializer,
    listMountRequestParamsSerializer: fromURLMountFilterParamsSerializer,
    onFilterApplyWithoutSerializing: data =>
      modifyURLByRawDataAndFiltersConfig(
        Object.assign(data, { page: [1] }, { name: data.gtin }),
        [...ProductList.filters, [{props: {name: 'name'} }] ]
      ),
    onPageSelect: pageNumber => {
      extendURLByKeyValueList({ page: pageNumber });
    },
    onFilterLoad: onFilterLoad,
  }),
  createProduct,
  uploadProductListCsv,
  downloadProductListCsv,
};

export default actions;
