import React from 'react';

export default {
  title: 'Табличное представление при выборе типа агрегации «По субъекту РК»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Субъект РК',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 190,
          subheaders: []
        },
        {
          Header: 'Количество торговых точек',
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
          subheaders: []
        },
        {
          Header: 'Объём реализации',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 410,
          subheaders: [
            {
              Header: 'в штуках',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 205,
              subheaders: []
            },
            {
              Header: 'в тенге (по цене продажи)',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 205,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
        },
        {
          'col1': <strong>Итого</strong>,
          'col4': '',
          'col5': '',
          'col6': '',
          'col7': '...',
          'col8': '...',
          'col9': '...',
        },
      ]
    },
  ]
}
