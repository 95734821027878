import React from 'react';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';
import {Information} from '@crpt-ui/icons';
import {Wrap} from './value';

export const UndefinedPhrase = ({ text }) => (
  <Wrap>
    <span>Загрузка...</span>
    <Tooltip
      text={text}
      mainText={[<Information />]}
      sliceTitleStart="0"
      sliceTitleEnd="1"
    />
  </Wrap>
);