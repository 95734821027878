import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { Input } from '@crpt/react-input';
import { selectLoginTheme } from '../../theme/ru-tabaco/select';
import { hasOnlyDigits } from '../../utils';
import { FilterURLHelper } from '../../utils/FilterURLHelper';

const MainWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  box-shadow: ${props =>
    props.isOpen ? selectLoginTheme.InputWrap.open.boxShadow : ''};
`;

const Content = styled.div`
  padding: 16px;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  top: 59px;
  border: ${selectLoginTheme.InputWrap.main.border};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: ${props =>
    props.isOpen ? selectLoginTheme.InputWrap.open.boxShadow : ''};
`;

const Select = styled.div`
  box-sizing: border-box;
  border-radius: ${selectLoginTheme.InputWrap.borderRadius};
  border: ${selectLoginTheme.InputWrap.main.border};
  height: ${selectLoginTheme.InputWrap.height};
  font-size: ${selectLoginTheme.InputElem.fontSize};
  font-weight: ${selectLoginTheme.InputElem.fontWeight};
  color: ${selectLoginTheme.InputElem.main.color};
  padding-left: 20px;
  display: flex;
  align-items: center;
`;

const ArrowWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: ${props => (props.isOpen ? '0' : '13px')};
  padding-left: ${props => (props.isOpen ? '13px' : '0')};
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : '')};
`;

const InputWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  width: 124px;
`;

const LineBetween = styled.div`
  width: 20px;
  height: 1px;
  background: #434244;
`;

const inputTheme = {
  height: '38px',
  fontSize: '16px',
  fontWeight: 400,
  borderRadius: '2px',
  Placeholder: {
    fontWeight: 200,
    main: {
      color: '#9F9F9F',
    },
    error: {
      color: '#FF3D00',
    },
    disabled: {
      color: 'rgba(82, 83, 90, 0.5)',
    },
    normal: {
      fontSize: '16px',
      height: '22px',
      top: '8px',
    },
    focused: {
      top: '2px',
      fontSize: '12px',
      height: '14px',
    },
  },
  InputWrap: {
    main: {
      background: '#FFFFFF',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'normal',
    },
    disabled: {
      background: '#ffffff',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'not-allowed',
    },
    error: {
      background: '#FFFFFF',
      border: '1px solid #FF3D00',
      cursor: 'normal',
    },
    height: '38px',
    borderRadius: '2px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  InputElem: {
    main: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text',
    },
    disabled: {
      color: '#abadb6',
      placeholderColor: '#ACADB5',
      cursor: 'not-allowed',
    },
    error: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text',
    },
    height: '16px',
    fontSize: '16px',
    fontWeight: 200,
    background: 'rgba(0,0,0,0)',
  },
};

class MRPFromToSelect extends Component {
  node;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      mrpFrom: null,
      mrpTo: null,
    };

    this.onSelectClick = this.onSelectClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onMrpFromChange = this.onMrpFromChange.bind(this);
    this.onMrpToChange = this.onMrpToChange.bind(this);
  }

  handleClick(e) {

    if (!this.node) return;

    if (this.node.contains(e.target)) {
      return;
    }

    this.setState({
      isOpen: false,
    });
  }
  // eslint-disable-next-line
  componentWillMount() {
    const mrpFrom = FilterURLHelper.getParamByKey('mrpFrom');
    const mrpTo = FilterURLHelper.getParamByKey('mrpTo');
    if (mrpFrom) {
      this.setState({
        mrpFrom: (+mrpFrom) / 100,
      });

      this.props.onMrpFromChange && this.props.onMrpFromChange(+mrpFrom);
    }

    if (mrpTo) {
      this.setState({
        mrpTo: (+mrpTo) / 100,
      });

      this.props.onMrpToChange && this.props.onMrpToChange(+mrpTo);
    }

    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  onMrpFromChange(val) {
    this.setState({
      mrpFrom: val.length ? val : null,
    });

    this.props.onMrpFromChange && this.props.onMrpFromChange(+val * 100);
  }

  onMrpToChange(val) {
    this.setState({
      mrpTo: val.length ? val : null,
    });

    this.props.onMrpToChange && this.props.onMrpToChange(+val * 100);
  }

  onSelectClick(e) {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const text =
      this.state.mrpFrom !== null || this.state.mrpTo !== null
        ? `${this.state.mrpFrom !== null ? this.state.mrpFrom : ''} - ${
            this.state.mrpTo !== null ? this.state.mrpTo : ''
          }`
        : 'МРЦ';

    return (
      <MainWrap isOpen={this.state.isOpen} innerRef={el => (this.node = el)}>
        <Select onClick={this.onSelectClick}>
          {text}
          <ArrowWrap isOpen={this.state.isOpen}>
            <Icon type="down-arrow" />
          </ArrowWrap>
        </Select>
        <Content isOpen={this.state.isOpen}>
          <InputWrap>
            <InputWrapper>
              <Input
                onRef={this.props.onMrpFromRef ? this.props.onMrpFromRef : null}
                theme={{ Input: inputTheme }}
                onChange={this.onMrpFromChange}
                name={'mrpFrom'}
                placeholder={'От'}
                validate={hasOnlyDigits}
              />
            </InputWrapper>
            <LineBetween />
            <InputWrapper>
              <Input
                onRef={this.props.onMrpToRef ? this.props.onMrpToRef : null}
                theme={{ Input: inputTheme }}
                onChange={this.onMrpToChange}
                validate={hasOnlyDigits}
                name={'mrpTo'}
                placeholder={'До'}
              />
            </InputWrapper>
          </InputWrap>
        </Content>
      </MainWrap>
    );
  }
}

MRPFromToSelect.displayName = 'MRPFromToSelect';

export default MRPFromToSelect;
