import Draft from './Draft.svg';
import DeliveringIsExpected from './DeliveringIsExpected.svg';
import Sent from './Sent.svg';
import Delivered from './Delivered.svg';
import ViewedSignatureIsNotRequired from './ViewedSignatureIsNotRequired.svg';
import SignatureRequired from './SignatureRequired.svg';
import ViewedSignatureRequired from './ViewedSignatureRequired.svg';
import Signed from './Signed.svg';
import SignatureDenied from './SignatureDenied.svg';
import Recalled from './Recalled.svg';
import Clarified from './Clarified.svg';
import ClarificationRequired from './ClarificationRequired.svg';
import SignError from './SignError.svg';
import DeliveringError from './DeliveringError.svg';
import WaitSending from './WaitSending.svg';
import WaitAnswer from './WaitAnswer.svg';
import Accepted from './Accepted.svg';
import NotAccepted from './NotAccepted.svg';
import Download from './Download.svg';
import Block from './Block.svg';
import Checkmark from './Checkmark.svg';
import Cross from './Cross.svg';
import Move from './Move.svg';
import Rectification from './Rectification.svg';
import BigEdit from './BigEdit.svg';
import Alcohol from './Alcohol.svg';
import Tobacco from './Tobacco.svg';
import Box from './Box.svg';
import DocumentText from  './DocumentText.svg';
import ConvertCube from './ConvertCube.svg';
import Charts from './Charts.svg';
import Global from './Global.svg';
import Tg from './Tg.svg';
import NoteRemove from './NoteRemove.svg';
import PresentationNotes from './PresentationNotes.svg';
import Calculator from './Calculator.svg';
import Message from './Message.svg';
import DocText from './DocText.svg';
import Clock from './clock.svg';
import Mobile from './Mobile.svg';
import Pause from './pause-circle.svg';
import CheckCircle from './tick-circle.svg';
import Gallery from './gallery.svg';
import Article from './Union.svg';
import EditUz from './EditUz.svg';
import EditArticle from './EditArticle.svg';
import Copy from './Copy.svg';
import Warning from './warning.svg'

const Icons = {
  Draft,
  DeliveringIsExpected,
  Sent,
  Delivered,
  ViewedSignatureIsNotRequired,
  SignatureRequired,
  ViewedSignatureRequired,
  Signed,
  SignatureDenied,
  Recalled,
  Clarified,
  ClarificationRequired,
  SignError,
  DeliveringError,
  WaitSending,
  WaitAnswer,
  Accepted,
  NotAccepted,
  Download,
  Block,
  Checkmark,
  Cross,
  Move,
  Rectification,
  BigEdit,
  Alcohol,
  Tobacco,
  Box,
  DocumentText,
  ConvertCube,
  Charts,
  Global,
  Tg,
  NoteRemove,
  PresentationNotes,
  Calculator,
  Message,
  DocText,
  Clock,
  Pause,
  Mobile,
  CheckCircle,
  Gallery,
  Article,
  Copy,
  EditUz,
  EditArticle,
  Warning,
};

export default Icons;
