export const informationTheme = {
  minWidth: '200px',
  height: '60px',
  fontFamily: 'SegoeUI, sans-serif',
  fontSize: '16px',
  fontWeight: 200,
  borderRadius: '3px',
  color: '#52535A',

  Placeholder: {
    fontWeight: 200,
    main: {
      color: '#52535A',
    },
    error: {
      color: '#abadb6',
    },
    disabled: {
      color: '#b6b6b9',
    },
    normal: {
      fontSize: '16px',
    },
    focused: {
      fontSize: '14px',
    },
  },

  InputWrap: {
    main: {
      background: '#FFFFFF',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'normal',
    },
    disabled: {
      background: '#FBFBFB',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'not-allowed',
    },
    error: {
      background: '#FFEBEB',
      border: '1px solid #FF3C21',
      cursor: 'normal',
    },
    height: '60px',
    borderRadius: '3px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },

  InputElem: {
    main: {
      color: '#52535A',
      placeholderColor: '#ACADB5',
      cursor: 'text',
    },
    disabled: {
      color: '#b3b3b4',
      placeholderColor: '#b6b6b9',
      cursor: 'not-allowed',
    },
    error: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text',
    },
    height: '24px',
    fontSize: '16px',
    fontWeight: 200,
    background: 'rgba(0,0,0,0)',
  },

  RenderWrap: {
    main: {
      color: '#212C42',
    },
    disabled: {
      color: '#212C42',
      cursor: 'not-allowed',
    },
    error: {
      color: '#212C42',
    },
    background: 'rgba(0,0,0,0)',
  },

  SelectOption: {
    height: 'auto',
    color: '#52535A',
    hoverColor: '#52535A',
    hoverBackground: '#F8EC31',

    disabled: {
      background: '#ffffff',
      color: '#ACADB5',
      hoverColor: '#ACADB5',
    },
  },

  SelectOptionsPanel: {
    paddingTop: '0px',
    paddingBottom: '0px',
    top: '58px',
    border: '1px solid rgba(196, 196, 196, 0.25)',
    maxHeight: '300px',
    open: {
      boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.25)',
    },
  },
};
