import XLSX from '../../packages/xlsx/xlsx.full.min';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as actions from './File.actions';

const readFile = (file, meta = {}) => {
  const { name } = file;
  const type = name
    .substr(name.lastIndexOf('.') + 1, name.length)
    .toUpperCase();

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = reject;

    if (meta.raw) {
      resolve({
        content: file,
        type,
        name,
      });
    } else if (meta.asText) {
      fileReader.onload = () =>
        resolve({
          content: fileReader.result,
          type,
          name,
        });

      fileReader.readAsText(file, 'utf-8');
    } else if (meta.rawWithText) {
      if (type === 'XLSX' || type === 'XLS') {
        fileReader.onload = e => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: 'array', raw: true, dateNF: 'dd"."mm"."yyyy', });

          resolve({
            content: workbook,
            data: e.target.result,
            file,
            type,
            name,
          });
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        fileReader.onload = () => {
          resolve({
            content: fileReader.result,
            file,
            type,
            name,
          });
        };

        fileReader.readAsText(file, 'utf-8');
      }
    }

    else if (meta.asArrayBuffer) {

      fileReader.onload = e => {

        var data = new Uint8Array(e.target.result);

        resolve({
          content: data,
          data: e.target.result,
          file,
          type,
          name,
        });
      };

      fileReader.readAsArrayBuffer(file);
    }

    else {
      fileReader.onload = () =>
        resolve({
          content: fileReader.result.split(',').pop(),
          type,
          name,
        });

      fileReader.readAsDataURL(file);
    }
  });
};

function* fileSaga(action) {
  const { payload, meta } = action;

  try {
    const file = yield call(readFile, payload, meta);
    yield put({
      type: `${actions.success} ${meta.subject}`,
      payload: file,
      meta,
    });
    if (meta.success) meta.success(file);
  } catch (e) {
    yield put({ type: `${actions.error} ${meta.subject}`, payload: e, meta });
    if (meta.error) meta.error(e);
  }
}

export default function* watchFileCalls() {
  yield takeEvery([actions.read], fileSaga);
}
