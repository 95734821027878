import React, {memo} from 'react';
import {useStyles} from './Title.styles';
import {Typography} from '@crpt-ui/core';

export const GroupInfoTitle = memo(({title, renderRightSide = null, isHeader = false, classes = ''}) => {
  const styles = useStyles();
  const typographyVariant = isHeader ? 'h2' : 'h3';
  
  return (
    <div className={`${styles.wrapper} ${classes}`}>
      <div>
        <Typography variant={typographyVariant}>
          {title}
        </Typography>
      </div>
      <div className={styles.rightSide}>
        {renderRightSide && renderRightSide}
      </div>
    </div>
  )
});