export const createUserTheme = {
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: '"SegoeUI", sans-serif',
  large: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '48px'
  },
  normal: {
    paddingLeft: '0px',
    paddingRight: '0px',
    height: '40px'
  },
  main: {
    border: '0',
    color: '#55B5E7',
    background: '#FFFFFF',
    boxShadow: 'none'
  },
  disabled: {
    border: '0',
    color: '#FFFFFF',
    background: 'linear-gradient(0deg, #C4C4C4, #C4C4C4);'
  },
  minWidth: '0',
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px'
};
