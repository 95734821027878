import React, { useMemo } from 'react';
import moment from 'moment';

import { Icon } from '@crpt/react-icon';
import { Tooltip } from '@crpt/react-tooltip';
import { HistoryHelper } from '../../utils/HistoryHelper';
import {
  CIS_STATUS_NAMES,
  CIS_STATUSES_TO_CONSTANTS, countryCodeFlags,
  EAESPackageTypes,
} from '../../constants';
import {
  packTrivialTypes,
  PACK_TYPE_TO_CONSTANTS,
} from '../../constants/packTypes';
import {
  getIconNameByPackType,
  getIconNameByCISStatus,
} from '../TableCells/CIS/utils';
import ParticipantSelectFilter from './filters/ParticipantSelectFilter';
import StatusFilter from './filters/StatusFilter';
import PackageFilter from './filters/PackageFilter';
import DateFilter from './filters/DateFilter';
import TextFilter from './filters/TextFilter';
import { DateName, NoWrap, OptionWrap, Link, IconWrapper } from './Grid.styled';
import i18next from 'i18next';
import { Translate } from '../../common_components/Translate/Translate';
import StatusExtFilter from './filters/StatusExt';

export const openedFilters = {
  filters: {},
};

export const PAGE_SIZE = 20;
export const HEADER_ROW_HEIGHT = 108;

export const statusesExt = {
  33: "Вложен в АИК",
  34: "Сформирован",
  35: "Расформирован",
  36: "Выпущен ГТК",
};

export const statusesExtNames = {
  INSERT_TO_AIC: "Вложен в АИК",
  AIC_AGGREGATED: "Сформирован",
  AIC_DISAGGREGATED: "Расформирован",
  CUSTOMS_ISSUED: "Выпущен ГТК",
}

export const columns = [
  {
    key: 'emissionDate',
    displayName: Translate('Дата'),
    name: (
      <DateName>
        <Tooltip
          position="right"
          theme={{ Tooltip: { padding: '5px 18px', zIndex: 9999 } }}
          content={<div>{Translate('Дата эмиссии')}</div>}
        >
          {Translate('Дата')}
        </Tooltip>
      </DateName>
    ),
    sortable: true,
    draggable: true,
    resizable: true,
    filterable: true,
    filterFirst: true,
    sortableWithFilterOn: true,
    width: 129,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <DateFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
          close={hide}
          placeholder={Translate('Фильтр по дате')}
          periods={{ owner: 30, producerId: 30, default: 5 }}
        />
      );
    },
    formatter: ({ value }) => {
      return (
        <NoWrap>
          {value &&
            moment(value)
              .utc()
              .format('DD.MM.YYYY')}
        </NoWrap>
      );
    },
    filterHeaderFormatter: val => {
      if (val.filterValue != null) {
        const { to, from } = val.filterValue;
        return `${
          from ? `${i18next.t('от')} ${moment(from).format('DD.MM.YYYY')}` : ''
        } ${to ? `${i18next.t('до')} ${moment(to).format('DD.MM.YYYY')}` : ''}`;
      }
      return val.filterTerm;
    },
    filterClass: 'react-grid-Header__date-btn',
    filterWidth: 300,
    hidden: false,
  },
  {
    key: 'status',
    displayName: Translate('Статус кода'),
    name: <NoWrap fontWeight={600}>{Translate('Статус кода')}</NoWrap>,
    sortable: true,
    draggable: true,
    filterable: true,
    filterFirst: true,
    resizable: true,
    width: 216,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <StatusFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },
    formatter: ({ value }) => {
      const CODE = CIS_STATUSES_TO_CONSTANTS[value];
      return (
        <OptionWrap>
          {value !== undefined && (
            <Icon
              type={getIconNameByCISStatus(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />
          )}
          <NoWrap>{value !== undefined && Translate(CIS_STATUS_NAMES[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    filterHeaderFormatter: val => {
      if (val.filterValue != null && val.filterValue.title != null) {
        const CODE = CIS_STATUSES_TO_CONSTANTS[val.filterTerm];

        return (
          <OptionWrap>
            {val.filterTerm !== undefined && (
              <Icon
                type={getIconNameByCISStatus(CODE)}
                fill="rgb(82, 83, 90)"
                size={16}
                spacingRight={4}
              />
            )}
            <NoWrap>
              {val.filterTerm !== undefined && Translate(CIS_STATUS_NAMES[CODE])}
            </NoWrap>
          </OptionWrap>
        );
      }
      return val.filterTerm;
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },
  ...countryCodeFlags.isUZ ? [{
    key: 'statusExt',
    displayName: Translate('Расширенный статус'),
    name: <NoWrap fontWeight={600}>{Translate('Расширенный статус')}</NoWrap>,
    sortable: false,
    draggable: true,
    resizable: true,
    hidden: false,
    width: 500,
    filterable: true,
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    formatter: ({ value }) => <NoWrap>{statusesExt[value] || '—'}</NoWrap>,
    filterHeaderFormatter: val => {
      return <OptionWrap>
        {Translate(statusesExt[val.filterTerm] || "")}
      </OptionWrap>;
    },
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <StatusExtFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },
  }] : [],
  {
    key: 'cis',
    displayName: Translate('Код'),
    name: <NoWrap fontWeight={600}>{Translate('Код')}</NoWrap>,
    sortable: false,
    draggable: true,
    resizable: true,
    filterable: false,
    filterFirst: true,
    width: 218,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <TextFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },
    formatter: props => {
      // TODO: проблема % в uri - описание в /utils/uri.js
      return (
        <Link
          href={`/cis/list/${encodeURIComponent(props.value)}`}
          onClick={e => {
            e.preventDefault();
            HistoryHelper.getHistory().push(
              `/cis/list/${encodeURIComponent(props.value)}`,
            );
          }}
        >
          <NoWrap>{props.value}</NoWrap>
        </Link>
      );
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },
  {
    key: 'packType',
    displayName: Translate('Тип упаковки'),
    name: <NoWrap fontWeight={600}>{Translate('Тип упаковки')}</NoWrap>,
    sortable: true,
    draggable: true,
    resizable: true,
    filterable: true,
    filterFirst: true,
    width: 168,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <PackageFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },
    formatter: ({ value }) => {
      let CODE = PACK_TYPE_TO_CONSTANTS[value];
      CODE = CODE === 'pallet' ? 'box' : CODE;
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{Translate(EAESPackageTypes[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    filterHeaderFormatter: val => {
      if (val.filterValue != null && val.filterValue.title != null) {
        return (
          <OptionWrap>
            <Icon
              type={getIconNameByPackType(
                PACK_TYPE_TO_CONSTANTS[val.filterTerm],
              )}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={4}
            />{' '}
            <NoWrap>{Translate(val.filterValue.title)}</NoWrap>
          </OptionWrap>
        );
      }
      return val.filterTerm;
    },
    filterWidth: 300,
    hidden: false,
  },
  {
    key: 'gtin',
    displayName: Translate('Товар'),
    name: <NoWrap fontWeight={600}>{Translate('Товар')}</NoWrap>,
    sortable: true,
    draggable: true,
    filterable: true,
    filterFirst: true,
    resizable: true,
    width: 202,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <TextFilter
          title="GTIN товара"
          onlyDigits
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },

    formatter: ({ value }) => {
      return (
        <div>
          <NoWrap>{value.gtin}</NoWrap>
          <NoWrap>{value.name}</NoWrap>
        </div>
      );
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },
  /*{
    key: 'mrc',
    name: 'МРЦ, Р',
    sortable: true,
    draggable: true,
    filterable: true,
    resizable: true,
    width: 132,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide
    ) {
      return (
        <TextFilter
          column={column}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
        />
      );
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },*/
  {
    key: 'producerId',
    displayName: Translate(countryCodeFlags.isUZ ? 'Производитель' : 'Эмитент'),
    name: <NoWrap fontWeight={600}>{Translate(countryCodeFlags.isUZ ? 'Производитель' : 'Эмитент')}</NoWrap>,
    sortable: true,
    draggable: true,
    resizable: true,
    filterable: true,
    width: 294,
    filterFirst: true,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <ParticipantSelectFilter
          column={column}
          name={Translate(countryCodeFlags.isUZ ? 'Производитель' : 'Эмитент')}
          placeholder={Translate(countryCodeFlags.isUZ ? 'Фильтр по производителю' : "Фильтр по эмитенту" )}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
          close={hide}
        />
      );
    },
    formatter: ({ value }) => {
      return (
        <div>
          <NoWrap>{value.name}</NoWrap>
          <NoWrap>{value.inn}</NoWrap>
        </div>
      );
    },
    filterHeaderFormatter: val => {
      if (val.filterValue != null && val.filterValue.name != null) {
        return val.filterValue.name;
      }
      return val.filterTerm;
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },
  {
    key: 'childrenCount',
    displayName: Translate('Ед. внутри'),
    name: <NoWrap fontWeight={600}>{Translate('Ед. внутри')}</NoWrap>,
    sortable: false,
    draggable: true,
    resizable: true,
    filterable: false,
    width: 120,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return;
    },
    formatter: ({ value }) => {
      return (
        <OptionWrap>{typeof value !== 'undefined' ? value : '-'}</OptionWrap>
      );
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 120,
    hidden: false,
  },
  {
    key: 'owner',
    displayName: Translate('Владелец'),
    name: <NoWrap fontWeight={600}>{Translate('Владелец')}</NoWrap>,
    sortable: true,
    draggable: true,
    resizable: true,
    filterable: true,
    filterFirst: true,
    width: 294,
    filterComponentRenderer: function(
      column,
      onFilterChange,
      appliedFilter,
      hide,
    ) {
      return (
        <ParticipantSelectFilter
          column={column}
          name={Translate('Владелец')}
          placeholder={Translate('Фильтр по владельцу')}
          onFilterChange={onFilterChange}
          appliedFilter={appliedFilter}
          close={hide}
        />
      );
    },
    formatter: ({ value }) => {
      if (value == null || value.inn == null) {
        return '';
      }
      return (
        <div>
          <NoWrap>{value.name}</NoWrap>
          <NoWrap>{value.inn}</NoWrap>
        </div>
      );
    },
    filterHeaderFormatter: val => {
      if (val.filterValue != null && val.filterValue.name != null) {
        return val.filterValue.name;
      }
      return val.filterTerm;
    },
    filterClass: 'react-grid-Header__filter-btn',
    filterWidth: 300,
    hidden: false,
  },
];
