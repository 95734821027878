import React from 'react';
import { connect } from 'react-redux';
import SidebarContentView from './SidebarContent.view';
import * as appSelectors from '../../../common_components/App/App.selectors';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import { cardTheme } from './SidebarContent.constants';


const SidebarContent = (props) => {
  const { config, onClose, ...pass } = props;

  return (
    <SidebarContentView
      theme={cardTheme}
      documentLink={config.docs.reg}
      onClose={onClose}
      {...pass}
    />
  );
};

const mapState = state => ({
  config: appSelectors.config(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(sidebarActions.close()),
});

export default connect(mapState, mapDispatch)(SidebarContent);
