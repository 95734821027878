import { registrationTheme } from './registration';
import { loginTheme } from './login';
import { exciseTheme } from './excise';
import { edosOfds } from './edosOfds';

export {
  registrationTheme as selectRegistrationThemeKZ,
  loginTheme as selectLoginThemeKZ,
  exciseTheme as selectExciseThemeKZ,
  edosOfds as selectEdosOfdsThemeKZ
};
