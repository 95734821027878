import { Tooltip } from '@crpt-ui/core';
import { withStyles } from '@material-ui/core';
import clsx from 'classnames';
import React, { useMemo } from 'react';

import AccountStyles from './Account.styles';
import { ProductGroupIconsMap, ProductGroupNamesMap } from './enums';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Translate } from '../Translate/Translate';
import { useTranslation } from 'react-i18next';

const Account = ({ account, classes, className, currency, ...rest }) => {
  const {t} = useTranslation();
  const balance = useMemo(
    () =>
      (account.balance || 0).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
      }),
    [account],
  );

  const productGroupId = useMemo(() => account.productGroupId, [account]);

  const icon = useMemo(() => ProductGroupIconsMap[productGroupId] || 'icon', [
    productGroupId,
  ]);

  const title = useMemo(() => ProductGroupNamesMap[productGroupId], [
    productGroupId,
  ]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {icon ? (
        <Tooltip
          arrow
          disableHoverListener={!title}
          disableTouchListener={!title}
          placement="bottom"
          title={
            <div
              style={{
                textAlign: 'center',
                color: 'white',
              }}
            >
              {title}
            </div>
          }
        >
          <div className={classes.icon}>{icon}</div>
        </Tooltip>
      ) : (
        <div />
      )}
      <div>
        <div className={classes.label}>
          {Translate('Лицевой счет')}&nbsp;&#8470;{account.contractId}
        </div>
        <div className={classes.value}>
          {balance}&nbsp;{t(currency)}
        </div>
      </div>
    </div>
  );
};
const mapState = state => ({
  currency: state.config.currency,
});

const mapDispatch = dispatch => ({});

const connectedComponent = compose(
  connect(mapState, mapDispatch),
  withStyles(AccountStyles),
)(Account);
export default connectedComponent;
