import React from 'react';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { Button as CrptButton } from '@crpt/react-button';
import { Wrap } from './styled';

const Element = props => (
  <Wrap inline={props.inline} noBottomMargin={props.noBottomMargin}>
    <CrptButton {...props} />
  </Wrap>
);

export default compose(
  setDisplayName('Button'),
  onlyUpdateForKeys(['children', 'disabled']),
)(Element);
