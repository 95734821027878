import React from 'react';
import moment from 'moment/moment';
import { compact, get, isArray, isNumber, isObject, isString } from 'lodash';
import ListFieldCenteredPanelWrapper from '../../Form/Fields/Common/ListFieldCenteredPanelWrapper';
import ListFieldFullWidthPanelWrapper from '../../Form/Fields/Common/ListFieldFullWidthPanelWrapper';

const getDisplayName = (c) => {
  if (!c) return undefined;
  if (isString(c)) return c;
  return c.displayName || c.name || 'Component';
};

const getValue = (definition, value) => {
  const { Field, props } = definition;

  if (!value && !isNumber(value)) return '';

  const format = get(definition, 'props.format', undefined);
  const toCellValue = get(definition, 'props.toCellValue', undefined);

  switch (getDisplayName(Field)) {
    case 'SingleSelect':
      if (!format) {
        return get(props.values.find(v => v.type === value), 'title', value);
      }
      break;
    case 'DatePicker':
    case 'RangePicker':
      if (isString(value)) {
        return format ? format(value) : moment(value).format(props.dateFormat || 'DD.MM.YYYY');
      }
      break;
    case 'SerialNumber':
      if (isArray(value)) {
        if (value.length === 0) return '-';
        if (value.length === 1) return value[0];
        return `${value.length} серийных номеров`;
      }
      break;
    default:
      break;
  }

  if (toCellValue) { return toCellValue(value); }
  if (format) { return format(value); }
  return value;
};

export const getVisibility = (props, values) => {
  const { visibility } = props;
  if (!visibility) return true;

  if (isArray(visibility)) {
    return visibility.reduce(
      (result, visibilityFunction) => (visibilityFunction(values) && result),
      true,
    );
  }

  return visibility(values);
};

const Cell = (cellProps = {}) => {
  const arrayName = get(cellProps, 'column.arrayName', '');
  const Header = get(cellProps, 'column.Header', '');
  const rowData = get(cellProps, 'original', get(cellProps, 'row._original'));
  const isEdited = get(cellProps, 'tdProps.rest.data-editing', false);
  const definition = get(cellProps, 'column.definition', {});
  const id = get(cellProps, 'column.id', '');
  const name = get(definition, 'props.name', id);
  const placeholder = get(definition, 'props.placeholder', Header);
  const { Field, props = {} } = definition;


  if (!isEdited) {
    if (getDisplayName(definition.Field) === 'RangePicker') {
      const from = getValue(definition, get(rowData, `${name}_from`));
      const to = getValue(definition, get(rowData, `${name}_from`));
      return `${from} - ${to}`;
    }

    return getValue(definition, get(rowData, name));
  }

  const fieldName = [arrayName, rowData._id, name].join('.');
  const { panelFillWidth } = props;

  const Wrapper = panelFillWidth
    ? ListFieldFullWidthPanelWrapper
    : ListFieldCenteredPanelWrapper;

  return (
    <Wrapper>
      <Field name={fieldName} {...props} placeholder={placeholder} marginBottom="0" />
    </Wrapper>
  );
};

export const prepareColumns = (name, columns, values) => {
  const updatedColumns = columns.map((column) => {
    if (!isObject(column.definition)) return column;
    if (!getVisibility(column, values)) { return null; }

    return {
      ...column,
      Cell: Cell,
    };
  });

  return compact(updatedColumns);
};
