import { Translate } from '../../../../common_components/Translate/Translate';
import React from 'react';
import { Value } from '../../../../fragments/Table/Cell/value';
import { makeInputFilter } from '@crpt-ui/datatable';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Заявка на регистрацию кодов ИС МДЛП'),
    id: 2,
  },
];

export const steps = {
  info: 0,
  cis: 1,
};

export const tabs = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
];

export const contentBodyConfirm = (
  <div>
    {Translate('Вы действительно хотите отменить отправку?')}
    <br />
    {Translate('Все введенные данные будут потеряны')}
  </div>
);

export const columns = [
  {
    id: 'cis',
    accessor: 'cis',
    Header: Translate(''),
  },
]
