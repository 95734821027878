import { takeLatest, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { notify } from 'react-notify-toast';
import * as actions from '../../ducks/DocumentDetails.actions';
import { CISService } from '../../../../services/CISService';

function* loadSaga(action) {
  const { payload } = action;

  const answer = yield call(CISService.getList, payload);

  if (get(answer, 'data.error', false)) {
    yield call(notify.show, get(answer, 'data.error', 'error'), 'error', 3000);
  } else {
    yield put(actions.loadedCodes(answer.data));
  }
}

export default function* watch() {
  yield takeLatest(actions.updateCodes, loadSaga);
}
