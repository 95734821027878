import { Button, Tabs } from '@crpt-ui/core';
import { Refresh, Send } from '@crpt-ui/icons';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Content from 'src/common_components/Content/Content';
import Preloader from 'src/common_components/Preloader/Preloader';
import { Modal } from 'src/fragments/Modal';
import { useModalState } from 'src/hooks';

import * as grayAreaActions from '../ducks/GrayArea.actions';
import { GrayAreaSignature } from '../GrayAreaSignature';
import { CardInformation } from './CardInformation';
import { DocumentStatusInfo } from './DocumentStatusInfo';
import * as grayAreaDocumentActions from './ducks/GrayAreaDocument.actions';
import * as grayAreaDocumentSelectors from './ducks/GrayAreaDocument.selectors';
import { getBreadcrumbs, tabs } from './GrayAreaDocument.constants';
import { TabContent, useStyles } from './GrayAreaDocument.styles';
import {
  reformActionAvailable,
  signActionAvailable,
} from './GrayAreaDocument.utils';
import { Gtins } from './Gtins';
import { ProductionLines } from './ProductionLines';
import { Translate } from '../../../common_components/Translate/Translate';

const GrayAreaDocument = ({
  history,
  selectedTab,
  documentId,
  setTab,
  returnToGrayZone,
  clear,
  getDocument,
  documentInfo,
  loaded,
  reformDocument,
  downloadGrayAreaReport,
}) => {
  useEffect(() => {
    getDocument({ id: documentId });
    return () => clear();
  }, []);

  const [isModalOpen, openModal, closeModal] = useModalState();

  const memoizedReformDocument = useCallback(() => {
    reformDocument({ id: documentInfo.id });
  }, [documentInfo]);

  const titleCardInfo =
    Translate('Уведомление о временно непрослеживаемых кодах идентификации');

  const breadcrumbs = getBreadcrumbs({ documentId, titleCardInfo });

  const styles = useStyles();

  const renderTabsContent = () => (
    <React.Fragment>
      <Modal
        maxWidth="md"
        useHeaderDivider
        title={Translate("Подписание документа")}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <GrayAreaSignature
          id={documentId}
          documentInfo={documentInfo.report}
          onClose={closeModal}
          downloadGrayAreaReport={downloadGrayAreaReport}
        />
      </Modal>
      <TabContent isVisible={selectedTab === 0}>
        <CardInformation
          returnToGrayZone={returnToGrayZone}
          documentInfo={documentInfo}
          titleCardInfo={titleCardInfo}
          downloadGrayAreaReport={downloadGrayAreaReport}
        />
      </TabContent>

      <TabContent isVisible={selectedTab === 1}>
        <ProductionLines productionLines={documentInfo.productionLines} />
      </TabContent>

      <TabContent isVisible={selectedTab === 2}>
        <Gtins gtins={documentInfo.products} />
      </TabContent>
    </React.Fragment>
  );

  return (
    <div className={styles.wrapper}>
      <Preloader isOpened={!loaded} isClosed={loaded}>
        <Breadcrumbs
          withCustomLinks
          onClick={val => history.push(val.href)}
          breadcrumbs={breadcrumbs}
          className={styles.breadcrumbs}
        />

        <div className={styles.topRow}>
          {documentInfo && (
            <ActionTopRow
              documentStatus={documentInfo.status}
              reportStatus={documentInfo.report.status}
              onSignClick={openModal}
              onReformClick={memoizedReformDocument}
            />
          )}
        </div>
        <Tabs
          value={selectedTab}
          items={tabs}
          variant="fullWidth"
          onChange={(event, id) => setTab(id)}
        />

        <Content>
          <div className={styles.contentWrapper}>
            {documentInfo && renderTabsContent()}
          </div>
        </Content>
      </Preloader>
    </div>
  );
};

const mapStateToProps = state => ({
  documentInfo: grayAreaDocumentSelectors.documentResult(state),
  loaded: grayAreaDocumentSelectors.loaded(state),
});

const mapDispatchToProps = {
  clear: grayAreaDocumentActions.unMount,
  getDocument: grayAreaDocumentActions.getDocument,
  reformDocument: grayAreaDocumentActions.reformDocument,
  downloadGrayAreaReport: grayAreaActions.downloadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrayAreaDocument);

const ActionTopRow = memo(
  ({ documentStatus, reportStatus, onSignClick, onReformClick }) => {
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          {signActionAvailable(documentStatus, reportStatus) && (
            <Button
              onClick={onSignClick}
              style={{ marginRight: '10px' }}
              icon={<Send />}
              size="md"
              variant="contained"
            >
              {Translate('Подписать и отправить')}
            </Button>
          )}
          {reformActionAvailable(documentStatus, reportStatus) && (
            <Button
              onClick={onReformClick}
              icon={<Refresh />}
              size="md"
              variant="contained"
            >
              {Translate('Запросить повторно')}
            </Button>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <DocumentStatusInfo status={documentStatus} />
        </div>
      </React.Fragment>
    );
  },
);
