import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompact';
import { isNull, get, uniqBy } from 'lodash';
import { Title } from '../../../styled';
import { Wrap } from '../styled';
import { Checkbox, MultiSelect } from '../../../Fields';
import { CheckboxGroup } from '../../styled';
import { PARTICIPANT_TYPES } from '../../../../../constants';
import { withRenderNothingNot, withRenderOkvedOrNothing } from '../../../utils';
import { ProfileService } from '../../../../../services/ProfileService';
import { selectEdosOfdsTheme } from '../../../../../theme/ru-tabaco/select';
import { RenderOption, RenderValues } from '../../../Fields/MultiSelect/index';

const displayNone = { display: 'none' };
const empty = [];

class EdoOperators extends React.Component {
  static propTypes = {
    values: PropTypes.shape({}).isRequired,
    onToggle: PropTypes.func.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    onAddUnique: PropTypes.func.isRequired,
  };

  state = {
    allItems: [],
    selectValues: [],
  };

  componentDidMount() {
    const { onUpdateField } = this.props;

    ProfileService.getEdoOperators().then(answer => {
      const data = get(answer, 'data');
      if (data) {
        const preparedData = data
          .map(item => ({
            id: item.id,
            title: item.name,
            name: item.name,
            type: item.id,
          }))
          .sort((v1, v2) => +v1.order - +v2.order);

        this.setState({
          allItems: preparedData.slice(0, 6),
          selectValues: preparedData.slice(6),
        });
      }
    });
    onUpdateField('edo', []);
  }

  componentWillUnmount() {
    const { onUpdateField } = this.props;
    onUpdateField('edo', undefined);
  }

  onToggle = value => {
    const { onToggle } = this.props;
    onToggle('edo', value);
  };

  onSelectNew = value => {
    const { onUpdateField, values: { edo } } = this.props;
    const { selectValues, allItems } = this.state;
    const values = value.map(id => selectValues.find(v => v.id === id));
    const selected = allItems.filter(v => edo.find(edo => edo.id === v.id));
    onUpdateField('edo', [...selected, ...values]);
  };

  onRemove = id => {
    const { onToggle } = this.props;
    const { selectValues } = this.state;
    const value = selectValues.find(v => v.id === id);
    if (value) onToggle('edo', value);
  };

  render() {
    const { values } = this.props;
    const { allItems, selectValues } = this.state;
    const items = values.edo || empty;

    return (
      <Wrap>
        <Title>С какими операторами ЭДО вы работаете?</Title>
        <CheckboxGroup>
          {allItems.map((item, index) => (
            <Checkbox
              active={items.indexOf(item) !== -1}
              key={index}
              item={item}
              id={item.id}
              title={item.title}
              width="50%"
              onToggle={this.onToggle}
            />
          ))}
        </CheckboxGroup>
        <MultiSelect
          placeholder="Укажите своих операторов ЭДО"
          values={selectValues}
          onSelect={this.onSelectNew}
          maxHeight="100px"
          onRemove={this.onRemove}
          withoutIcon
          savePlaceholder
          RenderValues={RenderValues}
          RenderOption={RenderOption}
          theme={{ Select: selectEdosOfdsTheme }}
        />
      </Wrap>
    );
  }
}

export default compose(
  withRenderNothingNot([
    PARTICIPANT_TYPES.PRODUCER,
    PARTICIPANT_TYPES.WHOLESALER,
    PARTICIPANT_TYPES.IMPORTER,
    PARTICIPANT_TYPES.RETAIL,
  ]),
  withRenderOkvedOrNothing([PARTICIPANT_TYPES.PRODUCER]),
  setDisplayName('EdoOperators')
)(EdoOperators);
