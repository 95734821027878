import { actionTypes } from '../../constants';

export function selectedReport(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.REPORT_DETAILS.REPORT_SELECTED:
      return action.payload;
    default:
      return state;
  }
}
