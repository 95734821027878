import { actionTypes } from '../../constants/';
import * as configs from '../../projects';

export function config(state = configs.motp, action = {}) {
  switch (action.type) {
    case actionTypes.APP.CONFIG_UPDATE:
      return action.value.config;
    default:
      return state;
  }
}
