import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styled from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { Link } from 'react-router-dom';
import { deleteLeadingZeros } from 'src/utils';

import Tooltip from 'src/common_components/_MaterialUi/Tooltip/Tooltip';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

class  OrderId extends PureComponent {
  static displayName = 'OrderId';

  static propTypes = {
    children: PropTypes.string,
    showIcon: PropTypes.bool,
    isReceipt: PropTypes.bool,
  };

  static defaultProps = {
    children: 'ERROR',
    showMail: true,
    isReceipt: false,
  };

  state = {
    hovered: false,
    clicked: false,
  };

  ref = React.createRef();

  onMouseEnter = () => {
    //this.ref.current.style.position = 'absolute';
    this.setState({ hovered: true });
  };

  onMouseLeave = () => {
    this.ref.current.style.position = '';
    this.setState({ hovered: false, clicked: false });
  };

  onClick = e => {
    const { children, history, href } = this.props;
    e.stopPropagation();
    this.setState({ clicked: true });
    if (href) {
      history.push(href);
    } else {
      history.push(`/documents/list/${encodeURIComponent(children)}`);
    }
  };

  getReceiptId(did, defaultId) {
    switch (window.env.ELK_COUNTRY) {
      case 'UZ':
        return deleteLeadingZeros(did.slice(-16));
      case 'KZ':
        return did.slice(0, did.length - 12);
      default:
        return defaultId;
    }
  }

  render() {
    const { children, iconType, showIcon, isReceipt } = this.props;
    const href = get(this.props, 'href', `/documents/list/${encodeURIComponent(children)}`);
    const docId = children.length > 5 ? children.slice(-6) : children;

    return (
      <Tooltip
        text={children}
        textComponent={
          <Wrapper>
            {showIcon && (
              <Icon
                type={iconType}
                spacingRight={12}
                fill="rgb(82, 83, 90)"
                size={16}
              />
            )}
            <StyledLink to={href}>{isReceipt ? this.getReceiptId(children, docId) : docId}</StyledLink>
          </Wrapper>
        }
        sliceTitleStart={-6}
        placement="bottom-start"
        textColor="#55b5e7"
        interactive
        showCopy
        showMail
        noWrap
      />
    );
  }
}


export { OrderId };

// TODO: если одобрят вариант с кнопками в одной ячейке с GUID, оформить компонентом
