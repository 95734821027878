import React, { useEffect } from 'react';
import {TimePickerInput} from '@crpt-ui/core'
import PropTypes from 'prop-types';
import FieldHoc from '../FieldHoc';

const InputTime = ({ input, meta, ...propsRest }) => {
  const { placeholder, errorPlaceholder, isError, ...rest } = propsRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  return (
    <TimePickerInput
      initialTime={input.value}
      onChange={input.onChange}
      label={placeholder}
      error={meta.error}
      {...rest}
    />
  );
};

InputTime.propTypes = {
  isError: PropTypes.func,
  required: PropTypes.bool,
};

InputTime.defaultProps = {
  isError: meta => meta.invalid,
  required: false,
};

export default FieldHoc(InputTime);

