import React, { Fragment, useEffect, useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import LightTheme from '../../../../common_components/_MaterialUi/LightTheme';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import {
  // actionSelectOptions,
  cisKey,
  // commodityGroup,
  goodsKey, steps,
} from '../ExportNotificationToTheEaes/ExportNotificationToTheEaes.constants';
import GoodsEditProduct from '../ExportNotificationToTheEaes/GoodsEditProduct';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import { unmounted } from '../../../../components/DocumentDetails/ducks/DocumentDetails.actions';
import * as selectors from './ducks/AcceptanceNotificationOfGoodsByTheEAES.selectors';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as actions from './ducks/AcceptanceNotificationOfGoodsByTheEAES.actions';
import AdditionalBottomBar from './AdditionalBottomBar';
import { Toast } from '../../../../utils/Toast';


const AcceptanceNotificationOfGoodsByTheEAES = (props) => {
  const { countryCode, loaded, match, isLoading, shippingDocuments, inn } = props;
  const { data, onSaveDraft, onShippingDocuments, onLoadDraft, onUnmount } = props;
  const { onSubmit } = props;
  const { id } = match.params;
  const [step, setStep] = useState(steps.info);

  useEffect(()=> {
    if (/draft-/.test(id)) {
      onLoadDraft(id.replace(/draft-/, ''));
    }
  },[onLoadDraft, id])

  useEffect(() => onUnmount,[onUnmount])

  useEffect(() => {
    inn && onShippingDocuments({
      inn
    });
  },[onShippingDocuments, inn])

  const handleOnSubmit = (values) => {
    const canSend = values.products.reduce((acc,item) => item.accepted !== "unset" && acc, true);
    if (canSend) onSubmit(values)
    else Toast.showError({ content: "Установите результат приемки товаров" });
  }

  const errorsCount = ({ formErrors }) => {
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    return Object.keys(prepared).length;
  };

  return (
    <Fragment>
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
      >
        <DocumentWrapper
          needViewActionsOfGoods={false}
          additionalBottomBar={AdditionalBottomBar}
          loaded={loaded}
          step={step}
          match={match}
          productGroupIdForGoods={2}
          isCreate={id === 'create'}
          actionSelectOptions={[]}
          goodsEditComponent={GoodsEditProduct}
          breadcrumbs={[
            {
              href: '/documents/list',
              title: Translate('Документы'),
              id: 1,
            },
            {
              title: Translate('Уведомление о приемке товаров ЕАЭС'),
              id: 2,
              ...id !== 'create' ? { href: `/documents/list/${id}` } : {}
            },
          ]}
          countryCode={countryCode}
          isLoading={isLoading}
          onSaveDraft={onSaveDraft}
          data={id === 'create' ? {
            shippingDocuments,
            request_type: "ACCEPTANCE"
          } : /draft-/.test(id)
            ? {
              ...data,
              shippingDocuments,
              request_type: "ACCEPTANCE"
            }
            : ({
              shippingDocuments,
              request_type: "ACCEPTANCE"
            })}
          onSetStep={setStep}
          docType={"LP_ACCEPT_GOODS"}
          codesCanBeChanged={true}
          fieldsInfo={InfoFields}
          errorsCountFunc={errorsCount}
          needAWarningAboutTheGoodsRecount={false}
          needCancelButton={true}
          needDraftButton={true}
          onSubmit={handleOnSubmit}
          title='Уведомление о приемке товаров ЕАЭС'
          filterPackageTypeForGoods="UNIT"
        />
      </MuiThemeProvider>
    </Fragment>
  )
}

const mapState = state => ({
  data: {
    ...selectors.data(state),
  },
  loaded: selectors.loaded(state),
  shippingDocuments: selectors.shippingDocuments(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
  user: authSelectors.userInfo(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onShippingDocuments: id => dispatch(actions.fetchShippingDocuments(id)),
  onUnmount: () => dispatch(unmounted()),
  // onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  // setDocType: data => dispatch(actions.setDocType(data)),
});

export default connect(mapState, mapDispatch)(AcceptanceNotificationOfGoodsByTheEAES);
