export default {
  title: 'Фильтры по умолчанию:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 100,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 280,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'Начало эксперимента'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'Текущие сутки'
        },
        {
          'col1': 'Тип агрегации',
          'col2': 'По товару'
        },
      ]
    }
  ]
}