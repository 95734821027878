import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import Icons from '../../common_components/Icons/Icons';
import {
  Wrap,
  Info,
  Text,
  Card,
  Link,
  Cards,
  Blocks,
  Heading,
  Divider,
  InfoCard,
  InfoLink,
  InfoGreen,
  ArticleCard,
  MainContent,
  CardContent,
  ContentHeader,
  InfoCardContent,
} from './styled';

 import HowWorksEN from './files/HowWorksEN.pdf';
 import HowWorksRU from './files/HowWorksRU.pdf';
 import HowWorksUZ from './files/HowWorksUZ.pdf';
 import LegalInfoRU from './files/LegalInfoRU.pdf';
 import LegalInfoEN from './files/LegalInfoEN.pdf';
 import LegalInfoUZ from './files/LegalInfoUZ.pdf';

const iconStyle = {
  width: '72px',
  height: '72px',
};

const articleIconStyle = {
  width: '40px',
  height: '40px',
  'margin-right': '10px',
};
const InfoIconStyle = {
  width: '24px',
  height: '24px',
};



export const HomePage = () => {
  const { t, i18n } = useTranslation();
  const cardItems = [{
    icon: Icons.Box,
    text: t('Опубликовать сведения о маркируемых товарах'),
  },
  {
    icon: Icons.DocumentText,
    text: t('Сформировать отчеты об обороте кодов маркировки'),
  },
  {
    icon: Icons.ConvertCube,
    text: t('Зарегистрировать уведомления об обороте маркированных товаров'),
  },
  {
    icon: Icons.Charts,
    text: t('Получить доступ к информации, хранящейся в системе маркировки'),
  }
];

const articleList = [
  {
    icon: Icons.DocumentText,
    label: t('Как работает система цифровой маркировки товаров'),
    link: 'HowWorks'
  },
  {
    icon: Icons.DocumentText,
    label: t('Правовые основы и ответственность за нарушение правил цифровой маркировки'),
    link: 'LegalInfo',
  }
];

const InfoList = [
  {
    icon: Icons.Global,
    label: t('Сайт оператора'),
    linkLabel: 'crpt-turon.uz',
    link: 'https://crpt-turon.uz',
  },
  {
    icon: Icons.Global,
    label: t('Справочный центр «ASL BELGISI»'),
    linkLabel: 'help.crpt-turon.uz',
    link: 'https://help.crpt-turon.uz',
  },
  {
    icon: Icons.Tg,
    label: t('Новостной канал в Telegram'),
    linkLabel: '@aslbelgisi',
    link: 'https://t.me/aslbelgisi',
  },
];


const getLinkValue = (link) => {
  if (link === 'HowWorks') {
    return i18n.language === 'RU' ? HowWorksRU : i18n.language === 'EN' ? HowWorksEN : HowWorksUZ
  }
  
  if (link === 'LegalInfo') {
    return i18n.language === 'RU' ? LegalInfoRU : i18n.language === 'EN' ? LegalInfoEN : LegalInfoUZ
  }
}

  return (
      <Wrap>
        <div>
          <Heading>{t('Добро пожаловать в личный кабинет')}</Heading>
          <Info>{t('Национальной информационной системы мониторинга маркировки и отслеживания продукции «ASL BELGISI» (НИС «ASL BELGISI»)')}</Info>
        </div>
        <MainContent>
          <Blocks>
            <ContentHeader>{t('Личный кабинет НИС «ASL BELGISI» позволяет')}:</ContentHeader>
            <Cards>
              {cardItems.map(({icon, text}) => (
                <Card>
                <Icon component={icon} style={iconStyle}/>
                <CardContent>
                  <Text>{text}</Text>
                </CardContent>
              </Card>
              ))}
            </Cards>
          </Blocks>
          <Divider/>
          <Blocks>
            <Cards>
              {articleList.map(({icon,label,link}) => (
                <ArticleCard>
                  <Icon component={icon} style={articleIconStyle}/>
                  <Link href={getLinkValue(link)} type="application/pdf" target="_blank">{label}</Link>
                </ArticleCard>
              ))}
            </Cards>
          </Blocks>
          <Divider/>
          <Blocks>
            <Cards>
              {InfoList.map(({icon,label, linkLabel, link}) => (
                <InfoCard>
                  <Icon component={icon} style={InfoIconStyle}/>
                  <InfoCardContent>
                    <InfoGreen>{label}</InfoGreen>
                    <InfoLink href={link} target="_blank" rel="noreferrer">{linkLabel}</InfoLink>
                  </InfoCardContent>
                </InfoCard>
              ))}
            </Cards>
          </Blocks>
        </MainContent>
      </Wrap>
    );
  }

