import { handleActions } from 'redux-actions';
import * as grayAreaSearchGtinsActions from './GrayAreaSearchGtins.actions';

const result = handleActions(
  {
    [grayAreaSearchGtinsActions.loaded]: (state, { payload }) => payload.result || [],
    [grayAreaSearchGtinsActions.clear]: () => [],
  },
  []
);


const total = handleActions(
  {
    [grayAreaSearchGtinsActions.loaded]: (state, { payload = {} }) => payload.total !== undefined ? payload.total : 0,
    [grayAreaSearchGtinsActions.clear]: () => 0,
  },
  0,
);

const loaded = handleActions(
  {
    [grayAreaSearchGtinsActions.loaded]: () => true,
    [grayAreaSearchGtinsActions.clear]: () => false,
  },
  false,
);

export default {
  result,
  loaded,
  total,
}
