import React, {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uniqBy, isEmpty, uniq } from 'lodash';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Plus } from '@crpt-ui/icons';
import { Select as SelectRow } from '@crpt-ui/core';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import MenuButton from 'src/common_components/Buttons/MenuButton';
import ScanDialog from '../../../../common_components/ScanDialog/ScanDialog';
import { Translate } from 'src/common_components/Translate/Translate';
import GoodsCommandBar from './CommandBar';
import { Toast } from 'src/utils/Toast';
import { styleCommandBar, FixedWrap, GoodsWrap, GoodsIconWrap, EmptyGoodsTextWrap } from '../Document/Document.styled';
import HandAddGoodModalContent from '../Document/HandAddGoodModalContent/HandAddGoodModalContent';
import { ContentWrapper, ContentErrorLoading, invalidCisKey } from './DocumentWrapperConstants';
// заменить на импорт в компоненте, в котором будет использваться wrapper
import { contentBodyEditGoods, contentBodyDeleteGoods } from '../ActDocument/ActDocument.constants';
import GoodCodesUploadModalContent from '../EaesImportDocument/Goods/GoodCodesUploadModalContent';
import CisListUploadModalContent from '../EaesImportDocument/Goods/CisListUploadModalContent/CisListUploadModalContent';
import GoodsIcon from '../EaesImportDocument/Goods/svg/GoodsIcon.svg';
import * as actions from '../EaesImportDocument/Goods/ducks/Goods.actions';
import * as selectors from '../EaesImportDocument/Goods/ducks/Goods.selectors';
import { cisKey, goodsKey, contentBodyConfirm, importThirdKG } from '../EaesImportDocument/EaesImportDocument.constants';
import {
  CodesRenderer,
  columnsKZ,
  columnsKG,
  columnsThirdCountryKG,
  columnsKZExportInEaes, columnsUZ,
} from '../EaesImportDocument/Goods/Goods.constants';
import {
  columnsAcceptanceOfEaesGoods,
} from '../AcceptanceNotificationOfGoodsByTheEAES/utls';
import FinalConfirmModal from './FinalConfirmModal';
import {Box} from "@material-ui/core";
import AcceptUploadModal from "./AcceptUploadModal";
import AdditionalBottomBarLPAccept from "../AcceptanceNotificationOfGoodsByTheEAES/AdditionalBottomBarLPAccept";
import {documentTypes} from "../../../../constants";

const keyField = 'cis';

const emptyFunc = () => {};


const Goods = (props) => {
  const { onOpenModal, onSetContent, onCloseModal, formMutators, formValues, handleGoodsChange, fieldsInfo } = props;
  const { onSaveDraft, onSetStep, show, formErrors, typeDoc, productGroupIdForGoods, history, needDraftButton } = props;
  const { countryCode, goodsChanged, disabledMenu, needCancelButton, codesCanBeChanged, checkSignInGoodsDisabled } = props;
  const { thereAreTotals, needAWarningAboutTheGoodsRecount, onSubmit, actionSelectOptions, finalConfirmModal } = props;
  const { GoodsEditComponent, filterPackageTypeForGoods, needViewActionsOfGoods, AdditionalBottomBar, title } = props;
  const { pageSizeInGoods, WrapperForTableInFinalConfirmModal, emptyItemText } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenScanDialog, setIsOpenScanDialog] = useState(false);
  const [isOpenEditGoods, setIsOpenEditGoods] = useState(null);
  const [page, setPage] = useState(0);
  let goodsList = useRef(null);

  useEffect(() => {
    if (goodsList.current) {
      goodsList.current.state.selectedRows = [...formValues.products.filter(el => el.accepted).map(el => el.cis)];
      setSelectedRows(goodsList.current.state.selectedRows);
    }
  }, [formValues])

  const onHandAddGood = () => {
    onSetContent(
      <HandAddGoodModalContent
        filterPackageTypeForGoods={filterPackageTypeForGoods}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        formValues={formValues}
        target={typeDoc}
        productGroupIdForGoods={productGroupIdForGoods}
      />,
    );
    onOpenModal();
  };

  const onAddGood = () => {
    onSetContent(
      <GoodCodesUploadModalContent
        formValues={formValues}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        onConvert={emptyFunc}
        typeDoc={typeDoc}
        types=".xls, .xlsx"
        onAccept={emptyFunc}
      />,
    );
    onOpenModal();
  };

  const handleSaveDraft = () => {
    const newFormValues = {...formValues};
    if (newFormValues.shippingDocuments) delete newFormValues.shippingDocuments;
    if (newFormValues.uploadProducts || newFormValues.uploadProducts === null) delete newFormValues.uploadProducts;
    onSaveDraft(newFormValues);
  };

  const onNextStep = () => {
    onSetStep('total');
  };

  const handleNextStepDisabled = () => {
    if (!show) return true;
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    const products = getProducts();
    const isFilledProducts = products => {
      for (let i = 0; i < products.length; i++) {
        if (!products[i].numberDT || !products[i].country ||
          !products[i].cert_number || !products[i].cert_date)
          return true;WrapperForTable
      }
      return false;
    };
    let result = !!Object.keys(prepared).length || isEmpty(products);
    if (result) return true;
    else if (typeDoc != importThirdKG) return false;
    else if (typeDoc === importThirdKG) return isFilledProducts(products);
  };

  const getProducts = () => {
    const products = formValues[goodsKey] || [];
    return products.map((item, idx) => ({ serialNumber: idx + 1, ...item }));
  };

  const onRowSelect = useCallback(selected => {
    const selectedKeys = Object.keys(selected);
    setSelectedRows(selectedKeys);
  },[setSelectedRows]);

  const onOpenCisModal = useCallback((e, row) => {
    e.preventDefault();
    e.stopPropagation();
    onSetContent(
      <CisListUploadModalContent
        row={row}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        title={Translate('Коды маркировки')}
      />,
    );
    onOpenModal();
  },[onSetContent, onOpenModal, onCloseModal, formMutators]);

  const onCancelGoodsEdit = useCallback((value, index) => {
    const { update } = formMutators;
    update(goodsKey, index, value);
  },[formMutators, goodsKey]);

  const onOpenProductModal = useCallback(selectedRows => {
    const { update } = formMutators;
    const columns =
      countryCode === 'KZ'
        ? columnsKZ
        : typeDoc === importThirdKG
        ? columnsThirdCountryKG
        : columnsKG;
    const __goodsOriginal = formValues[goodsKey]
      ? [...formValues[goodsKey]]
      : [];
    const onReset = () => {
      selectedRows.forEach(index =>
        update(goodsKey, index, __goodsOriginal[index] || ''),
      );
    };
    if (GoodsEditComponent) {
      onSetContent(
        <GoodsEditComponent
          onCancel={onCancelGoodsEdit}
          onCloseModal={() => {
            onCloseModal();
          }}
          formMutators={formMutators}
          onReset={onReset}
          preparedColumns={prepareColumns(columns)}
          selectedRows={selectedRows}
          title={Translate('Редактирование товаров')}
          typeDoc={typeDoc}
        />,
      );
      onOpenModal();
    } else {
      console.error('GoodsEditComponent is not defend')
    }
  },[
    formMutators, onOpenModal, typeDoc, countryCode,
    formValues, onCloseModal, onCancelGoodsEdit,
    GoodsEditComponent, onSetContent
  ]);

  const prepareColumns = columns => {
    return columns.map(column =>
      column.id === 'codes'
        ? {
          ...column,
          accessor: row => {
            return (
              <CodesRenderer
                row={row}
                onClick={e => onOpenCisModal(e, row)}
              />
            );
          },
        }
        : column,
    );
  };

  const toggleDialog = () => setIsModalOpen(!isModalOpen);

  const toggleScanDialog = () => setIsOpenScanDialog(!isOpenScanDialog);

  const onUpdate = useCallback(() => {
     if (typeDoc === 'LP_ACCEPT_GOODS') {
       formValues.products && formMutators.updateField('products', formValues.products.map(el => {
         return (goodsList.current.state.selectedRows.includes(el.cis)) ?
           {...el, accepted: true} : {...el, accepted: false}
       }))
     }
    setSelectedRows(goodsList.current.state.selectedRows);
  }, [typeDoc, setSelectedRows, formValues, formMutators, goodsList]);

  const onCancel =  useCallback(() => {
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  },[history, toast, Toast]);

  const addScannedCises = scanned => {
      const oldProducts = getProducts();
      const result = [...oldProducts, ...scanned].map((item, idx) => ({
        idx: idx + 1,
        ...item,
      }));
      formMutators.updateField(goodsKey, uniqBy(result, 'cis'));
  };

  const addScannedLPAcceptGoodsCises = scanned => {
    const entity = {
      accept: [],
      reject: [],
      rejectFromFile: 0,
    }
    formMutators.updateField('uploadProducts', null)
    formValues.products.forEach(el => {
      scanned.includes(el.cis) ? entity.accept.push(el) : entity.reject.push(el)
    })
    entity.rejectFromFile = scanned.length - entity.accept.length;
    formMutators.updateField('uploadProducts', entity)
  };

  const cancelEditGoods = () => {
    handleGoodsChange(false);
    setIsOpenEditGoods(null);
  };

  const submitReCountGoods = () => {
    switch (isOpenEditGoods) {
      case 'onAddGood':
        onAddGood();
        break;
      case 'onHandAddGood':
        onHandAddGood();
        break;
      case 'toggleScanDialog':
        toggleScanDialog();
        break;
      case 'actionWithTheProduct':
        actionWithTheProduct();
        break;
      default:
        console.error('неверный вариант');
        break;
    }
    setIsOpenEditGoods(null);
  };

  const reCountGoods = (mode) => {
    switch (mode) {
      case 'onAddGood':
        setIsOpenEditGoods('onAddGood');
        handleGoodsChange(true);
        break;
      case 'onHandAddGood':
        setIsOpenEditGoods('onHandAddGood');
        handleGoodsChange(true);
        break;
      case 'toggleScanDialog':
        setIsOpenEditGoods('toggleScanDialog');
        handleGoodsChange(true);
        break;
      case 'actionWithTheProduct':
        setIsOpenEditGoods('actionWithTheProduct');
        handleGoodsChange(true);
        break;
      default:
        console.error('неверный вариант');
        break;
    }
  };

  const actionWithTheProduct = () => {
    let products = formValues[goodsKey] || [];
    products = products
      .filter((item) => !selectedRows.includes(item.cis))
      .map((item, idx) => ({ ...item, serialNumber: idx + 1 }));
    goodsList.current.clearSelected();
    if (goodsList.current.state.isAllSelected) goodsList.current.toggleAll();
    formMutators.updateField(goodsKey, products);
  };

  const products = getProducts();

  const handleOnSubmit = () => {
    onSubmit({
      ...formValues,
      docType: typeDoc,
    });
  }

  const onOpenFinalConfirmModal = () => {
    onSetContent(
      <FinalConfirmModal
        WrapperForTable={WrapperForTableInFinalConfirmModal}
        formValues={formValues}
        title={title}
        typeDoc={typeDoc}
        onCloseModal={onCloseModal}
        cisData={products}
        cisColumns={preparedColumns}
        onSubmit={handleOnSubmit}
        FieldsInfo={fieldsInfo}
      />
    )
    onOpenModal();
  }

  let columns;

  switch (countryCode) {
    case 'KZ':
      switch (typeDoc) {
        case 'KZ_EXPORT_NOTICE':
          columns = columnsKZExportInEaes;
          break;
        case 'LP_ACCEPT_GOODS':
          columns = [
            ...columnsAcceptanceOfEaesGoods,
            // {
            //   id: 'accepted',
            //   accessor: 'accepted',
            //   Cell: ({ row }) => {
            //     return (
            //       <Select
            //         className="tableSelect"
            //         name={`products[${row._original.idx -1 }].accepted`}
            //         options={goodsAcceptanceResultOptions}
            //       />
            //     )
            //   },
            //   Header: Translate('Результат приемки товара'),
            //   width: 'auto',
            // },
          ];
          break;
        default:
          columns = columnsKZ;
          break;
      }
      break;
    case 'UZ':
      columns = columnsUZ;
      break;
    default:
      switch (typeDoc) {
        case importThirdKG:
          columns = columnsThirdCountryKG;
          break;
        default:
          columns = columnsKG;
          break;
      }
      break
  }
  const preparedColumns = prepareColumns(columns);
  let isInvalidCisShow = !isEmpty(formValues.invalidCis);
  const invalidList = formValues.invalidCis
    ? formValues.invalidCis.map(item => <div>Код: {item}</div>)
    : '';
  let contentErrorLoading = (
    <ContentErrorLoading>
      {invalidList}
      {Translate('Загрузить другой файл?')}
    </ContentErrorLoading>
  );

  let isOpenUploadModal = formValues.uploadProducts;

  const getUploadBtns = () => {
    return typeDoc === 'LP_ACCEPT_GOODS' ? (
      <Box style={{display: 'flex', margin: '-15px 0 20px 40px'}}>
        <PrimaryActionLargeButton
          onClick={() => setIsOpenScanDialog(true)}
        >
          {Translate('Сканировать коды')}
        </PrimaryActionLargeButton>

        <PrimaryActionLargeButton
          onClick={() => onAddGood()}
          style={{marginLeft: '40px'}}
        >
          {Translate('Загрузить коды')}
        </PrimaryActionLargeButton>
      </Box>
    ) : null
  }

  const getBtnText = () => {
    if (finalConfirmModal || typeDoc === documentTypes.MTTPS_90) return Translate('Отправить');
    if (typeDoc !== 'LP_ACCEPT_GOODS') return Translate('Подписать и отправить');
    if (formValues.products && selectedRows.length && formValues.products.length > selectedRows.length) return Translate('Подписать и отправить');
    if (formValues.products && selectedRows.length && selectedRows.length === formValues.products.length) return Translate('Принять выбранные');
    return Translate('Подписать и отклонить все');
  }

  const getPages = (n) => {
    const pages = Number(Math.ceil(n/10));
      const arr = [];
      for (let i = 0; i < pages; i += 1) {
        arr.push({page: i});
      }
      return arr;
  }

  const onAccept = () => {
    const newSelected = Array.from(
      new Set([...formValues.uploadProducts.accept.map(el => el.cis),
        ...formValues.products.reduce((filtered, el) => {
          if (el.accepted) filtered.push(el.cis);
          return filtered;
        }, [])]));
    if (formValues.uploadProducts.accept.length && formValues.uploadProducts.accept.length === formValues.products.length) {
      // Если совпали все строки в документе с кодами из файла
      goodsList.current.state.selectedRows = newSelected;
      goodsList.current.state.isAllSelected = true;
      goodsList.current.state.selectedAllPages = getPages(formValues.uploadProducts.accept.length);
    } else {
      goodsList.current.state.selectedRows = newSelected;
    }
    onUpdate();
  }

  const onAcceptAll = () => {
    goodsList.current.state.selectedRows = [...formValues.products.map(el => el.cis)];
    goodsList.current.state.isAllSelected = true;
    goodsList.current.state.selectedAllPages = getPages(formValues.products.length);
    onUpdate();
  }

  const onRejectAll = () => {
    goodsList.current.state.selectedRows = [];
    goodsList.current.state.isAllSelected = false;
    goodsList.current.state.selectedAllPages = [];
    onUpdate();
  }

  const signInGoodsDisabled = useMemo(()=> checkSignInGoodsDisabled ? checkSignInGoodsDisabled(formValues) : false,[formValues, checkSignInGoodsDisabled])
  const link = document.querySelector('[role="columnheader"]');
  if (typeDoc === 'LP_ACCEPT_GOODS' && link)  link.firstChild.style.display = 'none';

  return (
    <ContentWrapper hide={!show}>
      <ScanDialog
        open={isOpenScanDialog}
        onClose={toggleScanDialog}
        onAdd={addScannedCises}
        docType={typeDoc}
        addScannedLPAcceptGoodsCises={addScannedLPAcceptGoodsCises}
      />
      <AcceptUploadModal
        docType={typeDoc}
        formValues={formValues}
        formMutators={formMutators}
        open={isOpenUploadModal}
        onClose={() => formMutators.updateField('uploadProducts', null)}
        onAccept={onAccept}
      />
      <ConfirmModal
        isModalOpen={isOpenEditGoods}
        onClose={cancelEditGoods}
        onSubmit={submitReCountGoods}
        content={isOpenEditGoods === 'actionWithTheProduct' ? contentBodyDeleteGoods : contentBodyEditGoods}
        header={Translate('Предупреждение')}
        cancelButtonText="Нет"
        submitButtonText="Да"
        notCancelable
      />
      <ConfirmModal
        isModalOpen={isInvalidCisShow}
        onClose={() => formMutators.updateField(invalidCisKey, [])}
        onSubmit={() => {
          onAddGood();
          formMutators.updateField(invalidCisKey, []);
        }}
        content={contentErrorLoading}
        header={Translate('Ошибка')}
        cancelButtonText={Translate('Отмена')}
        submitButtonText={Translate('Загрузить файл')}
        color={'error'}
      />
      <ConfirmModal
        isModalOpen={isModalOpen}
        onClose={toggleDialog}
        onSubmit={onCancel}
        content={contentBodyConfirm}
        header={Translate("Предупреждение")}
        cancelButtonText={Translate("Нет")}
        submitButtonText={Translate("Да")}
        notCancelable
      />
      <Box style={{display: 'flex'}}>
        { typeDoc !== 'LP_ACCEPT_GOODS' && AdditionalBottomBar ? (
          <AdditionalBottomBar
            formValues={formValues}
            formMutators={formMutators}
            selectedRows={selectedRows}
          />
        ) : null}
        { typeDoc === 'LP_ACCEPT_GOODS' ? (
          <AdditionalBottomBarLPAccept
            formValues={formValues}
            onAcceptAll={onAcceptAll}
            onRejectAll={onRejectAll}
          />
        ) : null}
        {getUploadBtns()}
      </Box>
      <GoodsCommandBar
        left={needViewActionsOfGoods  ? [
          <MenuButton
            menu={[
              {
                title: Translate('Загрузить из файла'),
                onClick: goodsChanged && needAWarningAboutTheGoodsRecount ? () => reCountGoods('onAddGood') : onAddGood,
              },
              {
                title: Translate('Выбрать из списка'),
                onClick: goodsChanged && needAWarningAboutTheGoodsRecount ? () => reCountGoods('onHandAddGood') : onHandAddGood,
              },
              {
                title: Translate('Сканировать коды'),
                onClick: goodsChanged && needAWarningAboutTheGoodsRecount ? () => reCountGoods('toggleScanDialog') : toggleScanDialog,
              },
            ]}
            disabled={disabledMenu}
            positionY="bottom"
            variant="contained"
            color="primary"
          >
            <Plus/>
            <span style={{ fontSize: '12px', fontWeight: '700', margin: '8px' }}>
                  {Translate('ДОБАВИТЬ ТОВАР')}
                </span>
          </MenuButton>,
          ...countryCode === 'KZ' ? [
            <FixedWrap width={'260px'} stretch>
              <SelectRow
                options={actionSelectOptions}
                label={Translate('Действие')}
                onChange={(e) => {
                  if (e.target.value === 'delete') {
                    if (!goodsChanged && needAWarningAboutTheGoodsRecount) reCountGoods('actionWithTheProduct');
                    else actionWithTheProduct();
                  }
                  if (e.target.value === 'edit') {
                    const products = formValues[goodsKey];

                    const selected = products
                      .map((item, ndx) => {
                        if (selectedRows.includes(item[keyField])) return ndx;
                        return null;
                      })
                      .filter(item => item != null);

                    onOpenProductModal(selected);
                    goodsList.current.clearSelected();
                    if (goodsList.current.state.isAllSelected) goodsList.current.toggleAll();
                  }
                }}
                disabled={selectedRows.length === 0}
              />
            </FixedWrap>,
          ] : [],
        ] : []
        }
        right={[]}
      />
      {isEmpty(products) ? (
        <GoodsWrap minHeight="calc(100vh - 490px)">
          <GoodsIconWrap>
            <GoodsIcon/>
          </GoodsIconWrap>
          <EmptyGoodsTextWrap>
            {Translate(emptyItemText || 'Нет добавленных товаров')}
          </EmptyGoodsTextWrap>
        </GoodsWrap>
      ) : (
        <SelectionList
          columns={preparedColumns}
          data={products}
          onRef={goodsList}
          onUpdate={onUpdate}
          selectType={codesCanBeChanged ? 'checkbox' : 'correction'}
          defaultPageSize={pageSizeInGoods}
          showPagination={products.length > pageSizeInGoods}
          onPageChange={pageIdx => setPage(pageIdx)}
          keyField={keyField}
          page={page}
          customSelectAll
        />
      )}
      <br/>
      <GoodsCommandBar
        left={[
          ...needCancelButton
            ?
            [<CommonActionButton
              onClick={toggleDialog}
              disabled={false}
            >
              {Translate('Отменить')}
            </CommonActionButton>] : [],
          ...needDraftButton
            ?
            [<CommonActionButton
              onClick={handleSaveDraft}
              disabled={false}
            >
              {Translate('Сохранить как черновик')}
            </CommonActionButton>] : [],
        ]}
        style={{
          ...styleCommandBar,
          // width: isEmpty(commandBarLeft) ? 'auto' : '100%',
          width: '100%',
        }}
        right={thereAreTotals ? [
          <PrimaryActionLargeButton
            disabled={products.length === 0}
            onClick={onNextStep}
          >
            {Translate('Следующий шаг')}
          </PrimaryActionLargeButton>,
        ] : [
          <PrimaryActionLargeButton
            disabled={products.length === 0 || signInGoodsDisabled}
            onClick={finalConfirmModal ? onOpenFinalConfirmModal : handleOnSubmit}
          >
            {getBtnText()}
          </PrimaryActionLargeButton>,
        ]}
      />
    </ContentWrapper>
  );
};

Goods.propTypes = {
    countryCode: PropTypes.string.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    typeDoc: PropTypes.string,
    isDraft: PropTypes.bool,
    isCorrect: PropTypes.bool,
    goodsChanged: PropTypes.bool,
    handleGoodsChange: PropTypes.func.isRequired,
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  savedProducts: selectors.savedProducts(state),
});

const mapDispatch = dispatch => ({
  onGetPackageCount: params => dispatch(actions.getPackageCount(params)),
  onFixProducts: cises => dispatch(actions.fixProducts(cises)),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
  withRouter,
)(Goods);
