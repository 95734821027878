export const oksm = {
  '100': {
    global_id: 273330196,
    signature_date: '21.09.2016 15:41:10',
    system_object_id: '100',
    ALFA3: 'BGR',
    SHORTNAME: 'БОЛГАРИЯ',
    FULLNAME: 'Республика Болгария',
    ALFA2: 'BG',
    CODE: '100',
  },
  '104': {
    global_id: 273330197,
    signature_date: '21.09.2016 15:41:11',
    system_object_id: '104',
    ALFA3: 'MMR',
    SHORTNAME: 'МЬЯНМА',
    FULLNAME: 'Республика Союза Мьянма',
    ALFA2: 'MM',
    CODE: '104',
  },
  '108': {
    global_id: 273330198,
    signature_date: '21.09.2016 15:41:11',
    system_object_id: '108',
    ALFA3: 'BDI',
    SHORTNAME: 'БУРУНДИ',
    FULLNAME: 'Республика Бурунди',
    ALFA2: 'BI',
    CODE: '108',
  },
  '112': {
    global_id: 273330199,
    signature_date: '21.09.2016 15:41:12',
    system_object_id: '112',
    ALFA3: 'BLR',
    SHORTNAME: 'БЕЛАРУСЬ',
    FULLNAME: 'Республика Беларусь',
    ALFA2: 'BY',
    CODE: '112',
  },
  '116': {
    global_id: 273330200,
    signature_date: '21.09.2016 15:41:12',
    system_object_id: '116',
    ALFA3: 'KHM',
    SHORTNAME: 'КАМБОДЖА',
    FULLNAME: 'Королевство Камбоджа',
    ALFA2: 'KH',
    CODE: '116',
  },
  '120': {
    global_id: 273330201,
    signature_date: '21.09.2016 15:42:25',
    system_object_id: '120',
    ALFA3: 'CMR',
    SHORTNAME: 'КАМЕРУН',
    FULLNAME: 'Республика Камерун',
    ALFA2: 'CM',
    CODE: '120',
  },
  '124': {
    global_id: 273330202,
    signature_date: '21.09.2016 15:42:27',
    system_object_id: '124',
    ALFA3: 'CAN',
    SHORTNAME: 'КАНАДА',
    ALFA2: 'CA',
    CODE: '124',
  },
  '132': {
    global_id: 273330203,
    signature_date: '21.09.2016 15:42:28',
    system_object_id: '132',
    ALFA3: 'CPV',
    SHORTNAME: 'КАБО-ВЕРДЕ',
    FULLNAME: 'Республика Кабо-Верде',
    ALFA2: 'CV',
    CODE: '132',
  },
  '136': {
    global_id: 273330204,
    signature_date: '21.09.2016 15:42:30',
    system_object_id: '136',
    ALFA3: 'CYM',
    SHORTNAME: 'ОСТРОВА КАЙМАН',
    ALFA2: 'KY',
    CODE: '136',
  },
  '140': {
    global_id: 273330205,
    signature_date: '21.09.2016 15:42:30',
    system_object_id: '140',
    ALFA3: 'CAF',
    SHORTNAME: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    ALFA2: 'CF',
    CODE: '140',
  },
  '144': {
    global_id: 273330206,
    signature_date: '21.09.2016 15:42:59',
    system_object_id: '144',
    ALFA3: 'LKA',
    SHORTNAME: 'ШРИ-ЛАНКА',
    FULLNAME: 'Демократическая Социалистическая Республика Шри-Ланка',
    ALFA2: 'LK',
    CODE: '144',
  },
  '148': {
    global_id: 273329415,
    signature_date: '21.09.2016 15:26:00',
    system_object_id: '148',
    ALFA3: 'TCD',
    SHORTNAME: 'ЧАД',
    FULLNAME: 'Республика Чад',
    ALFA2: 'TD',
    CODE: '148',
  },
  '152': {
    global_id: 273330207,
    signature_date: '21.09.2016 15:43:03',
    system_object_id: '152',
    ALFA3: 'CHL',
    SHORTNAME: 'ЧИЛИ',
    FULLNAME: 'Республика Чили',
    ALFA2: 'CL',
    CODE: '152',
  },
  '156': {
    global_id: 273330208,
    signature_date: '21.09.2016 15:43:01',
    system_object_id: '156',
    ALFA3: 'CHN',
    SHORTNAME: 'КИТАЙ',
    FULLNAME: 'Китайская Народная Республика',
    ALFA2: 'CN',
    CODE: '156',
  },
  '158': {
    global_id: 273330209,
    signature_date: '21.09.2016 15:43:26',
    system_object_id: '158',
    ALFA3: 'TWN',
    SHORTNAME: 'ТАЙВАНЬ (КИТАЙ)',
    ALFA2: 'TW',
    CODE: '158',
  },
  '162': {
    global_id: 273330210,
    signature_date: '21.09.2016 15:43:29',
    system_object_id: '162',
    ALFA3: 'CXR',
    SHORTNAME: 'ОСТРОВ РОЖДЕСТВА',
    ALFA2: 'CX',
    CODE: '162',
  },
  '166': {
    global_id: 273330211,
    signature_date: '21.09.2016 15:43:28',
    system_object_id: '166',
    ALFA3: 'CCK',
    SHORTNAME: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    ALFA2: 'CC',
    CODE: '166',
  },
  '170': {
    global_id: 273330212,
    signature_date: '21.09.2016 15:43:58',
    system_object_id: '170',
    ALFA3: 'COL',
    SHORTNAME: 'КОЛУМБИЯ',
    FULLNAME: 'Республика Колумбия',
    ALFA2: 'CO',
    CODE: '170',
  },
  '174': {
    global_id: 273329416,
    signature_date: '21.09.2016 15:26:01',
    system_object_id: '174',
    ALFA3: 'COM',
    SHORTNAME: 'КОМОРЫ',
    FULLNAME: 'Союз Коморы',
    ALFA2: 'KM',
    CODE: '174',
  },
  '175': {
    global_id: 273330213,
    signature_date: '21.09.2016 15:44:02',
    system_object_id: '175',
    ALFA3: 'MYT',
    SHORTNAME: 'МАЙОТТА',
    ALFA2: 'YT',
    CODE: '175',
  },
  '178': {
    global_id: 273330214,
    signature_date: '21.09.2016 15:44:00',
    system_object_id: '178',
    ALFA3: 'COG',
    SHORTNAME: 'КОНГО',
    FULLNAME: 'Республика Конго',
    ALFA2: 'CG',
    CODE: '178',
  },
  '180': {
    global_id: 273330215,
    signature_date: '21.09.2016 15:44:31',
    system_object_id: '180',
    ALFA3: 'COD',
    SHORTNAME: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    FULLNAME: 'Демократическая Республика Конго',
    ALFA2: 'CD',
    CODE: '180',
  },
  '184': {
    global_id: 273330216,
    signature_date: '21.09.2016 15:44:33',
    system_object_id: '184',
    ALFA3: 'COK',
    SHORTNAME: 'ОСТРОВА КУКА',
    ALFA2: 'CK',
    CODE: '184',
  },
  '188': {
    global_id: 273330217,
    signature_date: '21.09.2016 15:44:34',
    system_object_id: '188',
    ALFA3: 'CRI',
    SHORTNAME: 'КОСТА-РИКА',
    FULLNAME: 'Республика Коста-Рика',
    ALFA2: 'CR',
    CODE: '188',
  },
  '191': {
    global_id: 273330218,
    signature_date: '21.09.2016 15:45:15',
    system_object_id: '191',
    ALFA3: 'HRV',
    SHORTNAME: 'ХОРВАТИЯ',
    FULLNAME: 'Республика Хорватия',
    ALFA2: 'HR',
    CODE: '191',
  },
  '192': {
    global_id: 273330219,
    signature_date: '21.09.2016 15:45:16',
    system_object_id: '192',
    ALFA3: 'CUB',
    SHORTNAME: 'КУБА',
    FULLNAME: 'Республика Куба',
    ALFA2: 'CU',
    CODE: '192',
  },
  '196': {
    global_id: 273330220,
    signature_date: '21.09.2016 15:45:17',
    system_object_id: '196',
    ALFA3: 'CYP',
    SHORTNAME: 'КИПР',
    FULLNAME: 'Республика Кипр',
    ALFA2: 'CY',
    CODE: '196',
  },
  '203': {
    global_id: 273330221,
    signature_date: '21.09.2016 15:45:19',
    system_object_id: '203',
    ALFA3: 'CZE',
    SHORTNAME: 'ЧЕШСКАЯ РЕСПУБЛИКА',
    ALFA2: 'CZ',
    CODE: '203',
  },
  '204': {
    global_id: 273330222,
    signature_date: '21.09.2016 15:45:19',
    system_object_id: '204',
    ALFA3: 'BEN',
    SHORTNAME: 'БЕНИН',
    FULLNAME: 'Республика Бенин',
    ALFA2: 'BJ',
    CODE: '204',
  },
  '208': {
    global_id: 273330223,
    signature_date: '21.09.2016 15:45:21',
    system_object_id: '208',
    ALFA3: 'DNK',
    SHORTNAME: 'ДАНИЯ',
    FULLNAME: 'Королевство Дания',
    ALFA2: 'DK',
    CODE: '208',
  },
  '212': {
    global_id: 273330224,
    signature_date: '21.09.2016 15:46:16',
    system_object_id: '212',
    ALFA3: 'DMA',
    SHORTNAME: 'ДОМИНИКА',
    FULLNAME: 'Содружество Доминики',
    ALFA2: 'DM',
    CODE: '212',
  },
  '214': {
    global_id: 273330225,
    signature_date: '21.09.2016 15:46:17',
    system_object_id: '214',
    ALFA3: 'DOM',
    SHORTNAME: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    ALFA2: 'DO',
    CODE: '214',
  },
  '218': {
    global_id: 273330226,
    signature_date: '21.09.2016 15:46:19',
    system_object_id: '218',
    ALFA3: 'ECU',
    SHORTNAME: 'ЭКВАДОР',
    FULLNAME: 'Республика Эквадор',
    ALFA2: 'EC',
    CODE: '218',
  },
  '222': {
    global_id: 273330227,
    signature_date: '21.09.2016 15:46:21',
    system_object_id: '222',
    ALFA3: 'SLV',
    SHORTNAME: 'ЭЛЬ-САЛЬВАДОР',
    FULLNAME: 'Республика Эль-Сальвадор',
    ALFA2: 'SV',
    CODE: '222',
  },
  '226': {
    global_id: 273330228,
    signature_date: '21.09.2016 15:46:22',
    system_object_id: '226',
    ALFA3: 'GNQ',
    SHORTNAME: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
    FULLNAME: 'Республика Экваториальная Гвинея',
    ALFA2: 'GQ',
    CODE: '226',
  },
  '231': {
    global_id: 273330229,
    signature_date: '21.09.2016 15:46:22',
    system_object_id: '231',
    ALFA3: 'ETH',
    SHORTNAME: 'ЭФИОПИЯ',
    FULLNAME: 'Федеративная Демократическая Республика Эфиопия',
    ALFA2: 'ET',
    CODE: '231',
  },
  '232': {
    global_id: 273330230,
    signature_date: '21.09.2016 15:47:27',
    system_object_id: '232',
    ALFA3: 'ERI',
    SHORTNAME: 'ЭРИТРЕЯ',
    FULLNAME: 'Государство Эритрея',
    ALFA2: 'ER',
    CODE: '232',
  },
  '233': {
    global_id: 273330231,
    signature_date: '21.09.2016 15:47:29',
    system_object_id: '233',
    ALFA3: 'EST',
    SHORTNAME: 'ЭСТОНИЯ',
    FULLNAME: 'Эстонская Республика',
    ALFA2: 'EE',
    CODE: '233',
  },
  '234': {
    global_id: 273330232,
    signature_date: '21.09.2016 15:47:30',
    system_object_id: '234',
    ALFA3: 'FRO',
    SHORTNAME: 'ФАРЕРСКИЕ ОСТРОВА',
    ALFA2: 'FO',
    CODE: '234',
  },
  '238': {
    global_id: 273330233,
    signature_date: '21.09.2016 15:47:31',
    system_object_id: '238',
    ALFA3: 'FLK',
    SHORTNAME: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    ALFA2: 'FK',
    CODE: '238',
  },
  '239': {
    global_id: 273330234,
    signature_date: '21.09.2016 15:47:32',
    system_object_id: '239',
    ALFA3: 'SGS',
    SHORTNAME: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    ALFA2: 'GS',
    CODE: '239',
  },
  '242': {
    global_id: 273330235,
    signature_date: '21.09.2016 15:47:32',
    system_object_id: '242',
    ALFA3: 'FJI',
    SHORTNAME: 'ФИДЖИ',
    FULLNAME: 'Республика Фиджи',
    ALFA2: 'FJ',
    CODE: '242',
  },
  '246': {
    global_id: 273330236,
    signature_date: '21.09.2016 15:48:42',
    system_object_id: '246',
    ALFA3: 'FIN',
    SHORTNAME: 'ФИНЛЯНДИЯ',
    FULLNAME: 'Финляндская Республика',
    ALFA2: 'FI',
    CODE: '246',
  },
  '248': {
    global_id: 273330237,
    signature_date: '21.09.2016 15:48:45',
    system_object_id: '248',
    ALFA3: 'ALA',
    SHORTNAME: 'ЭЛАНДСКИЕ ОСТРОВА',
    ALFA2: 'AX',
    CODE: '248',
  },
  '250': {
    global_id: 273330238,
    signature_date: '21.09.2016 15:48:47',
    system_object_id: '250',
    ALFA3: 'FRA',
    SHORTNAME: 'ФРАНЦИЯ',
    FULLNAME: 'Французская Республика',
    ALFA2: 'FR',
    CODE: '250',
  },
  '254': {
    global_id: 273330239,
    signature_date: '21.09.2016 15:48:50',
    system_object_id: '254',
    ALFA3: 'GUF',
    SHORTNAME: 'ФРАНЦУЗСКАЯ ГВИАНА',
    ALFA2: 'GF',
    CODE: '254',
  },
  '258': {
    global_id: 273330240,
    signature_date: '21.09.2016 15:48:53',
    system_object_id: '258',
    ALFA3: 'PYF',
    SHORTNAME: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    ALFA2: 'PF',
    CODE: '258',
  },
  '260': {
    global_id: 273330241,
    signature_date: '21.09.2016 15:48:55',
    system_object_id: '260',
    ALFA3: 'ATF',
    SHORTNAME: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    ALFA2: 'TF',
    CODE: '260',
  },
  '262': {
    global_id: 273330242,
    signature_date: '21.09.2016 15:49:57',
    system_object_id: '262',
    ALFA3: 'DJI',
    SHORTNAME: 'ДЖИБУТИ',
    FULLNAME: 'Республика Джибути',
    ALFA2: 'DJ',
    CODE: '262',
  },
  '266': {
    global_id: 273329417,
    signature_date: '21.09.2016 15:26:02',
    system_object_id: '266',
    ALFA3: 'GAB',
    SHORTNAME: 'ГАБОН',
    FULLNAME: 'Габонская Республика',
    ALFA2: 'GA',
    CODE: '266',
  },
  '268': {
    global_id: 273330243,
    signature_date: '21.09.2016 15:49:58',
    system_object_id: '268',
    ALFA3: 'GEO',
    SHORTNAME: 'ГРУЗИЯ',
    ALFA2: 'GE',
    CODE: '268',
  },
  '270': {
    global_id: 273330244,
    signature_date: '21.09.2016 15:49:59',
    system_object_id: '270',
    ALFA3: 'GMB',
    SHORTNAME: 'ГАМБИЯ',
    FULLNAME: 'Исламская Республика Гамбия',
    ALFA2: 'GM',
    CODE: '270',
  },
  '275': {
    global_id: 273329418,
    signature_date: '21.09.2016 15:26:03',
    system_object_id: '275',
    ALFA3: 'PSE',
    SHORTNAME: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
    FULLNAME: 'Государство Палестина',
    ALFA2: 'PS',
    CODE: '275',
  },
  '276': {
    global_id: 273330245,
    signature_date: '21.09.2016 15:49:59',
    system_object_id: '276',
    ALFA3: 'DEU',
    SHORTNAME: 'ГЕРМАНИЯ',
    FULLNAME: 'Федеративная Республика Германия',
    ALFA2: 'DE',
    CODE: '276',
  },
  '288': {
    global_id: 273330246,
    signature_date: '21.09.2016 15:50:00',
    system_object_id: '288',
    ALFA3: 'GHA',
    SHORTNAME: 'ГАНА',
    FULLNAME: 'Республика Гана',
    ALFA2: 'GH',
    CODE: '288',
  },
  '292': {
    global_id: 273330247,
    signature_date: '21.09.2016 15:50:27',
    system_object_id: '292',
    ALFA3: 'GIB',
    SHORTNAME: 'ГИБРАЛТАР',
    ALFA2: 'GI',
    CODE: '292',
  },
  '296': {
    global_id: 273330248,
    signature_date: '21.09.2016 15:50:29',
    system_object_id: '296',
    ALFA3: 'KIR',
    SHORTNAME: 'КИРИБАТИ',
    FULLNAME: 'Республика Кирибати',
    ALFA2: 'KI',
    CODE: '296',
  },
  '300': {
    global_id: 273330249,
    signature_date: '21.09.2016 15:50:28',
    system_object_id: '300',
    ALFA3: 'GRC',
    SHORTNAME: 'ГРЕЦИЯ',
    FULLNAME: 'Греческая Республика',
    ALFA2: 'GR',
    CODE: '300',
  },
  '304': {
    global_id: 273330250,
    signature_date: '21.09.2016 15:50:57',
    system_object_id: '304',
    ALFA3: 'GRL',
    SHORTNAME: 'ГРЕНЛАНДИЯ',
    ALFA2: 'GL',
    CODE: '304',
  },
  '308': {
    global_id: 273330251,
    signature_date: '21.09.2016 15:51:02',
    system_object_id: '308',
    ALFA3: 'GRD',
    SHORTNAME: 'ГРЕНАДА',
    ALFA2: 'GD',
    CODE: '308',
  },
  '312': {
    global_id: 273329419,
    signature_date: '21.09.2016 15:26:55',
    system_object_id: '312',
    ALFA3: 'GLP',
    SHORTNAME: 'ГВАДЕЛУПА',
    ALFA2: 'GP',
    CODE: '312',
  },
  '316': {
    global_id: 273330252,
    signature_date: '21.09.2016 15:50:59',
    system_object_id: '316',
    ALFA3: 'GUM',
    SHORTNAME: 'ГУАМ',
    ALFA2: 'GU',
    CODE: '316',
  },
  '320': {
    global_id: 273330253,
    signature_date: '21.09.2016 15:51:39',
    system_object_id: '320',
    ALFA3: 'GTM',
    SHORTNAME: 'ГВАТЕМАЛА',
    FULLNAME: 'Республика Гватемала',
    ALFA2: 'GT',
    CODE: '320',
  },
  '324': {
    global_id: 273330254,
    signature_date: '21.09.2016 15:51:41',
    system_object_id: '324',
    ALFA3: 'GIN',
    SHORTNAME: 'ГВИНЕЯ',
    FULLNAME: 'Гвинейская Республика',
    ALFA2: 'GN',
    CODE: '324',
  },
  '328': {
    global_id: 273330255,
    signature_date: '21.09.2016 15:51:42',
    system_object_id: '328',
    ALFA3: 'GUY',
    SHORTNAME: 'ГАЙАНА',
    FULLNAME: 'Республика Гайана',
    ALFA2: 'GY',
    CODE: '328',
  },
  '332': {
    global_id: 273330256,
    signature_date: '21.09.2016 15:51:43',
    system_object_id: '332',
    ALFA3: 'HTI',
    SHORTNAME: 'ГАИТИ',
    FULLNAME: 'Республика Гаити',
    ALFA2: 'HT',
    CODE: '332',
  },
  '334': {
    global_id: 273330257,
    signature_date: '21.09.2016 15:51:44',
    system_object_id: '334',
    ALFA3: 'HMD',
    SHORTNAME: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    ALFA2: 'HM',
    CODE: '334',
  },
  '336': {
    global_id: 273330258,
    signature_date: '21.09.2016 15:51:45',
    system_object_id: '336',
    ALFA3: 'VAT',
    SHORTNAME: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    ALFA2: 'VA',
    CODE: '336',
  },
  '340': {
    global_id: 273329420,
    signature_date: '21.09.2016 15:26:56',
    system_object_id: '340',
    ALFA3: 'HND',
    SHORTNAME: 'ГОНДУРАС',
    FULLNAME: 'Республика Гондурас',
    ALFA2: 'HN',
    CODE: '340',
  },
  '344': {
    global_id: 273330259,
    signature_date: '21.09.2016 15:52:47',
    system_object_id: '344',
    ALFA3: 'HKG',
    SHORTNAME: 'ГОНКОНГ',
    FULLNAME: 'Специальный административный регион Китая Гонконг',
    ALFA2: 'HK',
    CODE: '344',
  },
  '348': {
    global_id: 273330260,
    signature_date: '21.09.2016 15:52:48',
    system_object_id: '348',
    ALFA3: 'HUN',
    SHORTNAME: 'ВЕНГРИЯ',
    FULLNAME: 'Венгрия',
    ALFA2: 'HU',
    CODE: '348',
  },
  '352': {
    global_id: 273330261,
    signature_date: '21.09.2016 15:52:49',
    system_object_id: '352',
    ALFA3: 'ISL',
    SHORTNAME: 'ИСЛАНДИЯ',
    FULLNAME: 'Республика Исландия',
    ALFA2: 'IS',
    CODE: '352',
  },
  '356': {
    global_id: 273330262,
    signature_date: '21.09.2016 15:52:50',
    system_object_id: '356',
    ALFA3: 'IND',
    SHORTNAME: 'ИНДИЯ',
    FULLNAME: 'Республика Индия',
    ALFA2: 'IN',
    CODE: '356',
  },
  '360': {
    global_id: 273330263,
    signature_date: '21.09.2016 15:52:51',
    system_object_id: '360',
    ALFA3: 'IDN',
    SHORTNAME: 'ИНДОНЕЗИЯ',
    FULLNAME: 'Республика Индонезия',
    ALFA2: 'ID',
    CODE: '360',
  },
  '364': {
    global_id: 273329421,
    signature_date: '21.09.2016 15:26:57',
    system_object_id: '364',
    ALFA3: 'IRN',
    SHORTNAME: 'ИРАН, ИСЛАМСКАЯ РЕСПУБЛИКА',
    FULLNAME: 'Исламская Республика Иран',
    ALFA2: 'IR',
    CODE: '364',
  },
  '368': {
    global_id: 273330264,
    signature_date: '21.09.2016 15:52:51',
    system_object_id: '368',
    ALFA3: 'IRQ',
    SHORTNAME: 'ИРАК',
    FULLNAME: 'Республика Ирак',
    ALFA2: 'IQ',
    CODE: '368',
  },
  '372': {
    global_id: 273329422,
    signature_date: '21.09.2016 15:26:58',
    system_object_id: '372',
    ALFA3: 'IRL',
    SHORTNAME: 'ИРЛАНДИЯ',
    ALFA2: 'IE',
    CODE: '372',
  },
  '376': {
    global_id: 273330265,
    signature_date: '21.09.2016 15:53:04',
    system_object_id: '376',
    ALFA3: 'ISR',
    SHORTNAME: 'ИЗРАИЛЬ',
    FULLNAME: 'Государство Израиль',
    ALFA2: 'IL',
    CODE: '376',
  },
  '380': {
    global_id: 273330266,
    signature_date: '21.09.2016 15:53:36',
    system_object_id: '380',
    ALFA3: 'ITA',
    SHORTNAME: 'ИТАЛИЯ',
    FULLNAME: 'Итальянская Республика',
    ALFA2: 'IT',
    CODE: '380',
  },
  '384': {
    global_id: 273330267,
    signature_date: '21.09.2016 15:53:39',
    system_object_id: '384',
    ALFA3: 'CIV',
    SHORTNAME: 'КОТ Д`ИВУАР',
    FULLNAME: 'Республика Кот д`Ивуар',
    ALFA2: 'CI',
    CODE: '384',
  },
  '388': {
    global_id: 273330268,
    signature_date: '21.09.2016 15:53:41',
    system_object_id: '388',
    ALFA3: 'JAM',
    SHORTNAME: 'ЯМАЙКА',
    ALFA2: 'JM',
    CODE: '388',
  },
  '392': {
    global_id: 273329423,
    signature_date: '21.09.2016 15:26:59',
    system_object_id: '392',
    ALFA3: 'JPN',
    SHORTNAME: 'ЯПОНИЯ',
    ALFA2: 'JP',
    CODE: '392',
  },
  '398': {
    global_id: 273330269,
    signature_date: '21.09.2016 15:53:44',
    system_object_id: '398',
    ALFA3: 'KAZ',
    SHORTNAME: 'КАЗАХСТАН',
    FULLNAME: 'Республика Казахстан',
    ALFA2: 'KZ',
    CODE: '398',
  },
  '400': {
    global_id: 273330270,
    signature_date: '21.09.2016 15:53:45',
    system_object_id: '400',
    ALFA3: 'JOR',
    SHORTNAME: 'ИОРДАНИЯ',
    FULLNAME: 'Иорданское Хашимитское Королевство',
    ALFA2: 'JO',
    CODE: '400',
  },
  '404': {
    global_id: 273329424,
    signature_date: '21.09.2016 15:27:57',
    system_object_id: '404',
    ALFA3: 'KEN',
    SHORTNAME: 'КЕНИЯ',
    FULLNAME: 'Республика Кения',
    ALFA2: 'KE',
    CODE: '404',
  },
  '408': {
    global_id: 273330271,
    signature_date: '21.09.2016 15:53:54',
    system_object_id: '408',
    ALFA3: 'PRK',
    SHORTNAME: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    FULLNAME: 'Корейская Народно-Демократическая Республика',
    ALFA2: 'KP',
    CODE: '408',
  },
  '410': {
    global_id: 273330272,
    signature_date: '21.09.2016 15:54:34',
    system_object_id: '410',
    ALFA3: 'KOR',
    SHORTNAME: 'КОРЕЯ, РЕСПУБЛИКА',
    FULLNAME: 'Республика Корея',
    ALFA2: 'KR',
    CODE: '410',
  },
  '414': {
    global_id: 273330273,
    signature_date: '21.09.2016 15:54:35',
    system_object_id: '414',
    ALFA3: 'KWT',
    SHORTNAME: 'КУВЕЙТ',
    FULLNAME: 'Государство Кувейт',
    ALFA2: 'KW',
    CODE: '414',
  },
  '417': {
    global_id: 273329425,
    signature_date: '21.09.2016 15:27:58',
    system_object_id: '417',
    ALFA3: 'KGZ',
    SHORTNAME: 'КИРГИЗИЯ',
    FULLNAME: 'Киргизская Республика',
    ALFA2: 'KG',
    CODE: '417',
  },
  '418': {
    global_id: 273330274,
    signature_date: '21.09.2016 15:54:36',
    system_object_id: '418',
    ALFA3: 'LAO',
    SHORTNAME: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    ALFA2: 'LA',
    CODE: '418',
  },
  '422': {
    global_id: 273330275,
    signature_date: '21.09.2016 15:54:39',
    system_object_id: '422',
    ALFA3: 'LBN',
    SHORTNAME: 'ЛИВАН',
    FULLNAME: 'Ливанская Республика',
    ALFA2: 'LB',
    CODE: '422',
  },
  '426': {
    global_id: 273330276,
    signature_date: '21.09.2016 15:54:41',
    system_object_id: '426',
    ALFA3: 'LSO',
    SHORTNAME: 'ЛЕСОТО',
    FULLNAME: 'Королевство Лесото',
    ALFA2: 'LS',
    CODE: '426',
  },
  '428': {
    global_id: 273330277,
    signature_date: '21.09.2016 15:54:42',
    system_object_id: '428',
    ALFA3: 'LVA',
    SHORTNAME: 'ЛАТВИЯ',
    FULLNAME: 'Латвийская Республика',
    ALFA2: 'LV',
    CODE: '428',
  },
  '430': {
    global_id: 273330278,
    signature_date: '21.09.2016 15:55:19',
    system_object_id: '430',
    ALFA3: 'LBR',
    SHORTNAME: 'ЛИБЕРИЯ',
    FULLNAME: 'Республика Либерия',
    ALFA2: 'LR',
    CODE: '430',
  },
  '434': {
    global_id: 273330279,
    signature_date: '21.09.2016 15:55:22',
    system_object_id: '434',
    ALFA3: 'LBY',
    SHORTNAME: 'ЛИВИЯ',
    FULLNAME: 'Ливия',
    ALFA2: 'LY',
    CODE: '434',
  },
  '438': {
    global_id: 273330280,
    signature_date: '21.09.2016 15:55:21',
    system_object_id: '438',
    ALFA3: 'LIE',
    SHORTNAME: 'ЛИХТЕНШТЕЙН',
    FULLNAME: 'Княжество Лихтенштейн',
    ALFA2: 'LI',
    CODE: '438',
  },
  '440': {
    global_id: 273330281,
    signature_date: '21.09.2016 15:55:58',
    system_object_id: '440',
    ALFA3: 'LTU',
    SHORTNAME: 'ЛИТВА',
    FULLNAME: 'Литовская Республика',
    ALFA2: 'LT',
    CODE: '440',
  },
  '442': {
    global_id: 273330282,
    signature_date: '21.09.2016 15:56:00',
    system_object_id: '442',
    ALFA3: 'LUX',
    SHORTNAME: 'ЛЮКСЕМБУРГ',
    FULLNAME: 'Великое Герцогство Люксембург',
    ALFA2: 'LU',
    CODE: '442',
  },
  '446': {
    global_id: 273330283,
    signature_date: '21.09.2016 15:56:01',
    system_object_id: '446',
    ALFA3: 'MAC',
    SHORTNAME: 'МАКАО',
    FULLNAME: 'Специальный административный регион Китая Макао',
    ALFA2: 'MO',
    CODE: '446',
  },
  '450': {
    global_id: 273330284,
    signature_date: '21.09.2016 15:56:02',
    system_object_id: '450',
    ALFA3: 'MDG',
    SHORTNAME: 'МАДАГАСКАР',
    FULLNAME: 'Республика Мадагаскар',
    ALFA2: 'MG',
    CODE: '450',
  },
  '454': {
    global_id: 273330285,
    signature_date: '21.09.2016 15:56:03',
    system_object_id: '454',
    ALFA3: 'MWI',
    SHORTNAME: 'МАЛАВИ',
    FULLNAME: 'Республика Малави',
    ALFA2: 'MW',
    CODE: '454',
  },
  '458': {
    global_id: 273330286,
    signature_date: '21.09.2016 15:56:04',
    system_object_id: '458',
    ALFA3: 'MYS',
    SHORTNAME: 'МАЛАЙЗИЯ',
    ALFA2: 'MY',
    CODE: '458',
  },
  '462': {
    global_id: 273330287,
    signature_date: '21.09.2016 15:56:29',
    system_object_id: '462',
    ALFA3: 'MDV',
    SHORTNAME: 'МАЛЬДИВЫ',
    FULLNAME: 'Мальдивская Республика',
    ALFA2: 'MV',
    CODE: '462',
  },
  '466': {
    global_id: 273330288,
    signature_date: '21.09.2016 15:56:33',
    system_object_id: '466',
    ALFA3: 'MLI',
    SHORTNAME: 'МАЛИ',
    FULLNAME: 'Республика Мали',
    ALFA2: 'ML',
    CODE: '466',
  },
  '470': {
    global_id: 273329426,
    signature_date: '21.09.2016 15:27:59',
    system_object_id: '470',
    ALFA3: 'MLT',
    SHORTNAME: 'МАЛЬТА',
    FULLNAME: 'Республика Мальта',
    ALFA2: 'MT',
    CODE: '470',
  },
  '474': {
    global_id: 273330289,
    signature_date: '21.09.2016 15:56:31',
    system_object_id: '474',
    ALFA3: 'MTQ',
    SHORTNAME: 'МАРТИНИКА',
    ALFA2: 'MQ',
    CODE: '474',
  },
  '478': {
    global_id: 273330290,
    signature_date: '21.09.2016 15:57:03',
    system_object_id: '478',
    ALFA3: 'MRT',
    SHORTNAME: 'МАВРИТАНИЯ',
    FULLNAME: 'Исламская Республика Мавритания',
    ALFA2: 'MR',
    CODE: '478',
  },
  '480': {
    global_id: 273330291,
    signature_date: '21.09.2016 15:57:07',
    system_object_id: '480',
    ALFA3: 'MUS',
    SHORTNAME: 'МАВРИКИЙ',
    FULLNAME: 'Республика Маврикий',
    ALFA2: 'MU',
    CODE: '480',
  },
  '484': {
    global_id: 273330292,
    signature_date: '21.09.2016 15:57:04',
    system_object_id: '484',
    ALFA3: 'MEX',
    SHORTNAME: 'МЕКСИКА',
    FULLNAME: 'Мексиканские Соединенные Штаты',
    ALFA2: 'MX',
    CODE: '484',
  },
  '492': {
    global_id: 273330293,
    signature_date: '21.09.2016 15:57:42',
    system_object_id: '492',
    ALFA3: 'MCO',
    SHORTNAME: 'МОНАКО',
    FULLNAME: 'Княжество Монако',
    ALFA2: 'MC',
    CODE: '492',
  },
  '496': {
    global_id: 273330294,
    signature_date: '21.09.2016 15:57:43',
    system_object_id: '496',
    ALFA3: 'MNG',
    SHORTNAME: 'МОНГОЛИЯ',
    ALFA2: 'MN',
    CODE: '496',
  },
  '498': {
    global_id: 273330295,
    signature_date: '21.09.2016 15:57:44',
    system_object_id: '498',
    ALFA3: 'MDA',
    SHORTNAME: 'МОЛДОВА, РЕСПУБЛИКА',
    FULLNAME: 'Республика Молдова',
    ALFA2: 'MD',
    CODE: '498',
  },
  '499': {
    global_id: 273330296,
    signature_date: '21.09.2016 15:57:44',
    system_object_id: '499',
    ALFA3: 'MNE',
    SHORTNAME: 'ЧЕРНОГОРИЯ',
    ALFA2: 'ME',
    CODE: '499',
  },
  '500': {
    global_id: 273330297,
    signature_date: '21.09.2016 15:57:45',
    system_object_id: '500',
    ALFA3: 'MSR',
    SHORTNAME: 'МОНТСЕРРАТ',
    ALFA2: 'MS',
    CODE: '500',
  },
  '504': {
    global_id: 273330298,
    signature_date: '21.09.2016 15:57:45',
    system_object_id: '504',
    ALFA3: 'MAR',
    SHORTNAME: 'МАРОККО',
    FULLNAME: 'Королевство Марокко',
    ALFA2: 'MA',
    CODE: '504',
  },
  '508': {
    global_id: 273329427,
    signature_date: '21.09.2016 15:28:00',
    system_object_id: '508',
    ALFA3: 'MOZ',
    SHORTNAME: 'МОЗАМБИК',
    FULLNAME: 'Республика Мозамбик',
    ALFA2: 'МZ',
    CODE: '508',
  },
  '512': {
    global_id: 273330299,
    signature_date: '21.09.2016 15:58:52',
    system_object_id: '512',
    ALFA3: 'OMN',
    SHORTNAME: 'ОМАН',
    FULLNAME: 'Султанат Оман',
    ALFA2: 'OM',
    CODE: '512',
  },
  '516': {
    global_id: 273330300,
    signature_date: '21.09.2016 15:58:54',
    system_object_id: '516',
    ALFA3: 'NAM',
    SHORTNAME: 'НАМИБИЯ',
    FULLNAME: 'Республика Намибия',
    ALFA2: 'NA',
    CODE: '516',
  },
  '520': {
    global_id: 273330301,
    signature_date: '21.09.2016 15:58:54',
    system_object_id: '520',
    ALFA3: 'NRU',
    SHORTNAME: 'НАУРУ',
    FULLNAME: 'Республика Науру',
    ALFA2: 'NR',
    CODE: '520',
  },
  '524': {
    global_id: 273330302,
    signature_date: '21.09.2016 15:58:53',
    system_object_id: '524',
    ALFA3: 'NPL',
    SHORTNAME: 'НЕПАЛ',
    FULLNAME: 'Федеративная Демократическая Республика Непал',
    ALFA2: 'NP',
    CODE: '524',
  },
  '528': {
    global_id: 273330303,
    signature_date: '21.09.2016 15:58:53',
    system_object_id: '528',
    ALFA3: 'NLD',
    SHORTNAME: 'НИДЕРЛАНДЫ',
    FULLNAME: 'Королевство Нидерландов',
    ALFA2: 'NL',
    CODE: '528',
  },
  '531': {
    global_id: 273330304,
    signature_date: '21.09.2016 15:58:52',
    system_object_id: '531',
    ALFA3: 'CUW',
    SHORTNAME: 'КЮРАСАО',
    ALFA2: 'CW',
    CODE: '531',
  },
  '533': {
    global_id: 273329428,
    signature_date: '21.09.2016 15:28:01',
    system_object_id: '533',
    ALFA3: 'ABW',
    SHORTNAME: 'АРУБА',
    ALFA2: 'AW',
    CODE: '533',
  },
  '534': {
    global_id: 273330305,
    signature_date: '21.09.2016 16:00:03',
    system_object_id: '534',
    ALFA3: 'SXM',
    SHORTNAME: 'СЕН-МАРТЕН (нидерландская часть)',
    ALFA2: 'SX',
    CODE: '534',
  },
  '535': {
    global_id: 273330306,
    signature_date: '21.09.2016 16:00:05',
    system_object_id: '535',
    ALFA3: 'BES',
    SHORTNAME: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    ALFA2: 'BQ',
    CODE: '535',
  },
  '540': {
    global_id: 273329429,
    signature_date: '21.09.2016 15:29:04',
    system_object_id: '540',
    ALFA3: 'NCL',
    SHORTNAME: 'НОВАЯ КАЛЕДОНИЯ',
    ALFA2: 'NC',
    CODE: '540',
  },
  '548': {
    global_id: 273330307,
    signature_date: '21.09.2016 16:00:06',
    system_object_id: '548',
    ALFA3: 'VUT',
    SHORTNAME: 'ВАНУАТУ',
    FULLNAME: 'Республика Вануату',
    ALFA2: 'VU',
    CODE: '548',
  },
  '554': {
    global_id: 273330308,
    signature_date: '21.09.2016 16:00:07',
    system_object_id: '554',
    ALFA3: 'NZL',
    SHORTNAME: 'НОВАЯ ЗЕЛАНДИЯ',
    ALFA2: 'NZ',
    CODE: '554',
  },
  '558': {
    global_id: 273330309,
    signature_date: '21.09.2016 16:00:08',
    system_object_id: '558',
    ALFA3: 'NIC',
    SHORTNAME: 'НИКАРАГУА',
    FULLNAME: 'Республика Никарагуа',
    ALFA2: 'NI',
    CODE: '558',
  },
  '562': {
    global_id: 273330310,
    signature_date: '21.09.2016 16:00:08',
    system_object_id: '562',
    ALFA3: 'NER',
    SHORTNAME: 'НИГЕР',
    FULLNAME: 'Республика Нигер',
    ALFA2: 'NE',
    CODE: '562',
  },
  '566': {
    global_id: 273330311,
    signature_date: '21.09.2016 16:00:09',
    system_object_id: '566',
    ALFA3: 'NGA',
    SHORTNAME: 'НИГЕРИЯ',
    FULLNAME: 'Федеративная Республика Нигерия',
    ALFA2: 'NG',
    CODE: '566',
  },
  '570': {
    global_id: 273330312,
    signature_date: '21.09.2016 16:01:16',
    system_object_id: '570',
    ALFA3: 'NIU',
    SHORTNAME: 'НИУЭ',
    FULLNAME: 'Ниуэ',
    ALFA2: 'NU',
    CODE: '570',
  },
  '574': {
    global_id: 273330313,
    signature_date: '21.09.2016 16:01:17',
    system_object_id: '574',
    ALFA3: 'NFK',
    SHORTNAME: 'ОСТРОВ НОРФОЛК',
    ALFA2: 'NF',
    CODE: '574',
  },
  '578': {
    global_id: 273330314,
    signature_date: '21.09.2016 16:01:17',
    system_object_id: '578',
    ALFA3: 'NOR',
    SHORTNAME: 'НОРВЕГИЯ',
    FULLNAME: 'Королевство Норвегия',
    ALFA2: 'NO',
    CODE: '578',
  },
  '580': {
    global_id: 273330315,
    signature_date: '21.09.2016 16:01:18',
    system_object_id: '580',
    ALFA3: 'MNP',
    SHORTNAME: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
    FULLNAME: 'Содружество Северных Марианских островов',
    ALFA2: 'MP',
    CODE: '580',
  },
  '581': {
    global_id: 273330316,
    signature_date: '21.09.2016 16:01:18',
    system_object_id: '581',
    ALFA3: 'UMI',
    SHORTNAME: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    ALFA2: 'UM',
    CODE: '581',
  },
  '583': {
    global_id: 273330317,
    signature_date: '21.09.2016 16:01:19',
    system_object_id: '583',
    ALFA3: 'FSM',
    SHORTNAME: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
    FULLNAME: 'Федеративные Штаты Микронезии',
    ALFA2: 'FM',
    CODE: '583',
  },
  '584': {
    global_id: 273330318,
    signature_date: '21.09.2016 16:01:19',
    system_object_id: '584',
    ALFA3: 'MHL',
    SHORTNAME: 'МАРШАЛЛОВЫ ОСТРОВА',
    FULLNAME: 'Республика Маршалловы Острова',
    ALFA2: 'MH',
    CODE: '584',
  },
  '585': {
    global_id: 273330319,
    signature_date: '21.09.2016 16:02:18',
    system_object_id: '585',
    ALFA3: 'PLW',
    SHORTNAME: 'ПАЛАУ',
    FULLNAME: 'Республика Палау',
    ALFA2: 'PW',
    CODE: '585',
  },
  '586': {
    global_id: 273330320,
    signature_date: '21.09.2016 16:02:20',
    system_object_id: '586',
    ALFA3: 'PAK',
    SHORTNAME: 'ПАКИСТАН',
    FULLNAME: 'Исламская Республика Пакистан',
    ALFA2: 'PK',
    CODE: '586',
  },
  '591': {
    global_id: 273330321,
    signature_date: '21.09.2016 16:02:21',
    system_object_id: '591',
    ALFA3: 'PAN',
    SHORTNAME: 'ПАНАМА',
    FULLNAME: 'Республика Панама',
    ALFA2: 'PA',
    CODE: '591',
  },
  '598': {
    global_id: 273330322,
    signature_date: '21.09.2016 16:02:23',
    system_object_id: '598',
    ALFA3: 'PNG',
    SHORTNAME: 'ПАПУА НОВАЯ ГВИНЕЯ',
    FULLNAME: 'Независимое Государство Папуа Новая Гвинея',
    ALFA2: 'PG',
    CODE: '598',
  },
  '600': {
    global_id: 273330323,
    signature_date: '21.09.2016 16:02:24',
    system_object_id: '600',
    ALFA3: 'PRY',
    SHORTNAME: 'ПАРАГВАЙ',
    FULLNAME: 'Республика Парагвай',
    ALFA2: 'PY',
    CODE: '600',
  },
  '604': {
    global_id: 273330324,
    signature_date: '21.09.2016 16:02:25',
    system_object_id: '604',
    ALFA3: 'PER',
    SHORTNAME: 'ПЕРУ',
    FULLNAME: 'Республика Перу',
    ALFA2: 'PE',
    CODE: '604',
  },
  '608': {
    global_id: 273330325,
    signature_date: '21.09.2016 16:03:14',
    system_object_id: '608',
    ALFA3: 'PHL',
    SHORTNAME: 'ФИЛИППИНЫ',
    FULLNAME: 'Республика Филиппины',
    ALFA2: 'PH',
    CODE: '608',
  },
  '612': {
    global_id: 273330326,
    signature_date: '21.09.2016 16:03:19',
    system_object_id: '612',
    ALFA3: 'PCN',
    SHORTNAME: 'ПИТКЕРН',
    ALFA2: 'PN',
    CODE: '612',
  },
  '616': {
    global_id: 273330327,
    signature_date: '21.09.2016 16:03:18',
    system_object_id: '616',
    ALFA3: 'POL',
    SHORTNAME: 'ПОЛЬША',
    FULLNAME: 'Республика Польша',
    ALFA2: 'PL',
    CODE: '616',
  },
  '620': {
    global_id: 273330328,
    signature_date: '21.09.2016 16:03:16',
    system_object_id: '620',
    ALFA3: 'PRT',
    SHORTNAME: 'ПОРТУГАЛИЯ',
    FULLNAME: 'Португальская Республика',
    ALFA2: 'PT',
    CODE: '620',
  },
  '624': {
    global_id: 273330329,
    signature_date: '21.09.2016 16:03:15',
    system_object_id: '624',
    ALFA3: 'GNB',
    SHORTNAME: 'ГВИНЕЯ-БИСАУ',
    FULLNAME: 'Республика Гвинея-Бисау',
    ALFA2: 'GW',
    CODE: '624',
  },
  '626': {
    global_id: 273330330,
    signature_date: '21.09.2016 16:04:29',
    system_object_id: '626',
    ALFA3: 'TLS',
    SHORTNAME: 'ТИМОР-ЛЕСТЕ',
    FULLNAME: 'Демократическая Республика Тимор-Лесте',
    ALFA2: 'TL',
    CODE: '626',
  },
  '630': {
    global_id: 273330331,
    signature_date: '21.09.2016 16:04:30',
    system_object_id: '630',
    ALFA3: 'PRI',
    SHORTNAME: 'ПУЭРТО-РИКО',
    ALFA2: 'PR',
    CODE: '630',
  },
  '634': {
    global_id: 273330332,
    signature_date: '21.09.2016 16:04:32',
    system_object_id: '634',
    ALFA3: 'QAT',
    SHORTNAME: 'КАТАР',
    FULLNAME: 'Государство Катар',
    ALFA2: 'QA',
    CODE: '634',
  },
  '638': {
    global_id: 273330333,
    signature_date: '21.09.2016 16:04:33',
    system_object_id: '638',
    ALFA3: 'REU',
    SHORTNAME: 'РЕЮНЬОН',
    ALFA2: 'RE',
    CODE: '638',
  },
  '642': {
    global_id: 273330334,
    signature_date: '21.09.2016 16:04:34',
    system_object_id: '642',
    ALFA3: 'ROU',
    SHORTNAME: 'РУМЫНИЯ',
    ALFA2: 'RO',
    CODE: '642',
  },
  '643': {
    global_id: 273330335,
    signature_date: '21.09.2016 16:04:35',
    system_object_id: '643',
    ALFA3: 'RUS',
    SHORTNAME: 'РОССИЯ',
    FULLNAME: 'Российская Федерация',
    ALFA2: 'RU',
    CODE: '643',
  },
  '646': {
    global_id: 273330336,
    signature_date: '21.09.2016 16:04:35',
    system_object_id: '646',
    ALFA3: 'RWA',
    SHORTNAME: 'РУАНДА',
    FULLNAME: 'Руандийская Республика',
    ALFA2: 'RW',
    CODE: '646',
  },
  '652': {
    global_id: 273330337,
    signature_date: '21.09.2016 16:05:29',
    system_object_id: '652',
    ALFA3: 'BLM',
    SHORTNAME: 'СЕН-БАРТЕЛЕМИ',
    ALFA2: 'BL',
    CODE: '652',
  },
  '654': {
    global_id: 273330338,
    signature_date: '21.09.2016 16:05:31',
    system_object_id: '654',
    ALFA3: 'SHN',
    SHORTNAME: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    ALFA2: 'SH',
    CODE: '654',
  },
  '659': {
    global_id: 273330339,
    signature_date: '21.09.2016 16:05:32',
    system_object_id: '659',
    ALFA3: 'KNA',
    SHORTNAME: 'СЕНТ-КИТС И НЕВИС',
    ALFA2: 'KN',
    CODE: '659',
  },
  '660': {
    global_id: 273330340,
    signature_date: '21.09.2016 16:05:33',
    system_object_id: '660',
    ALFA3: 'AIA',
    SHORTNAME: 'АНГИЛЬЯ',
    ALFA2: 'AI',
    CODE: '660',
  },
  '662': {
    global_id: 273330341,
    signature_date: '21.09.2016 16:05:30',
    system_object_id: '662',
    ALFA3: 'LCA',
    SHORTNAME: 'СЕНТ-ЛЮСИЯ',
    ALFA2: 'LC',
    CODE: '662',
  },
  '663': {
    global_id: 273330342,
    signature_date: '21.09.2016 16:05:30',
    system_object_id: '663',
    ALFA3: 'MAF',
    SHORTNAME: 'СЕН-МАРТЕН',
    ALFA2: 'MF',
    CODE: '663',
  },
  '666': {
    global_id: 273330343,
    signature_date: '21.09.2016 16:06:28',
    system_object_id: '666',
    ALFA3: 'SPM',
    SHORTNAME: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    ALFA2: 'PM',
    CODE: '666',
  },
  '670': {
    global_id: 273330344,
    signature_date: '21.09.2016 16:06:27',
    system_object_id: '670',
    ALFA3: 'VCT',
    SHORTNAME: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    ALFA2: 'VC',
    CODE: '670',
  },
  '674': {
    global_id: 273330345,
    signature_date: '21.09.2016 16:06:26',
    system_object_id: '674',
    ALFA3: 'SMR',
    SHORTNAME: 'САН-МАРИНО',
    FULLNAME: 'Республика Сан-Марино',
    ALFA2: 'SM',
    CODE: '674',
  },
  '678': {
    global_id: 273330346,
    signature_date: '21.09.2016 16:06:26',
    system_object_id: '678',
    ALFA3: 'STP',
    SHORTNAME: 'САН-ТОМЕ И ПРИНСИПИ',
    FULLNAME: 'Демократическая Республика Сан-Томе и Принсипи',
    ALFA2: 'ST',
    CODE: '678',
  },
  '682': {
    global_id: 273330347,
    signature_date: '21.09.2016 16:06:25',
    system_object_id: '682',
    ALFA3: 'SAU',
    SHORTNAME: 'САУДОВСКАЯ АРАВИЯ',
    FULLNAME: 'Королевство Саудовская Аравия',
    ALFA2: 'SA',
    CODE: '682',
  },
  '686': {
    global_id: 273330348,
    signature_date: '21.09.2016 16:06:30',
    system_object_id: '686',
    ALFA3: 'SEN',
    SHORTNAME: 'СЕНЕГАЛ',
    FULLNAME: 'Республика Сенегал',
    ALFA2: 'SN',
    CODE: '686',
  },
  '688': {
    global_id: 273330349,
    signature_date: '21.09.2016 16:06:29',
    system_object_id: '688',
    ALFA3: 'SRB',
    SHORTNAME: 'СЕРБИЯ',
    FULLNAME: 'Республика Сербия',
    ALFA2: 'RS',
    CODE: '688',
  },
  '690': {
    global_id: 273330350,
    signature_date: '21.09.2016 16:07:37',
    system_object_id: '690',
    ALFA3: 'SYC',
    SHORTNAME: 'СЕЙШЕЛЫ',
    FULLNAME: 'Республика Сейшелы',
    ALFA2: 'SC',
    CODE: '690',
  },
  '694': {
    global_id: 273330351,
    signature_date: '21.09.2016 16:07:37',
    system_object_id: '694',
    ALFA3: 'SLE',
    SHORTNAME: 'СЬЕРРА-ЛЕОНЕ',
    FULLNAME: 'Республика Сьерра-Леоне',
    ALFA2: 'SL',
    CODE: '694',
  },
  '702': {
    global_id: 273330121,
    signature_date: '21.09.2016 15:29:09',
    system_object_id: '702',
    ALFA3: 'SGP',
    SHORTNAME: 'СИНГАПУР',
    FULLNAME: 'Республика Сингапур',
    ALFA2: 'SG',
    CODE: '702',
  },
  '703': {
    global_id: 273329430,
    signature_date: '21.09.2016 15:29:06',
    system_object_id: '703',
    ALFA3: 'SVK',
    SHORTNAME: 'СЛОВАКИЯ',
    FULLNAME: 'Словацкая Республика',
    ALFA2: 'SK',
    CODE: '703',
  },
  '704': {
    global_id: 273330122,
    signature_date: '21.09.2016 15:29:09',
    system_object_id: '704',
    ALFA3: 'VNM',
    SHORTNAME: 'ВЬЕТНАМ',
    FULLNAME: 'Социалистическая Республика Вьетнам',
    ALFA2: 'VN',
    CODE: '704',
  },
  '705': {
    global_id: 273330123,
    signature_date: '21.09.2016 15:30:08',
    system_object_id: '705',
    ALFA3: 'SVN',
    SHORTNAME: 'СЛОВЕНИЯ',
    FULLNAME: 'Республика Словения',
    ALFA2: 'SI',
    CODE: '705',
  },
  '706': {
    global_id: 273330124,
    signature_date: '21.09.2016 15:30:11',
    system_object_id: '706',
    ALFA3: 'SOM',
    SHORTNAME: 'СОМАЛИ',
    FULLNAME: 'Федеративная Республика Сомали',
    ALFA2: 'SO',
    CODE: '706',
  },
  '710': {
    global_id: 273330125,
    signature_date: '21.09.2016 15:30:11',
    system_object_id: '710',
    ALFA3: 'ZAF',
    SHORTNAME: 'ЮЖНАЯ АФРИКА',
    FULLNAME: 'Южно-Африканская Республика',
    ALFA2: 'ZA',
    CODE: '710',
  },
  '716': {
    global_id: 273330126,
    signature_date: '21.09.2016 15:30:10',
    system_object_id: '716',
    ALFA3: 'ZWE',
    SHORTNAME: 'ЗИМБАБВЕ',
    FULLNAME: 'Республика Зимбабве',
    ALFA2: 'ZW',
    CODE: '716',
  },
  '724': {
    global_id: 273330127,
    signature_date: '21.09.2016 15:30:09',
    system_object_id: '724',
    ALFA3: 'ESP',
    SHORTNAME: 'ИСПАНИЯ',
    FULLNAME: 'Королевство Испания',
    ALFA2: 'ES',
    CODE: '724',
  },
  '728': {
    global_id: 273330128,
    signature_date: '21.09.2016 15:30:09',
    system_object_id: '728',
    ALFA3: 'SSD',
    SHORTNAME: 'ЮЖНЫЙ СУДАН',
    FULLNAME: 'Республика Южный Судан',
    ALFA2: 'SS',
    CODE: '728',
  },
  '729': {
    global_id: 273330129,
    signature_date: '21.09.2016 15:31:08',
    system_object_id: '729',
    ALFA3: 'SDN',
    SHORTNAME: 'СУДАН',
    FULLNAME: 'Республика Судан',
    ALFA2: 'SD',
    CODE: '729',
  },
  '732': {
    global_id: 273330130,
    signature_date: '21.09.2016 15:31:09',
    system_object_id: '732',
    ALFA3: 'ESH',
    SHORTNAME: 'ЗАПАДНАЯ САХАРА',
    ALFA2: 'EH',
    CODE: '732',
  },
  '740': {
    global_id: 273330131,
    signature_date: '21.09.2016 15:31:09',
    system_object_id: '740',
    ALFA3: 'SUR',
    SHORTNAME: 'СУРИНАМ',
    FULLNAME: 'Республика Суринам',
    ALFA2: 'SR',
    CODE: '740',
  },
  '744': {
    global_id: 273330132,
    signature_date: '21.09.2016 15:31:09',
    system_object_id: '744',
    ALFA3: 'SJM',
    SHORTNAME: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    ALFA2: 'SJ',
    CODE: '744',
  },
  '748': {
    global_id: 273329431,
    signature_date: '21.09.2016 15:29:06',
    system_object_id: '748',
    ALFA3: 'SWZ',
    SHORTNAME: 'СВАЗИЛЕНД',
    FULLNAME: 'Королевство Свазиленд',
    ALFA2: 'SZ',
    CODE: '748',
  },
  '752': {
    global_id: 273330133,
    signature_date: '21.09.2016 15:31:10',
    system_object_id: '752',
    ALFA3: 'SWE',
    SHORTNAME: 'ШВЕЦИЯ',
    FULLNAME: 'Королевство Швеция',
    ALFA2: 'SE',
    CODE: '752',
  },
  '756': {
    global_id: 273330134,
    signature_date: '21.09.2016 15:31:10',
    system_object_id: '756',
    ALFA3: 'CHE',
    SHORTNAME: 'ШВЕЙЦАРИЯ',
    FULLNAME: 'Швейцарская Конфедерация',
    ALFA2: 'CH',
    CODE: '756',
  },
  '760': {
    global_id: 273330135,
    signature_date: '21.09.2016 15:31:11',
    system_object_id: '760',
    ALFA3: 'SYR',
    SHORTNAME: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    ALFA2: 'SY',
    CODE: '760',
  },
  '762': {
    global_id: 273330136,
    signature_date: '21.09.2016 15:32:04',
    system_object_id: '762',
    ALFA3: 'TJK',
    SHORTNAME: 'ТАДЖИКИСТАН',
    FULLNAME: 'Республика Таджикистан',
    ALFA2: 'TJ',
    CODE: '762',
  },
  '764': {
    global_id: 273330137,
    signature_date: '21.09.2016 15:32:07',
    system_object_id: '764',
    ALFA3: 'THA',
    SHORTNAME: 'ТАИЛАНД',
    FULLNAME: 'Королевство Таиланд',
    ALFA2: 'TH',
    CODE: '764',
  },
  '768': {
    global_id: 273330138,
    signature_date: '21.09.2016 15:32:06',
    system_object_id: '768',
    ALFA3: 'TGO',
    SHORTNAME: 'ТОГО',
    FULLNAME: 'Тоголезская Республика',
    ALFA2: 'TG',
    CODE: '768',
  },
  '772': {
    global_id: 273330139,
    signature_date: '21.09.2016 15:32:06',
    system_object_id: '772',
    ALFA3: 'TKL',
    SHORTNAME: 'ТОКЕЛАУ',
    ALFA2: 'TK',
    CODE: '772',
  },
  '776': {
    global_id: 273330140,
    signature_date: '21.09.2016 15:32:05',
    system_object_id: '776',
    ALFA3: 'TON',
    SHORTNAME: 'ТОНГА',
    FULLNAME: 'Королевство Тонга',
    ALFA2: 'TO',
    CODE: '776',
  },
  '780': {
    global_id: 273330141,
    signature_date: '21.09.2016 15:32:04',
    system_object_id: '780',
    ALFA3: 'TTO',
    SHORTNAME: 'ТРИНИДАД И ТОБАГО',
    FULLNAME: 'Республика Тринидад и Тобаго',
    ALFA2: 'TT',
    CODE: '780',
  },
  '784': {
    global_id: 273330142,
    signature_date: '21.09.2016 15:32:04',
    system_object_id: '784',
    ALFA3: 'ARE',
    SHORTNAME: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    ALFA2: 'AE',
    CODE: '784',
  },
  '788': {
    global_id: 273330143,
    signature_date: '21.09.2016 15:33:08',
    system_object_id: '788',
    ALFA3: 'TUN',
    SHORTNAME: 'ТУНИС',
    FULLNAME: 'Тунисская Республика',
    ALFA2: 'TN',
    CODE: '788',
  },
  '792': {
    global_id: 273330144,
    signature_date: '21.09.2016 15:33:09',
    system_object_id: '792',
    ALFA3: 'TUR',
    SHORTNAME: 'ТУРЦИЯ',
    FULLNAME: 'Турецкая Республика',
    ALFA2: 'TR',
    CODE: '792',
  },
  '795': {
    global_id: 273330145,
    signature_date: '21.09.2016 15:33:10',
    system_object_id: '795',
    ALFA3: 'TKM',
    SHORTNAME: 'ТУРКМЕНИЯ',
    FULLNAME: 'Туркменистан',
    ALFA2: 'TM',
    CODE: '795',
  },
  '796': {
    global_id: 273330146,
    signature_date: '21.09.2016 15:33:11',
    system_object_id: '796',
    ALFA3: 'TCA',
    SHORTNAME: 'ОСТРОВА ТЕРКС И КАЙКОС',
    ALFA2: 'TC',
    CODE: '796',
  },
  '798': {
    global_id: 273330147,
    signature_date: '21.09.2016 15:33:12',
    system_object_id: '798',
    ALFA3: 'TUV',
    SHORTNAME: 'ТУВАЛУ',
    ALFA2: 'TV',
    CODE: '798',
  },
  '800': {
    global_id: 273330148,
    signature_date: '21.09.2016 15:33:13',
    system_object_id: '800',
    ALFA3: 'UGA',
    SHORTNAME: 'УГАНДА',
    FULLNAME: 'Республика Уганда',
    ALFA2: 'UG',
    CODE: '800',
  },
  '804': {
    global_id: 273330149,
    signature_date: '21.09.2016 15:33:15',
    system_object_id: '804',
    ALFA3: 'UKR',
    SHORTNAME: 'УКРАИНА',
    ALFA2: 'UA',
    CODE: '804',
  },
  '807': {
    global_id: 273330150,
    signature_date: '21.09.2016 15:33:16',
    system_object_id: '807',
    ALFA3: 'MKD',
    SHORTNAME: 'РЕСПУБЛИКА МАКЕДОНИЯ',
    ALFA2: 'MK',
    CODE: '807',
  },
  '818': {
    global_id: 273330151,
    signature_date: '21.09.2016 15:33:17',
    system_object_id: '818',
    ALFA3: 'EGY',
    SHORTNAME: 'ЕГИПЕТ',
    FULLNAME: 'Арабская Республика Египет',
    ALFA2: 'EG',
    CODE: '818',
  },
  '826': {
    global_id: 273330152,
    signature_date: '21.09.2016 15:33:17',
    system_object_id: '826',
    ALFA3: 'GBR',
    SHORTNAME: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    FULLNAME: 'Соединенное Королевство Великобритании и Северной Ирландии',
    ALFA2: 'GB',
    CODE: '826',
  },
  '831': {
    global_id: 273330153,
    signature_date: '21.09.2016 15:34:20',
    system_object_id: '831',
    ALFA3: 'GGY',
    SHORTNAME: 'ГЕРНСИ',
    ALFA2: 'GG',
    CODE: '831',
  },
  '832': {
    global_id: 273330154,
    signature_date: '21.09.2016 15:34:23',
    system_object_id: '832',
    ALFA3: 'JEY',
    SHORTNAME: 'ДЖЕРСИ',
    ALFA2: 'JE',
    CODE: '832',
  },
  '833': {
    global_id: 273330155,
    signature_date: '21.09.2016 15:34:23',
    system_object_id: '833',
    ALFA3: 'IMN',
    SHORTNAME: 'ОСТРОВ МЭН',
    ALFA2: 'IM',
    CODE: '833',
  },
  '834': {
    global_id: 273329432,
    signature_date: '21.09.2016 15:29:07',
    system_object_id: '834',
    ALFA3: 'TZA',
    SHORTNAME: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    FULLNAME: 'Объединенная Республика Танзания',
    ALFA2: 'TZ',
    CODE: '834',
  },
  '840': {
    global_id: 273330156,
    signature_date: '21.09.2016 15:34:22',
    system_object_id: '840',
    ALFA3: 'USA',
    SHORTNAME: 'СОЕДИНЕННЫЕ ШТАТЫ',
    FULLNAME: 'Соединенные Штаты Америки',
    ALFA2: 'US',
    CODE: '840',
  },
  '850': {
    global_id: 273330157,
    signature_date: '21.09.2016 15:34:22',
    system_object_id: '850',
    ALFA3: 'VIR',
    SHORTNAME: 'ВИРГИНСКИЕ ОСТРОВА, США',
    FULLNAME: 'Виргинские острова Соединенных Штатов',
    ALFA2: 'VI',
    CODE: '850',
  },
  '854': {
    global_id: 273330158,
    signature_date: '21.09.2016 15:34:21',
    system_object_id: '854',
    ALFA3: 'BFA',
    SHORTNAME: 'БУРКИНА-ФАСО',
    ALFA2: 'BF',
    CODE: '854',
  },
  '858': {
    global_id: 273330159,
    signature_date: '21.09.2016 15:34:21',
    system_object_id: '858',
    ALFA3: 'URY',
    SHORTNAME: 'УРУГВАЙ',
    FULLNAME: 'Восточная Республика Уругвай',
    ALFA2: 'UY',
    CODE: '858',
  },
  '860': {
    global_id: 273330160,
    signature_date: '21.09.2016 15:35:07',
    system_object_id: '860',
    ALFA3: 'UZB',
    SHORTNAME: 'УЗБЕКИСТАН',
    FULLNAME: 'Республика Узбекистан',
    ALFA2: 'UZ',
    CODE: '860',
  },
  '862': {
    global_id: 273330161,
    signature_date: '21.09.2016 15:35:09',
    system_object_id: '862',
    ALFA3: 'VEN',
    SHORTNAME: 'ВЕНЕСУЭЛА, БОЛИВАРИАНСКАЯ РЕСПУБЛИКА',
    FULLNAME: 'Боливарианская Республика Венесуэла',
    ALFA2: 'VE',
    CODE: '862',
  },
  '876': {
    global_id: 273329433,
    signature_date: '21.09.2016 15:29:08',
    system_object_id: '876',
    ALFA3: 'WLF',
    SHORTNAME: 'УОЛЛИС И ФУТУНА',
    ALFA2: 'WF',
    CODE: '876',
  },
  '882': {
    global_id: 273330162,
    signature_date: '21.09.2016 15:35:10',
    system_object_id: '882',
    ALFA3: 'WSM',
    SHORTNAME: 'САМОА',
    FULLNAME: 'Независимое Государство Самоа',
    ALFA2: 'WS',
    CODE: '882',
  },
  '887': {
    global_id: 273330163,
    signature_date: '21.09.2016 15:35:12',
    system_object_id: '887',
    ALFA3: 'YEM',
    SHORTNAME: 'ЙЕМЕН',
    FULLNAME: 'Йеменская Республика',
    ALFA2: 'YE',
    CODE: '887',
  },
  '894': {
    global_id: 273330164,
    signature_date: '21.09.2016 15:35:12',
    system_object_id: '894',
    ALFA3: 'ZMB',
    SHORTNAME: 'ЗАМБИЯ',
    FULLNAME: 'Республика Замбия',
    ALFA2: 'ZM',
    CODE: '894',
  },
  '895': {
    global_id: 273330165,
    signature_date: '21.09.2016 15:35:36',
    system_object_id: '895',
    ALFA3: 'ABH',
    SHORTNAME: 'АБХАЗИЯ',
    FULLNAME: 'Республика Абхазия',
    ALFA2: 'AB',
    CODE: '895',
  },
  '896': {
    global_id: 273330166,
    signature_date: '21.09.2016 15:35:43',
    system_object_id: '896',
    ALFA3: 'OST',
    SHORTNAME: 'ЮЖНАЯ ОСЕТИЯ',
    FULLNAME: 'Республика Южная Осетия',
    ALFA2: 'OS',
    CODE: '896',
  },
  '040': {
    global_id: 273329414,
    signature_date: '21.09.2016 15:25:59',
    system_object_id: '040',
    ALFA3: 'AUT',
    SHORTNAME: 'АВСТРИЯ',
    FULLNAME: 'Австрийская Республика',
    ALFA2: 'AT',
    CODE: '040',
  },
  '004': {
    global_id: 273330167,
    signature_date: '21.09.2016 15:35:39',
    system_object_id: '004',
    ALFA3: 'AFG',
    SHORTNAME: 'АФГАНИСТАН',
    FULLNAME: 'Переходное Исламское Государство Афганистан',
    ALFA2: 'AF',
    CODE: '004',
  },
  '008': {
    global_id: 273330168,
    signature_date: '21.09.2016 15:36:49',
    system_object_id: '008',
    ALFA3: 'ALB',
    SHORTNAME: 'АЛБАНИЯ',
    FULLNAME: 'Республика Албания',
    ALFA2: 'AL',
    CODE: '008',
  },
  '010': {
    global_id: 273330169,
    signature_date: '21.09.2016 15:36:48',
    system_object_id: '010',
    ALFA3: 'ATA',
    SHORTNAME: 'АНТАРКТИДА',
    ALFA2: 'AQ',
    CODE: '010',
  },
  '012': {
    global_id: 273330170,
    signature_date: '21.09.2016 15:36:47',
    system_object_id: '012',
    ALFA3: 'DZA',
    SHORTNAME: 'АЛЖИР',
    FULLNAME: 'Алжирская Народная Демократическая Республика',
    ALFA2: 'DZ',
    CODE: '012',
  },
  '016': {
    global_id: 273330171,
    signature_date: '21.09.2016 15:36:47',
    system_object_id: '016',
    ALFA3: 'ASM',
    SHORTNAME: 'АМЕРИКАНСКОЕ САМОА',
    ALFA2: 'AS',
    CODE: '016',
  },
  '020': {
    global_id: 273330172,
    signature_date: '21.09.2016 15:36:46',
    system_object_id: '020',
    ALFA3: 'AND',
    SHORTNAME: 'АНДОРРА',
    FULLNAME: 'Княжество Андорра',
    ALFA2: 'AD',
    CODE: '020',
  },
  '028': {
    global_id: 273330174,
    signature_date: '21.09.2016 15:37:47',
    system_object_id: '028',
    ALFA3: 'ATG',
    SHORTNAME: 'АНТИГУА И БАРБУДА',
    ALFA2: 'AG',
    CODE: '028',
  },
  '024': {
    global_id: 273330173,
    signature_date: '21.09.2016 15:37:47',
    system_object_id: '024',
    ALFA3: 'AGO',
    SHORTNAME: 'АНГОЛА',
    FULLNAME: 'Республика Ангола',
    ALFA2: 'AO',
    CODE: '024',
  },
  '032': {
    global_id: 273330176,
    signature_date: '21.09.2016 15:37:49',
    system_object_id: '032',
    ALFA3: 'ARG',
    SHORTNAME: 'АРГЕНТИНА',
    FULLNAME: 'Аргентинская Республика',
    ALFA2: 'AR',
    CODE: '032',
  },
  '031': {
    global_id: 273330175,
    signature_date: '21.09.2016 15:37:48',
    system_object_id: '031',
    ALFA3: 'AZE',
    SHORTNAME: 'АЗЕРБАЙДЖАН',
    FULLNAME: 'Республика Азербайджан',
    ALFA2: 'AZ',
    CODE: '031',
  },
  '044': {
    global_id: 273330178,
    signature_date: '21.09.2016 15:37:51',
    system_object_id: '044',
    ALFA3: 'BHS',
    SHORTNAME: 'БАГАМЫ',
    FULLNAME: 'Содружество Багамы',
    ALFA2: 'BS',
    CODE: '044',
  },
  '048': {
    global_id: 273330179,
    signature_date: '21.09.2016 15:37:51',
    system_object_id: '048',
    ALFA3: 'BHR',
    SHORTNAME: 'БАХРЕЙН',
    FULLNAME: 'Королевство Бахрейн',
    ALFA2: 'BH',
    CODE: '048',
  },
  '036': {
    global_id: 273330177,
    signature_date: '21.09.2016 15:37:50',
    system_object_id: '036',
    ALFA3: 'AUS',
    SHORTNAME: 'АВСТРАЛИЯ',
    ALFA2: 'AU',
    CODE: '036',
  },
  '050': {
    global_id: 273330180,
    signature_date: '21.09.2016 15:38:47',
    system_object_id: '050',
    ALFA3: 'BGD',
    SHORTNAME: 'БАНГЛАДЕШ',
    FULLNAME: 'Народная Республика Бангладеш',
    ALFA2: 'BD',
    CODE: '050',
  },
  '052': {
    global_id: 273330182,
    signature_date: '21.09.2016 15:38:48',
    system_object_id: '052',
    ALFA3: 'BRB',
    SHORTNAME: 'БАРБАДОС',
    ALFA2: 'BB',
    CODE: '052',
  },
  '051': {
    global_id: 273330181,
    signature_date: '21.09.2016 15:38:47',
    system_object_id: '051',
    ALFA3: 'ARM',
    SHORTNAME: 'АРМЕНИЯ',
    FULLNAME: 'Республика Армения',
    ALFA2: 'AM',
    CODE: '051',
  },
  '056': {
    global_id: 273330183,
    signature_date: '21.09.2016 15:38:50',
    system_object_id: '056',
    ALFA3: 'BEL',
    SHORTNAME: 'БЕЛЬГИЯ',
    FULLNAME: 'Королевство Бельгии',
    ALFA2: 'BE',
    CODE: '056',
  },
  '060': {
    global_id: 273330184,
    signature_date: '21.09.2016 15:38:50',
    system_object_id: '060',
    ALFA3: 'BMU',
    SHORTNAME: 'БЕРМУДЫ',
    ALFA2: 'BM',
    CODE: '060',
  },
  '068': {
    global_id: 273330186,
    signature_date: '21.09.2016 15:38:51',
    system_object_id: '068',
    ALFA3: 'BOL',
    SHORTNAME: 'БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО',
    FULLNAME: 'Многонациональное Государство Боливия',
    ALFA2: 'BO',
    CODE: '068',
  },
  '064': {
    global_id: 273330185,
    signature_date: '21.09.2016 15:38:51',
    system_object_id: '064',
    ALFA3: 'BTN',
    SHORTNAME: 'БУТАН',
    FULLNAME: 'Королевство Бутан',
    ALFA2: 'BT',
    CODE: '064',
  },
  '072': {
    global_id: 273330188,
    signature_date: '21.09.2016 15:39:57',
    system_object_id: '072',
    ALFA3: 'BWA',
    SHORTNAME: 'БОТСВАНА',
    FULLNAME: 'Республика Ботсвана',
    ALFA2: 'BW',
    CODE: '072',
  },
  '070': {
    global_id: 273330187,
    signature_date: '21.09.2016 15:39:56',
    system_object_id: '070',
    ALFA3: 'BIH',
    SHORTNAME: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    ALFA2: 'BA',
    CODE: '070',
  },
  '074': {
    global_id: 273330189,
    signature_date: '21.09.2016 15:39:58',
    system_object_id: '074',
    ALFA3: 'BVT',
    SHORTNAME: 'ОСТРОВ БУВЕ',
    ALFA2: 'BV',
    CODE: '074',
  },
  '076': {
    global_id: 273330190,
    signature_date: '21.09.2016 15:39:59',
    system_object_id: '076',
    ALFA3: 'BRA',
    SHORTNAME: 'БРАЗИЛИЯ',
    FULLNAME: 'Федеративная Республика Бразилия',
    ALFA2: 'BR',
    CODE: '076',
  },
  '084': {
    global_id: 273330191,
    signature_date: '21.09.2016 15:40:00',
    system_object_id: '084',
    ALFA3: 'BLZ',
    SHORTNAME: 'БЕЛИЗ',
    ALFA2: 'BZ',
    CODE: '084',
  },
  '086': {
    global_id: 273330192,
    signature_date: '21.09.2016 15:40:01',
    system_object_id: '086',
    ALFA3: 'IOT',
    SHORTNAME: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    ALFA2: 'IO',
    CODE: '086',
  },
  '090': {
    global_id: 273330193,
    signature_date: '21.09.2016 15:41:06',
    system_object_id: '090',
    ALFA3: 'SLB',
    SHORTNAME: 'СОЛОМОНОВЫ ОСТРОВА',
    ALFA2: 'SB',
    CODE: '090',
  },
  '092': {
    global_id: 273330194,
    signature_date: '21.09.2016 15:41:07',
    system_object_id: '092',
    ALFA3: 'VGB',
    SHORTNAME: 'ВИРГИНСКИЕ ОСТРОВА, БРИТАНСКИЕ',
    FULLNAME: 'Британские Виргинские острова',
    ALFA2: 'VG',
    CODE: '092',
  },
  '096': {
    global_id: 273330195,
    signature_date: '21.09.2016 15:41:09',
    system_object_id: '096',
    ALFA3: 'BRN',
    SHORTNAME: 'БРУНЕЙ-ДАРУССАЛАМ',
    ALFA2: 'BN',
    CODE: '096',
  },
};
