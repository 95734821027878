import { call, put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';

import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';

import * as grayAreaSearchGtinsActions from './GrayAreaSearchGtins.actions';

function* requestSearchGtins({ payload }) {
  const requestPayload = {
    url: `/api/v3/facade/product/search-by-gtins`,
    method: 'POST',
    data: payload,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  yield put(
    grayAreaSearchGtinsActions.loaded({
      result: _.get(success, 'data.results', []),
      total: _.get(success, 'data.total', 0),
    }),
  );
}

export default function* watch() {
  yield takeLatest(
    grayAreaSearchGtinsActions.requestSearchGtins,
    requestSearchGtins,
  );
}
