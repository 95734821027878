export const signTheme = {
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: '"SegoeUI", sans-serif',
  large: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '60px',
  },
  normal: {
    paddingLeft: '0px',
    paddingRight: '0px',
    height: '60px',
  },
  main: {
    border: '1px solid rgba(196, 196, 196, 0.25)',
    color: '#52535A',
    background: '#FFF',
    boxShadow: '0',
  },
  disabled: {
    border: '0',
    color: '#FFFFFF',
    background: 'linear-gradient(0deg, #C4C4C4, #C4C4C4);',
  },
  minWidth: '62px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
};