import { Translate } from '../../../common_components/Translate/Translate';

const React = require('react');
import PropTypes from 'prop-types';
import GridParticipantSelect from '../../Select/GridParticipantSelect';
import GridProducerSelect from '../../Select/GridProducerSelect';

import { Wrap, SelectWrap } from '../Grid.styled';

import { gridFilterTheme } from '../../../theme/ru-tabaco/select/gridFilter';
import { ThemeProvider } from 'styled-components';

class ParticipantSelectFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    appliedFilter: PropTypes.object, // filterTerm, operator, filterValue
    close: PropTypes.func.isRequired,
  };

  select;

  constructor(props) {
    super(props);
    this.state = {
      filterTerm:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].filterTerm
          : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
      filterValue:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].filterValue
          : {},
    };

    console.log('ParticipantSelectFilter', this.props);
  }
  handleFilterChange = val => {
    console.log('VAL', val);

    if (val) {
      this.setState({ filterTerm: val.inn, filterValue: val });
    } else {
      this.setState({ filterTerm: '', filterValue: {} });
    }
  };

  reset = () => {
    this.setState({ value: '' });
    this.props.onFilterChange(undefined);
  };

  applyFilter = () => {
    //third parameter is filterValue object, need for restored complex filters
    this.props.onFilterChange([
      {
        filterTerm: this.state.filterTerm,
        operator: this.state.operator,
        filterValue: this.state.filterValue,
      },
    ]);
  };

  close = () => {
    //third parameter is filterValue object, need for restored complex filters
    this.props.close();
  };

  setFilter = filter => {
    this.setState({
      value: filter && filter.length > 0 ? filter[0].filterTerm : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    });
  };

  render() {
    let cannotFilterBeDropped =
      this.props.column.filterCannotBeRemoved === true;

    const isProducer = this.props.column.key === 'producerId';

    return (
      <Wrap>
        <SelectWrap>
          <ThemeProvider theme={{ Select: gridFilterTheme }}>
            {isProducer ? (
              <GridProducerSelect
                truncate
                placeholder={this.props.placeholder}
                filterValue={this.state.filterValue}
                onSelect={e => this.handleFilterChange(e)}
              />
            ) : (
              <GridParticipantSelect
                truncate
                placeholder={this.props.placeholder}
                filterValue={this.state.filterValue}
                onSelect={e => this.handleFilterChange(e)}
              />
            )}
          </ThemeProvider>
        </SelectWrap>

        {!cannotFilterBeDropped && (
          <button
            type="button"
            onClick={this.reset}
            className="filter-btn__cancel"
          >
            {Translate('Сбросить')}
          </button>
        )}
        {cannotFilterBeDropped && (
          <button
            type="button"
            onClick={this.close}
            className="filter-btn__cancel"
          >
            {Translate('Закрыть')}
          </button>
        )}
        <button
          type="button"
          onClick={this.applyFilter}
          disabled={!this.state.filterTerm.length}
          className="filter-btn__apply"
        >
          {Translate('Применить')}
        </button>
      </Wrap>
    );
  }
}

export default ParticipantSelectFilter;
