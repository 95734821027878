import React, { Component } from 'react';

import CisInfo from './CisInfo/CisInfo';
import ProductInfo from './ProductInfo/ProductInfo';

import { Wrapper, Column } from './BasicInfo.styled';

class BasicInfo extends Component {
  componentDidMount() {
    import('./BasicInfo.utils')
      .then(QRrendererModule => 
        QRrendererModule.QRrenderer('qr-canvas', this.props.data.cis)
      );
  }

  componentDidUpdate(prevProps){
    if (prevProps.data.cis !== this.props.data.cis) {
      import('./BasicInfo.utils')
        .then(QRrendererModule =>
          QRrendererModule.QRrenderer('qr-canvas', this.props.data.cis)
        );
    }
  }

  render() {
    return (
      <Wrapper>
        <Column><CisInfo {...this.props} /></Column>
        <Column><ProductInfo {...this.props} /></Column>
      </Wrapper>
    );
  }
}

export default BasicInfo;
