import { Button } from '@crpt-ui/core';
import React from 'react';

import { useStyles } from './NoCert.styles';
import { Translate } from '../../../../common_components/Translate/Translate';

export const NoCertView = ({ reloadCert }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <div>
          {Translate('Данные по текущему сертификату не найдены, пожалуйста, обновите информацию')}
        </div>
        <div className={classes.actionRow}>
          <Button onClick={reloadCert} size='sm'>
            {Translate('обновить')}
          </Button>
        </div>
      </div>
    </div>
  );
};
