import React from 'react';
import {withTheme} from 'styled-components';
import ParticipantDetails from '../../components/ParticipantDetails';

class ParticipantDetailsWrapper extends React.Component {
  render() {
    const { config } = this.props.App;

    const Component = config.ParticipantDetails && config.ParticipantDetails.component;

    return Component ? <Component {...this.props} /> : <ParticipantDetails {...this.props} />;
  }
}

export default withTheme(ParticipantDetailsWrapper);
