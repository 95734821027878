import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    left: calc(100% + 50px);
    white-space: nowrap;
    top: ${props => (props.top ? props.top : '14px')};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#FBFBFB')};
    padding: ${props => (props.padding ? props.padding : '5px 10px')};
    ${props => (props.fontSize ? css`font-size: ${props.fontSize}` : null)}
`;
