export const MARKING_CODES_INFO_TYPES_NAMES = {
  emissionType: 'Тип эмиссии',
  productionDate: 'Дата производства',
  expirationDate: 'Дата истечения срока',
  productionSerialNumber: 'Номер производственной серии',
};

export const MARKING_CODES_INFO_TYPES = {
  EMISSION_TYPE: 'emissionType',
  PRODUCTION_DATE: 'productionDate',
  EXPIRATION_DATE: 'expirationDate',
  PRODUCTION_SERIAL_NUMBER: 'productionSerialNumber',
};
