import { Modal, MenuButton } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import * as Icons from '@crpt-ui/icons';
import { Box, Grid, MuiThemeProvider } from '@material-ui/core';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// import LightTheme from '../../../common_components/_MaterialUi/LightTheme';
import Content from '../../../common_components/Content/Content';
import EmptyList from '../../../common_components/EmptyList/EmptyList';
// import MenuButton from '../../../common_components/MenuButton/MenuButton';
import Preloader from '../../../common_components/Preloader/Preloader';
import { getOrgRoles } from '../../../utils/userUtils';
import {
  hardcodeRoles,
  periodicityEnum,
  statusEnum,
  statusValuesMap,
  taskTypeEnum,
  taskTypeStatusEnum,
} from '../constants';
import { alphabetSort, isOperator, rangeDateFilter } from '../_utils/utils';
import * as unloadServiceCreateSelectors from '../UnloadServiceCreate/ducks/UnloadServiceCreate.selectors';
import UnloadServiceCreate from '../UnloadServiceCreate/UnloadServiceCreate';
import * as unloadServiceListActions from './ducks/UnloadServiceList.actions';
import * as unloadServiceListSelectors from './ducks/UnloadServiceList.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import { useColumns } from './UnloadServiceList.columns';
import { useStyles } from './UnloadServiceList.styles';
import { Redirect } from 'react-router-dom';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';
import { Translate } from '../../../common_components/Translate/Translate';

const UnloadServiceList = ({
  availableOrgRoles,
  loaded,
  location,
  onMounted,
  onUnmount,
  onGetData,
  onPutNewStatus,
  tasks,
  taskTypes,
  unloadServiceCreateMessage,
 isUserOGV
}) => {
  //this is temporal hack to disable unload service for OGV
  // cause OGV have 2 roles now: OPERATOR and OGV.
  // by role operator user have access to this route
  // but this is the hack on the backend...
  if(isUserOGV) {
    return (
      <Redirect to="/404" />
    )
  }
  const classes = useStyles();
  const instance = useRef();
  const [selectedRows, setSelectedRows] = useState({});

  const queryParams = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);
  const querySystemName = useMemo(() => queryParams.get('systemName'), [
    queryParams,
  ]);

  const initialState = useMemo(() => ({
    sortBy: [
      {
        id: 'createDate',
        desc: true,
      },
    ],
  }), []);

  useEffect(() => {
    onMounted();
    return () => onUnmount();
  }, []);

  const currentStatusFilterOptions = useMemo(
    () =>
      _.chain(statusValuesMap)
        .map((label, value) => ({
          label: label,
          value: value,
        }))
        .value(),
    [],
  );

  const systemNameFilterOptions = useMemo(() => {
    const raw = _.chain(tasks)
      .map(row => row.systemName)
      .uniq()
      .map(value => ({
        label: value,
        value: value,
      }))
      .value();

    return querySystemName &&
      !raw.some(option => option.value === querySystemName)
      ? [
          ...raw,
          {
            label: querySystemName,
            value: querySystemName,
          },
        ]
      : [...raw];
  }, [tasks]);

  const showForUser = ['name', 'systemName', 'createDate', 'currentStatus'];

  const allOrgRoles = useMemo(() => [...availableOrgRoles, ...hardcodeRoles], [
    availableOrgRoles,
  ]);

  const columns = useColumns({
    allOrgRoles,
    currentStatusFilterOptions,
    isOperator: isOperator(),
    showForUser,
    systemNameFilterOptions,
  });

  const rowClick = useCallback((_event, row) => {
    const id = _.get(row, 'original.id');
    const locationOrigin = window.location.origin;

    if (id) {
      window.open(`${locationOrigin}/unload-service/list/${encodeURI(id)}`);
    }
  }, []);

  const makeCallback = action => (...rows) => {
    makeAction({
      action: action,
      result_ids: rows.map(row => row.original.id),
    });
  };

  const actions = useMemo(
    () => [
      {
        label: Translate('Отменить'),
        callback: makeCallback(statusEnum.CANCELED),
        disabled: row =>
          row.original.periodicity === periodicityEnum.SINGLE ||
          row.original.currentStatus === statusEnum.CANCELED ||
          row.original.readonly,
      },
      {
        label: Translate('Архивировать'),
        callback: makeCallback(statusEnum.ARCHIVE),
        disabled: row =>
          row.original.periodicity === periodicityEnum.SINGLE ||
          row.original.currentStatus === statusEnum.ARCHIVE ||
          row.original.readonly,
      },
    ],
    [],
  );

  const bulkActions = useMemo(() => {
    const selectedFlatRows = _.get(instance, 'current.selectedFlatRows', []);

    return actions.map(action => ({
      onClick: () => action.callback(...selectedFlatRows),
      disabled: selectedFlatRows.some(action.disabled),
      label: action.label,
    }));
  }, [actions, selectedRows, instance.current]);

  const bulkActionsDisabled = useMemo(() => _.keys(selectedRows).length === 0, [
    selectedRows,
  ]);

  const makeAction = useCallback(
    ({ action, result_ids }) => {
      onPutNewStatus({
        action,
        ids: result_ids,
      });
    },
    [onPutNewStatus],
  );

  // устанавливаем selectedRows в значение по умолчанию
  useEffect(() => {
    setSelectedRows({});
  }, [tasks]);

  const [isModalOpen, setModal] = useState(false);
  const [serviceCreateProps, setServiceCreateProps] = useState({});

  const setModalOpen = () => setModal(true);
  const setModalClose = () => setModal(false);

  const currentPg = useMemo(() => 'tobacco', []);
  const orgRoles = useMemo(() => getOrgRoles(), []);

  const filterByTaskTypeStatus = taskTypes =>
    taskTypes.filter(taskType => taskType.taskTypeStatus === taskTypeStatusEnum.ACTIVE);

  const filterByReport = taskTypes =>
    taskTypes.filter(taskType => taskType.id !== taskTypeEnum.REPORT);

  const filterByPg = taskTypes =>
    taskTypes.filter(taskType => {
      const productGroups = taskType.productGroups.map(
        productGroup => productGroup.name,
      );
      return productGroups.includes(currentPg);
    });

  const filterByRoles = taskTypes =>
    taskTypes.filter(taskType => {
      const roles = taskType.roles.map(role => role.role);
      return !!_.intersection(orgRoles, roles).length;
    });

  const makeOptions = taskTypes =>
    taskTypes.map(taskType => ({
      label: taskType.name,
      onClick: () => {
        setServiceCreateProps({
          systemName: taskType.name,
          taskTypeId: taskType.id,
          availablePeriods: taskType.periods.map(periods => periods.name),
        });
        setModalOpen();
      },
    }));

  const newTaskActions = useMemo(
    () =>
      compose(
        makeOptions,
        filterByRoles,
        filterByPg,
        filterByReport,
        filterByTaskTypeStatus,
      )(taskTypes),
    [taskTypes, orgRoles, currentPg],
  );

  useEffect(() => {
    if (unloadServiceCreateMessage === 'task created') {
      onGetData();
    }
  }, [unloadServiceCreateMessage]);

  useEffect(() => {
    if (instance.current) {
      for (let item of queryParams.entries()) {
        instance.current.setFilter(item[0], [item[1]]);
      }
    }
  }, [tasks, queryParams]);

  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <Preloader isClosed={loaded} isOpened={!loaded} />
      {loaded && (
        <div className={classes.wrapper}>
          <Box mb={2}>
            <Grid container spacing={2}>
              {!!newTaskActions.length && (
                <Grid item>
                  <MenuButton
                    items={newTaskActions}
                    icon={<Icons.Plus />}
                    disableChevron
                  >
                    {Translate('Новое задание')}
                  </MenuButton>
                </Grid>
              )}

              <Grid item>
                <MenuButton
                  items={bulkActions}
                  disabled={bulkActionsDisabled}
                  variant="outlined"
                  width={200}
                >
                  {Translate('Действие')}
                </MenuButton>
              </Grid>
            </Grid>
          </Box>

          <Content>
            {tasks.length ? (
              <React.Fragment>
                <Datatable
                  actions={actions}
                  ref={instance}
                  columns={columns}
                  data={tasks}
                  manualFilters={false}
                  useRowSelect
                  onRowSelect={setSelectedRows}
                  useFilters
                  usePagination
                  useSort
                  initialState={initialState}
                  onRowClick={rowClick}
                  filterTypes={{
                    rangeDateFilter,
                  }}
                  sortTypes={{
                    alphabetSort,
                  }}
                />

                <Grid container justify="flex-end" alignItems="center">
                  <Grid item>
                    <div className={classes.tasksAmount}>
                      {Translate('Всего заданий:')} {tasks.length}
                    </div>
                  </Grid>
                </Grid>

                <Box pb={4} />
              </React.Fragment>
            ) : (
              <EmptyList icon={<Icons.TaskList />}>
                {Translate('Список заданий пуст')}
              </EmptyList>
            )}
          </Content>
        </div>
      )}

      <Modal
        onClose={setModalClose}
        open={isModalOpen}
        fullWidth={true}
        maxWidth="lg"
      >
        <UnloadServiceCreate onClose={setModalClose} {...serviceCreateProps} />
      </Modal>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  isUserOGV: authSelectors.isUserOGV(state),
  availableOrgRoles: unloadServiceListSelectors.availableOrgRoles(state),
  loaded: unloadServiceListSelectors.loaded(state),
  tasks: unloadServiceListSelectors.tasks(state),
  taskTypes: unloadServiceListSelectors.taskTypes(state),
  unloadServiceCreateMessage: unloadServiceCreateSelectors.message(state),
});

const mapDispatchToProps = {
  onGetData: unloadServiceListActions.getData,
  onMounted: unloadServiceListActions.mounted,
  onPutNewStatus: unloadServiceListActions.putNewStatus,
  onUnmount: unloadServiceListActions.unmounted,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnloadServiceList);
