import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { useTranslation } from "react-i18next";
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as actions from './ducks/Aggregation.actions';
import * as selectors from './ducks/Aggregation.selectors';
import * as cadesActions from "../../../../common_components/Eimzo/Eimzo.actions";
import moment from "moment";

const Aggregation = (props) => {
    const { match, countryCode, onSaveDraft, unMounted, onMountCades } = props;
    const { inn, shortName, onLoadDraft, data, onSubmit } = props;
    const { id } = match.params;
    const { t } = useTranslation();

    const initialValues = useMemo(()=> ({
        submissionMethodType: 'RETAIL',
        submissionMethodTypeName: t('Оптово-розничная сеть'),
        dateDoc: moment().format('YYYY-MM-DDThh:mm:ss'),
        participantId: `${inn}, ${shortName}`,
    }),[t, inn, shortName]);

    useEffect(()=> {
        onMountCades();
        return unMounted
    },[unMounted, onMountCades])

    useEffect(()=> {
        if (id !== 'create') onLoadDraft(id.replace(/draft-/, ''))
    }, [id, onLoadDraft])

    const handleOnSubmit = useCallback((values) => {
        onSubmit({
            values: omit(values, ['submissionMethodTypeName', 'invalidCis', 'docType']),
            idDraft: id.replace(/draft-/, '')
        });
    },[onSubmit, id]);

    const errorsCount = useCallback(
        ({ formErrors }) => Object.keys(JSON.parse(JSON.stringify(formErrors))).length,
        []
    );

    return (
        <DocumentWrapper
            finalConfirmModal
            needStepWrapper={false}
            needViewActionsOfGoods={false}
            loaded={true}
            rightDraftButton={false}
            step={0}
            thereAreCodes={false}
            match={match}
            isCreate={id === 'create'}
            actionSelectOptions={[]}
            breadcrumbs={[
                {
                    href: '/documents/list',
                    title: Translate('Документы'),
                    id: 1,
                },
                {
                    title: Translate('Агрегирование'),
                    id: 2,
                    ...id !== 'create' ? { href: `/documents/list/${id}` } : {},
                },
            ]}
            countryCode={countryCode}
            isLoading={true}
            onSaveDraft={onSaveDraft}
            data={id === 'create'
                ? initialValues
                : {
                    ...data,
                    submissionMethodTypeName: t('Оптово-розничная сеть'),
                }
            }
            docType={'AGGREGATION_DOCUMENT'}
            codesCanBeChanged={false}
            fieldsInfo={InfoFields}
            errorsCountFunc={errorsCount}
            needAWarningAboutTheGoodsRecount={false}
            needCancelButton={true}
            needDraftButton={true}
            onSubmit={handleOnSubmit}
            title='Новый документ агрегации'
            filterPackageTypeForGoods="UNIT"
        />
    )
}

const mapState = state => ({
    data: selectors.data(state),
    countryCode: state.config.countryCode,
    inn: authSelectors.userInn(state),
    shortName: authSelectors.userShortName(state),
    user: authSelectors.userInfo(state),
});

const mapDispatch = dispatch => ({
    onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
    onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
    onSubmit: data => dispatch(actions.submit(data)),
    unMounted: () => dispatch(actions.unmounted()),
    onMountCades: () => dispatch(cadesActions.load()),
});

export default connect(mapState, mapDispatch)(Aggregation);
