import { selector } from '../../utils/selector';

export const translate = state => selector(state, 'App.translate');
export const config = state => selector(state, 'App.config');
export const countryCode = state => selector(state, 'App.config.countryCode');
export const preheader = state => selector(state, 'App.preheader');

export const fromSameSubject = ({ router }) => {
  if (router.location.pathname.indexOf('draft') !== -1) return true;
  if (router.lastLocation.pathname === '/') return true;
  return router.lastLocation.pathname.indexOf(router.location.pathname.replace('/list', '')) !== -1;
};
