import { handleActions } from 'redux-actions';
import * as actions from './LoginFormKep.actions';


const errors = handleActions({
  [actions.mounted]: () => null,
  [actions.errors]: (state, { payload }) => payload || null,
}, null);

const submitDisabled = handleActions({
  [actions.submit]: () => true,
  [actions.errors]: () => false,
}, false);

export default {
  errors,
  submitDisabled,
};
