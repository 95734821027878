import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';
import defaultTheme from '../theme';

const StyledPlaceholder = styled.div`
  display: ${({ isFocused, isSaved }) =>
    isFocused && !isSaved ? 'none' : 'block'};
  position: absolute;
  line-height: ${props => props.height};
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  left: 0;
  font-family: ${props => props.fontFamily};
  top: ${props => props.top};
  transform: translateY(-50%);
  transition: all 0.3s ease;
  max-width: 100%;
`;

export const Placeholder = ({
  theme: themeFromProps,
  disabled,
  isError,
  isFocused,
  isSaved,
  children,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;

  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect ? themeFromProps.Select : {},
  );

  const theme = getThemeAsPlainObjectByKeys(merged);

  const mergedPlaceholder = innerMerge(
    {},
    defaultTheme.Select.Placeholder,
    (themeFromPropsHasSelect && themeFromProps.Select.Placeholder) || {},
  );

  const key = disabled ? 'disabled' : isError ? 'error' : 'main';

  Object.assign(
    theme,
    getThemeAsPlainObjectByKeys(
      mergedPlaceholder,
      key,
      isFocused ? 'focused' : 'normal',
    ),
  );

  return (
    <StyledPlaceholder
      {...theme}
      isFocused={isFocused}
      isSaved={isSaved}
    >
      {children}
    </StyledPlaceholder>
  );
};

Placeholder.propTypes = {
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      Placeholder: PropTypes.object,
    }),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};
