import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  statusWrapper: {
    display:'flex',
    alignItems: 'center',
    padding:'10px',
    borderRadius: '10px'
  },
  statusIcon: {
    marginRight:'15px'
  },
  statusText: {
    fontWeight: '600'
  }
}));