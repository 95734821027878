import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { Translate } from 'src/common_components/Translate/Translate';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Уведомление о ввозе товаров (ЕАЭС)'),
    id: 2,
  },
];

export const breadcrumbsThirdCountry = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Уведомление о ввозе товаров (третьи страны)'),
    id: 2,
  },
];

export const cisKey = 'cis';
export const goodsKey = 'products';
export const importThirdKG = 'import_third_kg';

export const tabsKZ = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
  { id: 2, label: Translate('Шаг 3. Итоги') },
];

export const tabsKG = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
  { id: 2, label: Translate('Шаг 3. Коды') },
];

export const steps = {
  info: 0,
  goods: 1,
  cis: 2,
};

export const documentNameOptions = [
  {
    label: Translate('Договор (контракт)'),
    value: Translate('Договор (контракт)'),
  },
  {
    label: Translate('Транспортная накладная'),
    value: Translate('Транспортная накладная'),
  },
  {
    label: Translate('Счет-фактура'),
    value: Translate('Счет-фактура'),
  },
];

export const contentBodyConfirm = (
  <div>
    {Translate('Вы действительно хотите отменить отправку?')}
    <br />
    {Translate('Все введенные данные будут потеряны')}
  </div>
);

export const importTransitFinishStatuses = [4, 16, 17, 18, 19];
