import { css } from 'styled-components';

import TableInput from './TableInput';
import TableSelect from './TableSelect';

const { style: inputStyle } = TableInput;
const { style: selectStyle } = TableSelect;

const TableHeader = {
  style: css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    user-select: none;

    &.-headerGroups {}

    &.-filters {
      input {
        ${inputStyle}
      }

      select {
        ${selectStyle}
      }

      .rt-th {}
    }

    &.-header {}

    .rt-tr {}

    .rt-th,
    .rt-td {}

    .rt-resizable-header {
      overflow: visible;

      &:last-child {
        overflow: hidden;
      }
    }

    .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  `,
};

export default TableHeader;
