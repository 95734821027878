import React from 'react';
import {
  ContentWrapper,
  HeaderWrapper, ModalClose,
  TextHeaderWrapper,
} from '../../../../common_components/Modals/ConfirmModal/ConfirmModal.styled';
import { Dialog } from '@material-ui/core';
import { Translate } from '../../../../common_components/Translate/Translate';
import {StyledLink, ListItem} from './Esf.styled'

export const CorrectionEsfModal = ({ open, onClose, list }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <HeaderWrapper>
        <TextHeaderWrapper>{Translate('Корректирующие ЭСФ')}</TextHeaderWrapper>
        <ModalClose onClick={onClose}>✖</ModalClose>
      </HeaderWrapper>
      <ContentWrapper>
        <ul style={{ margin: 0, padding: 0}}>
          {
            list && list.map(
              (item) => (
                <ListItem>
                  <StyledLink to={`/documents/list/${item.id}`} target="_blank">
                    {`${item.type} ${item.number} от ${item.date}`}
                  </StyledLink>
                </ListItem>
              )
            )
          }
        </ul>
      </ContentWrapper>
    </Dialog>
  )
};
