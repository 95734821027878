import React from 'react';
import { Icon } from '@crpt/react-icon';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const SignatureButton = props => (
  <Button variant="contained" {...props}>
    <Icon type="chat-send-arrow" spacingRight={16} fill={props.icon.fill} style={{ paddingTop: '4px' }} />
    {props.textbutton}
  </Button>
);

SignatureButton.propTypes = {
  textbutton: PropTypes.string,
  icon: PropTypes.shape({}),
};

SignatureButton.defaultProps = {
  textbutton: undefined,
  icon: {},
};

export default SignatureButton;
