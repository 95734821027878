import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Excise from '../../components/Excise/Excise';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions';
import * as selectors from '../../selectors';


class ExciseBound extends Component {
  render() {
    return (
      <ConnectParts
        component={Excise}
        actions={[actions.Excise, actions.Sidebar]}
        selectors={[selectors.Excise]}
        {...this.props}
      />
    );
  }
}


export default withRouter(ExciseBound);
