import { css } from 'styled-components';

const TableBody = {
  style: css`
    display: flex;
    overflow: visible;

    flex: 99999 1 auto;
    flex-direction: column;
    
    .rt-tr-group {}

    .rt-td {}

    .rt-tr-group:last-child {
      border-bottom: 1px solid rgba(196, 196, 196, 0.25);
    }

    .rt-expandable {
      cursor: pointer;
    }
  `,
};

export default TableBody;
