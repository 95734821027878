import React, { useMemo } from 'react';
import moment from "moment";
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import List from '../../../common_components/List/List';
import { Translate } from "../../../common_components/Translate/Translate";
import {HeadingId} from "../../Details/styled";
import {Title} from "./Codes.constants";
import {documentTypeNames} from "../../../constants";

const columns = [
    {
        id: 'cis',
        accessor: 'cis',
        Header: Translate('Коды'),
    },
]

export const InventoryCodes = ({ selectedDocument }) => {
    const { documentDate, documentId } = selectedDocument;
    const list = useMemo(() =>
            selectedDocument.cises
                ? selectedDocument.cises.map((item, idx) => ({ cis: item, idx: idx + 1 }))
                : [],
        [selectedDocument]);

    return (
        <CrossborderWrap>
            <Title>{Translate(documentTypeNames[selectedDocument.type])} <span>{Translate('от')} {moment(documentDate).format('DD.MM.YYYY')}</span></Title>
            <HeadingId grey>ID {documentId}</HeadingId>
            <List
                defaultPageSize={10}
                data={list}
                columns={columns}
                showPagination={list.length > 10}
            />
        </CrossborderWrap>
    );
};
