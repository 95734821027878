import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ProductDetailsWrapper from '../../containers/Wrappers/ProductDetailsWrapper';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';
import * as selectors from '../../selectors/index';

class DocumentDetailsBound extends Component {
  render() {
    return (
      <ConnectParts
        component={ProductDetailsWrapper}
        actions={[actions.ProductDetails]}
        selectors={[selectors.ProductDetails]}
        {...this.props}
      />
    );
  }
}

export default withRouter(DocumentDetailsBound);
