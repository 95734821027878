import React, { useEffect, useMemo, useState } from 'react';
import DiscrepancyNoticeView from './DiscrepancyNotice.view';
import { breadcrumbs } from './DiscrepancyNotice.constants';
import * as selectors from './ducks/DiscrepancyNotice.selectors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { getActData, getDiscrepancyData } from './DiscrepancyNotice.utils';
import * as actions from './ducks/DiscrepancyNotice.actions';
import Preloader from '../../../../components/Preloader';
import * as selectorsAuth from 'src/selectors/Auth';

const DiscrepancyNotice = ({
  match,
  data,
  history,
  send,
  loadDiscrepancyNotice,
  reject,
  isLoading,
  createCorrectionAct,
  userInfo,
}) => {
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [isCreateDocument, setIsCreateDocument] = useState(false);

  useEffect(() => {
    const isCreation = window.location.pathname.includes('create');
    const isView = window.location.pathname.includes('view');
    setIsViewDocument(isView);
    setIsCreateDocument(isCreation);
    const id = match.params.id;
    if (isCreation) {
      if (!data) {
        const url = id ? `/documents/list/act-in/${id}` : '/documents/list';
        history.push(url);
      }
    } else {
      loadDiscrepancyNotice({ id, isIncome: isView });
    }
  }, []);

  const act = useMemo(() => {
    const id = match.params.id;
    const isCreation = window.location.pathname.includes('create');
    return isCreation ? getActData(data, id) : getDiscrepancyData(data);
  }, [data]);

  const hideButton = useMemo(() => {
    return data && data.data.status !== 2;
  }, data);

  const onCancel = () => {
    if (isViewDocument) {
      history.push(`/documents/list`);
    } else {
      const id = match.params.id;
      history.push(`/documents/list/act-in/${id}`);
    }
  };

  const onClose = () => {
    history.push(`/documents/list`);
  };

  const onReject = () => {
    const id = match.params.id;
    reject(id);
  };

  const onSubmit = () => {
    send(act);
  };

  const createAct = () => {
    createCorrectionAct(act);
  };

  const beCorrectiveActOrNotData = useMemo(() => {
    const links = get(data, 'data.links', null);
    return links
      ? Object.values(links).includes(1100)
      : true
  },[data])

  return isLoading ? (
    <Preloader text="Загрузка данных" allwaysShow />
  ) : (
    <DiscrepancyNoticeView
      data={act}
      breadcrumbs={breadcrumbs}
      onClose={onClose}
      onCancel={onCancel}
      onReject={onReject}
      onSubmit={onSubmit}
      createAct={createAct}
      isView={isViewDocument}
      isCreate={isCreateDocument}
      hideButton={hideButton}
      isCreation={isCreateDocument}
      isLoading={isLoading}
      userInfo={userInfo}
      beCorrectiveActOrNotData={beCorrectiveActOrNotData}
    />
  );
};

const mapState = state => ({
  data: selectors.draft(state),
  isLoading: selectors.isLoading(state),
  userInfo: selectorsAuth.commonUserInfo(state),
});

const mapDispatch = dispatch => ({
  send: data => dispatch(actions.send(data)),
  reject: id => dispatch(actions.cancel(id)),
  createCorrectionAct: data => dispatch(actions.createCorrectionAct(data)),
  loadDiscrepancyNotice: data => dispatch(actions.loadDiscrepancyNotice(data)),
});

export default compose(
  withRouter,
  connect(mapState, mapDispatch),
)(DiscrepancyNotice);
