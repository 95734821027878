import React, { Component } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { animationData } from './Preloader.constans';
import PreloaderView from './Preloader.view';
import { loading } from './ducks/Preloader.selectors';
import { Translate } from "../Translate/Translate";

export class Preloader extends Component {
  static propTypes = {
    isOpened: PropTypes.bool,
    isOpen: PropTypes.bool,
    isClosed: PropTypes.bool,
    text: PropTypes.string,
    fullHeight: PropTypes.bool,
  };

  static defaultProps = {
    isOpened: false,
    isOpen: false,
    isClosed: false,
    text: 'Загрузка списка',
    fullHeight: true,
  };

  componentDidMount() {
    const containers = document.querySelectorAll('#lottie');
    [].forEach.call(containers, c => {
      const params = {
        container: c,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      };
      lottie.loadAnimation(params);
    });
  }

  render() {
    const { isOpen, isOpened, isClosed, text, ...props } = this.props;

    let isItOpen;
    if (isClosed) isItOpen = false;
    else {
      isItOpen = isOpen || isOpened;
    }
    const translatedText = typeof text === 'string' ? Translate(text) : text
    return <PreloaderView isOpen={isItOpen} text={translatedText} {...props} />;
  }
}

export default connect(state => ({
  isOpen: loading(state),
}))(Preloader);
