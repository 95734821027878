const certificates = state => state.LoginFormKep.certificates;
const language = state => state.LoginFormKep.language;
const loginButtonDisabled = state => state.LoginFormKep.loginButtonDisabled;
const certificateSelectDisabled = state => state.LoginFormKep.certificateSelectDisabled;


export {
  certificates,
  language,
  loginButtonDisabled,
  certificateSelectDisabled
};
