import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import ParticipantSidebarContent from '../../components/ParticipantSidebarContent';
import ConnectParts from '../ConnectParts';
import * as selectors from '../../selectors/index';


class ParticipantSidebarContentBound extends Component {
  render() {
    return (
      <ConnectParts
        component={ParticipantSidebarContent}
        actions={[]}
        selectors={[selectors.ParticipantDetails]}
        {...this.props}
      />
    );
  }
}

export default withRouter(ParticipantSidebarContentBound);