import styled from 'styled-components';


export const ModalCaption = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  padding-left: 32px;
  color: #212c42;
  margin-top: 32px;
  margin-bottom: 30px;
`;
