import { get } from 'lodash';

function replaceCommasWithDots(str) {
  return ('' + str).replace(/,/g, '.');
}

export function rubelsToKopecksInProducts(products = []) {
  return products.map(product => ({
    ...product,
    custom_cost: +(
      +replaceCommasWithDots(get(product, 'custom_cost', 0)) * 100
    ).toFixed(0),
    item_price: +(
      +replaceCommasWithDots(get(product, 'item_price', 0)) * 100
    ).toFixed(0),
    mrp: +(+replaceCommasWithDots(get(product, 'mrp', 0)) * 100).toFixed(0),
    cis_tax: +(
      +replaceCommasWithDots(get(product, 'cis_tax', 0)) * 100
    ).toFixed(0),
    vat_tax: +(
      +replaceCommasWithDots(get(product, 'vat_tax', 0)) * 100
    ).toFixed(0),
    //currency_rate не переводим в копейки, т.к. курс валюты не является денежным значением MOTP-4679
    //но необходимо заменить запятые на точки, поэтому остальную часть оставляем
    currency_rate: +(+replaceCommasWithDots(
      get(product, 'currency_rate', 0),
    )).toFixed(4),
  }));
}

export function kopecksToRubelsInProducts(products = []) {
  return products.map(product => ({
    ...product,
    custom_cost: (
      +replaceCommasWithDots(get(product, 'custom_cost', 0)) / 100
    ).toFixed(2),
    item_price: (
      +replaceCommasWithDots(get(product, 'item_price', 0)) / 100
    ).toFixed(2),
    mrp: (+replaceCommasWithDots(get(product, 'mrp', 0)) / 100).toFixed(2),
    cis_tax: (+replaceCommasWithDots(get(product, 'cis_tax', 0)) / 100).toFixed(
      2,
    ),
    vat_tax: (+replaceCommasWithDots(get(product, 'vat_tax', 0)) / 100).toFixed(
      2,
    ),
    //currency_rate не переводим в рубли, т.к. курс валюты не является денежным значением MOTP-4679
    //но необходимо заменить запятые на точки, поэтому остальную часть оставляем
    currency_rate: (+replaceCommasWithDots(
      get(product, 'currency_rate', 0),
    )).toFixed(4),
  }));
}

export const kopecksToRubels = val => {
  return val ? (+replaceCommasWithDots(val) / 100).toFixed(2) : val;
};

export const rubelsToKopecks = val => {
  return val ? Math.ceil(+replaceCommasWithDots(val) * 100) : val;
};

export function numbersToStrings(products = []) {
  return products.map(product => ({
    ...Object.keys(product).reduce((obj, key) => {
      if (typeof product[key] === 'number' && !isNaN(product[key]))
        obj[key] = '' + product[key];
      else if (typeof product[key] === 'number' && isNaN(product[key]))
        obj[key] = 0;
      else obj[key] = product[key];
      return obj;
    }, {}),
  }));
}

export function formatAddZeros(value, name) {
  const fixed =
    name && name.length && name.indexOf('currency_rate') !== -1 ? 4 : 2;
  // eslint-disable-next-line
  return /^[\d]+[\.,]?[\d]{0,9}$/.test(value)
    ? `${(+value.replace(',', '.')).toFixed(fixed)}`
    : value;
}

export const getListData = (results, selectedRows, userInfo) => {
  const list = results.map(item => {
    const documents = get(item, 'documents', []);
    const find = documents.find(doc => doc.id === item.id);

    return {
      ...item,
      type: find ? find.type : item.type,
      status: find ? find.status : item.status,
      number: find ? find.number : item.number,
      id: find ? find.id : item.id,
    };
  });
  selectedRows.forEach(key => {
    const id = key.replace('select-', '');
    const find = list.find(item => item.id === id);
    if (find) {
      const idx = list.findIndex(item => item.id === id);
      const documents = get(find, 'documents', []);
      const discrepancy = documents.find(item => [1005].includes(item.type));
      const correction = documents.find(item =>
        [1100, 1300].includes(item.type),
      );
      if (correction && correction.status > 0) {
        const data = getRowData(correction, userInfo);
        list.splice(idx + 1, 0, {
          ...data,
          type: find.type === 1000 ? 1100 : 1300,
          recipient: {
            name: get(find, 'recipient.name', '-'),
          },
        });
      }
      if (discrepancy) {
        const data = getRowData(discrepancy, userInfo);
        list.splice(idx + 1, 0, {
          ...data,
          type: 1005,
          recipient: {
            name: get(userInfo, 'company.shortName', '-'),
          },
        });
      }
    }
  });

  return list;
};

const getRowData = (item, userInfo) => ({
  id: item.id,
  number: item.number,
  date: item.date,
  created_at: item.processed_at,
  status: item.status,
  documents: [],
  hiddenToggle: true,
  sender: {
    name: get(userInfo, 'company.shortName', '-'),
  },
});
