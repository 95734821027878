import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import Card from '@crpt/react-card';
import {
  Blank,
  CloseButton,
  Container,
  Content,
  EnterText,
  Header,
  HeaderText,
  LightLink,
  Link,
  ListItem,
  Number,
  Text,
  DefaultLink,
} from './SidebarContent.styled';

const SidebarContentView = ({ theme, onClose, documentLink }) => (
  <Card theme={theme}>
    <Header>
      <HeaderText>Как войти</HeaderText>
      <CloseButton>
        <Icon type="cross" width={24} height={24} onClick={onClose} />
      </CloseButton>
    </Header>
    <EnterText>
      Вход в систему осуществляется с помощью электронной подписи. Сертификат
      электронной подписи можно получить в одном из аккредитованных Минкомсвязью
      России{' '}
      <DefaultLink
        target={'_blank'}
        href={
          'https://minsvyaz.ru/ru/activity/govservices/2/#section-list-of-accredited-centers'
        }
      >
        удостоверяющих центров
      </DefaultLink>
      .
    </EnterText>
    <Container>
      <ListItem>
        <Number>1</Number>
        <Content>
          Установите плагины для работы с электронной подписью.{' '}
          <DefaultLink
            href={'https://www.cryptopro.ru/products/cades/plugin/'}
            target={'_blank'}
          >
            КриптоПро ЭЦП Browser plug-in
          </DefaultLink>{' '}
          или{' '}
          <DefaultLink
            href={'https://www.rutoken.ru/support/download/rutoken-plugin/'}
            target={'_blank'}
          >
            Рутокен плагин
          </DefaultLink>
          . После установки перезапустите браузер.
        </Content>
      </ListItem>
      <ListItem marginBottom="12px">
        <Number>2</Number>
        <Content>
          Включите доступ к плагину в настройках своего браузера.
          <div>&nbsp;</div>
        </Content>
      </ListItem>
      <ListItem>
        <Number>3</Number>
        <Content>
          Присоедините к компьютеру носитель ключа электронной подписи, это
          может быть USB-токен, УЭК или смарт-карта.
        </Content>
      </ListItem>
      <ListItem>
        <Number>4</Number>
        <Content>
          Если вы являетесь производителем или организацией оптовой торговли при
          первом входе необходимо{' '}
          <DefaultLink
            href={`${window.location.protocol}//markirovka.crpt.ru/register`}
          >
            зарегистрироваться
          </DefaultLink>
        </Content>
      </ListItem>
    </Container>
    <Text>
      {documentLink ? (
        <Link target="_blank" rel="noopener noreferrer" href={documentLink}>
          {'Скачать полную инструкцию в формате PDF'}
        </Link>
      ) : null}
    </Text>
  </Card>
);

SidebarContentView.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  documentLink: PropTypes.string,
};

SidebarContentView.defaultProps = {
  documentLink: null,
};

export default SidebarContentView;
