import { compose, branch, renderNothing, withProps, renderComponent, withHandlers } from 'recompact';
import { RegistrationSteps } from '../../constants';
import FilledStep from './FilledStep';
import CurrentStep from './CurrentStep';


export default compose(
  branch(({ step }) => step === RegistrationSteps.COMPLETE, renderNothing),
  withProps(({ values, errors, step }) => {
    return ({
      name: values.name || '',
      phone: values.phone || '',
      email: values.email || '',
      canNext: !!(values.email && !errors.email),
      step: step,
    })
  }),
  withHandlers({
    onNext: ({ onNext, email, phone }) => () => onNext({ email, phone }),
  }),
)(CurrentStep);
