import { createAction } from 'redux-actions';

export const subject = '[Appeals]';

export const fetchAuthenticateSun = createAction(`${subject} fetchAuthenticateSun`);

export const fetchViolation = createAction(`${subject} fetchViolation`);
export const loadedViolation = createAction(`${subject} loadedViolation`);


export const fetchGoodsGroups = createAction(`${subject} fetchGoodsGroups`);
export const loadedGoodsGroups = createAction(`${subject} loadedGoodsGroups`);


export const fetchTerritorialUnits = createAction(`${subject} fetchTerritorialUnits`);
export const loadedTerritorialUnits = createAction(`${subject} loadedTerritorialUnits`);


export const fetchViolationCategories = createAction(`${subject} fetchViolationCategories`);
export const loadedViolationCategories = createAction(`${subject} loadedViolationCategories`);


export const fetchViolationTypes = createAction(`${subject} fetchViolationTypes`);
export const loadedViolationTypes = createAction(`${subject} loadedViolationTypes`);
