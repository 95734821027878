import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as AutocompleteCRPT } from '@crpt-ui/core';
import * as _ from 'lodash';

import { useStyles } from './AutocompleteMultiple.styles';

import FieldHoc from 'src/common_components/_refactor/Form/FieldHoc';

const AutocompleteMultiple = ({ input, meta, ...fieldRest }) => {
  const classes = useStyles();

  const {
    placeholder,
    errorPlaceholder,
    isError,
    options,
    multiple,
    ...rest
  } = fieldRest;

  const [sortedOptions, setSortedOptions] = useState(options);

  useEffect(() => {
    return () => input.onChange();
  }, []);

  const newOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        selected: Boolean(input.value.includes(option.value)),
      })),
    [options, input.value],
  );

  /**
   * Sorting the list after the selection window closes
   */
  useEffect(() => {
    if (!meta.active) {
      const sortedNewOptions = newOptions.sort(
        (a, b) => b.selected - a.selected,
      );

      setSortedOptions(sortedNewOptions);
    }
  }, [meta.active, newOptions]);

  const onChangeHandle = useCallback(
    (event, option) => {
      const values = option.map((item) => item.value);

      input.onChange(values);
    },
    [input],
  );

  const propsValue = useMemo(
    () =>
      _.isArray(input.value)
        ? sortedOptions.filter((item) => input.value.includes(item.value))
        : [],
    [input.value, sortedOptions],
  );

  const error = isError(meta);

  return (
    <AutocompleteCRPT
      {...input}
      onChange={onChangeHandle}
      label={placeholder}
      error={error}
      value={propsValue}
      options={sortedOptions}
      multiple={multiple}
      classes={classes}
      {...rest}
    />
  )
};

AutocompleteMultiple.propTypes = {
  isError: PropTypes.func,
  multiple: PropTypes.bool,
  meta: PropTypes.object,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.array,
  }),
};

AutocompleteMultiple.defaultProps = {
  isError: (meta) => meta.error,
  options: [],
};

export default FieldHoc(AutocompleteMultiple);
