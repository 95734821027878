import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Select } from '@crpt-ui/core';
import format from '@crpt-ui/core/lib/DatePicker/utils/format.i18n';
import { useTranslation } from 'react-i18next';

const YEAR_INTERVAL = 4;
const START_YEAR = 1980;
const CURRENT_YEAR = new Date().getFullYear();
const END_YEAR = CURRENT_YEAR + YEAR_INTERVAL;

const Controls = memo(({ goToMonth, month = 0, year = CURRENT_YEAR, startYear = START_YEAR, endYear = END_YEAR }) => {
  const {t} = useTranslation()

  const years = useMemo(
    () => [
      {
        label: startYear + '',
        value: startYear + '',
      },
      ...Array.from(Array(endYear - startYear).keys()).map(i => ({
        label: startYear + i + 1 + '',
        value: startYear + i + 1 + '',
      })),
    ],
    [startYear, endYear],
  );

  const months = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => {
        const formattedLabel = format(new Date(year, i, 6), 'LLLL', 'ru');

        return {
          label: t(
            formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1),
          ),
          value: i,
        };
      }),
    [year],
  );

  const onChangeDate = (value, type) => {
    if (type === 'month') {
      goToMonth(new Date(year, value, 5));
    } else if (type === 'year') {
      goToMonth(new Date(value, month, 5));
    }
  };
  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            label={t('Год')}
            options={years}
            value={year}
            onChange={e => onChangeDate(e.target.value, 'year')}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label={t('Месяц')}
            options={months}
            value={month}
            onChange={e => onChangeDate(e.target.value, 'month')}
          />
        </Grid>
      </Grid>
    </div>
  );
});

Controls.propTypes = {
  goToMonth: PropTypes.func,
  month: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  year: PropTypes.number,
  startYear: PropTypes.number,
  yearsAfter: PropTypes.number,
};

export default Controls;
