import styled from 'styled-components';


export const SidebarInfo = styled.div`
  color: ${props => (props.theme.Link && props.theme.Link.color) || '#55B5E7'};
  font-size: 16px;
  margin-left: 56px;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  color: #ff3c21;
`;
