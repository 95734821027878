import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './ducks/LoginFormKep.actions';
import LoginFormKepView from './LoginFormKep.view';
import { getTheme } from './ducks/LoginFormKep.selectors';

class LoginFormKep extends React.Component {
  static propTypes = {
    onMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    theme: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { path },
    } = this.props;
    const step =
      path.replace('/', '') === 'check-status' ? 'checkStatus' : 'register';

    this.state = {
      step,
    };
  }

  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  onClick = step => () => {
    this.setState({ step });
  };

  render() {
    const { theme, onSubmit } = this.props;
    const { step } = this.state;

    return (
      <LoginFormKepView
        theme={theme}
        onSubmit={onSubmit}
        onClick={this.onClick}
        step={step}
      />
    );
  }
}

const mapState = state => ({
  theme: getTheme(state),
});

const mapDispatch = dispatch => ({
  onMount: () => dispatch(actions.mounted()),
  onSubmit: data => dispatch(actions.submit(data)),
});

export default connect(
  mapState,
  mapDispatch,
)(LoginFormKep);
