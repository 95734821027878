import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentWrapper } from '../../../common_components/styled/index';
import * as actions from './ducks/RequestList.actions';
import List from '../../../common_components/List/List';
import FileNotify from '../../../common_components/FileNotify/FileNotify';
// import HighloadPagination from '../../../common_components/List/HightloadPagination/HighloadPagination';
import Pagination from '../../../common_components/List/Pagination/Pagination';
import Preloader from '../../../common_components/Preloader/Preloader';
import ActionBar from '../../../common_components/ActionBar/ActionBar';
import CommandBar from '../../../common_components/CommandBar/CommandBar';
import DownloadTooltip from './DownloadTooltip/DownloadTooltip';

// import { makeShortName } from '../../../common_components/PageHeader/PageHeader.utils';

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin: auto 36px 0; */
  margin: ${({ isPathCheck }) =>
    isPathCheck ? '-26px 36px 0' : 'auto 36px 0'};
`;

const RequestListView = props => {
  const {
    results,
    pagesCount,
    selectedPage,
    columns = [],
    options,
    getTdProps,
    getTrProps,
    getTheadThProps,
    actionBarLeft,
    actionBarRight,
    actionBarBottom,
    notifyErrors,
    onNotifyAccept,
    onNotifyCancel,
    isLoading,
    cellEvent,
    last,
    onRef,
    onDownloadClick,
    isUserOGV,
    meta,
    pageCounterInvisible,
  } = props;

  const columnsList = useMemo(() => {
    if (isUserOGV) {
      return columns.filter(({ id }) => id !== 'total');
    }

    return columns;
  }, [columns]);

  const isPathCheck = window.location.pathname.includes('/participants/list/');

  return (
    <ContentWrapper
      innerRef={onRef}
      data-selector={'request-list-relative-container'}
      theme={
        isPathCheck && {
          Content: { backgroundColor: 'white', marginTop: '-10px' },
        }
      }
    >
      <FileNotify
        errors={notifyErrors}
        onAccept={onNotifyAccept}
        onCancel={onNotifyCancel}
      />
      <ActionBar left={actionBarLeft} right={actionBarRight} />
      <Preloader />
      <List
        columns={columnsList}
        getTrProps={getTrProps}
        options={options}
        //height='calc(100vh - 267px)'
        data={results}
        getTdProps={getTdProps}
        getTheadThProps={getTheadThProps}
        meta={meta || actions}
        sortable
        hidden={isLoading}
      />
      {/*<FooterWrapper isPathCheck={isPathCheck}>*/}
      <FooterWrapper>
        <CommandBar left={actionBarBottom} flexBasis="40%" />
        <Pagination
          pageCounterInvisible={pageCounterInvisible}
          meta={meta || actions}
          last={last}
          pagesCount={pagesCount}
          selected={selectedPage}
        />
      </FooterWrapper>
      <DownloadTooltip
        onlyIcon
        cellEvent={cellEvent}
        onDownloadClick={onDownloadClick}
      />
    </ContentWrapper>
  );
};

RequestListView.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagesCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getTdProps: PropTypes.func.isRequired,
  getTheadThProps: PropTypes.func.isRequired,
  actionBarLeft: PropTypes.arrayOf(PropTypes.element),
  actionBarRight: PropTypes.arrayOf(PropTypes.element),
  onNotifyAccept: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onNotifyCancel: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  notifyErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  isUserOGV: PropTypes.bool,
  pageCounterInvisible: PropTypes.bool,
};

RequestListView.defaultProps = {
  actionBarLeft: [],
  actionBarRight: [],
  pageCounterInvisible: false,
};

export default RequestListView;
