import { Translate } from '../../../../common_components/Translate/Translate';
import styled from 'styled-components';

export const tabsKZ = [
  { id: 0, label: Translate('Шаг 1. Общая информация'), page: 'info' },
  { id: 1, label: Translate('Шаг 2. Товары'), page: 'goods' },
  { id: 2, label: Translate('Шаг 3. Итоги'), page: 'total' },
];

export const tabsKG = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
  { id: 2, label: Translate('Шаг 3. Коды') },
];

export const ContentWrapper = styled.div`
  display: ${props => props.hide ? 'none' : 'block'};
`;

export const ContentErrorLoading = styled.div`
  margin: auto;
`;

export const FieldsWrapper = styled.div`
  max-width: 89vw;
  min-width: 53vw;
  max-height: 89vh; 
`;

export const invalidCisKey = 'invalidCis';

export const steps = {
  info: 0,
  goods: 1,
  totals: 2,
};
