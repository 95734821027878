import { Toast } from '../../../utils/Toast';
import { call, put, takeEvery } from 'redux-saga/effects';
import Api from '../../Api/Api';
import { get } from 'lodash';
import * as actions from './AddIdentificationCodes.actions';

function* loadSaga(action, state) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn, codesKey } = payload;
  const fileType = get(file, 'type');

  if (!['XLS', 'XLSX'].includes(fileType)) {
    yield put(actions.reset());
    Toast.showError({
      content: `Загружаемый файл не соответствует установленному формату. Загрузите файл формата *.xls, *xlsx`,
    });
    return;
  }

  const data = {
    userId: +userId,
    organisationInn: inn,
  };

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(data)], { type: 'application/json' }),
  );

  formData.append(
    'file',
    new Blob([file.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    file.name,
  );
  const requestPayload = {
    url: '/api/v3/facade/marked_products/search-by-xls-cis-list',
    method: 'post',
    type: 'multipart',
    data: formData,
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);
      if (error) {
        yield put(actions.error(error));
        console.log('error', error);
      } else {
        const data = success.data.valid.results.map((item, i) => {
          return {
            idx: i + 1,
            cis: item.cis,
            name: item.name,
            gtin: item.gtin,
            cisPackageType: item.cisPackageType,
          };
        });
        const invalidData = success.data.invalid;
        formMutators.updateField(codesKey, data);
        formMutators.updateField('invalidCis', invalidData);
        yield call(onCloseModal);
        yield put(actions.reset());
      }
    } catch (e) {
      console.log('loadSaga', e);
      yield put(actions.reset());
      Toast.showError({
        content: `Ответ от сервера не соответствует ожидаемому формату.`,
      });
      console.log(e, { e });
    }
  }
  if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false);

    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
  }
}

export default function* watch() {
  yield takeEvery(actions.convert, loadSaga);
}
