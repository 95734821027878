import {getPaginatedListSelectors} from '../utils/selectorGenerators';

const stat = state => state.Excise.stat;
const markingStat = state => state.Excise.markingStat;
const declaration = state => state.Excise.declaration;

const selectors = {
  ...getPaginatedListSelectors('Excise'),
  stat,
  markingStat,
  declaration
};

export default selectors;