import { createAction } from 'redux-actions';

export const subject = '[RUTOKEN]';

export const load = createAction(`${subject} load`);
export const loaded = createAction(`${subject} loaded`);

export const sign = createAction(
  `${subject} sign`,
  payload => payload,
  (_, meta) => meta,
);

export const signed = createAction(`${subject} signed`);
