import { intersection } from 'lodash';
import { instance, prepareError } from './utils';
// import { mockData } from './mocks/templateService';
import { userRoles } from '../constants/userRoles';

export class TemplateService {
  static getList({ offset, ...parameters }, roles) {
    const params = {
      page: offset ? offset / 10 : 0,
      ...parameters,
      needTotal: true,
    };

    Object.keys(params).forEach(name => {
      if (params[name] === '') {
        delete params[name];
      }
    });

    const url = Boolean(
      intersection(roles, [userRoles.ROLE_ORG_IS_MP_OPERATOR]).length
    )
      ? '/api/v3/facade/label/template/operator/all'
      : '/api/v3/facade/label/template/all';

    return instance
      .request({
        url,
        method: 'get',
        params,
        data: {},
      })
      .then(answer =>
        Promise.resolve({ data: { result: answer.data.label_templates } })
      )
      .catch(err => Promise.reject(err));
  }

  static deleteTemplate(id) {
    return instance.request({
      url: `/api/v3/facade/label/template/${id}/delete`,
      method: 'delete',
      data: {},
    });
  }

  static changeStatusTemplate({ id, data }) {
    return instance.request({
      url: `/api/v3/facade/label/template/operator/${id}/status/change`,
      method: 'post',
      data,
    });
  }

  static createTemplate(data) {
    return new Promise((resolve, reject) => {
      instance
        .request({
          url: '/api/v3/facade/label/template/create',
          method: 'post',
          data,
        })
        .then(answer => resolve(answer))
        .catch(err => reject(prepareError(err)));
    });
  }
}
