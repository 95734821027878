import { takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import { get, last, head } from 'lodash';
import * as actions from './MarkedProductList.actions';
import * as selectors from './MarkedProductList.selectors';
import * as listActions from '../../../common_components/List/ducks/List.actions';

const sortColumnMapper = (columnName) => {
  switch (columnName) {
    case 'emd': return 'emissionDate';
    default: return columnName;
  }
};

function* requestSaga(action) {
  const { payload = {} } = action;
  const { pageDir, page } = payload;

  if (page) yield put(actions.updatePage(page));
  const sorting = yield select(selectors.sorting);

  if (pageDir) {
    const { orderColumn } = sorting;
    const list = yield select(selectors.results);
    const element = pageDir === 'PREV' ? head(list) : last(list);
    const uit = get(element, 'cis', '');
    const value = get(element, sortColumnMapper(orderColumn), undefined);

    const paginationParams = {
      uit,
      orderedColumnValue: value,
      pageDir,
    };

    yield put(actions.pagination(paginationParams));
  } else {
    yield put(actions.pagination({}));
  }

  const filters = yield select(selectors.filters);
  const pagination = yield select(selectors.pagination);


  const requestPayload = {
    url: '/api/v3/facade/marked_products/listV2',
    method: 'post',
    data: {
      pagination: {...pagination, limit: 10},
      filter: {...filters, ...sorting},
    },
  };

  console.log('requestPayload for CisList', requestPayload);

  yield put(listActions.gotRequest(requestPayload, actions));
}

function* sortSaga(action) {
  const { payload } = action;

  const sorting = yield select(selectors.sorting);
  const { order, orderColumn } = sorting;

  let newOrder = 'ASC';
  if (payload === orderColumn) {
    newOrder = order === 'ASC' ? 'DESC' : 'ASC';
  }

  yield put(actions.setSorting({ order: newOrder, orderColumn: payload }));
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.sort, sortSaga);
}
