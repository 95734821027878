import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import { addSpacesBetweenThousands, addKopecksForFloat } from '../../../utils';
import {Rubel} from '../../../components/AnalyticMap/styled';

const Link = styled.a`
  display: inline-flex;
  text-decoration: none;
  color: #434244;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LinkParticipant = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a99f4;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;

export default {
  actionNamespace: 'EXCISE_LIST',
  selectorNamespace: 'ExciseList',
  limit: 10,
  options: [],
  columns: [
    {
      Header: 'Наименование налогоплательщика',
      id: 'participantName',
      accessor: d => (
        <LinkParticipant
          href={`/participants/list/${d.participantId}/excise`}
          onClick={e => e.preventDefault()}
        >
          {d.participantName}
        </LinkParticipant>
      ),
    },
    {
      Header: 'Сумма акциза исчисленного',
      id: 'calculatedExcise',
      accessor: d => (
        <Link
          href={`/participants/list/${d.participantId}/excise`}
          onClick={e => e.preventDefault()}
        >
          {addKopecksForFloat(addSpacesBetweenThousands(d.calculatedExcise/100))}&nbsp;₽
        </Link>
      ),
    },
    {
      Header: 'Сумма акциза к вычету',
      id: 'exciseToRefund',
      accessor: d => (
        <Link
          href={`/participants/list/${d.participantId}/excise`}
          onClick={e => e.preventDefault()}
        >
          {addKopecksForFloat(addSpacesBetweenThousands(d.exciseToRefund/100))}&nbsp;₽
        </Link>
      ),
    },
    {
      Header: 'Кол-во реализованного товара',
      id: 'suppliesAmount',
      accessor: d => (
        <Link
          href={`/participants/list/${d.participantId}/excise`}
          onClick={e => e.preventDefault()}
        >
          {addSpacesBetweenThousands(d.suppliesAmount)}
        </Link>
      ),
    },
  ],
  beforeTable: {
    showGraph: false,
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: false,
    },
    [
      {
        component: Input,
        props: {
          name: 'search',
          placeholder: 'Поиск',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [
      {
        component: Input,
        props: {
          name: 'registrationRegion',
          placeholder: 'Регион',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
  ],
};
