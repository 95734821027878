import React from 'react';
import styled from 'styled-components';
import { countryCodeFlags } from '../../../../constants';
import { Translate } from 'src/common_components/Translate/Translate';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: countryCodeFlags.isUZ
      ? Translate('Уведомление о ввозе (импорт)')
      : Translate('Уведомление о ввозе товаров (третьи страны)'),
    id: 2,
  },
];
export const cisKey = 'cis';
export const invalidCisKey = 'invalidCis';
export const goodsKey = 'products';
export const disaggregatedKey = 'disaggregated';

export const tabs = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Коды') },
  { id: 2, label: Translate('Шаг 3. Товары') },
];
export const steps = {
  info: 0,
  goods: 1,
  cis: 2,
};
export const documentNameOptions = [
  {
    label: Translate('Договор (контракт)'),
    value: Translate('Договор (контракт)'),
  },
  {
    label: Translate('Транспортная накладная'),
    value: Translate('Транспортная накладная'),
  },
  {
    label: Translate('Счет-фактура'),
    value: Translate('Счет-фактура'),
  },
];
export const typeDocument = [
  {
    label: Translate('Декларация на товары'),
    value: 'DECLARATION',
  },
  {
    label: Translate(
      'Заявление о выпуске товаров до подачи декларации на товары',
    ),
    value: 'STATEMENT',
  },
];
export const contentBodyConfirm = (
  <div>
    {Translate('Вы действительно хотите отменить отправку?')}
    <br />
    {Translate('Все введенные данные будут потеряны')}
  </div>
);
export const solveCodeValues = [
  {
    label: '10 Выпуск товаров разрешен',
    value: '10',
  },
  {
    label:
      '11 Выпуск товаров при условии обеспечения исполнения обязанности по уплате таможенных пошлин, налогов, специальных, антидемпинговых, компенсационных пошлин, за исключением выпуска товаров, поименованного в позициях с кодами 12 и 13',
    value: '11',
  },
  {
    label:
      '12 Выпуск товаров с особенностями, предусмотренными статьей 121 Таможенного кодекса Евразийского экономического союза',
    value: '12',
  },
  {
    label:
      '13 Выпуск товаров с особенностями, предусмотренными статьей 122 Таможенного кодекса Евразийского экономического союза',
    value: '13',
  },
  {
    label:
      '14 Выпуск товаров с особенностями, предусмотренными статьей 123 Таможенного кодекса Евразийского экономического союза',
    value: '14',
  },
  {
    label: '20 Условный выпуск товаров',
    value: '20',
  },
  // {
  //   label: '40 Таможенная декларация отозвана до выпуска товаров',
  //   value: '40',
  // },
  // {
  //   label: '50 Выпуск товаров аннулирован при отзыве таможенной декларации в случаях, предусмотренных пунктами 4 – 6 статьи 113 или пунктом 9 статьи 116 Таможенного кодекса Евразийского экономического союза',
  //   value: '50',
  // },
  // {
  //   label: '51 Выпуск товаров аннулирован в случае, предусмотренном пунктом 10 статьи 116 Таможенного кодекса Евразийского экономического союза',
  //   value: '51',
  // },
  // {
  //   label: '52 Выпуск товаров аннулирован по мотивированному обращению декларанта в случаях, определенных в соответствии с абзацем вторым пункта 4 статьи 118 Таможенного кодекса Евразийского экономического союза',
  //   value: '52',
  // },
  // {
  //   label: '60 Срок выпуска товаров приостановлен',
  //   value: '60',
  // },
  // {
  //   label: '61 Срок приостановления срока выпуска товаров продлен',
  //   value: '61',
  // },
  // {
  //   label: '62 Приостановление срока выпуска товаров отменено',
  //   value: '62',
  // },
  // {
  //   label: '70 Срок выпуска товаров продлен',
  //   value: '70',
  // },
  // {
  //   label: '82 Таможенная декларация считается не поданной',
  //   value: '82',
  // },
  // {
  //   label: '90 Отказано в выпуске товаров',
  //   value: '90',
  // },
  // {
  //   label: '92 Иное решение, предусмотренное законодательством государств-членов Евразийского экономического союза',
  //   value: '92',
  // },
];

export const solveCodesDict = [
  {
    code: '50100',
    label:
      'КОМИТЕТ ГОСУДАРСТВЕННЫХ ДОХОДОВ МИНИСТЕРСТВА ФИНАНСОВ РЕСПУБЛИКИ КАЗАХСТАН',
  },
  {
    code: '50400',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АКТЮБИHСКОЙ ОБЛАСТИ',
  },
  { code: '50404', label: 'Т/П "ХРОМТАУ"' },
  { code: '50411', label: 'Т/П "ЕМБI"' },
  { code: '50417', label: 'Т/П "АКТОБЕ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '50427', label: 'Т/П "АУЕЖАЙ-АКТОБЕ"' },
  { code: '50450', label: 'Т/П "СПЕЦИАЛИЗИРОВАННЫЙ ПОСТ АКТОБЕ"' },
  {
    code: '50500',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АТЫРАУСКОЙ ОБЛАСТИ',
  },
  { code: '50505', label: 'Т/П "ТЕHИЗ"' },
  { code: '50517', label: 'Т/П "АК-ЖАЙЫК - ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '50522', label: 'Т/П "АУЕЖАЙ АТЫРАУ"' },
  { code: '50523', label: 'Т/П "КАШАГАН"' },
  {
    code: '50525',
    label:
      'Т/П "СПЕЦИАЛЬНАЯ ЭКОНОМИЧЕСКАЯ ЗОНА-НАЦИОНАЛЬНЫЙ ИНДУСТРИАЛЬНЫЙ НЕФТЕХИМИЧЕСКИЙ ТЕХНОПАРК"',
  },
  {
    code: '50600',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО КОСТАНАЙСКОЙ ОБЛАСТИ',
  },
  { code: '50601', label: 'Т/П "ЖЕТИКАРА"' },
  { code: '50622', label: 'Т/П "АРКАЛЫК"' },
  { code: '50624', label: 'Т/П "КОСТАНАЙ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '50700',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО МАHГИСТАУСКОЙ ОБЛАСТИ',
  },
  { code: '50708', label: 'Т/П "КАРА ШЕКПЕН"' },
  { code: '50709', label: 'Т/П "МОРПОРТ"' },
  { code: '50710', label: 'Т/П "ТЕМИР - БАБА"' },
  { code: '50711', label: 'Т/П "АКТАУ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '50712', label: 'Т/П "АУЕЖАЙ АКТАУ"' },
  { code: '50714', label: 'Т/П "ТАЖЕН"' },
  { code: '50715', label: 'Т/П "БЕЙНЕУ"' },
  { code: '50716', label: 'ТАМОЖЕННЫЙ ПОСТ БОЛАШАК' },
  { code: '50717', label: 'КПП "КУРЫК"  Т/П "МОРПОРТ"' },
  {
    code: '50800',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ПАВЛОДАРСКОЙ ОБЛАСТИ',
  },
  { code: '50802', label: 'Т/П "ЕКIБАСТУЗ"' },
  { code: '50823', label: 'Т/П "ПАВЛОДАР-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '50830',
    label: 'ТАМОЖЕННЫЙ ПОСТ "Павлодар-специальная экономическая зона"',
  },
  {
    code: '50900',
    label:
      'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО СЕВЕРО-КАЗАХСТАНСКОЙ ОБЛАСТИ',
  },
  { code: '50916', label: 'Т/П "ЖАНА ЕСИЛЬ"' },
  { code: '50919', label: 'Т/П "ТАЙЫНША"' },
  { code: '50921', label: 'Т/П "ПЕТРОПАВЛОВСК-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '51400',
    label:
      'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ВОСТОЧНО-КАЗАХСТАНСКОЙ ОБЛАСТИ',
  },
  { code: '51419', label: 'Т/П "ОСКЕМЕН-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '51423', label: 'Т/П "АУЕЖАЙ ОСКЕМЕН"' },
  { code: '51426', label: 'Т/П "БАХТЫ"' },
  { code: '51428', label: 'Т/П "СЕМЕЙ"' },
  { code: '51435', label: 'Т/П "МАЙКАПЧАГАЙ"' },
  {
    code: '51500',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ЖАМБЫЛСКОЙ ОБЛАСТИ',
  },
  { code: '51512', label: 'ТП "ТАРАЗ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '51700',
    label:
      'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ЗАПАДHО-КАЗАХСТАНСКОЙ ОБЛАСТИ',
  },
  { code: '51702', label: 'Т/П "АКСАЙ"' },
  { code: '51712', label: 'Т/П "ОРАЛ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '51800',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО КАРАГАHДИHСКОЙ ОБЛАСТИ',
  },
  { code: '51801', label: 'Т/П "ТЕМИРТАУ"' },
  { code: '51804', label: 'ТП "КАРАГАНДА-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '51810', label: 'Т/П "АУЕЖАЙ-КАРАГАНДА"' },
  {
    code: '51814',
    label: 'ТАМОЖЕННЫЙ ПОСТ "СПЕЦИАЛЬНАЯ ЭКОНОМИЧЕСКАЯ ЗОНА "САРЫАРКА"',
  },
  { code: '52900', label: 'ЦЕHТРАЛЬHАЯ ТАМОЖЕHHАЯ ЛАБОРАТОРИЯ' },
  { code: '54500', label: 'КИНОЛОГИЧЕСКИЙ ЦЕНТР' },
  {
    code: '55100',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО Г.НУР-СУЛТАН',
  },
  { code: '55101', label: 'Т/П "АСТАНА-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '55103', label: 'Т/П "АУЕЖАЙ-АСТАНА"' },
  {
    code: '55106',
    label: 'Т/П "СПЕЦИАЛЬНАЯ ЭКОНОМИЧЕСКАЯ ЗОНА "АСТАНА-ЖАНА КАЛА"',
  },
  {
    code: '55200',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АКМОЛИНСКОЙ ОБЛАСТИ',
  },
  { code: '55202', label: 'Т/П АТБАСАР' },
  { code: '55204', label: 'Т/П КОКШЕТАУ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ' },
  {
    code: '55300',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ГОРОДУ АЛМАТЫ',
  },
  { code: '55301', label: 'Т/П "ЖЕТIСУ"' },
  { code: '55302', label: 'Т/П АЛМАТЫ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ' },
  {
    code: '55303',
    label: 'Т/П "СПЕЦ.ЭКОНОМ.ЗОНА "ПАРК ИНФОРМАЦ-Х ТЕХНОЛОГИЙ"',
  },
  {
    code: '55307',
    label: 'ТАМОЖЕННЫЙ ПОСТ "АЛМАЛЫ - ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"',
  },
  {
    code: '55400',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО  АЛМАТИНСКОЙ ОБЛАСТИ',
  },
  { code: '55401', label: 'Т/П "ТАЛДЫКОРГАН"' },
  { code: '55403', label: 'Т/П КАЛЖАТ' },
  {
    code: '55407',
    label:
      'ТАМОЖHЯ "ДОСТЫК" ДЕПАРТАМЕНТА ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АЛМАТИНСКОЙ ОБЛАСТИ',
  },
  {
    code: '55408',
    label:
      'Т/П "АЛАКОЛЬ" ТАМОЖНИ "ДОСТЫК"  ДЕПАРТАМЕНТА ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АЛМАТИНСКОЙ ОБЛАСТИ',
  },
  {
    code: '55409',
    label:
      'Т/П "ТЕМИРЖОЛ"  ТАМОЖНИ "ДОСТЫК"  ДЕПАРТАМЕНТА ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО АЛМАТИНСКОЙ ОБЛАСТИ',
  },
  { code: '55410', label: 'Т/П "НУР ЖОЛЫ"' },
  { code: '55451', label: 'Т/П МЦПС "Хоргос"' },
  { code: '55453', label: 'Т/П "АЛТЫНКОЛЬ-ЖОЛ"' },
  {
    code: '55500',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ТУРКЕСТАНСКОЙ ОБЛАСТИ',
  },
  { code: '55501', label: 'Т/П "ЖИБЕК ЖОЛЫ"' },
  { code: '55502', label: 'Т/П "АТАМЕКЕН"' },
  { code: '55507', label: 'Т/П "СТ. САРЫАГАШ"' },
  { code: '55508', label: 'Т/П "КАЗЫГУРТ"' },
  { code: '55510', label: 'Т/П ИМЕНИ БАУЫРЖАНА КОНЫСБАЕВА' },
  { code: '55513', label: 'Т/П  "КАПЛАНБЕК"' },
  { code: '55515', label: 'КПП "ЦЕЛИННЫЙ"  Т/П "АТАМЕКЕН"' },
  { code: '55516', label: 'КПП "СЫРДАРЬЯ"  Т/П "АТАМЕКЕН"' },
  { code: '55520', label: 'Т/П "ТУРКЕСТАН-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  {
    code: '55600',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО КЫЗЫЛОРДИНСКОЙ ОБЛАСТИ',
  },
  { code: '55601', label: 'Т/П "АРАЛ"' },
  { code: '55603', label: 'Т/П КЫЗЫЛОРДА-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ' },
  { code: '55605', label: 'Т/П "БАЙКОНЫР"' },
  {
    code: '57200',
    label: 'ДЕПАРТАМЕНТ ГОСУДАРСТВЕННЫХ ДОХОДОВ ПО ГОРОДУ ШЫМКЕНТ',
  },
  { code: '57201', label: 'Т/П "ШЫМКЕНТ-ЦЕНТР ТАМОЖЕННОГО ОФОРМЛЕНИЯ"' },
  { code: '57202', label: 'Т/П "СЭЗ "ОНТYСТIК"' },
  { code: '57203', label: 'Т/П "АУЕЖАЙ-ШЫМКЕНТ"' },
];

export const currency = [
  { label: 'Лек', id: '008', code: 'ALL' },
  { label: 'Алжирский динар', id: '012', code: 'DZD' },
  { label: 'Аргентинское песо', id: '032', code: 'ARS' },
  { label: 'Австралийский доллар', id: '036', code: 'AUD' },
  { label: 'Багамский доллар', id: '044', code: 'BSD' },
  { label: 'Бахрейнский динар', id: '048', code: 'BНD' },
  { label: 'Така', id: '050', code: 'BDT' },
  { label: 'Армянский драм', id: '051', code: 'AMD' },
  { label: 'Барбадосский доллар', id: '052', code: 'BBD' },
  { label: 'Бермудский доллар', id: '060', code: 'BMD' },
  { label: 'Нгултрум', id: '064', code: 'BTN' },
  { label: 'Боливиано', id: '068', code: 'BOB' },
  { label: 'Пула', id: '072', code: 'BWP' },
  { label: 'Белизский доллар', id: '084', code: 'BZD' },
  { label: 'Доллар Соломоновых островов', id: '090', code: 'SBD' },
  { label: 'Брунейский доллар', id: '096', code: 'BND' },
  { label: 'Кьят', id: '104', code: 'MMK' },
  { label: 'Бурундийский франк', id: '108', code: 'BIF' },
  { label: 'Риель', id: '116', code: 'KНR' },
  { label: 'Канадский доллар', id: '124', code: 'CAD' },
  { label: 'Эскудо Кабо-Верде', id: '132', code: 'CVE' },
  { label: 'Доллар островов Кайман', id: '136', code: 'KYD' },
  { label: 'Шриланкийская рупия', id: '144', code: 'LKR' },
  { label: 'Чилийское песо', id: '152', code: 'CLP' },
  { label: 'Юань Жэньминьби', id: '156', code: 'CNY' },
  { label: 'Колумбийское песо', id: '170', code: 'COP' },
  { label: 'Франк Комор', id: '174', code: 'KMF' },
  { label: 'Костариканский колон', id: '188', code: 'CRC' },
  { label: 'Хорватский куна', id: '191', code: 'НRK' },
  { label: 'Кубинское песо', id: '192', code: 'CUP' },
  { label: 'Кипрский фунт', id: '196', code: 'CYP' },
  { label: 'Чешская крона', id: '203', code: 'CZK' },
  { label: 'Датская крона', id: '208', code: 'DKK' },
  { label: 'Доминиканское песо', id: '214', code: 'DOP' },
  { label: 'Сальвадорский колон', id: '222', code: 'SVC' },
  { label: 'Эфиопский быр', id: '230', code: 'ETB' },
  { label: 'Накфа', id: '232', code: 'ERN' },
  { label: 'Крона', id: '233', code: 'EEK' },
  { label: 'Фунт Фолклендских островов', id: '238', code: 'FKP' },
  { label: 'Доллар Фиджи', id: '242', code: 'FJD' },
  { label: 'Франк Джибути', id: '262', code: 'DJF' },
  { label: 'Даласи', id: '270', code: 'GMD' },
  { label: 'Гибралтарский фунт', id: '292', code: 'GIP' },
  { label: 'Кетсаль', id: '320', code: 'GTQ' },
  { label: 'Гвинейский франк', id: '324', code: 'GNF' },
  { label: 'Гайанский доллар', id: '328', code: 'GYD' },
  { label: 'Гурд', id: '332', code: 'НTG' },
  { label: 'Лемпира', id: '340', code: 'НNL' },
  { label: 'Гонконгский доллар', id: '344', code: 'НKD' },
  { label: 'Форинт', id: '348', code: 'НUF' },
  { label: 'Исландская крона', id: '352', code: 'ISK' },
  { label: 'Индийская рупия', id: '356', code: 'INR' },
  { label: 'Рупия', id: '360', code: 'IDR' },
  { label: 'Иранский риал', id: '364', code: 'IRR' },
  { label: 'Иракский динар', id: '368', code: 'IQD' },
  { label: 'Новый израильский шекель', id: '376', code: 'ILS' },
  { label: 'Ямайский доллар', id: '388', code: 'JMD' },
  { label: 'Иена', id: '392', code: 'JPY' },
  { label: 'Тенге', id: '398', code: 'KZT' },
  { label: 'Иорданский динар', id: '400', code: 'JOD' },
  { label: 'Кенийский шиллинг', id: '404', code: 'KES' },
  { label: 'Северо-Корейская вона', id: '408', code: 'KPW' },
  { label: 'Вона', id: '410', code: 'KRW' },
  { label: 'Кувейтский динар', id: '414', code: 'KWD' },
  { label: 'Сом', id: '417', code: 'KGS' },
  { label: 'Кип', id: '418', code: 'LAK' },
  { label: 'Ливанский фунт', id: '422', code: 'LBP' },
  { label: 'Лоти', id: '426', code: 'LSL' },
  { label: 'Латвийский лат', id: '428', code: 'LVL' },
  { label: 'Либерийский доллар', id: '430', code: 'LRD' },
  { label: 'Ливийский динар', id: '434', code: 'LYD' },
  { label: 'Литовский лит', id: '440', code: 'LTL' },
  { label: 'Патака', id: '446', code: 'MOP' },
  { label: 'Квача', id: '454', code: 'MWK' },
  { label: 'Малайзийский ринггит', id: '458', code: 'MYR' },
  { label: 'Руфия', id: '462', code: 'MVR' },
  { label: 'Мальтийская лира', id: '470', code: 'MTL' },
  { label: 'Угия', id: '478', code: 'MRO' },
  { label: 'Маврикийская рупия', id: '480', code: 'MUR' },
  { label: 'Мексиканское песо', id: '484', code: 'MXN' },
  { label: 'Тугрик', id: '496', code: 'MNT' },
  { label: 'Молдавский лей', id: '498', code: 'MDL' },
  { label: 'Марокканский дирхам', id: '504', code: 'MAD' },
  { label: 'Оманский риал', id: '512', code: 'OMR' },
  { label: 'Доллар Намибии', id: '516', code: 'NAD' },
  { label: 'Непальская рупия', id: '524', code: 'NPR' },
  { label: 'Нидерландский антильский гульден', id: '532', code: 'ANG' },
  { label: 'Арубанский гульден', id: '533', code: 'AWG' },
  { label: 'Вату', id: '548', code: 'VUV' },
  { label: 'Новозеландский доллар', id: '554', code: 'NZD' },
  { label: 'Золотая кордоба', id: '558', code: 'NIO' },
  { label: 'Найра', id: '566', code: 'NGN' },
  { label: 'Норвежская крона', id: '578', code: 'NOK' },
  { label: 'Пакистанская рупия', id: '586', code: 'PKR' },
  { label: 'Бальбоа', id: '590', code: 'PAB' },
  { label: 'Кина', id: '598', code: 'PGK' },
  { label: 'Гуарани', id: '600', code: 'PYG' },
  { label: 'Новый соль', id: '604', code: 'PEN' },
  { label: 'Филиппинское песо', id: '608', code: 'PНP' },
  { label: 'Песо Гвинеи-Бисау', id: '624', code: 'GWP' },
  { label: 'Катарский риал', id: '634', code: 'QAR' },
  { label: 'Российский рубль', id: '643', code: 'RUB' },
  { label: 'Франк Руанды', id: '646', code: 'RWF' },
  { label: 'Фунт Святой Елены', id: '654', code: 'SНP' },
  { label: 'Добра', id: '678', code: 'STD' },
  { label: 'Саудовский риал', id: '682', code: 'SAR' },
  { label: 'Сейшельская рупия', id: '690', code: 'SCR' },
  { label: 'Леоне', id: '694', code: 'SLL' },
  { label: 'Сингапурский доллар', id: '702', code: 'SGD' },
  { label: 'Словацкая крона', id: '703', code: 'SKK' },
  { label: 'Донг', id: '704', code: 'VND' },
  { label: 'Сомалийский шиллинг', id: '706', code: 'SOS' },
  { label: 'Рэнд', id: '710', code: 'ZAR' },
  { label: 'Доллар Зимбабве', id: '716', code: 'ZWD' },
  { label: 'Лилангени', id: '748', code: 'SZL' },
  { label: 'Шведская крона', id: '752', code: 'SEK' },
  { label: 'Швейцарский франк', id: '756', code: 'CНF' },
  { label: 'Сирийский фунт', id: '760', code: 'SYP' },
  { label: 'Бат', id: '764', code: 'TНB' },
  { label: 'Паанга', id: '776', code: 'TOP' },
  { label: 'Доллар Тринидада и Тобаго', id: '780', code: 'TTD' },
  { label: 'Дирхам (ОАЭ)', id: '784', code: 'AED' },
  { label: 'Тунисский динар', id: '788', code: 'TND' },
  { label: 'Манат', id: '795', code: 'TMM' },
  { label: 'Угандийский шиллинг', id: '800', code: 'UGX' },
  { label: 'Динар', id: '807', code: 'MKD' },
  { label: 'Египетский фунт', id: '818', code: 'EGP' },
  { label: 'Фунт стерлингов', id: '826', code: 'GBP' },
  { label: 'Танзанийский шиллинг', id: '834', code: 'TZS' },
  { label: 'Доллар США', id: '840', code: 'USD' },
  { label: 'Узбекский сум', id: '860', code: 'UZS' },
  { label: 'Тала', id: '882', code: 'WST' },
  { label: 'Йеменский риал', id: '886', code: 'YER' },
  { label: 'Квача', id: '894', code: 'ZMK' },
  { label: 'Новый тайваньский доллар', id: '901', code: 'TWD' },
  { label: 'Седи Ганы', id: '936', code: 'GНS' },
  { label: 'Боливар фуэрте', id: '937', code: 'VEF' },
  { label: 'Суданский фунт', id: '938', code: 'SDG' },
  { label: 'Уругвайское песо', id: '940', code: 'UYI' },
  { label: 'Сербский динар', id: '941', code: 'RSD' },
  { label: 'Метикал', id: '943', code: 'MZN' },
  { label: 'Азербайджанский манат', id: '944', code: 'AZN' },
  { label: 'Новый румынский лей', id: '946', code: 'RON' },
  { label: 'Новая турецкая лира', id: '949', code: 'TRY' },
  { label: 'Франк КФА ВЕАС*', id: '950', code: 'XAF' },
  { label: 'Восточно-Карибский доллар', id: '951', code: 'XCD' },
  { label: 'Франк КФА ВСЕАО**', id: '952', code: 'XOF' },
  { label: 'Франк КФП', id: '953', code: 'XPF' },
  { label: 'СДР (специальные права заимствования)', id: '960', code: 'XDR' },
  { label: 'Суринамский доллар', id: '968', code: 'SRD' },
  { label: 'Малагасийский ариари', id: '969', code: 'MGA' },
  { label: 'Афгани', id: '971', code: 'AFN' },
  { label: 'Сомони', id: '972', code: 'TJS' },
  { label: 'Кванза', id: '973', code: 'AOA' },
  { label: 'Белорусский рубль', id: '974', code: 'BYR' },
  { label: 'Болгарский лев', id: '975', code: 'BGN' },
  { label: 'Конголезский франк', id: '976', code: 'CDF' },
  { label: 'Конвертируемая марка', id: '977', code: 'BAM' },
  { label: 'Евро (единица валюты Европейского Союза)', id: '978', code: 'EVR' },
  { label: 'Гривна', id: '980', code: 'UAН' },
  { label: 'Лари', id: '981', code: 'GEL' },
  { label: 'Злотый', id: '985', code: 'PLN' },
  { label: 'Бразильский реал', id: '986', code: 'BRL' },
  {
    label: 'Другой вид валюты, не указанный в данном перечне',
    id: '999',
    code: '999',
  },
];
