import styled, { css } from 'styled-components';

export const ParticipantListFooter = styled.footer`
  display:flex;
  justify-content: space-between;
  
  padding: 10px 35px 25px 35px;
`;

export const ParticipantListCount = styled.span`
  font-size:12px;
  color: #B8B8B8;
`;