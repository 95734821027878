import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { Translate } from '../../../common_components/Translate/Translate';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { TwoLineLabel } from '@crpt/react-label';
import React, { Fragment } from 'react';
import { documentTypeNames } from '../../../constants';
import { pgTypesNames } from '../../../constants/productGroups';
import styled from 'styled-components';
export const Divider = styled.div`
  display: flex;
  height: 1px;
  border-bottom: 1px solid #d2d2d2;
`;

const ValidationInfo = ({ selectedDocument, id }) => {
  const {
    productGroup,
    cisesCount,
    contractorAreaId,
    deviceId,
    contractorTin,
    docErrors,
    documentDate,
    status,
  } = selectedDocument;

  return (
    <Fragment>
      <HeadingWrap
        title={Translate('Отчет о валидации качества печати')}
        id={id}
        date={documentDate}
        status={status}
        docType={documentTypeNames.VALIDATION}
        needDocTitle={false}
        bottomId={true}
      />
      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Общие данные')}>
            <TwoLineLabel heading={Translate('Товарная группа')}>
              {Translate(
                productGroup ? pgTypesNames[productGroup.toLowerCase()] : '-',
              )}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('Количество кодов')}>
              {cisesCount || '-'}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('Код площадки Сервис-провайдера')}>
              {contractorAreaId || '-'}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('Использованное устройство')}>
              {deviceId || '-'}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('ИНН сервис-провайдера')}>
              {contractorTin || '-'}
            </TwoLineLabel>
          </DocumentInfoBlock>
          {docErrors && docErrors.length !== 0 && (
            <DocumentInfoBlock title={Translate('Ошибки')}>
              {docErrors.map(item => (
                <p>{item.join(' ')}</p>
              ))}
            </DocumentInfoBlock>
          )}
        </Column>
      </Container>
    </Fragment>
  );
};

export default ValidationInfo;
