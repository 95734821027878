import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from '../../Fields/_refactor/Select/SingleSelect';
import { instance } from 'src/services/instance';
import HintedOption from '../../Fields/Select/HintedOption';
import { hasOnlyDigits } from 'src/utils';
import { solveCodesDict } from '../../../../motp_components/_Requests/RequestList/ImportThirdCountry/ImportThirdCountry.constants';

const { CancelToken } = axios;

const mapList = (pr, i) => ({
  type: pr.code,
  id: i + 1,
  title: `${pr.label}, код ${pr.code}`,
  code: pr.code,
});

class CustomsCodeSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    values: PropTypes.string,
    selectedId: PropTypes.number,
    errorPlaceholder: PropTypes.string,
  };
  static defaultProps = {
    onChange: undefined,
  };
  static cancelToken;
  state = {
    isChanged: false,
    loading: false,
    values: undefined,
    preventUpdate: false,
    isError: false,
    placeholder: '',
    inputValue: '',
    selectedValue: null,
  };

  componentDidMount() {
    const newValues = solveCodesDict
      .map(mapList);

    this.setState({
      values: newValues,
    });
  }

  onChange = (val, e) => {

    this.setState({
      placeholder: '',
      inputValue: val,
      isChanged: true,
    });

    this.setState({
      loading: true,
      preventUpdate: true,
    });

    CustomsCodeSelect.cancelToken && CustomsCodeSelect.cancelToken();

    const newValues = solveCodesDict
      .filter(
        (item) => item.code.includes(val)
      )
      .map(mapList);

    this.setState({
      loading: false,
      preventUpdate: true,
      values: newValues,
    });
  };

  isError = () => {
    const { validate, values } = this.props;
    if (validate) {
      const isInvalid = validate(values);
      if (isInvalid) {
        return isInvalid;
      }
    }
    return false;
  };

  render() {
    const { onChange, placeholder, errorPlaceholder, ...passProps } = this.props;
    const { loading, isChanged, values } = this.state;
    return (
      <SingleSelect
        {...passProps}
        placeholder={placeholder}
        errorPlaceholder={isChanged ? errorPlaceholder : placeholder}
        filterDisable
        truncate
        renderOption={HintedOption}
        onChange={this.onChange}
        isLoading={loading}
        values={values}
      />
    )
  }
}

export default CustomsCodeSelect;
