import styled from 'styled-components';
import { get } from 'lodash';

export const Description = styled.div`
  padding-top: ${({ theme }) =>
    get(theme, 'reportsList.tile.description.paddingTop', '100px')};
  padding-bottom: ${({ theme }) =>
    get(theme, 'reportsList.tile.description.paddingBottom', '100px')};
  padding-left: ${({ theme }) =>
    get(theme, 'reportsList.tile.description.paddingLeft', '100px')};
  padding-right: ${({ theme }) =>
    get(theme, 'reportsList.tile.description.paddingRight', '100px')};

  font-size: ${({ theme }) =>
    get(theme, 'reportsList.tile.description.fontSize', '32px')};
`;

export const Header = styled.div`
  padding-top: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.paddingTop', '100px')};
  padding-bottom: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.paddingBottom', '100px')};
  padding-left: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.paddingLeft', '100px')};
  padding-right: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.paddingRight', '100px')};
  min-height: 60px;
  font-size: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.fontSize', '32px')};
  line-height: ${({ theme }) =>
    get(theme, 'reportsList.tile.header.lineHeight', '30px')};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  height: ${({ theme }) => get(theme, 'reportsList.tile.icon.height', '300px')};

  margin-top: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.marginTop', '100px')};
  margin-bottom: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.marginBottom', '100px')};
  margin-left: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.marginLeft', '100px')};
  margin-right: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.marginRight', '100px')};

  padding-top: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.paddingTop', '100px')};
  padding-bottom: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.paddingBottom', '100px')};
  padding-left: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.paddingLeft', '100px')};
  padding-right: ${({ theme }) =>
    get(theme, 'reportsList.tile.icon.paddingRight', '100px')};

  ${({ isMouseOver, theme }) =>
    isMouseOver
      ? `background-color: ${get(
          theme,
          'reportsList.tile.icon.hover.backgroundColor',
          'red'
        )};`
      : null};
  & div {
    padding-top: 10px;
  } 
`;

export const TileWrapper = styled.a`
  text-decoration: none !important;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  flex: 0 0 ${({ theme }) => get(theme, 'reportsList.tile.width', '1000px')};
  flex-direction: column;
  color: #434244 !important;
  width: ${({ theme }) => get(theme, 'reportsList.tile.width', '1000px')};
  height: ${({ theme }) => get(theme, 'reportsList.tile.height', '1000px')};

  margin-top: ${({ theme }) =>
    get(theme, 'reportsList.tile.marginTop', '100px')};
  margin-bottom: ${({ theme }) =>
    get(theme, 'reportsList.tile.marginBottom', '100px')};
  margin-left: ${({ theme }) =>
    get(theme, 'reportsList.tile.marginLeft', '100px')};
  margin-right: ${({ theme }) =>
    get(theme, 'reportsList.tile.marginRight', '100px')};

  background-color: ${({ theme }) =>
    get(theme, 'reportsList.tile.backgroundColor', 'black')};
  box-shadow: ${({ theme }) => get(theme, 'reportsList.tile.shadowX', '10px')}
    ${({ theme }) => get(theme, 'reportsList.tile.shadowY', '10px')}
    ${({ theme }) => get(theme, 'reportsList.tile.shadowBlur', '10px')}
    ${({ theme }) => get(theme, 'reportsList.tile.shadowColor', 'red')};

  ${({ theme }) => {
    const cursorType = get(theme, 'reportsList.tile.icon.cursor', '');
    return cursorType ? `cursor: ${cursorType}}` : null;
  }} @media (max-width: 1450px) {
    padding: 0px 0px;
    padding-bottom: 20px;
  }
  @media (min-width: 1560px) and (max-width: 2104px) {
    padding: 13px 0px;
    padding-bottom: 20px;
  }
  @media (min-width: 2105px) and (max-width: 2768px) {
    padding: 26px 0px;
  }
  @media (min-width: 2769px) and (max-width: 3432px) {
    padding: 39px 0px;
  }
  @media (min-width: 3432px) {
    padding: 52px 0px;
  }
  
`;

export const DetailedInfo = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
  font-size: 16px;
  display: flex;
  height: 24px;
  /* visibility: ${props => props.visible ? 'visible' : 'hidden'}; */
  align-items: center;
  justify-content: center;
  color: #55b5e7;
  & > div {
    padding-right: 0;
  }
  & span {
    margin-right: 10px;
  }
  &:hover span {
    text-decoration: underline;
  }
`;
