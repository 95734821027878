import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, FormLine } from '@crpt/react-form';
import { Column, Container } from '@crpt/react-page-layout';
import { Button, SIZES, themes } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import {
  SidebarWrap,
  Header,
  HeaderText,
  AcceptButtonWrap,
  Controls,
  filterButtonTheme,
} from './styled';
import { Translate } from '../../../../../common_components/Translate/Translate';


export default class Filter extends PureComponent {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    filterFields: PropTypes.array.isRequired,
    onFilterClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  renderLine = (line, i) => (
    line.map((value, i) => {
      const Component = value && value.component;
      const type = value && value.type;
      const { type: typeFromProps } = this.props;

      const isShow = (!type) || (type === typeFromProps);
      const FilterComponent = (
        <FormLine key={i}>
          <Container fillWidth gutter={10.5}>
            <Column key={i}>
              {Component ? <Component {...value.props} /> : null}
            </Column>
          </Container>
        </FormLine>
      );

      return isShow ? FilterComponent : null;
    })
  );

  onSubmit = (value) => {
    const { onFilterApply, onFilterClose } = this.props;

    onFilterApply(value);
    onFilterClose();
  }

  render() {
    const {
      onFilterClose,
      filterFields,
    } = this.props;
    return (
      <SidebarWrap>
        <Header>
          <HeaderText>{Translate('Фильтр')}</HeaderText>
          <Icon
            type="cross"
            width={19}
            height={19}
            onClick={onFilterClose}
            fill="#52535a"
          />
        </Header>
        <Form
          onSubmit={this.onSubmit}
          onClear={value => this.onSubmit({})}
        >
          {filterFields.map(this.renderLine)}

          <Controls>
            <Button
              action="clear"
              size={SIZES.large}
              theme={themes.whiteTheme}
            >
              {Translate('Сбросить')}
            </Button>

            <AcceptButtonWrap>
              <Button
                type="submit"
                size={SIZES.large}
                theme={filterButtonTheme}
              >
                {Translate('Применить')}
              </Button>
            </AcceptButtonWrap>
          </Controls>

        </Form>
      </SidebarWrap>
    );
  }
}
