import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';

import Api from '../../../../common_components/Api/Api';
import { Toast } from '../../../../utils/Toast';

import { isOperator } from '../../_utils/utils';
import { hostname } from '../../constants';
import * as actions from './UnloadServiceDetails.actions';
import { Translate } from '../../../../common_components/Translate/Translate';

function* onMounted({ payload }) {
  const { task_id } = payload;

  //TODO: получать из url товарную группу
  const pg = 3;

  let requestArr = [
    call(fetchTask, task_id, { params: { pg } }),
    call(fetchResultList, { task_ids: task_id, pg, page: 0, size: 1000 }),
  ];

  if (isOperator()) {
    requestArr.push(call(fetchAvailableOrgRolesList));
  }

  const result = yield all(requestArr);

  const resultData = result.reduce((prev, current) => {
    const [success] = current;
    const url = _.get(success, 'config.url');

    if (/available-org-roles/.test(url)) {
      return {
        ...prev,
        availableOrgRoles: success.data,
      };
    }

    if (success) return { ...prev, ...success.data };
    return prev;
  }, {});

  const resultErrors = result
    .filter(([success, error]) => error)
    .map(([success, error]) => _.get(error, 'message', 'error'));

  yield put(actions.setData(resultData));
  yield put(actions.loaded());

  if (resultErrors.length) {
    yield call(Toast.showError, { content: resultErrors.join('\n\n') });
  }
}

function* onSetPage({ payload }) {
  const params = { ...payload };

  //TODO: получать из url товарную группу
  const pg = 3;

  const [success, error] = yield call(fetchResultList, { ...params, pg });

  const data = _.get(success, 'data', []);
  yield put(actions.setData({ ...data }));
}

function* onDownloadResults({ payload }) {
  //TODO: получать из url товарную группу
  const pg = 3;
  let index = 0;

  while (index < payload.length) {
    let url = `${hostname}/dispenser/v1/results/${payload[index]}/file`;

    const [success, error] = yield call(Api.fetchFile, url, {
      params: { pg },
    });

    if (success) {
      const url = _.get(success, 'config.url');
      const filename = url
        ? url
            .match(/results.+file/)[0]
            .replace(/\//g, '')
            .replace(/results/, '')
            .replace(/file/, '')
        : index;

      const file = new Blob([success.data], { type: 'application/zip' });
      yield call(saveAs, file, `${filename}.zip`);
    }

    if (error) {
      yield call(Toast.showError, {
        content: _.get(error, 'message', 'error'),
      });
    }

    index++;
  }
}

function* onChangeResults({ payload }) {
  const { task_id, result_ids, requestBody } = payload;

  //TODO: получать из url товарную группу
  const pg = 3;

  const requestArr = result_ids.map(result_id =>
    call(fetchResult, result_id, {
      ...requestBody,
      params: { ...requestBody.params, pg },
    }),
  );

  const result = yield all(requestArr);

  const resultData = result
    .filter(([success]) => success)
    .map(([success]) => _.get(success, 'data', ''))
    .filter(item => item);

  const resultErrors = result
    .filter(([success, error]) => error)
    .map(([success, error]) => _.get(error, 'message', 'error'));

  if (resultData.length) {
    if (requestBody.method === 'DELETE') {
      yield call(Toast.showSuccess, {
        content: Translate('Файлы запланированы на удаление'),
      });
    }

    if (requestBody.method === 'PUT') {
      yield call(Toast.showSuccess, { content: Translate('Файлы изменены') });
    }
  }

  if (resultErrors.length) {
    yield call(Toast.showError, { content: resultErrors.join('\n\n') });
  }

  yield put(actions.setPage({ task_ids: task_id, page: 0, size: 1000 }));
}

function* onChangeTask({ payload }) {
  const { task_id, taskDto } = payload;

  //TODO: получать из url товарную группу
  const pg = 3;
  const requestBody = {
    params: { pg },
    data: taskDto,
  };

  const [success, error] = yield call(fetchChangeTask, task_id, requestBody);

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  if (_.get(success, 'data')) {
    yield put(actions.setData({ ..._.get(success, 'data') }));
    yield call(Toast.showSuccess, { content: 'Роли изменены' });
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, onMounted);
  yield takeEvery(actions.setPage, onSetPage);
  yield takeEvery(actions.downloadResults, onDownloadResults);
  yield takeEvery(actions.changeResults, onChangeResults);
  yield takeEvery(actions.changeTask, onChangeTask);
}

function* fetchTask(task_id, { params }) {
  const request = {
    url: `${hostname}/dispenser/v1/tasks/${task_id}`,
    method: 'GET',
    params,
  };

  return yield call(Api.request, request);
}

function* fetchResultList(params) {
  const request = {
    url: `${hostname}/dispenser/v1/results`,
    method: 'GET',
    params,
  };

  return yield call(Api.request, request);
}

function* fetchResult(result_id, { method, data, params }) {
  const request = {
    url: `${hostname}/dispenser/v1/results/${result_id}`,
    method: method,
  };
  data ? (request.data = data) : null;
  params ? (request.params = params) : null;

  return yield call(Api.request, request);
}

function* fetchAvailableOrgRolesList() {
  const request = {
    url: `/api/v3/facade/profile/available-org-roles`,
    method: 'GET',
  };

  return yield call(Api.request, request);
}

function* fetchChangeTask(task_id, { data, params }) {
  const request = {
    url: `${hostname}/dispenser/v1/tasks/${task_id}`,
    method: 'PUT',
    data,
    params,
  };

  return yield call(Api.request, request);
}
