import { combineReducers } from 'redux';
import { getPaginatedListReducers } from '../../utils';
import { stat } from './stat';
import { markingStat } from './markingStat';
import { declaration } from './declaration';

export default combineReducers({
  ...getPaginatedListReducers('EXCISE'),
  stat,
  markingStat,
  declaration,
});
