import { css } from 'styled-components';

const TableResizer = {
  style: css`
    .rt-th,
    .rt-td {
      cursor: col-resize;
      transition: none !important;

      user-select: none;
    }
  `,
};

export default TableResizer;
