import { Divider as MuiDivider, withStyles } from '@material-ui/core';
import React from 'react';

export const styles = theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
});

export const MainContent = withStyles({
  root: {
    display: ({ isVisible }) => (!isVisible ? 'none' : 'flex'),
    flexGrow: 1,
    flexDirection: 'column',
  },
})(({ classes, children }) => (
  <div className={classes.root}>
    {children}
  </div>
));

export const Divider = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.border.light,
  },
  absolute: {
    bottom: 'auto',
  },
}))(MuiDivider);

