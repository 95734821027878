import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Box, Grid, Typography, MuiThemeProvider } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Button, RequiredFieldsWarning } from '@crpt-ui/core';
import { Close } from '@crpt-ui/icons';
import * as _ from 'lodash';
// import moment from 'moment';
// import LightTheme from '../../../common_components/_MaterialUi/LightTheme';
import mutators from '../../../common_components/_refactor/Form/mutators';
import Preloader from '../../../common_components/Preloader/Preloader';
import { PARTICIPANT_TYPES } from '../../../constants/participantTypes';

import { hardcodeRoles, periodicityEnum } from '../constants';
import * as unloadServiceCreateActions from './ducks/UnloadServiceCreate.actions';
import * as unloadServiceCreateSelectors from './ducks/UnloadServiceCreate.selectors';
import UnloadServiceCreateFields from './UnloadServiceCreate.fields';
import { useStyles } from './UnloadServiceCreate.styles';
import { formValidator } from './UnloadServiceCreate.validations';
// import { adaptBody } from './ducks/UnloadServiceCreate.adapters';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';
import { Translate } from '../../../common_components/Translate/Translate';

const UnloadServiceCreate = ({
  availablePeriods,
  data,
  formValues,
  loaded,
  loading,
  message,
  onClose,
  onMounted,
  onSubmit,
  onUnmount,
  pg,
  reportId,
  systemName,
  taskTypeId,
}) => {

  // Если availablePeriods пустой массив, то тип задания должно быть только "Однократное"
  const haveOnlySinglePeriodicity = useMemo(
    () => _.isArray(availablePeriods) && availablePeriods.length === 0,
    [availablePeriods],
  );

  const initFormValues = useMemo(() => {
    const initValues = formValues
      ? formValues
      : {
          users: [{}],
          periodicity: haveOnlySinglePeriodicity
            ? periodicityEnum.SINGLE
            : undefined,
          meta: {
            // Название системное
            systemName: systemName || '',
            // ID отчета
            reportId: reportId || '',
            // ID типа задания
            taskTypeId: taskTypeId || '',
            // Строка параметров задания
            params: _.get(data, 'reportParams', {}),
            // Доступные периоды для задания
            availablePeriods: availablePeriods || 'all',
          },
        };
    return initValues;
  }, [
    formValues,
    data.reportParams,
    systemName,
    reportId,
    taskTypeId,
    availablePeriods,
    haveOnlySinglePeriodicity,
  ]);

  const classes = useStyles();
  const usersValues =
    data.users && Array.isArray(data.users) && data.users.length
      ? data.users.map((item, index) => ({
          key: index,
          label: _.get(item, 'user.fullName', ''),
          value: _.get(item, 'user.id', ''),
          assignedRoles: _.get(item, 'assignedRoles', [])
            .map(item => item.description)
            .join(', '),
        }))
      : [];

  const rolesSelect = useMemo(
    () =>
      [
        ..._.get(data, 'availableOrgRoles', []),
        ...hardcodeRoles.filter(
          role => role.code !== PARTICIPANT_TYPES.IS_MP_OPERATOR,
        ),
      ].map((role, index) => ({
        key: index,
        label: role.name,
        value: role.code,
      })),
    [data],
  );

  useEffect(() => {
    onMounted({ pg, reportId, taskTypeId });
    return () => {
      onUnmount();
    };
  }, []);

  useEffect(() => {
    if (
      (!loading && message === 'task created') ||
      message === 'jasper errors'
    ) {
      onClose();
    }
  }, [message, loading]);

  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <Preloader isClosed={loaded}>
        <div className={classes.closeWrapper}>
          <Close onClick={onClose} />
        </div>

        <Form
          initialValues={initFormValues}
          mutators={mutators}
          onSubmit={onSubmit}
          validate={formValidator}
          subscription={{
            submitting: true,
            errors: true,
            values: true,
          }}
        >
          {({ handleSubmit, errors, values, mutators }) => {
            return (
              <React.Fragment>
                <Box mt={-2} mb={2}>
                  <Typography variant="h2">
                    {Translate('Создание нового задания')}{' '}
                    <span className={classes.textColorSecondary}>
                      ({systemName})
                    </span>
                  </Typography>
                </Box>

                {/* <pre>
                  adaptBody: {JSON.stringify(adaptBody(values), null, 2)}
                </pre> */}
                {/* <pre>values: {JSON.stringify(values, null, 2)}</pre> */}
                {/* <pre>errors: {JSON.stringify(errors, null, 2)}</pre> */}

                <Box mx={-4} mb={4}>
                  <div className={classes.devider} />
                </Box>

                <Box mb={2}>
                  <RequiredFieldsWarning error={!_.isEmpty(errors)} />
                </Box>

                <UnloadServiceCreateFields
                  haveOnlySinglePeriodicity={haveOnlySinglePeriodicity}
                  mutators={mutators}
                  rolesSelect={rolesSelect}
                  usersValues={usersValues}
                />

                <Box mx={-4} mt={4} mb={2}>
                  <div className={classes.devider} />
                </Box>

                <Box>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Button
                        disabled={!_.isEmpty(errors)}
                        onClick={handleSubmit}
                        loading={loading}
                        width={250}
                      >
                        {Translate('Создать задание')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={onClose} variant="outlined" width={250}>
                        {Translate('Отменить')}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            );
          }}
        </Form>
      </Preloader>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  data: unloadServiceCreateSelectors.data(state),
  message: unloadServiceCreateSelectors.message(state),
  loaded: unloadServiceCreateSelectors.loaded(state),
  loading: unloadServiceCreateSelectors.loading(state),
});

const mapDispatchToProps = {
  onMounted: unloadServiceCreateActions.mounted,
  onSubmit: unloadServiceCreateActions.submit,
  onUnmount: unloadServiceCreateActions.unMounted,
};

UnloadServiceCreate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}),
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMounted: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  taskTypeId: PropTypes.string.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UnloadServiceCreate,
);
