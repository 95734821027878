export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Показатель',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': 'Дата',
          'col2': 'Масштаб графика зависит от выбранного варианта отображения периода'
        },
        {
          'col1': 'Эмитирован',
          'col2': 'Количество эмитированных кодов за день/неделю/месяц, в зависимости от отображаемого периода'
        },
        {
          'col1': 'Нанесён',
          'col2': 'Количество нанесённых кодов за день/неделю/месяц, в зависимости от отображаемого периода'
        },
        {
          'col1': 'Отгружен',
          'col2': 'Количество введённых в оборот кодов за день/неделю/месяц, в зависимости от отображаемого периода'
        },
      ]
    }
  ]
}