import { combineReducers } from 'redux';
import {tabs} from '../Reports.constants';
import {actionTypes} from '../../../constants';

const getList = (url) => (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.REPORT_LIST.LIST_MOUNTED:
      return action.value.url === url ? action.value.list : state;
    default:
      return state;
  }
}

const tabsObj = tabs.reduce((obj, tab) => {
  obj[tab.url] = getList(tab.url);
  return obj;
}, {});

export const lists = combineReducers({
  ...tabsObj
});

