import React from 'react';
import { Translate } from 'src/common_components/Translate/Translate';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Уведомление о вводе в оборот'),
    id: 2,
  },
];

export const breadcrumbsCancelation = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Уведомление о выводе из оборота'),
    id: 2,
  },
];

export const cisKey = 'cis';
export const invalidCisKey = 'invalidCis';
export const goodsKey = 'products';
export const tabs = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
];
export const steps = {
  info: 0,
  goods: 1,
};
export const documentNameOptions = [
  {
    label: Translate('Договор (контракт)'),
    value: Translate('Договор (контракт)'),
  },
  {
    label: Translate('Транспортная накладная'),
    value: Translate('Транспортная накладная'),
  },
  {
    label: Translate('Счет-фактура'),
    value: Translate('Счет-фактура'),
  },
];

export const reasonEntry = [
  {
    label: 'Ввод в оборот товаров при обнаружении излишков',
    value: '1',
  },
];

export const docKindUz = {
  WRITE_OFF: "WRITE_OFF",
  SAMPLING: "SAMPLING",
  RETURN: "RETURN",
  ACCEPTANCE_AND_TRANSFER: "ACCEPTANCE_AND_TRANSFER",
  WITHDRAWAL: "WITHDRAWAL",
  PRESCRIPTION: "PRESCRIPTION",
  CUSTOMS_DECLARATION: "CUSTOMS_DECLARATION",
  CONTRACT: "CONTRACT",
  STATE_CONTRACT: "STATE_CONTRACT",
  POWER_OF_ATTORNEY: "POWER_OF_ATTORNEY",
  CONSIGNMENT: "CONSIGNMENT",
  RECEIPT: "RECEIPT",
  SALES_RECEIPT: "SALES_RECEIPT",
  OTHER: "OTHER",
}

export const docKindsNamesUz = {
  [docKindUz.WRITE_OFF]: "Акт списания",
  [docKindUz.SAMPLING]: "Акт отбора",
  [docKindUz.RETURN]: "Акт возврата",
  [docKindUz.ACCEPTANCE_AND_TRANSFER]: "Акт приема-передачи",
  [docKindUz.WITHDRAWAL]: "Акт изъятия",
  [docKindUz.PRESCRIPTION]: "Рецепт, назначение",
  [docKindUz.CUSTOMS_DECLARATION]: "Грузовая таможенная декларация",
  [docKindUz.CONTRACT]: "Договор, контракт",
  [docKindUz.STATE_CONTRACT]: "Государственный контракт",
  [docKindUz.POWER_OF_ATTORNEY]: "Доверенность",
  [docKindUz.CONSIGNMENT]: "Накладная",
  [docKindUz.RECEIPT]: "Кассовый чек",
  [docKindUz.SALES_RECEIPT]: "Товарный чек",
  [docKindUz.OTHER]: "Иной документ",
}

export const docKindsListUz = [
  {
    label: 'Акт списания',
    value: docKindUz.WRITE_OFF,
  },
  {
    label: 'Акт отбора',
    value: docKindUz.SAMPLING,
  },
  {
    label: 'Акт возврата',
    value: docKindUz.RETURN,
  },
  {
    label: 'Акт приема-передачи',
    value: docKindUz.ACCEPTANCE_AND_TRANSFER,
  },
  {
    label: 'Акт изъятия',
    value: docKindUz.WITHDRAWAL,
  },
  {
    label: 'Рецепт, назначение',
    value: docKindUz.PRESCRIPTION,
  },
  {
    label: 'Грузовая таможенная декларация',
    value: docKindUz.CUSTOMS_DECLARATION,
  },
  {
    label: 'Договор, контракт',
    value: docKindUz.CONTRACT,
  },
  {
    label: 'Государственный контракт',
    value: docKindUz.STATE_CONTRACT,
  },
  {
    label: 'Доверенность',
    value: docKindUz.POWER_OF_ATTORNEY,
  },
  {
    label: 'Накладная',
    value: docKindUz.CONSIGNMENT,
  },
  {
    label: 'Кассовый чек',
    value: docKindUz.RECEIPT,
  },
  {
    label: 'Товарный чек',
    value: docKindUz.SALES_RECEIPT,
  },
  {
    label: 'Иной документ',
    value: docKindUz.OTHER,
  }
]

export const reasonCancellationUz = {
  RETAIL: 'RETAIL',
  RETURN: 'RETURN',
  REMOTE_SALE: 'REMOTE_SALE',
  DESTRUCTION: 'DESTRUCTION',
  CONFISCATION: 'CONFISCATION',
  LIQUIDATION: 'LIQUIDATION',
  DONATION: 'DONATION',
  STATE_ENTERPRISE: 'STATE_ENTERPRISE',
  NO_RETAIL_USE: 'NO_RETAIL_USE',
  ENTERPRISE_USE: 'ENTERPRISE_USE',
  MEDICAL_PURPOSE: 'MEDICAL_PURPOSE',
  FREE_PRESCRIPTION: 'FREE_PRESCRIPTION',
  DEFECT: 'DEFECT',
  EXPIRY: 'EXPIRY',
  PRODUCT_RECALL: 'PRODUCT_RECALL',
  COMPLAINTS: 'COMPLAINTS',
  DEMO_SAMPLES: 'DEMO_SAMPLES',
  OTHER: 'OTHER',
  DAMAGE_LOSS: 'DAMAGE_LOSS',
  PROCESSING: 'PROCESSING',
  EXPORT: 'EXPORT',
}

export const reasonCancellationNamesUz = {
  [reasonCancellationUz.RETAIL]: 'Розничная реализация',
  [reasonCancellationUz.RETURN]: 'Возврат физическому лицу (комитенту)',
  [reasonCancellationUz.REMOTE_SALE]: 'Дистанционная продажа',
  [reasonCancellationUz.DESTRUCTION]: 'Уничтожение',
  [reasonCancellationUz.CONFISCATION]: 'Конфискация товаров',
  [reasonCancellationUz.LIQUIDATION]: 'Ликвидация предприятия',
  [reasonCancellationUz.DONATION]: 'Безвозмездная передача',
  [reasonCancellationUz.STATE_ENTERPRISE]: 'Реализация государственным органам и организациям',
  [reasonCancellationUz.NO_RETAIL_USE]: 'Использование для собственных нужд покупателем',
  [reasonCancellationUz.ENTERPRISE_USE]: 'Использование для собственных нужд предприятием',
  [reasonCancellationUz.MEDICAL_PURPOSE]: 'Использование по медицинскому назначению',
  [reasonCancellationUz.FREE_PRESCRIPTION]: 'Отпуск по бесплатному рецепту',
  [reasonCancellationUz.DEFECT]: 'Брак',
  [reasonCancellationUz.EXPIRY]: 'Истек срок годности',
  [reasonCancellationUz.PRODUCT_RECALL]: 'Отзыв с рынка',
  [reasonCancellationUz.COMPLAINTS]: 'Рекламации',
  [reasonCancellationUz.DEMO_SAMPLES]: 'Демонстрационные образцы',
  [reasonCancellationUz.OTHER]: 'Другие причины',
  [reasonCancellationUz.DAMAGE_LOSS]: 'Утрата или повреждение товаров',
  [reasonCancellationUz.PROCESSING]: 'Использование для производственных нужд (переработка)',
  [reasonCancellationUz.EXPORT]: 'Экспорт',
}

export const reasonCancellationListUz = [
  {
    label: 'Безвозмездная передача',
    value: reasonCancellationUz.DONATION,
  },
  {
    label: 'Брак',
    value: reasonCancellationUz.DEFECT,
  },
  {
    label: 'Возврат физическому лицу (комитенту)',
    value: reasonCancellationUz.RETURN,
  },
  {
    label: 'Демонстрационные образцы',
    value: reasonCancellationUz.DEMO_SAMPLES,
  },
  {
    label: 'Дистанционная продажа',
    value: reasonCancellationUz.REMOTE_SALE,
  },
  {
    label: 'Другие причины',
    value: reasonCancellationUz.OTHER,
  },
  {
    label: 'Использование для производственных нужд (переработка)',
    value: reasonCancellationUz.PROCESSING,
  },
  {
    label: 'Использование для собственных нужд покупателем',
    value: reasonCancellationUz.NO_RETAIL_USE,
  },
  {
    label: 'Использование для собственных нужд предприятием',
    value: reasonCancellationUz.ENTERPRISE_USE,
  },
  {
    label: 'Использование по медицинскому назначению',
    value: reasonCancellationUz.MEDICAL_PURPOSE,
  },
  {
    label: 'Истек срок годности',
    value: reasonCancellationUz.EXPIRY,
  },
  {
    label: 'Конфискация товаров',
    value: reasonCancellationUz.CONFISCATION,
  },
  {
    label: 'Ликвидация предприятия',
    value: reasonCancellationUz.LIQUIDATION,
  },
  {
    label: 'Отзыв с рынка',
    value: reasonCancellationUz.PRODUCT_RECALL,
  },
  {
    label: 'Отпуск по бесплатному рецепту',
    value: reasonCancellationUz.FREE_PRESCRIPTION,
  },
  {
    label: 'Реализация государственным органам и организациям',
    value: reasonCancellationUz.STATE_ENTERPRISE,
  },
  {
    label: 'Рекламации',
    value: reasonCancellationUz.COMPLAINTS,
  },
  {
    label: 'Розничная реализация',
    value: reasonCancellationUz.RETAIL,
  },
  {
    label: 'Уничтожение',
    value: reasonCancellationUz.DESTRUCTION,
  },
  {
    label: 'Утрата или повреждение товаров',
    value: reasonCancellationUz.DAMAGE_LOSS,
  },
  {
    label: 'Экспорт',
    value: reasonCancellationUz.EXPORT,
  },
]

export const reasonCancellation = [
  {
    label: 'Брак',
    value: '1',
  },
  {
    label: 'Утрата',
    value: '2',
  },
  {
    label: 'Повреждение',
    value: '3',
  },
  {
    label: 'Уничтожение',
    value: '4',
  },
  {
    label: 'Использование для собственных нужд предприятия',
    value: '5',
  },
  {
    label: 'Отбор образцов',
    value: '6',
  },
  {
    label: 'Вывод из оборота при интернет торговле',
    value: '7',
  },
  {
    label: 'Возврат физическому лицу',
    value: '8',
  },
  {
    label: 'Для медицинского назначения',
    value: '9',
  },
  {
    label: 'Отпуск по бесплатному рецепту',
    value: '10',
  },
  {
    label: 'Экспорт в страны ЕАЭС',
    value: '11',
  },
  {
    label: 'Экспорт за пределы ЕАЭС',
    value: '12',
  },
  {
    label: 'Розничная продажа',
    value: '13',
  },
  {
    label: 'Выбыл (переработка)',
    value: '14',
  },
  {
    label: 'Приобретение государственным предприятием',
    value: '15',
  },
  {
    label: 'Использование для собственных нужд покупателем',
    value: '16',
  },
  {
    label: 'Конфискация',
    value: '17',
  },
  {
    label: 'Другое',
    value: '18',
  },
];

export const contentBodyConfirm = (
  <div>
    {Translate('Вы действительно хотите отменить отправку?')}
    <br />
    {Translate('Все введенные данные будут потеряны')}
  </div>
);

export const actionSelectOptions = [
  {
    value: 'delete',
    label: Translate('Удалить'),
  },
];
