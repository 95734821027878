import React, { PureComponent } from 'react';

import { SingleSelect } from '@crpt/react-select';
import { ProfileService } from '../../services/ProfileService';

export default class EdoSelect extends PureComponent {
  static displayName = 'SingleSelect';

  select;

  state = {
    values: [],
    isLoading: false
  };

  componentDidMount() {
    if (this.props.values && this.props.values.length) {
      try {
        this.setState({ values: this.props.values });
        this.select.onSelect(
          { preventDefault: () => {} },
          this.props.values[0]
        );
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.values &&
      this.props.values !== prevProps.values &&
      this.props.values.length &&
      this.props.values[0].id
    ) {
      try {
        this.setState({ values: this.props.values });
        this.select.onSelect(
          { preventDefault: () => {} },
          this.props.values[0]
        );
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  onChange = val => {
    if (!val) return;
    this.setState({ isLoading: true });

    ProfileService.getEdoOperators(val)
      .then(answer => {
        this.setState({
          isLoading: false,
          values: answer.data.map(e => ({
            id: e.id,
            title: e.name,
            type: e
          }))
        });
      })
      .catch(err => console.warn('canceled or err', err));
  };

  render() {
    const {
      onChange,
      isLoading,
      values,
      selectedId,
      onSelect,
      onRef,
      ...other
    } = this.props;

    return (
      <SingleSelect
        {...other}
        onChange={this.onChange}
        isLoading={this.state.isLoading}
        values={this.state.values.length ? this.state.values : values}
        selectedId={selectedId}
        onSelect={onSelect}
        onRef={el => {
          onRef && onRef(el);
          this.select = el;
        }}
      />
    );
  }
}
