import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Table from '@crpt/react-table';
import { Icon } from '@crpt/react-icon';
import { Button } from '@crpt/react-button';
import { tableDefaultTheme } from '../../../theme/ru-tabaco/table';
import { buttonTabItemTheme } from '../../../theme/ru-tabaco/button';
import Modal from '../../Modal/Modal';
import {
  customContentStyles,
  H1,
  H2,
  WrapperTable,
  WrapperTables,
  TableCell,
  TableHeaderCell,
  TableHeaderGroupCell,
  WrapperCircleNumber,
  CircleNumber,
  ModalCloseCross,
  Footer,
  WrapperDescription,
  DescriptionHeader,
  DescriptionText,
  PreTable,
  PreTableItem,
  ShortDescriptionWrapper,
  HeaderWrapper,
  HeaderIcon,
} from './ReportHelp.styled';
import ReportHelpData from './ReportHelpData';
import { Translate } from '../../../common_components/Translate/Translate';

class ReportHelp extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    reportUrl: PropTypes.string.isRequired
  };

  state = {
    showDescription: false
  }

  shouldComponentUpdate (nextProps, nextState) {
    if ((nextProps.isOpen !== this.props.isOpen) || (nextState.showDescription !== this.state.showDescription)) {
      return true
    } else {
      return false;
    }
  }

  handleShowDescription = () => {
    if (this.state.showDescription) this.setState({showDescription: false})
    else this.setState({showDescription: true})
  }

  createColumn = (data) => {
    return {
      Header: data.Header,
      accessor: data.accessor,
      minWidth: data.minWidth,
      Cell: props => (
        Array.isArray(props.value)
        ? <WrapperCircleNumber>{props.value.map((item, index) => <CircleNumber key={index}>{item}</CircleNumber>)}</WrapperCircleNumber>
        : props.value
      ),
      style: data.style,
      headerStyle: data.headerStyle
    }
  }

  getColumns = (headers) => {
    return headers.map(item => {
      if (item.subheaders && item.subheaders.length > 0) {
        let subheaders = item.subheaders.map(subItem => this.createColumn(subItem))
        return {
          Header: item.Header,
          style: item.style,
          headerStyle: item.headerStyle,
          columns: subheaders
        }
      } else {
        return this.createColumn(item)
      }
    })
  }

  getPreTable = (data) => {
    let minWidth = 0;
    const preItems = data.map((item, index) => {
      minWidth+=item.width;
      return <PreTableItem key={index} itemWidth={item.width}>{item.name}</PreTableItem>
    })
    return <PreTable preWidth={minWidth}>{preItems}</PreTable>
  }

  render() {
    const {
      onRequestClose,
      isOpen,
      reportUrl,
      name,
      description: shortDescription,
      iconURL,
      iconName,
    } = this.props
    const { showDescription } = this.state

    const dataForUrlExist = reportUrl in ReportHelpData ? true : false;

    const contentTables = dataForUrlExist && ReportHelpData[reportUrl]['tables'].map((itemData, indexData) => {
      const tables = itemData.tables.map((table, indexTable) => {
        return <WrapperTable key={indexTable}>
          {table.preTable ? this.getPreTable(table.preTable) : null }
          <Table
            data={table.data}
            columns={this.getColumns(table.headers)}
            theme={{ Table: tableDefaultTheme }}
            getTheadGroupThProps={() => ({
              theme: {Table: { HeaderCell: TableHeaderGroupCell }}
            })}
            getTheadThProps={() => ({
              theme: {Table: { HeaderCell: TableHeaderCell }}
            })}
            getTdProps={() => ({
              theme: {Table: { Cell: TableCell }}
            })}
            minRows={0}
            defaultPageSize={30}
            striped
            highlight
            showPagination={false}
          />
        </WrapperTable>
      })

      return (
        <div key={indexData}>
          <H2>{itemData.title}</H2>
          <WrapperTables>
            {tables}
            {itemData.otherContent || null}
          </WrapperTables>
          {itemData.afterTable || null }
        </div>
      )
    })

    const description = dataForUrlExist && ReportHelpData[reportUrl]['description']
    ? <WrapperDescription>
        <DescriptionHeader onClick={this.handleShowDescription}>
          <Icon
            type={showDescription ? 'up-arrow' : 'down-arrow'}
            spacingRight={12}
            fill="#55B5E7"
          />
          {Translate('Общее описание')}
        </DescriptionHeader>
        {showDescription && <DescriptionText>{ReportHelpData[reportUrl]['description']}</DescriptionText>}
      </WrapperDescription>
    : null

    return (
      <Modal
        isOpen={isOpen}
        style={
          {
            content: {...customContentStyles.content, maxWidth: dataForUrlExist ? ReportHelpData[reportUrl]['maxWidth'] : '1360px'},
            overlay: customContentStyles.overlay
          }
        }
        onRequestClose={onRequestClose}
        bodyOpenClassName="ReactModal__Body--open-reportHelp"
      >
        <ModalCloseCross>
          <Icon
            onClick={onRequestClose}
            width={19}
            height={19}
            type="cross"
            fill="#52535a"
          />
        </ModalCloseCross>

        {dataForUrlExist
        ? <Fragment>
            <HeaderWrapper>
              <HeaderIcon>
                {iconURL ?
                  <img src={iconURL} height={64} width={64} />
                  :
                  <Icon type = {iconName} size={64} />
                }
              </HeaderIcon>
              <div>
                <H1>{name || ReportHelpData[reportUrl]['h1']}</H1>
                {shortDescription
                  ? <ShortDescriptionWrapper>
                    {shortDescription}
                  </ShortDescriptionWrapper>
                  : null
                }
              </div>
            </HeaderWrapper>
            {description}
            {contentTables}
          </Fragment>
        : <H1 style={{marginLeft: '20px'}}>{Translate('Информация недоступна.')}</H1>
        }

        <Footer>
          <Button
            theme={{ Button: buttonTabItemTheme}}
            onClick={onRequestClose}
          >
            {Translate('Закрыть')}
          </Button>
        </Footer>
      </Modal>
    )
  }
}

export default ReportHelp;
