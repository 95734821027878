import React from 'react';
import { kopecksToRubels } from '../../DocumentDetails.utils';
import {
  NdsBar,
  Row,
  RowLabel,
  RowText,
  RowNoBorder,
} from '../../DocumentDetails.styled';
import { Value } from 'src/fragments/Table/Cell/value';
import { GoodsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from '../../../Grid/Grid.styled';
import { getIconNameByPackType } from '../../../TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { Translate } from '../../../../common_components/Translate/Translate';

export const productsKey = 'importingProducts';

export const BeforeTable = ({ selectedDocument, ...rest }) => {
  const {
    exciseDocInfo: { exciseDocNumber, exciseDocDate, exciseAmount },
  } = selectedDocument;
  return (
    <NdsBar>
      <RowNoBorder>
        <RowLabel>{Translate('Номер квитанции об уплате акциза')}</RowLabel>
        <RowText>{exciseDocNumber || '-'}</RowText>
      </RowNoBorder>
      <RowNoBorder>
        <RowLabel>{Translate('Дата квитанции об уплате акциза')}</RowLabel>
        <RowText>{exciseDocDate || '-'}</RowText>
      </RowNoBorder>
      <RowNoBorder>
        <RowLabel>{Translate('Сумма акциза')}</RowLabel>
        <RowText>{exciseAmount || '-'}</RowText>
      </RowNoBorder>
    </NdsBar>
  );
};

export const columns = [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: '№ п/п',
    width: 60,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 200,
  },
  {
    id: 'name',
    accessor: 'name',
    Cell: ({ row }) => {
      return <Value>{row.name || '-'}</Value>;
    },
    Header: Translate('Наименование товара'),
    sortDependence: 'tnved',
    width: 'auto',
  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = (row.packageType || '').toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{packTrivialTypesKZ[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    width: 'auto',
  },
  {
    id: 'cis',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код идентификации'),
    width: 'auto',
  },
];
