import { allowedCategoryStatuses } from './Auth.constants';
import { deleteAllCookies } from 'src/utils/cookieHelpers';
import {getCookie} from "../../utils/cookieHelpers";

export const logoutUser = () => {
  const pointOfEnter = getCookie('pointOfEnter') || '/login-kep';
  deleteAllCookies();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = `${window.env.REACT_APP_ELK_URL}${pointOfEnter}`
}

export function getRedirectUrl() {
  if (window.location.hostname === 'ismotp.crptech.ru') {
    return `${window.location.protocol}//tobacco.crpt.ru${window.location.pathname}${window.location.search}${window.location.hash}`;
  }
}

export function getProfileUrl() {
  return window.env.REACT_APP_ELK_URL + '/profile';
}

/**
 * Filter not allowed product categories and map to link components
 * @param {Array} productGroups Array of product groups info
 * @param {Array} userCategories Array of available user product categories
 * @returns {Array} Array of filtered product categories that allowed to link
 */
export const getAllowedCategories = (productGroups = [], userCategories = []) =>
  userCategories.filter((category => {
    const productGroup = productGroups.find(group => group.code === category.name);
    if (productGroup && productGroup.productGroupStatus === 'COMMERCIAL') return allowedCategoryStatuses.includes(category.status);
    return true;
  }));

export const getAllowedOGVCategories = (productGroup = [], availableProductGroups = []) =>
  productGroup.filter(group => availableProductGroups.includes(group.id));
