import React from 'react';
import { compose, setDisplayName } from 'recompact';
import { SingleSelect } from '../../../Fields';
import { okvedValues } from './constants';
import { PARTICIPANT_TYPES } from '../../../../../constants';
import { withMountCycle, withRenderNothingNot } from '../../../utils';
import { required } from '../../../utils/validations';

const Okved = () => (
  <SingleSelect
    name="okved"
    placeholder="ОКВЭД"
    values={okvedValues}
    validate={required}
  />
);


export default compose(
  withRenderNothingNot([PARTICIPANT_TYPES.PRODUCER]),
  setDisplayName('Okved'),
  withMountCycle('okved', null),
)(Okved);
