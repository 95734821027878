import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ErrorMessage = styled.div`
  color: #ff3c21;
  margin-bottom: 10px;
`;

export const RegistrationButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Placeholder = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ContentWrap = styled.div`
  top: -60px;
  width: 100%;
  position: relative;
`;

export const Title = styled.div`
  font-size: 16px;
  margin: 40px 0 16px;
`;

export const Wrap = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;

export const DefaultWrap = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 3px;
`;

export const IconWrap = styled.div`
  height: 129px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Outer = styled.div`
  display: table-row;
  height: auto;
  background: #fcfcfc;
`;

export const Inner = styled.div`
  display: table-cell;
  height: auto;
  box-sizing: border-box;
`;

export const EnterWrap = styled.div`
  line-height: 32px;
  font-size: 32px;
  color: #52535a;
  margin-bottom: 26px;
`;

export const FormWrap = styled.div`
  margin-top: -100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

export const AlreadyRegisteredInfo = styled.div`
  margin-top: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  color: #52535a;
  font-weight: 300;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #55b5e7;
  font-weight: 400;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const StyledA = styled.a`
  text-decoration: none;
  color: #55b5e7;
  font-weight: 400;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const FooterLinks = styled.div`
  min-height: 142px;
  width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  margin-top: auto;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const FooterText = styled.div`
  font-size: 16px;
`;

export const FooterLink = styled.a`
  text-decoration: none;
  color: #55b5e7;
`;
