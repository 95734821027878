import {handleActions} from "redux-actions";
import * as actions from "./Aggregation.actions";

const data = handleActions(
    {
        [actions.documentLoaded]: (state, { payload = null }) => payload,
        [actions.unmounted]: () => ({}),
    },
    {},
);

export default {
    data,
}
