import { Value } from '../../../fragments/Table/Cell/value';
import { Translate } from '../../../common_components/Translate/Translate';
import { Link, NoWrap } from '../../Grid/Grid.styled';
import React from 'react';
import { isNull } from 'lodash';

export const columns = (disabled) => [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    width: 100,
  },
  {
    id: 'cis',
    accessor: row => {
      return disabled ? <Value>{row.cis}</Value> : (
        <Link
          onClick={e => {
            e.preventDefault();
            window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
          }}
        >
          <NoWrap>{row.cis}</NoWrap>
        </Link>
      );
    },
    Header: Translate('Код идентификации'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'accepted',
    accessor: row => {
      return <Value>{Translate(isNull(row.accepted)
        ? 'Сведения отсутствуют'
        : row.accepted
          ? 'Принят'
          : 'Не принят'
      )}</Value>;
    },
    Header: Translate('Статус приемки'),
    width: 'auto',
  }
];
