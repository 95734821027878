import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 434,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
            <li>Производитель</li>
          </ul>,
          'col3': 'Наименование производителя товара'
        },
        {
          'col1': [2, 4],
          'col2': <ul>
            <li>Производитель</li>
          </ul>,
          'col3': <div>(Объём произведённого товара на территории РК данного производителя / Общий объём произведённого товара) <strong>×</strong> 100%.<br />
          Общий объём произведённого товара (в пачках и тенге) = Объём произведённого товара на территории РК (в пачках и тенге) + Объём импортированного товара на территорию РФ (в пачках и рублях)</div>
        },
        {
          'col1': [3, 5],
          'col2': <ul>
            <li>Производитель</li>
          </ul>,
          'col3': <div>(Объём импортированного товара на территорию РК данного производителя / Общий объём произведённого товара) <strong>×</strong> 100%.<br />
          Общий объём произведённого товара (в пачках и тенге) = Объём произведённого товара на территории РК (в пачках и тенге) + Объём импортированного товара на территорию РФ (в пачках и рублях)</div>
        },
        {
          'col1': [6, 8],
          'col2': <ul>
            <li>Сводный</li>
          </ul>,
          'col3': <div>(Объём произведённого товара на территории РК / Общий объём произведённого товара на территории РК) <strong>×</strong> 100%.<br />
          Общий объём произведённого товара на территории РК (в пачках и тенге) = Объём произведённого товара на территории РК (в пачках и рублях) + Объём импортированного товара на территорию РФ (в пачках и рублях)</div>
        },
        {
          'col1': [7, 9],
          'col2': <ul>
            <li>Сводный</li>
          </ul>,
          'col3': <div>(Объём импортированного товара на территорию РК / Общий объём произведённого товара) <strong>×</strong> 100%.<br />
          Общий объём произведённого товара (в пачках и тенге) = Объём произведённого товара на территории РК (в пачках и рублях) + Объём импортированного товара на территорию РФ (в пачках и рублях)</div>
        },
      ]
    }
  ]
}
