import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    boxSizing: 'border-box',
    position: 'relative',
    minWidth: 770,
    width: 900,
    maxHeight: '95vh',
    padding: '20px 40px 0',
    borderRadius: 4,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
    '& > button': {
      width: 240,
    },
  },
  delAndDelDoc: {
    height: '60px !important',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
  iconCloseWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  divider: {
    height: 1,
    width: '100%',
    background: '#f2f2f2',
  },
  documentFormDateInput: {
    '& > div': {
      height: 60,
      width: 402,
      borderColor: 'rgba(196,196,196,0.25) !important',
      '& div': {
        display: 'none',
      },
    },
    '& .Mui-error': {
      borderColor: '#ff4b4b !important',
    },
    '& > label': {
      transform: 'translate3d(16px, 20px, 0) scale(0.85714)',
      fontSize: '16px !important',
      color: 'rgba(82,83,90,0.7) !important'
    }
  }
});
export default useStyles;
