export const countryCodes = [
  { id: 0, title: 'AD - АНДОРРА', type: 'AD' },
  { id: 1, title: 'AE - ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ', type: 'AE' },
  { id: 2, title: 'AF - АФГАНИСТАН', type: 'AF' },
  { id: 3, title: 'AG - АНТИГУА И БАРБУДА', type: 'AG' },
  { id: 4, title: 'AI - АНГИЛЬЯ', type: 'AI' },
  { id: 5, title: 'AL - АЛБАНИЯ', type: 'AL' },
  { id: 6, title: 'AM - АРМЕНИЯ', type: 'AM' },
  { id: 7, title: 'AO - АНГОЛА', type: 'AO' },
  { id: 8, title: 'AQ - АНТАРКТИДА', type: 'AQ' },
  { id: 9, title: 'AR - АРГЕНТИНА', type: 'AR' },
  { id: 10, title: 'AS - АМЕРИКАНСКОЕ САМОА', type: 'AS' },
  { id: 11, title: 'AT - АВСТРИЯ', type: 'AT' },
  { id: 12, title: 'AU - АВСТРАЛИЯ', type: 'AU' },
  { id: 13, title: 'AW - АРУБА', type: 'AW' },
  { id: 14, title: 'AX - ЭЛАНДСКИЕ ОСТРОВА', type: 'AX' },
  { id: 15, title: 'AZ - АЗЕРБАЙДЖАН', type: 'AZ' },
  { id: 16, title: 'BA - БОСНИЯ И ГЕРЦЕГОВИНА', type: 'BA' },
  { id: 17, title: 'BB - БАРБАДОС', type: 'BB' },
  { id: 18, title: 'BD - БАНГЛАДЕШ', type: 'BD' },
  { id: 19, title: 'BE - БЕЛЬГИЯ', type: 'BE' },
  { id: 20, title: 'BF - БУРКИНА-ФАСО', type: 'BF' },
  { id: 21, title: 'BG - БОЛГАРИЯ', type: 'BG' },
  { id: 22, title: 'BH - БАХРЕЙН', type: 'BH' },
  { id: 23, title: 'BI - БУРУНДИ', type: 'BI' },
  { id: 24, title: 'BJ - БЕНИН', type: 'BJ' },
  { id: 25, title: 'BL - СЕН-БАРТЕЛЕМИ', type: 'BL' },
  { id: 26, title: 'BM - БЕРМУДЫ', type: 'BM' },
  { id: 27, title: 'BN - БРУНЕЙ-ДАРУССАЛАМ', type: 'BN' },
  { id: 28, title: 'BO - БОЛИВИЯ (МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО)', type: 'BO' },
  { id: 29, title: 'BQ - БОНЭЙР, СИНТ-ЭСТАТИУС И САБА', type: 'BQ' },
  { id: 30, title: 'BR - БРАЗИЛИЯ', type: 'BR' },
  { id: 31, title: 'BS - БАГАМЫ', type: 'BS' },
  { id: 32, title: 'BT - БУТАН', type: 'BT' },
  { id: 33, title: 'BV - ОСТРОВ БУВЕ', type: 'BV' },
  { id: 34, title: 'BW - БОТСВАНА', type: 'BW' },
  { id: 35, title: 'BY - БЕЛАРУСЬ', type: 'BY' },
  { id: 36, title: 'BZ - БЕЛИЗ', type: 'BZ' },
  { id: 37, title: 'CA - КАНАДА', type: 'CA' },
  { id: 38, title: 'CC - КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА', type: 'CC' },
  { id: 39, title: 'CD - КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА', type: 'CD' },
  { id: 40, title: 'CF - ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА', type: 'CF' },
  { id: 41, title: 'CG - КОНГО', type: 'CG' },
  { id: 42, title: 'CH - ШВЕЙЦАРИЯ', type: 'CH' },
  { id: 43, title: 'CI - КОТ Д ИВУАР', type: 'CI' },
  { id: 44, title: 'CK - ОСТРОВА КУКА', type: 'CK' },
  { id: 45, title: 'CL - ЧИЛИ', type: 'CL' },
  { id: 46, title: 'CM - КАМЕРУН', type: 'CM' },
  { id: 47, title: 'CN - КИТАЙ', type: 'CN' },
  { id: 48, title: 'CO - КОЛУМБИЯ', type: 'CO' },
  { id: 49, title: 'CR - КОСТА-РИКА', type: 'CR' },
  { id: 50, title: 'CU - КУБА', type: 'CU' },
  { id: 51, title: 'CV - КАБО-ВЕРДЕ', type: 'CV' },
  { id: 52, title: 'CW - КЮРАСАО', type: 'CW' },
  { id: 53, title: 'CX - ОСТРОВ РОЖДЕСТВА', type: 'CX' },
  { id: 54, title: 'CY - КИПР', type: 'CY' },
  { id: 55, title: 'CZ - ЧЕХИЯ', type: 'CZ' },
  { id: 56, title: 'DE - ГЕРМАНИЯ', type: 'DE' },
  { id: 57, title: 'DJ - ДЖИБУТИ', type: 'DJ' },
  { id: 58, title: 'DK - ДАНИЯ', type: 'DK' },
  { id: 59, title: 'DM - ДОМИНИКА', type: 'DM' },
  { id: 60, title: 'DO - ДОМИНИКАНСКАЯ РЕСПУБЛИКА', type: 'DO' },
  { id: 61, title: 'DZ - АЛЖИР', type: 'DZ' },
  { id: 62, title: 'EC - ЭКВАДОР', type: 'EC' },
  { id: 63, title: 'EE - ЭСТОНИЯ', type: 'EE' },
  { id: 64, title: 'EG - ЕГИПЕТ', type: 'EG' },
  { id: 65, title: 'EH - ЗАПАДНАЯ САХАРА', type: 'EH' },
  { id: 66, title: 'ER - ЭРИТРЕЯ', type: 'ER' },
  { id: 67, title: 'ES - ИСПАНИЯ', type: 'ES' },
  { id: 68, title: 'ET - ЭФИОПИЯ', type: 'ET' },
  { id: 69, title: 'FI - ФИНЛЯНДИЯ', type: 'FI' },
  { id: 70, title: 'FJ - ФИДЖИ', type: 'FJ' },
  { id: 71, title: 'FK - ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)', type: 'FK' },
  { id: 72, title: 'FM - МИКРОНЕЗИЯ (ФЕДЕРАТИВНЫЕ ШТАТЫ)', type: 'FM' },
  { id: 73, title: 'FO - ФАРЕРСКИЕ ОСТРОВА', type: 'FO' },
  { id: 74, title: 'FR - ФРАНЦИЯ', type: 'FR' },
  { id: 75, title: 'GA - ГАБОН', type: 'GA' },
  { id: 76, title: 'GB - СОЕДИНЕННОЕ КОРОЛЕВСТВО', type: 'GB' },
  { id: 77, title: 'GD - ГРЕНАДА', type: 'GD' },
  { id: 78, title: 'GE - ГРУЗИЯ', type: 'GE' },
  { id: 79, title: 'GF - ФРАНЦУЗСКАЯ ГВИАНА', type: 'GF' },
  { id: 80, title: 'GG - ГЕРНСИ', type: 'GG' },
  { id: 81, title: 'GH - ГАНА', type: 'GH' },
  { id: 82, title: 'GI - ГИБРАЛТАР', type: 'GI' },
  { id: 83, title: 'GL - ГРЕНЛАНДИЯ', type: 'GL' },
  { id: 84, title: 'GM - ГАМБИЯ', type: 'GM' },
  { id: 85, title: 'GN - ГВИНЕЯ', type: 'GN' },
  { id: 86, title: 'GP - ГВАДЕЛУПА', type: 'GP' },
  { id: 87, title: 'GQ - ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ', type: 'GQ' },
  { id: 88, title: 'GR - ГРЕЦИЯ', type: 'GR' },
  { id: 89, title: 'GS - ЮЖН. ДЖОРДЖИЯ И ЮЖН. САНДВИЧ. ОСТРОВА', type: 'GS' },
  { id: 90, title: 'GT - ГВАТЕМАЛА', type: 'GT' },
  { id: 91, title: 'GU - ГУАМ', type: 'GU' },
  { id: 92, title: 'GW - ГВИНЕЯ-БИСАУ', type: 'GW' },
  { id: 93, title: 'GY - ГАЙАНА', type: 'GY' },
  { id: 94, title: 'HK - ГОНКОНГ', type: 'HK' },
  { id: 95, title: 'HM - ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД', type: 'HM' },
  { id: 96, title: 'HN - ГОНДУРАС', type: 'HN' },
  { id: 97, title: 'HR - ХОРВАТИЯ', type: 'HR' },
  { id: 98, title: 'HT - ГАИТИ', type: 'HT' },
  { id: 99, title: 'HU - ВЕНГРИЯ', type: 'HU' },
  { id: 100, title: 'ID - ИНДОНЕЗИЯ', type: 'ID' },
  { id: 101, title: 'IE - ИРЛАНДИЯ', type: 'IE' },
  { id: 102, title: 'IL - ИЗРАИЛЬ', type: 'IL' },
  { id: 103, title: 'IM - ОСТРОВ МЭН', type: 'IM' },
  { id: 104, title: 'IN - ИНДИЯ', type: 'IN' },
  { id: 105, title: 'IO - БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ', type: 'IO' },
  { id: 106, title: 'IQ - ИРАК', type: 'IQ' },
  { id: 107, title: 'IR - ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)', type: 'IR' },
  { id: 108, title: 'IS - ИСЛАНДИЯ', type: 'IS' },
  { id: 109, title: 'IT - ИТАЛИЯ', type: 'IT' },
  { id: 110, title: 'JE - ДЖЕРСИ', type: 'JE' },
  { id: 111, title: 'JM - ЯМАЙКА', type: 'JM' },
  { id: 112, title: 'JO - ИОРДАНИЯ', type: 'JO' },
  { id: 113, title: 'JP - ЯПОНИЯ', type: 'JP' },
  { id: 114, title: 'KE - КЕНИЯ', type: 'KE' },
  { id: 115, title: 'KG - КЫРГЫЗСТАН', type: 'KG' },
  { id: 116, title: 'KH - КАМБОДЖА', type: 'KH' },
  { id: 117, title: 'KI - КИРИБАТИ', type: 'KI' },
  { id: 118, title: 'KM - КОМОРЫ', type: 'KM' },
  { id: 119, title: 'KN - СЕНТ-КИТС И НЕВИС', type: 'KN' },
  { id: 120, title: 'KP - КОРЕЯ (НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА)', type: 'KP' },
  { id: 121, title: 'KR - КОРЕЯ, РЕСПУБЛИКА', type: 'KR' },
  { id: 122, title: 'KW - КУВЕЙТ', type: 'KW' },
  { id: 123, title: 'KY - ОСТРОВА КАЙМАН', type: 'KY' },
  { id: 124, title: 'KZ - КАЗАХСТАН', type: 'KZ' },
  { id: 125, title: 'LA - ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА', type: 'LA' },
  { id: 126, title: 'LB - ЛИВАН', type: 'LB' },
  { id: 127, title: 'LC - СЕНТ-ЛЮСИЯ', type: 'LC' },
  { id: 128, title: 'LI - ЛИХТЕНШТЕЙН', type: 'LI' },
  { id: 129, title: 'LK - ШРИ-ЛАНКА', type: 'LK' },
  { id: 130, title: 'LR - ЛИБЕРИЯ', type: 'LR' },
  { id: 131, title: 'LS - ЛЕСОТО', type: 'LS' },
  { id: 132, title: 'LT - ЛИТВА', type: 'LT' },
  { id: 133, title: 'LU - ЛЮКСЕМБУРГ', type: 'LU' },
  { id: 134, title: 'LV - ЛАТВИЯ', type: 'LV' },
  { id: 135, title: 'LY - ЛИВИЯ', type: 'LY' },
  { id: 136, title: 'MA - МАРОККО', type: 'MA' },
  { id: 137, title: 'MC - МОНАКО', type: 'MC' },
  { id: 138, title: 'MD - МОЛДОВА, РЕСПУБЛИКА', type: 'MD' },
  { id: 139, title: 'ME - ЧЕРНОГОРИЯ', type: 'ME' },
  { id: 140, title: 'MF - СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)', type: 'MF' },
  { id: 141, title: 'MG - МАДАГАСКАР', type: 'MG' },
  { id: 142, title: 'MH - МАРШАЛЛОВЫ ОСТРОВА', type: 'MH' },
  { id: 143, title: 'MK - РЕСПУБЛИКА МАКЕДОНИЯ', type: 'MK' },
  { id: 144, title: 'ML - МАЛИ', type: 'ML' },
  { id: 145, title: 'MM - МЬЯНМА', type: 'MM' },
  { id: 146, title: 'MN - МОНГОЛИЯ', type: 'MN' },
  { id: 147, title: 'MO - МАКАО', type: 'MO' },
  { id: 148, title: 'MP - СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА', type: 'MP' },
  { id: 149, title: 'MQ - МАРТИНИКА', type: 'MQ' },
  { id: 150, title: 'MR - МАВРИТАНИЯ', type: 'MR' },
  { id: 151, title: 'MS - МОНТСЕРРАТ', type: 'MS' },
  { id: 152, title: 'MT - МАЛЬТА', type: 'MT' },
  { id: 153, title: 'MU - МАВРИКИЙ', type: 'MU' },
  { id: 154, title: 'MV - МАЛЬДИВЫ', type: 'MV' },
  { id: 155, title: 'MW - МАЛАВИ', type: 'MW' },
  { id: 156, title: 'MX - МЕКСИКА', type: 'MX' },
  { id: 157, title: 'MY - МАЛАЙЗИЯ', type: 'MY' },
  { id: 158, title: 'MZ - МОЗАМБИК', type: 'MZ' },
  { id: 159, title: 'NA - НАМИБИЯ', type: 'NA' },
  { id: 160, title: 'NC - НОВАЯ КАЛЕДОНИЯ', type: 'NC' },
  { id: 161, title: 'NE - НИГЕР', type: 'NE' },
  { id: 162, title: 'NF - ОСТРОВ НОРФОЛК', type: 'NF' },
  { id: 163, title: 'NG - НИГЕРИЯ', type: 'NG' },
  { id: 164, title: 'NI - НИКАРАГУА', type: 'NI' },
  { id: 165, title: 'NL - НИДЕРЛАНДЫ', type: 'NL' },
  { id: 166, title: 'NO - НОРВЕГИЯ', type: 'NO' },
  { id: 167, title: 'NP - НЕПАЛ', type: 'NP' },
  { id: 168, title: 'NR - НАУРУ', type: 'NR' },
  { id: 169, title: 'NU - НИУЭ', type: 'NU' },
  { id: 170, title: 'NZ - НОВАЯ ЗЕЛАНДИЯ', type: 'NZ' },
  { id: 171, title: 'OM - ОМАН', type: 'OM' },
  { id: 172, title: 'PA - ПАНАМА', type: 'PA' },
  { id: 173, title: 'PE - ПЕРУ', type: 'PE' },
  { id: 174, title: 'PF - ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ', type: 'PF' },
  { id: 175, title: 'PG - ПАПУА НОВАЯ ГВИНЕЯ', type: 'PG' },
  { id: 176, title: 'PH - ФИЛИППИНЫ', type: 'PH' },
  { id: 177, title: 'PK - ПАКИСТАН', type: 'PK' },
  { id: 178, title: 'PL - ПОЛЬША', type: 'PL' },
  { id: 179, title: 'PM - СЕНТ-ПЬЕР И МИКЕЛОН', type: 'PM' },
  { id: 180, title: 'PN - ПИТКЭРН', type: 'PN' },
  { id: 181, title: 'PR - ПУЭРТО-РИКО', type: 'PR' },
  { id: 182, title: 'PS - ПАЛЕСТИНА, ГОСУДАРСТВО', type: 'PS' },
  { id: 183, title: 'PT - ПОРТУГАЛИЯ', type: 'PT' },
  { id: 184, title: 'PW - ПАЛАУ', type: 'PW' },
  { id: 185, title: 'PY - ПАРАГВАЙ', type: 'PY' },
  { id: 186, title: 'QA - КАТАР', type: 'QA' },
  { id: 187, title: 'RE - РЕЮНЬОН', type: 'RE' },
  { id: 188, title: 'RO - РУМЫНИЯ', type: 'RO' },
  { id: 189, title: 'RS - СЕРБИЯ', type: 'RS' },
  { id: 190, title: 'RU - РОССИЯ', type: 'RU' },
  { id: 191, title: 'RW - РУАНДА', type: 'RW' },
  { id: 192, title: 'SA - САУДОВСКАЯ АРАВИЯ', type: 'SA' },
  { id: 193, title: 'SB - СОЛОМОНОВЫ ОСТРОВА', type: 'SB' },
  { id: 194, title: 'SC - СЕЙШЕЛЫ', type: 'SC' },
  { id: 195, title: 'SD - СУДАН', type: 'SD' },
  { id: 196, title: 'SE - ШВЕЦИЯ', type: 'SE' },
  { id: 197, title: 'SG - СИНГАПУР', type: 'SG' },
  { id: 198, title: 'SH - СВ. ЕЛЕНА, О. ВОЗНЕСЕНИЯ, ТР.-ДА-КУНЬЯ', type: 'SH' },
  { id: 199, title: 'SI - СЛОВЕНИЯ', type: 'SI' },
  { id: 200, title: 'SJ - ШПИЦБЕРГЕН И ЯН МАЙЕН', type: 'SJ' },
  { id: 201, title: 'SK - СЛОВАКИЯ', type: 'SK' },
  { id: 202, title: 'SL - СЬЕРРА-ЛЕОНЕ', type: 'SL' },
  { id: 203, title: 'SM - САН-МАРИНО', type: 'SM' },
  { id: 204, title: 'SN - СЕНЕГАЛ', type: 'SN' },
  { id: 205, title: 'SO - СОМАЛИ', type: 'SO' },
  { id: 206, title: 'SR - СУРИНАМ', type: 'SR' },
  { id: 207, title: 'SS - ЮЖНЫЙ СУДАН', type: 'SS' },
  { id: 208, title: 'ST - САН-ТОМЕ И ПРИНСИПИ', type: 'ST' },
  { id: 209, title: 'SV - ЭЛЬ-САЛЬВАДОР', type: 'SV' },
  { id: 210, title: 'SX - СЕН-МАРТЕН (нидерландская часть)', type: 'SX' },
  { id: 211, title: 'SY - СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА', type: 'SY' },
  { id: 212, title: 'SZ - ЭСВАТИНИ', type: 'SZ' },
  { id: 213, title: 'TC - ОСТРОВА ТЕРКС И КАЙКОС', type: 'TC' },
  { id: 214, title: 'TD - ЧАД', type: 'TD' },
  { id: 215, title: 'TF - ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ', type: 'TF' },
  { id: 216, title: 'TG - ТОГО', type: 'TG' },
  { id: 217, title: 'TH - ТАИЛАНД', type: 'TH' },
  { id: 218, title: 'TJ - ТАДЖИКИСТАН', type: 'TJ' },
  { id: 219, title: 'TK - ТОКЕЛАУ', type: 'TK' },
  { id: 220, title: 'TL - ТИМОР-ЛЕСТЕ', type: 'TL' },
  { id: 221, title: 'TM - ТУРКМЕНИСТАН', type: 'TM' },
  { id: 222, title: 'TN - ТУНИС', type: 'TN' },
  { id: 223, title: 'TO - ТОНГА', type: 'TO' },
  { id: 224, title: 'TR - ТУРЦИЯ', type: 'TR' },
  { id: 225, title: 'TT - ТРИНИДАД И ТОБАГО', type: 'TT' },
  { id: 226, title: 'TV - ТУВАЛУ', type: 'TV' },
  { id: 227, title: 'TW - ТАЙВАНЬ (КИТАЙ)', type: 'TW' },
  { id: 228, title: 'TZ - ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА', type: 'TZ' },
  { id: 229, title: 'UA - УКРАИНА', type: 'UA' },
  { id: 230, title: 'UG - УГАНДА', type: 'UG' },
  { id: 231, title: 'UM - МАЛЫЕ ТИХООКЕАН. ОТДАЛЕН. ОСТ-ВА С.Ш.', type: 'UM' },
  { id: 232, title: 'US - СОЕДИНЕННЫЕ ШТАТЫ', type: 'US' },
  { id: 233, title: 'UY - УРУГВАЙ', type: 'UY' },
  { id: 234, title: 'UZ - УЗБЕКИСТАН', type: 'UZ' },
  { id: 235, title: 'VA - ПАПСКИЙ ПРЕСТОЛ (ГОС.-ГОРОД ВАТИКАН)', type: 'VA' },
  { id: 236, title: 'VC - СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ', type: 'VC' },
  { id: 237, title: 'VE - ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)', type: 'VE' },
  { id: 238, title: 'VG - ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)', type: 'VG' },
  { id: 239, title: 'VI - ВИРГИНСКИЕ ОСТРОВА (США)', type: 'VI' },
  { id: 240, title: 'VN - ВЬЕТНАМ', type: 'VN' },
  { id: 241, title: 'VU - ВАНУАТУ', type: 'VU' },
  { id: 242, title: 'WF - УОЛЛИС И ФУТУНА', type: 'WF' },
  { id: 243, title: 'WS - САМОА', type: 'WS' },
  { id: 244, title: 'YE - ЙЕМЕН', type: 'YE' },
  { id: 245, title: 'YT - МАЙОТТА', type: 'YT' },
  { id: 246, title: 'ZA - ЮЖНАЯ АФРИКА', type: 'ZA' },
  { id: 247, title: 'ZM - ЗАМБИЯ', type: 'ZM' },
  { id: 248, title: 'ZW - ЗИМБАБВЕ', type: 'ZW' },
];