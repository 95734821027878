import { Translate } from '../../../common_components/Translate/Translate';
import { documentTypeNames, documentTypes } from '../../../constants';
import moment from 'moment';
import { importStatusesNames } from '../../../constants/documentStatuses';
import React from 'react';
import { get } from 'lodash';
import Icon from '@crpt/react-icon';
import { getIconNameByDocType } from '../../../common_components/Table/TableCells/Document/utils';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { OrderId } from '../../../common_components/Table/TableCells/Order/OrderId';
import { documentStatus, iconNamesByDocStatus } from '../RequestList/RequestList.constants';

const DocTypeWrap = styled.div`
  display: flex;
`;

export const TableHeading = styled.div`
  
`;

const DraftNumWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 36px;
  height: 70px;
`;


export const columns = [
  {
    Header: Translate('Регистрационный номер'),
    id: 'id',
    accessor: 'id',
  },
  {
    Header: Translate('Тип документа'),
    id: 'type',
    accessor: type => documentTypeNames[type],
  },
  {
    Header: Translate('Наименование отправителя'),
    id: 'senderName',
    accessor: 'senderName',
  },
  {
    Header: Translate('Наименование получателя'),
    id: 'receiverName',
    accessor: 'receiverName',
  },
  {
    Header: Translate('Дата документа'),
    id: 'docDate',
    accessor: data => `${moment(new Date(data.docDate)).format('DD.MM.YYYY')}`,
  },
  {
    Header: Translate('Статус'),
    id: 'status',
    accessor: ({ status }) => {
      const iconType = get(iconNamesByDocStatus, status, 'checking');
      return <DocTypeWrap>
        <Icon type={iconType} spacingRight={12} fill="rgb(82, 83, 90)" size={16} />
        <div>{Translate(importStatusesNames[status])}</div>
      </DocTypeWrap>
    },
    width: 240,
    sortable: false,
  },
];

export const options = [
  {
    accessor: 'type',
    id: 'type',
    render: (props, rowInfo) => {
      if (!rowInfo) return <React.Fragment />;
      const docType = get(rowInfo, 'original.type', '');
      return (
        <DocTypeWrap>
          <Icon
            type={getIconNameByDocType(docType)}
            spacingRight={12}
            fill="rgb(82, 83, 90)"
            size={16}
          />
          <div>{Translate(documentTypeNames[docType] || docType)}</div>
        </DocTypeWrap>
      );
    },
  },
  {
    accessor: d => d,
    id: 'id',
    render: (props, rowInfo) => {
      const docStatus = get(rowInfo, 'original.status', '');
      const id = get(rowInfo, 'original.id', '');
      const iconType = get(iconNamesByDocStatus, docStatus, 'checking');
      return <OrderId
        {...props}
        iconType={iconType}
        // href={`/documents/import/${encodeURIComponent(id)}`}
      />;
    },
  },
];

export const limit = 10;

export const docTypes = [
  { id: '1', title: documentTypeNames[documentTypes.EAS_CROSSBORDER], type: documentTypes.EAS_CROSSBORDER },
  { id: '2', title: documentTypeNames[documentTypes.LP_ACCEPT_GOODS], type: documentTypes.LP_ACCEPT_GOODS },
]

export const documentStatusValues = [
  {
    id: '1',
    title: 'Проверяется',
    type: 'IN_PROGRESS',
  },
  {
    id: '2',
    title: 'Обработан с ошибками',
    type: 'CHECKED_NOT_OK',
  },
  {
    id: '3',
    title: 'Ожидает приемку',
    type: 'WAIT_ACCEPTANCE',
  },
  {
    id: '4',
    title: 'Обработан успешно',
    type: 'CHECKED_OK',
  }
];
