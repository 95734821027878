import styled, { css } from 'styled-components';
import { withTheme } from '@material-ui/core';
import { get } from 'lodash';


export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px;
`;
export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const ListItem = withTheme(styled.li`
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 10px;
  ${props => props.active ? `background-color: ${get(props, 'theme.palette.primary.main') || '#f8ec31'} ;` : null}
  ${props => props.active ? 'color: #fff;' : ''}  
  ${props => props.displayNone ? `display: none;` : null}
  :not(:last-child) {
    margin-right: 5px;
  }
  ${props => !props.active ? css`
    :hover {
      z-index: 2;
      color: #0056b3;
      background-color:  #e9ecef;
    }  
  ` : null
  }
  :first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  :last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`);