import moment from 'moment';
import { isString } from 'lodash';

import { getPaginatedListReducers } from './reducerGenerators';
import faviconKZ from '../theme/favicons/faviconKZ.ico';
import faviconUZ from '../theme/favicons/faviconUZ.ico';

export function getSelected(state, selectors) {
  const selected = {};

  for (const key in selectors) {
    selected[key] = selectors[key](state);
  }

  return selected;
}

export { getPaginatedListReducers };

export function hasOnlyDigits(value) {
  return /^-{0,1}\d+$/.test(value);
}

export function getRubelsAsFloatFromKopecks(kopecks) {
  if (/\./.test(kopecks)) {
    return kopecks.split('.')[1].length === 1 ? kopecks + '0' : kopecks;
  }

  return !kopecks
    ? kopecks
    : (kopecks + '')
        .split('')
        .reverse()
        .map((v, i) => (i === 1 ? '.' + v : v))
        .reverse()
        .join('');
}

export function addSpacesBetweenThousands(val) {
  const str = val + '';

  const cel = str.split('.')[0];
  const points = str.split('.')[1];

  return (
    (cel || '0' + '')
      .split('')
      .reverse()
      .map((v, i) => ((i + 1) % 3 ? v : ' ' + v))
      .reverse()
      .join('') + (points ? '.' + points : '')
  ).trim();
}

export function addKopecksForFloat(val, sign = ',') {
  const splitted = val.split(sign);
  if (splitted.length > 2) return val;
  if (splitted.length === 2) {
    let secondPart = splitted[1];

    if (secondPart.length > 2) return val;

    if (secondPart.length === 2) {
      return `${splitted[0]},${splitted[1]}`;
    }

    if (secondPart.length === 1) {
      return `${splitted[0]},${splitted[1]}0`;
    }
  }

  if (splitted.length === 1) {
    return `${splitted[0]},00`;
  }
}

export function innerMerge(obj, ...others) {
  others.forEach(v => {
    for (const key in v) {
      if (typeof obj[key] === 'object' && typeof v[key] === 'object') {
        obj[key] = innerMerge({}, obj[key], v[key]);
      } else {
        obj[key] = v[key];
      }
    }
  });

  return obj;
}

export function getAbbrFromFio(fio) {
  try {
    const name = fio.trim().replace('"', '');
    const splitted = name.split(' ');
    return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
  } catch (e) {
    return 'НН';
  }
}

export function getValuesArr(obj, field) {
  var result = [];
  if (Array.isArray(obj)) {
    for (const i in obj) result = result.concat(getValuesArr(obj[i], field));
  } else {
    for (const prop in obj) {
      if (prop === field) result.push(obj[prop]);
      else if (
        (obj[prop] !== null && typeof obj[prop] === 'object') ||
        Array.isArray(obj[prop])
      ) {
        result = result.concat(getValuesArr(obj[prop], field));
      }
    }
  }
  return result;
}

export function convertCamelToConst(str) {
  if (typeof str !== 'string') return 'UNDEFINED';
  return (
    str
      .replace(/\.?([A-Z]+)/g, (x, y) => '_' + y)
      .replace(/^_/, '')
      .toUpperCase() || 'UNDEFINED'
  );
}

export function escapeUrlForRoutePath(url) {
  return url.replace(/\(/g, '\\(').replace(/\)/g, '\\)');
}

export function getPrefix() {
  return window.location.hostname === 'ismotp.crptech.ru'
    ? ''
    : window.location.hostname.indexOf('demo') === 0
    ? 'demo.'
    : 'test.';
}

export function getPrefixForReports() {
  if (window.location.hostname === 'ismotp.crptech.ru') {
    return '';
  }

  if (window.location.hostname === 'demo.ismotp.crptech.ru') {
    return 'demo.';
  }

  if (window.location.hostname === 'stable.ismotp.crptech.ru') {
    return 'stable.';
  }

  return '';
}

export function isRusPhone(value) {
  return /^(\+7)?(\d{10})$/.test(value) || /^(\+7)?(\d{10})#(\d+)$/.test(value);
}

export function isMaskedPhone(value) {
  return isRusPhone(clearMaskedPhone(value));
}

export function clearMaskedPhone(value) {
  return value
    ? value
        .replace(/\)/g, '')
        .replace(/\(/g, '')
        .replace(/-/g, '')
        .replace(/_/g, '')
        .replace(/\s/g, '')
    : value;
}

export function fireEvent(target, eventName) {
  if (target.fireEvent) target.fireEvent(`on${eventName}`);
  else {
    const event = document.createEvent('Events');
    event.initEvent(eventName, true, false);
    target.dispatchEvent(event);
  }
}

export function urlencode(str) {
  str = (str + '').toString();
  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/%20/g, '+');
}

export function makeid(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function notEmpty(value) {
  return /[а-яёa-z]+/i.test(value);
}

export function getFilenameWithoutExtension(filename) {
  try {
    return filename
      .split('.')
      .slice(0, -1)
      .join('.');
  } catch (e) {
    return filename;
  }
}

export const prepareDateForJSON = (date, format) => {
  if (isString(date)) {
    //YYYY-MM-DD
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return moment(date, 'YYYY-MM-DD').format(format);
    }
    //DD.MM.YYYY
    if (/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
      return moment(date, 'DD.MM.YYYY').format(format);
    }
    //ISOString
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(date)) {
      return moment(date).format(format);
    }
    //other format
    return date;
  } else {
    return moment(date).format(format);
  }
};

export const setFavicon = countryCode => {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = countryCode === 'KZ' ? faviconKZ : faviconUZ;
  document.title = countryCode === 'KZ' ? 'ИС МПТ' : 'Asl Belgisi';
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const setGlobalThemeClass = countryCode => {
  const theme = countryCode === 'KZ' ? 'themeKZGlobal' : 'themeUZGlobal';
  document.querySelector('body').classList.add(theme);
};

export const addLeadingZeros = (string = '', maxNumberZero = 16) =>
  typeof string === 'string' &&
  '0'.repeat(maxNumberZero - string.length) + string;

export const deleteLeadingZeros = (string = '') => {
  if (typeof string === 'string') {
    for (let i = 0; i < string.length; i++) {
      if (string[i] !== '0') {
        return string.slice(i);
      }
    }
  }
};

export const formatUZPhoneWithPlus = (number) => {
  const onlyNums = number ? number.replace(/[^\d+]/g, '') : ""
  if (!onlyNums) return onlyNums;
  if (onlyNums.length > 1)  return `+${onlyNums.slice(1)}`;
  return `+${onlyNums.replace(/[^\d]/g, '')}`
}

export const formatUZPhoneWithPrefix = (value) => {
  if (!value || value === '—') {
    return value;
  }
  const prefix = '+998';
  const onlyNums = value.replace('+998', '').replace(/[^\d]/g, '')
  if (onlyNums.length === 0) {
    return onlyNums;
  }
  if (onlyNums.length <= 2) {
    return `${prefix} (${onlyNums}`
  }
  if (onlyNums.length <= 5) {
    return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 5)}`
  }
  if (onlyNums.length <= 7) {
    return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 5)}-${onlyNums.slice(5, 7)}`
  }

  return `${prefix} (${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 5)}-${onlyNums.slice(5, 7)}-${onlyNums.slice(7, 9)}`
}