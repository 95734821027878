import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import 'moment/locale/ru';
import { get } from 'lodash';
import { Column, Container } from '@crpt/react-page-layout';

import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { Card } from '@crpt/react-card';
import { TwoLineLabel } from '@crpt/react-label';
import { FormLine } from '@crpt/react-form';
import { ContentWrapper } from '../common/styled';
import { participantNames } from '../../constants';
import { Avatar } from '../Profile/Avatar';
import {
  CardHeading,
  DocInfoWrap,
  DocumentInfoHeadingLabel,
  CISInformationWrap,
} from './styled';
import { Translate } from '../../common_components/Translate/Translate';
import { pgTypesNames } from '../../constants/productGroups';
import { countryCodeFlags } from '../../constants';

moment.locale('ru');

class BasicInfo extends Component {
  static propTypes = {
    portalDownloadButtonId: PropTypes.string,
    selectedParticipant: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    portalDownloadButtonId: 'conteinerIdIsAbsent',
  };

  state = {
    bottomPart: null,
    buttonContainer: null,
  };

  componentDidMount() {
    const { bottomPartId, portalDownloadButtonId } = this.props;

    this.setState({
      bottomPart: document.getElementById(bottomPartId),
      buttonContainer: document.getElementById(portalDownloadButtonId),
    });
  }

  render() {
    const { selectedParticipant, isKZ } = this.props;
    const { bottomPart, buttonContainer } = this.state;

    const downloadXls = get(
      this.props.actions,
      'ParticipantDetails.downloadXls',
      false,
    );

    const status = get(selectedParticipant, 'status', false);
    const fullName = get(selectedParticipant, 'fullName', false);
    const shortName = get(selectedParticipant, 'shortName', false);
    const statusName = get(selectedParticipant, 'statusName', false);
    const inn = get(selectedParticipant, countryCodeFlags.isKZ ? 'inn' : 'tin', false);
    const kppElk = get(selectedParticipant, 'kppElk', false);
    const ogrn = get(selectedParticipant, 'ogrn', false);
    const head = get(selectedParticipant, 'head', []);
    const legalAddress = get(selectedParticipant, 'legalAddress', false);
    const addressRegion = get(selectedParticipant, 'addressRegion', '');
    const addressDistrict = get(selectedParticipant, 'addressDistrict', '');
    const productGroupsAndRoles = get(selectedParticipant, 'productGroupsAndRoles', []);
    const actualAddress = get(selectedParticipant, 'actualAddress', false);
    const email = get(selectedParticipant, 'email', false);
    const phone = get(selectedParticipant, 'phone', false);
    const roles = get(selectedParticipant, 'roles', []);
    const image = get(selectedParticipant, 'image', false);
    const registrationDate = get(selectedParticipant, 'registrationDate', false);
    const regDateFormatted =
      registrationDate && moment(registrationDate).format('DD.MM.YYYY');
    const participantId = get(selectedParticipant, 'id', false);
    const isEvader = status === 'REGISTERED_EVADER' || status === 'CHECKED_EVADER' || status === 'BLOCKED_EVADER';
    const buttonTheme = { Button: get(this.props, 'theme.tab.item') };

    return (
      <div>
        {buttonContainer &&
        ReactDOM.createPortal(
          <Button
            theme={buttonTheme}
            onClick={() => {
              participantId && downloadXls(participantId);
            }}
          >
            {Translate('Скачать Реквизиты')}
            <Icon type="download" spacingLeft={16}/>
          </Button>,
          buttonContainer,
        )}
        <Card>
          <CardHeading>{Translate('Реквизиты')}</CardHeading>
          <CISInformationWrap>
            <Container fillWidth>
              <Column col={10}>
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Наименование компании')}
                    </DocumentInfoHeadingLabel>
                    <FormLine>
                      <TwoLineLabel heading={Translate('Полное')}>
                        {fullName || '—'}
                      </TwoLineLabel>
                    </FormLine>
                    <FormLine>
                      <TwoLineLabel heading={Translate('Краткое')}>
                        {shortName || '—'}
                      </TwoLineLabel>
                    </FormLine>
                  </div>
                </DocInfoWrap>
                <DocInfoWrap last>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Налоговые данные')}
                    </DocumentInfoHeadingLabel>
                    <FormLine>
                      <TwoLineLabel
                        heading={isKZ ? Translate('ИИН/БИН') : Translate('ИНН/ПИНФЛ')}
                      >
                        {inn || '—'}
                      </TwoLineLabel>
                    </FormLine>
                    {!isKZ && (
                      <FormLine>
                        <TwoLineLabel
                          heading={Translate('Налоговый статус')}
                        >
                          {statusName || '—'}
                        </TwoLineLabel>
                      </FormLine>
                    )}
                  </div>
                </DocInfoWrap>
              </Column>
              <Column col={10}>
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Контактные данные')}
                    </DocumentInfoHeadingLabel>
                    {
                      (!isEvader || countryCodeFlags.isKZ) && (
                        <FormLine>
                          <TwoLineLabel heading={Translate('Руководитель')}>
                            {head.join(', ') || '—'}
                          </TwoLineLabel>
                        </FormLine>
                      )
                    }
                    <FormLine>
                      <TwoLineLabel heading={Translate('Юридический адрес')}>
                        {legalAddress || '—'}
                      </TwoLineLabel>
                    </FormLine>
                    <FormLine>
                      <TwoLineLabel heading={Translate('Регион и район регистрации')}>
                        {addressRegion && addressDistrict ? `${addressRegion} ${addressDistrict}` : '—'}
                      </TwoLineLabel>
                    </FormLine>
                    {
                      (!isEvader || countryCodeFlags.isKZ) && (
                        <Fragment>
                          <FormLine>
                            <TwoLineLabel heading={Translate('Фактический адрес')}>
                              {actualAddress || '—'}
                            </TwoLineLabel>
                          </FormLine>
                          <FormLine>
                            <TwoLineLabel heading={Translate('E-mail')}>
                              {email || '—'}
                            </TwoLineLabel>
                          </FormLine>
                          <FormLine>
                            <TwoLineLabel heading={Translate('Телефон')}>
                              {phone || '—'}
                            </TwoLineLabel>
                          </FormLine>
                        </Fragment>
                      )
                    }
                  </div>
                </DocInfoWrap>
              </Column>
              <Column>{image && <Avatar src={image}/>}</Column>
            </Container>
          </CISInformationWrap>
        </Card>
        {
          ((!isEvader || countryCodeFlags.isKZ) && Object.keys(productGroupsAndRoles).length > 0) && (
            <ContentWrapper>
              <Card>
                <CardHeading>
                  {Translate('Товарные группы участника')}
                </CardHeading>
                {
                  productGroupsAndRoles.map(el => (
                    <Container key={el.code} fillWidth>
                      <Column col={10}>
                        <FormLine>
                          <TwoLineLabel heading={Translate('Товарная группа')}>
                            {Translate(pgTypesNames[el.code])}
                          </TwoLineLabel>
                        </FormLine>
                      </Column>
                      <Column col={10}>
                        <FormLine>
                          <TwoLineLabel heading={Translate('Тип участника')}>
                            {el.types.map((item, i) => (
                              <Fragment>
                                {Translate(participantNames[item])}
                                {el.types[i + 1] ? ', ' : ''}
                              </Fragment>
                            ))}
                          </TwoLineLabel>
                        </FormLine>
                      </Column>
                    </Container>
                  ))
                }
              </Card>
            </ContentWrapper>
          )
        }
        {bottomPart &&
          ReactDOM.createPortal(
            <ContentWrapper>
              <Card>
                <CardHeading>
                  {Translate('Дополнительная информация')}
                </CardHeading>
                <Container fillWidth>
                  {/*<Column>*/}
                  {/*  <FormLine>*/}
                  {/*    <TwoLineLabel heading={Translate('Тип участника')}>*/}
                  {/*      {roles.map((v,i) => i === roles.length - 1 ? `${participantNames[v]}` : `${participantNames[v]}, `)}*/}
                  {/*    </TwoLineLabel>*/}
                  {/*  </FormLine>*/}
                  {/*</Column>*/}
                  <Column>
                    <FormLine>
                      <TwoLineLabel
                        heading={Translate('Дата регистрации в системе')}
                      >
                        {regDateFormatted || '—'}
                      </TwoLineLabel>
                    </FormLine>
                  </Column>
                  <Column>
                    <FormLine>
                      <TwoLineLabel heading={Translate('ID участника')}>
                        {participantId || '—'}
                      </TwoLineLabel>
                    </FormLine>
                  </Column>
                </Container>
              </Card>
            </ContentWrapper>,
            bottomPart,
          )}
      </div>
    );
  }
}

export default withTheme(withRouter(BasicInfo));
