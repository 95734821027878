import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../../Fields/_refactor/FieldsWrapper';
import InputAdapter from '../MuiAdapters/InputAdapter';

const Input = props => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        {params => {
          return (
            <InputAdapter name={name} {...passProps} {...params} />
          );
        }}
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder:  PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelOnDisabled: PropTypes.bool,
};

Input.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  placeholder: undefined,
  errorPlaceholder: undefined,
  labelOnDisabled: false,
};

Input.displayName = 'Input';

export default Input;
