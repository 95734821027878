import React, { useState } from 'react';
import {
  CisListWrap,
  FindWrap,
  Title,
} from '../../../../motp_components/_Requests/RequestList/ActDocument/Goods/CisListUploadModalContent/CisListUploadModalContent.styled';
import { Input } from '@crpt-ui/core';
import { Translate } from '../../../../common_components/Translate/Translate';
import {
  ContentWrapper,
  HeaderWrapper, ModalClose,
  TextHeaderWrapper,
} from '../../../../common_components/Modals/ConfirmModal/ConfirmModal.styled';
import { Dialog } from '@material-ui/core';

export const EsfCisModal = ({ open, codes, onClose }) => {
  const [ filter, setFilter ] = useState('');

  const filtered =
    filter === ''
      ? codes
      : codes.filter(code => code.toLowerCase().indexOf(filter) >= 0);

  const close = () => {
    setFilter('');
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth="sm"
    >
      <HeaderWrapper>
        <TextHeaderWrapper>{Translate('Коды маркировки')}</TextHeaderWrapper>
        <ModalClose onClick={close}>✖</ModalClose>
      </HeaderWrapper>
      <div>
        <div>
        <FindWrap>
          <Input
            value={filter}
            onChange={e => {
              setFilter(e.target.value.toLowerCase());
            }}
            label={Translate('Поиск кода маркировки')}
          />
        </FindWrap>
        <CisListWrap>
          {filtered.map((code, i) => (
            <div key={i}>{code}</div>
          ))}
        </CisListWrap>
        </div>
      </div>
    </Dialog>
  )
};
