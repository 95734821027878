import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1],
          'col2': 'ИНН владельца товара'
        },
        {
          'col1': [2],
          'col2': 'Юридический адрес владельца товара'
        },
        {
          'col1': [3, 4],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [5],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [6],
          'col2': <div>
            Формула расчёта оборачиваемости:<br />
            Средний товарный запас за период <strong>×</strong> Количество дней в периоде / Товарооборот за период.<br />
            Средний товарный запас за период = (Остатки на начало периода + Остатки на конец периода) / 2
          </div>
        }
      ]
    }
  ]
}