import { authInstance } from './authInstance';

export class HomeExciseService {

  static getConsolidated(params) {
    return authInstance({
      url: `/private-office-api/private/v2/excise/consolidated`,
      method: 'POST',
      data: params
    })
  }

  static getList(params) {
    return authInstance({
      url: `/private-office-api/private/v2/excise/common-list`,
      method: 'POST',
      data: params
    })
  }
}