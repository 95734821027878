import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@crpt/react-icon';

import { Description, Wrapper } from './InfoLabel.styled';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  customStyle: PropTypes.object,
};

const InfoLabelView = ({ value, customStyle }) => (
  <Wrapper customStyle={customStyle}>
    <Icon type="notification" size={24} fill="#52535A" spacingRight={16} />
    <Description>{value}</Description>
  </Wrapper>
);

InfoLabelView.propTypes = propTypes;
export default InfoLabelView;
