import {actionTypes} from '../../../constants/';

export function total(state = 0, action = {}) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS.CIS_LIST_LOADED:
      return action.value.total;
    default:
      return state;
  }
}
