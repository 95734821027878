import { css } from 'styled-components';

import TableInput from './TableInput';

const { style: inputStyle } = TableInput;

const TableSelect = {
  style: css`
    ${inputStyle}
  `,
};

export default TableSelect;
