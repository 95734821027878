import { handleActions } from 'redux-actions';
import * as actions from './DraftItem.actions';

const data = handleActions(
  {
    [actions.mounted]: () => ({}),
    [actions.loaded]: (state, { payload }) => payload || {},
    [actions.unmounted]: () => ({}),
  },
  {}
);

const errors = handleActions(
  {
    [actions.update]: () => ({}),
    [actions.gotErrors]: (state, { payload }) => payload || {},
  },
  {}
);

export default {
  data,
  errors,
};
