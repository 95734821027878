import styled from 'styled-components';

const TooltipWrapper = styled.div`
  position: absolute;
  padding: 31px 23px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.13);
  left: ${props => props.left};
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  ${props => (props.isVisible ? 'z-index: 10000;' : 'z-index: -1;')}
`;

export default TooltipWrapper;
