import { takeEvery, put, call } from 'redux-saga/effects';
import { get } from 'lodash';

import * as actions from './ExportEaes.actions';
import Api from '../../../../common_components/Api/Api';
import { Toast } from '../../../../utils/Toast';

function* mountSaga(action) {
  const { payload } = action;
  const { id } = payload;

  const requestPayload = {
    url: `/api/v3/facade/edo-api/export-notifications/${id}/view`,
    method: 'get',
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success && success.data) {
    const { data } = success;
  // const changedData = {
  //     // ...data,
  //     productGroup: "shoes",
  //     documentNumber: "12",
  //     documentDate: "2021-07-30",
  //     transferDate: "2021-08-10",
  //     receiverCountryCode: "RU",
  //     receiverCode: "1212121212",
  //     receiverName: "Тест УОТ-импортер",
  //     exporterCode: "406700910039",
  //     exporterName: "УОТ-экспортер",
  //     status: "CHECKING",
  //     type: "KZ_EXPORT_NOTICE",
  //     productList: [
  //       {
  //         cis: "04600266991232oz<dGrY",
  //         emissionCountryCode: "3",
  //         price: "350.50",
  //         vat: "30.20",
  //         documentList: [
  //           {
  //             docKind: "certificate",
  //             docNumber: "123123",
  //             docDate: "2021-07-30"
  //           },
  //           {
  //             docKind: "declaration",
  //             docNumber: "12312344",
  //             docDate: "2021-07-30"
  //           }
  //         ]
  //       },
  //       {
  //         cis: "04600266991232B%rrtAp",
  //         emissionCountryCode: "3",
  //         price: "350.50",
  //         vat: "30.20",
  //         documentList: [
  //           {
  //             docKind: "certificate",
  //             docNumber: "123123",
  //             docDate: "2021-07-30"
  //           }
  //         ]
  //       }
  //     ],
  //     errors: [
  //       {
  //         errorCode: "1",
  //         errorDescription: "описание",
  //       },
  //       {
  //         errorCode: "2",
  //         errorDescription: "описание 2",
  //       },
  //     ]
  //   }
  const [cisesSuccess] = yield call(Api.request, {
    url: `/api/v3/facade/cis/cis_list`,
    method: 'post',
    data: data.body.productList.map(el=> el.cis),
  });
    yield put(actions.loaded({
      ...data,
      productList: data.body.productList.map((el, index)=> {
          const cisesData = cisesSuccess.data[el.cis]
          return ({
            idx: index + 1,
            ...el,
            ...cisesData,
          })
        })
    }));
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
    yield put(actions.loaded());
  }
}

function* downloadJSONSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/receipt/${payload}/download`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': null,
    },
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data, headers } = success;

    try {
      let type = 'xml';
      const disposition = headers['content-disposition'];
      if (disposition && ~disposition.indexOf('attachment')) {
        // eslint-disable-next-line
        const match = disposition.match(/filename\=.*?\.([^;\n ]+)/);
        if (match) type = match[1];
      }

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveOrOpenBlob(
          new Blob([new Uint8Array(data)], {
            type: 'text/plain;charset=utf-8;',
          }),
          `${payload}.${type}`,
        );
      } else {
        const elem = document.createElement('a');
        elem.setAttribute(
          'href',
          window.URL.createObjectURL(new Blob([new Uint8Array(data)])),
        );
        elem.setAttribute('download', `${payload}.${type}`);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    } catch (e) {
      console.log('downloadDocumentClicked error: ', e);
    }
  }

  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.downloadJSON, downloadJSONSaga);
}
