import React from 'react';
import styled, { css } from 'styled-components';

import {Icon} from '@crpt/react-icon';

import {getIconNameByCISStatus, getStatusName} from './utils';

const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconWrap = styled.div`
  min-width: 16px;
  margin-right: 16px;
  padding-bottom: 0px;
  width: 16px;
  height: auto;
  display: flex;
  align-items: center;
`;

const CISstatus = props => {
  const cis = props.options.status;

  const iconName = getIconNameByCISStatus(cis._original.status);

  return (
    <Wrap>
      <IconWrap>
        <Icon type={iconName} width={16} height={16} withoutMask />
      </IconWrap>
        {getStatusName(cis.status)}
    </Wrap>
  );
};

export default CISstatus;
