import React from 'react';
import PropTypes from 'prop-types';
import HintedEmailInputAdapter from '../Adapters/HintedEmailInputAdapter';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../_refactor/FieldsWrapper';


const HintedEmailInput = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <HintedEmailInputAdapter
          name={name}
          {...passProps}
          placeholder="E-mail*"
          errorPlaceholder="Введите корректный адрес e-mail"
        />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

HintedEmailInput.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

HintedEmailInput.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};


export default HintedEmailInput;
