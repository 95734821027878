import React from 'react';

export default {
  title: 'Табличное представление при типе агрегации «Тип документа»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Тип документа',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
        },
        {
          Header: 'Количество документов, обработанных успешно',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
        {
          Header: 'Количество документов, обработанных с ошибкой',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
      ],
      data: [
        {
          'col3': [3],
          'col5': [5],
          'col6': [6],
        },
        {
          'col3': <strong>Итого</strong>,
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
        },        
      ]
    },
  ]
}