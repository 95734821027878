import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';

export default {
  title: Translate('Табличное представление при типе агрегации «Сводный отчёт»:'),
  amountOfTable: 1,
  tables: [
    // table#1
    {
      preTable: [
        {
          name: '',
          width: 370
        },
        {
          name: Translate('Количество кодов'),
          width: 750
        },
        {
          name: '',
          width: 140
        },
      ],
      headers: [
        {
          Header: Translate('ИНН'),
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
        },
        {
          Header: Translate('Наименование'),
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
        },
        {
          Header: Translate('Тип упаковки'),
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: Translate('Эмитировано за текущий период'),
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
        },
        {
          Header: Translate('Нанесено за текущий период'),
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
        },
        {
          Header: Translate('Оплачено за текущий период'),
          accessor: 'col13',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
        },
        {
          Header: Translate('Нанесено и не оплачено на конец периода (всего)'),
          accessor: 'col11',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
        },
        {
          Header: Translate('Стоимость кодов'),
          accessor: 'col12',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col13': [13],
          'col11': [11],
          'col12': [12],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': '',
          'col6': '',
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
        }
      ]
    },
  ]
}
