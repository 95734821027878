import {getCertSerial} from './cookieHelpers';

export class CadesUtil {
  static CADESCOM_CADES_BES = 1;

  static CAPICOM_CURRENT_USER_STORE = 2;

  static CAPICOM_MY_STORE = 'My';

  static CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;

  static CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;

  static STORE_OBJECT_NAME = 'CAdESCOM.Store';

  static CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;

  static CADESCOM_BASE64_TO_BINARY = 1;

  static CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;

  static CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;

  static CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN = 1;

  static async getVersion() {
    if (window.cadesplugin.CreateObjectAsync) {
      try {
        const info = {};
        await window.cadesplugin;
        const about = await window.cadesplugin.CreateObjectAsync(
          'CAdESCOM.About'
        );

        info.cadesVersion = await about.PluginVersion;
        info.cspVersion = await about.CSPVersion();
        if (!info.cadesVersion) {
          info.cadesVersion = await about.Version;
        }
        info.cadesVersion = await info.cadesVersion.toString();
        info.cspVersion = await info.cspVersion.toString();
        return info;
      } catch (e) {
        console.log('err', e);
      }
    } else {
      try {
        const info = {};
        const about = cadesplugin.CreateObject('CAdESCOM.About');
        info.cadesVersion = about.PluginVersion;
        if (!info.cadesVersion) {
          info.cadesVersion = about.Version;
        }
        info.cspVersion = about.CSPVersion();
        info.cadesVersion = info.cadesVersion.toString();
        info.cspVersion = info.cspVersion.toString();
        return info;
      } catch (e) {
        console.log('err', e);
      }
    }
  }

  static init() {
    return new Promise((resolve, reject) => {
      if (window.cadesplugin.CreateObjectAsync) {
        window.cadesplugin.then(
          () => {
            // console.log("async init");
            resolve();
          },
          error => {
            reject(error);
          }
        );
      } else {
        window.addEventListener(
          'message',
          event => {
            if (event.data === 'cadesplugin_loaded') {
              // console.log("sync init");
              resolve();
            } else if (event.data === 'cadesplugin_load_error') {
              reject(event);
            }
          },
          false
        );
        window.postMessage('cadesplugin_echo_request', '*');
      }
    });
  }

  static createObject(name) {
    return window.cadesplugin.CreateObjectAsync
      ? window.cadesplugin.CreateObjectAsync(name)
      : new Promise((resolve, reject) => {
          // console.log("before call");
          const obj = window.cadesplugin.CreateObject(name);
          // console.log("sync obj", obj);
          resolve(obj);
        });
  }

  static openStore(store, ...params) {
    return new Promise((resolve, reject) => {
      if (!window.cadesplugin.CreateObjectAsync) {
        store.Open.apply(this, params);
        resolve(store);
      } else {
        store.Open.apply(this, params).then(() => resolve(store));
      }
    });
  }

  static getCertsObjFromOpenStore(store) {
    return new Promise((resolve, reject) => {
      if (window.cadesplugin.CreateObjectAsync) {
        return store.Certificates.then(obj =>
          obj.Find(CadesUtil.CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, '')
        ).then(certs => {
          resolve(certs);
        });
      }
      return resolve(
        store.Certificates.Find(
          CadesUtil.CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME,
          ''
        )
      );
    });
  }

  static getCertBySerial(certs) {
    const serial = getCertSerial();

    try {
      const cert = certs.find(i => i.info[3] === serial);
      return cert.certificate;
    } catch (e) {
      throw new Error('cert not found');
    }
  }

  static getCountFromCerts(certs) {
    return new Promise((resolve, reject) => {
      if (window.cadesplugin.CreateObjectAsync) {
        certs.Count.then(count => resolve(count));
      } else {
        resolve(certs.Count);
      }
    });
  }

  static getCompanyNameFromSubject(subj) {
    return CadesUtil.getByTagFromSubject(subj, 'O');
  }

  static getNameFromSubject(subj) {
    let name = '';

    name = `${CadesUtil.getByTagFromSubject(subj, 'SN')} `;
    name += CadesUtil.getByTagFromSubject(subj, 'G');
    name = name.trim();

    if (!name.length) {
      name = CadesUtil.getByTagFromSubject(subj, 'CN');
    }

    return name;
  }

  static getByTagFromSubject(subj, tag) {
    const splitted = subj.split(',');
    let val = '';
    splitted.forEach(sp => {
      const spl = sp.split('=');
      if (spl[0].trim() === tag) val = spl[1].trim();
    });
    return val;
  }

  static getSNFromSubject(subj) {
    return CadesUtil.getByTagFromSubject(subj, 'SN');
  }

  static getCertsByCount(certs, count) {
    return new Promise((resolve, reject) => {
      if (window.cadesplugin.CreateObjectAsync) {
        const promises = [];
        for (let i = 1; i < count + 1; i += 1) {
          promises.push(certs.Item(i));
        }
        Promise.all(promises).then(certificates => {
          const namePromises = [];
          const fromPromises = [];
          const toPromises = [];
          const serialPromises = [];
          const issuerName = [];

          certificates.forEach(c => {
            issuerName.push(c.IssuerName);
            namePromises.push(c.SubjectName);
            fromPromises.push(c.ValidFromDate);
            toPromises.push(c.ValidToDate);
            serialPromises.push(c.SerialNumber);
          });

          Promise.all(
            [
              namePromises,
              fromPromises,
              toPromises,
              serialPromises,
              issuerName,
            ].map(c => Promise.all(c))
          ).then(names => {
            resolve(
              certificates.map((c, i) => ({
                certificate: c,
                info: names.map(info => info[i]),
              }))
            );
          });
        });
      } else {
        // console.log("here parse it certs");
        // console.log("certs", certs);
        // console.log("count", count);
        // const cert = certificatesList.Item(i);
        // signaturesObjList.push(cert);
        //
        // model.signaturesList.push({
        //   company: parseCert(cert.SubjectName, 'O'),
        //   email: parseCert(cert.SubjectName, 'E'),
        //   fromDate: new Date(cert.ValidFromDate),
        //   toDate: new Date(cert.ValidToDate),
        //   name: getNameFromCertificate(cert.SubjectName)
        // });
      }
    });
  }

  static getAsyncCertArray() {
    return new Promise((resolve, reject) => {
      CadesUtil.init()
        .then(() => CadesUtil.createObject(CadesUtil.STORE_OBJECT_NAME))
        .then(store =>
          CadesUtil.openStore(
            store,
            CadesUtil.CAPICOM_CURRENT_USER_STORE,
            CadesUtil.CAPICOM_MY_STORE,
            CadesUtil.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
          )
        )

        .then(store => {
          CadesUtil.getCertsObjFromOpenStore(store)
            .then(certs =>
              CadesUtil.getCountFromCerts(certs).then(count =>
                CadesUtil.getCertsByCount(certs, count)
              )
            )
            .then(certificates => {
              store.Close();
              resolve(certificates);
            });
        })
        .catch(err => reject(err));
    });
  }

  static getSyncCertArray() {
    // console.log("GET SYNC CERT ARRAY");

    return new Promise((resolve, reject) => {
      const oStore = window.cadesplugin.CreateObject('CAdESCOM.Store');
      oStore.Open(
        CadesUtil.CAPICOM_CURRENT_USER_STORE,
        CadesUtil.CAPICOM_MY_STORE,
        CadesUtil.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const oCertificates = oStore.Certificates.Find(
        CadesUtil.CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME,
        ''
      );

      if (oCertificates.Count === 0) {
        alert('Certificate not found');
        return resolve([]);
      }

      const certs = [];

      for (let i = 1; i <= oCertificates.Count; i += 1) {
        const cert = oCertificates.Item(i);

        const ValidFromDate = new Date(cert.ValidFromDate);
        const ValidToDate = new Date(cert.ValidToDate);

        console.log('ValidFromDate', ValidFromDate);
        console.log('ValidToDate', ValidToDate);

        certs.push({
          certificate: cert,
          info: [
            cert.SubjectName,
            ValidFromDate,
            ValidToDate,
            cert.SerialNumber,
            cert.IssuerName,
          ],
        });
      }
      // oStore.Close();
      resolve(certs);
    });
  }

  static getFinalCertsArray() {
    if (window.cadesplugin.CreateObjectAsync) {
      return CadesUtil.getAsyncCertArray();
    }
    return CadesUtil.getSyncCertArray();
  }

  static signMessageAsync(message, cert, detached, binary = false) {
    const preparedMessage = binary ? message : CadesUtil.Base64.encode(message);

    return new Promise((resolve, reject) => {
      const signer = window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
      const signedData = window.cadesplugin.CreateObjectAsync(
        'CAdESCOM.CadesSignedData'
      );
      const signingTimeAttr = window.cadesplugin.CreateObjectAsync(
        'CADESCOM.CPAttribute'
      );
      const documentNameAttr = window.cadesplugin.CreateObjectAsync(
        'CADESCOM.CPAttribute'
      );

      let oSigner = null;
      let oSignedData = null;
      let attrTime = null;
      let attrDate = null;
      let attr = null;

      Promise.all([signer, signedData, signingTimeAttr, documentNameAttr])
        .then(res => {
          [oSigner, oSignedData, attrTime, attrDate] = res;
        })
        .then(() => oSigner.AuthenticatedAttributes2)
        .then(a => {
          attr = a;
        })
        .then(() => attrTime.propset_Name(CadesUtil.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME))
        .then(() => attrTime.propset_Value(new Date()))
        .then(() => attrDate.propset_Name(CadesUtil.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME))
        .then(() => attrDate.propset_Value('CRPT'))
        .then(() => attr.Add(attrTime))
        .then(() => attr.Add(attrDate))
        .then(() => oSigner.propset_Certificate(cert))
        .then(() => oSigner.propset_Options(CadesUtil.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN))
        .then(() => oSignedData.propset_ContentEncoding(CadesUtil.CADESCOM_BASE64_TO_BINARY))
        .then(() => oSignedData.propset_Content(preparedMessage))
        .then(() => oSignedData.SignCades(oSigner, CadesUtil.CADESCOM_CADES_BES, detached))
        .then(signature => resolve(signature))
        .catch(err => reject(err));
    });
  }

  static signByteArrayAsync(byteArray, cert) {
    return new Promise((resolve, reject) => {
      window.cadesplugin.CADESCOM_ENCODE_BASE64 = 1;

      const signer = window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');

      const signedData = window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

      const dataString = new Uint8Array(byteArray)
        .reduce((data, byte) => data + String.fromCharCode(byte), '');
      const base64Data = window.btoa( dataString );

      let oSigner = null;
      let oSignedData = null;

      Promise.all([signer, signedData])
        .then(result => {
          [oSigner, oSignedData] = result;
        })
        .then(() => oSigner.propset_Certificate(cert))
        .then(() => oSignedData.propset_ContentEncoding(CadesUtil.CADESCOM_BASE64_TO_BINARY))
        .then(() => oSignedData.propset_Content(base64Data))
        .then(() => oSignedData.SignCades(oSigner, CadesUtil.CADESCOM_CADES_BES, true))
        .then(signature => resolve(signature))
        .catch(err => reject(err));
    });
  }

  static isActiveX() {
    try {
      new window.ActiveXObject('CAdESCOM.store');
      return true;
    } catch (e) {
      return false;
    }
  }

  static signMessageSync(message, cert, detached, binary = false) {
    const preparedMessage = binary ? message : CadesUtil.Base64.encode(message);

    return new Promise((resolve, reject) => {
      window.cadesplugin.CADESCOM_ENCODE_BASE64 = 1;

      const oSigningTimeAttr = window.cadesplugin.CreateObject('CADESCOM.CPAttribute');
      oSigningTimeAttr.Name = CadesUtil.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME;
      const oTimeNow = new Date();

      oSigningTimeAttr.Value = typeof oTimeNow.getVarDate === 'function'
        ? oTimeNow.getVarDate()
        : oTimeNow;

      const oDocumentNameAttr = window.cadesplugin.CreateObject('CADESCOM.CPAttribute');
      oDocumentNameAttr.Name = CadesUtil.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME;
      oDocumentNameAttr.Value = 'CRPT';

      const oSigner = window.cadesplugin.CreateObject('CAdESCOM.CPSigner');
      // console.log("oSigner created");
      oSigner.Certificate = cert;
      oSigner.AuthenticatedAttributes2.Add(oSigningTimeAttr);
      oSigner.AuthenticatedAttributes2.Add(oDocumentNameAttr);

      const oSignedData = window.cadesplugin.CreateObject('CAdESCOM.CadesSignedData');

      oSignedData.ContentEncoding = this.CADESCOM_BASE64_TO_BINARY;
      oSignedData.Content = preparedMessage;

      try {
        const signed = oSignedData.SignCades(
          oSigner,
          CadesUtil.CADESCOM_CADES_BES,
          detached
        );
        resolve(signed);
      } catch (err) {
        console.log('error', JSON.stringify(err));
        reject(err);
      }
    });
  }

  static signMessage(message, cert, detached = false, binary = false) {
    console.log(message, cert);
    if (window.cadesplugin.CreateObjectAsync) {
      // console.log("sign async");
      return CadesUtil.signMessageAsync(message, cert, detached, binary);
    }
    // console.log("sign sync");
    return CadesUtil.signMessageSync(message, cert, detached, binary);
  }

  static Base64 = {
    keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

    encode: input => {
      let output = '';

      const data = CadesUtil.Base64.utf8_encode(input);
      let i = 0;

      while (i < data.length) {
        const chr1 = data.charCodeAt(i++);
        const chr2 = data.charCodeAt(i++);
        const chr3 = data.charCodeAt(i++);

        const enc1 = chr1 >> 2;
        const enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        let enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = 64;
          enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }

        output +=
          CadesUtil.Base64.keyStr.charAt(enc1) +
          CadesUtil.Base64.keyStr.charAt(enc2) +
          CadesUtil.Base64.keyStr.charAt(enc3) +
          CadesUtil.Base64.keyStr.charAt(enc4);
      }

      return output;
    },

    decode: input => {
      let output = '';
      let i = 0;

      // eslint-disable-next-line no-useless-escape
      const data = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

      while (i < data.length) {
        const enc1 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc2 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc3 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));
        const enc4 = CadesUtil.Base64.keyStr.indexOf(data.charAt(i++));

        const chr1 = (enc1 << 2) | (enc2 >> 4);
        const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        const chr3 = ((enc3 & 3) << 6) | enc4;

        output += String.fromCharCode(chr1);

        if (enc3 !== 64) {
          output += String.fromCharCode(chr2);
        }
        if (enc4 !== 64) {
          output += String.fromCharCode(chr3);
        }
      }

      output = CadesUtil.Base64.utf8_decode(output);

      return output;
    },

    utf8_encode: string => {
      const data = string.replace(/\r\n/g, '\n');
      let result = '';

      for (let n = 0; n < data.length; n++) {
        const c = data.charCodeAt(n);

        if (c < 128) {
          result += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          result += String.fromCharCode((c >> 6) | 192);
          result += String.fromCharCode((c & 63) | 128);
        } else {
          result += String.fromCharCode((c >> 12) | 224);
          result += String.fromCharCode(((c >> 6) & 63) | 128);
          result += String.fromCharCode((c & 63) | 128);
        }
      }

      return result;
    },

    utf8_decode: utftext => {
      let result = '';
      let i = 0;

      while (i < utftext.length) {
        const c = utftext.charCodeAt(i);

        if (c < 128) {
          result += String.fromCharCode(c);
          i++;
        } else if (c > 191 && c < 224) {
          const c2 = utftext.charCodeAt(i + 1);
          result += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
          i += 2;
        } else {
          const c2 = utftext.charCodeAt(i + 1);
          const c3 = utftext.charCodeAt(i + 2);
          result += String.fromCharCode(
            ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
          );
          i += 3;
        }
      }

      return result;
    },
  };

  static prepareError(error) {
    let code = null;
    const match = error.message.match(/\((.*?)\)$/);
    if (match) code = match[1];

    switch (code) {
      case '0x8000FFFF':
        return 'Сертификат УКЭП не действителен.';
      case '0x800B010A':
        return 'Не установлен корневой сертификат.';
      case '0x8010006E':
        return null;
      default:
        return error.message.replace(/\(.*?\)/g, '');
    }
  }
}
