import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isObject, isNull, isUndefined, get } from 'lodash';
import { SingleSelect } from '@crpt/react-select';
import { Wrap } from '../_refactor/Fields.styled';

const PureSingleSelect = memo(SingleSelect);

class SingleSelectAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.shape({})),
    onSelect: PropTypes.func,
    selectedId: PropTypes.number,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    values: [],
    onSelect: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    formMutators: undefined,
    selectedId: undefined,
    defaultValue: undefined,
    onChange: () => null,
  };

  state = {
    values: undefined,
    prepared: undefined,
  };

  componentDidMount() {
    const {
      selectedId,
      defaultValue,
      values,
      formMutators,
      fieldInput = {},
      onChange,
    } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value)) {
      if (selectedId) {
        const selectedItem = values.find(i => i.id === selectedId);
        if (selectedItem) {
          setTimeout(
            () => formMutators.updateField(name, selectedItem.type || selectedItem),
            0
          );
        }
      } else if (defaultValue) {
        setTimeout(() => formMutators.updateField(name, defaultValue), 0);
      }
    } else if (isUndefined(selectedId)) {
      onChange(value);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.values) {
      return {
        values: props.values,
        prepared: props.values.filter(v => !v.hidden),
      };
    }

    return null;
  }

  onSelect = (val) => {
    const { fieldInput, onSelect } = this.props;

    if (fieldInput) {
      const value = get(val, 'type', val);
      fieldInput.onChange(!isNull(value) ? value : undefined);
    }
    if (onSelect) onSelect(val);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError
      && fieldMeta
      && fieldMeta.dirtySinceLastSubmit
      && !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error);
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total, fieldMeta } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  getSelectedId = () => {
    const { fieldInput = {}, values, defaultValue, selectedId } = this.props;
    const { value } = fieldInput;
    const searchValue = isUndefined(value) ? defaultValue : value;

    let selectedValue;

    if (!isUndefined(searchValue)) {
      if (isObject(searchValue)) {
        selectedValue = values.find(v => v.id === searchValue.id);
      } else {
        selectedValue = values.find(v => v.type === searchValue);
      }
    }

    return selectedValue ? selectedValue.id : selectedId;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      disabled,
      placeholder,
      errorPlaceholder,
      values,
      ...passProps
    } = this.props;
    const { prepared } = this.state;

    return (
      <Wrap
        onBlur={fieldInput && fieldInput.onBlur}
        marginBottom={marginBottom}
        isError={this.isError()}
      >
        <PureSingleSelect
          withoutIcon={disabled}
          savePlaceholder
          showPointer
          {...passProps}
          values={prepared}
          disabled={disabled}
          isError={!!this.isError()}
          placeholder={this.getPlaceholder()}
          onSelect={this.onSelect}
          selectedId={this.getSelectedId()}
        />
      </Wrap>
    );
  }
}

export default SingleSelectAdapter;
