import React from 'react';
import PropTypes from 'prop-types';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { Input as CrptInput } from '@crpt/react-input';
import { Wrap } from './styled';
import { withFieldWrapper, withCountedPlaceholder, withErrorProp } from './utils';


const Element = ({ input, inSign, ...rest }) => (
  <Wrap onBlur={input && input.onBlur} inSign={inSign}>
    <CrptInput
      onChange={(input && input.onChange) || rest.onChange}
      {...rest}
    />
  </Wrap>
);

Element.propTypes = {
  index: PropTypes.number,
  total: PropTypes.number,
};

Element.defaultProps = {
  index: 0,
  total: 0,
};


const enchanced = compose(
  withErrorProp,
  withCountedPlaceholder,
  onlyUpdateForKeys(['name', 'placeholder', 'index', 'total', 'defaultValue', 'isError']),
)(Element);


export default compose(
  setDisplayName('Input'),
  withFieldWrapper(enchanced),
)(enchanced);
