import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  commonWrapper: {
    display: 'flex',
  },
  contentWrapper: {
    marginLeft: '80px',
    display: 'inline-flex',
    width: '100%',
    minHeight: '100vh',
    paddingTop: '0',
  },
});
