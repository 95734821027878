import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import * as previewActions from '../Document/Preview/ducks/Preview.actions';
import * as actions from './ducks/ImportThirdCountry.actions';
import * as selectors from './ducks/ImportThirdCountry.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import ImportThirdCountryView from './ImportThirdCountry.view';
import {
  breadcrumbs,
  steps,
} from './ImportThirdCountry.constants';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../../../common_components/_MaterialUi/ThemeUZ';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';
import { get } from 'lodash';
import { Toast } from '../../../../utils/Toast';
import { Translate } from '../../../../common_components/Translate/Translate';
import { toast } from 'react-toastify';

class ImportThirdCountry extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    onMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onRemoveDraft: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    setPreview: PropTypes.func.isRequired,
    openPreview: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    countryCode: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      step: steps.info,
      hasPaperDoc: false,
      isFirstTime: true,
    };
  }

  componentDidMount() {
    const {
      match,
      onMount,
      onLoadDraft,
      onLoadDictionaries,
      onMountCades,
      countryCode,
    } = this.props;
    const { id } = match.params;
    if (/draft-/.test(id)) {
      onLoadDraft(id.replace(/draft-/, ''));
    } else if (id !== 'create') {
      onMount(encodeURIComponent(id));
    }
    if (countryCode === 'UZ') {
      onMountCades();
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      nextProps.data.hasPaperDoc !== undefined &&
      nextProps.data.hasPaperDoc !== prevState.hasPaperDoc &&
      prevState.isFirstTime
    ) {
      return {
        hasPaperDoc: nextProps.data.hasPaperDoc,
        isFirstTime: false,
      };
    }
    return null;
  };

  handleCheckboxChange = () =>
    this.setState(({ hasPaperDoc }) => ({ hasPaperDoc: !hasPaperDoc }));

  onSubmit = values => {
    // console.log('===onSubmit', values);
  };

  onSaveDraft = values => {
    const { match, onSaveDraft } = this.props;
    const { hasPaperDoc } = this.state;
    const data = { ...match, ...values, hasPaperDoc, isDraft: true };
    if (!hasPaperDoc) {
      delete data.notification_number;
      delete data.notification_data;
    }
    onSaveDraft({ match, values: data });
  };

  onSetStep = step => {
    this.setState({ step });
  };

  onHandleRemoveDraft = () => {
    const { match, countryCode, onRemoveDraft } = this.props;

    if (countryCode !== 'UZ') return;

    const { id } = match.params;
    const cleanId = id.replace(/draft-/, '');
    onRemoveDraft([cleanId]);
  };

  onCloseNotification = (history, formValues) => {
    const { countryCode } = this.props;
    let isDraft = false;
    if (countryCode === 'KZ') {
      const locationHref = window.location.href;
      isDraft = /draft-/.test(locationHref);
    } else {
      isDraft = get(formValues, 'isDraft', false);
      if (!isDraft) this.onHandleRemoveDraft();
    }

    history.push(isDraft ? '/documents/draft' : '/documents/list');

    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  render() {
    const { loaded, isLoading, data, match, countryCode } = this.props;
    const { id } = match.params;

    const { step, hasPaperDoc } = this.state;

    return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
      >
        <ImportThirdCountryView
          data={id === 'create' ? {} : data}
          step={step}
          hasPaperDoc={hasPaperDoc}
          loaded={loaded}
          isCreate={id === 'create'}
          breadcrumbs={breadcrumbs}
          onSubmit={this.onSubmit}
          onSetStep={this.onSetStep}
          onPreview={() => {}}
          onSaveDraft={this.onSaveDraft}
          onCloseNotification={this.onCloseNotification}
          handleCheckboxChange={this.handleCheckboxChange}
          isLoading={isLoading}
        />
      </MuiThemeProvider>
    );
  }
}

const mapState = state => ({
  data: {
    ...selectors.data(state),
  },
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
});
const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  onUnmount: () => dispatch(actions.unmounted()),
  openPreview: data => dispatch(previewActions.open(data)),
  setPreview: data => dispatch(previewActions.setContent(data)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMountCades: () => dispatch(cadesActions.load()),
  onRemoveDraft: id => dispatch(actionsDraft.remove(id)),
});
export default connect(mapState, mapDispatch)(ImportThirdCountry);
