import styled from "styled-components";

export const InfoFieldsWrapper = styled.div`
  width: 100%;
  button {
    color: white;
  }
`;

export const AggregationUnitsError = styled.div`
  color: red;
  margin-top: -13px;
`;

export const UploadBtnWrap = styled.div`
  button {
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-color: #9e9e9e;
    background: #fff;
    box-shadow: none;
    &:hover {
        background: #e0e0e0;
        border-color: #e0e0e0;
        box-shadow: none;
    }
    svg {
        fill: #63666a;
    }
  }
`;
