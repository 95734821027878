import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver/FileSaver';

import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import { RangePicker } from '@crpt/react-datepicker';

import {
  CISPackageSelect,
  CISStatusSelect,
  ParticipantSelect,
  ProductSelect
} from '../../../components/Select';
import CIScis from '../../../components/TableCells/CIS/CIScis';
import CISProductName from '../../../components/TableCells/CIS/CISProductName';
import ParticipantNameBound from '../../../containers/Bounds/ParticipantNameBound';

import { TableCell } from '../../../components/common/Table/TableCellTooltip';
import { statuses } from '../../../components/Select/CISStatusSelect';
import { defaultDatepickerTheme } from '../../../theme/ru-tabaco/datepicker';
import { CISService } from '../../../services/CISService';
import { StoreHelper } from '../../../utils/StoreHelper';
import { default as CISList } from '../../../selectors/CISList';
import { defaultDatepickerThemeKZ } from '../../../theme/kz-tobaco/datepicker';

const DISALLOWED_STATUSES = [
  'WITHDRAW',
  'OWNER_CHANGE',
  'AGGREGATION',
  'DISAGGREGATION'
];

const statusesValues = statuses.filter(
  s => !~DISALLOWED_STATUSES.indexOf(s.cisStatus)
);

export default {
  actionNamespace: 'CIS_LIST',
  selectorNamespace: 'CISList',
  limit: 10,
  options: [
    {
      accessor: d => d,
      id: 'cis',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <CIScis {...props} />
          </TableCell>
        );
      }
    },
    {
      accessor: d => d,
      id: 'productName',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <CISProductName {...otherProps} />
          </TableCell>
        );
      }
    },
    {
      accessor: d => d,
      id: 'producerName',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        const name = otherProps.options.producerName.producerName;
        const inn = otherProps.options.producerName._original.producerInn;

        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <ParticipantNameBound name={name} inn={inn} {...otherProps} />
          </TableCell>
        );
      }
    },
    {
      accessor: d => d,
      id: 'ownerName',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        const name = otherProps.options.ownerName.ownerName;
        const inn = otherProps.options.ownerName._original.ownerInn;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <ParticipantNameBound name={name} inn={inn} {...otherProps} />
          </TableCell>
        );
      }
    }
  ],
  columns: [
    {
      Header: 'Дата',
      id: 'producedDate',
      accessor: d =>
        `${moment(d.producedDate || d.emissionDate).format('DD.MM.YYYY')}`,
      width: 105
    },
    {
      Header: 'Код',
      id: 'cis',
      accessor: 'cis',
      width: 270
    },
    {
      Header: 'Товар',
      id: 'productName',
      accessor: 'productName',
      width: 224
    },
    {
      Header: 'МРЦ, Р',
      id: 'mrp',
      accessor: 'mrp',
      width: 90
    },
    {
      Header: 'Производитель',
      id: 'producerName',
      accessor: 'producerName',
      width: 300
    },
    {
      Header: 'Текущий владелец',
      id: 'ownerName',
      accessor: 'ownerName'
    }
  ],
  beforeTable: {
    showGraph: false
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: [
        {
          title: 'Скачать CSV',
          onClick: () => {
            const filterParams = CISList.filterParams(StoreHelper.getState());
            CISService.getListReport('csv', filterParams).then(answer =>
              saveAs(answer.data, `cis_list.csv`)
            );
          }
        }
      ]
    },
    [
      {
        component: Input,
        props: {
          name: 'cisNumber',
          placeholder: 'Номер кода',
          rightIcon: <Icon type="search" />
        }
      }
    ],
    [
      {
        component: RangePicker,
        props: {
          inline: false,
          name: 'date',
          placeholder: 'Дата',
          format: 'DD.MM.YYYY',
          positionX: 'left',
          controls: true,
          acceptText: 'Применить',
          resetText: 'Сбросить',
          theme: { DatePicker: defaultDatepickerThemeKZ }
        }
      }
    ],
    [
      {
        component: ProductSelect,
        props: {
          inline: false,
          name: 'gtin',
          placeholder: 'Товар / GTIN'
        }
      }
    ],
    [
      {
        component: CISStatusSelect,
        props: {
          inline: false,
          name: 'cisStatus',
          placeholder: 'Статус',
          values: statusesValues
        }
      }
    ],
    [
      {
        component: CISPackageSelect,
        props: {
          inline: false,
          name: 'cisPackageType',
          placeholder: 'Упаковка'
        }
      }
    ],
    [
      {
        component: ParticipantSelect,
        props: {
          inline: false,
          name: 'producer',
          placeholder: 'Производитель'
        }
      }
    ],
    [
      {
        component: ParticipantSelect,
        props: {
          inline: false,
          name: 'participantInn',
          valueKey: 'inn',
          placeholder: 'Текущий владелец'
        }
      }
    ]
  ]
};
