import { handleActions } from 'redux-actions';

import * as unloadServiceListActions from './UnloadServiceList.actions';
const dataDefaultState = {
  tasks: [],
  taskTypes: [],
}

const data = handleActions(
  {
    [unloadServiceListActions.mounted]: () => dataDefaultState,
    [unloadServiceListActions.getData]: () => dataDefaultState,
    [unloadServiceListActions.setData]: (_state, { payload }) => payload,
    [unloadServiceListActions.unmounted]: () => dataDefaultState,
  },
  dataDefaultState,
);

const loaded = handleActions(
  {
    [unloadServiceListActions.getData]: () => false,
    [unloadServiceListActions.setData]: () => true,
    [unloadServiceListActions.unmounted]: () => false,
  },
  false,
);

export default {
  data,
  loaded,
};
