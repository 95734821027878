import { get, isObject } from 'lodash';
import moment from 'moment';
import { codesCountryClassifier } from './ImportDocument.constants';
import { formatAddZeros } from '../RequestList.utils';
import {required} from '../../../../common_components/Form/utils/validations';

export const validateDate = (...params) => {
  const [val, vals, _, name, format = 'DD.MM.YYYY'] = params;
  if(!val) return `Укажите дату в формате ${format}`;
  const dateVal = moment(val, format);
  const dateParsed = dateVal.isValid() ? dateVal : moment(val);
  return dateParsed.isValid() && dateParsed.isSameOrBefore(moment()) ? undefined : `Укажите дату в формате ${format}`;
};

export const validateDateInGoodsFields = (values) => {
  const { excise_tax_document_number, excise_tax_document_date, excise_tax_sum } = values;
  const format = 'DD.MM.YYYY';
  const errors = {};
  const dateVal = moment(excise_tax_document_date, format);
  const dateParsed = dateVal.isValid() ? dateVal : moment(excise_tax_document_date);
  if (excise_tax_document_date && !excise_tax_sum) {
    errors.excise_tax_sum = 'Укажите сумму';
  }
  if (!excise_tax_document_date && !excise_tax_sum && !excise_tax_document_number) {
    errors.excise_tax_document_number = undefined;
    errors.excise_tax_sum = undefined;
  }
  if (excise_tax_document_date && !excise_tax_document_number) {
    errors.excise_tax_document_number = 'Укажите номер квитанции';
  }
  if (excise_tax_document_date && !excise_tax_document_number && !excise_tax_sum) {
    errors.excise_tax_document_number = 'Укажите номер квитанции';
    errors.excise_tax_sum = 'Укажите сумму';
  }
  if (!excise_tax_document_date && !excise_tax_document_number && !excise_tax_sum) {
    errors.excise_tax_document_number = undefined;
    errors.excise_tax_sum = undefined;
    errors.excise_tax_document_date = undefined;
  }
  if (!excise_tax_document_date && (excise_tax_document_number || excise_tax_sum)) {
    errors.excise_tax_document_date = `Укажите дату в формате ${format}`;
  }
  if (!(dateParsed.isValid() && dateParsed.isSameOrBefore(moment()))) {
    errors.excise_tax_document_date = `Укажите дату в формате ${format}`;
  }
  return errors;
};

export const validateDateWithEmptyValue = (...params) => {
  const [val, vals, _, name, format = 'DD.MM.YYYY'] = params;
  if (!val || !val[0]) {
    return undefined;
  }
  const dateVal = moment(val, format);
  const dateParsed = dateVal.isValid() ? dateVal : moment(val);
  return dateParsed.isValid() && dateParsed.isSameOrBefore(moment()) ? undefined : `Укажите дату в формате ${format}`;
};

export const validateMaxLength = max => value => {
  if (!value) return 'Код таможенного органа не указан';
  if(value.length > max)  return 'Превышено допустимое количество символов';

  return undefined;
};

export const validateContractDate = (value) => {
  if (!value) return 'Дата договора рассрочки не указана';

  // TODO: запрос к реестру, для проверки наличия и даты

  return undefined;
};

export const validateNumber = (value, values, _, name) => {
  //if (!value) return 'Не указано';

  const text = `${value}`;
  if (text.indexOf('.') !== -1 || text.indexOf(',') !== -1) {
    if (name.indexOf('currency_rate') !== -1) {
      if (!text.match(/^\d+[.,]\d{1,4}$/)) return 'Недопустимый формат';
    } else {
      if (!text.match(/^\d+[.,]\d+$/)) return 'Недопустимый формат';
    }
  }

  if (text.match(/[^\d.,]/)) return 'Недопустимый формат';

  if(/^[\d]{10,}/.test(text)) return 'Недопустимое значение';

  const parsed = parseFloat(value);
  if (parsed < 0) return 'Не может быть меньше или равно нулю';

  return undefined;
};

export const validateProductCost = (value) => {
  if (!value) return 'Не указано';

  const text = `${value}`;
  if (text.indexOf('.') !== -1 || text.indexOf(',') !== -1) {
    if (!text.match(/^\d+[.,]\d{1,2}$/)) return 'Недопустимый формат';
  }

  if (text.match(/[^\d.,]/)) return 'Недопустимый формат';

  if(/^[\d]{10,}/.test(text)) return 'Находится вне допустимого числового диапазона';

  const parsed = parseFloat(value);
  if (parsed <= 0) return 'Не может быть меньше или равно нулю';

  return undefined;
};

export const validateNumberAfterDotRequiredAndNotEmpty = (value, values, _, name) => {
  if (!value) return 'Error';

  const text = value+"";
  if (name.indexOf('currency_rate') !== -1) {
    if (!text.match(/^\d+[.,]\d{4}$/)) return 'Недопустимый формат';
  } else {
    if (!text.match(/^\d+[.,]\d{2}$/)) return 'Недопустимый формат';
  }
  if(/^[\d]{10,}/.test(text)) return 'Находится вне допустимого числового диапазона';

  const parsed = parseFloat(value.replace(',', '.'));
  if (parsed <= 0) return 'Не может быть меньше или равно нулю';

  return undefined;
};

export const validateFixNumberAfterDot = (value, values, _, name) => {
  if (!value) return 'Не указано';

  const text = formatAddZeros(value, name);
  if (name.indexOf('currency_rate') !== -1) {
    if (!text.match(/^\d+[.,]\d{4}$/)) return 'Недопустимый формат';
  } else {
    if (!text.match(/^\d+[.,]\d{2}$/)) return 'Недопустимый формат';
  }
  if(/^[\d]{10,}/.test(text)) return 'Находится вне допустимого числового диапазона';

  const parsed = parseFloat(value);
  if (parsed <= 0) return 'Не может быть меньше или равно нулю';

  return undefined;
};

export const validateFixNumberAfterDotWithEmpty = (value, values, _, name) => {
  if (!value) return undefined;

  const text = formatAddZeros(value, name);
  if (name.indexOf('currency_rate') !== -1) {
    if (!text.match(/^\d+[.,]\d{4}$/)) return 'Недопустимый формат';
  } else {
    if (!text.match(/^\d+[.,]\d{2}$/)) return 'Недопустимый формат';
  }
  if(/^[\d]{10,}/.test(text)) return 'Находится вне допустимого числового диапазона';

  const parsed = parseFloat(value);
  if (parsed <= 0) return 'Не может быть меньше или равно нулю';

  return undefined;
};

export const validateProductTax = (value, values, _, name) => {
  const costValidations = validateProductCost(value);
  if (costValidations) return costValidations;

  const costName = name.replace('product_tax', 'product_cost');
  const cost = get(values, costName);

  if (cost && parseFloat(value) >= parseFloat(cost)) {
    return 'Находится вне допустимого числового диапазона';
  }

  return undefined;
};

export const validateUIT = (value, values, _, name) => {
  const uitName = name.replace('uit_code', 'uitu_code');
  const uitu = get(values, uitName, '');
  const isError = (!uitu || !uitu.match(/^[A-z\d]{38}$/));
  // uitu - невалиден, поэтому проверяем uit
  if (isError) {
    if (!value) return 'УИТ не указан';
    if (!value.match(/^[A-z\d]{38}$/)) return 'Недопустимое количество символов УИТ';
    if (value.match(/[^A-z\d]/)) return 'Недопустимый формат УИТ';
    // if (values.products.filter(i => get(i, 'uit_code') === value).length > 1) {
    //   return 'УИТ не уникален в документе';
    // }
  }

  return undefined;
};

export const validateUITU = (value, values, _, name) => {
  const uitName = name.replace('uitu_code', 'uit_code');
  const uit = get(values, uitName, '');
  const isError = (!uit || !uit.match(/^[A-z\d]{38}$/) || uit.match(/[^A-z\d]/)
        //|| values.products.filter(i => get(i, 'uit_code') === uit).length > 1
  );

  // uit - невалиден, поэтому проверяем uitu
  if (isError) {
    if (!value) return 'УИТУ не указан';
    if (!value.match(/^[A-z\d]{38}$/)) return 'Недопустимое количество символов УИТУ';
  }

  return undefined;
};

export const validateTNVED = (value) => {
  if (!value) return 'Код ТН ВЭД ЕАЭС не указан';
  if (value.length !== 10) return 'Недопустимое количество символов Кода ТН ВЭД';
  if (value.match(/[^\d]/)) return 'Недопустимый формат Кода ТН ВЭД';

  return undefined;
};

export const validateCountryClassifier = (value) => {
  if (!value) return 'Не указано';
  return codesCountryClassifier.some(item => item.type === value) ? undefined : 'Не указано';
};

export const validateNotSpecified = value => {
  if (isObject(value)) {
    return value.from && value.to ? undefined : 'Не указано';
  }
  return value ? undefined : 'Не указано';
};

export const validateRegistrationNumber = (value) => {
  if (!value) return 'Не указано';
  if (value.length !== 23) return 'Недопустимое количество символов';
  if (!value.match(/^\d{8}\/\d{6}\/\d{7}$/)) return 'Не соответствует формату';

  return undefined;
};

export const validateNumberDT = val => {
  const isError = !!required(val);
  return isError ? 'Не указано' : undefined;
}
