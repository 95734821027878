import {actionTypes} from '../constants/';


function updateConfig(config) {
  return {
    type: actionTypes.APP.CONFIG_UPDATE,
    value: config
  }
}


export { updateConfig };
