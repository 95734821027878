import moment from 'moment';

export const prepareSearchFilters = payload => payload.reduce(
  (prev, next) => {
    switch (next.id) {
      case 'createDate':
        return {
          ...prev,
          createDate: {
            from: moment(next.value.startDate).toISOString(),
            to: moment(next.value.endDate).toISOString(),
          },
        };
      case 'id':
        return {
          ...prev,
          id: next.value.trim(),
        };
      case 'from':
        return {
          ...prev,
          closingPeriod: {
            ...prev['closingPeriod'],
            from: moment(next.value.date).toISOString(),
            to: prev['closingPeriod'] && prev['closingPeriod'].to
              ? prev['closingPeriod'].to
              : '',
          },
        };
      case 'to':
        return {
          ...prev,
          closingPeriod: {
            ...prev['closingPeriod'],
            from: prev['closingPeriod'] && prev['closingPeriod'].from
              ? prev['closingPeriod'].from
              : '',
            to: moment(next.value.date).toISOString(),
          },
        };
      case 'periodClosingDocumentStatus':
        return {
          ...prev,
          documentStatuses: [ next.value ],
        };
      case 'reportStatus':
        return {
          ...prev,
          reportStatuses: [ next.value ],
        };
    }
    return prev;
  },
  {}
);

export const convertDateTimeToISOString = (date, time) => {
  const timeObject = {
    hour: +time.hours,
    minute: +time.minutes,
    second: +time.seconds,
    millisecond: 0,
  }

  const dateTime = moment(date).set(timeObject);

  return moment(dateTime).toISOString();
}

export const getFrontendTaskUrl = absolutePath => {
  const locationOrigin = window.location.origin;
  return /localhost/.test(locationOrigin)
    ? `https://qa04.gismt.crpt.tech${absolutePath}`
    : `${locationOrigin}${absolutePath}`
}

export const paramIdsMap = {
  createDate: 'documentCreateDate',
  id: 'id',
  from: 'periodFrom',
  to: 'periodTo',
  periodClosingDocumentStatus: 'status',
  reportStatus: 'analyticalDownloads.status',
};
