/* eslint-disable */
import * as Icons from '@crpt-ui/icons';
import { withStyles } from '@material-ui/core';
import clsx from 'classnames';
import React from 'react';

import Account from './Account';
import AccountsStyles from './Accounts.styles';
import { Translate } from '../Translate/Translate';

const Accounts = ({ accounts = [], children, classes, className, ...rest }) =>
  accounts.length ? (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.head}>
        <div className={classes.icon}>
          <Icons.Wallet />
        </div>
        <div className={classes.title}>{Translate('Счета')}</div>
      </div>

      <div className={classes.notification}>
        {Translate(
          'Обязательно указывайте номер лицевого счета в назначении платежа при оплате',
        )}
      </div>
      <div className={classes.body}>
        {accounts.map((account, index) => (
          <Account account={account} key={index} />
        ))}

        {children ? (
          <div style={{ padding: 16, boxShadow: '0 1px 0 #f2f2f2' }}>
            {children}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;

export default withStyles(AccountsStyles)(Accounts);
