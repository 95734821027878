import { createAction } from 'redux-actions';

export const subject = '[ImportThirdCountry]';

export const convert = createAction(`${subject} convert`);
export const convertCis = createAction(`${subject} convert cis`);
export const load = createAction(`${subject} load`);
export const error = createAction(`${subject} error`);
export const reset = createAction(`${subject} reset`);
export const disaggregation = createAction(`${subject} disaggregation`);
export const fixProducts = createAction(`${subject} fix products`);
