import React, { useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  DividerOfContent,
  DocumentTitleName
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { Translate } from '../../../common_components/Translate/Translate';
import { Button as SButton, themes as BUTTON_THEMES } from '@crpt/react-button';
import Icon from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { importStatusesNames } from '../../../constants/documentStatuses';
import { Accord, Content, IconWrap } from '../Act/Showing.styled';
import { get } from 'lodash';
import { documentTypeNames } from '../../../constants';
import { CrossborderWrap, StyledLink } from './EasCrossborder.styles';

export const EasCrossborderBasicInfo = ({ selectedDocument, id }) => {
  const [show, setShow] = useState({
    common: true,
    documents: true,
    sender: true,
    receiver: true,
  });

  const onShow = name => {
    setShow(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const linkedDocs = useMemo(() => {
    return selectedDocument.linkedDocs
      ? selectedDocument.linkedDocs.map((doc) => {
        const type = documentTypeNames[doc.docType];
        return {
          title: `${type} №${doc.docId} от ${doc.docDate}`,
          link: `/documents/list/${encodeURIComponent(doc.docId)}`
        }
      })
      : [];
  }, [selectedDocument]);

  return (
    <CrossborderWrap>
      <React.Fragment>
        <Grid container justify="space-between">
          <Grid item>
            <DocumentTitleName>
              {Translate('Уведомление об отгрузке ЕАЭС №')}{' '}
              <span>{id}</span>{' '}
            </DocumentTitleName>
          </Grid>
          <Grid item>
            <SButton theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(importStatusesNames[selectedDocument.status])}
            </SButton>
          </Grid>
        </Grid>
        <br/>
        <DividerOfContent/>
        <br />
        <Accord onClick={() => onShow('common')}>
          <span>{Translate('Сведения об отгрузке')}</span>
          <IconWrap>
            <Icon
              type={show.common ? 'up-arrow' : 'down-arrow'}
              spacingLeft={6}
            />
          </IconWrap>
        </Accord>
        {show.common && (
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {Translate('Номер первичного документа')} <br/>
              <Content>{get(selectedDocument, 'documentNum', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('Дата первичного документа')} <br/>
              <Content>{get(selectedDocument, 'documentDate', '-')}</Content>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={4}>
              {Translate('Дата отгрузки')} <br/>
              <Content>{get(selectedDocument, 'transferDate', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('Дата приемки')} <br/>
              <Content>{get(selectedDocument, 'acceptanceDate', '-')}</Content>
            </Grid>
          </Grid>
        )}
        <br />
        <DividerOfContent />
        <br />
        <Accord onClick={() => onShow('documents')}>
          <span>{Translate('Связанные документы')}</span>
          <IconWrap>
            <Icon
              type={show.documents ? 'up-arrow' : 'down-arrow'}
              spacingLeft={6}
            />
          </IconWrap>
        </Accord>
        {show.documents && (
          <Grid container spacing={1}>
            {
              linkedDocs.length > 0
                ? linkedDocs.map(
                  (doc) => (
                    <Grid item xs={12}>
                      <StyledLink to={doc.link} target="_blank">{doc.title}</StyledLink>
                    </Grid>
                  )
                )
                : (
                  <Grid item xs={12}>
                    <p>{Translate('Связанные документы отсутствуют')}</p>
                  </Grid>
                )
            }
          </Grid>
        )}
        <br />
        <DividerOfContent />
        <br />
        <Accord onClick={() => onShow('sender')}>
          <span>{Translate('Отправитель')}</span>
          <IconWrap>
            <Icon type={show.sender ? 'up-arrow' : 'down-arrow'} spacingLeft={6} />
          </IconWrap>
        </Accord>
        {show.sender && (
          <Grid container>
            <Grid item xs={4}>
              {Translate('Страна отправления')} <br />
              <Content>{get(selectedDocument, 'senderCountryCode', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('Наименование отправителя')} <br />
              <Content>{get(selectedDocument, 'exporter', '-')}</Content>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={4}>
              {Translate('ИНН отправителя')} <br />
              <Content>{get(selectedDocument, 'exporterInn', '-')}</Content>
            </Grid>
          </Grid>
        )}
        <br />
        <DividerOfContent />
        <br />
        <Accord onClick={() => onShow('receiver')}>
          <span>{Translate('Получатель')}</span>
          <IconWrap>
            <Icon type={show.receiver ? 'up-arrow' : 'down-arrow'} spacingLeft={6} />
          </IconWrap>
        </Accord>
        {show.receiver && (
          <Grid container>
            <Grid item xs={4}>
              {Translate('Наименование получателя')} <br />
              <Content>{get(selectedDocument, 'receiver', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('ИНН получателя')} <br />
              <Content>{get(selectedDocument, 'receiverInn', '-')}</Content>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    </CrossborderWrap>
  )
}
