import { defaultTheme } from './default';
import { loginTheme } from './login';
import { registrationTheme } from './registration';
import { exciseTheme } from './excise';

export {
  defaultTheme as sidebarDefaultTheme,
  loginTheme as sidebarLoginTheme,
  registrationTheme as sidebarRegistrationTheme,
  exciseTheme as sidebarExciseTheme
};
