import React, { Component } from 'react';
import * as _ from 'lodash';
import { Route, Switch, withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import 'moment/locale/ru';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import { Button } from '@crpt-ui/core';
import { Plus } from '@crpt-ui/icons';
import { Modal } from '@crpt-ui/core';

import LightTheme from '../../../common_components/_MaterialUi/LightTheme';
import UnloadService from '../../UnloadService/UnloadServiceCreate/UnloadServiceCreate';
import { taskTypeEnum } from '../../UnloadService/constants';
import ReportView from './Report.view';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';

moment.locale('ru');

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  setModalOpen = () => this.setState({ isModalOpen: true });
  setModalClose = () => this.setState({ isModalOpen: false });

  toUnloadServiceList = () => {
    const { history, item } = this.props;
    const systemName = _.get(item, 'name');
    history.push(`/unload-service/list?systemName=${encodeURI(systemName)}`);
  };

  render() {
    const { item, breadcrumbs, history } = this.props;
    const { isModalOpen } = this.state;

    const reportId = _.get(item, 'id');
    const systemName = _.get(item, 'name');

    const urlPrefix = 'http://testurl.ru';
    const url = new URL(`${urlPrefix}${_.get(item, 'link', '/')}`);
    const pg = url.searchParams.get('pg');

    return (
      <MuiThemeProvider theme={MaterialUiThemeKZ}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Breadcrumbs
              withCustomLinks
              onClick={value => history.push(value.href)}
              breadcrumbs={breadcrumbs}
            />
          </Grid>
        </Grid>

        <ReportView frameLink={item && item.link ? item.link : false} />

        <Modal
          onClose={this.setModalClose}
          open={isModalOpen}
          fullWidth={true}
          maxWidth="lg"
        >
          <UnloadService
            onClose={this.setModalClose}
            reportId={reportId}
            pg={pg}
            systemName={systemName}
            taskTypeId={taskTypeEnum.REPORT}
          />
        </Modal>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(Report);
