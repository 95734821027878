import { Button } from '@crpt/react-button';
import { Card } from '@crpt/react-card';
import { Dialog } from '@crpt/react-dialog';
import { Icon } from '@crpt/react-icon';
import { InlineWrap } from '@crpt/react-page-layout';
import { get } from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import {
  buttonCreateUserTheme,
  buttonRedTheme
} from '../../theme/ru-tabaco/button';
import {
  getCurrentParticipantId,
  getCurrentParticipantInn, isFoivOrOperator
} from '../../utils/userUtils';
import FilterForm from '../List/FilterForm';
import { filters } from './constants';
import { ProductsList } from './ProductsList';
import { Statistics } from './Statistics';
import { paramsFormatter } from './utils';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'src/common_components/Translate/Translate';

const useStyles = () => ({
  content: {
    marginTop: '6px'
  },
  filterButtonsWrap: {
    position: 'relative',
    top: '20px',
  },
  wrapDivider: {
    height: '32px'
  }
})

class Excise extends Component {
  input;

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  componentDidMount() {
    const { selectedParticipant } = this.props;

    const producerId = isFoivOrOperator() ? get(selectedParticipant, 'id', null) : getCurrentParticipantId();
    const producerInn = isFoivOrOperator() ? get(selectedParticipant, 'inn', null) : getCurrentParticipantInn();

    const {
      ListMounted,
      updateExciseStat,
      updateMarkingStat,
    } = this.props.actions.Excise;

    ListMounted(
      {
        producerId,
        producerInn,
      },
      paramsFormatter
    );
    updateExciseStat();
    updateMarkingStat();
  }

  onUpload() {
    this.props.actions.Excise.onDeclarationUpload();
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
    if (this.input) this.input.value = null;
    this.props.actions.Excise.declarationSelected(null);
  }

  onSelect(e) {
    this.props.actions.Excise.declarationSelected(e.target.files[0]);
  }

  render() {
    const {
      FilterClicked,
      updateExciseStat,
      updateMarkingStat,
    } = this.props.actions.Excise;

    const { declaration } = this.props.Excise;
    const {
      selectedParticipant,
      headingId,
      portalFilterButtonId,
      classes
    } = this.props;
    const headingElem = headingId && document.getElementById(headingId);
    const filterElem =
      portalFilterButtonId && document.getElementById(portalFilterButtonId);

    return (
      <Card>
        <Dialog
          isOpen={this.state.isModalOpen}
          minWidth={'482px'}
          maxWidth={'482px'}
          title={Translate('Загрузка декларации')}
          ButtonComponent={Button}
          content={
            <div>
              <label htmlFor="upload">
                {declaration
                  ? Translate('Выбран файл:')
                  : Translate('Выберите файл декларации *.xml')}
                <input
                  ref={el => (this.input = el)}
                  type="file"
                  id="upload"
                  name="upload"
                  accept=".xml"
                  onChange={this.onSelect}
                />
              </label>
            </div>
          }
          onOverlayClick={this.closeModal}
          buttons={[
            {
              children: (
                <React.Fragment>
                  {' '}
                  <Icon
                    spacingRight={16}
                    type={'download'}
                    fill={!declaration ? '#FFFFFF' : '#FF3D00'}
                  />Загрузить
                </React.Fragment>
              ),
              marginRight: 20,
              theme: { Button: buttonRedTheme },
              onClick: this.onUpload,
              disabled: !declaration,
            },
            {
              children: Translate('Отменить'),
              onClick: this.closeModal,
            },
          ]}
        />

        {filterElem &&
          ReactDOM.createPortal(
            <div className={classes.filterButtonsWrap}>
              {' '}
              <FilterForm
                filters={filters}
                onFilterApply={values => {
                  FilterClicked(values, paramsFormatter, vals => {
                    const newVals = Object.assign({}, vals);
                    delete newVals.isMonth;
                    return newVals;
                  });
                  updateMarkingStat();
                  updateExciseStat();
                }}
                total={0}
                isTotalHidden={true}
              />
            </div>,
            filterElem
          )}

        {selectedParticipant &&
          headingElem &&
          ReactDOM.createPortal(
            <InlineWrap flex alignItems="center" right>
              <Button
                onClick={this.openModal}
                theme={{ Button: buttonCreateUserTheme }}
              >
                <Icon type={'add-plus'} spacingRight={12} width={10} />
                {Translate('Загрузить декларацию')}
              </Button>
            </InlineWrap>,
            headingElem
          )}

        <div className={classes.content}>
          <div className={classes.wrapDivider} />

          <Statistics Excise={this.props.Excise} actions={this.props.actions} />

          <ProductsList
            Excise={this.props.Excise}
            actions={this.props.actions}
          />
        </div>
      </Card>
    );
  }
}

export default withRouter(withStyles(useStyles)(Excise));
