import React from 'react';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from 'src/components/Grid/Grid.styled';
import { GoodsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';
import { Value, Wrap } from 'src/fragments/Table/Cell/value';
import { packTrivialTypesKZ } from '../../../../../constants/packTypes';
import { getIconNameByPackType } from '../../../../../components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { countryCodes } from '../../../../../constants/countryCodes';
import { makeInputFilter } from '@crpt-ui/datatable';
import { Translate } from 'src/common_components/Translate/Translate';

export const actionSelectOptions = [
  {
    value: 1,
    label: Translate('Редактировать'),
  },
];

export const actionSelectOptionsKZ = [
  {
    value: 1,
    label: Translate('Удалить'),
  },
];

export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  ERROR,
};

export const CodesRenderer = ({ row, onClick }) => {
  return (
    <Wrap>
      <Value>{row.codes ? row.codes[0] : null}</Value>&nbsp;
      <Link href={''} onClick={onClick}>
        +{row.codes ? row.codes.length - 1 : null}
      </Link>
    </Wrap>
  );
};

export const columnsKZExportInEaes = [
  {
    id: 'serialNumber',
    accessor: row => (
      <div>
        <Value>{row.serialNumber}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    disableFilters: true,
    width: 100,
  },
  {
    id: 'name',
    accessor: row => (
      <div>
        <Value>{row.name || '-'}</Value>
      </div>
    ),
    Header: Translate('Наименование товара'),
    width: 200,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 200,
  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = row.cisPackageType.toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{Translate(packTrivialTypesKZ[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    width: 200,
  },
  {
    id: 'cis',
    accessor: 'cis',
    Header: Translate('Код идентификации'),
    width: 300,
  },
];

export const columnsKZ = [
  {
    id: 'serialNumber',
    accessor: row => (
      <div>
        <Value>{row.serialNumber}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    disableFilters: true,
    width: 100,
  },
  {
    id: 'name',
    accessor: row => (
      <div>
        <Value>{row.name || '-'}</Value>
      </div>
    ),
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 'auto',
  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = row.cisPackageType.toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{Translate(packTrivialTypesKZ[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    width: 'auto',
  },
  {
    id: 'cis',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код идентификации'),
    width: 'auto',
  },
  // {
  //   id: 'cisesCounts',
  //   accessor: 'cisesCounts',
  //   Header: Translate('Количество потребительских упаковок'),
  //   width: 'auto',
  // },
];

export const columnsUZ = [
  {
    id: 'cis',
    accessor: row => (
      <div>
        <Value>{row.cis}</Value>
      </div>
    ),
    Header: Translate('Код'),
    disableFilters: true,
  },
];

export const columnsKG = [
  {
    ...GoodsColumns.name,
    disableFilters: true,
  },
  {
    ...GoodsColumns.gtin,
    disableFilters: true,
  },
  {
    id: 'codes',
    accessor: row => (
      <div>
        <Value>{row.codes[0]}</Value>click here...
      </div>
    ),
    Header: Translate('Код маркировки'),
    disableFilters: true,
  },
  {
    ...GoodsColumns.certNumber,
    disableFilters: true,
  },
  {
    ...GoodsColumns.certDate,
    disableFilters: true,
  },
  {
    ...GoodsColumns.tnved,
    disableFilters: true,
  },
  {
    ...GoodsColumns.country,
    disableFilters: true,
  },
];

export const columnsThirdCountryKG = [
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    disableSortBy: true,
    width: 'auto',
  },
  {
    id: 'name',
    accessor: 'name',
    Cell: ({ row }) => {
      return <Value>{row.name}</Value>;
    },
    Header: Translate('Наименование'),
    // Filter: makeInputFilter({ label: 'Наименование' }),
    width: 'auto',
  },
  {
    id: 'codes',
    accessor: row => (
      <div>
        <Value>{row.codes[0]}</Value>click here...
      </div>
    ),
    Header: Translate('Код маркировки'),
    disableSortBy: true,
    width: 'auto',
  },
  {
    id: 'tnved',
    accessor: 'tnved',
    Cell: ({ row }) => {
      return <Value>{row.tnved}</Value>;
    },
    Header: Translate('Код ТН ВЭД'),
    width: 'auto',
  },
  {
    id: 'numberDT',
    accessor: 'numberDT',
    Cell: ({ row }) => {
      return <Value>{row.numberDT || '-'}</Value>;
    },
    Header: Translate('Номер товара в ДТ'),
    Filter: makeInputFilter({ label: 'Номер товара в ДТ' }),
    width: 'auto',
  },
  {
    id: 'country',
    accessor: 'country',
    Cell: ({ row }) => {
      const country = countryCodes.find(item => item.type === row.country);
      return <Value>{country ? country.title : '-'}</Value>;
    },
    Header: Translate('Страна производства'),
    width: 'auto',
  },
  {
    id: 'cert_number',
    accessor: 'cert_number',
    Cell: ({ row }) => {
      return <Value>{row.cert_number || '-'}</Value>;
    },
    Header: Translate('Номер сертификата'),
    width: 'auto',
  },
  {
    id: 'cert_date',
    accessor: 'cert_date',
    Cell: ({ row }) => {
      return <Value>{row.cert_date || '-'}</Value>;
    },
    Header: Translate('Дата сертификата'),
    width: 'auto',
  },
];
