import { createAction } from 'redux-actions';

export const subject = '[Preview]';

// export const openFile = createAction(
//   `${subject} open file`,
//   payload => payload,
//   (_, meta) => meta
// );
//
// export const openString = createAction(
//   `${subject} open string`,
//   payload => payload,
//   (_, meta) => meta
// );

export const setContent = createAction(
  `${subject} set content`,
  payload => payload,
  (_, meta) => meta,
);

export const setCallbacks = createAction(`${subject} set callbacks`);
export const open = createAction(`${subject} open`);
export const close = createAction(`${subject} close`);
