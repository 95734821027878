import { call, put, takeEvery } from 'redux-saga/effects';

import Api from 'src/common_components/Api/Api';
import * as _ from 'lodash';
import { setProductionData, onLoad } from './Production.actions';

function* fetchProductionLines(participantId) {
  const [productionLines] = yield call(Api.request, {
    url: `/api/v3/facade/profile/productions/participant-id/${participantId}`,
  });

  if (productionLines) {
    yield put(setProductionData(productionLines.data));
  }
}

function* fetchProductionCountries() {
  const [productionCountries] = yield call(
    Api.request,
    {
      url: '/api/v3/facade/profile/productions/countries',
    },
    {
      preloading: false,
    },
  );

  if (productionCountries) {
    const productionCountriesList = productionCountries.data.map(country => ({
      id: country.numericCode,
      title: country.name,
      label: country.name,
      type: country.numericCode,
    }));
    const sorted = _.sortBy(productionCountriesList, ['title']);
    yield put(setCountries(sorted));
  }
}

function* fetchProductionDataSaga({ payload }) {
  yield call(fetchProductionLines, payload);
  // yield call(fetchProductionCountries);
}

export default function* watch() {
  yield takeEvery(onLoad, fetchProductionDataSaga);
}
