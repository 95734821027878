import React, { useEffect, useMemo, useState } from 'react';
import { Header } from './components/header';
import { FormWrap, GridContainer, Title, useStyles, Breadcrumb } from './creation.styled';
import { ListPreview } from './components/preview';
import { SelectButton } from './components/select-button';
import UZ from '../../common_components/Icons/article/uz.svg';
import RU from '../../common_components/Icons/article/ru.svg';
import Article from '../../common_components/Icons/article/Article.svg';
import Banner from '../../common_components/Icons/article/Banner.svg';
import { FormControlLabel, FormGroup, Icon, Switch, TextField } from '@material-ui/core';
import { ImageSelector } from './components/image-selector';
import { OrderField } from './components/order-field';
import { history } from '../../store';
import Editor from './components/editor';
import { useTranslation } from 'react-i18next';
import * as actions from './ducks/articlesCreation.actions';
import * as listActions from '../ArticleListPage/ducks/articles.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withPermissions from '../../common_components/hocs/withPermissions';
import { permissionConfig } from '../../motp_components/_Requests/RequestList/RequestList.permissions';
import { getHtmlTemplate, getTitleTemplate, socialString } from './components/editor/constants';
import moment from 'moment';
import { BUCKET_URL } from '../ArticleListPage/constants';

const ArticleCreation = ({ create, update, loadList, list }) => {
  const halfHour = 1800000;
  const utcDifference =  (Math.abs(new Date().getTimezoneOffset()) * 60000);
  const addThirtyMinutes = new Date().getTime() + halfHour + utcDifference;
  const addOneMonth = new Date(new Date().getTime() + utcDifference).setMonth(new Date().getMonth() +1);
  const minDateTimeFrom = new Date(addThirtyMinutes).toISOString().slice(0,new Date().toISOString().lastIndexOf(":"));
  const minDateTimeTo= new Date(addOneMonth).toISOString().slice(0,new Date().toISOString().lastIndexOf(":"));
  const [lang, setLang] = useState('uz');
  const [type, setType] = useState('article');
  const [listTitle, setListTitle] = useState('');
  const [title, setTitle] = useState('');
  const [order, setOrder] = useState(1);
  const [isEqualTitles, setIsEqualTitles] = useState(false);
  const [status, setStatus] = useState('');
  const [isManualUnPublish, setIsManualUnPublish] = useState(false);
  const [dateStart, setDateStart] = useState(minDateTimeFrom);
  const [dateFinish, setDateFinish] = useState(minDateTimeTo);
  const [text, setText] = useState('');
  const [icon, setIcon] = useState('');
  const [banner, setBanner] = useState(null);
  const [hasSocialNetworks, setHasSocialNetworks] = useState(false);

  const isUpdate = location.pathname.includes('update');
  const isCopy = location.pathname.includes('copy');
  const pathnameUrl = isUpdate ? '/mobile-content/articles/update/' : '/mobile-content/articles/copy/'
  const url = location.pathname.replace(pathnameUrl, '').split('/');
  const { t } = useTranslation();
  const [isFromDateError, setFromDateError ] = useState(false);
  const [isToDateError, setToDateError] = useState(false);
  const [helperTextFrom, setHelperTextFrom] = useState(' ');
  const [helperTextTo, setHelperTextTo] = useState(' ');
  
  const styles = useStyles();

  const dateTimeClassNameFrom = isFromDateError && styles.controlError;
  const dateTimeClassNameTo = isToDateError && styles.controlError;

  useEffect(() => {
    if (isUpdate || isCopy) {
      loadList(url[0]);
    }
  }, []);

  useEffect(() => {
    const item = list && list.find(({ id }) => id == url[1]);

    if (item && (isUpdate || isCopy)) {
      setLang(url[0]);
      setStatus(item.status)
      setListTitle(item.name);
      setTitle(item.subtitle);
      setOrder(item.order);
      if (item.itemType === 'article') {
        setIcon(item.icon);
      } else {
        setBanner(item.icon);
      }

      if (isUpdate) {
        setDateStart(moment(item.startDate).format('YYYY-MM-DDTHH:mm'));

        if (item.expirationDate) {
          setDateFinish(moment(item.expirationDate).format('YYYY-MM-DDTHH:mm'));
        } else {
          setIsManualUnPublish(true)
        }
      }

      setType(item.itemType);

      fetch(BUCKET_URL + item.articleUrl)
        .then((resp) => {
          resp.text().then((t) => {
            if (t.includes(socialString)) {
              setHasSocialNetworks(true);
            }
            setText(t.replace(`<h2>${item.subtitle}</h2>`, ''));
          })
        })
    }
  }, [list]);

  useEffect(() => {
    if (isManualUnPublish) {
      setDateFinish('');
      setToDateError(false);
      setHelperTextTo(' ')
    }
  }, [isManualUnPublish, dateStart]);

  const handleChangeTitle = (val) => {
    setListTitle(val);
    if (isEqualTitles) {
      setTitle(val);
    }
  }

  const handleChangeSwitch = (val) => {
    if (val) {
      setTitle(listTitle);
    }
    setIsEqualTitles(val)
  }

  const isSubmitDisable = useMemo(() => {
    let errors = !listTitle || !title || !dateStart || !text;

    if (!isManualUnPublish) {
      errors = !dateFinish
    }

    return errors;
  }, [
    listTitle,
    title,
    dateStart,
    dateFinish,
    text,
    isManualUnPublish,
  ])

  const onSubmit = () => {
    const body = {
      lang,
      name: listTitle,
      subtitle: title,
      itemType: type,
      icon: type === 'article' ? icon : banner,
      order,
      startDate: moment(dateStart).format(),
      expirationDate: moment(dateFinish).format(),
    };
    const articleTitle = getTitleTemplate(title);
    const social = hasSocialNetworks ? socialString : '';
    const article = getHtmlTemplate(articleTitle + text + social)

    if (isUpdate) {
      update({ body, text: article, id: url[1] });
    } else {
      create({ body, text: article });
    }
  }

  const handelHelperTextFrom = (message) => {
    if (isFromDateError) {
      setHelperTextFrom(t('Невозможно установить время меньше установленного по умолчанию'))
    } else {
      setHelperTextFrom(message)
    }
  }

  const handelHelperTextTo = (message) => {
    if (isToDateError) {
      setHelperTextTo(helperTextTo)
    } else {
      setHelperTextTo(message)
    }
  }

  const handleChangeDateFrom = (event) => {
    setFromDateError(false);
    const minDate = new Date(new Date().getTime() + halfHour + utcDifference).toISOString().slice(0,new Date().toISOString().lastIndexOf(":"));
    const formattedMinDate = moment(minDate);
    const formattedInputDate = moment(event.target.value);
    if (formattedMinDate > formattedInputDate) {
      setFromDateError(true);
    } else {
      setDateStart(event.target.value);
    }
  }


  const handleChangeDateTo = (event) => {
    setToDateError(false);
    const formattedMinDate = moment(dateStart);
    const formattedInputDate = moment(event.target.value);
    const systemDateFormatted = moment(new Date())
    
    if (formattedMinDate >= formattedInputDate) {
      setToDateError(true);
      setHelperTextTo(t("Значение должно быть больше указанного в поле 'С'"))
    } else if (systemDateFormatted >= formattedInputDate) {
      setHelperTextTo(t('Невозможно установить дату меньше текущей системной даты'))
      setToDateError(true);
    } else  {
      setDateFinish(event.target.value);
    }
  }

  return <div>
    <Breadcrumb onClick={() => history.push("/mobile-content/articles")}>{t('Управление контентом')}</Breadcrumb>
    <Header isCreation={!isUpdate} idDisableSubmit={isSubmitDisable} onSubmit={onSubmit}/>
    <FormWrap>
      <Title>{t('Общая информация')}</Title>
      <GridContainer>
        <div>
          <div>
            <label>{t('Язык')}</label>
            <div className={styles.flex}>
              <SelectButton
                onClick={() => setLang('uz')}
                title={"Узбекский"}
                selected={lang === 'uz'}
                icon={<Icon component={UZ}/>}
                disabled={isUpdate || isCopy}
              />
              <SelectButton
                onClick={() => setLang('ru')}
                title={"Русский"}
                selected={lang === 'ru'}
                icon={<Icon component={RU}/>}
                disabled={isUpdate || isCopy}
              />
            </div>
          </div>
          <br/>
          <div>
            <label>{t('Тип статьи')}</label>
            <div className={styles.flex}>
              <SelectButton
                onClick={() => setType('article')}
                title={"Статья"}
                selected={type === 'article'}
                icon={<Icon component={Article}/>}
                disabled={isUpdate || isCopy}
              />
              <SelectButton
                onClick={() => setType('carousel')}
                title={"Баннер"}
                selected={type === 'carousel'}
                icon={<Icon component={Banner}/>}
                disabled={isUpdate || isCopy}
              />
            </div>
          </div>
          <br/>
          <ImageSelector lang={lang} type={type} icon={icon} setIcon={setIcon} banner={banner} setBanner={setBanner}/>
          <br/>
          <label>{t('Заголовок в списке статей')}*</label>
          <div className={styles.input}>
            <input
              required
              name="listTitle"
              value={listTitle}
              onChange={(e) => handleChangeTitle(e.target.value)}
            />
          </div>
          <br/>
          <label>{t('Заголовок при просмотре статьи')}*</label>
          {
            !isUpdate && (
              <FormGroup className={styles.switch}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isEqualTitles}
                      onChange={() => handleChangeSwitch(event.target.checked)}
                    />}
                  label={t('Совпадает с заголовком в ленте')}
                />
              </FormGroup>
            )
          }
          <div className={styles.input}>
            <input
              required
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              disabled={isEqualTitles}
            />
          </div>
          <br/>
          <label>{t('Порядковый номер статьи в ленте')}</label>
          <OrderField value={order} onChange={setOrder} type={type}/>
          <br/>
          <label>{t('Период отображения в приложении')}*</label>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={isManualUnPublish}
                  onChange={() => setIsManualUnPublish(event.target.checked)}
                />}
              label={t('Ручное снятие с публикации')}
            />
          </FormGroup>
          <div className={styles.datapickers}>
            <div className={styles.datapicker}>
              <label>{t('C')}*</label>
              <TextField
                className={dateTimeClassNameFrom}
                type="datetime-local"
                value={dateStart}
                error={isFromDateError}
                disabled={isUpdate && status !== 'PLANNED'}
                min={new Date().toISOString().slice(0, -8)}
                onChange={handleChangeDateFrom}
                onMouseEnter={() => handelHelperTextFrom(t('Невозможно установить время меньше установленного по умолчанию'))}
                onMouseLeave={() => handelHelperTextFrom(' ')}
                helperText={helperTextFrom}
              />
            </div>
            <div className={styles.divider}>
              -
            </div>
            <div className={styles.datapicker}>
              <label>{t('По')}*{dateFinish}</label>
              <TextField
                className={dateTimeClassNameTo}
                placeholder="до"
                type="datetime-local"
                disabled={isManualUnPublish}
                value={dateFinish}
                error={isToDateError}
                min={minDateTimeTo}
                onChange={handleChangeDateTo}
                onMouseEnter={() => handelHelperTextTo(t("Значение должно быть больше указанного в поле 'С'"))}
                onMouseLeave={() => handelHelperTextTo(' ')}
                helperText={helperTextTo}
              />
            </div>
          </div>
        </div>
        <ListPreview title={listTitle} type={type} order={order} image={banner} icon={icon}/>
      </GridContainer>
    </FormWrap>
    <Editor
      lang={lang}
      title={title}
      setText={setText}
      value={text}
      hasSocialNetworks={hasSocialNetworks}
      setHasSocialNetworks={setHasSocialNetworks}
    />
  </div>
}

const mapState = state => ({
  item: state.LP.ArticleCreation.item,
  list: state.LP.ArticleListPage.list,
});

const mapDispatch = dispatch => ({
  create: (data) => dispatch(actions.create(data)),
  update: (data) => dispatch(actions.update(data)),
  loadList: (payload) => dispatch(listActions.loadList(payload)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
)(ArticleCreation);
