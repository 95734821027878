export default {
  title: 'Табличное представление при выборе типа агрегации «По населённому пункту»:',
  amountOfTable: 2,
  tables: [
    {
      headers: [
        {
          Header: 'Субъект РК',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
          subheaders: []
        },
        {
          Header: 'Населённый пункт',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
          subheaders: []
        },
        {
          Header: 'Численность населённого пункта',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 80,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Доля рынка по реализации товаров',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 320,
          subheaders: [
            {
              Header: '% в штуках',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 160,
              subheaders: []
            },
            {
              Header: '% в тенге (по цене продажи)',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 160,
              subheaders: []
            },
          ]
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
        }
      ]
    },
  ]
}
