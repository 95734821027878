import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    header: {
      display:'flex',
      justifyContent: 'space-between',
      alignItems:'center'
    },
    title: {
      marginBottom:'0'
    }
  });
