import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxWrap, PlusWrap, MinusWrap } from './ToggleCheckbox.styled';
import { checkboxState } from './ToggleCheckbox';

const ToggleCheckboxView = ({ onClick, state }) => {
  const { disabled, status } = state;

  return (
    <CheckboxWrap onClick={!disabled && onClick} state={status}>
      {status === checkboxState.NORMAL && (<PlusWrap>+</PlusWrap>)}
      {status === checkboxState.SELECTED && (<MinusWrap>-</MinusWrap>)}
      {status === checkboxState.EDITED && (<PlusWrap>+</PlusWrap>)}
    </CheckboxWrap>
  );
};
ToggleCheckboxView.propTypes = {
  onClick: PropTypes.func.isRequired,
  state: PropTypes.shape({}).isRequired,
};
export default ToggleCheckboxView;
