import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [3],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [4, 5],
          'col2': <div>(Объём производства маркированного товара за выбранный период (в штуках и тенге) / Объём производства всех маркированных товаров на рынке за выбранный период (в штуках и рублях)) <strong>×</strong> 100%</div>
        }
      ]
    }
  ]
}
