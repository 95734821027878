import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Group, Ring, Arc, Text } from 'react-konva';


class SinglePercentChart extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      backRing: PropTypes.shape({
        innerRadius: PropTypes.number.isRequired,
        outerRadius: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
      }).isRequired,
      frontRing: PropTypes.shape({
        innerRadius: PropTypes.number.isRequired,
        outerRadius: PropTypes.number.isRequired,
        fill: PropTypes.string.isRequired,
        rotationDeg: PropTypes.number.isRequired,
      }).isRequired,
      percentText: PropTypes.shape({
        fontSize: PropTypes.number.isRequired,
        fontFamily: PropTypes.string.isRequired,
        fill: PropTypes.string.isRequired,
      }).isRequired,
      percentSign: PropTypes.shape({
        fontSize: PropTypes.number.isRequired,
        fontFamily: PropTypes.string.isRequired,
        fill: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    percent: PropTypes.number.isRequired,
  };

  renderPercent = () => {
    const { percent, theme } = this.props;
    const { frontRing, percentText, percentSign } = theme;

    const size = frontRing.outerRadius * 2;
    const center = size / 2;

    const text = `${parseFloat(percent).toFixed(2)}`;
    const percentPositionX = -text.length * percentText.fontSize / 4
      - percentSign.fontSize / 2 + 6;
    const percentPositionY = -percentText.fontSize / 2 - 1;
    const signPositionX = -percentSign.fontSize / 2
      + text.length * percentText.fontSize / 3.5;
    const signPositionY = -4;

    return (
      <Group
        x={center}
        y={center}
      >

        <Text
          x={percentPositionX}
          y={percentPositionY}
          {...percentText}
          text={text}
        />
        <Text
          x={signPositionX}
          y={signPositionY}
          {...percentSign}
          text="%"
        />
      </Group>
    );
  };

  render() {
    const { percent, theme } = this.props;
    const { backRing, frontRing, frontRingShadowWidth } = theme;
    const size = frontRing.outerRadius * 2;
    const center = size / 2;

    return (
      <Stage width={size + frontRingShadowWidth} height={size + frontRingShadowWidth}>
        <Layer>
          <Ring x={center} y={center} {...backRing} />
          <Arc x={center} y={center} {...frontRing} angle={percent * 3.6} />

          {this.renderPercent()}
        </Layer>
      </Stage>
    );
  }
}

export default SinglePercentChart;
