import React from 'react';
import { ParentActWrap } from '../../Document/Document.styled';
import { StyledLink } from '../../../OutcomeList/OutcometList.constants';
import { Icon } from '@crpt/react-icon';

export const ParentAct = ({ id }) => {
  return (
    <ParentActWrap>
      <div style={{ marginRight: 5, paddingTop: 5 }}>
        <Icon type={'info'} />
      </div>
      <StyledLink to={`/documents/list/act-out/${id}`}>Исходный акт</StyledLink>
    </ParentActWrap>
  )
};
