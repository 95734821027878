import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@crpt/react-icon';
import { HintedAddressInput, Input, Button } from '../../../Fields';
import { Closed } from '../styled';
import { required, is16digit } from '../../../utils/validations';


const iconStyle = { marginBottom: '16px' };

class SalesOutlet extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    onActivate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onPushValue: PropTypes.func.isRequired,
  };

  onAddKKT = () => {
    const { onPushValue, name } = this.props;
    onPushValue(`${name}.kkt`, {number: ''});
  };

  onActivate = () => {
    const { onActivate, index } = this.props;
    onActivate(index);
  };

  onRemove = () => {
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  render() {
    const { name, data, active } = this.props;
    const { address, code, kkt } = data;

    if (active) {
      return (
        <React.Fragment>
          <HintedAddressInput
            name={`${name}.address`}
            placeholder="Адрес точки продаж"
            defaultValue={address}
            validate={required}
          />
          <Input
            name={`${name}.code`}
            placeholder="Код точки продаж"
            defaultValue={code}
            validate={required}
          />
          {kkt.map((number, index) => (
            <Input
              key={`${name}.kkt[${index}].number`}
              name={`${name}.kkt[${index}].number`}
              placeholder="Регистрационный номер кассы"
              errorPlaceholder="Регистрационный номер кассы должен состоять из 16 цифр"
              defaultValue={number.number}
              mask={'9999999999999999'}
              index={index + 1}
              total={kkt.length}
              validate={is16digit}
            />
          ))}
          <Button onClick={this.onAddKKT}>
            Добавить кассу
          </Button>
        </React.Fragment>
      );
    }

    return (
      <Closed>
        <Button onClick={this.onActivate}>{address}</Button>
        <Icon type="cross" size={16} style={iconStyle} onClick={this.onRemove} />
      </Closed>
    );
  }
}

export default SalesOutlet;
