import styled from 'styled-components';


export const Heading = styled.div`
  display: flex;
  margin-bottom: 42px;
  margin-top: ${props => props.excise ? '-50px' : ''};
  font-size: 24px;
  color: #52535A;
  font-weight: 400;
  align-items: center;
`;
