import React, { useMemo } from 'react';
import Datatable from '@crpt-ui/datatable';
import { TableWrap } from '../Act/Showing.styled';
import { columnsCises, acceptedColumn } from './Crossborder.constants';
import { CrossborderWrap } from './EasCrossborder.styles';
import { importStatuses } from '../../../constants/documentTypes';
import { prepareCisList } from './Crossborder.utils';

export const CrossborderCises = ({ selectedDocument }) => {
  const list = useMemo(() => prepareCisList(selectedDocument.products), [selectedDocument]);

  const isAccepted = useMemo(() => !!selectedDocument.acceptanceDate, [selectedDocument]);
  const isDisabled = useMemo(() => selectedDocument.status !== importStatuses.ACCEPTED, [selectedDocument]);

  const columns = useMemo(() => {
    const commonColumns = columnsCises(isDisabled)
    return isAccepted ? [...commonColumns, acceptedColumn] :  [...commonColumns];
  }, [isAccepted, isDisabled]);

  return (
    <CrossborderWrap withoutPadding>
      <TableWrap>
        <Datatable columns={columns} useSort={false} data={list} />
      </TableWrap>
    </CrossborderWrap>
  )
}
