import { TwoLineLabel } from '@crpt/react-label';
import { Column } from '@crpt/react-page-layout';
import PropTypes from 'prop-types';
import React from 'react';
import ParticipantNameBound from 'src/containers/Bounds/ParticipantNameBound';
import { Link } from 'src/fragments/Link';
import { isFoivOrOperator } from 'src/utils/userUtils';

import { useStyles } from '../../Operation.styles';
import { Translate } from '../../../../../../common_components/Translate/Translate';

const SecondCell = ({ firstHeading, secondHeading, op }) => {
  const classes = useStyles();

  const ParticipantLink = ({ to, children }) =>
    isFoivOrOperator() ? (
      <Link to={to}>{children}</Link>
    ) : (
      <div>{children}</div>
    );

  return (
    <Column col={4}>
      <div className={classes.fullWidthWrapper}>
        <TwoLineLabel heading={firstHeading}>
          <ParticipantLink
            to={`/participants/list/${op.participant1Inn}/basic-info`}
          >
            <ParticipantNameBound
              wrap
              name={op.participant1Name}
              id={op.participant1Id}
              inn={op.participant1Inn}
            />
          </ParticipantLink>
        </TwoLineLabel>
        {secondHeading && (
          <div className={classes.wrap}>
            <TwoLineLabel heading={secondHeading}>
              <ParticipantLink
                to={`/participants/list/${op.participant2Inn}/basic-info`}
              >
                <ParticipantNameBound
                  name={op.participant2Name}
                  id={op.participant2Id}
                  inn={op.participant2Inn}
                />
              </ParticipantLink>
            </TwoLineLabel>
          </div>
        )}
      </div>
    </Column>
  );
};

SecondCell.defaultProps = {
  firstHeading: Translate('Производитель'),
  secondHeading: '',
};

SecondCell.propTypes = {
  firstHeading: PropTypes.string,
  secondHeading: PropTypes.string,
  op: PropTypes.object.isRequired,
};

export default SecondCell;
