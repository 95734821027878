import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import 'datejs';
import { withRouter } from 'react-router-dom';
import { Input } from '@crpt/react-input';

import { scenariosInfo } from './constants';
import { getColumnsByScheme, getOptionsByScheme } from './utils';

import CisListView from '../CisList/View/CisListView';
import { AltScenariosService } from '../../services/AltScenariosService';
import {Translate} from '../../common_components/Translate/Translate';

class CISList extends Component {
  static propTypes = {
    App: PropTypes.shape({
      config: PropTypes.shape({
        lists: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      AltScenarioCISList: PropTypes.shape({
        onPageSelect: PropTypes.func.isRequired,
        onFilterApplyClick: PropTypes.func.isRequired,
        onListMount: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    CISList: PropTypes.shape({
      selectedPage: PropTypes.number.isRequired,
      pagesCount: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      List: PropTypes.any,
      isInfinite: PropTypes.bool.isRequired,
      preloaderIsOpen: PropTypes.bool.isRequired,
    }).isRequired,
    theme: PropTypes.any.isRequired,
  };

  async componentDidMount() {
    const { actions } = this.props;
    actions.AltScenarioCISList.onListMount();
    const type = get(this.props, 'match.params.type', null);
    actions.AltScenarioCISList.getScheme(type);
  }

  getPreparedFilters = () => {
    const filters = get(this.props, 'App.config.lists.CISList.filters', []);
    const type = get(this.props, 'match.params.type', null);
    const downloadButton = Object.assign({}, filters[0]);
    return [
      { ...downloadButton, showDownloadListButton: false },
      ...filters.slice(1),
      [
        {
          component: Input,
          props: {
            name: 'scenarioType',
            defaultValue: type,
            style: { display: 'none' },
          },
        },
      ],
    ];
  };

  render() {
    const {
      actions: {
        AltScenarioCISList: { onPageSelect, onFilterApplyClick },
      },
      CISList: {
        selectedPage,
        pagesCount,
        total,
        List,
        isInfinite,
        preloaderIsOpen,
      },
      AltScenarios: { scheme },
    } = this.props;
    const type = get(this.props, 'match.params.type', null);

    const preparedFilters = this.getPreparedFilters();

    const { statistic } = this.props.AltScenarios;
    const scenario =
      statistic &&
      statistic[type] &&
      statistic[type].find(v => v.scenarioType.code === type);

    const scenarioName =
      scenario && scenario.scenarioType && scenario.scenarioType.descr;

    const scenarioColumns = type
      ? getColumnsByScheme(
          scheme
            ? type !== '9.1.2' && type !== '14'
              ? scheme
              : scheme.filter(c => c.title !== 'Покупатель')
            : scenariosInfo[type] && scenariosInfo[type].scheme
            ? type !== '9.1.2' && type !== '14'
              ? scenariosInfo[type].scheme
              : scenariosInfo[type].scheme.filter(c => c.title !== 'Покупатель')
            : [],
        )
      : [];

    const scenarioOptions = type
      ? getOptionsByScheme(
          scheme
            ? scheme
            : scenariosInfo[type] && scenariosInfo[type].scheme
            ? scenariosInfo[type].scheme
            : [],
        )
      : [];

    const breadcrumbs = [
      {
        href: '/charts',
        title: Translate('Сводная'),
        id: 1,
      },
      {
        href: `cis/scenario/${type}`,
        title: <span style={{ fontWeight: 600 }}>{scenarioName}</span>,
        id: 2,
      },
    ];

    return (
      <CisListView
        selectedPage={selectedPage}
        breadcrumbs={breadcrumbs}
        pagesCount={pagesCount}
        onPageSelect={onPageSelect}
        onFilterApplyClick={onFilterApplyClick}
        total={total}
        data={List}
        isInfinite={isInfinite}
        preloaderIsOpen={preloaderIsOpen}
        columns={scenarioColumns}
        options={scenarioOptions}
        filters={preparedFilters}
      />
    );
  }
}

export default withTheme(withRouter(CISList));
