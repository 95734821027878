import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FieldsView from './Fields.view';
import * as actions from '../ducks/LoginFormKep.actions';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as selectors from '../ducks/LoginFormKep.selectors';
import SidebarContent from '../SidebarContent/SidebarContent';

const Fields = props => {
  const { onOpenSidebar, ...pass } = props;
  const openSidebar = useCallback(() => onOpenSidebar(<SidebarContent />), []);

  return <FieldsView {...pass} onOpenSidebar={openSidebar} />;
};

Fields.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
};

const mapState = state => ({
  errors: selectors.errors(state),
  disabled: selectors.submitDisabled(state),
});

const mapDispatch = dispatch => ({
  onSelectCertificate: () => dispatch(actions.errors()),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
});

export default connect(
  mapState,
  mapDispatch,
)(Fields);
