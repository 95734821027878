export class StoreHelper {
  static _store;
  static setStore(store) {
    if (!StoreHelper._store) {
      StoreHelper._store = store;
    }
  }

  static getStore() {
    return StoreHelper._store;
  }

  static getState() {
    return StoreHelper._store ? StoreHelper._store.getState() : {};
  }

  static dispatch(action) {
    return StoreHelper._store ? StoreHelper._store.dispatch(action) : null;
  }
}
