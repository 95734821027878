import {actionTypes} from '../../constants/';

export function stat(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.EXCISE.STAT_LOADED:
      return action.value;
    default:
      return state;
  }
}
