import React from 'react';
import * as Icons from '@crpt-ui/icons';
import { logoutUser } from 'src/common_components/Auth/Auth.utils';
import { UserBalance } from './UserBalance/UserBalance';
import { Translate } from '../../Translate/Translate';

/**
 * @param {Array} balanceAccounts Array of user balance accounts.
 * @param {boolean} isUserOGV Is user from the government
 * @param fetchSignOut
 * @param countryCode
 * @returns {Array} Array of menu items for user profile based on user OGV/Foiv status
 */
export const getMenuItems = (balanceAccounts, isUserOGV, fetchSignOut, countryCode) => {

  const items = [
    ...isUserOGV ? [] : [
      {
        key: 'profile',
        component: 'a',
        href: window.env.REACT_APP_ELK_URL + '/profile',
        label: 'Профиль',
        icon: <Icons.Profile />,
      },
      {
        key: 'documents',
        component: 'a',
        href: window.env.REACT_APP_ELK_URL + '/documents',
        label: 'Документы от оператора',
        icon: <Icons.Documents />,
      }
    ],
    {
      label: 'Выход',
      icon: <Icons.Signout />,
      onClick: () => { countryCode === 'KZ' ? fetchSignOut() : logoutUser() },
      key: 'logout',
    },
  ];

  if (!isUserOGV && balanceAccounts !== undefined) {
    items.unshift({
      component: () => <UserBalance accounts={balanceAccounts} key="balance" />,
    });
  }
  return items.map(item => ({ ...item, label: Translate(item.label) }));
};

export const makeShortName = longName => {
  if (!longName) {
    return '';
  }

  const [surname, ...names] = longName.split(' ');
  const abbrs = names.map(name => `${name.slice(0, 1)}. `);

  return `${surname} ${abbrs.join('')}`;
};

export const getUserIcon = (isFoiv, countryCode) => (
  <div
    style={{
      width: 40,
      height: 40,
      background: countryCode === 'KZ'
          ? '#0287B0'
          : '#67ac5c',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
    }}
  >
    <Icons.User />
  </div>
);
