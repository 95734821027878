import React, { useMemo, useRef, useState } from 'react';
import { FormWrap, GridContainer, Title } from '../../creation.styled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useStyles } from './editor.styled';
import { Toast } from '../../../../utils/Toast';
import { ArticlePreview } from '../preview';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import * as actions from '../../ducks/articlesCreation.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withPermissions from '../../../../common_components/hocs/withPermissions';
import { permissionConfig } from '../../../../motp_components/_Requests/RequestList/RequestList.permissions';
import { useTranslation } from 'react-i18next';
import { BUCKET_URL } from '../../../ArticleListPage/constants';

const Editor = ({ lang, title, setText, value, uploadFile, hasSocialNetworks, setHasSocialNetworks }) => {
  const { t } = useTranslation();

  const styles = useStyles();
  const quillRef = useRef(null);
  const limit = 2; // 2MB
  const acceptExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('class', 'ql-image');
    input.setAttribute('accept', acceptExtensions.join(', '));
    input.click();
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      const quillObj = quillRef.current.getEditor();
      const range = quillObj.getSelection(true);

      const onFinish = (img) => {
        if (img) {
          quillObj.insertEmbed(range.index, 'image', BUCKET_URL + img, 'silent');
        } else {
          Toast.showError({ content: t('Не удалось загрузить файл')});
        }
      }


      if (file) {
        if (!acceptExtensions.includes(file.type)) {
          Toast.showError({ content: `${t('Файл имеет недопустимый тип (должен быть')} ${acceptExtensions.join(',')}).`});
          return;
        }
        if (file.size < limit * 1024 * 1024) {
          uploadFile({ file, imageUsage: 'CONTENT', onFinish, lang });
        } else {
          Toast.showError({ content: t('Файл превышает максимально допустимый размер в 2МБ.')});
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },

  }), []);

  return (
    <FormWrap>
      <Title>{t('Редактор статьи')}</Title>
      <GridContainer>
        <div className={styles.editorWrap}>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={value}
            onChange={setText}
            modules={modules}
          />
        </div>
        <div>
          <FormGroup className={styles.switch}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={hasSocialNetworks}
                  onChange={() => setHasSocialNetworks(event.target.checked)}
                />}
              label={t('Ссылки на социальные сети')}
            />
          </FormGroup>
          <ArticlePreview title={title} text={value} showSocial={hasSocialNetworks} isArticleAvailable/>
        </div>
      </GridContainer>
    </FormWrap>
  )
}

const mapState = state => ({});

const mapDispatch = dispatch => ({
  uploadFile: (data) => dispatch(actions.uploadFile(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
)(Editor);
