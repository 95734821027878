import styled from 'styled-components';

export const Badge = styled.div`
  width: 30px;
  height: 22px;
  box-sizing: border-box;
  background: #f8ec31;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #52535a;
  margin-left: 14px;
  position: relative;
  :before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 6px 11px 0;
    border-color: transparent #f8ec31 transparent transparent;
    position: absolute;
    left: -6px;
  }
`;
