import React, { useMemo } from 'react';
import ContentWrapper from '../../../../common_components/styled/ContentWrapper';
import {
  Content,
  DividerOfContent,
  DocumentTitleName,
  PageWrapper,
  StepTitle,
} from '../Document/Document.styled';
import Breadcrumbs from '../../../../common_components/Breadcrumbs/Breadcrumbs';
import { Grid } from '@material-ui/core';
import { InfoBlock, InfoBlockLabel } from './DiscrepancyNotice.styled';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionButton';
import Form from '../../../../common_components/Form/Form';
import FormContextWrapper from '../../../../common_components/Form/FormContextWrapper';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import CommandBar from '../../../../common_components/CommandBar/CommandBar';
import { ReconciliationResults } from '../ActDocument/Reconciliation/components/ReconciliationResults';
import List from '../../../../common_components/List/List';
import { columns } from './DiscrepancyNotice.constants';
import { Link } from '../../../../common_components/ScanHistory/DayItem/DayItem';
import { get } from 'lodash';

const DiscrepancyNoticeView = ({
  breadcrumbs,
  data,
  onClose,
  onCancel,
  onSubmit,
  createAct,
  onReject,
  isView,
  hideButton,
  isCreate,
  userInfo,
  beCorrectiveActOrNotData
}) => {
  const commandBarRightNew = useMemo(() => {
    return [
      <CommonActionButton onClick={onCancel}>Отменить</CommonActionButton>,
      <PrimaryActionButton onClick={onSubmit}>Отправить</PrimaryActionButton>,
    ];
  }, []);

  const commandBarRight = useMemo(() => {
    return [
      <PrimaryActionButton onClick={createAct}>
        Создать исправленный акт
      </PrimaryActionButton>,
    ];
  }, []);

  const showPagination = useMemo(() => data.reconciliation.length > 10, [data]);

  const cisesList = useMemo(
    () =>
      get(data, 'reconciliation', []).map(item => ({
        ...item,
        userINN: userInfo.inn,
      })),
    [data],
  );

  return (
    <React.Fragment>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <PageWrapper>
        <Form data={{}} onSubmit={() => {}}>
          <FormContextWrapper>
            <ContentWrapper>
              <Content>
                <DocumentTitleName>
                  Уведомление о расхождениях
                </DocumentTitleName>
                <br />
                <DividerOfContent />
                <StepTitle>Реквизиты исходного акта</StepTitle>
                <Grid container>
                  <Grid xs={5} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>
                        Регистрационный номер акта:
                      </InfoBlockLabel>
                      <Link
                        href={
                          isView
                            ? `/documents/list/act-in/${data.id}`
                            : `/documents/list/act-out/${data.id}`
                        }
                        target="_blank"
                        title={2}
                      >
                        {data.act_number}
                      </Link>
                    </InfoBlock>
                  </Grid>
                  <Grid xs={6} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>
                        Номер первичного документа:
                      </InfoBlockLabel>
                      {data.origin_number}
                    </InfoBlock>
                  </Grid>
                  <Grid xs={5} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>Дата регистрации акта:</InfoBlockLabel>
                      {data.sending_date}
                    </InfoBlock>
                  </Grid>
                  <Grid xs={6} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>
                        Дата первичного документа:
                      </InfoBlockLabel>
                      {data.origin_date}
                    </InfoBlock>
                  </Grid>
                  <Grid xs={12} alignItems="flex-start">
                    <br />
                    <InfoBlock>
                      <InfoBlockLabel>Тип операции:</InfoBlockLabel>
                      {data.operation_type}
                    </InfoBlock>
                    <br />
                  </Grid>
                  <Grid xs={12} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>Продавец:</InfoBlockLabel>
                      {data.seller}
                    </InfoBlock>
                  </Grid>
                  <Grid xs={12} alignItems="flex-start">
                    <InfoBlock>
                      <InfoBlockLabel>Покупатель:</InfoBlockLabel>
                      {data.buyer}
                    </InfoBlock>
                  </Grid>
                </Grid>
                <br />
                <br />
                <List
                  columns={columns}
                  data={cisesList}
                  sortable={false}
                  defaultPageSize={10}
                  showPagination={showPagination}
                />
                <div
                  style={
                    showPagination
                      ? {
                          marginTop: -52,
                          position: 'relative',
                          width: 'fit-content',
                        }
                      : {}
                  }
                >
                  <ReconciliationResults list={data.reconciliation} />
                </div>
                <DividerOfContent />
                <CommandBar
                  left={[
                    <CommonActionButton onClick={onClose}>Закрыть</CommonActionButton>,
                    ...isCreate || isView || hideButton || beCorrectiveActOrNotData ? [] : [<PrimaryActionButton onClick={onReject}>Отклонить</PrimaryActionButton>,]
                  ]}
                  right={
                    isCreate
                      ? commandBarRightNew
                      : isView || hideButton || beCorrectiveActOrNotData
                        ? []
                        : commandBarRight
                  }
                />
              </Content>
            </ContentWrapper>
          </FormContextWrapper>
        </Form>
      </PageWrapper>
    </React.Fragment>
  );
};

export default DiscrepancyNoticeView;
