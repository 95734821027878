import React from 'react';
import { required } from 'src/common_components/Form/utils/validations';
import Input from 'src/common_components/Form/MaterialFields/Input/Input';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import DatePicker from 'src/common_components/Form/MaterialFields/DatePicker/DatePicker';
import { Translate } from 'src/common_components/Translate/Translate';

export const InformationKGFields = () => {
  return (
    <React.Fragment>
      <Input
        forceValidate
        disabled
        name="accompanying_document_name"
        placeholder={Translate('Наименование документа')}
        validate={required}
      />
      <div />
      <DatePicker
        required
        forceValidate
        name="accompanying_document_date"
        placeholder={Translate('Дата первичного документа')}
        maxDate={new Date()}
        validate={(...vals) => {
          return validateDate(...vals, 'DD.MM.YYYY');
        }}
      />
      <Input
        forceValidate
        required
        name="accompanying_document_number"
        placeholder={Translate('Номер первичного документа')}
        validate={required}
      />
    </React.Fragment>
  );
};
