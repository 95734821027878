import { takeEvery, put, select } from 'redux-saga/effects';
import moment from 'moment';
import * as actions from './ExportList.actions';
import * as selectors from './ExportList.selectors';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import { limit } from '../ExportList.constants';
import * as qs from 'qs';

function* requestSaga(action = {}) {
  const { payload = {} } = action;
  const { page, dir } = payload;
  yield put(actions.updatePage(page));
  const newOffset = ((page || 1) - 1) * limit;
  yield put(actions.updateOffset(newOffset));

  const filters = yield select(selectors.filters);
  const sorting = yield select(selectors.sorting);

  if (filters.dateFrom && filters.dateFrom.includes('T')) {
    filters.dateFrom = moment(filters.dateFrom).format('X');
  }
  if (filters.dateTo && filters.dateTo.includes('T')) {
    filters.dateTo = moment(filters.dateTo).format('X');
  }

  const offset = yield select(selectors.offset);

  let paginationParams = {
    limit,
    offset,
  };

  const requestPayload = {
    url: '/api/v3/facade/edo-api/export-notifications/search',
    // url: '/api/v1/edo/export-notifications',
    method: 'get',
    params: {
      ...filters,
      ...paginationParams,
      ...sorting,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  };

  yield put(listActions.gotRequest(requestPayload, actions));
}


export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
}
