import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from './SingleSelect';
import { instance } from '../../../../services/instance';
import HintedOption from './HintedOption';

const { CancelToken } = axios;

class UserNameSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: undefined,
  };

  static cancelToken;

  state = {
    isLoading: false,
    values: undefined,
    selectedId: undefined,
  };

  _request = (data, isSelectedId) => {
    this.setState({ isLoading: true, selectedId: undefined });

    instance.request({
      url: `/api/v3/auth/company/users/list`,
      method: 'POST',
      data,
      cancelToken: new CancelToken(((c) => {
        UserNameSelect.cancelToken = c;
      })),
    }).then((answer) => {
      const values = answer.data.map((user, i) => ({
        type: user.user.id,
        id: user.user.id,
        title: user.user.fullName,
      }));

      this.setState({ 
        isLoading: false, values,
        selectedId: isSelectedId && values[0] && values[0].id
      });
    });
  }
  
  onChange = (val) => {
    // если у нас val интеджер, то пользователь закрыл и открыл фильтр
    // eslint-disable-next-line radix
    if (parseInt(val)) {
      this._request({ ids: [val] }, true)
      return
    }

    if (!val || val.length < 1) {
      this.setState({ values: undefined, selectedId: undefined });
      return
    }

    this._request({ fullName: val })
  };

  render() {
    const { ...passProps } = this.props;

    return (
      <SingleSelect
        {...passProps}
        filterDisable
        truncate
        onChange={this.onChange}
        renderOption={HintedOption}
        {...this.state}
      />
    );
  }
}

export default UserNameSelect;
