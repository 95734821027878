import withTheme from '@material-ui/core/styles/withTheme';
import styled from 'styled-components';

export const HeaderWrapper = withTheme(styled.div`
  border-bottom: 1px solid #d3d3d3;
}
};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
`);


export const TextHeaderWrapper = styled.div`
  font-weight: bold;
  width: 100%;
`;
export const ScanningWrapper = styled.div`
  display: block;
  padding: 16px 32px;
`;

export const ScanningTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
export const ActionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ReconciliationError = styled.div`
    color: red;
    font-weight: 600;
    margin-left: 15px;
`;

export const ActionButton = styled.button`
  height: 40px;
  font-size: 12px;
  line-height: 1.3333333333333333;
  padding-left: 32px;
  padding-right: 32px;
  color: inherit;
  border: 1px solid #9e9e9e;
  overflow: hidden;
  transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "SegoeUI",sans-serif;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: .02em;
  text-transform: uppercase;
  vertical-align: top;
  background: white;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.5 : 1};
  &:hover {
     background: ${props => props.disabled ? 'transparent' : '#e0e0e0'};
     border-color: ${props => props.disabled ? '#9e9e9e' : '#e0e0e0'};
  }
}
`;

export const TableWrap = styled.div`
  & div[role="rowgroup"] {
    height: calc(100vh - 700px) !important;
  }
`;

export const ResultsWrap = styled.div`
 display: flex;
  width: 520px;
  justify-content: space-between;
  margin: 20px 0;
`;

export const Actions = styled.div`
 display: flex;
 justify-content: space-between;
 margin-top: 40px;
`;

export const LeftActions = styled.div`
 display: flex;
 width: 500px;
 justify-content: space-between;
`;
export const RightActions = styled.div`
 display: flex;
`;
