import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import 'datejs';

import Table from '@crpt/react-table';

import { Pagination } from '@crpt/react-pagination';
import RemoteButtons from '../Details/common/RemoteButtons';
// import ElementsCount from '../common/Table/ElementsCount';
import { InnerContentWrapper, Height20px } from '../common/styled';
import TableScrollWrapper from '../TableScrollWrapper';
import { TableToolTip } from '../common/Table/TableCellTooltip';
import PaginationWrapper from '../common/styled/PaginationWrapper';
import Preloader from '../Preloader';
import { countryCode } from '../../common_components/App/App.selectors';
import { RangePicker } from '@crpt/react-datepicker';
import { defaultDatepickerThemeKZ } from '../../theme/kz-tobaco/datepicker';
import { defaultDatepickerThemeUZ } from '../../theme/uz-tobaco/datepicker';
import { Translate } from '../../common_components/Translate/Translate';

class Documents extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      DocumentList: PropTypes.shape({
        ListMounted: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    selectedParticipant: PropTypes.shape({
      inn: PropTypes.string.isRequired,
    }).isRequired,
    DocumentList: PropTypes.shape({
      pagesCount: PropTypes.string.isRequired,
      selectedPage: PropTypes.func.isRequired,
    }).isRequired,
    DocumentFilterSubmitClicked: PropTypes.func.isRequired,
    portalFilterButtonId: PropTypes.string,
    portalDownloadButtonId: PropTypes.string,
    App: PropTypes.shape({
      config: PropTypes.shape({
        lists: PropTypes.shape({
          ParticipantDetails: PropTypes.shape({
            DocumentList: PropTypes.shape({
              filters: PropTypes.array.isRequired,
              columns: PropTypes.array.isRequired,
              options: PropTypes.array.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    documents: PropTypes.shape({
      total: PropTypes.number.isRequired,
      results: PropTypes.any,
    }),
    theme: PropTypes.any.isRequired,
  };

  static defaultProps = {
    portalFilterButtonId: 'containerIdIsAbsent',
    portalDownloadButtonId: 'containerIdIsAbsent',
    documents: {},
  };

  state = {
    cellEvent: null,
    isToolTipVisible: false,
    children: <div />,
  };

  componentDidMount() {
    const { actions, selectedParticipant } = this.props;
    actions.ParticipantDetails.DocumentList.onListMount({
      senderInn: +selectedParticipant.tin,
      receiverInn: +selectedParticipant.tin,
    });
  }

  onMouseEvent = ({ isToolTipVisible, children, cellEvent }) => {
    this.setState(() => ({ isToolTipVisible, children, cellEvent }));
  };

  getDateField = () => {
    const countryCode = window.env.ELK_COUNTRY;

    return [
      {
        component: RangePicker,
        props: {
          inline: false,
          name: 'date',
          placeholder: 'Дата',
          format: 'DD.MM.YYYY',
          positionX: 'left',
          controls: true,
          acceptText: 'Применить',
          resetText: 'Сбросить',
          theme: {
            DatePicker:
              countryCode === 'KZ'
                ? defaultDatepickerThemeKZ
                : defaultDatepickerThemeUZ,
          },
        },
      },
    ];
  };

  render() {
    const { theme, App, selectedParticipant } = this.props;
    const { cellEvent, children, isToolTipVisible } = this.state;
    const { portalDownloadButtonId, portalFilterButtonId } = this.props;
    const { actions, DocumentList } = this.props;
    const { selectedPage, List, total, preloaderIsOpen } = DocumentList;
    const {
      onFilterApplyClick,
      onPageSelect,
    } = actions.ParticipantDetails.DocumentList;

    const currentList = Array.isArray(List)
      ? List
      : typeof List === 'object'
      ? Object.values(List)
      : [];

    const listWithHref = currentList.map(doc => ({
      ...doc,
      href: `/documents/list/${encodeURIComponent(doc.id)}/`,
    }));

    const DocumentListConfig = get(
      App,
      'config.lists.ParticipantDetails.DocumentList',
      {},
    );
    const { filters, columns, options, limit = 10 } = DocumentListConfig;
    const { showDownloadListButton, popupMenu } = filters[0];
    const filterFields = filters.slice(1);
    filterFields[1] = this.getDateField();
    const filteredColumns = columns.filter(({ id }) => id !== 'total');

    const optionsWithCallback = options.map(item => ({
      onMouseEvent: this.onMouseEvent,
      ...item,
    }));

    return (
      <InnerContentWrapper
        minHeight={preloaderIsOpen}
        // style={{ marginTop: '10px' }}
        style={{ marginTop: '0' }}
      >
        <RemoteButtons
          showDownloadListButton={showDownloadListButton}
          popupMenu={popupMenu}
          filterFields={filterFields}
          onFilterApply={values => {
            onFilterApplyClick({
              ...values,
              participantInn: [+selectedParticipant.tin],
            });
          }}
          downloadButtonId={portalDownloadButtonId}
          filterButtonId={portalFilterButtonId}
        />
        <Preloader />
        {/*<Height20px />*/}
        {/*{preloaderIsOpen ? null : <ElementsCount value={total} />}*/}
        <TableScrollWrapper>
          {preloaderIsOpen ? null : (
            <Table
              theme={theme}
              columns={filteredColumns}
              data={listWithHref}
              noDataText={Translate('Нет данных для отображения')}
              minRows={0}
              highlight
              striped
              options={optionsWithCallback}
            />
          )}
        </TableScrollWrapper>

        {preloaderIsOpen ? null : (
          <PaginationWrapper>
            <Pagination
              selected={selectedPage}
              pagesCount={
                currentList.length < limit ? selectedPage : 999999999999
              }
              onSelect={v => onPageSelect(v)}
              pageCounterInvisible
              isBelowZeroDisabled
              isUpperThenMaxDisabled
              leftPaginationText={Translate('Предыдущая')}
              rightPaginationText={Translate('Следующая')}
            />
          </PaginationWrapper>
        )}
        <TableToolTip
          event={cellEvent}
          content={children}
          isVisible={isToolTipVisible}
        />
      </InnerContentWrapper>
    );
  }
}

const mapState = state => ({
  countryCode: countryCode(state),
});

export default compose(
  withTheme,
  withRouter,
  connect(mapState, null),
)(Documents);
