import { handleAction } from 'redux-actions';

import * as actions from './Eimzo.actions';

const certificates = handleAction(
  actions.loaded,
  (state, { payload }) => payload,
  [],
);

const preparedCertificates = handleAction(
  actions.prepared,
  (state, { payload }) => payload,
  [],
);

export default {
  certificates,
  preparedCertificates,
};
