import {combineReducers} from 'redux';
import {getPaginatedListReducers} from '../../utils';
import {actionTypes} from '../../constants/';

export function consolidated(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.EXCISE_LIST.CONSOLIDATED_LOADED:
      return action.value;
    default:
      return state;
  }
}


export default combineReducers({
  ...getPaginatedListReducers('EXCISE_LIST'),
  consolidated
});

