import React from 'react';
import moment from 'moment';

import { RangePicker } from '@crpt/react-datepicker';
import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';

import TableCell from '../../../../components/common/Table/TableCellTooltip/TableCell';

import { addSpacesBetweenThousands } from '../../../../utils';

import DocumentType from '../../../../components/TableCells/Document/DocumentType';
import DocumentId from '../../../../components/TableCells/Document/DocumentId';
import { defaultDatepickerTheme } from '../../../../theme/ru-tabaco/datepicker';
import { saveAs } from 'file-saver';
import { DocumentService } from '../../../../services/DocumentService';
import { StoreHelper } from '../../../../utils/StoreHelper';
import { default as DocumentListSelector } from '../../../../selectors/DocumentList';
import {
  DocumentStatusSelect,
  DocumentTypeSelect,
} from '../../../../components/Select';
import { Translate } from '../../../../common_components/Translate/Translate';

const documentTypes = [
  {
    id: 0,
    title: 'УПД',
    documentType: 'UNIVERSAL_TRANSFER_DOCUMENT',
  },
  {
    id: 1,
    title: 'УПДи',
    documentType: 'UNIVERSAL_TRANSFER_DOCUMENT_FIX',
  },
  {
    id: 2,
    title: 'Агрегирование',
    documentType: 'AGGREGATION_DOCUMENT',
  },
  {
    id: 3,
    title: 'Чек',
    documentType: 'RECEIPT',
  },
  {
    id: 4,
    title: 'Документ о списании',
    documentType: 'WRITE_OFF',
  },
];

export default {
  actionNamespace: 'PARTICIPANT_DETAILS_DOCUMENT_LIST',
  options: [
    {
      accessor: d => d,
      id: 'type',
      render: props => <DocumentType {...props} />,
    },
    {
      accessor: d => d,
      id: 'id',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <DocumentId {...otherProps} />
          </TableCell>
        );
      },
    },
  ],
  columns: [
    {
      Header: Translate('Дата'),
      id: 'docDate',
      accessor: d => `${moment(new Date(d.docDate)).format('DD.MM.YYYY')}`,
      width: 105,
    },
    {
      Header: Translate('Тип документа'),
      id: 'type',
      width: 230,
    },
    {
      Header: Translate('Номер документа участника'),
      id: 'id',
      accessor: 'id',
    },
    {
      Header: Translate('Сумма, Р'),
      id: 'total',
      accessor: d => addSpacesBetweenThousands((d.total / 100).toFixed(2)),
      width: 150,
    },
  ],
  beforeTable: {
    showGraph: false,
  },
  beforeFilterForm: {
    addButton: true,
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: [
        {
          title: 'Скачать XLS',
          onClick: e => {
            const filterParams = DocumentListSelector.filterParams(
              StoreHelper.getState(),
            );
            const { senderInn, receiverInn } = filterParams;
            DocumentService.getListReport('xls', filterParams).then(answer =>
              saveAs(
                answer.data,
                `document_list_${senderInn}-${receiverInn}.xls`,
              ),
            );
          },
        },
      ],
      retailOrganisation: true,
    },
    [
      {
        component: Input,
        props: {
          name: 'search',
          placeholder: 'Номер документа',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [
      {
        component: RangePicker,
        props: {
          inline: false,
          name: 'date',
          placeholder: 'Дата',
          format: 'DD.MM.YYYY',
          positionX: 'left',
          controls: true,
          acceptText: Translate('Применить'),
          resetText: Translate('Сбросить'),
          theme: { DatePicker: defaultDatepickerTheme },
        },
      },
    ],
    [
      {
        component: DocumentTypeSelect,
        props: {
          inline: false,
          name: 'documentType',
          placeholder: Translate('Тип документа'),
        },
      },
    ],
    [
      {
        component: DocumentStatusSelect,
        props: {
          inline: false,
          name: 'documentStatus',
          placeholder: Translate('Статус'),
        },
      },
    ],
  ],
};
