import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { getDocumentStatusIconByDocStatus } from '../../DocumentDetails.utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import { columns } from './ImportKzBaseInfo.constants';
import moment from 'moment';
import {
  WrapImportTransit,
  Container,
  HeadWrap,
  LabelWrap,
  Head,
  Row,
  RowLabel,
  RowText,
} from '../../DocumentDetails.styled';
import Box from '@material-ui/core/Box';
import { PrintButton } from '../InOutputBasicInfo';
import * as actions from '../../ducks/DocumentDetails.actions';
import { connect } from 'react-redux';
import { Translate } from '../../../../common_components/Translate/Translate';

const ImportKZBasicInfo = ({ printDocument, selectedDocument, id }) => {
  const hasPaperDoc =
    selectedDocument.originalDocNumber || selectedDocument.originalDocDate;

  const onPrint = () => {
    printDocument({ id, selectedDocument });
  };

  return (
    <WrapImportTransit>
      <Container>
        <HeadWrap>
          <Head>
            {Translate('Уведомление о ввозе товаров (третьи страны)')}{' '}
            <Box fontWeight={100} fontSize={16} component="span">
              {id}
            </Box>
          </Head>

          <LabelWrap>
            <Button theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(documentStatusesNames[selectedDocument.status])}
            </Button>
            <PrintButton onClick={onPrint}>{Translate('Печать')}</PrintButton>
          </LabelWrap>
        </HeadWrap>

        <Container>
          {columns.map(({ Header, cid, formater }, i) => {
            return (
              <Row key={i}>
                {[
                  'importerCode',
                  'customDocType',
                  'decisionCode',
                  'certificateDocType',
                ].includes(cid) ? (
                  <React.Fragment>
                    <RowLabel>{Translate(Header)}</RowLabel>
                    <RowLabel>
                      {formater
                        ? formater(selectedDocument[cid]) || '-'
                        : selectedDocument[cid] || '-'}
                    </RowLabel>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <RowText>{Translate(Header)}</RowText>
                    <RowText>
                      {formater
                        ? formater(selectedDocument[cid]) || '-'
                        : selectedDocument[cid] || '-'}
                    </RowText>
                  </React.Fragment>
                )}
              </Row>
            );
          })}
          <Row>
            <RowLabel>
              {Translate(
                'Сведения об уведомлении, выписанном на бумажном носителе',
              )}
            </RowLabel>
            <RowLabel>
              {hasPaperDoc ? Translate('Да') : Translate('Нет')}
            </RowLabel>
          </Row>
          <Row>
            <RowText>{Translate('Номер уведомления о ввозе')}</RowText>
            <RowText>{selectedDocument.originalDocNumber || '-'}</RowText>
          </Row>
          <Row>
            <RowText>{Translate('Дата уведомления о ввозе')}</RowText>
            <RowText>
              {selectedDocument.originalDocDate
                ? moment(selectedDocument.originalDocDate).format('DD.MM.YYYY')
                : '-'}
            </RowText>
          </Row>
        </Container>
      </Container>
    </WrapImportTransit>
  );
};

ImportKZBasicInfo.propTypes = {
  selectedDocument: PropTypes.shape({}).isRequired,
};

const mapState = state => ({});
const mapDispatch = dispatch => ({
  printDocument: data => dispatch(actions.printDocument(data)),
});

export default connect(mapState, mapDispatch)(ImportKZBasicInfo);
