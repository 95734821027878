import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './Pagination.actions';
import StylesPagination from '../StyledPagination';

function isNumber(n) { return (/^[0-9]+$/i).test(n); }

class Pagination extends React.Component {
  static propTypes = {
    pagesCount: PropTypes.number.isRequired,
    selected: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    list: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}).isRequired,
    last: PropTypes.bool,
    pageCounterInvisible: PropTypes.bool,
    withMargin: PropTypes.bool,
  };

  static defaultProps = {
    list: [],
    selected: 1,
    withMargin: false,
  };

  onSelect = (page) => {
    const { onSelect, list, meta, selected, pagesCount, onChange } = this.props;
    if (selected === page) return;
    const dir = page > selected ? 'NEXT' : 'PREV';
    onChange ? onChange(page) : onSelect({ selected, pagesCount, list, page, dir }, meta);
  };


  onClickPage = (e) => {
    const { pageCounterInvisible, selected } = this.props;
    if(!pageCounterInvisible) {
      const page = e.target.innerHTML;
      if (isNumber(page) && page != selected) {
        this.onSelect(page)
      }
    }
  };

  render() {
    const { selected, pagesCount, last, pageCounterInvisible, withMargin } = this.props;

    return (
      <StylesPagination
        withMargin={withMargin}
        last={last}
        pagesCount={pagesCount}
        onSelect={this.onSelect}
        selected={selected}
        pageCounterInvisible={pageCounterInvisible}
        onClick={this.onClickPage}
      />
    );
  }
}


const mapDispatch = dispatch => ({
  onSelect: (payload, meta) => dispatch(actions.select(payload, meta)),
});

export default connect(null, mapDispatch)(Pagination);
