import React from 'react';
import { compose, pure } from 'recompact';
import { Icon } from '@crpt/react-icon';
import { Tooltip } from '@crpt/react-tooltip';
import { IconWrap, TextWrap } from './styled';


const HintedContent = () => (
  <IconWrap>
    <Tooltip
      position="top"
      content={(
        <TextWrap>
          <div>Укажите действующий E-mail для</div>
          <div>отправки информации о работе</div>
          <div>организации в системе МОТП</div>
        </TextWrap>
      )}
    >
      <Icon type="info" size={16} />
    </Tooltip>
  </IconWrap>
);


export default HintedContent;
