const strings = {
  AUTH: {
    STATE: {
      PRELOADING: null,
      AUTHORIZED: null,
      NOT_AUTHORIZED: null
    }
  },
  REGISTRATION: {
    STEP: {
      CERT: null,
      CONTACTS: null,
      DATA: null,
      PARTICIPANT_TYPE_SELECTED: null,
      ADDITIONAL_DATA_FILLED: null,
      THANK_YOU: null
    }
  },
};

function updateKey(obj, key, value) {
  if (obj[key] === null) {
    return value;
  }

  if (typeof obj[key] === 'string') {
    return obj[key];
  }

  const answer = {};

  for (const innerKey in obj[key]) {
    answer[innerKey] = updateKey(obj[key], innerKey, value + '_' + innerKey);
  }

  return answer;
}

for (const key in strings) {
  strings[key] = updateKey(strings, key, key);
}

export { strings };
