import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import * as cadesSelectors from '../../../../Cades/Cades.selectors';
import * as cadesActions from '../../../../Cades/Cades.actions';

import * as rutokenSelectors from '../../../../Rutoken/Rutoken.selectors';
import * as rutokenActions from '../../../../Rutoken/Rutoken.actions';

import { getCertificateValues } from './CertificateSelect.utils';
import Select from '../../../../_MaterialUi/Select/Select';
import MenuItem from '../../../../_MaterialUi/MenuItem/MenuItem';
import CertMenuItem from './MaterialCertSelect/CertMenuItem';
import Options from './MaterialCertSelect/Options';
import { MenuItemStyles } from './MaterialCertSelect/MaterialCertSelect.styled';

const MaterialCertSelect = props => {
  const { values, onMount, onSelect, classes, ...pass } = props;

  useEffect(() => onMount(), []);

  const preparedValues = useMemo(() => getCertificateValues(values), [values]);

  const handlerOnChange = fn => val => {
    onSelect();
    fn(val);
  };

  return (
    <Field {...pass}>
      {({ input }) => {
        return (
          <Select
            label={get(
              input,
              'value.source',
              'Выберите электронную подпись...',
            )}
            onChange={handlerOnChange(input.onChange)}
            options={val => <Options {...val} />}
            menuProps={{
              PaperProps: {
                style: {
                  maxHeight: 380,
                  maxWidth: 518,
                },
              },
            }}
          >
            {Boolean(preparedValues.length) ? (
              preparedValues.map(({ id, disabled, ...props }, index) => {
                const isOdd = Boolean(index % 2);
                return (
                  <MenuItem
                    className={
                      classes.MenuItem +
                      (isOdd ? ' ' + classes.MenuItemOdd : '')
                    }
                    key={id}
                    disabled={disabled}
                    value={{ ...props }}
                    disableGutters
                  >
                    <CertMenuItem {...props} disabled={disabled} />
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem disabled>
                Сертификат УКЭП не найден либо не настроен плагин КриптоПро
              </MenuItem>
            )}
          </Select>
        );
      }}
    </Field>
  );
};

MaterialCertSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  onMount: PropTypes.func.isRequired,
  values: PropTypes.array,
};

const mapState = state => {

  const cadesCerts = cadesSelectors.certificates(state);
  const rutokenCerts = rutokenSelectors.certificates(state);

  const certs = [...cadesCerts, ...rutokenCerts];

  return { values: certs };
};

const mapDispatch = dispatch => ({
  onMount: () => {
    dispatch(rutokenActions.load());
    dispatch(cadesActions.load());
  }
});

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),
  withStyles(MenuItemStyles),
)(MaterialCertSelect);
