import styled from 'styled-components';


const Wrapper = styled.div`
  padding-bottom: 22px;
  padding-left: 21px;
  padding-right: 15px; 
`;

export default Wrapper;
