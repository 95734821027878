import { get } from 'lodash';
import { ProfileService } from '../../../services/ProfileService';
import { Item } from './Item';
import i18next from 'i18next';


export class ProfileItem extends Item {
  icon = 'info';

  title = 'Профиль';

  onClick = async () => {
    try {
      const answer = await ProfileService.getActiveUsers();
      const results = get(answer, 'data');
      if (results && results.length) {
        const foundIds = [];
        for (let i = 0; i < results.length; i += 1) {
          const user = results[i].user;

          if (user.fullName.indexOf(this.value) !== -1 || user.email === this.value) {
            foundIds.push(user.id);
          }
        }

        if (foundIds.length) {
          if (foundIds.length === 1) {
            this.history.push(`/profile/users/list/${foundIds[0]}`);
          } else {
            this.history.push('/profile/users/list');
          }

          this.onSuccess();
          return;
        }
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
