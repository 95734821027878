import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { isOptionsSelected } from './utils';

export const HiddenSelect = ({
  options: optionsFromProps,
  name,
  selectValues,
}) => {
  const selectRef = useRef(null);

  const renderOptions = useCallback(() => {
    return optionsFromProps.map((option, i) => {
      const { id, title } = option;
      return (
        <option
          key={`Key${i}${id}`}
          value={id}
          selected={isOptionsSelected(option, selectValues)}
        >
          {title}
        </option>
      );
    });
  }, [selectValues, optionsFromProps]);

  return (
    <select
      style={{ display: 'none' }}
      multiple
      ref={selectRef}
      name={name}
    >
      {renderOptions()}
    </select>
  );
};

HiddenSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  selectValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
};
