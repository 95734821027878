import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [3],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [4],
          'col2': 'Месяц и год производства товара'
        },
        {
          'col1': [5],
          'col2': 'Актуальный статус товара'
        },
        {
          'col1': [6],
          'col2': 'Диапазон количества месяцев с даты производства товара до его реализации ИЛИ до окончания выбранного периода, если товар находится в обороте'
        },  
        {
          'col1': [7],
          'col2': 'Количество товара, находящегося в данном диапазоне'
        },        
      ]
    }
  ]
}