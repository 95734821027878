import styled from 'styled-components';


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  color: #52535a;
  max-width: 400px;
`;

export const DefaultLink = styled.a`
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #55b5e7;
`;

export const CloseButton = styled.div``;

export const EnterText = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: #52535a;
  margin-top: 58px;
  margin-right: 30px;
  font-weight: 600;
`;

export const Container = styled.div`
  width: 100%;
  color: #212c42;
  margin: 24px 0 40px;
`;

export const Text = styled.div`
  font-size: 16px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '27px')};
`;

export const Number = styled.div`
  background: #f8ec31;
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 29px;
  color: #52535a;
`;

export const Blank = styled.div`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  margin-right: 29px;
`;

export const Content = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: #212c42;
  max-width: 380px;
  font-weight: 400;
`;

export const LightLink = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  &, &:active, &:visited, &:hover {
    color: #55B5E7;
  }
`;

export const Link = styled.a`
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  &, &:active, &:visited, &:hover {
    color: #55B5E7;
  }
`;