import moment from 'moment';
import { actionTypes } from '../constants';
import { AltScenariosService } from '../services/AltScenariosService';
import { FormSerializer } from '../utils/FormSerializer';
import { get } from 'lodash';
import {getUserFromStore} from '../utils/userUtils';

const loading = type => ({
  type: actionTypes.ALT_SCENARIOS[type].LOADING,
});

const loaded = (type, values) => ({
  type: actionTypes.ALT_SCENARIOS[type].LOADED,
  values,
});

const error = type => ({
  type: actionTypes.ALT_SCENARIOS[type].LOAD_ERROR,
});

const updateFilterParams = value => ({
  type: actionTypes.ALT_SCENARIOS.FILTER_PARAMS_UPDATED,
  value,
});

const loadStatistic = data => async dispatch => {
  dispatch(loading('STATISTIC'));
  await AltScenariosService.getStatistics(data)
    .then(answer => {
      const results = get(answer, 'data.results', []);
      dispatch(loaded('STATISTIC', results));
      dispatch(loaded('ALL'));
    })
    .catch(err => {
      console.log('AltScenario loadStatistic error: ', err);
      dispatch(error('STATISTIC'));
      dispatch(loaded('ALL'));
    });
};

const altScenariosMounted = ({ isOGV, initFilterParams }) => async dispatch => {
  dispatch(loading('ALL'));

  dispatch(loading('ROUND_GRAPH'));
  {
    !isOGV ? dispatch(loading('TAXES')) : null
  }

  const momentTo = moment(initFilterParams.dateTo, 'DD.MM.YYYY');
  const momentFrom = moment(initFilterParams.dateFrom, 'DD.MM.YYYY');

  // const roundParams = {
  //   query_type: 'day',
  //   dt_beg: momentFrom.format('YYYY-MM-DD'),
  //   dt_end: momentTo.format('YYYY-MM-DD'),
  // };

  const filterParams = {
    dateFrom: momentFrom.format('DD.MM.YYYY'),
    dateTo: momentTo.format('DD.MM.YYYY'),
  };

  // await AltScenariosService.getRoundGraphStats(roundParams).then(({ data }) => {
  //   dispatch(loaded('ROUND_GRAPH', data));
  // });

  const user = getUserFromStore();

  const taxParams = {
    dateFrom: momentFrom.unix(),
    dateTo: momentTo.unix(),
    participantInn: get(user, 'inn', ''),
  };

  {
    !isOGV
      ? await AltScenariosService.getTaxInfo(taxParams).then(({ data }) => {
          dispatch(loaded('TAXES', data));
        })
      : null;
  }

  dispatch(
    loadStatistic({
      documentDateFrom: moment(
        filterParams.dateFrom,
        'DD.MM.YYYY',
      ).toISOString(),
      documentDateTo: moment(filterParams.dateTo, 'DD.MM.YYYY').toISOString(),
    }),
  );
};

const loadTypes = () => dispatch => {
  dispatch(loading('TYPES'));
  AltScenariosService.getTypes()
    .then(answer => dispatch(loaded('TYPES', answer.data)))
    .catch(err => {
      console.log('AltScenarios loadTypes error: ', err);
      dispatch(error('TYPES'));
    });
};

const filterApply = ({ isOGV, initFilterParams, ...formed }) => dispatch => {
  const params = {};

  params.documentDateFrom = formed.dateFrom
    ? moment(formed.dateFrom).unix()
    : moment(initFilterParams.dateFrom, 'DD.MM.YYYY')
        .unix();
  params.documentDateTo = formed.dateTo
    ? moment(formed.dateTo).unix()
    : moment(initFilterParams.dateTo, 'DD.MM.YYYY')
        .unix();

  if (formed.gtin) {
    params.gtin = formed.gtin;
  }

  if (formed.cisStatus) {
    params.cisStatus = formed.cisStatus;
  }

  if (formed.cisPackageType) {
    params.cisPackageType = formed.cisPackageType;
  }

  if (formed.participantId) {
    params.producer = formed.participantId;
  }

  // const roundParams = {
  //   query_type: 'day',
  //   dt_beg: moment.unix(params.documentDateFrom).format('YYYY-MM-DD'),
  //   dt_end: moment.unix(params.documentDateTo).format('YYYY-MM-DD'),
  // };

  // AltScenariosService.getRoundGraphStats(roundParams).then(({ data }) => {
  //   dispatch(loaded('ROUND_GRAPH', data));
  // });

  const user = getUserFromStore();

  const taxParams = {
    dateFrom: params.documentDateFrom,
    dateTo: params.documentDateTo,
    participantInn: get(user, 'inn', ''),
  };

  {
    !isOGV
      ? AltScenariosService.getTaxInfo(taxParams).then(({ data }) => {
          dispatch(loaded('TAXES', data));
        })
      : null;
  }

  dispatch(
    loadStatistic({
      ...params,
      documentDateFrom: moment
        .unix(params.documentDateFrom)
        .toISOString(),
      documentDateTo: moment
        .unix(params.documentDateTo)
        .toISOString(),
    }),
  );
};

const altScenariosUnMounted = () => ({
  type: actionTypes.ALT_SCENARIOS.UNMOUNTED,
});

export { altScenariosMounted, loadTypes, loadStatistic, filterApply, updateFilterParams, altScenariosUnMounted };
