import React from 'react';
import PropTypes from 'prop-types';

import { TooltipWrapper } from './DownloadTooltip.styled';
import DownloadButton from '../../../../components/common/Table/Buttons/DownloadButton';
import { Translate } from '../../../../common_components/Translate/Translate';

class DownloadTooltip extends React.Component {
  static propTypes = {
    onDownloadClick: PropTypes.func.isRequired,
    cellEvent: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  state = {
    mouseIn: false,
    cellEvent: null,
  };

  static defaultProps = {
    onlyIcon: false,
  };

  popupMenu = [
    {
      title: Translate('Скачать XLS'),
      onClick: e => {
        this.props.onDownloadClick(
          'xls',
          encodeURIComponent(this.state.cellEvent.original.id),
        );
        e.preventDefault();
        e.stopPropagation();
      },
    },
  ];

  static getDerivedStateFromProps(props, state) {
    if (
      (state.cellEvent !== null &&
        props.cellEvent !== null &&
        state.cellEvent !== props.cellEvent) ||
      state.cellEvent === null
    ) {
      return {
        cellEvent: props.cellEvent,
      };
    }

    if (!state.mouseIn)
      return {
        cellEvent: props.cellEvent,
      };
  }

  calcPosition = event => {
    try {
      const rowElem = document.querySelector(
        `[data-row-document-id="${event.original.id}"]`,
      );
      const rect = rowElem.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const top = rect.top + scrollTop;
      const left = rect.left + scrollLeft;

      const container = document.querySelector(
        `[data-selector="request-list-relative-container"]`,
      );
      const cRect = container.getBoundingClientRect();
      const cTop = cRect.top + scrollTop;

      return {
        top: top - cTop + 'px',
        right: '32px',
        height: rowElem.clientHeight + 'px',
        visible: true,
      };
    } catch (e) {
      return {
        visible: false,
      };
    }
  };

  onMouseEnter = e => {
    this.setState({ mouseIn: true });
  };

  onMouseLeave = e => {
    this.setState({ mouseIn: false });
  };

  render() {
    const { onlyIcon } = this.props;
    const { cellEvent } = this.state;

    const params = this.calcPosition(cellEvent);

    return (
      <TooltipWrapper
        {...params}
        visible={this.state.mouseIn || params.visible}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <DownloadButton
          showDownloadListButton
          popupMenu={this.popupMenu}
          onlyIcon={onlyIcon}
        />
      </TooltipWrapper>
    );
  }
}

export default DownloadTooltip;
