import React from 'react';
import { Translate } from '../../../common_components/Translate/Translate';
import { makeInputFilter } from '@crpt-ui/datatable/lib/Filters';


export const columns = [
  {
    accessor: 'description',
    id: 'description',
    Header: Translate('Описание ошибки'),
  },
  {
    accessor: 'code',
    id: 'code',
    Header: Translate('Объект ошибки'),
    Filter: makeInputFilter({
      label: Translate('Объект ошибки'),
    })
  },
]

export const parseData = (docErrors) => {
  return docErrors.map(el=> ({
    code: el[0],
    description: el[1],
  }))
}
