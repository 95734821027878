import {authInstance} from './authInstance';
import { getCookie } from '../utils/cookieHelpers';

export class ReportsService {


  static getCategories(pg) {
    return authInstance.request({
      method: 'get',
      url: pg ? `/jasperserver/registry/v1/categories?pg=${pg}`: '/jasperserver/registry/v1/categories',
    });
  }

  static getReports(pg) {
    return authInstance.request({
      method: 'get',
      url: pg ? `/jasperserver/registry/v1/reports?pg=${pg}` : '/jasperserver/registry/v1/reports',
    });
  }
}
