import { selector } from '../../../utils/selector';

export const results = state => selector(state, 'CisList.results');
export const total = state => selector(state, 'CisList.total');
export const loaded = state => selector(state, 'CisList.loaded');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const sorting = state => selector(state, 'CisList.sorting');
export const selectedPage = state => selector(state, 'CisList.selectedPage');
export const pagination = state => selector(state,'CisList.pagination');
export const filters = state => selector(state,'CisList.filters');
