import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { documentTypeNames } from '../../../constants';
import { Translate } from '../../../common_components/Translate/Translate';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { TwoLineLabel } from '@crpt/react-label';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LinkAndLabel } from './HeadingWrap';


const AicDisaggregationInfo = ({ selectedDocument }) => {
  const { senderInn, documentDate, documentId, senderName, aic, status } = selectedDocument
  return (
    <Fragment>
      <HeadingWrap
        date={documentDate}
        title={documentTypeNames.AIC_DISAGGREGATION}
        id={documentId}
        status={status}
        needDocTitle={false}
        bottomId={true}
      />
      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Общие данные')}>
            <TwoLineLabel heading={Translate('ИНН/ПИНФЛ заявителя')}>{senderInn}</TwoLineLabel>
            <TwoLineLabel heading={Translate('Наименование заявителя')}>{senderName}</TwoLineLabel>
            <LinkAndLabel>
              АИК
              <Link target="_blank" to={`/cis/list/${aic}`}>{aic}</Link>
            </LinkAndLabel>
          </DocumentInfoBlock>
        </Column>
      </Container>
    </Fragment>
  )
}

export default AicDisaggregationInfo;
