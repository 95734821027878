import styled from 'styled-components';

export const DocumentWrap = styled.div`
  padding: ${props => (props.withoutPadding ? '' : '34px')};
  padding-bottom: 0;
  min-height: calc(100vh - 220px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const EmptyCisList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 470px);
  align-items: center;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 34px;
`;
