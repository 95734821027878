import styled from 'styled-components';


export const BlockWrap = styled.div`
  margin-bottom: 0px;
`;

export const BorderWrap = styled.div`
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
`;

export const ElemCountWrap = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #abadb6;
  margin-left: 24px;
`;

export const PaginationWrap = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 32px;
`;

export const TopBorderWrap = styled.div`
  border-top: 1px solid rgba(196, 196, 196, 0.25);
`;

export const CardHeading = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #212c42;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
`;

export const CISInformationWrap = styled.div`
  display: flex;
`;

export const Wrap = styled.div`
  height: 32px;
`;

export const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: ${props => (props.last ? '' : '32px')};
`;

export const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 24px;
`;

export const LabelWrap = styled.div`
  margin-left: 40px;
  width: auto;
  height: 32px;
  background: #fcfcfc;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const DocIdAndLabelWrap = styled.div`
  display: flex;
  max-width: calc(100vw - 401px);
  width: calc(100vw - 601px);
  justify-content: space-between;
`;

export const DocIdAndLabel = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const HeadingWrap = styled.div`
  padding-top: 0px;
  padding-bottom: 22px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
  justify-content: space-between;
  align-items: center;
`;
