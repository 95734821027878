import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import SignatureButton from '../../../../../common_components/Buttons/SignatureButton';
import {
  Blocker,
  ModalWrapper,
  Wrapper,
  FlexWrapper,
  FileName,
  CloseWrapper,
  ButtonWrapper,
} from '../../../../../common_components/FilePreview/FilePreview.styled';
import { WrapBlockInfo } from './Preview.styled';
import { Translate } from '../../../../../common_components/Translate/Translate';

const PreviewView = props => {
  const {
    title,
    onAccept,
    onCancel,
    onBlockerClick,
    name,
    type,
    children,
  } = props;

  const propsButton = {
    style: { background: '#F8EC31', color: '#434343' },
    icon: { background: '#F8EC31' },
    textbutton: 'Отправить',
  };

  const signatureButton = (
    <SignatureButton {...propsButton} onClick={onAccept} />
  );

  return (
    <Wrapper>
      <Blocker
        onClick={onBlockerClick}
        onMouseDown={onBlockerClick}
        onMouseUp={onBlockerClick}
      />
      <ModalWrapper>
        <CloseWrapper>
          <Icon type="cross" onClick={onCancel} width={25} />
        </CloseWrapper>

        <FlexWrapper>
          <div>
            <FileName>{title}</FileName>
            <ButtonWrapper vAlignTop>{signatureButton}</ButtonWrapper>
            <Button onClick={onCancel} style={{ paddingTop: '7px' }}>
              {Translate('Отменить')}
            </Button>

            <WrapBlockInfo>
              <nobr>{Translate('Документ будет подписан сертификатом:')} </nobr>
              <nobr>{name}</nobr>
            </WrapBlockInfo>
          </div>
          <div style={{ flex: 1 }}>{children}</div>
        </FlexWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

PreviewView.propTypes = {
  title: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBlockerClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  type: PropTypes.number.isRequired,
  name: PropTypes.string,
};

PreviewView.defaultProps = {
  name: undefined,
  docNumber: undefined,
};

export default PreviewView;
