import styled from 'styled-components';
import { get } from 'lodash';

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => get(props, 'theme.TableFilter.filterPanel.paddingTop', '100px')};
  padding-bottom: ${props => get(props, 'theme.TableFilter.filterPanel.paddingBottom', '100px')};
  padding-left: ${props => get(props, 'theme.TableFilter.filterPanel.paddingLeft', '100px')};
  padding-right: ${props => get(props, 'theme.TableFilter.filterPanel.paddingRight', '100px')};
  color: #52535a;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => get(props, 'theme.TableFilter.filterPanel.header.marginTop', '100px')};
  margin-bottom: ${props => get(props, 'theme.TableFilter.filterPanel.header.marginBottom', '100px')};;
  white-space: normal;
  align-items: flex-start;
`;

export const HeaderText = styled.div`
  font-size: ${props => get(props, 'theme.TableFilter.filterPanel.header.fontSize', '100px')};
`;


export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: normal;
  align-items: flex-start;
`;

// TODO style for buttons should be placed inside theme
export const AcceptButtonWrap = styled.div`
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;


export const filterButtonTheme = {
  Button: {
    fontSize: '16px',
    fontWeight: 400,
    large: {
      paddingLeft: '16px',
      paddingRight: '21px',
      height: '40px',
    },
    main: {
      border: 'none',
      color: '#434244',
      background: 'rgba(0,0,0,0)',
    },
    disabled: {
      color: '#ABADB6',
      background: 'rgba(0,0,0,0)',
    },
    minWidth: '126px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
};
