import { get, invertBy } from 'lodash';
import { Item } from './Item';
import { documentTypeNames } from '../../../constants/documentTypes';
import { DocumentService } from '../../../services/DocumentService';
import { DocumentList } from '../../../actions';
import {
  isFoivOrOperator,
  getCurrentParticipantId,
} from '../../../utils/userUtils';
import i18next from 'i18next';

const types = invertBy(documentTypeNames, newKey => newKey.toLocaleLowerCase());

export class DocumentsItem extends Item {
  icon = 'menu-document-list';

  title = 'Документы';

  strictTest = () => !!types[this.value.toLocaleLowerCase()];

  onClick = async () => {
    this.onFailure(i18next.t('Идет поиск документов...'));
    const isType = this.strictTest();
    const onlyOwnDocuments = !isFoivOrOperator();
    const participantId = getCurrentParticipantId();


    const params = {
      pagination: { limit: 10 },
      ...(isType && { documentType: types[this.value.toLocaleLowerCase()][0] }),
      ...(!isType && { search: this.value }),
    };

    try {
      const answer = await DocumentService.getListWithoutLk(params);
      const results = get(answer, 'data');
      if (results && results.length) {
        if (results.length === 1) {
          this.history.push(
            `/documents/list/${encodeURIComponent(params.search)}/basic-info`
          );
        } else {

          this.history.push('/documents/list');

          if (params.search) {
            this.dispatch(
              DocumentList.onFilterApplyClick({
                search: [params.search],
                ...(onlyOwnDocuments && { participantId: [participantId] }),
              })
            );
          } else {
            this.dispatch(
              DocumentList.onFilterApplyClick({
                documentType: [params.documentType],
                ...(onlyOwnDocuments && { participantId: [participantId] }),
              })
            );
          }

        }

        this.onSuccess();
        return;
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
