import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1],
          'col2': 'ИНН владельца розничной торговой точки'
        },
        {
          'col1': [2],
          'col2': 'Наименование владельца розничной торговой точки'
        },
        {
          'col1': [3],
          'col2': 'Уникальный идентификатор торговой точки из справочника розничных торговых точек'
        },
        {
          'col1': [4, 5],
          'col2': 'Количество и стоимость товара (в пачках), поступившего на склад владельца торговой точки (юр. лица) за выбранный период'
        },
        {
          'col1': [6, 7],
          'col2': 'Количество и стоимость товара (в пачках), реализованного конечному покупателю за выбранный период'
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': 'Подсчёт итогового значения происходит по всем записям, без ограничения'
        },
      ]
    }
  ]
}