import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import defaultTheme from './theme';
import { getThemeAsPlainObjectByKeys, innerMerge, toStyled } from './utils';

const StyledLabel = styled.label`
  position: relative;
  box-sizing: border-box;
  js-display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  width: ${({ inline, width }) => (inline ? width : '100%')};
  min-width: ${({ inline, minWidth }) => (inline ? minWidth : '100%')};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  cursor: ${props => props.cursor};
  box-shadow: ${({ boxShadow = '' }) => boxShadow};
  ${toStyled('border', 'border')}
  ${toStyled('borderLeft', 'border-left')}
  ${toStyled('borderTop', 'border-top')}
  ${toStyled('borderRight', 'border-right')}
  ${toStyled('borderBottom', 'border-bottom')}
`;

export const Label = ({
  children,
  inline,
  theme: themeFromProps,
  disabled,
  isError,
  isOpen,
  innerRef,
  onMouseOut,
  onMouseMove,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;

  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : 'main',
  );

  const mergedMultiSelectLabel = innerMerge(
    {},
    defaultTheme.Select.InputWrap,
    (themeFromPropsHasSelect && themeFromProps.Select.InputWrap) || {},
  );

  Object.assign(
    theme,
    getThemeAsPlainObjectByKeys(
      mergedMultiSelectLabel,
      disabled ? 'disabled' : isError ? 'error' : 'main',
    ),
  );

  if (isOpen) {
    Object.assign(
      theme,
      getThemeAsPlainObjectByKeys(mergedMultiSelectLabel, 'open'),
    );
  }

  const bindRef = useCallback(el => (innerRef.current = el), [innerRef]);

  return (
    <StyledLabel
      inline={inline}
      innerRef={bindRef}
      onMouseOut={onMouseOut}
      onMouseMove={onMouseMove}
      {...theme}
    >
      {children}
    </StyledLabel>
  );
};

Label.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.elementType,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      InputWrap: PropTypes.object,
    }),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  innerRef: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
};
