import styled from "styled-components";

export const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;

export const ModalTitleWrapper = styled.div`
  padding-top: 0px;
`;

export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;

export const STEPS = {
    PRELOAD,
    LOADING,
    ERROR,
};
