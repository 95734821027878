import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Logo = withTheme(styled.img`
  width: 40px
`);

export const LogoFull = withTheme(styled.img`
  width: 100px
`);

export const LogoFullKG = withTheme(styled.img`
  width: 157px;
`);

export const LogoFullUZ = withTheme(styled.div`
  width: 190px;
`);

export const LogoSmallUZ = withTheme(styled.div`
  width: 50px;
  overflow: hidden;
  & svg {
     width: 190px;
  }
`);

export const LogoUz = withTheme(styled.img`
  width: 40px;
  height: 40px;
    object-fit: cover;
`);
