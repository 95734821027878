import React from 'react';

export default {
  title: 'Табличное представление отчёта при типе агрегации «По товарам в разрезе производителей»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИНН производителя',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
        {
          Header: 'Наименование производителя',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
        {
          Header: 'Количество товара',
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col8': [8],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <strong>...</strong>,
          'col8': <strong>...</strong>,
        },       
      ]
    },
  ]
}