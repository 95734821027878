import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  > label {
    height: 100%;
    > div > input {
      top: 0;
    }
  }
`;

export default HeaderWrapper;
