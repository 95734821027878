import { handleAction, handleActions } from 'redux-actions';

import * as actions from './Cades.actions';

const certificates = handleAction(
  actions.loaded,
  (state, { payload }) => payload,
  []
);

const selectedCertificate = handleAction(
  actions.select,
  (state, { payload }) => payload,
  null
);

const preparedCertificates = handleAction(
  actions.prepared,
  (state, { payload }) => payload,
  [],
);

const certBySerial = handleActions(
  {
    [actions.clearCert]: () => null,
    [actions.errorCert]: () => null,
    [actions.loadCert]: () => null,
    [actions.loadedCert]: (state, { payload }) => payload || null,
  },
  null
);

const loadedCertBySerial = handleActions(
  {
    [actions.clearCert]: () => false,
    [actions.errorCert]: () => true,
    [actions.loadCert]: () => false,
    [actions.loadedCert]: () => true,
  },
  false
);

export default {
  certBySerial,
  certificates,
  loadedCertBySerial,
  preparedCertificates,
  selectedCertificate,
};
