import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { UserBalanceView } from './UserBalance.view';

/**
 * Render user balance by all accounts
 */
export const UserBalance = memo(({ accounts }) => {
  const balanceAccounts = accounts
    .filter(account => account.contractId)
    .map(account => ({
      ...account,
      balance: account.balance ? + account.balance / 100 : 0,
    }));

  return <UserBalanceView accounts={balanceAccounts} />
});

UserBalance.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    organisationId: PropTypes.number.isRequired,
    contractId: PropTypes.number.isRequired,
    productGroupId: PropTypes.number.isRequired,
    balance: PropTypes.number
  })).isRequired,
};

