import React from 'react';
import Datatable from '@crpt-ui/datatable';
import { totalColumns } from 'src/motp_components/_Requests/RequestList/ActDocument/Cis/Cis.constants';
import * as selectors from '../ducks/DocumentDetails.selectors';
import { connect } from 'react-redux';

const TotalAct = ({ data }) => {

  return (
    <React.Fragment>
      <Datatable columns={totalColumns} data={data.group} />
    </React.Fragment>
  );
};

const mapState = state => ({
  data: selectors.getData(state),
});
const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(TotalAct);
