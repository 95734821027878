import 'datejs';
import moment from 'moment';

export class FormSerializer {
  static  serialize(vals) {
    const prepared = {};

    for (const key in vals) {
      vals[key].forEach(v => {
        switch (v._type) {
          case 'MultiSelect':
            if (v[key]) prepared[key] = vals[key].map(vv => vv[key]);
            break;
          case 'SingleSelect':
            if (v[key]) prepared[key] = v[key];
            break;
          case 'DatePicker':
            if (v.date)
              prepared[key] = +moment(v.date, [
                'YYYY.MM.DD',
                'DD.MM.YYYY',
              ]).format('x');
            break;
          case 'RangePicker':
            if (v.from)
              prepared.dateFrom = +moment(v.from, [
                'YYYY.MM.DD',
                'DD.MM.YYYY',
              ]).format('x');
            if (v.to)
              prepared.dateTo = +moment(v.to, [
                'YYYY.MM.DD',
                'DD.MM.YYYY',
              ]).format('x');
            break;
          //case ''

          default:
            prepared[key] = v;
            break;
        }
      });
    }

    return prepared;
  }

  static serializeCisGridFiltersForPrivateOffice(filters) {
    console.log('serializeCisGridFiltersForPrivateOffice', filters);
    const serialized = filters.reduce((done, current) => {
      if (current.filterValue) {
        if (current.filterValue.from) {
          done.dateFrom = +moment(
            current.filterValue.from,
            'YYYY-MM-DDTHH:mm:ss'
          ).format('x');
        }

        if (current.filterValue.to) {
          done.dateTo = +moment(
            current.filterValue.to,
            'YYYY-MM-DDTHH:mm:ss'
          ).format('x');
        }
      }

      if (current.column === 'gtin') {
        done.gtin = current.filterTerm;
      }

      if (current.column === 'owner') {
        done.participantInn = current.filterTerm;
      }

      if (current.column === 'cis') {
        done.cisNumber = current.filterTerm;
      }

      return done;
    }, {});

    console.log('serialized', serialized);

    return serialized;
  }
}
