import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as selectors from './ducks/AikDisbandment.selectors';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as actions from './ducks/AikDisbandment.actions';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';


const AikDisbandment = (props) => {
  const { match, countryCode, onSaveDraft, onMountCades } = props;
  const { inn, user, onLoadDraft, data, onSubmit } = props;
  const { id } = match.params;

  useEffect(()=> {
    onMountCades();
  },[onMountCades])

  useEffect(()=> {
    if (id !== 'create') onLoadDraft(id.replace(/draft-/, ''))
  }, [id, onLoadDraft])

  const handleOnSubmit = (values) => {
    onSubmit({ values, idDraft: id.replace(/draft-/, '')});
  }

  const errorsCount = ({ formErrors }) => Object.keys(JSON.parse(JSON.stringify(formErrors))).length

  return (
    <DocumentWrapper
      finalConfirmModal
      needStepWrapper={false}
      needViewActionsOfGoods={false}
      loaded={true}
      rightDraftButton={false}
      step={0}
      thereAreCodes={false}
      match={match}
      isCreate={id === 'create'}
      actionSelectOptions={[]}
      breadcrumbs={[
        {
          href: '/documents/list',
          title: Translate('Документы'),
          id: 1,
        },
        {
          title: Translate('Расформирование АИК'),
          id: 2,
          ...id !== 'create' ? { href: `/documents/list/${id}` } : {},
        },
      ]}
      countryCode={countryCode}
      isLoading={true}
      onSaveDraft={onSaveDraft}
      data={id === 'create' ? {
        applicantInn: inn,
        applicantName: user.full_name,
      } : {
        ...data
      }}
      docType={'AIC_DISAGGREGATION'}
      codesCanBeChanged={false}
      fieldsInfo={InfoFields}
      errorsCountFunc={errorsCount}
      needAWarningAboutTheGoodsRecount={false}
      needCancelButton={true}
      needDraftButton={true}
      onSubmit={handleOnSubmit}
      title='Расформирование АИК'
      filterPackageTypeForGoods="UNIT"
    />
  )
}

const mapState = state => ({
  data: selectors.data(state),
  // isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
  user: authSelectors.userInfo(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onSubmit: data => dispatch(actions.disbandment(data)),
  onMountCades: () => dispatch(cadesActions.load()),
});

export default connect(mapState, mapDispatch)(AikDisbandment);
