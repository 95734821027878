import React, { useMemo, useState } from 'react';
import moment from 'moment';
import Icon from '@crpt/react-icon';
import { Button as SButton, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Grid } from '@material-ui/core';
import { Accord, Content, IconWrap, Paragraph2 } from '../Act/Showing.styled';
import { Translate } from '../../../common_components/Translate/Translate';
import { ProductGroupEnum, ProductGroupNamesMap } from '../../../common_components/Balance/enums';
import {
  DividerOfContent,
  DocumentTitleName
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { countryCodes } from '../../../constants';
import { exportStatusesNames, getDocumentExportEAESStatus } from '../../../constants/documentStatuses';
import { InfoWrapper, StatusWrapper } from './ExportEAES.constants';
import { get } from 'lodash';


const InfoExportEaes = ({ exportDoc, id }) => {
  const { status, errors, createDate } = exportDoc;
  const productGroup = get(exportDoc, 'body.productGroup', null);
  const documentDate = get(exportDoc, 'body.documentDate', null);
  const receiverName = get(exportDoc, 'body.receiverName', null);
  const transferDate = get(exportDoc, 'body.transferDate', null);
  const documentNumber = get(exportDoc, 'body.documentNumber', null);
  const receiverCode = get(exportDoc, 'body.receiverCode', null);
  const exporterName = get(exportDoc, 'body.exporterName', null);
  const exporterCode = get(exportDoc, 'body.exporterCode', null);
  const receiverCountryCode = get(exportDoc, 'body.receiverCountryCode', null);

  const linkedDoc = useMemo(() => {
    if(exportDoc.acceptionDoc) {
      const num = get(exportDoc, 'acceptionDoc.docId', null);
      const date = get(exportDoc, 'acceptionDoc.docDate', null);
      const formattedDate = moment(date).format('DD.MM.YYYY');
      return `Документ приемки №${num} от ${formattedDate}`
    }
    return '—'
  }, [exportDoc]);

  const [show, setShow] = useState({
    sender: true,
    receiver: true,
    requisites: true,
    errors: false,
  });

  const onShow = name => {
    setShow(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const country = useMemo(() => receiverCountryCode
    ? countryCodes.find(item => item.type === receiverCountryCode).title
    : '-',
    [receiverCountryCode]);

  return (
    <InfoWrapper>
      <Grid container justify="space-between">
        <Grid item>
          <DocumentTitleName>
            {Translate('Уведомление об экспорте в ЕАЭС №')}{' '}
            <span>{id}</span>{' '}
          </DocumentTitleName>
        </Grid>
        <Grid item>
          <SButton theme={BUTTON_THEMES.whiteTheme}>
            <Icon
              type={getDocumentExportEAESStatus(status)}
              spacingRight={9}
            />
            {status && Translate(exportStatusesNames[status])}
          </SButton>
        </Grid>
      </Grid>
      <br/>
      <DividerOfContent/>
      <Paragraph2> {Translate('Товарная группа')} </Paragraph2>
      <Content> {ProductGroupNamesMap[ProductGroupEnum[productGroup ? productGroup.toLocaleUpperCase() : null]] || '—'} </Content>
      <br/>
      <DividerOfContent/>
      <Paragraph2> {Translate('Связанные документы')} </Paragraph2>
      <Content>{linkedDoc}</Content>
      <br/>
      <DividerOfContent/>
      <br />
      <Accord onClick={() => onShow('receiver')}>
        <span>{Translate('Отправитель')}</span>
        <IconWrap>
          <Icon
            type={show.receiver ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.receiver && (
        <Grid container>
          <Grid item xs={4}>
            {Translate('Наименование')} <br/>
            <Content>{exporterName || '—'}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('ИИН/БИН')} <br/>
            <Content>{exporterCode || '—'}</Content>
          </Grid>
        </Grid>
      )}
      <br/>
      <DividerOfContent/>
      <br />
      <Accord onClick={() => onShow('sender')}>
        <span>{Translate('Получатель')}</span>
        <IconWrap>
          <Icon
            type={show.sender ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.sender && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {Translate('Наименование')} <br/>
            <Content>{receiverName || '—'}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('ИИН/БИН')} <br/>
            <Content>{receiverCode || '—'}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('Страна')} <br/>
            <Content>{country}</Content>
          </Grid>
        </Grid>
      )}
      <br/>
      <DividerOfContent/>
      <br />
      <Accord onClick={() => onShow('requisites')}>
        <span>{Translate('Прочие реквизиты документа')}</span>
        <IconWrap>
          <Icon
            type={show.requisites ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.requisites && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {Translate('Дата бумажного документа')} <br/>
            <Content>{(documentDate && moment(documentDate).format('DD MMMM YYYY')) || '—'}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('Номер бумажного документа')} <br/>
            <Content>{documentNumber || '—'}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('Дата фактической отгрузки')} <br/>
            <Content>{(transferDate && moment(transferDate).format('DD MMMM YYYY')) || '—'}</Content>
          </Grid>
        </Grid>
      )}
      <br/>
      <DividerOfContent/>
      <br />
      <Accord onClick={() => onShow('errors')}>
        <span>{Translate('Ошибки')}</span>
        <IconWrap>
          <Icon
            type={show.errors ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.errors && (
        <React.Fragment>
          {
            errors
              ? errors.length > 0
                ? errors.map(el=> {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      {Translate('Код ошибки')} <br/>
                      <Content>{el.errorCode || '—'}</Content>
                    </Grid>
                    <Grid item xs={4}>
                      {Translate('Сообщение')} <br/>
                      <Content>
                        {el.errorMessage
                          .split('\\n')
                          .filter(el=> el !== "")
                          .map(el=> (
                            <React.Fragment>
                              {el}<br />
                            </React.Fragment>)
                          ) || '—'}
                      </Content>
                    </Grid>
                  </Grid>
                );
              } )
                : "Ошибок нет"
              : "Ошибки не загружены"
          }
        </React.Fragment>
      )}
    </InfoWrapper>
  );
};

export default InfoExportEaes;
