import React from 'react';
import { withStyles } from '@material-ui/core';

const Content = ({ children, classes, ...rest }) => (
  <div className={classes.root} {...rest}>{children}</div>
);

export default withStyles({
  root: {
    background: 'white',
    borderRadius: 4,
    boxShadow: '0px 1px 6px rgba(41, 49, 53, 0.1)',
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
})(Content);
