import set from 'lodash/fp/set';
import get from 'lodash/get';
import moment from 'moment';
import { readFile, getCertBySerial, prepareError } from './utils';
import 'moment/locale/ru';
import { authInstance } from './authInstance';

import { CadesUtil } from '../utils/CadesUtil';

const getSignedData = (cert, data, files) => {
  if (!files || !files.length) {
    const convertedData = CadesUtil.Base64.encode(JSON.stringify(data));
    return CadesUtil.signMessage(convertedData, cert, true, true)
      .then(signed => ({
        signature: signed,
        document: convertedData,
        document_format: 'MANUAL',
      }));
  }

  let fileType = null;
  let fileContent = null;

  return readFile(files[0])
    .then((file) => {
      fileType = file.type;
      fileContent = file.data;
      return CadesUtil.signMessage(fileContent, cert, true, true);
    })
    .then(signed => ({
      signature: signed,
      document: fileContent,
      document_format: fileType,
    }));
};

const prepareRequest = (data = {}, files) => {
  let documentInfo = JSON.parse(JSON.stringify(data));
  const cemDate = get(documentInfo, 'cem_contract_date', undefined);
  const contractDate = get(documentInfo, 'contract_date', undefined);

  if (cemDate) {
    documentInfo = set(
      'cem_contract_date',
      moment(cemDate).toISOString(true).replace(/\+.*$/, 'Z'),
      documentInfo
    );
  }
  if (contractDate) {
    documentInfo = set(
      'contract_date',
      moment(contractDate).toISOString(true).replace(/\+.*$/, 'Z'),
      documentInfo
    );
  }

  return CadesUtil.getFinalCertsArray()
    .then(certs => getCertBySerial(certs))
    .then(cert => getSignedData(cert, documentInfo, files));
};

export class OrderService {

  static getList(params, isOperatorRole) {
    const newParams = { ...params, limit: 10 };
    const data = Object.assign({ needTotal: true }, newParams);

    data.sort = 'ORDER_CREATION_DATE';
    data.direction = 'DESC';
    delete data.size;

    const url = (isOperatorRole ?
      '/api/v3/facade/order/operator/all' :
      '/api/v3/facade/order/all');

    return authInstance.request({
      url,
      method: 'get',
      params: data,
    }).then(answer => {
      let results = get(answer, 'data.subOrders', []);
      if (results === null) results = [];
      return {
        data: {
          results,
          total: answer.data.total,
        },
      };
    })
  }

  static createDraft(id, documentInfo, files) {
    return new Promise((resolve, reject) => {
      prepareRequest(documentInfo, files)
        .then(data => authInstance.request({
          url: '/api/v3/facade/order/create',
          method: 'post',
          data,
        }))
        .then(answer => resolve(answer))
        .catch(err => reject(prepareError(err)));
    });
  }

  static updateDraft(id, documentInfo, files) {
    return new Promise((resolve, reject) => {
      prepareRequest(documentInfo, files)
        .then(data => authInstance.request({
          url: `/api/v3/facade/order/${id}/update`,
          method: 'patch',
          data,
        }))
        .then(answer => resolve(answer))
        .catch(err => reject(prepareError(err)));
    });
  }

  static createOrder(id, documentInfo, files) {
    return new Promise((resolve, reject) => {
      prepareRequest(documentInfo, files)
        .then(data => authInstance.request({
          url: '/api/v3/facade/order/create',
          method: 'post',
          data,
        }))
        .then(answer => resolve(answer))
        .catch(err => reject(prepareError(err)));
    });
  }

  static updateOrder(id, documentInfo, files) {
    return new Promise((resolve, reject) => {
      prepareRequest(documentInfo, files)
        .then(data => authInstance.request({
          url: `/api/v3/facade/order/${id}/submit`,
          method: 'put',
          data,
        }))
        .then(answer => resolve(answer))
        .catch(err => reject(prepareError(err)));
    });
  }

  static loadOrder(id, isOperatorRole) {
    const url = (isOperatorRole ?
      `/api/v3/facade/order/operator/${id}/details`:
      `/api/v3/facade/order/${id}/details`);

    return authInstance.request({
      url: url,
      method: 'get',
    });
  }
}
