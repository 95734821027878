import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import CreateSagaMiddleware from 'redux-saga';
import { utils } from '@crpt-edo/core';
import thunk from 'redux-thunk';
import { allMiddlewares, allStores, allSagas, order, config } from './App.config';
import getMiddlewares from './middlewares/middlewares';
import getReducers from './reducers';
import getMainSaga from './middlewares/mainSaga';

import {StoreHelper} from './utils/StoreHelper';
import {isEdited} from './selectors/Profile';
import {openModal} from './actions/Profile';
import {getToken} from './utils/userUtils';


const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

utils.Routing.init({
  getUserConfirmation: function(message, callback) {
    if (window.location.pathname === '/profile/edit') {
      const edited = isEdited(StoreHelper.getState());
      if (edited) {
        StoreHelper.getStore().dispatch(openModal(callback, getToken()));
      }

      else callback(true);

    }
    else callback(true);
  }
});

const sagaMiddleware = CreateSagaMiddleware();

const middlewares = getMiddlewares(order, allMiddlewares);
const reducers = getReducers(order, allStores, config);
const mainSaga = getMainSaga(order, allSagas);

const enhancer = composeEnhancers(
  applyMiddleware(thunk, ...middlewares, sagaMiddleware, routerMiddleware(utils.Routing.history))
);

export const history = utils.Routing.history;
export const store = createStore(reducers, enhancer);
sagaMiddleware.run(mainSaga);
