import { selector } from 'src/utils/selector';
import { limit } from '../OutcometList.constants';

export const results = state => selector(state, 'Requests.OutcomeList.results');
export const total = state => selector(state, 'Requests.OutcomeList.total');
export const last = state => selector(state, 'Requests.OutcomeList.last');
export const pagesCount = state => Math.ceil(total(state) / limit);
export const sorting = state => selector(state, 'Requests.OutcomeList.sorting');
export const selectedPage = state => selector(state, 'Requests.OutcomeList.selectedPage');
export const pagination = state => selector(state, 'Requests.OutcomeList.pagination');
export const filters = state => selector(state, 'Requests.OutcomeList.filters');
export const uploadErrors = state => selector(state, 'Requests.OutcomeList.uploadErrors');
export const offset = state => selector(state, 'Requests.OutcomeList.offset');
