import _ from 'lodash';
import React, { Component, useMemo } from 'react';
import styled from 'styled-components';
import { columns } from 'src/motp_components/_Requests/RequestList/NotificationOfEntry/Goods/Goods.constants';
import Datatable from '@crpt-ui/datatable';

const PageWrapper = styled.div`
  height: calc(100vh - 190px);
  padding: 34px;
`;
const TableWrap = styled.div`
  & div[role='rowgroup'] {
    height: calc(100vh - 370px) !important;
  }
  & *::-webkit-scrollbar {
    display: block !important;
  }
  // Эти стили скрывают скролбар в Firefox
  @-moz-document url-prefix() {
    & div[role='rowgroup'] {
      & > div:first-child {
        & > div {
          scrollbar-width: none !important;
        }
      }
    }
  }
`;

const InOutputGoods = ({ selectedDocument }) => {
  const codes = useMemo(() => {
    const productList = _.get(
      selectedDocument,
      'body.document.productList',
      [],
    );
    return productList.map((item, i) => ({
      idx: i + 1,
      cis: item.value,
      gtin: item.gtin,
      name: item.productName,
      cisPackageType: item.packageType,
    }));
  }, [selectedDocument]);

  return (
    <PageWrapper>
      <TableWrap>
        <Datatable columns={columns} useSort={true} data={codes} />
      </TableWrap>
    </PageWrapper>
  );
};

InOutputGoods.propTypes = {};
export default InOutputGoods;
