import { createMuiTheme } from '@material-ui/core/styles';

export const MaterialUiThemeKZ = createMuiTheme({
  spacing: 8,
  modal:{
    padding:'0px 40px',
  },
  palette: {
    text: {
      primary: '#63666a',
      secondary: '#868686',
      light: '#b8b8b8',
    },
    primary: {
      main: '#0287B0',
    },
    border: {
      light:'#F2F2F2',
    },
    secondary: {
      main: '#55B5E7',
    },
    error: {
      main: '#ff4b4b',
    },
    success: {
      main: '#60e84a',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#63666a'
        },
        'button:focus': {
          outline: 'none !important',
        },
      },
    },
    MuiInput: {
      root: {
        '&$error': {
          '&&:after': {
            'transform': 'scaleX(0)',
            'borderBottomColor': 'transparent',
          }
        }
      },
      formControl: {
        margin: '0 !important',
        height: '100%',
      },
      underline: {
        '&:after': {
          transition: 'transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          borderBottom: '2px solid rgb(248, 236, 49)',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        marginTop: '0 !important'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#434244',
        fontSize: '16px',
        lineHeight: '1',
        zIndex: '1',
        pointerEvents: 'none',
        '&$focused': {
          color: 'rgba(82, 83, 90, 0.7)',
        },
        '&$error': {
          color: '#abadb6',
        },
      },
      formControl: {
        transform: 'translate(20px, 18px) scale(1)',
        fontFamily: 'SegoeUI,sans-serif',
        color: '#52535A',
        fontWeight: 200,
      },
      shrink: {
        // maxWidth: 'calc(100% - 111px)',
        fontSize: '14px',
        color: 'rgba(82, 83, 90, 0.7)',
        transform: 'translate(20px, 8px) scale(1)',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
        borderRadius: '3px',
        '&$focused': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      underline: {
        '&:after': {
          transition: 'transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          borderBottom: '2px solid rgb(248, 236, 49)',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      root: {
        width: '100%',
        background: '#FFFFFF',
        border: '1px solid rgba(196, 196, 196, 0.25)',
        boxSizing: 'border-box',
        borderRadius: '3px',
      },
      select: {
        padding: '29px 20px 9px',
        color: '#52535A',
        '&:focus': {
          borderRadius: 'inherit',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPopover: {
      paper: {
        boxShadow:
          '1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06)',
        borderRadius: '3px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#434244',
        fontFamily: 'SegoeUI, sans-serif',
        fontWeight: '400',
        fontSize: '16px',
        minHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        padding: '0',
        '&$selected': {
          color: 'white',
          backgroundColor: '#0287B0',
          '&:focus': {
            color: 'white',
            backgroundColor: '#0287B0',
          },
        },
      },
      gutters: {
        paddingLeft: '19px',
        paddingRight: '19px',
      },
    },
    MuiListItem: {
      root: {
        '&&:hover': {
          color: 'white',
          backgroundColor: '#0287B0',
        },
        '& div': {
          backgroundColor: '#0287B0 !important',
          '& svg': {
            color: 'white',
          },
        },
      },
      button: {
        '&:hover': {
          color: 'white',
          backgroundColor: '#0287B0',
        },
        '&:focus': {
          color: 'white',
          backgroundColor: '#0287B0',
        },
      },
    },
    MuiPaper: {
      root: {
        background: '#FFFFFF',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
      },
      elevation2: {
        boxShadow:
          '1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06)',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        height: '100%',
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
        marginRight: '0',
        marginBottom: '24px',
        '&:last-child': {
          marginBottom: '0',
        },
      },
      label: {
        color: '#52535A',
        fontSize: '18px',
        margin: '0 0 0 18px',
        fontWeight: '400',
        fontFamily: 'SegoeUI',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'SegoeUI',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '400',
        '&&$disabled': {
          boxShadow:
            '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          borderRadius: '3px',
          backgroundColor: '#FFFFFF',
          color: 'rgba(82, 83, 90, 0.5)',
          fill: 'rgba(82, 83, 90, 0.5)',
        },
      },
      contained: {
        boxShadow:
          '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderRadius: '3px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          color: 'white',
          backgroundColor: '#0287B0',
          '& svg': {
            fill: 'white',
          },
        },
        '&.blue': {
          color: '#55B5E7',
          fill: '#55B5E7',
        },
      },
      containedPrimary: {
        color: 'white',
      },
      text: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        whiteSpace: 'nowrap',
      },
    },
    MuiTooltip: {
      popper: {
        opacity: '1',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderRadius: '3px',
        boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.15)',
        border: '1px solid rgba(0, 0, 0, 0.04)',
        padding: '0',
        maxWidth: 'none',
        color: 'inherit',
      },
    },
    MuiDrawer: {
      paper: {
        width: '560px',
      },
    },
    MuiTabs: {
      root: {
        color: 'rgba(0, 0, 0, 0.7)',
      },
    },
    MuiButtonBase: {
      root: {
        outline: 'none',
        '&:focus': {
          outline: 'none',
        },
      }
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'top',
        fontSize: '24px',
      }
    },
  },
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});
