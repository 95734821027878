import { createAction } from 'redux-actions';

const subject = '[HIGHLOAD_PAGINATION]';

export const mounted = createAction(
  `${subject} mounted`,
  payload => payload,
  (_, meta) => meta,
);

export const loaded = createAction(
  `${subject} loaded`,
  payload => payload,
  (_, meta) => meta,
);

export const select = createAction(
  `${subject} select`,
  payload => payload,
  (_, meta) => meta,
);

export const getParams = createAction(
  `${subject} get_params`,
  payload => payload,
  (_, meta) => meta,
);

export const gotParams = createAction(
  `${subject} got_params`,
  payload => payload,
  (_, meta) => meta,
);
