import React from 'react';
import Button from './Button';
import { FormContext } from '../../Form.constants';


export default props => (
  <FormContext.Consumer>
    {({ reset }) => (
      <Button
        name="__form_clear"
        marginBottom="0"
        {...props}
        onClick={() => {
          if (props.onClick) props.onClick();
          reset();
        }}
      />
    )}
  </FormContext.Consumer>
);
