import React, { useMemo } from 'react';
import { CrossborderWrap } from './EasCrossborder.styles';
import { Accordion } from '../../../common_components/Accordion';
import { Grid } from '@material-ui/core';

const mock = [
  { errorCode: 'Код ошибки: 10', errorDescription: 'УОТ не зарегистрирован в ИС МПТ (ИИН 12345678)'},
  { errorCode: 'Код ошибки: 1', errorDescription: 'КИ не зарегистрированы в ИС МПТ: 123ваы34аыа223423243аваыа234'},
  { errorCode: 'Код ошибки: 262', errorDescription: 'УОТ не зарегистрирован в ИС МПТ (ИИН 12345678)'},
]

export const CrossborderErrors = ({ selectedDocument }) => {
  const errors = useMemo(() => selectedDocument.documentErrors || mock, [selectedDocument]);
  return (
    <CrossborderWrap>
      {
        errors.map((error) => (
          <Accordion title={error.errorCode}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {error.errorDescription}
              </Grid>
            </Grid>
          </Accordion>
        ))
      }
    </CrossborderWrap>
  )
}
