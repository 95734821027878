export const packTypes = {
  // pack: 'Пачка',
  // block: 'Блок',
  // box: 'Короб',
  // pallet: 'Палета',
  aic: 'Агрегированный импортный код',
  unit: 'Потребительская',
  pack: 'Потребительская',
  block: 'Групповая',
  box: 'Транспортная',
  pallet: 'Транспортная',
  'trade-unit': 'Потребительская',
  'inner-pack': 'Групповая',
  level1: 'Групповая',
  level2: 'Транспортная',
  level3: 'Транспортная',
};

export const packLevels = {
  unit: 'Потребительская',
  level1: 'Групповая',
  level2: 'Транспортная',
  level3: 'Транспортная',
  box: 'Транспортная',
  'trade-unit': 'Потребительская',
  'inner-pack': 'Групповая',
};

export const packTrivialTypes = {
  block: 'Блок',
  box: 'Короб',
  pallet: 'Палета',
  pack: 'Пачка',
  'trade-unit': 'Пачка',
  'inner-pack': 'Блок',
};

export const EAESPackageTypes = {
  box: 'Транспортная упаковка',
  'trade-unit': 'Потребительская упаковка',
  'inner-pack': 'Групповая упаковка',
  'aic': 'Агрегированный импортный код'
};

export const packTrivialTypesKZ = {
  block: 'Групповая',
  box: 'Транспортная',
  pallet: 'Транспортная',
  pack: 'Потребительская',
  unit: 'Потребительская',
  'trade-unit': 'Потребительская',
  'inner-pack': 'Групповая',
  level1: 'Групповая',
  level2: 'Транспортная',
  level3: 'Транспортная',
  'aic': 'Агрегированный импортный код',
};

export const PACK_TYPE = {
  block: 1,
  'inner-pack': 1,
  box: 2,
  pallet: 3,
  pack: 0,
  aic: 99,
  'trade-unit': 0,
};

export const PACK_TYPE_TO_CONSTANTS = {
  ...Object.keys(PACK_TYPE).reduce((obj, current) => {
    obj[PACK_TYPE[current]] = current;
    return obj;
  }, {}),
};
