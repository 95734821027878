import React, {memo} from 'react';
import { useStyles } from './Item.styles';

export const GroupInfoItem = memo(({title, value, classes = ''}) => {
  const styles = useStyles();
  return (
    <div className={`${styles.wrapper} ${classes}`}>
      <div className={`${styles.contentPart} ${styles.titlePart}`}>
        {title}
      </div>
      <div className={styles.contentPart}>
        {value}
      </div>
    </div>
  );
});