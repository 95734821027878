import React, { useMemo } from 'react';

import { columns } from './ProductionLines.constants';

import {NoInfo} from 'src/fragments/Data/NoInfo';
import Datatable from '@crpt-ui/datatable';
import {ReactComponent as EmptyProductionLinesLogo} from '../../images/emptyLines.svg';

import {useStyles} from '../constants/tableContentStyles';
import { Typography } from '@crpt-ui/core';
import {Translate} from '../../../../common_components/Translate/Translate';

export const ProductionLinesView = ({productionLines}) => {
  const styles = useStyles();
  const instance = React.useRef();

  const EmptyIcon = useMemo(() => (
    <div className={styles.iconWrapper}>
      <EmptyProductionLinesLogo />
    </div>
  ), []);

  if(productionLines.length === 0) {
    return <NoInfo title={Translate("Отборы по производственным линиям отсутствуют")} renderIcon={EmptyIcon} />
  }

  // transform production line data from backend to view-api from facade
  // to use reusable column constants for production lines
  const transformedProductionLines = useMemo(() => {
    return productionLines.map(line =>  ({
      productionCountryName: line.country,
      productionPlaceName: line.manufactureName,
      productionLine: line.lineNumber
    }))
  }, []);

  return (
    <div className={styles.contentWrapper}>
        <Datatable
          data={transformedProductionLines}
          columns={columns}
          useFilters
          ref={instance}
          manualFilters={false}
          useSort
        />
      <div className={styles.footerWrapper}>
        <div>
          <Typography variant="body2">
            {Translate('Всего записей:')}{' '} {transformedProductionLines.length}
          </Typography>
        </div>
      </div>
    </div>
  )
};
