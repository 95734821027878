import {
  packTypes,
  EAESPackageTypes,
  countryCodeFlags,
  productGroupNames,
  productGroupNamesForUrlParameters,
} from 'src/constants';
import i18next from '../../languages/index';
import { Translate } from '../../common_components/Translate/Translate';

const { isEAESCountry, isKZ } = countryCodeFlags;

export const getPackageTypes = () => {
  const packageTypes = isEAESCountry ? EAESPackageTypes : packTypes;
  return Object.keys(packageTypes)
    .filter(el=> isKZ ? el !== 'box' : true)
    .filter(el=> !(isKZ && el === 'aic'))
    .map((key, i) => {
    return {
      id: i,
      title: Translate(packageTypes[key]),
      package_type: key,
    };
  });
};

export const getProductGroups = () => {
  return Object.entries(productGroupNames).map(([id, title]) => ({
    id,
    title,
    pg: productGroupNamesForUrlParameters[id],
  }));
};
