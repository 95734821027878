import { handleActions } from 'redux-actions';

import * as grayAreaActions from './GrayArea.actions';

// list of documents
const documentsResults = handleActions(
  {
    [grayAreaActions.requestDocumentsList]: () => [],
    [grayAreaActions.loaded]: (state, { payload }) => payload.content || [],
    [grayAreaActions.unMount]: () => [],
  },
  []
);

const page = handleActions(
  {
    [grayAreaActions.loaded]: (_state, { payload }) => payload.number || 0,
    [grayAreaActions.unMount]: () => 0,
  },
  0,
);

const total = handleActions(
  {
    [grayAreaActions.requestDocumentsList]: () => 0,
    [grayAreaActions.loaded]: (state, { payload = {} }) => payload.totalElements || 0,
    [grayAreaActions.unMount]: () => 0,
  },
  0,
);

const last = handleActions(
  {
    [grayAreaActions.loaded]: (state, { payload = {} }) => payload.last || false,
    [grayAreaActions.unMount]: () => false,
  },
  false,
);

const loaded = handleActions(
  {
    [grayAreaActions.requestDocumentsList]: () => false,
    [grayAreaActions.loaded]: () => true,
    [grayAreaActions.unMount]: () => false,
  },
  false,
);

const error = handleActions(
  {
    [grayAreaActions.requestDocumentsList]: () => '',
    [grayAreaActions.setError]: (state, { payload = {} }) => payload,
    [grayAreaActions.unMount]: () => '',
  },
  '',
);

const loadingCreateDocument = handleActions(
  {
    [grayAreaActions.createDocument]: () => true,
    [grayAreaActions.createDocumentDone]: () => false,
  },
  false,
);

export default {
  documentsResults,
  error,
  last,
  loaded,
  loadingCreateDocument,
  page,
  total,
};
