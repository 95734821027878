import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';
import defaultTheme from '../theme';

const OptionsWrapper = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  top: ${({ top }) => (top ? top : '62px')};
  left: -1px;
  position: absolute;
  z-index: 9999;
  min-width: ${({ width }) => (width ? width : 'calc(100% + 2px)')};
  maxHeight: 400px;
`;

export const Wrapper = ({
  children,
  visible,
  theme: themeFromProps,
  disabled,
  isError,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;

  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect ? themeFromProps.Select : {},
  );

  innerMerge(
    merged,
    defaultTheme.Select.SelectOptionsPanel,
    (themeFromPropsHasSelect && themeFromProps.Select.SelectOptionsPanel) || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : isError ? 'error' : 'main',
  );

  return (
    <OptionsWrapper
      visible={visible}
      disabled={disabled}
      isError={isError}
      {...theme}
    >
      {children}
    </OptionsWrapper>
  );
};

Wrapper.propTypes = {
  children: PropTypes.elementType,
  visible: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      SelectOptionsPanel: PropTypes.object,
    }),
  }).isRequired,
};
