import styled from 'styled-components';

export const Content = styled.div`
  border-right: ${(props) =>
    props.last ? '0' : '1px solid rgba(196, 196, 196, 0.25)'};
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.first ? '0' : '20px')};
  font-size: 16px;
  font-weight: 300;
  color: #52535a;
  position: relative;
`;

export const ProductionEmpty = styled.div`
  padding: 50px 0;
  text-align: center;
  border-top: 1px solid #f2f2f2;
`;

export const ProductionEmptyText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
`;

export const ProductionTableWrap = styled.div`
`;
