import React, {useEffect, memo, useState, Fragment, useMemo, useRef}  from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Table from '@crpt/react-table';
import { Pagination } from '@crpt-ui/core';
import { TableWrap } from '../../components/DocumentDetails/Act/Showing.styled';
import { useEventCallback } from '@material-ui/core';


import { ContentWrapper, PaginationWrapper } from '../common/styled';
import * as actions from './ducks/Appeals.actions';
import * as selectors from './ducks/Appeals.selectors';
import {AppealsCount} from './AppealsCount';
import TableScrollWrapper from '../TableScrollWrapper';
import { Translate } from '../../common_components/Translate/Translate';
import Filter from '../common/Table/Buttons/Filter';
import ActionBar from '../common/ActionBar';
import { getColumns, getFilters } from './constants';
import Datatable from '@crpt-ui/datatable';

const size = 10;

const AppealsComponent = memo(({
    onMounted,
    fetchViolation,
    violations,
    goodsGroups,
    territorialUnits,
    violationCategories,
    violationTypes,
    theme
}) => {
    const instance = useRef();
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1);
    const [usedFilter, setFilters] = useState({});
    const [usedSorting, setSorting] = useState([]);

    useEffect(() => {
        onMounted();
    }, []);

    const columns = useMemo(() => getColumns(
        i18n.language,
        violationCategories,
        territorialUnits,
        t
        ),[
            i18n.language,
            violationCategories,
            territorialUnits,
            t,
        ]
    );

    const filters = useMemo(() => getFilters(
        goodsGroups,
        i18n.language,
        t,
        territorialUnits,
        violationCategories,
        violationTypes
        ),[
            goodsGroups,
            i18n.language,
            territorialUnits,
            violationCategories,
            violationTypes
        ]);

    const onPageSelect = (selectedPage) => {
        setPage(selectedPage);
        fetchViolation({
            page: selectedPage,
            size,
            ...serializeFilter(usedFilter),
            ...getSortingRequest(usedSorting),
        });
    };

    const serializeFilter = (filters) => {
     return Object.keys(filters).reduce((acc,curr) => {
        const filter = filters[curr];

        console.log('cure', curr, filter)

        if (filter.length) {
            if (curr !== 'updateDay' && curr!== 'fixationDate') {
                acc = {...acc, [curr]: filter.map((filterItem) => filterItem.id ? filterItem.id : filterItem)}
            } else {
                const filterItem = filters[curr][0];
                if (filterItem.to) {
                    acc = {...acc, [`${curr}TO`]: [(new Date(filterItem.to)).getTime()]}
                }
                if (filterItem.from) {
                    acc = {...acc, [`${curr}From`]:  [(new Date(filterItem.from)).getTime()]}
                }
            }
        } 
        return acc
    }, {})
    };

    const onFilterApplyClick = (selectedFilters) => {
        setFilters(selectedFilters);
        setPage(1);
        fetchViolation({
            page: 1,
            size,
            ...serializeFilter(selectedFilters),
            ...getSortingRequest(usedSorting),
        });
    };
    const getSortingRequest = useEventCallback((sort) => {
        return  sort.reduce((acc, curr) => {
             acc = {sort: `${curr.id},${curr.desc ? 'desc' : 'asc'}`}
            return acc

    }, {})
    });

    const onSortingChange = useEventCallback((sort) => {
        setSorting(sort);
        setPage(1);
        fetchViolation({
            page: 1,
            size,
            ...getSortingRequest(sort),
            ...serializeFilter(usedFilter),
        });
    });

    const rightSideComponents = [
        {
          component: (
            <Filter
              filters={filters}
              onFilterApply={onFilterApplyClick}
            />
          ),
          wrap: true,
        },
    ];

    return (
        <ContentWrapper hasMrginTop>
            <ActionBar
                leftSideComponents={[]}
                rightSideComponents={rightSideComponents}
            />
            <Fragment>
                <AppealsCount value={violations.totalElements} />
                <TableScrollWrapper>
                    <Datatable
                        ref={instance}
                        columns={columns}
                        data={violations.content || []}
                        initialState={{
                            usedSorting,
                        }}
                        state= {{
                            usedSorting,
                        }}
                        useSort
                        manualSortBy
                        manualSorting
                        onSortChange={onSortingChange}
                    />
                </TableScrollWrapper>
            </Fragment>

            <PaginationWrapper>
                <Pagination
                    page={page}
                    pagesCount={violations.totalPages}
                    onPageChange={onPageSelect}
                />
            </PaginationWrapper>
        </ContentWrapper>
    );
});


const mapState = state => ({
    violations: selectors.violations(state),
    goodsGroups: selectors.goodsGroups(state),
    territorialUnits: selectors.territorialUnits(state),
    violationCategories: selectors.violationCategories(state),
    violationTypes: selectors.violationTypes(state),
});

const mapDispatchToProps = {
    onMounted: actions.fetchAuthenticateSun,
    fetchViolation: actions.fetchViolation,
};

export const Appeals = compose(connect(mapState, mapDispatchToProps), withRouter, withTheme)(AppealsComponent);


