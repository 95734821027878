import React, { memo, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { get } from 'lodash';

import {
  getFormattedDateStringFromStamp,
  getShortDateStringFromStamp,
} from '../../utils/';
import { reportStatusValuesMap, documentStatusEnum } from '../../constants';

import { Icon } from '@crpt-ui/core';
import { GroupInfoTitle } from 'src/fragments/Data/GroupInfo/Title';
import { GroupInfoItem } from 'src/fragments/Data/GroupInfo/Item';
import { NotificationList } from '../NotificationsList';

import {DownloadLink} from '../../DownloadLink'

import { CardInfoStyles, FileInfoStyles } from './CardInformation.styles';

import { convertByteToMb } from 'src/utils/convertByteToMb';
import { Translate } from '../../../../common_components/Translate/Translate';
import { Trans } from 'react-i18next';

const CardInformationViewComponent = memo(
  ({ documentInfo, downloadGrayAreaReport, titleCardInfo, classes }) => {
    const renderNotificationsList = useMemo(
      () => (
        <NotificationList
          notificationsList={documentInfo.report.notificationEmailList}
        />
      ),
      [],
    );

    return (
      <div className={classes.overflowWrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.documentInfoWrapper}>
            <div className={classes.groupInfoWrapper}>
                <GroupInfoTitle
                  isHeader
                  title={`${titleCardInfo} ${documentInfo.id}`}
                  classes={classes.groupTitleHeaderWrapper}
                />
                <GroupInfoItem
                  title={Translate("Дата документа")}
                  value={getFormattedDateStringFromStamp(
                    documentInfo.savingDate,
                  )}
                  classes={classes.groupItemWrapper}
                />
                <GroupInfoItem
                  title={Translate("Дата поступления в систему")}
                  value={getFormattedDateStringFromStamp(
                    documentInfo.createDate,
                  )}
                  classes={classes.groupItemWrapper}
                />
            </div>

            <div className={classes.groupInfoWrapper}>
                <GroupInfoTitle title={Translate("Данные производителя")} classes={classes.groupTitleWrapper} />
                <GroupInfoItem
                  title={Translate("Наименование")}
                  value={documentInfo.producer.producerName}
                  classes={classes.groupItemWrapper}
                />
                <GroupInfoItem
                  title={Translate("ИНН")}
                  value={documentInfo.producer.producerInn}
                  classes={classes.groupItemWrapper}
                />
            </div>

            <div className={classes.groupInfoWrapper}>

                <GroupInfoTitle title={Translate("Выгрузка")} classes={classes.groupTitleWrapper} />
                <GroupInfoItem
                  title={Translate("Начало периода")}
                  value={getShortDateStringFromStamp(
                    documentInfo.report.reportPeriod.from,
                    false,
                    true,
                  )}
                  classes={classes.groupItemWrapper}
                />

                <GroupInfoItem
                  title={Translate("Окончание периода")}
                  value={getShortDateStringFromStamp(
                    documentInfo.report.reportPeriod.to,
                    false,
                    true,
                  )}
                  classes={classes.groupItemWrapper}
                />
                <GroupInfoItem
                  title={Translate("Статус выгрузки")}
                  value={reportStatusValuesMap[documentInfo.report.status]}
                  classes={classes.groupItemWrapper}
                />
              <FileInfo documentInfo={documentInfo} downloadGrayAreaReport={downloadGrayAreaReport} />
              {documentInfo.report.notificationEmailList.length > 0 && (
                  <GroupInfoItem
                    title={Translate("Получатель уведомления о готовности выгрузки")}
                    value={renderNotificationsList}
                    classes={classes.groupItemWrapper}
                  />
              )}
            </div>
          </div>
          { documentInfo.status === documentStatusEnum.PREPARING &&
            <div className={classes.notificationWrapper}>
              <span className={classes.infoIconWrapper}>
                <Icon name="Information" />
              </span>
              <span className={classes.infoAttention}>
                <Trans>Перед отправкой уведомления о временно непрослеживаемых кодах идентификации,<br />скачайте и ознакомьтесь со сформированной выгрузкой</Trans>
              </span>
            </div>
          }
        </div>
      </div>
    );
  },
);

export const CardInformationView = withStyles(CardInfoStyles)(
  CardInformationViewComponent,
);


const FileInfoComponent = memo(({ documentInfo, downloadGrayAreaReport, classes }) => {
  if(documentInfo.report.reportLink === null) {
    return null;
  }

  const renderLinkValue = useMemo(() => (
    <DownloadLink
      reportLink={documentInfo.report.reportLink}
      downloadFile={() => downloadGrayAreaReport(documentInfo.report.reportLink)}
    />
  ), []);

  const reportSize = convertByteToMb(get(documentInfo, 'report.reportFileSize', 0))

  return (
    <React.Fragment>
        <GroupInfoItem title="Файл выгрузки" value={renderLinkValue} classes={classes.groupItemWrapper} />
        <GroupInfoItem
          title={Translate("Количество строк в файле")}
          value={documentInfo.report.reportLinesCount}
          classes={classes.groupItemWrapper}
        />
        <GroupInfoItem
          title={Translate("Размер файла, мб")}
          value={reportSize}
          classes={classes.groupItemWrapper}
        />
    </React.Fragment>
  );
});

const FileInfo = withStyles(FileInfoStyles)(FileInfoComponent);
