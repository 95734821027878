import React from 'react';
import { Wrapper, ItemWrapper } from './styled';


const ProducerName = ({ options }) => {
  const { _original } = options.participant1Name;
  const { participant1Name, participant2Name } = _original;

  return (
    <Wrapper>
      <ItemWrapper>{participant1Name}</ItemWrapper>
      {participant1Name !== participant2Name && (<ItemWrapper>{participant2Name}</ItemWrapper>)}
    </Wrapper>
  );
};

export default ProducerName;
