import { ExciseService } from '../services/ExciseService';
import { getPaginatedListActions } from '../utils/actionGenerators';
import { actionTypes } from '../constants';
import { default as ExciseSelectors } from '../selectors/Excise';
import { paramsFormatter } from '../components/Excise/utils';
import {Toast} from '../utils/Toast';

const paginatedActions = {
  ...getPaginatedListActions('EXCISE', ExciseService)
};

function updateExciseStat() {
  return (dispatch, getState) => {
    const filterParams = ExciseSelectors.filterParams(getState());
    const updatedParams = Object.assign({},paramsFormatter(filterParams, getState));
    delete updatedParams.isMonth;
    delete updatedParams.offset;
    ExciseService.getStat(updatedParams)
      .then(({ data }) => {
        dispatch(statLoaded(data));
      })
      .catch(err => console.warn('ExciseService stats error', err));
  };
}

function updateMarkingStat() {
  return (dispatch, getState) => {
    const filterParams = ExciseSelectors.filterParams(getState());
    ExciseService.getMarkingStat(paramsFormatter(filterParams, getState))
      .then(({ data }) => {
        dispatch(markingStatLoaded(data));
      })
      .catch(err => console.warn('ExciseService marking stats error', err));
  };
}

function statLoaded(value) {
  return {
    type: actionTypes.EXCISE.STAT_LOADED,
    value
  };
}

function markingStatLoaded(value) {
  return {
    type: actionTypes.EXCISE.MARKING_STAT_LOADED,
    value
  };
}

function declarationSelected(value) {
  return {
    type: actionTypes.EXCISE.DECLARATION_SELECTED,
    value
  };
}

function onDeclarationUpload() {
  return (dispatch, getState) => {
    const filterParams = ExciseSelectors.filterParams(getState());
    const declaration = ExciseSelectors.declaration(getState());
    const params = paramsFormatter(filterParams, getState);
    params.declaration = declaration;
    ExciseService.uploadDeclaration(params)
      .then(answer => {
        Toast.showSuccess({content: 'Декларация успешно загружена.'});
        dispatch(declarationSelected(null));
      })
      .catch(err => {

      });
  }
}


const actions = {
  ...paginatedActions,
  updateExciseStat,
  updateMarkingStat,
  declarationSelected,
  onDeclarationUpload
};

export default actions;
