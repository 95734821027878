import { call, takeEvery, put } from 'redux-saga/effects';
import * as actions from './articles.actions';
import Api from '../../../common_components/Api/Api';
import { Toast } from '../../../utils/Toast';
import { get } from 'lodash';

function* loadListSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v1/mobile-content/articles`,
    method: 'get',
    params: { lang: payload }
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data } = success;
    yield put(actions.setList(data))
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
    yield put(actions.setList([]))
    yield put(actions.loaded());
  }

}

export default function* watch() {
  yield takeEvery(actions.loadList, loadListSaga);

}
