/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { MuiThemeProvider } from '@material-ui/core';
import {
  SignModalWrap,
  ModalHeading,
  ModalContentWrap,
  ButtonWrap,
  ErrorWrap,
  CertWrap,
} from './Cis.styled';
import Input from 'src/common_components/Form/MaterialFields/Input/Input';
import { InfoFields } from '../../Document/Document.styled';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { submitUZ } from '../ducks/ImportThirdCountry.actions';
import CountrySelect from 'src/common_components/Form/MaterialFields/Select/CountrySelect';
import { isSignButtonDisabled } from './ducks/Cis.selectors';
import { required } from 'src/common_components/Form/utils/validations';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import TableCard from './SignModalContent/TableCard';
import { getCertSerial } from '../../../../../utils/cookieHelpers';
import * as cadesSelectors from 'src/common_components/Eimzo/Eimzo.selectors';
import * as cadesActions from 'src/common_components/Eimzo/Eimzo.actions';
import { MaterialUiThemeUZ } from '../../../../../common_components/_MaterialUi/ThemeUZ';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

const defaultProps = {};

const SignModalContentUz = ({
  onCloseModal,
  isSignButtonDisabled,
  values,
  formMutators,
  submit,
  certificates,
}) => {
  const [error, setError] = useState(false);
  const [cert, setCert] = useState(null);

  useEffect(() => {
    const serial = getCertSerial();
    const find = certificates.find(item => item.serialNumber === serial);

    if (find) {
      setCert(find);
      setError(false);
    } else {
      setError('Сертификат не найден');
    }
  }, []);

  useEffect(() => {
    formMutators.updateField(
      'solve_date_time',
      values.solve_date + ' ' + values.solve_time,
    );
    formMutators.updateField(
      'recipient_inn_name',
      values.recipient_inn
    );
  }, [values]);

  return (
    <MuiThemeProvider theme={MaterialUiThemeUZ}>
      <SignModalWrap>
        <ModalHeading>
          Уведомление о ввозе (импорт)
          <Icon type={'cross'} onClick={onCloseModal} />
        </ModalHeading>
        <ModalContentWrap>
          {error && <ErrorWrap>{error}</ErrorWrap>}
          {cert && (
            <CertWrap>
              <span>Документ будет подписан сертификатом:</span>
              {cert.name}
            </CertWrap>
          )}
          <div>
            <InfoFields>
              <Input
                name="recipient_inn_name"
                placeholder="ИНН заявителя"
                disabled
                validate={required}
              />

              <CountrySelect
                placeholder="Страна экспорта"
                name="country_export"
                disabled
                forceValidate
                required
                validate={required}
              />
              <DatePicker
                disabled
                required
                forceValidate
                name="registration_date"
                placeholder="Дата регистрации"
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
              <Input
                name="registration_number"
                placeholder="Номер ГТД"
                disabled
                forceValidate
                required
                validate={required}
              />
              <Input
                name="registration_date"
                placeholder="Дата принятия ГТД"
                disabled
                forceValidate
                required
                validate={required}
              />
            </InfoFields>
          </div>
        </ModalContentWrap>

        <TableCard values={values} />

        <ButtonWrap>
          <PrimaryActionLargeButton
            disabled={isSignButtonDisabled || error}
            onClick={e => submit(values)}
          >
            Подписать и отправить
          </PrimaryActionLargeButton>
          <CommonActionButton onClick={onCloseModal}>
            Отменить
          </CommonActionButton>
        </ButtonWrap>
      </SignModalWrap>
    </MuiThemeProvider>
  );
};

SignModalContentUz.defaultProps = defaultProps;
SignModalContentUz.propTypes = propTypes;

const mapState = state => {
  return {
    isSignButtonDisabled: isSignButtonDisabled(state),
    certificates: cadesSelectors.certificates(state),
  };
};

const mapDispatch = dispatch => ({
  submit: values => dispatch(submitUZ(values)),
  onMount: () => dispatch(cadesActions.load()),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(SignModalContentUz);
