import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/ru';

import DayItem from '../DayItem/DayItem';

const Day = ({ data, date }) => {
  return (
    <div
      style={{
        marginBottom: 8,
      }}
    >
      <div
        style={{
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 24 / 16,
          marginBottom: 8,
          paddingTop: 8,
          paddingBottom: 8,
          background: '#fafafa',
          paddingLeft: 40,
          paddingRight: 40,
          marginLeft: -40,
          marginRight: -40,
        }}
      >
        {moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')} г.
      </div>

      {data.map((item, index) => (
        <DayItem info={item} key={index} />
      ))}
    </div>
  );
};

export default Day;
