import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ProductList from '../../components/ProductList';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';
import * as selectors from '../../selectors/index';

class ProductListBound extends Component {
  render() {
    return (
      <ConnectParts
        component={ProductList}
        actions={[actions.ProductList]}
        selectors={[selectors.ProductList]}
        {...this.props}
      />
    );
  }
}

export default withRouter(ProductListBound);
