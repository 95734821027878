import React from 'react';
import { Grid } from '@material-ui/core';
import Input from '../../../../../../common_components/Form/MaterialFields/Input/Input';
import { required } from '../../../../../../common_components/Form/utils/validations';
import CountrySelect from '../../../../../../common_components/Form/MaterialFields/Select/CountrySelect';

export const GoodsEditKzFields = ({ index }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Input
          required
          name={`[products][${index}]name`}
          placeholder="Наименование товара"
          disabled
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          disabled
          name={`[products][${index}]gtin`}
          placeholder="GTIN"
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          disabled
          name={`[products][${index}]tnved`}
          placeholder="Код ТНВЭД"
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          forceValidate
          name={`[products][${index}]numberDT`}
          placeholder="Номер товара в ДТ"
          marginBottom="0"
          type="number"
          validate={required}
        />
      </Grid>
      <Grid item xs={12}>
        <CountrySelect
          forceValidate
          placeholder="Страна производства"
          name={`[products][${index}]country`}
          required
          eas={false}
          validate={required}
        />
      </Grid>
    </Grid>
  )
}
