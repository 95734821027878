import { createActions } from 'redux-actions';

export const {
  mounted,
  loaded,
  loading,
  unmounted,
  submit,
  getData,
  setData,
  putNewStatus
} = createActions(
  {
    MOUNTED: () => {},
    LOADED: () => {},
    LOADING: payload => payload,
    UNMOUNTED: () => {},
    SUBMIT: payload => payload,
    GET_DATA: () => {},
    SET_DATA: payload => payload,
    PUT_NEW_STATUS: payload => payload,
  },
  {
    prefix: 'UNLOAD_SERVICE_LIST',
  },
);
