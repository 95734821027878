import React from 'react';
import { withStyles} from '@material-ui/core';
import {Link} from '@crpt-ui/core';

export const renderValue = (value, needEllipsis) => {
  if(needEllipsis) return <Value>{value}</Value>
  return (
    <div>{value}</div>
  );
}

const Valuestyles = {
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
};

export const Value = withStyles(Valuestyles)(({ classes, children, ...rest }) => (
  <div className={classes.root}{...rest}>
    {children}
  </div>
));

export const LinkValue = withStyles(Valuestyles)(({ classes, children, ...rest }) => (
  <Link className={classes.root}{...rest}>
    {children}
  </Link>
));

const WrapStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      color: '#FF4B4B',
    },
  }
};

export const Wrap = withStyles(WrapStyles)(({ classes, children, ...rest }) => (
  <div className={classes.root}{...rest}>
    {children}
  </div>
));
