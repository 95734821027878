import { RuToken, } from '../../packages/RusCryptoJS/src/index';

export async function getRutokenCertificates() {
  const rutoken   = new RuToken();
  await rutoken.init();
  const certs     = await rutoken.listCertificates();
  const infoCerts = await Promise.all(
    certs.map(async cert => {
      const info = await rutoken.certificateInfo(cert.id);
      return { ...cert, ...info, certType: 'RUTOKEN' };
    })
  );
  return Promise.resolve(infoCerts);
}
