import React from 'react';
import PropTypes from 'prop-types';

import { TwoLineLabel } from '@crpt/react-label';

import { InlineWrapper } from './HeadedLabel.styled';


const propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const HeadedLabel = ({ header, value }) => (
  <InlineWrapper>
    <TwoLineLabel heading={header}>
      {value}
    </TwoLineLabel>
  </InlineWrapper>
);

HeadedLabel.propTypes = propTypes;

export default HeadedLabel;
