import styled from 'styled-components';
import { get } from 'lodash';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props => get(props, 'theme.TableFilter.filterPanel.paddingTop', '100px')};
  padding-bottom: ${props => get(props, 'theme.TableFilter.filterPanel.paddingBottom', '100px')};
  padding-left: ${props => get(props, 'theme.TableFilter.filterPanel.paddingLeft', '100px')};
  padding-right: ${props => get(props, 'theme.TableFilter.filterPanel.paddingRight', '100px')};
  color: #52535a;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => get(props, 'theme.TableFilter.filterPanel.header.marginTop', '100px')};
  margin-bottom: ${props => get(props, 'theme.TableFilter.filterPanel.header.marginBottom', '100px')};;
  white-space: normal;
  align-items: flex-start;
`;

export const HeaderText = styled.div`
  font-size: ${props => get(props, 'theme.TableFilter.filterPanel.header.fontSize', '100px')};
`;


export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: normal;
  align-items: flex-start;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;
