import styled from 'styled-components';

export const HeadingBlock = styled.div`
  margin-left: 38px;
  line-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #52535a;
`;
