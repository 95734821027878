import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import 'moment/locale/ru';
import { get, uniqBy } from 'lodash';

import Table from '@crpt/react-table';

import { TableToolTip } from '../../common/Table/TableCellTooltip';
import TableScrollWrapper from '../../TableScrollWrapper';
import { documentTypes, packTypes, cisStatuses} from 'src/constants';

import HeadedLabel from './HeadedLabel/HeadedLabel';
import { Wrapper } from './Documents.styled';
import {Translate} from '../../../common_components/Translate/Translate';
import { autoDisaggregationTypesNotForReceipt, columns, options } from './Documents.constants';

moment.locale('ru');

class Documents extends Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
    data: PropTypes.object,
  };

  static defaultProps = {
    data: null,
  };

  state = {
    cellEvent: null,
    isToolTipVisible: false,
    children: <div />,
  };

  onMouseEvent = ({ isToolTipVisible, children, cellEvent }) => {
    this.setState(() => ({ isToolTipVisible, children, cellEvent }));
  };

  render() {
    const { cellEvent, children, isToolTipVisible } = this.state;
    const { theme, data } = this.props;
    const cisStatusKey = get(data, 'status', 'withoutStatus');
    const cisStatusValue = get(cisStatuses, `${cisStatusKey}`, '-');
    const packageType = get(data, 'product.packageType');

    const docsHistory =
      data &&
      data.history
        .filter(v => v.docId)
        .filter(v => v.type !== documentTypes.PAYMENT && v.type !== 'EMISSION')
        .filter(v => !(v.type === 'APPLICATION' && [packTypes.pack, packTypes.block].includes(packTypes[packageType])));

    const docs =
      Array.isArray(docsHistory) &&
      uniqBy(docsHistory, 'docId').map(v =>
        Object.assign({
          docDate: v.operationDate,
          id: v.docId,
          type: v.documentType || v.type,
          participant1Name: v.participant1Name,
          participant2Name: v.participant2Name,
          href:
            ['RECEIPT', 'RECEIPT_RETURN'].includes(v.documentType) ||
            [
              'RECEIPT',
              'RECEIPT_RETURN',
              'RETURN_TO_TURNOVER',
              autoDisaggregationTypesNotForReceipt.includes(v.documentType) ? '' : 'AUTO_DISAGGREGATION',
              v.documentType === 'MTTPS_90' ? '' : 'WITHDRAW',
            ].includes(v.type)
              ? `/documents/receipt/${v.docId}/`
              : `/documents/list/${encodeURIComponent(v.docId)}/`,
        }),
      );

    const optionsWithCallback = options.map(item => ({
      onMouseEvent: this.onMouseEvent,
      ...item,
    }));

    return (
      <Wrapper>
        <HeadedLabel header={Translate("Код")} value={get(data, 'cis', '-')} />

        <HeadedLabel header={Translate("Статус")} value={Translate(cisStatusValue)} />

        <TableScrollWrapper>
          <Table
            theme={theme}
            noDataText={Translate("Данных нет")}
            columns={columns}
            data={docs || []}
            minRows={0}
            highlight
            striped
            options={optionsWithCallback}
          />
        </TableScrollWrapper>

        <TableToolTip
          event={cellEvent}
          content={children}
          isVisible={isToolTipVisible}
        />
      </Wrapper>
    );
  }
}

export default withTheme(withRouter(Documents));
