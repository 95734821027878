import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import { get } from 'lodash';
import 'moment/locale/ru';

import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { TwoLineLabel } from '@crpt/react-label';
import {
  getDocumentStatusIconByDocStatus,
  nano2milli,
} from '../DocumentDetails.utils';
import HeadedLabel from './HeadedLabel';
import { Separator, FlexWrapper } from './styled';

import HeadingWrap from './HeadingWrap';
import { getParticipantNamesByRoles } from '../DocumentDetails.utils';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { documentStatusesNames } from '../../../constants/documentStatuses';
import { Translate } from '../../../common_components/Translate/Translate';

moment.locale('ru');

const propTypes = {};

// const Timezone = styled.div`
//   color: #abadb6;
// `;

const LeftLayer = styled.div`
  width: 160px;
  min-width: 160px;
`;

// const ImageWrap = styled.div`
//   width: 128px;
//   min-width: 128px;
//   height: 128px;
//   background-color: #ffffff;
//   border: solid 1px #e6e6e6;
//   box-sizing: border-box;
//   margin-right: 32px;
//   border-radius: ${props => (props.round ? "50%" : "")};
// `;

const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 16px;
`;

const InfoLine = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

// const AfterLarge = styled.div`
//   width: 100%;
//   height: 15px;
// `;

class AggregationBasicInfo extends Component {
  render() {
    const { id, selectedDocument, countryCode } = this.props;
    const {
      documentDate,
      receivedAt,
      senderInfo,
      type,
      status,
    } = selectedDocument;

    const isKZ = countryCode === 'KZ';

    const docDate = moment(nano2milli(documentDate));
    const docDateString = (docDate && docDate.format('DD.MM.YYYY')) || '—';

    const receivedDate = moment(nano2milli(receivedAt));
    const receivedDateString =
      (receivedDate && receivedDate.format('DD.MM.YYYY')) || '—';

    const docNumber = get(selectedDocument, 'number', false);
    const submissionMethodType = get(selectedDocument, 'submissionMethodType', null);
    const docNumberString = docNumber || '—';

    const senderInn = get(selectedDocument, 'senderInn', '-');
    const senderName = get(senderInfo, 'name', '-');
    const senderAddress = get(senderInfo, 'legalAddress', '-');
    const kpp = get(senderInfo, 'kppElk', '-');
    const head = get(senderInfo, 'head', '-');

    const senderRoles = get(selectedDocument, 'senderInfo.roles', []);

    const LabelWrap = styled.div`
      width: auto;
      & > button {
        padding-left: 32px !important;
      }
    `;

    return (
      <React.Fragment>
        <HeadingWrap id={id} type={type} />
        <LabelWrap>
          <Button theme={BUTTON_THEMES.whiteTheme}>
            <Icon
              type={
                status
                  ? getDocumentStatusIconByDocStatus(status)
                  : 'withoutAnyIcon'
              }
              spacingRight={16}
            />
            {status && Translate(documentStatusesNames[status])}
          </Button>
        </LabelWrap>
        <FlexWrapper>
          <HeadedLabel
            header={Translate('Дата документа')}
            value={docDateString}
          />
          <HeadedLabel
            header={Translate('Поступил в систему')}
            value={receivedDateString}
          />
        </FlexWrapper>

        <Separator />

        <Card>
          <Container fillWidth gutter={32}>
            <Column>
              <DocInfoWrap>
                <LeftLayer />
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Участник')}
                  </DocumentInfoHeadingLabel>
                  <InfoLine>
                    <TwoLineLabel
                      heading={getParticipantNamesByRoles(senderRoles)}
                    >
                      {senderName}
                    </TwoLineLabel>
                  </InfoLine>
                  <InfoLine>
                    <TwoLineLabel heading={Translate('Адрес')}>
                      {senderAddress}
                    </TwoLineLabel>
                  </InfoLine>
                  <InfoLine>
                    <TwoLineLabel heading={Translate('Способ подачи документа')}>
                      {Translate(submissionMethodType === 'RETAIL' ? 'Оптово-розничная сеть' : 'ИЗКМ')}
                    </TwoLineLabel>
                  </InfoLine>
                </div>
              </DocInfoWrap>
            </Column>

            <Column>
              <DocInfoWrap>
                <LeftLayer />
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Налоговые данные')}
                  </DocumentInfoHeadingLabel>
                  <InfoLine>
                    <TwoLineLabel
                      heading={isKZ ? Translate('ИИН/БИН') : Translate('ИНН/ПИНФЛ')}
                    >
                      {senderInn}
                    </TwoLineLabel>
                  </InfoLine>
                </div>
              </DocInfoWrap>
              <DocInfoWrap>
                <LeftLayer />
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Подписант')}
                  </DocumentInfoHeadingLabel>
                  <InfoLine>
                    <TwoLineLabel heading={Translate('ФИО')}>
                      {head}
                    </TwoLineLabel>
                  </InfoLine>
                </div>
              </DocInfoWrap>
            </Column>
          </Container>
        </Card>
      </React.Fragment>
    );
  }
}

AggregationBasicInfo.propTypes = propTypes;
export default withRouter(AggregationBasicInfo);
