import styled from "styled-components";

export const steps = {
    info: 0,
    goods: 1,
};

export const WrapperForTable = styled.div`
  .rt-td {
    min-height: 40px;
  }
`;
