import React from 'react';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from 'src/components/Grid/Grid.styled';
import moment from 'moment';
import { CIS_STATUS_NAMES, CIS_STATUSES_TO_CONSTANTS } from 'src/constants';
import { Icon } from '@crpt/react-icon';
import {
  getIconNameByCISStatus,
  getIconNameByPackType,
} from 'src/components/TableCells/CIS/utils';
import {
  PACK_TYPE_TO_CONSTANTS,
  packTrivialTypes,
} from 'src/constants/packTypes';
import { packTypes } from '../../../../../constants/packTypes';
import { Translate } from '../../../../../common_components/Translate/Translate';

export const columnsKZ = [
  {
    id: 'status',
    accessor: 'status',
    Cell: ({ row }) => {
      const CODE = row.status;
      return (
        <OptionWrap>
          {row.status !== undefined && (
            <Icon
              type={getIconNameByCISStatus()}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />
          )}
          <NoWrap>{row.status !== undefined && CIS_STATUS_NAMES[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Статус кода'),
    sortable: false,
    width: 150,
  },
  {
    id: 'emissionDate',
    accessor: 'emissionDate',
    Cell: ({ row }) => {
      return (
        <NoWrap>
          {row.emissionDate && moment(row.emissionDate).format('DD.MM.YYYY')}
        </NoWrap>
      );
    },
    Header: Translate('Дата'),
    sortable: false,
    width: 130,
  },
  {
    id: 'codes',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код'),
    sortable: false,
    width: 250,
  },
  {
    id: 'cisPackageType',
    accessor: 'cisPackageType',
    Cell: ({ row }) => {
      const CODE = row.cisPackageType ? row.cisPackageType.toLowerCase() : '';
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{packTypes[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    sortable: false,
    width: 200,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return (
        <div>
          <NoWrap>{row.gtin}</NoWrap>
        </div>
      );
    },
    Header: 'GTIN',
    sortable: false,
    width: 200,
  },
  {
    id: 'productName',
    accessor: 'productName',
    Cell: ({ row }) => {
      return (
        <div>
          <NoWrap>{row.productName}</NoWrap>
        </div>
      );
    },
    Header: Translate('Наименование товара'),
    sortable: false,
    width: 200,
  },
  {
    id: 'ownerName',
    accessor: 'ownerName',
    Cell: ({ row }) => {
      return (
        <div>
          <NoWrap>{row.ownerName}</NoWrap>
          <NoWrap>{row.ownerInn}</NoWrap>
        </div>
      );
    },
    Header: Translate('Владелец'),
    sortable: false,
    width: 300,
  },
  {
    id: 'producerName',
    accessor: 'producerName',
    Cell: ({ row }) => {
      return (
        <div>
          <NoWrap>{row.producerName}</NoWrap>
          <NoWrap>{row.producerInn}</NoWrap>
        </div>
      );
    },
    Header: Translate('Производитель'),
    sortable: false,
    width: 300,
  },
];
