import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
// import Tooltip from 'src/common_components/_MaterialUi/Tooltip/Tooltip';
import Tooltip from '@material-ui/core/Tooltip';
import {
  modalStyle,
  ButtonsBarWrapper,
  ContentWrapper,
  ItemWrapper,
  HeaderWrapper,
  ModalClose,
  TextHeaderWrapper,
  BodyTooltip,
  Hr,
} from './modalStyle';
import Icon from '@crpt/react-icon';
import Button from '../../../../common_components/Buttons/Button';
import { Translate } from '../../../../common_components/Translate/Translate';

const propTypes = {
  isModal: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {
  title: '',
};
const TakeSignReject = ({ modalWindow, onClose }) => {
  return (
    <Modal
      isOpen={modalWindow.isOpen}
      style={modalStyle}
      contentLabel={Translate('Подписание')}
      onRequestClose={onClose}
    >
      <div>
        <HeaderWrapper>
          <TextHeaderWrapper>{modalWindow.title}</TextHeaderWrapper>
          <ModalClose onClick={onClose}>
            <Icon type="cross" size={19} fill="#52535a" />
          </ModalClose>
        </HeaderWrapper>
        <ContentWrapper>
          {modalWindow.data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Hr />
                <Tooltip
                  title={
                    <BodyTooltip>
                      УПД №УПД №4444 от 20.05.2020 Lorem ipsum dolor sit amet,
                      consectetur adipisicing elit. Nesciunt, sint!
                    </BodyTooltip>
                  }
                  placement="top"
                >
                  <ItemWrapper>
                    <Icon
                      type="success"
                      size={19}
                      fill="#9ACD32"
                      spacingRight={10}
                    />
                    <span style={{ color: '#55b5e7' }}>
                      УПД №УПД №4444 от 20.05.2020 Lorem ipsum dolor sit amet,
                      consectetur adipisicing elit. Nesciunt, sint!
                    </span>
                  </ItemWrapper>
                </Tooltip>

                <Hr />
              </React.Fragment>
            );
          })}
        </ContentWrapper>
        <ButtonsBarWrapper>
          <Button onClick={onClose}>{Translate('Продолжить работу')}</Button>
        </ButtonsBarWrapper>
      </div>
    </Modal>
  );
};
TakeSignReject.propTypes = {
  modalWindow: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
TakeSignReject.defaultProps = defaultProps;
export default TakeSignReject;
