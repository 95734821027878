import React from 'react';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from 'src/components/Grid/Grid.styled';
import { GoodsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';
import { Value, Wrap } from 'src/fragments/Table/Cell/value';
import { packTrivialTypesKZ, packTypes } from 'src/constants/packTypes';
import {
  getIconNameByCISStatus,
  getIconNameByPackType,
} from 'src/components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { makeInputFilter } from '@crpt-ui/datatable';
import { countryCodes } from 'src/constants/countryCodes';
import styled from 'styled-components';
import moment from 'moment';
import { cisStatuses } from '../../../../../constants';
import { CisCodeWrap } from '../Cis/Cis.styled';
import { get } from 'lodash';
import { cisKey } from '../ImportThirdCountry.constants';
import { Translate } from 'src/common_components/Translate/Translate';

export const actionSelectOptions = [
  {
    value: 1,
    label: Translate('Редактировать'),
  },
];

export const actionSelectOptionsForDelete = [
  {
    value: 1,
    label: Translate('Удалить'),
  },
];
export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  ERROR,
};
export const CodesRenderer = ({ row, onClick }) => {
  return (
    <Wrap>
      <Value>{row.codes ? row.codes[0] : null}</Value>&nbsp;
      <Link href={''} onClick={onClick}>
        +{row.codes ? row.codes.length - 1 : null}
      </Link>
    </Wrap>
  );
};

export const columnsKZ = [
  {
    Header: Translate('Дата нанесения'),
    id: 'producedDate',
    accessor: d => `${moment(d.emissionDate).format('DD.MM.YYYY')}`,
    width: 129,
  },
  {
    Header: Translate('Статус кода'),
    id: 'status',
    accessor: d => (
      <div>
        <Icon type={getIconNameByCISStatus(d.status)} spacingRight={6} />
        {cisStatuses[d.status]}
      </div>
    ),
    width: 176,
  },
  {
    Header: Translate('Код'),
    id: 'cis',
    accessor: d => (
      <CisCodeWrap
        target={'_blank'}
        href={`/cis/list/${encodeURIComponent(d.cis)}`}
      >
        {d.cis}
      </CisCodeWrap>
    ),
    width: 328,
  },
  {
    Header: Translate('Тип упаковки'),
    id: 'cisPackageType',
    accessor: d => {
      const cisPackageType = get(
        d,
        'cisPackageType',
        get(d, 'packageType', ''),
      ).toLowerCase();
      const iconType = getIconNameByPackType(cisPackageType);
      return (
        <div>
          <IconWrapper type={iconType}>
            <Icon type={iconType} spacingRight={6} />
          </IconWrapper>
          {packTypes[cisPackageType]}
        </div>
      );
    },
    width: 198,
  },
  {
    Header: Translate('Товар'),
    id: 'gtin',
    accessor: d => (
      <div>
        <div>{d.gtin}</div>
        <div>{d.productName}</div>
      </div>
    ),
    width: 211,
  },
  {
    Header: Translate('Производитель'),
    id: 'producerInn',
    accessor: d => (
      <div>
        <div>{d.producerInn}</div>
        <div>{d.producerName}</div>
      </div>
    ),
    width: 300,
  },
  {
    Header: Translate('Текущий владелец'),
    id: 'ownerInn',
    accessor: d => (
      <div>
        <div>{d.ownerInn}</div>
        <div>{d.ownerName}</div>
      </div>
    ),
    width: 300,
  },
].map(v => ({ ...v, arrayName: cisKey }));

export const columnsKG = [
  {
    ...GoodsColumns.name,
    disableFilters: true,
  },
  {
    ...GoodsColumns.gtin,
    disableFilters: true,
  },
  {
    id: 'codes',
    accessor: row => (
      <div>
        <Value>{row.codes[0]}</Value>click here...
      </div>
    ),
    Header: Translate('Код маркировки'),
    disableFilters: true,
  },
  {
    ...GoodsColumns.certNumber,
    disableFilters: true,
  },
  {
    ...GoodsColumns.certDate,
    disableFilters: true,
  },
  {
    ...GoodsColumns.tnved,
    disableFilters: true,
  },
  {
    ...GoodsColumns.country,
    disableFilters: true,
  },
];

export const ContentErrorLoading = styled.div`
  margin: auto;
`;
