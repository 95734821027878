import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@crpt-ui/header';
import { useTranslation } from 'react-i18next';

const MenuAppsView = ({ icon, items, productGroupInfo }) => {
  const { i18n } = useTranslation();

  const isNKActive = useMemo(() => {
    const productGroupsAndRoles = productGroupInfo || [];

    if (productGroupsAndRoles.length === 1) {
      if (productGroupsAndRoles[0].code === 'pharma') {
        const types = productGroupsAndRoles[0].types.filter(
          item => item !== 'TRADE_PARTICIPANT',
        );
        if (types.length === 1 && types[0] === 'MEDICAL') {
          return false;
        }
        return true;
      }
      return true;
    }
    return true;
  }, [productGroupInfo]);

  const menuItems = useMemo(
    () =>
      items.filter(item => {
        if (item.key === 'nationalCatalog' && !isNKActive) return false;
        return true;
      }),
    [i18n.language, productGroupInfo],
  );

  return <MenuButton icon={icon} items={menuItems} />;
};

MenuAppsView.propTypes = {
  items: PropTypes.array.isRequired,
  icon: PropTypes.element,
  productGroupInfo: PropTypes.array.isRequired,
};

export default React.memo(MenuAppsView);
