import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { get } from 'lodash';
import Input from '@crpt/react-input';
import * as Icons from '@crpt-ui/icons';
import {
  Wrapper,
  Title,
  IconClosePositionWrapper,
  IconSearchPositionWrapper,
} from './styled';

import { inputTheme } from './constants';
import DropdownSearch from './DropdownSearch';
import { generateItems } from './Items';
import { getOrgRoles } from '../../utils/userUtils';
import { Translate } from '../../common_components/Translate/Translate';

class GlobalSearch extends Component {
  static displayName = 'GlobalSearch';

  static propTypes = {
    theme: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      error: '',
      inputKey: new Date().getTime(),
    };

    const orgRoles = getOrgRoles();

    this.items = generateItems(
      orgRoles,
      props.dispatch,
      props.history,
      this.onSuccess,
      this.onFailure,
    );
  }

  onValueChange = value => {
    this.matched = null;
    this.setState({ value: value.trim(), error: '' });
  };

  onSuccess = () =>
    this.setState({
      value: '',
      error: '',
      inputKey: new Date().getTime(),
    });

  onFailure = error => this.setState({ error });

  onEnterKey = () => {
    this.items.forEach(i => i.strictTest() && i.onClick());
  };

  onPaste = () => {
    this.setState({
      error: '',
    });
  };

  getInputElement = () => {
    const { value, inputKey } = this.state;

    return (
      <Input
        key={inputKey}
        placeholder={!value ? Translate('Поиск') : ''}
        onPaste={this.onPaste}
        onChange={this.onValueChange}
        onEnterKey={this.onEnterKey}
        theme={inputTheme}
        style={{ height: '100%' }}
      />
    );
  };

  getControlIcon = () => {
    const { value } = this.state;
    return (
      <IconClosePositionWrapper>
        {value ? <Icons.Close onClick={this.onSuccess} /> : null}
      </IconClosePositionWrapper>
    );
  };

  getItems = () => {
    const { value, error } = this.state;

    if (error) return [<Title key={0}>{error}</Title>];

    return [
      <Title key={0}>{Translate('Искать в разделе')}</Title>,
      ...this.items.map(i => i.get(value)).filter(i => i).map(el=> ({ ...el, title: Translate(el.title) })),
    ];
  };

  render() {
    const { theme } = this.props;
    const { value } = this.state;
    const isDropdownVisible = !!value;
    const headerHeight = get(theme, 'Header.height');
    const items = this.getItems();

    return (
      <Wrapper>
        <IconSearchPositionWrapper>
          <Icons.Search />
        </IconSearchPositionWrapper>
        <DropdownSearch
          controlled
          visible={isDropdownVisible}
          header={this.getInputElement()}
          headerHeight={headerHeight}
          Control={this.getControlIcon()}
          items={items}
        />
      </Wrapper>
    );
  }
}

export default connect(null, dispatch => ({ dispatch }))(
  withRouter(withTheme(GlobalSearch)),
);
