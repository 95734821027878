import {actionTypes} from '../../constants/';

export function userInfo(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.PROFILE_UPDATED:
      return Object.assign({}, state, {participant: action.value.entity});
    case actionTypes.AUTH.LOGIN.GOT_INFO:
      return action.value;

    default:
      return state;
  }
}
