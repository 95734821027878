import axios from 'axios';
import { call, put, race, delay } from 'redux-saga/effects';
import { authInstance } from '../../services/authInstance';
import { instanceMulti, instance } from '../../services/instance';
import * as preloaderActions from '../Preloader/ducks/Preloader.actions';
import { notifyMessageHelper } from './Api.utils';

const CancelToken = axios.CancelToken;

export function* request(payload, meta = {}) {
  const axiosInstance =
    payload.type === 'multipart'
      ? instanceMulti
      : payload.type === 'raw'
        ? axios
        : payload.type === 'suz' ? instance : authInstance;

  const {
    preloading = payload.preloading !== undefined ? payload.preloading : true,
    errorNotify = payload.errorNotify !== undefined
      ? payload.errorNotify
      : true,
    timeout: time = 360000,
    artificialDelay = 0,
  } = meta;

  if (preloading) yield put(preloaderActions.start());

  let success;
  let timeout;
  let error;

  try {
    [success, timeout] = yield race([
      call(axiosInstance, payload),
      delay(time),
    ]);
  } catch (e) {
    error = e;
    if (errorNotify) notifyMessageHelper(e);
  }

  if (Boolean(artificialDelay)) yield delay(artificialDelay);

  if (preloading) yield put(preloaderActions.end());

  return [success, error, timeout];
}

export function* fetchFile(url, { params }) {
  let prevLoaded = 0;
  let prevTimeStamp = 0;
  let deltaTime = 0;
  let phase = 0;
  let cancelRequest;
  const maxDeltaTime = 60000; // in millisecond

  const request = {
    url,
    method: 'GET',
    type: 'zip',
    responseType: 'arraybuffer',
    params,
    onDownloadProgress: progressEvent => {
      // progressEvent.loaded - количество загруженных байт
      // progressEvent.timeStamp - возвращает время (в миллисекундах), в котором было создано событие
      if (phase === 0) {
        prevTimeStamp = progressEvent.timeStamp;
        phase++;
      }

      if (progressEvent.loaded === prevLoaded) {
        deltaTime = progressEvent.timeStamp - prevTimeStamp;
        if (deltaTime > maxDeltaTime) {
          cancelRequest('timeout cancel request');
        }
      } else {
        prevLoaded = progressEvent.loaded;
        prevTimeStamp = progressEvent.timeStamp;
        deltaTime = 0;
      }
    },
    cancelToken: new CancelToken(function(c) {
      cancelRequest = c;
    }),
    timeout: 0,
  };

  let success;
  let error;

  try {
    success = yield call(authInstance, request);
  } catch (e) {
    error = e;
  }

  return [success, error];
}
