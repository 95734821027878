import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Route from './Route';
import * as authSelectors from '../Auth/Auth.selectors';
import { AuthState } from '../Auth/Auth.constants';


const PrivateRoute = (props) => {
  const { component, render, authState, ...rest } = props;
  const isAuthorized = authState === AuthState.AUTHORIZED;

  if (isAuthorized) return (<Route component={component} render={render} {...rest} />);

  return (
    <Route
      {...rest}
      render={props => <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  );
};


const mapState = state => ({
  authState: authSelectors.authState(state),
});

export default connect(mapState)(PrivateRoute);
