import moment from 'moment/moment';
import { CadesUtil } from '../../utils/CadesUtil';


export const prepareCertificates = (certs = []) => certs.map((c, i) => {
  const from = moment(c.info[1]);
  const to = moment(c.info[2]);

  return ({
    id: i + 1,
    name: CadesUtil.getNameFromSubject(c.info[0]),
    date: `${from.format('DD.MM.YYYY')} - ${to.format('DD.MM.YYYY')}`,
    source: CadesUtil.getCompanyNameFromSubject(c.info[0]),
    issuer: CadesUtil.getNameFromSubject(c.info[4]),
    title: c.info[0],
    disabled: moment() > to,
    ...c,
  });
});
