import {actionTypes} from '../../../constants/';

export function selectedPage(state = 1, action = {}) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS.PAGE_SELECTED:
      return action.value;
    case actionTypes.PRODUCT_DETAILS.FILTER_APPLY_CLICKED:
      return 1;
    default:
      return state;
  }
}
