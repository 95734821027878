import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Input as CoreInput } from '@crpt-ui/core';
import { isUndefined } from 'lodash';
import Icon from '@crpt/react-icon';
import { Wrap, IconWrapper } from '../../Fields/_refactor/Fields.styled';

const PureInput = memo(CoreInput);



class InputAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    onChange: PropTypes.func,
    formMutators: PropTypes.shape({}),
    forceValidate: PropTypes.bool,
    disabled: PropTypes.bool,
    clearValue: PropTypes.bool,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onChange: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    formMutators: undefined,
    forceValidate: false,
    disabled: false,
    clearValue: false,
  };

  onChangeTimeout;

  componentDidMount() {
    const { defaultValue, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value) && !isUndefined(defaultValue)) {
      setTimeout(() => formMutators.updateField(name, defaultValue), 0);
    }
  }

  checkValid = (value) => {
    if (value.length == 0) return value;
    let result = value.match(/\d/ig)
    result = result || []

    if (result.length > 2){
      if(result.length >= 4) result.splice(4, result.length);
      result.splice(2, 0, ':');
      return result.join('')
    }
    return result.join('');
  };

  onChange = (event) => {
    const { fieldInput, onChange } = this.props;
    const value = this.checkValid(event.target.value);
    fieldInput && fieldInput.onChange(value);
    if (onChange) onChange(value);
  };

  onClear = (val) => {
    const { fieldInput } = this.props;
    if (fieldInput && fieldInput.onChange) fieldInput.onChange('');
  };

  isError = () => {
    const { isError, fieldMeta, forceValidate, fieldInput } = this.props;
    if(fieldInput.value && fieldInput.value.length < 5) return true;
    if(fieldInput.value){
      let value = fieldInput.value
      value  = value.match(/\d/ig)
      value = value || []
      if(value[0] && value[0] > 2) return true;
      if(value[1] && value[0] == 2 && value[1] > 3) return true;
      if(value[2] && value[2] > 5) return true;
    }

    if (isError) return true;

    if (
      fieldMeta
      && fieldMeta.dirtySinceLastSubmit
      && !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error && (fieldMeta.touched || forceValidate));
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total, fieldMeta } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error' && fieldMeta.error) newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      placeholder,
      errorPlaceholder,
      disabled,
      clearValue,
      location,
      ...passProps
    } = this.props;

    const value = isUndefined(fieldInput.value) ? '' : `${fieldInput.value}`;
    const customStyleIfValid = formValues[this.props.name] && !!formValues[this.props.name].length && !formErrors[this.props.name]
      ? true
      : false;

    return (
      <Wrap
        onBlur={fieldInput && fieldInput.onBlur}
        marginBottom={marginBottom}
        location={location}
        customStyleIfValid={customStyleIfValid}
      >
        <PureInput
          {...passProps}
          onChange={this.onChange}
          error={this.isError()}
          label={this.getPlaceholder()}
          value={value}
          autoComplete="off"
          spellCheck="false"
          disabled={disabled}
        />

        {clearValue && !disabled && Boolean(value.length) && (
          <IconWrapper onClick={this.onClear}>
            <Icon type="cross" size={13} fill="#212C42" />
          </IconWrapper>
        )}
      </Wrap>
    );
  }
}

export default InputAdapter;
