import React from 'react';
import { useStyles } from '../editor/editor.styled';
import { socialString } from '../editor/constants';
import Icons from '../../../../common_components/Icons/Icons';
import { Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getInnerBody } from './constants';

export const ArticlePreview = ({ title, text, showSocial, isArticleAvailable }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const innerBody = getInnerBody(text)

  return isArticleAvailable ? (
    <div className={styles.previewContainer}>
    {title && (<h2>{title}</h2>)}
      <div dangerouslySetInnerHTML={{ __html: innerBody }}/>
      {
        showSocial && (
          <div dangerouslySetInnerHTML={{ __html: socialString }}/>
        )
      }
    </div>
  ) : (
    <div className={styles.previewContainer}>
      <div className={styles.PreviewWarningContainer}>
        <Icon component={Icons.Warning} style={{ width: 50, height: 50 }}/>
        <span>{t('Файл с данными статьи не найден')}</span>
        </div>
    </div>
  )
}
