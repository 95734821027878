import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';

import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import Popup from '../../../Popup';
import Action from '../../../List/FilterForm/ActionBar/styled/Action';
import { Translate } from '../../../../common_components/Translate/Translate';

class DownloadButton extends PureComponent {
  static propTypes = {
    showDownloadListButton: PropTypes.bool.isRequired,
    popupMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  };

  static defaultProps = {
    popupMenu: [
      {
        title: 'Скачать XLS',
        onClick: () => console.log('clicked XLS'),
      },
    ],
  };

  render() {
    const buttonTheme = {
      Button: get(this.props, 'theme.TableFilter.buttonBar.button'),
    };
    const iconFill = get(buttonTheme, 'Button.iconFill', '#434244');
    const { showDownloadListButton, popupMenu, onlyIcon } = this.props;

    const SimpleDownloadButton = !onlyIcon
      ? () => (
          <Button theme={buttonTheme}>
            <Icon type="black-download" spacingRight={16} fill={iconFill} />
            {Translate('Скачать')}
          </Button>
        )
      : () => <Icon type="black-download" fill={'#55b5e7'} />;

    const right = onlyIcon ? '-20px' : undefined;

    return (
      showDownloadListButton &&
      (popupMenu ? (
        <Popup menu={popupMenu} right={right}>
          <SimpleDownloadButton />
        </Popup>
      ) : (
        <SimpleDownloadButton />
      ))
    );
  }
}

export default withTheme(DownloadButton);
