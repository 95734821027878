import { HomeExciseService } from '../services/HomeExciseService';
import { getListActions } from '../utils/actionGenerators';
import { actionTypes, PAGINATED_LIST_CONSTANTS } from '../constants';
import {
  defaultFilterParamSerializer,
  defaultRequestParamSerializer,
  exciseListMountSerializer,
} from '../utils/filterSerializers';
import ExciseList from '../projects/motp/lists/ExciseList';
import { getLimitOffsetFormatter } from '../utils/pageFormatters';
import moment from 'moment/moment';
import { endPreloader, startPreloader } from './Preloader';
import { StoreHelper } from '../utils/StoreHelper';
import { FormSerializer } from '../utils/FormSerializer';
import {Toast} from '../utils/Toast';

const ConsolidatedLoaded = data => ({
  type: actionTypes.EXCISE_LIST.CONSOLIDATED_LOADED,
  value: data,
});

const LoadConsolidated = params => dispatch => {
  HomeExciseService.getConsolidated(params).then(answer => {
    console.log('answeeeer', answer.data);
    dispatch(ConsolidatedLoaded(answer.data));
  });
};

const LoadList = params => dispatch => {
  dispatch(startPreloader());
  HomeExciseService.getList(params)
    .then(answer => {
      dispatch(endPreloader());
      dispatch({
        type: actionTypes[ExciseList.actionNamespace].LIST_LOADED,
        value: answer.data,
      });
    })
    .catch(err => {
      console.log('err', { err: err });
      dispatch({
        type: actionTypes[ExciseList.actionNamespace].LOADING_FINISHED,
      });
      dispatch(endPreloader());
      if (err.response && err.response.status === 500) {
        dispatch({
          type: actionTypes[ExciseList.actionNamespace].LIST_LOADED,
          value: { results: [] },
        });
        Toast.showError({
          content: 'Сервер вернул 500 ошибку.'
        });
      }
    });
};

const onYearSelect = () => (dispatch, getState) => {
  const filterParams = getState()[ExciseList.selectorNamespace].filterParams;
  console.log('onYearSelect filterParams', filterParams);
  const filters = Object.assign({}, filterParams, {
    dateFrom: moment(filterParams.dateFrom)
      .startOf('year')
      .toISOString(),
    dateTo: moment(filterParams.dateTo)
      .endOf('year')
      .toISOString(),
  });

  dispatch({
    type: actionTypes[ExciseList.actionNamespace].LOADING_STARTED,
  });
  dispatch(startPreloader());
  dispatch(LoadConsolidated(filters));
  dispatch(LoadList(filters));
};

const onMonthSelect = () => (dispatch, getState) => {
  const filterParams = getState()[ExciseList.selectorNamespace].filterParams;
  console.log('onYearSelect filterParams', filterParams);
  const filters = Object.assign({}, filterParams, {
    dateFrom: moment(filterParams.dateFrom)
      .startOf('month')
      .toISOString(),
    dateTo: moment(filterParams.dateTo)
      .endOf('month')
      .toISOString(),
  });

  dispatch({
    type: actionTypes[ExciseList.actionNamespace].LOADING_STARTED,
  });
  dispatch(startPreloader());
  dispatch(LoadConsolidated(filters));
  dispatch(LoadList(filters));
};

const onDateSelect = (val, isMonth) => dispatch => {
  const filters = {
    dateFrom: moment(val.date)
      .startOf(isMonth ? 'month' : 'year')
      .toISOString(),
    dateTo: moment(val.date)
      .endOf(isMonth ? 'month' : 'year')
      .toISOString(),
  };

  dispatch({
    type:
      actionTypes[ExciseList.actionNamespace][
        PAGINATED_LIST_CONSTANTS.FILTER_PARAMS_UPDATED
      ],
    value: filters,
  });
  dispatch(LoadList(filters));
  dispatch(LoadConsolidated(filters));
};

export default {
  ...getListActions({
    actionNamespace: ExciseList.actionNamespace,
    selectorNamespace: ExciseList.selectorNamespace,
    getList: HomeExciseService.getList,
    pageFormatter: page => ({}),
    listMountFilterParamsSerializer: exciseListMountSerializer,
    listMountRequestParamsSerializer: exciseListMountSerializer,
    filterApplyFilterParamsSerializer: (formVals, pageParams) => {
      const filterParams = StoreHelper.getState()[ExciseList.selectorNamespace]
        .filterParams;
      return {
        ...filterParams,
        ...FormSerializer.serialize(formVals),
        ...pageParams,
      };
    },
    filterApplyRequestParamsSerializer: (formVals, pageParams) => {
      const filterParams = StoreHelper.getState()[ExciseList.selectorNamespace]
        .filterParams;
      return {
        ...FormSerializer.serialize(formVals),
        ...pageParams,
        ...filterParams,
      };
    },
    onFilterApply: params => {
      StoreHelper.dispatch(LoadConsolidated(params));
    },
  }),
  onYearSelect,
  onMonthSelect,
  LoadConsolidated,
  onDateSelect,
};
