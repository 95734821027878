import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';

import { Wrap, IconWrap, TextWrap } from './CertLine.styled';
import {
  cert,
  userInn,
} from '../../../../../../common_components/Auth/Auth.selectors';
import { loadCert } from '../../../../../../common_components/Auth/Auth.actions';
import { CadesUtil } from '../../../../../../utils/CadesUtil';
import { removeQuotes } from '../../../../../../utils';
import moment from 'moment/moment';
import { Translate } from '../../../../../../common_components/Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
};

const defaultProps = {};

class CertLine extends React.Component {
  // eslint-disable-next-line
  componentWillMount() {
    this.props.loadCert();
  }

  render() {
    const { cert, inn } = this.props;

    if (cert) {
      const { info } = cert;
      const issuer = CadesUtil.getNameFromSubject(info[4]);
      const from = moment(info[1]);
      const to = moment(info[2]);
      const date = `с ${from.format('DD.MM.YYYY')} до ${to.format(
        'DD.MM.YYYY',
      )}`;
      const title = CadesUtil.getCompanyNameFromSubject(info[0]);
      const code = info[3];

      return (
        <Wrap>
          <IconWrap>
            <Icon type={'success'} fill={'#60E84A'} />
          </IconWrap>
          <TextWrap>
            <div>
              {Translate('Сертификат')}
              {': '} {code}
            </div>
            <div>
              {Translate('Владелец')}
              {': '} {title}
            </div>
            <div>
              {Translate('ИНН:')} {inn}
            </div>
          </TextWrap>
          <TextWrap>
            <div>
              {Translate('Выдан:')} {issuer}
            </div>
            <div>
              {Translate('Действителен:')} {date}
            </div>
          </TextWrap>
        </Wrap>
      );
    }

    return <Wrap>{Translate('Не удалось загрузить сертификат')}</Wrap>;
  }
}

CertLine.defaultProps = defaultProps;
CertLine.propTypes = propTypes;

const mapState = state => {
  return {
    cert: cert(state),
    inn: userInn(state),
  };
};

const mapDispatch = dispatch => ({
  loadCert: () => dispatch(loadCert()),
});

export default compose(connect(mapState, mapDispatch), withTheme)(CertLine);
