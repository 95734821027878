import 'datejs';
import 'moment/locale/ru';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { countryCode } from 'src/common_components/App/App.selectors';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { Icon } from '@crpt/react-icon';
import { get, intersection } from 'lodash';
import moment from 'moment';
import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { countryCodeFlags, PARTICIPANT_TYPES, participantStatuses } from 'src/constants';
import ExciseBound from 'src/containers/Bounds/ExciseBound';
import DocumentsBound from 'src/containers/ParticipantDetails/DocumentsBound';
import WarehouseBound from 'src/containers/ParticipantDetails/WarehouseBound';
import { getOrgRoles } from 'src/utils/userUtils';

import ButtonTabs from '../ButtonTabs/ButtonTabs';
import ActionBar from '../common/ActionBar';
import { ContentWrapper } from '../common/styled';
import {
  BottomPart,
  BreadcrumbsWrap,
  HeadingId,
  HeadingSmallTitle,
} from '../Details/styled';
import Preloader from '../Preloader';
import BasicInfo from './BasicInfo';
import NDS from './NDS';
import Receipts from './Receipts';
import {
  DocIdAndLabel,
  DocIdAndLabelWrap,
  HeadingWrap,
  LabelWrap,
} from './styled';
import { ProductionConnected } from './ProductionInfo/Production';
import { Translate } from '../../common_components/Translate/Translate';

const Tree = React.lazy(() => import('./Tree'));
moment.locale('ru');

const propTypes = {};

const CAN_SEE_EXCISE = [PARTICIPANT_TYPES.IS_MP_OPERATOR];

const HAS_EXCISE = [PARTICIPANT_TYPES.IMPORTER, PARTICIPANT_TYPES.PRODUCER];

class ParticipantDetails extends Component {
  headingId = 'heading-details-id';
  portalFilterButtonId = 'buttonPlace1';
  portalDownloadButtonId = 'buttonPlace2';
  bottomPartid = 'bottomPartId';

  componentDidMount() {
    const inn = this.props.match.params.inn;

    const formated = decodeURIComponent(inn);
    console.log('inn', inn, 'formated', formated);

    if (
      window.location.pathname === `/participants/list/${inn}` ||
      window.location.pathname === `/participants/list/${inn}/`
    )
      this.props.history.replace(`/participants/list/${inn}/basic-info`);

    this.props.actions.ParticipantDetails.ParticipantDetailsMounted(formated);
  }

  componentDidUpdate(prevProps) {
    const { actions, match } = this.props;
    const inn = match.params.inn;
    const formated = decodeURIComponent(inn);
    const selectedParticipant = decodeURIComponent(prevProps.match.params.inn);
    if (selectedParticipant !== formated) {
      actions.ParticipantDetails.ParticipantDetailsMounted(formated);
    }
  }

  render() {
    const inn = this.props.match.params.inn;
    const { countryCode } = this.props;
    const isKZ = countryCode === 'KZ';
    const isUZ = countryCode === 'UZ';

    const {
      selectedParticipant,
      documents,
      Warehouse: warehouse,
      isPreloaderOpen,
    } = this.props.ParticipantDetails;

    const {
      App: {
        config: {
          ParticipantDetails: { Tree: tree },
        },
      },
    } = this.props;

    const orgRoles = getOrgRoles();
    const participantRoles = get(selectedParticipant, 'roles', []);
    const canSeeExcise =
      !!intersection(orgRoles, CAN_SEE_EXCISE).length &&
      !!intersection(participantRoles, HAS_EXCISE).length;
    const hasWarehouse =
      selectedParticipant &&
      !intersection(selectedParticipant.orgRoles, CAN_SEE_EXCISE).length;
    const isUserOGV = !!~orgRoles.findIndex(
      item => item === PARTICIPANT_TYPES.OGV,
    );

    const {
      DocumentsMounted,
      DocumentFilterSubmitClicked,
      WarehouseMounted,
      WarehouseFilterSubmitClicked,
      WarehouseSelectPage,
      ProducerFilterChanged,
    } = this.props.actions.ParticipantDetails;

    const breadcrumbs = [
      {
        href: '/participants/list',
        title: Translate('Участники'),
        id: 1,
      },
      {
        href: `/participants/list/${inn}`,
        title: (
          <span style={{ fontWeight: 600 }}>
            {(selectedParticipant &&
              (selectedParticipant.shortName ||
                selectedParticipant.fullName)) ||
              Translate('Имя участника')}
          </span>
        ),
        id: 2,
      },
    ];

    const tabs = [
      {
        id: 0,
        title: Translate('Общая информация'),
        route: `/participants/list/${inn}/basic-info`,
        check: [`/participants/list/${inn}/basic-info`],
      },
      // {
      //   id: 1,
      //   title: 'Коды',
      //   route: `/participants/list/${id}/cis`,
      //   check: [`/participants/list/${id}/cis`],
      // },
      {
        id: 2,
        title: Translate('Документы'),
        route: `/participants/list/${inn}/documents`,
        check: [`/participants/list/${inn}/documents`],
      },
      {
        id: 3,
        title: Translate('Чеки'),
        route: `/participants/list/${inn}/receipts`,
        check: [`/participants/list/${inn}/receipts`],
      },
      {
        id: 4,
        title: Translate('Склад'),
        route: `/participants/list/${inn}/warehouse`,
        check: [`/participants/list/${inn}/warehouse`],
      },
      {
        id: 5,
        title: Translate('Акцизный налог'),
        route: `/participants/list/${inn}/excise`,
        check: [`/participants/list/${inn}/excise`],
      },
      {
        id: 6,
        title: Translate('Дерево связей'),
        route: `/participants/list/${inn}/tree`,
        check: [`/participants/list/${inn}/tree`],
      },
      {
        id: 7,
        title: Translate('Производство'),
        route: `/participants/list/${inn}/production`,
        check: [`/participants/list/${inn}/production`],
      },
    ].filter(({ id }) => {
      const KGVisibleTabsIds = [0, 2];
      const KZVisibleTabsIds = [0, 2, 7];
      const UZVisibleTabsIds = [0, 2, 3];
      if (!canSeeExcise && id === 5) return false;
      if (!hasWarehouse && id === 4) return false;
      if (isUZ && !UZVisibleTabsIds.includes(id)) return false;
      if (isKZ && !KZVisibleTabsIds.includes(id)) return false;
      return true;
    });

    const currentIndex = tabs.findIndex(
      v => ~v.check.indexOf(window.location.pathname),
    );

    const leftSideComponents = [
      {
        component: (
          <ButtonTabs
            tabs={tabs}
            currentIndex={currentIndex}
            onSelect={val => this.props.history.push(val.route)}
          />
        ),
        wrap: true,
      },
    ];

    const rightSideComponents = [
      <div key="0" id="buttonPlace1" />,
      <div key="1" id="buttonPlace2" />,
    ];



    return (
      <div>
        <BreadcrumbsWrap>
          <Breadcrumbs
            withCustomLinks
            onClick={val => this.props.history.push(val.href)}
            breadcrumbs={breadcrumbs}
          />
        </BreadcrumbsWrap>
        {selectedParticipant && selectedParticipant[countryCodeFlags.isKZ ? 'inn' : 'tin'] === '' + inn && (
          <ContentWrapper>
            <ActionBar
              leftSideComponents={leftSideComponents}
              rightSideComponents={rightSideComponents}
            />
            <HeadingWrap>
              <div>
                <HeadingSmallTitle>{Translate('Участник')}</HeadingSmallTitle>
                <DocIdAndLabelWrap>
                  <DocIdAndLabel>
                    <HeadingId>
                      {(selectedParticipant &&
                        (selectedParticipant.shortName ||
                          selectedParticipant.fullName)) ||
                        Translate('Имя участника')}
                    </HeadingId>
                    <LabelWrap>
                      <Icon type="checkmark" width={16} spacingRight={16} />
                      {(selectedParticipant &&
                        Translate(participantStatuses[selectedParticipant.status])) ||
                        '-'}
                    </LabelWrap>
                  </DocIdAndLabel>
                </DocIdAndLabelWrap>
              </div>

              <div id={this.headingId} />
            </HeadingWrap>
            <div>
              {selectedParticipant && (
                <Switch>
                  <Route
                    render={props => (
                      <BasicInfo
                        isKZ={isKZ}
                        isUZ={isUZ}
                        selectedParticipant={selectedParticipant}
                        portalDownloadButtonId={this.portalDownloadButtonId}
                        actions={this.props.actions}
                        bottomPartId={this.bottomPartid}
                        {...props}
                      />
                    )}
                    path={`/participants/list/${inn}/basic-info`}
                  />

                  <Route
                    render={props => (
                      <DocumentsBound
                        DocumentFilterSubmitClicked={
                          DocumentFilterSubmitClicked
                        }
                        documents={documents}
                        DocumentsMounted={DocumentsMounted}
                        selectedParticipant={selectedParticipant}
                        portalFilterButtonId={this.portalFilterButtonId}
                        portalDownloadButtonId={this.portalDownloadButtonId}
                        {...props}
                      />
                    )}
                    path={`/participants/list/${inn}/documents`}
                  />
                  <Route
                    render={props => (
                      <Receipts
                        // DocumentFilterSubmitClicked={DocumentFilterSubmitClicked}
                        // documents={documents}
                        // DocumentsMounted={DocumentsMounted}
                        selectedParticipant={selectedParticipant}
                        inn={inn}
                        portalFilterButtonId={this.portalFilterButtonId}
                        portalDownloadButtonId={this.portalDownloadButtonId}
                        {...props}
                      />
                    )}
                    path={`/participants/list/${inn}/receipts`}
                  />
                  {hasWarehouse && (
                    <Route
                      render={props => (
                        <WarehouseBound
                          onMount={WarehouseMounted}
                          onFilterSubmit={WarehouseFilterSubmitClicked}
                          onSelectPage={WarehouseSelectPage}
                          onProducerFilterChanged={ProducerFilterChanged}
                          Warehouse={warehouse}
                          selectedParticipant={selectedParticipant}
                          isPreloaderOpen={isPreloaderOpen}
                          portalFilterButtonId={this.portalFilterButtonId}
                          portalDownloadButtonId={this.portalDownloadButtonId}
                          {...props}
                        />
                      )}
                      path={`/participants/list/${inn}/warehouse`}
                    />
                  )}

                  <Route
                    render={props => (
                      <NDS
                        selectedParticipant={selectedParticipant}
                        {...props}
                      />
                    )}
                    path={`/participants/list/${inn}/vat`}
                  />

                  {canSeeExcise && (
                    <Route
                      render={props => (
                        <ExciseBound
                          selectedParticipant={selectedParticipant}
                          headingId={this.headingId}
                          portalFilterButtonId={this.portalFilterButtonId}
                          portalDownloadButtonId={this.portalDownloadButtonId}
                          {...props}
                        />
                      )}
                      path={`/participants/list/${inn}/excise`}
                    />
                  )}

                  {isKZ && (
                    <Route
                      render={props => (
                        <ProductionConnected
                          selectedParticipant={selectedParticipant}
                        />
                      )}
                      path={`/participants/list/${inn}/production`}
                    />
                  )}

                  <Route
                    render={props => (
                      <div>
                        <Suspense
                          fallback={
                            <Preloader
                              allwaysShow={true}
                              text={'Загрузка дерева связей'}
                            />
                          }
                        >
                          <Tree
                            portalFilterButtonId={this.portalFilterButtonId}
                            portalDownloadButtonId={this.portalDownloadButtonId}
                            selectedParticipant={selectedParticipant}
                            tree={tree}
                            {...props}
                          />
                        </Suspense>
                      </div>
                    )}
                    path={`/participants/list/${inn}/tree`}
                  />
                </Switch>
              )}
            </div>
          </ContentWrapper>
        )}
        <BottomPart id={this.bottomPartid} />
      </div>
    );
  }
}

const mapState = state => ({
  countryCode: countryCode(state),
});

ParticipantDetails.propTypes = propTypes;

export default compose(withRouter, connect(mapState, null))(ParticipantDetails);
