import { createAction } from 'redux-actions';

export const subject = '[ActDocument]';

export const reconciliationLoading = createAction(`${subject} reconciliation loading`);
export const reconciliationDocument = createAction(`${subject} reconciliation document`);
export const setReconciliationDocument = createAction(`${subject} set reconciliation document`);
export const clearReconciliationDocument = createAction(`${subject} clear reconciliation document`);
export const setReconciliationDocumentErrors = createAction(`${subject} set reconciliation document errors`);
export const createReconciliationDraft = createAction(`${subject} create reconciliation draft`);
export const getReconciliationDraft = createAction(`${subject} get reconciliation draft`);
export const setReconciliationDraft = createAction(`${subject} set reconciliation draft`);
export const updateReconciliationDraft = createAction(`${subject} update reconciliation draft`);
