import React from 'react';
import { SingleSelect } from '@crpt/react-select';
import { FilterURLHelper } from '../../../utils/FilterURLHelper';
import { Translate } from '../../../common_components/Translate/Translate';
// import { statusesExt } from '../Grid.constants';


const statusesExtNumber = {
  INSERT_TO_AIC: 33,
  AIC_AGGREGATED: 34,
  AIC_DISAGGREGATED: 35,
  CUSTOMS_ISSUED: 36,
}
const statusesExtNames = {
  INSERT_TO_AIC: "Вложен в АИК",
  AIC_AGGREGATED: "Сформирован",
  AIC_DISAGGREGATED: "Расформирован",
  CUSTOMS_ISSUED: "Выпущен ГТК",
}

const statuses = Object.keys(statusesExtNumber).map(el=> ({
  id: statusesExtNumber[el],
  title: Translate(statusesExtNames[el]),
  cisStatus: statusesExtNumber[el],
}))

class StatusExtSelect extends React.Component {

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const status = statuses.find(v => v.cisStatus === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {
    const {selectedStatus} = this.props;
    let selectedId;
    if (selectedStatus) {
      selectedId = statuses.find(s => s.cisStatus === selectedStatus).cisStatus;
    }
    const {onRef} = this.props;

    return (
      <SingleSelect
        name="statusExt"
        placeholder={Translate("Расширенный статус")}
        values={statuses}
        selectedId={selectedId}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

StatusExtSelect.displayName = 'SingleSelect';

export default StatusExtSelect;
