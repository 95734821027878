import { actionTypes } from '../../constants';

export function glossaryList(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.REPORT_LIST.REPORT_LIST_MOUNTED:
      return action.payload.glossaryList;
    default:
      return state;
  }
}
