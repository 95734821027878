import React, { Fragment } from 'react';
import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { documentTypeNames } from '../../../constants';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { Translate } from '../../../common_components/Translate/Translate';
import { TwoLineLabel } from '@crpt/react-label';
import { FlexWrapper } from './styled';
import HeadedLabel from './HeadedLabel';
import * as actions from '../ducks/DocumentDetails.actions';
import { connect } from 'react-redux';
import moment from 'moment';


const CustomsDeclarationInfo = ({ selectedDocument, onDownloadJSON }) => {
  const { declarationNumber, declarationDate, senderName, senderInn, status } = selectedDocument
  const { documentId, documentDate } = selectedDocument

  const onDownload = () => {
    onDownloadJSON(documentId)
  }

  return (
    <Fragment>
      <HeadingWrap
        title={documentTypeNames.CUSTOMS_DECLARATION}
        id={documentId}
        status={status}
        date={documentDate}
        needDocTitle={false}
        bottomId={true}
        downloadDoc={onDownload}
      />
      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Общие данные')}>
            <TwoLineLabel heading={Translate('ИНН/ПИНФЛ импортера')}>{senderInn}</TwoLineLabel>
            <TwoLineLabel heading={Translate('Наименование заявителя')}>{senderName}</TwoLineLabel>
          </DocumentInfoBlock>
        </Column>
      </Container>
      <FlexWrapper>
        <HeadedLabel header={Translate('Дата ГТД')} value={moment(declarationDate).format('DD.MM.YYYY')} />
        <HeadedLabel header={Translate('Номер ГТД')} value={declarationNumber} />
      </FlexWrapper>
    </Fragment>
  )
}

const mapDispatch = dispatch => ({
  onDownloadJSON: id => dispatch(actions.downloadJSON(id)),
});

export default connect(null, mapDispatch)(CustomsDeclarationInfo);
