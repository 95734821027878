import React from 'react';

export default {
  title: 'Табличное представление при типе агрегации «Накопительный»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИНН',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: 'Наименование',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: 'Дата',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: 'Количество производственных линий',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
        },
        {
          'col1': '',
          'col2': '',
          'col3': <strong>Максимальное значение</strong>,
          'col4': <strong>...</strong>,
        },
        {
          'col1': <strong>Итого: текущее значение</strong>,
          'col2': '',
          'col3': '',
          'col4': <strong>...</strong>,
        },
        {
          'col1': <strong>Итого: максимальное значение</strong>,
          'col2': '',
          'col3': '',
          'col4': <strong>...</strong>,
        },       
      ]
    },
  ]
}