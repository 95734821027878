import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get, isArray } from 'lodash';

import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import Popup from '../../../Popup';
import {Translate} from '../../../../common_components/Translate/Translate';

class DownloadButton extends PureComponent {
  static propTypes = {
    popupMenu: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
    ]).isRequired,
  };

  menuItems = [{
    title: 'Скачать XLS',
    onClick: event => console.log('clicked XLS'),
  }];

  getMenuItems = () => {
    const { popupMenu } = this.props;
    if (isArray(popupMenu)) return popupMenu;
    return this.menuItems;
  };

  render() {
    const { popupMenu, handleDownload } = this.props;

    const buttonTheme = { Button: get(this.props, 'theme.TableFilter.buttonBar.button') };

    const SimpleDownloadButton = () => (
      <Button theme={buttonTheme} onClick={handleDownload}>
        <Icon type="black-download" spacingRight={16} />
        {Translate('Скачать')}
      </Button>
    );

    return (
      popupMenu
        ? (
          <Popup menu={this.getMenuItems()}>
            <SimpleDownloadButton />
          </Popup>
        )
        : <SimpleDownloadButton />
    );
  }
}

export default withTheme(DownloadButton);
