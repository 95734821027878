import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        },  
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Дата события оплаты',
        },
        {
          'col1': [2, 3],
          'col2': <ul>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'ИНН и наименование производителя товара',
        },
        {
          'col1': [4],
          'col2': <ul>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Уникальный код товара',
        },
        {
          'col1': [5],
          'col2': <ul>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Уникальный идентификационный номер пакета документов с КМ в системе',
        },
        {
          'col1': [6],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Количество пакетов документов в системе Onyma',
        },
        {
          'col1': [7],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Количество событий оплаты по этим пакетам документов в системе Onyma',
        },
        {
          'col1': [8],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Количество пакетов документов в системе Аналитики',
        },
        {
          'col1': [9],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Количество событий оплаты по этим пакетам документов в системе Аналитики',
        },
        {
          'col1': [10],
          'col2': <ul>
            <li>Дата</li>
            <li>Дата + Производитель</li>
            <li>Дата + Производитель + GTIN</li>
            <li>Дата + Производитель + GTIN + DID</li>
          </ul>,
          'col3': 'Разница в количестве событий оплаты систем Onyma и Аналитики',
        },
      ]
    }
  ]
}