import React from 'react';

import { getOp } from './Operation.constants';
import { useStyles } from './Operation.styles';

const Operation = ({ op }) => {
  const Op = getOp(op);
  const classes = useStyles();
  return Op ? <div className={classes.lineWrap}>{Op}</div> : null;
};

export default Operation;
