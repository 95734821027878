import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  ActionWrap,
  ScanError,
  ScanningTop,
  ScanningWrapper,
} from './ScanDialog.styled';
import Icon from '@crpt/react-icon';
import PrimaryActionButton from '../Buttons/MuiPrimaryActionMediumButton';
import CommonActionButton from '../Buttons/MuiCommonActionMediumButton';
import DialogContent from '@material-ui/core/DialogContent';
import { ScanDialogResults } from './components/ScanDialogResults';
import Typography from '@material-ui/core/Typography';
import * as actions from './ducks/ScanDialog.actions';
import * as selectors from './ducks/ScanDialog.selectors';
import { connect } from 'react-redux';
import { ScanFiled } from './components/ScanField';
import DialogActions from '@material-ui/core/DialogActions';
import { Translate } from '../Translate/Translate';
import { deletingCryptoEnd } from '../../motp_components/_Requests/RequestList/ActDocument/Reconciliation/Reconciliation.utils';

const ScanDialog = ({
  docType,
  open,
  onClose,
  scanCodes,
  errorInfo,
  cisList,
  removeCodesFromScannedList,
  onAdd,
  closeModal,
  addScannedLPAcceptGoodsCises,
}) => {
  const [codes, setCodes] = useState('');
  const [localErrors, setLocalErrors] = useState([]);

  useEffect(() => {
    setCodes('');
  }, [cisList]);

  const validation = codes => {
    const codesArray = codes
      .trim()
      .replace(/\n/g, ' ')
      .split(' ');
    return codesArray.reduce((acc, el) => {
      if (el[0] === '(' && el[3] === ')' && el[18] === '(' && el[21] === ')') {
        setLocalErrors([...localErrors, el]);
        return true;
      } else {
        return acc || false;
      }
    }, false);
  };

  const onScan = () => {
    if (!validation(codes)) scanCodes({ codes, docType });
  };

  const onCheckLPAcceptGoods = () => {
    if (!validation(codes)) {
      const newCodes = deletingCryptoEnd(codes);
      const newSet = new Set(newCodes);
      addScannedLPAcceptGoodsCises(Array.from(newSet));
      closeAndClear();
    }
  };

  const onDeleteScannedItem = codes => {
    removeCodesFromScannedList(codes);
  };

  const isActive = useMemo(() => {
    return cisList.filter(item => !item.isInvalid).length > 0;
  }, [cisList]);

  const onAddClick = () => {
    const cises = cisList.filter(item => !item.isInvalid);
    onAdd(cises);
    closeAndClear();
  };

  const closeAndClear = () => {
    closeModal();
    onClose();
  };

  const onChangeCodes = codes => {
    setLocalErrors([]);
    setCodes(codes);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={closeAndClear}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">{Translate('Сканировать коды')}</Typography>
      </DialogTitle>
      <DialogContent>
        <ScanningWrapper>
          <ScanningTop>
            <Icon
              type="warning"
              withoutMask
              spacingRight={16}
              size={32}
              fill="#52535a"
            />
            <div>
              <div>
                {Translate(
                  'Для начала сканирования поместите курсор ввода в текстовое поле ниже и начинайте сканировать коды маркировки',
                )}
              </div>
              <div>
                {Translate('Раскладка клавиатуры должна быть ENG (Английская)')}
              </div>
            </div>
          </ScanningTop>

          <div>
            <ScanFiled
              value={codes}
              onChange={onChangeCodes}
              errors={
                localErrors.length > 0
                  ? `[${localErrors.join(', ')}]`
                  : errorInfo
              }
            />
          </div>
          {docType !== 'LP_ACCEPT_GOODS' ? (
            <Fragment>
              <ActionWrap>
                <PrimaryActionButton
                  onClick={onScan}
                  disabled={codes.trim(/\s/) === ''}
                >
                  {Translate('Проверить')}
                </PrimaryActionButton>
                {errorInfo && localErrors.length === 0 && (
                  <ScanError>
                    {Translate(
                      'Внимание! В отсканированном списке КМ есть некорректные коды. Удалите их и попробуйте снова',
                    )}
                  </ScanError>
                )}
                {localErrors.length > 0 && (
                  <ScanError>
                    {Translate(
                      'Внимание! Коды должны быть отсканированы и иметь формат data-matrix',
                    )}
                  </ScanError>
                )}
              </ActionWrap>
              <ScanDialogResults
                list={cisList}
                onRemove={onDeleteScannedItem}
              />
            </Fragment>
          ) : null}
        </ScanningWrapper>
      </DialogContent>
      <DialogActions>
        <CommonActionButton onClick={closeAndClear}>
          {Translate('Закрыть')}
        </CommonActionButton>
        {docType === 'LP_ACCEPT_GOODS' ? (
          <PrimaryActionButton
            onClick={onCheckLPAcceptGoods}
            disabled={codes === ''}
          >
            {Translate('Проверить')}
          </PrimaryActionButton>
        ) : (
          <PrimaryActionButton onClick={onAddClick} disabled={!isActive}>
            {Translate('Добавить')}
          </PrimaryActionButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapState = state => ({
  errorInfo: selectors.errorInfo(state),
  cisList: selectors.cisList(state),
});
const mapDispatch = dispatch => ({
  scanCodes: data => dispatch(actions.scanCodes(data)),
  closeModal: () => dispatch(actions.closeModal()),
  removeCodesFromScannedList: cis =>
    dispatch(actions.removeCodesFromScannedList(cis)),
});
export default connect(mapState, mapDispatch)(ScanDialog);
