import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  aboutWrapper: {
    marginBottom: '20px'
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.modal.padding
  },
  certInfoWrapper: {
    backgroundColor: '#F9F9F9',
    display: 'flex',
    marginBottom: '20px',
    padding: theme.modal.padding,
    paddingTop: '17px',
    paddingBottom: '17px'
  },
  infoContentWrapper: {
    padding: theme.modal.padding
  },
  main: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  notificationWrapper: {
    marginBottom: '40px'
  }
}));
