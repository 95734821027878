import { createAction } from 'redux-actions';

export const subject = '[Auth]';

export const gotInfo = createAction(`${subject} got info`);
export const clear = createAction(`${subject} clear`);
export const loaded = createAction(`${subject} loaded`);
export const certLoaded = createAction(`${subject} cert loaded`);
export const loadCert = createAction(`${subject} load cert`);
export const gotStartRoute = createAction(`${subject} got start route`);
export const saveCompany = createAction(`${subject} save company`);
export const productGroups = createAction(`${subject} set product groups`);
