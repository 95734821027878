import {actionTypes} from '../../constants/';

export function passwordError(state = false, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM.GOT_ERROR_LOGIN:
      return true;

    default:
      return state;
  }
}
