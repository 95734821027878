export const participantExceptions = [
  {
    inn: '3223000539',
    company: 'АО "Погарская сигаретно - сигарная фабрика"',
  },
  {
    inn: '3443033593',
    company: 'ООО "Империал Тобакко Волга"',
  },
  {
    inn: '3901503125',
    company: 'ООО "Новая линия"',
  },
  {
    inn: '3905027090',
    company: 'ООО "Балтийская табачная фабрика"',
  },
  {
    inn: '4003028481',
    company: 'ООО "Кей Ти Эн Джи Рус"',
  },
  {
    inn: '4720007247',
    company: 'АО "Филип Моррис Ижора"',
  },
  {
    inn: '6162063051',
    company: 'АО "Донской Табак"',
  },
  {
    inn: '7809008119',
    company: 'АО "БАТ-СПб"',
  },
  {
    inn: '7834005168',
    company: 'ООО "Петро"',
  },
  {
    inn: '7743228819',
    company: 'ООО "Универсальная Сигаретная Мануфактура"',
  },
];

export const iconStyle = {
  background: '#F6F42E',
  borderRadius: 2,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8,
}