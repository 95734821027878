import {actionTypes} from '../../constants';


export function error(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.COMPANY_INFO_ERROR:
      return action.value;
    case actionTypes.PROFILE.PROFILE_INFO_UPDATED:
      return null;
    default:
      return state;
  }
}
