import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  contentWrapper: {
    width:'100%',
    height: '100%',
    
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
    color:theme.palette.text.primary,
  },
  iconWrapper: {
    
  }
}));
