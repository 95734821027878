import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getOverflow } from './utils';
import { getParentByRole } from '../utils';

import Tooltip from './Tooltip/index';

export default class TableTooltip extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    event: PropTypes.any,
    content: PropTypes.any,
  };

  static defaultProps = {
    isVisible: false,
    event: null,
    content: <div />,
  };

  calcPosition = (event) => {
    const cellElement = event;

    const isOverflow = getOverflow(cellElement);
    const scrollFlexElement = getParentByRole({ elem: cellElement, role: 'tablescrooller' });
    const rowElement = getParentByRole({ elem: cellElement, role: 'row' });

    let rowWidth = rowElement.offsetWidth;
    let scrollLeftValue = 0;

    if (scrollFlexElement) {
      rowWidth = scrollFlexElement.offsetWidth;
      scrollLeftValue = scrollFlexElement.scrollLeft;
    }

    const pageHeight = document.documentElement.clientHeight + window.pageYOffset;
    const elementPagePosition = window.pageYOffset + rowElement.getBoundingClientRect().top;
    const heightToBack = pageHeight - elementPagePosition;

    const eventCoordinates = {
      offsetX: cellElement.offsetLeft + cellElement.offsetParent.offsetLeft - scrollLeftValue,
      offsetY: cellElement.offsetTop + cellElement.offsetParent.offsetTop,
      width: cellElement.offsetWidth,
      height: cellElement.offsetHeight,
    };

    return {
      isVisible: isOverflow,
      rowWidth,
      heightToBack,
      ...eventCoordinates,
      content: event.children,
    };
  };

  render() {
    const { event, content, isVisible } = this.props;
    if (!event) { return null; }
    if (!isVisible) { return null; }

    const {
      isVisible: isShowToolTip, offsetX, offsetY, width, height, heightToBack, rowWidth,
    } = this.calcPosition(event);

    return isShowToolTip
      ? (
        <Tooltip
          offsetX={offsetX}
          offsetY={offsetY}
          width={width}
          height={height}
          content={content}
          heightToBack={heightToBack}
          rowWidth={rowWidth}
        />
      )
      : null;
  }
}
