import React from 'react';
import { withTheme } from 'styled-components';
import { CsvToHtmlTable } from 'react-csv-to-table';
import XLSX from '../../../packages/xlsx/xlsx.full.min';
import { PreviewWrapperCsv } from '../FilePreview.styled';

const XlsxFilePreview = props => {
  const { content, ...pass } = props;

  const html = XLSX.utils.sheet_to_html(content.Sheets[content.SheetNames[0]], {
    dateNF: 'DD.MM.YYYY'
  });

  return (
    <PreviewWrapperCsv>
      <div dangerouslySetInnerHTML={{__html: html}}></div>
    </PreviewWrapperCsv>
  );
};

export default withTheme(XlsxFilePreview);
