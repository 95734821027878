import React from 'react';
// import PropTypes from 'prop-types';
import { get, isNull, isUndefined } from 'lodash';
import HeadedLabel from './HeadedLabel';
import { LeftColumn, ProductImage, Wrapper } from './styled';
import { getRubelsAsFloatFromKopecks } from 'src/utils';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { Translate } from '../../../../common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { documentTypes } from '../../../../constants';

export default function ProductItem (props) {
  const { countryCode, isAggregationDocument, documentType } = props;
  const {t} = useTranslation();
  const isKZ = countryCode === 'KZ';
  const isUZ = countryCode === 'UZ';

  const productId = get(props, 'product.id', '');
  // static propTypes = {
  //   isAggregationDocument: PropTypes.bool,
  //   countryCode: PropTypes.oneOf(['KZ', 'UZ']),
  //   product: PropTypes.shape({
  //     id: PropTypes.number,
  //     name: PropTypes.string,
  //     price: PropTypes.string,
  //     count: PropTypes.number,
  //     packageType: PropTypes.string,
  //     sum: PropTypes.string,
  //     nds: PropTypes.string,
  //     percentage: PropTypes.string,
  //     excise: PropTypes.string,
  //   }).isRequired,
  // };

  const updateEmptyValue = value => {
    if (isUndefined(value) || isNull(value)) {
      return '-';
    }
    return value;
  };

  const productName = updateEmptyValue(get(props, 'product.name'));
  const currencySign = isKZ ? '₸' : isUZ ? t('сум') : '₽';
  const productPrice = isKZ
      ? get(props, 'product.price', 0) / 100
      : get(props, 'product.itemPrice', '') ||
        get(props, 'product.price', ''); // getRubelsAsFloatFromKopecks

    const productPriceString = productPrice
      ? `${productPrice} ${currencySign}` // addSpacesBetweenThousands
      : '-';

    const productCount = updateEmptyValue(
      isUZ && documentType === documentTypes.RECEIPT ? (get(props, 'product.count') / 1000) : get(props, 'product.count')
    )

    const productPackageType = get(props, 'product.packageType', '');
    const productPackageNameFromConstants = get(
      packTrivialTypesKZ,
      productPackageType,
      '',
    );
    const productPackageName = productPackageNameFromConstants
      ? `(${productPackageNameFromConstants} ${t('упаковка')})`
      : '';

    const productSum = isKZ
      ? get(props, 'product.sum', 0) / 100
      : get(props, 'product.sum', 0) / 100 ||
        get(props, 'product.price', ''); // getRubelsAsFloatFromKopecks
    const productSumString = productSum
      ? `${productSum} ${currencySign}` // addSpacesBetweenThousands
      : '-';

    const productNdsWithoutKopecks = get(props, 'product.nds', '');
    const productNds = isUZ
      ? productNdsWithoutKopecks
      : getRubelsAsFloatFromKopecks(productNdsWithoutKopecks);
    const productGTIN = get(props, 'product.gtin');
    const productNdsWithCurrencySign = productNds
      ? `${productNds} ${currencySign}` // addSpacesBetweenThousands
      : '-';

  return (
    <Wrapper>
      <LeftColumn>
        {(isAggregationDocument || isKZ || isUZ) && (
          <HeadedLabel header="GTIN" value={productGTIN || '-'} />
        )}
        {
          <HeadedLabel
              header={Translate('Наименование')}
              value={productName}
          />
        }
          {!isAggregationDocument && !isUZ && (
            <HeadedLabel
              header={Translate('Цена')}
              value={productPriceString}
            />
          )}
          <HeadedLabel header={Translate('Количество')} value={productCount} />
          {
            <HeadedLabel header={Translate('Сумма')} value={productSumString} />
          }{' '}
          {isUZ && (
            <HeadedLabel
              header={Translate('НДС')}
              value={productNdsWithCurrencySign}
            />
          )}
        </LeftColumn>
        {!isKZ && !isUZ && <ProductImage productId={productId} />}
    </Wrapper>
  );
}
