import React from 'react';
import { Grid } from '@material-ui/core';
import {
  DividerOfContent,
  DocumentTitleName
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { Translate } from '../../../common_components/Translate/Translate';
import { Button as SButton, themes as BUTTON_THEMES } from '@crpt/react-button';
import Icon from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { importStatusesNames } from '../../../constants/documentStatuses';
import { CrossborderWrap, StyledLink } from '../EasCrossborder/EasCrossborder.styles';
import { Accordion } from '../../../common_components/Accordion';
import { Content } from '../Act/Showing.styled';
import { get } from 'lodash';

export const AcceptGoodsBasicInfo = ({ selectedDocument, id }) => {
  return (
    <CrossborderWrap>
      <React.Fragment>
        <Grid container justify="space-between">
          <Grid item>
            <DocumentTitleName>
              {Translate('Уведомление о приемке товаров ЕАЭС №')}{' '}
              <span>{id}</span>{' '}
            </DocumentTitleName>
          </Grid>
          <Grid item>
            <SButton theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(importStatusesNames[selectedDocument.status])}
            </SButton>
          </Grid>
        </Grid>
        <br/>
        <DividerOfContent/>
        <br />
        <Accordion title={Translate("Сведения об отгрузке")} expand>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {Translate('Номер документа отгрузки')} <br/>
              <Content>
                <StyledLink to={`/documents/list/${encodeURIComponent(selectedDocument.shipmentId)}` || '#'} target="_blank">{selectedDocument.shipmentId || '-'}</StyledLink>
              </Content>
            </Grid>
            <Grid item xs={8}/>
            <Grid item xs={4}>
              {Translate('Номер первичного документа')} <br/>
              <Content>{get(selectedDocument, 'documentNum', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('Дата первичного документа')} <br/>
              <Content>{get(selectedDocument, 'documentDate', '-')}</Content>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={4}>
              {Translate('Дата приемки')} <br/>
              <Content>{get(selectedDocument, 'acceptanceDate', '-')}</Content>
            </Grid>
          </Grid>
        </Accordion>
        <Accordion title={Translate("Отправитель товара")} expand>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {Translate('Страна отправления')} <br />
              <Content>{get(selectedDocument, 'senderCountryCode', '-')}</Content>
            </Grid>
            <Grid item xs={8}/>
            <Grid item xs={4}>
              {Translate('Наименование отправителя')} <br />
              <Content>{get(selectedDocument, 'exporter', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('ИНН')} <br />
              <Content>{get(selectedDocument, 'exporterInn', '-')}</Content>
            </Grid>
          </Grid>
        </Accordion>
        <Accordion title={Translate("Получатель товара")} expand>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {Translate('Наименование получателя')} <br />
              <Content>{get(selectedDocument, 'receiver', '-')}</Content>
            </Grid>
            <Grid item xs={4}>
              {Translate('ИНН/БИН')} <br />
              <Content>{get(selectedDocument, 'receiverInn', '-')}</Content>
            </Grid>
          </Grid>
        </Accordion>
      </React.Fragment>
    </CrossborderWrap>
  )
};
