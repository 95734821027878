import { handleActions } from 'redux-actions';
import * as actions from './ScanDialog.actions';

const isLoadingScannerCis = handleActions(
  {
    [actions.setScannedCodes]: () => false,
    [actions.scanCodes]: () => true,
  },
  false,
);

const comparedCodes = handleActions(
  {
    [actions.setCodesForComparing]: (state, { payload }) => payload,
    [actions.unmounted]: () => null,
    [actions.closeModal]: () => null,
  },
  null,
);

const cisList = handleActions(
  {
    [actions.setScannedCodes]: (state, { payload = [] }) => payload,
    [actions.removeCodesFromScannedList]: (state, { payload }) =>
      state.filter(stateItem => stateItem.cis !== payload),
    [actions.unmounted]: () => [],
    [actions.closeModal]: () => [],
  },
  [],
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.setScannedCodes]: () => null,
    [actions.unmounted]: () => null,
    [actions.closeModal]: () => null,
  },
  null,
);

export default {
  cisList,
  comparedCodes,
  isLoadingScannerCis,
  errorInfo,
};
