import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { throttle, get } from 'lodash';

import { AutocompleteFilter as AutoCompleteFilterCRPT } from '@crpt-ui/datatable/lib/Filters';
import * as selectors from './ducks/AutocompleteFilter.selectors';
import * as actions from './ducks/AutocompleteFilter.actions';

const AutocompleteFilter = ({
  error,
  loaded,
  onMount,
  onUnmount,
  results,
  otherProps,
  prepareOptions,
  label,
  optionLabel = 'label',
}) => {
  const {
    onInputChange,
    ...rest
  } = otherProps;

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  const options = prepareOptions(results);

  const onInputChangeCallback = useCallback(value => {
    onInputChange(value);
  }, []);

  const value = () => {
    const formStateValue = get(rest.formState.values, 'value', '');

    return { label: formStateValue, name: formStateValue };
  };

  return (
    <AutoCompleteFilterCRPT
      onInputChange={throttle(onInputChangeCallback, 1000)}
      options={options}
      optionLabel={optionLabel}
      label={label}
      autoComplete
      autoSelect
      loading={!loaded}
      error={error}
      noOptionsText={' ...'}
      name={'value'}
      defaultValue={value()}
      {...rest}
    />
  );
};

const mapState = state => ({
  loaded: selectors.loaded(state),
  results: selectors.results(state),
  error: selectors.error(state),
});

const mapDispatch = dispatch => ({
  onMount: () => dispatch(actions.mounted()),
  onUnmount: () => dispatch(actions.unmounted()),
});

export default connect(mapState, mapDispatch)(AutocompleteFilter);
