import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { memo } from 'react';
import {
  DatePicker,
  InputTime,
} from 'src/common_components/_refactor/Form/Fields';

import { InfoBlock } from '../InfoBlock';
import { Translate } from 'src/common_components/Translate/Translate';
import { Trans } from 'react-i18next';

const ClosingPeriod = ({ maxDate, minDate, fromTime, toTime }) => (
  <InfoBlock
    title={Translate("Период производства продукции")}
    description={ClosingPeriodDescription}
  >
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <DatePicker
          name="information.fromDate"
          placeholder={Translate("Начало периода")}
          maxDate={maxDate}
          minDate={minDate}
          required
        />
      </Grid>
      <GridWitnTimezone
        name="information.fromTime"
        placeholder={Translate("Время начала периода")}
        value={fromTime}
      />
      <Grid item xs={7}>
        <DatePicker
          name="information.toDate"
          placeholder={Translate("Окончание периода")}
          maxDate={maxDate}
          minDate={minDate}
          required
        />
      </Grid>
      <GridWitnTimezone
        name="information.toTime"
        placeholder={Translate("Время окончания периода")}
        value={toTime}
      />
    </Grid>
  </InfoBlock>
);

export default ClosingPeriod;

const ClosingPeriodDescription = () => (
  <React.Fragment>
    <Trans>Для формирования выгрузки по не агрегированным и не списанным кодам, выберите период производства продукции, <b>за который полностью завершена агрегация и списание</b></Trans>
  </React.Fragment>
);

const GridWitnTimezone = ({ name, placeholder, value }) => {
  const isInputValue = !!Object.values(value).join('');

  return (
    <Grid item xs={5} style={{ position: 'relative' }}>
      <InputTime
        name={name}
        placeholder={placeholder}
        InputProps={{
          readOnly: true,
          ...(isInputValue ? { endAdornment: <RenderEndAdornment /> } : {}),
        }}
      />
    </Grid>
  );
};

const RenderEndAdornment = memo(() => (
  <Box
    color="grey.600"
    position="absolute"
    top="22px"
    left="80px"
    fontWeight={600}
    fontSize={14}
  >
    {moment().format('Z UTC')}
  </Box>
));
