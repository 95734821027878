import React from 'react';
import { isMasked16digit } from '../utils/validations';
import { Input } from './index';

function occurrences(string, subString, allowOverlapping) {
  string += '';
  subString += '';
  if (subString.length <= 0) return string.length + 1;

  var n = 0,
    pos = 0,
    step = allowOverlapping ? 1 : subString.length;

  for(;;) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
}

export class EmissionRegistrarNumberInput extends React.Component {

  render() {
    const { ...props } = this.props;

    return (
      <Input
        {...props}
        placeholder="Номер регистратора эмиссии"
        errorPlaceholder={
          'Номер регистратора эмиссии должен быть ровно 16 цифр'
        }
        validate={isMasked16digit}
        mask={'9999999999999999'}
        maskOptions={{
          numericInput: true,
          placeholder: '0'
        }}
        inSign
      />
    );
  }
}
