import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 14px;
`;

export const TwoLineWrapper = styled.div`
  display: inline-flex;
  padding-bottom: 22px;
  padding-left: 21px;
  padding-right: 15px; 
`;