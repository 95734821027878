const options = state => state.OrderForm.options;
const contact = state => state.OrderForm.contact;
const date = state => state.OrderForm.date;
const items = state => state.OrderForm.items;
const isOpen = state => state.OrderForm.isOpen;


export {
  options,
  contact,
  date,
  items,
  isOpen,
};
