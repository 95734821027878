import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { InlineWrap } from '@crpt/react-page-layout';
import { ErrorMessage, SidebarInfo } from './Fields.styled';
import { certRequired } from '../../../common_components/Form/utils/validations';
import { FormContext } from '../../../common_components/Form/Form.constants';
// import CertificateSelect from '../../../common_components/Form/Fields/Select/CertificateSelect/CertificateSelect';
import MaterialCertSelect from '../../../common_components/Form/Fields/Select/CertificateSelect/MaterialCertSelect';
import SubmitButton from '../../../common_components/Form/Fields/Button/SubmitButton';

const FieldsView = ({ onOpenSidebar, errors, onSelectCertificate }) => (
  <React.Fragment>
    {/* <CertificateSelect
      name="cert"
      validate={certRequired}
      onSelect={onSelectCertificate}
    /> */}
    <MaterialCertSelect
      name="cert"
      validate={certRequired}
      onSelect={onSelectCertificate}
    />
    <FormContext.Consumer>
      {({ values }) => (
        <ErrorMessage>
          {Boolean(get(values, 'cert', false)) && errors}
        </ErrorMessage>
      )}
    </FormContext.Consumer>

    <InlineWrap flex alignItems="center">
      <SubmitButton
        marginBottom="0px"
        color="primary"
      >
        {'Войти в личный кабинет'}
      </SubmitButton>
      <SidebarInfo onClick={onOpenSidebar}>
        {'Как войти?'}
      </SidebarInfo>
    </InlineWrap>
  </React.Fragment>
);

FieldsView.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  onSelectCertificate: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

FieldsView.defaultProps = {
  errors: '',
};

export default FieldsView;
