import { selector } from 'src/utils/selector';

export const loaded = state => selector(state, 'DocumentDetails.loaded');
export const selectedDocument = state => selector(state, 'DocumentDetails.selectedDocument');
export const requestedMarkedList = state => selector(state, 'DocumentDetails.requestedMarkedList');
export const loadedMarkedList = state => selector(state, 'DocumentDetails.loadedMarkedList');
export const markedListData = state => selector(state, 'DocumentDetails.markedListData');
export const codes = state => selector(state, 'DocumentDetails.codes');
export const totalData = state => selector(state, 'DocumentDetails.totalData');
export const loadedCodes = state => selector(state, 'DocumentDetails.loadedCodes');
export const errorMessage = state => selector(state, 'DocumentDetails.errorMessage');
export const getData = state => selector(state, 'DocumentDetails.showingData');
