import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  right: ${props => props.right};
  top: ${props => props.top};
  height: ${props => props.height};
  display: ${props => props.visible ? 'flex' : 'none'};
  z-index: 10000;
`;

