import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  uploadedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadedBanner: {
    height: 150,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& img': {
      height: '100%',
      borderRadius: 16,
      overflow: 'hidden',
    }
  }
});
