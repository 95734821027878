import { authInstance } from './authInstance';
import { AltScenariosService } from './AltScenariosService';
import axios from 'axios';

const CancelToken = axios.CancelToken;

export class CISService {
  static _getListReportCancel;
  static _getListCancel;

  static getList(params) {
    if (CISService._getListCancel) CISService._getListCancel('PREVENT_TOAST');

    const data = Object.assign({ needTotal: true }, params);

    if (params.scenarioType) return AltScenariosService.getList(data);

    return authInstance.request({
      url: '/private-office-api/private/v2/cis/search',
      method: 'post',
      data,
      cancelToken: new CancelToken(function(c) {
        CISService._getListCancel = c;
      }),
    });
  }

  static getByCis(cis) {
    if (CISService._getListCancel) CISService._getListCancel('PREVENT_TOAST');

    const data = {
      pageSize: 20,
      currentPage: 0,
      filters: [{ operator: '=', column: 'cis', filterTerm: cis }],
      sorts: [],
      groupBy: [],
    };

    return authInstance.request({
      url: '/api/km-grid/getPageData',
      method: 'post',
      data,
      cancelToken: new CancelToken(function(c) {
        CISService._getListCancel = c;
      }),
    });
  }

  static getCISDetails(id) {
    return authInstance.request({
      url: '/private-office-api/private/v2/cis/info',
      method: 'get',
      params: {
        id,
      },
    });
  }

  static getListReport(type, data) {
    if (CISService._getListReportCancel) CISService._getListReportCancel();

    return authInstance.request({
      url: `/private-office-api/private/v2/cis/buildReport/${type}`,
      method: 'post',
      responseType: 'blob',
      data,
      cancelToken: new CancelToken(function(c) {
        CISService._getListReportCancel = c;
      }),
    });
  }
}
