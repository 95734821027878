import arrayMutators from 'final-form-arrays';


export const formMutators = {
  ...arrayMutators,
  updateField: ([name, value], state, { changeValue }) => changeValue(state, name, () => value),

  addUnique: ([name, value], state, { changeValue }) => (
    changeValue(
      state,
      name,
      (old = []) => (
        old.indexOf(value) !== -1
          ? old
          : [...old, value]
      )
    )),

  toggle: ([name, value], state, { changeValue }) => (
    changeValue(
      state,
      name,
      (old = []) => {
        if (old.indexOf(value) === -1) return [...old, value];
        const values = old.filter(e => e !== value);
        if (!values.length) return undefined;
        return values;
      }
    )),
};
