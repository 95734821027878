import { countryCodes } from '../../../../constants/countryCodes';
import { nano2milli } from '../../DocumentDetails.utils';

const dateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timezone: 'UTC',
};

export const columns = [
  { Header: 'ИИН/БИН получателя', cid: 'applicantInn' },
  { Header: 'Наименование получателя', cid: 'name' },
  { Header: 'ИНН отправителя или его аналог', cid: 'shipperInn' },
  { Header: 'Наименование отправителя', cid: 'shipperName' },
  {
    Header: 'Страна отправления',
    cid: 'shipperCountry',
    formater: val => {
      const country = countryCodes.find(({ title, type }) => type === val);
      return country ? country.title : '-';
    },
  },
  { Header: 'Дата первичного документа', cid: 'originalDocInfo.docDate' },
  { Header: 'Номер первичного документа', cid: 'originalDocInfo.docNumber' },
];
