import { createAction } from 'redux-actions';

export const subject = '[ActDocument]';

export const convert = createAction(`${subject} convert`);
export const convertCis = createAction(`${subject} convert cis`);
export const load = createAction(`${subject} load`);
export const error = createAction(`${subject} error`);
export const reset = createAction(`${subject} reset`);
export const getPackageCount = createAction(`${subject} get package count`);
export const fixProducts = createAction(`${subject} fix products`);
