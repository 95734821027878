import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ImportBasicInfo.styles';

const DocumentInfoBlock = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.docInfoWrapUZ}>
      <div>
        <div className={classes.documentInfoHeadingLabel}>{title}</div>
        <div className={classes.infoLineUZ}>{children}</div>
      </div>
    </div>
  );
};

DocumentInfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.elementType,
};

export default DocumentInfoBlock;
