import { takeEvery, put,  call } from 'redux-saga/effects';
import { get } from 'lodash';

import * as actions from './Receipt.actions';
import Api from '../../../../common_components/Api/Api';
import { Toast } from '../../../../utils/Toast';

function* mountSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/receipt/${payload}/info`,
    method: 'get',
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data } = success;
    const changedData = {
      ...data,
      products: data.products ? data.products.map(el=> {
        return ({
          ...el,
          sum: el.sum.includes('.') ? parseFloat(el.sum) : parseInt(el.sum),
          nds: parseInt(el.nds) / 100,
          count: parseInt(el.count)
        })
      }) : []
    }
    yield put(actions.loaded(changedData));
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
    yield put(actions.loaded());
  }
}

function* downloadJSONSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/receipt/${payload}/download`,
    method: 'get',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': null,
    },
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data, headers } = success;

    try {
      let type = 'xml';
      const disposition = headers['content-disposition'];
      if (disposition && ~disposition.indexOf('attachment')) {
        // eslint-disable-next-line
        const match = disposition.match(/filename\=.*?\.([^;\n ]+)/);
        if (match) type = match[1];
      }

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveOrOpenBlob(
          new Blob([new Uint8Array(data)], {
            type: 'text/plain;charset=utf-8;',
          }),
          `${payload}.${type}`,
        );
      } else {
        const elem = document.createElement('a');
        elem.setAttribute(
          'href',
          window.URL.createObjectURL(new Blob([new Uint8Array(data)])),
        );
        elem.setAttribute('download', `${payload}.${type}`);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    } catch (e) {
      console.log('downloadDocumentClicked error: ', e);
    }
  }

  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.downloadJSON, downloadJSONSaga);
}
