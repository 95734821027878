export const ROLES = {
  manageUsers: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: true,
          type: 'Просмотр'
        },
        {
          access: true,
          type: 'Заказ кодов'
        },
        {
          access: true,
          type: 'Аннулирование заказов'
        },
        {
          access: true,
          type: 'Завершение заказов'
        },
        {
          access: true,
          type: 'Списание кодов'
        }
      ]
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: true,
          type: 'Редактирование реквизитов заказов'
        },
        {
          access: true,
          type: 'Управление пользователям'
        },
        {
          access: true,
          type: 'Редактирование личного профиля'
        }
      ]
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: true,
          type: 'Продажа'
        },
        {
          access: true,
          type: 'Возврат'
        },
        {
          access: false,
          type: 'Вывод из оборота'
        }
      ]
    }
  ],
  participant_principal: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: true,
          type: 'Просмотр'
        },
        {
          access: true,
          type: 'Заказ кодов'
        },
        {
          access: true,
          type: 'Аннулирование заказов'
        },
        {
          access: true,
          type: 'Завершение заказов'
        },
        {
          access: true,
          type: 'Списание кодов'
        }
      ]
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: true,
          type: 'Редактирование реквизитов заказов'
        },
        {
          access: true,
          type: 'Управление пользователям'
        },
        {
          access: true,
          type: 'Редактирование личного профиля'
        }
      ]
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: true,
          type: 'Продажа'
        },
        {
          access: true,
          type: 'Возврат'
        },
        {
          access: true,
          type: 'Вывод из оборота'
        }
      ]
    }
  ],
  participant_employee: [
    {
      headline: 'Работа с кодами и заказами',
      list: [
        {
          access: false,
          type: 'Просмотр'
        },
        {
          access: true,
          type: 'Заказ кодов'
        },
        {
          access: false,
          type: 'Аннулирование заказов'
        },
        {
          access: true,
          type: 'Завершение заказов'
        },
        {
          access: true,
          type: 'Списание кодов'
        }
      ]
    },
    {
      headline: 'Настройки',
      list: [
        {
          access: false,
          type: 'Редактирование реквизитов заказов'
        },
        {
          access: true,
          type: 'Управление пользователям'
        },
        {
          access: false,
          type: 'Редактирование личного профиля'
        }
      ]
    },
    {
      headline: 'Работа с товарами',
      list: [
        {
          access: false,
          type: 'Продажа'
        },
        {
          access: true,
          type: 'Возврат'
        },
        {
          access: false,
          type: 'Вывод из оборота'
        }
      ]
    }
  ]
};