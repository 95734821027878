export default {
  title: 'Фильтры по умолчанию:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }
      ],
      data: [
        {
          'col1': 'Тип агрегации',
          'col2': 'Дата'
        },
        {
          'col1': 'Вариант задания периода',
          'col2': 'Месяц'
        },
        {
          'col1': 'Период',
          'col2': 'Текущий месяц'
        },
        {
          'col1': 'Вид отчёта',
          'col2': 'Сверка с ИС МПТ'
        },
      ]
    }
  ]
}
