import { uniq, uniqBy } from 'lodash';

export const cisKey = 'cis';
export const invalidCisKey = 'invalidCis';
export const goodsKey = 'products';

export const act_DTO = props => {
  const { formMutators, formValues, list, selectedRows, mode } = props;
  let productsLength = 0;
  if (mode === 'editedAct' && formValues.km) productsLength = formValues.km.length;
  if (mode !== 'editedAct' && formValues.products) productsLength = formValues.products.length;

  const result = selectedRows.map((item, idx) => {
    const find = list.find(v => item === v.id);
    return {
      ...find,
      idx: idx + productsLength + 1,
      cisPackageType: find.cisPackageType || '',
      name: find.productName,
      ownerName: find.ownerName,
      ownerInn: find.ownerInn,
      producerInn: find.producerInn,
      producerName: find.producerName,
    };
  });
  if (productsLength === 0) formMutators.updateField(mode === 'editedAct' ? 'km' : 'products', result);
  else
    formMutators.updateField(
      mode === 'editedAct' ? 'km' : 'products',
      mode === 'editedAct'
        ? formValues.km.concat(
          result.filter(
            item => !formValues.km.map(item => item.cis).includes(item.cis),
          ),
        )
        : formValues.products.concat(
        result.filter(
          item => !formValues.products.map(item => item.cis).includes(item.cis),
        ),
        ),
    );
};

export const notifications_DTO = props => {
  const { formMutators, formValues, list, selectedRows } = props;
  let cisLength = 0;
  if (formValues[cisKey]) cisLength = formValues[cisKey].length;
  const result = selectedRows.map((item, idx) => {
    const find = list.find(v => item === v.id);
    return {
      ...find,
      idx: idx + cisLength + 1,
      cisPackageType: find.cisPackageType || '',
    };
  });

  if (cisLength === 0) formMutators.updateField('products', result);
  else
    formMutators.updateField(
      'cis',
      formValues[cisKey].concat(
        result.filter(
          item => !formValues.cis.map(item => item.cis).includes(item.cis),
        ),
      ),
    );
};

export const thirdCountry_DTO = props => {
  const { formMutators, formValues, list, selectedRows } = props;

  const oldCis = formValues[cisKey] || [];

  const choice = selectedRows.map(item => {
    const find = list.find(v => item === v.id);
    return {
      ...find,
      cisPackageType: find.cisPackageType,
      name: find.productName,
      ownerName: find.ownerName,
      ownerInn: find.ownerInn,
      producerInn: find.producerInn,
      producerName: find.producerName,
    };
  });

  const resultCis = oldCis.concat(choice);

  formMutators.updateField(cisKey, resultCis);
};

export const exportNotificationToTheEaes_DTO = props => {
  const { formMutators, formValues, list, selectedRows } = props;
  const oldCis = formValues['products'] || [];
  const choice = selectedRows.map(item => {
    const find = list.find(v => item === v.id);
    return {
      ...find,
      cisPackageType: find.cisPackageType,
      name: find.productName,
      ownerName: find.ownerName,
      ownerInn: find.ownerInn,
      producerInn: find.producerInn,
      producerName: find.producerName,
    };
  });
  const resultCis = oldCis.concat(choice);
  formMutators.updateField(
    'products',
    resultCis
  );
};
