import moment from 'moment';
import { get, omit } from 'lodash';
import { authInstance } from './authInstance';
import { getPrefix } from '../utils';
import { TaxService } from '../components/ProductDetails/services/TaxService';

export class AltScenariosService {

  static getRoundGraphStats(params) {
    return authInstance.request({
      url: `/stat/get_cis`,
      method: 'GET',
      withCredentials: false,
      params
    })
  }

  static getTaxInfo(data) {
    return authInstance.request({
      method: 'POST',
      url: '/api/v3/tax/calc',
      data
    });
  }

  static getTypes() {
    return authInstance.request({
      url: '/private-office-api/private/v2/alt-scenario/types',
      method: 'get',
    });
  }

  static searchCases(data) {
    // return instance.request({
    //   url: '/private-office-api/private/v2/alt-scenario/search-cases',
    //   method: 'post',
    //   data,
    // });
    return Promise.resolve({
      data: {
        total: 1,
        isLast: true,
        results: [
          {
            cis: 'cis',
            cisStatus: 'cisStatus',
            gtin: 'gtin',
            mrp: 'mrp',
            productName: 'productName',
            brand: 'brand',
            producerInn: 'producerInn',
            producerName: 'producerName',
            documentDate: 0,
            receivedDocumentDate: 0,
            sellerInn: 'sellerInn',
            sellerName: 'sellerName',
            buyerInn: 'buyerInn',
            buyerName: 'buyerName',
          },
        ],
      },
    });
  }

  static getList(params) {
    return AltScenariosService.searchCis(params);
  }

  static searchCis(data) {
    const scenarioType = get(data, 'scenarioType', '');

    const dateFromISO = data.dateFrom
      ? moment(data.dateFrom).toISOString()
      : undefined;
    const dateToISO = data.dateTo
      ? moment(data.dateTo).toISOString()
      : undefined;

    const dateFromTimestamp = data.dateFrom ? data.dateFrom : undefined;
    const dateToTimestamp = data.dateTo ? data.dateTo : undefined;

    let date = {};

    // TODO: MOTP-5888.
    // Выяснить какому сценария, какая дата соответсвует.
    // Пока сделал для всех сценариев фильтрацию по emissionDate.
    switch(scenarioType) {
      case 'documentDate':
        date = {
          documentDateFrom: dateFromISO,
          documentDateTo: dateToISO,
        };
        break;
      case 'documentRecvDate':
        date = {
          documentRecvDateFrom: dateFromTimestamp,
          documentRecvDateTo: dateToTimestamp,
        };
        break
      default:
        date = {
          emissionDateFrom: dateFromTimestamp,
          emissionDateTo: dateToTimestamp,
        };
    }

    return authInstance.request({
      url: '/private-office-api/private/v2/alt-scenario/search-cis',
      method: 'post',
      data: {
        ...omit(data, ['dateFrom', 'dateTo']),
        ...date,
      },
    });
    // return Promise.resolve({
    //   data: {
    //     total: 1,
    //     isLast: true,
    //
    //     results: [
    //       {
    //         cis: 'cis',
    //         emissionDate: 0,
    //         gtin: 'gtin',
    //         mrp: 'mrp',
    //         productName: 'productName',
    //         brand: 'brand',
    //         producerInn: 'producerInn',
    //         producerName: 'producerName',
    //         ownerInn: 'ownerInn',
    //         ownerName: 'ownerName',
    //       },
    //     ],
    //   },
    // });
  }

  static getStatistics(data) {

    return authInstance.request({
      url: '/private-office-api/private/v2/alt-scenario/statistics',
      method: 'post',
      data,
    });
    
  }

  static getScheme(scenarioCode) {
    return authInstance.request({
      url: '/private-office-api/private/v2/alt-scenario/scheme',
      method: 'get',
      params: {
        scenarioCode
      }
    })
  }
}
