export const documentDownloadTheme = {
  paddingTop: '0px',
  paddingBottom: '8px',
  buttonBar: {
    button: {
      iconFill: '#55B5E7',
      fontSize: '16px',
      fontWeight: '400',
      boxShadow: '0 4px 7px rgba(0,0,0,0.13)',

      normal: {
        paddingLeft: '16px',
        paddingRight: '16px',
        height: '40px',
      },
      main: {
        border: '1px solid #FFFFFF',
        color: '#55B5E7',
        background: '#FFFFFF',
      },
      disabled: {
        border: '1px solid rgba(0,0,0,0)',
        color: '#FFFFFF',
        background: 'rgba(0,0,0,0)',
      },
      fontFamily: '\'SegoeUI\', sans-serif',
      minWidth: '108px',
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      marginRight: '8px',
      marginLeft: '8px',
    },
    bottom: '20px',
    marginRight: '8px',
    marginLeft: '8px',
  },
  filterPanel: {
    paddingTop: '29px',
    paddingBottom: '29px',
    paddingLeft: '24px',
    paddingRight: '24px',
    header: {
      fontSize: '24px',
      marginTop: '0px',
      marginBottom: '24px',
    },
  },
};
