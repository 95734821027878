import Preloader from '../../common_components/Preloader/Preloader';
import React, { Component, lazy, Suspense } from 'react';
import i18next from 'i18next';
const Grid = lazy(() => import('./Grid'));

class AsyncGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Suspense
        fallback={
          <Preloader isOpened={true} text={i18next.t('Загрузка таблицы')} />
        }
      >
        <Grid ref={this.props.gridRef} {...this.props} />
      </Suspense>
    );
  }
}

export { AsyncGrid as Grid };
