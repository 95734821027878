import React from 'react';

import {documentStatusBackgroundColorsMap, documentStatusColorsMap, documentStatusIconsMap, documentStatusValuesMap} from '../../constants';

import {useStyles} from './DocumentStatusInfo.styles';


/**
 *
 * @param status {string} Current document status ( period )
 */
export const DocumentStatusInfo = ({status}) => {
  const statusName = documentStatusValuesMap[status];
  const statusIcon = documentStatusIconsMap[status];
  const statusTextColor = documentStatusColorsMap[status];
  const backgroundColor = documentStatusBackgroundColorsMap[status];

  const styles = useStyles();

  return (
    <div style={{color: statusTextColor, backgroundColor: backgroundColor}} className={styles.statusWrapper}>
      <span className={styles.statusIcon}>
        {statusIcon}
      </span>
      <span className={styles.statusText}>
        {statusName}
      </span>
    </div>
  )
};