import { combineReducers } from 'redux';
import { get, isFunction, isObject, keys, set, unset } from 'lodash';
import router from './router';

import AltScenarios from './AltScenarios';
import Analytics from './Analytics';
import Auth from './Auth';
import LoginForm from './LoginForm';
import LoginFormKep from './LoginFormKep';
import CISList from './CISList';
import DocumentList from './DocumentList';
import ProductList from './ProductList';
import ParticipantList from './ParticipantList';
import DocumentDetails from './DocumentDetails';
import ProductDetails from './ProductDetails';
import ParticipantDetails from './ParticipantDetails';
import Profile from './Profile';
import Sidebar from './Sidebar';
import Registration from './Registration';
import OrderList from './OrderList';
import OrderForm from './OrderForm';
import App from './App';
import Form from './Form';
import ReportDetails from './ReportDetails';
import ReportList from './ReportList';
import Excise from './Excise';
import ExciseList from './ExciseList';
import Preloader from './Preloader';

const legacyReducers = {
  Preloader,
  AltScenarios,
  Analytics,
  Auth,
  App,
  LoginForm,
  LoginFormKep,
  CISList,
  DocumentList,
  ProductList,
  ParticipantList,
  DocumentDetails,
  ProductDetails,
  ParticipantDetails,
  Profile,
  Sidebar,
  Registration,
  OrderList,
  OrderForm,
  Form,
  ReportDetails,
  ReportList,
  Excise,
  ExciseList
};

const getReducers = (order, reducers, config) => {
  const reducersTree = {};
  const map = {};

  order.forEach(project => {
    const obj = reducers.find(r => r.id === project);

    obj.data.keys().forEach(f => {
      const reducer = obj.data(f).default;
      const temp = f.split('/');
      temp.shift();
      temp.pop();
      let key = temp.filter(k => k !== 'ducks').join('.');

      if (map[key] && map[key] !== obj.id) {
        const keyToDelete = `${map[key]}.${key}`;
        unset(reducersTree, keyToDelete);
      }

      map[key] = obj.id;

      if (obj.id) key = `${obj.id}.${key}`;
      const current = get(reducersTree, key, {});
      set(reducersTree, key, { ...reducer, ...current });
    });
  });

  function getMapped(obj, first = false) {
    const reducer = {};

    keys(obj).forEach((key) => {
      const newKey = key.replace(/^_/, '');
      if (isFunction(obj[key])) {
        reducer[newKey] = obj[key];
      } else if (isObject(obj[key])) {
        reducer[newKey] = getMapped(obj[key]);
      }
    });

    if (first) return reducer;
    return combineReducers({ ...reducer });
  }

  return combineReducers({
    ...getMapped(reducersTree, true),
    ...legacyReducers,
    router,
    order: () => order,
    config: () => config,
  });
};

export default getReducers;
