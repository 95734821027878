import { Icon } from '@crpt-ui/core';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const Introduced = ({ op, countryCode }) => {
  const title = useMemo(() => {
    switch (op.documentType) {
      case 'IMPORT_TRANSIT':
        return 'Ввоз из ЕАЭС';
      case 'IMPORT':
        if (countryCode === 'UZ') return 'Уведомление о ввозе (импорт)';
        return 'Уведомление о ввозе товаров (третьи страны)';
      case 'MTTPS_90':
        return 'Вывод из оборота';
      default:
        return 'Ввод в оборот';
    }
  }, [op]);

  return (
    <Layout>
      <FirstCell icon={<Icon name="CodeEmission" />} title={Translate(title)} />
      <SecondCell op={op} />
      <ThirdCell useLink op={op} heading={Translate('Документ')} />
    </Layout>
  );
};

const mapState = state => ({
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState))(Introduced);
