import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Button } from '@crpt-ui/core';
import { Box, Grid, withStyles } from '@material-ui/core';

import { Select } from '../../../../common_components/_refactor/Form/Fields';
import { formValidator } from './EditRoles.validations';
import { Translate } from '../../../../common_components/Translate/Translate';

const styles = {
  devider: {
    backgroundColor: '#F2F2F2',
    height: 1,
    width: '100%',
  },
};

const EditRoles = ({ classes, formValues, onClose, onSubmit, rolesSelect }) => {
  const initFormValues = useMemo(() => {
    const initValues = formValues ? formValues : {};
    return initValues;
  }, [formValues]);

  return (
    <Form
      initialValues={initFormValues}
      onSubmit={onSubmit}
      validate={formValidator(initFormValues)}
      subscription={{
        submitting: true,
        errors: true,
        values: true,
      }}
    >
      {({ handleSubmit, errors, values }) => {
        return (
          <React.Fragment>
            {/* <pre>values: {JSON.stringify(values, null, 2)}</pre>
            <pre>errors: {JSON.stringify(errors, null, 2)}</pre> */}

            <Select
              name="sharedRoles"
              placeholder={Translate("Доступно ролям")}
              options={rolesSelect}
              multiple
            />

            <Box mx={-4} my={2}>
              <div className={classes.devider} />
            </Box>

            <Box>
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <Button
                    disabled={!_.isEmpty(errors)}
                    onClick={handleSubmit}
                    width={250}
                  >
                    {Translate('Сохранить')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={onClose} variant="outlined" width={250}>
                    {Translate('Отменить')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

EditRoles.propTypes = {
  formValues: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rolesSelect: PropTypes.array.isRequired,
};

export default withStyles(styles)(EditRoles);
