import { createActions } from 'redux-actions';

/**
 * Page header actions
 */
export const { fetchElkCode, fetchSuzSignIn, fetchSignOut, fetchAuthenticateSun  } = createActions(
  {
    FETCH_ELK_CODE: () => {},
    FETCH_SUZ_SIGN_IN: () => {},
    FETCH_SIGN_OUT: payload => payload,
    FETCH_AUTHENTICATE_SUN: () => {},
  },
  {
    prefix: 'PAGE_HEADER',
  },
);
