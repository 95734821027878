export const userRoleTypes = {
  ADMIN: 'admin',
  PARTICIPANT_PRINCIPLE: 'participant_principal',
  PARTICIPANT_EMPLOYEE: 'participant_employee',
  UNDEFINED: 'undefined',
};

export const sortedUserTypes = [
  userRoleTypes.ADMIN,
  userRoleTypes.PARTICIPANT_PRINCIPLE,
  userRoleTypes.PARTICIPANT_EMPLOYEE,
  userRoleTypes.UNDEFINED
];