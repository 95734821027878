import { combineReducers } from 'redux';
import { company } from './company';
import { currentUser } from './currentUser';
import { removedUsers } from './removedUsers';
import { activeUsers } from './activeUsers';
import { cert } from './cert';
import { certUploadedMessage } from './certUploadedMessage';
import { isEdited } from './isEdited';
import { callbackModal } from './callbackModal';
import { isModalOpen } from './isModalOpen';
import { uploadedImage } from './uploadedImage';
import { certificates } from './certificates';
import { error } from './error';

export default combineReducers({
  company,
  currentUser,
  activeUsers,
  removedUsers,
  cert,
  certUploadedMessage,
  isEdited,
  isModalOpen,
  callbackModal,
  uploadedImage,
  certificates,
  error
});
