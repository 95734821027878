import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import * as grayAreaUsersActions from '../../GrayAreaAdditionalInfo/GrayAreaUsers/ducks/GrayAreaUsers.actions';
import * as grayAreaUsersSelectors from '../../GrayAreaAdditionalInfo/GrayAreaUsers/ducks/GrayAreaUsers.selectors';
import { actionTypes } from '../GrayAreaCreate.constants';
import { InformationHelper } from '../helpers';
import InformationView from './Information.view';

const Information = ({
  dispatch,
  loaded,
  onUnmount,
  requestUsers,
  users,
}) => {
  const informationHelper = new InformationHelper();

  useEffect(() => {
    requestUsers();
    return () => onUnmount;
  }, []);

  const initFormValues = useMemo(() => ({
    information: {
      fromTime: {
        hours:'00',
        minutes:'00',
        seconds: '00'
      },
      toTime: {
        hours:'23',
        minutes:'59',
        seconds: '59'
      },
      noticeList: [],
    }
  }), []);

  const [isTogglePopupAutocomplete, setTogglePopupAutocomplete] = useState(
    true,
  );

  const [prevErrors, setPrevErrors] = useState(
    false,
  );

  const maxDate = useMemo(() => moment().subtract(1, 'day').toDate(), []);
  const minDate = useMemo(() => new Date('2018-01-02'), []);

  const onInformationChange = useCallback(hasValidationErrors => values => {
    dispatch({
      type: actionTypes.INFORMATION.UPDATE,
      payload: values,
    });

    if (prevErrors !== hasValidationErrors) {
      dispatch({
        type: actionTypes.INFORMATION.SET_ERRORS,
        payload: hasValidationErrors,
      });
      setPrevErrors(hasValidationErrors)
    }
  }, [prevErrors]);

  return (
    <InformationView
      initFormValues={initFormValues}
      informationHelper={informationHelper}
      isTogglePopupAutocomplete={isTogglePopupAutocomplete}
      loaded={loaded}
      maxDate={maxDate}
      minDate={minDate}
      onInformationChange={onInformationChange}
      setTogglePopupAutocomplete={setTogglePopupAutocomplete}
      users={users}
    />
  )
}

const mapStateToProps = state => ({
  users: grayAreaUsersSelectors.result(state),
  loaded: grayAreaUsersSelectors.loaded(state),
});

const mapDispatchToProps = {
  requestUsers: grayAreaUsersActions.requestUsers,
  onUnmount: grayAreaUsersActions.clear,
};

Information.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  onUnmount: PropTypes.func.isRequired,
  requestUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
