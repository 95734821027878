import { selector } from '../../../../../utils/selector';

export const data = state =>
  selector(state, 'Requests.RequestList.EaesImportDocument.data');
export const loaded = state =>
  selector(state, 'Requests.RequestList.EaesImportDocument.loaded');
export const errorInfo = state =>
  selector(state, 'Requests.RequestList.EaesImportDocument.errorInfo');
export const correctData = state =>
  selector(state, 'Requests.RequestList.EaesImportDocument.correctData');
