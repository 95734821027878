import { gridFilterTheme } from '../../../theme/ru-tabaco/select/gridFilter';

const React = require('react');
import PropTypes from 'prop-types';
import CISPackageSelect from '../../Select/CISTrivialPackageSelect';
import { Wrap, SelectWrap } from '../Grid.styled';
import { Translate } from '../../../common_components/Translate/Translate';

class PackageFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    appliedFilter: PropTypes.object, // filterTerm, operator, filterValue
    close: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].filterTerm
          : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    };
  }

  handleFilterChange = val => {
    console.log('handleFilterChange val', val);

    if (val) {
      this.setState({ value: val.cisPackageType, title: val.title });
    } else {
      this.setState({ value: '', title: '' });
    }
  };

  reset = () => {
    this.setState({ value: '' });
    this.props.onFilterChange('', this.state.operator);
  };

  applyFilter = () => {
    //third parameter is filterValue object, need for restored complex filters
    this.props.onFilterChange([
      {
        filterTerm: this.state.value,
        operator: this.state.operator,
        filterValue: {
          title: this.state.title,
          value: this.state.value,
        },
      },
    ]);
  };

  setFilter = filter => {
    this.setState({
      value: filter && filter.length > 0 ? filter[0].filterTerm : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    });
  };

  render() {
    return (
      <Wrap>
        <SelectWrap>
          <CISPackageSelect
            withoutIcon
            truncate
            theme={{ Select: gridFilterTheme }}
            selectedPackageType={
              this.state.value.length !== '' ? this.state.value : undefined
            }
            onSelect={val => this.handleFilterChange(val)}
          />
        </SelectWrap>
        <button
          type="button"
          onClick={this.reset}
          className="filter-btn__cancel"
        >
          {Translate('Сбросить')}
        </button>
        <button
          type="button"
          onClick={this.applyFilter}
          disabled={this.state.value === ''}
          className="filter-btn__apply"
        >
          {Translate('Применить')}
        </button>
      </Wrap>
    );
  }
}

export default PackageFilter;
