import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { withRouter, Switch, Route } from 'react-router-dom';
import { InlineWrap, Layout, Footer } from '@crpt/react-page-layout';

import SidebarBound from '../../containers/Bounds/SidebarBound';
import HeaderLogoWithText from '../../svg/header-logo.svg';
import LogoWithText from '../../svg/logo.svg';
import SidebarContentBound from '../../containers/Bounds/SidebarContentBound';

import Certificate from './Steps/Certificate';
import Complete from './Steps/Complete';
import Contacts from './Steps/Contacts';
import Data from './Steps/Data';
import Wizard from './Wizard';
import {
  EnterWrap,
  FormWrap,
  IconWrap,
  Inner,
  Outer,
  FooterLinks,
  FooterText,
  FooterLink,
  AlreadyRegisteredInfo,
  StyledLink,
  StyledA
} from './styled';
import { strings } from '../../constants';
import { RegistrationSteps } from './constants';
import { certVisible } from '../../reducers/Registration/certVisible';
import LoginFormSidebarContent from '../LoginFormSidebarContent';
import { certEditable } from '../../reducers/Registration/certEditable';

class Registration extends Component {
  static propTypes = {
    App: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({
      Registration: PropTypes.shape({
        registrationMounted: PropTypes.func,
        onRegisterClick: PropTypes.func,
      }),
    }).isRequired,
    Registration: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.Registration.registrationMounted();

    if (location.hostname === 'ismotp.crptech.ru') {
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      fbq('init', '985743444847074');
      fbq('track', 'PageView');

      !(function() {
        var t = document.createElement('script');
        (t.type = 'text/javascript'),
          (t.async = !0),
          (t.src = 'https://vk.com/js/api/openapi.js?160'),
          (t.onload = function() {
            VK.Retargeting.Init('VK-RTRG-341785-b5GTO'), VK.Retargeting.Hit();
          }),
          document.head.appendChild(t);
      })();

      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({ id: '3086702', type: 'pageView', start: new Date().getTime() });
      (function(d, w, id) {
        if (d.getElementById(id)) return;
        var ts = d.createElement('script');
        ts.type = 'text/javascript';
        ts.async = true;
        ts.id = id;
        ts.src = 'https://top-fwz1.mail.ru/js/code.js';
        var f = function() {
          var s = d.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(ts, s);
        };
        if (w.opera == '[object Opera]') {
          d.addEventListener('DOMContentLoaded', f, false);
        } else {
          f();
        }
      })(document, window, 'topmailru-code');
    }


  }

  onSubmit = (values, setStep) => {
    const { actions } = this.props;
    const { selectedCert, ...other } = values;

    actions.Registration.onRegisterClick(
      { selectedCert, user: other },
      setStep
    );
  };

  openSidebar = () => {
    const sidebarTheme = Object.assign({
      panel: {
        width: '546px',
        backgroundColor: '#FFFFFF',
        location: 'right',
      },
    });
    const content = <SidebarContentBound component={LoginFormSidebarContent} />;
    this.props.actions.Sidebar.sidebarOpen(content, sidebarTheme);
  };

  render() {
    const { App, Registration: data, actions } = this.props;
    const {
      step,
      dataVisible,
      contactsVisible,
      certVisible,
      certEditable,
      contactsEditable,
    } = data;
    const {
      toggleCert,
      toggleContacts,
      toggleData,
      showContacts,
      showData,
      toggleCertEdit,
      toggleContactsEdit,
    } = actions.Registration;

    return (
      <ThemeProvider theme={App.config.Registration.theme}>
        <Layout>
          <Outer>
            <Inner>
              <SidebarBound />
              <InlineWrap
                flex
                height="394px"
                width="100%"
                background="#F8EC31"
                justifyContent="center"
              >
                <IconWrap>
                  <HeaderLogoWithText />
                  <LogoWithText />
                </IconWrap>
              </InlineWrap>

              <FormWrap>
                <InlineWrap flex justifyContent="center" width="618px">
                  <InlineWrap
                    flex
                    flexDirection="column"
                    alignItems="stretch"
                    width="100%"
                  >
                    <EnterWrap>Регистрация</EnterWrap>
                    <Wizard
                      onSubmit={this.onSubmit}
                      isCompleted={step === RegistrationSteps.COMPLETE}
                    >
                      <Certificate
                        openSidebar={this.openSidebar}
                        certVisible={certVisible}
                        certEditable={certEditable}
                        toggleCert={toggleCert}
                        toggleCertEdit={toggleCertEdit}
                        showContacts={showContacts}
                      />
                      <Contacts
                        contactsEditable={contactsEditable}
                        toggleContactsEdit={toggleContactsEdit}
                        contactsVisible={contactsVisible}
                        toggleContacts={toggleContacts}
                        showData={showData}
                      />
                      <Data dataVisible={dataVisible} toggleData={toggleData} />
                      <Complete />
                    </Wizard>
                  </InlineWrap>
                </InlineWrap>

                <AlreadyRegisteredInfo>
                  <div>Уже зарегистрированы?</div>
                  <div>
                    <StyledLink to="/login-kep">Войдите</StyledLink> или узнайте
                    больше{' '}
                    <StyledA href="https://честныйзнак.рф/business/projects/tobacco/#about-experiment" target="_blank">на сайте системы маркировки</StyledA>.
                  </div>
                </AlreadyRegisteredInfo>

                <FooterLinks>
                  <FooterText>
                    При возникновении проблем с регистрацией или авторизацией
                    направьте письмо на{' '}
                    <FooterLink href={'mailto:support@crpt.ru'}>
                      support@crpt.ru
                    </FooterLink>
                  </FooterText>
                  {/* <FooterText>
                    <FooterLink
                      href={
                        '/docs/Часто_задаваемые_вопросы.pdf'
                      }
                    >
                      Часто задаваемые вопросы
                    </FooterLink>
                  </FooterText> */}
                </FooterLinks>
              </FormWrap>
            </Inner>
          </Outer>
        </Layout>
      </ThemeProvider>
    );
  }
}

export default withRouter(Registration);
