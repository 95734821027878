import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { isArray, isFunction, isUndefined, isNull } from 'lodash';

const displayNone = { display: 'none' };


class FieldsWrapper extends React.Component {
  static propTypes = {
    formValues: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    formDisabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.func.isRequired]),
    name: PropTypes.string.isRequired,
    parse: PropTypes.func,
    format: PropTypes.func,
    validate: PropTypes.func,
    visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  };

  validateValue = {}
  parseValue = undefined

  static defaultProps = {
    formValues: undefined,
    formErrors: undefined,
    formMutators: undefined,
    formDisabled: false,
    parse: undefined,
    format: undefined,
    validate: undefined,
    visibility: undefined,
    disabled: undefined,
  };

  parse = (value) => {
    /**
     * следующий две строки предохраняет от двойного срабатывания
     * т.к. иногда parse на беке и происходит два запроса
     * мы берем первое срабатывание а второе возвращает value
     */
    if(this.parseValue === value) return value
    this.parseValue = value

    const { parse, name, formMutators, formValues } = this.props;
    return parse ? parse(value, formMutators, name, formValues) : value;
  };

  validate = (...args) => {
    const { validate, name } = this.props;
    return validate ? validate(...args, name) : undefined;
  };

  format = (value) => {
    const { format, name } = this.props;
    return format ? format(value, name) : value;
  };

  visibility = () => {
    const { visibility, name, formValues } = this.props;
    if (!visibility) return true;
    if (isArray(visibility)) {
      return visibility.reduce(
        (result, fn) => fn(formValues, name) && result,
        true,
      );
    }

    return visibility(formValues, name);
  };

  disabled = () => {
    const { disabled, name, formValues, formErrors, formDisabled } = this.props;
    if (isFunction(disabled)) {
      return disabled(name, formValues, formErrors, formDisabled);
    }

    return !isUndefined(disabled) ? disabled : formDisabled;
  };

  renderConsumer = ({ input, meta }) => {
    const { children, formValues, formErrors, formMutators } = this.props;

    const params = {
      fieldInput: input,
      fieldMeta: meta,
      formValues,
      formErrors,
      formMutators,
      disabled: this.disabled(),
    };

    if (isFunction(children)) return children(params);
    return React.cloneElement(children, params);
  };

  render() {
    const { name, validate } = this.props;
    const visibility = this.visibility();

    if (visibility === false) return (null);

    if (isNull(visibility)) {
      return (
        <div style={displayNone}>
          <Field name={name} parse={this.parse} validate={validate ? this.validate : undefined} format={this.format}>
            {this.renderConsumer}
          </Field>
        </div>
      );
    }

    return (
      <Field name={name} parse={this.parse} validate={validate ? this.validate : undefined} format={this.format}>
        {this.renderConsumer}
      </Field>
    );
  }
}

export default FieldsWrapper;
