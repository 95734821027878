import get from 'lodash/get';
import { call, takeEvery, takeLatest } from 'redux-saga/effects';

import { Toast } from '../../../utils/Toast';
import { getToken } from '../../../utils/userUtils';
import Api from '../../Api/Api';
import * as actions from './PageHeader.actions';
import { logoutUser } from '../../Auth/Auth.utils';

function* fetchElkCode() {
  const token = yield call(getToken);

  const requestElkCode = {
    url: window.env.REACT_APP_NATIONAL_CATALOG_URL + '/rest/elk/auth',
    method: 'POST',
    data: {
      token,
    },
  };

  const [success, error] = yield call(Api.request, requestElkCode, {
    preloading: false,
  });

  const elkCode = get(success, 'data.elkCode', '');
  if (elkCode) {
    const href =
      window.env.REACT_APP_NATIONAL_CATALOG_URL + `/profile?elkCode=${elkCode}`;
    window.open(href, '_self');
  }

  if (error) {
    const message = get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }
}

function* fetchSuzSignIn() {
  const token = yield call(getToken);

  const requestSuzCode = {
    url: `${window.env.REACT_APP_SUZ_URL}/signin`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      token,
    },
    type: 'suz'
  };

  const [success, error] = yield call(Api.request, requestSuzCode, {
    preloading: false,
  });

  if (success) {
    window.open(success.data);
  }

  if (error) {
    const message = get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }
}

function* fetchSignOut() {
  const requestParams = {
    url: `/api/v3/facade/auth/logout`,
    method: 'GET',
  };
  const [success, error] = yield call(Api.request, requestParams, {
    preloading: false,
  });
  if (success) {
    logoutUser();
  }
  if (error) {
    const message = get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }
}

function* fetchAuthenticateSunSaga() {
  const requestPayload = {
    url: `${window.env.REACT_APP_SUN_FRONTEND_URL}/api/v1/security/mpt/authenticate`,
    method: 'post',
  };
  const [success] = yield call(Api.request, requestPayload);
  if (success) {
    window.open(`${window.env.REACT_APP_SUN_FRONTEND_URL}/contraventions`, "_blank")
  }
}


export default function* watch() {
  yield takeLatest(actions.fetchElkCode, fetchElkCode);
  yield takeLatest(actions.fetchSignOut, fetchSignOut);
  yield takeEvery(actions.fetchSuzSignIn, fetchSuzSignIn);
  yield takeEvery(actions.fetchAuthenticateSun, fetchAuthenticateSunSaga);
}
