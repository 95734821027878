import React from 'react';
import PropTypes from 'prop-types';
import { ActionWrapper, Group, Wrapper } from './ActionBar.styled';


const ActionBar = ({ left, right }) => (
  <Wrapper data-test="component-action-bar">
    <Group>
      {left.map((item, index) => (
        <ActionWrapper key={index}>
          {item}
        </ActionWrapper>
      ))}
    </Group>
    <Group>
      {right.map((item, index) => (
        <ActionWrapper key={index}>
          {item}
        </ActionWrapper>
      ))}
    </Group>
  </Wrapper>
);

ActionBar.propTypes = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
};

ActionBar.defaultProps = {
  left: [],
  right: [],
};

export default ActionBar;
