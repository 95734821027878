import React from 'react';
import Grid from '@material-ui/core/Grid';
import { StepTitle } from '../Document/Document.styled';
import { Translate } from '../../../../common_components/Translate/Translate';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import {
  isValidInn,
  required,
  validateRequiredAndMaxMinLength,
} from '../../../../common_components/Form/utils/validations';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import { countryCodes } from '../../../../constants';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDate } from '../ImportDocument/ImportDocument.validations';
import { commodityGroup } from './ExportNotificationToTheEaes.constants';
import { Plug } from './ExportNotificationToTheEaes.utls';


export const ExportNotificationToTheEaesFields = (props) => {
  const { formValues, formMutators } = props;
  const countrySelectOptions = countryCodes
    .map(c => ({ label: c.title, value: c.type }))
    .filter(({ value }) => ['RU']
      .includes(value));

  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <StepTitle>{Translate('Общие данные')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Input
            name="sender_inn"
            placeholder={Translate('ИНН/БИН отправителя')}
            disabled
            validate={required}
          />
          <Input
            forceValidate
            name="recipient_inn"
            type="number"
            required
            placeholder={Translate('ИНН получателя (или его аналог)')}
            validate={isValidInn(formValues.sender_country)}
          />
          <Select
            placeholder={Translate('Страна получателя') }
            name="sender_country"
            options={countrySelectOptions}
            forceValidate
            required
            validate={required}
            onSelect={() => {
              setTimeout(() => {
                formMutators.updateField('recipient_inn', formValues.recipient_inn);
              }, 100);
            }}
          />
          <Input
            name="document_number"
            forceValidate
            format={(value)=> value ? value.replace(/[^\w\s|-]/g, "") : value}
            placeholder={Translate('Номер первичного документа')}
            validate={validateRequiredAndMaxMinLength(128)}
            required
          />
          <Select
            placeholder={Translate('Товарная группа')}
            name="commodityGroup"
            options={commodityGroup}
            forceValidate
            required
            validate={required}
            onSelect={() => {}}
          />
        </Grid>
        <Grid item xs={5}>
          <Input
            forceValidate
            name="sender_name"
            placeholder={Translate('Наименование отправителя')}
            disabled
            validate={required}
          />
          <Input
            name="recipient_name"
            required
            forceValidate
            placeholder={Translate('Наименование получателя')}
            validate={required}
          />
          <Plug />
          <DatePicker
            required={true}
            forceValidate
            name="dateOfOriginalDocument"
            placeholder={Translate('Дата первичного документа')}
            maxDate={new Date()}
            validate={(...vals) => {
              return validateDate(...vals, "DD.MM.YYYY");
            }}
          />
          <DatePicker
            required={true}
            forceValidate
            name="actualShipmentDate"
            placeholder={Translate('Дата фактической отгрузки товара')}
            maxDate={new Date()}
            validate={(...vals) => {
              return validateDate(...vals, "DD.MM.YYYY");
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
