import React from 'react';
import { CodeColumns } from 'src/fragments/Table/Cell/Codes/columns.constants';
import { GoodsColumns } from '../../../../../fragments/Table/Cell/Goods/columns.constants';
import { Value } from '../../../../../fragments/Table/Cell/value';
import { makeInputFilter } from '@crpt-ui/datatable';
import { Translate } from 'src/common_components/Translate/Translate';
import i18next from 'i18next';

export const columns = [
  {
    ...CodeColumns.producedDate,
    disableFilters: true,
  },
  {
    ...CodeColumns.status,
    disableFilters: true,
  },
  {
    ...CodeColumns.cis,
    disableFilters: true,
  },
  {
    ...CodeColumns.type,
    disableFilters: true,
  },
  {
    ...CodeColumns.productName,
    disableFilters: true,
  },
  {
    ...CodeColumns.producerName,
    disableFilters: true,
  },
  {
    ...CodeColumns.ownerName,
    disableFilters: true,
  },
];

export const totalColumns = [
  {
    ...GoodsColumns.gtin,
    disableFilters: true,
  },
  {
    ...GoodsColumns.name,
    disableFilters: true,
    Header: Translate('Наименование товара'),
  },
  {
    id: 'gtinsCounts',
    accessor: 'gtinsCounts',
    Header: Translate('Итого потребительских упаковок'),
    Filter: makeInputFilter({
      label: i18next.t('Итого потребительских упаковок'),
    }),
    width: 'auto',
  },
];
