import React, { useMemo } from 'react';
import { ActionButton, Actions, LeftActions, RightActions } from '../ReconciliationDialog.styled';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { APP_LINKS } from '../../../../../../constants';

const ReconciliationActions = ({ onClose, isLoading, createDraft, createDiscrepancyDraft, list }) => {

  const isDisabled = useMemo(() => list.filter(
    (item) => item.reconciliationStatus !== 'NOT_FOUND').length === 0
    , [list]);

  return (
    <Actions>
      <LeftActions>
        <ActionButton
          onClick={onClose}
          disabled={isLoading}
        >
          Закрыть без сохранения
        </ActionButton>
        <ActionButton
          onClick={createDraft}
          disabled={isLoading || isDisabled}
        >
          Сохранить как черновик
        </ActionButton>
      </LeftActions>
      <RightActions>
        <ActionButton
          onClick={createDiscrepancyDraft}
          disabled={isLoading || isDisabled}
        >
          Создать уведомление
        </ActionButton>
      </RightActions>
    </Actions>
  )
};

export default compose(
  withRouter,
)(ReconciliationActions)
