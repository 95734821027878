export const defaultTheme = {
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: '"SegoeUI", sans-serif',
  large: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '48px'
  },
  normal: {
    paddingLeft: '19px',
    paddingRight: '19px',
    height: '40px'
  },
  main: {
    border: '0',
    color: '#52535A',
    background: '#F8EC31',
    boxShadow:
      '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1)'
  },
  disabled: {
    border: '0',
    color: '#FFFFFF',
    background: 'linear-gradient(0deg, #C4C4C4, #C4C4C4);'
  },
  minWidth: '108px',
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px'
};
