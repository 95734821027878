import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys } from 'recompact';
import { SingleSelect, Button } from '../../Fields';
import CertOption from './CertOption';
import CertValue from './CertValue';
import { required } from '../../utils/validations';
import {
  Card,
  ContentWrap,
  Title,
  Wrap,
  SidebarInfo,
  CertPreview,
  CertCompany,
  CertName,
} from '../styled';
import { Icon } from '@crpt/react-icon';
import { RegistrationSteps } from '../../constants';
import {buttonWhiteTheme} from '../../../../theme/ru-tabaco/button';

import { InlineWrap } from '@crpt/react-page-layout';

const CurrentStep = ({
  certs,
  canNext,
  onNext,
  selectedCert,
  certVisible,
  certEditable,
  step,
  toggleCert,
  showContacts,
  onUpdateCert,
  toggleCertEdit,
  openSidebar,
}) => {

  console.log('certEditable', certEditable);

  return (
    <Wrap>
      <Card>
        <Title
          onClick={e => {
            toggleCert();
          }}
        >
          Электронная подпись{' '}
          <Icon
            spacingLeft={12}
            type={certVisible ? 'up-arrow' : 'down-arrow'}
          />
        </Title>
        <ContentWrap hidden={!certVisible}>
          {certEditable ? (
            <SingleSelect
              name="selectedCert"
              placeholder="Выберите электронную подпись"
              savePlaceholder={!selectedCert}
              renderOption={CertOption}
              renderValue={CertValue}
              values={certs}
              selectedId={selectedCert ? selectedCert.id : undefined}
              validate={required}
              onSelect={val => onUpdateCert(val)}
              noValuesText="Сертификат УКЭП не найден либо не настроен плагин КриптоПро"
            />
          ) : (
            <div>
              <CertPreview>
                <CertCompany>{selectedCert.source}</CertCompany>
                <CertName>{selectedCert.name}</CertName>
              </CertPreview>
              <Button theme={{Button: buttonWhiteTheme}} onClick={e => {
                toggleCertEdit();
              }}>Изменить</Button>
            </div>
          )}
        </ContentWrap>
        {certEditable &&
          certVisible && (step === RegistrationSteps.CERTIFICATE ? (
            <InlineWrap flex alignItems={'center'}>
              <Button
                disabled={!canNext}
                onClick={e => {
                  console.log('onClick triggered');
                  onNext();
                  console.log('after onNext');
                  toggleCert();
                  showContacts();
                }}
              >
                {'Продолжить'}
              </Button>
              <SidebarInfo onClick={openSidebar}>Как войти по КЭП?</SidebarInfo>
            </InlineWrap>
          ) : (
          <InlineWrap flex alignItems={'center'}>
            <Button
              disabled={!canNext}
              onClick={e => {
                toggleCert();
                toggleCertEdit();
              }}
            >
              {'Сохранить'}
            </Button>
          </InlineWrap>
        ))}
      </Card>
    </Wrap>
  );
};

CurrentStep.propTypes = {
  certs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCert: PropTypes.shape({}),
  canNext: PropTypes.bool.isRequired,
  certVisible: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  step: PropTypes.number,
};

CurrentStep.defaultProps = {
  selectedCert: null,
};

export default onlyUpdateForKeys(['certs', 'canNext', 'step', 'certVisible', 'certEditable'])(
  CurrentStep
);
