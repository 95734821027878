export const EMITTED = 'EMITTED';
export const APPLIED = 'APPLIED';
export const INTRODUCED = 'INTRODUCED';
export const WRITTEN_OFF = 'WRITTEN_OFF';
export const WITHDRAWN = 'WITHDRAWN';
export const UNDEFINED = 'UNDEFINED';
export const INTRODUCED_RETURNED = 'INTRODUCED_RETURNED';
export const DISAGGREGATED = 'DISAGGREGATED';
export const WAIT_SHIPMENT = 'WAIT_SHIPMENT';
export const EXPORTED = 'EXPORTED';
export const APPLIED_NOT_PAID = 'APPLIED_NOT_PAID';

export const CIS_STATUS_NAMES = {
  [EMITTED]: 'Эмитирован',
  [APPLIED]: 'Нанесен',
  [INTRODUCED]: 'В обороте',
  [WRITTEN_OFF]: 'Списан',
  [WITHDRAWN]: 'Выведен из оборота, продан',
  [UNDEFINED]: 'Не определен',
  [INTRODUCED_RETURNED]: 'В обороте, возвращен',
  [DISAGGREGATED]: 'Дезагрегирован',
  [WAIT_SHIPMENT]: 'Ожидает отгрузки',
  [EXPORTED]: 'Экспортирован',
  [APPLIED_NOT_PAID]: 'Нанесён, не оплачен'
};

export const cisStatuses = {
  [UNDEFINED]: CIS_STATUS_NAMES[UNDEFINED],
  [INTRODUCED_RETURNED]: CIS_STATUS_NAMES[INTRODUCED_RETURNED],
  [WITHDRAWN]: CIS_STATUS_NAMES[WITHDRAWN],
  [DISAGGREGATED]: CIS_STATUS_NAMES[DISAGGREGATED],
  [WRITTEN_OFF]: CIS_STATUS_NAMES[WRITTEN_OFF],
  [WAIT_SHIPMENT]: CIS_STATUS_NAMES[WAIT_SHIPMENT],
  [EMITTED]: CIS_STATUS_NAMES[EMITTED],
  [APPLIED]: CIS_STATUS_NAMES[APPLIED],
  [INTRODUCED]: CIS_STATUS_NAMES[INTRODUCED],
  [EXPORTED]: CIS_STATUS_NAMES[EXPORTED],
  [APPLIED_NOT_PAID]: CIS_STATUS_NAMES[APPLIED_NOT_PAID],
  //OLD
  // RECYCLED: 'КМ нанесен на товар, ожидает подтверждения ФТС',
  // RETIRED: 'КМ выбыл',
  // APPLIED_BAN: 'КМ нанесен на товар (запрет ФТС)',
  // IN_CIRCULATION: 'Товар в обороте',
  // WITHDRAW: 'Продан в розницу',
  // OWNER_CHANGE: 'Смена собственника',
  // CORRECTION: 'Корректировка',
  // FIX: 'Исправление',
  // AGGREGATION: 'Агрегирован',
  // DISAGGREGATION: 'Дезагрегирован',
  // ERROR: 'Нарушение',
};

export const CIS_STATUSES = {
  [EMITTED]: 0,
  [APPLIED]: 1,
  [INTRODUCED]: 2,
  [WRITTEN_OFF]: 3,
  [WITHDRAWN]: 4,
  // [UNDEFINED]: 5,
  [INTRODUCED_RETURNED]: 6,
  [DISAGGREGATED]: 7,
  // [WAIT_SHIPMENT]: 8,
  // [EXPORTED]: 9,
  [APPLIED_NOT_PAID]: 12
};

export const CIS_STATUSES_TO_CONSTANTS = {
  ...Object.keys(CIS_STATUSES).reduce((obj, current) => {
    obj[CIS_STATUSES[current]] = current;
    return obj;
  }, {})
};
