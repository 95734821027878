import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  imageWrap: {
    width: '128px',
    minWidth: '128px',
    height: '128px',
    backgroundColor: '#ffffff',
    border: `solid 1px ${theme.palette.border.secondary}`,
    boxSizing: 'border-box',
    marginLeft: '32px',
    borderRadius: '50%',
  },
  docInfoWrap: {
    display: 'flex',
    marginBottom: '32px',
  },
  docInfoWrapUZ: {
    padding: '32px',
  },
  documentInfoHeadingLabel: {
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: theme.palette.text.documentHeadingLabel,
    marginBottom: '16px',
  },
  infoLine: {
    display: 'flex',
    marginBottom: '16px',
  },
  infoLineUZ: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:last-child)': {
      marginBottom: '10px'
    },
    '& p': {
      margin: 5
    }
  },
  afterLarge: {
    width: '100%',
    height: '15px',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '700px',
    background: '#D61515',
    opacity: '0.5',
    borderRadius: '3px',
    color: '#fff',
    padding: '10px 20px'
  },
  errorMessage: {
    paddingLeft: '12px',
  },
}));
