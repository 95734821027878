import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@crpt/react-icon';
import { CheckboxWrap } from './SelectionCheckbox.styled';
import { checkboxState } from './SelectionCheckbox';
import { countryCodeFlags } from '../../../constants';

const zIndex = { zIndex: 1 };
const SelectCheckboxView = ({ onClick, state }) => {
  const { disabled, status } = state;
  let fillColor = '#52535A';
  if (status === checkboxState.SELECTED) fillColor = '#fff';
  if (status === checkboxState.EDITED) fillColor = '#52535A';
  if (disabled) fillColor = '#b9b9b9';

  return (
    <CheckboxWrap onClick={!disabled && onClick} state={status}>
      {status === checkboxState.NORMAL && (<Icon type="checkbox-empty" size={16} fill={fillColor}/>)}
      {status === checkboxState.SELECTED && (
        <Icon
          type="checkmark"
          size={16}
          fill={fillColor}
          style={{ background: countryCodeFlags.isUZ ? '#67ac5c': countryCodeFlags.isKZ ? '#0287B0' : '#057EC6'}}
        />
      )}
      {status === checkboxState.EDITED && (<Icon type="checkmark" style={zIndex} size={21} fill={fillColor}/>)}
    </CheckboxWrap>
  );
};
SelectCheckboxView.propTypes = {
  onClick: PropTypes.func.isRequired,
  state: PropTypes.shape({}).isRequired,
};
export default SelectCheckboxView;
