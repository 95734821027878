import { createAction } from 'redux-actions';

export const subject = '[CADES]';

export const load = createAction(`${subject} load`);
export const loaded = createAction(`${subject} loaded`);
export const select = createAction(`${subject} select`);
export const prepared = createAction(`${subject} prepared`);

export const sign = createAction(
  `${subject} sign`,
  payload => payload,
  (_, meta) => meta,
);

export const signByteArray = createAction(
  `${subject} sign byteArray`,
  payload => payload,
  (_, meta) => meta,
);

export const signed = createAction(`${subject} signed`);
export const signedByteArray = createAction(`${subject} signed byteArray`);
export const error = createAction(`${subject} sign error`);

export const loadCert = createAction(`${subject} load cert`);
export const loadedCert = createAction(
  `${subject} cert loaded`,
  payload => payload,
  (_, meta) => meta,
);
export const errorCert = createAction(`${subject} cert error load`);
export const clearCert = createAction(
  `${subject} clear cert`,
  payload => payload,
  (_, meta) => meta,
);
