import { actionTypes } from '../constants/';
import { DocumentService } from '../services/DocumentService';
import { ParticipantService } from '../services/ParticipantService';
import { CISService } from '../services/CISService';
import { FormSerializer } from '../utils/FormSerializer';
import {showAnyError} from '../utils/requestErrorHandlers';
import * as preloaderActions from '../common_components/Preloader/ducks/Preloader.actions';

function DocumentDetailsMounted(id) {
  return dispatch => {

    dispatch(preloaderActions.start());

    DocumentService.getDocumentDetails(id).then(async answer => {

      const doc = answer.data;

      try {
        if (doc.senderInn) {
          const senderInfo = await ParticipantService.getParticipantInfoByInn(
            doc.senderInn
          );
          doc.senderInfo = senderInfo.data;
        }
      } catch (e) { null; }

      try {
        if (doc.receiverInn) {
          const receiverInfo = await ParticipantService.getParticipantInfoByInn(
            doc.receiverInn
          );
          doc.receiverInfo = receiverInfo.data;
        }
      } catch (e) { null; }

      dispatch(preloaderActions.end());
      dispatch(DocumentDetailsLoaded(doc));
    });
  };
}

function DocumentDetailsLoaded(data) {
  return {
    type: actionTypes.DOCUMENT_DETAILS.DOCUMENT_DETAILS_LOADED,
    value: data,
  };
}

function downloadDocumentClicked(id) {
  return dispatch => {
    DocumentService.getBase64DataById(id)
      .then(answer => {
        let type = 'xml';
        const disposition = answer.headers['content-disposition'];
        if (disposition && ~disposition.indexOf('attachment')) {
          // eslint-disable-next-line
          const match = disposition.match(/filename\=.*?\.([^;\n ]+)/);
          if (match) type = match[1];
        }

        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([new Uint8Array(answer.data)], {
              type: 'text/plain;charset=utf-8;',
            }),
            `${id}.${type}`
          );
        } else {
          const elem = document.createElement('a');
          elem.setAttribute(
            'href',
            window.URL.createObjectURL(new Blob([new Uint8Array(answer.data)]))
          );
          elem.setAttribute('download', `${id}.${type}`);
          elem.style.display = 'none';
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      })
      .catch(error => console.log('downloadDocumentClicked error: ', error));
  };
}

function cisListMounted(documentId) {
  return dispatch => {
    const params = { limit: 10, offset: 0, documentId };
    dispatch({
      type: actionTypes.DOCUMENT_DETAILS.CIS_LIST_MOUNTED,
      value: params,
    });

    CISService.getList(params).then(answer => {
      console.log('answer cisListMounted', answer.data);
      dispatch(
        cisListLoaded(Object.assign(answer.data, { limit: params.limit }))
      );
    });
  };
}

function cisListUpdated(params, documentId) {
  return dispatch => {
    console.log('cisListUpdated params', params);
    console.log('cisListUpdated documentId', documentId);
    const { filters, pageSize, page } = params;
    const newData = FormSerializer.serializeCisGridFiltersForPrivateOffice(
      filters
    );
    const data = { limit: pageSize, offset: page * pageSize, ...newData, documentId };
    dispatch({
      type: actionTypes.DOCUMENT_DETAILS.PAGE_SELECTED,
      value: page + 1,
    });
    CISService.getList(data)
      .then(answer => {
        console.log('answer cisListMounted', answer.data);
        dispatch(
          cisListLoaded(Object.assign(answer.data, { limit: pageSize }))
        );
      })
      .catch(e => {

        dispatch(
          cisListLoaded(Object.assign({results: [], total: 0}, { limit: pageSize }))
        );

        if (!(e.message && e.message === 'PREVENT_TOAST')) showAnyError(e);

        console.log('request canceled may be', e);
      });
  };
}

function cisListFilterApplyClicked(vals, documentId) {
  return dispatch => {
    const params = { limit: 10, offset: 0 };
    const prepared = FormSerializer.serialize(vals);
    const data = Object.assign(params, prepared, { documentId });
    dispatch({
      type: actionTypes.DOCUMENT_DETAILS.FILTER_APPLY_CLICKED,
      value: data,
    });
    console.log('data', data);
    CISService.getList(data).then(answer => {
      console.log('answer', answer.data);
      dispatch(
        cisListLoaded(Object.assign(answer.data, { limit: params.limit }))
      );
    });
  };
}

function cisListLoaded(value) {
  return {
    type: actionTypes.DOCUMENT_DETAILS.CIS_LIST_LOADED,
    value,
  };
}

function pageSelected(pageNumber, cis) {
  return dispatch => {
    console.log('pageNumber', pageNumber);
    const limit = cis.filterParams.limit;
    console.log('limit', limit);
    console.log('filterParams', cis.filterParams);
    const params = Object.assign({}, cis.filterParams, {
      offset: limit * (pageNumber - 1),
      needTotal: false,
    });

    CISService.getList(params).then(answer => {
      console.log('cis list', answer.data);
      dispatch({
        type: actionTypes.DOCUMENT_DETAILS.PAGE_SELECTED,
        value: pageNumber,
      });
      dispatch(CISListPageLoaded(answer.data));
    });
  };
}

function CISListPageLoaded(data) {
  return {
    type: actionTypes.DOCUMENT_DETAILS.CIS_LIST_PAGE_LOADED,
    value: data,
  };
}

function onUnmount() {
  return dispatch => {
    dispatch({type: actionTypes.DOCUMENT_DETAILS.CLEAR});
  };
}

export {
  DocumentDetailsMounted,
  DocumentDetailsLoaded,
  downloadDocumentClicked,
  cisListFilterApplyClicked,
  cisListMounted,
  cisListLoaded,
  cisListUpdated,
  pageSelected,
  onUnmount,
};
