import React from 'react';
import {
  ParticipantSelect,
  RangePicker,
  SingleSelect,
  Input,
} from '../../../common_components/Form/Fields/Fields';
import {
  documentStatusValues,
} from './ExportList.constants';
import { Translate } from '../../../common_components/Translate/Translate';

const ExportListFilters = ({ countryCode }) => {
  const totalStatusValues = (countryCode === 'KZ'
    ? documentStatusValues.filter(el=> el.type !== "17")
    : documentStatusValues).map((item) => ({ ...item, title: Translate(item.title)}))
  return (
    <React.Fragment>
      <Input name="documentID" placeholder={Translate('Регистрационный номер документа')}/>
      <RangePicker
        name="date"
        leftPlaceholder={Translate('Период получения от')}
        rightPlaceholder={Translate('Период получения до')}
      />
      <Input name="receiverCode" placeholder={Translate('ИНН получателя')}/>
      <SingleSelect
        name="status"
        placeholder={Translate('Статус')}
        values={totalStatusValues}
      />
    </React.Fragment>
  );
};
export default ExportListFilters;
