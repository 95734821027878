import { createAction } from 'redux-actions';

export const subject = '[Aggregation]';

export const transportCodesGenerate = createAction(`${subject} transportCodesGenerate`);
export const submit = createAction(`${subject} submit`);
export const documentLoaded = createAction(`${subject} documentLoaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const error = createAction(`${subject} error`);
export const reset = createAction(`${subject} reset`);
