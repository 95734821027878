import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { has, isUndefined } from 'lodash';
import { Input } from '@crpt/react-input';
import { Wrap } from '../../Fields.styled';

const PureInput = memo(Input);

class InputAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    formMutators: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onChange: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    defaultValue: undefined,
  };

  onChangeTimeout;

  componentDidMount() {
    const { defaultValue, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value) && !isUndefined(defaultValue)) {
      setTimeout(() => formMutators.updateField(name, defaultValue), 0);
    }
  }

  onChange = val => {
    const { fieldInput, formValues, fieldMeta, name, onChange } = this.props;

    const isTouchOrInitialised = fieldMeta.touched || (fieldMeta.initial !== undefined);
    if (!has(formValues, name) && isTouchOrInitialised && (val === '')) {
      return;
    }

    if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(() => fieldInput && fieldInput.onChange(val), 100);

    if (onChange) onChange(val);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (isError && fieldMeta && fieldMeta.dirtySinceLastSubmit && !fieldMeta.error)
      return false;

    return isError || (fieldMeta && !isUndefined(fieldMeta.error));
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total, fieldMeta } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;

    return (
      <Wrap onBlur={fieldInput && fieldInput.onBlur} marginBottom={marginBottom}>
        <PureInput
          savePlaceholder
          {...passProps}
          onChange={this.onChange}
          isError={this.isError()}
          placeholder={this.getPlaceholder()}
          defaultValue={fieldInput.value}
        />
      </Wrap>
    );
  }
}

export default InputAdapter;
