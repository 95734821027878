import { selector } from '../../../utils/selector';


export const results = state => selector(state, 'MarkedProductList.results');
export const total = state => selector(state, 'MarkedProductList.total');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const sorting = state => selector(state, 'MarkedProductList.sorting');
export const loaded = state => selector(state, 'MarkedProductList.loaded');
export const selectedPage = state => selector(state, 'MarkedProductList.selectedPage');
export const pagination = state => selector(state, 'MarkedProductList.pagination');
export const filters = state => selector(state, 'MarkedProductList.filters');
