import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Drawer } from '@material-ui/core';
import { getActive, getContent } from './ducks/Sidebar.selectors';
import { close } from './ducks/Sidebar.actions';

const propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element,
};

const defaultProps = {
  children: undefined,
};

const Sidebar = props => {
  const { active, children, onClose } = props;

  return (
    <Drawer anchor="right" open={active} onClose={onClose}>
      {children}
    </Drawer>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

const mapState = state => ({
  children: getContent(state),
  active: getActive(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(close()),
});

export default compose(
  connect(mapState, mapDispatch)
)(Sidebar);
