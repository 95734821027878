import React, { Component } from 'react';

import { SingleSelect } from '@crpt/react-select';

import { ParticipantService } from '../../services/ParticipantService';
import {FilterURLHelper} from '../../utils/FilterURLHelper';

class ParticipantSelect extends Component {

  static searchParam = 'name';

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      isLoading: false,
    };

    this.select = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    //TODO: all lists to List.saga, all filter fill in saga

    setTimeout(() => {
      const {name} = this.props;
      const searchParam = FilterURLHelper.getPairSearchParamByKey(name);
      const value = FilterURLHelper.getParamByKey(name);

      if (searchParam && value) {
        const participant = {
          id: 0,
          title: searchParam,
          name: searchParam,
          [this.props.name || 'participantInn']: value
        };

        this.setState({
          values: [participant]
        });

        this.select.current.onSelect({preventDefault: () => {}}, participant);
      }
    }, 500);

  }

  onChange(val) {
    this.setState({
      isLoading: true,
    });

    ParticipantService.searchByInput(val)
      .then(answer => {
        this.setState({
          isLoading: false,
          values: answer.data.map((pr, i) =>
            Object.assign(pr, {
              [this.props.name || 'participantInn']: pr[
                this.props.valueKey || 'inn'
              ],
              id: i,
              title: pr.name,
            })
          ),
        });
      })
      .catch(err => console.warn('canceled or err', err));
  }

  render() {
    const { onChange, isLoading, values, onRef, ...other } = this.props;

    return (
      <SingleSelect
        {...other}
        onChange={this.onChange}
        onTogglePanel={this.onTogglePanel}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
        isLoading={this.state.isLoading}
        values={this.state.values}
      />
    );
  }
}

ParticipantSelect.displayName = 'SingleSelect';

export default ParticipantSelect;
