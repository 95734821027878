import styled from 'styled-components';
import { get } from 'lodash';

const ListWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  right: 0;
  top: 60px;
  width: 100%;
  color: ${props => get(props, 'theme.Dropdown.color', 'green')};
  box-shadow:
    ${props => get(props, 'theme.Dropdown.shadowX', '10px')}
    ${props => get(props, 'theme.Dropdown.shadowY', '10px')}
    ${props => get(props, 'theme.Dropdown.shadowBlur', '10px')}
    ${props => get(props, 'theme.Dropdown.shadowColor', 'red')};
  
  background-color: ${props => get(props, 'theme.Dropdown.backgroundColor', 'red')};
`;

export default ListWrapper;
