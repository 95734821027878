import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ScanResultsWrap, TableWrap } from '../ScanDialog.styled';
import {
  EmptyGoodsTextWrap,
  GoodsIconWrap, GoodsWrap,
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import GoodsIcon from '../../../motp_components/_Requests/RequestList/EaesImportDocument/Goods/svg/GoodsIcon.svg';
import Datatable from '@crpt-ui/datatable';
import { columns } from '../ScanDialog.constants';
import List from '../../List/List';
import Icon from '@crpt/react-icon';
import { get } from 'lodash';
import { COLORS } from '../ScanDialog.constants'
import {Translate} from '../../Translate/Translate';

export const ScanDialogResults = ({ list = [], onRemove }) => {
  return (
    <ScanResultsWrap>
      <Typography variant="h6">{Translate('Результаты сканирования')}</Typography>
      {
        list.length === 0 && (
          <GoodsWrap minHeight="200px">
            <GoodsIconWrap>
              <GoodsIcon />
            </GoodsIconWrap>

            <EmptyGoodsTextWrap>{Translate('Нет отсканированных кодов')}</EmptyGoodsTextWrap>
          </GoodsWrap>
        )
      }
      {
        list.length > 0 && (
          <TableWrap>
            <List
              columns={[
                {
                  Header: '',
                  id: 'rowId',
                  accessor: d => (
                    <Icon
                      type="pan"
                      size={18}
                      onClick={() => onRemove(d.cis)}
                    />
                  ),
                  minWidth: 48,
                },
                ...columns,
              ]}
              data={list}
              defaultPageSize={1000}
              getTdProps={(state, rowInfo) => {
                const isInvalidCis = get(rowInfo, 'original.isInvalid');

                return {
                  style: {
                    ...(isInvalidCis && {
                      backgroundColor: COLORS.missingInWaybill,
                    }),
                  },
                };
              }}
              height="calc(100vh - 650px)"
              minRows={1}
            />
          </TableWrap>
        )
      }

    </ScanResultsWrap>
  )
};
