import React, { useEffect, useState } from 'react';
import { Accord, AccordTitle, IconWrap } from '../../components/DocumentDetails/Act/Showing.styled';
import Icon from '@crpt/react-icon';
import { DividerOfContent } from '../../motp_components/_Requests/RequestList/Document/Document.styled';

export const Accordion = ({ children, title, expand }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(expand) setOpen(true);
  }, [expand]);

  return (
    <React.Fragment>
      <Accord onClick={() => setOpen(!open)}>
        <AccordTitle withWidth={!expand}>
          <span>{title}</span>
          <IconWrap>
            <Icon
              type={open ? 'up-arrow' : 'down-arrow'}
              spacingLeft={6}
            />
          </IconWrap>
        </AccordTitle>
      </Accord>
      {
        open && children
      }
      <br/>
      <DividerOfContent/>
      <br />
    </React.Fragment>
  )
}

Accordion.defaultProps = {
  expand: false,
};
