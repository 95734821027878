import { get, flatten, cloneDeep } from 'lodash';

export const productListForNewTypeDocument = selectedDocument => {
  let items = cloneDeep(get(selectedDocument, 'items', []));
  items = items.map(item => {
    let products = item.products.map(prod => {
      if (!prod.iCodes || !prod.iCodes.length)
        return { ...prod, iCodes: [item.iCodeFromDeclaration] };
      return prod;
    });
    return { ...item, products };
  });

  const products = flatten(items.map(item => item.products));

  let result = [];
  products.forEach(prod => {
    const currentProd = result.find(item => item.gtin === prod.gtin);

    if (currentProd)
      currentProd.iCodes = [...currentProd.iCodes, ...prod.iCodes];
    else result.push(prod);
  });

  return result.map((item, idx) => ({
    idx: idx + 1,
    name: item.productName,
    gtin: item.gtin,
    tnved: item.tnved,
    country: item.originCountry,
    numberDT: item.itemNumber,
    cises: item.iCodes || [],
  }));
};
