import {actionTypes} from '../../constants/';

export function language(state = 'RU', action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM_KEP.SET_LANGUAGE:
      return action.value;

    default:
      return state;
  }
}
