import { handleAction } from 'redux-actions';
import * as actions from './Ncalayer.actions';

const certificate = handleAction(
  actions.storeCertificate,
  (state, { payload }) => payload,
  null,
);

const certificateError = handleAction(
  actions.errorStoreCertificate,
  (state, { payload }) => payload,
  null,
);

const connectionStatus = handleAction(
  actions.setConnectionStatus,
  (state, { payload }) => payload,
  null,
);


export default {
  certificate,
  certificateError,
  connectionStatus,
};
