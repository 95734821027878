import moment from 'moment';
import { authInstance } from './authInstance';


export class AnalyticsService {
  static getMapData({ region, period, }) {
    const params = {
      reg_code: region,
      dt_beg: period && period.from
        ? moment(period.from).format('YYYY-MM-DD')
        : moment().add(-5, 'year').format('YYYY-MM-DD'),
      dt_end: period && period.to
        ? moment(period.to).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    };

    return authInstance.request({
      url: '/stat/get_map_data',
      withCredentials: false,
      method: 'get',
      params,
    });
  }
}
