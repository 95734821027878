import React, {memo} from 'react';
import {Link} from 'src/fragments/Link';

import {useStyles} from './defaultRoute.styles';
import {Translate} from '../../common_components/Translate/Translate';

export const DefaultRoute = memo(() => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>404</div>
      <div className={styles.notFound}>{Translate('Страница не найдена')}</div>
      <span className={styles.message}>{Translate('К сожалению, запрошенной вами страницы не существует')}</span>
      <span>{Translate('Попробуйте')}{' '} <Link to="">{Translate('вернуться на главную')}</Link></span>
    </div>
  )
});
