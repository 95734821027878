import styled from 'styled-components';
import { get } from 'lodash';

//TODO: theme!!!
const ElementsCountWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #434244;
`;

export default ElementsCountWrapper;
