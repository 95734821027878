export const reportsTheme = {
  Header: {
    background: '#F7F9F9',
  },
  Content: {
    tobacco: {
      background: '#fcfcfc',
    }
  },
  Footer: {
    background: '#F7F9F9',
  },
};
