/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Checkbox as CheckboxCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

const CheckboxAdapter = ({ input, meta, ...fieldRest }) => {

  useEffect(() => {
    input.onChange(input.checked);
    return () => input.onChange();
  }, []);

  return <CheckboxCRPT
    {...input}
    {...fieldRest}
  />;
};

export default FieldHoc(CheckboxAdapter, { type: 'checkbox' });
