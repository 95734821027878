export const getInnerBody = (text) => {
  const startSubstring = '<body>';
  const endSubstring = '</body>';

  const start = text.indexOf(startSubstring);
  const end = text.lastIndexOf(endSubstring);

  if (start === -1 || end === -1) {
    return text;
  }

  return text.slice(start + startSubstring.length, end);
};
