import { Button } from '@crpt-ui/core';
import * as Icons from '@crpt-ui/icons';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import format from '@crpt-ui/core/lib/DatePicker/utils/format.i18n';
import { useTranslation } from 'react-i18next';

const isButtonsDisabled = (result) => {
  if (result === null) {
    return true;
  }
  if (result !== null) {
    if (Object.values(result).length === 2) {
      return !(result.startDate && result.endDate);
    }
    return false;
  }
};

const MemoizedControlButtons = memo(
  ({ buttonsDisabled, handleReset, handleSubmit }) => {
    const { t } = useTranslation();

    return (
      <div
        style={{
          padding: '24px 16px 16px',
          display: 'flex',
          alignItems: 'center',
          fontFamily: "'SegoeUI', sans-serif",
        }}
      >
        <Button
          style={{
            width: '144px',
            marginRight: '24px',
            fontWeight: 'bold',
            color: 'white',
          }}
          disabled={buttonsDisabled}
          color="primary"
          fullWidth={true}
          size="md"
          variant="contained"
          onClick={handleSubmit}
        >
          {t('Применить')}
        </Button>
        <MemoizedTrashButton
          onReset={handleReset}
          isDisabled={buttonsDisabled}
        />
      </div>
    );
  },
);

MemoizedControlButtons.propTypes = {
  buttonsDisabled: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const Footer = memo(
  ({
    activeMonths,
    goToMonth,
    onResetDates,
    onSubmit,
    result,
    type,
    isTodayDay,
    isTodayDisabled,
    onTodayDateSelect,
    isFilter,
  }) => {
    const today = new Date();
    const { month, year } = activeMonths[0];
    const todayMonth = getMonth(today);
    const todayYear = getYear(today);

    const { t } = useTranslation();

    const isCurrentMonth = month === todayMonth && year === todayYear;
    const hanldeClickToday = () => {
      if (type === 'single' && !isTodayDay && !isTodayDisabled) {
        goToMonth(today);
        return onTodayDateSelect(today);
      }
      if (!isCurrentMonth) {
        return goToMonth(today);
      }
    };
    const buttonsDisabled = isButtonsDisabled(result);

    const handleSubmit = useCallback(() => onSubmit(result), [result]);
    const handleReset = useCallback(() => {
      onSubmit(null);
      onResetDates();
    }, []);

    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              display: 'flex',
              borderTop: '1px solid #F2F2F2',
              borderBottom: '1px solid #F2F2F2',
              padding: '8px 16px',
            }}
          >
            <button
              disabled={isTodayDisabled}
              style={{
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '19px',
                display: 'inline-block',
                color: !isTodayDisabled ? '#5CA6DC' : '#B8B8B8',
                cursor: !isTodayDisabled ? 'pointer' : 'default',
                fontFamily: "'SegoeUI', sans-serif",
                border: 'none',
                outline: 'none',
              }}
              onClick={hanldeClickToday}
            >
              {`${t('Сегодня')} ${format(today, 'dd.MM.yyyy')}`}
            </button>
          </div>
          {!isFilter &&
            React.createElement(MemoizedControlButtons, {
              buttonsDisabled,
              handleReset,
              handleSubmit,
            })}
        </div>
      </div>
    );
  },
);

const MemoizedTrashButton = memo(({ onReset, isDisabled }) => (
  <Button
    style={{
      border: '1px solid #D9D9D9',
      borderRadius: '4px',
    }}
    disabled={isDisabled}
    size="md"
    icon={<Icons.Trash />}
    variant="contained"
    color="secondary"
    onClick={onReset}
  />
));

MemoizedTrashButton.propTypes = {
  onReset: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

Footer.propTypes = {
  activeMonths: PropTypes.array.isRequired,
  goToMonth: PropTypes.func.isRequired,
  onResetDates: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  result: PropTypes.object,
  isTodayDay: PropTypes.bool,
  isTodayDisabled: PropTypes.bool,
  onTodayDateSelect: PropTypes.func,
  isFilter: PropTypes.bool,
};

export default Footer;
