import { makeStyles, withStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px',
  },
  topRow: {
    display:'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  breadcrumbs: {
    paddingBottom: '18px',
  },
  contentWrapper: {
    height: '100%',
  },
}));


export const TabContent = withStyles({
  root: {
    display: ({ isVisible }) => (isVisible ? 'flex' : 'none'),
    height: '100%'
  }
})(({classes, children}) => (
  <div className={classes.root}>
    {children}
  </div>
));
