export const redTheme = {
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: '"SegoeUI", sans-serif',
  large: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '48px'
  },
  normal: {
    paddingLeft: '19px',
    paddingRight: '19px',
    height: '40px'
  },
  main: {
    border: '0',
    color: '#FF3D00',
    background: '#FFFFFF',
    boxShadow:
      '0px 3px 4px rgba(255, 61, 0, 0.25), 0px 1px 3px rgba(255, 61, 0, 0.3)'
  },
  disabled: {
    border: '0',
    color: '#FFFFFF',
    background: 'linear-gradient(0deg, #d4d4d4, #d4d4d4);',
    boxShadow:
      '0px 3px 4px rgba(212, 212, 212, 0.25), 0px 1px 3px rgba(212, 212, 212, 0.3)'
  },
  minWidth: '108px',
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px'
};
