import React, { useEffect, useMemo, useState } from 'react';
import SingleSelect from '../_refactor/Select/SingleSelect';
import HintedOption from './HintedOption';

const parseOptions = (item) => ({ id: item.id, title: item.id, type: item.id });

export const CrossborderSelect = ({ list, onSelect, noValuesText, valueKey, ...passProps }) => {
  const [values, setValues] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues(list.map(parseOptions));
  }, [list])

  const onBlur = () => {
    setCurrentValue('');
  }

  const onChangeValue = (val) => {
    setCurrentValue(val);
    if(val.length > 2){
      setLoading(true)
      const newValues = list
        .filter((item) => item.id.includes(val))
        .map(parseOptions);
      setLoading(false);
      setValues(newValues);
    } else {
      setLoading(false);
      setValues(list.map(parseOptions));
    }
  };

  const textEmpty = useMemo(() => {
    if (currentValue.length > 2 && values.length === 0) {
      return <span style={{color: 'red'}}>Не найдено ни одного документа отгрузки!</span>
    }
    return noValuesText;
  }, [noValuesText, currentValue, values])

  return (
    <SingleSelect
      {...passProps}
      noValuesText={textEmpty}
      onBlur={onBlur}
      truncate
      renderOption={HintedOption}
      onChange={onChangeValue}
      isLoading={loading}
      values={values}
      onSelect={onSelect}
    />
  );
}
