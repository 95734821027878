import { defaultTheme } from './default';
import { loginTheme } from './login';
import { registrationTheme } from './registration';
import { exciseTheme } from './excise';

export {
  defaultTheme as sidebarDefaultThemeKZ,
  loginTheme as sidebarLoginThemeKZ,
  registrationTheme as sidebarRegistrationThemeKZ,
  exciseTheme as sidebarExciseThemeKZ
};
