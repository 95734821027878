import { handleActions } from 'redux-actions';
import * as actions from './Cis.actions';

const isSignButtonDisabled = handleActions(
  {
    [actions.sign]: (state, { payload }) => true,
    [actions.finishSign]: () => false,
  },
  false
);

export default {
  isSignButtonDisabled
};