import { gridFilterTheme } from '../../../theme/ru-tabaco/select/gridFilter';

const React = require('react');
import PropTypes from 'prop-types';
import CISStatusSelect from '../../Select/CISStatusSelectDigit';
import { Wrap, SelectWrap } from '../Grid.styled';
import { Translate } from '../../../common_components/Translate/Translate';

class StatusFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    appliedFilter: PropTypes.object, // filterTerm, operator, filterValue
    close: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const isHaveFilter =
      this.props.appliedFilter && this.props.appliedFilter.length > 0;

    this.state = {
      value: isHaveFilter ? this.props.appliedFilter[0].filterTerm : '',
      operator: isHaveFilter ? this.props.appliedFilter[0].operator : '=',
      title: isHaveFilter ? this.props.appliedFilter[0].filterValue.title : '',
    };
  }

  handleFilterChange = val => {
    if (val !== undefined && val !== null) {
      this.setState({ value: val.cisStatus, title: val.title });
    } else {
      this.setState({ value: null, title: '' });
    }
  };

  reset = () => {
    this.setState({ value: '' });
    this.props.onFilterChange(undefined);
  };

  applyFilter = () => {
    //third parameter is filterValue object, need for restored complex filters
    this.props.onFilterChange([
      {
        filterTerm: this.state.value,
        operator: this.state.operator,
        filterValue: {
          title: this.state.title,
          value: this.state.value,
        },
      },
    ]);
  };

  setFilter = filter => {
    this.setState({
      value: filter && filter.length > 0 ? filter[0].filterTerm : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    });
  };

  render() {
    return (
      <Wrap>
        <SelectWrap>
          <CISStatusSelect
            withoutIcon
            truncate
            theme={{ Select: gridFilterTheme }}
            selectedStatus={
              this.state.value !== undefined && this.state.value !== null
                ? this.state.value
                : undefined
            }
            onSelect={val => this.handleFilterChange(val)}
          />
        </SelectWrap>
        <button
          type="button"
          onClick={this.reset}
          className="filter-btn__cancel"
        >
          {Translate('Сбросить')}
        </button>
        <button
          disabled={
            this.state.value !== undefined && this.state.value !== null
              ? false
              : true
          }
          type="button"
          onClick={this.applyFilter}
          className="filter-btn__apply"
        >
          {Translate('Применить')}
        </button>
      </Wrap>
    );
  }
}

export default StatusFilter;
