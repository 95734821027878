import React, { useMemo } from 'react';
import Datatable from '@crpt-ui/datatable';
import { columnsKZ } from '../../../motp_components/_Requests/RequestList/ActDocument/Goods/Goods.constants';
import { TableWrap } from './Showing.styled';
import { connect } from 'react-redux';
import * as selectors from '../ducks/DocumentDetails.selectors';
import * as selectorsAuth from 'src/selectors/Auth';
import { get } from 'lodash';

const ActGoodsTable = ({ data, userInfo }) => {
  const list = useMemo(
    () => get(data, 'km', []).map((item, index) => {
      let cisesCounts;
      if (data.data.content.cises) {
          cisesCounts = data.data.status === 18 || data.data.status === 0 ? ' ' : data.data.content.cises.find(el => el.code === item.cis).count;
        } else cisesCounts = ' ';
        return ({
          ...item,
          serialNumber: index + 1,
          userINN: userInfo.inn,
          isOperator: userInfo.authorities.includes('ROLE_ORG_Оператор'),
          cisesCounts,
          documentStatus: data.data.status,
        });
      }),
    [data],
  );

  return (
    <div>
      <TableWrap>
        <Datatable columns={columnsKZ} useSort={false} data={list} />
      </TableWrap>
    </div>
  );
};

const mapState = state => ({
  data: selectors.getData(state),
  userInfo: selectorsAuth.commonUserInfo(state),
});

export default connect(mapState)(ActGoodsTable);
