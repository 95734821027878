import React, { Fragment, useEffect, useState } from 'react';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import * as selectors from  './ducks/Aik.selectors'
import * as actions from  './ducks/Aik.actions'
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import { connect } from 'react-redux';
import { steps, WrapperForTable } from './Aik.constants';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import AddAikBar from './AddAikBar';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';


const Aik = (props) => {
  const { match, loaded, countryCode, isLoading, onSaveDraft } = props;
  const { inn, user, onLoadDraft, data, onSubmit, onMountCades, onMount } = props;
  const { id } = match.params;
  const [step, setStep] = useState(steps.info);

  useEffect(() => {
    onMount();
    onMountCades();
  }, [onMountCades, onMount]);

  useEffect(()=> {
    if (id !== 'create') onLoadDraft(id.replace(/draft-/, ''))
  }, [id, onLoadDraft])

  const handleOnSubmit = (values) => {
    onSubmit({ values, idDraft: values.idDraft ?  values.idDraft : id.replace(/draft-/, '')});
  };

  const errorsCount = ({ formErrors }) => Object.keys(JSON.parse(JSON.stringify(formErrors))).length

  const checkSignInGoodsDisabled = (formValues) => !formValues.aik

  return (
    <WrapperForTable>
      <DocumentWrapper
        finalConfirmModal
        wrapperForTableInFinalConfirmModal={WrapperForTable}
        checkSignInGoodsDisabled={checkSignInGoodsDisabled}
        needViewActionsOfGoods={false}
        additionalBottomBar={AddAikBar}
        rightDraftButton={false}
        loaded={true}
        step={step}
        match={match}
        productGroupIdForGoods={2}
        isCreate={id === 'create'}
        actionSelectOptions={[]}
        breadcrumbs={[
          {
            href: '/documents/list',
            title: Translate('Документы'),
            id: 1,
          },
          {
            title: Translate('Формирование АИК'),
            id: 2,
            ...id !== 'create' ? { href: `/documents/list/${id}` } : {},
          },
        ]}
        countryCode={countryCode}
        // isLoading={isLoading}
        isLoading={true}
        onSaveDraft={onSaveDraft}
        data={id === 'create' ? {
          applicantInn: inn,
          applicantName: user.full_name,
        } : {
          ...data
        }}
        onSetStep={setStep}
        docType={'AIC_AGGREGATION'}
        codesCanBeChanged={false}
        fieldsInfo={InfoFields}
        errorsCountFunc={errorsCount}
        needAWarningAboutTheGoodsRecount={false}
        needCancelButton={true}
        needDraftButton={true}
        onSubmit={handleOnSubmit}
        title='Формирование АИК'
        pageSizeInGoods={20}
        filterPackageTypeForGoods="UNIT"
      />
    </WrapperForTable>
  );
};

const mapState = state => ({
  data: selectors.data(state),
  countryCode: state.config.countryCode,
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
  user: authSelectors.userInfo(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMountCades: () => dispatch(cadesActions.load()),
  onSubmit: data => dispatch(actions.confirmAikDoc(data)),
  onMount: () => dispatch(actions.mounted()),
});

export default connect(mapState, mapDispatch)(Aik);
