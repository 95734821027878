import React from 'react';
import { MaterialUiThemeKZ } from '../../../../../common_components/_MaterialUi/ThemeKZ';
import { Box, Grid, MuiThemeProvider } from '@material-ui/core';
import {
  DividerOfContent,
  ParticipantSelectWrapper,
  StepTitle,
} from '../../Document/Document.styled';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import {
  required,
  requireTime,
} from '../../../../../common_components/Form/utils/validations';
import CountrySelect from '../../../../../common_components/Form/MaterialFields/Select/CountrySelect';
import Select from '../../../../../common_components/Form/MaterialFields/Select/Select';
import { solveCodeValues, typeDocument } from '../ImportThirdCountry.constants';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import TimeInput from '../../../../../common_components/Form/MaterialFields/Input/TimeInput';
import CustomsCodeSelect from '../../../../../common_components/Form/MaterialFields/Select/CustomsCodeSelect';
import { hasOnlyDigits } from '../../../../../utils';
import Checkbox from '../../../../../common_components/Form/Fields/Checkbox/Checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { Translate } from '../../../../../common_components/Translate/Translate';

export const KzFields = ({ hasPaperDoc, handleCheckboxChange }) => {
  const { t } = useTranslation();
  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            <Trans t={t}>
              сведения об участнике оборота <br /> маркируемых товаров
              (импортере)
            </Trans>
          </StepTitle>
        </Grid>
        <Grid xs={3}>
          <Box pr={2}>
            <Input
              name="recipient_inn"
              placeholder={Translate('ИИН/БИН получателя')}
              disabled
              validate={required}
            />
          </Box>
        </Grid>
        <Grid xs={5}>
          <Input
            name="recipient_name"
            placeholder={Translate('Наименование получателя')}
            disabled
            validate={required}
          />
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>{Translate('Страна экспорта')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <CountrySelect
            placeholder={Translate('Страна экспорта')}
            name="country_export"
            forceValidate
            required
            validate={required}
          />
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            {Translate('сведения о документе таможенного оформления')}
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container>
            <Grid xs={12}>
              <Select
                placeholder={Translate('Тип документа')}
                name="type_document"
                options={typeDocument}
                defaultValue={typeDocument[0].value}
                forceValidate
                required
                validate={required}
              />
            </Grid>
            <Grid xs={6}>
              <Box pr={2}>
                <Input
                  name="registration_number"
                  placeholder={Translate('Регистрационный номер')}
                  forceValidate
                  required
                  validate={required}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <DatePicker
                required
                forceValidate
                name="registration_date"
                placeholder={Translate('Дата регистрации')}
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            {Translate('сведения о решении таможенного органа')}
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container>
            <Grid xs={6}>
              <Box pr={2}>
                <Select
                  placeholder={Translate('Код решения')}
                  name="solve_code"
                  options={solveCodeValues}
                  forceValidate
                  required
                  validate={required}
                />
              </Box>
            </Grid>
            <Grid xs={3}>
              <Box pr={2}>
                <DatePicker
                  required
                  forceValidate
                  name="solve_date"
                  placeholder={Translate('Дата решения')}
                  maxDate={new Date()}
                  validate={(...vals) => {
                    return validateDate(...vals, 'DD.MM.YYYY');
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={3}>
              <TimeInput
                name="solve_time"
                placeholder={Translate('Время решения')}
                forceValidate
                required
                validate={requireTime}
              />
            </Grid>

            <Grid xs={12}>
              <ParticipantSelectWrapper>
                <CustomsCodeSelect
                  name="customs_organization_code"
                  placeholder={Translate('Код таможенного органа')}
                  errorPlaceholder={Translate(
                    'Код таможенного органа не может быть пустым или больше 5 символов',
                  )}
                  forceValidate
                  valueKey="code"
                  required
                  validate={val =>
                    (hasOnlyDigits(val) && val.length > 5) || !val
                  }
                />
              </ParticipantSelectWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            <Trans>
              сведения о документе, подтверждающем
              <br />
              соответствие товаров
            </Trans>
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container>
            <Grid xs={12}>
              <Input
                name="confirming_document_type"
                placeholder={Translate('Тип документа')}
                forceValidate
                required
                validate={required}
              />
            </Grid>
            <Grid xs={6}>
              <Box pr={2}>
                <Input
                  name="confirming_document_number"
                  placeholder={Translate('Номер документа')}
                  forceValidate
                  required
                  validate={required}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <DatePicker
                required
                forceValidate
                name="confirming_document_data"
                placeholder={Translate('Дата документа')}
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            <Trans>
              сведения об уведомлении, выписанном на
              <br />
              бумажном носителе
            </Trans>
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          <Checkbox
            checked={hasPaperDoc}
            onClick={handleCheckboxChange}
            name="isHasPaperDocument"
          />
          {hasPaperDoc && (
            <Grid container>
              <Grid xs={6}>
                <Box pr={2}>
                  <Input
                    name="notification_number"
                    placeholder={Translate('Номер уведомления')}
                    forceValidate
                    required
                    validate={required}
                  />
                </Box>
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  required
                  forceValidate
                  name="notification_data"
                  placeholder={Translate('Дата уведомления о ввозе')}
                  maxDate={new Date()}
                  validate={(...vals) => {
                    return validateDate(...vals, 'DD.MM.YYYY');
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};
