import styled from 'styled-components';

import {Heading} from './Heading';
import {ContentBlock} from './ContentBlock';
import {ContentLine} from './ContentLine';
import {Title} from './Title';
import {ProfileHeading} from './ProfileHeading';
import {ListWrap} from './ListWrap';
import {ListItem} from './ListItem';
import {AvatarWrap} from './AvatarWrap';
import {AvatarBlock} from './AvatarBlock';
import {MainBlock} from './MainBlock';
import {ModalCaption} from './ModalCaption';
import {ModalWrap} from './ModalWrap';
import {ModalContent} from './ModalContent';
import {ModalButtonWrap} from './ModalButtonWrap';
import {TimeWrap} from './TimeWrap';
import {BetweenCards} from './BetweenCards';
import {Wrap} from './Wrap';
import {UnassignedPermission} from './UnassignedPermission';
import {CommandsWrap} from './CommandsWrap';
import {PermissionWrap} from './PermissionWrap';
import {SaveChangesWrap} from './SaveChangesWrap';
import {HeadingBlock} from './HeadingBlock';
import {Badge} from './Badge';
import {UserListHeading} from './UserListHeading';
import {UserListHeadingContent} from './UserListHeadingContent';
import {UserListItemContent} from './UserListItemContent';
import {ContentBlockHeading} from './ContentBlockHeading';
import {ContentSmallMarginBlock} from './ContentSmallMarginBlock';
import {RoleBlock} from './RoleBlock';
import {RoleHeading} from './RoleHeading';
import {RoleBlockContent} from './RoleBlockContent';
import {IconWrap} from './IconWrap';
import {BigIconWrap} from './BigIconWrap';
import {SmallWrap} from './SmallWrap';
import {UserInfoButtonsWrap} from './UserInfoButtonsWrap';
import {UserListHeadingSelected} from './UserListHeadingSelected';
import {UserListItemButtonsWrap} from './UserListItemButtonsWrap';
import {AvatarUploadLink} from './AvatarUploadLink';
import {AvatarOverlay} from './AvatarOverlay';
import {UploadCert} from './UploadCert';
import {ButtonsWrap as MainButtonsWrap} from './ButtonsWrap';
import {get} from 'lodash';


export {
  Heading,
  ContentBlock,
  ContentLine,
  Title,
  ProfileHeading,
  ListWrap,
  ListItem,
  AvatarWrap,
  AvatarBlock,
  MainBlock,
  ModalCaption,
  ModalWrap,
  ModalContent,
  ModalButtonWrap,
  TimeWrap,
  BetweenCards,
  Wrap,
  UnassignedPermission,
  CommandsWrap,
  PermissionWrap,
  SaveChangesWrap,
  HeadingBlock,
  Badge,
  UserListHeading,
  UserListHeadingContent,
  UserListItemContent,
  ContentBlockHeading,
  ContentSmallMarginBlock,
  RoleBlock,
  RoleHeading,
  RoleBlockContent,
  IconWrap,
  BigIconWrap,
  SmallWrap,
  UserInfoButtonsWrap,
  UserListHeadingSelected,
  UserListItemButtonsWrap,
  AvatarUploadLink,
  AvatarOverlay,
  UploadCert,
  MainButtonsWrap
};

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  color: #55b5e7;
  cursor: pointer;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.marginBottom};
`;

export const PreloaderWrap = styled.div`
  position: relative;
  padding: 20px;
  margin-bottom: 0px;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;