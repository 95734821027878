import {actionTypes} from '../../constants/';

export function selectedProduct(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS.PRODUCT_DETAILS_LOADED:
      return action.value;
    case actionTypes.PRODUCT_DETAILS.RATE_CHANGED:
      return {
        ...state,
        rateType: action.value,
        rateTypeChanged : true,
      };
    case actionTypes.PRODUCT_DETAILS.RATE_CHANGES_HISTORY:
      return {
        ...state,
        rateChangesHistory: action.value,
      };
    case actionTypes.PRODUCT_DETAILS.RATE_CHANGES_HISTORY_CLEAR:
      return {
        ...state,
        rateChangesHistory: null,
      };
    case actionTypes.PRODUCT_DETAILS.RATE_CHANGED_FALSE:
      return {
        ...state,
        rateTypeChanged : false,
      };
    case actionTypes.PRODUCT_DETAILS.PRODUCT_DETAILS_UPDATED:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
}
