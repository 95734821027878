import React from 'react';
import styled, { css } from 'styled-components';

import {Icon} from '@crpt/react-icon';

import {withRouter} from 'react-router-dom';

const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a99f4;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconWrap = styled.div`
  min-width: 16px;
  margin-right: 16px;
  padding-bottom: 0px;
  width: 16px;
  height: auto;
  display: flex;
  align-items: center;
`;

const CIScis = props => {
  const cis = props.options.cis;

  return (
    <Wrap>
      <Link href={`/cis/list/${encodeURIComponent(cis.cis)}/`} onClick={e => {
        e.preventDefault();
        props.history.push(`/cis/list/${encodeURIComponent(cis.cis)}/`);
      }}>
        {cis.cis}
      </Link>
      {false &&
        <Icon type="not-allow" spacingLeft={4} />
      }
    </Wrap>
  );
};

export default withRouter(CIScis);
