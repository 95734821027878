import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 5,
    marginTop: 10,
  },
  item: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  selected: {
    borderRadius: 8,
    backgroundColor: '#00000014'
  }
});
