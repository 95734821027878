import { createActions } from 'redux-actions';

export const {
  createDocument,
  createDocumentDone,
  downloadReport,
  loaded,
  requestDocumentsList,
  setError,
  signAndSend,
  unMount,
} = createActions(
  {
    CREATE_DOCUMENT: payload => payload,
    CREATE_DOCUMENT_DONE: () => ({}),
    DOWNLOAD_REPORT: payload => payload,
    LOADED: payload => payload,
    REQUEST_DOCUMENTS_LIST: payload => payload,
    SET_ERROR: payload => payload,
    SIGN_AND_SEND: payload => payload,
    UN_MOUNT: () => [],
  },
  {
    prefix: 'GRAY_AREA',
  },
);
