import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import * as selectors from  './ducks/Inventory.selectors'
import * as actions from  './ducks/Inventory.actions'
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import { steps, WrapperForTable } from './Inventory.constants';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';
import AddCodesBar from "./AddCodesBar";

const changeTabsFunc = (tab) => tab.id === 1 ? ({ ...tab, label: Translate("Шаг 2. Коды") }) : tab

const Inventory = (props) => {
    const { match, unMounted, countryCode, isLoading, onSaveDraft } = props;
    const { inn, onLoadDraft, data, onSubmit, onMountCades } = props;
    const { id } = match.params;
    const [step, setStep] = useState(steps.info);

    useEffect(()=> {
        onMountCades();
        return unMounted
    },[unMounted, onMountCades])

    useEffect(()=> {
        if (id !== 'create') onLoadDraft(id.replace(/draft-/, ''))
    }, [id, onLoadDraft])

    const handleOnSubmit = (values) => {
        onSubmit({ values, idDraft: values.idDraft ?  values.idDraft : id.replace(/draft-/, '')});
    };

    const errorsCount = ({ formErrors }) => Object.keys(JSON.parse(JSON.stringify(formErrors))).length

    return (
        <WrapperForTable>
            <DocumentWrapper
                changeTabsFunc={changeTabsFunc}
                wrapperForTableInFinalConfirmModal={WrapperForTable}
                needViewActionsOfGoods={false}
                additionalBottomBar={AddCodesBar}
                rightDraftButton={false}
                loaded={true}
                step={step}
                match={match}
                productGroupIdForGoods={2}
                isCreate={id === 'create'}
                actionSelectOptions={[]}
                breadcrumbs={[
                    {
                        href: '/documents/list',
                        title: Translate('Документы'),
                        id: 1,
                    },
                    {
                        title: Translate('Уведомление о товарных остатках (производство)'),
                        id: 2,
                        ...id !== 'create' ? { href: `/documents/list/${id}` } : {},
                    },
                ]}
                countryCode={countryCode}
                isLoading={isLoading}
                onSaveDraft={onSaveDraft}
                data={id === 'create' ? {
                    applicantInn: inn,
                } : {
                    ...data
                }}
                onSetStep={setStep}
                docType={'INVENTORY'}
                codesCanBeChanged={false}
                fieldsInfo={InfoFields}
                errorsCountFunc={errorsCount}
                needAWarningAboutTheGoodsRecount={false}
                needCancelButton={true}
                needDraftButton={true}
                onSubmit={handleOnSubmit}
                title="Уведомление о товарных остатках (производство)"
                pageSizeInGoods={20}
                filterPackageTypeForGoods="UNIT"
            />
        </WrapperForTable>
    );
};

const mapState = state => ({
    data: selectors.data(state),
    // loaded: selectors.loaded(state),
    isLoading: preloaderSelectors.loading(state),
    countryCode: state.config.countryCode,
    inn: authSelectors.userInn(state),
    shortName: authSelectors.userShortName(state),
});

const mapDispatch = dispatch => ({
    onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
    onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
    onMountCades: () => dispatch(cadesActions.load()),
    onSubmit: data => dispatch(actions.confirmDoc(data)),
    unMounted: data => dispatch(actions.unmounted(data)),
});

export default connect(mapState, mapDispatch)(Inventory);
