import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withModal from 'src/common_components/Modals/hocs/withModal';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import {
  cisKey,
  contentBodyConfirm, group,
  steps,
  tabsKZ,
} from '../ActDocument.constants';
import { PageWrapper, StepWrapper } from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { submit } from '../ducks/ActDocument.actions';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import List from 'src/common_components/List/List';
import { columns } from './Total.const';
import { Translate } from 'src/common_components/Translate/Translate';
import * as selectors from '../ducks/ActDocument.selectors';
import { Preloader } from '../../../../../common_components/Preloader/Preloader';
import * as actions from '../ducks/ActDocument.actions';
import * as actActions from '../ducks/ActDocument.actions';
import { ButtonWrap } from '../../../../../components/DocumentDetails/Act/Showing.styled';
import Button from '@material-ui/core/Button';
import { Action } from '@crpt-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReconciliationDialog from '../Reconciliation/ReconciliationDialog';
import { ExistReconciliationDraftDialog } from '../Reconciliation/components/ExistReconciliationDraftDialog';
import * as reconciliationSelectors from '../Reconciliation/ducks/Reconciliation.selectors';
import * as reconciliationActions from '../Reconciliation/ducks/Reconciliation.actions';
// import AddGtinFields from './AddGtin';
// import * as Icons from '@crpt-ui/icons';

class Total extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    mode: PropTypes.string,
    direction: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.number,
    isFixed: PropTypes.bool,
    isDraft: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedRow: null,
      activePage: 1,
      rowsPerPage: 5,
      anchorEl: null,
      showReconciliationDraftDialog: false,
      showReconciliationDialog: false,
      dataForReconciliation: [],
    };
  }

  handleRecount = () => {
    const { recount, formValues, data, id } = this.props;
    const parentId = Object.values(data.data.links).includes(1100)
      ? Object.keys(data.data.links).find(el=> data.data.links[el] === 1000)
      : null

    recount({
      ...data.data,
      ...parentId ? {parent_id: parentId} : {},
      content: {
        ...data.data.content,
        doc_id: id,
        cises: formValues.km.map(elKm => ({
          code: elKm.cis,
          count: 1,
        })),
        products: [
          {
            gtin: "00000000000000",
            count: 0
          }
        ],
      },
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  onNextStep = () => {
    const { submit, formValues } = this.props;
    submit(formValues);
  };

  onPreviousStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  isNextStepDisabled = () => {
    const { show, submitWaiting } = this.props;
    if (!show || submitWaiting) return true;
    const cis = this.getCis();
    return isEmpty(cis);
  };

  tabsCurrent = () => {
    const { show } = this.props;
    if (!show) return tabsKZ;
    const { onSetStep } = this.props;
    return tabsKZ.map(tab =>
      tab.id === steps.cis
        ? tab
        : {
          ...tab,
          onClick: () => {
            onSetStep(tab.id);
          },
        },
    );
  };

  getCis = () => {
    const { formValues, mode, goodsChanged, status } = this.props;
    const cis = formValues[mode === 'editedAct' ? group : cisKey] || [];

    return goodsChanged && status === 19 ? [] : cis;
  };

  preparedCis = cis => {
    const { activePage, rowsPerPage } = this.state;
    const page = activePage - 1;

    return cis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  handleSend = () => {
    const { send, id } = this.props;
    send(id);
  };

  startReconciliation = () => {
    const { getReconciliationDraft, id } = this.props;

    getReconciliationDraft({
      id,
      onFinish: isExist => {
        if (isExist) {
          this.onReconciliationDraftToggle();
        } else {
          this.newReconciliation();
        }
      },
    });
  };

  onReconciliationToggle = () => {
    const { showReconciliationDialog } = this.state;
    this.setState({
      showReconciliationDialog: !showReconciliationDialog,
    });
  };

  onReconciliationDraftToggle = () => {
    const { showReconciliationDraftDialog } = this.state;
    this.setState({
      showReconciliationDraftDialog: !showReconciliationDraftDialog,
    });
  };

  continueReconciliation = () => {
    const { reconciliationDraft } = this.props;
    this.setState({
      showReconciliationDraftDialog: false,
      dataForReconciliation: reconciliationDraft,
    });
    this.onReconciliationDraftToggle();
    this.onReconciliationToggle();
  };

  newReconciliation = () => {
    const { data } = this.props;
    this.setState({
      showReconciliationDraftDialog: false,
      showReconciliationDialog: false,
      dataForReconciliation: data.km,
    });
    this.onReconciliationToggle();
  };

  // deleteRow = (gtin) => {
  //   const { formMutators, formValues } = this.props;
  //   const oldArr = cloneDeep(formValues[cisKey]) || [];
  //   const result = oldArr.filter((item) => item.gtin !== gtin);
  //   formMutators.updateField(cisKey, [ ...result ]);
  //  };
  //
  // onEditStart = (selectedRow) => {
  //   this.setState({ selectedRow })
  // };
  //
  // onFinishEdit = () => {
  //   this.setState({ selectedRow: null })
  // };

  getColumns = () => {
    return [
      ...columns,
      // {
      //   id: 'edit',
      //   accessor: (row) => {
      //     return <div style={{ cursor: 'pointer'}}>
      //         <Icons.Edit onClick={() => this.onEditStart(row)}/>
      //     </div>;
      //   },
      //   Header: '',
      //   width: 60
      // },
      // {
      //   id: 'delete',
      //   accessor: (row) => {
      //     return<div style={{ cursor: 'pointer'}}>
      //         <Icons.Trash onClick={() => this.deleteRow(row.gtin)}/>
      //       </div>;
      //   },
      //   Header: '',
      //   width: 60
      // }
    ];
  };

  approveAct = () => {
    const { accept, id } = this.props;
    this.handleClose();
    accept({ id, decision: 'approve' });
  };

  rejectAct = () => {
    const { reject, id } = this.props;
    this.handleClose();
    reject({ id, decision: 'reject' });
  };

  handleReconciliation = () => {
    this.startReconciliation();
    this.handleClose();
  };

  render() {
    const { isModalOpen, anchorEl, showReconciliationDraftDialog, showReconciliationDialog } = this.state;
    const { dataForReconciliation } = this.state;
    const { show, status, isCorrection, goodsChanged, id, data, isFixed, direction, isDraft } = this.props;
    const cis = this.getCis();

    const preparedColumns = this.getColumns();
    const ownerInn = get(data, 'data.content.buyer.inn');
    const supplierInn = get(data, 'data.content.seller.inn');

    return (
      <PageWrapper hide={!show}>
        <ConfirmModal
          isModalOpen={isModalOpen}
          onClose={this.toggleDialog}
          onSubmit={this.onCancel}
          content={contentBodyConfirm}
          header={Translate('Предупреждение')}
          cancelButtonText={Translate('Нет')}
          submitButtonText={Translate('Да')}
          notCancelable
        />
        <ReconciliationDialog
          onClose={this.onReconciliationToggle}
          open={showReconciliationDialog}
          showingData={dataForReconciliation}
          id={id}
          supplierInn={supplierInn}
          ownerInn={ownerInn}
        />
        <ExistReconciliationDraftDialog
          onClose={this.onReconciliationDraftToggle}
          continueReconciliation={this.continueReconciliation}
          newReconciliation={this.newReconciliation}
          open={showReconciliationDraftDialog}
        />
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.cis}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          {/*<AddGtinFields*/}
          {/*  formMutators={formMutators}*/}
          {/*  formValues={formValues}*/}
          {/*  selectedRow={selectedRow}*/}
          {/*  onFinishEdit={this.onFinishEdit}*/}
          {/*/>*/}
          {status === 18
            ? (<Preloader isOpen={true} text="Выполняется расчет итогов"/>)
            : (
              <React.Fragment>
                <List
                  defaultPageSize={10}
                  columns={preparedColumns}
                  data={cis}
                  noDataText=""
                  showPagination={cis.length > 10}
                />
                {[3, 13].includes(status) && direction === 'act-in' && (
                  <ButtonWrap>
                    <Button
                      onClick={this.handleClick}
                      variant="contained"
                      startIcon={<Action/>}
                    >
                      {Translate("Действия")}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      autoFocus={false}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <MenuItem onClick={this.approveAct}>{Translate("Принять документ")}</MenuItem>
                      <MenuItem onClick={this.rejectAct}>{Translate("Отклонить документ")}</MenuItem>
                      {!isFixed && (
                        <MenuItem onClick={this.handleReconciliation}>{Translate("Выполнить сверку")}</MenuItem>
                      )}
                    </Menu>
                  </ButtonWrap>
                )}
                <CommandBar
                  left={status === null || isDraft
                    ? [
                      <CommonActionButton onClick={this.toggleDialog}>
                        {Translate('Отменить')}
                      </CommonActionButton>,
                      <CommonActionButton onClick={this.onSaveDraft}>
                        {Translate('Сохранить как черновик')}
                      </CommonActionButton>,
                    ]
                    : isCorrection
                      ? [
                        <CommonActionButton onClick={this.toggleDialog}>
                          {Translate('Отменить')}
                        </CommonActionButton>,
                      ]
                      : []
                  }
                  style={{
                    padding: '37px 40px',
                  }}
                  right={status === null || (status === undefined && isCorrection) || isDraft
                    ? [(
                      <PrimaryActionLargeButton
                        disabled={false}
                        onClick={this.onNextStep}
                      >
                        {Translate('Рассчитать итоги')}
                      </PrimaryActionLargeButton>
                    )]
                      : status === 19
                      ? [(
                        <PrimaryActionLargeButton
                          disabled={false}
                          onClick={this.handleRecount}
                        >
                          {Translate('Рассчитать итоги')}
                        </PrimaryActionLargeButton>
                      ), (
                        <PrimaryActionLargeButton
                          onClick={this.handleSend}
                          disabled={goodsChanged}
                        >
                          {Translate('Отправить')}
                        </PrimaryActionLargeButton>
                      )]
                      : []
                  }
                />
              </React.Fragment>
            )
          }
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  submitWaiting: selectors.submitWaiting(state),
  reconciliationDraft: reconciliationSelectors.draft(state),
});

const mapDispatch = dispatch => ({
  submit: values => dispatch(submit(values)),
  send: data => dispatch(actions.send(data)),
  recount: data => dispatch(actActions.recount(data)),
  accept: data => dispatch(actActions.acceptDocument(data)),
  reject: data => dispatch(actActions.rejectDocument(data)),
  getReconciliationDraft: data => dispatch(reconciliationActions.getReconciliationDraft(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(Total);
