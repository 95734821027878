import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { DividerOfContent, StepTitle } from '../Document/Document.styled';
import { Translate } from '../../../../common_components/Translate/Translate';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import { countryCodeFlags, documentTypes } from '../../../../constants';
import { required } from '../../../../common_components/Form/utils/validations';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDate } from '../ImportDocument/ImportDocument.validations';
import {
  docKindsListUz,
  reasonCancellation, reasonCancellationListUz,
  reasonEntry,
} from './NotificationOfEntry.constants';
import { getCookie } from '../../../../utils/cookieHelpers';


const parseOption = (options) => options.map(item => ({
  ...item,
  label: Translate(item.label),
}));

const parseOptionReason = (docType, productGroup) => {
  let optionReason = [];

  if (docType === documentTypes.KZ_INTRODUCE_GOODS)
    optionReason = parseOption(reasonEntry);
  else {
    optionReason = parseOption(reasonCancellation)
  }
  return optionReason;
};

export const NotificationOfEntryFields = ({ docType }) => {
  const optionReason = parseOptionReason(docType, getCookie('productGroup'));
  const optionsUz = useMemo(()=> parseOption(reasonCancellationListUz),[reasonCancellationListUz]);
  const docKindsUzTranslated = useMemo(()=> parseOption(docKindsListUz),[docKindsListUz]);
  const documentDateName = useMemo(() =>
    countryCodeFlags.isUZ
      ? 'date'
      : 'permit_document_date',
    [countryCodeFlags.isUZ]
  );
  const documentNumberName = useMemo(() =>
    countryCodeFlags.isUZ
      ? 'number'
      : "permit_document_number",
    [countryCodeFlags.isUZ]
  );

  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>{Translate('Общие данные')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <Input
            // name={countryCodeFlags.isUZ ? 'senderTin' : 'applicant_inn'}
            name={'applicant_inn'}
            placeholder={
              countryCodeFlags.isUZ
                ? Translate("ИНН/ПИНФЛ и наименование отправителя")
                : docType === documentTypes.KZ_INTRODUCE_GOODS
                ? Translate(
                'ИИН/БИН/Наименование Организации, осуществляющей ввод в оборот',
                )
                : Translate('ИИН/БИН/Наименование Организации')
            }
            disabled
            validate={required}
          />
          <Select
            placeholder={
              docType === documentTypes.KZ_INTRODUCE_GOODS
                ? Translate('Причина')
                : Translate('Причина вывода из оборота')
            }
            name={countryCodeFlags.isUZ ? "eliminationReason" : "reason"}
            options={countryCodeFlags.isUZ ? optionsUz :optionReason}
            forceValidate
            required
            validate={required}
          />
        </Grid>
      </Grid>
      <DividerOfContent/>
      <br/>
      <Grid container>
        <Grid xs={4}>
          <StepTitle uppercase>
            {Translate('Данные документа основания')}
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          { countryCodeFlags.isUZ ? (
            <Select
              placeholder={Translate('Вид документа основания')}
              name="type"
              options={docKindsUzTranslated}
              forceValidate
              required
              validate={required}
            />
            ) : (
            <Input
              forceValidate
              required
              name="permit_document_name"
              placeholder={Translate('Наименование документа основания')}
              validate={required}
            />
            )
          }
          <Input
            forceValidate
            required
            name={documentNumberName}
            placeholder={Translate('Номер документа основания')}
            validate={required}
          />
          <DatePicker
            required
            forceValidate
            name={documentDateName}
            placeholder={Translate('Дата документа основания')}
            maxDate={new Date()}
            validate={(...vals) => {
              return validateDate(...vals, 'DD.MM.YYYY');
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
