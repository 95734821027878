import React from 'react';
import PropTypes from 'prop-types';
import { compose, onlyUpdateForKeys } from 'recompact';
import { Container, Column } from '@crpt/react-page-layout';
import {
  ADDRESS_HINT_REQUESTER_POSTAL_CODE,
  ADDRESS_HINT_REQUESTER_REGION_CODE,
  ADDRESS_HINT_REQUESTER_AREA,
  ADDRESS_HINT_REQUESTER_CITY,
  ADDRESS_HINT_REQUESTER_SETTLEMENT,
  ADDRESS_HINT_REQUESTER_STREET,
  ADDRESS_HINT_REQUESTER_HOUSE,
  ADDRESS_HINT_REQUESTER_BLOCK,
} from '@crpt/react-dadata';
import { HintedAddressInput, Input } from '../../../Fields';

const FiasAddress = ({ name, query, ...rest }) => (
  <div>
    <Container fillWidth gutter={17}>
      <Column>
        <HintedAddressInput
          placeholder="Код региона"
          defaultValue={query.regionCode}
          type={ADDRESS_HINT_REQUESTER_REGION_CODE}
          query={query}
          onChange={rest.onRegionCodeChange}
        />
      </Column>
      <Column>
        <HintedAddressInput
          placeholder="Индекс"
          defaultValue={query.postalCode}
          type={ADDRESS_HINT_REQUESTER_POSTAL_CODE}
          query={query}
          onChange={rest.onPostalCodeChange}
        />
      </Column>
      <Column>
        {' '}
        <HintedAddressInput
          placeholder="Район"
          defaultValue={query.area}
          type={ADDRESS_HINT_REQUESTER_AREA}
          query={query}
          onChange={rest.onAreaChange}
        />
      </Column>
    </Container>
    <div>
      <HintedAddressInput
        placeholder="Город"
        defaultValue={query.city}
        type={ADDRESS_HINT_REQUESTER_CITY}
        query={query}
        onChange={rest.onCityChange}
      />
    </div>
    <div>
      <HintedAddressInput
        placeholder="Населенный пункт"
        defaultValue={query.settlement}
        type={ADDRESS_HINT_REQUESTER_SETTLEMENT}
        query={query}
        onChange={rest.onSettlementChange}
      />
    </div>
    <div>
      <HintedAddressInput
        placeholder="Улица"
        defaultValue={query.street}
        type={ADDRESS_HINT_REQUESTER_STREET}
        query={query}
        onChange={rest.onStreetChange}
      />
    </div>
    <Container fillWidth gutter={17}>
      <Column>
        {' '}
        <HintedAddressInput
          placeholder="Дом"
          defaultValue={query.house}
          type={ADDRESS_HINT_REQUESTER_HOUSE}
          query={query}
          onChange={rest.onHouseChange}
        />
      </Column>
      <Column>
        <HintedAddressInput
          placeholder="Корпус"
          defaultValue={query.block}
          type={ADDRESS_HINT_REQUESTER_BLOCK}
          query={query}
          onChange={rest.onBlockChange}
        />
      </Column>
      <Column>
        <Input
          placeholder="Офис"
          defaultValue={query.office}
          onChange={rest.onOfficeChange}
        />
      </Column>
    </Container>
  </div>
);

FiasAddress.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,
};

export default compose(onlyUpdateForKeys(['name']))(FiasAddress);
