import React from 'react';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 100,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 280,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Тип агрегации',
          'col2': <div>
              Возможность выбора агрегации:
              <ul>
                <li>Сводный</li>
                <li>Производитель</li>
              </ul>
          </div>
        },
        {
          'col1': 'Производитель',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
      ]
    }
  ]
}