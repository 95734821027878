import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { MultiSelectWithSearch } from 'src/common_components/MultiSelectWithSearch';
import { getProductGroups } from './utils';
import { FilterURLHelper } from 'src/utils/FilterURLHelper';

const ProductsGroupMultiSelect = ({
  onRef,
  name,
  onSelect,
  placeholder,
  inline,
  availableProductGroupNames,
}) => {
  const { t } = useTranslation();
  const [selectedGroups, setSelectedGroups] = useState([]);

  const productGroups = useMemo(()=>
    getProductGroups()
      .filter(({ pg }) => availableProductGroupNames.includes(pg))
      .map(pg=> ({ ...pg, title: t(pg.title) })),
    [availableProductGroupNames, t]
  );

  useEffect(() => {
    const param = FilterURLHelper.getParamByKey(name);
    if (param && !!productGroups.length) {
      if (Array.isArray(param)) {
        setSelectedGroups(productGroups.filter(v => param.includes(v.pg)));
      } else {
        const productGroup = productGroups.find(v => v.pg === param);
        setSelectedGroups([productGroup]);
      }
    }
  }, [name, productGroups]);

  const selectRefBinder = useCallback(el => onRef(el), [onRef]);

  return (
    <MultiSelectWithSearch
      name={name}
      onSelectRef={selectRefBinder}
      onSelect={onSelect}
      options={productGroups}
      defaultSelectValue={selectedGroups}
      inline={inline}
      placeholder={placeholder}
      savePlaceholder
    />
  );
};

ProductsGroupMultiSelect.defaultProps = {
  inline: false,
  onRef: () => {},
  onSelect: () => {},
};

ProductsGroupMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  onSelect: PropTypes.func,
  onRef: PropTypes.func,
  availableProductGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ProductsGroupMultiSelect.displayName = 'MultiSelect';

export default ProductsGroupMultiSelect;
