import styled from 'styled-components';

const TableCellWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export default TableCellWrapper;
