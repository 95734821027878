import { Icon } from '@crpt-ui/core';
import React, { useMemo } from 'react';
import { Link } from 'src/fragments/Link';
import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import FourthCell from './components/FourthCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { history } from '../../../../../store';

const Aggregation = ({ op }) => {
  const classes = useStyles();

  const onLinkClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(url);
  };

  const encodedPart = useMemo(() => {
    return encodeURIComponent(op.aggregationCis);
  }, [op]);

  return (
    <Layout>
      <FirstCell
        icon={<Icon name="Aggregation" />}
        title={Translate('Агрегирование')}
      />
      <SecondCell op={op} />
      <ThirdCell useLink op={op} heading={Translate('Документ об агрегировании')} />
      {op.aggregationCis ? (
        <FourthCell heading={Translate('В упаковку с кодом')}>
          <Link
            className={classes.link}
            onClick={e => onLinkClick(e, `/cis/list/${encodedPart}/basic-info`)}
            to={`/cis/list/${encodeURIComponent(encodedPart)}/basic-info`}
          >
            {op.aggregationCis}
          </Link>
        </FourthCell>
      ) : null}
    </Layout>
  );
};

export default Aggregation;
