import * as actions from '../ducks/Reconciliation.actions';
import Api from '../../../../../../common_components/Api/Api';
import { Toast } from '../../../../../../utils/Toast';
import { get } from 'lodash';
import { takeEvery, put, call } from 'redux-saga/effects';
// import {
//   errorMockData,
//   successMockData,
// } from '../ReconciliationDialog.constants';
import { prepareCodesForReconciliation } from '../Reconciliation.utils';

function* reconciliationDocumentSaga({ payload }) {
  const { scanned, id, codes, ownerInn, supplierInn, onFinish } = payload;
  yield put(actions.reconciliationLoading(true));

  const cises = prepareCodesForReconciliation(codes, ownerInn);
  const unknownCises = [];

  const requestPayload = {
    url: `/api/v3/facade/edo-api/reconciliation/${id}`,
    method: 'post',
    preloading: false,
    data: {
      supplierInn,
      scanned,
      cises,
      unknownCises,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(actions.reconciliationLoading(false));

    const unknownList = get(success, 'data.unknownCises');
    const list = get(success, 'data.cises', []).map(item => ({
      ...item,
      cisPackageType: item.packageType,
      name: item.productName,
      ownerInn: supplierInn,
      status: item.codeStatus,
    }));

    if (unknownList && unknownList.length > 0) {
      yield put(actions.setReconciliationDocumentErrors(unknownList));
      yield put(actions.setReconciliationDocument(list));
      yield onFinish(unknownList);
      const errorMessages = unknownList.map(
        item => `${item.cis} - ${item.reason}`,
      );
      Toast.showError({
        content: errorMessages,
      });
    } else {
      yield put(actions.setReconciliationDocumentErrors(null));
      yield put(actions.setReconciliationDocument(list));
      yield onFinish(null);
    }
    Toast.showSuccess({
      content: 'Сверка успешно произведена.',
    });
  }
  if (error) {
    yield put(actions.reconciliationLoading(false));
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

function* clearReconciliationDocumentSaga() {
  yield put(actions.setReconciliationDocument(null));
  yield put(actions.setReconciliationDocumentErrors(null));
}

function* createReconciliationDraftSaga({ payload }) {
  const { id, body, method } = payload;
  const requestPayload = {
    url:
      method === 'post'
        ? `/api/v3/facade/reconciliations`
        : `/api/v3/facade/reconciliations/${id}`,
    method,
    data: {
      document_id: id,
      body: JSON.stringify(body),
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    console.log(success);
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

export function* getReconciliationDraftSaga({ payload }) {
  const { id, onFinish } = payload;
  const requestPayload = {
    url: `/api/v3/facade/reconciliations/${id}`,
    method: 'get',
    errorNotify: false,
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const reconciliation = get(success, 'data.body', '[]');
    yield put(actions.setReconciliationDraft(JSON.parse(reconciliation)));
    onFinish && onFinish(true);
  }
  if (error) {
    yield put(actions.setReconciliationDraft(null));
    onFinish && onFinish(false);
  }
}

function* updateReconciliationDraftSaga(action) {}

export default function* watch() {
  yield takeEvery(actions.reconciliationDocument, reconciliationDocumentSaga);
  yield takeEvery(
    actions.clearReconciliationDocument,
    clearReconciliationDocumentSaga,
  );
  yield takeEvery(
    actions.createReconciliationDraft,
    createReconciliationDraftSaga,
  );
  yield takeEvery(actions.getReconciliationDraft, getReconciliationDraftSaga);
  yield takeEvery(
    actions.updateReconciliationDraft,
    updateReconciliationDraftSaga,
  );
}
