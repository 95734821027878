import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  margin-top: 16px;
  padding-bottom: 10px;
`;

export const Heading = styled.div`
  display: flex;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.73px;
  color: #000000;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  color: #000000;
`;

export const Info =  styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #636666;
`;

export const InfoGreen =  styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #66AC5C;
  ;
`;

export const Text =  styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #52535A;
  ;
`;

export const Divider =  styled.div`
  display: flex;
  height: 1px;
  border-bottom: 1px solid #D2D2D2;
`;


export const Blocks =  styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
`;

export const MainContent =  styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
`;

export const Card =  styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 240px;
  border-radius: 16px;
  padding: 32px 16px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  text-align: center;
  margin-right: 20px
`;

export const CardContent =  styled.div`
  width: 208px;
  height: 80px;
  margin-top: 30px;
`;

export const Cards =  styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ArticleCard =  styled.div`
  display: flex;
  height: 56px;
  justify-content: center;
`;

export const InfoCard =  styled.div`
  display: flex;
  height: 73px;
  width: 450px;
  justify-content: flex-start;
  border: 1px solid #66AC5C;
  padding: 16px; 
  gap: 8px;
  border-radius: 16px;
  margin-right: 10px;
`;

export const InfoCardContent =  styled.div`
  display: flex;
  flex-direction: column;
`;


export const Link = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #66AC5C;
  text-decoration: none;
  cursor: pointer;
  white-space: word-wrap;
  width: 300px;
`;

export const InfoLink = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #52535A;
  width: 100px;
  text-decoration: none;
`;
