import {combineReducers} from 'redux';
import {certificates} from './certificates';
import {language} from './language';
import {loginButtonDisabled} from './loginButtonDisabled';
import {certificateSelectDisabled} from './certificateSelectDisabled';

export default combineReducers({
  certificates,
  language,
  loginButtonDisabled,
  certificateSelectDisabled
});
