import React from 'react';
import { toast } from 'react-toastify';
import {Content} from '../common_components/Toast/Content';
import {CancelButton} from '../common_components/Toast/CancelButton';

export class Toast {

  static showError({content}) {
    return toast(<Content>{content}</Content>, {
      className: 'error-toast-container',
      closeOnClick: true,
      autoClose: 10 * 1000,
      hideProgressBar: true,
      closeButton: true
    });
  }

  static showSuccess({content}) {
    return toast(<Content>{content}</Content>, {
      className: 'success-toast-container',
      closeOnClick: true,
      autoClose: 10 * 1000,
      hideProgressBar: true,
      closeButton: true
    });
  }

  static showCancelable({content, onCancel}) {
    return toast(<Content>{content} {onCancel && <CancelButton onCancel={onCancel}>Отменить</CancelButton>}</Content>, {
      className: 'cancelable-toast-container',
      closeOnClick: false,
      autoClose: false,
      hideProgressBar: true,
      closeButton: false
    });
  }

  static showInfo({
                    content,
                    closeOnClick,
                    autoClose,
                    hideProgressBar,
                    closeButton,
                    position
                  }) {
    return toast(<Content>{content}</Content>, {
      className: 'cancelable-toast-container',
      closeOnClick: closeOnClick ? closeOnClick : true,
      autoClose: autoClose ? autoClose : 3000,
      hideProgressBar: hideProgressBar ? hideProgressBar : true,
      closeButton: closeButton ? closeButton : true,
      position: position !== undefined ? position : toast.POSITION.BOTTOM_RIGHT
    });
  }

  static closeToast(toastId) {
    toast.dismiss(toastId);
  }
}
