import { takeEvery, put, take, call, all, select } from 'redux-saga/effects';
import { get } from 'lodash';
import * as actions from './ExciseList.actions';
import Api from '../../../common_components/Api/Api';
import { notify } from 'react-notify-toast';

function* requestSaga(action) {
  const {
    payload: { filter },
  } = action;

  filter.payerRegion ? (filter.payerRegion = filter.payerRegion.id) : null;

  const listPayload = {
    url: `/api/v3/facade/excise/common/list`,
    method: 'POST',
    data: {
      filter,
      pagination: {
        limit: 10,
        needTotal: true,
        offset: 0,
      },
    },
  };

  const consolidatedPayload = {
    url: `/api/v3/facade/excise/common/consolidated`,
    method: 'GET',
    params: {
      ...filter,
    },
  };

  const result = yield all([
    call(Api.request, listPayload),
    call(Api.request, consolidatedPayload),
  ]);

  const resultData = result.reduce((prev, current) => {
    const [success] = current;
    if (success) return { ...prev, ...success.data };
  }, {});

  const resultErrors = result
    .filter(([success, error]) => error)
    .map(([success, error]) => {
      return get(error, 'message', 'error');
    });

  yield put(actions.loaded(resultData));
  yield put(actions.setError(resultData.errorCode));

  if (resultErrors.length) {
    yield call(notify.show, resultErrors.join('\n\n'), 'error', 3000);
  }
}

function* setPageSaga(action) {
  const {
    payload: { filter, pagination },
  } = action;

  filter.payerRegion ? (filter.payerRegion = filter.payerRegion.id) : null;

  const commonListPayload = {
    url: `/api/v3/facade/excise/common/list`,
    method: 'POST',
    data: {
      filter,
      pagination,
    },
  };

  const [success, error] = yield call(Api.request, commonListPayload);

  yield put(actions.loaded({ results: get(success, 'data.results', []) }));
  yield put(actions.setError(get(success, 'data.errorCode', null)));

  if (error) {
    yield call(notify.show, get(error, 'message', 'error'), 'error', 3000);
  }
}

function* downloadSaga({ payload }) {
  const params = payload.payerRegion
    ? { ...payload, payerRegion: payload.payerRegion.id }
    : { ...payload };

  const request = {
    url: `/api/v3/facade/excise/common/list/csv`,
    method: 'GET',
    type: 'csv',
    responseType: 'arraybuffer',
    params,
  };

  const [success, error] = yield call(Api.request, request);

  if (success) {
    try {
      const file = new Blob([success.data], { type: 'text/csv' });
      const filename = 'file';

      if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      else {
        // Others
        const a = document.createElement('a'),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } catch (error) {
      call(notify.show, get(error, 'message', 'error'));
    }
  }

  if (error) {
    yield call(notify.show, get(error, 'message', 'error'), 'error', 3000);
  }
}

export default function* watch() {
  yield takeEvery(actions.loadData, requestSaga);
  yield takeEvery(actions.setPage, setPageSaga);
  yield takeEvery(actions.download, downloadSaga);
}
