export const socialString = '<div style="display: flex; justify-content: center;gap: 30px;">\n' +
  '        <a href="https://t.me/aslbelgisi">\n' +
  '          <svg className="material-icons MuiIcon-root" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"\n' +
  '               fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '            <path fill-rule="evenodd" clip-rule="evenodd"\n' +
  '                  d="M3.375 10.319c5.369-2.4 8.949-3.981 10.74-4.745 5.114-2.182 6.177-2.561 6.87-2.574.483-.009.894.241.975.731.025.147.055.482.031.744-.277 2.987-1.476 10.235-2.086 13.58-.259 1.416-.767 1.89-1.259 1.937-1.07.1-1.881-.725-2.917-1.422-1.621-1.09-2.537-1.768-4.11-2.831-1.818-1.23-.64-1.905.396-3.009.272-.288 4.984-4.684 5.075-5.083.075-.326-.202-.434-.467-.372-.163.038-2.752 1.793-7.768 5.266-.735.518-1.4.77-1.997.757-.658-.015-1.923-.382-2.863-.695-1.153-.385-2.07-.588-1.99-1.24.041-.341.498-.689 1.37-1.044z"\n' +
  '                  fill="#6BAC5B"></path>\n' +
  '          </svg>\n' +
  '        </a>\n' +
  '        <a href="https://www.instagram.com/asl.belgisi">\n' +
  '          <svg className="material-icons MuiIcon-root" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"\n' +
  '               fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '            <path\n' +
  '              d="M12.004 15.279a3.19 3.19 0 0 1-3.19-3.191 3.19 3.19 0 0 1 3.19-3.192 3.19 3.19 0 0 1 3.192 3.192 3.19 3.19 0 0 1-3.192 3.19z"\n' +
  '              fill="#6BAC5B"></path>\n' +
  '            <path fill-rule="evenodd" clip-rule="evenodd"\n' +
  '                  d="M16.035 1.926H8.057c-3.443 0-6.13 2.687-6.13 6.046v7.979a6.021 6.021 0 0 0 6.046 6.046h7.978a6.021 6.021 0 0 0 6.047-6.046V7.972c.084-3.359-2.603-6.046-5.963-6.046zm-4.03 5.207c-2.772 0-4.955 2.267-4.955 4.955 0 2.687 2.267 4.955 4.954 4.955 2.688 0 4.955-2.268 4.955-4.955 0-2.688-2.183-4.955-4.955-4.955zm4.114-.337c0-.672.504-1.175 1.176-1.175.672 0 1.176.503 1.176 1.175s-.504 1.176-1.176 1.176a1.148 1.148 0 0 1-1.176-1.176z"\n' +
  '                  fill="#6BAC5B"></path>\n' +
  '          </svg>\n' +
  '        </a>\n' +
  '        <a href="https://www.facebook.com/markirovka.uzb">\n' +
  '          <svg className="material-icons MuiIcon-root" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"\n' +
  '               fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '            <path\n' +
  '              d="M22 16.19c0 3.64-2.17 5.81-5.81 5.81H15c-.55 0-1-.45-1-1v-5.77c0-.27.22-.5.49-.5l1.76-.03c.14-.01.26-.11.29-.25l.35-1.91a.303.303 0 0 0-.3-.35l-2.13.03c-.28 0-.5-.22-.51-.49l-.04-2.45c0-.16.13-.3.3-.3l2.4-.04c.17 0 .3-.13.3-.3l-.04-2.4c0-.17-.13-.3-.3-.3l-2.7.04a2.996 2.996 0 0 0-2.95 3.05l.05 2.75c.01.28-.21.5-.49.51l-1.2.02c-.17 0-.3.13-.3.3l.03 1.9c0 .17.13.3.3.3l1.2-.02c.28 0 .5.22.51.49l.09 5.7c.01.56-.44 1.02-1 1.02h-2.3C4.17 22 2 19.83 2 16.18V7.81C2 4.17 4.17 2 7.81 2h8.38C19.83 2 22 4.17 22 7.81v8.38z"\n' +
  '              fill="#6BAC5B"></path>\n' +
  '          </svg>\n' +
  '        </a>\n' +
  '        <a href="https://help.crpt-turon.uz">\n' +
  '          <svg className="material-icons MuiIcon-root" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24"\n' +
  '               fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '            <path\n' +
  '              d="M17 2.43H7c-3 0-5 2-5 5v6c0 3 2 5 5 5v2.13c0 .8.89 1.28 1.55.83L13 18.43h4c3 0 5-2 5-5v-6c0-3-2-5-5-5zM12 14.6a.749.749 0 1 1 0-1.5.749.749 0 1 1 0 1.5zm1.26-4.15c-.39.26-.51.43-.51.71v.21c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.21c0-1.16.85-1.73 1.17-1.95.37-.25.49-.42.49-.68 0-.5-.41-.91-.91-.91s-.91.41-.91.91c0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-1.33 1.08-2.41 2.41-2.41s2.41 1.08 2.41 2.41c0 1.14-.84 1.71-1.15 1.92z"\n' +
  '              fill="#6BAC5B"></path>\n' +
  '          </svg>\n' +
  '        </a>\n' +
  '      </div>'


export const getTitleTemplate = (title) => {
  return '<h2>' + title + '</h2>'
}

export const getHtmlTemplate = (text) => {
  return `<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>
    <body>
        ${text}
    </body>
</html>`;
};