export const exciseTheme = {
  blocker: {
    backgroundColor: 'rgba(33, 44, 66, 0)',
  },

  panel: {
    width: '547px',
    backgroundColor: '#FFFFFF',
    location: 'right',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 3px 6px rgba(0, 0, 0, 0.1)'
  },
};
