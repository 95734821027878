import { defaultTheme } from './default';
import { whiteTheme } from './white';
import { tabItem } from './tabItem';
import { selectedTabItem } from './selectedTabItem';
import { createUserTheme } from './createUser';
import { redTheme } from './red';
import { clearTheme } from './clear';
import { signTheme } from './sign';

export {
  defaultTheme as buttonDefaultThemeUZ,
  tabItem as buttonTabItemThemeUZ,
  selectedTabItem as buttonSelectedTabItemThemeUZ,
  whiteTheme as buttonWhiteThemeUZ,
  createUserTheme as buttonCreateUserThemeUZ,
  redTheme as buttonRedThemeUZ,
  clearTheme as buttonClearThemeUZ,
  signTheme as buttonSignThemeUZ,
};
