import React from 'react';
import styled from 'styled-components';
import graphicPresentation from './graphicPresentation.png';

const Wrapper = styled.div`
  padding: 0 28px;
  text-align: center;
  img {
    max-width: 100%;
  }
`

export default {
  title: 'Графическое представление при типе агрегации периода «Месяц»:',
  amountOfTable: 0,
  tables: [],
  otherContent: <Wrapper><img src={graphicPresentation} width="1000px" /></Wrapper>
}