import {actionTypes} from '../../constants';


export function currentUser(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.AUTH.LOGIN.GOT_INFO:
      return action.value;
    default:
      return state;
  }
}
