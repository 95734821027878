import axios from 'axios';
import { authInstance } from './authInstance';

// const CancelToken = axios.CancelToken;

export class ReceiptService {
  // static _getListCancel;

  static searchReceipt(params) {
    // if (ReceiptService._getListCancel)
    // ReceiptService._getListCancel('PREVENT_TOAST');

    return authInstance.request({
      url: `/api/v3/facade/receipt/${params}/info`,
      method: 'get',
      // cancelToken: new CancelToken(function(c) {
      //   ReceiptService._getListCancel = c;
      // }),
    });
  }
}
