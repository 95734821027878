import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  linkStyles: {
    fontWeight: 600, 
    lineHeight: '24px', 
    marginLeft: 8, 
    overflow:'hidden', 
    whiteSpace:'nowrap', 
    textOverflow: 'ellipsis', 
    display:'block'
  },
  itemWrapper: row => ({
    paddingLeft: row.depth * 26,
    display:'flex', 
    alignItems:'center',
    overflow:'hidden',
  }),
  button: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  plug: {
    height: 48,
    paddingLeft: 13,
    paddingRight: 13,
  },
  layoutStyle: {
    tableLayout: 'fixed',
  },
});
