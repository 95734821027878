import React from 'react';

import { SingleSelect } from '@crpt/react-select';
import {
  documentStatuses as docStatuses,
  documentStatusesNames,
} from '../../constants/documentStatuses';
import { FilterURLHelper } from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';
// import { statuses } from './CISStatusSelect';

const documentStatuses = Object.keys(docStatuses).map((v, i) => {
  return {
    id: i,
    title: Translate(documentStatusesNames[v]),
    documentStatus: v,
  };
});

class DocumentStatusSelect extends React.Component {
  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const status = documentStatuses.find(v => v.documentStatus === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {
    const { onRef } = this.props;

    return (
      <SingleSelect
        name="documentStatus"
        placeholder={Translate("Статус")}
        values={documentStatuses}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

DocumentStatusSelect.displayName = 'SingleSelect';

export default DocumentStatusSelect;
