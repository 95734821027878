import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Datatable from '@crpt-ui/datatable';
import { CodesRenderer, columns } from './Esf.constants';
import { compose } from 'redux';
import { PageWrapper, TableWrap } from './Esf.styled';
import { EsfCisModal } from './EsfCisModal';

const EsfGoodsTable = ({
                         selectedDocument,
                       }) => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const codes = useMemo(() => {
    const list = _.get(
      selectedDocument,
      'products',
      [],
    );
    const total = list.reduce((acc, item) => {
      const sum = item.sum;
      const vatSum = item.vatSum;
      const exciseSum = item.exciseSum;
      const totalSum = item.totalSum;
      return {
        price: 'Всего:',
        count: 'x',
        vatRate: 'x',
        exciseRate: 'x',
        sum: acc.sum + parseFloat(sum),
        vatSum: acc.vatSum + parseFloat(vatSum),
        exciseSum: acc.exciseSum + parseFloat(exciseSum),
        totalSum: acc.totalSum + parseFloat(totalSum),
      }
    }, { sum: 0, vatSum: 0, exciseSum: 0, totalSum: 0});

    return [...list, total];
  }, [selectedDocument]);

  const onOpenCisModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    const data =  row.marks.map((item) => item.kiz);
    setSelectedRow(data);
    setOpen(true)
  };

  const onCloseCisModal = () => {
    setSelectedRow([]);
    setOpen(false);
  };

  const prepareColumns = columns.map(column =>
    column.id === 'codes'
      ? {
        ...column,
        accessor: row => (
          <CodesRenderer
            row={row}
            onClick={e => onOpenCisModal(e, row)}
          />
        ),
      }
      : column,
  );

  return (
    <PageWrapper>
      <EsfCisModal
        codes={selectedRow}
        open={open}
        onClose={onCloseCisModal}
      />
      <TableWrap withTotal>
        <Datatable columns={prepareColumns} useSort={true} data={codes} />
      </TableWrap>
    </PageWrapper>
  );
};

EsfGoodsTable.propTypes = {};

export default compose(
)(EsfGoodsTable);

