import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../../FormContextWrapper';
import FieldsWrapper from '../FieldsWrapper';
import InputAdapter from '../../Adapters/InputAdapter';
import TwoLineLabelAdapter from '../../Adapters/TwoLineLabelAdapter';

const Input = props => {
  const {
    name,
    format,
    validate,
    parse,
    visibility,
    disabled,
    labelOnDisabled,
    ...passProps
  } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        {params => {
          if (params.disabled && labelOnDisabled) {
            return (
              <TwoLineLabelAdapter name={name} {...passProps} {...params} />
            );
          }

          return <InputAdapter name={name} {...passProps} {...params} />;
        }}
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.string,
  labelOnDisabled: PropTypes.bool,
};

Input.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  placeholder: undefined,
  errorPlaceholder: undefined,
  labelOnDisabled: false,
};

Input.displayName = 'Input';

export default Input;
