import styled from 'styled-components';

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 24px;
  color: #52535a;
`;

export const Header = styled.div`
  js-display: flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

export const HeaderText = styled.div`
  font-size: 24px;
`;

export const Controls = styled.div`
  js-display: flex;
  display: flex;
  justify-content: space-between;
  white-space: normal;
  align-items: flex-start;
`;

export const AcceptButtonWrap = styled.div`
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;
