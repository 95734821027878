import React, {useState, useCallback, useMemo} from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as appSelectors from '../App/App.selectors';
import * as authSelectors from '../Auth/Auth.selectors';
import { NavigationInfo } from './NavigationInfo/NavigationInfo';
import { GlobalSearchWrapper, PageHeaderWrapper } from './PageHeader.styled';
import GlobalSearch from '../../components/GlobalSearch';
import Header from '@crpt-ui/header';
import { Grid } from '@material-ui/core';
import { MenuApps } from './MenuApps/MenuApps';
import { UserProfile } from './UserProfile/UserProfile';
import { isFoiv } from './PageHeader.utils';
import Drawer from '../Drawer/Drawer';
import ScanHistory from '../ScanHistory/ScanHistory';
import { MaterialUiThemeKZ } from '../_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../_MaterialUi/ThemeUZ';
import LanguageMenu from '../../components/Language/Menu';

const CrptUiThemeUZ = createMuiTheme({
  ...MaterialUiThemeUZ,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
  },
});

const CrptUiThemeKZ = createMuiTheme({
  ...MaterialUiThemeKZ,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
  },
});

const PageHeaderComponent = ({ userInfo, config, isUserOGV, isOperator, countryCode }) => {
  const userAuthorities = userInfo.authorities ? userInfo.authorities : [];

  const [isDrawerOpen, setDrawer] = useState(false);

  const transferToRmt = useMemo(()=> {
    const quantityRmtRoles = userAuthorities.reduce((acc, item)=> (item.indexOf("RMT_") !== -1) ? acc + 1 : acc, 0)
    if (quantityRmtRoles === 1 && userAuthorities.includes("ROLE_RMT_SUPER_USER") && !userAuthorities.includes("ROLE_ADMIN")) {
      return false;
    }
    return userAuthorities && (userAuthorities.includes("ROLE_ADMIN") || quantityRmtRoles > 0 )
  },[userAuthorities])

  const setDrawerOpen = useCallback(() => setDrawer(true), []);
  const setDrawerClose = useCallback(() => setDrawer(false), []);

  return (
    <PageHeaderWrapper>
      <Header style={{ zIndex: '8' }}>
        <Grid container>
          <Grid item>
            <MenuApps
                isUserOGV={isUserOGV}
                transferToRmt={transferToRmt}
                isOperator={isOperator}
                inn={userInfo.inn}
            />
            {/*<ProductCategories isUserOGV={isUserOGV} allowedCategories={userInfo.allowedCategories} />*/}
            <NavigationInfo menuItems={config.menuItems} />
          </Grid>

          <Grid
            item
            style={{
              display: 'flex',
              flexGrow: '1',
            }}
          >
            {
              !isUserOGV && (
                <GlobalSearchWrapper>
                  <GlobalSearch />
                </GlobalSearchWrapper>
              )
            }
          </Grid>

          <Grid item>
            {/*{!isUserOGV && <VideoInfo />}*/}
            {/*{isUserOGV && <ScanHistoryBtn openDrawer={setDrawerOpen} />}*/}
          </Grid>
          <Grid item>
            <MuiThemeProvider
              theme={countryCode === 'KZ' ? CrptUiThemeKZ : CrptUiThemeUZ}
            >
              <Drawer open={isDrawerOpen} onClose={setDrawerClose}>
                <ScanHistory onClose={setDrawerClose} />
              </Drawer>
            </MuiThemeProvider>
            <LanguageMenu />
            <UserProfile
              countryCode={countryCode}
              isUserFoiv={isFoiv(userAuthorities)}
              isUserOGV={isUserOGV}
              balanceAccounts={userInfo.balance}
              userName={userInfo.full_name}
            />
          </Grid>
        </Grid>
      </Header>
    </PageHeaderWrapper>
  );
};

PageHeaderComponent.propTypes = {
  userInfo: PropTypes.shape({}),
  config: PropTypes.shape({}).isRequired,
  isUserOGV: PropTypes.bool,
  countryCode: PropTypes.string,
};

const mapState = state => ({
  config: appSelectors.config(state),
  userInfo: authSelectors.userInfo(state),
  isUserOGV: authSelectors.isUserOGV(state),
  isOperator: authSelectors.isOperator(state),
  countryCode: state.config.countryCode,
});

/**
 * Render page-header
 */
export const PageHeader = connect(mapState)(PageHeaderComponent);
