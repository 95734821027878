import { handleActions } from 'redux-actions';
import * as actions from './Auth.actions';
import { AuthState } from './Auth.constants';

const state = handleActions(
  {
    [actions.gotInfo]: () => AuthState.AUTHORIZED,
    [actions.clear]: () => AuthState.NOT_AUTHORIZED,
  },
  AuthState.PRELOADING,
);

const userInfo = handleActions(
  {
    [actions.gotInfo]: (_, { payload }) => payload || {},
    [actions.clear]: () => ({}),
  },
  {},
);

const startRoute = handleActions(
  {
    [actions.gotStartRoute]: (_, { payload }) => payload || {},
    [actions.clear]: () => ({}),
  },
  {},
);

const cert = handleActions(
  {
    [actions.certLoaded]: (_, { payload = null }) => payload,
  },
  null,
);

const company = handleActions(
  {
    [actions.saveCompany]: (_, { payload = {} }) => payload,
  },
  {},
);

const productGroups = handleActions(
  {
    [actions.productGroups]: (_, { payload = {} }) => payload,
  },
  {},
);


export default {
  startRoute,
  state,
  userInfo,
  cert,
  company,
  productGroups
};
