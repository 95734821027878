import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    color: '#7B8695',
    borderRadius: 8,
    padding: '6px 8px',
    alignItems: 'center',
    width: 'max-content',
    gap: 5,
    cursor: 'pointer',
    margin: '5px 0',
  },
  buttonSelected: {
    backgroundColor: '#00000014',
  },
  disabled: {
    cursor: 'initial'
  }
});
