import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { intersection } from 'lodash';
import { COLUMN_TYPES, NO_OPTION_NEEDED_COLUMNS, WIDTHS } from './constants';
import { TableCell } from '../common/Table/TableCellTooltip/';
import CIScis from '../../components/TableCells/CIS/CIScis';
import CISProductName from '../../components/TableCells/CIS/CISProductName';
import ParticipantNameBound from '../../containers/Bounds/ParticipantNameBound';
import DocumentType from '../TableCells/Document/DocumentType';
import DocumentId from '../TableCells/Document/DocumentId';

const Wrap = styled.div`
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
`;

export function getColumnsByScheme(scheme) {
  return scheme.map(op => {
    const basic = {
      Header: op.title,
      id: op.fields.value,
      accessor: op.fields.value,
      width: WIDTHS[op.type],
    };

    switch (op.type) {
      case COLUMN_TYPES.DATE:
        return Object.assign(basic, {
          accessor: c => op.fields.format 
          ? `${moment(c[op.fields.value]).format(op.fields.format)}`
          : `${moment(c[op.fields.value]).format('DD.MM.YYYY')}`,
        });

      case COLUMN_TYPES.DOCUMENT:
      case COLUMN_TYPES.CIS:
      case COLUMN_TYPES.PARTICIPANT:
      case COLUMN_TYPES.PRODUCT:
      default:
        return basic;
    }
  });
}

export function getOptionsByScheme(scheme) {
  return scheme
    .filter(op => !intersection([op.type], NO_OPTION_NEEDED_COLUMNS).length)
    .map(op => {
      const basic = {
        id: op.fields.value,
        accessor: opt => opt,
      };

      switch (op.type) {
        case COLUMN_TYPES.DOCUMENT:
          return Object.assign(basic, {
            render: props => {
              const { onMouseEvent, ...otherProps } = props;
              const options = otherProps.options[op.fields.value];
              const type = options._original[op.fields.type];
              const date = options._original[op.fields.date];
              const id = options._original[op.fields.value];
              const status = options._original[op.fields.status];

              return (
                <TableCell onMouseEvent={onMouseEvent}>
                  <Wrap>
                    {type && <DocumentType type={type} {...props} />}
                    {/* {date && (
                      <div>{moment(date).format(op.fields.format)}</div>
                    )} */}
                    {id && <DocumentId status={status || ' '} id={id} {...props} />}
                  </Wrap>
                </TableCell>
              );
            },
          });

        case COLUMN_TYPES.PARTICIPANT:
          return Object.assign(basic, {
            render: props => {
              const { onMouseEvent, ...otherProps } = props;
              const name = otherProps.options[op.fields.value][op.fields.value];
              const inn =
                otherProps.options[op.fields.value]._original[op.fields.inn];

              return (
                <TableCell onMouseEvent={onMouseEvent}>
                  <ParticipantNameBound name={name} inn={inn} {...otherProps} />
                </TableCell>
              );
            },
          });

        case COLUMN_TYPES.PRODUCT:
          return Object.assign(basic, {
            render: props => {
              const { onMouseEvent, ...otherProps } = props;

              const cis = props.options[op.fields.value];

              const packageType = cis._original[op.fields.packageType];
              const gtin = cis._original[op.fields.gtin];
              const productName = cis._original[op.fields.value];

              return (
                <TableCell onMouseEvent={onMouseEvent}>
                  <CISProductName
                    packageType={packageType}
                    cis={cis}
                    gtin={gtin}
                    productName={productName}
                    {...otherProps}
                  />
                </TableCell>
              );
            },
          });

        case COLUMN_TYPES.CIS:
          return Object.assign(basic, {
            render: props => {
              const { onMouseEvent, ...otherProps } = props;
              return (
                <TableCell onMouseEvent={onMouseEvent}>
                  <CIScis {...props} />
                </TableCell>
              );
            },
          });

        default:
          return basic;
      }
    });
}
