import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../Form/Form';
import SubmitButton from '../Buttons/SubmitButton';
import ClearButton from '../Buttons/ClearButton';
import {
  HeaderWrapper,
  TextHeaderWrapper,
  ModalClose,
  ContentWrapper,
  ButtonsBarWrapper,
} from './ConfirmModal.styled';
import { Dialog } from '@material-ui/core';


const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  header: PropTypes.object.isRequired,
  content: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  cancelButtonText: PropTypes.object,
  submitButtonText: PropTypes.object,
};

const defaultProps = {
  data: {},
  cancelButtonText: 'Отменить',
  submitButtonText: 'Применить',
};

const buttonStyle = { minWidth: '100%' };

const ConfirmModal = props => {
  const {
    isModalOpen,
    header,
    content,
    data,
    cancelButtonText,
    submitButtonText,
    onClose,
    onCancelFunction,
    onSubmit,
    notCancelable
  } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
    >
      <HeaderWrapper notCancelable={notCancelable} {...props}>
        <TextHeaderWrapper>{header}</TextHeaderWrapper>
        {!notCancelable && <ModalClose onClick={onClose}>✖</ModalClose>}
      </HeaderWrapper>

      <Form data={data} onSubmit={onSubmit}>
        <ContentWrapper>{content}</ContentWrapper>

        <ButtonsBarWrapper color={props.color}>
          <ClearButton style={buttonStyle} onClick={onCancelFunction ? onCancelFunction : onClose}>
            {cancelButtonText}
          </ClearButton>
          <SubmitButton style={buttonStyle}>
            {submitButtonText}
          </SubmitButton>
        </ButtonsBarWrapper>
      </Form>

    </Dialog>
  );
};

ConfirmModal.propTypes = propTypes;
ConfirmModal.defaultProps = defaultProps;

export default ConfirmModal;
