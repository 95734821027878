import { handleActions } from 'redux-actions';
import * as actions from './Appeals.actions';

const violations = handleActions(
  {
    [actions.loadedViolation]: (state, { payload }) => payload
  },
  {}
);

const goodsGroups = handleActions(
  {
    [actions.loadedGoodsGroups]: (state, { payload }) => payload
  },
  {}
);

const territorialUnits = handleActions(
  {
    [actions.loadedTerritorialUnits]: (state, { payload }) => payload
  },
  {}
);

const violationCategories = handleActions(
  {
    [actions.loadedViolationCategories]: (state, { payload }) => payload
  },
  {}
);

const violationTypes = handleActions(
  {
    [actions.loadedViolationTypes]: (state, { payload }) => payload
  },
  {}
);

export default {
  violations,
  goodsGroups,
  territorialUnits,
  violationCategories,
  violationTypes
};
