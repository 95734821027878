import React, { useMemo } from 'react';

export const makeColoredIconAndText = ({ colors, icons, values, value }) => {
  const currentValue = useMemo(() => _.get(values, value, '-'), [value]);
  const color = useMemo(() => _.get(colors, value), [value]);
  const icon = useMemo(() => _.get(icons, value), [value]);

  return (
    <span
      style={{
        color: color,
        display: 'flex',
        fontWeight: 600,
      }}
    >
      {icon ? (
        <span
          style={{
            marginRight: 8,
          }}
        >
          {icon}
        </span>
      ) : null}
      {currentValue}
    </span>
  );
};