import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { AddAikBarWrapper, AikBarButtons } from './Aik.constants';
import { createAik } from './ducks/Aik.actions';
import { withModal } from '../../../../common_components/Modals/hocs/withModal';
import { Translate } from '../../../../common_components/Translate/Translate';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import ConfirmModal from '../../../../common_components/Modals/ConfirmModal/ConfirmModal';
import AddIdentificationCodes from '../../../../common_components/AddIdentificationCodes/AddIdentificationCodes';
import { withRouter } from 'react-router-dom';


const AddAikBar = (props) => {
  const { onOpenModal, onSetContent, onCloseModal, createAik } = props;
  const { formMutators, formValues, match, selectedRows } = props;
  const { id } = match.params;
  const [isModalOpen, setIsOpenModal] = useState(false);
  const {t} = useTranslation();

  const openLoadCisListModal = () => {
    onSetContent(
      <AddIdentificationCodes
        formMutators={formMutators}
        onCloseModal={onCloseModal}
        types=".xls, .xlsx"
      />
    );
    onOpenModal();
  }

  const onClose = () => {
    setIsOpenModal(false);
  }

  const onSubmit = () => {
    createAik({
      values: formValues,
      formMutators,
      id
    })
  }

  const createAikDisabled = useMemo(() =>
    !formValues.pg ||
    !formValues.count ||
    !formValues.gtin ||
    !formValues.tnved ||
    !formValues.products ||
    formValues.aik ||
    formValues.products.length === 0
    , [formValues])

  return (
    <AddAikBarWrapper>
      <Input
        name="aik"
        style={{ width: 250 }}
        placeholder={t('АИК')}
        disabled
        InputProps={{
          inputMode: 'numeric'
        }}
      />
      <AikBarButtons>
        <PrimaryActionButton
          disabled={!!formValues.aik}
          onClick={openLoadCisListModal}
        >
          {t('Загрузить XLS')}
        </PrimaryActionButton>
        <PrimaryActionButton
          disabled={createAikDisabled}
          onClick={()=> setIsOpenModal(true)}
        >
          {t('Сформировать АИК')}
        </PrimaryActionButton>
      </AikBarButtons>
      <ConfirmModal
        isModalOpen={isModalOpen}
        header={Translate("Предупреждение")}
        content={(
          <div>
            {Translate('Вы действительно хотите сгенерировать АИК?')}
            <br />
            {Translate('После генерации АИК редактирование документа будет невозможно')}
          </div>
        )}
        submitButtonText={Translate("Да")}
        cancelButtonText={Translate("Нет")}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </AddAikBarWrapper>
  )
}

const mapDispatch = dispatch => ({
  createAik: data => dispatch(createAik(data)),
})

export default  compose(connect(null, mapDispatch), withModal, withRouter)(AddAikBar);
