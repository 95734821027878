import React from 'react';
import styled from 'styled-components';
import { CheckIcon } from '../icons';

const IconWrap = styled.div`
  width: 40px;
  display: inline-flex;
  justify-content: flex-end;
`;

export const SelectedOptionsIcon = () => {
  return (
    <IconWrap>
      <CheckIcon />
    </IconWrap>
  );
};
