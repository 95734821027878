import React, { Component } from 'react';
import styled from 'styled-components';

import Card from '@crpt/react-card';
import { Icon } from '@crpt/react-icon';
import {Translate} from '../../common_components/Translate/Translate';

const cardTheme = {
  background: '#FFFFFF',
  paddingLeft: '32px',
  paddingTop: '32px',
  paddingRight: '80px',
  paddingBottom: '40px',
};

const Header = styled.div`
  js-display: flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

const HeaderText = styled.div`
  font-size: 24px;
  color: #52535a;
  max-width: 400px;
`;

const CloseButton = styled.div``;

const EnterText = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: #52535a;
  margin-top: 58px;
  width: 335px;
  font-weight: 600;
`;

const Container = styled.div`
  width: 100%;
  color: #212c42;
  margin: 24px 0 40px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const ListItem = styled.div`
  js-display: flex;
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '27px')};
`;

const Number = styled.div`
  background: #f8ec31;
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 29px;
  color: #52535a;
`;

const Blank = styled.div`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  margin-right: 29px;
`;

const Content = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: ${props => (props.color ? props.color : '#212c42')};
  max-width: 380px;
  font-weight: 400;
`;

const LightLink = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
`;

class LoginFormSidebarContent extends Component {
  render() {
    const { config } = this.props.App;

    return (
      <Card theme={cardTheme}>
        <Header>
          <HeaderText>{Translate('Начисленный акцизный налог за текущий период')}</HeaderText>
          <CloseButton>
            <Icon
              type="cross"
              width={24}
              height={24}
              onClick={this.props.actions.Sidebar.sidebarClosing}
            />
          </CloseButton>
        </Header>
        <Container>
          <ListItem marginBottom={'12px'}>
            <Number>1</Number>
            <Content>
              {Translate('Для каждого документа участника о передаче прав на табачную продукцию акцизный налог рассчитывается по формуле:')}
            </Content>
          </ListItem>
          <ListItem>
            <Blank />
            <Content color={'#A5A5A5'}>
              {Translate('Сумма акциза по сделке = 1718 тенге. х (кол-во сигарет в штуках: 1000 шт) х МРЦ х кол-во пачек х 14,5%, где 1718 тенге. - ставка налога с 01.07.2018')}
            </Content>
          </ListItem>
          <ListItem marginBottom={'12px'}>
            <Number>2</Number>
            <Content>{Translate('Рассчитываем сумма акциза по минимальной ставке:')}</Content>
          </ListItem>
          <ListItem>
            <Blank />
            <Content color={'#A5A5A5'}>
              {Translate('2335 тенге. : 1000 шт х кол-во сигарет в штуках, где 2335 тенге. – минимальная ставка акциза с 01.07.2018.')}
            </Content>
          </ListItem>
          <ListItem>
            <Number>3</Number>
            <Content>
              {Translate('Сравниваем сумму акциза с суммой акциза по минимальной ставке, из которой берем наибольшее значение.')}
            </Content>
          </ListItem>
          <ListItem>
            <Number>4</Number>
            <Content>
              {Translate('Сумма акцизов за выбранный период = сумма акцизов по всем сделкам на основе УПД с учетом актуальных изменений (УПД(и), УКД, УКД(и)) Производителя/Импортера за период.')}{' '}
            </Content>
          </ListItem>
        </Container>
      </Card>
    );
  }
}

LoginFormSidebarContent.propTypes = {};

export default LoginFormSidebarContent;
