/* eslint-disable */
import moment from 'moment';

const splitKeep = (str,splitter, ahead) => {
  const self = str;
  const result = [];
  if (splitter !== '') {
    // Substitution of matched string
    const getSubst = (value) => {
      const substChar = value[0] == '0' ? '1' : '0';
      let subst = '';
      for (let i = 0; i < value.length; i++) {
        subst += substChar;
      }
      return subst;
    };
    const matches = [];
    // Getting mached value and its index
    const replaceName = splitter instanceof RegExp ? 'replace' : 'replaceAll';
    const r = self[replaceName](splitter, function(m, i, e) {
      matches.push({value: m, index: i});
      return getSubst(m);
    });
    // Finds split substrings
    let lastIndex = 0;
    for (let i = 0; i < matches.length; i++) {
      const m = matches[i];
      const nextIndex = ahead ? m.index : m.index + m.value.length;
      if (nextIndex !== lastIndex) {
        const part = self.substring(lastIndex, nextIndex);
        result.push(part);
        lastIndex = nextIndex;
      }
    }
    if (lastIndex < self.length) {
      const part = self.substring(lastIndex, self.length);
      result.push(part);
    }
  } else {
    result.add(self);
  }
  return result;
};

export const getX500Val = (s, f) => {
  const res = splitKeep(s,/,[A-Z]+=/g, true);
  for (let i in res) {
    const n = res[i].search((i > 0 ? ',' : '') + f + '=');
    if (n !== -1) {
      return res[i].slice(n + f.length + 1 + (i > 0 ? 1 : 0));
    }
  }
  return '';
};

const dates = {
  convert: function(d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return (
      d.constructor === Date ? d :
        d.constructor === Array ? new Date(d[0], d[1], d[2]) :
          d.constructor === Number ? new Date(d) :
            d.constructor === String ? new Date(d) :
              typeof d === 'object' ? new Date(d.year, d.month, d.date) :
                NaN
    );
  },
  compare: function(a, b) {
    // Compare two dates (could be of any type supported by the convert
    // function above) and returns:
    //  -1 : if a < b
    //   0 : if a = b
    //   1 : if a > b
    // NaN : if a or b is an illegal date
    // NOTE: The code inside isFinite does an assignment (=).
    return (
      isFinite(a = this.convert(a).valueOf()) &&
      isFinite(b = this.convert(b).valueOf()) ?
        (a > b) - (a < b) :
        NaN
    );
  },
  inRange: function(d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return (
      isFinite(d = this.convert(d).valueOf()) &&
      isFinite(start = this.convert(start).valueOf()) &&
      isFinite(end = this.convert(end).valueOf()) ?
        start <= d && d <= end :
        NaN
    );
  },
};

const uiCreateItem = (itmKey, vo) => {
  return {
    id: itmKey,
    name: vo.CN,
    position: vo.T,
    date: `${moment(vo.validFrom).format('DD.MM.YYYY')} - ${moment(vo.validTo).format('DD.MM.YYYY')}`,
    source: vo.subjectName,
    issuer: vo.issuerName,
    number: vo.serialNumber,
    ...vo,
  };
};

export const itemGeneration = (o, i) => 'itm-' + o.serialNumber + '-' + i;

export const prepareAllCertificateList = (certkey, pfx, ftjc) => {
  const certkeyList = getCertKeys(certkey.certificates);
  const pfxList = getPfxs(pfx.certificates);
  const ftjcList = getFTJC(ftjc.tokens);
  return [...certkeyList, ...pfxList, ...ftjcList];
};

export const getCertKeys = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];
    const vo = {
      disk: el.disk,
      path: el.path,
      certName: el.name,
      serialNumber: el.serialNumber,
      subjectName: el.subjectName,
      validFrom: new Date(el.validFrom),
      validTo: new Date(el.validTo),
      issuerName: el.issuerName,
      publicKeyAlgName: el.publicKeyAlgName,
      CN: getX500Val(el.subjectName, 'CN'),
      TIN: getX500Val(el.subjectName, 'INITIALS'),
      O: getX500Val(el.subjectName, 'O'),
      T: getX500Val(el.subjectName, 'T'),
      type: 'certkey',
    };
    if (!vo.TIN) {
      continue;
    }
    const itmkey = itemGeneration(vo,rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};

export const getPfxs = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];
    let x500name_ex = el.alias.toUpperCase();
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.1=', 'INN=');
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.2=', 'PINFL=');
    const vo = {
      disk: el.disk,
      path: el.path,
      certName: el.name,
      alias: el.alias,
      serialNumber: getX500Val(x500name_ex, 'SERIALNUMBER'),
      validFrom: new Date(getX500Val(x500name_ex, 'VALIDFROM').replace(/\./g, '-').replace(' ', 'T')),
      validTo: new Date(getX500Val(x500name_ex, 'VALIDTO').replace(/\./g, '-').replace(' ', 'T')),
      CN: getX500Val(x500name_ex, 'CN'),
      TIN: (getX500Val(x500name_ex, 'INN') ? getX500Val(x500name_ex, 'INN') : getX500Val(x500name_ex, 'UID')),
      UID: getX500Val(x500name_ex, 'UID'),
      O: getX500Val(x500name_ex, 'O'),
      T: getX500Val(x500name_ex, 'T'),
      type: 'pfx',
    };
    if (!vo.serialNumber) {
      continue;
    }
    const itmkey = itemGeneration(vo, rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};

export const getFTJC = (list) => {
  const result = [];
  for (let rec in list) {
    const el = list[rec];
    let x500name_ex = el.info.toUpperCase();
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.1=', 'INN=');
    x500name_ex = x500name_ex.replace('1.2.860.3.16.1.2=', 'PINFL=');
    var vo = {
      cardUID: el.cardUID,
      statusInfo: el.statusInfo,
      ownerName: el.ownerName,
      info: el.info,
      serialNumber: getX500Val(x500name_ex, 'SERIALNUMBER'),
      validFrom: new Date(getX500Val(x500name_ex, 'VALIDFROM')),
      validTo: new Date(getX500Val(x500name_ex, 'VALIDTO')),
      CN: getX500Val(x500name_ex, 'CN'),
      TIN: (getX500Val(x500name_ex, 'INN')
        ? getX500Val(x500name_ex, 'INN')
        : getX500Val(x500name_ex, 'UID')),
      UID: getX500Val(x500name_ex, 'UID'),
      O: getX500Val(x500name_ex, 'O'),
      T: getX500Val(x500name_ex, 'T'),
      type: 'ftjc',
    };
    if (!vo.TIN) {
      continue;
    }
    const itmkey = itemGeneration(vo, rec);
    const itm = uiCreateItem(itmkey, vo);
    result.push(itm);
  }
  return result;
};
