import Datatable from '@crpt-ui/datatable';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Preloader from 'src/common_components/Preloader/Preloader';
import * as actions from 'src/components/CisDetails/ducks/CisDetails.actions';
import * as selectors from 'src/components/CisDetails/ducks/CisDetails.selectors';
import { columns } from './Agregation.constants';
import { Layout } from '@crpt/react-page-layout';
import { useStyles } from './Agregation.styles';

const Agregation = props => {
  const instance = useRef();
  const styles = useStyles();
  const { cisData, data, loaded } = props;
  const { cis } = cisData;

  useEffect(() => {
    const { onMount } = props;
    if (cis && !data.length) {
      onMount(cis);
    }
  }, [cis]);

  return (
    <React.Fragment>
      {!loaded ? <Preloader isOpened={true} /> :
      <Layout className={styles.layoutStyle}>
        <div className="hiddenScrollBar">
          <Datatable
            ref={instance}
            columns={columns}
            data={data}
            useExpanded
          />
        </div>
      </Layout>}
    </React.Fragment>
  );
}

Agregation.propTypes = {
  data: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
};

const mapState = state => ({
  data: selectors.agregation(state),
  loaded: selectors.agregationLoaded(state),
});

const mapDispatchToProps = {
  onMount: actions.mountedAgregation,
};

export default connect(mapState, mapDispatchToProps)(Agregation);
