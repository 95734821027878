import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get, flattenDeep, isEmpty } from 'lodash';
import { notify } from 'react-notify-toast';
import Api from '../../../../common_components/Api/Api';
import * as actions from '../../ducks/DocumentDetails.actions';

function* loadSaga(action) {
  const { payload } = action;

  const { products } = payload;

  const codes = flattenDeep(
    products.map(product =>
      product.codes
        ? product.codes
        : product.cises
        ? product.cises
        : product.cis,
    ),
  ).map(code => ({
    gtin: '',
    sgtin: '',
    tnvedGroup: '',
    productName: '',
    ownerName: '',
    ownerInn: '',
    producerName: '',
    producerInn: '',
    status: '',
    emissionDate: '',
    producedDate: '',
    name: '',
    brand: '',
    model: '',
    statusEx: '',
    cis: code,
  }));

  const onlyCodes = codes.map(code => code.cis).filter(code => code);

  const requestPayload = {
    url: '/api/v3/facade/marked_products/search-by-cis-list',
    method: 'GET',
    params: {
      cisList: onlyCodes.join(','),
    },
  };

  const finalResult = {};

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    success.data.results.forEach(code => (finalResult[code.cis] = code));
  }

  if (error) {
    yield call(notify.show, error, 'error', 3000);
  }

  const resultCodes = codes.map(code => get(finalResult, code.cis, code));

  const resultProducts = products.map(product => {
    try {
      const filtered = resultCodes
        .filter(
          code =>
            (product.cises && product.cises.includes(code.cis)) ||
            (product.codes && product.codes.includes(code.cis)),
        )
        .shift();
      product.name = filtered.productName ? filtered.productName : product.name;
      product.gtin = filtered.gtin ? filtered.gtin : product.gtin;
      return product;
    } catch (e) {
      console.log('error', e);
      return product;
    }
  });

  yield put(
    actions.loadedMarkedList({ codes: resultCodes, products: resultProducts }),
  );
}

export default function* watch() {
  yield takeEvery(actions.loadMarkedList, loadSaga);
}
