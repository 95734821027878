/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import { get, some } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import * as actions from './ducks/IncomeList.actions';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import * as selectors from './ducks/IncomeList.selectors';
import * as sidebarActions from 'src/common_components/Sidebar/ducks/Sidebar.actions';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import * as filePreviewActions from 'src/common_components/FilePreview/ducks/FilePreview.actions';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import * as AppSelectors from 'src/common_components/App/App.selectors';
import IncomeListView from './IncomeList.view';
import { limit, columns, options } from './IncometList.constants';
import IncomeListFilters from './IncomeListFilters';
import Filters from 'src/common_components/List/Filters/Filters';
import FiltersButton from 'src/common_components/Buttons/FiltersButton';
import {
  prepareFromRange,
  prepareToRange,
} from 'src/common_components/Form/Form.utils';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import TakeSignReject from './Modals/TakeSignReject';
import {
  BlueButton,
  RedButton,
} from 'src/common_components/Buttons/Button.styled';
import OutcomeListView from '../OutcomeList/OutcomeList.view';
import { Translate } from '../../../common_components/Translate/Translate';
import moment from 'moment';

class IncomeList extends React.Component {
  static propTypes = {
    last: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pagesCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    filters: PropTypes.shape({}).isRequired,
    sorting: PropTypes.shape({}).isRequired,
    onMount: PropTypes.func.isRequired,
    onListMount: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterApply: PropTypes.func.isRequired,
    onOpenSidebar: PropTypes.func.isRequired,
    onOpenDocument: PropTypes.func.isRequired,
    onOpenNewFile: PropTypes.func.isRequired,
    uploadErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClearErrors: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSetContent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      actionBarLeft: this.getLeftActionBarItems(),
      actionBarRight: [<FiltersButton onClick={this.onOpenFiltersSidebar} />],
      isAddMenuOpen: false,
      isUploadMenuOpen: false,
      cellEvent: null,
      modalWindow: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    const { onMount, onListMount, countryCode } = this.props;
    onMount();
    if (countryCode !== 'UZ') {
      onListMount();
    }
  }

  componentWillUnmount() {
    this.props.onUnMount();
  }

  getLeftActionBarItems = () => {
    const { actionBarLeft } = this.props;
    return actionBarLeft;
  };

  handlTakeSignRejectModalOpen = key => {
    let data = this.props.results.filter((item, index) =>
      this.state.selectedRows.includes(index),
    );
    console.log('===data', data);
    let title;
    if (key === 'takeSign') title = Translate('Подписание принятых документов');
    else if (key === 'reject')
      title = Translate('Подписание отклоненных документов');
    this.setState(prev => ({
      ...prev,
      modalWindow: {
        isOpen: true,
        title,
        data,
      },
    }));
  };

  handlTakeSignRejectModalClose = () => {
    this.setState(prev => ({ ...prev, modalWindow: { isOpen: false } }));
    if (this.docList.state.isAllSelected) this.docList.toggleAll();
    else this.docList.clearSelected();
  };

  getBottomActionBarItems = () => {
    return [
      // <BlueButton hidden={this.state.selectedRows.length === 0}>
      //   <Button
      //     variant="contained"
      //     onClick={() => this.handlTakeSignRejectModalOpen('takeSign')}
      //   >
      //     <Icon type="checkmark" fill="#55b5e7" spacingRight={16} /> Принять и
      //     подписать
      //   </Button>
      // </BlueButton>,
      // <RedButton hidden={this.state.selectedRows.length === 0}>
      //   <Button
      //     variant="contained"
      //     onClick={() => this.handlTakeSignRejectModalOpen('reject')}
      //   >
      //     <Icon type="cross" fill="#FA8072" spacingRight={16} /> Отклонить
      //   </Button>
      // </RedButton>,
    ];
  };

  onClear = () => {
    const { onClear } = this.props;
    onClear();
    setTimeout(() => this.onOpenFiltersSidebar(), 0);
  };

  onOpenFiltersSidebar = () => {
    const { onOpenSidebar, filters, countryCode } = this.props;
    console.log('handleOpenFiltersSidebar filters',filters);
    const data = prepareToRange(filters);
    if (data.date_from) {
      data.date_from = moment(data.date_from, 'X')
        .utc()
        .format();
    }
    if (data.date_to) {
      data.date_to = moment(data.date_to, 'X')
        .utc()
        .format();
    }
    onOpenSidebar(
      <Filters
        onClear={this.onClear}
        data={data}
        onSubmit={this.onFilterApply}
        filters={<IncomeListFilters countryCode={countryCode} />}
      />,
    );
  };
  onFilterApply = params => {
    const { onFilterApply, selectedPage } = this.props;
    //onFilterApply(prepareFromRange({ ...params, page: selectedPage }));
    onFilterApply(prepareFromRange({ ...params, page: 1 }));
  };

  onRef = el => {
    this.docList = el;
  };

  onUpdate = () => {
    if (this.docList.state.isAllSelected)
      this.setState(prev => ({
        ...prev,
        selectedRows: this.docList.state.selectedRows,
      }));
    else {
      const selectedRows = [...this.docList.state.selectedRows];
      this.setState(prev => ({
        ...prev,
        selectedRows,
      }));
    }
  };

  onClickRow = row => {
    const locationOrigin = window.location.origin;
    const url =
      row.type === 1000
        ? `/documents/list/act-in/${row.id}`
        : `/documents/discrepancy_notice/view/${row.id}`;
    this.props.history.push(url);
  };

  render() {
    const {
      results,
      pagesCount,
      uploadErrors,
      onClearErrors,
      selectedPage,
      isLoading,
      last,
      isModalOpen,
      onCloseModal,
      isUserOGV,
      totalCount,
      userInfo,
    } = this.props;

    const {
      actionBarLeft,
      actionBarRight,
      cellEvent,
      selectedRows,
    } = this.state;
    console.log('filters',this.props.filters);
    return (
      <React.Fragment>
        <IncomeListView
          isUserOGV={isUserOGV}
          onRef={this.onRef}
          last={last}
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          cellEvent={cellEvent}
          results={results}
          pagesCount={pagesCount}
          selectedPage={selectedPage}
          columns={columns}
          options={options}
          actionBarLeft={actionBarLeft}
          actionBarBottom={this.getBottomActionBarItems()}
          actionBarRight={actionBarRight}
          notifyErrors={uploadErrors}
          onNotifyCancel={onClearErrors}
          isLoading={isLoading}
          onUpdate={this.onUpdate}
          userInfo={userInfo}
          getTrProps={(state, rowInfo) => ({
            onClick: () => this.onClickRow(rowInfo.original),
          })}
          pageCounterInvisible={true}
          totalCount={totalCount}
          selectedRows={selectedRows}
        />

        <TakeSignReject
          isModal={this.state.modalWindow}
          onClose={this.handlTakeSignRejectModalClose}
        />
      </React.Fragment>
    );
  }
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: selectors.pagesCount(state),
    totalCount: selectors.total(state),
    last: selectors.results(state).length < limit, //selectors.last(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    roles: authSelectors.roles(state),
    userInfo: authSelectors.userInfo(state),
    uploadErrors: selectors.uploadErrors(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
    isUserOGV: authSelectors.isUserOGV(state),
    countryCode: AppSelectors.countryCode(state),
  };
};
const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onOpenDocument: (id, type, docType) =>
    dispatch(actions.openDocument({ id, type, docType })),
  onOpenNewFile: (obj, meta) =>
    dispatch(filePreviewActions.openFile(obj, meta)),
  onClearErrors: () => dispatch(actions.clearErrors()),
  onErrors: val => dispatch(actions.gotErrors(val)),
  onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
  downloadDoc: id => dispatch(actions.downloadDoc(id)),
});
export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(IncomeList);
