import { get, intersection } from 'lodash';

export const getMenuItems = (orgRoles, authorities, menuItems) => {
  const filtered = menuItems
    .filter(i => !i.hide)
    .filter(r => {
      if (!r.checkPermissionFunc) return true;
      return r.checkPermissionFunc(authorities)
    })
    .map(r => {
      if (r.routes) {
        for (const route of r.routes) {
          if (
            intersection(route.orgRoles, orgRoles).length ||
            intersection(route.authorities, authorities).length
          ) {
            r.route = route.route;
          }
        }
      }
      return r;
    })
    .filter((r, i, all) => {
      const index = all.findIndex(inner => r.route === inner.route);
      return index === i;
    });

  return filtered;
};
