import React from 'react';

import {SingleSelect} from '@crpt/react-select';
import {FilterURLHelper} from '../../utils/FilterURLHelper';
import {participantStatuses} from '../../constants';

const statuses = Object.keys(participantStatuses).map((status, i) => ({
  id: i,
  value: status,
  status: status,
  title: participantStatuses[status]
}));

class ParticipantStatusSelect extends React.Component {


  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const status = statuses.find(v => v.status === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {

    const {onRef} = this.props;

    return (
      <SingleSelect
        name="status"
        placeholder="Статус"
        values={statuses}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

ParticipantStatusSelect.displayName = 'SingleSelect';

export default ParticipantStatusSelect;
