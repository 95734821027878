import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';
import * as actions from 'src/components/CisDetails/ducks/CisDetails.actions';
import * as agregationActions from './Agregation.actions';

const clearObj = obj => {
  const res = {};
  if (typeof obj === 'object') {
    Object.keys(obj).forEach(key => (res[key] = null));
  }
  return res;
};

function* __requestSaga(payload) {
  const { childs } = payload;

  const childsPayload = {
    url: `/api/v3/facade/cis/cis_list`,
    method: 'post',
    data: childs,
    preloading: false,
  };

  try {
    const [success, error] = yield call(Api.request, childsPayload);
    if (success) {
      const rows = Object.values(success.data);
      const [demoRow] = rows;
      const { childs } = demoRow;
      const emptyRow = clearObj(demoRow);
      const results =
        Array.isArray(childs) && childs.length
          ? rows.map(item => {
              const subRows = Array.isArray(item.childs) ? item.childs.map(child => ({
                ...emptyRow,
                cis: child,
              })) : undefined;
              return { ...item, subRows };
            })
          : rows;

      return results;
    }
  } catch (e) {
    console.log('e',e);
    return null;
  }
  return null;
}

function* loadRowSaga(action) {
  const { payload } = action;
  const { id, childs } = payload;

  yield put(agregationActions.init({ id }));

  const rows = yield call(__requestSaga, { childs });

  if (rows) {
    yield put(agregationActions.loaded({ id }));
    yield put(
      actions.loadedAgregationRow({ rowAgregation: rows, path: id.split('.') }),
    );
  } else {
    yield put(agregationActions.eror({ id }));
    yield call(Toast.showError, { content: 'Не удалось загрузить список!' });
  }
}

function* loadSaga(action) {
  const { payload } = action;

  const cisPayload = {
    url: `/api/v3/facade/cis`,
    method: 'post',
    preloading: false,
    data: payload,
  };

  const [success, error] = yield call(Api.request, cisPayload);

  if (success) {
    const { childs } = success.data;

    const rows = yield call(__requestSaga, { childs });

    if (rows) {
      yield put(actions.loadedAgregation(rows));
    } else {
      yield put(actions.loadedAgregation([]));
      yield call(Toast.showError, { content: 'Не удалось загрузить список!' });
    }
  }

  if (error) {
    yield put(actions.loadedAgregation([]));
    yield call(Toast.showError, { content: 'Не удалось загрузить список!' });
  }
}

export default function* watch() {
  yield takeLatest(actions.mountedAgregation, loadSaga);
  yield takeEvery(actions.expandedAgregation, loadRowSaga);
}
