import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          style: {
            textAlign: 'left',
            justifyContent: 'flex-start',
          },
          minWidth: 150,
          subheaders: []
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          style: {
            textAlign: 'left',
            justifyContent: 'flex-start',
          },
          minWidth: 630,
          subheaders: []
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [2, 3],
          'col2': <div>(Объём производства маркированных товаров производителя за выбранный период (в штуках и тенге) / Объём производства всех маркированных товаров на рынке за выбранный период (в штуках и рублях)) <strong>×</strong> 100%</div>
        }
      ]
    }
  ]
}
