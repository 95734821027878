export default {
  title: 'Табличное представление при выборе типа агрегации «По субъекту РК»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Субъект РК',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'Доля рынка по реализации товаров',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 340,
          subheaders: [
            {
              Header: '% в штуках',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: '% в тенге (по цене продажи)',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
        }
      ]
    },
  ]
}
