import { generateUUID, generateUUIDBig } from '../Draft/Draft.utils';
import moment from 'moment';
import fp from 'lodash/fp';
import { groupBy, get, isEmpty } from 'lodash';
import { cisKey, goodsKey } from './ImportThirdCountry.constants';
import { countryCodeFlags } from '../../../../constants';

export const  buildImportDocumentBody = (values) => {
  const uuid = generateUUID();
  return {
    fileId: generateUUIDBig(43),
    version: '1.0',
    sendingDateTime: moment().format(),
    document: {
      type: '1',
      primaryDocId: uuid,
      primaryDocNumber: uuid,
      primaryDocDate: moment().format('DD.MM.YYYY'),
      mainInfo: {
        importerInfo: {
          importerName: values.recipient_name,
          importerCode: values.recipient_inn,
        },
        exportCountry: values.country_export,
        customDocument: {
          docType: values.type_document,
          regNumber: values.registration_number,
          regDate: moment(values.registration_date, 'DD MM YYYY').format(
            'YYYY-MM-DD',
          ),
        },
        customDecisionInfo: {
          decisionCode: values.solve_code,
          decisionDateTime: `${moment(values.solve_date, 'DD MM YYYY').format(
            'YYYY-MM-DD',
          )}T${values.solve_time}:00Z`,
          customCode: values.customs_organization_code,
        },
        certificationInfo: {
          docType: values.confirming_document_type,
          docNumber: values.confirming_document_number,
          docDate: moment(values.confirming_document_data, 'DD MM YYYY').format(
            'YYYY-MM-DD',
          ),
        },
        originalDocInfo: {
          docNumber: values.notification_number,
          docDate: moment(values.notification_data, 'DD MM YYYY').format(
            'YYYY-MM-DD',
          ),
        },
      },
      productItems: values.productItems,
    },
  };
};

export const getProductItems = data =>
  fp.pipe(
    fp.get(cisKey),
    fp.map(item => {
      return {
        iCodeFromDeclaration: item.cis,
        products: getPropsForGTIN(data, item.cis),
      };
    }),
  )(data);

const getPropsForGTIN = (data, parentCis) => {
  const disaggregatedStructure = get(data, 'disaggregated.structure');
  const childCisesOfICodeFromDeclaration = disaggregatedStructure[parentCis];

  return fp.pipe(
    fp.get(goodsKey),
    fp.filter(item =>
      item.codes.find(code => childCisesOfICodeFromDeclaration.includes(code)),
    ),
    fp.map(item => ({
      tnved: item.tnved,
      gtin: item.gtin,
      originCountry: item.country,
      itemNumber: item.numberDT,
      iCodes: item.codes.filter(cis =>
        childCisesOfICodeFromDeclaration.includes(cis),
      ),
    })),
  )(data);
};

export const getGroupedProducts = data => {
  const productKeys = groupBy(data, 'gtin');
  return Object.keys(productKeys).map((key, idx) => {
    const productFirstCis = productKeys[key][0];
    return {
      idx: idx + 1,
      name: productFirstCis.productName,
      gtin: key,
      codes: productKeys[key].map(code => code.cis),
      ownerName: productFirstCis.ownerName,
      ownerInn: productFirstCis.ownerInn,
      producerName: productFirstCis.producerName,
      cisPackageType: productFirstCis.cisPackageType,
      tnved: productFirstCis.tnVedCode10,
      producerInn: productFirstCis.producerInn,
    };
  });
};

export const buildImportDocumentBodyForUz = data => {
  const uuid = generateUUIDBig(36);

  return {
    fileId: uuid,
    version: '1.0',
    sendingDateTime: moment().format(),
    document: {
      mainInfo: {
        importerInfo: {
          importerName: data.recipient_name,
          importerCode: data.recipient_inn,
        },
        exportCountry: data.country_export,
        customDocument: {
          declarationNumber: data.registration_number,
          declarationDate: data.registration_date,
          currency: data.currency,
          currencyRate: data.excise_tax_sum,
        },
      },
      productItems: getProductItemsUZ(data),
    },
  };
};

const getProductItemsUZ = data => {
  return fp.pipe(
    fp.get(cisKey),
    fp.map(item => {
      return {
        iCodeFromDeclaration: item.cis,
        products: getPropsForGTINUZ(data, item.cis),
      };
    }),
  )(data);
};

const getPropsForGTINUZ = (data, parentCis) => {
  const disaggregatedStructure = get(data, 'disaggregated.structure');
  const childCisesOfICodeFromDeclaration = disaggregatedStructure[parentCis];

  return fp.pipe(
    fp.get(goodsKey),
    fp.filter(item =>
      item.codes.find(code => childCisesOfICodeFromDeclaration.includes(code)),
    ),
    fp.map(item => ({
      tnved: item.tnved,
      gtin: item.gtin,
      originCountry: item.country,
      certificateNumber: item.permit_document,
      certificateDate: item.doc_allow_date,
      itemNumber: item.numberDT,
      customValue: item.custom_cost,
      unitPrice: item.item_price,
      ExciseTax: item.cis_tax,
      vat: item.vat_tax,
      iCodes: item.codes.filter(cis =>
        childCisesOfICodeFromDeclaration.includes(cis),
      ),
    })),
  )(data);
};

export const buildProductAfterDisagregationUzKz = (
  infoList,
  disaggregated,
  products,
  gtins,
) => {
  return Object.keys(disaggregated.gtinGrouped).map(gtin => {
    const info = gtins.find(item => item.gtin == gtin) || {};
    let handInfo = products.find(item => item.gtin == gtin) || {};

    if (isEmpty(handInfo) && countryCodeFlags.isUZ) {
      const firstCis = disaggregated.gtinGrouped[gtin][0].cis;
      const structure = get(disaggregated, 'structure', {});
      const groupedCis = Object.keys(structure).find(code => {
        return structure[code].includes(firstCis);
      });

      handInfo = products.find(item => item.codes.includes(groupedCis)) || {};
    }

    return {
      cisPackageType: info.packageType,
      codes: disaggregated.gtinGrouped[gtin].map(el => el.cis),
      gtin: info.gtin,
      name: info.name,
      ownerInn: info.ownerInn,
      ownerName: info.ownerName,
      producerInn: info.producerInn,
      producerName: info.producerName,
      tnved: info.tnVedCode10,
      numberDT: handInfo.numberDT,
      country: handInfo.country,
      cis_tax: handInfo.cis_tax,
      custom_cost: handInfo.custom_cost,
      doc_allow_date: handInfo.doc_allow_date,
      item_price: handInfo.item_price,
      permit_document: handInfo.permit_document,
      vat_tax: handInfo.vat_tax,
    };
  });
};
