const sort = (a, b, key) => {
  const first = a[key] || '';
  const second = b[key] || '';
  if (first > second) return 1;
  if (first < second) return -1;
  return 0;
};

export const sortLevel2 = (key1, key2, data) => {
  data.sort((a, b) => {
    return sort(a, b, key1);
  });

  data.sort((a, b) => {
    if (a[key1] === b[key1]) return sort(a, b, key2);
    else return 0;

  });
};
