import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'src/fragments/Modal';
import { useModalState } from 'src/hooks';
import { initFromUrl } from 'src/utils/urlUtils';

import { reportStatusEnum } from '../constants';
import * as grayAreaActions from '../ducks/GrayArea.actions';
import * as grayAreaSelectors from '../ducks/GrayArea.selectors';
import { signActionAvailable } from '../GrayAreaDocument/GrayAreaDocument.utils';
import { GrayAreaSignature } from '../GrayAreaSignature';
import { columns as rawColumns } from './GrayAreaTable.constants';
import { parseFilterFromUrlStr, getLinkValue } from './GrayAreaTable.utils';
import { getSortDataFromUrl } from 'src/utils/urlUtils';
import GrayAreaTableView from './GrayAreaTable.view';
import { Translate } from '../../../common_components/Translate/Translate';

const GrayAreaTableComponent = ({
  clear,
  documents,
  downloadGrayAreaReport,
  error,
  fetchDocuments,
  history,
  loaded,
  page,
  total
}) => {
  const columns = useMemo(() => {
    const preparedColumns = {
      ...rawColumns,
      reportLink: {
        ...rawColumns.reportLink,
        Cell: ({ row }) => {
          return getLinkValue(row.original, () => downloadGrayAreaReport(row.original.reportLink));
        },
      },
    };
    return Object.values(preparedColumns);
  },
  []);

  const getParamsFromUrl = useCallback(() => {
    const filterNames = columns.map(({ id }) => id);
    const { page, filters, sort } = initFromUrl(location.search, filterNames, parseFilterFromUrlStr);
    return { ...page, filters, sort };
  }, []);

  const initialParams = useMemo(() => getParamsFromUrl(), []);

  const instance = useRef();

  const pagesCount = useMemo(() => Math.ceil(total / 10), [total]);

  const onPageChange = useCallback(page => {
    const filters = _.get(instance, 'current.state.filters', []);
    const sort = _.get(instance, 'current.state.sortBy', []);

    fetchDocuments({
      filters,
      sort,
      history,
      page,
      pathname: location.pathname,
      updateHistory: true,
    });
  }, [instance]);

  useEffect(() => {
    const { page, filters, sort } = initialParams;

    fetchDocuments({ page, filters, sort: getSortDataFromUrl(sort) });
    return () => clear();
  }, []);

  const goToCreate = useCallback(
    () => {
      history.push('/gray-area-create');
    },
    [],
  );

  const onFilterApply = filters => {
    const sort = _.get(instance, 'current.state.sortBy', []);
    fetchDocuments({
      filters,
      sort,
      history,
      pathname: location.pathname,
      updateHistory: true,
    });
  };

  const resetFilters = () => instance.current.setAllFilters([]);

  const [currentDocument, setDocument] = useState({});
  const [isModalOpen, openModal, closeModal] = useModalState();

  const [selectedRowIds, setSelectedRowIds] = useState({});

  const rowActions = useMemo(() => [
    {
      callback: ({ original }) => downloadGrayAreaReport(original.reportLink),
      disabled: ({ original }) => original.reportStatus !== reportStatusEnum.READY,
      label: Translate('Скачать выгрузку'),
      actionKey: 'download',
    },
    {
      callback: ({ original }) => {
        setDocument(original);
        openModal();
      },
      disabled: ({ original }) => !signActionAvailable(original.periodClosingDocumentStatus, original.reportStatus),
      label: Translate('Подписать и отправить'),
      actionKey: 'sign',
    },
  ], []);

  const bulkActions = useMemo(() => {
    const selectedFlatRows = _.get(instance, 'current.selectedFlatRows', []);
    const rowsCount = selectedFlatRows.length;

    return rowActions
      .map(action => ({
        onClick: () => {
          selectedFlatRows.map(row => action.callback(row));
          instance.current.toggleAllRowsSelected(false);
        },
        label: action.label,
        isDownload: action.actionKey === 'download', // true - для Скачать выгрузку из массива действий
        isSign: action.actionKey === 'sign', // true - для Подписать и отправить из массива действий
      }))
      .filter(action => {
        let filteredDataCount = 0;

        // определение возможности подписания и отправки
        // для одной выбранной строки
        if (rowsCount === 1 && action.isSign) {
          filteredDataCount = selectedFlatRows.filter(item => signActionAvailable(item.original.periodClosingDocumentStatus, item.original.reportStatus)).length;
        }
        // определение возможности скачивания
        // для выбранных строк
        if (action.isDownload) {
          filteredDataCount = selectedFlatRows.filter(item => item.original.reportStatus === reportStatusEnum.READY).length;
        }

        return rowsCount === filteredDataCount;
      });
  }, [selectedRowIds, instance.current]);

  const bulkActionsDisabled = useMemo(() => _.keys(selectedRowIds).length === 0, [
    selectedRowIds,
  ]);

  const isSingleRowSelected = useMemo(() => _.keys(selectedRowIds).length === 1, [
    selectedRowIds,
  ]);

  const bulkTooltipTitle = useMemo(() => bulkActions.length > 0
    ? ''
    : isSingleRowSelected
      ? Translate('Действия для выбранной карточки недоступны')
      : Translate('Для выполнения массовых действий необходимо выбрать карточки с одинаковым разрешенным статусом'),
  [
    bulkActions.length,
    isSingleRowSelected,
  ]);

  const isDatatableEmpty = useMemo(() => {
    const { filters } = getParamsFromUrl();
    return loaded && !documents.length && !filters.length;
  }, [loaded, documents]);

  const onSortChange = selectedColumns => {
    const filters = _.get(instance, 'current.state.filters', []);
    const sort = _.get(instance, 'current.state.sortBy', []);
    fetchDocuments({
      filters,
      sort,
      history,
      pathname: location.pathname,
      updateHistory: true,
    });
  };

  return (
    <React.Fragment>
      <Modal maxWidth="md" useHeaderDivider title={Translate("Подписание документа")} isOpen={isModalOpen} onClose={closeModal}>
        <GrayAreaSignature
          calledFrom="table"
          id={currentDocument.id}
          documentInfo={currentDocument}
          onClose={closeModal}
          initialParams={getParamsFromUrl()}
          downloadGrayAreaReport={downloadGrayAreaReport}
        />
      </Modal>
      <GrayAreaTableView
        bulkActions={bulkActions}
        bulkActionsDisabled={bulkActionsDisabled}
        bulkTooltipTitle={bulkTooltipTitle}
        documents={documents}
        goToCreate={goToCreate}
        initialState={{ filters: initialParams.filters, sortBy: getSortDataFromUrl(initialParams.sort) }}
        instance={instance}
        loaded={loaded}
        onFilterApply={onFilterApply}
        resetFilters={resetFilters}
        onPageChange={onPageChange}
        page={page}
        pagesCount={pagesCount}
        rowActions={rowActions}
        setSelectedRowIds={setSelectedRowIds}
        total={total}
        columns={columns}
        isDatatableEmpty={isDatatableEmpty}
        onSortChange={onSortChange}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  documents: grayAreaSelectors.documentsResults(state),
  error: grayAreaSelectors.error(state),
  loaded: grayAreaSelectors.loaded(state),
  page: grayAreaSelectors.page(state),
  total: grayAreaSelectors.total(state),
});

const mapDispatchToProps = {
  clear: grayAreaActions.unMount,
  downloadGrayAreaReport: grayAreaActions.downloadReport,
  fetchDocuments: grayAreaActions.requestDocumentsList,
};

export const GrayAreaTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrayAreaTableComponent);
