import styled, { css } from 'styled-components';

export const Header = styled.div`
  height: 64px;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 61px;
  padding-left: 24px;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  color: #52535a;
`;

export const Download = styled.div`
  font-size: 16px;
  color: ${props => (props.disabled ? '#74757c' : '#55b5e7')};
  display: ${props => (props.hidden ? 'none' : 'inline-flex')};
  align-items: center;
  margin-top: -4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Wrapper = styled.div`
  display: ${props => props.hidden ? 'none' : 'block'};
`;

export const ColumnMenuWrapper = styled.div`
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #55b5e7;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  :active,
  :hover,
  :visited {
    text-decoration: none;
    color: #55b5e7;
  }
`;

export const OptionWrap = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NoWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  ${({ fontWeight })=>  fontWeight ? `font-weight: ${fontWeight};` : ''}
`;

export const DateName = styled.div`
  height: 77px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
`;

export const Wrap = styled.div`
  display: flex;
  position: absolute;
  left: -5px;
  top: 10px;
  min-height: 105px;
  padding-bottom: 20px;
  z-index: 2;
  background-color: white;
  width: 300px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.39);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.39);
  cursor: default;
`;

export const SelectWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  width: 100%;
`;

// TODO: delete IconWrapper
// IconWrapper нужен для устранения бага, связанного с отображением иконки box
export const IconWrapper = styled.span`
  ${({type}) => type === 'box' ? css`& path { mask: none }`: null}
`
