import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Input } from '@crpt/react-input';
import { submit, unmount } from './ducks/CheckStatus.actions';
import { error, success } from './ducks/CheckStatus.selectors';

class CheckStatus extends Component {
  state = {
    value: '',
    block: true,
  };

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount();
  }

  onChange = value => {
    if (Boolean(value)) {
      this.setState({ value, block: false });
    } else {
      this.setState({ block: true });
    }
  };

  onSubmit = () => {
    const { submit } = this.props;
    const { value, block } = this.state;
    if (!block) submit(value);
  };

  render() {
    const { children, error, success } = this.props;
    const { block } = this.state;
    return (
      <Fragment>
        <Input
          onChange={this.onChange}
          placeholder={'Номер заявки'}
        />
        {children({ submut: this.onSubmit, error, success, block })}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    error: error(state),
    success: success(state),
  }),
  { submit, unmount }
)(CheckStatus);
