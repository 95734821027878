export default {
  title: 'Табличное представление при типе агрегации «По товару»:',
  amountOfTable: 2,
  tables: [
    {
      headers: [
        {
          Header: 'GTIN',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Месяц производства',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Статус',
          accessor: 'col11',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Количество месяцев с даты производства',
          accessor: 'col12',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 300,
          subheaders: []
        },
        {
          Header: 'Количество, шт',
          accessor: 'col13',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col11': [11],
          'col12': [12],
          'col13': [13],
        }
      ]
    },
  ]
}