import styled from 'styled-components';

export const WrapIcons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  display: flex;
`;

export const WrapIcon = styled.div`
  height: 100%;
  width: 56px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isClicked }) => (isClicked ? 'auto' : 'none')};
`;
