import styled from 'styled-components';

export const PrimaryButtonWrap = styled.div`
  button {
    color: white
  };
   a {
    color: white
  };
`;

export const RedButton = styled.div`
  visibility: ${props=>props.hidden ? 'hidden' : 'visible'};
  button {
    color: #FA8072;
  }
`;

export const BlueButton = styled.div`
  visibility: ${props=>props.hidden ? 'hidden' : 'visible'};
  button {
    color: #55b5e7;
  }
`;