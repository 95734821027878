import { actionTypes } from '../../../constants';


export function pagesCount(state = 0, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_LOADED:
      return Math.ceil(action.value.total / action.value.limit);
    default:
      return state;
  }
}
