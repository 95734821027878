import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Box, Grid, Typography, MuiThemeProvider } from '@material-ui/core';
import { Close } from '@crpt-ui/icons';

import withForm from 'src/common_components/hocs/withForm';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionMediumButton';
import PrimaryActionButton from 'src/common_components/Buttons/MuiPrimaryActionMediumButton';

import { goodsKey } from '../ImportThirdCountry.constants';
import useStyles from './Goods.styled';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { connect } from 'react-redux';
import { Translate } from 'src/common_components/Translate/Translate';
import { GoodsEditKzFields } from './edit/GoodsEditKzFields';
import { GoodsEditUzFields } from './edit/GoodsEditUzFields';
import { MaterialUiThemeUZ } from '../../../../../common_components/_MaterialUi/ThemeUZ';
// import Datatable from '@crpt-ui/datatable';
// import Input from 'src/common_components/Form/MaterialFields/Input/Input';
// import DatePicker from 'src/common_components/Form/MaterialFields/DatePicker/DatePicker';
// import LightTheme from '../../../../../common_components/_MaterialUi/LightTheme';
// import {
//   validateDate,
//   validateNumberAfterDotRequiredAndNotEmpty,
// } from '../../ImportDocument/ImportDocument.validations';
// import { required } from 'src/common_components/Form/utils/validations';
// import CountrySelect from 'src/common_components/Form/MaterialFields/Select/CountrySelect';

const GoodsEditProduct = ({
  onCloseModal,
  formValues,
  formErrors,
  selectedRows,
  // onCancel,
  onReset,
  // preparedColumns,
  title = '',
  countryCode,
}) => {
  const classes = useStyles();
  // const [isTableOpen, setIsTableOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const index = +selectedRows[rowIndex];

  const isError = useMemo(() => {
    if (!formValues[goodsKey] || !formValues[goodsKey][index]) return true;
    if (
      formErrors[goodsKey] &&
      formErrors[goodsKey][index] &&
      Object.keys(formErrors[goodsKey][index]).length > 0
    )
      return true;
    return false;
  }, [formValues, formErrors]);

  return (
    <MuiThemeProvider
      theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
    >
      <div className={classes.content}>
        <div className={classes.iconCloseWrapper}>
          <Close
            onClick={() => {
              onReset();
              onCloseModal();
            }}
          />
        </div>
        <Typography variant="h4" color="textPrimary">
          {title}
        </Typography>

        <Box mx={-4} mt={2} mb={4}></Box>

        <Box color="text.primary" fontSize={14} mb={2}>
          {Translate(
            'Заполните уникальные параметры для выбранных товаров (Заполнено товаров',
          )}
          {': '}
          {rowIndex + 1} {Translate('из')} {selectedRows.length}
          {')'}
        </Box>
        {countryCode === 'UZ' ? (
          <GoodsEditUzFields index={index} />
        ) : (
          <GoodsEditKzFields index={index} />
        )}
        <Box mt={4}>
          <div className={classes.divider} />
        </Box>

        <div className={classes.buttonsWrapper}>
          <PrimaryActionButton
            disabled={isError}
            onClick={
              rowIndex === selectedRows.length - 1
                ? onCloseModal
                : e => {
                    setRowIndex(rowIndex + 1);
                  }
            }
          >
            {rowIndex === selectedRows.length - 1
              ? Translate('Сохранить и завершить')
              : Translate('Продолжить')}
          </PrimaryActionButton>
          <CommonActionButton
            onClick={() => {
              onReset();
              onCloseModal();
            }}
          >
            {Translate('Отменить')}
          </CommonActionButton>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

GoodsEditProduct.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  // onCancel: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  formErrors: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  // preparedColumns: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const mapState = state => ({
  countryCode: state.config.countryCode,
});
export default compose(connect(mapState), withForm)(GoodsEditProduct);
