import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';

import {
  HeadingWrap,
  HeadingSmallTitle,
  HeadingId,
} from '../../Details/styled';
import { documentStatusesNames, documentTypeNames } from 'src/constants';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { Translate } from '../../../common_components/Translate/Translate';
import moment from 'moment';
import { isNil } from 'lodash';

const DocHeadingWrap = styled(HeadingWrap)`
  padding-bottom: 24px;
`;

const DocIdAndLabelWrap = styled.div`
  display: flex;
  max-width: calc(100vw - 401px);
  width: calc(100vw - 401px);
  justify-content: ${({ hasIdOrStatus }) =>
    hasIdOrStatus ? 'space-between' : 'flex-start'};
  & > div:first-child {
    margin-right: ${({ hasIdOrStatus }) =>
    hasIdOrStatus ? '0' : '150px'};
  }
  & > div:nth-child(2) {
    margin-right: ${({ hasIdOrStatus }) =>
    hasIdOrStatus ? '0' : '50px'};
  }
`;

const GtkApprovedWrapper = styled.div`
  color: ${({ gtkApproved }) => gtkApproved ? 'green' : 'red'}
`;

const DateWrapper = styled.div`
  font-size: 18px;
  margin-left: 10px;
`;

const DocIdAndLabel = styled.div`
  display: flex;
  overflow: hidden;
  align-items: ${({ flexEnd }) => flexEnd ? 'flex-end' : 'center'};
`;

export const LinkAndLabel = styled.div`
  color: #ABADB6;
  display: flex;
  flex-direction: column;
`;

export const StatusWrapper = styled.div`
  display: flex;
`;

export default (props) => {
  const { date, gtkApproved, bottomId = false, downloadDoc } = props;
  const { title, id, status, needDocTitle, viewTime = false } = props;

  const hasIdOrStatus = id !== undefined || status !== undefined;

  return (
    <DocHeadingWrap>
      {
        needDocTitle && (
          <HeadingSmallTitle>{Translate('Документ')}</HeadingSmallTitle>
        )
      }
      <DocIdAndLabelWrap hasIdOrStatus={hasIdOrStatus}>
        <DocIdAndLabel flexEnd>
          <HeadingId>{typeof title === 'string' ? Translate(title) : title}</HeadingId>
          {date && (
            <DateWrapper>
              {Translate('от')} {moment(date).format(`DD.MM.YYYY ${ viewTime ? ', hh:mm:ss' : ''}`)}
            </DateWrapper>
          )}
        </DocIdAndLabel>
        {
          id !== undefined && !bottomId && (
            <DocIdAndLabel>
              <HeadingId>{id}</HeadingId>
            </DocIdAndLabel>
          )
        }
        <StatusWrapper>
          {
            status !== undefined && (
              <Button theme={BUTTON_THEMES.whiteTheme}>
                <Icon
                  type={getDocumentStatusIconByDocStatus(status)}
                  spacingRight={9}
                />
                {Translate(documentStatusesNames[status])}
              </Button>
            )
          }
          {
            downloadDoc && (
              <Button
                onClick={downloadDoc}
                variant="contained"
              >
                <Icon type="upload" spacingRight={16} />{' '}
                {Translate('Скачать документ')}
              </Button>
            )
          }
        </StatusWrapper>
        {
          (gtkApproved !== null && gtkApproved !== undefined) && (
            <GtkApprovedWrapper
              gtkApproved={gtkApproved}
            >
              {gtkApproved ? Translate('Принят в ГТК') : Translate('Не принят в ГТК')}
              {
                !gtkApproved && (
                  <div>
                    Причина: Отказ в регистрации АИК от ГТК
                  </div>
                )
              }
            </GtkApprovedWrapper>
          )
        }
      </DocIdAndLabelWrap>
      {
        bottomId && (
          <HeadingId grey>ID {id}</HeadingId>
        )
      }
    </DocHeadingWrap>
  );
};
