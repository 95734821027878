import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import {
  IconWrapper,
  TileWrapper,
  Header,
  Description,
  DetailedInfo,
} from './ReportTile.styled';
import ReportHelp from '../ReportHelp/ReportHelp';

class ReportTile extends React.Component {
  static propTypes = {
    report: PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  };
  iconRef = null

  state = {
    isMouseOver: false,
    isModalOpen: false,
  };

  onMouseEnter = () => {
    if(this.iconRef){
      const paths = this.iconRef.contentDocument.querySelectorAll('path')
      for(let p of paths){
        p.style.fill = '#fff'
      }
    }
    this.setState({ isMouseOver: true });
  };

  onMouseLeave = () => {
    if(this.iconRef){
      const paths = this.iconRef.contentDocument.querySelectorAll('path')
      for(let p of paths){
        p.style.fill = ''
      }
    }
    this.setState({ isMouseOver: false });
  };

  onClick = e => {
    const { report, namespace, history } = this.props;

    e.preventDefault();
    history.push(`${namespace}/${report.id}`);
  };

  onDetailedInfoClick = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isModalOpen: false,
      isMouseOver: false,
    });
  };

  render() {
    const { report, namespace } = this.props;
    const { isMouseOver, isModalOpen } = this.state;

    return (
      <Fragment>
        <TileWrapper
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onClick}
          href={`${namespace}/${report.id}`}
        >
          <IconWrapper isMouseOver={isMouseOver}>
            {report.iconURL ? (
              <div >
                <object type="image/svg+xml" data={report.iconURL} ref={e => this.iconRef = e}/>

              </div>
            ) : 
              report.iconName ? 
              (<Icon type={report.iconName} size={64} />)
              : report.icon
            }
            <Icon type="report-right-arrow" size={64} fill="#fff" />
          </IconWrapper>
          <Header>{report.name}</Header>
          <Description>{report.description}</Description>
          <DetailedInfo onClick={this.onDetailedInfoClick}>
        </DetailedInfo>
        </TileWrapper>
        <ReportHelp
          reportUrl={report.id}
          onRequestClose={this.closeModal}
          isOpen={isModalOpen}
          name={report.name}
          description={report.description}
          iconURL={report.iconURL}
          iconName={report.iconName}
        />
      </Fragment>
    );
  }
}

export default withRouter(ReportTile);
