import { modalActionTypes } from './Gtins.constants';

export const initialModalState = {
  isGtinsModalOpen: false,
  isUploadGtinsModalOpen: false,
}

export const reducerModal = (state, action) => {
  switch (action.type) {
    case modalActionTypes.GTINS_MODAL.OPEN:
      return {
        ...state,
        isGtinsModalOpen: true,
      }
    case modalActionTypes.GTINS_MODAL.CLOSE:
      return {
        ...state,
        isGtinsModalOpen: false,
      }
    case modalActionTypes.UPLOAD_GTINS_MODAL.OPEN:
      return {
        ...state,
        isUploadGtinsModalOpen: true,
      }
    case modalActionTypes.UPLOAD_GTINS_MODAL.CLOSE:
      return {
        ...state,
        isUploadGtinsModalOpen: false,
      }
    default:
      throw new Error();
  }
}
