import { handleAction } from 'redux-actions';
import * as actions from './CurrentSignatureDescription.action';


const certificateInformation = handleAction(
  actions.reloadedCertificateInformation,
  (state, { payload }) => payload,
  {},
);

export default {
  certificateInformation,
};
