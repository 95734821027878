import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { WrapImg, WrapPreloader, Text } from './Preloader.styled';

const PreloaderView = ({ isOpen, text, children, fullHeight }) => (
  <Fragment>
    {!isOpen && children}
    <WrapPreloader isOpen={isOpen} fullHeight={fullHeight}>
      <WrapImg id="lottie" />
      <Text>{text}</Text>
    </WrapPreloader>
  </Fragment>
);

PreloaderView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  fullHeight: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

export default PreloaderView;
