import React from 'react';

import {SingleSelect} from '@crpt/react-select';
import {FilterURLHelper} from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';
import { ARTICLE_TYPE_NAMES, ARTICLE_TYPES } from '../ArticleListPage/constants';

const documentTypes = (types) => Object.keys(types)
  .map((v, i) => {
    return {
      id: i,
      title: ARTICLE_TYPE_NAMES[v],
      value: types[v]
    };
  })

class ArticleTypeSelect extends React.Component {
  state = {
    values: [],
  };

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    const typesDictionary = ARTICLE_TYPES;
    const types = documentTypes(typesDictionary);
    this.setState({ values: types });
    if (value) {
      const status = types.find(v => v.value === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {

    const {onRef} = this.props;
    const {values} = this.state;

    return (
      <SingleSelect
        name="type"
        placeholder={Translate("Тип статьи")}
        values={values}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

ArticleTypeSelect.displayName = 'SingleSelect';

export default ArticleTypeSelect;

