import Api from 'src/common_components/Api/Api';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as grayAreaDocumentActions from './GrayAreaDocument.actions';
import { getFrontendTaskUrl } from '../../ducks/GrayArea.utils';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import i18next from 'i18next';

function* getDocumentInfo({ payload = {} }) {
  console.log('payload: ', payload);
  const { id } = payload;

  const requestPayload = {
    url: `/api/v3/gray-zone/gray-zone-document/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'get',
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(grayAreaDocumentActions.loaded(success.data));
  }
}

function* reformDocument({ payload = {} }) {
  const { id } = payload;

  yield call(console.log, 'test');
  const requestPayload = {
    url: `/api/v3/gray-zone/gray-zone-document/${id}/reform`,
    method: 'put',
    data: {
      notificationUrl: getFrontendTaskUrl('/gray-area/'),
    },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(grayAreaDocumentActions.loaded(success.data));
    yield call(Toast.showInfo, {
      content: i18next.t(
        'Заявка на формирование повторной выгрузки отправлена',
      ),
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export default function* watch() {
  yield takeLatest(grayAreaDocumentActions.getDocument, getDocumentInfo);
  yield takeLatest(grayAreaDocumentActions.reformDocument, reformDocument);
}
