import { css } from 'styled-components';

const TableFooter = {
  style: css`
    display: flex;

    flex: 1 0 auto;
    flex-direction: column;

    .rt-td {
      &:first-child {}
      &:last-child {}
    }
  `,
};

export default TableFooter;
