import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get, head, last } from 'lodash';
import moment from 'moment';
import { push } from 'react-router-redux';
import { saveAs } from 'file-saver/FileSaver';
import Api from '../../../../common_components/Api/Api';
import * as actions from './ReceiptList.actions';
import * as selectors from './ReceiptList.selectors';
import { limit } from '../ReceiptList.constants';
import * as listActions from '../../../../common_components/List/ducks/List.actions';
import { makeid } from '../../../../utils';
import { Toast } from '../../../../utils/Toast';

function ArrayBufferToString(buffer) {
  return BinaryToString(
    String.fromCharCode.apply(
      null,
      Array.prototype.slice.apply(new Uint8Array(buffer)),
    ),
  );
}

function BinaryToString(binary) {
  var error;

  try {
    return decodeURIComponent(escape(binary));
  } catch (_error) {
    error = _error;
    if (error instanceof URIError) {
      return binary;
    } else {
      throw error;
    }
  }
}

function* requestSaga(action = {}) {
  const { payload = {} } = action;
  const { page, dir } = payload;

  yield put(actions.updatePage(page));

  let paginationParams = {
    limit,
  };

  yield put(actions.pagination(paginationParams));

  //const newOffset = ((page || 1) - 1) * 10;
  //yield put(actions.updateOffset(newOffset));

  const filters = yield select(selectors.filters);
  const offset = yield select(selectors.offset);

  if (dir) {
    const results = yield select(selectors.results);
    if (Array.isArray(results) && results.length && dir) {
      const current = dir === 'PREV' ? results[0] : results[results.length - 1];
      const { did, senderInn, receiptDate } = current;
      paginationParams = {
        ...paginationParams,
        pageDir: dir,
        did,
        senderInn,
        receiptDate: moment(receiptDate)
          .utc()
          .format(),
      };
    }
  }

  const newFilters = {...filters}
  if (filters.dateFrom && !filters.dateTo) {
    newFilters.dateTo = moment().endOf("day").toDate().toISOString()
  }
  if (!filters.dateFrom && filters.dateTo) {
    newFilters.dateFrom = new Date(0).toISOString()
  }

  const requestPayload = {
    url: '/api/v3/facade/receipt/search',
    method: 'post',
    data: {
      receipt: newFilters,
      pagination: paginationParams,
    },
  };

  yield put(listActions.gotRequest(requestPayload, actions));
}

function* fileUploadSaga(action) {
  const { payload } = action;
  const {
    file: { file, properties, content, data },
    message,
    url,
  } = payload;
  const { document_type } = properties;

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(properties)], { type: 'application/json' }),
  );
  if (typeof content !== 'string') {
    const fileType = /\.xls[x]?/.test(file.name)
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'multipart/form-data';
    formData.append('file', new Blob([data], { type: fileType }), file.name);
  } else {
    formData.append(
      'file',
      new Blob([content], { type: file.type }),
      file.name,
    );
  }

  const requestPayload = {
    url,
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    if (success.data.errors && success.data.errors.length) {
      const errors = get(success, 'data.errors', []);
      yield put(actions.gotErrors(errors));
    } else {
      yield call(Toast.showSuccess, { content: message });
      if (url === '/api/v3/facade/doc/upload') {
        if (document_type === 'IMPORT_TRANSIT') {
          yield put(
            push(`/documents/${APP_LINKS}/draft-${success.data.result}`),
          );
        } else {
          yield put(push(`/documents/import/draft-${success.data.result}`));
        }
      } else {
        yield put(actions.mounted());
        yield put(listActions.mounted(undefined, actions));
      }
    }
  }
  if (error) {
    console.log(error);
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* filePreview({ payload }) {
  const { id, type, docType } = payload;

  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const number = get(success, 'data.number', '');
    const status = get(success, 'data.status', '');
    const content = get(success, 'data.content', '');
    const warning = get(success, 'data.downloadDesc', '');
    if (!content) {
      put(actions.gotErrors('Сервер вернул пустой файл.'));
    } else {
      yield put(
        filePreviewActions.openString({
          number,
          content,
          status,
          type,
          warning,
          docType,
        }),
      );
    }
  }
  if (error) {
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* sortSaga(action) {
  const { payload } = action;

  const sorting = yield select(selectors.sorting);
  const { order, orderColumn } = sorting;

  let newOrder = 'ASC';
  if (payload === orderColumn) {
    newOrder = order === 'ASC' ? 'DESC' : 'ASC';
  }

  yield put(actions.setSorting({ order: newOrder, orderColumn: payload }));
}

function* downloadDoc(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/private-office-api/private/v2/documents/${payload.id}/buildReport/${payload.type}`,
    method: 'get',
    responseType: 'blob',
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield call(saveAs, success.data, `${payload.id}.${payload.type}`);
  }

  if (error) {
    console.log('error', error);
  }
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.sort, sortSaga);
  yield takeEvery(actions.fileUpload, fileUploadSaga);
  yield takeEvery(actions.openDocument, filePreview);
  yield takeEvery(actions.downloadDoc, downloadDoc);
}
