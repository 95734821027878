import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

export const InfoLabelViewStyle = {
  height: 'auto',
  margin: '10px 0 0 0',
  padding: '10px 30px',
};

export const Blocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 1100px;
  width: 90%;
  min-height: 400px;
  max-height: 800px;
  height: 80%;
  background-color: white;
  padding: 34px 34px 40px 34px;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 20px;
  background: #f8ec31;
  margin-bottom: 32px;
`;

export const ActionsWrapper = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const CloseWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 30px;
  z-index: 2;
`;

export const TitleTable = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 25px;
  color: #52535a;
`;

export const WrapBlockInfo = styled.div`
  background-color: #f0f0f0;
  /* width: calc(100% - 2px);
  transform: translateX(-33px); */
  margin-bottom: 40px;
  padding: 10px 34px 10px 19px;
`;

export const WrapBlock = styled.div`
  margin-bottom: 20px;
`;

export const TitleBlock = styled.div`
  display: block;
  color: rgba(82, 83, 90, 0.7);
  font-size: 14px;
`;

export const DataBlock = styled.div`
  color: #52535a;
  font-size: 16px;
`;

export const LineTop = styled.div`
  background: #ffa9a9;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  width: calc(100% - 2px);
  transform: translateX(-33px) translateY(-33px);
  padding: 10px 34px 10px 34px;
`;

export const TextWarning = styled.div`
  padding-left: 39px;
`;

export const TableWrap = styled.div`
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
  padding-left: 24px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

export const IconWrap = styled.div`
  padding-top: 8px;
`;
