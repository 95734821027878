import React, { Component } from 'react';
import { Count, Header, Title, Wrap } from '../styled';
import { addSpacesBetweenThousands } from '../../../utils';

export class Taxes extends Component {
  render() {
    const { taxes: { excise, vat, vatReturn } } = this.props;

    return (
      <Header>
        <div>
          <Title>НДС исчисленный:</Title>
          <Count>{vat ? addSpacesBetweenThousands(vat) : 0}</Count>
        </div>
        <div>
          <Title>НДС к вычету:</Title>
          <Count>{vatReturn ? addSpacesBetweenThousands(vatReturn) : 0}</Count>
        </div>
        <div>
          <Title>Акциз исчисленный:</Title>
          <Count>{excise ? addSpacesBetweenThousands(excise) : 0}</Count>
        </div>
        <div>
          <Title>Акциз возврат:</Title>
          <Count>0</Count>
        </div>
      </Header>
    );
  }
}
