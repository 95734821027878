import { Base64 } from '@crpt-ui/crypto';
import { Toast } from '../../../../../utils/Toast';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import Api from '../../../../../common_components/Api/Api';
import { get } from 'lodash';
import * as actions from './Aik.actions';
import moment from 'moment';
import { generateUUID } from '../../Draft/Draft.utils';
import * as authSelectors from '../../../../../common_components/Auth/Auth.selectors';
import { replace } from 'react-router-redux';
import { senderDocumentFormat } from '../Aik.constants';
import { signUZSaga } from '../../../../../common_components/Eimzo/Eimzo.saga';
import { TNVEDService } from '../../../../../services/TNVEDService';
import * as preloaderActions from '../../../../../common_components/Preloader/ducks/Preloader.actions';

function* confirmAikDocSaga(action) {
  const { payload } = action;
  const { idDraft, values } = payload;

  const document = senderDocumentFormat(values);
  const signature = yield call(signUZSaga, {
    payload: { data: document, file: false },
  });
  if (!signature) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }
  const base64_data = Base64.encode(JSON.stringify(document));
  const requestPayload = {
    url : '/api/v3/true-api/documents/aic/confirmation',
    method: 'post',
    data: {
      ...signature,
      document: base64_data,
    }
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const [successDraft, errorDraft] = yield call(Api.request, {
      url : `/api/v3/facade/draft/${idDraft}`,
      method: 'delete',
    })
    if (successDraft) {
      yield put(replace('/documents/list'));
      yield call(Toast.showInfo({
        content: 'Документ создан.',
        autoClose: 5000,
      }));
    }
    if (errorDraft) {
      yield call(Toast.showError, {
        content: get(error, 'responsecertificate.data.message', 'error'),
      });
    }
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'responsecertificate.data.message', 'error'),
    });
  }
}

function* createAikSaga(action) {
  const { payload } = action;
  const { values, id, formMutators } = payload;

  const document = senderDocumentFormat(values);
  const [success, error] = yield call(Api.request, {
    url : '/api/v3/true-api/documents/aic/enrichment',
    method: 'post',
    data: document,
  })
  if (success) {
    const authData = yield select(authSelectors.userInfo);
    formMutators.updateField('aik', success.data.aic)
    formMutators.updateField('productName', success.data.productName)
    const [successDraft, errorDraft] = yield call(Api.request, {
      url : `/api/v3/facade/draft/${id === 'create' ? "" : id.replace(/draft-/, '') }`,
      method: id === 'create' ? 'post' : 'put',
      data: {
        date_saved: `${moment().toISOString(false)}`,
        document_body: JSON.stringify({
          ...values,
          aik: success.data.aic,
          productName: success.data.productName,
        }),
        document_type: 'AIC_AGGREGATION',
        document_uuid: id === 'create' ? generateUUID() : id.replace(/draft-/, ''), // id === 'create' ?  : "",
        draft_uuid: id === 'create' ? '' : id.replace(/draft-/, ''),
        error_list: [],
        organisation_inn: `${authData.inn}`,
        registration_date: `${moment().toISOString(false)}`,
        user_id: authData.id,
      },
    })

    if (successDraft) {
      formMutators.updateField('idDraft', successDraft.data.draft_uuid)
    }
    if (errorDraft) {
      yield call(Toast.showError, {
        content: get(error, 'responsecertificate.data.message', 'error'),
      });
    }
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'responsecertificate.data.message', 'error'),
    });
  }
}

function* mountedSaga() {
  try {
    yield put(preloaderActions.start())
  
    const response = yield call(TNVEDService.getTnvedAddUnits);
    const data = get(response, 'data', {});
  
    yield put(actions.loadTnvedAddUnits(data))
    yield put(preloaderActions.end())
  }
  catch (error) {
    console.log(error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

export default function* watch() {
  yield takeEvery(actions.createAik, createAikSaga);
  yield takeEvery(actions.confirmAikDoc, confirmAikDocSaga);
  yield takeEvery(actions.mounted, mountedSaga);
}
