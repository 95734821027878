import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import { Grid } from '@material-ui/core';
import * as altPropTypes from '../altPropTypes';
import { SinglePercentChart, SmallLineChart } from '../Charts/index';
import {
  ChangeDown,
  ChangeUp,
  Count,
  Info,
  SmallChartWrap,
  ItemTitle,
  ItemWrap,
  ItemContentWrap
} from './styled';
import { addSpacesBetweenThousands } from '../../../utils';

class StructureItem extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      SinglePercentChart: PropTypes.object.isRequired,
      SmallLineChart: PropTypes.object.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(altPropTypes.statisticType).isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    const { onClick, data } = this.props;
    const type = get(data, '0.scenarioType.code', null);
    if (!type) return;
    onClick(type);
  };

  renderChangeInfo = () => {
    const { data } = this.props;

    if (data.length === 1) {
      return (
        <div>
          <Count>{addSpacesBetweenThousands(data[0].countNum)}</Count>
        </div>
      );
    }

    const [prev, current] = data.slice(-2);
    const percent =
      -Math.round((100 - current.countNum / prev.countNum * 100) * 100) / 100;

    return (
      <div>
        <Count>{current.countNum}</Count>
        {percent > 0 && <ChangeUp>{percent}</ChangeUp>}
        {percent < 0 && <ChangeDown>{percent}</ChangeDown>}
      </div>
    );
  };

  getPercent = () => {
    const { data } = this.props;
    return data.slice(-1)[0].countPercent;
  };

  getChartData = () => {
    const { data } = this.props;
    return data.map(i => i.countNum);
  };

  render() {
    const { title, data, theme } = this.props;
    if (!data.length) return null;

    const { frontRing } = theme.SinglePercentChart;
    const size = frontRing.outerRadius * 2;

    return (
      <Grid item xs={12} md={3}>
        <ItemContentWrap onClick={this.onClick}>
          <SinglePercentChart
            percent={this.getPercent()}
            theme={theme.SinglePercentChart}
          />
          <Info>
            <ItemTitle>{title}</ItemTitle>
            {this.renderChangeInfo()}
            <SmallChartWrap>
              <SmallLineChart
                data={this.getChartData()}
                theme={theme.SmallLineChart}
              />
            </SmallChartWrap>
          </Info>
        </ItemContentWrap>
      </Grid>
    );
  }
}

export default withTheme(StructureItem);
