import axios from 'axios';
import {getToken} from '../utils/userUtils';

const authInstance = axios.create({
  timeout: 120000,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    'Cache-Control': 'no-cache'
  },
  withCredentials: true,
  mode: 'no-cors',
  crossdomain: true,
  request: {
    withCredentials: true,
  },
});

authInstance.interceptors.request.use (
  function (config) {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

export { authInstance };
