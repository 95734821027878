import { actionTypes } from '../constants/';
import { FormSerializer } from './FormSerializer';
import { Toast } from './Toast';
import { PAGINATED_LIST_CONSTANTS } from '../constants';
import { startPreloader, endPreloader } from '../actions/Preloader';
import { get } from 'lodash';

export function getPaginatedListActions(namespace, service) {
  function FilterParamsUpdated(value) {
    return {
      type:
        actionTypes[namespace][PAGINATED_LIST_CONSTANTS.FILTER_PARAMS_UPDATED],
      value,
    };
  }

  function LoadingStarted() {
    return {
      type: actionTypes[namespace].LOADING_STARTED,
    };
  }

  function LoadingFinished() {
    return {
      type: actionTypes[namespace].LOADING_FINISHED,
    };
  }

  function ListMounted(params = {}, paramsFormatter) {
    return (dispatch, getState) => {
      const newParams = { ...params, limit: 10, offset: 0 };
      dispatch(startPreloader());

      dispatch({ type: actionTypes[namespace].LIST_MOUNTED, value: newParams });

      //TODO: придумать protected variation

      if (params.scenarioType) {
        dispatch(loadStatistic({ scenarioType: params.scenarioType }));
      }

      service
        .getList(
          paramsFormatter ? paramsFormatter(newParams, getState) : newParams,
        )
        .then(answer => {
          dispatch(endPreloader());

          dispatch(ListLoaded(answer.data, newParams.limit));
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(LoadingFinished());
          if (err.response && err.response.status >= 500) {
            dispatch(
              ListLoaded(
                { results: [], total: newParams.total },
                newParams.limit,
              ),
            );
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function ListLoaded(data, limit) {
    return {
      type: actionTypes[namespace].LIST_LOADED,
      value: Object.assign(data, { limit }),
    };
  }

  function PageLoaded(data) {
    return {
      type: actionTypes[namespace].LIST_PAGE_LOADED,
      value: data,
    };
  }

  function FilterClicked(data, paramsFormatter, preSendFormatter) {
    return (dispatch, getState) => {
      const params = { limit: 10, offset: 0 };
      const prepared = FormSerializer.serialize(data);

      const _data = Object.assign(params, prepared);
      const filterParams = paramsFormatter
        ? paramsFormatter(_data, getState)
        : _data;
      dispatch({
        type: actionTypes[namespace].FILTER_APPLY_CLICKED,
        value: filterParams,
      });
      dispatch(startPreloader());

      const listParams = preSendFormatter
        ? preSendFormatter(filterParams, getState)
        : filterParams;

      service
        .getList(listParams)
        .then(answer => {
          dispatch(ListLoaded(answer.data, params.limit));

          dispatch(endPreloader());
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(LoadingFinished());
          if (err.response && err.response.status >= 500) {
            dispatch(
              ListLoaded({ results: [], total: params.total }, params.limit),
            );
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function rawListUpdated(params, paramsFormatter) {
    return (dispatch, getState) => {
      dispatch(LoadingStarted());
      service
        .getList(paramsFormatter ? paramsFormatter(params, getState) : params)
        .then(answer => {
          dispatch(endPreloader());
          dispatch(ListLoaded(answer.data, params.limit));
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(LoadingFinished());
          dispatch(endPreloader());
          if (err.response && err.response.status >= 500) {
            dispatch(
              ListLoaded({ results: [], total: params.total }, params.limit),
            );
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function pageSelected(pageNumber, values) {
    return dispatch => {
      const limit = values.filterParams.limit;

      const params = Object.assign({}, values.filterParams, {
        offset: limit * (pageNumber - 1),
        needTotal: false,
      });

      dispatch(LoadingStarted());
      dispatch(startPreloader());

      service
        .getList(params)
        .then(answer => {
          dispatch(endPreloader());
          dispatch({
            type: actionTypes[namespace].PAGE_SELECTED,
            value: pageNumber,
          });

          dispatch(PageLoaded(answer.data));
        })
        .catch(err => {
          dispatch(LoadingFinished());
          dispatch(endPreloader());
          console.log('err', { err: err });
          if (err.response && err.response.status >= 500) {
            dispatch(PageLoaded({ results: [] }));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  return {
    FilterClicked,
    ListMounted,
    ListLoaded,
    pageSelected,
    FilterParamsUpdated,
    rawListUpdated,
  };
}

export function getListActions({
  actionNamespace,
  selectorNamespace,
  getList,
  getCancelListRequest = () => {},
  pageFormatter = () => ({}),
  listMountFilterParamsSerializer = _ => _,
  listMountRequestParamsSerializer = _ => _,
  filterApplyFilterParamsSerializer = _ => _,
  filterApplyRequestParamsSerializer = _ => _,
  pageSelectRequestParamsSerializer = _ => _,
  onListMount = () => {},
  onFilterApplyWithoutSerializing = () => {},
  onFilterApply = () => {},
  onFilterLoad = () => {},
  loadedDataFormatter = _ => _,
  pageSerializer = _ => _,
  onPageSelect = () => {},
}) {
  function onFilterParamsUpdate(value) {
    return {
      type:
        actionTypes[actionNamespace][
          PAGINATED_LIST_CONSTANTS.FILTER_PARAMS_UPDATED
        ],
      value,
    };
  }

  function onListLoad(value) {
    return {
      type: actionTypes[actionNamespace].LIST_LOADED,
      value,
    };
  }

  function onPageLoad(data) {
    return {
      type: actionTypes[actionNamespace].LIST_PAGE_LOADED,
      value: data,
    };
  }

  function onLoadingFinish() {
    return {
      type: actionTypes[actionNamespace].LOADING_FINISHED,
    };
  }

  function onLoadingStart() {
    return {
      type: actionTypes[actionNamespace].LOADING_STARTED,
    };
  }

  function onListMountInner(params = {}) {
    return (dispatch, getState) => {
      const pageSerialized = pageSerializer(1);
      const pageParams = pageFormatter(pageSerialized);
      const newParams = { ...params, ...pageParams };
      dispatch(startPreloader());

      dispatch({
        type: actionTypes[actionNamespace].LIST_MOUNTED,
        value: listMountFilterParamsSerializer(newParams),
      });

      if (pageSerialized !== 1) {
        dispatch({
          type: actionTypes[actionNamespace].PAGE_SELECTED,
          value: pageSerialized,
        });
      }

      onListMount(newParams);

      getList(listMountRequestParamsSerializer(newParams, getState))
        .then(answer => {
          dispatch(endPreloader());
          const listData = {
            ...answer.data,
            limit: get(answer.data, 'limit', newParams.limit),
          };
          dispatch(onListLoad(loadedDataFormatter(listData)));
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(onLoadingFinish());
          if (err.response && err.response.status >= 500) {
            dispatch(onListLoad(loadedDataFormatter({ results: [] })));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onFilterApplyClick(data) {
    return (dispatch, getState) => {
      const pageParams = { ...pageFormatter(1) };

      const filterParams = filterApplyFilterParamsSerializer(
        data,
        pageParams,
        getState,
      );

      dispatch({
        type: actionTypes[actionNamespace].FILTER_APPLY_CLICKED,
        value: filterParams,
      });

      dispatch(startPreloader());

      const requestParams = filterApplyRequestParamsSerializer(
        data,
        pageParams,
        getState,
      );

      onFilterApply(requestParams);
      onFilterApplyWithoutSerializing(data);

      getList(requestParams)
        .then(answer => {
          onFilterLoad(loadedDataFormatter(answer.data));
          dispatch(onListLoad(loadedDataFormatter(answer.data)));
          dispatch(endPreloader());
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(onLoadingFinish());
          if (err.response && err.response.status >= 500) {
            dispatch(onListLoad(loadedDataFormatter({ results: [] })));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onPageSelectInner(pageNumber) {
    return (dispatch, getState) => {
      const state = getState();

      onPageSelect(pageNumber);
      const filterParams = getState()[selectorNamespace].filterParams;
      const params = { ...filterParams, ...pageFormatter(pageNumber) };

      dispatch(onLoadingStart());
      dispatch(startPreloader());

      getList(pageSelectRequestParamsSerializer(params))
        .then(answer => {
          dispatch(endPreloader());
          dispatch({
            type: actionTypes[actionNamespace].PAGE_SELECTED,
            value: pageNumber,
          });

          dispatch(onPageLoad(loadedDataFormatter(answer.data)));
        })
        .catch(err => {
          dispatch(onLoadingFinish());
          dispatch(endPreloader());
          console.log('err', { err: err });
          if (err.response && err.response.status >= 500) {
            dispatch(onPageLoad(loadedDataFormatter({ results: [] })));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onListUnmount() {
    return dispatch => {
      const cancelList = getCancelListRequest();

      if (cancelList) cancelList('PREVENT_TOAST');

      dispatch({
        type: actionTypes[actionNamespace].FILTER_APPLY_CLICKED,
        value: {},
      });
    };
  }

  return {
    onListMount: onListMountInner,
    onFilterApplyClick,
    onPageSelect: onPageSelectInner,
    onFilterParamsUpdate,
    onListUnmount,
  };
}

export function getPaginationListActions({
  actionNamespace,
  selectorNamespace,
  getList,
  getCancelListRequest = () => {},
  pageFormatter = () => ({}),
  listMountFilterParamsSerializer = _ => _,
  listMountRequestParamsSerializer = _ => _,
  filterApplyFilterParamsSerializer = _ => _,
  filterApplyRequestParamsSerializer = _ => _,
  pageSelectRequestParamsSerializer = _ => _,
  onListMount = () => {},
  onFilterApplyWithoutSerializing = () => {},
  onFilterApply = () => {},
  onFilterLoad = () => {},
  loadedDataFormatter = _ => _,
  pageSerializer = _ => _,
  onPageSelect = () => {},
}) {
  function onFilterParamsUpdate(value) {
    return {
      type:
        actionTypes[actionNamespace][
          PAGINATED_LIST_CONSTANTS.FILTER_PARAMS_UPDATED
        ],
      value,
    };
  }

  function onListLoad(value) {
    return {
      type: actionTypes[actionNamespace].LIST_LOADED,
      value,
    };
  }

  function onPageLoad(data) {
    return {
      type: actionTypes[actionNamespace].LIST_PAGE_LOADED,
      value: data,
    };
  }

  function onLoadingFinish() {
    return {
      type: actionTypes[actionNamespace].LOADING_FINISHED,
    };
  }

  function onLoadingStart() {
    return {
      type: actionTypes[actionNamespace].LOADING_STARTED,
    };
  }

  function onListMountInner(params = {}) {
    return (dispatch, getState) => {
      const pageSerialized = pageSerializer(1);
      const pageParams = pageFormatter(pageSerialized);
      const newParams = { ...params, pagination: pageParams };
      dispatch(startPreloader());

      dispatch({
        type: actionTypes[actionNamespace].LIST_MOUNTED,
        value: listMountFilterParamsSerializer(newParams),
      });

      if (pageSerialized !== 1) {
        dispatch({
          type: actionTypes[actionNamespace].PAGE_SELECTED,
          value: pageSerialized,
        });
      }

      onListMount(newParams);

      getList(listMountRequestParamsSerializer(newParams, getState))
        .then(answer => {
          dispatch(endPreloader());
          dispatch(onListLoad(loadedDataFormatter(answer.data)));
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(onLoadingFinish());
          if (err.response && err.response.status >= 500) {
            dispatch(onListLoad(loadedDataFormatter(null)));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onFilterApplyClick(data) {
    return (dispatch, getState) => {
      const pageParams = { ...pageFormatter(pageSerializer(1)) };
      const participantFilterParams = getState()[selectorNamespace]
        .filterParams;

      const {
        senderInn,
        receiverInn,
        pagination = pageParams,
      } = participantFilterParams;

      const filterParams = pageSelectRequestParamsSerializer({
        senderInn,
        receiverInn,
        pagination,
        ...filterApplyFilterParamsSerializer(data),
      });

      dispatch({
        type: actionTypes[actionNamespace].FILTER_APPLY_CLICKED,
        value: filterParams,
      });

      dispatch(startPreloader());

      onFilterApply(filterApplyRequestParamsSerializer(data));
      onFilterApplyWithoutSerializing(data);

      getList(filterParams)
        .then(answer => {
          onFilterLoad(loadedDataFormatter(answer.data));
          dispatch(onListLoad(loadedDataFormatter(answer.data)));
          dispatch(endPreloader());
        })
        .catch(err => {
          console.log('err', { err: err });
          dispatch(endPreloader());
          dispatch(onLoadingFinish());
          if (err.response && err.response.status >= 500) {
            dispatch(onListLoad(loadedDataFormatter(null)));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onPageSelectInner(pageNumber) {
    return (dispatch, getState) => {
      const state = getState();

      onPageSelect(pageNumber);
      const filterParams = getState()[selectorNamespace].filterParams;
      const params = { ...filterParams, pagination: pageFormatter(pageNumber) };

      dispatch(onLoadingStart());
      dispatch(startPreloader());

      getList(pageSelectRequestParamsSerializer(params))
        .then(answer => {
          dispatch(endPreloader());
          dispatch({
            type: actionTypes[actionNamespace].PAGE_SELECTED,
            value: pageNumber,
          });

          dispatch(onPageLoad(loadedDataFormatter(answer.data)));
        })
        .catch(err => {
          dispatch(onLoadingFinish());
          dispatch(endPreloader());
          console.log('err', { err: err });
          if (err.response && err.response.status >= 500) {
            dispatch(onPageLoad(loadedDataFormatter({ results: [] })));
            Toast.showError({
              content: `Сервер вернул ${err.response.status} ошибку.`,
            });
          }
        });
    };
  }

  function onListUnmount() {
    return dispatch => {
      const cancelList = getCancelListRequest();

      if (cancelList) cancelList('PREVENT_TOAST');

      dispatch({
        type: actionTypes[actionNamespace].FILTER_APPLY_CLICKED,
        value: {},
      });
    };
  }

  return {
    onListMount: onListMountInner,
    onFilterApplyClick,
    onPageSelect: onPageSelectInner,
    onFilterParamsUpdate,
    onListUnmount,
  };
}
