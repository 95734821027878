import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, Icon } from '@material-ui/core';


const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
  iconStyle: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.string,
};

const defaultProps = {
  icon: undefined,
  children: undefined,
  variant: 'contained',
  iconStyle: {
    marginRight: '10px',
    width: '16px',
    height: '16px',
  },
};

const Button = props => {
  const { icon, iconStyle, children, staticContext, ...pass } = props;

  return (
    <MaterialButton {...pass}>
      {icon && <Icon component={icon} style={iconStyle} />}
      {children}
    </MaterialButton>
  );
};


Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
