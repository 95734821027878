import { handleActions } from 'redux-actions';
import * as actions from './Locker.actions';


const isLocked = handleActions({
  [actions.lock]: () => true,
  [actions.unlock]: () => false,
}, false);

export default {
  isLocked,
};
