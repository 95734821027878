import styled from 'styled-components';


const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  min-width: 530px;
  flex-wrap: nowrap;
  padding-top: 23px;
  padding-bottom: 23px;
  padding-left: 12px;
  padding-right: 12px;
  
`;

export default Wrapper;
