import { css } from 'styled-components';

const TableWrapper = {
  style: css`
    display: flex;
    overflow: visible;

    align-items: stretch;
    flex: auto 1;
    flex-direction: column;

    width: 100%;

    border-collapse: collapse;
  `,
};

export default TableWrapper;
