import React, { useEffect, useMemo, useState } from 'react';
import { CisRegistrationView } from './CisRegistrationView';
import * as selectors from './ducks/CisRegistration.selectors';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import * as selectorsDocumentDetails from '../../../../components/DocumentDetails/ducks/DocumentDetails.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as actions from './ducks/CisRegistration.actions';
import { connect } from 'react-redux';
import Form from '../../../../common_components/Form/Form';
import FormContextWrapper from '../../../../common_components/Form/FormContextWrapper';
import Preloader from '../../../../common_components/Preloader/Preloader';
import { Toast } from '../../../../utils/Toast';
import { Translate } from '../../../../common_components/Translate/Translate';
import { toast } from 'react-toastify';
import UploadCisesModal from './components/UploadCisesModal';
import { compose } from 'redux';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';
import { history } from '../../../../store';

const CisRegistration = ({ loaded, isLoading, user, data,
                           cisList, onSaveDraft, match, onUnmount,
                           onSubmit, countryCode, onLoadDraft ,onMountCades, ...props }) => {
  const [isCreate, setIsCreate] = useState(true);

  useEffect(() => {
    return () => {
      onUnmount()
    }
  }, [])

  const initialState = useMemo(() => ({
    inn: user.inn,
    name: user.full_name,
    pg: 'Лекарственные средства',
    country: 'РОССИЯ',
  }), [user]);

  useEffect(() => {
    const { id } = match.params;
    if (/draft-/.test(id)) {
      onLoadDraft(id.replace(/draft-/, ''));
    }
    if (countryCode === 'UZ') {
      onMountCades();
    }
  }, [match]);

  const saveDocument = () => {
    const submitData = { ...match, ...initialState, cisList: cisList || [], isDraft: false };
    onSubmit({ match, values: submitData });
  };

  const onCancel = () => {
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const openLoadCisListModal = () => {
    const { onOpenModal, onSetContent, onCloseModal } = props;
    onSetContent(
      <UploadCisesModal
        onCloseModal={onCloseModal}
        types=".csv"
      />
    );
    onOpenModal();
  }

  const saveDraft = () => {
    const draftData = { ...match, ...initialState, cisList: cisList || [], isDraft: true };
    onSaveDraft({ match, values: draftData });
  };

  return (
    <Form
      data={isCreate ? {...initialState} : {...data}}
      onSubmit={saveDocument}
      disabled={!isCreate && !loaded}
    >
      {isLoading && <Preloader/>}
      {!isLoading && (
        <FormContextWrapper>
          <CisRegistrationView
            cisList={cisList}
            onCancel={onCancel}
            onSubmit={saveDocument}
            onSaveDraft={saveDraft}
            openLoadCisListModal={openLoadCisListModal}
          />
        </FormContextWrapper>

      )}
    </Form>
  )
};

const mapState = state => ({
  data: selectors.data(state),
  cisList: selectors.cisList(state),
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  user: authSelectors.userInfo(state),
  selectedDocument: selectorsDocumentDetails.selectedDocument(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  onUnmount: () => dispatch(actions.unmounted()),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMountCades: () => dispatch(cadesActions.load()),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
)(CisRegistration);
