import {combineReducers} from 'redux';

import {step} from './step';
import {certificates} from './certificates';
import {errors} from './errors';
import {certVisible} from './certVisible';
import {contactsVisible} from './contactsVisible';
import {dataVisible} from './dataVisible';
import {certEditable} from './certEditable';
import {contactsEditable} from './contactsEditable';

export default combineReducers({
  step,
  certificates,
  errors,
  certVisible,
  contactsVisible,
  dataVisible,
  certEditable,
  contactsEditable
});
