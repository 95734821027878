import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import * as previewActions from '../Document/Preview/ducks/Preview.actions';
import * as actions from './ducks/NotificationOfEntry.actions';
import * as selectors from './ducks/NotificationOfEntry.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import {
  actionSelectOptions,
  breadcrumbs,
  breadcrumbsCancelation, cisKey, goodsKey,
  steps,
} from './NotificationOfEntry.constants';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { APP_LINKS, countryCodeFlags, documentTypes } from 'src/constants';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import { NotificationOfEntryFields } from './NotificationOfEntryFields';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import AddNotificationOfEntryFieldsBar from './AddNotificationOfEntryFieldsBar';
import { Translate } from '../../../../common_components/Translate/Translate';
import { MaterialUiThemeUZ } from "../../../../common_components/_MaterialUi/ThemeUZ";


const changeTabsFunc = (tab) => tab.id === 1 ? ({ ...tab, label: Translate("Шаг 2. Коды") }) : tab

const errorsCount = ({ formErrors }) => {
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    return Object.keys(prepared).length;
}

const NotificationOfEntry = (props) => {
  const { loaded, isLoading, data, match, countryCode, docType, onUnmount } = props;
  const { inn, shortName, onSubmit, onSaveDraft } = props;
  const { setDocType, onMount, onLoadDraft } = props;
  const { id } = match.params;
  const [step, setStep] = useState(steps.info)
  const draftId = useMemo(()=> /draft-/.test(id) ? id.replace(/draft-/, '') : null, [id])

  useEffect(()=> {
    if (match.path.includes(APP_LINKS.NotificationOfCancellation)) setDocType(documentTypes.MTTPS_90);
    else setDocType(documentTypes.KZ_INTRODUCE_GOODS);
    if (draftId) {
      onLoadDraft(draftId);
    } else if (id !== 'create') {
      onMount(encodeURIComponent(id));
    }
    return onUnmount;
  },[id, onMount, onLoadDraft])

  const preparedData = useMemo(() => id === 'create' ? {
      applicant_inn: `${inn}, ${shortName}`,
      applicant: shortName,
      inn
  } : {
      ...data.values ? data.values : data,
      ...draftId ? { draftId } : {}
  }, [id, draftId, data, inn, shortName])

  const preparedBreadcrumbs = useMemo(() =>
      docType === documentTypes.KZ_INTRODUCE_GOODS ? breadcrumbs : breadcrumbsCancelation,
      [docType]
  )

  return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
      >
        <DocumentWrapper
          additionalBottomBar={countryCodeFlags.isUZ ? AddNotificationOfEntryFieldsBar : undefined}
          needViewActionsOfGoods={!countryCodeFlags.isUZ}
          codesCanBeChanged={!countryCodeFlags.isUZ}
          changeTabsFunc={countryCodeFlags.isUZ ? changeTabsFunc : undefined}
          emptyItemText={countryCodeFlags.isUZ ? 'Нет добавленных кодов' : undefined}
          loaded={loaded}
          step={step}
          match={match}
          actionSelectOptions={actionSelectOptions}
          isCreate={id === 'create'}
          breadcrumbs={preparedBreadcrumbs}
          countryCode={countryCode}
          isLoading={isLoading}
          onSaveDraft={onSaveDraft}
          data={preparedData}
          onSetStep={setStep}
          docType={docType}
          fieldsInfo={NotificationOfEntryFields}
          errorsCountFunc={errorsCount}
          needAWarningAboutTheGoodsRecount={false}
          needCancelButton={true}
          needDraftButton={true}
          onSubmit={onSubmit}
          title={docType === documentTypes.KZ_INTRODUCE_GOODS
            ? 'Уведомление о вводе в оборот'
            : 'Уведомление о выводе из оборота'
          }
        />
      </MuiThemeProvider>
    );
}

NotificationOfEntry.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  loaded: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  openPreview: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  countryCode: PropTypes.string,
  setDocType: PropTypes.func.isRequired,
  docType: PropTypes.string,
};

const mapState = state => ({
  data: {
    ...selectors.data(state),
  },
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  docType: selectors.docType(state),
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
});
const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(countryCodeFlags.isUZ ? actions.submitUz(data) : actions.submit(data)),
  onUnmount: () => dispatch(actions.unmounted()),
  openPreview: data => dispatch(previewActions.open(data)),
  setPreview: data => dispatch(previewActions.setContent(data)),
  setDocType: data => dispatch(actions.setDocType(data)),
});
export default connect(mapState, mapDispatch)(NotificationOfEntry);
