import React from 'react';
import PropTypes from 'prop-types';
import { Card, ContentWrap, Title, Wrap } from '../styled';
import MultiSelect from '../../Fields/MultiSelect';
import Okved from './Okved';
import Foiv from './Foiv';
import ProductionPlaces from './ProductionPlaces';
import SalesOutlets from './SalesOutlets';
import Warehouses from './Warehouses';
import EdoOperators from './EdoOperators';
import FiscalOperators from './FiscalOperators';
import Button from '../../Fields/Button';
import { required } from '../../utils/validations';
import { ErrorMessage, RegistrationButtonWrap } from '../../styled';
import { Icon } from '@crpt/react-icon';
import { RegistrationSteps } from '../../constants';

const CurrentStep = ({
  orgRoles,
  participantValues,
  values,
  errors,
  onUpdateField,
  onPushValue,
  onRemoveValue,
  onAddUnique,
  onToggle,
  canNext,
  dataVisible,
  toggleData,
  step,
}) => {
  const canToggle = step === RegistrationSteps.DATA;

  return (
    <Wrap>
      <Card>
        <Title onClick={canToggle ? e => toggleData() : null}>
          Данные участника{' '}
          {canToggle && (
            <Icon
              spacingLeft={12}
              type={dataVisible ? 'up-arrow' : 'down-arrow'}
            />
          )}
        </Title>
        <ContentWrap hidden={!dataVisible}>
          <MultiSelect
            name="orgRoles"
            placeholder="Тип участника"
            values={participantValues}
            validate={required}
          />
        </ContentWrap>
        <ContentWrap hidden={!orgRoles || !dataVisible}>
          <Okved orgRoles={orgRoles} onUpdateField={onUpdateField} />
          <Foiv orgRoles={orgRoles} onUpdateField={onUpdateField} />
          <ProductionPlaces
            orgRoles={orgRoles}
            values={values}
            onPushValue={onPushValue}
            onRemoveValue={onRemoveValue}
            onUpdateField={onUpdateField}
          />
          <SalesOutlets
            orgRoles={orgRoles}
            values={values}
            onPushValue={onPushValue}
            onRemoveValue={onRemoveValue}
            onUpdateField={onUpdateField}
          />
          <Warehouses
            orgRoles={orgRoles}
            values={values}
            onPushValue={onPushValue}
            onRemoveValue={onRemoveValue}
            onUpdateField={onUpdateField}
          />
          <EdoOperators
            orgRoles={orgRoles}
            values={values}
            onAddUnique={onAddUnique}
            onToggle={onToggle}
            onUpdateField={onUpdateField}
          />
          <FiscalOperators
            orgRoles={orgRoles}
            values={values}
            onAddUnique={onAddUnique}
            onToggle={onToggle}
            onUpdateField={onUpdateField}
          />
        </ContentWrap>
        {dataVisible &&
          errors.length > 0 &&
          errors.map(err => (
            <ErrorMessage key={new Date().getTime()}>{err}</ErrorMessage>
          ))}
        {dataVisible &&
          step === RegistrationSteps.DATA && (
            <RegistrationButtonWrap>
              <Button disabled={!canNext} type="submit">
                {'Зарегистрироваться'}
              </Button>
            </RegistrationButtonWrap>
          )}
      </Card>
    </Wrap>
  );
};

CurrentStep.propTypes = {
  orgRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  participantValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  canNext: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onPushValue: PropTypes.func.isRequired,
  onRemoveValue: PropTypes.func.isRequired,
  onAddUnique: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default CurrentStep;
