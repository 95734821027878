import { takeEvery, put, take, call, race, select } from 'redux-saga/effects';
import { flattenDeep, get, groupBy, uniqBy } from 'lodash';
import * as actions from './Goods.actions';
import { goodsKey, cisKey, invalidCisKey, codesMark } from '../../ActDocument.constants';
import Api from 'src/common_components/Api/Api';
import * as fileActions from 'src/common_components/File/File.actions';
import { Toast } from 'src/utils/Toast';

function* loadSaga(action, state) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn, mode, formValues } = payload;

  const fileType = get(file, 'type');

  if (!['XLS', 'XLSX'].includes(fileType)) {
    yield put(actions.reset());
    Toast.showError({
      content: `Загружаемый файл не соответствует установленному формату. Загрузите файл формата *.xls, *xlsx`,
    });
    return;
  }

  const data = {
    userId: +userId,
    organisationInn: inn,
  };

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(data)], { type: 'application/json' }),
  );

  formData.append(
    'file',
    new Blob([file.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    file.name,
  );

  const requestPayload = {
    url: '/api/v3/facade/marked_products/search-by-xls-cis-list',
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  // //далее надо получить список кодов

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);
      if (error) {
        yield put(actions.error(error));
        console.log('error', error);
      } else {
        let data = success.data.valid.results;
        const invalidData = success.data.invalid;
        console.log('answer from server', data);

        let products = [];
        products = data.map((item, idx) => ({
          idx: idx + 1,
          name: item.productName,
          gtin: item.gtin,
          cis: item.cis,
          ownerName: item.ownerName,
          ownerInn: item.ownerInn,
          producerName: item.producerName,
          cisPackageType: item.cisPackageType,
          producerInn: item.producerInn,
        }));

        data = groupBy(data, 'gtin');
        data = Object.keys(data).map(key => {
          return {
            gtin: data[key][0].gtin,
            name: data[key][0].name,
          };
        });
        const previousValues = formValues[mode === 'editedAct' ? codesMark : goodsKey]
          ? formValues[mode === 'editedAct' ? codesMark : goodsKey]
          : {
              [mode === 'editedAct' ? codesMark : goodsKey]: []
          }
        formMutators.updateField(
          mode === 'editedAct'
            ? codesMark
            : goodsKey,
          uniqBy([...previousValues, ...products ], 'cis')
        );
        // formMutators.updateField(cisKey, data);
        formMutators.updateField(invalidCisKey, invalidData);
        yield call(onCloseModal);
        yield put(actions.reset());
      }
    } catch (e) {
      yield put(actions.reset());
      // Toast.showError({
      //   content: `Ответ от сервера не соответствует ожидаемому формату.`,
      // });
      console.log(e, { e });
    }
  } else if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false);
    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
    console.log('error /api/v3/facade/marked_products/search-by-xls-cis-list', {
      error,
    });
  }
}

function* loadCisSaga(action, state) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn } = payload;
  yield put(
    fileActions.read(file, { subject: actions.openFile, rawWithText: true }),
  );

  const fileLoaderData = yield race({
    success: take(`${fileActions.success} ${actions.openFile}`),
    error: take(`${fileActions.error} ${actions.openFile}`),
  });
  yield put(actions.load());
  const fileData = get(fileLoaderData, 'success.payload', {
    content: null,
    data: null,
  });

  if (fileData) {
    yield put(actions.convert({ ...payload, file: fileData }));
  } else {
    yield put(actions.error('Недопустимый формат файла!'));
  }
}

function* getPackageCount(action) {
  const { payload } = action;
  const { products, formMutators } = payload;

  if (products.length === 0) {
    formMutators.updateField(cisKey, []);
    formMutators.updateField(goodsKey, []);
    return;
  }

  const requestPayload = {
    url: `/api/v3/facade/cis/cises-units-info`,
    method: 'post',
    data: JSON.stringify(products.map(item => item.cis)),
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const { wideTotal, wideProducts } = yield call(
        getProductsAndCisesForDocument,
        success,
        products,
      );

      formMutators.updateField(cisKey, wideTotal);
      formMutators.updateField(goodsKey, wideProducts);
    } catch (e) {
      Toast.showError({
        content: `Ответ от сервера не соответствует ожидаемому формату.`,
      });
      console.log(e, { e });
    }
  } else if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false);
    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
  }
}

export const getProductsAndCisesForDocument = (success, products) => {
  const unitsByCis = get(success, 'data.unitsByCis', {});
  const unitsByGtin = get(success, 'data.unitsByGtin', {});

  const wideProducts = products.map((item, idx) => ({
    ...item,
    idx: idx + 1,
    cisesCounts: unitsByCis[item.cis].count,
  }));

  let wideTotal = Object.entries(unitsByGtin).map(item => {
    const [gtin, fields] = item;

    return {
      gtin,
      name: fields.productName,
      gtinsCounts: fields.count,
    };
  });
  return { wideTotal, wideProducts };
};

export default function* watch() {
  yield takeEvery(actions.convert, loadSaga);
  yield takeEvery(actions.convertCis, loadCisSaga);
  yield takeEvery(actions.getPackageCount, getPackageCount);
}
