import React from 'react';
import PropTypes from 'prop-types';
import {
  PointerUp, PointerUpOverlap, PointerDown, PointerDownOverlap,
} from './styled/index';

const propTypes = {
  position: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['up', 'down']).isRequired,
};

const Arrow = ({ position, direction }) => (
  direction === 'up'
    ? (
      <React.Fragment>
        <PointerUp position={position} />
        <PointerUpOverlap position={position} />
      </React.Fragment>
    )
    : (
      <React.Fragment>
        <PointerDown position={position} />
        <PointerDownOverlap position={position} />
      </React.Fragment>
    )
);

Arrow.propTypes = propTypes;

export default Arrow;
