import { connect } from 'react-redux';
import { compose } from 'redux';
import FieldsView from './Fields.view';
import * as selectors from '../ducks/LoginForm.selectors';


const mapState = state => ({
  errors: selectors.errors(state),
  disabled: selectors.submitDisabled(state),
});

export default compose(
  connect(mapState),
)(FieldsView);
