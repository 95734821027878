import React from 'react';
import { isObject, isArray } from 'lodash';


const withMountCycle = (name, defaultValue) => Component => (
  class extends React.Component {
    componentDidMount() {
      const { onUpdateField } = this.props;
      onUpdateField(
        name,
        (isObject(defaultValue) || isArray(defaultValue))
          ? JSON.parse(JSON.stringify(defaultValue))
          : defaultValue
      );
    }

    componentWillUnmount() {
      const { onUpdateField } = this.props;
      onUpdateField(name, undefined);
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }
);

export default withMountCycle;
