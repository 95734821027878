import styled from 'styled-components';


export const Wrap = styled.div`
  position: fixed;
  z-index: 999;
  width: 300px;
  top: 10px;
  right: 10px;
  padding: 10px;
  color: #fff;
  background: #d9534f;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
