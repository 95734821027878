import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import FourthCell from './components/FourthCell';
import { Translate } from '../../../../../common_components/Translate/Translate';
import Task from '../../../../../svg/task.svg';

const PrintQualityClass = ({ op }) => {

  return (
    <Layout>
      <FirstCell
        icon={<SvgIcon component={Task} />}
        title={Translate('Валидация качества печати')}
      />
      <SecondCell op={op} />
      <ThirdCell useLink op={op} heading={Translate('Отчет о валидации качества печати')} />
      <FourthCell heading={Translate('Класс качества печати')}>
        <span>{op.printQualityClass}</span>
      </FourthCell>
    </Layout>
  );
};

export default PrintQualityClass;
