import React from 'react';
import { compose, setDisplayName } from 'recompact';
import { SingleSelect } from '../../../Fields';
import { foivValues } from './constants';
import { PARTICIPANT_TYPES } from '../../../../../constants';
import { withMountCycle, withRenderNothingNot } from '../../../utils';
import { required } from '../../../utils/validations';


const Foiv = () => (
  <SingleSelect
    name="foiv"
    placeholder="ФОИВ"
    values={foivValues}
    validate={required}
  />
);


export default compose(
  withRenderNothingNot([PARTICIPANT_TYPES.OGV]),
  setDisplayName('Foiv'),
  withMountCycle('foiv', null),
)(Foiv);
