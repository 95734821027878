import React from 'react';
import { Box, Grid, MuiThemeProvider } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { MaterialUiThemeUZ } from '../../../../../common_components/_MaterialUi/ThemeUZ';
import { DividerOfContent, StepTitle } from '../../Document/Document.styled';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import {
  isValidGTD,
  required,
} from '../../../../../common_components/Form/utils/validations';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import CountrySelect from '../../../../../common_components/Form/MaterialFields/Select/CountrySelect';
import CurrencySelect from '../../../../../common_components/Form/MaterialFields/Select/CurrencySelect';
import { FloatInput } from './FloatInput';
import { Translate } from '../../../../../common_components/Translate/Translate';

export const UzFields = () => {
  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={MaterialUiThemeUZ}>
      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            <Trans t={t}>
              сведения об участнике оборота <br /> маркируемых товаров
              (импортере)
            </Trans>
          </StepTitle>
        </Grid>
        <Grid xs={3}>
          <Box pr={2}>
            <Input
              name="recipient_inn"
              placeholder={Translate('ИНН/ПИНФЛ заявителя')}
              disabled
              validate={required}
            />
          </Box>
        </Grid>
      </Grid>

      <DividerOfContent />
      <br />

      <Grid container>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle uppercase>
            {Translate('Сведения о поставке маркируемой продукции')}
          </StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container>
            <Grid xs={6}>
              <Box pr={2}>
                <Input
                  name="registration_number"
                  placeholder={Translate('Номер ГТД')}
                  forceValidate
                  required
                  validate={isValidGTD}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <DatePicker
                required
                forceValidate
                name="registration_date"
                placeholder={Translate('Дата принятия ГТД')}
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
            </Grid>
            <Grid xs={12}>
              <CountrySelect
                placeholder={Translate('Страна отправления')}
                name="country_export"
                forceValidate
                required
                validate={required}
              />
            </Grid>
            <Grid xs={6}>
              <Box pr={2}>
                <CurrencySelect
                  placeholder={Translate('Валюта')}
                  name="currency"
                  forceValidate
                  required
                  validate={required}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <FloatInput
                name="excise_tax_sum"
                label={Translate('Курс валюты')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};
