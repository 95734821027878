import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from '../../../../common_components/ActionBar/ActionBar';
import Preloader from '../../../../common_components/Preloader/Preloader';
import SelectionList from '../../../../common_components/List/SelectionList/SelectionList';
import CommandBar from '../../../../common_components/CommandBar/CommandBar';
import Pagination from '../../../../common_components/List/Pagination/Pagination';
import * as actions from './ducks/Draft.actions';
import { FooterWrapper } from './Draft.styled';
import ContentWrapper from '../../../../common_components/styled/ContentWrapper';
import Form from '../../../../common_components/Form/Form';
import RequestListView from '../RequestList.view';

const DraftView = props => {
  const {
    actionBarLeft,
    actionBarRight,
    commandBarLeft,
    pagesCount,
    selectedPage,
    columns,
    options,
    getTdProps,
    getTheadThProps,
    data,
    onUpdate,
    onListRef,
    // isLoading,
  } = props;

  return (
    <ContentWrapper setHeight="auto">
      <ActionBar left={actionBarLeft} right={actionBarRight} />
      <Preloader>
        <Form data={data}>
          <SelectionList
            columns={columns}
            options={options}
            getTdProps={getTdProps}
            getTheadThProps={getTheadThProps}
            // height="calc(100vh - 267px)"
            name="data"
            data={data}
            meta={actions}
            onUpdate={onUpdate}
            onRef={onListRef}
            // hidden={isLoading}
          />
        </Form>
      </Preloader>

      <FooterWrapper>
        <CommandBar left={commandBarLeft} flexBasis="40%" />
        <Pagination
          meta={actions}
          pagesCount={pagesCount}
          selected={selectedPage}
          pageCounterInvisible={true}
        />
      </FooterWrapper>
    </ContentWrapper>
  );
};

DraftView.propTypes = {
  results: PropTypes.shape({}).isRequired,
  actionBarLeft: PropTypes.arrayOf(PropTypes.element),
  actionBarRight: PropTypes.arrayOf(PropTypes.element),
  commandBarLeft: PropTypes.arrayOf(PropTypes.element),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getTdProps: PropTypes.func.isRequired,
  // isLoading: PropTypes.bool.isRequired,
  pagesCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  onListRef: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

DraftView.defaultProps = {
  actionBarLeft: [],
  actionBarRight: [],
  commandBarLeft: [],
};

export default DraftView;
