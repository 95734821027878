export const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  SUZ: 'SUZ',
};

export const PREPARED_AUTHORITIES = Object.keys(USER_ROLES).reduce((obj, role) => {
  obj[role] = `ROLE_${role}`;
  return obj;
}, {});

export const userRoles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_ORG_IS_MP_OPERATOR: 'ROLE_ORG_IS_MP_OPERATOR',
  ROLE_ORG_LABELLING_CENTER: 'ROLE_ORG_LABELLING_CENTER',
  ROLE_ORG_FEDERAL_AUTHORITY: 'ROLE_ORG_FEDERAL_AUTHORITY',
  ROLE_ORG_TRADE_PARTICIPANT: 'ROLE_ORG_TRADE_PARTICIPANT',
  ROLE_ORG_OGV: 'ROLE_ORG_OGV',
  // ROLE_ORG_: '',
};
