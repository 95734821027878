import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import {
  Blocker,
  ButtonWrapper,
  ModalWrapper,
  Wrapper,
  InfoLabelViewStyle,
  TitleWrapper,
  FileName,
  styleStatusWrap,
  CloseWrapper,
} from './FilePreview.styled';
import InfoLabel from '../Form/Fields/Adapters/InfoLabel/InfoLabel';
import DocumentStatusLabel from '../DocumentStatusLabel/DocumentStatusLabel';
import {Translate} from '../Translate/Translate';

const FilePreviewView = (props) => {
  const {
    loaded,
    onAccept,
    onCancel,
    onBlockerClick,
    children,
    warning,
    docType,
    status,
    docNumber,
    title,
  } = props;

  return (
    <Wrapper>
      <Blocker
        onClick={onBlockerClick}
        onMouseDown={onBlockerClick}
        onMouseUp={onBlockerClick}
      />
      <ModalWrapper>
        <CloseWrapper>
          <Icon type="cross" onClick={onCancel} width={25} />
        </CloseWrapper>

        <TitleWrapper>
          <FileName>{ title }</FileName>
          {status && (
            <DocumentStatusLabel
              style={styleStatusWrap}
              status={status}
            />
          )}
        </TitleWrapper>

        {!loaded && (
          <React.Fragment>
            <ButtonWrapper>
              <Button onClick={onAccept} variant="contained" color="primary">
                <Icon type="upload" spacingRight={16} />
                {Translate('Загрузить')}
              </Button>
            </ButtonWrapper>
            <Button onClick={onCancel} variant="contained">{Translate('Отменить')}</Button>
          </React.Fragment>
        )}

        {children}

        {Boolean(warning) && (
          <InfoLabel value={warning} customStyle={InfoLabelViewStyle} />
        )}

      </ModalWrapper>
    </Wrapper>
  );
};


FilePreviewView.propTypes = {
  warning: PropTypes.string,
  docNumber: PropTypes.string,
  docType: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBlockerClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

FilePreviewView.defaultProps = {
  warning: undefined,
  docNumber: undefined,
  docType: undefined,
  status: undefined,
};

export default FilePreviewView;
