import moment from 'moment';

import { convertDateTimeToISOString, getFrontendTaskUrl } from './GrayArea.utils';

export const adaptCreateDocumentBody = payload => {
  const { information, gtins, productionLines } = payload;

  let data = {};

  data.docDate = moment().toISOString();
  data.productGroup = 'TOBACCO';

  data.notificationUrl = getFrontendTaskUrl('/gray-area/');

  data.closingPeriod = {
    from: convertDateTimeToISOString(information.fromDate, information.fromTime),
    to: convertDateTimeToISOString(information.toDate, information.toTime),
  }

  !_.isEmpty(information.noticeList)
    ? data.noticeList = information.noticeList
    : null

  !_.isEmpty(productionLines)
    ? data.productionLines = productionLines.map(({ productionLine }) => productionLine)
    : null

  !_.isEmpty(gtins)
    ? data.gtins = gtins.map(({ gtin }) => gtin)
    : null

  return data;
}
