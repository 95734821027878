import { registrationTheme } from './registration';
import { loginTheme } from './login';
import { goodsTheme } from './goods';
import { informationTheme } from './information';


export {
  loginTheme as selectLoginTheme,
  registrationTheme as selectRegistrationTheme,
  goodsTheme as selectGoodsTheme,
  informationTheme as selectInformationTheme,
};
