import React, { useMemo } from 'react';
import * as _ from 'lodash';

import {
  formatPeriodDate,
  formatDate,
  getOrgRolesStr,
} from '../../_utils/utils';
import {
  periodicityEnum,
  periodicityValuesMap,
} from '../../constants';
import { Translate } from '../../../../common_components/Translate/Translate';

export const useElements = ({ allOrgRoles, onEditRoles, periodicity, users }) =>
  useMemo(
    () => [
      {
        id: 'orgInn',
        label: Translate('Организация'),
        accessor: data => _.get(data, 'orgInn', []),
      },
      {
        id: 'rolesValues',
        label: Translate('Роли'),
        accessor: data => {
          const roles = _.get(data, 'roles', []);
          return getOrgRolesStr(roles, allOrgRoles);
        },
      },
      {
        id: 'systemName',
        label: Translate('Системное название'),
        accessor: data => _.get(data, 'systemName', ''),
      },
      {
        id: 'preparedCreateDate',
        label: Translate('Дата регистрации'),
        accessor: data => formatDate(_.get(data, 'createDate', '')),
      },
      {
        id: 'creator',
        label: Translate('Зарегестрировал'),
        accessor: data => _.get(data, 'creator.fullName'),
      },
      {
        id: 'preparedUpdateDate',
        label: Translate('Дата изменения'),
        accessor: data => formatDate(_.get(data, 'updateDate', '')),
      },
      {
        id: 'updater',
        label: Translate('Изменил'),
        accessor: data => _.get(data, 'updater.fullName'),
      },
      {
        id: 'paramsCount',
        label: Translate('Количество применённых фильтров'),
        accessor: data => {
          const params = _.get(data, 'params') ? JSON.parse(data.params) : {};
          return Object.keys(params).length;
        },
      },
      {
        id: 'format',
        label: Translate('Формат'),
        accessor: () => 'CSV',
      },
      {
        id: 'periodicity',
        label: Translate('Тип'),
        accessor: () => periodicityValuesMap[periodicity],
      },
      {
        id: 'period',
        label: Translate('Период отправления'),
        accessor: data => formatPeriodDate(data, 'startDate', 'endDate'),
      },
      {
        id: 'users',
        label: Translate(`Получател${users.length <= 1 ? 'ь' : 'и'}`),
        accessor: () => users.join(', '),
      },
      {
        id: 'sharedRoles',
        label: Translate('Доступно ролям'),
        accessor: data => {
          const sharedRoles = _.get(data, 'sharedRoles', []);
          return getOrgRolesStr(sharedRoles, allOrgRoles);
        },
        action: {
          label: Translate('Редактировать'),
          callback: () => onEditRoles(),
          disabled: periodicity === periodicityEnum.SINGLE,
        },
      },
    ],
    [allOrgRoles, onEditRoles, periodicity, users],
  );
