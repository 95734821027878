import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto 36px 0;
`;

export const CellWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsWrapper = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
`;

export const Erros = styled.p`
  margin: 0;
  color: #ff3d00;
`;
