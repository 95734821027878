import React, { Fragment } from 'react';
import {
  DocumentTitleName,
  RedText,
  GreenText,
  WarningContent,
  WarningIconWrap,
  WarningOfNeed,
} from '../../Document/Document.styled';
import { Error, Success } from '@crpt-ui/icons';
import { Translate } from 'src/common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import {countryCodeFlags} from "../../../../../constants";

export const TitleAndWarning = ({ left, countryCode }) => {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <DocumentTitleName>
        {
          countryCode === 'UZ'
            ? Translate('Уведомление о ввозе (импорт)')
            : Translate('Уведомление о ввозе товаров (третьи страны)')
        }
      </DocumentTitleName>
      <WarningOfNeed>
        <WarningContent>
          <WarningIconWrap>
            {left > 0 ? <Error htmlColor={'#ff4b4b'}/> : <Success htmlColor={'#60e84a'}/>}
          </WarningIconWrap>
          {left > 0 ? (
            <div>
              <RedText>{Translate('Красным')}</RedText>{' '}
              {Translate('отмечены обязательные для заполнения поля')}
            </div>
          ) : (
            <div>
              {i18n.language === 'RU' || i18n.language === 'UZ' ?
                countryCodeFlags.isUZ
                  ? Translate('Все обязательные поля заполнены')
                    : (
                      <Fragment>
                        <GreenText>{Translate('Зеленым')}</GreenText>{' '}
                        {Translate('отмечены обязательные для заполнения')}
                        {' '}
                        {Translate('заполненные поля')}
                      </Fragment>
                    )
                  : i18n.language === 'EN'
                    ? countryCodeFlags.isUZ
                      ? Translate('Все обязательные поля заполнены')
                      : (
                        <Fragment>
                            Mandatory and completed fields are marked in <GreenText>{'green'}</GreenText>
                        </Fragment>
                      )
                    : (
                      <Fragment>
                          Міндетті және толтырылған өрістер <GreenText>{'жасыл'}</GreenText> түспен белгіленген
                      </Fragment>
                      )
              }
            </div>
          )}
        </WarningContent>
      </WarningOfNeed>
    </React.Fragment>
  );
};
