import { GoodsColumns as gtinsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';

export const modalActionTypes = {
  GTINS_MODAL: {
    OPEN: 'GTINS_MODAL_OPEN',
    CLOSE: 'GTINS_MODAL_CLOSE',
  },
  UPLOAD_GTINS_MODAL: {
    OPEN: 'UPLOAD_GTINS_MODAL_OPEN',
    CLOSE: 'UPLOAD_GTINS_MODAL_CLOSE',
  },
};

export const columns = [
  {
    ...gtinsColumns.name,
  },
  {
    ...gtinsColumns.gtin,
  },
];

export const manualDataKey = 'gtin';
