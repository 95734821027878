import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { Toast } from 'src/utils/Toast';
import { replace } from 'react-router-redux';
import * as actions from './ExportNotificationToTheEaes.actions';
import * as requestListActions from '../../ducks/RequestList.actions';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import Api from 'src/common_components/Api/Api';
import * as draftSelectors from '../../Draft/ducks/Draft.selectors';
import { NCALayerService } from 'src/common_components/Ncalayer/NCALayerService';
import { Base64 } from '@crpt-ui/crypto';
import { senderDocumentFormat } from '../ExportNotificationToTheEaes.utls';

function* mountSaga(action) {
  const { payload } = action;
  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id: payload },
  };
  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
        __form_document_status: success.data.status,
      }),
    );
    yield put(actions.setError(success.data.downloadDesc));
  }
  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* submitSaga(action) {
  const { payload } = action;

  const url = '/api/v1/edo/export-notification';
  const DOC_TYPE = payload.docType;
  const document = senderDocumentFormat(payload);
  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const base64_data = Base64.encode(JSON.stringify(document));
  const signature = yield Ncalayer.signData(base64_data);

  if (!signature) {
    yield put(actions.reset());
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  let data = {
    registration_number: null,
    signature,
    document: base64_data,
    document_type: DOC_TYPE,
  };

  const locationHref = window.location.href;
  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    data = {
      ...data,
      draft_id:
        drafttId ||
        locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, ''),
    };
  }

  const requestPayload = {
    url,
    method: 'post',
    data,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  if (success) {
    const error = get(success, 'data.errors.0', false);
    if (error.errorCode !== 0) {
      Toast.showError({ content: error.errorMessage });
    } else {
      Toast.showSuccess({
        content: 'Уведомление об экспорте в ЕАЭС отправлено',
      });
      Toast.showInfo({
        content: 'Создание уведомления может занять некоторое время.',
        autoClose: 5000,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace('/documents/list'));
    }
  }
  if (error) {
    yield put(actions.reset());
    yield call(Toast.showError, {
      content: get(error, 'responsecertificate.data.message', 'error'),
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.submit, submitSaga);
}
