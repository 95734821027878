import { Icon } from '@crpt-ui/core';
import React from 'react';
import {isNil} from "lodash";
import { Link } from 'src/fragments/Link';
import { CellTooltip } from 'src/fragments/Table/Cell/CellTooltip';
import { useStyles } from '../Operation.styles';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { countryCodeFlags } from '../../../../../constants';
import FirstCell from './components/FirstCell';
import FourthCell from './components/FourthCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import FifthCell from './components/FifthCell';
import Layout from './Layout';

const ReturnToTurnover = ({ op }) => {
  const classes = useStyles();

  const renderValues = () => (
    <Link
      className={classes.link}
      to={`/documents/receipt/${encodeURIComponent(op.docId)}/`}
    >
      {
        // middleDottedString(op.docId)
        op.docId
      }
    </Link>
  );

  return (
    <Layout>
      <FirstCell
        icon={<Icon name="Retail" />}
        title={Translate('Чек возврата')}
      />
      <SecondCell firstHeading={Translate('Розница')} op={op} />
      <ThirdCell heading={Translate('Кассовый чек')}>
        <CellTooltip
          alwaysDisplay
          placement="top"
          title={op.docId}
          render={renderValues}
        />
      </ThirdCell>
      <FourthCell heading={Translate('Цена продажи')}>{op.total}</FourthCell>
      {countryCodeFlags.isUZ && !isNil(op.partialQuantity) ? (
        <FifthCell heading={Translate('Вскрытая упаковка')}>
          {`${op.partialQuantity}%`}
          {' '}
          {Translate('упаковки реализовано')}
        </FifthCell>
      ) : null}
    </Layout>
  );
};

export default ReturnToTurnover;
