import { authInstance } from './authInstance';
import { CadesUtil } from '../utils/CadesUtil';
import { AuthService } from './AuthService';
import { Signer } from '../utils/Signer';
import { Toast } from '../utils/Toast';

export class RegistrationService {
  static registerParticipant(vals) {
    return new Promise((resolve, reject) => {
      AuthService.getCertKey()
        .then(answer => {
          console.log('answer key register', answer.data);
          const user = vals;
          // //TODO: hotfix, do it in Registration components
          user.type = user.participantType;
          delete user.participantType;
          // //
          const uuid = answer.data.uuid;
          const data = answer.data.data;
          const cert = vals.selectedCert;
          Object.assign(user, user.user);
          delete user.user;
          delete user.selectedCert;
          console.log('user =========== register ===============', user);
          user.ofd = user.ofd && user.ofd.map(v => ({id: v.id, name: v.name}));
          user.edo = user.edo && user.edo.map(v => ({id: v.id, name: v.name}));
          user.phones = [user.phone];
          user.productionPlaces = user.productionPlaces && user.productionPlaces.map(pr => {
            pr.emissionRegistrars = pr.emissionRegistrars.map(v => ({partNumber: v.trim()}));
            return pr;
          });
          delete user.phone;
          console.log('cert =========== register ===============', cert);
          Signer.signMessage(data, cert, { detached: false })
            .then(signed => {
              console.log('signed', signed);
              authInstance
                .request({
                  url: '/private-office-api/private/v2/participants/cert/reg',
                  method: 'post',
                  headers: {
                    Authorization: null,
                    ContentType: 'application/json;charset=UTF-8',
                  },
                  data: {
                    user,
                    uuid,
                    data: signed,
                  },
                })
                .then(answer => resolve(answer))
                .catch(err => reject(err));
            })
            .catch(err => {
              const error = CadesUtil.prepareError(err);
              Toast.showError({
                content: `Не удалось подписать сообщение: ${error}.`,
              });
            });
        })
        .catch(err => {
          console.error('err register key answer', err);
          reject(err);
        });
    });
  }
}
