import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Form from '../Form';
import FormContextWrapper from '../FormContextWrapper';
import * as actions from './ducks/ModalForm.actions';
import * as selectors from './ducks/ModalForm.selectors';

Modal.setAppElement('body');


class ModalForm extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    style: PropTypes.shape({}).isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.element,
  };

  static defaultProps = {
    content: null,
  };

  onClose = () => {
    const { onCancel, onClose } = this.props;
    onClose();
    onCancel();
  };

  render() {
    const { isOpen, onAccept, style, content } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={style}
      >
        <Form onSubmit={onAccept}>
          <FormContextWrapper>
            {content}
          </FormContextWrapper>
        </Form>
      </Modal>
    );
  }
}

const mapState = state => ({
  isOpen: selectors.isOpen(state),
  style: selectors.style(state),
  content: selectors.content(state),
  onAccept: selectors.onAccept(state),
  onCancel: selectors.onCancel(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(actions.close()),
});


export default connect(mapState, mapDispatch)(ModalForm);
