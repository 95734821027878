import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompact';
import { Wrap } from '../styled';
import { Title, RegistrationButtonWrap } from '../../../styled/index';
import { PARTICIPANT_TYPES } from '../../../../../constants/index';
import { Button } from '../../../Fields';
import ProductionPlace from './ProductionPlace';
import { buttonWhiteTheme } from '../../../../../theme/ru-tabaco/button';
import {
  withMountCycle,
  withRenderNothingNot,
  withItemsArray,
  withRenderOkvedOrNothing,
} from '../../../utils';

const newProductionPlace = {
  address: '',
  code: '',
  emissionRegistrars: [''],
  productionCodeLines: [''],
  key_id: new Date().getTime(),
};

const ProductionPlaces = props => {
  const {
    active,
    items,
    onAppend,
    onActivate,
    onRemove,
    onRemoveValue,
    onPushValue,
    onUpdateField,
  } = props;

  const canContinue = items.reduce((result, current) => {
    if (!result) return false;
    return (
      current.address &&
      current.address.length &&
      current.code &&
      current.code.length &&
      current.productionCodeLines &&
      current.productionCodeLines.reduce((total, val) => {
        return total && val.code && val.code.length;
      }, true) &&
      current.emissionRegistrars &&
      current.emissionRegistrars.reduce((total, val) => {
        return total && val && val.length;
      }, true)
    );
  }, true);

  return (
    <Wrap>
      <Title>Производство</Title>
      {items.map((place, index) => (
        <ProductionPlace
          key={place.key_id}
          name={`productionPlaces[${index}]`}
          index={index}
          data={place}
          active={index === active}
          onActivate={onActivate}
          onPushValue={onPushValue}
          onRemoveValue={onRemoveValue}
          onRemove={onRemove}
          onUpdate={onUpdateField}
        />
      ))}
      <RegistrationButtonWrap>
        <Button
          inline
          onClick={onAppend}
          disabled={!canContinue}
          theme={{ Button: buttonWhiteTheme }}
        >
          <Icon type="add-plus" fill={canContinue ? "#434244" : '#ffffff'} width={10} spacingRight={19} />
          Добавить производство
        </Button>
      </RegistrationButtonWrap>
    </Wrap>
  );
};

ProductionPlaces.propTypes = {
  active: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAppend: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onPushValue: PropTypes.func.isRequired,
};

export default compose(
  withRenderNothingNot([PARTICIPANT_TYPES.PRODUCER]),
  withRenderOkvedOrNothing([PARTICIPANT_TYPES.PRODUCER]),
  setDisplayName('ProductionPlaces'),
  withItemsArray('productionPlaces', newProductionPlace),
  withMountCycle('productionPlaces', [newProductionPlace]),
  onlyUpdateForKeys(['items'])
)(ProductionPlaces);
