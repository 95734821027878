import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';
import defaultTheme from '../theme';

const MultiSelectOption = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.padding || '8px 16px'};
  margin: ${props => props.margin};

  :after {
    content: ${props => (props.multi ? null : '')};
    width: 40px;
  }
  cursor: ${props => props.cursor};
  font-size: 16px;
  box-sizing: border-box;
  height: ${props => (props.custom ? '' : props.height)};
  color: ${props => props.color};
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
  background: ${props => props.background};
  &:hover {
    background: ${props => props.hoverBackground};
    color: ${props => props.hoverColor};
  }
`;

export const Option = ({
  children,
  theme: themeFromProps,
  disabled,
  custom,
  onClick,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;
  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : 'main',
  );

  const mergedSelectOption = innerMerge(
    {},
    defaultTheme.Select.SelectOption,
    (themeFromPropsHasSelect && themeFromProps.Select.SelectOption) || {},
  );

  Object.assign(
    theme,
    getThemeAsPlainObjectByKeys(
      mergedSelectOption,
      disabled ? 'disabled' : undefined,
    ),
  );
  return (
    <MultiSelectOption {...theme} custom={custom} onClick={onClick}>
      {children}
    </MultiSelectOption>
  );
};

Option.defaultProps = {
  disabled: false,
  custom: false,
};

Option.propTypes = {
  children: PropTypes.elementType,
  disabled: PropTypes.bool,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      SelectOptionsPanel: PropTypes.object,
    }),
  }).isRequired,
  custom: PropTypes.bool,
  onClick: PropTypes.func,
};
