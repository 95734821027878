import React from 'react';

import {getTableData} from './DeclarationTable.utils';

import {DeclarationTableView} from './DeclarationTable.view';

export const DeclarationTable = ({declarationDocument}) => {
  const tableData = getTableData(declarationDocument.body.content);
  const tableInstance = React.useRef();
  console.log('tableData: ', tableData);
  
  return <DeclarationTableView data={tableData} tableInstance={tableInstance} />;
}