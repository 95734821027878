import { Translate } from '../../../common_components/Translate/Translate';

export const steps = [
  { value: 0, label: Translate('Шаг 1. Общая информация') },
  { value: 1, label: Translate('Шаг 2. Производственные линии') },
  { value: 2, label: Translate('Шаг 3. GTIN') },
];

export const breadcrumbs = [
  {
    href: '/gray-area',
    title: Translate('Серая зона'),
    id: 0,
  },
  {
    title: Translate('Формирование выгрузки'),
    id: 1,
  },
];

export const actionTypes = {
  INFORMATION: {
    UPDATE: 'INFORMATION_UPDATE',
    SET_ERRORS: 'SET_ERRORS',
  },
  GTINS: {
    ADD: 'GTINS_ADD',
    DELETE: 'GTINS_DELETE',
    UPDATE: 'GTINS_UPDATE',
  },
  PRODUCTION_LINES: {
    ADD: 'PRODUCTION_LINES_ADD',
    DELETE: 'PRODUCTION_LINES_DELETE',
  }
}
