import React from 'react';
import PropTypes from 'prop-types';
import { CellContentWrap, CellWrap, TableWrap } from './TableScrollWrapper.styled';


const TableScrollWrapper = ({ height, children, hidden, fullHeight }) => (
  <TableWrap height={height} hidden={hidden} fullHeight={fullHeight}>
    <CellWrap fullHeight={fullHeight}>
      <CellContentWrap 
        //eslint-disable-next-line 
        role="tablescrooller" 
        height={height}
        className="tablescroller"
        fullHeight={fullHeight}
      >
        { children }
      </CellContentWrap>
    </CellWrap>
  </TableWrap>
);

TableScrollWrapper.propTypes = {
  height: PropTypes.string,
  children: PropTypes.element.isRequired,
  hidden: PropTypes.bool.isRequired,
};

TableScrollWrapper.defaultProps = {
  height: undefined,
};

export default TableScrollWrapper;
