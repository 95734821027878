import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Icon } from '@crpt/react-icon';

import { withRouter } from 'react-router-dom';

import { getDocumentStatusIconByDocStatus } from '../../DocumentDetails/DocumentDetails.utils';
import {documentTypes} from '../../../constants/documentTypes';
import { countryCodeFlags } from '../../../constants';

const Wrap = styled.div`
  width: 100%;
  display: flex;
`;

const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a99f4;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;

const Id = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;

const DocumentId = props => {
  const document = props.document ? props.document : props.options.id;
  const status = props.status ? get(props, 'status', null) : get(document, '_original.status', null);
  const documentType = get(document, '_original.documentType', false) || get(document, '_original.type', '') || get(document, 'type', '');
  const id = props.id ? props.id : document.id;

  const isPayment = documentType === documentTypes.PAYMENT;

  const scenarioGroups = {
    '/cis/scenario/3': 'list',
    '/cis/scenario/5': 'list',
  };
  const scenarioUrl = _.get(props, 'match.url');
  const documentRoute = scenarioGroups[scenarioUrl] || 'receipt';
  const documentHref = `/documents/${documentRoute}/${encodeURIComponent(id)}/`;
  const docNotUndefined = documentType !== 'UNDEFINED'
  const href = get(props, 'options.id._original.href', documentHref);
  // const href = get(props, 'options.id._original.href', `/documents/receipt/${encodeURIComponent(id)}/`);
  // const href = get(props, 'options.id._original.href', `/documents/list/${encodeURIComponent(id)}/`);

  return (
    <Wrap>
      <Icon type={getDocumentStatusIconByDocStatus(status)} spacingRight={16} />
      {!isPayment && docNotUndefined ? <Link
        href={href}
        onClick={e => {
          e.preventDefault();
          props.history.push(href);
        }}
      >
        {id}
      </Link> : <Id>{id}</Id>}
    </Wrap>
  );
};

export default withRouter(DocumentId);
