import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './Ncalayer.actions';
import { store } from '../../store';
import { getCertSerial } from '../../utils/cookieHelpers';
import Crypto, { Base64 } from '@crpt-ui/crypto';
import { Toast } from 'src/utils/Toast';
import { CadesUtil } from 'src/utils/CadesUtil';

let webSocket;
let callback;


function* connectToNcalayerAppletSaga() {
  yield webSocket = new WebSocket('wss://127.0.0.1:13579/');
  webSocket.onopen = function() {
    store.dispatch(actions.setSuccessConnectionStatus());
  };
  webSocket.onclose = function(event) {
    // eslint-disable-next-line no-empty
    if (event.wasClean) {} else {
      store.dispatch(actions.setErrorConnectionStatus());
    }
  };

  webSocket.onmessage = function(event) {
    const result = JSON.parse(event.data);
    if (result != null) {
      const rw = {
        code: result['code'],
        message: result['message'],
        responseObject: result['responseObject'],
        getResult: function() {
          return this.result;
        },
        getMessage: function() {
          return this.message;
        },
        getResponseObject: function() {
          return this.responseObject;
        },
        getCode: function() {
          return this.code;
        },
      };
      if (callback != null) {
        callback(rw);
      }
    }
  };
}

function* connectionErrorSaga() {
  yield put(actions.setConnectionStatus('fail'));
}

function* connectionSuccessSaga() {
  yield put(actions.setConnectionStatus('success'));
}

export function* signSaga(action, callback, isAttach = true) {
  yield createCAdESFromBase64('PKCS12', 'AUTHENTICATION', action, isAttach, callback);
}

function createCAdESFromBase64(storageName, keyType, base64ToSign, flag, callBack) {
  const data = {
    'module': 'kz.gov.pki.knca.commonUtils',
    'method': 'createCMSSignatureFromBase64',
    'args': [storageName, keyType, base64ToSign, true],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(data));
}

export function* getKeyInfoSaga(action, callBack) {
  yield getKeyInfo('PKCS12', callBack);
}

function getKeyInfo(storageName, callBack) {
  const data = {
    'module': 'kz.gov.pki.knca.commonUtils',
    'method': 'getKeyInfo',
    'args': [storageName],
  };

  callback = callBack;
  webSocket.send(JSON.stringify(data));
}

export default function* watchSignCalls() {
  yield takeEvery(actions.verifyApplet, connectToNcalayerAppletSaga);
  yield takeEvery(actions.verifyApplet, connectToNcalayerAppletSaga);
  yield takeEvery(actions.setErrorConnectionStatus, connectionErrorSaga);
  yield takeEvery(actions.setSuccessConnectionStatus, connectionSuccessSaga);
  yield takeEvery(actions.signed, signSaga);
  yield takeEvery(actions.getKeyInfo, getKeyInfoSaga);
}
