import { createActions } from 'redux-actions';

export const {
  mounted,
  unMounted,
  loaded,
  setData,
  setPage,
  downloadResults,
  changeResults,
  changeTask,
} = createActions(
  {
    MOUNTED: payload => payload,
    UN_MOUNTED: () => {},
    LOADED: () => {},
    SET_DATA: payload => payload,
    SET_PAGE: payload => payload,
    DOWNLOAD_RESULTS: payload => payload,
    CHANGE_RESULTS: payload => payload,
    CHANGE_TASK: payload => payload,
  },
  {
    prefix: 'UNLOAD_SERVICE_DETAILS',
  },
);
