import React, { useMemo } from 'react';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import * as _ from 'lodash';

import {
  hardcodeRoles,
  statusColorsMap,
  statusIconsMap,
  statusValuesMap,
} from '../../constants';
import { formatPeriodDate, isOperator } from '../../_utils/utils';
import { useElements } from './Info.elements';

const DocumentAttribute = React.memo(
  withStyles(theme => ({
    root: {
      fontSize: 14,
      lineHeight: 16 / 14,
      paddingTop: 16,
      paddingBottom: 16,
      boxShadow: 'inset 0 -1px 0 #F2F2F2',
      transition: theme.transitions.create('box-shadow'),

      '&:hover': {
        boxShadow: `inset 0 -2px 0 ${theme.palette.primary.main}`,
      },
    },
    action: {
      textAlign: 'right',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    label: {
      fontWeight: 600,
    },
  }))(({ value, label, classes, action }) => {
    return (
      <div className={classes.root}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={6}>
            <div className={classes.label}>{label}</div>
          </Grid>

          <Grid item xs={action && !action.disabled ? 4 : 6}>
            {value ? value : '-'}
          </Grid>

          {!!action && !action.disabled && (
            <Grid item xs={2}>
              <div className={classes.action} onClick={action.callback}>
                {action.label}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }),
);

const Info = ({ data, onEditRoles }) => {
  const name = useMemo(() => _.get(data, 'name', ''), [data]);
  const currentStatus = useMemo(() => _.get(data, 'currentStatus', ''), [data]);
  const readonly = useMemo(() => _.get(data, 'readonly', false), [data]);

  const periodicity = useMemo(() => _.get(data, 'periodicity', ''), [data]);
  const period = useMemo(() => formatPeriodDate(data, 'startDate', 'endDate'), [
    data,
  ]);

  const users = useMemo(
    () =>
      _.get(data, 'users', [])
        .map(user =>
          [user.fullName, user.email].filter(item => item).join('/ '),
        )
        .filter(user => user),
    [data],
  );

  const allOrgRoles = useMemo(
    () => [..._.get(data, 'availableOrgRoles', []), ...hardcodeRoles],
    [data],
  );

  const elements = useElements({
    allOrgRoles,
    onEditRoles,
    periodicity,
    users,
  });

  const showForUser = [
    'preparedCreateDate',
    'systemName',
    'paramsCount',
    'format',
    'periodicity',
    'period',
    'users',
  ];

  const renderElements = useMemo(
    () =>
      elements
        .filter(element =>
          isOperator() ? true : showForUser.includes(element.id),
        )
        .filter(element => (period ? true : element.id !== 'period'))
        .filter(element => (!readonly ? true : element.id !== 'users'))
        .map((element, index) => {
          const props = _.omit(element, ['id', 'accessor']);
          const value = element.accessor(data);
          return <DocumentAttribute key={index} value={value} {...props} />;
        }),
    [elements, period, readonly, showForUser],
  );

  return (
    <Box padding={4}>
      <Box mb={8}>
        <Grid item lg={8} md={12}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h1">{name}</Typography>
            </Grid>

            <Grid item>
              <div
                style={{
                  color: statusColorsMap[currentStatus],
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 16 / 14,
                  marginLeft: 20,
                }}
              >
                {statusIconsMap[currentStatus] && (
                  <span
                    style={{
                      marginRight: 8,
                    }}
                  >
                    {statusIconsMap[currentStatus]}
                  </span>
                )}
                {statusValuesMap[currentStatus]}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid item md={12} lg={8}>
        {renderElements}
      </Grid>
    </Box>
  );
};

export default Info;
