import React from 'react';
import { SingleSelect } from '@crpt/react-select';
import { packTypes } from '../../constants';
import { FilterURLHelper } from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';

export const cisPackageTypes = Object.keys(packTypes).map((key, i) => {
  return {
    id: i,
    title: packTypes[key],
    cisPackageType: key,
  };
});

class CISPackageSelect extends React.Component {
  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const cisPackage = cisPackageTypes.find(v => v.cisPackageType === value);
      this.select.current.onSelect({ preventDefault: () => {} }, cisPackage);
    }
  }

  render() {

    const {selectedPackageType} = this.props;

    let selectedId;

    if (selectedPackageType) {
      selectedId = cisPackageTypes.findIndex(s => s.cisPackageType === selectedPackageType);
    }
    const {onRef} = this.props;

    return (
      <SingleSelect
        placeholder={Translate(("Упаковка"))}
        name="cisPackageType"
        values={cisPackageTypes}
        selectedId={selectedId}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

CISPackageSelect.displayName = 'SingleSelect';

export default CISPackageSelect;
