import { notify } from 'react-notify-toast';
import { takeEvery, call } from 'redux-saga/effects';
import * as actions from './CheckStatus.actions';
import Api from '../../../../common_components/Api/Api';

function* submitSaga(action = {}) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/auth/document/status/${payload}`,
    method: 'get',
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    notify.show(
      `Статус заявки на регистрацию в ИС МП ${payload} направлен на адрес электронной почты, указанный в заявке на регистрацию`,
      'success',
      5000,
    );
  }
}

export default function* watch() {
  yield takeEvery(actions.submit, submitSaga);
}
