import { Icon } from '@crpt-ui/core';
import React from 'react';
import { Link } from 'src/fragments/Link';

import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import { getPackType } from './constants';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const Application = ({ op }) => {
  const classes = useStyles();
  return (
    <Layout>
      <FirstCell
        icon={<Icon name="Application" />}
        title={Translate('Нанесение')}
      />
      <SecondCell op={op} />
      <ThirdCell heading={Translate('Документ о нанесении')}>
        {getPackType(op.packageType) ? (
          <div className={classes.id}>{op.docId}</div>
        ) : (
          <Link
            className={classes.link}
            to={`/documents/list/${encodeURIComponent(op.docId)}/basic-info`}
          >
            {op.docId}
          </Link>
        )}
      </ThirdCell>
    </Layout>
  );
};

export default Application;
