import React from 'react';

export const CheckIcon = () => {
  return (
    <svg
      width="16"
      fill="#1A99F4"
      height="16"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="select-checkmarkFill 3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M 1.88672 0.331075C 1.45462 -0.110358 0.756182 -0.110358 0.324078 0.331075C -0.108026 0.772509 -0.108026 1.48603 0.324078 1.92746L 6.33223 8.00056L 3.32408 11.0725C 2.89197 11.514 2.89197 12.2286 3.32408 12.6689C 3.75618 13.1104 4.45462 13.1104 4.88672 12.6689L 8.6762 8.79876C 8.8917 8.57748 9 8.28959 9 8.00056C 9 7.71154 8.8917 7.42252 8.6762 7.20237L 1.88672 0.331075Z"
          transform="matrix(0 1 -1 0 15 4)"
        />
      </g>
    </svg>
  );
};
