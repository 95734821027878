import { Container } from '@crpt/react-page-layout';
import React from 'react';
import { useStyles } from '../Operation.styles';

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <Container fillWidth gutter={16} className={classes.container}>
      {children}
    </Container>
  );
};

export default Layout;
