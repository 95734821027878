import {actionTypes} from '../../constants';
import {set} from 'lodash/fp';
import {filter, get} from 'lodash';


export function data(state = {}, action = {}) {
  let temp = null;

  switch (action.type) {
    case actionTypes.FORM.DELETE_FORM:
      return set(action.path, undefined, state);

    case actionTypes.FORM.ITEM_VALIDATED:
      return set(`${action.path}.error`, action.value, state);

    case actionTypes.FORM.ITEM_ACTIVE:
      return set(`${action.path}.active`, true, state);

    case actionTypes.FORM.ITEM_ACTIVE_ONLY: {
      const oldCol = get(state, action.path, []);
      let newCol = oldCol.map(i => ({ ...i, active: false }));
      const newState = set(action.path, newCol, state);
      return set(`${action.only}.active`, true, newState);
    }


    case actionTypes.FORM.ITEM_CHANGED: {
      let value = action.value;
      if (typeof value === 'string') value = value.trim();
      return set(`${action.path}.filled`, !!value, state);
    }


    case actionTypes.FORM.ITEM_REMOVE:
      temp = get(state, action.path, []);
      temp = filter(temp, (i, k) => k !== action.index);
      return set(`${action.path}`, temp, state);

    case actionTypes.FORM.ITEM_LOADING:
      return set(`${action.path}.loading`, true, state);

    case actionTypes.FORM.ITEM_LOADED:
      temp = set(`${action.path}.loading`, false, state);
      if (action.value.results) {
        return set(`${action.path}.values`, action.value.results.map(v => action.loader(v)), temp);
      } else {
        return set(`${action.path}.values`, action.loader(action.value), temp);
      }

    case actionTypes.FORM.CHECKBOX_TOGGLED: {
      const active = get(state, `${action.path}.checked`, []);
      if (~active.indexOf(action.value)) {
        temp = set(`${action.path}.checked`, active.filter(c => c !== action.value), state);
        if (active.length === 1) return set(`${action.path}.filled`, false, temp);
        return temp;
      } else {
        temp = set(`${action.path}.checked`, [].concat(active, action.value), state);
        return set(`${action.path}.filled`, true, temp);
      }
    }

    case actionTypes.FORM.CLEAR_PATHS: {
      let oldState = state;
      for (const path of action.paths) {
        oldState = set(path, undefined, oldState);
      }
      return oldState;
    }

    default:
      return state;
  }
}
