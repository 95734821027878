import { saveAs } from 'file-saver';

import { CISService } from '../services/CISService';
import { getListActions } from '../utils/actionGenerators';
import { getLimitOffsetFormatter } from '../utils/pageFormatters';
import { loadStatistic } from '../actions/AltScenarios';
import CISList from '../projects/motp/lists/CISList';
import {
  defaultFilterParamSerializer,
  defaultRequestParamSerializer,
} from '../utils/filterSerializers';

import {StoreHelper} from '../utils/StoreHelper';
import {AltScenariosService} from '../services/AltScenariosService';
import {actionTypes} from '../constants';

const downloadListReport = (type, data) => dispatch => {
  CISService.getListReport(type, data)
    .then(answer => saveAs(answer.data, `report.${type}`))
    .catch(err => console.log('download list error: ', err));
};

const getScheme = (scenarioCode) => async dispatch => {
  try {
    const scheme = await AltScenariosService.getScheme(scenarioCode);
    dispatch({type: actionTypes.ALT_SCENARIOS.SCHEME_LOADED, value: scheme.data.scheme});
  }

  catch(e) {
    console.warn('cannot load scheme');
  }

};

export default {
  ...getListActions({
    actionNamespace: CISList.actionNamespace,
    selectorNamespace: CISList.selectorNamespace,
    getList: CISService.getList,
    pageFormatter: getLimitOffsetFormatter(CISList.limit),
    filterApplyFilterParamsSerializer: defaultFilterParamSerializer,
    listMountFilterParamsSerializer: params => {
      const scenarioType = window.location.pathname.split('/')[3];
      return { ...params, scenarioType };
    },
    onListMount: params => {
      StoreHelper.dispatch(loadStatistic({ scenarioType: params.scenarioType }));
    },
    listMountRequestParamsSerializer: params => {
      const scenarioType = window.location.pathname.split('/')[3];
      return { ...params, scenarioType };
    },
    filterApplyRequestParamsSerializer: defaultRequestParamSerializer,
  }),
  downloadListReport,
  getScheme,
};
