import {actionTypes} from '../../constants';


const initialState = false;

export function isOpen(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ORDER_FORM.ORDER_FORM_SHOW:
      return true;
    case actionTypes.ORDER_FORM.ORDER_FORM_CLOSE:
      return false;
    default:
      return state;
  }
}
