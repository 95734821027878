export function hexMaskToArrayItems(hex, arr) {
  let pickedItems = [];
  if (Array.isArray(arr) && arr.length > 0 && typeof hex === 'string') {
    for (let i = 0; i < hex.length; i++) {
      const mask = parseInt(hex.charAt(i), 16);
      if (!isNaN(mask)) {
        for (let j = 0; j < 4; j++) {
          if ((mask >>> j) % 2 === 1) {
            let pickedItem = arr[i * 4 + j];
            if (pickedItem) {
              pickedItems.push(pickedItem);
            }
          }
        }
      }
    }
  }
  return pickedItems;
}