import styled from 'styled-components';
import {UserListItemButtonsWrap} from './UserListItemButtonsWrap';

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  transition: background 0.2s ease;
  background: rgba(0,0,0,0);
  height: 60px;
  box-sizing: border-box;
  background: #ffffff;
  width: 100%;
  cursor: pointer;
  padding-left: ${props => props.cardTheme.paddingLeft};
  padding-right: ${props => props.cardTheme.paddingRight};
  position: relative;
  :nth-child(2n) {
    background: rgba(196, 196, 196, 0.08);
  }
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.22);
    z-index: 999;
    ${UserListItemButtonsWrap} {
      display: flex;
    }
  }
`;
