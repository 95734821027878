import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import SingleSelect from '../../_refactor/Select/SingleSelect';
import Option from './Option';
import Value from './Value';
import { getCertificateValues } from './CertificateSelect.utils';
import * as cadesActions from '../../../../Cades/Cades.actions';
import * as cadesSelectors from '../../../../Cades/Cades.selectors';

import * as rutokenActions from '../../../../Rutoken/Rutoken.actions';
import * as rutokenSelectors from '../../../../Rutoken/Rutoken.selectors';

const CertificateSelect = props => {
  const { values, onMount, ...pass } = props;

  useEffect(() => onMount(), []);

  const preparedValues = useMemo(() => getCertificateValues(values), [values]);

  return (
    <SingleSelect
      placeholder="Выберите электронную подпись"
      noValuesText="Сертификат УКЭП не найден либо не настроен плагин КриптоПро"
      doubleIcon
      savePlaceholder={false}
      renderValue={Value}
      renderOption={Option}
      {...pass}
      values={preparedValues}
    />
  );
};

const mapState = state => {

  const cadesCerts = cadesSelectors.certificates(state);
  const rutokenCerts = rutokenSelectors.certificates(state);

  const certs = [...cadesCerts, ...rutokenCerts];

  return { values: certs };
};

const mapDispatch = dispatch => ({
  onMount: () => {
    dispatch(rutokenActions.load());
    dispatch(cadesActions.load());
  }
});

export default connect(
  mapState,
  mapDispatch,
)(CertificateSelect);
