import { createAction } from 'redux-actions';

export const subject = '[CisRegistration]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const submit = createAction(`${subject} submit`);
export const setError = createAction(`${subject} setErrorInfo`);
export const openDraft = createAction(`${subject} open draft`);
export const reset = createAction(`${subject} reset`);
export const setDocType = createAction(`${subject} set docType`);

export const convert = createAction(`${subject} convert`);
export const validateFile = createAction(`${subject} validate file`);
export const setCisList = createAction(`${subject} set cis list`);
export const convertCis = createAction(`${subject} convert cis`);
export const load = createAction(`${subject} load`);
export const error = createAction(`${subject} error`);

