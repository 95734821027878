import styled from 'styled-components';

export const TableActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;


export const Link = styled.a`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #55b5e7;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  :active,
  :hover,
  :visited {
    text-decoration: none;
    color: #55b5e7;
  }
`;
