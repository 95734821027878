import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from '@crpt/react-button';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import {
  buttonSelectedTabItemTheme,
  buttonTabItemTheme,
} from '../../theme/ru-tabaco/button';

const theme = {
  true: { Button: buttonSelectedTabItemTheme },
  false: { Button: buttonTabItemTheme },
};

const TabButton = props => {
  const {
    active,
    icon,
    children,
    onClick,
    style,
    disabled,
    marginBottom,
    size = 16,
    ...pass
  } = props;

  return (
    <Button
      theme={theme[active]}
      onClick={onClick}
      style={style}
      disabled={disabled}
      {...pass}
    >
      {icon && <Icon type={icon} size={size} spacingRight={16} {...pass} />}
      {children}
    </Button>
  );
};

TabButton.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
};

TabButton.defaultProps = {
  active: false,
  icon: null,
  children: '',
  onClick: null,
  disabled: false,
  marginBottom: undefined,
  variant: 'contained',
};

export default TabButton;
