import { Value } from '../../../fragments/Table/Cell/value';
import { Translate } from '../../../common_components/Translate/Translate';
import { IconWrapper, Link, NoWrap, OptionWrap } from '../../Grid/Grid.styled';
import { getIconNameByPackType } from '../../TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../../constants/packTypes';
import React from 'react';
import { isNull } from 'lodash';

export const columnsGoods = (disabled) => [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    width: 100,
  },
  {
    id: 'gtin',
    accessor: row => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 'auto',
  },
  {
    id: 'productName',
    accessor: 'productName',
    disableFilters: true,
    Header: Translate('Наименование'),
    width: 'auto',
  },
  {
    id: 'packageType',
    accessor: row => {
      const CODE = row.packageType.toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{Translate(packTrivialTypesKZ[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'cis',
    accessor: row => {
      return disabled ? <Value>{row.cis || '-'}</Value> : (
        <Link
          onClick={e => {
            e.preventDefault();
            window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
          }}
        >
          <NoWrap>{row.cis}</NoWrap>
        </Link>
      );
    },
    Header: Translate('Код идентификации'),
    disableFilters: true,
    width: 'auto',
  },
];

export const columnsCises = (disabled) => [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    width: 100,
  },
  {
    id: 'cis',
    accessor: row => {
      return disabled ? <Value>{row.cis}</Value> :(
        <Link
          onClick={e => {
            e.preventDefault();
            window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
          }}
        >
          <NoWrap>{row.cis}</NoWrap>
        </Link>
      );
    },
    Header: Translate('Код идентификации'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'productCost',
    accessor: row => {
      return <Value>{row.productCost || '—'}</Value>;
    },
    Header: Translate('Цена за единицу'),
    width: 'auto',
  },
  {
    id: 'productTax',
    accessor: 'productTax',
    Header: Translate('НДС'),
    width: 'auto',
  },
  {
    id: 'certificateDocuments',
    accessor: 'certificateDocuments',
    disableFilters: true,
    Header: Translate('Документ соответствия'),
    width: 'auto',
  },
];

export const acceptedColumn = {
  id: 'accepted',
  accessor: row => {
    return <Value>{Translate(isNull(row.accepted)
      ? 'Сведения отсутствуют'
      : row.accepted
        ? 'Принят'
        : 'Не принят'
    )}</Value>;
  },
  Header: Translate('Статус приемки'),
  width: 'auto',
};

export const CERTIFICATE_TYPES = {
  CONFORMITY_CERTIFICATE: 'Сертификат',
  CONFORMITY_DECLARATION: 'Декларация',
}
