import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { toast } from 'react-toastify';
import Steps from '@crpt-ui/core/lib/Steps/Steps';

import {
  cisKey,
  goodsKey,
  steps,
  tabsKZ,
  tabsKG,
  operationTypes,
  contentBodyConfirm,
} from '../ActDocument.constants';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import * as selectors from '../ducks/ActDocument.selectors';
import {
  PageWrapper,
  Content,
  DividerOfContent,
  InfoFields,
  InfoWrap,
  StepTitle,
  StepWrapper,
  ParticipantSelectWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import { TitleAndWarning } from './TitleAndWarning';
import Input from 'src/common_components/Form/MaterialFields/Input/Input';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { required } from 'src/common_components/Form/utils/validations';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import { Toast } from 'src/utils/Toast';
import DatePicker from 'src/common_components/Form/MaterialFields/DatePicker/DatePicker';
import LightTheme from 'src/common_components/_MaterialUi/LightTheme';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import Checkbox from 'src/common_components/Form/Fields/Checkbox/Checkbox';
import { ParticipantSelect } from 'src/common_components/Form/Fields/Fields';
import Select from 'src/common_components/Form/MaterialFields/Select/Select';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { Translate } from '../../../../../common_components/Translate/Translate';
import CheckboxField from '../../../../../common_components/Form/MaterialFields/Checkbox/Checkbox';

class Information extends React.Component {
  static propTypes = {
    inn: PropTypes.string.isRequired,
    history: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
  };

  participantSelect;

  constructor(props) {
    super(props);

    this.state = {
      commandBarLeft: [
        <CommonActionButton onClick={this.toggleDialog}>
          {Translate('Отменить')}
        </CommonActionButton>,
      ],
      commandBarRight: [
        <CommonActionButton
          onClick={this.onSaveDraft}
          //disabled={this.isNextStepDisabled}
        >
          {Translate('Сохранить как черновик')}
        </CommonActionButton>,
        <PrimaryActionButton
          disabled={this.isNextStepDisabled}
          onClick={this.onNextStep}
        >
          {Translate('Следующий шаг')}
        </PrimaryActionButton>,
      ],
      hasPaperDoc: false,
      fixed: false,
      showSelect: false,
      isModalOpen: false,
      isFirstTime: true,
      draftBuyerName: null,
    };
  }

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    const data = { ...formValues };
    if (!formValues.isHasPaperDocument) {
      delete data.paper_document_date;
      delete data.paper_document_number;
    }
    onSaveDraft(data);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  errorsCount = () => {
    const { formErrors } = this.props;
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    delete prepared['excise_tax_document_date'];
    delete prepared['excise_tax_document_number'];
    delete prepared['excise_tax_sum'];

    return Object.keys(prepared).length;
  };

  isNextStepDisabled = () => {
    const { show } = this.props;
    if (!show) return true;

    return this.errorsCount() > 0;
  };

  componentDidMount() {
    const { inn, formMutators, user, formValues, countryCode } = this.props;
    formMutators.updateField('applicant_inn', inn);
    formMutators.updateField(
      'sender_name_full',
      user.company.shortName + ' / ' + user.inn,
    );
    formMutators.updateField('sender_name', user.company.shortName);
    formMutators.updateField('sender_inn', user.inn);
    setTimeout(() => {
      this.setState({ showSelect: true });
    }, 200);
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      nextProps.data.hasPaperDoc !== undefined &&
      nextProps.data.hasPaperDoc !== prevState.hasPaperDoc &&
      prevState.isFirstTime
    ) {
      return {
        hasPaperDoc: nextProps.data.hasPaperDoc,
        isFirstTime: false,
      };
    }
    return null;
  };

  tabsCurrent = () => {
    const { show, countryCode } = this.props;
    const tabs = countryCode === 'KZ' ? tabsKZ : tabsKG;
    if (!show) return tabs;
    const isNextStepDisabled = this.isNextStepDisabled();
    const { onSetStep } = this.props;
    return tabs
      .map(tab =>
        tab.id === steps.info
          ? tab
          : {
              ...tab,
              onClick: () => {
                onSetStep(tab.id);
              },
            },
      )
      .map(tab =>
        tab.id === steps.info || (tab.id === steps.goods && !isNextStepDisabled)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  onCountrySelect = () => {
    const { formMutators, formValues } = this.props;
    setTimeout(() => {
      formMutators.updateField('sender_inn', formValues.sender_inn);
    }, 100);
  };

  handleFixedCheckboxChange = () =>
    this.setState(({ fixed }) => ({ fixed: !fixed }));

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  addDocument = () => {
    const { formMutators } = this.props;
    formMutators.push('documents', {});
  };

  render() {
    const {
      commandBarLeft,
      commandBarRight,
      hasPaperDoc,
      isModalOpen,
      fixed,
      showSelect,
    } = this.state;
    const {
      show,
      countryCode,
      formValues,
      formMutators,
      isDraft,
      inn,
    } = this.props;
    const errorsCount = this.errorsCount();

    if (formValues.buyer) {
      const index = formValues.buyer.lastIndexOf('/');
      const buyer_name = formValues.buyer.slice(0, index - 1);
      if (formValues.buyer_name !== buyer_name)
        formMutators.updateField('buyer_name', buyer_name);
      const buyer_inn = formValues.buyer.slice(index + 2);
      if (formValues.buyer_inn !== buyer_inn)
        formMutators.updateField('buyer_inn', buyer_inn);
    }
    return (
      <PageWrapper hide={!show}>
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.info}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <Content>
            <ConfirmModal
              isModalOpen={isModalOpen}
              onClose={this.toggleDialog}
              onSubmit={this.onCancel}
              content={contentBodyConfirm}
              header={Translate('Предупреждение')}
              cancelButtonText={Translate('Нет')}
              submitButtonText={Translate('Да')}
              notCancelable
            />
            <TitleAndWarning
              showLeft={false}
              left={
                errorsCount > 0
                  ? Math.ceil(Math.round((errorsCount / 7) * 100) / 5) * 5
                  : 0
              }
            />
            <DividerOfContent />

            <MuiThemeProvider
              theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
            >
              <StepTitle>{Translate('Общие данные')}</StepTitle>

              <InfoWrap noMargin>
                <InfoFields noMargin>
                  <Checkbox
                    checked={fixed}
                    onClick={() => {}}
                    // onClick={this.handleFixedCheckboxChange}
                    name="fixed"
                    disabled
                    placeholder={Translate('Исправленный')}
                  />
                </InfoFields>
                <div style={{ minWidth: '400px' }} />
              </InfoWrap>
              <StepTitle noMarginTop>{Translate('Продавец')}</StepTitle>
              <InfoWrap noMargin>
                <InfoFields noMargin fullWidth>
                  <Input
                    forceValidate
                    name="sender_name_full"
                    placeholder={Translate('Наименование Организации/ИИН/БИН')}
                    disabled
                  />
                </InfoFields>
                <div style={{ minWidth: '400px' }} />
              </InfoWrap>
              <StepTitle noMarginTop>{Translate('Покупатель')}</StepTitle>
              <InfoWrap noMargin>
                <InfoFields noMargin fullWidth>
                  <ParticipantSelectWrapper>
                    {showSelect && (
                      <ParticipantSelect
                        forceValidate
                        hiddenParticipant={inn}
                        validate={required}
                        required
                        name="buyer"
                        valueKey="all"
                        placeholder={Translate(
                          'Наименование Организации/ИИН/БИН',
                        )}
                        noValuesText={Translate(
                          'Для поиска введите от 3-ёх символов',
                        )}
                      />
                    )}
                  </ParticipantSelectWrapper>
                </InfoFields>
                <div style={{ minWidth: '400px' }} />
              </InfoWrap>
              <StepTitle noMarginTop>{Translate('Тип операции')}</StepTitle>
              <InfoWrap noMargin>
                <InfoFields noMargin>
                  <Select
                    name="operation_type"
                    validate={required}
                    required
                    forceValidate
                    placeholder={Translate('Тип операции')}
                    options={operationTypes}
                    defaultValue="realisation"
                  />
                  <div />
                  <CheckboxField
                    name="isHasPaperDocument"
                    placeholder={Translate('Бумажный носитель')}
                  />
                  <div />
                  {formValues.isHasPaperDocument && (
                    <React.Fragment>
                      <Input
                        forceValidate
                        required
                        name="paper_document_number"
                        placeholder={Translate('Номер первичного документа')}
                        validate={required}
                      />
                      <DatePicker
                        required
                        forceValidate
                        name="paper_document_date"
                        placeholder={Translate('Дата первичного документа')}
                        maxDate={new Date()}
                        validate={(...vals) => {
                          return validateDate(...vals, 'DD.MM.YYYY');
                        }}
                      />
                    </React.Fragment>
                  )}
                </InfoFields>
                <div style={{ minWidth: '400px' }} />
              </InfoWrap>
            </MuiThemeProvider>

            <DividerOfContent />

            <CommandBar left={commandBarLeft} right={commandBarRight} />
          </Content>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  inn: authSelectors.userInn(state),
  user: authSelectors.userInfo(state),
  data: selectors.data(state),
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState), withRouter)(Information);
