import React from 'react';

export const CrossIcon = ({style, onClick}) => {
  return (
    <svg
      onClick={onClick}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      stroke="#212C42"
    >
      <g id="cross">
        <line x1="1" y1="1" x2="10" y2="10" strokeWidth="2" />
        <line x1="10" y1="1" x2="1" y2="10" strokeWidth="2" />
      </g>
    </svg>
  );
};
