const defaultParseFilterFromUrlStr = str => {
  const [id, param] = str.split('=');
  if (id === 'page') return { page: JSON.parse(decodeURIComponent(param)) };
  return { id, value: JSON.parse(decodeURIComponent(param)) };
};

export const url2filters = (url, fn = defaultParseFilterFromUrlStr) =>
  url.split('&').map(str => fn(str));

export const filters2url = filters =>
  filters
    .map(
      ({ id, value }) => `${id}=${encodeURIComponent(JSON.stringify(value))}`,
    )
    .join('&');

const getFilterParams = filter => {
  const { id, value } = filter;
  if (!id || !value) return {};
  const paramValue = value.hasOwnProperty('value') ? value.value : value;
  return { [id]: paramValue };
};

export const filters2params = (filters, fn = getFilterParams) =>
  filters.reduce((prev, next) => {
    return { ...prev, ...fn(next) };
  }, {});

export const initFromUrl = (searchString = '', names = [], fn) => {
  const urlParams =
    searchString && searchString[0] === '?' && searchString.indexOf('=') > 0
      ? url2filters(searchString.substr(1), fn)
      : [];
  const page = urlParams.find(param => param.hasOwnProperty('page'));
  const orderDirection = urlParams.find(param => param.id === 'orderDirection');
  const orderColumn = urlParams.find(param => param.id === 'orderColumn');
  const sort = urlParams.length ? [orderDirection, orderColumn] : [];
  const filters =
    Array.isArray(names) && names.length
      ? urlParams.filter(param => names.some(name => name === param.id))
      : urlParams.filter(param => !param.hasOwnProperty('page'));
  return { sort, page, filters };
};

export const getSortData2url = sort => {
  if (!sort.length) return [];

  const [{ id, desc }] = sort;

  return [
    {
      id: 'orderColumn',
      value: id
    },
    {
      id: 'orderDirection',
      value: desc ? 'desc' : 'asc',
    }
  ];
};

export const getSortDataFromUrl = sort => {
  return sort && sort.length
    ? [{
      id: sort.find(item => item.id === 'orderColumn').value,
      desc: sort.find(item => item.id === 'orderDirection').value === 'desc',
    }]
    : [];
};

export const prepareSortParam = (sort, paramIdsMap) => {
  const { orderColumn, orderDirection } = getSortValues(sort, paramIdsMap);
  return orderColumn && orderDirection ? { sort: `${orderColumn},${orderDirection}`} : {};
};

const getSortValues = (sort, paramIdsMap) => {
  if (!sort) return [];

  const [{ id, desc }] = sort;

  const orderColumn = paramIdsMap[id];
  const orderDirection = desc ? 'desc' : 'asc';

  return { orderColumn, orderDirection };
};
