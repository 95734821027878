import React from 'react';
import moment from 'moment';

import {
  documentStatusesColorsMap,
  documentStatusesIconsMap,
  documentStatusesNames,
} from 'src/constants/documentStatuses';

import {makeColoredIconAndText} from 'src/fragments/Data/makeColoredIconAndText';
import {GroupInfoTitle} from 'src/fragments/Data/GroupInfo/Title'
import {GroupInfoItem} from 'src/fragments/Data/GroupInfo/Item';

import {useStyles} from './Declaration.styles';

export const DeclarationView = ({selectedDocument, id}) => {
  const styles = useStyles();
  const declarationInfo = selectedDocument.body.content;
  
  return (
    <main className={styles.main}>
      <div className={styles.contentWrapper}>
       
        <div className={styles.groupInfoWrapper}>
          <div className={styles.groupTitleHeaderWrapper}>
            <GroupInfoTitle
              isHeader
              title={`Декларация на товары ${id}`}
              renderRightSide={makeColoredIconAndText({
                icons: documentStatusesIconsMap,
                values: documentStatusesNames,
                colors: documentStatusesColorsMap,
                value: selectedDocument.status,
              })}
            />
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код принятого решения" value={declarationInfo.ftsDecisionCode}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Дата и время принятого решения" value={moment(declarationInfo.ftsDecisionTimestamp).format("D.MM.YYYY HH:mm:ss")}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код таможенного органа" value={declarationInfo.customsCode}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Дата регистрации на товары" value={moment(declarationInfo.goodsDeclarationDate).format("D.MM.YYYY")}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Регистрационный номер декларации" value={declarationInfo.goodsDeclarationRegistrationNumber}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код таможенной процедуры" value={declarationInfo.customsProcedureCode}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код особенности декларирования" value={declarationInfo.declaringCode}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Количество товаров в декларации на товары" value={declarationInfo.markedProducts.length}/>
          </div>


        </div>

        <div className={styles.groupInfoWrapper}>
          <div className={styles.groupTitleWrapper}>
            <GroupInfoTitle title="Сведения об отправителе " />
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Наименование организации-отправителя" value={declarationInfo.senderName}/>
          </div>
          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код налогоплательщика-отправителя" value={declarationInfo.senderForeignCode}/>
          </div>
          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Код страны отправителя" value={declarationInfo.senderCountryCode}/>
          </div>
          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Наименование страны-отправителя" value={declarationInfo.senderCountryName}/>
          </div>
          
        </div>

        <div className={styles.groupInfoWrapper}>
          <div className={styles.groupTitleWrapper}>
            <GroupInfoTitle title="Сведения о получателе " />
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Наименование организации-получателя" value={declarationInfo.receiverName}/>
          </div>
          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="ИНН получателя" value={declarationInfo.receiverInn}/>
          </div>

        </div>

        <div className={styles.groupInfoWrapper}>
          <div className={styles.groupTitleWrapper}>
            <GroupInfoTitle title="Сведения о декларанте товаров " />
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="Наименование организации / ИП" value={declarationInfo.declarantName}/>
          </div>
          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="ИНН декларанта" value={declarationInfo.declarantInn}/>
          </div>

          <div className={styles.groupItemWrapper}>
            <GroupInfoItem title="КПП декларанта" value={declarationInfo.declarantKppcurrencyCodeInContract}/>
          </div>

        </div>


      </div>
    </main>
  )
};