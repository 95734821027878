import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from './ScanDialog.actions';
import * as selectors from './ScanDialog.selectors';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import Api from 'src/common_components/Api/Api';
import { get } from 'lodash';
import {
  COLORS,
  IMPORT_TRANSIT_CORRECT_STATUSES,
  INTRODUCE_GOODS_CORRECT_STATUSES, KZ_EXPORT_NOTICE_CORRECT_STATUSES,
  KZ_UPD_CORRECT_STATUSES,
  MTTPS_90_CORRECT_STATUSES,
} from '../ScanDialog.constants';
import * as _ from 'lodash';
import { deletingCryptoEnd } from '../../../motp_components/_Requests/RequestList/ActDocument/Reconciliation/Reconciliation.utils';
const SERIAL_NUMBER_LENGTH = 7;

export const fullMatching = value => value;
export const serialNumberMatching = value => value.slice(-SERIAL_NUMBER_LENGTH);
export const partialGTINMatching = value => value.slice(4, 10);

function getStatusesByDocType(docType) {
  switch (docType) {
    case 'IMPORT':
    case 'IMPORT_TRANSIT':
      return IMPORT_TRANSIT_CORRECT_STATUSES;
    case 'KZ_INTRODUCE_GOODS':
      return INTRODUCE_GOODS_CORRECT_STATUSES;
    case 'MTTPS_90':
      return MTTPS_90_CORRECT_STATUSES;
    case 'KZ_UPD':
      return KZ_UPD_CORRECT_STATUSES;
    case 'KZ_EXPORT_NOTICE':
      return KZ_EXPORT_NOTICE_CORRECT_STATUSES;
    default:
      return IMPORT_TRANSIT_CORRECT_STATUSES;
  }
}

function getStatusesErrorByDocType(docType) {
  switch (docType) {
    case 'IMPORT':
    case 'IMPORT_TRANSIT':
      return 'invalidStatusImport';
    case 'KZ_INTRODUCE_GOODS':
      return 'invalidStatusIntroduceGoods';
    case 'MTTPS_90':
      return 'invalidStatusMttps90';
    case 'KZ_UPD':
      return 'invalidStatusAct';
    default:
      return 'invalidStatus';
  }
}

function validateCis(cis, userInn, c, docType) {
  if (typeof cis !== 'object') {
    return { cis, isInvalid: true };
  }
  if (
    docType !== 'KZ_UPD' &&
    docType !== 'KZ_INTRODUCE_GOODS' &&
    cis.ownerInn !== userInn
  ) {
    return { ...cis, isInvalid: true, error: 'notOwner' };
  }

  const statuses = getStatusesByDocType(docType);

  if (docType === 'MTTPS_90') {
    const isCorrect = statuses.includes(cis.status);
    if (!isCorrect) {
      const errorName = getStatusesErrorByDocType(docType);
      return { ...cis, isInvalid: true, error: errorName };
    }
  } else {
    if (!statuses.includes(cis.status)) {
      const errorName = getStatusesErrorByDocType(docType);
      return { ...cis, isInvalid: true, error: errorName };
    }
  }

  return cis;
}

function prepareScannedCis(payload) {
  return (
    payload
      .trim()
      .replace(/<div>/g, ' ')
      .replace(/<\/div>/g, ' ')
      // .replace(/\n/g, ' ')
      .replace(/<br>/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .split(' ')
      .filter(item => item !== '')
      .map(item => item.replace('<span>', '').replace('</span>', ''))
  );
}

function* loadCisDataSaga({ payload, needPreloading = false }) {
  const parsedCisList = deletingCryptoEnd(payload);
  let finalCisList = [];

  const requestCisData = {
    url: '/api/v3/facade/marked_products/search-by-cis-list',
    method: 'POST',
    data: parsedCisList,
  };

  const [cisData, errorCisData] = yield call(Api.request, requestCisData, {
    preloading: needPreloading,
  });

  if (cisData) {
    finalCisList = [...finalCisList, ...cisData.data.results].map(el=> {
      let currentCis = el.cis.split('');
      if (el.cis[0] === '(' && el.cis[3] === ')' && el.cis[18] === '(' && el.cis[21] === ')') {
        currentCis.splice(0, 1)
        currentCis.splice(2, 1)
        currentCis.splice(16, 1)
        currentCis.splice(18, 1)
      }
      return ({
        ...el,
        cis: currentCis.join('')
      });
    });
  }

  if (errorCisData) {
    const error = get(errorCisData, 'response.data.description');
    yield put(actions.setError(error));
  }

  return [finalCisList, parsedCisList];
}

function* scanCodesSaga({ payload }) {
  const userInn = yield select(authSelectors.userInn);
  const { codes, docType } = payload;
  const [finalCisList, parsedCisList] = yield call(loadCisDataSaga, {
    payload: codes,
  });

  if (finalCisList.length) {
    const previousScannedCodes = yield select(selectors.cisList);
    const newCodes = parsedCisList.flat().map(cis => {
      const searchCis = finalCisList.find(item =>
        cis.includes(item.cis), // .slice(-7)
      );
      return validateCis(searchCis, userInn, cis, docType);
    });
    const cises = _.uniqBy([...previousScannedCodes, ...newCodes], 'cis');
    yield put(actions.setScannedCodes(cises));
  }
}

function* compareCisSaga({ payload }) {
  const parsedCisList = prepareScannedCis(payload);
  const existCis = yield call(loadCisDataSaga, {
    payload: parsedCisList,
    needPreloading: true,
  });

  const addHighlightColor = color => code => ({ ...code, color });
  const bodyDraft = yield select(selectors.draftBody);
  const codesInWaybill = bodyDraft.cis;
  const enteredCis = parsedCisList.flat();

  const handledList = searchMatchCodes({ enteredCis, existCis }).map(cis =>
    typeof cis !== 'object' ? { cis } : cis,
  );

  const matchingCodes = handledList
    .filter(code => codesInWaybill.some(item => item.cis === code.cis))
    .map(addHighlightColor(COLORS.match));

  const missingCodesInScannedList = codesInWaybill
    .filter(code => !handledList.some(item => item.cis === code.cis))
    .map(addHighlightColor(COLORS.missingInScan));

  const missingCodesInWaybill = handledList
    .filter(code => !codesInWaybill.some(item => item.cis === code.cis))
    .map(addHighlightColor(COLORS.missingInWaybill));

  yield put(
    actions.setCodesForComparing({
      matchingCodes,
      missingCodesInScannedList,
      missingCodesInWaybill,
      all: [
        ...matchingCodes,
        ...missingCodesInScannedList,
        ...missingCodesInWaybill,
      ],
    }),
  );
}

// function that search codes for match
function searchMatchCodes({ enteredCis, existCis }) {
  let codes = [];

  // filter for full matching
  enteredCis.forEach(cis => {
    const searchedCode = existCis.find(item => cis === fullMatching(item.cis));

    searchedCode && codes.push(searchedCode);
  });

  // filter for serial number match
  enteredCis.forEach(cis => {
    const searchedCode = existCis.find(
      item =>
        cis.includes(serialNumberMatching(item.cis)) &&
        !codes.some(code => cis.includes(serialNumberMatching(code.cis))),
    );

    searchedCode && codes.push(searchedCode);
  });

  // no matched codes
  const unknownCodes = enteredCis.filter(cis =>
    codes.every(
      code =>
        code.cis !== cis &&
        !(
          cis.includes(serialNumberMatching(code.cis)) &&
          cis.includes(partialGTINMatching(code.cis))
        ),
    ),
  );

  return [...codes, ...unknownCodes];
}

export default function* watch() {
  yield takeEvery(actions.compareCodes, compareCisSaga);
  yield takeEvery(actions.scanCodes, scanCodesSaga);
}
