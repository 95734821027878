import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuItemWrapper = styled(NavLink)`
  text-decoration: inherit;
  color:inherit;

  &:hover {
    text-decoration: inherit;
    color: inherit;
    svg {
      fill: white
    }
  }


`;
