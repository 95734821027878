import { get } from 'lodash';
import { Translate } from '../../Translate/Translate';

export const getTitle = (menuItems, location) => {
  const { pathname } = location;

  const currentItem =
    menuItems
      .filter(({ route, testRoute }) => pathname === (testRoute || route))
      .shift() ||
    menuItems
      .filter(({ route, testRoute }) => pathname.includes(testRoute || route))
      .shift();

  const selectedTitle = get(currentItem, 'title', '');
  return Translate(selectedTitle.toUpperCase());
};
