import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import get from 'lodash/get';

import moment from 'moment';

import 'moment/locale/ru';
import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { TwoLineLabel } from '@crpt/react-label';
import {
  addSpacesBetweenThousands,
  getRubelsAsFloatFromKopecks,
} from '../../../utils';
import { nano2milli } from '../DocumentDetails.utils';
import HeadedLabel from './HeadedLabel';
import { participantNames } from '../../../constants';

import { FlexWrapper, Separator, TextWithIconWrapper } from './styled';
import HeadingWrap from './HeadingWrap';
import { getParticipantNamesByRoles } from '../DocumentDetails.utils';
import { Translate } from '../../../common_components/Translate/Translate';

// TODO: Refactoring is needed

moment.locale('ru');

const propTypes = {};

const ImageWrap = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
  margin-left: 32px;
  border-radius: ${props => (props.round ? '50%' : '')};
`;

const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 16px;
`;

const InfoLine = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AfterLarge = styled.div`
  width: 100%;
  height: 15px;
`;

class WriteOffBasicInfo extends Component {
  render() {
    const { id, selectedDocument, countryCode } = this.props;
    const isKZ = countryCode === 'KZ';

    const { documentDate, receivedAt } = selectedDocument;

    const docDate = moment(nano2milli(documentDate));
    const docDateString = (docDate && docDate.format('DD MMMM YYYY')) || '—';

    const receivedDate = moment(nano2milli(receivedAt));
    const receivedDateString =
      (receivedDate && receivedDate.format('DD MMMM YYYY')) || '—';

    const taxRaw = get(selectedDocument, 'tax', false);
    const tax = taxRaw ? getRubelsAsFloatFromKopecks(taxRaw) : false;
    const taxString = (tax && addSpacesBetweenThousands(tax) + ' ₽') || '—';

    const excise_raw = get(selectedDocument, 'excise', false);
    const excise = excise_raw ? getRubelsAsFloatFromKopecks(excise_raw) : false;
    const exciseString =
      (excise && addSpacesBetweenThousands(excise) + ' ₽') || 'Без акциза';

    const sum =
      selectedDocument &&
      getRubelsAsFloatFromKopecks(selectedDocument.totalSum);
    const sumString = (sum && addSpacesBetweenThousands(sum) + ' ₽') || '—';

    const senderInfo = get(selectedDocument, 'senderInfo', {});
    const receiverInfo = get(selectedDocument, 'receiverInfo', {});

    const senderShortName =
      get(senderInfo, 'shortName', false) ||
      get(selectedDocument, 'senderName', false);
    const senderActualAddress =
      get(senderInfo, 'actualAddress', false) ||
      get(selectedDocument, 'senderAddress', false);

    const receiverShortName =
      get(receiverInfo, 'shortName', false) ||
      get(selectedDocument, 'receiverName', false);
    const receiverActualAddress =
      get(receiverInfo, 'actualAddress', false) ||
      get(selectedDocument, 'receiverAddress', false);
    const receiverRoles = get(selectedDocument, 'receiverInfo.roles', []);
    const preperedReceiverRoles = receiverRoles
      .filter(item => item)
      .map(item => participantNames[item])
      .filter(item => item);

    const senderINN =
      get(senderInfo, 'inn', false) ||
      get(selectedDocument, 'senderInn', false);
    const senderKPP =
      get(senderInfo, 'kpp', false) ||
      get(selectedDocument, 'senderKpp', false);

    const receiverINN =
      get(receiverInfo, 'inn', false) ||
      get(selectedDocument, 'receiverInn', false);
    const receiverKPP =
      get(receiverInfo, 'kpp', false) ||
      get(selectedDocument, 'receiverKpp', false);
    const signers = get(selectedDocument, 'signers', []);

    const shipperName =
      selectedDocument &&
      selectedDocument.shipper &&
      `${selectedDocument.shipper.ipCertificate.fio.lastName} ${selectedDocument.shipper.ipCertificate.fio.firstName} ${selectedDocument.shipper.ipCertificate.fio.middleName}`;

    const shipperAddress = get(selectedDocument, 'shipperAddress', false);

    const shipperINN = get(selectedDocument, 'shipper.inn', false);
    const shipperKPP = get(selectedDocument, 'shipper.kpp', false);

    const recipientName =
      selectedDocument &&
      selectedDocument.recipient &&
      selectedDocument.recipient.legalEntityData.title;

    const recipientAddress = get(selectedDocument, 'recipientAddress', false);

    const recipientINN = get(
      selectedDocument,
      'recipient.legalEntityData.inn',
      false,
    );
    const recipientKPP = get(
      selectedDocument,
      'recipient.legalEntityData.kpp',
      false,
    );

    const needShipper =
      shipperName || shipperAddress || shipperINN || shipperKPP;
    const needRecipient =
      recipientName || recipientAddress || recipientINN || recipientKPP;

    const senderRoles = get(selectedDocument, 'senderInfo.roles', []);

    return (
      <React.Fragment>
        <HeadingWrap
          type={selectedDocument.type}
        />
        <FlexWrapper>
          <HeadedLabel
            header={Translate('Дата документа')}
            value={docDateString}
          />
          <HeadedLabel
            header={Translate('Поступил в систему')}
            value={receivedDateString}
          />
        </FlexWrapper>

        <Separator />

        <Card>
          <Container fillWidth gutter={32}>
            <Column col={10}>
              <DocInfoWrap>
                <TextWithIconWrapper>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Продавец')}
                    </DocumentInfoHeadingLabel>
                    {senderShortName && (
                      <InfoLine>
                        <TwoLineLabel
                          heading={getParticipantNamesByRoles(senderRoles)}
                        >
                          {senderShortName}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                    {senderActualAddress && (
                      <InfoLine>
                        <TwoLineLabel heading={Translate('Адрес')}>
                          {senderActualAddress}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </TextWithIconWrapper>
              </DocInfoWrap>

              {(senderINN || senderKPP) && (
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Налоговые данные')}
                    </DocumentInfoHeadingLabel>
                    {senderINN && (
                      <InfoLine>
                        <TwoLineLabel
                          heading={
                            isKZ ? Translate('ИИН/БИН') : Translate('ИНН')
                          }
                        >
                          {senderINN}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </DocInfoWrap>
              )}

              {!!signers.length && (
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      {Translate('Подписан')}
                    </DocumentInfoHeadingLabel>
                    <InfoLine>
                      <TwoLineLabel heading={Translate('Подписант')}>
                        {signers[0].name || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  </div>
                </DocInfoWrap>
              )}
            </Column>
            <Column>
              <DocInfoWrap>{/*<ImageWrap round />*/}</DocInfoWrap>
            </Column>
          </Container>

          <AfterLarge />

          {(needShipper || needRecipient) && (
            <Container fillWidth gutter={32}>
              <Column>
                {needShipper && (
                  <div>
                    <DocInfoWrap>
                      <div>
                        <DocumentInfoHeadingLabel>
                          {Translate('Грузоотправитель')}
                        </DocumentInfoHeadingLabel>
                        {shipperName && (
                          <InfoLine>
                            <TwoLineLabel heading={Translate('Производитель')}>
                              {shipperName}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                        {shipperAddress && (
                          <InfoLine>
                            <TwoLineLabel heading={Translate('Адрес')}>
                              {shipperAddress}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                      </div>
                    </DocInfoWrap>

                    {(shipperINN || shipperKPP) && (
                      <DocInfoWrap>
                        <div>
                          <DocumentInfoHeadingLabel>
                            {Translate('Налоговые данные')}
                          </DocumentInfoHeadingLabel>
                          {shipperINN && (
                            <InfoLine>
                              <TwoLineLabel heading={Translate('ИНН')}>
                                {shipperINN || '—'}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                          {shipperKPP && (
                            <InfoLine>
                              <TwoLineLabel heading={Translate('КПП')}>
                                {shipperKPP || '—'}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                        </div>
                      </DocInfoWrap>
                    )}
                  </div>
                )}
              </Column>

              <Column>
                {needRecipient && (
                  <div>
                    <DocInfoWrap>
                      <div>
                        <DocumentInfoHeadingLabel>
                          {Translate('Грузополучатель')}
                        </DocumentInfoHeadingLabel>
                        {recipientName && (
                          <InfoLine>
                            <TwoLineLabel heading={Translate('Опт/розница')}>
                              {recipientName || '—'}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                        {recipientAddress && (
                          <InfoLine>
                            <TwoLineLabel heading={Translate('Адрес')}>
                              {recipientAddress || '—'}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                      </div>
                    </DocInfoWrap>

                    {(recipientINN || recipientKPP) && (
                      <DocInfoWrap>
                        <div>
                          <DocumentInfoHeadingLabel>
                            {Translate('Налоговые данные')}
                          </DocumentInfoHeadingLabel>
                          {recipientINN && (
                            <InfoLine>
                              <TwoLineLabel heading={Translate('ИНН')}>
                                {recipientINN}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                          {recipientKPP && (
                            <InfoLine>
                              <TwoLineLabel heading={Translate('КПП')}>
                                {recipientKPP}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                        </div>
                      </DocInfoWrap>
                    )}
                  </div>
                )}
              </Column>
            </Container>
          )}

          <AfterLarge />
        </Card>
      </React.Fragment>
    );
  }
}

WriteOffBasicInfo.propTypes = propTypes;
export default withRouter(WriteOffBasicInfo);
