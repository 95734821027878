import styled from 'styled-components';


const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(196, 196, 196, 0.25);
  `;

export default Separator;
