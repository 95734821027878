import moment from 'moment';
import { CadesUtil } from '../../utils/CadesUtil';

export class CertAdapter {
  static getSerializedCert(c, i) {
    switch (c.certType) {
      case 'JACARTA':
      case 'JACARTA2':
      case 'RUTOKEN': {
        const from = moment(c.ValidFromDate);
        const to = moment(c.ValidToDate);

        return Object.assign({}, c, {
          id: +i,
          serial: c.id,
          name: `${c.Subject.surname} ${c.Subject.givenName}`,
          date: `${from.format('YYYY.MM.DD')} - ${to.format('YYYY.MM.DD')}`,
          source: c.Subject.organizationName,
          title: c.Subject.organizationName,
          disabled: !c.IsValid,
        });
      }

      case 'CADES':
      default: {
        const from = moment(c.info[1]);
        const to = moment(c.info[2]);

        return Object.assign(
          {
            id: +i,
            serial: c.info[3],
            name: CadesUtil.getNameFromSubject(c.info[0]),
            date: `${from.format('YYYY.MM.DD')} - ${to.format('YYYY.MM.DD')}`,
            source: CadesUtil.getCompanyNameFromSubject(c.info[0]),
            title: c.info[0],
            disabled: moment() > to,
          },
          c
        );
      }

    }
  }
}
