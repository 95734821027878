import {
  PARTICIPANT_TYPES,
  PREPARED_PARTICIPANT_TYPES,
  APP_LINKS,
} from 'src/constants';

export const permissionConfig = {
  fiscal: {
    canUpload: false,
    canCreate: false,
  },
  // Ввод товара в оборот - импорт. НЕ ЕАЭС
  import: {
    canCreate: false,
    canUpload: false,
  },
  '/documents/outgoing/upd820SfDop/create': {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.MEDICAL],
    ],
  },
  [APP_LINKS.Eaes_import]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.NotificationOfCancellation]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.MEDICAL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.CATERING],
    ],
    canUpload: false,
  },
  [APP_LINKS.NotificationOfEntry]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.MEDICAL],
    ],
    canUpload: false,
  },
  [APP_LINKS.ImportThirdCountry]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: [PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER]],
  },
  [APP_LINKS.ImportThirdCountryKG]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.Eaes_act]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.MEDICAL],
    ],
    canUpload: false,
  },
  [APP_LINKS.ExportNotificationToTheEaes]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.MEDICAL],
    ],
    canUpload: false,
  },
  [APP_LINKS.AcceptanceNotificationOfGoodsByTheEAES]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IS_MP_OPERATOR],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.ROLE_ORG_IS_MP_OPERATOR],
    ],
    canUpload: false,
  },
  [APP_LINKS.CisRegistry]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.aik]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.aik_disaggregation]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.aggregation]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.inventory]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.tradeInventory]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.IMPORTER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.RETAIL],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.WHOLESALER],
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.PRODUCER],
    ],
    canUpload: false,
  },
  [APP_LINKS.corInfoCm]: {
    canCreate: [
      PREPARED_PARTICIPANT_TYPES[PARTICIPANT_TYPES.TRADE_PARTICIPANT],
    ],
    canUpload: false,
  },
};
