import {actionTypes} from '../../constants';


export function isEdited(state = false, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.PROFILE_EDITED:
      return true;
    case actionTypes.PROFILE.PROFILE_EDIT_UNMOUNTED:
    case actionTypes.PROFILE.PROFILE_SAVED:
      return false;
    default:
      return state;
  }
}
