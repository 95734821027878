
import { createAction } from 'redux-actions';

export const subject = '[E-IMZO]';

export const load = createAction(`${subject} load`);
export const loaded = createAction(`${subject} loaded`);
export const prepared = createAction(`${subject} prepared`);

export const sign = createAction(
  `${subject} sign`,
  (payload) => payload,
  (_, meta) => meta,
);

export const signByteArray = createAction(
  `${subject} sign byteArray`,
  (payload) => payload,
  (_, meta) => meta,
);

export const signed = createAction(`${subject} signed`);
export const signedByteArray = createAction(`${subject} signed byteArray`);
export const error = createAction(`${subject} sign error`);
