import React from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';


const Route = (props) => {
  const { component: Component, render, ...rest } = props;

  return (
    <ReactRoute
      {...rest}
      render={(props) => (
        <ErrorBoundary>
          {Component ? <Component {...props} /> : render(props)}
        </ErrorBoundary>
      )}
    />
  );
};

export default Route;
