export const defaultTheme = {
  firstRow: {
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  lastRow: {
    marginTop: '32px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  tile: {
    width: '410px',
    height: '400px',
    marginTop: '12px',
    marginBottom: '12px',
    marginLeft: '0px',
    marginRight: '24px',
    backgroundColor: '#FFFFFF',
    shadowX: '1px',
    shadowY: '2px',
    shadowBlur: '5px',
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    icon: {
      height: '82px',
      cursor: 'pointer',
      marginTop: '32px',
      marginBottom: '0px',
      marginLeft: '0px',
      marginRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '28px',
      paddingRight: '28px',
      hover: {
        backgroundColor: '#0287B0',
        color: '#ffffff'
      },
    },
    header: {
      paddingTop: '20px',
      paddingBottom: '0px',
      paddingLeft: '32px',
      paddingRight: '42px',
      fontSize: '26px',
      lineHeight: '30px',

    },
    description: {
      paddingTop: '24px',
      paddingBottom: '0px',
      paddingLeft: '32px',
      paddingRight: '24px',
      fontSize: '16px',
    },
  },
};
