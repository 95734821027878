import React, {Component} from "react";
import PropTypes from 'prop-types';

import { SHOW_TIMEOUT, HIDE_TIMEOUT } from './StyledPopup.constants';
import { Container, MenuItem, Panel } from './StyledPopup.styled';


class Popup extends Component {
  static propTypes = {
    positionY: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    menu: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    positionY: 'top',
    menu: [],
    disabled: false,
  };

  state = {
    isVisible: false,
  };

  visibleTimeout;

  onMouseEnter = () => {
    const { disabled } = this.props;
    if (disabled) return;

    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);
    this.visibleTimeout = setTimeout(() => {
      this.setState({ isVisible: true });
    }, SHOW_TIMEOUT);
  };

  onMouseOut = () => {
    const { disabled } = this.props;
    if (disabled) return;

    if (this.visibleTimeout) clearTimeout(this.visibleTimeout);
    this.visibleTimeout = setTimeout(() => {
      this.setState({ isVisible: false });
    }, HIDE_TIMEOUT);
  };

  render = () => {
    const { children, menu, positionY } = this.props;
    const { isVisible } = this.state;

    return (
      <Container
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseOut}
      >
        {children}
        {isVisible && (
          <Panel positionY={positionY}>
            {menu.map(({ title, ...othersProps }, index) => (
              <MenuItem key={`menu-item-${index}`} {...othersProps}>
                {title}
              </MenuItem>
            ))}
          </Panel>)
        }
      </Container>
    );
  }
}

export default Popup;
