import { createAction } from 'redux-actions';

export const subject = '[CisList]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const updatePage = createAction(`${subject} update page`);
export const getRequest = createAction(`${subject} get request`);
export const pagination = createAction(`${subject} pagination`);
export const filters = createAction(`${subject} filters`);
export const sort = createAction(`${subject} sort`);
export const setSorting = createAction(`${subject} set sorting`);
