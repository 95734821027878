import styled from 'styled-components';

export const Text = styled.p`
  margin: 0;
  cursor: ${({ cursorPointer }) => cursorPointer ? 'pointer' : 'initial'};
  font-size: 16px;
  line-height: 1;
  white-space: ${({ setMaxWidth }) => (setMaxWidth ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  color: ${({ textColor }) => textColor};
`;

export const LinkMail = styled.a`
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
  color: #1a99f4;
  display: flex;
`;

export const WrapIcon = styled.div`
  margin: 0 0 0 16px;
  display: flex;
`;

export const TooltipBody = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  max-width: ${({ setMaxWidth }) =>
    setMaxWidth ? `${setMaxWidth}px` : 'none'};
`;
