
import React, { useCallback } from 'react';
import SelectCheckboxView from '../SelectionCheckbox.view';
import ToggleCheckboxView from './ToggleCheckboxView';

export const checkboxState = {
  NORMAL: 0,
  SELECTED: 1,
  EDITED: 2,
  HIDDEN: 3,
};

const ToggleCheckbox = getCheckboxState => props => {
  const { id, checked, row, onClick } = props;
  // console.log('ToggleCheckbox', id)
  if (getCheckboxState(id).status === checkboxState.HIDDEN) return null;

  const state = getCheckboxState(id, checked);
  const clicked = useCallback(
    e => {
      e.stopPropagation();
      onClick(id, e.shiftKey, row);
    },
    [id, row],
  );

  if (row && row.hiddenCheckBox)
    return (
      <ToggleCheckboxView
        onClick={clicked}
        state={{ ...state, disabled: true }}
      />
    );
  return <ToggleCheckboxView onClick={clicked} state={state} />;
};

export default ToggleCheckbox;
