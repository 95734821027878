export default {
  title: 'Табличное представление при выборе типа агрегации «Сводный»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Доля рынка (% в штуках)',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 472,
          subheaders: [
            {
              Header: 'Производство',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 236,
              subheaders: []
            },
            {
              Header: 'Импорт',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 236,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Доля рынка (% в тенге)',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 472,
          subheaders: [
            {
              Header: 'Производство',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 236,
              subheaders: []
            },
            {
              Header: 'Импорт',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 236,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
        }
      ]
    }
  ]
}
