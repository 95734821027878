import { css } from 'styled-components';

const TableRoot = {
  style: css`
    position: relative;
    display: flex;
    overflow: visible;
    flex-direction: column;

    background-color: rgb(255, 255, 255);
    width: 100%;

    *:not(svg) {
      box-sizing: border-box;
    }

    &.-striped {
      .rt-tr {
        &.-odd {
          background-color: rgba(196, 196, 196, 0.08);
        }
      }
    }

    &.-highlight {
      .rt-tbody {
        .rt-tr {
          &.-odd {
            &:not(.-padRow){
              &:hover {
                .rt-td {
                  border-right: 1px solid rgba(196, 196, 196, 0);
                }
                border-radius: 3px;
                box-shadow:
                  0 -5px 0 0 rgb(250, 250, 250),
                  0 5px 0 0 rgb(250, 250, 250),
                  0 14px 10px rgb(199, 199, 199);
                  z-index: 1200;
              }
            }
          }
          &.-even {
            &:not(.-padRow){
              &:hover{
                .rt-td {
                  border-right: 1px solid rgba(196, 196, 196, 0);
                }
                border-radius: 3px;
                box-shadow:
                  0 -5px 0 0 rgb(255, 255, 255),
                  0 5px 0 0 rgb(255, 255, 255),
                  0 14px 10px rgb(199, 199, 199);
                  z-index: 1200;
              }
            }
          }
        }
      }
    }
  `,
};

export default TableRoot;
