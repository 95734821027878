import { createActions } from 'redux-actions';

export const {
  loaded,
  unMount,
  getDocument,
  reformDocument,
} = createActions(
  {
    LOADED: payload => payload,
    UN_MOUNT: () => {},
    GET_DOCUMENT: payload => payload,
    REFORM_DOCUMENT: payload => payload,
  },
  {
    prefix: 'GRAY_AREA_DOCUMENT',
  },
);

