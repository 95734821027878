import React from 'react';

import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkCRPT } from '@crpt-ui/core'

const Link = ({ ...rest }) => (
  <LinkCRPT component={LinkRouter} {...rest} />
);

export { Link };
