import {actionTypes} from '../../constants';

const initialState = {
  name: null,
  phone: '',
  email: '',
  address: null
};

export function contact(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ORDER_FORM.CONTACT_FIELD_CHANGED:
      return { ...state, [action.key]: action.value };
    case actionTypes.ORDER_FORM.CLEAR:
      return initialState;
    default:
      return state;
  }
}
