import React from 'react';
import { authInstance } from './services/authInstance';
import { Toast } from './utils/Toast';
import { errorHandlers } from './utils/requestErrorHandlers';

authInstance.interceptors.response.use(null, function(err) {
  if (err.code === 'ECONNABORTED') return errorHandlers[408](err);

  if (err.__CANCEL__) {
    if (!(err.message && err.message === 'PREVENT_TOAST')) Toast.showError({ content: 'Пользователь отменил запрос.' });
  }

  if (!err.response && err.config && err.config.url && err.message) {
    Toast.showError({
      content: `${err.config.url}: ${err.message}`,
    });
  }

  if (!err.response || !err.response.status) return Promise.reject(err);

  errorHandlers[err.response.status] && errorHandlers[err.response.status](err);

  return Promise.reject(err);
});
