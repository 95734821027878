import {isNil, isNumber, isObject} from 'lodash';

export const required = value => {
  if (isObject(value)) {
    return value.from && value.to ? undefined : 'Error';
  }
  return value ? undefined : 'Error';
};

export const validateRequiredAndLength = (length) => value => {
  return value && value.length === length ? undefined : 'Error';
};

export const pipe = (...args) => (v) => (
    args.map((arg) => arg(v))
        .filter((vv) => !!vv)
        .shift()
)

export const validateRequiredAndMaxMinLength = (length, comparison = 'max') => value => {
  if (isNil(value) || value === "") {
    return 'Error'
  }
  return comparison === 'max'
      ? value.length < length
          ? undefined
          : 'Error'
      : value.length > length
          ? undefined
          : 'Error' ;
};

export const validateRequiredAndMaxMin = (max, min) => value => {
  if (isNil(value) || value === "") {
    return 'Error'
  }
  return value < max && value > min
    ? undefined
    : 'Error';
};

export const requiredNumber = (value) => {
  return value
    ? (/^[0-9]+$/i).test(value) ? undefined : 'Error'
    : 'Error';
}

export const notRequiredNumber = (value) => {
  return !value || !value[0]
    ? undefined
    : (/^[0-9]+$/i).test(value) ? undefined : 'Error';
}


export const requiredWithOutputErrorMessage = value => {
  if (isObject(value)) {
    return value.from && value.to ? undefined : 'Обязательное поле';
  }
  return value ? undefined : 'Обязательное поле';
};

export const requireTime = value => {
  if (!value) return 'Error';
  if (value && value.length < 5) return 'Error';
  if (value) {
    value = value.match(/\d/gi);
    value = value || [];
    if (value[0] && value[0] > 2) return 'Error';
    if (value[1] && value[0] == 2 && value[1] > 3) return 'Error';
    if (value[2] && value[2] > 5) return 'Error';
  }
  return undefined;
};

export const acyncCreatableRequired = val => {
  return Boolean(val) ? false : true;
};

export const isExciseTax = value => {
  if (!value) {
    return undefined;
  } else {
    const numbers = value.split('.');
    const isEmptyAfterPoint = numbers[1].includes('_');
    return !isEmptyAfterPoint
      ? undefined
      : 'Error';
  }
};

export const isExciseTaxRequired = value => {
  if (!value) {
    return 'Error';
  } else {
    const numbers = value.split('.');
    const isEmptyAfterPoint = numbers[1].includes('_');
    return !isEmptyAfterPoint
      ? undefined
      : 'Error';
  }
};

export const isExcisePrice = value => {
  if (!value) {
    return undefined;
  } else {
    const float = parseFloat(value);
    const numbers = value.split('.');
    // const isOnlyZeros = numbers[0].length > 1 && /^0*$/.test(numbers[0]);
    const isEmptyAfterPoint = numbers[1].includes('_');
    return float > 0 && !isEmptyAfterPoint // && !isOnlyZeros
      ? undefined
      : 'Error';
  }
};

export const isExcisePriceWithEmptyValue = value => {
  if (!value) {
    return undefined;
  } else {
    const float = parseFloat(value);
    const numbers = value.split('.');
    // const isOnlyZeros = numbers[0].length > 1 && /^0*$/.test(numbers[0]);
    const isEmptyAfterPoint = numbers[1].includes('_');
    return float > 0 && !isEmptyAfterPoint // && !isOnlyZeros
      ? undefined
      : 'Error';
  }
};

export const exciseTaxDocumentNumberValidator = (value, args) => {
  const { excise_tax_sum, excise_tax_document_date } = args;
  if (!value && (excise_tax_sum || excise_tax_document_date)) return 'Укажите сумму';
  if (!value && !excise_tax_sum && !excise_tax_document_date) {
    return undefined;
  }
}

export const isExciseTaxWithEmptyValue = (value, args) => {
  const { excise_tax_document_number, excise_tax_document_date } = args;
  if (!value && (excise_tax_document_number || excise_tax_document_date)) return 'Укажите сумму';
  if (!value && !excise_tax_document_number && !excise_tax_document_date) {
    return undefined;
  } else {
    const float = parseFloat(value);
    const numbers = value.split('.');
    const isOnlyZeros = numbers[0].length > 1 && /^0*$/.test(numbers[0]);
    const isEmptyAfterPoint = numbers[1].includes('_');
    return float > 0 && !isOnlyZeros && !isEmptyAfterPoint
      ? undefined
      : 'Error';
  }
};

export const certRequired = value => {
  if (isObject(value)) {
    return value.date ? undefined : 'Error';
  }
  return value ? undefined : 'Error';
};

export const validateIsNumber = value => {
  return !/\D/.test(value) ? undefined : 'Error';
};

export const isPhone = value =>
  value && value.match(/^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/)
    ? undefined
    : 'Error';

export const isInn = value =>
  value && value.match(/^(\d{10}|\d{12})$/) ? undefined : 'Error';

export const validateArray = vals => {
  if (!(vals && vals.length)) return ['Error'];
  return undefined;
};

export const validateUIT = value =>
  value && /^[A-z\d]{38}$/.test(value) ? undefined : 'Error';

export const validateEmail = value => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(value) ? undefined : 'Error';
};

export const validateMaxLength = maxLength => (value = '') =>
  value.length > 0 && value.length < maxLength ? undefined : 'Error';

export const isValidInn = country => value => {
  if (!value) {
    return 'Error';
  }
  if (!/^\d+$/.test(value)) {
    return 'Error';
  }

  if (country) {
    switch (country) {
      case 'AM':
        return value.length === 8 ? undefined : 'Error';
      case 'BY':
        return value.length === 9 ? undefined : 'Error';
      case 'KG':
        return value.length === 14 ? undefined : 'Error';
      case 'RU':
        return value.length === 10 || value.length === 12 ? undefined : 'Error';
    }
  }

  return undefined;
};

export const isValidGTD = value =>
  value &&
  /\d{5}\/(((((0[1-9]{1}|1[0-9]{1}|2[0-8]{1})\.(0[1-9]{1}|1[0-2]{1}))|((29|30)\.(01|0[3-9]{1}|1[0-2]{1}))|(31\.(01|03|05|07|08|10|12)))\.((19|20)[0-9]{2}))|(29\.02\.((19|20)(((0|2|4|6|8)(0|4|8))|((1|3|5|7|9)(2|6))))))\/\d{7}/.test(
    value,
  )
    ? undefined
    : 'Error';

export const isValidProductionSerialNumber = (value) =>
  value && /^(?!\s*$).+/.test(value) ? undefined : 'Error';