import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionBar from './ActionBar';
import ElementsCount from './ElementsCount';

import { FilterFormWrapper } from './styled';

class FilterForm extends PureComponent {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    leftSideComponents: PropTypes.array,
    total: PropTypes.number,
    isTotalHidden: PropTypes.bool,
    type: PropTypes.string,
    onFilterApply: PropTypes.func.isRequired,
  };

  static defaultProps = {
    leftSideComponents: [],
    total: 0,
    isTotalHidden: false,
    type: '',
  };

  render() {
    const {
      filters, onFilterApply, total, isTotalHidden, type, leftSideComponents,
    } = this.props;

    return (
      <FilterFormWrapper>
        <ActionBar
          filters={filters}
          leftSideComponents={leftSideComponents}
          type={type}
          onFilterApply={onFilterApply}
        />
        {isTotalHidden ? <div /> : <ElementsCount value={total} />}
      </FilterFormWrapper>
    );
  }
}

export default FilterForm;
