import { actionTypes } from '../../../constants';


export function isLoading(state = false, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_MOUNTED:
      return true;
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_LOADED:
      return false;
    default:
      return state;
  }
}
