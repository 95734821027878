import cn from 'classnames';
import { Value } from '../../../../fragments/Table/Cell/value';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from '../../../../components/Grid/Grid.styled';
import { getIconNameByPackType } from '../../../../components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { GoodsColumns } from '../../../../fragments/Table/Cell/Goods/columns.constants';
import { CIS_STATUS_NAMES } from '../../../../constants';
import React from 'react';
import { reconciliationStatuses } from '../ActDocument/Reconciliation/ReconciliationDialog.constants';
import { get } from 'lodash';
import { Toast } from '../../../../utils/Toast';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: 'Документы',
    id: 1,
  },
  {
    title: 'Уведомление о расхождениях',
    id: 2,
  },
];

export const columns = [
  {
    id: 'cisStatus',
    accessor: row => (
      <div
        className={cn({
          status_red: row.reconciliationStatus === 'NOT_FOUND',
          status_black: row.reconciliationStatus === 'FOUND',
          status_blue: row.reconciliationStatus === 'EXCLUDED',
          status_green: row.reconciliationStatus === 'ADDED',
        })}
      >
        <Value>{reconciliationStatuses[row.reconciliationStatus]}</Value>
      </div>
    ),
    Header: 'Статус сверки',
    disableFilters: true,
    width: 150,
  },
  {
    id: 'cis',
    accessor: row => {
      return (
        <Link
          onClick={e => {
            e.preventDefault();
            if (!row.userINN || row.ownerInn === row.userINN)
              window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
            else
              Toast.showError({
                content: `Открыть карточку кода идентификации может только его текущий владелец.`,
              });
          }}
        >
          <NoWrap>{row.cis}</NoWrap>
        </Link>
      );
    },
    Header: 'Код',
    disableFilters: true,
    width: 300,
  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = get(row, 'cisPackageType', '').toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{packTrivialTypesKZ[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: 'Тип упаковки',
    disableFilters: true,
    width: 170,
  },
  {
    id: 'name',
    accessor: row => (
      <div>
        <Value>{row.name}</Value>
      </div>
    ),
    disableFilters: true,
    Header: 'Наименование товара',
    width: 200,
  },
  {
    id: 'gtin',
    accessor: row => (
      <div>
        <Value>{row.gtin}</Value>
      </div>
    ),
    disableFilters: true,
    Header: 'Код товара (GTIN)',
    width: 170,
  },
  {
    id: 'status',
    accessor: row => (
      <div>
        <Value>{CIS_STATUS_NAMES[row.status]}</Value>
      </div>
    ),
    Header: 'Статус кода',
    disableFilters: true,
    width: 170,
  },
  {
    id: 'producerName',
    accessor: row => (
      <div>
        <Value>{row.producerName}</Value>
      </div>
    ),
    Header: 'Производитель',
    disableFilters: true,
    width: 350,
  },
  {
    id: 'ownerName',
    accessor: row => (
      <div>
        <Value>{row.ownerName}</Value>
      </div>
    ),
    Header: 'Владелец',
    disableFilters: true,
    width: 350,
  },
];
