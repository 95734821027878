export default {
  actionNamespace: 'REPORT_LIST',
  options: [],
  columns: [{
    Header: 'Наименование',
    id: 'name',
    accessor: 'name',
    width: 320,
  }, {
    Header: 'Описание',
    id: 'description',
    accessor: 'description',
    minWidth: 700,
  }],
  beforeTable: {
    showGraph: false,
  },
  beforeFilterForm: {
    addButton: false,
  },
  filters: [],
};
