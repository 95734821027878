import { createAction } from 'redux-actions';

export const subject = '[Draft]';

export const mounted = createAction(`${subject} mounted`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const updatePage = createAction(`${subject} update page`);
export const getRequest = createAction(`${subject} get request`);
export const pagination = createAction(`${subject} pagination`);
export const filters = createAction(`${subject} filters`);
export const sort = createAction(`${subject} sort`);
export const setSorting = createAction(`${subject} set sorting`);
export const updateParams = createAction(`${subject} update params`);
export const create = createAction(`${subject} create`);
export const save = createAction(`${subject} save`);
export const remove = createAction(`${subject} delete`);
export const download = createAction(`${subject} download`);
export const loadDraft = createAction(`${subject} load draft`);
export const loadBodyDraft = createAction(`${subject} load body draft`);
export const createDraft = createAction(`${subject} create draft`);
