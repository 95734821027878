import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTheme} from "styled-components";
import { ModalTitle, ModalTitleWrapper, STEPS } from './AddIdentificationCodes.constantes'
import FileUploadModalContent from "../Modals/FileUploadModal/FileUploadModal.content";
import { Translate } from "../Translate/Translate";
import * as selectors from "./ducks/AddIdentificationCodes.selectors";
import * as authSelectors from "../Auth/Auth.selectors";
import * as actions from "./ducks/AddIdentificationCodes.actions";

const propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onConvert: PropTypes.func.isRequired,
    types: PropTypes.string.isRequired,
    step: PropTypes.oneOf(Object.values(STEPS)).isRequired,
    errorText: PropTypes.string,
    formMutators: PropTypes.object.isRequired,
    json: PropTypes.string,
    file: PropTypes.object,
    title: PropTypes.string,
    inn: PropTypes.string,
    codesKey: PropTypes.string,
    userId: PropTypes.any,
};

const defaultProps = {
    codesKey: 'products'
};

export class AddIdentificationCodes extends React.Component {
    onLoad = file => {
        const { onCloseModal, formMutators, inn, userId, codesKey } = this.props;
        this.props.convert({ file, onCloseModal, formMutators, inn, userId, codesKey });
        // this.props.onConvert();
    };

    onError = error => {
        console.log('onError', error);
    };

    onDrop = files => {
        this.props.load();
    };

    onCancelSign = e => {
        this.props.reset();
        this.props.onCloseModal();
    };

    onClose = e => {
        this.props.reset();
        this.props.onCloseModal();
    };

    getQty = file => {
        try {
            const qty = JSON.parse(file.content);
            return qty.filter(
                ({ receipt }) => receipt.code === 3 || receipt.code === 31,
            ).length;
        } catch (e) {
            return 0;
        }
    };

    render() {
        const { title, types, step, errorText, file } = this.props;

        return (
            <FileUploadModalContent
                isError={step === STEPS.ERROR}
                errorContent={<div>{errorText}</div>}
                onCloseModal={this.onClose}
                types={types}
                isLoading={step === STEPS.LOADING}
                onError={this.onError}
                onLoad={this.onLoad}
                onDrop={this.onDrop}
                fileOpenParams={{
                    asArrayBuffer: true,
                }}
                title={Translate(title || 'Добавление товара')}
                preloaderText={Translate('Загрузка файла. Пожалуйста, подождите')}
                customTitle={
                    step === STEPS.PRELOAD || step === STEPS.LOADING ? (
                        <ModalTitleWrapper>
                            <ModalTitle>{Translate('Загрузка кодов маркировки товара')}</ModalTitle>
                        </ModalTitleWrapper>
                    ) : null
                }
                beforeLoadText={Translate(
                    'Перетащите или выберите файл формата *.xls, *.xlsx',
                )}
            />
        );
    }
}

AddIdentificationCodes.defaultProps = defaultProps;
AddIdentificationCodes.propTypes = propTypes;

const mapState = state => {
    return {
        step: selectors.step(state),
        errorText: selectors.errorText(state),
        inn: authSelectors.userInn(state),
        userId: authSelectors.userId(state),
    };
};

const mapDispatch = dispatch => ({
    convert: file => dispatch(actions.convert(file)),
    load: () => dispatch(actions.load()),
    reset: () => dispatch(actions.reset()),
});

export default compose(
    connect(mapState, mapDispatch),
    withTheme,
)(AddIdentificationCodes);
