import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { FormWrap, GridContainer, Title, useStyles, MainContainer, Text, Header, PreviewSection, IconWrap, ErrorTextWrap, Breadcrumb } from './preview.styled';
import { ListPreview,  ArticlePreview } from '../ArticleCreation/components/preview';
import { Button } from '@crpt-ui/core';
import UZ from '../../common_components/Icons/article/uz.svg';
import RU from '../../common_components/Icons/article/ru.svg';
import Article from '../../common_components/Icons/article/Article.svg';
import Banner from '../../common_components/Icons/article/Banner.svg';
import { Icon } from '@material-ui/core';
import { history } from '../../store';
import { useTranslation } from 'react-i18next';
import * as actions from '../ArticleListPage/ducks/articles.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withPermissions from '../../common_components/hocs/withPermissions';
import { permissionConfig } from '../../motp_components/_Requests/RequestList/RequestList.permissions';
import { SelectButton } from '../ArticleCreation/components/select-button';
import { BUCKET_URL } from '../ArticleListPage/constants';
import { StatusCell } from '../ArticleListPage/components/StatusCell';
import Icons from '../../common_components/Icons/Icons';
import { CancelDialog } from './components/cancel-dialog';
import * as actionsCreate from '../ArticleCreation/ducks/articlesCreation.actions';

const ArticlePreviewView = ({ loadList, list, location, setItemForEdit, stopArticle}) => {
  const urlId = Number(location.pathname.replace('/mobile-content/articles/preview/',''));
  const lang = String(location.search.replace('?lang=',''));
  const { t } = useTranslation();
  const styles = useStyles();
  const [openCancel, setOpenCancel] = useState(false);
  const [isIconAvailable, setAvailableIcon] = useState(false);
  const [isArticleAvailable, setArticleAvailable] = useState(false);
  const [article, setArticle] = useState('');
  const dateFormat = 'DD.MM.YYYY HH:mm:ss';

  const handleCLose = () => {
    setOpenCancel(false);
    stopArticle({id: urlId, lang})
  }

  const onEdit = () => {
    history.push(`/mobile-content/articles/update/${lang}/${urlId}`);
  }

  const onCopy = () => {
    history.push(`/mobile-content/articles/copy/${lang}/${urlId}`);
  }

  useEffect(() => {
   loadList(lang);
  }, []);

  const getHtml = async () => {
      await fetch(`${BUCKET_URL + data.articleUrl}`)
      .then((data) => {
        if (data.ok) {
          return data.text();
        } else {
          setArticleAvailable(false)
          return Promise.reject()
        }
      })
      .then((data) => {
        setArticle(data)
        setArticleAvailable(true);
      })
      .catch((e) => {
        setArticleAvailable(false)
        console.error(e)
      });
     
  };

  const getImage= async () => {
      const serverImage =  await fetch(`${BUCKET_URL + data.icon}`)
      .then((data) => {
        return data.text();
      })
      .then((data) => data)
      .catch((e) => {
        setAvailableIcon(false);
        console.error(e)
      });
      const isArticleImage = data.itemType === 'article' && serverImage.includes('</svg>');
      const isBanner = data.itemType !== 'article' && data.icon;
      setAvailableIcon(isArticleImage || isBanner);
  };

  const data = useMemo(() => (list && list.find(({ id }) => id === urlId) || []), [urlId, list, lang])

  useEffect(() => {
    if(data && data.articleUrl) {
      getHtml();
      getImage();
    }
  }, [urlId, list, lang]);


  const getExpirationDate = () => {
    if (data.expirationDate) {
      return moment(data.expirationDate).format(dateFormat);
    }
      return t('Дата окончания не установлена')
  }

  return (<div>
    <Breadcrumb onClick={() => history.push("/mobile-content/articles")}>{t('Управление контентом')}</Breadcrumb>
    <div>
    <Header>
      <CancelDialog
          isOpen={openCancel}
          handleCLose={handleCLose}
          handleCancel={() => setOpenCancel(false)}
        />
      <div>
        <Title>{t('Статья')} {urlId}</Title>
        <div>{StatusCell({status: data.status})}</div>
      </div>
      <div>
        {data.status === 'ACTIVE' && (<Button
          type="button"
          variant="text"
          size="md"
          color="error"
          onClick={() => setOpenCancel(true)}
          className={"redColor"}
        >
          {t('Остановить действие')}
        </Button>)}
        <Button
          type="button"
          color="secondary"
          variant="contained"
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '4px',
            background: '#7B869547',
            margin: '0 10px',
          }}
          size="md"
          icon={<Icon component={Icons.Copy}/>}
          onClick={onCopy}
          >
          {t('Дублировать')}
        </Button>
        {['ACTIVE', 'PLANNED'].includes(data.status) && (<Button
          type="button"
          variant="contained"
          size="md"
          icon={<Icon component={Icons.EditArticle} fill="white"/>}
          onClick={onEdit}
          className={"whiteColor"}
        >
          {t('Редактировать')}
        </Button>)}
      </div>
    </Header>
    </div>
    <FormWrap>
      <MainContainer>
        <GridContainer>
          <div>
            <Title>{t('Общая информация')}</Title>
            <br/>
            <div>
              <label>{t('Дата создания')}</label>
              <Text>{moment(data.createdOn).format(dateFormat)}</Text>
            </div>
            <br/>
            <div>
              <label>{t('Язык')}</label>
              <div className={styles.flex}>
                <div className={styles.flex}>
                  <SelectButton
                    title={lang === 'uz' ? "Узбекский" : "Русский"}
                    icon={lang === 'uz' ? <Icon component={UZ}/> : <Icon component={RU}/>}
                  />
                </div>
              </div>
            </div>
            <br/>
            <div>
              <label>{t('Тип статьи')}</label>
              <SelectButton
                  title={data.itemType === 'article' ? "Статья" : "Баннер"}
                  icon={data.itemType === 'article'? <Icon component={Article}/> : <Icon component={Banner}/>}
                />
            </div>
            <br/>
            <label>{t('Изображение')}*</label>
            { isIconAvailable ? data.itemType === 'article' ?
              (
              <div>
                <img src={BUCKET_URL + data.icon} alt="" width="50px" height="50px"/>
              </div>)
            : (
              <div>
                <img src={BUCKET_URL + data.icon} alt="" width="160px" height="130px"/>
              </div>)
              : <IconWrap>
                  <div>
                    <Icon component={Icons.Warning}/>
                  </div>
                  <ErrorTextWrap>{t('Изображение не найдено')}</ErrorTextWrap>
                </IconWrap>
            }
            <br/>
            <label>{t('Заголовок в списке статей')}*</label>
            <Text>{data.name}</Text>
            <br/>
            <label>{t('Заголовок при просмотре статьи')}*</label>
            <Text>{data.subtitle}</Text>
            <br/>
            <label>{t('Порядковый номер статьи в ленте')}</label>
            <Text> {data.order}</Text>
            <br/>
            <label>{t('Период отображения в приложении')}*</label>
            <Text>{moment(data.startDate).format(dateFormat)} - {getExpirationDate()}</Text>
          </div>
        </GridContainer>
        <MainContainer>
          <PreviewSection>
            <ListPreview title={data.name} type={data.itemType} order={data.order} image={data.icon} icon={data.icon}/>
          </PreviewSection>
          <PreviewSection>
            <ArticlePreview text={article} isArticleAvailable={isArticleAvailable}/>
          </PreviewSection>
        </MainContainer>
      </MainContainer>
    </FormWrap>
  </div>)
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  list: state.LP.ArticleListPage.list,
});

const mapDispatch = dispatch => ({
  loadList: (payload) => dispatch(actions.loadList(payload)),
  setItemForEdit: (item) => dispatch(actionsCreate.setItem(item)),
  stopArticle: (item) => dispatch(actionsCreate.stop(item)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
)(ArticlePreviewView);
