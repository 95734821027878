import { CadesUtil } from '../../utils/CadesUtil';
import { RuToken, JaCarta, JaCarta2 } from '../../packages/RusCryptoJS/src/index';

export class GetCertificates {

  static async getCadesCertificates() {
    let certificates = await CadesUtil.getFinalCertsArray();
    return Promise.resolve(certificates.map(c => ({ ...c, certType: 'CADES' })));
  }

  static async getRutokenCertificates() {
    const rutoken   = new RuToken();
    const info      = await rutoken.init();
    const certs     = await rutoken.listCertificates();
    const infoCerts = await Promise.all(
      certs.map(async cert => {
        const info = await rutoken.certificateInfo(cert.id);
        return { ...cert, ...info, certType: 'RUTOKEN' };
      })
    );
    return Promise.resolve(infoCerts);
  }

  static async getJacartaCertificates() {
    const jacarta = new JaCarta();
    const info    = await jacarta.init();
    const certs     = await jacarta.listCertificates();
    const infoCerts = await Promise.all(
      certs.map(async cert => {
        const info = await jacarta.certificateInfo(cert.id);
        return { ...cert, ...info, certType: 'JACARTA' };
      })
    );
    return Promise.resolve(infoCerts);
  }

  static async getJacarta2Certificates() {
    const jacarta = new JaCarta2();
    const info    = await jacarta.init();
    const certs     = await jacarta.listCertificates();
    const infoCerts = await Promise.all(
      certs.map(async cert => {
        const info = await jacarta.certificateInfo(cert.id);
        return { ...cert, ...info, certType: 'JACARTA2' };
      })
    );
    return Promise.resolve(infoCerts);
  }

}
