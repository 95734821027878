import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DELAY = 100;

const defaultTransition = `${DELAY}ms top linear`;

const LetterWrap = styled.div`
  width: 28px;
  height: 43px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
  perspective: 90px;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const StaticWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 6;
`;

const TopLetterPart = styled.div`
  width: 100%;
  height: 21px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  ${props => (props.moving ? 'border-radius: 3px;' : '')}
  box-sizing: border-box;
  }} position: relative;
  background-color: #434244;
  overflow: hidden;
  display: flex;
`;

const BottomLetterPart = styled.div`
  width: 100%;
  height: 21px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #434244;
  overflow: hidden;
  position: relative;
`;

const MovingPart = styled.div`
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0px;
  z-index: 7;
  visibility: ${props => (props.changing ? 'visible' : 'hidden')};
  top: ${props => (props.changing ? '22px' : '0')};
  transition: ${props => (props.changing ? defaultTransition : '0')};
`;

const LetterPart = styled.div`
  font-size: 30px;
  color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 43px;
  box-sizing: border-box;
  ${props => (props.top ? 'top: 0px;' : 'bottom: 0px;')};
`;

const BorderPart = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
`;

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const LETTERS = ' 0123456789'.split(''); //" ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.,':()&!?+-".split('');

export class Letter extends Component {
  timeout;

  constructor(props) {
    super(props);

    this.LETTERS = LETTERS; //shuffle(LETTERS.slice());

    this.state = {
      changing: false,
      targetLetter: this.props.value,
      letterIndex: 0
    };

    this.updateTimeout = this.updateTimeout.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.targetLetter) {
      return {
        targetLetter: props.value,
        changing: true
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.targetLetter !== prevState.targetLetter) {
      this.updateTimeout();
    }
  }

  componentDidMount() {
    this.updateTimeout();
  }

  updateTimeout() {
    const targetLetter = this.state.targetLetter.toLocaleUpperCase();
    if (targetLetter === this.LETTERS[this.state.letterIndex]) return;

    this.timeout = setTimeout(() => {
      if (this.timeout) clearTimeout(this.timeout);
      this.state.changing
        ? this.setState({
            changing: false,
            letterIndex: this.state.letterIndex >= this.LETTERS.length -1 ? 0 : this.state.letterIndex + 1
          })
        : this.setState({
            changing: true
          });

      this.updateTimeout();
    }, DELAY);
  }

  render() {
    const currentLetter = this.LETTERS[this.state.letterIndex];
    const nextLetter = this.LETTERS[this.state.letterIndex + 1];
    const staticTopLetter = this.state.changing ? nextLetter : currentLetter;

    return (
      <LetterWrap>
        <StaticWrap>
          <TopLetterPart>
            <LetterPart top>{staticTopLetter}</LetterPart>
          </TopLetterPart>
          <BorderPart />
          <BottomLetterPart>
            <LetterPart>{currentLetter}</LetterPart>
          </BottomLetterPart>
        </StaticWrap>

        <MovingPart changing={this.state.changing}>
          <TopLetterPart moving>
            <LetterPart top>{currentLetter}</LetterPart>
          </TopLetterPart>
        </MovingPart>
      </LetterWrap>
    );
  }
}
