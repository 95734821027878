import { takeEvery, put } from 'redux-saga/effects';
import * as actions from './Pagination.actions';
import * as listActions from '../ducks/List.actions';

function* selectedSaga(action) {
  const { payload, meta } = action;
  const { selected, pagesCount, page, dir } = payload;

  if (!(selected > pagesCount && page === 1)) {
    yield put(listActions.paginate({ page, dir }, meta));
  } else {
    yield put(listActions.paginate({ page: 1 }, meta));
  }
}

export default function* watchActions() {
  yield takeEvery(actions.select, selectedSaga);
}
