import { isNumber, isString, isNaN } from 'lodash';

import { addSpacesBetweenThousands } from '../../../../utils';


const toView = (value) => {
  if (!isString(value) && !isNumber(value)) { return '-'; }
  if (isString(value) && isNaN(Number(value))) { return '-'; }

  const valueIsNumber = isString(value) ? Number(value) : value;
  return `${addSpacesBetweenThousands(valueIsNumber)}`;
};

export default toView;
