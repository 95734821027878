export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': 'Тип упаковки товара'
        },
        {
          'col1': [2, 3],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [4],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [5],
          'col2': 'Страна производства'
        },
        {
          'col1': [6, 7],
          'col2': 'ИНН и наименование владельца товара, списавшего товар'
        },
        {
          'col1': [8],
          'col2': 'Субъект РК, в котором зарегистрирован владелец товара, списавшего товар'
        },
        {
          'col1': [9],
          'col2': 'Населённый пункт, в котором зарегистрирован владелец товара, списавшего товар'
        },
        {
          'col1': [10],
          'col2': 'Количество списанного товара'
        },
        {
          'col1': [11],
          'col2': 'Стоимость списанного товара в тенге'
        },
      ]
    }
  ]
}
