import { handleAction, handleActions } from 'redux-actions';
import * as actions from './FilePreview.actions';

const doNothing = () => {};

const status = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.status || null,
  null
);

const content = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.content || null,
  null
);

const file = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.file || null,
  null
);

const data = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.data || null,
  null
);

const number = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.number || null,
  null
);

const warning = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.warning || null,
  null
);

const contentType = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.type || null,
  null
);

const docType = handleAction(
  actions.setContent,
  (state, { payload = {} }) => payload.docType || null,
  null
);


const onAccept = handleAction(
  actions.setCallbacks,
  (state, { payload = {} }) => payload.onAccept || doNothing,
  doNothing
);

const onCancel = handleAction(
  actions.setCallbacks,
  (state, { payload = {} }) => payload.onCancel || doNothing,
  doNothing
);

const types = handleAction(
  actions.openFile,
  (state, { meta = {} }) => meta.types || '.xml, .csv',
  '.xml, .csv'
);

const gotErrors = handleAction(
  actions.setCallbacks,
  (state, { payload = {} }) => payload.gotErrors || [],
  []
);

const openFileDialog = handleActions(
  {
    [actions.openFileDialog]: () => true,
    [actions.setContent]: () => false,
    [actions.close]: () => false,
  },
  false
);

const loaded = handleActions(
  {
    [actions.openString]: () => true,
    [actions.close]: () => false,
  },
  false
);

export default {
  warning,
  status,
  number,
  content,
  contentType,
  gotErrors,
  onAccept,
  onCancel,
  types,
  docType,
  openFileDialog,
  loaded,
  file,
  data
};
