import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
              <li>По населённому пункту</li>
              <li>По субъекту РК</li>
            </ul>,
          'col3': 'Субъект РК, в котором был реализован товар'
        },
        {
          'col1': [2],
          'col2': <ul>
              <li>По населённому пункту</li>
            </ul>,
          'col3': 'Наименование населённого пункта (по ФИАС), в котором был реализован товар'
        },
        {
          'col1': [3],
          'col2': <ul>
              <li>По населённому пункту</li>
            </ul>,
          'col3': 'Диапазон численности населённого пункта, в котором был реализован товар'
        },
        {
          'col1': [4, 5],
          'col2': <ul>
              <li>По населённому пункту</li>
              <li>По субъекту РК</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Уникальный код и наименование товара'
        },
        {
          'col1': [6],
          'col2': <ul>
              <li>По населённому пункту</li>
              <li>По субъекту РК</li>
              <li>По товару</li>
            </ul>,
          'col3': 'Наименование производителя товара'
        },
        {
          'col1': [7, 8],
          'col2': <ul>
              <li>По населённому пункту</li>
              <li>По субъекту РК</li>
              <li>По товару</li>
            </ul>,
          'col3': <div>(Объём реализации конечному покупателю маркированного товара за выбранный период (в штуках и тенге) / Объём реализации конечному покупателю всех маркированных товаров на рынке в РК/регионе/населённом пункте за выбранный период (в штуках и рублях)) <strong>×</strong> 100%.<br /><br />
          Расчёт доли рынка происходит по всем записям, без ограничений
          </div>
        }
      ]
    }
  ]
}
