import { Icon } from '@crpt-ui/core';
import React, { useMemo } from 'react';
import { isNil } from "lodash";
import { Link } from 'src/fragments/Link';
import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import FourthCell from "./components/FourthCell";
import FifthCell from "./components/FifthCell";
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { GreyWrapper } from "../../../BasicInfo/CisInfo/CisInfo.styled";
import { countryCodeFlags } from "../../../../../constants";

const Withdraw = ({ op }) => {
  const classes = useStyles();

  const title = useMemo(
    () =>
      op.documentType === 'MTTPS_90'
        ? Translate('Вывод из оборота/Продажа')
        : Translate('Продажа в розницу'),
    [op],
  );

  const renderValuesReceipt = () => (
    <Link
      className={classes.link}
      to={`/documents/receipt/${encodeURIComponent(op.docId)}/`}
    >
      {/*{middleDottedString(op.docId)}*/}
      {op.docId}
    </Link>
  );

  const renderValuesDocument = () => (
    <Link
      className={classes.link}
      to={`/documents/list/${encodeURIComponent(op.docId)}/`}
    >
      {/*{middleDottedString(op.docId)}*/}
      {op.docId}
    </Link>
  );

  return (
    <Layout>
      <FirstCell icon={<Icon name="Retail" />} title={title} />
      <SecondCell firstHeading={Translate('Розница')} op={op} />
      <ThirdCell
        heading={
          op.documentType === 'MTTPS_90'
            ? Translate('Уведомление о выводе из оборота')
            : Translate('Кассовый чек')
        }
      >
        {/*<CellTooltip*/}
        {/*  alwaysDisplay*/}
        {/*  placement="top"*/}
        {/*  title={op.docId}*/}
        {/*  render={*/}
        {
            op.documentType === 'MTTPS_90'
              ? renderValuesDocument()
              : renderValuesReceipt()
          }
        {/*/>*/}
      </ThirdCell>
      {op.isHoreca ? (
        <FourthCell heading="">
          <GreyWrapper>
            {Translate('Товар реализован предприятием общественного питания, гостиницей, или продается в розлив')}
          </GreyWrapper>
        </FourthCell>
      ) : null}
      {countryCodeFlags.isUZ && !isNil(op.partialQuantity) ? (
        <FifthCell heading={Translate('Вскрытая упаковка')}>
          {`${op.partialQuantity}%`}
          {' '}
          {Translate('упаковки реализовано')}
        </FifthCell>
      ) : null}
      {/*<FourthCell heading={Translate('Цена продажи')}>{op.total}</FourthCell>*/}
    </Layout>
  );
};

export default Withdraw;
