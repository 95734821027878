import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../common_components/Form/Form';
import FormContextWrapper from '../../../../common_components/Form/FormContextWrapper';
import Preloader from '../../../../common_components/Preloader/Preloader';
import Preview from '../Document/Preview/Preview';
import Breadcrumbs from '../../../../common_components/Breadcrumbs/Breadcrumbs';
import Information from './Information/Information';
import Goods from './Goods/Goods';
import Cis from './Cis/Cis';
import { steps } from './ActDocument.constants';
import Total from './Total/Total';
import CorrectionInfo from './Information/CorrectionInfo';
import Info from '../../../../components/DocumentDetails/Act/Info';
import { get } from 'lodash';

const ActDocumentView = props => {
  const { data, breadcrumbs, onSubmit, loaded, isCreate, isCorrection,
    isLoading, step, onSetStep, onSaveDraft, countryCode, isDraft,
    mode, direction, id, unsubscribe } = props;
  const [goodsChanged, setGoodsChanged] = useState(false);

  const status = data === null
    ? undefined
    : Object.keys(data).length === 0
      ? null
      : data.data === undefined
        ? undefined
        : data.data.status;

  const resetGoodsChanges = () => setGoodsChanged(false)

  const isFixed = useMemo(() => get(data, 'data.type', null) === 1100,[data]);

  return (
    <React.Fragment>
      <Preview onSubmit={onSubmit}/>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <Form
        data={isCreate ? {} : data}
        onSubmit={onSubmit}
        disabled={!isCreate && !loaded && !isCorrection && !isFixed}
      >
        {isLoading && (<Preloader/>)}
        {!isLoading && (
          <FormContextWrapper>
            {
              mode === 'editedAct'
                ? (
                  <Info
                    showingData={data}
                    unsubscribe={unsubscribe}
                    direction={direction}
                    id={id}
                    onSetStep={onSetStep}
                    countryCode={countryCode}
                    show={step === steps.info}
                    pgInfo={data && data.group && data.group.length ? data.group[0].productGroupId : null}
                  />
                )
                : isCorrection
                ? (
                    <CorrectionInfo
                      loaded={loaded}
                      onSetStep={onSetStep}
                      show={step === steps.info}
                      data={data}
                    />
                  )
                : (<Information loaded={loaded} onSetStep={onSetStep} onSaveDraft={onSaveDraft} isDraft={isDraft}
                                show={step === steps.info}/>)
            }
            <Goods
              goodsChange={() => setGoodsChanged(true)}
              resetGoodsChanges={resetGoodsChanges}
              goodsChanged={goodsChanged}
              mode={mode}
              loaded={loaded}
              onSetStep={onSetStep}
              onSaveDraft={onSaveDraft}
              show={step === steps.goods}
              isCorrection={isCorrection}
              status={status}
              direction={direction}
              isFixed={isFixed}
              isDraft={isDraft}
              data={mode === 'editedAct' ? data : null }
              id={id}
            />
            {
              countryCode === 'KZ'
                ? (
                  <Total
                    data={mode === 'editedAct' ? data : null }
                    mode={mode}
                    goodsChanged={goodsChanged}
                    loaded={loaded}
                    direction={direction}
                    onSetStep={onSetStep}
                    onSaveDraft={onSaveDraft}
                    show={step === steps.cis}
                    isCorrection={isCorrection}
                    status={status}
                    isFixed={isFixed}
                    isDraft={isDraft}
                    id={id}
                  />
                )
                : <Cis loaded={loaded} onSetStep={onSetStep} onSaveDraft={onSaveDraft} show={step === steps.cis}/>
            }
          </FormContextWrapper>
        )}
      </Form>
    </React.Fragment>
  );
};

ActDocumentView.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loaded: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(Object.values(steps)).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onPreview: PropTypes.func,
  onSetStep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  countryCode: PropTypes.string,
};

export default ActDocumentView;
