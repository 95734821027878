import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
  lineWrap: {
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '10px',
    paddingBottom: '8px',
    marginTop: '12px',
    marginBottom: '12px',
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      marginBottom: '0px',
    },
    '&:hover': {
      backgroundColor: '#ebf4fa',
    },
  },
  fullWidthWrapper: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  link: {
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    color: '#1a99f4',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  id: {
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    textDecoration: 'none',
  },
  wrap: {
    marginTop: '16px',
  },
  container: {
    padding: '10px 32px 8px',
  },
  emptyHeading: {
    marginTop: '18px',
    display: 'flex',
  },
  icon: {
    marginTop: '2px',
    paddingRight: '12px',
  },
}));

export const Id = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;
