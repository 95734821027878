export const usualTheme = {
  innerRadius: 67,
  outerRadius: 102,
  rotationDeg: 270,
  percentText: {
    fontSize: 14,
    fontFamily: '"SegoeUI", sans-serif',
    fill: '#ffffff',
  },
};
