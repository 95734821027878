import { Button, MenuButton, Toolbar } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import * as Icons from '@crpt-ui/icons';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import EmptyList from 'src/common_components/EmptyList/EmptyList';
import { Modal } from 'src/fragments/Modal';
import { ReadParseFile } from 'src/fragments/ReadParseFile';

import { Divider } from '../GrayAreaCreate.styles';
import { columns, modalActionTypes } from './Gtins.constants';
import { rowHandler } from './Gtins.utils';
import { GtinsModal } from './GtinsModal';
import { UploadGtinsModal } from './UploadGtinsModal';
import { Trans } from 'react-i18next';
import { Translate } from '../../../../common_components/Translate/Translate';

const GtinsView = ({
  actions,
  bulkActions,
  bulkActionsDisabled,
  data,
  dispatchModal,
  gtinsFromFile,
  onReadParseFileSuccess,
  onUpdateGtins,
  onUploadGtins,
  setCustomIdsToRows,
  setSelectedRowIds,
  stateModal,
  tableRef,
}) => (
  <React.Fragment>
    <ActionsRow
      bulkActions={bulkActions}
      bulkActionsDisabled={bulkActionsDisabled}
      dispatchModal={dispatchModal}
      instance={tableRef}
      onReadParseFileSuccess={onReadParseFileSuccess}
    />

    <div style={{ position: 'relative', flexGrow: 1 }}>
      {data.length ? (
        <Datatable
          actions={actions}
          ref={tableRef}
          data={data}
          columns={columns}
          onRowSelect={setSelectedRowIds}
          getRowId={setCustomIdsToRows}
          useRowSelect
          useRowState
          useFilters
          useSort
          manualFilters={false}
        />
      ) : (
        <React.Fragment>
          <Divider/>
          <EmptyList icon={<Icons.TaskList/>}>
            <Box textAlign="center">
              <Box mb={2.5} fontSize={20}>Список GTIN пуст</Box>
              <Box fontWeight={500} color="text.secondary">
                <Trans>Данный шаг <b>не является обязательным</b> для заполнения,<br/>Вы можете пропустить добавление GTIN</Trans>
              </Box>
            </Box>
          </EmptyList>
        </React.Fragment>
      )
      }
    </div>

    <Modal
      title="GTIN"
      isOpen={stateModal.isGtinsModalOpen}
      onClose={() => dispatchModal({ type: modalActionTypes.GTINS_MODAL.CLOSE })}
      useHeaderDivider={true}
    >
      <GtinsModal
        onClose={() => dispatchModal({ type: modalActionTypes.GTINS_MODAL.CLOSE })}
        onUpdateGtins={onUpdateGtins}
        preselectedData={data}
      />
    </Modal>

    <Modal
      title="Загрузка GTIN"
      isOpen={stateModal.isUploadGtinsModalOpen}
      onClose={() => dispatchModal({ type: modalActionTypes.UPLOAD_GTINS_MODAL.CLOSE })}
    >
      <UploadGtinsModal
        onClose={() => dispatchModal({ type: modalActionTypes.UPLOAD_GTINS_MODAL.CLOSE })}
        onUploadGtins={onUploadGtins}
        gtinsFromFile={gtinsFromFile}
      />
    </Modal>
  </React.Fragment>
);

export default GtinsView;

const ActionsRow = ({
  bulkActions,
  bulkActionsDisabled,
  dispatchModal,
  instance,
  onReadParseFileSuccess,
}) => {
  return bulkActionsDisabled ? (
    <Box py={2} px={5}>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={<Icons.Plus/>}
            onClick={() => dispatchModal({ type: modalActionTypes.GTINS_MODAL.OPEN })}
          >
            {Translate('Добавить')}
          </Button>
        </Grid>
        <Grid item>
          <ReadParseFile
            onSuccess={onReadParseFileSuccess}
            rowHandler={rowHandler}
          >
            {({ onUploadClick }) => (
              <Button
                icon={<Icons.Upload/>}
                onClick={onUploadClick}
                variant="outlined"
              >
                {Translate('Загрузить')}
              </Button>
            )}
          </ReadParseFile>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Toolbar
      onClose={() => {
        instance.current.toggleAllRowsSelected(false);
      }}
    >
      <MenuButton
        items={bulkActions}
        variant="outlined"
      >
        {Translate('Действия&nbsp;с&nbsp;выбранными')}
      </MenuButton>
    </Toolbar>
  );
};
