import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import {Input} from '@crpt-ui/core';
import parse from 'date-fns/parse';

import DatePicker from './DatePicker';
import formatDatesString from '@crpt-ui/core/lib/DatePicker/utils/formatDatesString';

const Datepicker = ({
  onChange,
  date,
  minDate,
  maxDate,
  parse: parseFormat,
  format,
  disabled = false,
  displayFormat = 'dd.MM.yyyy',
  startYear,
  endYear,
  ...props
}) => {
  const dateToValue = (d) => {
    if (!d) {
      return '';
    }
    if (typeof d === 'string') {
      const parsedDate = parse(date, parseFormat || '', new Date());
      if (parsedDate instanceof Date && !isNaN(parsedDate)) {
        return formatDatesString({startDate: parsedDate}, {displayFormat});
      } else {
        console.error('Invalid parse format for received input value');
        return '';
      }
    } else {
      return formatDatesString({startDate: date}, {displayFormat});
    }
  };
  const [state, setState] = useState({
    value: dateToValue(date),
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setState({
      value: dateToValue(date),
    });
  }, [date]);

  const handleClick = event => {
    if(!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDateSubmit = (d) => {
    setState({
      value: formatDatesString({startDate: d}, {displayFormat}),
    });
    if (format) {
      onChange(formatDatesString({startDate: d}, {displayFormat: format}));
    } else {
      onChange(d);
    }
    handleClose();
  };
  const handleChangeInput = (inputDate) => {
    if(!inputDate) {
      setState({value: inputDate});
      onChange(null);
      return;
    }
    const parsedDate = parse(inputDate, displayFormat, new Date());
    setState({value: inputDate});
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      if (format) {
        onChange(formatDatesString({startDate: parsedDate}, {displayFormat: format}));
      } else {
        onChange(parsedDate);
      }
    }
  };
  const getDate = (d) => {
    const parsedDate = parse(d, displayFormat, new Date());
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      return parsedDate;
    } else {
      return null;
    }
  };

  return (
    <div>
      <Input
        value={state.value}
        onChange={e => handleChangeInput(e.target.value)}
        onClick={handleClick}
        disabled={disabled}
        {...props}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          anchorEl ?
            <DatePicker
              onClose={handleClose}
              onSubmit={handleDateSubmit}
              date={getDate(state.value)}
              minDate={minDate}
              maxDate={maxDate}
              startYear={startYear}
              endYear={endYear}  
            /> : null
        }
      </Menu>
    </div>
  );
};
Datepicker.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  displayFormat: PropTypes.string,
  parse: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  startYear: PropTypes.number,
  endYear: PropTypes.number,
};

export default Datepicker;
