import { handleActions } from 'redux-actions';
import * as actions from './CisList.actions';


const results = handleActions({
  [actions.mounted]: () => [],
  [actions.loaded]: (state, { payload = {} }) => payload.results || [],
}, []);

const total = handleActions({
  [actions.mounted]: () => 0,
  [actions.loaded]: (state, { payload = {} }) => payload.total || 0,
}, 0);

const selectedPage = handleActions({
  [actions.updatePage]: (state, { payload }) => payload,
}, 1);

const pagination = handleActions({
  [actions.pagination]: (state, { payload = {} }) => payload,
}, {});

const filters = handleActions({
  [actions.filters]: (state, { payload = {} }) => payload,
}, {});

const defaultSorting = {
  order: 'DESC',
  orderColumn: 'emd',
};

const sorting = handleActions({
  [actions.setSorting]: (state, { payload }) => payload || defaultSorting,
}, defaultSorting);

const loaded = handleActions({
  [actions.updatePage]: () => false,
  [actions.loaded]: () => true,
  [actions.mounted]: () => false,
}, false);

export default {
  results,
  total,
  selectedPage,
  pagination,
  filters,
  sorting,
  loaded
};
