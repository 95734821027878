import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const FormWrap = styled.div`
  width: 100%;
  background-color: white;
  margin: 16px 0;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 3px 6px 0px #00000014;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 33.2px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #212121;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

export const Breadcrumb = styled.a`
  color: #6BAC5B;
  font-weight: 400;
  font-size: 16px;
  ine-height: 18.67px;
  letter-spacing: 0.15px;
  cursor: pointer;
`;

export const useStyles = makeStyles({
  flex: {
    display: 'flex',
    gap: 10,
  },
  input: {
    marginTop: 10,
    '& > label': {
      height: 45
    },
    '& input': {
      width: '100%',
      outline: 'none',
      borderRadius: '4px',
      height: 45,
      border: '1px solid #d3d3d3',
      padding: '0 16px',
      top: 0
    }
  },
  switch: {
    marginTop: 10,
    '& span': {
      margin: 0,
      fontSize: '14px',
    }
  },
  datapickers: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginTop: 10,
    '& input': {
      width: '100%',
      fontFamily: 'SegoeUI',
      outline: 'none',
      borderRadius: '4px',
      height: 45,
      border: '1px solid #d3d3d3',
      padding: '0 16px',
      position: 'relative',
    }
  },
  datapicker: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  
  controlError: {
    '& input': {
      background: '#FFEBEB',
      border: '1px solid #FF3C21',
    }
  },
  divider: {
    marginBottom: '10px',
  }
});
