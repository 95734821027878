import styled from 'styled-components';

export const MainWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 10px;
`;

export const CheckedWrap = styled.div`
  min-height: 26px;
  font-size: 16px;
  display: flex;
  color: #52535a;
  align-items: center;
  background: white;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 4px;
  padding: 5px;
  box-sizing: border-box;
`;

export const IconWrap = styled.div`
  margin-left: 5px;
  cursor: pointer;
  padding-top: 5px;
`;

export const Textarea = styled.textarea`
  white-space: nowrap;
  border: 0;
  outline: 0;
  resize: none;
  height: auto;
  font-size: 16px;
  width: ${props => props.width};
  background: rgba(0, 0, 0, 0);
  border-top: 4px solid transparent;
`;

export const OptionWrap = styled.div`
  display: block;
`;

export const OptionContent = styled.div`
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  cursor: pointer;
`;

export const OptionText = styled.div``;

export const IconOptionWrap = styled.div`
  display: flex;
  margin-right: 18px;
  padding-top: 3px;
`;
