import React from 'react';
import {
  Wrap,
  IconWrap,
  TextWrap,
  Text,
  SvgIconError,
  SvgIconSuccess,
} from './MaterialCertSelect.styled';

const CertMenuItem = ({ name, position, date, source, disabled }) => {
  return (
    <Wrap>
      <IconWrap>{disabled ? <SvgIconError /> : <SvgIconSuccess />}</IconWrap>
      <TextWrap>
        <Text setMargin="0 0 5px 0">{source}</Text>
        <Text isDisabled={disabled} setMargin="0 0 8px 0">
          {date}
        </Text>
        <Text setFotnSize="16px">
          {name} · {position}
        </Text>
      </TextWrap>
    </Wrap>
  );
};
export default CertMenuItem;
