import './imports';
import './axiosDefaults';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { store } from './store';
import './languages';
import { getCookie, setProductGroup } from './utils/cookieHelpers';

if (process.env.NODE_ENV === 'development' && !getCookie('productGroup')) { 
  setProductGroup('tobacco');
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

unregister();
