import styled from 'styled-components';

export const WrapImg = styled.div`
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
  position: relative;
  z-index: 1001;
  height: ${props => props.height ? props.height : '32px'};
  width: ${props => props.width ? props.width : '32px'};
  
`;

export const Text = styled.p`
  margin: 10px 0 0 0;
  color: #434244;
  font-weight: 200;
  font-size: ${props => props.large ? '16px' : '14px'};
`;

export const WrapPreloader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  /* :after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.05;
  } */
`;

export const WrapPreloaderInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  /* :after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.05;
  } */
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 200px;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;


export const FixedOverlay = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(82, 83, 90, 0.5);
`;

export const ButtonWrap = styled.div`
  margin-bottom: 20px;
  pointer-events: all;
`;

export const ButtonText = styled.div`
  padding-left: 44px;
  padding-right: 44px;
`;