import { get } from 'lodash';
import { saveAs } from 'file-saver/FileSaver';

import { actionTypes } from '../constants/';
import { ParticipantService } from '../services/ParticipantService';
import { DocumentService } from '../services/DocumentService';
import { ProfileService } from '../services/ProfileService';
import { FormSerializer } from '../utils/FormSerializer';
import moment from 'moment/moment';
import * as selectors from '../selectors';
import {defaultFilterParamSerializer, 
  defaultRequestParamSerializer,
  defaultDateFilterParamSerializer,
  ParticipantDetailsCisListFilterParamSerializer, 
  ParticipantDetailsCisListRequestParamSerializer} from '../utils/filterSerializers';
import {CISService} from '../services/CISService';
import { getListActions, getPaginationListActions } from '../utils/actionGenerators';
import { getLimitOffsetFormatter, getUrlPage } from '../utils/pageFormatters';
import CISListConfig from '../projects/motp/lists/CISList';
import DocumentListConfig from '../projects/motp/lists/DocumentList';
import {extendURLByKeyValueList, modifyURLByRawDataAndFiltersConfig} from '../utils/formEvents';
import { startPreloader, endPreloader } from './Preloader';


function ParticipantDetailsMounted(inn) {
  return async dispatch => {
    try {
      const info = await ParticipantService.getParticipantInfoByInn(inn);
      dispatch(ParticipantDetailsLoaded(info.data));
    } catch (e) { null; }
  };
}

function ParticipantDetailsLoaded(data) {
  return {
    type: actionTypes.PARTICIPANT_DETAILS.PARTICIPANT_DETAILS_LOADED,
    value: data,
  };
}

function DocumentsMounted(inn) {
  return dispatch => {
    DocumentService.getList({ participantInn: inn, pagination: { limit: 10 } }).then(
      answer => {
        console.log('documents participant loaded', answer.data);
        dispatch(ParticipantDocumentsLoaded(answer.data));
      }
    );
  };
}

function ParticipantDocumentsLoaded(data) {
  return {
    type: actionTypes.PARTICIPANT_DETAILS.DOCUMENTS_LOADED,
    value: data,
  };
}

function DocumentFilterSubmitClicked(data) {
  return dispatch => {
    console.log('submit clicked', data);

    const params = { pagination: { limit: 10 } };

    const dataSearchLenght = get(data, 'search.length', 0);
    if (dataSearchLenght && data.search[0].length) {
      params.search = data.search[0];
    }

    const dataDateLenght = get(data, 'date.length', 0);
    if (dataDateLenght && (data.date[0].from || data.date[0].to)) {
      if (data.date[0].from) {
        params.dateFrom = +moment(data.date[0].from).format('x');
        console.log('dateFrom', moment(data.date[0].from).format('YYYY MM DD'));
      }
      if (data.date[0].to) {
        params.dateTo = +moment(data.date[0].to).format('x');
        console.log('dateTo', moment(data.date[0].to).format('YYYY MM DD'));
      }
    }

    const dataDocumentStatusLenght = get(data, 'documentStatus.length', 0);
    if (dataDocumentStatusLenght && data.documentStatus[0].status) {
      params.documentStatus = data.documentStatus[0].status;
    }

    const dataDocumentTypeLenght = get(data, 'documentType.length', 0);
    if (dataDocumentTypeLenght && data.documentType[0].type) {
      params.documentType = data.documentType[0].type;
    }

    DocumentService.getList(params).then(answer => {
      console.log('document list', answer.data);
      dispatch(ParticipantDocumentsLoaded(answer.data));
    });
  };
}

function WarehouseMounted(inn) {
  return dispatch => {

    dispatch(startPreloader());


    const dateFrom = `${moment('2017-01-01 00:00:00').format(
      'YYYY-MM-DDTHH:mm:ss'
    )}Z`;
    const dateTo = `${moment().format('YYYY-MM-DDTHH:mm:ss')}Z`;



    ParticipantService.getWarehouse({
      inn,
      limit: 10,
      page: 0,
      dateFrom,
      dateTo,
    }).then(answer => {
      console.log('warehouse loaded', answer.data);
      dispatch(ParticipantWarehouseLoaded(answer.data, 10, 0));
      dispatch(endPreloader());
    });
  };
}

function WarehouseFilterSubmitClicked(inn, data) {
  return dispatch => {
    console.log('submit clicked', data);

    const params = { inn, limit: 10, page: 0 };
    const prepared = FormSerializer.serialize(data);
    Object.keys(prepared).forEach(
      key => !prepared[key] && delete prepared[key]
    );

    const mergedData = Object.assign(params, prepared);

    const {
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      ...othersFilterProps
    } = mergedData;

    const dateFrom = filterDateFrom
      ? `${moment(filterDateFrom).format('YYYY-MM-DDTHH:mm:ss')}Z`
      : `${moment('2017-01-01 00:00:00').format('YYYY-MM-DDTHH:mm:ss')}Z`;

    const dateTo = filterDateTo
      ? `${moment(filterDateTo).format('YYYY-MM-DDTHH:mm:ss')}Z`
      : `${moment().format('YYYY-MM-DDTHH:mm:ss')}Z`;

    const updatedData = {
      dateFrom,
      dateTo,
      ...othersFilterProps,
    };

    dispatch({
      type: actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_APPLY_CLICKED,
      value: mergedData,
    });

    ParticipantService.getWarehouse(updatedData).then(answer => {
      console.log('warehouse loaded', answer.data);
      if (!answer.data.results) return;
      dispatch(
        ParticipantWarehouseLoaded(answer.data, params.limit, params.offset)
      );
    });
  };
}

function WarehouseSelectPage(incrementedPage) {
  return (dispatch, getState) => {
    if (!incrementedPage) return;
    const state = getState();

    const { filters } = selectors.ParticipantDetails.Warehouse(state);
    const { inn } = selectors.ParticipantDetails.selectedParticipant(state);

    const {
      participantInn,
      page,
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      ...othersFilterProps
    } = filters;

    const dateFrom = filterDateFrom
      ? `${moment(filterDateFrom).format('YYYY-MM-DDTHH:mm:ss')}Z`
      : `${moment('2017-01-01 00:00:00').format('YYYY-MM-DDTHH:mm:ss')}Z`;

    const dateTo = filterDateTo
      ? `${moment(filterDateTo).format('YYYY-MM-DDTHH:mm:ss')}Z`
      : `${moment().format('YYYY-MM-DDTHH:mm:ss')}Z`;

    const data = {
      inn,
      limit: 10,
      page: incrementedPage - 1,
      dateFrom,
      dateTo,
      ...othersFilterProps,
    };
    console.log(data);

    ParticipantService.getWarehouse(data).then(answer => {
      if (!answer.data.results) return;
      dispatch(
        ParticipantWarehouseLoaded(answer.data, data.limit, data.offset)
      );
    });
  };
}

function ParticipantWarehouseLoaded(data, limit, offset) {
  return {
    type: actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_LOADED,
    value: { ...data, limit, offset },
  };
}

function ProducerFilterChanged(val) {
  return dispatch => {
    dispatch({
      type: actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_PRODUCER_CHANGED,
    });

    ParticipantService.searchByInput(val)
      .then(answer =>
        dispatch({
          type:
            actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_PRODUCER_LOADED,
          value: answer.data,
        })
      )
      .catch(err => console.warn('canceled or err', err));
  };
}

function downloadXls(inn) {
  return dispatch => {
    console.log('downloadXls', inn);
    ParticipantService.getXls(inn).then(answer =>
      saveAs(answer.data, `${inn}.xls`)
    );
  };
}

const CISList = {
  ...getListActions({
    actionNamespace: CISListConfig.actionNamespace,
    selectorNamespace: CISListConfig.selectorNamespace,
    getList: CISService.getList,
    pageFormatter: getLimitOffsetFormatter(CISListConfig.limit),
    pageSerializer: getUrlPage,
    filterApplyFilterParamsSerializer: ParticipantDetailsCisListFilterParamSerializer,
    filterApplyRequestParamsSerializer: ParticipantDetailsCisListRequestParamSerializer,
    onFilterApplyWithoutSerializing: data =>
      modifyURLByRawDataAndFiltersConfig(
        Object.assign(data, { page: [1] }),
        CISList.filters
      ),
    onPageSelect: pageNumber => {
      extendURLByKeyValueList({ page: pageNumber });
    },
  }),
};

const DocumentList = {
  ...getPaginationListActions({
    actionNamespace: DocumentListConfig.actionNamespace,
    selectorNamespace: DocumentListConfig.selectorNamespace,
    getList: DocumentService.getListWithoutLk,
    pageFormatter: getLimitOffsetFormatter(DocumentListConfig.limit),
    filterApplyFilterParamsSerializer: defaultDateFilterParamSerializer,
    filterApplyRequestParamsSerializer: defaultDateFilterParamSerializer,
  }),
};

export {
  ParticipantDetailsMounted,
  ParticipantDetailsLoaded,
  DocumentsMounted,
  DocumentFilterSubmitClicked,
  WarehouseFilterSubmitClicked,
  WarehouseMounted,
  WarehouseSelectPage,
  ProducerFilterChanged,
  downloadXls,
  CISList,
  DocumentList,
};
