import styled from 'styled-components';


const Header = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #434244;
  
  margin-top: 28px; 
  margin-bottom: 12px;
  margin-left: 32px;
`;

export default Header;
