import axios from 'axios';
import { find } from 'lodash/fp';
import { notify } from 'react-notify-toast';
import { instance } from './instance';
import { CadesUtil } from '../utils/CadesUtil';
import {getCertSerial} from '../utils/cookieHelpers';

const { CancelToken } = axios;

class apiUtils {
  static searchProductCancel;

  static searchByInputHelper({ url }) {
    if (apiUtils.searchProductCancel) apiUtils.searchProductCancel();

    return instance.request({
      url,
      method: 'get',
      cancelToken: new CancelToken(c => {
        apiUtils.searchProductCancel = c;
      }),
    });
  }
}

const readFile = file => {
  let type = 'XLS';

  if (file.name.endsWith('xml')) type = 'XML';
  else if (file.name.endsWith('csv')) type = 'CSV';

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () =>
      resolve({
        data: fileReader.result.split(',').pop(),
        type,
      });

    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
};

const getCertBySerial = certs => {
  const serial = getCertSerial();
  const cert = find(i => i.info[3] === serial, certs);

  try {
    return cert.certificate;
  } catch (e) {
    throw new Error('cert not found');
  }
};

export const getCertBySerialFromCerts = (serial, certs) => {
  const cert = find(i => i.info[3] === serial, certs);

  try {
    return cert;
  } catch (e) {
    throw new Error('cert not found');
  }
};

const prepareError = error => {
  if (error.response) {
    return error.response.data;
  }

  if (error.message) {
    const message = CadesUtil.prepareError(error);
    if (message) notify.show(message, 'error', 3000);
    return [];
  }

  return [{ message: 'Сервер недоступен. Попробуйте позднее.' }];
};

const getSignedData = (cert, data, files) => {
  if (!files || !files.length) {
    const convertedData = CadesUtil.Base64.encode(JSON.stringify(data));
    return CadesUtil.signMessage(convertedData, cert, true, true).then(
      signed => ({
        signature: signed,
        product_document: convertedData,
        document_format: 'MANUAL',
      }),
    );
  }

  let fileType = null;
  let fileContent = null;

  return readFile(files[0])
    .then(file => {
      fileType = file.type;
      fileContent = file.data;
      return CadesUtil.signMessage(fileContent, cert, true, true);
    })
    .then(signed => ({
      signature: signed,
      product_document: fileContent,
      document_format: fileType,
    }));
};

const getSignedAndKey = (data, cert) =>
  CadesUtil.signMessage(data, cert, true, true);

const subscribeDocument = (documentInfo, testDocument) => {
  const convertedData = testDocument
    ? CadesUtil.Base64.encode(documentInfo)
    : CadesUtil.Base64.encode(JSON.stringify(documentInfo));

  return CadesUtil.getFinalCertsArray()
    .then(certs => getCertBySerial(certs))
    .then(cert => getSignedAndKey(convertedData, cert))
    .then(signed => ({
      document: convertedData,
      signature: signed,
      document_format: 'MANUAL',
    }));
};

export {
  instance,
  apiUtils,
  readFile,
  getCertBySerial,
  prepareError,
  getSignedData,
  subscribeDocument,
};
