import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { getActions, reducer } from './state';
import { getValidDefaultSelectValues } from './utils';
import { Label } from './Label';
import { HiddenSelect } from './HiddenSelect';
import { SelectInput } from './SelectInput';
import { Options } from './Options';
import { ON_MOUSE_OUT_TIMEOUT_MS } from './constants';
import { Translate } from '../Translate/Translate';

export const MultiSelectWithSearch = withTheme(
  ({
    disabled,
    theme,
    isError,
    inline,
    placeholder,
    defaultSelectValue: defaultSelectValues,
    options,
    isLoading,
    savePlaceholder,
    noValuesText,
    name,
    onSelect,
    onSelectRef,
  }) => {
    const initialState = {
      isOpen: false,
      isFocused: false,
      selectValues: [],
      inputValue: '',
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const { isOpen, isFocused, selectValues, inputValue } = state;
    const actions = getActions(dispatch);
    const blurTimeout = useRef(null);
    const labelRef = useRef(null);

    const handleFocus = useCallback(() => {
      actions.openOptionsPanel();
    }, []);

    const onClear = useCallback(() => {
      actions.clearSelectValue();
      onSelect([]);
    }, []);

    const handleInputValueChange = useCallback(e => {
      actions.setInputValue(e.target.value);
      if (!isOpen) {
        actions.openOptionsPanel();
      }
    }, []);

    const handleOptionClick = useCallback(option => {
      actions.toggleSelectValues(option);
    }, []);

    const setBlurTimeout = () => {
      blurTimeout.current = setTimeout(() => {
        actions.closeOptionsPanel(true);
      }, ON_MOUSE_OUT_TIMEOUT_MS);
    };

    const resetBlurTimeout = () => {
      if (blurTimeout.current) {
        clearTimeout(blurTimeout.current);
      }
    };

    const handleMouseOutFromSelect = useCallback(() => {
      resetBlurTimeout();
      setBlurTimeout();
    }, []);

    const handleMouseMoveOnSelect = useCallback(() => {
      resetBlurTimeout();
    }, []);

    const handleClickOutSelect = e => {
      resetBlurTimeout();
      const label = labelRef.current;
      if (label && !label.contains(e.target)) {
        actions.closeOptionsPanel();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutSelect);
      return () => {
        document.removeEventListener('mousedown', handleClickOutSelect);
        resetBlurTimeout();
      };
    }, []);

    useEffect(() => {
      onSelectRef({
        clear: onClear,
      });
    }, []);

    useEffect(() => {
      if (!!options.length && !!defaultSelectValues[0]) {
        actions.setDefaultSelectValues(
          getValidDefaultSelectValues(defaultSelectValues, options),
        );
      }
    }, [options, defaultSelectValues]);

    useEffect(() => {
      onSelect(selectValues);
    }, [selectValues]);

    return (
      <Label
        theme={theme}
        disabled={disabled}
        isError={isError}
        isOpen={isOpen}
        inline={inline}
        onMouseMove={handleMouseMoveOnSelect}
        onMouseOut={handleMouseOutFromSelect}
        innerRef={labelRef}
      >
        <SelectInput
          onFocus={handleFocus}
          selectValues={selectValues}
          placeholder={placeholder}
          theme={theme}
          isFocused={isFocused}
          disabled={disabled}
          isError={isError}
          savePlaceholder={savePlaceholder}
          onClear={onClear}
          inputValue={inputValue}
          onChange={handleInputValueChange}
        />

        <HiddenSelect
          name={name}
          options={options}
          selectValues={selectValues}
        />
        <Options
          theme={theme}
          isLoading={isLoading}
          options={options}
          isOpen={isOpen}
          noValuesText={noValuesText}
          inputValue={inputValue}
          selectValues={selectValues}
          onOptionClick={handleOptionClick}
          isError={isError}
          disabled={disabled}
        />
      </Label>
    );
  },
);

MultiSelectWithSearch.defaultProps = {
  defaultSelectValue: [],
  options: [],
  disabled: false,
  isLoading: false,
  placeholder: '',
  inline: false,
  savePlaceholder: false,
  noValuesText: Translate('<пусто>'),
  onRef: () => {},
  onSelect: () => {},
};

MultiSelectWithSearch.displayName = 'MultiSelect';

MultiSelectWithSearch.propTypes = {
  defaultSelectValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  savePlaceholder: PropTypes.bool,
  noValuesText: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectRef: PropTypes.func,
};
