import {actionTypes} from '../../constants/';

export function selectedDocument(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.DOCUMENT_DETAILS.DOCUMENT_DETAILS_LOADED:
      return action.value;
    case actionTypes.DOCUMENT_DETAILS.CLEAR:
      return null;
    default:
      return state;
  }
}
