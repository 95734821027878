import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import Button from './Button';


const ContinueButton = (props) => {
  const disabled = useCallback((name, values, errors) => !isEmpty(errors), []);

  return (
    <Button name="__form_continue" marginBottom="0" disabled={disabled} {...props} />
  );
};

export default ContinueButton;
