const initialState = {
  labelingCenter: [
    {
      id: 1,
      title: 'ООО Центр этикетирования и маркировки',
      name: 'ООО Центр этикетирования и маркировки',
      inn: '123123123123'
    },
  ],
  contract: [
    {
      id: 1,
      title: 'Договор на услуги ЦЭМ № 33445566',
      date: '2018.07.21',
      number: '321321',
      inn: '123123123123'
    },
  ],
  address: [
    { id: 1, title: 'г. Москва, ' },
  ],
  contacts: [
    { id: 1, title: 'Иванов Иван Иванович', phone: '+7 911 5555555', email: 'email@mail.com' },
    { id: 2, title: 'Петров Петр Петрович', phone: '+7 911 3333333', email: 'pochta@pochta.com' },
  ],
  operatorContracts: [
    { id: 1, title: 'Договор с оператором 1', date: '2018.07.21', number: '123123' },
    { id: 2, title: 'Договор с оператором 2', date: '2018.07.22', number: '234234' },
  ]
};

export function options(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}