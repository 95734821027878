import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({
  modalContent: {
    display:'flex',
    flexDirection: 'column',
    padding: theme.modal.padding,
    marginBottom: '20px'
  },
  notificationList: {
    fontWeight: '600'
  },
  listWrapper: {
    width: '380px',
    height: '320px',
    padding: '10px',
    marginBottom:'40px',
    
    overflowY: 'scroll',

    border: '1px solid #D9D9D9',
    borderRadius: '3px',
  },
  footer: {
    display:'flex'
  }
}));