import React from 'react';
import { addSpacesBetweenThousands } from '../../utils';
import { InlineWrap } from '@crpt/react-page-layout';
import { TableHeading, TableParam } from './styled';
import {statuses} from '../Select/CISStatusSelect';
import {cisPackageTypes} from '../Select/CISPackageSelect';
import {CISPackageSelect, CISStatusSelect, ProductSelect} from '../Select';
import {Translate} from '../../common_components/Translate/Translate';

export const colors = {
  cnt_emitted: '#035ECC',
  cnt_applied: '#0378CC',
  cnt_sold: '#1A99F4',
  cnt_not_sold: '#7AC5FC',
  cnt_withdrawn: '#BFBFBF'
};

const ALLOWED_STATUSES = ['EMITTED', 'APPLIED', 'INTRODUCED', 'WRITTEN_OFF', 'WITHDRAWN', 'UNDEFINED', 'INTRODUCED_RETURNED'];

export const filters = [
  {
    showDownloadListButton: false,
    popupMenu: false
  },
  [
    {
      component: ProductSelect,
      props: {
        inline: false,
        name: 'gtin',
        placeholder: Translate('Название продукта'),
        savePlaceholder: true,
      }
    },
  ]
];

export const options = [
  {
    accessor: d => d,
    id: 'name'
  },
  {
    accessor: d => d,
    id: 'shippedGoods',
    render: props => {
      const shippedGoods = props.options.shippedGoods._original.shippedGoods;
      const unshippedGoods =
        props.options.shippedGoods._original.unshippedGoods;
      return (
        <InlineWrap flex>
          <TableParam>{addSpacesBetweenThousands(shippedGoods)}</TableParam>
          <TableParam>{addSpacesBetweenThousands(unshippedGoods)}</TableParam>
        </InlineWrap>
      );
    }
  },
];
