import PropTypes from 'prop-types';

export const ProductionLineListItemType = PropTypes.shape({
  productionAddress: PropTypes.string,
  productionCountryName: PropTypes.string,
  productionCountryNumericCode: PropTypes.string,
  productionKpp: PropTypes.string,
  productionLines: PropTypes.arrayOf(PropTypes.string),
  productionPlaceName: PropTypes.string,
});

export const ProductionLineType = PropTypes.shape({
  last: PropTypes.boolean,
  result: PropTypes.arrayOf(ProductionLineListItemType),
  total: PropTypes.number,
});

export const CountriesType = {
  id: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  selected: PropTypes.bool,
};
