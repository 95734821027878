import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';

export default {
  title: Translate('Описание показателей:'),
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: Translate('Номер показателя'),
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: Translate('Тип агрегации'),
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: Translate('Описание'),
          accessor: 'col3',
          minWidth: 400,
        },
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('ИНН и наименование производителя товара'),
        },
        {
          'col1': [3],
          'col2': <ul>
            <li>{Translate('Детализация по датам')}</li>
          </ul>,
          'col3': Translate('Дата эмиссии/нанесения кодов в выбранном периоде'),
        },
        {
          'col1': [4, 5],
          'col2': <ul>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Уникальный код и наименование товара'),
        },
        {
          'col1': [6],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Тип упаковки товара'),
        },
        {
          'col1': [7],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Количество эмитированных кодов за текущий период'),
        },
        {
          'col1': [8],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Количество нанесённых кодов за текущий период'),
        },
        {
          'col1': [9],
          'col2': <ul>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
          </ul>,
          'col3': Translate('Количество нанесённых и оплаченных кодов за текущий период'),
        },
        {
          'col1': [10],
          'col2': <ul>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
          </ul>,
          'col3': Translate('Количество кодов, нанесённых в предыдущем периоде и оплаченных в текущем периоде'),
        },
        {
          'col1': [11],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Количество кодов, нанесённых и не оплаченных на конец периода'),
        },
        {
          'col1': [12],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по часам')}</li>
            <li>{Translate('Детализация по датам')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': <div>{Translate('(Количество нанесённых и оплаченных кодов за текущий период + Количество кодов, нанесённых в предыдущем периоде и оплаченных в текущем периоде)')} <strong>×</strong> 50 копеек</div>,
        },
        {
          'col1': [13],
          'col2': <ul>
            <li>{Translate('Сводный отчёт')}</li>
            <li>{Translate('Детализация по товару')}</li>
          </ul>,
          'col3': Translate('Количество кодов, оплаченных за текущий период'),
        },
        {
          'col1': [14],
          'col2': <ul>
            <li>{Translate('Детализация по часам')}</li>
          </ul>,
          'col3': Translate('Час эмиссии/нанесения кодов в выбранном периоде'),
        },
      ]
    }
  ]
}
