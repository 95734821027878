import { find } from 'lodash/fp';
import { get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { CadesUtil } from '../../../utils/CadesUtil';
import { prepareCertificates } from '../../Cades/Cades.utils';
import * as actions from './CurrentSignatureDescription.action';
import { getCertSerial } from '../../../utils/cookieHelpers';
import i18next from 'i18next';

function* reloadCertificateSaga() {
  const certificates = yield call(CadesUtil.getFinalCertsArray);

  const serial = yield call(getCertSerial);

  let certificate = {};

  try {
    certificate = find(item => item.info[3] === serial, certificates);
  } catch (e) {
    throw new Error(i18next.t('Сертификат не найден'));
  }

  const certificateInfo = (yield call(prepareCertificates, [certificate]))[0];
  const signedByFullInfo = get(certificateInfo, 'info[0]', '');
  const signedBy = yield call(
    CadesUtil.getByTagFromSubject,
    signedByFullInfo,
    'CN',
  );
  const surname = yield call(
    CadesUtil.getByTagFromSubject,
    signedByFullInfo,
    'SN',
  );
  const fullName = yield call(
    CadesUtil.getByTagFromSubject,
    signedByFullInfo,
    'G',
  );

  let inn = yield call(CadesUtil.getByTagFromSubject, signedByFullInfo, 'INN');
  if (!inn)
    inn = yield call(CadesUtil.getByTagFromSubject, signedByFullInfo, 'ИНН');

  let name;
  let patronymic;
  try {
    [name, patronymic] = fullName.split(' ');
  } catch (e) {
    console.log('fullName error', fullName);
  }

  yield put(
    actions.reloadedCertificateInformation({
      serial,
      name,
      patronymic,
      surname,
      fullName: certificateInfo.name,
      organisation: certificateInfo.source,
      date: certificateInfo.date,
      inn,
      signedBy,
    }),
  );
}

function* clearCertificateSaga(action) {
  const { payload } = action;
  const { pathname } = payload;

  if (pathname === '/login-kep') {
    yield put(actions.reloadedCertificateInformation({}));
  }
}

export default function* watch() {
  yield takeEvery(actions.reloadCertificateInformation, reloadCertificateSaga);
  yield takeEvery(LOCATION_CHANGE, clearCertificateSaga);
}
