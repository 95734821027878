import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import LightTheme from '../../../../common_components/_MaterialUi/LightTheme';
import * as previewActions from '../Document/Preview/ducks/Preview.actions';
import * as actions from './ducks/ActDocument.actions';
import * as selectors from './ducks/ActDocument.selectors';
import * as documentDetailsActions from '../../../../components/DocumentDetails/ducks/DocumentDetails.actions';
import * as selectorsDocumentDetails from '../../../../components/DocumentDetails/ducks/DocumentDetails.selectors';
import * as discrepancySelectors from '../DiscrepancyNotice/ducks/DiscrepancyNotice.selectors';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import * as preloaderSelectors from '../../../../common_components/Preloader/ducks/Preloader.selectors';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import ActDocumentView from './ActDocument.view';
import { breadcrumbs as breadcrumbsCreate, steps } from './ActDocument.constants';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import * as actActions from './ducks/ActDocument.actions';
import * as reconciliationActions from './Reconciliation/ducks/Reconciliation.actions';
import * as reconciliationSelectors from './Reconciliation/ducks/Reconciliation.selectors';
import { Translate } from '../../../../common_components/Translate/Translate';
import { documentStatusesNames, documentTypeNames, documentTypes } from '../../../../constants';


class ActDocument extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    onMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    setPreview: PropTypes.func.isRequired,
    openPreview: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    countryCode: PropTypes.string,
    direction: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      // step: steps.goods
      step: steps.info,
      isDraft: false,
      isCorrection: false, // переменая обозначающая этап коррект. акта isFixed - созданный коррект акт
      editedActData: null,
    };
  }

  componentDidMount() {
    const { match, onMount, onLoadDraft, history, correction, onMountSimpleAct, mode } = this.props;
    const { id } = match.params;

    if (mode === 'editedAct') {
      const { id, key } = match.params;
      onMountSimpleAct({ id: key, direction: id });
    } else {
      const isCorrection = id === 'correction';
      if (isCorrection && !correction) {
        history.push(`/documents/outcoming/`);
      } else {
        this.setState({ isCorrection });
      }
      if (/draft-/.test(id)) {
        this.setState({ isDraft: true }, () =>
          onLoadDraft(id.replace(/draft-/, '')),
        );
        // onLoadDraft(id.replace(/draft-/, ''));
        return;
      }
      if (id !== 'create' && id !== 'correction') {
        onMount(encodeURIComponent(id));
      }
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  onSubmit = values => {
    // console.log('onSubmit', values);
  };

  onSaveDraft = values => {
    const { match, onSaveDraft } = this.props;
    onSaveDraft({ match, values });
  };

  onSetStep = step => {
    this.setState({ step });
  };

  breadCrumbTitle = () => {
    const { selectedDocument, match, mode } = this.props;
    const documentStatusName = documentStatusesNames[selectedDocument.status] || '';
    const documentTypeName = documentTypeNames[selectedDocument.type] || '—';
    const { key, id } = match.params;
    const decodedId = mode === 'editedAct' ? decodeURIComponent(key) :  decodeURIComponent(id);
    switch (selectedDocument.type) {
      case documentTypes.LP_FTS_INTRODUCE_RESPONSE:
        return (
          <React.Fragment>
            {documentTypeName ? Translate(documentTypeName) : ''} {decodedId}
          </React.Fragment>
        );
      case documentTypes.MTTPS_90:
      case documentTypes.KZ_INTRODUCE_GOODS:
        return <React.Fragment>{Translate(documentTypeName)}</React.Fragment>;
      case documentTypes.KZ_UPD:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {decodedId.replace('EEU_ACT_', '')}
          </React.Fragment>
        );
      case documentTypes[1200]:
      case documentTypes[1300]:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {selectedDocument.number}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {documentStatusName ? Translate(documentStatusName) : ''}&nbsp;
            {documentTypeName ? Translate(documentTypeName) : ''}&nbsp;
            {decodedId}
          </React.Fragment>
        );
    }
  };

  render() {
    const {
      loaded,
      isLoading,
      data,
      match,
      countryCode,
      correction,
      mode,
      dataSimpleAct,
      unsubscribe,
    } = this.props;
    const { id, key } = match.params;
    const { step, isDraft } = this.state;

    const breadcrumbs = [
      {
        href: '/documents/list',
        title: Translate('Документы'),
        id: 1,
      },
      {
        href: `/documents/list/${key}`,
        title: <span style={{ fontWeight: 600 }}>{this.breadCrumbTitle()}</span>,
        id: 2,
      },
    ];

    return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
      >
        <ActDocumentView
          data={
            mode === 'editedAct'
              ? dataSimpleAct
              : id === 'create'
              ? {}
              : id === 'correction'
                ? correction
                : data
          }
          direction={id}
          id={key}
          step={step}
          unsubscribe={unsubscribe}
          mode={mode}
          loaded={loaded}
          isCreate={id === 'create'}
          isCorrection={id === 'correction'}
          breadcrumbs={mode === 'editedAct' ? breadcrumbs : breadcrumbsCreate}
          onSubmit={this.onSubmit}
          onSetStep={this.onSetStep}
          onPreview={this.onPreview}
          onSaveDraft={this.onSaveDraft}
          isLoading={isLoading}
          countryCode={countryCode}
          isDraft={isDraft}
        />
      </MuiThemeProvider>
    );
  }
}

const mapState = state => ({
  data: {
    ...selectors.data(state),
  },
  correction: discrepancySelectors.correction(state),
  inn: authSelectors.userInn(state),
  loaded: selectors.loaded(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: state.config.countryCode,
  // Все, что ниже для уже созданного акта
  dataSimpleAct: selectorsDocumentDetails.getData(state),
  reconciliationDraft: reconciliationSelectors.draft(state),
  selectedDocument: selectorsDocumentDetails.selectedDocument(state),
});

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onMount: id => dispatch(actions.mounted(id)),
  onSubmit: data => dispatch(actions.submit(data)),
  onUnmount: () => dispatch(actions.unmounted()),
  openPreview: data => dispatch(previewActions.open(data)),
  setPreview: data => dispatch(previewActions.setContent(data)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  // Все, что ниже для уже созданного акта
  onMountSimpleAct: id => dispatch(documentDetailsActions.mountAct(id)),
  unsubscribe: id => dispatch(actActions.refundDocument(id)),
  getReconciliationDraft: data =>
    dispatch(reconciliationActions.getReconciliationDraft(data)),
});

export default connect(mapState, mapDispatch)(ActDocument);
