import { get } from 'lodash';
import { Item } from './Item';
import { ReceiptService } from '../../../services/ReceiptService';
import i18next from 'i18next';

export class ReceiptItem extends Item {
  icon = 'receipt';

  title = 'Чеки';

  strictTest = () => window.env.ELK_COUNTRY === 'UZ' ?
    Boolean(this.value.match(/^(UZ|NA|VG)[\da-zA-Z~!"%&'`*+\-./_,:;=<>?’\]]{9,40}$/)) :
    Boolean(this.value.match(/^\d{9,40}$/));


  onClick = async () => {
    const strict = this.strictTest();
    if (!strict) {
      this.onFailure(
        i18next.t('Неверно указан формат ID чека')
      );
      return;
    }

    try {
      this.onFailure(i18next.t('Идет поиск чеков...'));
      const answer = await ReceiptService.searchReceipt(this.value);
      const results = get(answer, 'data');
      const id = results.documentId;

      if (id === this.value) {
        this.history.push(`/documents/receipt/${id}`);

        this.onSuccess();
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
