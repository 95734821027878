import {combineReducers} from 'redux';

import {data} from './data';
import {fields} from './fields';


export default combineReducers({
  data,
  fields
});
