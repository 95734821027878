import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import { HintedAddressInput, ADDRESS_HINT_REQUESTER_FULL } from '@crpt/react-dadata';
import { Wrap } from '../_refactor/Fields.styled';
import * as appSelectors from '../../../App/App.selectors';


const emptyQuery = {
  fullAddress: '',
  postalCode: '',
  regionCode: '',
  area: '',
  city: '',
  settlement: '',
  street: '',
  house: '',
  block: '',
};

const PureHintedAddressInput = memo(HintedAddressInput);


class HintedAddressInputAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    dadataSettings: PropTypes.shape({}).isRequired,
    isError: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onChange: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
  };

  onChangeTimeout;

  onChange = (val) => {
    const { fieldInput, onChange } = this.props;

    if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(() => fieldInput && fieldInput.onChange(val), 100);
    if (onChange) onChange(val);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (isError && fieldMeta && fieldMeta.dirtySinceLastSubmit && !fieldMeta.error) return false;
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total, fieldMeta } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return (index && (total > 1)) ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput = {},
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dadataSettings,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;

    const value = isUndefined(fieldInput.value) ? '' : `${fieldInput.value}`;

    return (
      <Wrap onBlur={fieldInput.onBlur} marginBottom={marginBottom}>
        <PureHintedAddressInput
          apiKey={dadataSettings.apiKey}
          apiURL={dadataSettings.apiEmailURL}
          hideOptionsPanel={!dadataSettings}
          type={ADDRESS_HINT_REQUESTER_FULL}
          query={emptyQuery}
          savePlaceholder
          filterDisabled
          {...passProps}
          onChange={this.onChange}
          isError={this.isError()}
          placeholder={this.getPlaceholder()}
          defaultText={value}
        />
      </Wrap>
    );
  }
}

const mapState = state => ({
  dadataSettings: appSelectors.config(state).dadataSettings,
});


export default connect(mapState)(HintedAddressInputAdapter);
