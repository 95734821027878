import React from 'react';
import { Link } from 'src/fragments/Link';
import { InfoFields } from '../../Document/Document.styled';
import PropTypes from 'prop-types';
import CheckboxField from '../../../../../common_components/Form/MaterialFields/Checkbox/Checkbox';
import { Translate } from '../../../../../common_components/Translate/Translate';

const ChangedStatus = ({ formValues }) => {
  const { isChanged, parent_id } = formValues;

  return (
    <React.Fragment>
      <InfoFields fullMinWidth style={{ marginBottom: '0px' }}>
        <div
          style={{
            flexBasis: '30%',
          }}
        >
          <CheckboxField
            disabled
            name="isChanged"
            placeholder={Translate('Исправленный')}
          />
        </div>

        <div
          style={{
            lineHeight: '1.5rem',
            flexBasis: '70%',
            visibility: !isChanged && 'hidden',
          }}
        >
          <Link
            to={''}
            onClick={e => {
              e.preventDefault();
              window.open(`/documents/list/eaes-out/${parent_id}`);
            }}
          >
            Исходный
          </Link>
        </div>
      </InfoFields>
    </React.Fragment>
  );
};

ChangedStatus.propTypes = {
  formValues: PropTypes.shape({}).isRequired,
};

export default ChangedStatus;
