import { get } from 'lodash';
import { createSelector } from 'reselect';
import { selector } from 'src/utils/selector';

const getCommon = state => get(state, 'COMMON');

export const documentsResults = state =>  selector(state, 'GrayArea.documentsResults');
export const error = state =>  selector(state, 'GrayArea.error');
export const loaded = state =>  selector(state, 'GrayArea.loaded');
export const total = state =>  selector(state, 'GrayArea.total');
export const page = state => selector(state, 'GrayArea.page');
export const loadingCreateDocument = state =>  selector(state, 'GrayArea.loadingCreateDocument');

export const cert = createSelector(getCommon, common => get(common, 'Auth.cert'));
