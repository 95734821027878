import React from 'react';
import {reportStatusEnum} from '../constants'
import {DownloadLink} from '../DownloadLink';

export const parseFilterFromUrlStr = str => {
  const [id, param] = str.split('=');
  if (id === 'page') return { page: JSON.parse(decodeURIComponent(param)) };
  return {
    id,
    value: JSON.parse(decodeURIComponent(param), (key, value) => {
      return (key && ['startDate', 'endDate', 'date'].includes(key))
        ? new Date(value)
        : value;
    }),
  };
};

export const getLinkValue = (documentInfo, onDownload) => {
  switch(documentInfo.reportStatus) {
    case reportStatusEnum.READY:
      return (
        <DownloadLink
          reportLink={documentInfo.reportLink}
          downloadFile={onDownload}
        />
      );
    case reportStatusEnum.DELETED:
      return 'Удален по истичении срока';
      
    case reportStatusEnum.PREPARING:
      return 'В процессе формирования...';
      
    case reportStatusEnum.ERROR:
      return 'Произошла ошибка...';
      
    default:
      return null;
  }
};
