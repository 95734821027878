import styled from 'styled-components';

const ItemIconWrapper = styled.div`
  font-size: 24px;
  padding: 0 13px 0 20px;
  display: flex;
  align-items: center;
`;

export default ItemIconWrapper;
