import { css } from 'styled-components';

const TableLoading = {
  style: css`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all .3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    > div {
      position:absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      transform: translateY(-52%);
      transition: all .3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }
    
    &.-active {
      opacity: 1;
      z-index: 2;
      pointer-events: all;
      > div {
        transform: translateY(50%);
      }
    }
  `,
};

export default TableLoading;
