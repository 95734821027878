import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';

import * as grayAreaActions from '../ducks/GrayArea.actions';
import * as grayAreaSelectors from '../ducks/GrayArea.selectors';
import { initialState, rootReducer, productionLinesHelper } from './GrayAreaCreate.reducers';
import GrayAreaCreateView from './GrayAreaCreate.view';

const GrayAreaCreate = ({ createDocument, history, loadingCreateDocument }) => {
  const [step, setStep] = useState(0);

  const onChangeStep = useCallback((_event, value) => {
    setStep(value);
  }, []);

  const [state, dispatch] = useReducer(rootReducer, initialState);

  const onCancel = useCallback(() => history.push('/gray-area'), []);

  const onCreateDocument = () => {
    createDocument(state);
  };

  const isNextStepDisabled = useMemo(() => {
    switch (true) {
      case step === 0 && state.information.hasErrors:
      case step === 1 && Boolean(productionLinesHelper.validator(state.productionLines)):
        return true
      default:
        return false
    }
  }, [state, step]);

  return (
    <GrayAreaCreateView
      onCreateDocument={onCreateDocument}
      onCancel={onCancel}
      onChangeStep={onChangeStep}
      step={step}
      dispatch={dispatch}
      state={state}
      loadingCreateDocument={loadingCreateDocument}
      isNextStepDisabled={isNextStepDisabled}
    />
  );
};

const mapStateToProps = state => ({
  loadingCreateDocument: grayAreaSelectors.loadingCreateDocument(state),
});

const mapDispatchToProps = {
  createDocument: grayAreaActions.createDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrayAreaCreate);
