import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { get, intersection } from 'lodash';
import moment from 'moment';
import Statistic from './Statistic';
import StructureAndDynamic from './StructureAndDynamic';
import { Taxes } from './Taxes/Taxes';
import { Count, Header, Title, Wrap } from './styled';
import * as altPropTypes from './altPropTypes';
import { PARTICIPANT_TYPES } from '../../constants';
import { getOrgRoles } from '../../utils/userUtils';
import { Preloader } from '../../common_components/Preloader/Preloader';
import {Translate} from '../../common_components/Translate/Translate';

class AltScenarios extends PureComponent {
  constructor(props) {
    super(props);

    const orgRoles = getOrgRoles();
    const monthTo = moment();
    const monthFrom = moment(monthTo).add(-1, 'month');
    const initFilterParams = {
      dateFrom: monthFrom.format('DD.MM.YYYY'),
      dateTo: monthTo.format('DD.MM.YYYY'),
    };

    if (
      intersection(orgRoles, [
        PARTICIPANT_TYPES.OGV,
      ]).length
    ) {
      this.state = {isOGV: true, initFilterParams};
    } else {
      this.state = {isOGV: false, initFilterParams};
    }
  }

  static propTypes = {
    App: PropTypes.shape({
      config: PropTypes.shape({
        AltScenarios: PropTypes.object.isRequired
      }).isRequired
    }).isRequired,
    actions: PropTypes.shape({
      AltScenarios: PropTypes.shape({
        altScenariosMounted: PropTypes.func.isRequired,
        loadTypes: PropTypes.func.isRequired,
        loadStatistic: PropTypes.func.isRequired
      }).isRequired,
      Sidebar: PropTypes.shape({
        sidebarOpen: PropTypes.func.isRequired,
        sidebarClosing: PropTypes.func.isRequired
      }).isRequired
    }).isRequired,
    AltScenarios: PropTypes.shape({
      types: PropTypes.arrayOf(altPropTypes.scenarioType)
    }).isRequired
  };

  componentDidMount() {
    const { actions } = this.props;
    const { isOGV, initFilterParams } = this.state;
    actions.AltScenarios.altScenariosMounted({ isOGV, initFilterParams });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.AltScenarios.altScenariosUnMounted();
  }

  render() {
    const {
      App,
      actions,
      AltScenarios: {
        statistic,
        roundGraphStats,
        taxes,
        loaded,
      }
    } = this.props;
    const { theme } = App.config.AltScenarios;
    const { isOGV, initFilterParams } = this.state;

    const scenarios = Object.keys(statistic).map(type => {
      const title = get(
        statistic,
        `${type}.0.scenarioType.description`,
        'Неизвестно'
      );
      return { type, title, items: statistic[type] };
    });

    return (
      <ThemeProvider theme={theme}>
        <Wrap>
        <Preloader isOpen={!loaded} text={Translate("Загрузка данных")}>
          {!isOGV ? <Taxes taxes={taxes} /> : null}
          <Statistic roundGraphStats={roundGraphStats} />
          <StructureAndDynamic
            scenarios={scenarios}
            actions={actions}
            isOGV={isOGV}
            initFilterParams={initFilterParams}
          />
        </Preloader>

        </Wrap>
      </ThemeProvider>
    );
  }
}

export default AltScenarios;
