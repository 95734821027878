import styled from 'styled-components';


export const Wrap = styled.div`
  margin-bottom: ${props => props.noBottomMargin ? '' : '16px'};
  position: relative;
  width: ${props => props.inSign ? 'calc(100% + 1px)' : props.inline ? '' : '100%'};
  margin-right: ${props => props.inSign ? '-1px' : ''};
`;

export const CheckboxWrap = styled.div`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${props => props.width ? props.width : '100%'};
  margin-bottom: 16px;
  cursor: pointer;
`;

export const CheckboxIconWrap = styled.div`
  display: flex;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  min-height: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 1px solid #abadb5;
  margin-right: 16px;
  cursor: pointer;
`;

export const CheckboxTitle = styled.div`
  font-size: 16px;
  line-height: 1.57;
  color: #52535A;
  cursor: pointer;
`;

