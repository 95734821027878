import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { Plus } from '@crpt-ui/icons';
import {
  cisKey,
  goodsKey,
  steps,
  tabsKZ,
  tabsKG,
  contentBodyConfirm,
  importThirdKG,
} from '../EaesImportDocument.constants';
import {
  PageWrapper,
  styleCommandBar,
  FixedWrap,
  GoodsWrap,
  GoodsIconWrap,
  EmptyGoodsTextWrap,
  StepWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import {
  CodesRenderer,
  columnsKZ,
  columnsKG,
  columnsThirdCountryKG,
  actionSelectOptionsKZ,
} from './Goods.constants';
import GoodsCommandBar from './CommandBar';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import GoodCodesUploadModalContent from './GoodCodesUploadModalContent';
import GoodsEditProduct from './Goods.edit.product';
import CisListUploadModalContent from './CisListUploadModalContent/CisListUploadModalContent';
import GoodsIcon from './svg/GoodsIcon.svg';
import { Select as SelectRow } from '@crpt-ui/core';
import { connect } from 'react-redux';
import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import { withRouter } from 'react-router-dom';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import { GoodsFields } from './GoodsFields';
import { ContentErrorLoading } from '../../NotificationOfEntry/Goods/Goods.constants';
import { invalidCisKey } from '../../NotificationOfEntry/NotificationOfEntry.constants';
import * as actions from './ducks/Goods.actions';
import MenuButton from 'src/common_components/Buttons/MenuButton';
import ScanDialog from '../../../../../common_components/ScanDialog/ScanDialog';
import { uniqBy } from 'lodash';
import HandAddGoodModalContent from '../../Document/HandAddGoodModalContent/HandAddGoodModalContent';
import { Translate } from 'src/common_components/Translate/Translate';
import * as selectors from './ducks/Goods.selectors';
import { contentBodyEditGoods, contentBodyDeleteGoods } from '../../ActDocument/ActDocument.constants';

const keyField = 'cis';

class Goods extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    typeDoc: PropTypes.string,
    isDraft: PropTypes.bool,
    isCorrect: PropTypes.bool,
    goodsChanged: PropTypes.bool,
    onGetPackageCount: PropTypes.func.isRequired,
    savedProducts: PropTypes.array.isRequired,
    onFixProducts: PropTypes.func.isRequired,
    handleGoodsChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.instance = React.createRef();
    this.state = {
      selectedRows: [],
      isModalOpen: false,
      isOpenScanDialog: false,
      isOpenEditGoods: null,
      page: 0,
      topBarRight: [],
    };
  }

  setPage = page => {
    this.setState({ page });
  };

  onHandAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
    } = this.props;

    onSetContent(
      <HandAddGoodModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        formValues={formValues}
        target="eaes"
      />,
    );
    onOpenModal();
  };

  getTopBarRight = () => {
    return [];
  };

  onConvert = () => {
  };

  onAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
    } = this.props;

    onSetContent(
      <GoodCodesUploadModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        onConvert={this.onConvert}
        types=".xls, .xlsx"
        onAccept={file => console.log('onAccept called!', file)}
      />,
    );
    onOpenModal();
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.cis);
  };

  isNextStepDisabled = () => {
    const { show, formErrors, typeDoc } = this.props;
    if (!show) return true;

    const prepared = JSON.parse(JSON.stringify(formErrors));

    delete prepared[cisKey];
    delete prepared[goodsKey];

    const products = this.getProducts();

    const isFilledProducts = products => {
      for (let i = 0; i < products.length; i++) {
        if (
          !products[i].numberDT ||
          !products[i].country ||
          !products[i].cert_number ||
          !products[i].cert_date
        )
          return true;
      }
      return false;
    };

    let result = !!Object.keys(prepared).length || isEmpty(products);

    if (result) return true;
    else if (typeDoc != importThirdKG) return false;
    else if (typeDoc === importThirdKG) return isFilledProducts(products);
    // return !!Object.keys(prepared).length || isEmpty(products) //|| products.some(hasErrors);
  };

  getProducts = () => {
    const { formValues } = this.props;
    const products = formValues[goodsKey] || [];
    return products.map((item, idx) => ({ serialNumber: idx + 1, ...item }));
  };

  onRowSelect = selected => {
    const selectedKeys = Object.keys(selected);
    this.setState({
      selectedRows: selectedKeys,
    });
  };

  tabsCurrent = () => {
    const { show, countryCode } = this.props;
    const tabs = countryCode === 'KZ' ? tabsKZ : tabsKG;

    if (!show) return tabs;
    const { onSetStep } = this.props;
    const isNextStepDisabled = this.isNextStepDisabled();
    return tabs
      .map(tab =>
        tab.id === steps.goods
          ? tab
          : {
            ...tab,
            onClick: () => {
              onSetStep(tab.id);
            },
          },
      )
      .map(tab =>
        tab.id <= steps.goods || (tab.id === steps.cis && !isNextStepDisabled)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  onOpenCisModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
    } = this.props;

    onSetContent(
      <CisListUploadModalContent
        row={row}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        title={Translate('Коды маркировки')}
      />,
    );
    onOpenModal();
  };

  onCancelGoodsEdit = (value, index) => {
    const {
      formMutators: { update },
    } = this.props;

    update(goodsKey, index, value);
  };

  onOpenProductModal = selectedRows => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators: { update },
      formValues,
      countryCode,
      typeDoc,
    } = this.props;
    const columns =
      countryCode === 'KZ'
        ? columnsKZ
        : typeDoc === importThirdKG
        ? columnsThirdCountryKG
        : columnsKG;

    const __goodsOriginal = formValues[goodsKey]
      ? [...formValues[goodsKey]]
      : [];

    const onReset = () => {
      selectedRows.forEach(index =>
        update(goodsKey, index, __goodsOriginal[index] || ''),
      );
    };

    onSetContent(
      <GoodsEditProduct
        onCancel={this.onCancelGoodsEdit}
        onCloseModal={() => {
          this.setState(prev => ({ ...prev }));
          onCloseModal();
        }}
        onReset={onReset}
        preparedColumns={this.prepareColumns(columns)}
        selectedRows={selectedRows}
        title={Translate('Редактирование товаров')}
        typeDoc={typeDoc}
      />,
    );
    onOpenModal();
  };

  prepareColumns = columns =>
    columns.map(column =>
      column.id === 'codes'
        ? {
          ...column,
          accessor: row => (
            <CodesRenderer
              row={row}
              onClick={e => this.onOpenCisModal(e, row)}
            />
          ),
        }
        : column,
    );

  toggleDialog = () => this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  toggleScanDialog = () => this.setState(({ isOpenScanDialog }) => ({
    isOpenScanDialog: !isOpenScanDialog,
  }));

  onListRef = ref => {
    this.goodsList = ref;
  };

  onUpdate = () => {
    this.setState({ selectedRows: this.goodsList.state.selectedRows });
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  addScannedCises = scanned => {
    const { formMutators } = this.props;
    const oldProducts = this.getProducts();
    const result = [...oldProducts, ...scanned].map((item, idx) => ({
      idx: idx + 1,
      ...item,
    }));
    formMutators.updateField(goodsKey, uniqBy(result, 'cis'));
  };


  cancelEditGoods = () => {
    const { handleGoodsChange } = this.props;
    handleGoodsChange(false);
    this.setState({ isOpenEditGoods: null });
  };

  submitReCountGoods = () => {
    const { isOpenEditGoods } = this.state;
    switch (isOpenEditGoods) {
      case 'onAddGood':
        this.onAddGood();
        break;
      case 'onHandAddGood':
        this.onHandAddGood();
        break;
      case 'toggleScanDialog':
        this.toggleScanDialog();
        break;
      case 'actionWithTheProduct':
        this.actionWithTheProduct();
        break;
      default:
        console.error('неверный вариант');
        break;
    }
    this.setState({ isOpenEditGoods: null });
  };

  reCountGoods = (mode) => {
    const { handleGoodsChange } = this.props;
    switch (mode) {
      case 'onAddGood':
        this.setState({ isOpenEditGoods: 'onAddGood' }, () => handleGoodsChange(true));
        break;
      case 'onHandAddGood':
        this.setState({ isOpenEditGoods: 'onHandAddGood' }, () => handleGoodsChange(true));
        break;
      case 'toggleScanDialog':
        this.setState({ isOpenEditGoods: 'toggleScanDialog' }, () => handleGoodsChange(true));
        break;
      case 'actionWithTheProduct':
        this.setState({ isOpenEditGoods: 'actionWithTheProduct' }, () => handleGoodsChange(true));
        break;
      default:
        console.error('неверный вариант');
        break;
    }
  };

  actionWithTheProduct = () => {
    const selectedRows = [...this.state.selectedRows];
    const { formValues, formMutators } = this.props;
    let products = formValues[goodsKey] || [];
    products = products
      .filter((item) => !selectedRows.includes(item.cis))
      .map((item, idx) => ({  ...item, serialNumber: idx + 1 }));
    this.goodsList.clearSelected();
    if (this.goodsList.state.isAllSelected) this.goodsList.toggleAll();
    formMutators.updateField(goodsKey, products);
  };

  render() {
    const { isModalOpen, isOpenScanDialog, page, isOpenEditGoods } = this.state;
    const { show, countryCode, typeDoc, formValues, formMutators, isDraft } = this.props;
    const { goodsChanged, isCorrect } = this.props;
    // this.cisesUnitsInfo();
    const topBarRight = this.getTopBarRight();
    const products = this.getProducts();
    const columns = countryCode === 'KZ'
      ? columnsKZ
      : typeDoc === importThirdKG
        ? columnsThirdCountryKG
        : columnsKG;
    const preparedColumns = this.prepareColumns(columns);
    let isInvalidCisShow = !isEmpty(formValues.invalidCis);
    const invalidList = formValues.invalidCis
      ? formValues.invalidCis.map(item => <div>Код: {item}</div>)
      : '';
    let contentErrorLoading = (
      <ContentErrorLoading>
        {invalidList}
        {Translate('Загрузить другой файл?')}
      </ContentErrorLoading>
    );

    const cancelBtn = (
      <CommonActionButton
        onClick={this.toggleDialog}
        disabled={false}
      >
        {Translate('Отменить')}
      </CommonActionButton>
    );

    return (
      <PageWrapper hide={!show}>
        <ScanDialog
          open={isOpenScanDialog}
          onClose={this.toggleScanDialog}
          onAdd={this.addScannedCises}
          docType="IMPORT_TRANSIT"
        />
        <ConfirmModal
          isModalOpen={isOpenEditGoods}
          onClose={this.cancelEditGoods}
          onSubmit={this.submitReCountGoods}
          content={isOpenEditGoods === 'actionWithTheProduct' ? contentBodyDeleteGoods : contentBodyEditGoods}
          header={Translate('Предупреждение')}
          cancelButtonText="Нет"
          submitButtonText="Да"
          notCancelable
        />
        <ConfirmModal
          isModalOpen={isInvalidCisShow}
          onClose={() => formMutators.updateField(invalidCisKey, [])}
          onSubmit={() => {
            this.onAddGood();
            formMutators.updateField(invalidCisKey, []);
          }}
          content={contentErrorLoading}
          header={Translate('Ошибка')}
          cancelButtonText={Translate('Отмена')}
          submitButtonText={Translate('Загрузить файл')}
          color={'error'}
        />
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.goods}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <ConfirmModal
            isModalOpen={isModalOpen}
            onClose={this.toggleDialog}
            onSubmit={this.onCancel}
            content={contentBodyConfirm}
            header={Translate("Предупреждение")}
            cancelButtonText={Translate("Нет")}
            submitButtonText={Translate("Да")}
            notCancelable
          />
          {countryCode === 'KZ' && <GoodsFields formValues={formValues}/>}
          <GoodsCommandBar
            left={[
              <MenuButton
                menu={[
                  {
                    title: Translate('Загрузить из файла'),
                    onClick: formValues.status === 19 && !goodsChanged ? () => this.reCountGoods('onAddGood') : this.onAddGood,
                  },
                  {
                    title: Translate('Выбрать из списка'),
                    onClick: formValues.status === 19 && !goodsChanged ? () => this.reCountGoods('onHandAddGood') : this.onHandAddGood,
                  },
                  {
                    title: Translate('Сканировать коды'),
                    onClick: formValues.status === 19 && !goodsChanged ? () => this.reCountGoods('toggleScanDialog') : this.toggleScanDialog,
                  },
                ]}
                disabled={!isCorrect && !isDraft && formValues.status !== null && formValues.status !== 19 && formValues.type !== 1300}
                positionY="bottom"
                variant="contained"
                color="primary"
              >
                <Plus/>
                <sapn style={{ fontSize: '12px', fontWeight: '700', margin: '8px' }}>
                  {Translate('ДОБАВИТЬ ТОВАР')}
                </sapn>
              </MenuButton>,
              ...countryCode === 'KZ' ? [
                <FixedWrap width={'260px'} stretch>
                  <SelectRow
                    options={actionSelectOptionsKZ}
                    label={Translate('Действие')}
                    onChange={formValues.status === 19 && !goodsChanged ? () => this.reCountGoods('actionWithTheProduct') : this.actionWithTheProduct}
                    disabled={this.state.selectedRows.length === 0}
                  />
                </FixedWrap>,
              ] : [],
            ]
            } right={topBarRight}
          />
          {isEmpty(products) ? (
            <GoodsWrap minHeight="calc(100vh - 490px)">
              <GoodsIconWrap>
                <GoodsIcon/>
              </GoodsIconWrap>
              <EmptyGoodsTextWrap>
                {Translate('Нет добавленных товаров')}
              </EmptyGoodsTextWrap>
            </GoodsWrap>
          ) : (
            <SelectionList
              columns={preparedColumns}
              data={products}
              onRef={this.onListRef}
              onUpdate={this.onUpdate}
              selectType={isDraft || isCorrect || ((formValues.status === null || formValues.status === 19)) ? 'checkbox' : 'correction'}
              defaultPageSize={10}
              showPagination={products.length > 10}
              onPageChange={pageIdx => this.setPage(pageIdx)}
              keyField={keyField}
              page={page}
              customSelectAll
            />
          )}
          <GoodsCommandBar
            left={isDraft || formValues.status === null ? [
              cancelBtn,
              <CommonActionButton
                onClick={this.onSaveDraft}
                disabled={false}
              >
                {Translate('Сохранить как черновик')}
              </CommonActionButton>,
            ] : isCorrect ? [cancelBtn] : []}
            style={{
              ...styleCommandBar,
              // width: isEmpty(commandBarLeft) ? 'auto' : '100%',
              width: '100%',
            }}
            right={[
              <PrimaryActionLargeButton
                disabled={this.isNextStepDisabled}
                onClick={this.onNextStep}
              >
                {Translate('Следующий шаг')}
              </PrimaryActionLargeButton>,
            ]}
          />
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  savedProducts: selectors.savedProducts(state),
});

const mapDispatch = dispatch => ({
  onGetPackageCount: params => dispatch(actions.getPackageCount(params)),
  onFixProducts: cises => dispatch(actions.fixProducts(cises)),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
  withRouter,
)(Goods);
