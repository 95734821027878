import React, { Fragment, useEffect, useRef } from 'react';
import { ContentWrapper } from '../../common/styled';
import DataTable from '@crpt-ui/datatable';
import { evadersColumns } from '../ParticipantList.constants';
import { Preloader } from '../../../fragments/Table/Preloader';
import { ParticipantListCount, ParticipantListFooter } from '../ParticipantList.styled';
import { Pagination } from '@crpt-ui/core';
import { Translate } from '../../../common_components/Translate/Translate';
import { initFromUrl } from '../../../utils/urlUtils';
import ActionsRow from '../Actions';
import * as selectors from '../ducks/ParticipantList.selectors';
import * as actions from '../ducks/ParticipantList.actions';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';


const UnauthorizedParticipants = (props) => {
  const { location, history, fetchData, results, loaded, total } = props;
  const { currentPage, pagesCount, onUnmount } = props;
  const filterNames = evadersColumns.map(({ id }) => id);
  const initialParams = initFromUrl(props.location.search, filterNames);
  const instance = useRef();
  const resetFilters = () => instance.current.setAllFilters([]);

  useEffect(() => {
    const { page, filters } = initialParams;
    fetchData({
      page: page ? page.page : null,
      filters
    })
    return onUnmount
  },[fetchData, onUnmount])

  const onPageChange = (...params) => {
    const [page, filters = []] = params;
    fetchData({ filters, page, updateHistory: true, pathname: location.pathname, history });
  };

  const onFilterApply = (filters = []) => {
    fetchData({ filters, page: 1, updateHistory: true, pathname: location.pathname, history });
  };

  return (
    <Fragment>
      <ActionsRow resetFilters={resetFilters} isActionsDisabled={!loaded}/>
      <ContentWrapper>
        <DataTable
          columns={evadersColumns}
          data={loaded ? results : []}
          initialState={initialParams.filters}
          ref={instance}
          useFilters
          onFiltersChange={onFilterApply}
        />
        <Preloader isOpen={!loaded}/>
        <ParticipantListFooter>
          <div>
            {(results.length > 0 && pagesCount > 1) && (
              <Pagination
                page={currentPage}
                onPageChange={page => onPageChange(page, instance.current.state.filters)}
                pagesCount={pagesCount}
              />
            )}
          </div>
          <ParticipantListCount>
            {Translate('Всего участников:')}{' '} {total}
          </ParticipantListCount>
        </ParticipantListFooter>
      </ContentWrapper>
    </Fragment>
  );
};
const mapState = state => ({
  results: selectors.evadersResults(state),
  total: selectors.evadersTotal(state),
  currentPage: selectors.evadersPage(state),
  pagesCount: selectors.pagesCountEvaders(state),
  loaded: selectors.evadersLoaded(state),
});

const mapDispatch = dispatch => ({
  fetchData: params => dispatch(actions.getRequestEvaders(params)),
  onUnmount: () => dispatch(actions.unmountedEvaders()),
});

export default compose(
  withTheme,
  connect(mapState, mapDispatch),
)(UnauthorizedParticipants);
