import { createAction } from 'redux-actions';

export const subject = '[DiscrepancyNotice]';

export const send = createAction(`${subject} send`);
export const cancel = createAction(`${subject} cancel`);
export const createCorrectionAct = createAction(`${subject} create fixed act`);
export const setCorrectionAct = createAction(`${subject} set fixed act`);
export const clearCorrectionAct = createAction(`${subject} clear fixed act`);
export const setDiscrepancyNotice = createAction(`${subject} set Discrepancy Notice`);
export const clearDiscrepancyNotice = createAction(`${subject} clear Discrepancy Notice`);
export const createDiscrepancyNotice = createAction(`${subject} create Discrepancy Notice`);
export const loadDiscrepancyNotice = createAction(`${subject} load Discrepancy Notice`);
export const isLoadingStart = createAction(`${subject} load Discrepancy Notice start`);
export const isLoadingEnd = createAction(`${subject} load Discrepancy Notice end`);
