export const getParentByRole = ({ elem, role }) => {
  let el = elem;
  while (el.getAttribute('role') !== role) {
    if (!el.parentElement) {
      el = null;
      break;
    }
    el = el.parentElement;
  }
  return el;
};

export const getParentByDataRole = ({ elem, role }) => {
  let el = elem;
  while (el.getAttribute('data-role') !== role) {
    if (!el.parentElement) {
      el = null;
      break;
    }
    el = el.parentElement;
  }
  return el;
};
