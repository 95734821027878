import {createSelector} from 'reselect';
import {get} from 'lodash';

const data = state => state.Form.data;
const fields = state => state.Form.fields;

const getField = createSelector(
    fields,
    fields => (path, defaultValue) => get(fields, path, defaultValue)
);

const getData = createSelector(
  data,
  data => (path, defaultValue) => get(data, path, defaultValue)
);

const getFieldSelector = (path, defaultValue) => createSelector(
  fields,
  fields => get(fields, path, defaultValue)
);

const getDataSelector = (path, defaultValue) => createSelector(
  data,
  data => get(data, path, defaultValue)
);

export { data, fields, getField, getData, getFieldSelector, getDataSelector };
