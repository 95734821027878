import { get } from 'lodash';
import moment from 'moment';

export function convertData(correctData) {
  const sender_inn = get(correctData, 'main_info.supplier.inn');
  const sender_name = get(correctData, 'main_info.supplier.name');
  const sender_country = get(correctData, 'main_info.supplier.country_code');
  const parent_id = get(correctData, 'parent_id');
  const excise_tax_document_number = get(
    correctData,
    'main_info.excise_doc.number',
  );
  const excise_tax_document_date = get(
    correctData,
    'main_info.excise_doc.date',
  );
  const excise_tax_sum = get(correctData, 'main_info.excise_doc.amount');

  const compliance = get(correctData, 'main_info.compliance', []);
  const documents = compliance.map(item => ({
    document_date: item.date,
    document_number: item.number,
    document_type: item.doc_type,
  }));

  const isOriginDoc = get(correctData, 'main_info.origin_doc', null);
  let origin_doc = {};
  if (isOriginDoc) {
    origin_doc = {
      isHasPaperDocument: true,
      paper_document_number: get(isOriginDoc, 'number'),
      paper_document_date: get(isOriginDoc, 'date'),
    };
  }

  const primary_doc = {
    id: get(correctData, 'parent_id', null),
    number: get(correctData, 'number', null),
    date: moment(get(correctData, 'sending_date_time') * 1000).format(
      'DD.MM.YYYY',
    ),
  };

  const isChanged = get(correctData, 'isChanged', false);

  return {
    primary_doc,
    isChanged,
    sender_inn,
    sender_name,
    sender_country,
    parent_id,
    excise_tax_document_number,
    excise_tax_document_date,
    excise_tax_sum,
    documents,
    ...origin_doc,
  };
}
