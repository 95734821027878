import React from 'react';
import moment from 'moment';

import DocumentType from '../../TableCells/Document/DocumentType';
import DocumentId from '../../TableCells/Document/DocumentId';
import ProducerName from '../../TableCells/CISDetails/ProducerName';
import { TableCell } from '../../common/Table/TableCellTooltip';
import {Translate} from '../../../common_components/Translate/Translate';

export const options = [
  {
    accessor: d => d,
    id: 'type',

    render: props => {
      return <DocumentType {...props} />;
    },
  },
  {
    accessor: d => d,
    id: 'id',

    render: (props) => {
      const { onMouseEvent, ...otherProps } = props;
      return (
        <TableCell onMouseEvent={onMouseEvent}>
          <DocumentId {...otherProps} />
        </TableCell>
      );
    },
  },
  {
    accessor: d => d,
    id: 'participant1Name',

    render: (props) => {
      const { onMouseEvent, ...otherProps } = props;
      return (
        <TableCell onMouseEvent={onMouseEvent}>
          <ProducerName {...otherProps} />
        </TableCell>
      );
    },
  }
];

export const columns = [
  {
    Header: Translate('Дата'),
    id: 'docDate',
    accessor: d => `${moment(new Date(d.docDate)).format('DD.MM.YYYY')}`,
    width: 105,
  },
  {
    Header: Translate('Документ'),
    id: 'type',
  },
  {
    Header: Translate('Номер документа участника'),
    id: 'id',
    accessor: 'id',
  },
  {
    Header: Translate('Участники'),
    id: 'participant1Name',
    accessor: d => (
      <div>
        <div>{d.participant1Name}</div>
        <div>{d.participant2Name}</div>
      </div>
    ),
  }
];

export const autoDisaggregationTypesNotForReceipt = [
  'WRITE_OFF',
  'KZ_UPD',
  'MTTPS_90',
  'IMPORT_TRANSIT',
  'KZ_INTRODUCE_GOODS',
  'IMPORT',
]
