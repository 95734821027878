import React from 'react';
import CommonDescription from '../CommonDescription/CommonDescription';
import defaultParameters from './defaultParameters';
import customParameters from './customParameters';
import tableView from './tableView';
import indicatorsDescription from './indicatorsDescription';

export default {
  h1: 'Доля рынка по производству',
  maxWidth: '1360px',
  description: <CommonDescription />,
  tables: [
    defaultParameters,
    customParameters,
    tableView,
    indicatorsDescription
  ]
}
