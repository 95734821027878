import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
  location: {
    pathname: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        location: action.payload,
        lastLocation: state.location,
      };
    default:
      return state;
  }
};
