import React from 'react';

export default {
  title: 'Табличное представление:',
  amountOfTable: 2,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'Тип упаковки',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 110,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: []
        },
        {
          Header: 'Страна производства товара',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': '',
        },
      ]
    },
// table#2
    {
      headers: [
        {
          Header: 'Данные о владельце товара',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 730,
          subheaders: [
            {
              Header: 'ИНН',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 90,
              subheaders: []
            },
            {
              Header: 'Наименование',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Субъект РК',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 220,
              subheaders: []
            },
            {
              Header: 'Населённый пункт',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 250,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Объём списания',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 300,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 150,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col11',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 150,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
          'col10': [10],
          'col11': [11],
        },
        {
          'col6': '',
          'col7': '',
          'col8': '',
          'col9': '',
          'col10': '...',
          'col11': '...',
        },
      ]
    }
  ]
}
