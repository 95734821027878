import axios from 'axios';
import {getToken} from '../utils/userUtils';

const instance = axios.create({
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    accept: 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache'
  },
  mode: 'no-cors',
  crossdomain: true,
});

const instanceMulti = axios.create({
  timeout: 600000,
  headers: {
    "Content-Type": "multipart/form-data; charset=utf-8",
    'Accept': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache'
  },
  mode: 'no-cors',
  crossdomain: true,
});

instance.interceptors.request.use (
  function (config) {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

instanceMulti.interceptors.request.use (
  function (config) {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);


export { instance, instanceMulti };
