import { documentSteps } from '../Document/Document.constants';

export const cisKey = 'cis';
export const goodsKey = 'products';

export const tabs = [
  { id: documentSteps.COMMON, title: 'Общая информация' },
  { id: documentSteps.CIS, title: 'Коды' },
  { id: documentSteps.GOODS, title: 'Товары' },
];

export const withdrawingActionType = {
  SALE: 1,
  EXPORT_TO_EAES: 2,
  EXPORT_BEYOND_EAES: 3,
  LOAN_AGREEMENT: 4,
  DAMAGE: 5,
  FREE_TRANSFER: 6,
  RETURN: 7,
  BANKRUPTCY: 8,
  SALES_OF_CONFISCATED: 9,
  USING: 10,
  INSTALLMENT_AGREEMENT: 11,
  SALE_KKT: 12,
};

export const withdrawingActionTypeValues = [
  { id: 0, title: 'Розничная продажа', type: withdrawingActionType.SALE },
  // { id: 1, title: 'Экспорт в страны ЕАЭС', type: withdrawingActionType.EXPORT_TO_EAES },
  // { id: 2, title: 'Экспорт за пределы стран ЕАЭС', type: withdrawingActionType.EXPORT_BEYOND_EAES },
  // { id: 3, title: 'Кредитный договор', type: withdrawingActionType.LOAN_AGREEMENT },
  // { id: 4, title: 'Порча или утеря товара', type: withdrawingActionType.DAMAGE },
  // { id: 5, title: 'Безвозмездная передача', type: withdrawingActionType.FREE_TRANSFER },
  // { id: 6, title: 'Возврат физическому лицу', type: withdrawingActionType.RETURN },
  // { id: 7, title: 'Банкротство или ликвидация ЮЛ/ИП', type: withdrawingActionType.BANKRUPTCY },
  // { id: 8, title: 'Реализации конфискованных товаров', type: withdrawingActionType.SALES_OF_CONFISCATED },
  // { id: 9, title: 'Использование на предприятии', type: withdrawingActionType.USING },
  // {
  //   id: 10,
  //   title: 'Договор рассрочки',
  //   type: withdrawingActionType.INSTALLMENT_AGREEMENT,
  // },
  // { id: 11, title: 'Розничная продажа (ККТ)', type: withdrawingActionType.SALE_KKT },
];

export const withdrawDocumentType = {
  FISCAL: 1,
  FORM_OF_STRICT_ACCOUNTABILITY: 2,
  CONTRACT: 3,
  ACT_OF_DESTRUCTION: 4,
  PACKING_LIST: 5,
  INVOICE: 6,
  OTHERS: 7,
};

export const withdrawingDocumentTypeValues = [
  { id: 0, title: 'Фискальный документ', type: withdrawDocumentType.FISCAL },
  {
    id: 1,
    title: 'Бланк строгой отчетности',
    type: withdrawDocumentType.FORM_OF_STRICT_ACCOUNTABILITY,
  },
  { id: 2, title: 'Договор', type: withdrawDocumentType.CONTRACT },
  {
    id: 3,
    title: 'Акт уничтожения',
    type: withdrawDocumentType.ACT_OF_DESTRUCTION,
  },
  {
    id: 4,
    title: 'Товарная накладная',
    type: withdrawDocumentType.PACKING_LIST,
  },
  { id: 5, title: 'Счет-фактура', type: withdrawDocumentType.INVOICE },
  { id: 6, title: 'Прочее', type: withdrawDocumentType.OTHERS },
];

export const installmentWithdrawingDocumentType = withdrawDocumentType.FISCAL;

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: 'Документы',
    id: 1,
  },
  {
    title: 'Уведомление о ввозе товаров',
    id: 2,
  },
];

export const displayNone = { display: 'none' };

export const codesCountryValues = [
  { id: 0, title: '004 - АФГАНИСТАН', type: '004' },
  { id: 1, title: '008 - АЛБАНИЯ', type: '008' },
  { id: 2, title: '010 - АНТАРКТИДА', type: '010' },
  { id: 3, title: '012 - АЛЖИР', type: '012' },
  { id: 4, title: '016 - АМЕРИКАНСКОЕ', type: '016' },
  { id: 5, title: '020 - АНДОРРА', type: '020' },
  { id: 6, title: '024 - АНГОЛА', type: '024' },
  { id: 7, title: '028 - АНТИГУА', type: '028' },
  { id: 8, title: '031 - АЗЕРБАЙДЖАН', type: '031' },
  { id: 9, title: '032 - АРГЕНТИНА', type: '032' },
  { id: 10, title: '036 - АВСТРАЛИЯ', type: '036' },
  { id: 11, title: '040 - АВСТРИЯ', type: '040' },
  { id: 12, title: '044 - БАГАМЫ', type: '044' },
  { id: 13, title: '048 - БАХРЕЙН', type: '048' },
  { id: 14, title: '050 - БАНГЛАДЕШ', type: '050' },
  { id: 15, title: '051 - АРМЕНИЯ', type: '051' },
  { id: 16, title: '052 - БАРБАДОС', type: '052' },
  { id: 17, title: '056 - БЕЛЬГИЯ', type: '056' },
  { id: 18, title: '060 - БЕРМУДЫ', type: '060' },
  { id: 19, title: '064 - БУТАН', type: '064' },
  { id: 20, title: '068 - БОЛИВИЯ,', type: '068' },
  { id: 21, title: '070 - БОСНИЯ', type: '070' },
  { id: 22, title: '072 - БОТСВАНА', type: '072' },
  { id: 23, title: '074 - ОСТРОВ', type: '074' },
  { id: 24, title: '076 - БРАЗИЛИЯ', type: '076' },
  { id: 25, title: '084 - БЕЛИЗ', type: '084' },
  { id: 26, title: '086 - БРИТАНСКАЯ', type: '086' },
  { id: 27, title: '090 - СОЛОМОНОВЫ', type: '090' },
  { id: 28, title: '092 - ВИРГИНСКИЕ', type: '092' },
  { id: 29, title: '096 - БРУНЕЙ-ДАРУССАЛАМ', type: '096' },
  { id: 30, title: '100 - БОЛГАРИЯ', type: '100' },
  { id: 31, title: '104 - МЬЯНМА', type: '104' },
  { id: 32, title: '108 - БУРУНДИ', type: '108' },
  { id: 33, title: '112 - БЕЛАРУСЬ', type: '112' },
  { id: 34, title: '116 - КАМБОДЖА', type: '116' },
  { id: 35, title: '120 - КАМЕРУН', type: '120' },
  { id: 36, title: '124 - КАНАДА', type: '124' },
  { id: 37, title: '132 - КАБО-ВЕРДЕ', type: '132' },
  { id: 38, title: '136 - ОСТРОВА', type: '136' },
  { id: 39, title: '140 - ЦЕНТРАЛЬНО-АФРИКАНСКАЯ', type: '140' },
  { id: 40, title: '144 - ШРИ-ЛАНКА', type: '144' },
  { id: 41, title: '148 - ЧАД', type: '148' },
  { id: 42, title: '152 - ЧИЛИ', type: '152' },
  { id: 43, title: '156 - КИТАЙ', type: '156' },
  { id: 44, title: '158 - ТАЙВАНЬ', type: '158' },
  { id: 45, title: '162 - ОСТРОВ', type: '162' },
  { id: 46, title: '166 - КОКОСОВЫЕ', type: '166' },
  { id: 47, title: '170 - КОЛУМБИЯ', type: '170' },
  { id: 48, title: '174 - КОМОРЫ', type: '174' },
  { id: 49, title: '175 - МАЙОТТА', type: '175' },
  { id: 50, title: '178 - КОНГО', type: '178' },
  { id: 51, title: '180 - КОНГО,', type: '180' },
  { id: 52, title: '184 - ОСТРОВА', type: '184' },
  { id: 53, title: '188 - КОСТА-РИКА', type: '188' },
  { id: 54, title: '191 - ХОРВАТИЯ', type: '191' },
  { id: 55, title: '192 - КУБА', type: '192' },
  { id: 56, title: '196 - КИПР', type: '196' },
  { id: 57, title: '203 - ЧЕШСКАЯ', type: '203' },
  { id: 58, title: '204 - БЕНИН', type: '204' },
  { id: 59, title: '208 - ДАНИЯ', type: '208' },
  { id: 60, title: '212 - ДОМИНИКА', type: '212' },
  { id: 61, title: '214 - ДОМИНИКАНСКАЯ', type: '214' },
  { id: 62, title: '218 - ЭКВАДОР', type: '218' },
  { id: 63, title: '222 - ЭЛЬ-САЛЬВАДОР', type: '222' },
  { id: 64, title: '226 - ЭКВАТОРИАЛЬНАЯ', type: '226' },
  { id: 65, title: '231 - ЭФИОПИЯ', type: '231' },
  { id: 66, title: '232 - ЭРИТРЕЯ', type: '232' },
  { id: 67, title: '233 - ЭСТОНИЯ', type: '233' },
  { id: 68, title: '234 - ФАРЕРСКИЕ', type: '234' },
  { id: 69, title: '238 - ФОЛКЛЕНДСКИЕ', type: '238' },
  { id: 70, title: '239 - ЮЖНАЯ', type: '239' },
  { id: 71, title: '242 - ФИДЖИ', type: '242' },
  { id: 72, title: '246 - ФИНЛЯНДИЯ', type: '246' },
  { id: 73, title: '248 - ЭЛАНДСКИЕ', type: '248' },
  { id: 74, title: '250 - ФРАНЦИЯ', type: '250' },
  { id: 75, title: '254 - ФРАНЦУЗСКАЯ', type: '254' },
  { id: 76, title: '258 - ФРАНЦУЗСКАЯ', type: '258' },
  { id: 77, title: '260 - ФРАНЦУЗСКИЕ', type: '260' },
  { id: 78, title: '262 - ДЖИБУТИ', type: '262' },
  { id: 79, title: '266 - ГАБОН', type: '266' },
  { id: 80, title: '268 - ГРУЗИЯ', type: '268' },
  { id: 81, title: '270 - ГАМБИЯ', type: '270' },
  { id: 82, title: '275 - ПАЛЕСТИНА,', type: '275' },
  { id: 83, title: '276 - ГЕРМАНИЯ', type: '276' },
  { id: 84, title: '288 - ГАНА', type: '288' },
  { id: 85, title: '292 - ГИБРАЛТАР', type: '292' },
  { id: 86, title: '296 - КИРИБАТИ', type: '296' },
  { id: 87, title: '300 - ГРЕЦИЯ', type: '300' },
  { id: 88, title: '304 - ГРЕНЛАНДИЯ', type: '304' },
  { id: 89, title: '308 - ГРЕНАДА', type: '308' },
  { id: 90, title: '312 - ГВАДЕЛУПА', type: '312' },
  { id: 91, title: '316 - ГУАМ', type: '316' },
  { id: 92, title: '320 - ГВАТЕМАЛА', type: '320' },
  { id: 93, title: '324 - ГВИНЕЯ', type: '324' },
  { id: 94, title: '328 - ГАЙАНА', type: '328' },
  { id: 95, title: '332 - ГАИТИ', type: '332' },
  { id: 96, title: '334 - ОСТРОВ', type: '334' },
  { id: 97, title: '336 - ПАПСКИЙ', type: '336' },
  { id: 98, title: '340 - ГОНДУРАС', type: '340' },
  { id: 99, title: '344 - ГОНКОНГ', type: '344' },
  { id: 100, title: '348 - ВЕНГРИЯ', type: '348' },
  { id: 101, title: '352 - ИСЛАНДИЯ', type: '352' },
  { id: 102, title: '356 - ИНДИЯ', type: '356' },
  { id: 103, title: '360 - ИНДОНЕЗИЯ', type: '360' },
  { id: 104, title: '364 - ИРАН,', type: '364' },
  { id: 105, title: '368 - ИРАК', type: '368' },
  { id: 106, title: '372 - ИРЛАНДИЯ', type: '372' },
  { id: 107, title: '376 - ИЗРАИЛЬ', type: '376' },
  { id: 108, title: '380 - ИТАЛИЯ', type: '380' },
  { id: 109, title: '384 - КОТ', type: '384' },
  { id: 110, title: '388 - ЯМАЙКА', type: '388' },
  { id: 111, title: '392 - ЯПОНИЯ', type: '392' },
  { id: 112, title: '398 - КАЗАХСТАН', type: '398' },
  { id: 113, title: '400 - ИОРДАНИЯ', type: '400' },
  { id: 114, title: '404 - КЕНИЯ', type: '404' },
  { id: 115, title: '408 - КОРЕЯ,', type: '408' },
  { id: 116, title: '410 - КОРЕЯ,', type: '410' },
  { id: 117, title: '414 - КУВЕЙТ', type: '414' },
  { id: 118, title: '417 - КИРГИЗИЯ', type: '417' },
  { id: 119, title: '418 - ЛАОССКАЯ', type: '418' },
  { id: 120, title: '422 - ЛИВАН', type: '422' },
  { id: 121, title: '426 - ЛЕСОТО', type: '426' },
  { id: 122, title: '428 - ЛАТВИЯ', type: '428' },
  { id: 123, title: '430 - ЛИБЕРИЯ', type: '430' },
  { id: 124, title: '434 - ЛИВИЯ', type: '434' },
  { id: 125, title: '438 - ЛИХТЕНШТЕЙН', type: '438' },
  { id: 126, title: '440 - ЛИТВА', type: '440' },
  { id: 127, title: '442 - ЛЮКСЕМБУРГ', type: '442' },
  { id: 128, title: '446 - МАКАО', type: '446' },
  { id: 129, title: '450 - МАДАГАСКАР', type: '450' },
  { id: 130, title: '454 - МАЛАВИ', type: '454' },
  { id: 131, title: '458 - МАЛАЙЗИЯ', type: '458' },
  { id: 132, title: '462 - МАЛЬДИВЫ', type: '462' },
  { id: 133, title: '466 - МАЛИ', type: '466' },
  { id: 134, title: '470 - МАЛЬТА', type: '470' },
  { id: 135, title: '474 - МАРТИНИКА', type: '474' },
  { id: 136, title: '478 - МАВРИТАНИЯ', type: '478' },
  { id: 137, title: '480 - МАВРИКИЙ', type: '480' },
  { id: 138, title: '484 - МЕКСИКА', type: '484' },
  { id: 139, title: '492 - МОНАКО', type: '492' },
  { id: 140, title: '496 - МОНГОЛИЯ', type: '496' },
  { id: 141, title: '498 - МОЛДОВА,', type: '498' },
  { id: 142, title: '499 - ЧЕРНОГОРИЯ', type: '499' },
  { id: 143, title: '500 - МОНТСЕРРАТ', type: '500' },
  { id: 144, title: '504 - МАРОККО', type: '504' },
  { id: 145, title: '508 - МОЗАМБИК', type: '508' },
  { id: 146, title: '512 - ОМАН', type: '512' },
  { id: 147, title: '516 - НАМИБИЯ', type: '516' },
  { id: 148, title: '520 - НАУРУ', type: '520' },
  { id: 149, title: '524 - НЕПАЛ', type: '524' },
  { id: 150, title: '528 - НИДЕРЛАНДЫ', type: '528' },
  { id: 151, title: '531 - КЮРАСАО', type: '531' },
  { id: 152, title: '533 - АРУБА', type: '533' },
  { id: 153, title: '534 - СЕН-МАРТЕН', type: '534' },
  { id: 154, title: '535 - БОНЭЙР,', type: '535' },
  { id: 155, title: '540 - НОВАЯ', type: '540' },
  { id: 156, title: '548 - ВАНУАТУ', type: '548' },
  { id: 157, title: '554 - НОВАЯ', type: '554' },
  { id: 158, title: '558 - НИКАРАГУА', type: '558' },
  { id: 159, title: '562 - НИГЕР', type: '562' },
  { id: 160, title: '566 - НИГЕРИЯ', type: '566' },
  { id: 161, title: '570 - НИУЭ', type: '570' },
  { id: 162, title: '574 - ОСТРОВ', type: '574' },
  { id: 163, title: '578 - НОРВЕГИЯ', type: '578' },
  { id: 164, title: '580 - СЕВЕРНЫЕ', type: '580' },
  { id: 165, title: '581 - МАЛЫЕ', type: '581' },
  { id: 166, title: '583 - МИКРОНЕЗИЯ,', type: '583' },
  { id: 167, title: '584 - МАРШАЛЛОВЫ', type: '584' },
  { id: 168, title: '585 - ПАЛАУ', type: '585' },
  { id: 169, title: '586 - ПАКИСТАН', type: '586' },
  { id: 170, title: '591 - ПАНАМА', type: '591' },
  { id: 171, title: '598 - ПАПУА', type: '598' },
  { id: 172, title: '600 - ПАРАГВАЙ', type: '600' },
  { id: 173, title: '604 - ПЕРУ', type: '604' },
  { id: 174, title: '608 - ФИЛИППИНЫ', type: '608' },
  { id: 175, title: '612 - ПИТКЕРН', type: '612' },
  { id: 176, title: '616 - ПОЛЬША', type: '616' },
  { id: 177, title: '620 - ПОРТУГАЛИЯ', type: '620' },
  { id: 178, title: '624 - ГВИНЕЯ-БИСАУ', type: '624' },
  { id: 179, title: '626 - ТИМОР-ЛЕСТЕ', type: '626' },
  { id: 180, title: '630 - ПУЭРТО-РИКО', type: '630' },
  { id: 181, title: '634 - КАТАР', type: '634' },
  { id: 182, title: '638 - РЕЮНЬОН', type: '638' },
  { id: 183, title: '642 - РУМЫНИЯ', type: '642' },
  { id: 184, title: '643 - РОССИЯ', type: '643' },
  { id: 185, title: '646 - РУАНДА', type: '646' },
  { id: 186, title: '652 - СЕН-БАРТЕЛЕМИ', type: '652' },
  { id: 187, title: '654 - СВЯТАЯ', type: '654' },
  { id: 188, title: '659 - СЕНТ-КИТС', type: '659' },
  { id: 189, title: '660 - АНГИЛЬЯ', type: '660' },
  { id: 190, title: '662 - СЕНТ-ЛЮСИЯ', type: '662' },
  { id: 191, title: '663 - СЕН-МАРТЕН', type: '663' },
  { id: 192, title: '666 - СЕН-ПЬЕР', type: '666' },
  { id: 193, title: '670 - СЕНТ-ВИНСЕНТ', type: '670' },
  { id: 194, title: '674 - САН-МАРИНО', type: '674' },
  { id: 195, title: '678 - САН-ТОМЕ', type: '678' },
  { id: 196, title: '682 - САУДОВСКАЯ', type: '682' },
  { id: 197, title: '686 - СЕНЕГАЛ', type: '686' },
  { id: 198, title: '688 - СЕРБИЯ', type: '688' },
  { id: 199, title: '690 - СЕЙШЕЛЫ', type: '690' },
  { id: 200, title: '694 - СЬЕРРА-ЛЕОНЕ', type: '694' },
  { id: 201, title: '702 - СИНГАПУР', type: '702' },
  { id: 202, title: '703 - СЛОВАКИЯ', type: '703' },
  { id: 203, title: '704 - ВЬЕТНАМ', type: '704' },
  { id: 204, title: '705 - СЛОВЕНИЯ', type: '705' },
  { id: 205, title: '706 - СОМАЛИ', type: '706' },
  { id: 206, title: '710 - ЮЖНАЯ', type: '710' },
  { id: 207, title: '716 - ЗИМБАБВЕ', type: '716' },
  { id: 208, title: '724 - ИСПАНИЯ', type: '724' },
  { id: 209, title: '728 - ЮЖНЫЙ', type: '728' },
  { id: 210, title: '729 - СУДАН', type: '729' },
  { id: 211, title: '732 - ЗАПАДНАЯ', type: '732' },
  { id: 212, title: '740 - СУРИНАМ', type: '740' },
  { id: 213, title: '744 - ШПИЦБЕРГЕН', type: '744' },
  { id: 214, title: '748 - СВАЗИЛЕНД', type: '748' },
  { id: 215, title: '752 - ШВЕЦИЯ', type: '752' },
  { id: 216, title: '756 - ШВЕЙЦАРИЯ', type: '756' },
  { id: 217, title: '760 - СИРИЙСКАЯ', type: '760' },
  { id: 218, title: '762 - ТАДЖИКИСТАН', type: '762' },
  { id: 219, title: '764 - ТАИЛАНД', type: '764' },
  { id: 220, title: '768 - ТОГО', type: '768' },
  { id: 221, title: '772 - ТОКЕЛАУ', type: '772' },
  { id: 222, title: '776 - ТОНГА', type: '776' },
  { id: 223, title: '780 - ТРИНИДАД', type: '780' },
  { id: 224, title: '784 - ОБЪЕДИНЕННЫЕ', type: '784' },
  { id: 225, title: '788 - ТУНИС', type: '788' },
  { id: 226, title: '792 - ТУРЦИЯ', type: '792' },
  { id: 227, title: '795 - ТУРКМЕНИЯ', type: '795' },
  { id: 228, title: '796 - ОСТРОВА', type: '796' },
  { id: 229, title: '798 - ТУВАЛУ', type: '798' },
  { id: 230, title: '800 - УГАНДА', type: '800' },
  { id: 231, title: '804 - УКРАИНА', type: '804' },
  { id: 232, title: '807 - РЕСПУБЛИКА', type: '807' },
  { id: 233, title: '818 - ЕГИПЕТ', type: '818' },
  { id: 234, title: '826 - СОЕДИНЕННОЕ', type: '826' },
  { id: 235, title: '831 - ГЕРНСИ', type: '831' },
  { id: 236, title: '832 - ДЖЕРСИ', type: '832' },
  { id: 237, title: '833 - ОСТРОВ', type: '833' },
  { id: 238, title: '834 - ТАНЗАНИЯ,', type: '834' },
  { id: 239, title: '840 - СОЕДИНЕННЫЕ', type: '840' },
  { id: 240, title: '850 - ВИРГИНСКИЕ', type: '850' },
  { id: 241, title: '854 - БУРКИНА-ФАСО', type: '854' },
  { id: 242, title: '858 - УРУГВАЙ', type: '858' },
  { id: 243, title: '860 - УЗБЕКИСТАН', type: '860' },
  { id: 244, title: '862 - ВЕНЕСУЭЛА', type: '862' },
  { id: 245, title: '876 - УОЛЛИС', type: '876' },
  { id: 246, title: '882 - САМОА', type: '882' },
  { id: 247, title: '887 - ЙЕМЕН', type: '887' },
  { id: 248, title: '894 - ЗАМБИЯ', type: '894' },
  { id: 249, title: '895 - АБХАЗИЯ', type: '895' },
  { id: 250, title: '896 - ЮЖНАЯ', type: '896' },
];

export const decisionCodeValues = [
  { id: 0, title: '10 Выпуск товаров разрешен', type: "10" },
  {
    id: 1,
    title:
      '11 Выпуск товаров при условии обеспечения исполнения обязанности по уплате таможенных пошлин',
    type: "11",
  },
  {
    id: 2,
    title:
      '12 Выпуск товаров с особенностями, предусмотренными статьей 121 ТК ЕАЭС',
    type: "12",
  },
  {
    id: 3,
    title:
      '13 Выпуск товаров с особенностями, предусмотренными статьей 122 ТК ЕАЭС',
    type: "13",
  },
  {
    id: 4,
    title:
      '14 Выпуск товаров с особенностями, предусмотренными статьей 123 ТК ЕАЭС',
    type: "14",
  },
  { id: 5, title: '20 Условный выпуск товаров', type: "20" },
];

export const certificateDocument = {
  CONFORMITY_CERT: 'CONFORMITY_CERT',
  CONFORMITY_DECLAR: 'CONFORMITY_DECLAR',
};

export const certificateDocumentName = {
  CONFORMITY_CERT: 'Сертификат соответствия',
  CONFORMITY_DECLAR: 'Декларация соответствия',
};

// export const certificateDocument = {
//   CONFORMITY_CERT: 'CONFORMITY_CERT',
//   CONFORMITY_DECLAR: 'CONFORMITY_DECLAR',
// };

export const certificateDocumentValues = [
  {
    id: 0,
    title: 'Сертификат соответствия',
    type: certificateDocument.CONFORMITY_CERT,
  },
  {
    id: 1,
    title: 'Декларация соответствия',
    type: certificateDocument.CONFORMITY_DECLAR,
  },
];

export const markingType = {
  PRODUCT_PACKAGE: 'PRODUCT_PACKAGE',
  PRODUCT: 'PRODUCT',
  PRODUCT_LABEL: 'PRODUCT_LABEL',
};

export const markingTypeName = {
  PRODUCT_PACKAGE: 'На потребительскую упаковку',
  PRODUCT: 'На товар',
  PRODUCT_LABEL: 'На товарный ярлык',
};

export const markingTypeValues = [
  {
    id: 1,
    title: 'На потребительскую упаковку',
    type: markingType.PRODUCT_PACKAGE,
  },
  { id: 2, title: 'На товар', type: markingType.PRODUCT },
  { id: 3, title: 'На товарный ярлык', type: markingType.PRODUCT_LABEL },
];


export const currencies = [
  { id: 1, title: 'RUB', type: 'RUB' },
  { id: 2, title: 'USD', type: 'USD' },
  { id: 3, title: 'EUR', type: 'EUR' },
  { id: 4, title: 'GBP', type: 'GBP' },
  { id: 5, title: 'JPY', type: 'JPY' },
  { id: 6, title: 'AED', type: 'AED' },
  { id: 7, title: 'AFN', type: 'AFN' },
  { id: 8, title: 'ALL', type: 'ALL' },
  { id: 9, title: 'AMD', type: 'AMD' },
  { id: 10, title: 'ANG', type: 'ANG' },
  { id: 11, title: 'AOA', type: 'AOA' },
  { id: 12, title: 'ARS', type: 'ARS' },
  { id: 13, title: 'AUD', type: 'AUD' },
  { id: 14, title: 'AWG', type: 'AWG' },
  { id: 15, title: 'AZN', type: 'AZN' },
  { id: 16, title: 'BAM', type: 'BAM' },
  { id: 17, title: 'BBD', type: 'BBD' },
  { id: 18, title: 'BDT', type: 'BDT' },
  { id: 19, title: 'BGN', type: 'BGN' },
  { id: 20, title: 'BHD', type: 'BHD' },
  { id: 21, title: 'BIF', type: 'BIF' },
  { id: 22, title: 'BMD', type: 'BMD' },
  { id: 23, title: 'BND', type: 'BND' },
  { id: 24, title: 'BOB', type: 'BOB' },
  { id: 25, title: 'BRL', type: 'BRL' },
  { id: 26, title: 'BTN', type: 'BTN' },
  { id: 27, title: 'BWP', type: 'BWP' },
  { id: 28, title: 'BYN', type: 'BYN' },
  { id: 29, title: 'BYR', type: 'BYR' },
  { id: 30, title: 'BZD', type: 'BZD' },
  { id: 31, title: 'CAD', type: 'CAD' },
  { id: 32, title: 'CDF', type: 'CDF' },
  { id: 33, title: 'CHF', type: 'CHF' },
  { id: 34, title: 'CLP', type: 'CLP' },
  { id: 35, title: 'CNY', type: 'CNY' },
  { id: 36, title: 'COP', type: 'COP' },
  { id: 37, title: 'COU', type: 'COU' },
  { id: 38, title: 'CRC', type: 'CRC' },
  { id: 39, title: 'CUC', type: 'CUC' },
  { id: 40, title: 'CUP', type: 'CUP' },
  { id: 41, title: 'CVE', type: 'CVE' },
  { id: 42, title: 'CZK', type: 'CZK' },
  { id: 43, title: 'DJF', type: 'DJF' },
  { id: 44, title: 'DKK', type: 'DKK' },
  { id: 45, title: 'DOP', type: 'DOP' },
  { id: 46, title: 'DZD', type: 'DZD' },
  { id: 47, title: 'EGP', type: 'EGP' },
  { id: 48, title: 'ERN', type: 'ERN' },
  { id: 49, title: 'ETB', type: 'ETB' },
  { id: 50, title: 'FJD', type: 'FJD' },
  { id: 51, title: 'FKP', type: 'FKP' },
  { id: 52, title: 'GEL', type: 'GEL' },
  { id: 53, title: 'GHS', type: 'GHS' },
  { id: 54, title: 'GIP', type: 'GIP' },
  { id: 55, title: 'GMD', type: 'GMD' },
  { id: 56, title: 'GNF', type: 'GNF' },
  { id: 57, title: 'GTQ', type: 'GTQ' },
  { id: 58, title: 'GYD', type: 'GYD' },
  { id: 59, title: 'HKD', type: 'HKD' },
  { id: 60, title: 'HNL', type: 'HNL' },
  { id: 61, title: 'HRK', type: 'HRK' },
  { id: 62, title: 'HTG', type: 'HTG' },
  { id: 63, title: 'HUF', type: 'HUF' },
  { id: 64, title: 'IDR', type: 'IDR' },
  { id: 65, title: 'ILS', type: 'ILS' },
  { id: 66, title: 'INR', type: 'INR' },
  { id: 67, title: 'IQD', type: 'IQD' },
  { id: 68, title: 'IRR', type: 'IRR' },
  { id: 69, title: 'ISK', type: 'ISK' },
  { id: 70, title: 'JMD', type: 'JMD' },
  { id: 71, title: 'JOD', type: 'JOD' },
  { id: 72, title: 'KES', type: 'KES' },
  { id: 73, title: 'KGS', type: 'KGS' },
  { id: 74, title: 'KHR', type: 'KHR' },
  { id: 75, title: 'KMF', type: 'KMF' },
  { id: 76, title: 'KPW', type: 'KPW' },
  { id: 77, title: 'KRW', type: 'KRW' },
  { id: 78, title: 'KWD', type: 'KWD' },
  { id: 79, title: 'KYD', type: 'KYD' },
  { id: 80, title: 'KZT', type: 'KZT' },
  { id: 81, title: 'LAK', type: 'LAK' },
  { id: 82, title: 'LBP', type: 'LBP' },
  { id: 83, title: 'LKR', type: 'LKR' },
  { id: 84, title: 'LRD', type: 'LRD' },
  { id: 85, title: 'LSL', type: 'LSL' },
  { id: 86, title: 'LTL', type: 'LTL' },
  { id: 87, title: 'LVL', type: 'LVL' },
  { id: 88, title: 'LYD', type: 'LYD' },
  { id: 89, title: 'MAD', type: 'MAD' },
  { id: 90, title: 'MDL', type: 'MDL' },
  { id: 91, title: 'MGA', type: 'MGA' },
  { id: 92, title: 'MKD', type: 'MKD' },
  { id: 93, title: 'MMK', type: 'MMK' },
  { id: 94, title: 'MNT', type: 'MNT' },
  { id: 95, title: 'MOP', type: 'MOP' },
  { id: 96, title: 'MRO', type: 'MRO' },
  { id: 97, title: 'MRU', type: 'MRU' },
  { id: 98, title: 'MUR', type: 'MUR' },
  { id: 99, title: 'MVR', type: 'MVR' },
  { id: 100, title: 'MWK', type: 'MWK' },
  { id: 101, title: 'MXN', type: 'MXN' },
  { id: 102, title: 'MYR', type: 'MYR' },
  { id: 103, title: 'MZN', type: 'MZN' },
  { id: 104, title: 'NAD', type: 'NAD' },
  { id: 105, title: 'NGN', type: 'NGN' },
  { id: 106, title: 'NIO', type: 'NIO' },
  { id: 107, title: 'NOK', type: 'NOK' },
  { id: 108, title: 'NPR', type: 'NPR' },
  { id: 109, title: 'NZD', type: 'NZD' },
  { id: 110, title: 'OMR', type: 'OMR' },
  { id: 111, title: 'PAB', type: 'PAB' },
  { id: 112, title: 'PEN', type: 'PEN' },
  { id: 113, title: 'PGK', type: 'PGK' },
  { id: 114, title: 'PHP', type: 'PHP' },
  { id: 115, title: 'PKR', type: 'PKR' },
  { id: 116, title: 'PLN', type: 'PLN' },
  { id: 117, title: 'PYG', type: 'PYG' },
  { id: 118, title: 'QAR', type: 'QAR' },
  { id: 119, title: 'RON', type: 'RON' },
  { id: 120, title: 'RSD', type: 'RSD' },
  { id: 121, title: 'RWF', type: 'RWF' },
  { id: 122, title: 'SAR', type: 'SAR' },
  { id: 123, title: 'SBD', type: 'SBD' },
  { id: 124, title: 'SCR', type: 'SCR' },
  { id: 125, title: 'SDG', type: 'SDG' },
  { id: 126, title: 'SEK', type: 'SEK' },
  { id: 127, title: 'SGD', type: 'SGD' },
  { id: 128, title: 'SHP', type: 'SHP' },
  { id: 129, title: 'SLL', type: 'SLL' },
  { id: 130, title: 'SOS', type: 'SOS' },
  { id: 131, title: 'SRD', type: 'SRD' },
  { id: 132, title: 'SSP', type: 'SSP' },
  { id: 133, title: 'STD', type: 'STD' },
  { id: 134, title: 'STN', type: 'STN' },
  { id: 135, title: 'SVC', type: 'SVC' },
  { id: 136, title: 'SYP', type: 'SYP' },
  { id: 137, title: 'SZL', type: 'SZL' },
  { id: 138, title: 'THB', type: 'THB' },
  { id: 139, title: 'TJS', type: 'TJS' },
  { id: 140, title: 'TMT', type: 'TMT' },
  { id: 141, title: 'TND', type: 'TND' },
  { id: 142, title: 'TOP', type: 'TOP' },
  { id: 143, title: 'TRY', type: 'TRY' },
  { id: 144, title: 'TTD', type: 'TTD' },
  { id: 145, title: 'TWD', type: 'TWD' },
  { id: 146, title: 'TZS', type: 'TZS' },
  { id: 147, title: 'UAH', type: 'UAH' },
  { id: 148, title: 'UGX', type: 'UGX' },
  { id: 149, title: 'UYI', type: 'UYI' },
  { id: 150, title: 'UYU', type: 'UYU' },
  { id: 151, title: 'UZS', type: 'UZS' },
  { id: 152, title: 'VEF', type: 'VEF' },
  { id: 153, title: 'VES', type: 'VES' },
  { id: 154, title: 'VND', type: 'VND' },
  { id: 155, title: 'VUV', type: 'VUV' },
  { id: 156, title: 'WST', type: 'WST' },
  { id: 157, title: 'XAF', type: 'XAF' },
  { id: 158, title: 'XCD', type: 'XCD' },
  { id: 159, title: 'XDR', type: 'XDR' },
  { id: 160, title: 'XOF', type: 'XOF' },
  { id: 161, title: 'XPF', type: 'XPF' },
  { id: 162, title: 'YER', type: 'YER' },
  { id: 163, title: 'ZAR', type: 'ZAR' },
  { id: 164, title: 'ZMW', type: 'ZMW' },
  { id: 165, title: 'ZWL', type: 'ZWL' },
];

export const codesCountryClassifier = [
  { id: 0, title: 'AD - АНДОРРА', type: 'AD' },
  { id: 1, title: 'AE - ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ', type: 'AE' },
  { id: 2, title: 'AF - АФГАНИСТАН', type: 'AF' },
  { id: 3, title: 'AG - АНТИГУА И БАРБУДА', type: 'AG' },
  { id: 4, title: 'AI - АНГИЛЬЯ', type: 'AI' },
  { id: 5, title: 'AL - АЛБАНИЯ', type: 'AL' },
  { id: 6, title: 'AM - АРМЕНИЯ', type: 'AM' },
  { id: 7, title: 'AO - АНГОЛА', type: 'AO' },
  { id: 8, title: 'AQ - АНТАРКТИДА', type: 'AQ' },
  { id: 9, title: 'AR - АРГЕНТИНА', type: 'AR' },
  { id: 10, title: 'AS - АМЕРИКАНСКОЕ САМОА', type: 'AS' },
  { id: 11, title: 'AT - АВСТРИЯ', type: 'AT' },
  { id: 12, title: 'AU - АВСТРАЛИЯ', type: 'AU' },
  { id: 13, title: 'AW - АРУБА', type: 'AW' },
  { id: 14, title: 'AX - ЭЛАНДСКИЕ ОСТРОВА', type: 'AX' },
  { id: 15, title: 'AZ - АЗЕРБАЙДЖАН', type: 'AZ' },
  { id: 16, title: 'BA - БОСНИЯ И ГЕРЦЕГОВИНА', type: 'BA' },
  { id: 17, title: 'BB - БАРБАДОС', type: 'BB' },
  { id: 18, title: 'BD - БАНГЛАДЕШ', type: 'BD' },
  { id: 19, title: 'BE - БЕЛЬГИЯ', type: 'BE' },
  { id: 20, title: 'BF - БУРКИНА-ФАСО', type: 'BF' },
  { id: 21, title: 'BG - БОЛГАРИЯ', type: 'BG' },
  { id: 22, title: 'BH - БАХРЕЙН', type: 'BH' },
  { id: 23, title: 'BI - БУРУНДИ', type: 'BI' },
  { id: 24, title: 'BJ - БЕНИН', type: 'BJ' },
  { id: 25, title: 'BL - СЕН-БАРТЕЛЕМИ', type: 'BL' },
  { id: 26, title: 'BM - БЕРМУДЫ', type: 'BM' },
  { id: 27, title: 'BN - БРУНЕЙ-ДАРУССАЛАМ', type: 'BN' },
  { id: 28, title: 'BO - БОЛИВИЯ (МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО)', type: 'BO' },
  { id: 29, title: 'BQ - БОНЭЙР, СИНТ-ЭСТАТИУС И САБА', type: 'BQ' },
  { id: 30, title: 'BR - БРАЗИЛИЯ', type: 'BR' },
  { id: 31, title: 'BS - БАГАМЫ', type: 'BS' },
  { id: 32, title: 'BT - БУТАН', type: 'BT' },
  { id: 33, title: 'BV - ОСТРОВ БУВЕ', type: 'BV' },
  { id: 34, title: 'BW - БОТСВАНА', type: 'BW' },
  { id: 35, title: 'BY - БЕЛАРУСЬ', type: 'BY' },
  { id: 36, title: 'BZ - БЕЛИЗ', type: 'BZ' },
  { id: 37, title: 'CA - КАНАДА', type: 'CA' },
  { id: 38, title: 'CC - КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА', type: 'CC' },
  { id: 39, title: 'CD - КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА', type: 'CD' },
  { id: 40, title: 'CF - ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА', type: 'CF' },
  { id: 41, title: 'CG - КОНГО', type: 'CG' },
  { id: 42, title: 'CH - ШВЕЙЦАРИЯ', type: 'CH' },
  { id: 43, title: 'CI - КОТ Д ИВУАР', type: 'CI' },
  { id: 44, title: 'CK - ОСТРОВА КУКА', type: 'CK' },
  { id: 45, title: 'CL - ЧИЛИ', type: 'CL' },
  { id: 46, title: 'CM - КАМЕРУН', type: 'CM' },
  { id: 47, title: 'CN - КИТАЙ', type: 'CN' },
  { id: 48, title: 'CO - КОЛУМБИЯ', type: 'CO' },
  { id: 49, title: 'CR - КОСТА-РИКА', type: 'CR' },
  { id: 50, title: 'CU - КУБА', type: 'CU' },
  { id: 51, title: 'CV - КАБО-ВЕРДЕ', type: 'CV' },
  { id: 52, title: 'CW - КЮРАСАО', type: 'CW' },
  { id: 53, title: 'CX - ОСТРОВ РОЖДЕСТВА', type: 'CX' },
  { id: 54, title: 'CY - КИПР', type: 'CY' },
  { id: 55, title: 'CZ - ЧЕХИЯ', type: 'CZ' },
  { id: 56, title: 'DE - ГЕРМАНИЯ', type: 'DE' },
  { id: 57, title: 'DJ - ДЖИБУТИ', type: 'DJ' },
  { id: 58, title: 'DK - ДАНИЯ', type: 'DK' },
  { id: 59, title: 'DM - ДОМИНИКА', type: 'DM' },
  { id: 60, title: 'DO - ДОМИНИКАНСКАЯ РЕСПУБЛИКА', type: 'DO' },
  { id: 61, title: 'DZ - АЛЖИР', type: 'DZ' },
  { id: 62, title: 'EC - ЭКВАДОР', type: 'EC' },
  { id: 63, title: 'EE - ЭСТОНИЯ', type: 'EE' },
  { id: 64, title: 'EG - ЕГИПЕТ', type: 'EG' },
  { id: 65, title: 'EH - ЗАПАДНАЯ САХАРА', type: 'EH' },
  { id: 66, title: 'ER - ЭРИТРЕЯ', type: 'ER' },
  { id: 67, title: 'ES - ИСПАНИЯ', type: 'ES' },
  { id: 68, title: 'ET - ЭФИОПИЯ', type: 'ET' },
  { id: 69, title: 'FI - ФИНЛЯНДИЯ', type: 'FI' },
  { id: 70, title: 'FJ - ФИДЖИ', type: 'FJ' },
  { id: 71, title: 'FK - ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)', type: 'FK' },
  { id: 72, title: 'FM - МИКРОНЕЗИЯ (ФЕДЕРАТИВНЫЕ ШТАТЫ)', type: 'FM' },
  { id: 73, title: 'FO - ФАРЕРСКИЕ ОСТРОВА', type: 'FO' },
  { id: 74, title: 'FR - ФРАНЦИЯ', type: 'FR' },
  { id: 75, title: 'GA - ГАБОН', type: 'GA' },
  { id: 76, title: 'GB - СОЕДИНЕННОЕ КОРОЛЕВСТВО', type: 'GB' },
  { id: 77, title: 'GD - ГРЕНАДА', type: 'GD' },
  { id: 78, title: 'GE - ГРУЗИЯ', type: 'GE' },
  { id: 79, title: 'GF - ФРАНЦУЗСКАЯ ГВИАНА', type: 'GF' },
  { id: 80, title: 'GG - ГЕРНСИ', type: 'GG' },
  { id: 81, title: 'GH - ГАНА', type: 'GH' },
  { id: 82, title: 'GI - ГИБРАЛТАР', type: 'GI' },
  { id: 83, title: 'GL - ГРЕНЛАНДИЯ', type: 'GL' },
  { id: 84, title: 'GM - ГАМБИЯ', type: 'GM' },
  { id: 85, title: 'GN - ГВИНЕЯ', type: 'GN' },
  { id: 86, title: 'GP - ГВАДЕЛУПА', type: 'GP' },
  { id: 87, title: 'GQ - ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ', type: 'GQ' },
  { id: 88, title: 'GR - ГРЕЦИЯ', type: 'GR' },
  { id: 89, title: 'GS - ЮЖН. ДЖОРДЖИЯ И ЮЖН. САНДВИЧ. ОСТРОВА', type: 'GS' },
  { id: 90, title: 'GT - ГВАТЕМАЛА', type: 'GT' },
  { id: 91, title: 'GU - ГУАМ', type: 'GU' },
  { id: 92, title: 'GW - ГВИНЕЯ-БИСАУ', type: 'GW' },
  { id: 93, title: 'GY - ГАЙАНА', type: 'GY' },
  { id: 94, title: 'HK - ГОНКОНГ', type: 'HK' },
  { id: 95, title: 'HM - ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД', type: 'HM' },
  { id: 96, title: 'HN - ГОНДУРАС', type: 'HN' },
  { id: 97, title: 'HR - ХОРВАТИЯ', type: 'HR' },
  { id: 98, title: 'HT - ГАИТИ', type: 'HT' },
  { id: 99, title: 'HU - ВЕНГРИЯ', type: 'HU' },
  { id: 100, title: 'ID - ИНДОНЕЗИЯ', type: 'ID' },
  { id: 101, title: 'IE - ИРЛАНДИЯ', type: 'IE' },
  { id: 102, title: 'IL - ИЗРАИЛЬ', type: 'IL' },
  { id: 103, title: 'IM - ОСТРОВ МЭН', type: 'IM' },
  { id: 104, title: 'IN - ИНДИЯ', type: 'IN' },
  { id: 105, title: 'IO - БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ', type: 'IO' },
  { id: 106, title: 'IQ - ИРАК', type: 'IQ' },
  { id: 107, title: 'IR - ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)', type: 'IR' },
  { id: 108, title: 'IS - ИСЛАНДИЯ', type: 'IS' },
  { id: 109, title: 'IT - ИТАЛИЯ', type: 'IT' },
  { id: 110, title: 'JE - ДЖЕРСИ', type: 'JE' },
  { id: 111, title: 'JM - ЯМАЙКА', type: 'JM' },
  { id: 112, title: 'JO - ИОРДАНИЯ', type: 'JO' },
  { id: 113, title: 'JP - ЯПОНИЯ', type: 'JP' },
  { id: 114, title: 'KE - КЕНИЯ', type: 'KE' },
  { id: 115, title: 'KG - КЫРГЫЗСТАН', type: 'KG' },
  { id: 116, title: 'KH - КАМБОДЖА', type: 'KH' },
  { id: 117, title: 'KI - КИРИБАТИ', type: 'KI' },
  { id: 118, title: 'KM - КОМОРЫ', type: 'KM' },
  { id: 119, title: 'KN - СЕНТ-КИТС И НЕВИС', type: 'KN' },
  { id: 120, title: 'KP - КОРЕЯ (НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА)', type: 'KP' },
  { id: 121, title: 'KR - КОРЕЯ, РЕСПУБЛИКА', type: 'KR' },
  { id: 122, title: 'KW - КУВЕЙТ', type: 'KW' },
  { id: 123, title: 'KY - ОСТРОВА КАЙМАН', type: 'KY' },
  { id: 124, title: 'KZ - КАЗАХСТАН', type: 'KZ' },
  { id: 125, title: 'LA - ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА', type: 'LA' },
  { id: 126, title: 'LB - ЛИВАН', type: 'LB' },
  { id: 127, title: 'LC - СЕНТ-ЛЮСИЯ', type: 'LC' },
  { id: 128, title: 'LI - ЛИХТЕНШТЕЙН', type: 'LI' },
  { id: 129, title: 'LK - ШРИ-ЛАНКА', type: 'LK' },
  { id: 130, title: 'LR - ЛИБЕРИЯ', type: 'LR' },
  { id: 131, title: 'LS - ЛЕСОТО', type: 'LS' },
  { id: 132, title: 'LT - ЛИТВА', type: 'LT' },
  { id: 133, title: 'LU - ЛЮКСЕМБУРГ', type: 'LU' },
  { id: 134, title: 'LV - ЛАТВИЯ', type: 'LV' },
  { id: 135, title: 'LY - ЛИВИЯ', type: 'LY' },
  { id: 136, title: 'MA - МАРОККО', type: 'MA' },
  { id: 137, title: 'MC - МОНАКО', type: 'MC' },
  { id: 138, title: 'MD - МОЛДОВА, РЕСПУБЛИКА', type: 'MD' },
  { id: 139, title: 'ME - ЧЕРНОГОРИЯ', type: 'ME' },
  { id: 140, title: 'MF - СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)', type: 'MF' },
  { id: 141, title: 'MG - МАДАГАСКАР', type: 'MG' },
  { id: 142, title: 'MH - МАРШАЛЛОВЫ ОСТРОВА', type: 'MH' },
  { id: 143, title: 'MK - РЕСПУБЛИКА МАКЕДОНИЯ', type: 'MK' },
  { id: 144, title: 'ML - МАЛИ', type: 'ML' },
  { id: 145, title: 'MM - МЬЯНМА', type: 'MM' },
  { id: 146, title: 'MN - МОНГОЛИЯ', type: 'MN' },
  { id: 147, title: 'MO - МАКАО', type: 'MO' },
  { id: 148, title: 'MP - СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА', type: 'MP' },
  { id: 149, title: 'MQ - МАРТИНИКА', type: 'MQ' },
  { id: 150, title: 'MR - МАВРИТАНИЯ', type: 'MR' },
  { id: 151, title: 'MS - МОНТСЕРРАТ', type: 'MS' },
  { id: 152, title: 'MT - МАЛЬТА', type: 'MT' },
  { id: 153, title: 'MU - МАВРИКИЙ', type: 'MU' },
  { id: 154, title: 'MV - МАЛЬДИВЫ', type: 'MV' },
  { id: 155, title: 'MW - МАЛАВИ', type: 'MW' },
  { id: 156, title: 'MX - МЕКСИКА', type: 'MX' },
  { id: 157, title: 'MY - МАЛАЙЗИЯ', type: 'MY' },
  { id: 158, title: 'MZ - МОЗАМБИК', type: 'MZ' },
  { id: 159, title: 'NA - НАМИБИЯ', type: 'NA' },
  { id: 160, title: 'NC - НОВАЯ КАЛЕДОНИЯ', type: 'NC' },
  { id: 161, title: 'NE - НИГЕР', type: 'NE' },
  { id: 162, title: 'NF - ОСТРОВ НОРФОЛК', type: 'NF' },
  { id: 163, title: 'NG - НИГЕРИЯ', type: 'NG' },
  { id: 164, title: 'NI - НИКАРАГУА', type: 'NI' },
  { id: 165, title: 'NL - НИДЕРЛАНДЫ', type: 'NL' },
  { id: 166, title: 'NO - НОРВЕГИЯ', type: 'NO' },
  { id: 167, title: 'NP - НЕПАЛ', type: 'NP' },
  { id: 168, title: 'NR - НАУРУ', type: 'NR' },
  { id: 169, title: 'NU - НИУЭ', type: 'NU' },
  { id: 170, title: 'NZ - НОВАЯ ЗЕЛАНДИЯ', type: 'NZ' },
  { id: 171, title: 'OM - ОМАН', type: 'OM' },
  { id: 172, title: 'PA - ПАНАМА', type: 'PA' },
  { id: 173, title: 'PE - ПЕРУ', type: 'PE' },
  { id: 174, title: 'PF - ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ', type: 'PF' },
  { id: 175, title: 'PG - ПАПУА НОВАЯ ГВИНЕЯ', type: 'PG' },
  { id: 176, title: 'PH - ФИЛИППИНЫ', type: 'PH' },
  { id: 177, title: 'PK - ПАКИСТАН', type: 'PK' },
  { id: 178, title: 'PL - ПОЛЬША', type: 'PL' },
  { id: 179, title: 'PM - СЕНТ-ПЬЕР И МИКЕЛОН', type: 'PM' },
  { id: 180, title: 'PN - ПИТКЭРН', type: 'PN' },
  { id: 181, title: 'PR - ПУЭРТО-РИКО', type: 'PR' },
  { id: 182, title: 'PS - ПАЛЕСТИНА, ГОСУДАРСТВО', type: 'PS' },
  { id: 183, title: 'PT - ПОРТУГАЛИЯ', type: 'PT' },
  { id: 184, title: 'PW - ПАЛАУ', type: 'PW' },
  { id: 185, title: 'PY - ПАРАГВАЙ', type: 'PY' },
  { id: 186, title: 'QA - КАТАР', type: 'QA' },
  { id: 187, title: 'RE - РЕЮНЬОН', type: 'RE' },
  { id: 188, title: 'RO - РУМЫНИЯ', type: 'RO' },
  { id: 189, title: 'RS - СЕРБИЯ', type: 'RS' },
  { id: 190, title: 'RU - РОССИЯ', type: 'RU' },
  { id: 191, title: 'RW - РУАНДА', type: 'RW' },
  { id: 192, title: 'SA - САУДОВСКАЯ АРАВИЯ', type: 'SA' },
  { id: 193, title: 'SB - СОЛОМОНОВЫ ОСТРОВА', type: 'SB' },
  { id: 194, title: 'SC - СЕЙШЕЛЫ', type: 'SC' },
  { id: 195, title: 'SD - СУДАН', type: 'SD' },
  { id: 196, title: 'SE - ШВЕЦИЯ', type: 'SE' },
  { id: 197, title: 'SG - СИНГАПУР', type: 'SG' },
  { id: 198, title: 'SH - СВ. ЕЛЕНА, О. ВОЗНЕСЕНИЯ, ТР.-ДА-КУНЬЯ', type: 'SH' },
  { id: 199, title: 'SI - СЛОВЕНИЯ', type: 'SI' },
  { id: 200, title: 'SJ - ШПИЦБЕРГЕН И ЯН МАЙЕН', type: 'SJ' },
  { id: 201, title: 'SK - СЛОВАКИЯ', type: 'SK' },
  { id: 202, title: 'SL - СЬЕРРА-ЛЕОНЕ', type: 'SL' },
  { id: 203, title: 'SM - САН-МАРИНО', type: 'SM' },
  { id: 204, title: 'SN - СЕНЕГАЛ', type: 'SN' },
  { id: 205, title: 'SO - СОМАЛИ', type: 'SO' },
  { id: 206, title: 'SR - СУРИНАМ', type: 'SR' },
  { id: 207, title: 'SS - ЮЖНЫЙ СУДАН', type: 'SS' },
  { id: 208, title: 'ST - САН-ТОМЕ И ПРИНСИПИ', type: 'ST' },
  { id: 209, title: 'SV - ЭЛЬ-САЛЬВАДОР', type: 'SV' },
  { id: 210, title: 'SX - СЕН-МАРТЕН (нидерландская часть)', type: 'SX' },
  { id: 211, title: 'SY - СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА', type: 'SY' },
  { id: 212, title: 'SZ - ЭСВАТИНИ', type: 'SZ' },
  { id: 213, title: 'TC - ОСТРОВА ТЕРКС И КАЙКОС', type: 'TC' },
  { id: 214, title: 'TD - ЧАД', type: 'TD' },
  { id: 215, title: 'TF - ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ', type: 'TF' },
  { id: 216, title: 'TG - ТОГО', type: 'TG' },
  { id: 217, title: 'TH - ТАИЛАНД', type: 'TH' },
  { id: 218, title: 'TJ - ТАДЖИКИСТАН', type: 'TJ' },
  { id: 219, title: 'TK - ТОКЕЛАУ', type: 'TK' },
  { id: 220, title: 'TL - ТИМОР-ЛЕСТЕ', type: 'TL' },
  { id: 221, title: 'TM - ТУРКМЕНИСТАН', type: 'TM' },
  { id: 222, title: 'TN - ТУНИС', type: 'TN' },
  { id: 223, title: 'TO - ТОНГА', type: 'TO' },
  { id: 224, title: 'TR - ТУРЦИЯ', type: 'TR' },
  { id: 225, title: 'TT - ТРИНИДАД И ТОБАГО', type: 'TT' },
  { id: 226, title: 'TV - ТУВАЛУ', type: 'TV' },
  { id: 227, title: 'TW - ТАЙВАНЬ (КИТАЙ)', type: 'TW' },
  { id: 228, title: 'TZ - ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА', type: 'TZ' },
  { id: 229, title: 'UA - УКРАИНА', type: 'UA' },
  { id: 230, title: 'UG - УГАНДА', type: 'UG' },
  { id: 231, title: 'UM - МАЛЫЕ ТИХООКЕАН. ОТДАЛЕН. ОСТ-ВА С.Ш.', type: 'UM' },
  { id: 232, title: 'US - СОЕДИНЕННЫЕ ШТАТЫ', type: 'US' },
  { id: 233, title: 'UY - УРУГВАЙ', type: 'UY' },
  { id: 234, title: 'UZ - УЗБЕКИСТАН', type: 'UZ' },
  { id: 235, title: 'VA - ПАПСКИЙ ПРЕСТОЛ (ГОС.-ГОРОД ВАТИКАН)', type: 'VA' },
  { id: 236, title: 'VC - СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ', type: 'VC' },
  { id: 237, title: 'VE - ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)', type: 'VE' },
  { id: 238, title: 'VG - ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)', type: 'VG' },
  { id: 239, title: 'VI - ВИРГИНСКИЕ ОСТРОВА (США)', type: 'VI' },
  { id: 240, title: 'VN - ВЬЕТНАМ', type: 'VN' },
  { id: 241, title: 'VU - ВАНУАТУ', type: 'VU' },
  { id: 242, title: 'WF - УОЛЛИС И ФУТУНА', type: 'WF' },
  { id: 243, title: 'WS - САМОА', type: 'WS' },
  { id: 244, title: 'YE - ЙЕМЕН', type: 'YE' },
  { id: 245, title: 'YT - МАЙОТТА', type: 'YT' },
  { id: 246, title: 'ZA - ЮЖНАЯ АФРИКА', type: 'ZA' },
  { id: 247, title: 'ZM - ЗАМБИЯ', type: 'ZM' },
  { id: 248, title: 'ZW - ЗИМБАБВЕ', type: 'ZW' },
];

export const InformationFieldsName = {
  organisation_code: 'ИНН отправителя',
  registration_date: 'Дата регистрации декларации',
  registration_number: 'Регистрационный номер декларации',
  custom_code: 'Код таможенного органа',
  solution_code: 'Код принятого решения',
  solution_date: 'Дата принятия решения',
  currency_rate: 'Курс валюты',
  currency: 'Валюта',
}
