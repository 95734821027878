import React from 'react';
import PropTypes from 'prop-types';
import { shouldUpdate } from 'recompact';
import { Card, Title, Wrap } from './styled';

const FilledStep = ({ title }) => (
  <Wrap>
    <Card>
      <Title>{title}</Title>
    </Card>
  </Wrap>
);

FilledStep.propTypes = {
  title: PropTypes.string.isRequired,
};


export default shouldUpdate(() => false)(FilledStep);
