import React from 'react';
import moment from 'moment';
import { Value } from 'src/fragments/Table/Cell/value';
import { countryCodes } from 'src/constants/countryCodes';
import { Translate } from 'src/common_components/Translate/Translate';
import { CisCodeWrap } from './Cis.styled';

moment.updateLocale(moment.locale(), { invalidDate: '' });

export const columnsKZ = [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    sortable: false,
    width: 60,
  },
  {
    id:'tnved',
    accessor:'tnved',
    Cell: ({row}) => {
      return (
        <Value>{row.tnved || '-'}</Value>
      )
    },
    Header: Translate('ТН ВЭД'),
    sortable: false,
    width: 200,
  },
  {
    id:'name',
    accessor:'name',
    Cell: ({row}) => {
      return (
        <Value>{row.name || '-'}</Value>
      )
    },
    Header: Translate('Наименование товара'),
    sortable: false,
    sortDependence: 'tnved',
    width: 350,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    sortable: false,
    width: 200,
  },
  {
    id: 'numberDT',
    accessor: 'numberDT',
    Cell: ({ row }) => {
      return <Value>{row.numberDT || '-'}</Value>;
    },
    Header: Translate('Номер товара в ДТ'),
    sortable: false,
    width: 200,
  },
  {
    id: 'country',
    accessor: 'country',
    Cell: ({ row }) => {
      const country = countryCodes.find(item => item.type === row.country);
      return <Value>{country ? country.title : '-'}</Value>;
    },
    Header: Translate('Страна производства'),
    sortable: false,
    width: 350,
  },
  {
    id: 'codes',
    accessor: row => (
      <div>
        <Value>{row.codes[0]}</Value>
      </div>
    ),
    Header: Translate('Код маркировки'),
    sortable: false,
    width: 200,
  },
  // {
  //   id: 'cis',
  //   accessor: row => (
  //     <div>
  //       <Value>{row.cis}</Value>
  //     </div>
  //   ),
  //   Header: 'Код маркировки',
  //   disableSortBy: true,
  //   width: '200',
  // },
];
// export const columns = [
//   {
//     Header: 'Дата нанесения',
//     id: 'producedDate',
//     accessor: d => `${moment(d.emissionDate).format('DD.MM.YYYY')}`,
//     width: 129,
//   },
//   {
//     Header: 'Статус кода',
//     id: 'status',
//     accessor: d => (
//       <div>
//         <Icon type={getIconNameByCISStatus(d.status)} spacingRight={6} />
//         {cisStatuses[d.status]}
//       </div>
//     ),
//     width: 176,
//   },
//   {
//     Header: 'Код',
//     id: 'cis',
//     accessor: d => (
//       <CisCodeWrap target={'_blank'} href={`/cis/list/${encodeURIComponent(d.cis)}`}>
//         {d.cis}
//       </CisCodeWrap>
//     ),
//     width: 328,
//   },
//   {
//     Header: 'Тип упаковки',
//     id: 'cisPackageType',
//     accessor: d => {
//       const cisPackageType = get(d, 'cisPackageType', '').toLowerCase();
//       const iconType = getIconNameByPackType(cisPackageType);
//       return (
//         <div>
//           <IconWrapper type={iconType}>
//             <Icon type={iconType} spacingRight={6} />
//           </IconWrapper>
//           {packTypes[cisPackageType]}
//         </div>
//       )
//     },
//     width: 198,
//   },
//   {
//     Header: 'Товар',
//     id: 'gtin',
//     accessor: d => (
//       <div>
//         <div>{d.gtin}</div>
//         <div>{d.productName}</div>
//       </div>
//     ),
//     width: 211,
//   },
//   {
//     Header: 'Производитель',
//     id: 'producerInn',
//     accessor: d => (
//       <div>
//         <div>{d.producerInn}</div>
//         <div>{d.producerName}</div>
//       </div>
//     ),
//     width: 226,
//   },
//   {
//     Header: 'Текущий владелец',
//     id: 'ownerInn',
//     accessor: d => (
//       <div>
//         <div>{d.ownerInn}</div>
//         <div>{d.ownerName}</div>
//       </div>
//     ),
//     //width: 226,
//   },
// ].map(v => ({ ...v, arrayName: cisKey }));

export const columnsUZ = [
  {
    id:'name',
    accessor:'name',
    Cell: ({row}) => {
      return (
        <Value>{row.name || '-'}</Value>
      )
    },
    Header: 'Наименование',
    sortable: false,
    sortDependence: 'tnved',
    width: 350,
  },
  {
    id:'gtin',
    accessor:'gtin',
    Cell: ({row}) => {
      return (
        <Value>{row.gtin || '-'}</Value>
      )
    },
    Header: 'GTIN',
    sortable: false,
    width: 200,
  },
  {
    id:'tnved',
    accessor:'tnved',
    Cell: ({row}) => {
      return (
        <Value>{row.tnved || '-'}</Value>
      )
    },
    Header: 'Код ТН ВЭД',
    sortable: false,
    width: 200
  },
  {
    id:'country',
    accessor:'country',
    Cell: ({row}) => {
      const country = countryCodes.find((item) => item.type === row.country);
      return (
        <Value>{country ? country.title : '' }</Value>
      )
    },
    Header: 'Страна производства',
    sortable: false,
    width: 350,
  },
  {
    id:'permit_document',
    accessor:'permit_document',
    Cell: ({row}) => {
      return (
        <Value>{row.permit_document || '-'}</Value>
      )
    },
    Header: 'Номер разрешительного документа',
    sortable: false,
    width: 200,
  },
  {
    id:'doc_allow_date',
    accessor:'doc_allow_date',
    Cell: ({row}) => {
      return (
        <Value>{row.doc_allow_date || '-'}</Value>
      )
    },
    Header: 'Дата разрешительного документа',
    sortable: false,
    width: 200,
  },
  {
    id:'numberDT',
    accessor:'numberDT',
    Cell: ({row}) => {
      return (
        <Value>{row.numberDT || '-'}</Value>
      )
    },
    Header: 'Номер товара в ДТ',
    sortable: false,
    width: 200,
  },
  {
    Header: 'Код маркировки',
    id: 'codes',
    accessor: d => (
      <CisCodeWrap target={'_blank'} href={`/cis/list/${encodeURIComponent(d.codes)}`}>
        {d.codes}
      </CisCodeWrap>
    ),
    width: 328,
  },
  {
    id:'custom_cost',
    accessor:'custom_cost',
    Cell: ({row}) => {
      return (
        <Value>{row.custom_cost || '-'}</Value>
      )
    },
    Header: 'Таможенная стоимость ввезенного товара',
    sortable: false,
    width: 200,
  },
  {
    id:'item_price',
    accessor:'item_price',
    Cell: ({row}) => {
      return (
        <Value>{row.item_price || '-'}</Value>
      )
    },
    Header: 'Цена единицы товара',
    sortable: false,
    width: 200,
  },
  {
    id:'cis_tax',
    accessor:'cis_tax',
    Cell: ({row}) => {
      return (
        <Value>{row.cis_tax || '-'}</Value>
      )
    },
    Header: 'Сумма акциза',
    sortable: false,
    width: 200,
  },
  {
    id:'vat_tax',
    accessor:'vat_tax',
    Cell: ({row}) => {
      return (
        <Value>{row.vat_tax || '-'}</Value>
      )
    },
    Header: 'Сумма НДС',
    sortable: false,
    width: 200,
  },
];
