import {regTheme} from './registration';
import {tabsReportsThemeKZ, tabsReportsThemeKG, tabsReportsThemeUZ} from './reports';


export {
  regTheme as tabsRegThemeKZ,
  tabsReportsThemeKZ,
  tabsReportsThemeKG,
  tabsReportsThemeUZ
}
