import React from 'react';
import PropTypes from 'prop-types';

import { Ellipsis } from './styled';


const propTypes = {
  value: PropTypes.string,
};

const defaultProps = {
  value: '',
};

const EllipsisString = ({ value }) => (
  <Ellipsis>{value}</Ellipsis>
);

EllipsisString.propTypes = propTypes;
EllipsisString.defaultProps = defaultProps;

export default EllipsisString;
