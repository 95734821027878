import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { StepTitle } from '../Document/Document.styled';
import { required, validateRequiredAndLength } from '../../../../common_components/Form/utils/validations';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';

const InfoFields = ({ finalConfirm }) => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <StepTitle noMarginTop>{t('Общие данные')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="applicantInn"
            placeholder={t('ИНН/ПИНФЛ заявителя')}
            required
            validate={required}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="applicantName"
            placeholder={t('Наименование заявителя')}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="aic"
            placeholder={t('АИК')}
            format={(value)=> value ? value.replace(/[^\d]/g, '') : value}
            InputProps={{
              inputProps: {
                maxLength: 25,
              }
            }}
            required
            disabled={finalConfirm}
            validate={validateRequiredAndLength(25)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InfoFields;
