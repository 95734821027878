import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Letter } from './Letter';

const Wrap = styled.div`
  display: flex;
`;

const LetterWrap = styled.div`
  margin-right: 6px;
`

export class LetterBoard extends Component {

  render() {
    return <Wrap>
      {this.props.value && this.props.value.split('').map((v, i) => <LetterWrap key={i}><Letter value={v} /></LetterWrap>)}
    </Wrap>;
  }
}


LetterBoard.propTypes = {
  value: PropTypes.string.isRequired
};