import React, {useState, useCallback} from 'react';
import WorkNotificationStyles from './WorkNotification.styles';
import { Box, withStyles } from '@material-ui/core';
import { Dialog, Typography, Button } from '@crpt-ui/core';
import moment from 'moment';
import * as Icons from '@crpt-ui/icons';


const WorkNotificationComponent = ({
  title,
  text,
  overDate,
  storageKey,
  classes
                                   }) => {
  const isNotificationSeen = !Boolean(window.localStorage.getItem(storageKey));
  const isDateOver = moment(new Date()).isAfter(overDate);
  const showNotification = isDateOver ? false : isNotificationSeen;

  const [isModalOpen, setModalOpen] = useState(showNotification);

  const closeModal = useCallback(() => {
    window.localStorage.setItem(storageKey, 'true');
    setModalOpen(false)
  }, []);
  return (
    <Dialog open={isModalOpen} maxWidth="md" onClose={closeModal}>
      <Box mt={-2} mx={-2} mb={4} className={classes.header} >
        <Typography className={classes.title} variant="h4" color="textPrimary">
          {title}
        </Typography>
        <div>
          <Button onClick={closeModal} size="sm" variant="text" icon={<Icons.Close />} />
        </div>
      </Box>

      <Box mx={-2} >
        <Typography variant="body1" color="textPrimary">
          {text}
        </Typography>
      </Box>
    </Dialog>
  )
}

export const WorkNotification =  withStyles(WorkNotificationStyles)(WorkNotificationComponent)
