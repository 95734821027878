import React from 'react';
import {Icon} from '@crpt/react-icon';

import {
  MainWrap,
  OptionWrap,
  CheckedWrap,
  IconWrap,
  Textarea,
  IconOptionWrap,
  OptionContent,
  OptionText,
} from './index.styled';

export const RenderOption = ({ value, active, onSelect }) => {
  return (
    <OptionWrap onClick={e => onSelect(e, value)}>
      <OptionContent>
        <IconOptionWrap>
          <Icon type={active ? 'selected-checkbox' : 'empty-checkbox'} />
        </IconOptionWrap>
        <OptionText>{value.title}</OptionText>
      </OptionContent>


    </OptionWrap>
  );
};

export class RenderValues extends React.Component {
  div;

  onTextareaKeydown = (e, onFilter) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  onChange = e => {
    const { onFilter } = this.props;
    onFilter(e.target.value);
    this.div.innerHTML = e.target.value;
    this.setState({
      width: this.div.clientWidth + 5 + 'px',
      value: e.target.value,
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      width: '21px',
      value: '',
    };
  }
  // eslint-disable-next-line
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    const { container, onFilter } = this.props;
    if (!container) return;
    if (container.contains(e.target)) return;

    this.setState({
      value: '',
      width: '21px',
    });

    onFilter('');
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    this.div = document.createElement('div');
    document.body.appendChild(this.div);
    this.div.style.display = 'inline-block';
    this.div.style.pointerEvents = 'none';
    this.div.style.visibility = 'hidden';
    this.div.style.fontSize = '16px';
  }

  render() {
    const { onFocus, selectedIds, onFilter, onRemove } = this.props;

    const selected = this.props.values.filter(v => ~selectedIds.indexOf(v.id));

    return (
      <MainWrap onClick={onFocus}>
        {selected.map((v, i) => (
          <CheckedWrap key={i}>
            {v.title}
            <IconWrap onClick={e => onRemove(v.id)}>
              <Icon type={'cross'} />
            </IconWrap>
          </CheckedWrap>
        ))}
        <Textarea
          onFocus={onFocus}
          width={this.state.width}
          value={this.state.value}
          onKeyDown={this.onTextareaKeydown}
          onChange={this.onChange}
          rows="1"
        />
      </MainWrap>
    );
  }
}
