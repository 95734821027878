import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import { Tooltip } from '@crpt/react-tooltip';
import { Icon } from '@crpt/react-icon';
import styled from 'styled-components';
import { HintedEmailInput } from '@crpt/react-dadata';
import { Wrap } from '../_refactor/Fields.styled';
import * as appSelectors from '../../../App/App.selectors';
import {Translate} from '../../../Translate/Translate';

const PureHintedEmailInput = memo(HintedEmailInput);


export const IconWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TextWrap = styled.div`
  width: 247px;
  display: inline-block;
`;


class HintedEmailInputAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    dadataSettings: PropTypes.shape({}).isRequired,
    isError: PropTypes.bool,
    onChange: PropTypes.func,
    hint: PropTypes.element,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onChange: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    hint: null,
  };

  onChangeTimeout;

  onChange = (val) => {
    const { fieldInput, onChange } = this.props;

    if (this.onChangeTimeout) clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(() => fieldInput && fieldInput.onChange(val), 100);
    if (onChange) onChange(val);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (isError && fieldMeta && fieldMeta.dirtySinceLastSubmit && !fieldMeta.error) return false;
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total, fieldMeta } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return (index && (total > 1)) ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput = {},
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dadataSettings,
      placeholder,
      errorPlaceholder,
      hint,
      ...passProps
    } = this.props;

    const value = isUndefined(fieldInput.value) ? '' : `${fieldInput.value}`;

    return (
      <Wrap onBlur={fieldInput.onBlur} marginBottom={marginBottom}>
        <PureHintedEmailInput
          apiKey={dadataSettings.apiKey}
          apiURL={dadataSettings.apiEmailURL}
          hideOptionsPanel={!dadataSettings}
          filterDisabled
          savePlaceholder
          {...passProps}
          onChange={this.onChange}
          isError={this.isError()}
          placeholder={this.getPlaceholder()}
          defaultText={value}
        />
        <IconWrap>
          <Tooltip
            position="top"
            content={(
              <TextWrap>
                <div>{Translate('Укажите действующий E-mail для')}</div>
                <div>{Translate('отправки информации о работе')}</div>
                <div>{Translate('организации в системе ИС МП')}</div>
              </TextWrap>
            )}
          >
            <Icon type="info" size={16} />
          </Tooltip>
        </IconWrap>
      </Wrap>
    );
  }
}

const mapState = state => ({
  dadataSettings: appSelectors.config(state).dadataSettings,
});


export default connect(mapState)(HintedEmailInputAdapter);
