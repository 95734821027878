import styled from 'styled-components';
import { get } from 'lodash';

const ContentWrapper = styled.div`
  position: relative;
  background: ${props => get(props, 'theme.Content.backgroundColor', 'black')};
  margin-bottom: 0px;
  margin-top: ${props => props.hasMrginTop ? get(props, 'theme.Content.marginTop', '100px') : 0};
  min-height: ${({ minHeight }) => (minHeight ? '500px' : 'auto')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: ${props => get(props, 'theme.Content.shadowX', '0')}
    ${props => get(props, 'theme.Content.shadowY', '0')}
    ${props => get(props, 'theme.Content.shadowBlur', '0')}
    ${props => get(props, 'theme.Content.shadowColor', 'black')};
`;

export default ContentWrapper;
