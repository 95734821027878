import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  downloadWrapper: {
    fontWeight: 600,
    '& span': {
      display: 'flex',
    },
    '& svg path': {
      fill: 'currentColor',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 120px)',
  },
  contentWrapper: {
    background: 'white',
    boxShadow: '0px 1px 8px rgba(41, 49, 53, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  container: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  unloadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: 40,
    position: 'relative',
  },
  devider: {
    backgroundColor: '#F2F2F2',
    height: 1,
    width: '100%',
  },
  subHeader: {
    fontSize: 14,
    lineHeight: 24 / 14,
    letterSpacing: '.02em',
    fontWeight: 700,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  statusIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 10,
    },
  },
  amountWrapper: {
    color: '#B8B8B8',
    fontSize: 12,
    fontWeight: 600,
    marginRight: 50,
    marginTop: 40,
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));
