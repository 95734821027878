import {selector} from 'src/utils/selector';
import {subject} from './CisRegistration.actions'

export const data = state => selector(state, `Requests.RequestList.${subject}.data`);
export const loaded = state => selector(state, `Requests.RequestList.${subject}.loaded`);
export const errorInfo = state => selector(state, `Requests.RequestList.${subject}.errorInfo`);
export const docType = state => selector(state, `Requests.RequestList.${subject}.docType`);
export const cisList = state => selector(state, `Requests.RequestList.${subject}.cisList`);

export const step = state => selector(state, 'Requests.RequestList.CisRegistration.step');
export const errorText = state => selector(state, 'Requests.RequestList.CisRegistration.errorText');
