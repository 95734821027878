import React from 'react';
import { compose } from 'redux';
import { get, isEmpty } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import {
  cisKey,
  contentBodyConfirm,
  goodsKey,
  steps,
  tabs,
} from '../ImportThirdCountry.constants';
import {
  PageWrapper,
  styleCommandBar,
  FixedWrap,
  StepWrapper,
  Wrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import { columnsKZ, columnsUZ } from './Cis.constants';
import {
  actionSelectOptions,
  CodesRenderer,
  columnsKG,
  ContentErrorLoading,
} from '../Goods/Goods.constants';
import GoodsCommandBar from '../Goods/CommandBar';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import GoodsEditProduct from '../Goods/Goods.edit.product';
import CisListUploadModalContent from '../Goods/CisListUploadModalContent/CisListUploadModalContent';
import GoodsIcon from '../Goods/svg/GoodsIcon.svg';
import { Select as SelectRow } from '@crpt-ui/core';
import { connect } from 'react-redux';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import PrimaryActionButton from 'src/common_components/Buttons/MuiPrimaryActionMediumButton';
import SignModalContent from './SignModalContent';
import { Translate } from 'src/common_components/Translate/Translate';
import * as actions from '../ducks/ImportThirdCountry.actions';
import SignModalContentUz from './SignModalContentUz';

const keyField = 'gtin';

class CisTotal extends React.Component {
  constructor(props) {
    super(props);

    this.instance = React.createRef();

    this.state = {
      selectedRows: [],
      isModalOpen: false,
      page: 0,
      commandBarLeft: [
        <CommonActionButton onClick={this.toggleDialog}>
          {Translate('Отменить')}
        </CommonActionButton>,
        <CommonActionButton onClick={this.onSaveDraft}>
          {Translate('Сохранить как черновик')}
        </CommonActionButton>,
      ],

      topBarLeft: [],
    };
  }

  getTopBarLeft = () => {
    const { countryCode, formValues } = this.props;
    const { topBarLeft } = this.state;
    const topBar = [...topBarLeft];
    if (countryCode === 'KZ' || countryCode === 'UZ') {
      topBar.push(
        <FixedWrap width={'260px'} stretch>
          <SelectRow
            options={actionSelectOptions}
            label={Translate('Действие')}
            onChange={e => {
              const selectedRows = [...this.state.selectedRows];
              const products = formValues[goodsKey];

              const selected = products
                .map((item, ndx) => {
                  if (selectedRows.includes(item[keyField])) return ndx;
                  return null;
                })
                .filter(item => item != null);

              this.onOpenProductModal(selected);
              this.goodsList.clearSelected();
              if (this.goodsList.state.isAllSelected)
                this.goodsList.toggleAll();
            }}
            disabled={this.state.selectedRows.length < 1}
          />
        </FixedWrap>,
      );
    }
    return topBar;
  };

  onConvert = () => {
    // console.log('onConvert');
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
      countryCode,
    } = this.props;

    const invalidProducts = formValues.products
      .filter(item => !item.tnved)
      .map(item => item.gtin);
    if (invalidProducts.length > 0) {
      Toast.showError({
        content: `В списке присутствует товары без ТНВЭД! Товары : ${invalidProducts.join(
          ',',
        )}`,
      });
    } else {
      if (countryCode === 'UZ') {
        onSetContent(
          <SignModalContentUz
            onCloseModal={onCloseModal}
            formMutators={formMutators}
            values={formValues}
          />,
        );
      } else {
        onSetContent(
          <SignModalContent
            onCloseModal={onCloseModal}
            formMutators={formMutators}
            values={formValues}
          />,
        );
      }
    }
    onOpenModal();
  };

  isNextStepDisabled = () => {
    const { show, formErrors } = this.props;
    if (!show) return true;

    const prepared = JSON.parse(JSON.stringify(formErrors));

    delete prepared[cisKey];
    delete prepared[goodsKey];

    const products = this.getProducts();

    const isFilledProducts = products => {
      for (let i = 0; i < products.length; i++) {
        if (!products[i].numberDT || !products[i].country) return true;
      }
      return false;
    };

    return (
      !!Object.keys(prepared).length ||
      isEmpty(products) ||
      isFilledProducts(products)
    );
  };

  getProducts = () => {
    const { formValues } = this.props;
    const products = formValues[goodsKey] || [];
    return products;
  };

  onRowSelect = selected => {
    const selectedKeys = Object.keys(selected);
    this.setState({
      selectedRows: selectedKeys,
    });
  };

  tabsCurrent = () => {
    const { show } = this.props;

    if (!show) return tabs;
    const { onSetStep } = this.props;
    return tabs
      .map(tab =>
        tab.id === steps.cis
          ? tab
          : {
              ...tab,
              onClick: () => {
                onSetStep(tab.id);
              },
            },
      )
      .map(tab => (tab.id <= steps.cis ? tab : { ...tab, disabled: true }));
  };

  onOpenCisModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
    } = this.props;

    onSetContent(
      <CisListUploadModalContent
        row={row}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        title={Translate('Коды маркировки')}
      />,
    );
    onOpenModal();
  };

  onCancelGoodsEdit = (value, index) => {
    const {
      formMutators: { update },
    } = this.props;

    update(goodsKey, index, value);
  };

  onOpenProductModal = selectedRows => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators: { update },
      formValues,
      countryCode,
    } = this.props;
    const columns = countryCode === 'KZ' ? columnsKZ : columnsKG;

    const __goodsOriginal = formValues[goodsKey]
      ? [...formValues[goodsKey]]
      : [];

    const onReset = () => {
      selectedRows.forEach(index =>
        update(goodsKey, index, __goodsOriginal[index] || ''),
      );
    };

    onSetContent(
      <GoodsEditProduct
        onCancel={this.onCancelGoodsEdit}
        onCloseModal={onCloseModal}
        onReset={onReset}
        preparedColumns={this.prepareColumns(columns)}
        selectedRows={selectedRows}
        title={Translate('Редактирование товаров')}
      />,
    );
    onOpenModal();
  };

  prepareColumns = columns =>
    columns.map(column =>
      column.id === 'codes'
        ? {
            ...column,
            accessor: row => (
              <CodesRenderer
                row={row}
                onClick={e => this.onOpenCisModal(e, row)}
              />
            ),
          }
        : column,
    );

  toggleDialog = () => this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  onListRef = ref => {
    this.goodsList = ref;
  };

  onUpdate = () => {
    this.setState({
      selectedRows: this.goodsList.state.selectedRows,
    });
  };

  onCancel = () => {
    const { history, formValues, onCloseNotification } = this.props;
    onCloseNotification(history, formValues);
  };

  setPage = page => {
    this.setState({ page });
  };

  render() {
    const { commandBarLeft, isModalOpen, page } = this.state;
    const { show, countryCode } = this.props;

    const columns = countryCode === 'UZ' ? columnsUZ : columnsKZ;
    const topBarLeft = this.getTopBarLeft();
    const preparedColumns = this.prepareColumns(columns);
    const products = this.getProducts();

    return (
      <PageWrapper hide={!show}>
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.cis}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <ConfirmModal
            isModalOpen={isModalOpen}
            onClose={this.toggleDialog}
            onSubmit={this.onCancel}
            content={contentBodyConfirm}
            header={Translate('Предупреждение')}
            cancelButtonText={Translate('Нет')}
            submitButtonText={Translate('Да')}
            notCancelable
          />
          <GoodsCommandBar left={topBarLeft} right={[]} />
          <Wrapper margin="0">
            <SelectionList
              defaultPageSize={10}
              columns={preparedColumns}
              data={products}
              onRef={this.onListRef}
              onUpdate={this.onUpdate}
              showPagination={products.length > 10}
              onPageChange={pageIdx => this.setPage(pageIdx)}
              keyField={keyField}
              page={page}
              customSelectAll
            />
            <GoodsCommandBar
              left={commandBarLeft}
              style={{
                ...styleCommandBar,
                width: isEmpty(commandBarLeft) ? 'auto' : '100%',
              }}
              right={[
                <PrimaryActionButton
                  disabled={this.isNextStepDisabled}
                  onClick={this.onNextStep}
                >
                  {Translate('Отправить')}
                </PrimaryActionButton>,
              ]}
            />
          </Wrapper>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  countryCode: state.config.countryCode,
});

const mapDispatch = dispatch => ({
  onSubmit: data => dispatch(actions.submitUZ(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
  withRouter,
)(CisTotal);
