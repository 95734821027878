import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as sidebarActions from '../../Sidebar/ducks/Sidebar.actions';
import FiltersView from './Filters.view';

const mapDispatch = dispatch => ({
  onClose: () => dispatch(sidebarActions.close()),
});

export default compose(connect(null, mapDispatch))(FiltersView);
