import { handleActions } from 'redux-actions';
import * as actions from './Sidebar.actions';

const content = handleActions({
  [actions.open]: (state, { payload }) => payload,
  [actions.close]: () => null,
}, null);

const active = handleActions({
  [actions.open]: () => true,
  [actions.close]: () => false,
}, false);

export default {
  content,
  active,
};
