import React from 'react';
import {Value} from 'src/fragments/Table/Cell/value';
import moment from 'moment';
import {CellTooltip} from 'src/fragments/Table/Cell/CellTooltip';

export const declarationColumns = [
  {
    Header: 'Порядковый номер в ДТ',
    id: 'dtNumber',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.productNumberInDeclaration}</Value>;
    },
  },
  {
    Header: 'КИ / КИГУ / КИТУ',
    id: 'cis',
    accessor: 'cis',
    Cell: ({ row }) => {
      const renderValues = () => {
        return (
          <Value>
              {row.original.cis}
          </Value>
        )
      };
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={row.original.cis}
          render={renderValues}
        />
      );
    },
  },
  {
    Header: 'Наименование товаров в ДТ',
    id: 'productsDtName',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.productNameInDeclaration}</Value>;
    },
  },
  {
    Header: 'Количество товаров внутри',
    id: 'productsCount',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.productAmountInDeclaration}</Value>;
    },
  },
  {
    Header: 'Код товарной номенклатуры',
    id: 'goodsCode',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.tnVed}</Value>;
    },
  },
  {
    Header: 'Валюта',
    id: 'currency',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.currencyCodeInContract.toUpperCase()}</Value>;
    },
  },
  {
    Header: 'Таможенная стоимость товара',
    id: 'customsProductPrice',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      const valueNumber = new Intl.NumberFormat('ru-RU', ).format(row.original.productInfo.customsProductPrice)
      return <Value>{valueNumber}</Value>;
    },
  },
  {
    Header: 'Статистическая стоимость товара',
    id: 'statisticalProductPrice',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      const valueNumber = new Intl.NumberFormat('ru-RU', ).format(row.original.productInfo.statisticalProductPrice)
      return <Value>{valueNumber}</Value>;
    },
  },
  {
    Header: 'Цена товара (фактурная стоимость)',
    id: 'productPrice',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      const valueNumber = new Intl.NumberFormat('ru-RU', ).format(row.original.productInfo.productPrice)
      return <Value>{valueNumber}</Value>;
    },
  },
  {
    Header: 'Код страны происхождения',
    id: 'productionCountryCode',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.productionCountryCode}</Value>;
    },
  },
  {
    Header: 'Количество товаров в доп.единице измерения',
    id: 'productAmountInExtraUnitMeasure',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.productAmountInExtraUnitMeasure}</Value>;
    },
  },
  {
    Header: 'Наименование доп.единицы измерения',
    id: 'extraUnitMeasureName',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.extraUnitMeasureName}</Value>;
    },
  },
  {
    Header: 'Код доп.единицы измерения',
    id: 'extraUnitMeasureCode',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.extraUnitMeasureCode}</Value>;
    },
  },
  {
    Header: 'Номер разрешительного документа',
    id: 'approvementDocumentNumber',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      return <Value>{row.original.productInfo.approvementDocumentNumber}</Value>;
    },
  },
  {
    Header: 'Дата разрешительного документа',
    id: 'approvementDocumentDate',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      const date = moment(row.original.productInfo.approvementDocumentDate).format("D.MM.YYYY")
      return <Value>{date}</Value>;
    },
  },
  {
    Header: 'Сумма НДС',
    id: 'vat',
    accessor: 'productInfo',
    Cell: ({ row }) => {
      const valueNumber = new Intl.NumberFormat('ru-RU', ).format(row.original.productInfo.vat)
      return <Value>{valueNumber}</Value>;
    },
  },
  {
    Header: 'Сумма акцизного налога',
    id: 'excisePaidTaxAmount',
    accessor: 'excisePaidTaxAmount',
    Cell: ({ row }) => {
      const valueNumber = new Intl.NumberFormat('ru-RU', ).format(row.original.productInfo.excisePaidTaxAmount)
      return <Value>{valueNumber}</Value>;
    },
  },
  
];