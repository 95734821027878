import React from 'react';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import {
  isValidInn,
  required,
} from '../../../../../common_components/Form/utils/validations';
import CountrySelect from 'src/common_components/Form/MaterialFields/Select/CountrySelect';
import { Translate } from 'src/common_components/Translate/Translate';

export const CommonFields = ({ formValues, onCountrySelect }) => {
  const { isChanged } = formValues;
  return (
    <React.Fragment>
      <Input
        name="applicant_inn"
        placeholder={Translate('ИИН/БИН получателя')}
        disabled
        validate={required}
      />
      <Input
        name="applicant_name"
        placeholder={Translate('Наименование получателя')}
        disabled
        validate={required}
      />
      <Input
        required
        forceValidate
        type="number"
        name="sender_inn"
        placeholder={Translate('ИНН отправителя (или его аналог)')}
        validate={isValidInn(formValues.sender_country)}
        disabled={isChanged}
      />
      <Input
        forceValidate
        required
        name="sender_name"
        placeholder={Translate('Наименование отправителя')}
        validate={required}
        disabled={isChanged}
      />

      <CountrySelect
        forceValidate
        placeholder={Translate('Страна отправления')}
        name="sender_country"
        required
        eas={true}
        validate={required}
        onSelect={onCountrySelect}
        disabled={isChanged}
      />
      <div />
    </React.Fragment>
  );
};
