import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { permissionConfig } from './Requests.permissions';
import withPermissions from '../../common_components/hocs/withPermissions';
import RequestList from './RequestList/RequestList';
import ReceiptList from './ReceiptList/ReceiptList';
import EaesImportDocument from './RequestList/EaesImportDocument/EaesImportDocument';
import Route from '../../common_components/Routing/Route';
import ButtonTabs from '../../common_components/ButtonTabs/ButtonTabs';
import Draft from './RequestList/Draft/Draft';
import { tabs } from './Requests.tabs';
import IncomeList from './IncomeList/IncomeList';
import { APP_LINKS } from 'src/constants';
import NotificationOfEntry from './RequestList/NotificationOfEntry/NotificationOfEntry';
import ImportThirdCountry from './RequestList/ImportThirdCountry/ImportThirdCountry';
import ActDocument from './RequestList/ActDocument/ActDocument';
import OutcomeList from './OutcomeList/OutcomeList';
import DiscrepancyNotice from './RequestList/DiscrepancyNotice/DiscrepancyNotice';
import ExportList from './ExportList/ExportList';
import ExportListUz from './ExportListUz/ExportList';
import ExportNotificationToTheEaes from './RequestList/ExportNotificationToTheEaes/ExportNotificationToTheEaes';
import ImportList from './ImportList';
import AcceptanceNotificationOfGoodsByTheEAES from './RequestList/AcceptanceNotificationOfGoodsByTheEAES/AcceptanceNotificationOfGoodsByTheEAES';
import CisRegistration from './RequestList/CisRegistration';
import Aik from './RequestList/Aik/Aik';
import AikAikDisbandment from './RequestList/AikDisbandment/AikDisbandment';
import Aggregation from './RequestList/Aggregation/Aggrigation';
import Inventory from "./RequestList/Inventory/Inventory";
import TradeInventory from "./RequestList/TradeInventory/TradeInventory";
import CorInfoCm from './RequestList/CorInfoCm/CorInfoCm';

const tabsPermitted = (permissions, countryCode) =>
  tabs.filter(tab => {
    if (new RegExp(countryCode).test(tab.blockCounty)) return false;
    const keysPerm = Object.keys(permissions);

    const permissionName = keysPerm.find(key =>
      new RegExp(`\\/${key}`).test(tab.href),
    );

    if (!permissionName) return true;
    return permissions[permissionName]['canAccess'];
  });
const tabsForCountry = countryCode =>
  tabs.filter(tab => {
    if (new RegExp(countryCode).test(tab.blockCounty)) return false;
    return true;
  });
const Requests = ({ permissions, countryCode }) => (
  <Switch>
    <Route
      exact
      path="/documents"
      render={props => (
        <RequestList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/list"
      render={props => (
        <RequestList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/receipts"
      render={props => (
        <ReceiptList
          {...props}
          countryCode={countryCode}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/draft"
      render={props =>
        permissions['draft']['canAccess'] ? (
          <Draft
            {...props}
            actionBarLeft={[<ButtonTabs tabs={tabsForCountry(countryCode)} />]}
          />
        ) : (
          <Redirect to="/documents" />
        )
      }
    />
    <Route
      exact
      path="/documents/outcoming"
      render={props => (
        <OutcomeList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/incoming"
      render={props => (
        <IncomeList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/export"
      render={props => (
        <ExportList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/import-uz"
      render={props => (
        <ExportListUz
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    <Route
      exact
      path="/documents/import"
      render={props => (
        <ImportList
          {...props}
          actionBarLeft={[
            <ButtonTabs tabs={tabsPermitted(permissions, countryCode)} />,
          ]}
        />
      )}
    />
    {/*<Route*/}
    {/*  exact*/}
    {/*  path="/documents/(outgoing|incoming)/list"*/}
    {/*  render={props =>*/}
    {/*    permissions['outgoing']['canAccess'] ? (*/}
    {/*      <EdoRequests*/}
    {/*        {...props}*/}
    {/*        actionBarLeft={[<ButtonTabs tabs={tabsPermitted(permissions, countryCode)}/>]}*/}
    {/*      />*/}
    {/*    ) : (*/}
    {/*      <Redirect to="/documents"/>*/}
    {/*    )*/}
    {/*  }*/}
    {/*/>*/}
    <Route
      exact
      path={`/documents/${APP_LINKS.Eaes_act}/:id`}
      component={ActDocument}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.ExportNotificationToTheEaes}/:id`}
      component={ExportNotificationToTheEaes}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.AcceptanceNotificationOfGoodsByTheEAES}/:id`}
      component={AcceptanceNotificationOfGoodsByTheEAES}
    />
    {/*<Route exact path="/documents/import/:id" component={ImportDocument}/>*/}
    <Route
      exact
      path={`/documents/${APP_LINKS.Eaes_import}/:id`}
      component={EaesImportDocument}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.NotificationOfCancellation}/:id`}
      component={NotificationOfEntry}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.ImportThirdCountryKG}/:id`}
      component={EaesImportDocument}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.NotificationOfEntry}/:id`}
      component={NotificationOfEntry}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.Discrepancy_notice}/create/:id`}
      component={DiscrepancyNotice}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.Discrepancy_notice}/:id`}
      component={DiscrepancyNotice}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.Discrepancy_notice}/view/:id`}
      component={DiscrepancyNotice}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.ImportThirdCountry}/:id`}
      component={ImportThirdCountry}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.CisRegistry}/:id`}
      component={CisRegistration}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.aik}/:id`}
      component={Aik}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.aik_disaggregation}/:id`}
      component={AikAikDisbandment}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.aggregation}/:id`}
      component={Aggregation}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.inventory}/:id`}
      component={Inventory}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.tradeInventory}/:id`}
      component={TradeInventory}
    />
    <Route
      exact
      path={`/documents/${APP_LINKS.corInfoCm}/:id`}
      component={CorInfoCm}
    />
  </Switch>
);
const mapState = state => ({
  countryCode: state.config.countryCode,
});
export default compose(
  withRouter,
  withPermissions(permissionConfig),
  connect(mapState),
)(Requests);
