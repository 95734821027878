import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';
import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import Sidebar from '@crpt/react-sidebar';

import DownloadButton from './DownloadButton';
import Filter from './Filter';

import { ActionBarWrapper, ActionGroup, Action } from './styled';

class ActionBar extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    leftSideComponents: PropTypes.array,
    type: PropTypes.string,
    onFilterApply: PropTypes.func.isRequired,
  };

  static defaultProps = {
    leftSideComponents: [],
    type: '',
  };

  state = {
    sidebarActive: false,
  };

  onSidebarOpenClick = () => {
    this.setState({ sidebarActive: true });
  };

  onSidebarCloseClick = () => {
    this.setState({ sidebarActive: false });
  };

  render() {
    const {
      filters, onFilterApply, type, leftSideComponents, buttons
    } = this.props;

    const topLine = filters[0];
    const filterFields = filters.slice(1);

    const { popupMenu, showDownloadListButton } = topLine;
    const buttonTheme = { Button: get(this.props, 'theme.TableFilter.buttonBar.button') };
    const { sidebarActive } = this.state;

    return (
      <ActionBarWrapper>
        <ActionGroup>
          {leftSideComponents.map((Item, i) => (
            <Action key={`LSB_${i}`}><Item /></Action>)
          )}
        </ActionGroup>

        <ActionGroup>
          <Action>
            <Button theme={buttonTheme} onClick={this.onSidebarOpenClick}>
              <Icon type="black-filter" spacingRight={16} />
              Фильтр
            </Button>
          </Action>

          <DownloadButton
            showDownloadListButton={showDownloadListButton}
            popupMenu={popupMenu}
          />
        </ActionGroup>

        <Sidebar active={sidebarActive} onClose={this.onSidebarCloseClick}>
          <Filter
            type={type}
            onFilterApply={onFilterApply}
            onFilterClose={this.onSidebarCloseClick}
            filterFields={filterFields}
          />
        </Sidebar>

      </ActionBarWrapper>
    );
  }
}

export default withTheme(ActionBar);
