import React from 'react';

import {SingleSelect} from '@crpt/react-select';
import {
  documentTypeNames,
  KZdocumentTypes,
  UZdocumentTypes,
} from '../../constants/documentTypes';
import {FilterURLHelper} from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';

const documentTypes = (types) => Object.keys(types)
  .map((v, i) => {
    return {
      id: i,
      title: Translate(documentTypeNames[v]),
      documentType: types[v]
    };
  })
  .filter(v => ![types.PAYMENT, types.RECEIPT, types.RECEIPT_RETURN].includes(v.documentType));

class DocumentTypeSelect extends React.Component {
  state = {
    values: [],
  };

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    const countryCode = window.env.ELK_COUNTRY;
    const typesDictionary = countryCode === 'UZ' ? UZdocumentTypes : KZdocumentTypes;
    const types = documentTypes(typesDictionary);
    this.setState({ values: types });
    if (value) {
      const status = types.find(v => v.documentType === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {

    const {onRef} = this.props;
    const {values} = this.state;

    return (
      <SingleSelect
        name="documentType"
        placeholder={Translate("Тип документа")}
        values={values}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

DocumentTypeSelect.displayName = 'SingleSelect';

export default DocumentTypeSelect;

