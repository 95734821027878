import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { isString, get, isUndefined } from 'lodash';
import { RangePicker } from '@crpt/react-datepicker';
import { Wrap } from '../_refactor/Fields.styled';
import { defaultDatepickerThemeKZ } from '../../../../theme/kz-tobaco/datepicker';
import { defaultDatepickerThemeUZ } from '../../../../theme/uz-tobaco/datepicker';

const PureRangePicker = memo(RangePicker);

class RangePickerAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    camelCaseVal: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    camelCaseVal: false,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onUpdate: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    formValues: undefined,
    formMutators: undefined,
  };

  state = {
    fromValue: undefined,
    toValue: undefined,
    from: undefined,
    to: undefined,
  };

  componentDidMount() {
    const { from, to, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value) && (!isUndefined(from) || !isUndefined(to))) {
      setTimeout(() => formMutators.updateField(name, { from, to }), 0);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      name,
      formValues,
      dateFormat,
      from,
      to,
      fieldInput,
      camelCaseVal = true,
    } = props;
    const { value, onChange } = fieldInput;

    const preparedName = name.replace('__form_RangePicker_', '');
    const fromName = camelCaseVal
      ? `${preparedName}From`
      : `${preparedName}_from`;
    const toName = camelCaseVal ? `${preparedName}To` : `${preparedName}_to`;

    let fromValue;
    let toValue;

    const fromFormValue = get(formValues, fromName);
    const toFormValue = get(formValues, toName);

    if (value) {
      fromValue = get(value, 'from', from);
      toValue = get(value, 'to', to);
    } else if (
      (fromFormValue && fromFormValue !== state.fromValue) ||
      (toFormValue && toFormValue !== state.toValue)
    ) {
      fromValue = get(formValues, fromName, from);
      toValue = get(formValues, toName, to);
      setTimeout(() => onChange({ from: fromValue, to: toValue }), 0);
    }

    let fromDate;
    let toDate;

    if (isString(fromValue)) {
      fromDate = moment(
        fromValue,
        dateFormat || fromValue.length === 10 ? 'DD.MM.YYYY' : undefined
      ).format(dateFormat || 'DD.MM.YYYY');
    } else {
      fromDate = fromValue
        ? moment(fromValue).format(dateFormat || 'DD.MM.YYYY')
        : undefined;
    }

    if (isString(toValue)) {
      toDate = moment(
        toValue,
        dateFormat || toValue.length === 10 ? 'DD.MM.YYYY' : undefined
      ).format(dateFormat || 'DD.MM.YYYY');
    } else {
      toDate = toValue
        ? moment(toValue).format(dateFormat || 'DD.MM.YYYY')
        : undefined;
    }

    if (toValue !== state.toValue || fromValue !== state.fromValue) {
      return {
        fromValue,
        toValue,
        from: fromDate,
        to: toDate,
      };
    }

    return null;
  }

  onUpdate = val => {
    const { onUpdate, fieldInput } = this.props;
    const from = val && val.from;
    const to = val && val.to;

    const newVal = {
      from: from ? moment(from).toISOString(true) : undefined,
      to: to ? moment(to).endOf('day').toISOString(true) : undefined,
      //from: from ? moment(from).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : undefined,
      //to: to ? moment(to).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : undefined,
    };

    if (fieldInput) fieldInput.onChange(newVal);
    if (onUpdate) onUpdate(newVal);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError &&
      fieldMeta &&
      fieldMeta.dirtySinceLastSubmit &&
      !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const {
      placeholder,
      errorPlaceholder,
      index,
      total,
      fieldMeta,
    } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dateFormat,
      ...passProps
    } = this.props;
    const { from, to } = this.state;
    const countryCode = window.env.ELK_COUNTRY;
    return (
      <div className="datePickerButtons">
      <Wrap
        onBlur={fieldInput && fieldInput.onBlur}
        marginBottom={marginBottom}
      >
        <PureRangePicker
          savePlaceholder
          {...passProps}
          placeholder={this.getPlaceholder()}
          isError={this.isError()}
          format={dateFormat || 'DD.MM.YYYY'}
          onUpdate={this.onUpdate}
          inline={false}
          from={from}
          to={to}
          theme={{ DatePicker: countryCode === 'KZ' ? defaultDatepickerThemeKZ : defaultDatepickerThemeUZ}}
        />
      </Wrap>
      </div>
    );
  }
}

export default RangePickerAdapter;
