import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Translate } from '../../../common_components/Translate/Translate';


const Wrap = styled.div`
  font-size: 13px;
  color: #212C42;
  line-height: 1.54;
`;

const FlexWrap = styled.div`
  js-display: flex;
  display: flex;
  align-items: center;
`;
const Column = styled.div`
  flex-grow: 1;
  width: 25%;
`;


function WarehouseHeader({ title }) {
  return (
    <Wrap>
      <div>{title}</div>
      <FlexWrap>
        <Column>{Translate('Было')}</Column>
        <Column>{Translate('Приход')}</Column>
        <Column>{Translate('Расход')}</Column>
        <Column>{Translate('Остаток')}</Column>
      </FlexWrap>
    </Wrap>
  )
}


WarehouseHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export { WarehouseHeader };
