import React, { useMemo } from 'react';
import {
  ParticipantSelect,
  RangePicker,
  SingleSelect,
  Input,
} from '../../../common_components/Form/Fields/Fields';
import {
  // documentFormatValues,
  documentStatusValues,
  // documentInputTypeValues,
  // documentNameValues,
  documentTypes,
  documentTypesUZ,
} from './RequestList.constants';
import { Translate } from '../../../common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';

const RequestListFilters = ({ countryCode }) => {
  const { t } = useTranslation();

  const values = useMemo(
    () => (countryCode === 'KZ' ? documentTypes : documentTypesUZ)
      .map((item) => ({ ...item, title: t(item.title)})),
    [countryCode],
  );
  return (
    <React.Fragment>
      <Input name="search" placeholder={Translate('Номер')} />
      <RangePicker
        name="date"
        leftPlaceholder={Translate('От')}
        rightPlaceholder={Translate('До')}
      />
      {/*<SingleSelect*/}
      {/*name="inputFormat"*/}
      {/*placeholder="Тип документа"*/}
      {/*values={documentInputTypeValues}*/}
      {/*/>*/}
      <SingleSelect
        name="documentStatus"
        placeholder={Translate('Статус')}
        values={documentStatusValues}
      />
      <SingleSelect
        name="documentType"
        placeholder={Translate('Тип документа')}
        values={values}
      />
      <ParticipantSelect
        name="senderInn"
        valueKey="inn"
        placeholder={Translate('Отправитель')}
      />
      <ParticipantSelect
        name="receiverInn"
        valueKey="inn"
        placeholder={Translate('Получатель')}
      />
      {/*<SingleSelect name="documentFormat" placeholder="Способ получения" values={documentFormatValues} />*/}
    </React.Fragment>
  );
};

RequestListFilters.defaultProps = {
  countryCode: 'KZ',
};

export default RequestListFilters;
