import moment from 'moment';
import 'moment/locale/ru';
import * as configs from './projects';
import { Config } from '@crpt-edo/core';

moment.locale('ru');

export const projects = {
  COMMON: 'COMMON',
  LP: 'LP',
  MOTP: 'MOTP'
};

const allSagas = [
  { id: projects.COMMON, data: require.context('./common_components', true, /\.saga.js/) },
  { id: projects.MOTP, data: require.context('./motp_components', true, /\.saga.js/) },
  { id: projects.LP, data: require.context('./components', true, /\.saga.js/) },
];

const allMiddlewares = [
  { id: projects.COMMON, data: require.context('./common_components', true, /\.middleware.js/) },
  { id: projects.MOTP, data: require.context('./motp_components', true, /\.middleware.js/) },
  { id: projects.LP, data: require.context('./components', true, /\.middleware.js/) },
];

const allStores = [
  { id: projects.COMMON, data: require.context('./common_components', true, /\.store.js/) },
  { id: projects.MOTP, data: require.context('./motp_components', true, /\.store.js/) },
  { id: projects.LP, data: require.context('./components', true, /\.store.js/) },

];

const order = [];
const config = {
  countryCode: window.env.ELK_COUNTRY,
  currency: window.env.ELK_COUNTRY === 'KZ' ? 'тенге' : window.env.ELK_COUNTRY === 'UZ' ? 'сум' : 'сом',
  countryName: window.env.ELK_COUNTRY === 'KZ' ? 'РК' : window.env.ELK_COUNTRY === 'UZ' ? 'УЗ' : 'КГ',
};

order.push(projects.COMMON, projects.LP, projects.MOTP);
Object.assign(config, configs.motp);

Config.configChanged({
  url: '/edo-api',
  // url: '/api/v1',
  actions: { move: false, create_ukd: false },
  upd: { sf: false },
});

export {
  order,
  config,
  allSagas,
  allMiddlewares,
  allStores,
};
