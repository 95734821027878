import { actionTypes } from './GrayAreaCreate.constants';
import { combineReducers } from 'redux';
import { GtinsHelper, InformationHelper, ProductionLinesHelper } from './helpers';

const informationHelper = new InformationHelper();
export const productionLinesHelper = new ProductionLinesHelper();
const gtinsHelper = new GtinsHelper();

export const initialState = {
  information: {
    hasErrors: false,
  },
  productionLines: [],
  gtins: [],
}

const informationReducer = (state = initialState.information, action) => {
  switch (action.type) {
    case actionTypes.INFORMATION.UPDATE:
      return informationHelper.update({ state, payload: action.payload })
    case actionTypes.INFORMATION.SET_ERRORS:
      return informationHelper.setErrors({ state, payload: action.payload })
    default:
      return state;
  }
}

const productionLinesReducer = (state  = initialState.productionLines, action) => {
  switch (action.type) {
    case actionTypes.PRODUCTION_LINES.ADD:
      return productionLinesHelper.add({ payload: action.payload })
    case actionTypes.PRODUCTION_LINES.DELETE:
      return productionLinesHelper.delete({ state, payload: action.payload })
    default:
      return state;
  }
}

const gtinsReducer = (state = initialState.gtins, action) => {
  switch (action.type) {
    case actionTypes.GTINS.ADD:
      return gtinsHelper.add({ state, payload: action.payload })
    case actionTypes.GTINS.DELETE:
      return gtinsHelper.delete({ state, payload: action.payload })
    case actionTypes.GTINS.UPDATE:
      return gtinsHelper.update({ payload: action.payload })
    default:
      return state;
  }
}

export const rootReducer = combineReducers({
  information: informationReducer,
  productionLines: productionLinesReducer,
  gtins: gtinsReducer,
});
