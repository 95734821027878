import React from 'react';

import { SingleSelect } from '@crpt/react-select';

import { cisStatuses } from '../../constants';
import { FilterURLHelper } from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';
// import { ParticipantService } from '../../services/ParticipantService';

const cisKeys = Object.keys(cisStatuses);
const cisValues = Object.values(cisStatuses);

export const statuses = cisKeys
  .filter((key, pos) => {
    return (
      cisValues.indexOf(cisStatuses[key]) ===
      cisValues.indexOf(cisStatuses[cisKeys[pos]])
    );
  })
  .map((key, i) => {
    return {
      id: i,
      title: cisStatuses[key],
      cisStatus: key,
    };
  });

class CISStatusSelect extends React.Component {

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const status = statuses.find(v => v.cisStatus === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {

    const {selectedStatus} = this.props;

    let selectedId;

    if (selectedStatus) {
      selectedId = statuses.findIndex(s => s.cisStatus === selectedStatus);
    }
    const {onRef} = this.props;

    return (
      <SingleSelect
        name="cisStatus"
        placeholder={Translate("Статус")}
        values={statuses}
        selectedId={selectedId}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

CISStatusSelect.displayName = 'SingleSelect';

export default CISStatusSelect;
