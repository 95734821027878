import chroma from 'chroma-js';
import { buttonWhiteTheme, buttonClearTheme } from '../button';


export const defaultTheme = {
  height: '60px',
  borderRadius: '2px',
  fontSize: '16px',
  fontWeight: 200,
  Day: {
    width: '24px',
    height: '24px',
    borderRadius: '2px',
    hoverBackground: '#67ac5c',
    hoverColor: '#ffffff',
    hovered: {
      background: chroma('#67ac5c')
        .brighten(0.8)
        .css(),
      color: '#ffffff',
    },
    selected: {
      background: '#67ac5c',
      color: '#ffffff',
      fontWeight: 600
    },
    main: {
      color: '#212C42',
      background: 'rgba(0,0,0,0)',
    },
  },
  DayWrap: {
    width: '28px',
    height: '28px',
    hoverBackground: "#67ac5c",
    hoverColor: "#ffffff",
    hovered: {
      background: chroma("#67ac5c")
        .brighten(0.8)
        .css(),
      color: "#ffffff"
    },
  },
  placeholder: {
    normal: '#ACADB5',
    disabled: '#ACADB5',
    error: '#ACADB5',
  },
  Placeholder: {
    fontWeight: 200,
    main: {
      color: '#abadb6',
    },
    error: {
      color: '#abadb6',
    },
    disabled: {
      color: '#abadb6',
    },
    normal: {
      fontSize: '16px',
      height: '24px',
      top: '11px',
    },
    focused: {
      top: '4px',
      fontSize: '12px',
      height: '14px',
    },
  },
  InputWrap: {
    main: {
      background: '#FFFFFF',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'normal',
    },
    disabled: {
      background: '#FFFFFF',
      border: '1px solid #ABADB5',
      cursor: 'not-allowed',
    },
    error: {
      background: '#FFEBEB',
      border: '1px solid #FF3C21',
      cursor: 'normal',
    },
    height: '60px',
    borderRadius: '3px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  InputElem: {
    main: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text',
      background: 'rgba(0,0,0,0)',
    },
    disabled: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'not-allowed',
      background: 'rgba(0,0,0,0)',
    },
    error: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text',
      background: 'rgba(0,0,0,0)',
    },
    height: '24px',
  },
  InputContentWrap: {
    width: 'calc(100% - 32px)',
  },
  DatePickerPanelWrap: {
    top: '36px',
    bottom: '36px',
    borderRadius: '3px',
    paddingTop: '15px',
    paddingLeft: '23px',
    paddingRight: '23px',
    paddingBottom: '15px',
    main: {
      border: '1px solid rgba(196, 196, 196, 0.25)',
    },
    Accept: {
      Button: {
        ...buttonWhiteTheme,
      },
    },
    Reset: {
      Button: {
        ...buttonClearTheme,
        marginRight: 40,
      },
    },
  },
};
