import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

const ProductNameWrapper = styled.div`
  white-space: nowrap;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductName = (props) => {
  let name = get(props, 'options.name._original.name', '');
  if (name === '') { name = '—'; }

  return <ProductNameWrapper>{name}</ProductNameWrapper>;
};

export default ProductName;
