import { Icon } from '@crpt-ui/core';
import React from 'react';
import DocumentId from 'src/components/TableCells/Document/DocumentId';

import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const Payment = ({ op }) => {
  return (
    <Layout>
      <FirstCell
        icon={<Icon name="CirculationInput" />}
        title={Translate('Оплата')}
      />
      <SecondCell op={op} />
      <ThirdCell heading={Translate('Идентификатор операции')}>
        <DocumentId id={op.docId} document={op} />
      </ThirdCell>
    </Layout>
  );
};

export default Payment;
