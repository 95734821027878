import { registrationTheme } from './registration';
import { loginTheme } from './login';
import { exciseTheme } from './excise';
import { edosOfds } from './edosOfds';

export {
  registrationTheme as selectRegistrationTheme,
  loginTheme as selectLoginTheme,
  exciseTheme as selectExciseTheme,
  edosOfds as selectEdosOfdsTheme
};
