import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display:'flex',
    flexDirection: 'column',
    width:'100%',
    color: theme.palette.text.primary,
  },
  iconWrapper: {
    marginBottom: '30px'
  },
  footerWrapper: {
    display:'flex',
    justifyContent: 'flex-end',
    
    marginTop: '40px',
    paddingLeft:'40px',
    paddingRight:'40px'
  }
}));
