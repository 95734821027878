import { createSelector } from 'reselect';

export const getProduction = (state) => {
  return state.LP.ParticipantDetails.ProductionInfo;
};

export const getProductionData = createSelector(
  getProduction,
  (production) => production.data,
);
