import React from 'react';
import { documentTypes, receiptTypes } from 'src/constants/documentTypes';
import { documentStatuses } from 'src/constants/documentStatuses';
import * as Icons from '@crpt-ui/icons';

export const getStatusIconByDocStatus = status => {
  switch (status) {
    case documentStatuses.IN_PROGRESS:
    case documentStatuses.UNDEFINED:
      return <Icons.DocumentProcessing />;

    case documentStatuses.CHECKED_OK:
    case documentStatuses.DRAFT:
    case documentStatuses.READY_FOR_EXTERNAL_PROCESSING:
    case documentStatuses.COMPLETED:
      return <Icons.Published />;

    case documentStatuses.CHECKED_NOT_OK:
    case documentStatuses.PROCESSING_ERROR:
    case documentStatuses.ERROR:
      return <Icons.ErrorDocument />;

    default:
      return <Icons.DocumentProcessing />;
  }
};

export const getStatusIconByDocType = typeName => {
  switch (typeName) {
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_FIX:
      return <Icons.DocumentDraft />;

    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT:
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED:
      return <Icons.UPD />;

    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED:
    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT:
      return <Icons.UKD />;

    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_FIX:
      return <Icons.UKDI />;

    case documentTypes.AGGREGATION_DOCUMENT:
      return <Icons.AggregationDocument />;

    case receiptTypes.RECEIPT:
    case receiptTypes.AUTO_DISAGGREGATION:
    case receiptTypes.WITHDRAW:
      return <Icons.Receipt />;

    case receiptTypes.RECEIPT_RETURN:
    case receiptTypes.RETURN_TO_TURNOVER:
      return <Icons.Rejection />;

    case documentTypes.WRITE_OFF:
    case documentTypes.MTTPS_10:
    case documentTypes.MTTPS_90:
      return <Icons.Trash />;

    case documentTypes.IMPORT:
    case documentTypes.IMPORT_TRANSIT:
      return <Icons.Import />;

    default:
      return <Icons.Import />;
  }
};
