import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '@crpt/react-card';
import { Icon } from '@crpt/react-icon';

const cardTheme = {
  background: '#FFFFFF',
  paddingLeft: '32px',
  paddingTop: '32px',
  paddingRight: '80px',
  paddingBottom: '40px',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

const HeaderText = styled.div`
  font-size: 24px;
  color: #52535a;
  max-width: 400px;
`;

const CloseButton = styled.div``;

const EnterText = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: #52535a;
  margin-top: 58px;
  margin-right: 30px;
  font-weight: 600;
`;

const Container = styled.div`
  width: 100%;
  color: #212c42;
  margin: 24px 0 40px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '33px')};
`;

const Number = styled.div`
  background: ${props => (props.noBackground ? null : '#f8ec31')};
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 29px;
  color: #52535a;
`;

const Blank = styled.div`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  margin-right: 29px;
`;

const Content = styled.div`
  line-height: 24px;
  font-size: 16px;
  color: #212c42;
  max-width: 380px;
  font-weight: 400;
`;

const LightLink = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  &,
  &:active,
  &:visited,
  &:hover {
    color: #55b5e7;
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #55b5e7;
`;

const DefaultLink = styled.a`
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #55b5e7;
`;

class LoginFormSidebarContent extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      Sidebar: PropTypes.shape({
        sidebarClosing: PropTypes.func,
      }),
    }).isRequired,
    App: PropTypes.shape({
      config: PropTypes.shape({}),
    }).isRequired,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    console.log('props LoginFormSidebarContent', this.props);
    const { App, actions } = this.props;

    const { config } = App;

    return (
      <Card theme={cardTheme}>
        <Header>
          <HeaderText>Как войти</HeaderText>
          <CloseButton>
            <Icon
              type="cross"
              width={24}
              height={24}
              onClick={actions.Sidebar.sidebarClosing}
            />
          </CloseButton>
        </Header>
        <EnterText>
          Вход в систему осуществляется с помощью электронной подписи.
          Сертификат электронной подписи можно получить в одном из
          аккредитованных Минкомсвязью России <DefaultLink
            target={'_blank'}
            href={
              'https://minsvyaz.ru/ru/activity/govservices/2/#section-list-of-accredited-centers'
            }
          >
            удостоверяющих центров
          </DefaultLink>.
        </EnterText>
        <Container>
          <ListItem>
            <Number>1</Number>
            <Content>
              Установите плагины для работы с электронной подписью. <DefaultLink
                href={'https://www.cryptopro.ru/products/cades/plugin/'}
                target={'_blank'}
              >
                КриптоПро ЭЦП Browser plug-in
              </DefaultLink> или <DefaultLink
                href={'https://www.rutoken.ru/support/download/rutoken-plugin/'}
                target={'_blank'}
              >
                Рутокен плагин
              </DefaultLink>. После установки перезапустите браузер.
            </Content>
          </ListItem>
          <ListItem marginBottom="12px">
            <Number>2</Number>
            <Content>
              Включите доступ к плагину в настройках своего браузера.
              <div>&nbsp;</div>
            </Content>
          </ListItem>
          <ListItem>
            <Number>3</Number>
            <Content>
              Присоедините к компьютеру носитель ключа электронной подписи, это
              может быть USB-токен, УЭК или смарт-карта.
            </Content>
          </ListItem>
          <ListItem>
            <Number>4</Number>
            <Content>
              Если вы являетесь производителем или организацией оптовой торговли
              при первом входе необходимо{' '}
              <DefaultLink href={'https://ismotp.crptech.ru/register'}>
                зарегистрироваться
              </DefaultLink>
            </Content>
          </ListItem>
        </Container>
        <Text>
          {config.docs.reg ? (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={config.docs.reg}
            >
              {'Скачать полную инструкцию в формате PDF'}
            </Link>
          ) : null}
        </Text>
      </Card>
    );
  }
}

export default LoginFormSidebarContent;
