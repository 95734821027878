import React, { Component } from 'react';
import { SingleSelect } from '@crpt/react-select';
import { ParticipantService } from 'src/services/ParticipantService';
import { Translate } from '../../common_components/Translate/Translate';
// import { hasOnlyDigits } from 'src/utils';

const Option = props => {
  const { value } = props;
  if (!value) return null;

  return (
    <div>
      <div>{value.title}</div>
      <div>{value.inn}</div>
    </div>
  );
};

class ProducerSelect extends Component {
  select;

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      isLoading: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.filterValue && this.props.filterValue.name) {
      const val = {
        id: 0,
        ...this.props.filterValue,
        title: this.props.filterValue.name,
      };
      this.setState({
        values: [val],
      });

      this.select.onSelect({ preventDefault: () => {} }, val);
    }
  }

  onChange(val) {
    if (val.length > 0) {
      this.setState({
        isLoading: true,
      });

      ParticipantService.searchProducerByInput(val)
        .then(answer => {
          // const values =
          //   val.length >= 10 && val.length <= 12 && hasOnlyDigits(val)
          //     ? [
          //         {
          //           [this.props.name || 'participantInn']: val,
          //           id: 0,
          //           title: 'val',
          //           inn: val,
          //         },
          //         ...answer.data.map((item, index) => {
          //           return Object.assign(item, {
          //             [this.props.name || 'participantInn']: item[
          //               this.props.valueKey || 'inn'
          //             ],
          //             id: index + 1,
          //             title: item.name,
          //           });
          //         }),
          //       ]
          //     : answer.data.map((item, index) => {
          //         return Object.assign(item, {
          //           [this.props.name || 'participantInn']: item[
          //             this.props.valueKey || 'inn'
          //           ],
          //           id: index,
          //           title: item.name,
          //         });
          //       });

          const values = answer.data.map((item, index) => {
            return Object.assign(item, {
              inn: item.inn,
              [this.props.name || 'participantInn']: item[
                this.props.valueKey || 'inn'
              ],
              id: index,
              filterString: val,
              title: item.name,
            });
          });

          this.setState({
            isLoading: false,
            values,
          });
        })
        .catch(err => {
          console.warn('canceled or err', err);
        });
    } else if (this.state.values.length > 0) {
      this.setState({
        values: [],
      });
    }
  }

  render() {
    const { onChange, isLoading, values, onRef, ...other } = this.props;

    return (
      <SingleSelect
        {...other}
        onRef={el => {
          this.select = el;
          onRef && onRef(el);
        }}
        withoutIcon
        onChange={this.onChange}
        isLoading={this.state.isLoading}
        values={this.state.values}
        renderOption={Option}
        noValuesText={Translate('<пусто>')}
      />
    );
  }
}

ProducerSelect.displayName = 'SingleSelect';

export default ProducerSelect;
