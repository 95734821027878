import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './AikDisbandment.actions';
import Api from '../../../../../common_components/Api/Api';
import { signUZSaga } from '../../../../../common_components/Eimzo/Eimzo.saga';
import { Base64 } from '@crpt-ui/crypto';
import { replace } from 'react-router-redux';
import { Toast } from '../../../../../utils/Toast';
import { get } from 'lodash';


function* disbandmentSaga(action) {
  const { payload } = action;
  const { idDraft, values } = payload;
  const { applicantInn, aic } = values;
  const document = { aic: aic, inn: applicantInn };
  const signature = yield call(signUZSaga, {
    payload: { data: document, file: false },
  });
  if (!signature) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }
  const base64_data = Base64.encode(JSON.stringify(document));
  const requestPayload = {
    url: '/api/v3/true-api/documents/aic/disaggregation',
    method: 'post',
    data: {
      ...signature,
      document: base64_data,
    }
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success && idDraft !== 'create') {
    const [successDraft, errorDraft] = yield call(Api.request, {
      url : `/api/v3/facade/draft/${idDraft}`,
      method: 'delete',
    })
    if (successDraft) {
      yield put(replace('/documents/list'));
      yield call(Toast.showInfo({
        content: 'Документ создан.',
        autoClose: 5000,
      }));
    }
    if (errorDraft) {
      yield call(Toast.showError, {
        content: get(error, 'responsecertificate.data.message', 'error'),
      });
    }
  }
  if (success && idDraft === 'create') {
    yield put(replace('/documents/list'));
    yield call(Toast.showInfo({
      content: 'Документ создан.',
      autoClose: 5000,
    }));
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'responsecertificate.data.message', 'error'),
    });
  }
}


export default function* watch() {
  yield takeEvery(actions.disbandment, disbandmentSaga);
}
