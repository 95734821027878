import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../_refactor/FieldsWrapper';
import RangePickerAdapter from '../Adapters/RangePickerAdapter';


const RangePicker = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  const nameArray = name.split('.');
  nameArray[nameArray.length - 1] = `__form_RangePicker_${nameArray[nameArray.length - 1]}`;
  const preparedName = nameArray.join('.');

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={preparedName}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <RangePickerAdapter name={preparedName} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

RangePicker.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

RangePicker.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};

RangePicker.displayName = 'RangePicker';


export default RangePicker;
