import React from 'react';
import { Dialog } from '@material-ui/core';
import {
  ButtonsBarWrapper,
  ContentWrapper,
  HeaderWrapper, ModalClose,
  TextHeaderWrapper,
} from '../../../../../../common_components/Modals/ConfirmModal/ConfirmModal.styled';
import { ActionButton } from '../ReconciliationDialog.styled';

export const ExistReconciliationDraftDialog = ({ open, onClose, newReconciliation, continueReconciliation }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <HeaderWrapper>
        <TextHeaderWrapper>ВНИМАНИЕ!</TextHeaderWrapper>
        <ModalClose onClick={onClose}>✖</ModalClose>
      </HeaderWrapper>
      <ContentWrapper>
        <div>
          Для текущего Акта приема-передачи существует сохраненный черновик сверки. Продолжить приемку ?
        </div>
      </ContentWrapper>

      <ButtonsBarWrapper>
        <ActionButton
          onClick={continueReconciliation}
        >
          Продолжить приемку
        </ActionButton>
        <ActionButton
          onClick={newReconciliation}
        >
          Начать приемку заново
        </ActionButton>
      </ButtonsBarWrapper>
    </Dialog>
  )
};
