import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import ReportList from './ReportList/ReportList';
// import { tabs } from './Reports.constants';
import { ReportsService } from '../../services/ReportsService';
import { get } from 'lodash';
import { StoreHelper } from '../../utils/StoreHelper';
import { startPreloader, endPreloader } from '../../actions/Preloader';
import Preloader from '../Preloader';
import {
  EmptyGoodsTextWrap,
  GoodsIconWrap,
  GoodsWrap,
} from '../../motp_components/_Requests/RequestList/Document/Document.styled';
import GoodsIcon from '../../motp_components/_Requests/RequestList/EaesImportDocument/Goods/svg/GoodsIcon.svg';
import { AuthService } from '../../services/AuthService';
import { b64DecodeUnicode } from '../../utils/base64';

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [],
      reports: [],
      load: true,
    };
  }

  onTabSelect = tab => {
    this.props.history.push(`/reports/${tab.url}`);
  };

  async componentDidMount() {
    StoreHelper.dispatch(startPreloader());
    
    try {
      const answer = await ReportsService.getCategories();
      const categories = get(answer, 'data', []);
      const tabs = categories.filter(({id}) => !['violation', 'excise', 'statistic'].includes(id) ).map((c, i) => ({
        ...c,
        title: c.name,
        url: c.id,
        id: i + 1,
      }));

      this.setState({ load: false, tabs: Array.isArray(tabs) ? tabs : [] });
    } catch (e) {
      console.log('Error in categories request:', { e });
    }

    try {
      const answer = await ReportsService.getReports();
      const reports = get(answer, 'data', []);
      this.setState({
        load: false,
        reports: Array.isArray(reports) ? reports : [],
      });
    } catch (e) {
      console.log('Error in reports request:', { e });
    }

    StoreHelper.dispatch(endPreloader());
  }

  render() {
    const { tabs, reports, load } = this.state;
    const url = get(this.state, 'tabs[0].url', '');
    const toggle = Boolean(tabs.length);

    return (
      <div>
        {load ? (
          <Preloader />
        ) : toggle ? (
          <Switch>
            {tabs.map((tab, key) => (
              <Route
                key={key}
                path={`/reports/${tab.url}`}
                component={() => (
                  <ReportList
                    tab={tab}
                    tabs={tabs}
                    reports={reports}
                    onTabSelect={this.onTabSelect}
                  />
                )}
              />
            ))}
            {url ? <Redirect to={`/reports/${url}`} /> : null}
          </Switch>
        ) : (
          <GoodsWrap minHeight="calc(100vh - 200px)">
            <GoodsIconWrap>
              <GoodsIcon />
            </GoodsIconWrap>

            <EmptyGoodsTextWrap>
              Для участника оборота отсутствуют доступные отчеты
            </EmptyGoodsTextWrap>
          </GoodsWrap>
        )}
      </div>
    );
  }
}

export default withRouter(Reports);
