import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getTitle } from './NavigationInfo.utils';
import { Button } from '@crpt-ui/header';

const NavigationInfoComponent = ({ menuItems, location }) => (
  <Button >
    {getTitle(menuItems, location)}
  </Button>
)

NavigationInfoComponent.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    testRoute: PropTypes.string
  })).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

/**
 * Render current navigation path
 */
export const NavigationInfo = compose(
  withRouter,
)(NavigationInfoComponent);

