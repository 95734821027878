import styled from 'styled-components';

export const FilenameWrap = styled.div`
  margin-bottom: 25px;
  color: #4aa5e8;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
`;

export const Description = styled.div`
  margin-bottom: 35px;
  font-size: 14px;
  line-height: 20px;
  color: #63666a;
  font-weight: 600;
`;

export const CertLine = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
  color: #63666A;
  font-size: 14px;
  width: calc(100% + 80px);
  padding-left: 40px;
  padding-right: 40px;
  margin-left: -40px;
  margin-bottom: 30px;
`;

export const ButtonsLine = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrap = styled.div`
  margin-right: 20px;
`;
