
export const inputTheme = {
  Input: {
    InputWrap: {
      main: {
        border: '0',
      },
      disabled: {
        border: '0',
      },
      error: {
        border: '0',
      },
    },
    InputElem: {
      centered: '1',
    },
    Placeholder: {
      normal: {
        fontSize: '14px',
        lineHeight: '19px',
        height: '19px',
      },
      focused: {
        top: '19px',
        fontSize: '14px',
        lineHeight: '19px',
        height: '19px',
        color: '#FFFFFF',
      },
    },
  },
};
