import { Button, Icon, MenuButton, Pagination, Toolbar, Tooltip } from '@crpt-ui/core';
import DataTable from '@crpt-ui/datatable';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback } from 'react';
import { ContentWrapper } from 'src/common_components/styled';
import { NoInfo } from 'src/fragments/Data/NoInfo';
import { Preloader } from 'src/fragments/Table/Preloader';

import { ReactComponent as EmptyListLogo } from '../images/emptyList.svg';
import { useStyles } from './GrayAreaTable.styles';
import { buttonDefaultThemeKZ } from '../../../theme/kz-tobaco/button';
import { Translate } from '../../../common_components/Translate/Translate';

const GrayAreaTableView = memo(({
  bulkActions,
  bulkActionsDisabled,
  bulkTooltipTitle,
  documents,
  goToCreate,
  initialState,
  instance,
  loaded,
  onFilterApply,
  resetFilters,
  onPageChange,
  page,
  pagesCount,
  rowActions,
  setSelectedRowIds,
  total,
  columns,
  isDatatableEmpty,
  onSortChange
}) => {
  const classes = useStyles();

  const ActionsRow = ({resetFilters, isActionsDisabled}) => (
    <div className={classes.actionsRowWrapper}>
      <Button
        onClick={resetFilters}
        disabled={isActionsDisabled}
        size="sm"
        variant="text"
        icon={<Icon name='remove' />}
      >
        {Translate('Сбросить фильтры')}
      </Button>
    </div>
  );

  const renderIcon = useCallback(() => (
    <div className={classes.iconWrapper}>
      <EmptyListLogo />
    </div>
  ), []);

  return (
      <div className={classes.contentWrapper}>
        {bulkActionsDisabled ? (
          <div className={classes.actionWrapper}>
            <Button
              icon={<Icon name='plus' />}
              onClick={goToCreate}
            >
              {Translate('Сформировать выгрузку')}
            </Button>
            <ActionsRow resetFilters={resetFilters} isActionsDisabled={!loaded}/>
          </div>
        ) : (
          <Toolbar
            onClose={() => {
              instance.current.toggleAllRowsSelected(false);
            }}
          >
            <Tooltip
              arrow
              title={bulkTooltipTitle}
              placement='bottom'
            >
              <div>
                <MenuButton
                  items={bulkActions}
                  variant="outlined"
                  disabled={bulkActions.length === 0}
                >
                  {Translate('Действия&nbsp;с&nbsp;выбранными')}
                </MenuButton>
              </div>
            </Tooltip>
          </Toolbar>
        )}
        <ContentWrapper className={classes.datatableWrapper}>
          {isDatatableEmpty ? (
            <NoInfo
              renderIcon={renderIcon}
              title={Translate("Список выгрузок пуст")}
            />
          ) : (
            <Fragment>
              <DataTable
                actions={rowActions}
                columns={columns}
                data={documents}
                initialState={initialState}
                manualFilters
                onFiltersChange={onFilterApply}
                onRowSelect={setSelectedRowIds}
                ref={instance}
                useFilters
                useSort
                onSortChange={onSortChange}
                manualSortBy
                useRowSelect
              />
              <Preloader isOpen={!loaded} />
              <div className={classes.paginationContainer}>
                {total > 10 &&
                  <Pagination
                    page={page + 1}
                    pagesCount={pagesCount}
                    onPageChange={onPageChange}
                  />
                }
                {loaded &&
                  <div className={classes.paginationCount}>
                    {Translate('Всего записей:')}{' '}{total}
                  </div>
                }
              </div>
            </Fragment>
          )}
        </ContentWrapper>
      </div>
  );
});

GrayAreaTableView.propTypes = {
  bulkActions: PropTypes.array.isRequired,
  bulkActionsDisabled: PropTypes.bool.isRequired,
  bulkTooltipTitle: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  goToCreate: PropTypes.func.isRequired,
  initialState: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  setSelectedRowIds: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  isDatatableEmpty: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default GrayAreaTableView;
