import styled from 'styled-components';

export const CancelWrap = styled.div`
  color: #55B5E7;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  box-sizing: border-box;
`;