import { defaultTheme } from './default';
import { whiteTheme } from './white';
import { tabItem } from './tabItem';
import { selectedTabItem } from './selectedTabItem';
import { createUserTheme } from './createUser';
import { redTheme } from './red';
import { clearTheme } from './clear';
import { signTheme } from './sign';

export {
  defaultTheme as buttonDefaultThemeKZ,
  tabItem as buttonTabItemThemeKZ,
  selectedTabItem as buttonSelectedTabItemThemeKZ,
  whiteTheme as buttonWhiteThemeKZ,
  createUserTheme as buttonCreateUserThemeKZ,
  redTheme as buttonRedThemeKZ,
  clearTheme as buttonClearThemeKZ,
  signTheme as buttonSignThemeKZ,
};
