import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTitleName, RedText,
  GreenText, WarningContent, WarningIconWrap,
  WarningOfNeed } from '../Document/Document.styled';
import { Error, Success } from '@crpt-ui/icons';
import { Translate } from 'src/common_components/Translate/Translate';
import { countryCodeFlags } from "../../../../constants";

export const TitleAndWarning = ({ left, showLeft = true, title }) => {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <DocumentTitleName>{Translate(title)}</DocumentTitleName>
      <WarningOfNeed>
        <WarningContent>
          <WarningIconWrap>
            {left > 0 ? (
              <Error htmlColor={'#ff4b4b'} />
            ) : (
              <Success htmlColor={'#60e84a'} />
            )}
          </WarningIconWrap>
          {left > 0 ? (
            <div>
              <RedText>{Translate('Красным')}</RedText>{' '}
              {Translate('отмечены обязательные для заполнения поля')}
            </div>
          ) : (
            <div>
              {i18n.language.toUpperCase() === 'RU' || i18n.language.toUpperCase() === 'UZ' ?
                countryCodeFlags.isUZ
                  ? Translate('Все обязательные поля заполнены')
                  : (
                    <Fragment>
                      <GreenText>{Translate('Зеленым')}</GreenText>{' '}
                      {Translate('отмечены обязательные для заполнения')}
                      {' '}
                      {Translate('заполненные поля')}
                    </Fragment>
                    )
                : i18n.language.toUpperCase() === 'EN'
                  ? countryCodeFlags.isUZ
                    ? Translate('Все обязательные поля заполнены')
                    : (
                      <Fragment>
                        Mandatory and completed fields are marked in <GreenText>{'green'}</GreenText>
                      </Fragment>
                    )
                  :(
                  <Fragment>
                    Міндетті және толтырылған өрістер <GreenText>{'жасыл'}</GreenText> түспен белгіленген
                  </Fragment>
                )
              }
            </div>
          )}
        </WarningContent>
        {showLeft && (
          <WarningContent>
            {left > 0 ? (
              <RedText>
                {Translate('Не заполнено')} {left}%
              </RedText>
            ) : (
              <GreenText>{Translate('Заполнено 100%')}</GreenText>
            )}
          </WarningContent>
        )}
      </WarningOfNeed>
    </React.Fragment>
  );
};
