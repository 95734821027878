import React from 'react';
import CommonDescription from '../CommonDescription/CommonDescription';
import defaultParameters from './defaultParameters';
import customParameters from './customParameters';
import tableView1 from './tableView1';
import tableView2 from './tableView2';
import indicatorsDescription from './indicatorsDescription';

export default {
  h1: 'Отчёт по документам',
  maxWidth: '1360px',
  description: <CommonDescription />,
  tables: [
    defaultParameters,
    customParameters,
    tableView1,
    tableView2,
    indicatorsDescription
  ]
}
