import * as Icons from '@crpt-ui/icons';
import {
  Link as MuiLink,
  Tooltip as TooltipMUI,
  withStyles,
} from '@material-ui/core';
import * as _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { environmentLinks } from '../../../constants/environment';
import {Translate} from '../../Translate/Translate';

const productGroupIconsMap = {
  clothes: <Icons.Clothes />,
  electronics: <Icons.Photo />,
  lp: <Icons.Clothes />,
  milk: <Icons.Milk />,
  shoes: <Icons.Shoes />,
  tires: <Icons.Tires />,
  tobacco: <Icons.Tobacco />,
};

export const Link = withStyles(() => ({
  root: {
    color: '#4aa5e8',

    '&:hover': {
      color: '#63666a',
      textDecoration: 'none',
    },
  },
}))(MuiLink);

const Tooltip = withStyles(theme => ({
  tooltip: {
    padding: '16px',
    fontSize: 14,
    lineHeight: 16 / 14,
    backgroundColor: 'rgba(0, 0, 0, .6)',
  },
  popperArrow: {
    '&[x-placement*="bottom"] $arrow': {
      marginTop: '-1em',
      left: `${theme.spacing(2)}px !important`,
      color: 'rgba(0, 0, 0, .6)',
      fontSize: 9,
    },
  },
  arrow: {},
}))(TooltipMUI);

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    padding: '10px 0',
  },
  icon: {
    width: 40,
    height: 40,
    backgroundColor: '#F7F7F7',
    borderRadius: 3,
    display: 'flex',
    marginRight: 24,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#63666A',
    flexShrink: 0,
  },
  code: {
    flexGrow: 1,
    fontWeight: 600,
    marginRight: 24,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const DayItem = ({ info: data, classes }) => {
  const cis = React.useMemo(
    () => _.get(data, `${data.category}Data.codeData.cis`, data.code),
    [data],
  );

  const href = React.useMemo(() => {
    if (data.codeFounded) {
      const prefix = _.get(environmentLinks, data.category);
      const suffix = _.get({ tobacco: 'cis/list' }, data.category, 'cis');

      if (prefix && suffix) {
        const code = _.get(
          data,
          `${data.category}Data.codeData.cis`,
          data.code,
        );

        return `${prefix}/${suffix}/${encodeURIComponent(code)}`;
      }
    }

    return '#';
  }, [data]);

  const onClick = React.useCallback(
    event => {
      event.preventDefault();

      if (data.codeFounded) {
        const prefix = _.get(environmentLinks, data.category);
        const suffix = _.get({ tobacco: 'cis/list' }, data.category, 'cis');

        if (prefix && suffix) {
          const code = _.get(
            data,
            `${data.category}Data.codeData.cis`,
            data.code,
          );

          window.open(
            `${prefix}/${suffix}/${encodeURIComponent(code)}`,
            '_blank',
          );
        }
      }
    },
    [data],
  );

  const renderIcon = React.useCallback(() => {
    if (data.category && data.codeFounded) {
      return _.get(productGroupIconsMap, data.category);
    }

    return <Icons.Close />;
  }, [data]);

  const renderCis = React.useCallback(() => {
    if (!data.codeFounded) {
      return (
        <Tooltip
          arrow
          enterDelay={1000}
          interactive
          placement="bottom-start"
          title={Translate('Код маркировки отсутствует в базе')}
        >
          <span>{cis}</span>
        </Tooltip>
      );
    }

    return (
      <Link href={href} target="_blank" title={cis}>
        {cis}
      </Link>
    );
  }, [cis, href, data]);

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{renderIcon()}</div>
      <div className={classes.code}>{renderCis()}</div>
      <div style={{ fontSize: 14 }}>
        {moment(data.checkDate).format('HH:mm')}
      </div>
    </div>
  );
};

export default withStyles(styles)(DayItem);
