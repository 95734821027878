import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProductService } from 'src/services/ProductService';
import { MultiSelectWithSearch } from 'src/common_components/MultiSelectWithSearch';
import { FilterURLHelper } from 'src/utils/FilterURLHelper';
import { countryCodeFlags } from '../../constants';

const { isKZ } = countryCodeFlags;

const TnVedCodesMultiSelect = ({
  name,
  placeholder,
  inline,
  onRef,
  onSelect,
}) => {
  const [loading, setLoading] = useState(true);
  const [tnVedCodes, setTnVedCodes] = useState([]);
  const [defaultSelectedCodes, setDefaultSelectedCodes] = useState([]);

  const selectRefBinder = useCallback(el => onRef(el), [onRef]);

  useEffect(() => {
    ProductService.getTnVedCodes().then(({ data }) => {
      setTnVedCodes(
        data.filter(el=> isKZ ? el.length === 10 : true).map((tnVedCode, i) => ({
          id: i + tnVedCode,
          title: tnVedCode,
          tn_ved_code: tnVedCode,
        })),
      );
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!!tnVedCodes.length) {
      const value = FilterURLHelper.getParamByKey(name);
      if (value) {
        if (Array.isArray(value)) {
          setDefaultSelectedCodes(
            tnVedCodes.filter(v => value.includes(v.tn_ved_code)),
          );
        } else {
          const tnVedCode = tnVedCodes.find(v => v.tn_ved_code === value);
          setDefaultSelectedCodes([tnVedCode]);
        }
      }
    }
  }, [name, tnVedCodes]);

  return (
    <MultiSelectWithSearch
      options={tnVedCodes}
      isLoading={loading}
      name={name}
      inline={inline}
      placeholder={placeholder}
      onSelect={onSelect}
      onSelectRef={selectRefBinder}
      defaultSelectValue={defaultSelectedCodes}
      savePlaceholder
    />
  );
};

TnVedCodesMultiSelect.defaultProps = {
  inline: false,
  onRef: () => {},
  onSelect: () => {},
};

TnVedCodesMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  onSelect: PropTypes.func,
  onRef: PropTypes.func,
};

TnVedCodesMultiSelect.displayName = 'MultiSelect';

export default TnVedCodesMultiSelect;
