import { TwoLineLabel } from '@crpt/react-label';
import { Column } from '@crpt/react-page-layout';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from '../../Operation.styles';

const FifthCell = ({ heading, children }) => {
    const classes = useStyles();
    return (
        <Column col={4}>
            <div className={classes.fullWidthWrapper}>
                <TwoLineLabel heading={heading}>{children}</TwoLineLabel>
            </div>
        </Column>
    );
};

FifthCell.propTypes = {
    heading: PropTypes.string.isRequired,
    content: PropTypes.object,
};

export default FifthCell;
