import { createAction } from 'redux-actions';

export const subject = '[ScanDialog]';

export const unmounted = createAction(`${subject} unmounted`);
export const scanCodes = createAction(`${subject} scanning codes`);
export const setScannedCodes = createAction(`${subject} set scanned codes`);
export const removeCodesFromScannedList = createAction(
  `${subject} remove codes from scanned list`,
);
export const compareCodes = createAction(
  `${subject} compare codes (scanned and in act)`,
);
export const setCodesForComparing = createAction(
  `${subject} set codes for compare`,
);

// helper action
export const setError = createAction(`${subject} set error info`);
export const closeModal = createAction(`${subject} close marked list modal`);
