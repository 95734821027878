import React from 'react';
import { Translate } from '../../common_components/Translate/Translate';
import { Value } from '../../fragments/Table/Cell/value';
import { StatusCell } from './components/StatusCell';
import Icons from '../../common_components/Icons/Icons';
import { Icon } from '@material-ui/core';
import { ArticleType } from './components/StatusCell/cell.styled';
import { TableActions } from './articles.styled';
import {
  Link,
} from './articles.styled';
import moment from 'moment';

export const ARTICLE_TYPES = {
  article: 'article',
  carousel: 'carousel',
}

export const ARTICLE_TYPE_NAMES = {
  [ARTICLE_TYPES.article]: Translate('Статья'),
  [ARTICLE_TYPES.carousel]: Translate('Баннер'),
}

export const ARTICLE_TYPE_OPTIONS = Object.values(ARTICLE_TYPES).map((type) => ({
  label: ARTICLE_TYPE_NAMES[type],
  value: type
}));

export const ARTICLE_TYPE_ICON = {
  [ARTICLE_TYPES.article]: <Icon component={Icons.Article} style={{ width: 19 }}/>,
  [ARTICLE_TYPES.carousel]: <Icon component={Icons.Gallery} style={{ width: 19 }}/>,
}

export const ARTICLE_STATUSES = {
  ACTIVE: 'ACTIVE',
  PLANNED: 'PLANNED',
  STOPPED: 'STOPPED',
};

export const ARTICLE_STATUSES_NAMES = {
  [ARTICLE_STATUSES.ACTIVE]: Translate('Активна'),
  [ARTICLE_STATUSES.PLANNED]: Translate('Запланирована'),
  [ARTICLE_STATUSES.STOPPED]: Translate('Остановлена'),
}

export const ARTICLE_STATUS_OPTIONS = Object.values(ARTICLE_STATUSES).map((status) => ({
  label: ARTICLE_STATUSES_NAMES[status],
  value: status
}))

const orders = [1,2,3,4,5,6,7,8,9,10];

export const ARTICLE_ORDER_OPTIONS = orders.map((order) => ({
  label: order,
  value: order
}))

export const BUCKET_URL = `${window.env.CONTENT_S3_URL}/s3-proxy/mobile-content/`;

export const INITIAL_TABLE_STATE = {sortBy: [{id: 'id', desc: true}]}

export const getColumns = (onCopy, onEdit, lang, onPreview) => [
  {
    accessor: 'id',
    id: 'id',
    Cell: ({ row }) => {
      return <Value>{row.original.id}</Value>;
    },
    Header: Translate('Идентификатор'),
    width: 140,
  },
  {
    accessor: 'itemType',
    id: 'itemType',
    Cell: ({ row }) => <ArticleType>
      {ARTICLE_TYPE_ICON[row.original.itemType]}
      {ARTICLE_TYPE_NAMES[row.original.itemType]}
    </ArticleType>,
    Header: Translate('Тип'),
    width: 150,
  },
  {
    accessor: 'order',
    id: 'order',
    Cell: ({ row }) => row.original.order,
    Header: Translate('№'),
    width: 100,
  },
  {
    accessor: 'name',
    id: 'name',
    Cell: ({ row }) => {return (
      <Link onClick={() => onPreview(row.original)}>
        {row.original.name}
      </Link>
    )},
    Header: Translate('Заголовок'),
    width: 400,
  },
  {
    accessor: 'status',
    id: 'status',
    Cell: ({ row }) => <StatusCell status={row.original.status}/>,
    Header: Translate('Статус'),
  },
  {
    accessor: 'createdOn',
    id: 'createdOn',
    Cell: ({ row }) => moment(row.original.createdOn).format('YYYY-MM-DD HH:mm'),
    Header: Translate('Дата создания'),
  },
  {
    accessor: 'startDate',
    id: 'startDate',
    Cell: ({ row }) => moment(row.original.startDate).format('YYYY-MM-DD HH:mm'),
    Header: Translate('Дата публикации'),
  },
  {
    accessor: 'action',
    id: 'action',
    Cell: ({ row }) => {
      return (
        <TableActions>
          <Icon component={Icons.Copy} onClick={() => onCopy(row.original)}  />
          {
            row.original.status !== ARTICLE_STATUSES.STOPPED && (
              <Icon component={Icons.EditUz} onClick={() => onEdit(row.original)} />
            )
          }
        </TableActions>
      )
    },
    Header: '',
    width: 80,
  },
]
