import { push } from 'react-router-redux';
import { get } from 'lodash';
import { actionTypes } from '../constants';
import { AuthService } from '../services/AuthService';
import { showAnyError } from '../utils/requestErrorHandlers';
import { ProfileService } from '../services/ProfileService';
import { GetCertificates } from './LoginFormKep/GetCertificates';
import { Toast } from '../utils/Toast';
import {saveToken, saveCertSerial} from '../utils/cookieHelpers';
import {localStorageHelpers} from '../utils/localStorageHelpers';

export const certificatesLoaded = certificates => ({
  type: actionTypes.LOGIN_FORM_KEP.CERTIFICATES_LOADED,
  value: certificates,
});

export const setLanguage = language => ({
  type: actionTypes.LOGIN_FORM_KEP.SET_LANGUAGE,
  value: language,
});

export const certSelected = () => ({
  type: actionTypes.LOGIN_FORM_KEP.CERTIFICATE_SELECTED,
});

export const gotLoginSuccess = () => ({
  type: actionTypes.LOGIN_FORM.GOT_SUCCESS_LOGIN,
});

export const gotLoginError = () => ({
  type: actionTypes.LOGIN_FORM.GOT_ERROR_LOGIN,
});

export const mounted = () => async dispatch => {
  let certificates = [];
  const certToast = Toast.showInfo({
    content: 'Идет загрузка сертификатов...',
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
  });

  try {
    const cadesCertificates = await GetCertificates.getCadesCertificates();
    certificates = certificates.concat(cadesCertificates);
  } catch (e) {
    console.log('Cannot get cades certificates:', e);
  }

  try {
    const rutokenCertificates = await GetCertificates.getRutokenCertificates();
    certificates = certificates.concat(rutokenCertificates);
  } catch (e) {
    console.log('Cannot get rutoken certificates:', e);
  }

  try {
    const jacartaCertificates = await GetCertificates.getJacartaCertificates();
    certificates = certificates.concat(jacartaCertificates);
  } catch (e) {
    console.log('Cannot get jacarta certificates:', e);
  }

  try {
    const jacarta2Certificates = await GetCertificates.getJacarta2Certificates();
    certificates = certificates.concat(jacarta2Certificates);
  } catch (e) {
    console.log('Cannot get jacarta2 certificates:', e);
  }

  Toast.closeToast(certToast);
  Toast.showInfo({ content: 'Загрузка сертификатов завершена.' });

  console.log('certificates', certificates);
  dispatch(certificatesLoaded([...certificates]));
};

export const loginPressed = values => async dispatch => {
  const cert = values.cert[0];

  try {
    const answer = await AuthService.loginByKep(cert);
    const url = get(answer, 'request.responseURL', '');
    if (url.indexOf('Error') !== -1) {
      dispatch(gotLoginError());
      return;
    }

    saveToken(answer.data.token);
    saveCertSerial(cert.serial);

    const user = {};
    user.isAdmin = answer.data.admin;

    const roles = localStorageHelpers.getRolesFromToken(answer.data.token);
    Object.assign(user, roles);

    try {
      const profile = await ProfileService.getCompanyProfile();
      user.company = profile.data;
    } catch (e) { null; }

    try {
      const image = await ProfileService.getParticipantImage(user.id);
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(image.data))
      );
      user.company
        ? (user.company.image = 'data:image/png;base64,' + base64String)
        : (user.company = {image: 'data:image/png;base64,' + base64String});
    } catch (e) {
      console.log('cannot get participant image:', e);
    }

    user.participant = answer.data.participant;
    localStorage.setItem('user', JSON.stringify(user));

    dispatch(gotLoginSuccess());

    dispatch(push('/after-login'));
  } catch (e) {
    showAnyError(e);
  }
};
