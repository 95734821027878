import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, FormLine } from '@crpt/react-form';
import { Column, Container } from '@crpt/react-page-layout';
import { Button, SIZES, themes } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import {
  SidebarWrap,
  Header,
  HeaderText,
  AcceptButtonWrap,
  Controls,
  filterButtonTheme,
} from './styled';
import { FilterURLHelper } from '../../../../../utils/FilterURLHelper';
import { Translate } from '../../../../../common_components/Translate/Translate';
import i18next from 'i18next';

export default class FilterSidebar extends PureComponent {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    filterFields: PropTypes.array.isRequired,
    onFilterClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  renderLine = line => {
    const { filterParams } = this.props;

    return line.map((value, i) => {
      const Component = value && value.component;
      const type = value && value.type;
      const { type: typeFromProps } = this.props;

      const initial = FilterURLHelper.getInitialValue(filterParams, value);
      const isShow = !type || type === typeFromProps;

      const rendered = Component ? (
        <Component {...value.props} {...initial} />
      ) : null;

      const FilterComponent = (
        <FormLine key={i}>
          <Container fillWidth gutter={10.5}>
            <Column key={i}>{rendered}</Column>
          </Container>
        </FormLine>
      );

      return isShow ? FilterComponent : null;
    });
  };

  render() {
    const { onFilterApply, onFilterClose, filterFields } = this.props;

    return (
      <SidebarWrap>
        <Header>
          <HeaderText>{Translate('Фильтр')}</HeaderText>
          <Icon
            type="cross"
            width={19}
            height={19}
            onClick={onFilterClose}
            fill="#52535a"
          />
        </Header>
        <Form
          onSubmit={value => onFilterApply(value)}
          onClear={value => console.log('clear value', value)}
        >
          {filterFields.map(this.renderLine)}
          <Controls>
            <Button
              action="clear"
              type="submit"
              size={SIZES.large}
              theme={themes.whiteTheme}
            >
              {i18next.t('Сбросить')}
            </Button>

            <AcceptButtonWrap>
              <Button
                type="submit"
                size={SIZES.large}
                theme={filterButtonTheme}
              >
                {i18next.t('Применить')}
              </Button>
            </AcceptButtonWrap>
          </Controls>
        </Form>
      </SidebarWrap>
    );
  }
}
