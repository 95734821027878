import React from 'react';

import { SingleSelect } from '@crpt/react-select';
import { PACK_TYPE } from '../../constants/packTypes';
// import { statuses } from './CISStatusSelect';
import { countryCodeFlags, EAESPackageTypes } from '../../constants';
import { Translate } from '../../common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';

export const cisPackageTypes = Object.keys(EAESPackageTypes)
  .filter(key => countryCodeFlags.isKZ ? key !== 'aic': key)
  .map((key, i) => {
  return {
    id: i,
    title: EAESPackageTypes[key],
    cisPackageType: PACK_TYPE[key],
  };
});

function CISPackageSelect(props) {
  const { selectedPackageType } = props;
  const { t } = useTranslation();

  let selectedId;

  if (selectedPackageType) {
    selectedId = cisPackageTypes.findIndex(
      s => s.cisPackageType === selectedPackageType,
    );
  }
  const translatedCisPackageTypes = cisPackageTypes.map(el=> ({ ...el, title: t(el.title) }));
  return (
    <SingleSelect
      placeholder={t('Упаковка')}
      name="cisPackageType"
      values={translatedCisPackageTypes}
      selectedId={selectedId}
      {...props}
    />
  );
}

CISPackageSelect.displayName = 'SingleSelect';

export default CISPackageSelect;
