import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './Wrapper';
import { getInputValueString, onSelectBlocker } from '../utils';
import { BottomArrowIcon, CrossIcon, SearchIcon } from '../icons';
import { Input } from './Input';
import { Placeholder } from './Placeholder';

export const SelectInput = ({
  selectValues,
  isFocused,
  onClear,
  savePlaceholder,
  theme,
  disabled,
  isError,
  placeholder,
  onFocus,
  inputValue,
  onChange,
}) => {
  const selectValuesString = getInputValueString(selectValues);
  const value = isFocused ? inputValue : selectValuesString;
  const renderIcon = useCallback(() => {
    if (isFocused) {
      return <SearchIcon />;
    } else if (!selectValuesString) {
      return <BottomArrowIcon />;
    }
    return <CrossIcon style={{ cursor: 'pointer' }} onClick={onClear} />;
  }, [isFocused, selectValuesString]);

  return (
    <Wrapper onSelect={onSelectBlocker}>
      <Placeholder
        isFocused={isFocused || !!selectValuesString}
        disabled={disabled}
        isError={isError}
        theme={theme}
        isSaved={savePlaceholder}
      >
        {placeholder}
      </Placeholder>
      <Input
        onFocus={onFocus}
        centered={!savePlaceholder}
        value={value}
        theme={theme}
        isError={isError}
        disabled={disabled}
        onChange={onChange}
      />
      {renderIcon()}
    </Wrapper>
  );
};

SelectInput.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  savePlaceholder: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      InputElem: PropTypes.object,
      Placeholder: PropTypes.object,
    }),
  }).isRequired,
  selectValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onFocus: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
