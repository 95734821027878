import papa from 'papaparse';
import XLSX from 'src/packages/xlsx/xlsx.full.min';
import { Toast } from 'src/utils/Toast';

export const readFile = async file => {
  try {
    const { name } = file;
    const type = name
      .substr(name.lastIndexOf('.') + 1, name.length)
      .toUpperCase();

    const success = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = reject;
      if (type === 'XLSX' || type === 'XLS') {
        fileReader.onload = e => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array', raw: true, dateNF: 'dd"."mm"."yyyy' });

          resolve({
            content: workbook,
            data: e.target.result,
            file,
            type,
            name,
          });
        };

        fileReader.readAsArrayBuffer(file);
      } else {
        fileReader.onload = () => {
          resolve({
            content: fileReader.result,
            file,
            type,
            name,
          });
        };

        fileReader.readAsText(file, 'utf-8');
      }
    });
    return ({ success });
  } catch (error) {
    return ({ error });
  }
};

export const parseFile = (data, rowHandler, parsedData = []) => {
  const { type, content: payloadContent } = data;
  const content = ['XLS', 'XLSX'].includes(type)
    ? XLSX.utils.sheet_to_csv(payloadContent.Sheets[payloadContent.SheetNames[0]], {
      dateNF: 'DD.MM.YYYY',
    })
    : payloadContent;

  try {
    papa.parse(content, {
      step: rowHandler(parsedData),
      delimiter: ',',
    });
    return parsedData;
  } catch (error) {
    return error;
  }
};

/**
 * parsedData - массив для сохранения данных парсинга
 * results.data - данные строки
 * results.errors - ошибки, возникшие при парсинге строки
 */
export const rowHandlerDefault = parsedData => (results, parser) => {
  if (results.errors.length) {
    parser.abort();
    const errorEntry = Object.entries(results.errors[0]);
    const message = errorEntry.map(([key, value]) => ` ${key}: ${value}`);
    Toast.showError({ content: message });
    throw new Error(`Некорректные данные, ${message}`);
  }

  parsedData.push(results.data);
};
