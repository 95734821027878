import React, { Fragment } from 'react';
import {
  DocumentTitleName,
  RedText,
  GreenText,
  WarningContent,
  WarningIconWrap,
  WarningOfNeed,
} from '../../Document/Document.styled';
import { Error, Success } from '@crpt-ui/icons';
import { Translate } from 'src/common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';

export const TitleAndWarning = ({ left, showLeft, showWarning }) => {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <DocumentTitleName>
        {Translate('Акт приема-передачи товаров')}
      </DocumentTitleName>
      {
        showWarning && (
          <WarningOfNeed>
            <WarningContent>
              <WarningIconWrap>
                {left > 0 ? (
                  <Error htmlColor={'#ff4b4b'} />
                ) : (
                  <Success htmlColor={'#60e84a'} />
                )}
              </WarningIconWrap>
              {left > 0 ? (
                <div>
                  <RedText>{Translate('Красным')}</RedText>{' '}
                  {Translate('отмечены обязательные для заполнения поля')}
                </div>
              ) : (
                <div>
                  {i18n.language === 'RU' || i18n.language === 'UZ' ? (
                    <Fragment>
                      <GreenText>{Translate('Зеленым')}</GreenText>{' '}
                      {Translate('отмечены обязательные для заполнения')}{' '}
                      {Translate('заполненные поля')}
                    </Fragment>
                    )
                    : (
                      <Fragment>
                        Міндетті және толтырылған өрістер <GreenText>{'жасыл'}</GreenText> түспен белгіленген
                      </Fragment>
                    )
                  }
                </div>
              )}
            </WarningContent>
            {showLeft && (
              <WarningContent>
                {left > 0 ? (
                  <RedText>
                    {Translate('Не заполнено')} {left}%
                  </RedText>
                ) : (
                  <GreenText>{Translate('Заполнено 100%')}</GreenText>
                )}
              </WarningContent>
            )}
          </WarningOfNeed>
        )
      }
    </React.Fragment>
  );
};

TitleAndWarning.defaultProps = {
  showLeft: true,
  showWarning: true,
};
