import { authInstance } from './authInstance';
import {getPrefix} from '../utils';
import moment from 'moment';

const PACK_TYPES = {
  pack: 0,
  block: 1,
  box: 2
};

export class ExciseService {

  static getList(params) {
    return ExciseService.getExciseProductList(params);
  }

  static uploadDeclaration(params) {
    const {
      year,
      month = 0,
      producerInn,
      declaration,
     } = params;

    const formData = new FormData();
    formData.append('file', declaration);

    return authInstance({
      url: `/api/v3/facade/excise/upload-declaration/${producerInn}/${year}/${month}`,
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }


  static getExciseProductList(params) {
    const {
      year,
      month = 0,
      producerId,
      limit = 10,
      needTotal = true,
      offset = 0,
    } = params;

    return authInstance({
      url: `/api/v3/facade/excise/${producerId}/products/${year}/${month}`,
      method: 'GET',
      params: { limit, needTotal, offset }
    });

  }

  static getMarkingStat(data) {

    const params = {};

    const day = data.month ? `${data.year}-${data.month}` : `${data.year}`;
    const format = data.month ? 'YYYY-MM' : 'YYYY';
    const of = data.month ? 'month' : 'year';

    const dt_beg = moment(day, format).startOf(of).format('YYYY-MM-DD');
    const dt_end = moment(day, format).endOf(of).format('YYYY-MM-DD');

    params.dt_beg = dt_beg;
    params.dt_end = dt_end;
    params.pack_type = PACK_TYPES[data.packageType];
    params.inn = data.producerInn;
    if (data.gtin) params.gtin = data.gtin;

    return authInstance.request({
      url: `/stat/get_producer_data`,
      method: 'GET',
      params,
      withCredentials: false,
    });

  }

  static getStat(params) {
    const { year, month = 0, producerId } = params;

    return authInstance.request({
      url: `/api/v3/facade/excise/${producerId}/statistics/${year}/${month}`,
      method: 'GET',
    });
  }
}
