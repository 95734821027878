import moment from 'moment';
import { CadesUtil } from '../../../../../utils/CadesUtil';

export function getSerializedRutokenCert(c, i) {
  const from = moment(c.ValidFromDate);
  const to = moment(c.ValidToDate);

  return Object.assign({}, c, {
    id: i,
    serial: c.id,
    name: `${c.Subject.surname} ${c.Subject.givenName}`,
    date: `${from.format('YYYY.MM.DD')} - ${to.format('YYYY.MM.DD')}`,
    source: c.Subject.organizationName,
    title: c.Subject.organizationName,
    disabled: !c.IsValid,
  });
}

export function getSerializedCadesCert(c, i) {
  const from = moment(c.info[1]);
  const to = moment(c.info[2]);

  return {
    id: i,
    name: CadesUtil.getNameFromSubject(c.info[0]),
    position: CadesUtil.getByTagFromSubject(c.info[0], 'T'),
    date: `${from.format('DD.MM.YYYY')} - ${to.format('DD.MM.YYYY')}`,
    source: CadesUtil.getCompanyNameFromSubject(c.info[0]),
    issuer: CadesUtil.getNameFromSubject(c.info[4]),
    title: c.info[0],
    disabled: moment() > to,
    ...c,
  };
}

export function getSerializedCert(c, i) {
  switch (c.certType) {
    case 'JACARTA':
    case 'JACARTA2':
    case 'RUTOKEN':
      return getSerializedRutokenCert(c, i);
    case 'CADES':
    default:
      return getSerializedCadesCert(c, i);
  }
}

export const getCertificateValues = (certs = []) =>
  certs.map(getSerializedCert);
