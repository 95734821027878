import { get, chunk, isUndefined, isArray, isFunction } from 'lodash';
import  { prepareDateForJSON } from '../../../../../utils';

export const getTitleSelect = (value, values) => {
  const item = values.filter(item => item.type === value);
  return item[0].title;
};

export const getParsedProps = (fields, values, item, key) => {
  if (item.props.hasOwnProperty('name')) {
    // удаляем ненужную вложенность, если есть
    const name = (!isUndefined(key) ? item.props.name.replace(`${key}.`, '') : item.props.name);
    // смотрим только те что были выбраны
    if (values.hasOwnProperty(name)) {
      const value = values[name];
      const type = item.type.displayName;
      switch (type) {
        case 'SingleSelect':
          fields[name] = {
            title: item.props.placeholder,
            value: getTitleSelect(value, item.props.values),
          };
          break;
        case 'DatePicker':
          fields[name] = {
            title: item.props.placeholder,
            value: prepareDateForJSON(value, 'DD.MM.YYYY'),
          };
          break;
        case 'InfoLabel':
        case undefined:
          break;
        case 'File':
          // if (value) {
          //   fields[name] = {
          //     title: 'Файл PDF',
          //     value: 'прикреплен',
          //   };
          // }
          break;
        default:
          fields[name] = {
            title: item.props.placeholder,
            value,
          };
      }
    }
  }

  if (item.props.hasOwnProperty('children')) {
    enumerateProps(fields, values, item, key);
  }
};


export const enumerateProps = (fields, values, data, key) => {
  // парсим и вытаскиваем нужные данные
  if (isArray(data.props.children)) {
    data.props.children.forEach((item, i) => {
      getParsedProps(fields, values, item, key);
    });
  } else if (!isFunction(data.props.children)) {
    getParsedProps(fields, values, data.props.children, key);
  }
};

export const getPropsFile = (fields, values, data) => {
  enumerateProps(fields, values, data);
};

export const getPropsData = (values, data, key) => {
  const fields = {};

  // если вложенность - дастаем
  if (!isUndefined(key)) {
    getPropsFile(fields, values, data);
    values = values[key];
  }
  // перебираем пропсы компонентов
  enumerateProps(fields, values, data, key);
  return fields;
};

export const transformChunkArray = (content, numCol) => {
  const numRows = Math.ceil(content.length / numCol);

  const data = chunk(content, numRows);
  const arrData = [];

  if (data.length > numCol) {
    data.forEach((item, i) => {
      if (i >= numCol) {
        arrData[0] = arrData[0].concat(item);
      } else {
        arrData[i] = item;
      }
    });

    return arrData;
  }

  return data;
};

export const getDataTable = (columns_, values, goodsKey) => {
  // Определяем какие колонки выводить / видимые
  const arrСolumns = columns_.filter((column) => {
    if (column.hasOwnProperty('visibility')) {
      const visibility = get(column, 'visibility');
      return visibility(values);
    }
    return true;
  });

  // Специфические accessor задаются из констант - простые тут
  const columns = arrСolumns.map((item) => {
    if (!item.hasOwnProperty('accessor')) {
      item.accessor = item.id;
    }
    return item;
  });

  return [columns, values[goodsKey]];
};
