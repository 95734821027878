import styled, { css } from 'styled-components';

export const CisCodeWrap = styled.a`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  color: #55b5e7;
  cursor: pointer;
  display: block;
  text-decoration: none !important;
`;

// TODO: delete IconWrapper
// IconWrapper нужен для устранения бага, связанного с отображением иконки box
export const IconWrapper = styled.span`
  ${({type}) => type === 'box' ? css`& path { mask: none }`: null}
`

export const SignModalWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;  
  max-width: 89vw;
  max-height: 89vh;  
  overflow-y: auto;
  padding: 10px;
`;

export const ModalHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 22px;
  border-bottom: 1px solid #f2f2f2;
  color: #63666A;
  font-size: 20px;
  height: 60px;
`;

export const ModalContentWrap = styled.div`
  padding: 40px;
  padding-bottom: 20px;
`;

export const ErrorWrap = styled.div`
  background: red;
  color: white;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
 `;

export const CertWrap = styled.div`
    background: #f3f0f0;
    width: 100%;
    padding: 25px 15px;
    margin-bottom: 15px;
    & span {
      font-weight: 600;
      margin-right: 10px;
    }
 `;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  height: 90px;
  border-top: 1px solid #F2F2F2;
`;
