import styled from 'styled-components';

export const steps = {
  info: 0,
  goods: 1,
};

export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  ERROR,
};

export const senderDocumentFormat = documentData => {
  const submitDocument = {
    aic: documentData.aik,
    inn: documentData.applicantInn,
    tnved: documentData.tnved,
    gtin: documentData.gtin,
    productName: documentData.productName,
    pg: documentData.pg,
    cisesCount: documentData.count,
    cises: documentData.products.map(el => el.cis),
  }

  const tnvedAddUnits = documentData.tnvedAddUnits
  const tnvedAddUnitsCount = documentData.tnvedAddUnitsCount

  if (tnvedAddUnits && tnvedAddUnitsCount) {
    submitDocument.tnvedAddUnits = tnvedAddUnits
    submitDocument.tnvedAddUnitsCount = tnvedAddUnitsCount
  }

  return submitDocument
};

export const AikBarButtons = styled.div`
  display: flex;
  button {
    margin-left: 20px;
  }
`

export const AddAikBarWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: -15px;
    display: flex;
    justify-content: space-between;
    button {
      width: 200px;
    }
`;

export const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;

export const WrapperForTable = styled.div`
  .rt-td {
    min-height: 40px;
  }
`;

export const ModalTitleWrapper = styled.div`
  padding-top: 0px;
`;
