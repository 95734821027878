import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { get } from 'lodash';

const style = {
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const hinterStyle = {
  display: 'none',
  visibility: 'hidden',
  position: 'fixed',
  zIndex: 10000,
};

const ShadowWrapper = memo(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: ${({ theme }) => get(theme, 'Menu.item.hint.backgroundColor', 'red')};
  
  border-radius: ${({ theme }) => get(theme, 'Menu.item.hint.borderRadius', '10px')};
  padding-top: ${({ theme }) => get(theme, 'Menu.item.hint.paddingTop', '10px')};
  padding-bottom: ${({ theme }) => get(theme, 'Menu.item.hint.paddingBottom', '10px')};
  padding-right: ${({ theme }) => get(theme, 'Menu.item.hint.paddingRight', '10px')};
  padding-left: ${({ theme }) => get(theme, 'Menu.item.hint.paddingLeft', '10px')};
  
  box-shadow: ${({ theme }) => get(theme, 'Menu.item.hint.boxShadow', '')};
`);

const HinterWrapper = memo(styled.div`
  z-index: 4;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: ${({ theme }) => get(theme, 'Menu.item.hint.backgroundColor', 'red')};
  
  border-radius: ${({ theme }) => get(theme, 'Menu.item.hint.borderRadius', '10px')};
  padding-top: ${({ theme }) => get(theme, 'Menu.item.hint.paddingTop', '10px')};
  padding-bottom: ${({ theme }) => get(theme, 'Menu.item.hint.paddingBottom', '10px')};
  padding-right: ${({ theme }) => get(theme, 'Menu.item.hint.paddingRight', '10px')};
  padding-left: ${({ theme }) => get(theme, 'Menu.item.hint.paddingLeft', '10px')};
`);

class HintedOption extends React.Component {
  hinterRef = React.createRef();

  itemRef = React.createRef();

  hinterWrapperRef = React.createRef();

  shadowWrapperRef = React.createRef();

  componentDidMount() {
    this.itemRef.current.addEventListener('mouseenter', this.onMouseEnter);
    this.itemRef.current.addEventListener('mouseleave', this.onMouseLeave);
    this.itemRef.current.parentNode.parentNode.addEventListener('scroll', this.onMouseLeave);
  }

  componentWillUnmount() {
    this.itemRef.current.removeEventListener('mouseenter', this.onMouseEnter);
    this.itemRef.current.removeEventListener('mouseleave', this.onMouseLeave);
    this.itemRef.current.parentNode.parentNode.removeEventListener('scroll', this.onMouseLeave);
    this.onMouseLeave();
  }

  onMouseEnter = () => {
    const { theme } = this.props;
    const paddingTop = parseInt(get(theme, 'Menu.item.hint.paddingTop', '10px'), 10);
    const paddingBottom = parseInt(get(theme, 'Menu.item.hint.paddingBottom', '10px'), 10);

    const parentRect = this.itemRef.current.parentNode.getBoundingClientRect();
    const { top, left, width, height } = parentRect;

    document.body.appendChild(this.hinterRef.current);
    this.hinterRef.current.style.display = 'block';

    let textRect = this.hinterRef.current.firstChild.getBoundingClientRect();
    const lineHeight = textRect.height - paddingTop - paddingBottom;

    if (left + textRect.width > document.body.clientWidth) {
      if (left - (textRect.width - width) < 0) {
        this.hinterWrapperRef.current.style.whiteSpace = 'normal';
        this.shadowWrapperRef.current.style.whiteSpace = 'normal';
        this.hinterRef.current.style.left = '20px';
        this.hinterRef.current.style.right = `${document.body.clientWidth - (left + width)}px`;

        textRect = this.hinterRef.current.firstChild.getBoundingClientRect();
        this.hinterRef.current.style.top = `${top - height - (textRect.height - paddingTop - paddingBottom - lineHeight) - 5}px`;
      } else {
        this.hinterRef.current.style.top = `${top - height - 5}px`;
        this.hinterRef.current.style.left = `${left - (textRect.width - width)}px`;
      }
    } else {
      this.hinterRef.current.style.left = `${left}px`;
      this.hinterRef.current.style.top = `${top - height - 5}px`;
    }
    this.hinterRef.current.style.visibility = 'inherit';
  };

  onMouseLeave = () => {
    this.hinterRef.current.style.display = 'none';
    this.hinterRef.current.style.visibility = 'hidden';
    this.hinterRef.current.style.left = '';
    this.hinterRef.current.style.right = '';
    this.hinterWrapperRef.current.style.width = '';
    this.hinterWrapperRef.current.style.whiteSpace = '';
    this.shadowWrapperRef.current.style.width = '';
    this.shadowWrapperRef.current.style.whiteSpace = '';
    this.itemRef.current.appendChild(this.hinterRef.current);
  };

  render() {
    const { value } = this.props;

    return (
      <div style={style} ref={this.itemRef} onClick={this.onMouseLeave}>
        {value.title}
        <div style={hinterStyle} ref={this.hinterRef}>
          <HinterWrapper innerRef={this.hinterWrapperRef}>
            {value.title}
          </HinterWrapper>
          <ShadowWrapper innerRef={this.shadowWrapperRef}>
            {value.title}
          </ShadowWrapper>
        </div>
      </div>
    );
  }
}

HintedOption.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(HintedOption);
