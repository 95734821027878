import { Translate } from '../../../../common_components/Translate/Translate';


export const cisKey = 'cis';
export const goodsKey = 'products';

export const steps = {
  info: 0,
  goods: 1,
};

export const commodityGroup = [
  {
    label: Translate("Обувь"), value: 'shoes'
  },
]

export const docKinds = [
  {
    id: 1, title: "Декларация", value: 'declaration'
  },
  {
    id: 2, title: "Сертификат", value: 'certificate'
  },
]

export const actionSelectOptions = [
  {
    value: 'delete',
    label: Translate('Удалить'),
  },
  {
    value: 'edit',
    label: Translate('Редактировать'),
  },
];
