import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100% - 1px);
  box-shadow: -1px 0 0 #f2f2f2;
  align-content: stretch;
  background-color: #fff;
`;

export const Title = styled.div`
  line-height: 52px;
  font-weight: bold;
  cursor: default;
  text-align: center;
`;

export const IconClosePositionWrapper = styled.div`
  padding-right: 10px;
  > svg {
    color: #868686;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const IconSearchPositionWrapper = styled.div`
  padding-left: 24px;
  > svg {
    color: #b8b8b8;
    width: 24px;
    height: 24px;
  }
`;
