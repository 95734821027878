import React, {useMemo} from 'react';
import moment from 'moment';
import { withTheme } from 'styled-components';
import { get, intersection } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

import { Content, Layout } from '@crpt/react-page-layout';
import {DefaultRoute} from 'src/components/404'

import { PageHeader } from '../PageHeader/PageHeader';
import PageMenu from '../PageMenu/PageMenu';
import FilePreview from '../FilePreview/FilePreview';

import ModalForm from '../Form/ModalForm/ModalForm';
import PrivateRoute from '../Routing/PrivateRoute';
import Route from '../Routing/Route';
import { config } from '../App/App.selectors';

import { getAuthorities, getOrgRoles } from '../../utils/userUtils';
import {WorkNotification} from '../WorkNotification';

import { useStyles } from './DefaultWrap.styles';

const DefaultWrap = props => {
  const {
    config: {
      extend: { routes },
    },
  } = props;
  const { pathname } = window.location;
  const currentRoute = props.routes.find(r => pathname.startsWith(r.route));
  const theme =
    (currentRoute && currentRoute.custom && currentRoute.custom.theme) ||
    props.theme;

  const contentStyle = {
    padding: 16,
    height: '100%',
  };

  const layoutStyle = {
    tableLayout: 'fixed',
  };

  const authorities = getAuthorities();
  const orgRoles = getOrgRoles();

  let final = [];

  routes
    .filter(r => {
      return (
        intersection(r.orgRoles, orgRoles).length ||
        intersection(r.authorities, authorities).length
      );
    })
    .forEach(r => (final = final.concat(r.include)));

  const filtered = props.routes
    .filter(r => {
      let overwrited = false;
      final.forEach(inner => {
        if (inner.route === r.route) overwrited = true;
      });
      return !overwrited;
    })
    .concat(final);

    const classes = useStyles();

  const notificationTitle = "Уважаемые пользователи системы маркировки Честный ЗНАК!"

  const notificationText = useMemo(() => {
    return (
      <React.Fragment>
        В&nbsp;связи с&nbsp;проведением технологических работ, 15.07.2020&nbsp;с 18:30 до&nbsp;19:00 возможны ограничения в&nbsp;работе сервиса (прерывание работы до&nbsp;10&nbsp;минут), связанные с&nbsp;получением и&nbsp;заказом кодов маркировки, а&nbsp;также отправкой отчетов.
        <br/>
        Приносим извинения за&nbsp;временные неудобства.
      </React.Fragment>
      )
  }, []);

  const overDateNotification = moment('07-15-2020 19:01 PM', 'MM-DD-YYYY hh:mm A')

  return (
    <React.Fragment>
      <div className={classes.commonWrapper}>
        <PageMenu />
        <div className={classes.contentWrapper}>
          <Layout style={layoutStyle}>
            <PageHeader location={props.location} />
            <Content style={contentStyle} colored="tobacco" theme={theme}>
              <WorkNotification storageKey="15072020attention" overDate={overDateNotification} title={notificationTitle} text={notificationText} />
              <Sidebar />
              <FilePreview />
              <ModalForm />

              <Switch>
                {filtered.map((v, i) => {
                  return v.private ? (
                    <PrivateRoute
                      key={i}
                      exact={v.exact}
                      path={v.route}
                      component={v.component}
                    />
                  ) : (
                    <Route
                      key={i}
                      exact={v.exact}
                      path={v.route}
                      component={v.component}
                    />
                  );
                })}
                <Route
                  render={props => {
                    return <DefaultRoute />
                  }}
                />
              </Switch>
            </Content>
          </Layout>
        </div>
      </div>
    </React.Fragment>
  );
};

DefaultWrap.displayName = 'DefaultWrap';

const mapState = state => ({
  config: config(state),
});

export default compose(connect(mapState), withRouter, withTheme)(DefaultWrap);
