import { RequiredFieldsWarning, Typography } from '@crpt-ui/core';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import Preloader from 'src/common_components/Preloader/Preloader';
import { OnChange } from 'src/fragments/Form/Fields';

import mutators from '../../../../common_components/_refactor/Form/mutators';
import { Divider } from '../GrayAreaCreate.styles';
import { ClosingPeriod } from './ClosingPeriod';
import { NoticeList } from './NoticeList';
import { Translate } from 'src/common_components/Translate/Translate';

const InformationView = ({
  initFormValues,
  informationHelper,
  isTogglePopupAutocomplete,
  loaded,
  maxDate,
  minDate,
  onInformationChange,
  setTogglePopupAutocomplete,
  users,
}) => (
  <Preloader isOpened={!loaded} isClosed={loaded}>
    <Form
      initialValues={initFormValues}
      mutators={mutators}
      onSubmit={() => {}}
      validate={informationHelper.formValidator}
      subscription={{
        hasValidationErrors: true,
        values: true,
      }}
    >
      {({ hasValidationErrors, values }) => {
        return (
          <Box px={5}>
            <Box pt={5} pb={4}>
              <Typography variant="h3">{Translate('Формирование выгрузки')}</Typography>
            </Box>
            <Divider absolute />

            <Box py={1}>
              <RequiredFieldsWarning error={hasValidationErrors} />
            </Box>
            <Divider absolute />

            <ClosingPeriod
              maxDate={maxDate}
              minDate={minDate}
              fromTime={values.information.fromTime}
              toTime={values.information.toTime}
            />
            <Divider absolute />

            <NoticeList
              isTogglePopupAutocomplete={isTogglePopupAutocomplete}
              setTogglePopupAutocomplete={setTogglePopupAutocomplete}
              users={users}
            />

            <OnChange
              name="information"
              onChange={onInformationChange(hasValidationErrors)}
            />
          </Box>
        )
      }}
    </Form>
  </Preloader>
);

InformationView.propTypes = {
  initFormValues: PropTypes.object.isRequired,
  informationHelper: PropTypes.object.isRequired,
  isTogglePopupAutocomplete: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  maxDate: PropTypes.object.isRequired,
  onInformationChange: PropTypes.func.isRequired,
  setTogglePopupAutocomplete: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default InformationView;
