import {cisStatuses, packTypes} from '../../../../constants';

const statuses = {};
Object.keys(cisStatuses).map(k => statuses[k] = k);

const packs = {};
Object.keys(packTypes).map(k => packs[k] = k);

export function getIconNameByCISStatus(status) {
  switch (status) {
    case statuses.WITHDRAWN:
      return 'sold-out';
    case statuses.WITHDRAW:
      return 'sold-out';
    case statuses.WRITTEN_OFF:
      return 'pan';
    case statuses.INTRODUCED:
      return 'turnover';
    case statuses.APPLIED:
      return 'print-flame';
    case statuses.EMITTED:
      return 'emitted';
    default:
      return '';
  }
}

export function getIconNameByPackType(pack) {
  switch (pack) {
    case packs.pack:
      return 'pack';
    case packs.block:
      return 'block';
    case packs.box:
    case packs.pallet:
      return 'box';
    default:
      return packs.pack;
  }
}