import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isFunction, isUndefined } from 'lodash';
import { Wrap } from './ButtonTabs.styled';
import Tab from './Tab';
import { selectedTabTheme, tabTheme } from './ButtonTabs.constants';

class ButtonTabs extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    currentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    onSelect: PropTypes.func,
    checkDisabled: PropTypes.func,
    theme: PropTypes.object,
  };

  static defaultProps = {
    currentIndex: undefined,
    checkDisabled: undefined,
  };

  /* TODO: при срабатываение условия (tab.href) добавляется к body (в инспекторе элементов можно посмотреть)
   * непонятный див с контентом: "1" с кучей стилей, который ломает верстку в FF, в остальных браузерах не проверял
   * Переделать на link с react-router-dom табы
   **/
  onSelect = tab => {
    const { onSelect, history } = this.props;
    if (onSelect) onSelect(tab);
    else if (tab.href) history.push(tab.href);
  };

  getCurrentIndex = () => {
    const { currentIndex, tabs } = this.props;

    if (isUndefined(currentIndex)) {
      return tabs.findIndex(
        tab => tab.href.indexOf(window.location.pathname) !== -1,
      );
    }

    if (isFunction(currentIndex)) {
      return currentIndex(tabs);
    }

    return currentIndex;
  };

  checkDisabled = tab => {
    const { checkDisabled } = this.props;
    if (!checkDisabled) return false;

    return checkDisabled(tab);
  };

  render() {
    const { tabs, theme, ...props } = this.props;
    const currentIndex = this.getCurrentIndex();

    return (
      <Wrap>
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            data={tab}
            disabled={this.checkDisabled(tab)}
            theme={
              theme ? theme : i === currentIndex ? selectedTabTheme : tabTheme
            }
            selectedTab={i === currentIndex ? true : false}
            {...props}
            onSelect={this.onSelect}
          />
        ))}
      </Wrap>
    );
  }
}

export default withRouter(ButtonTabs);
