import styled from 'styled-components';
import { checkboxState } from './SelectionCheckbox';


export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${props => props.width || '100%'};
  ${props => (props.state === checkboxState.EDITED ? 'margin-top: 10px;' : 'height: 100%;')};
  cursor: pointer;
`;
