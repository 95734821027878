import React from 'react';
import { Translate } from '../../../common_components/Translate/Translate';

export const tabs = [
  {
    id: 0,
    label: Translate('Общая информация'),
  },
  {
    id: 1,
    label: Translate('Товары'),
  },
  {
    id: 2,
    label: Translate('Итоги'),
  },
];

export const totalColumns = [
  {
    id: 'gtin',
    accessor: 'gtin',
    Header: Translate('Код товара (GTIN)'),
    width: 'auto',
  },
  {
    id: 'name',
    accessor: 'name',
    width: 'auto',
    Header: Translate('Наименование товара'),
  },
  {
    id: 'gtinsCounts',
    accessor: 'gtinsCounts',
    Header: Translate('Итого потребительских упаковок'),
    width: 'auto',
  },
];
