import React from 'react';
import {useStyles} from './NoInfo.styles';
import { Typography } from '@crpt-ui/core';

export const NoInfo = ({title, typographyVariant = 'h4', renderIcon = null}) => {
  const styles = useStyles();
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.iconWrapper}>
        {renderIcon && (typeof  renderIcon === 'object' ? renderIcon : renderIcon())}
      </div>
      <Typography variant={typographyVariant}>
        {title}
      </Typography>
    </div>
  )
};