import { Button, Steps } from '@crpt-ui/core';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { Box, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ContentWrapper from 'src/common_components/Content/Content';

import { breadcrumbs, steps } from './GrayAreaCreate.constants';
import { Divider, MainContent, styles } from './GrayAreaCreate.styles';
import { setDisabledSteps } from './GrayAreaCreate.utils';
import Gtins from './Gtins/Gtins';
import Information from './Information/Information';
import ProductionLines from './ProductionLines/ProductionLines';
import { StepWrapper } from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { Translate } from '../../../common_components/Translate/Translate';
import { Trans } from 'react-i18next';

const GrayAreaCreateView = ({
  classes,
  dispatch,
  history,
  onCancel,
  onCreateDocument,
  onChangeStep,
  state,
  step,
  loadingCreateDocument,
  isNextStepDisabled,
}) => {
  return (
    <div className={classes.wrapper}>
      <Breadcrumbs
        onClick={val => history.push(val.href)}
        breadcrumbs={breadcrumbs}
        style={{ paddingBottom: 30 }}
      />

      <ContentWrapper style={{ display: 'flex', flexDirection: 'column' }}>
        <StepWrapper>
          <Steps
            value={step}
            items={steps.map(setDisabledSteps(state))}
            onChange={onChangeStep}
            variant="fullWidth"
          />
        </StepWrapper>
        <MainContent isVisible={step === 0}>
          <Information dispatch={dispatch}/>
        </MainContent>

        <MainContent isVisible={step === 1}>
          <ProductionLines
            dispatch={dispatch}
            selectedProductionLines={state.productionLines}
          />
        </MainContent>

        <MainContent isVisible={step === 2}>
          <Gtins
            dispatch={dispatch}
            data={state.gtins}
          />
        </MainContent>

        <Divider />

        <Box p={5}>
          <Grid container spacing={3} justify={'space-between'}>
            <Grid item>
              <Button onClick={onCancel} variant="outlined" size="lg">
                {Translate('Отменить')}
              </Button>
            </Grid>

            <Grid item>
              {step < 2 && (
                <Button
                  onClick={ev => onChangeStep(ev, step + 1)}
                  size="lg"
                  disabled={isNextStepDisabled}
                >
                  {Translate('Следующий&nbsp;шаг')}
                </Button>
              )}
              {step === 2 && (
                <Button
                  onClick={onCreateDocument}
                  size="lg"
                  loading={loadingCreateDocument}
                >
                  {Translate('Сформировать')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </ContentWrapper>
    </div>
  );
};

GrayAreaCreateView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  setDisabledSteps: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
)(GrayAreaCreateView);
