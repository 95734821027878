import { createSelector } from 'reselect';
import { get, intersection } from 'lodash';
import { userRoles } from '../../constants';
import { selector } from '../../utils/selector';

export const getRoles = state => selector(state, 'Auth.userInfo.authorities');
export const userInfo = state => selector(state, 'Auth.userInfo');
export const userId = state => selector(state, 'Auth.userInfo.id');
export const userInn = state => selector(state, 'Auth.userInfo.inn');
export const userShortName = state =>
  selector(state, 'Auth.userInfo.company.shortName');
export const authState = state => selector(state, 'Auth.state');
export const authority = state => selector(state, 'Auth.authority');
export const startRoute = state => selector(state, 'Auth.startRoute');
export const cert = state => selector(state, 'Auth.cert');
export const company = state => selector(state, 'Auth.company');
export const productGroups = state => selector(state, 'Auth.productGroups');

export const roles = createSelector(userInfo, info =>
  get(info, 'authorities', []),
);

export const companyRoles = createSelector(company, info =>
  get(info, 'roles', []),
);

export const isOperator = createSelector(
  roles,
  r =>
    !!intersection(r, [userRoles.ROLE_ORG_IS_MP_OPERATOR])
      .length,
);

export const isAdmin = createSelector(
  roles,
  r => !!intersection(r, [userRoles.ROLE_ORG_TRADE_PARTICIPANT]).length,
);

export const isUserOGV = createSelector(
  roles,
  r => !!intersection(r, [userRoles.ROLE_ORG_OGV]).length,
);
