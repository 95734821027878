import styled from 'styled-components';

export const modalStyle = {
  content: {
    zIndex: 1,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px',
    width: 'calc(100% - 304px)',
    maxWidth: '620px',
    maxHeight: 'calc(100% - 50px)',
  },
  overlay: {
    zIndex: 2,
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  overflow: 'visible',
};
export const ButtonsBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  > button {
    margin-left: 16px;
  };
`;
export const ContentWrapper = styled.div`
  padding: 32px;
  font-size: 1rem;
`;
export const ItemWrapper = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
export const HeaderWrapper = styled.div`  
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ModalClose = styled.div`
  cursor: pointer;
`;
export const TextHeaderWrapper = styled.div`
  text-align: left;
  font-size: 24px;
  color: #52535a;
`;

export const Hr = styled.hr`
  border-color: rgba(189, 189, 189, 0.2);
`;
export const BodyTooltip = styled.div`
  max-width: 800px;
  font-size: 1rem;    
  padding: 10px;
`;