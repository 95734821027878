import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 34px;
  box-sizing: border-box;
  position: relative;
`;

export const ProductionLineWrapper = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
`;
