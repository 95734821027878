import { createAction } from 'redux-actions';

export const subject = '[ParticipantList]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const evadersLoaded = createAction(`${subject} evadersLoaded`);
export const getRequest = createAction(`${subject} get request`);
export const getRequestEvaders = createAction(`${subject} get getRequestEvaders`);
export const updatePage = createAction(`${subject} updatePage`);
export const updatePageEvaders = createAction(`${subject} updatePageEvaders`);
export const unmounted = createAction(`${subject} unmounted`);
export const unmountedEvaders = createAction(`${subject} unmounted`);
export const download = createAction(`${subject} download`);
export const error = createAction(`${subject} error`);
