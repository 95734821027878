import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from '@crpt-ui/core';
import { useTranslation } from 'react-i18next';

export const CancelDialog = ({ isOpen, handleCLose, handleCancel, isCreation }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isCreation ? t('Отменить создание статьи?') : t('Отменить редактирование статьи?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isCreation ? t('Все введенные данные не сохранятся.') : t('Все измененные данные не сохранятся')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="success">{t('Назад')}</Button>
        <Button variant="contained" color="error" onClick={handleCLose} autoFocus>
          {t('Отмена')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
