import intersection from 'lodash/intersection';
import { CisItem } from './CisItem';
import { ProductsItem } from './ProductsItem';
import { DocumentsItem } from './DocumentsItem';
import { ParticipantsItem } from './ParticipantsItem';
import { ProfileItem } from './ProfileItem';
import { ReceiptItem } from './ReceiptItem';
import {
  CAN_SEE_PARTICIPANTS,
  CAN_SEE_PRODUCTS,
} from '../../../projects/motp/permissions';

export const generateItems = (
  orgRoles,
  dispatch,
  history,
  onSuccess,
  onFailure,
) => {
  const items = [CisItem, ReceiptItem, DocumentsItem];

  if (intersection(orgRoles, CAN_SEE_PRODUCTS).length) {
    items.push(ProductsItem);
  }

  if (intersection(orgRoles, CAN_SEE_PARTICIPANTS).length) {
    items.push(ParticipantsItem);
  }

  items.push(ProfileItem);

  return items.map(C => new C(dispatch, history, onSuccess, onFailure));
};
