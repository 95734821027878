import {actionTypes} from '../../constants';


export function cert(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.CERT_SELECTED:
      return action.value;
    default:
      return state;
  }
}
