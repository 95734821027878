import React from 'react';
import { ImportantBlock } from '../../ReportHelp.styled';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  afterTable: <ImportantBlock><strong>Важно!</strong> В отчёт выводятся первые 1000 строк</ImportantBlock>,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Тип агрегации',
          'col2': <div>Возможность выбора агрегации:
              <ul>
                <li>По товару</li>
                <li>По владельцу товара</li>
              </ul>
            </div>
        },
        {
          'col1': 'GTIN',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование товара',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Производитель',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Месяц производства',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Тип участника',
          'col2': <div>
              Возможность фильтрации по значениям:
              <ul>
                <li>Импортёр</li>
                <li>Опт</li>
                <li>Производитель</li>
                <li>Розница</li>
                <li>Розница, Опт</li>
              </ul>
            </div>
        },
        {
          'col1': 'ИНН',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Юридический адрес',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'ИД розничной т.т.',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Статус продукции',
          'col2': <div>
            Возможность фильтрации по значениям:
            <ul>
              <li>В обороте</li>
              <li>Реализован</li>
              <li>Списан</li>
            </ul>
          </div>
        },
      ]
    }
  ]
}