import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@crpt/react-icon';
import { Button as CrptButton } from '@crpt/react-button';
import { HintedAddressInput, Input, Button, EmissionRegistrarNumberInput } from '../../../Fields';
import { Closed, InputWithPlusOrMinusWrap } from '../styled';
import {is16digit, required} from '../../../utils/validations';
import { buttonSignTheme } from '../../../../../theme/ru-tabaco/button';


const iconStyle = { marginBottom: '16px' };

class ProductionPlace extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    onActivate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onPushValue: PropTypes.func.isRequired,
  };

  onAddEmissionRegistrarNumber = () => {
    const { onPushValue, name } = this.props;
    onPushValue(`${name}.emissionRegistrars`, '');
  };

  onRemoveEmissionRegistrarNumber = index => {
    const { onRemoveValue, name } = this.props;
    onRemoveValue(`${name}.emissionRegistrars`, index);
  };

  onAddCodeLine = () => {
    const { onPushValue, name } = this.props;
    onPushValue(`${name}.productionCodeLines`, {code: ''});
  };

  onRemoveCodeLine = index => {
    const { onRemoveValue, name } = this.props;
    onRemoveValue(`${name}.productionCodeLines`, index);
  };

  onActivate = () => {
    const { onActivate, index } = this.props;
    onActivate(index);
  };

  onRemove = () => {
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  render() {
    const { name, data, active } = this.props;
    const { address, code, emissionRegistrars, productionCodeLines } = data;

    if (active) {
      return (
        <React.Fragment>
          <HintedAddressInput
            name={`${name}.address`}
            placeholder="Адрес производственного помещения"
            defaultValue={address}
            validate={required}
          />
          <Input
            name={`${name}.code`}
            placeholder="Код производственного помещения"
            defaultValue={code}
            validate={required}
          />
          {emissionRegistrars.map((number, index) => (
            <InputWithPlusOrMinusWrap key={index}>
              <EmissionRegistrarNumberInput
                key={`${name}.emissionRegistrars[${index}]`}
                name={`${name}.emissionRegistrars[${index}]`}
                defaultValue={number}
                index={index + 1}
              />
              <CrptButton
                theme={{ Button: buttonSignTheme }}
                onClick={
                  index === 0
                    ? this.onAddEmissionRegistrarNumber
                    : () => this.onRemoveEmissionRegistrarNumber(index)
                }
              >
                <Icon
                  type={index === 0 ? 'add-plus' : 'minus-sign'}
                  fill={'#434244'}
                  width={10}
                />
              </CrptButton>
            </InputWithPlusOrMinusWrap>
          ))}
          {productionCodeLines.map((line, index) => (
            <InputWithPlusOrMinusWrap key={index}>
              <Input
                key={`${name}.productionCodeLines[${index}].code`}
                name={`${name}.productionCodeLines[${index}].code`}
                placeholder="Код производственной линии"
                defaultValue={line.code}
                index={index + 1}
                total={productionCodeLines.length}
                validate={required}
                inSign
              />
              <CrptButton
                theme={{ Button: buttonSignTheme }}
                onClick={
                  index === 0
                    ? this.onAddCodeLine
                    : () => this.onRemoveCodeLine(index)
                }
              >
                <Icon
                  type={index === 0 ? 'add-plus' : 'minus-sign'}
                  fill={'#434244'}
                  width={10}
                />
              </CrptButton>
            </InputWithPlusOrMinusWrap>
          ))}
        </React.Fragment>
      );
    }

    return (
      <Closed>
        <Button onClick={this.onActivate}>{address}</Button>
        <Icon
          type="cross"
          size={16}
          style={iconStyle}
          onClick={this.onRemove}
        />
      </Closed>
    );
  }
}

export default ProductionPlace;
