export const defaultTheme = {
  common: {
    width: {
      full: '241px',
      fold: '78px',
    },
    backgroundColor: '#FFFFFF',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    borderWidth: '1px',
    shadowX: '0px',
    shadowY: '2px',
    shadowColor: 'rgba(0, 0, 0, 0.25)',
  },
  header: {
    height: '55px',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  items: {
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'flex-start',
  },
  item: {
    width: '100%',
    height: '52px',
    borderRadius: '4px',
    marginTop: '1px',
    marginBottom: '1px',
    hoverBorderWidth: '1px',
    hoverBorderColor: 'rgba(0, 0, 0, 0.05)',
    shadowX: '0px',
    shadowY: '2px',
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    justifyContentOnFull: 'flex-start',
    justifyContentOnFold: 'flex-start',
    textColor: 'black',
    activeStyle: {
      color: 'black',
      borderColor: '#0287B0',
      borderRadius: '4px',
      borderWidth: '1px',
    },
    hint: {
      arrow: {
        height: '31px',
        width: '31px',
        top: '-17px',
        left: '50px',
        borderWidth: '1px',
        borderType: 'solid',
        borderColor: '#F0F0F0',
      },
      top: '-24px',
      left: '65px',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '22px',
      paddingRight: '22px',
      borderRadius: '4px',
      backgroundColor: '#FFFFFF',
      boxShadow:
        '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1), -2px 0px 0px white',
    },
  },
  fullModeButton: {
    height: '80px',
  },
  separator: {
    width: '80%',
    height: '0px',
    color: 'rgba(0, 0, 0, 0.05)',
  },
  footer: {
    height: '69px',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
};
