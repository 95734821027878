import React, { useMemo } from 'react';
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import { columns } from './constants';
import List from '../../../common_components/List/List';

export const CisRegistrationGoods = ({ selectedDocument }) => {
  const list = useMemo(() =>
    selectedDocument.cisList.map((item, idx) => ({ cis: item, idx: idx + 1 })) || [], [selectedDocument]);

  return (
    <CrossborderWrap withoutPadding>
      <List
        defaultPageSize={10}
        data={list}
        columns={columns}
        showPagination={list.length > 10}
      />
    </CrossborderWrap>
  );
};
