import styled from 'styled-components';
import { styled as materialStyled } from '@material-ui/styles';

export const TabsWrap = styled.div`
  margin-top: 20px;
`;
export const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 35px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WrapImportTransit = styled.div`
  padding: 0 30px 30px;
  min-height: ${({ isIT }) =>
    isIT ? 'calc(100vh - 330px)' : 'calc(100vh - 190px)'};
`;
export const HeadWrap = styled.div`
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
`;
export const LabelWrap = styled.div`
  margin-left: 40px;
  width: auto;
`;
export const Head = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #63666a;
`;
export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  margin-right: 16px;
  min-width: 60vw;
`;
export const RowNoBorder = styled.div`
  display: flex;
  padding: 10px 0;
  margin-right: 16px;
  min-width: 60vw;
`;
export const RowLabel = styled.div`
  flex: 1;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
`;
export const RowText = styled.div`
  flex: 1;
`;
export const NdsBar = styled.div`
  // margin: 35px 20% 20px 30%;
  margin: 10px 20px;
  overflow: hidden;
`;
export const TableWrap = styled.div`
  height: calc(100% - 118px);
`;

export const Value = materialStyled('div')({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const LittleHead = styled.div`
  font-size: 18px;
  // font-weight: 600;
  // margin: 20px 0;
`;
export const Separator = styled.div`
  border-bottom: 1px solid #ccc;
`;

export const DocWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  span {
    font-weight: 600;
    margin-right: 5px;
  }
`;

export const PaddingWrap = styled.div`
  padding: 20px 20px;
`;

export const TooltipContent = styled.div`
  padding: 10px;
  font-size: 14px;
  max-width: 90vw;
`;

export const ActBasicInfoWrap = styled.div`
  padding: 34px;
`;
