import { get, transform } from 'lodash';
import moment from 'moment';
import { APP_LINKS } from 'src/constants';
import { currencies } from '../ImportDocument/ImportDocument.constants.js';
import { documentTypes } from 'src/constants';
import fp from 'lodash/fp';

export const prepareToRange = params =>
  transform(params, (result, value, key) => {
    const matchFrom = key.match(/(.*)Start$/);
    if (matchFrom && value) {
      const newKey = `${matchFrom[1]}_from`;

      // eslint-disable-next-line no-param-reassign
      result[newKey] = moment(value)
        // .add(-3, 'hour')
        .toISOString(false);
      return;
    }

    const matchTo = key.match(/(.*)End$/);
    if (matchTo && value) {
      const newKey = `${matchTo[1]}_to`;

      const newValue = moment(value);
      // .add(-1, 'day')
      // .add(-3, 'hour')
      // .add(1, 'millisecond');
      // eslint-disable-next-line no-param-reassign
      result[newKey] = newValue.toISOString(false);
      return;
    }

    // eslint-disable-next-line no-param-reassign
    result[key] = value;
  });

export const prepareFilters = params =>
  transform(params, (result, value, key) => {
    const matchFrom = key.match(/(.*)From$/);
    if (matchFrom && value) {
      const newKey = `${matchFrom[1]}start`;
      // eslint-disable-next-line no-param-reassign
      // result[newKey] = moment(value)
      //   .toISOString(true)
      //   .replace(/\+.*/, 'Z');
      result[newKey] = moment.utc(value).format();

      return;
    }

    const matchTo = key.match(/(.*)To$/);
    if (matchTo && value) {
      const newKey = `${matchTo[1]}end`;
      // const newValue = moment(value)
      // .add(1, 'day')
      // .add(-1, 'millisecond');
      // eslint-disable-next-line no-param-reassign
      // result[newKey] = moment(value).toISOString(true).replace(/\+.*/, 'Z');
      result[newKey] = moment.utc(value).format();
      return;
    }

    // eslint-disable-next-line no-param-reassign
    result[key] = value;

    if (key.match(/(.*)_from$/)) delete result[key];
    if (key.match(/(.*)_to$/)) delete result[key];
  });

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function generateUUIDBig(length = 36) {
  // Public Domain/MIT
  var d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  const mask = getUUIDMask(length);

  return mask.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

const getUUIDMask = length => {
  switch (length) {
    case 36:
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    case 40:
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxxxxx';
    case 43:
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxxxxxxxx';
    case 44:
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxxxxxxxxx';
    default:
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  }
};

export const confirmCurrency = currency => {
  return currencies.some(curItem => curItem.type === currency);
};

export const type2path = document_type => {
  switch (document_type) {
    case 'LP_INTRODUCE_GOODS':
      return 'comissioning';
    case 'LP_ACCEPT_GOODS':
      return APP_LINKS.AcceptanceNotificationOfGoodsByTheEAES;
    case 'IMPORT':
      return APP_LINKS.ImportThirdCountry;
    case 'IMPORT_TRANSIT':
      return APP_LINKS.Eaes_import;
    case 'LP_SHIP_GOODS':
      return 'shipment';
    case 'LK_RECEIPT':
      return 'withdrawing';
    case 'LK_REMARK':
      return 'remarking';
    case 'LK_CONTRACT_COMMISSIONING':
      return 'contract';
    case 'LK_INDI_COMMISSIONING':
      return 'indi';
    case 'LK_KM_CANCELLATION':
    case 'LK_APPLIED_KM_CANCELLATION':
      return 'ciscancel';
    case 'UNIVERSAL_TRANSFER_DOCUMENT':
      return 'utd';
    case 'MTTPS_90':
      return APP_LINKS.NotificationOfCancellation;
    case 'KZ_INTRODUCE_GOODS':
      return APP_LINKS.NotificationOfEntry;
    case 'KZ_UPD':
      return APP_LINKS.Eaes_act;
    case 'KZ_EXPORT_NOTICE':
      return APP_LINKS.ExportNotificationToTheEaes;
    case 'UZ_ADMIT_APPLICATION':
      return 'cis_registry';
    case 'AIC_AGGREGATION':
      return APP_LINKS.aik;
    case 'AIC_DISAGGREGATION':
      return APP_LINKS.aik_disaggregation;
    case 'AGGREGATION_DOCUMENT':
      return APP_LINKS.aggregation;
    case 'INVENTORY':
      return APP_LINKS.inventory;
    case 'TRADE_INVENTORY':
      return APP_LINKS.tradeInventory;
    case 'COR_INFO_CM':
      return APP_LINKS.corInfoCm;
    default:
      return null;
  }
};

export const getSenderOrBuyerName = (
  document_body,
  document_type,
  fieldName,
) => {
  let body, name, inn;
  switch (document_type) {
    case 'KZ_UPD':
      body = JSON.parse(document_body);
      name = get(body, `${fieldName}_name`, '-');
      inn = get(body, `${fieldName}_inn`, '-');
      return name + ' / ' + inn;
    case documentTypes.MTTPS_90:
    case documentTypes.KZ_INTRODUCE_GOODS:
      if (fieldName === 'sender') {
        body = JSON.parse(document_body);
        name = get(body, `applicant`, '-');
        inn = get(body, `inn`, '-');
        return name + ' / ' + inn;
      } else return '-';
    case documentTypes.IMPORT_TRANSIT:
      if (fieldName === 'buyer') {
        body = JSON.parse(document_body);
        name = get(body, `applicant_name`, '-');
        inn = get(body, `applicant_inn`, '-');
        return name + ' / ' + inn;
      } else return '-';
    case documentTypes.IMPORT:
      if (fieldName === 'buyer') {
        body = JSON.parse(document_body);
        name = get(body, `recipient_name`, '-');
        inn = get(body, `recipient_inn`, '-');
        return name + ' / ' + inn;
      } else return '-';
    default:
      return '-';
  }
};

export const prepareUZImportDraft = data => {
  return {
    ...data,
    recipient_inn: data.recipient_inn || data.organisation_code,
    country_export: data.country_export || data.departure_country,
    excise_tax_sum: data.excise_tax_sum || data.currency_rate,
    cis: data.cis || prepareCis(data.products),
    products: data.products.map(pr => ({
      ...pr,
      country: pr.country || pr.production_country,
      doc_allow_date: pr.doc_allow_date || pr.allow_doc_date,
      permit_document: pr.permit_document || pr.allow_doc_number,
      numberDT: pr.numberDT || pr.doc_product_number,
    })),
  };
  // return data
};

const prepareCis = data =>
  fp.pipe(
    fp.map(item => item.codes),
    fp.flatMap(),
  )(data);
