import React, { PureComponent } from 'react';
import { withTheme } from 'styled-components';

import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import 'datejs';

import ProductItem from './ProductItem';
import { ContentWrapper } from '../../common/styled';
import { TilesWrapper, Header, FlexWrapper } from './styled';
import { Translate } from '../../../common_components/Translate/Translate';

class ProductList extends PureComponent {
  //TODO PropTypes is absent
  static propTypes = {};

  render() {
    const documentType = get(this.props, 'selectedDocument.type', null);
    const products = get(this.props, 'selectedDocument.products', []);
    const { countryCode, isAggregationDocument } = this.props;

    const productList = products.map((item, k) => (
      <ProductItem
        key={k}
        product={item}
        documentType={documentType}
        countryCode={countryCode}
        isAggregationDocument={isAggregationDocument}
      />
    ));

    return (
      <ContentWrapper>
        <FlexWrapper>
          <Header>{Translate('Товар')}</Header>
          <TilesWrapper>{productList}</TilesWrapper>
        </FlexWrapper>
      </ContentWrapper>
    );
  }
}

export default withTheme(withRouter(ProductList));
