import React from 'react';
import { columnsTable, GoodsWrapper } from './ExportEAES.constants';
import Datatable from '@crpt-ui/datatable';
import { TableWrap } from '../Act/Showing.styled';


const Goods = ({ list = [] }) => {
  return (
    <GoodsWrapper>
      <TableWrap>
        <Datatable columns={columnsTable} useSort={false} data={list} />
      </TableWrap>
    </GoodsWrapper>
  )
}

export default Goods;
