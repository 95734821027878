import React from 'react';
import { isEmpty } from 'lodash';
import Button from '../../Form/Fields/Button/Button';
import { ModalButtonWrapper } from './Buttons.style';

const disabled = (name, values, errors) => !isEmpty(errors);

const WrappedButton = (props) => (
  <ModalButtonWrapper>
    <Button type="submit" name="__form_submit" marginBottom="0" disabled={disabled} {...props} />
  </ModalButtonWrapper>
);

export default WrappedButton;
