import {actionTypes} from '../../constants/';

export function submitButtonDisabled(state = true, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM.FILLED_WITH_VALUES:
      return false;

    case actionTypes.LOGIN_FORM.LOGIN_CLEARED:
    case actionTypes.LOGIN_FORM.PASSWORD_CLEARED:
      return true;

    case actionTypes.LOGIN_FORM.SUBMIT_CLICKED:
      return true;

    case actionTypes.LOGIN_FORM.GOT_ERROR_LOGIN:
    case actionTypes.LOGIN_FORM.GOT_SUCCESS_LOGIN:
      return false;

    default:
      return state;
  }
}
