import { createActions } from 'redux-actions';

export const {
  requestGtins,
  clear,
  loaded,
} = createActions(
  {
    REQUEST_GTINS: (payload = {}) => payload,
    CLEAR: () => ({}),
    LOADED: payload => payload
  },
  {
    prefix: 'GRAY_AREA_GTINS',
  },
);
