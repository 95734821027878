import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import CISList from '../../components/AltScenarios/CISList';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';
import * as selectors from '../../selectors/index';

class AltScenarioCisListBound extends Component {
  render() {
    return (
      <ConnectParts
        component={CISList}
        actions={[actions.AltScenarioCISList]}
        selectors={[selectors.CISList, selectors.AltScenarios]}
        {...this.props}
      />
    );
  }
}

export default withRouter(AltScenarioCisListBound);
