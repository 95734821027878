import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { Input } from '@crpt-ui/core';
import PrimaryActionButton from '../../../../../../common_components/Buttons/MuiPrimaryActionMediumButton';
import CommonButton from '../../../../../../common_components/Buttons/MuiCommonActionMediumButton';
import { STEPS } from '../Goods.constants';
import * as actions from '../ducks/Goods.actions';

import * as selectors from '../ducks/Goods.selectors';
import * as authSelectors from '../../../../../../common_components/Auth/Auth.selectors';

import { FileInput } from '../../../../../../common_components/FilePreview/FilePreview.styled';
import {
  Title,
  FindWrap,
  InputWrap,
  FindButtonWrap,
  CisListWrap,
  ButtonsWrap,
  BottomButtonWrap,
  ErrorZone,
} from './CisListUploadModalContent.styled';

import Preloader from '../../../../../../common_components/Preloader/Preloader';
import { Translate } from 'src/common_components/Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  step: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  title: PropTypes.string.isRequired,
  inn: PropTypes.string,
  userId: PropTypes.any,
};

const defaultProps = {};

class CisListUploadModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();

    this.state = {
      filter: '',
    };
  }

  onFileInputChange = e => {
    const file = get(e, 'target.files.0');
    console.log('onChange', file);
    const { onCloseModal, formMutators, inn, userId } = this.props;
    this.props.convert({ file, onCloseModal, formMutators, inn, userId });
  };

  render() {
    const { onCloseModal, title, row, step, errorText } = this.props;
    const { codes } = row;

    const { filter } = this.state;
    const filtered =
      filter === ''
        ? codes
        : codes.filter(code => code.toLowerCase().indexOf(filter) >= 0);

    return (
      <div>
        <Title>
          <div>{title}</div>
          <Icon type={'cross'} onClick={onCloseModal} />
        </Title>
        <Preloader
          isClosed={step !== STEPS.LOADING}
          isOpened={step === STEPS.LOADING}
          fullHeight={false}
        />
        {errorText ? (
          <ErrorZone visible={step === STEPS.LOADING}>
            <Icon type={'warning'} fill={'#ffffff'} spacingRight={16} />
            {errorText}
          </ErrorZone>
        ) : (
          <React.Fragment>
            <FindWrap>
              {/* <InputWrap> */}
              <Input
                value={filter}
                onChange={e => {
                  this.setState({ filter: e.target.value.toLowerCase() });
                }}
                placeholder={Translate('Поиск кода маркировки')}
              />
              {/* </InputWrap> */}
              {/* <FindButtonWrap>
                <PrimaryActionButton onClick={e => { this.setState({ filter }) }}>Найти</PrimaryActionButton>
              </FindButtonWrap> */}
            </FindWrap>
            <CisListWrap>
              {filtered.map((code, i) => (
                <div key={i}>{code}</div>
              ))}
            </CisListWrap>
          </React.Fragment>
        )}

        <ButtonsWrap>
          <BottomButtonWrap>
            <PrimaryActionButton
              onClick={e => {
                this.fileInput.current.click();
              }}
            >
              {Translate('Загрузить новый список')}
            </PrimaryActionButton>
          </BottomButtonWrap>
          {/* <BottomButtonWrap>
            <CommonButton onClick={e => { this.setState({ filter: '' }) }}>Очистить</CommonButton>
          </BottomButtonWrap> */}
        </ButtonsWrap>
        <FileInput
          innerRef={this.fileInput}
          type={'file'}
          onChange={this.onFileInputChange}
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          }
        />
      </div>
    );
  }
}

CisListUploadModalContent.defaultProps = defaultProps;
CisListUploadModalContent.propTypes = propTypes;

const mapState = state => {
  return {
    step: selectors.step(state),
    errorText: selectors.errorText(state),
    inn: authSelectors.userInn(state),
    userId: authSelectors.userId(state),
  };
};

const mapDispatch = dispatch => ({
  convert: params => dispatch(actions.convertCis(params)),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(CisListUploadModalContent);
