import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from '@crpt-ui/icons';

import {
  ListWrapper,
  Item,
  ItemIconWrapper,
  TitleWrapper,
  IconArrowRightWrapper,
} from './styled/index';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        active: PropTypes.bool,
        onClick: PropTypes.func,
      }),
      PropTypes.element,
    ]),
  ).isRequired,
  header: PropTypes.element,
  headerHeight: PropTypes.string,
};

const defaultProps = {
  header: null,
  headerHeight: null,
};

const List = ({ header, headerHeight, items }) => {
  const itemList = items.map((item, k) => {
    const getItemIcon = () => {
      switch (item.icon) {
        case 'menu-cis-list':
          return <Icons.MarkingCodes />;
        case 'menu-document-list':
          return <Icons.Documents />;
        case 'error':
          return <Icons.Error />;
        case 'menu-participant-list':
          return <Icons.Participants />;
        case 'menu-product-list':
          return <Icons.Products />;
        case 'info':
          return <Icons.Information />;
        case 'receipt':
          return <Icons.Receipt />;
        default:
          return null;
      }
    };

    return React.isValidElement(item) ? (
      item
    ) : (
      <Item key={k} onClick={item.onClick} active={item.active}>
        <ItemIconWrapper>{getItemIcon()}</ItemIconWrapper>

        <TitleWrapper>{item.title}</TitleWrapper>

        <IconArrowRightWrapper>
          <Icons.ArrowRight />
        </IconArrowRightWrapper>

        {item.body}
      </Item>
    );
  });

  return (
    <ListWrapper height={headerHeight}>
      {header}
      {itemList}
    </ListWrapper>
  );
};

List.propTypes = propTypes;

List.defaultProps = defaultProps;

export default List;
