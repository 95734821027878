import { combineReducers } from 'redux';
import { actionTypes } from '../../constants';
import get from 'lodash/get';

const defaultOwner = {};
const defaultChilds = [];

const owner = (state = defaultOwner, action = {}) => {
  switch (action.type) {
    case actionTypes.ANALYTICS.MAP_DATA.LOADED:
      return get(action, 'data.owner', defaultOwner);
    case actionTypes.ANALYTICS.MAP_DATA.LOADING:
      return defaultOwner;
    default:
      return state;
  }
};

const childs = (state = defaultChilds, action = {}) => {
  switch (action.type) {
    case actionTypes.ANALYTICS.MAP_DATA.LOADED:
      return get(action, 'data.childs', defaultChilds);
    case actionTypes.ANALYTICS.MAP_DATA.LOADING:
      return defaultChilds;
    default:
      return state;
  }
};

export default combineReducers({
  owner,
  childs,
});
