import styled from 'styled-components';

export const PageWrapper = styled.div`
  height: 100%;
  display: ${props => props.hide ? 'none' : ''};
`;

export const DocumentDateWrapper = styled.div`
  color: #52535a;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  width: calc(100% - 2px);
  padding: 20px 33px;
  margin-bottom: 20px;
  transform: translateX(-33px);
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: table;
`;

export const DocumentDate = styled.div`
  padding: 0 20px;
  span {
    color: #868686;
  }
`;

export const Title = styled.div`
  color: #52535a;
  font-size: 24px;
  font-family: SegoeUI, sans-serif;
`;

export const Subtitle = styled.div`
  font-weight: bolder;
  color: #52535a;
  font-size: 24px;
  font-family: SegoeUI, sans-serif;
  margin-bottom: 32px;
`;

export const Wrapper = styled.div`
  position: relative;
  margin: ${props => (props.margin ? props.margin : '14px 32px 0')};
  height: calc(100% - 40px);
`;

export const TableWrapper = styled.div`
  height: calc(100% - 118px);
`;

export const CountRecords = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-bottom: 20px;
`;

export const AdditionalControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const FieldsGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: ${props => props.marginLeft};

  & > div {
    flex-basis: 32.5%;

    @media (max-width: 1000px) {
      flex-basis: 49%;
    }
  }
`;

export const styleCommandBar = {
  boxSizing: 'border-box',
  // position: 'absolute',
  // bottom: 0,
  // right: 0,
  paddingRight: '40px',
  paddingLeft: '40px',
  paddingTop: '20px',
  paddingBottom: '20px'
};

export const LabelWrap = styled.div`
  margin-left: 40px;
  width: auto;
  font-size: 14px;
  line-height: 16px;
`;

export const DocIdAndLabelWrap = styled.div`
  display: flex;
  max-width: calc(100vw - 401px);
  width: calc(100vw - 401px);
  justify-content: space-between;
`;

export const DocIdAndLabel = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const HeadingId = styled.div`
  font-size: 24px;
  color: #434343;
`;

export const HeadingSmallTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #434343;
`;

export const HeadingWrap = styled.div`
  padding: 0 0 32px;
`;

export const DocumentTitleName = styled.div`
  font-size: 20px;
  color: #63666a;
  font-weight: 700;
  text-transform: uppercase;
  span {
      text-transform: initial;
      margin-left: 4px;
  }
`;

export const Content = styled.div`
  padding: 40px;
`;

export const WarningOfNeed = styled.div`
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

export const WarningContent = styled.div`
  display: flex;
  align-items: center;
`;

export const RedText = styled.div`
  color: #ff4b4b;
  display: inline-block;
`;

export const LabelText = styled.div`
  display: inline-block;
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
`;

export const GreenText = styled.div`
  color: #60e84a;
  display: inline-block;
`;

export const DividerOfContent = styled.div`
  height: 1px;
  width: calc(100% + 80px);
  margin-left: -40px;
  background: #f2f2f2;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #f2f2f2;
`;

export const WarningIconWrap = styled.div`
  margin-right: 8px;
`;

export const InfoFields = styled.div`
  min-width: ${({ fullMinWidth }) =>  fullMinWidth ? '100%' : '720px' };
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    flex-basis: ${({fullWidth}) => fullWidth ? '100%' : '49%'};
  }
  margin-bottom: ${({noMargin}) => noMargin ? '0' : '20px'};
`;

export const FixedActWrap = styled.div`
  display: flex;
`;

export const ParentActWrap = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-size: 16px;
    margin-bottom: 15px;

`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  margin-bottom: ${({noMargin}) => noMargin ? '0' : '135px'};
`;

export const FieldsInfoContainer = styled.div`
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DownloadBlock = styled.div`
  background: rgba(248, 236, 49, 0.15);
  border-radius: 6px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  padding: 30px;
  color: #63666a;
  width: 440px;
  min-width: 440px;
  height: 120px;
  margin-left: 60px;
  @media (max-width: 1220px) {
    margin-left: 0px;
  }
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #55b5e7;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :active,
  :hover,
  :visited {
    text-decoration: none;
    color: #55b5e7;
  }
`;

export const StepTitle = styled.div`
  margin-top: ${({noMarginTop}) => noMarginTop ? '0' : '20px'};
  margin-bottom: 20px;
  font-size: 16px;
  color: #63666a;
  font-weight: 600;
  ${props => props.uppercase && 'text-transform: uppercase;'}
`;

export const FixedWrap = styled.div`
  min-width: ${props => props.width};
  width: ${props => props.width};
  display: inline-flex;
  ${props => props.marginBottom && `margin-top: ${props.marginBottom}`}
  ${props => props.stretch && `> * {
    flex-grow: 1;   
  }`}
`;

export const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;

export const ModalTitleWrapper = styled.div`
  padding-top: 0px;
`;

export const GoodsWrap = styled.div`
  min-height: ${props => props.minHeight ? props.minHeight : '495px'};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const GoodsIconWrap = styled.div`
  margin-bottom: 20px;
`;

export const EmptyGoodsTextWrap = styled.div`
  color: #868686;
  font-size: 16px;
`;

export const TableWrap = styled.div`
  overflow-x: auto;
  width: 100%;
`;


export const TableWrapFullWidth = styled.div`
  overflow-x: auto;
  width: 100%;
  .pagination-bottom {
     margin-bottom: 20px;
   }
`;

export const TableWrapScrollableBody = styled.div`
  overflow-x: auto;
  width: calc(100vw - 144px);
  .rt-tbody {
    overflow-y: auto;
    overflow-x: hidden;
    ${props => props.height ? `height: calc(100vh - ${props.height}px)` : ''};
  }
`;

export const ScrollableTableWrap = styled.div`
  overflow-x: auto;
  width: calc(100vw - 144px);
  overflow-y: auto;
  height: 55vh;
`;

export const AddDocTitleWrap = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100% !important;
  margin-bottom: 20px;
  & button {
    margin-left: 20px;
  }
`;

export const DocArrayWrapper = styled.div`
  flex-basis: 100% !important;
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 1fr;
  grid-column-gap: 15px;
  position: relative;
`;

export const DeleteDocumentButton = styled.div`
  // position: absolute;
  // right: -105px;
`;

export const ExciseTaxFields = styled.div`
  padding: 30px 40px 0;
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
`;


export const StepWrapper = styled.div`
  & div[role='tablist'] > button[aria-selected=true] {
    color: white;
  }
`;
export const ParticipantSelectWrapper = styled.div`
  & label {
  & > div > div {
    font-size: 14px;  
  }
    height: 48px;
  }
  & input {
    font-size: 14px;
  }
`;


export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 10px;
`;

export const TableWrapActGood = styled.div`
  padding: 0 10px;
  & div[role='rowgroup'] {
    height: calc(100vh - 465px) !important;
    overflow: auto;
    overflow: overlay;
  }
  & *::-webkit-scrollbar {
    display: block !important
  }
`;
