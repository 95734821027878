import React from 'react';
import PropTypes from 'prop-types';
import SingleSelect from '../_refactor/Select/SingleSelect';
import { TemplateService } from '../../../../services/TemplateService';

export const templateStatus = {
  DELETED: 'DELETED',
  CREATED: 'CREATED',
  REJECTED: 'REJECTED',
  ACTIVATED: 'ACTIVATED',
  ACCEPTED: 'ACCEPTED'
};

class LabelTemplateSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: undefined,
  };

  static cancelToken;

  state = {
    loading: false,
    values: undefined,
  };

  componentDidMount() {
    const { roles } = this.props;

    this.setState({ loading: true });
    setTimeout(() => {
      const params = { limit: 200, offset: 0 };
      const statuses = [templateStatus.ACTIVATED];

      TemplateService.getList(params, roles)
        .then((answer) => {
          const values = answer.data.result.map((item, i) => {
            if (statuses.includes(item.label_template_status)) {
              return {
                id: i,
                title: item.label_template_name,
                type: item.label_template_id,
              };
            }
            return undefined;
          }).filter(i => i);

          this.setState({ values, loading: false });
        })
        .catch(err => this.setState({ values: [], loading: false }));
    }, 0);
  }

  onChange = (val) => {
    const { onChange } = this.props;
    if (onChange) onChange(val);
  };

  render() {
    const { onChange, ...passProps } = this.props;
    const { values, loading } = this.state;

    return (
      <SingleSelect
        {...passProps}
        onChange={this.onChange}
        isLoading={loading}
        values={values}
      />
    );
  }
}

LabelTemplateSelect.displayName = 'SingleSelect';

export default LabelTemplateSelect;
