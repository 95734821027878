import React from 'react';
import {
  ParticipantSelect,
  RangePicker,
  SingleSelect,
} from '../../../common_components/Form/Fields/Fields';
import { Translate } from '../../../common_components/Translate/Translate';
import { receiptStatus } from '../RequestList/RequestList.constants';
import { generateValuesFromDict } from '../../../common_components/Form/Fields/Select/SingleSelect.utils';

const RequestListFilters = ({ countryCode }) => (
  <React.Fragment>
    <RangePicker
      name="date"
      placeholder="Дата"
      leftPlaceholder={Translate('От')}
      rightPlaceholder={Translate('До')}
    />
    <ParticipantSelect
      name="senderInn"
      valueKey="inn"
      placeholder={Translate('Участник(и)')}
    />
    {
      countryCode === 'UZ' && (
        <SingleSelect
          name="status"
          placeholder={Translate('Статус')}
          values={generateValuesFromDict(receiptStatus)}
        />
      )
    }
  </React.Fragment>
);

export default RequestListFilters;
