/* eslint-disable no-useless-escape */
import { b64DecodeUnicode } from './localStorageHelpers';

export function saveToken(token) {
  const data = token.split('.')[1];
  const decoded = b64DecodeUnicode(data);
  const exp = JSON.parse(decoded).exp;
  const expires = new Date(exp * 1000);
  const domain = getCurrentDomain();
  const path = '/';

  const options = {
    expires,
    domain,
    path,
  };

  const slices = getTokenSlices(token);
  const count = slices.length;
  setCookie('count', '' + count, options);

  slices.forEach((tokenPart, i) => {
    setCookie(`tokenPart${i + 1}`, tokenPart, options);
  });
}

export function getTokenSlices(token) {
  let i,
    j,
    splitted = token.split(''),
    results = [],
    chunk = 4000;
  for (i = 0, j = splitted.length; i < j; i += chunk) {
    results.push(splitted.slice(i, i + chunk));
  }
  return results.map(v => v.join(''));
}

export function saveCertSerial(value) {
  deleteCookie('certSerial', getCurrentDomain());
  setCookie('certSerial', value, { domain: getCurrentDomain() });
}

export function saveCertSerialType(value) {
  deleteCookie('certSerialType', getCurrentDomain());
  setCookie('certSerialType', value, { domain: getCurrentDomain() });
}

export function getCertSerial() {
  const serial = getCookie('certSerial');
  return serial;
}

export function getCertSerialType() {
  const serialType = getCookie('certSerialType');
  return serialType;
}

function setCookie(name, value, options) {
  options = options || {};

  var expires = options.expires;

  if (typeof expires == 'number' && expires) {
    var d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  var updatedCookie = name + '=' + value;

  for (var propName in options) {
    updatedCookie += '; ' + propName;
    var propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += '=' + propValue;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)' /* eslint-disable-line */,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

function deleteCookie(name, domain) {
  document.cookie =
    name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
}

export function getCurrentDomain() {
  return `.${location.hostname
    .split('.')
    .slice(-2)
    .join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }
}

export function setLanguage(lng) {
  setCookie('language', lng, { domain: getCurrentDomain(), path: '/' });
}

export function setProductGroup(group) {
  setCookie('productGroup', group, { domain: getCurrentDomain(), path: '/' });
}

export function setSunToken(token) {
  setCookie('sunToken', token, { domain: getCurrentDomain(), path: '/' });
}

export function getSunToken() {
  return getCookie('sunToken');
}

