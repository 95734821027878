import React from 'react';
import { get } from 'lodash';
import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import {
  ProducerSelect,
  ProductPackageSelect,
  ProductBrandSelect,
  ProductsGroupMultiSelect,
  TnVedCodesMultiSelect,
} from 'src/components/Select';
import GTIN from 'src/components/TableCells/Product/GTIN';
import ProductName from 'src/components/TableCells/Product/ProductName';
import ProducerName from 'src/components/TableCells/Product/ProducerName';
import { TableCell } from 'src/components/common/Table/TableCellTooltip';
import { countryCodeFlags, EAESPackageTypes } from 'src/constants';
import { StoreHelper } from 'src/utils/StoreHelper';
import styled from 'styled-components';
import { ProductGroupIcon } from 'src/common_components/ProductGroupIcon';
import { Translate } from '../../../common_components/Translate/Translate';
import { productGroupNames } from '../../../constants';


const { isEAESCountry } = countryCodeFlags;

const productList = {
  actionNamespace: 'PRODUCT_LIST',
  selectorNamespace: 'ProductList',
  limit: 10,
  options: [
    {
      accessor: d => d,
      id: 'gtin',
      render: props => <GTIN iconDisable {...props} />,
    },
    {
      accessor: d => d,
      id: 'name',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <ProductName {...otherProps} />
          </TableCell>
        );
      },
    },
    {
      accessor: d => d,
      id: 'producer',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <ProducerName {...otherProps} />
          </TableCell>
        );
      },
    },
  ],
  columns: [
    {
      Header: Translate('Наименование'),
      id: 'name',
      accessor: 'name',
      minWidth: 100,
    },
    {
      Header: 'GTIN',
      id: 'gtin',
      accessor: 'gtin',
      width: 190,
    },
    ...countryCodeFlags.isUZ ? [
      {
        Header: Translate('Производитель/Импортер'),
        id: 'producer',
        accessor: 'producer',
        minWidth: 100,
      },
    ] : [],
    {
      Header: Translate('Марка'),
      id: 'brand',
      accessor: d => (d.brand ? d.brand : '—'),
    },
    // {
    //   Header: 'МРЦ, Р',
    //   id: 'mrp',
    //   accessor: d => d.mrp ? d.mrp : '—',
    //   width: 150,
    // },
    //TODO: вернуть поле и переименовать, когда на беке добавят поле "в обороте", innerUnitCount это кол-во в упаковке
    // {
    //   Header: 'В обороте',
    //   id: 'turnover',
    //   accessor:  d => d.turnover ? d.turnover : '—',
    //   width: 190,
    //   sortable: true,
    // },
  ],
  beforeTable: {
    showGraph: false,
  },
  beforeFilterForm: {
    addButton: true,
  },
  defaultFilters: [
    {
      showDownloadListButton: false,
      popupMenu: false,
    },
    [
      {
        component: Input,
        props: {
          name: 'gtin',
          placeholder: Translate('GTIN или наименование товара'),
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [
      {
        component: ProductPackageSelect,
        props: {
          inline: false,
          name: 'package_type',
          placeholder: isEAESCountry
            ? Translate('Тип упаковки')
            : Translate('Упаковка'),
          savePlaceholder: true,
        },
      },
    ],
    ...countryCodeFlags.isUZ ? [[
      {
        component: ProducerSelect,
        props: {
          inline: false,
          name: 'inn',
          placeholder: Translate('Производитель'),
          savePlaceholder: true,
        },
      },
    ]] : [],
    [
      {
        component: ProductBrandSelect,
        props: {
          inline: false,
          name: 'brand',
          placeholder: Translate('Марка'),
          savePlaceholder: true,
        },
      },
    ],
  ],

  get filters() {
    if (!isEAESCountry) {
      return this.defaultFilters;
    }
    return [
      ...this.defaultFilters,
      [
        {
          component: TnVedCodesMultiSelect,
          props: {
            name: 'tn_ved_code',
            placeholder: Translate('ТНВЭД'),
            savePlaceholder: true,
          },
        },
      ],
      [
        {
          component: ProductsGroupMultiSelect,
          props: {
            name: 'pg',
            placeholder: Translate('Товарная группа'),
            savePlaceholder: true,
            availableProductGroupNames: get(
              StoreHelper.getState(),
              'COMMON.Auth.userInfo[product_group_info]',
              [],
            ).map(({ name }) => name),
          },
        },
      ],
    ];
  },
};

const PgWrapper =  styled.div`
 display: flex;
 align-items: center;
`
const ProductGroup =  styled.div`
 padding-left: 5px;
`

if (isEAESCountry) {
  productList.columns = [
    ...productList.columns,
    {
      Header: Translate('Товарная группа'),
      id: 'productGroupId',
      accessor: d => {
        return (
          <PgWrapper>
            <ProductGroupIcon productGroupId={d.productGroupId}/>
            <ProductGroup>{d.productGroupId ? Translate(productGroupNames[d.productGroupId]) : "—"}</ProductGroup>
          </PgWrapper>
        );
      },
    },
    {
      Header: Translate('Код ТНВЭД'),
      id: 'tnVedCode10',
      accessor: d => d.tnVedCode10 || '—',
    },
    {
      Header: Translate('Тип упаковки'),
      id: 'packageType',
      accessor: d => (d.packageType ? Translate(EAESPackageTypes[d.packageType]) : '—'),
    },
  ];
}

export default productList;
