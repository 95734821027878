import { get } from "lodash";
import { replace } from "react-router-redux";
import { call, put, takeEvery } from "redux-saga/effects";
import { Toast } from "../../../../../utils/Toast";
import Api from "../../../../../common_components/Api/Api";
import * as actions from "./Aggregation.actions";
import { signUZSaga } from "../../../../../common_components/Eimzo/Eimzo.saga";
import { documentTypes } from "../../../../../constants";


function* transportCodesGenerateSaga(action) {
    const { packageType, updateField } = action.payload;
    const [success, error] = yield call(Api.request, {
        url: '/api/v3/facade/transport-codes/generate',
        method: 'post',
        data: {
            count: 1,
            packageType
        }
    });
    if (success) {
        updateField('aggregationUnits[0].unitSerialNumber', success.data.generatedCodes[0])
    }
    if (error) {
        yield call(Toast.showError, {
            content: get(error, 'response.data.message', 'error'),
        });
    }
}

function* submitSaga(action) {
    const signature = yield call(signUZSaga, {
        payload: {data: prepareSubmitData(action.payload), file: false},
    });
    if (!signature) {
        yield put(actions.reset());
        Toast.showInfo({
            content: 'Документ не подписан.',
            autoClose: 5000,
        });
        return;
    }
    const requestPayload = {
        url: '/api/v3/facade/doc/signed',
        method: 'post',
        data: {
            registration_number: null,
            signature: signature.signature,
            document: signature.document,
            document_type: documentTypes.AGGREGATION_DOCUMENT,
        },
    };
    const [success, error] = yield call(Api.request, requestPayload)
    if (success) {
        Toast.showSuccess({
            content: 'Документ агрегации успешно создан.',
        });
        yield put(replace('/documents/list'));
    }
    if (error) {
        yield call(Toast.showError, {
            content: get(error, 'response.data.message', 'error'),
        });
    }
}

export default function* watch() {
    yield takeEvery(actions.transportCodesGenerate, transportCodesGenerateSaga);
    yield takeEvery(actions.submit, submitSaga);
}

const prepareSubmitData = ({ values }) => ({
    ...values,
    dateDoc: new Date(values.dateDoc).getTime(),
    participantId: values.participantId.split(',')[0],
    aggregationUnits: [{
        ...values.aggregationUnits[0],
        aggregationUnitCapacity: parseInt(values.aggregationUnits[0].aggregationUnitCapacity, 10),
        sntins: values.aggregationUnits[0].sntins.map(({ cis })=> cis)
    }]
})
