import { get } from 'lodash';

export const loadFile = ({ success, name = '' }) => {
  const content = get(success, 'data');
  let fileName = name;
  if (!fileName) {
    const match = get(
      success,
      'payload.headers[content-disposition]',
      ''
    ).match(/filename=(.+)/);

    fileName = match ? match[1] : 'file';
  }

  // for IE 11
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const csvData = new Blob([content], {
      type: get(success, 'data.type'),
    });
    window.navigator.msSaveOrOpenBlob(csvData, fileName);
  }

  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');

  link.href = url;
  link.rel = 'noopener';
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 100);
};

export const downloadFile = (
  b64value,
  filename = 'file.pdf',
  type = 'application/pdf'
) => {
  const byteArray = new Buffer(b64value, 'base64');

  const url = window.URL.createObjectURL(new Blob([byteArray], { type }));

  const link = document.createElement('a');
  link.href = url;
  link.rel = 'noopener';
  link.setAttribute('download', filename);
  link.click();
};

export const openFile = ({ success, type = 'application/pdf' }) => {
  const url = window.URL.createObjectURL(
    new Blob([get(success, 'data')], { type })
  );
  window.open(url);
};
