import { selector } from '../../../../utils/selector';


export const results = state => selector(state, 'Requests.ReceiptList.results');
export const total = state => selector(state, 'Requests.ReceiptList.total');
export const last = state => selector(state, 'Requests.ReceiptList.last');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const sorting = state => selector(state, 'Requests.ReceiptList.sorting');
export const selectedPage = state => selector(state, 'Requests.ReceiptList.selectedPage');
export const pagination = state => selector(state, 'Requests.ReceiptList.pagination');
export const filters = state => selector(state, 'Requests.ReceiptList.filters');
export const uploadErrors = state => selector(state, 'Requests.ReceiptList.uploadErrors');
export const offset = state => selector(state, 'Requests.ReceiptList.offset');
