export const clearTheme = {
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: '"SegoeUI", sans-serif',
  large: {
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '48px',
  },
  normal: {
    paddingLeft: '19px',
    paddingRight: '19px',
    height: '40px',
  },
  main: {
    border: '0',
    color: '#52535A',
  },
  disabled: {
    border: '0',
    color: '#2D3D5B',
    background: '#b9b9b9',
  },
  minWidth: '108px',
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
};
