import { makeRadioGroupFilter } from '@crpt-ui/datatable';

import { documentStatusEnum, documentStatusValuesMap } from '../../constants';

const options = Object.keys(documentStatusEnum)
  .map(status => ({
    label: documentStatusValuesMap[status],
    value: documentStatusEnum[status]
  }));

export const DocumentStatus = makeRadioGroupFilter({options});
