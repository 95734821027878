export const participantStatuses = {
  REGISTERED: 'Зарегистрирован',
  // REGISTERED_EVADER: 'Зарегистрирован как нарушитель',
  // BLOCKED_EVADER: 'Нарушитель, заблокирован',
  // CHECKED_EVADER: 'Нарушитель, проверен',
  PRE_REGISTERED: "В процессе регистрации",
  PRE_REGISTERED_ISSUER: "В процессе регистрации производитель",
  PRE_REGISTERED_TRADER: "В процессе регистрации опт/розница",
  BLOCKED: 'Заблокирован',
};
