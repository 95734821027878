import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { get, isEmpty, isEqual } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { Action, Plus } from '@crpt-ui/icons';
import {
  cisKey,
  goodsKey,
  steps,
  tabsKZ,
  tabsKG,
  contentBodyConfirm, codesMark, contentBodyEditGoods, contentBodyDeleteGoods,
} from '../ActDocument.constants';
import {
  PageWrapper,
  Divider,
  styleCommandBar,
  FixedWrap,
  GoodsWrap,
  GoodsIconWrap,
  EmptyGoodsTextWrap,
  StepWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import {
  actionSelectOptions,
  CodesRenderer,
  columnsKZ,
  columnsKG,
} from './Goods.constants';
import GoodsCommandBar from './CommandBar';
import GoodCodesUploadModalContent from './GoodCodesUploadModalContent';
import GoodsEditProduct from './Goods.edit.product';
import CisListUploadModalContent from './CisListUploadModalContent/CisListUploadModalContent';
import GoodsIcon from './svg/GoodsIcon.svg';
import { Select as SelectRow } from '@crpt-ui/core';
import { connect } from 'react-redux';
import { invalidCisKey } from '../../NotificationOfEntry/NotificationOfEntry.constants';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { ContentErrorLoading } from '../../NotificationOfEntry/Goods/Goods.constants';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import withModal from 'src/common_components/Modals/hocs/withModal';
import MenuButton from 'src/common_components/Buttons/MenuButton';
import ScanDialog from 'src/common_components/ScanDialog/ScanDialog';
import * as _ from 'lodash';
import HandAddGoodModalContent from '../../Document/HandAddGoodModalContent/HandAddGoodModalContent';
import * as actions from './ducks/Goods.actions';
import SelectionList from '../../../../../common_components/List/SelectionList/SelectionList';
import { Translate } from 'src/common_components/Translate/Translate';
import * as selectors from './ducks/Goods.selectors';
import { ButtonWrap } from '../../../../../components/DocumentDetails/Act/Showing.styled';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as actActions from '../ducks/ActDocument.actions';
import ReconciliationDialog from '../Reconciliation/ReconciliationDialog';
import { ExistReconciliationDraftDialog } from '../Reconciliation/components/ExistReconciliationDraftDialog';
import * as reconciliationActions from '../Reconciliation/ducks/Reconciliation.actions';
import * as reconciliationSelectors from '../Reconciliation/ducks/Reconciliation.selectors';
// import CommandBar from 'src/common_components/CommandBar/CommandBar';
// import Input from 'src/common_components/Form/MaterialFields/Input/Input';
// import Select from 'src/common_components/Form/MaterialFields/Select/Select';
// import SubmitButton from 'src/common_components/Form/Fields/Button/SubmitButton';
// import PrimaryMediumActionButton from 'src/common_components/Buttons/MuiPrimaryActionMediumButton';
// import { required } from 'src/common_components/Form/utils/validations';
// import { withModal } from 'src/common_components/Modals/hocs/withModal';
// import { withMultiModal } from 'src/common_components/Modals/hocs/withMultiModal';
// import GoodsEdit from './Goods.edit';
// import { items } from 'src/common_components/menu/CreateDocument/CreateDocument.constants';
// import { validateNumberAfterDotRequiredAndNotEmpty, validateDate } from '../../ImportDocument/ImportDocument.validations';
// import CancelDocumentModal from 'src/common_components/Modals/CancelDocumentModal/CancelDocumentModal';
// import DatePicker from 'src/common_components/Form/MaterialFields/DatePicker/DatePicker';

const keyField = 'cis';

class Goods extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onGetPackageCount: PropTypes.func.isRequired,
    savedProducts: PropTypes.array.isRequired,
    onFixProducts: PropTypes.func.isRequired,
    resetGoodsChanges: PropTypes.func.isRequired,
    isDraft: PropTypes.bool,
    mode: PropTypes.string,
    direction: PropTypes.string,
    id: PropTypes.string,
    isFixed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.instance = React.createRef();

    this.state = {
      selectedRows: [],
      isModalOpen: false,
      isOpenScanDialog: false,
      isOpenEditGoods: null,
      anchorEl: null,
      page: 0,
      topBarRight: [],
      showReconciliationDraftDialog: false,
      showReconciliationDialog: false,
      dataForReconciliation: [],
    };
  }

  setPage = page => {
    this.setState({ page });
  };

  getTopBarRight = () => {
    return [];
  };

  onConvert = () => {
    // this.props.onFixProducts();
  };

  reCountGoods = (mode) => {
    const { goodsChange } = this.props;
    switch (mode) {
      case 'onAddGood':
        this.setState({ isOpenEditGoods: 'onAddGood' }, goodsChange);
        break;
      case 'onHandAddGood':
        this.setState({ isOpenEditGoods: 'onHandAddGood' }, goodsChange);
        break;
      case 'toggleScanDialog':
        this.setState({ isOpenEditGoods: 'toggleScanDialog' }, goodsChange);
        break;
      case 'actionWithTheProduct':
        this.setState({ isOpenEditGoods: 'actionWithTheProduct' }, goodsChange);
        break;
      default:
        console.error('неверный вариант');
        break;
    }
  };

  submitReCountGoods = () => {
    const { isOpenEditGoods } = this.state;
    switch (isOpenEditGoods) {
      case 'onAddGood':
        this.onAddGood();
        break;
      case 'onHandAddGood':
        this.onHandAddGood();
        break;
      case 'toggleScanDialog':
        this.toggleScanDialog();
        break;
      case 'actionWithTheProduct':
        this.actionWithTheProduct();
        break;
      default:
        console.error('неверный вариант');
        break;
    }
    this.setState({ isOpenEditGoods: null });
  };

  onAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
      mode,
    } = this.props;

    onSetContent(
      <GoodCodesUploadModalContent
        mode={mode}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        formValues={formValues}
        onConvert={this.onConvert}
        types=".xls, .xlsx"
        onAccept={file => console.log('onAccept called!', file)}
      />,
    );
    onOpenModal();
  };

  onHandAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
      mode,
    } = this.props;

    onSetContent(
      <HandAddGoodModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        formValues={formValues}
        mode={mode}
        target="act"
      />,
    );
    onOpenModal();
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.cis);
  };

  onPreviousStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.info);
  };

  isNextStepDisabled = () => {
    const { show, formErrors } = this.props;
    if (!show) return true;

    const prepared = JSON.parse(JSON.stringify(formErrors));

    delete prepared[cisKey];
    delete prepared[goodsKey];

    const products = this.getProducts();

    return !!Object.keys(prepared).length || isEmpty(products); // || products.some(hasErrors);
  };

  getProducts = () => {
    const { direction, formValues, mode, status, goodsChanged } = this.props;
    const products = formValues[mode === 'editedAct' ? codesMark : goodsKey] || [];
    return products.map((el,i) => ({
      ...el,
      serialNumber: i + 1,
      cisesCounts: status === 18 || status === 0 || (goodsChanged && status === 19)
        ? ''
        : el.cisesCounts,
      documentStatus: status,
      direction
    }));
  };

  getProductsCost = () => {
    const products = this.getProducts();
    return (
      products
        .reduce((prev, { price }) => {
          return prev + +(price || '').replace(',', '.');
        }, 0)
        .toFixed(2) || '?'
    );
  };

  onRowSelect = selected => {
    const selectedKeys = Object.keys(selected);
    this.setState({
      selectedRows: selectedKeys,
    });
  };

  tabsCurrent = () => {
    const { show, countryCode } = this.props;
    const tabs = countryCode === 'KZ' ? tabsKZ : tabsKG;

    if (!show) return tabs;
    const { onSetStep } = this.props;
    const isNextStepDisabled = this.isNextStepDisabled();
    return tabs
      .map(tab =>
        tab.id === steps.goods
          ? tab
          : {
            ...tab,
            onClick: () => {
              onSetStep(tab.id);
            },
          },
      )
      .map(tab =>
        tab.id <= steps.goods || (tab.id === steps.cis && !isNextStepDisabled)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  onOpenCisModal = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
    } = this.props;

    onSetContent(
      <CisListUploadModalContent
        row={row}
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        title={Translate('Коды маркировки')}
      />,
    );
    onOpenModal();
  };

  onCancelGoodsEdit = (value, index) => {
    const {
      formMutators: { update },
    } = this.props;

    update(goodsKey, index, value);
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  onOpenProductModal = selectedRows => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators: { update },
      formValues,
      countryCode,
    } = this.props;
    const columns = countryCode === 'KZ' ? columnsKZ : columnsKG;

    const __goodsOriginal = formValues[goodsKey]
      ? [...formValues[goodsKey]]
      : [];

    const onReset = () => {
      selectedRows.forEach(index =>
        update(goodsKey, index, __goodsOriginal[index] || ''),
      );
    };

    onSetContent(
      <GoodsEditProduct
        onCancel={this.onCancelGoodsEdit}
        onCloseModal={onCloseModal}
        onReset={onReset}
        preparedColumns={this.prepareColumns(columns)}
        selectedRows={selectedRows}
        title={Translate('Редактирование товаров')}
      />,
    );
    onOpenModal();
  };

  prepareColumns = columns =>
    columns.map(column =>
      column.id === 'codes'
        ? {
          ...column,
          accessor: row => (
            <CodesRenderer
              row={row}
              onClick={e => this.onOpenCisModal(e, row)}
            />
          ),
        }
        : column,
    );

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  toggleScanDialog = () =>
    this.setState(({ isOpenScanDialog }) => ({
      isOpenScanDialog: !isOpenScanDialog,
    }));

  addScannedCises = scanned => {
    const { formMutators, mode } = this.props;
    const oldProducts = this.getProducts();
    const result = [...oldProducts, ...scanned].map((item, idx) => ({
      idx: idx + 1,
      ...item,
    }));
    formMutators.updateField(
      mode === 'editedAct'
        ? codesMark
        : goodsKey, _.uniqBy(result, 'cis'),
    );
  };

  onListRef = ref => {
    this.goodsList = ref;
  };

  onUpdate = () => {
    this.setState({ selectedRows: this.goodsList.state.selectedRows });
  };

  cisesUnitsInfo = () => {
    const {
      formMutators,
      savedProducts,
      onFixProducts,
      // onGetPackageCount,
    } = this.props;
    const products = this.getProducts();
    const productsCis = products.map(item => item.cis);

    if (!isEqual(savedProducts, productsCis) && productsCis.length > 0) {
      onFixProducts(productsCis);
      // onGetPackageCount({ products, formMutators });
    } else if (productsCis.length === 0 && savedProducts.length > 0) {
      formMutators.updateField(goodsKey, []);
      onFixProducts();
    }
  };

  actionWithTheProduct = () => {
    const { formValues, formMutators, mode } = this.props;
    const selectedRows = [...this.state.selectedRows];
    let products = formValues[mode === 'editedAct' ? codesMark : goodsKey] || [];
    products = products.filter(
      item => !selectedRows.includes(item[keyField]),
    );
    this.goodsList.clearSelected();
    this.setState({ selectedRows: products })
    if (this.goodsList.state.isAllSelected) this.goodsList.toggleAll();
    formMutators.updateField(mode === 'editedAct' ? codesMark : goodsKey, products);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  approveAct = () => {
    const { accept, id } = this.props;
    this.handleClose();
    accept({ id, decision: 'approve' });
  };

  rejectAct = () => {
    const { reject, id } = this.props;
    this.handleClose();
    reject({ id, decision: 'reject' });
  };

  startReconciliation = () => {
    const { getReconciliationDraft, id } = this.props;

    getReconciliationDraft({
      id,
      onFinish: isExist => {
        if (isExist) {
          this.onReconciliationDraftToggle();
        } else {
          this.newReconciliation();
        }
      },
    });
  };

  newReconciliation = () => {
    const { data } = this.props;
    this.setState({
      showReconciliationDraftDialog: false,
      showReconciliationDialog: false,
      dataForReconciliation: data.km,
    });
    this.onReconciliationToggle();
  };

  onReconciliationToggle = () => {
    const { showReconciliationDialog } = this.state;
    this.setState({
      showReconciliationDialog: !showReconciliationDialog,
    });
  };

  onReconciliationDraftToggle = () => {
    const { showReconciliationDraftDialog } = this.state;
    this.setState({
      showReconciliationDraftDialog: !showReconciliationDraftDialog,
    });
  };

  handleReconciliation = () => {
    this.startReconciliation();
    this.handleClose();
  };

  continueReconciliation = () => {
    const { reconciliationDraft } = this.props;
    this.setState({
      showReconciliationDraftDialog: false,
      dataForReconciliation: reconciliationDraft,
    });
    this.onReconciliationDraftToggle();
    this.onReconciliationToggle();
  };

  cancelEditGoods = () => {
    const { resetGoodsChanges } = this.props;
    resetGoodsChanges();
    this.setState({
      isOpenEditGoods: null,
    })
  }

  render() {
    const { isModalOpen, isOpenScanDialog, page, isOpenEditGoods, anchorEl, showReconciliationDraftDialog, showReconciliationDialog } = this.state;
    const { dataForReconciliation } = this.state;
    const { show, countryCode, formMutators, formValues, isCorrection, status, goodsChanged, direction, isFixed, data, id, isDraft } = this.props;

    this.cisesUnitsInfo();

    const products = this.getProducts();
    const columns = countryCode === 'KZ' ? columnsKZ : columnsKG;
    const preparedColumns = this.prepareColumns(columns);

    let isInvalidCisShow = !isEmpty(formValues.invalidCis);
    const invalidList = formValues.invalidCis
      ? formValues.invalidCis.map(item => <div>Код: {item}</div>)
      : '';
    let contentErrorLoading = (
      <ContentErrorLoading>
        {invalidList}
        {Translate('Загрузить другой файл?')}
      </ContentErrorLoading>
    );
    const ownerInn = get(data, 'data.content.buyer.inn');
    const supplierInn = get(data, 'data.content.seller.inn');

    return (
      <PageWrapper hide={!show}>
        <ScanDialog
          open={isOpenScanDialog}
          onClose={this.toggleScanDialog}
          onAdd={this.addScannedCises}
          docType="KZ_UPD"
        />
        <ReconciliationDialog
          onClose={this.onReconciliationToggle}
          open={showReconciliationDialog}
          showingData={dataForReconciliation}
          id={id}
          supplierInn={supplierInn}
          ownerInn={ownerInn}
        />
        <ExistReconciliationDraftDialog
          onClose={this.onReconciliationDraftToggle}
          continueReconciliation={this.continueReconciliation}
          newReconciliation={this.newReconciliation}
          open={showReconciliationDraftDialog}
        />
        <ConfirmModal
          isModalOpen={isModalOpen}
          onClose={this.toggleDialog}
          onSubmit={this.onCancel}
          content={contentBodyConfirm}
          header={Translate('Предупреждение')}
          cancelButtonText="Нет"
          submitButtonText="Да"
          notCancelable
        />
        <ConfirmModal
          isModalOpen={isOpenEditGoods}
          onClose={this.cancelEditGoods}
          onSubmit={this.submitReCountGoods}
          content={
            isOpenEditGoods === null
              ? <div />
              : isOpenEditGoods === 'actionWithTheProduct'
                ? contentBodyDeleteGoods
                : contentBodyEditGoods
          }
          header={Translate('Предупреждение')}
          cancelButtonText="Нет"
          submitButtonText="Да"
          notCancelable
        />
        <ConfirmModal
          isModalOpen={isInvalidCisShow}
          onClose={() => formMutators.updateField(invalidCisKey, [])}
          onSubmit={() => {
            this.onAddGood();
            formMutators.updateField(invalidCisKey, []);
          }}
          content={contentErrorLoading}
          header={Translate('Ошибка')}
          cancelButtonText={Translate('Отмена')}
          submitButtonText={Translate('Загрузить файл')}
          color={'error'}
        />
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.goods}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <GoodsCommandBar
            left={[
              <MenuButton
                menu={[
                  {
                    title: Translate('Загрузить из файла'),
                    onClick: status === 19 && !goodsChanged ? () => this.reCountGoods('onAddGood') : this.onAddGood,
                  },
                  {
                    title: Translate('Выбрать из списка'),
                    onClick: status === 19 && !goodsChanged ? () => this.reCountGoods('onHandAddGood') : this.onHandAddGood,
                  },
                  {
                    title: Translate('Сканировать коды'),
                    onClick: status === 19 && !goodsChanged ? () => this.reCountGoods('toggleScanDialog') : () => this.toggleScanDialog(),
                  },
                ]}
                positionY="bottom"
                variant="contained"
                color="primary"
                disabled={isCorrection || (status !== null && status !== 19 && !isDraft) || isFixed } //  || (status !== null && status !== 19)
              >
                <Plus/>
                <span style={{ fontSize: '12px', fontWeight: '700', margin: '8px' }}>
                  {Translate('ДОБАВИТЬ ТОВАР')}
                </span>
              </MenuButton>,
              ...countryCode === 'KZ' ? [<FixedWrap width={'260px'} stretch>
                <SelectRow
                  options={actionSelectOptions}
                  label={Translate('Действие')}
                  onChange={status === 19 && !goodsChanged ? () => this.reCountGoods('actionWithTheProduct') : this.actionWithTheProduct}
                  disabled={this.state.selectedRows.length === 0 || isCorrection || (status !== null && status !== 19 && !isDraft) || isFixed}
                />
              </FixedWrap>] : [],
            ]}
            right={[]}
          />

          <Divider/>

          {isEmpty(products) ? (
            <GoodsWrap minHeight={'calc(100vh - 395px)'}>
              <GoodsIconWrap>
                <GoodsIcon/>
              </GoodsIconWrap>

              <EmptyGoodsTextWrap>
                {Translate('Нет добавленных товаров')}
              </EmptyGoodsTextWrap>
            </GoodsWrap>
          ) : (
            <SelectionList
              defaultPageSize={10}
              columns={preparedColumns}
              data={products}
              onRef={this.onListRef}
              onUpdate={this.onUpdate}
              showPagination={products.length > 10}
              onPageChange={pageIdx => this.setPage(pageIdx)}
              keyField={keyField}
              page={page}
              selectType={((status !== null && status !== 19 && !isDraft) || isFixed) ? 'correction' : isCorrection ? 'correction' : 'checkbox'}
              customSelectAll
            />
          )}
          <GoodsCommandBar
            left={
              status === null || isDraft
                ? [
                  <CommonActionButton onClick={this.toggleDialog}>
                    {Translate('Отменить')}
                  </CommonActionButton>,
                  <CommonActionButton onClick={this.onSaveDraft}>
                    {Translate('Сохранить как черновик')}
                  </CommonActionButton>,
                ]
                : []
            }
            style={{
              ...styleCommandBar,
              width: status !== null ? 'auto' : '100%',
            }}
            right={!isFixed && ![3, 13].includes(status)
              ? [
                <PrimaryActionLargeButton
                  disabled={this.isNextStepDisabled}
                  onClick={this.onNextStep}
                >
                  {Translate('Следующий шаг')}
                </PrimaryActionLargeButton>,
              ]
              : []
            }
          />
          {[3, 13].includes(status) && direction === 'act-in' && (
            <ButtonWrap>
              <Button
                onClick={this.handleClick}
                variant="contained"
                startIcon={<Action/>}
              >
                {Translate("Действия")}
              </Button>
              <Menu
                anchorEl={anchorEl}
                autoFocus={false}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <MenuItem onClick={this.approveAct}>{Translate("Принять документ")}</MenuItem>
                <MenuItem onClick={this.rejectAct}>{Translate("Отклонить документ")}</MenuItem>
                {!isFixed && (
                  <MenuItem onClick={this.handleReconciliation}>{Translate("Выполнить сверку")}</MenuItem>
                )}
              </Menu>
            </ButtonWrap>
          )}
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  savedProducts: selectors.savedProducts(state),
  reconciliationDraft: reconciliationSelectors.draft(state),
});

const mapDispatch = dispatch => ({
  onGetPackageCount: params => dispatch(actions.getPackageCount(params)),
  onFixProducts: cises => dispatch(actions.fixProducts(cises)),
  accept: data => dispatch(actActions.acceptDocument(data)),
  reject: data => dispatch(actActions.rejectDocument(data)),
  getReconciliationDraft: data => dispatch(reconciliationActions.getReconciliationDraft(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
  withRouter,
)(Goods);
