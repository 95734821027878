export default {
  title: 'Табличное представление при выборе типа агрегации «Производитель»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Производитель',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 176,
          subheaders: []
        },
        {
          Header: 'Доля рынка (% в штуках)',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 384,
          subheaders: [
            {
              Header: 'Производство',
              accessor: 'col2',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 192,
              subheaders: []
            },
            {
              Header: 'Импорт',
              accessor: 'col3',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 192,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Доля рынка (% в тенге)',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 384,
          subheaders: [
            {
              Header: 'Производство',
              accessor: 'col4',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 192,
              subheaders: []
            },
            {
              Header: 'Импорт',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 192,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
        }
      ]
    }
  ]
}
