import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Close } from '@crpt-ui/icons';
import { Box, Grid, MuiThemeProvider, Typography } from '@material-ui/core';
import useStyles from '../ImportThirdCountry/Goods/Goods.styled';
import { goodsKey } from '../ImportThirdCountry/ImportThirdCountry.constants';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../../../common_components/_MaterialUi/ThemeUZ';
import { Translate } from '../../../../common_components/Translate/Translate';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionMediumButton';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionMediumButton';
import { formMutators as finalFormMutators } from '../../../../common_components/Form/Form.constants'
import withForm from '../../../../common_components/hocs/withForm';
import Input from '../../../../common_components/Form/Fields/Input/Input';
// import { FloatInput } from '../ImportThirdCountry/Information/FloatInput';
import Select from '../../../../common_components/Form/Fields/Select/SingleSelect';
import { docKinds } from './ExportNotificationToTheEaes.constants';
import { DividerOfContent } from './ExportNotificationToTheEaes.utls';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { validateDateWithEmptyValue } from '../ImportDocument/ImportDocument.validations';
import {FloatField} from "../ImportThirdCountry/Information/FloatField";

// Есть аналог в ImportThirdCountry! Потом вынести компонент в отдельное место
const GoodsEditProduct = (props) => {
  const { onCloseModal, formValues, formErrors, selectedRows } = props;
  const { onReset, title = '', countryCode, formMutators } = props;
  const classes = useStyles();
  const [rowIndex, setRowIndex] = useState(0);
  const index = +selectedRows[rowIndex];
  const isExport = window.location.pathname.includes('exportNotificationToTheEaes');
  let docList = [];
  let currentFormMutators;

  useEffect(()=> {
    if (!isExport) {
      addDoc();
    }
  },[index])

  const hasErrors = (values) => {
    const checkPrice = ((values.products[index].price || values.products[index].price === 0)
      && ((!values.products[index].vat_tax && values.products[index].vat_tax !== 0) || Object.keys(formErrors).length))
    const checkTax = ((values.products[index].vat_tax || values.products[index].vat_tax === 0)
      && ((!values.products[index].price && values.products[index].price !== 0) || Object.keys(formErrors).length))
    if (checkPrice || checkTax) return true;

    const errorsInDocs = values.products[index].documentList
      ? values.products[index].documentList.reduce((acc,doc) => {
        return acc || doc.docKind && (!doc.docNumber || !doc.docDate)
      }, false)
      : false
    if (!values[goodsKey] || !values[goodsKey][index]) return true;
    if (errorsInDocs) return true;
    const firstElList = get(formErrors, `[${goodsKey}][${index}].documentList[0]`, {});
    return !!(
      formErrors[goodsKey] && formErrors[goodsKey][index] &&
      Object.keys(formErrors[goodsKey][index]).length > 0 && Object.keys(firstElList).length > 0
    );
  };

  const addDoc = () => {
    currentFormMutators.updateField(`[products][${index}][documentList][${docList.length === 0 ? 0 : 1}]docKind`, "");
    currentFormMutators.updateField(`[products][${index}][documentList][${docList.length === 0 ? 0 : 1}]docDate`, "");
    currentFormMutators.updateField(`[products][${index}][documentList][${docList.length === 0 ? 0 : 1}]docNumber`, "");
  };

  const deleteDoc = (i) => {
    currentFormMutators.removeKeys(`[products][${index}][documentList]`, [i]);
  };

  const validator = (values) => {
    let errors = {}
    if (values.products[index].price && !values.products[index].vat_tax) {
      let products= [];
      products[index] = { vat_tax: "Error" }
      errors = { products }
    }
    if (!values.products[index].price && values.products[index].vat_tax) {
      let products= [...errors.products || []]
      products[index] = { ...errors.products ? errors.products[index] : {}, price: "Error" }
      errors = { ...errors, products }
    }
    // console.log('validator values',values);
    // if(values.products[index].price && isExcisePrice(values.products[index].price)) {
    //   let products= [...errors.products || []]
    //   products[index] = { ...errors.products ? errors.products[index] : {}, price: "Error" }
    //   errors = { ...errors, products }
    // }
    // if(values.products[index].vat_tax && isExciseTax(values.products[index].vat_tax)) {
    //   let products= [...errors.products || []]
    //   products[index] = { ...errors.products ? errors.products[index] : {}, vat_tax: "Error" }
    //   errors = { ...errors, products }
    // }
    values.products.forEach(product=> {
      if (product.documentList) {
        let documentList = [];
        product.documentList.forEach((doc, i) => {
          if (doc.docKind && !(doc.docDate && doc.docDate)) {
            documentList[i] = {
              docDate: 'Error'
            }
          }
          if (doc.docKind && !(doc.docNumber && doc.docNumber)) {
            documentList[i] = {
              ...documentList[i] ? documentList[i] : {},
              docNumber: 'Error'
            }
          }
        })
        if (documentList.length !== 0) {
          let products = [...errors.products || []]
          products[index] = { ...errors.products ? errors.products[index] : {}, documentList }
          errors = { products }
        }
      }
    })
    return errors;
  }

  const saveChanges = (values) => {
    formMutators.updateField('products', [
      ...values.products.map(product => ({
        ...product,
        ...product.documentList && product.documentList.length > 0
          ? { documentList:  product.documentList.map(el=> ({...el, docKind: el.docKind.value || el.docKind})) }
          : {}
      })),
    ]);
    onCloseModal();
  }

  const preparedData = useMemo(()=> {
    return ({
      ...formValues,
      products: formValues.products.map(product => ({
        ...product,
        ...product.documentList && product.documentList.length > 0
          ? { documentList:  product.documentList.map(el=> {
                return ({ ...el, docKind: docKinds.find(kind => el.docKind === kind.value) || el.docKind })
              }
            ) }
          : {}
      }))})
  },[formValues])


  const formatFloatInput = (val) => {
    if (!val) return ''

    val = val.replace(',', '.')

    let integerPart = val.split('.')[0] ? val.split('.')[0].replace(/[^0-9]/g,"") : ''
    let floatPart = val.split('.')[1] ? val.split('.')[1].replace(/[^0-9]/g,"") : ''
    if (integerPart.length > 17) integerPart = integerPart.substr(0, 17)
    if (floatPart.length > 2) floatPart = floatPart.substr(0, 2)
    if (floatPart) {
      return `${integerPart || ""}.${floatPart}`
    } else {
      if (val === `${integerPart}.`) return `${integerPart}.`
      return `${integerPart || ""}`
    }
  }

  return (
    <MuiThemeProvider
      theme={countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ}
    >
      <Form
        initialValues={preparedData}
        mutators={{ ...finalFormMutators }}
        validate={validator}
        onSubmit={saveChanges}
      >
        {({ handleSubmit, values, form }) => {
          docList = values.products[index].documentList || []
          currentFormMutators = form.mutators
          const disabled = hasErrors(values)
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.iconCloseWrapper}>
                  <Close
                    onClick={() => {
                      onReset();
                      onCloseModal();
                    }}
                  />
                </div>
                <Typography variant="h4" color="textPrimary">
                  {title}
                </Typography>
                <Box mx={-4} mt={2} mb={4}/>
                <Box color="text.primary" fontSize={14} mb={2}>
                  {Translate('Заполните уникальные параметры для выбранных товаров (Заполнено товаров')}
                  {': '}
                  {rowIndex + 1} {Translate('из')} {selectedRows.length}
                  {')'}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      name={`[products][${index}]${isExport ? 'cis' : 'name'}`}
                      placeholder={Translate(isExport ? 'Код идентификации' : 'Наименование товара')}
                      disabled
                    />
                  </Grid>
                  {
                    !isExport ? (
                      <Grid item xs={6} spacing={2}>
                        <Input
                          required
                          disabled
                          name={`[products][${index}]gtin`}
                          placeholder="GTIN"
                        />
                      </Grid>
                    ) : <Grid item spacing={2}/>
                  }
                  <Grid item xs={6} spacing={2}>
                    {/*<FloatInput*/}
                    {/*  inputNotFromMui*/}
                    {/*  format={formatFloatInput}*/}
                    {/*  name={`[products][${index}]price`}*/}
                    {/*  label={Translate('Цена с НДС за единицу товара')}*/}
                    {/*  mask='9{1,17}.99'*/}
                    {/*/>*/}
                    <FloatField
                      name={`[products][${index}]price`}
                      format={formatFloatInput}
                      label={'Цена с НДС за единицу товара'}
                      value={values.products[index].price}
                      error={values.products[index].vat_tax && !values.products[index].price}
                      currentFormMutators={currentFormMutators}
                    />
                  </Grid>
                  <Grid item xs={6} spacing={2}>
                    {/*<FloatInput*/}
                    {/*  inputNotFromMui*/}
                    {/*  format={formatFloatInput}*/}
                    {/*  name={`[products][${index}]vat_tax`}*/}
                    {/*  label="НДС"*/}
                    {/*  mask='9{1,17}.99'*/}
                    {/*/>*/}
                    <FloatField
                      name={`[products][${index}]vat_tax`}
                      format={formatFloatInput}
                      label="НДС"
                      value={values.products[index].vat_tax}
                      error={values.products[index].price && !values.products[index].vat_tax}
                      currentFormMutators={currentFormMutators}
                    />
                  </Grid>
                  <DividerOfContent />
                  {
                    docList.map((el, i) => {
                      return (
                        <Fragment>
                          <Grid item xs={6}>
                            <Select
                                placeholder={Translate('Вид документа')}
                                name={`[products][${index}][documentList][${i}]docKind`}
                                values={docKinds}
                                forceValidate
                                required={!isExport}
                              />
                            </Grid>
                            <Grid item xs={6} spacing={2}>
                                <DatePicker
                                  className={classes.documentFormDateInput}
                                  maxDate={new Date()}
                                  label={'Дата документа'}
                                  placeholder={Translate('Дата')}
                                  name={`[products][${index}][documentList][${i}]docDate`}
                                  validate={(...vals) => {
                                    return validateDateWithEmptyValue(...vals, "DD.MM.YYYY");
                                  }}
                                />
                            </Grid>
                            <Grid item xs={6} spacing={2}>
                              <Input
                                inputNotFromMui
                                name={`[products][${index}][documentList][${i}]docNumber`}
                                placeholder={Translate('Номер документа')}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CommonActionButton
                                onClick={() => deleteDoc(i)}
                                disabled={false}
                                className={classes.delAndDelDoc}
                              >
                                {Translate('Убрать документ')}
                              </CommonActionButton>
                            </Grid>
                          </Fragment>
                        );
                      })
                  }
                  {docList.length < 2 && (
                    <Grid item xs={6}>
                      <CommonActionButton
                        onClick={addDoc}
                        disabled={false}
                        className={classes.delAndDelDoc}
                      >
                        {Translate('Добавить документ')}
                      </CommonActionButton>
                    </Grid>
                  )}
                  {/*<Grid item xs={6} spacing={2} />*/}
                  {/*<Grid item xs={6} spacing={2} />*/}
                  {/*<Grid item xs={6} spacing={2} />*/}
                  {/*<Grid item xs={6} spacing={2} />*/}
                </Grid>
                <Box mt={4}>
                  <div className={classes.divider}/>
                </Box>
                <div className={classes.buttonsWrapper}>
                  <PrimaryActionButton
                    disabled={disabled}
                    onClick={()=> {
                      if (rowIndex === selectedRows.length - 1) {
                        form.submit();
                        onCloseModal();
                      } else {
                        setRowIndex(rowIndex + 1);
                      }
                    }}
                  >
                    {rowIndex === selectedRows.length - 1
                      ? Translate('Сохранить и завершить')
                      : Translate('Продолжить')}
                  </PrimaryActionButton>
                  <CommonActionButton
                    onClick={() => {
                      form.reset();
                      onCloseModal();
                    }}
                  >
                    {Translate('Отменить')}
                  </CommonActionButton>
                </div>
              </div>
            </form>
          )
        }}
      </Form>
    </MuiThemeProvider>
  );
};

GoodsEditProduct.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  formErrors: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

const mapState = state => ({
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState), withForm)(GoodsEditProduct);

{/*<div className={classes.content}>*/}
{/*<div className={classes.iconCloseWrapper}>*/}
{/*  <Close*/}
{/*    onClick={() => {*/}
{/*      onReset();*/}
{/*      onCloseModal();*/}
{/*    }}*/}
{/*  />*/}
{/*</div>*/}
{/*<Typography variant="h4" color="textPrimary">*/}
{/*  {title}*/}
{/*</Typography>*/}
{/*<Box mx={-4} mt={2} mb={4}/>*/}
{/*<Box color="text.primary" fontSize={14} mb={2}>*/}
{/*  {Translate('Заполните уникальные параметры для выбранных товаров (Заполнено товаров')}*/}
{/*  {': '}*/}
{/*  {rowIndex + 1} {Translate('из')} {selectedRows.length}*/}
{/*  {')'}*/}
{/*</Box>*/}
{/*<Grid container spacing={2}>*/}
{/*  <Grid item xs={6}>*/}
{/*    <Input*/}
{/*      name={`[products][${index}]${isExport ? 'cis' : 'name'}`}*/}
{/*      placeholder={Translate(isExport ? 'Код идентификации' : 'Наименование товара')}*/}
{/*      disabled*/}
{/*      validate={required}*/}
{/*    />*/}
{/*  </Grid>*/}
{/*  {*/}
{/*    !isExport ? (*/}
{/*      <Grid item xs={6}>*/}
{/*        <Input*/}
{/*          required*/}
{/*          disabled*/}
{/*          name={`[products][${index}]gtin`}*/}
{/*          placeholder="GTIN"*/}
{/*          marginBottom="0"*/}
{/*        />*/}
{/*      </Grid>*/}
{/*    ) : <Grid item/>*/}
{/*  }*/}
{/*  <Grid item xs={6}>*/}
{/*    <FloatInput*/}
{/*      forceValidate={!!formValues.products[index].vat_tax}*/}
{/*      validate={isExport && formValues.products[index].vat_tax ? isExcisePrice : isExcisePriceWithEmptyValue}*/}
{/*      name={`[products][${index}]price`}*/}
{/*      label={Translate('Цена с НДС за единицу товара')}*/}
{/*      mask='9{1,17}.99'*/}
{/*      substitutes={{ ",": "."}}*/}
{/*      required={!!formValues.products[index].vat_tax}*/}
{/*    />*/}
{/*  </Grid>*/}
{/*  <Grid item xs={6}>*/}
{/*    <FloatInput*/}
{/*      required={!!formValues.products[index].price}*/}
{/*      forceValidate={!!formValues.products[index].price}*/}
{/*      validate={isExport && formValues.products[index].price ? (value) => {*/}
{/*        console.log('1',formValues.products[index].price);*/}
{/*        isExciseTax(value);*/}
{/*      } : (value) => {*/}
{/*        console.log('2',formValues.products[index].price);*/}
{/*        isExcisePriceWithEmptyValue(value)*/}
{/*      }}*/}
{/*      name={`[products][${index}]vat_tax`}*/}
{/*      label="НДС"*/}
{/*      mask='9{1,17}.99'*/}
{/*      substitutes={{ ",": "."}}*/}
{/*    />*/}
{/*  </Grid>*/}
{/*  <DividerOfContent/>*/}
{/*  {*/}
{/*    docList.map((el, i) => {*/}
{/*      return (*/}
{/*        <Fragment>*/}
{/*          <Grid item xs={6}>*/}
{/*            <Select*/}
{/*              placeholder={Translate('Вид документа')}*/}
{/*              name={`[products][${index}][documentList][${i}]docKind`}*/}
{/*              options={docKinds}*/}
{/*              forceValidate*/}
{/*              required={!isExport}*/}
{/*              validate={isExport ? undefined : required}*/}
{/*              onSelect={() => {*/}
{/*                formMutators.updateField(*/}
{/*                  `[products][${index}][documentList][${i}]docNumber`,*/}
{/*                  Array.isArray(docList[i].docNumber) ? '' : docList[i].docNumber*/}
{/*                )*/}
{/*              }}*/}
{/*            />*/}
{/*          </Grid>*/}
{/*          <Grid item xs={6}>*/}
{/*            {*/}
{/*              <DatePickerInput*/}
{/*                savePlaceholder*/}
{/*                inline={false}*/}
{/*                onChange={(value)=>{changeDate(value, i)}}*/}
{/*                maxDate={new Date()}*/}
{/*                label={'Дата документа'}*/}
{/*                error={formValues.products[index].documentList[i].docKind[0] && !dates[i]}*/}
{/*                value={dates[i]}*/}
{/*                required={formValues.products[index].documentList[i].docKind[0]}*/}
{/*              />*/}
{/*            }*/}
{/*          </Grid>*/}
{/*          <Grid item xs={6}>*/}
{/*            <Input*/}
{/*              forceValidate={formValues.products[index].documentList[i].docKind[0]}*/}
{/*              name={`[products][${index}][documentList][${i}]docNumber`}*/}
{/*              placeholder={Translate('Номер документа')}*/}
{/*              validate={formValues.products[index].documentList[i].docKind[0] ? requiredNumber : notRequiredNumber}*/}
{/*              // required={formValues.products[index].documentList[i].docKind[0]}*/}
{/*            />*/}
{/*          </Grid>*/}
{/*          <Grid item xs={6}>*/}
{/*            <CommonActionButton*/}
{/*              onClick={() => deleteDoc(i)}*/}
{/*              disabled={false}*/}
{/*            >*/}
{/*              {Translate('Убрать документ')}*/}
{/*            </CommonActionButton>*/}
{/*          </Grid>*/}
{/*        </Fragment>*/}
{/*      );*/}
{/*    })*/}
{/*  }*/}
{/*  {docList.length < 2 && (*/}
{/*    <Grid item xs={6}>*/}
{/*      <CommonActionButton*/}
{/*        onClick={addDoc}*/}
{/*        disabled={false}*/}
{/*      >*/}
{/*        {Translate('Добавить документ')}*/}
{/*      </CommonActionButton>*/}
{/*    </Grid>*/}
{/*  )}*/}
{/*</Grid>*/}
{/*<Box mt={4}>*/}
{/*  <div className={classes.divider}/>*/}
{/*</Box>*/}
{/*<div className={classes.buttonsWrapper}>*/}
{/*  <PrimaryActionButton*/}
{/*    disabled={isError}*/}
{/*    onClick={*/}
{/*      rowIndex === selectedRows.length - 1*/}
{/*        ? ()=> {*/}
{/*          setDates({0: undefined, 1: undefined})*/}
{/*          onCloseModal();*/}
{/*        }*/}
{/*        : () => {*/}
{/*          setDates({0: undefined, 1: undefined})*/}
{/*          setRowIndex(rowIndex + 1);*/}
{/*        }*/}
{/*    }*/}
{/*  >*/}
{/*    {rowIndex === selectedRows.length - 1*/}
{/*      ? Translate('Сохранить и завершить')*/}
{/*      : Translate('Продолжить')}*/}
{/*  </PrimaryActionButton>*/}
{/*  <CommonActionButton*/}
{/*    onClick={closeModalAndReset}*/}
{/*  >*/}
{/*    {Translate('Отменить')}*/}
{/*  </CommonActionButton>*/}
{/*</div>*/}
{/*</div>*/}
