import {actionTypes} from '../../constants/';

export function passwordDisabled(state = false, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM.SUBMIT_CLICKED:
      return true;

    case actionTypes.LOGIN_FORM.GOT_ERROR_LOGIN:
    case actionTypes.LOGIN_FORM.GOT_SUCCESS_LOGIN:
      return false;

    default:
      return state;
  }
}
