import React from 'react';
import Select from './Select';
import { countryCodes } from '../../../../constants/countryCodes';

const options = countryCodes.map(c => ({ label: c.title, value: c.type }));

export default function CountrySelect(props) {
  const { eas } = props;
  const currentOptions = !eas ? options : options.filter(({ value }) => ['RU', 'KG', 'AM', 'BY'].includes(value));
  return <Select placeholder="Выберите страну" options={currentOptions} {...props} />;
}
