import React from 'react';
import { packTrivialTypes } from 'src/constants/packTypes';
import { cisStatuses } from 'src/constants';
import {CodeColumns} from 'src/fragments/Table/Cell/Codes/columns.constants';
import * as _ from 'lodash';
import styled from "styled-components";

export const limit = 10;

export const columns = [
  {
    ...CodeColumns.producedDate,
    disableFilters: true,
    disableSortBy: true,
    width: 140,
  },
  {
    sortType: (rowA, rowB, columnID) => {
      const statusA = cisStatuses[_.get(rowA, `original.${columnID}`)] || 'Не определен'
      const statusB = cisStatuses[_.get(rowB, `original.${columnID}`)] || 'Не определен'
      if (statusA > statusB) {
        return 1
      }
      if (statusA < statusB) {
        return -1
      }
      return 0
    },
    ...CodeColumns.status,
    disableFilters: true,
    width: 200,
  },
  {
    ...CodeColumns.cis,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    sortType: (rowA, rowB, columnID) => {
      const typeA = _.get(rowA, `original.${columnID}`)
        ? _.get(rowA, `original.${columnID}`).toLowerCase()
        : '';
      const typeB = _.get(rowB, `original.${columnID}`)
        ? _.get(rowB, `original.${columnID}`).toLowerCase()
        : '';

      const preparedTypeA =
        packTrivialTypes[typeA] || 'Не определен';
      const preparedTypeB =
        packTrivialTypes[typeB] || 'Не определен';
      if (preparedTypeA > preparedTypeB) {
        return 1;
      }
      if (preparedTypeA < preparedTypeB) {
        return -1;
      }
      return 0;
    },
    ...CodeColumns.type,
    disableFilters: true,
    width: 230,
  },
  {
    id: 'name',
    accessor: 'name',
    sortType: 'basic',
    ...CodeColumns.productName,
    disableFilters: true,
    width: 160,
  },
  {
    id: 'producerInn',
    accessor: 'producerInn',
    ...CodeColumns.producerName,
    disableFilters: true,
    disableSortBy: true,
    width: 200,
  },
  {
    id: 'ownerInn',
    accessor: 'ownerInn',
    ...CodeColumns.ownerName,
    disableFilters: true,
    disableSortBy: true,
    width: 200,
  },
];

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: #232c40;
  padding-left: 2px;
  span {
    font-size: 18px;
    font-weight: 500;
    color: rgb(99, 102, 106);
  }
`;
