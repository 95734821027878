import { combineReducers } from 'redux';
import { reportData } from './reportList';
import { glossaryList } from './glossaryList';
import {lists} from '../../components/Reports/ReportList/ReportList.reducers'

export default combineReducers({
  reportData,
  glossaryList,
  lists
});
