import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import { get } from 'lodash';

export const ButtonsBarWrapper = withTheme(styled.div`
  display: flex;
  justify-content: ${props => props.flexEnd ? 'flex-end' : 'space-between'};
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  & button {
    margin-left: ${props => props.flexEnd ? '10px' : 0};
    &:hover {
      ${props => props.color === 'error' ? 'background: white; color: black;' : ''}
    }
  }  
`);
export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 46px;
  padding: 32px 32px;
  max-height: 60vh;
  overflow-y: auto;
`;
export const HeaderWrapper = withTheme(styled.div`
  background: ${props => {
      const color = get(props, 'color', 'primary');
      return get(props, `theme.palette.${color}.main`);
    }
  };
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: ${props => props.notCancelable ? 0 : '45px'};
`);
export const ModalClose = styled.div`
  cursor: pointer;
  padding-right: 27px;
  color: white; 
`;
export const TextContentWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
export const TextHeaderWrapper = styled.div`
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: white; 
`;
