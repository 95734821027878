export const registrationTheme = {
  height: '60px',
  fontSize: '16px',
  fontWeight: 400,
  borderRadius: '2px',
  Placeholder: {
    fontWeight: 200,
    main: {
      color: 'rgba(82, 83, 90, 0.7)'
    },
    error: {
      color: '#abadb6'
    },
    disabled: {
      color: 'rgba(82, 83, 90, 0.5)'
    },
    normal: {
      fontSize: '16px',
      height: '24px',
      top: '19px'
    },
    focused: {
      top: '11px',
      fontSize: '14px',
      height: '14px'
    }
  },
  InputWrap: {
    main: {
      background: '#FFFFFF',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'normal'
    },
    disabled: {
      background: '#ffffff',
      border: '1px solid rgba(196, 196, 196, 0.25)',
      cursor: 'not-allowed'
    },
    error: {
      background: '#FFEBEB',
      border: '1px solid #FF3C21',
      cursor: 'normal'
    },
    height: '60px',
    borderRadius: '2px',
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  InputElem: {
    main: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text'
    },
    disabled: {
      color: '#abadb6',
      placeholderColor: '#ACADB5',
      cursor: 'not-allowed'
    },
    error: {
      color: '#212C42',
      placeholderColor: '#ACADB5',
      cursor: 'text'
    },
    height: '24px',
    fontSize: '16px',
    fontWeight: 200,
    background: 'rgba(0,0,0,0)'
  }
};
