import { Value } from '../../fragments/Table/Cell/value';
import { IconWrapper, Link, NoWrap, OptionWrap } from '../../components/Grid/Grid.styled';
import { getIconNameByPackType } from '../../components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../constants/packTypes';
import React from 'react';
import { CIS_STATUS_NAMES } from '../../constants';
import moment from 'moment';
import { getIconNameByCISStatus } from '../Table/TableCells/CIS/utils';
import Tooltip from '@material-ui/core/Tooltip';
import { Translate } from '../Translate/Translate';

export const COLORS = {
  match: '#aed581',
  missingInScan: '#ffd740',
  missingInWaybill: '#f6685e',
};

export const columns = [
  // {
  //   id: 'action',
  //   accessor: row => (
  //     <div className={row.isInvalid ? 'errorRow' : ''}>
  //       <Value>{row.idx}</Value>
  //     </div>
  //   ),
  //   Header: '',
  //   disableFilters: true,
  //   width: 50
  // },
  {
    id:'status',
    accessor: row => row.error && row.error === 'notOwner' ? (<div />) : (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <Icon
          withoutMask
          type={getIconNameByCISStatus(row.status)}
          spacingRight={8}
        />
        <Value>{CIS_STATUS_NAMES[row.status] || '-'}</Value>
      </div>
    ),
    Header: Translate('Статус кода'),
    disableSortBy: true,
    minWidth: 250,
  },
  {
    id:'emissionDate',
    accessor: row => row.error && row.error === 'notOwner' ? (<div />) :(
      <div>
        <Value>{moment(row.emissionDate).format('DD.MM.YYYY') || '-'}</Value>
      </div>
    ),
    Header: Translate('Дата'),
    disableSortBy: true,
    minWidth: 120,
  },
  {
    id: 'cis',
    accessor: row => (
      <Link
        href={`/cis/list/${encodeURIComponent(row.cis)}`}
        onClick={e => {
          e.preventDefault();
          window.open(
            `/cis/list/${encodeURIComponent(row.cis)}`
          );
        }}
      >
        <NoWrap>{row.cis}</NoWrap>
      </Link>
    ),
    Header: Translate('Код'),
    disableFilters: true,
    minWidth: 250,

  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = row.cisPackageType.toLowerCase();
      return row.error && row.error === 'notOwner' ? (<div />) : (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon type={getIconNameByPackType(CODE)} fill="rgb(82, 83, 90)" size={16} spacingRight={12} />{' '}
          </IconWrapper>
          <NoWrap>{packTrivialTypesKZ[CODE]}</NoWrap>
        </OptionWrap>
      )
    },
    Header: Translate('Тип упаковки'),
    disableFilters: true,
    minWidth: 200,
  },
  {
    id:'name',
    accessor: row => row.error && row.error === 'notOwner' ? (<div />) : (
      <div>
        <Value>{row.name || '-'}</Value>
      </div>
    ),
    Header: Translate('Наименование товара'),
    disableSortBy: true,
    minWidth: 250,

  },
  {
    id:'gtin',
    accessor: row => row.error && row.error === 'notOwner' ? (<div />) : (
      <div>
        <Value>{row.gtin || '-'}</Value>
      </div>
    ),
    Header: Translate('Код товара (GTIN)'),
    disableSortBy: true,
    minWidth: 200,
  },
  {
    id:'producerName',
    accessor: row => row.error && row.error === 'notOwner' ? (<div />) : (
      <div>
        <Value>{row.producerName || '-'}</Value>
      </div>
    ),
    Header: Translate('Производитель'),
    disableSortBy: true,
    minWidth: 300,
  },
  {
    id:'ownerName',
    accessor: row => (
      <div>
        <Value>{row.ownerName || '-'}</Value>
      </div>
    ),
    Header: Translate('Владелец'),
    disableSortBy: true,
    minWidth: 300,
  },
  {
    id:'error',
    accessor: row => (
      <div>
        <Tooltip title="Delete">

        <Value>

          {ERROR_MESSAGES[row.error] || '-'}
        </Value>
        </Tooltip>

      </div>
    ),
    Header: Translate('Ошибка'),
    disableSortBy: true,
    minWidth: 400,
  },
];

export const ERROR_MESSAGES = {
  invalidStatusImport: Translate("Статус кода не соответствует 'Нанесен, оплачен'"),
  invalidStatusIntroduceGoods: Translate("Статус кода не соответствует 'Выведен из оборота, продан'"),
  invalidStatusMttps90: Translate('Статус кода не соответствует "Возвращен в оборот" и "В обороте" для типа упаковки Потребительская и Групповая, или "В обороте" для типа упаковки Транспортная'),
  invalidStatus: Translate("Неверный статус кода"),
  invalidStatusAct: Translate("Статус кода не соответствует 'Нанесен, оплачен' или 'В обороте' или 'Возвращен в оборот'"),
  notOwner: Translate("Код не принадлежит текущему участнику оборота (владелец кода другой участник)"),
};

export const IMPORT_TRANSIT_CORRECT_STATUSES = ['APPLIED_PAID', 'APPLIED'];
export const INTRODUCE_GOODS_CORRECT_STATUSES = ['WITHDRAWN'];
export const MTTPS_90_CORRECT_STATUSES = ['INTRODUCED_RETURNED', 'INTRODUCED', 'APPLIED'];
export const KZ_UPD_CORRECT_STATUSES = ['APPLIED_PAID', 'APPLIED', 'INTRODUCED_RETURNED', 'INTRODUCED'];
export const KZ_EXPORT_NOTICE_CORRECT_STATUSES = ['APPLIED', 'INTRODUCED', 'INTRODUCED_RETURNED'];
