import React from 'react';
import PropTypes from 'prop-types';
import { TwoLineLabel } from '@crpt/react-label';
import { FormLine } from '@crpt/react-form';
import styled from 'styled-components';
import { Translate } from '../../common_components/Translate/Translate';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    &:first-child {
      margin-right: 10px
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    border: none;
    background: #fff0;
    padding: 0;
    color: #ABADB6;
    font-size: 14px;
    font-weight: 200;
    line-height: 14px;
    margin-bottom: 4px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AttributeField = ({ label, value, onChange, tariffRateChangesHistory }) => {
  return (
    <FormLine>
      <ButtonWrap>
        <TwoLineLabel heading={typeof label === 'string' ? Translate(label) : label}>
          {typeof value === 'string' ? Translate(value) : value || '—'}
        </TwoLineLabel>
        <ButtonContainer>
          {
            onChange && (
              <button onClick={onChange}>{Translate('Изменить')}</button>
            )
          }
          {
            tariffRateChangesHistory && (
              <button onClick={tariffRateChangesHistory}>{Translate('История изменений')}</button>
            )
          }
        </ButtonContainer>
      </ButtonWrap>
    </FormLine>
  );
};

AttributeField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
};

export default AttributeField;
