import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get, cloneDeep } from 'lodash';
import { Toast } from '../../../../../utils/Toast';
import moment from 'moment';

import { replace } from 'react-router-redux';
import * as actions from './ImportDocument.actions';
import * as requestListActions from '../../ducks/RequestList.actions';
import * as cadesActions from '../../../../../common_components/Cades/Cades.actions';
import * as listActions from '../../../../../common_components/List/ducks/List.actions';
import Api from '../../../../../common_components/Api/Api';
import * as draftSelectors from '../../Draft/ducks/Draft.selectors';
import * as authSelectors from '../../../../../common_components/Auth/Auth.selectors';
import { prepareDateForJSON } from '../../../../../utils';
import { numbersToStrings, rubelsToKopecks } from '../../RequestList.utils';

function* mountSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id: payload },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
        __form_document_status: success.data.status,
      }),
    );
    yield put(actions.setError(success.data.downloadDesc));
  }
  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* uploadSaga(action, state) {
  const message = 'Файл заявки на ввод товара в оборот (импорт) загружен';
  const url = '/api/v3/facade/doc/upload';
  const userInfo = yield select(authSelectors.userInfo);

  const properties = {
    document_type: 'IMPORT_NOTIFICATION',
    user_id: userInfo.id,
    organisation_inn: userInfo.inn,
  };

  yield put(
    requestListActions.fileUpload({
      file: { ...action.payload, properties },
      message,
      url,
    }),
  );
}

function* submitSaga(action) {
  const { payload } = action;

  const url = '/api/v3/facade/doc/signed';

  const products = numbersToStrings(
    cloneDeep(payload.products).map(product => ({
      ...product,
      custom_cost: rubelsToKopecks(product.custom_cost) + '',
      item_price: rubelsToKopecks(product.item_price) + '',
      cis_tax: rubelsToKopecks(product.cis_tax) + '',
      vat_tax: rubelsToKopecks(product.vat_tax) + '',
    })),
  );

  const document = {
    ...payload,
    solution_date: prepareDateForJSON(payload.solution_date, 'DD.MM.YYYY'),
    registration_date: prepareDateForJSON(
      payload.registration_date,
      'DD.MM.YYYY',
    ),
    products: products,
  };

  delete document.cis;
  delete document.errorInfo;

  yield put(
    cadesActions.sign({ data: document }, { subject: actions.subject }),
  );

  const signed = yield take(`${cadesActions.signed} ${actions.subject}`);

  let data = {
    registration_number: document.registration_number,
    signature: signed.payload.signature,
    document: signed.payload.document,
    document_type: 'IMPORT',
  };

  const locationHref = window.location.href;
  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    data = {
      ...data,
      draft_id:
        drafttId ||
        locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, ''),
    };
  }

  const requestPayload = {
    url,
    method: 'post',
    data,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  if (success) {
    const error = get(success, 'data.errors.0', false);

    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Уведомление о ввозе товаров успешно отправлено.',
      });
      Toast.showInfo({
        content: 'Создание уведомления может занять некоторое время.',
        autoClose: 5000,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace('/documents/list'));
    }
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.upload, uploadSaga);
  yield takeEvery(actions.submit, submitSaga);
}
