import React from 'react';
import { ImportantBlock } from '../../ReportHelp.styled';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  afterTable: <ImportantBlock><strong>Важно!</strong> В отчёт выводятся первые 1000 строк</ImportantBlock>,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 100,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 280,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'ИНН/Наименование участника',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'ИД розничной торговой точки',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
      ]
    }
  ]
}