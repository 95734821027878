export const defaultTheme = {
  height: '60px',
  backgroundColor: '#FFFFFF',
  borderBottom: {
    width: '1px',
    color: '#e5e5e5',
  },
  ProfileLink: {
    color: '#434244',
  },
  innerBorder: {
    width: '1px',
    color: '#e5e5e5',
    paddingLeft: '20px',
  },
  userInfo: {
    width: '150px',
    menu: {
      backgroundColor: '#FFFFFF',
      color: '#434244',
      shadowX: '1px',
      shadowY: '2px',
      shadowBlur: '5px',
      shadowColor: 'rgba(0, 0, 0, 0.25)',
      item: {
        height: '46px',
        fontSize: '16px',
        cursor: 'pointer',
        hover: {
          backgroundColor: '#f7f7f7',
        },
      },
    },
  },
  navigationInfo: {
    width: '300px',
  },
};
