import React from 'react';
import TabButton from '../../../../common_components/Buttons/TabButton';
import { Translate } from '../../../../common_components/Translate/Translate';

export const AppendButton = props => (
  <TabButton
    icon="add-plus"
    fill="#434343"
    color="#434343"
    marginRight={16}
    {...props}
  >
    {Translate('Добавить')}
  </TabButton>
);

export const EditButton = props => (
  <TabButton
    icon="edit"
    fill="#55B5E7"
    color="#55B5E7"
    marginRight={16}
    {...props}
  >
    {Translate('Редактировать')}
  </TabButton>
);

export const DeleteButton = props => (
  <TabButton
    icon="pan"
    fill="#55B5E7"
    color="#55B5E7"
    marginRight={16}
    {...props}
  >
    {Translate('Удалить')}
  </TabButton>
);
