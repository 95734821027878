import * as _ from 'lodash';
import moment from 'moment';
import {
  formatEnum,
  periodicityEnum,
  taskTypeEnum,
} from '../../constants';

export const adaptBody = payload => {
  let data = {};

  data.name = payload.name ? payload.name.trim() : '';
  data.systemName = payload.meta.systemName;
  data.taskTypeId = payload.meta.taskTypeId;

  data.format = formatEnum.CSV;
  data.periodicity = payload.periodicity;

  payload.meta.reportId ? (data.reportId = payload.meta.reportId) : null;

  const params = payload.meta.params;
  !_.isEmpty(params) ? (data.params = JSON.stringify(params)) : null;

  const locationOrigin = window.location.origin;
  const absolutePath = '/unload-service/list/'
  data.frontendTaskUrl = /localhost/.test(locationOrigin)
    ? `https://qa04.gismt.crpt.tech${absolutePath}`
    : `${locationOrigin}${absolutePath}`;

  // if (
  //   data.taskTypeId === taskTypeEnum.REPORT &&
  //   data.periodicity === periodicityEnum.SINGLE
  // ) {
  //   data.dataStartDate = params['0202_IC_Date_From'];
  //   data.dataEndDate = params['0203_IC_Date_To'];
  // }

  if (
    data.taskTypeId !== taskTypeEnum.REPORT &&
    data.periodicity === periodicityEnum.SINGLE
  ) {
    const startDate = _.get(payload, 'dataPeriod.startDate', new Date());
    const endDate = _.get(payload, 'dataPeriod.endDate', new Date());

    data.dataStartDate = moment(startDate).format('YYYY-MM-DD');
    data.dataEndDate = moment(endDate).format('YYYY-MM-DD');
  }

  if (data.periodicity === periodicityEnum.REGULAR) {
    data.period = payload.period;

    const hour = moment().hour() + 1;
    // startDate не может быть раньше текущего времени. Проверка до секунды
    const startDate = _.get(payload, 'departurePeriod.startDate', new Date());
    const endDate = _.get(payload, 'departurePeriod.endDate', new Date());

    data.startDate = moment(startDate)
      .hour(hour)
      .toISOString();
    data.endDate = moment(endDate)
      .hour(hour)
      .toISOString();
  }

  const users = _.uniq(
    _.get(payload, 'users', [])
      .map(item => item.user)
      .filter(item => item),
  );
  if (users.length) {
    data.users = users;
  }

  !_.isEmpty(payload.sharedRoles)
    ? (data.sharedRoles = payload.sharedRoles)
    : null;

  return data;
};
