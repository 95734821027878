import { ProductionLinesColumns } from 'src/fragments/Table/Cell/ProductionLines/columns.constants';

export const columns = [
  {
    ...ProductionLinesColumns.placeName,
  },
  {
    ...ProductionLinesColumns.countryName,
  },
  {
    ...ProductionLinesColumns.line,
  },
];