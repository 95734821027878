import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from '../_refactor/Select/SingleSelect';
import { instance } from '../../../../services/instance';
import HintedOption from './HintedOption';
import { get } from 'lodash';
import { hasOnlyDigits } from '../../../../utils';

const { CancelToken } = axios;

class CustomsCodeSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    values: PropTypes.string,
    selectedId: PropTypes.number,
    errorPlaceholder: PropTypes.string,
  };

  static defaultProps = {
    onChange: undefined,
  };

  static cancelToken;

  state = {
    loading: false,
    values: undefined,
    preventUpdate: false,
    isError: false,
    placeholder: '',
    inputValue: '',
    selectedValue: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.preventUpdate) return null;

    if (props.values !== state.values) {
      return {
        values: props.values,
        placeholder: props.placeholder,
      };
    }

    return null;
  }

  onChange = (val, e) => {
    const { onChange, onValues } = this.props;
    const isError = !hasOnlyDigits(val) || val.length > 8;

    this.setState({
      isError: isError,
      placeholder: '',
      inputValue: val,
    });

    if (onChange) onChange(val);
    else {
      // const values = val ? [
      //   {
      //     type: val,
      //     id: 0,
      //     title: val,
      //     code: val,
      //   },
      // ] : this.state.values;

      this.setState({
        loading: true,
        preventUpdate: true,
        // values: values,
      });

      CustomsCodeSelect.cancelToken && CustomsCodeSelect.cancelToken();

      instance
        .request({
          url: `/api/v3/facade/custom/search?${
            parseInt(val) ? `code=${val}` : `name=${encodeURIComponent(val)}`
          }`,
          method: 'get',
          cancelToken: new CancelToken(c => {
            CustomsCodeSelect.cancelToken = c;
          }),
        })
        .then(answer => {
          const values = answer.data.result.map((pr, i) => ({
            type: pr.code,
            id: i + 1,
            title: `${pr.name}, код ${pr.code}`,
            code: pr.code,
          }));

          if(onValues){
            onValues(values);
          }

          this.setState({
            loading: false,
            preventUpdate: true,
            // values: [
            //   ...values,
            //   // {
            //   //   type: val,
            //   //   id: values.length + 1,
            //   //   title: val,
            //   //   code: val,
            //   // },
            // ],
          });
        })
        .catch(e => null);
    }
  };

  render() {
    const { onChange, ...passProps } = this.props;
    const { /*values,*/ loading, placeholder, isError } = this.state;

    return (
      <SingleSelect
        {...passProps}
        isError={isError || passProps.isError}
        placeholder={placeholder || passProps.placeholder}
        filterDisable
        truncate
        renderOption={HintedOption}
        onChange={this.onChange}
        isLoading={loading}
        //values={values}
      />
    );
  }
}

export default CustomsCodeSelect;
