export const usualTheme = {
  backRing: {
    innerRadius: 39,
    outerRadius: 49,
    fill: 'rgba(159, 159, 159, 0.37)',
  },
  frontRing: {
    innerRadius: 35,
    outerRadius: 53,
    fill: '#67ac5c',
    rotationDeg: 270,
    shadowColor: '#C4C4C4',
    shadowOffset: {
      x: 3,
      y: 3,
    },
    shadowBlur: 5,
    shadowOpacity: 0.7,
  },
  frontRingShadowWidth: 10,
  percentText: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: '"SegoeUI", sans-serif',
    fill: '#9f9f9f',
  },
  percentSign: {
    fontSize: 14,
    fontFamily: '"SegoeUI", sans-serif',
    fill: '#9f9f9f',
  },
};
