import React  from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter, { registerLanguage } from 'react-syntax-highlighter/light';
import xml from 'react-syntax-highlighter/languages/hljs/xml';
import vs from 'react-syntax-highlighter/styles/hljs/vs';
import { PreviewWrapper } from '../FilePreview.styled';

registerLanguage('xml', xml);


const XmlFilePreview = ({ content }) => (
  <PreviewWrapper>
    <SyntaxHighlighter language="xml" style={vs}>
      {content}
    </SyntaxHighlighter>
  </PreviewWrapper>
);

XmlFilePreview.propTypes = {
  content: PropTypes.string.isRequired,
};

export default XmlFilePreview;
