import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const IconWrap = styled.div`
  height: 129px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Outer = styled.div`
  display: table-row;
  height: auto;
  background: #fcfcfc;
`;

export const Inner = styled.div`
  display: table-cell;
  height: auto;
  box-sizing: border-box;
`;

export const EnterWrap = styled.div`
  line-height: 32px;
  font-size: 32px;
  color: #52535a;
  margin-bottom: 26px;
`;

export const ContentWrap = styled.div`
  margin-top: -100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const SelectWrap = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 53px 50px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;

export const BottomInfo = styled.div`
  margin-top: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  color: #52535a;
  font-weight: 300;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #55b5e7;
  font-weight: 400;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const Text = styled.p`
  text-decoration: none;
  color: #55b5e7;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const WrapCheckStatusButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 0 0;
`;

export const Button = styled.div`
  height: 40px;
  background: ${({ block }) =>
    !block ? '#ffffff' : 'linear-gradient(0deg,#C4C4C4,#C4C4C4)'};
  border-radius: ${({ block }) => (block ? '0' : '3px')};
  box-shadow: ${({ block }) =>
    block
      ? 'none'
      : '0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1)'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ block }) => (block ? '#FFFFFF' : '#52535A')};
  height: 40px;
  font-family: 'SegoeUI', sans-serif;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  min-width: 217px;
  padding: 0 19px;
  margin: 0 56px 0 0;
  cursor: ${({ block }) => (block ? ' not-allowed' : 'pointer')};
`;

export const ErorText = styled.p`
  display: flex;
  color: #ff3c21;
  margin: 16px 0 0 0;
`;

export const StyledA = styled.a`
  text-decoration: none;
  color: #55b5e7;
  font-weight: 400;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const LanguageWrap = styled.div`
  position: absolute;
  top: 20px;
  right: 45px;
  width: 40px;  
`;
