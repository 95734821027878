import React, {Component, Suspense, lazy} from 'react';
import moment from 'moment';
import 'datejs';
import {Card, CardLine} from '@crpt/react-card';
const Graph = lazy(() => import('./NDSGraph/components/Graph'));
import {Preloader} from '../../common_components/Preloader/Preloader';
import {Header} from './NDSGraph/styled/Header';
import {MainContainer} from './styled/MainContainer';
import {ParticipantService} from '../../services/ParticipantService';
import {TaxService} from './services/TaxService';
import { StickContentWrapper } from '../common/styled';


class NDS extends Component {
  constructor(props) {
    super(props);

    const data = {};

    [0, 1, 2, 3].forEach((v, i) => {
      const key = moment()
        .add(-i, 'month')
        .format('MM.DD.YYYY');
      data[key] = [];
    });

    console.log('data', data);

    this.state = {
      data
    };
  }
  // eslint-disable-next-line
  componentWillMount() {
    //const token = localStorage.getItem("token");
    const id = +window.location.pathname.split('/')[3];
    let date = null;

    Object.keys(this.state.data).forEach(v => {
      const _tmpdate = moment(Date.parse(v));

      if (!date) return (date = _tmpdate);

      if (date.isBefore(_tmpdate, 'month')) date = _tmpdate;
    });

    date = date.format('MM.DD.YYYY');

    const dateFrom = moment(date)
      .add(-(Object.keys(this.state.data).length - 1))
      .startOf('month')
      .unix();
    const dateTo = moment(date)
      .endOf('month')
      .unix();

    ParticipantService.getParticipantInfo(id).then(answerP => {
      console.log('participant info', answerP);
      TaxService.getTaxInfo({
        dateFrom,
        dateTo,
        participantInn: answerP.data.inn
      }).then(answerT => {
        console.log('answer tax', answerT);

        const data = this.state.data;

        data[date] = [
          { vertSync: 0 },
          { vertSync: 1 },
          { vertSync: 2 },
          {
            vertSync: 3,
            vat: +(answerT.data.vat + '')
              .split('')
              .reverse()
              .map((v, i) => (i === 1 ? '.' + v : v))
              .reverse()
              .join(''),
            vatReturn: +(answerT.data.vatReturn + '')
              .split('')
              .reverse()
              .map((v, i) => (i === 1 ? '.' + v : v))
              .reverse()
              .join('')
          }
        ];

        this.setState({
          data
        });
      });
    });
  }

  render() {
    return (
      <div>
        <MainContainer>
          <Header>{Translate('НДС (исчисленный+вычет)')}</Header>
          <CardLine>
            <Card>
              <Suspense fallback={<Preloader isOpened={true} text="Загрузка"/>}>
                <Graph data={this.state.data} dotsOnly />
              </Suspense>
            </Card>
          </CardLine>
        </MainContainer>
      </div>
    );
  }
}

export default NDS;
