import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { isString, isUndefined } from 'lodash';
import { DatePicker } from '@crpt/react-datepicker';
import { Wrap } from '../Fields.styled';

const PureDatePicker = memo(DatePicker);

class DatePickerAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onUpdate: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
  };

  state = {
    value: undefined,
    date: undefined,
  };

  componentDidMount() {
    const { defaultValue, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value) && !isUndefined(defaultValue)) {
      setTimeout(() => formMutators.updateField(name, defaultValue), 0);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fieldInput.value !== state.value) {
      const {
        fieldInput = {},
        dateFormat,
        defaultValue,
        parseDateFormat,
      } = props;
      const { value } = fieldInput;
      const parseFormat = parseDateFormat || dateFormat;
      const newValue = value || defaultValue;

      let date;

      if (isString(newValue)) {
        date = moment(
          newValue,
          parseFormat || (newValue.length === 10 ? 'DD.MM.YYYY' : undefined)
        ).format(dateFormat || 'DD.MM.YYYY');
      } else {
        date = newValue
          ? moment(newValue).format(dateFormat || 'DD.MM.YYYY')
          : undefined;
      }

      return {
        value,
        date,
      };
    }

    return null;
  }

  onUpdate = val => {
    const { fieldInput, onUpdate } = this.props;
    const date = val && val.date;

    const newVal = (date && date.toISOString()) || undefined;

    if (fieldInput) fieldInput.onChange(newVal);
    if (onUpdate) onUpdate(newVal);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError &&
      fieldMeta &&
      fieldMeta.dirtySinceLastSubmit &&
      !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const {
      placeholder,
      errorPlaceholder,
      index,
      total,
      fieldMeta,
    } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dateFormat,
      placeholder,
      errorPlaceholder,
      location,
      ...passProps
    } = this.props;
    const { date } = this.state;

    const customStyleIfValid =
      formValues[this.props.name] &&
      !!formValues[this.props.name].length &&
      !formErrors[this.props.name]
        ? true
        : false;

    return (
      <Wrap
        onBlur={fieldInput && fieldInput.onBlur}
        marginBottom={marginBottom}
        location={location}
        customStyleIfValid={customStyleIfValid}
      >
        <PureDatePicker
          savePlaceholder
          {...passProps}
          placeholder={this.getPlaceholder()}
          isError={this.isError()}
          format={dateFormat || 'DD.MM.YYYY'}
          onUpdate={this.onUpdate}
          inline={false}
          date={date}
        />
      </Wrap>
    );
  }
}

export default DatePickerAdapter;
