import { colors } from './constants';
import { default as Excise } from '../../selectors/Excise';

import * as selectors from '../../selectors';
import { get } from 'lodash';

export function prepareValuesForDiagram(stat) {
  const {
    total,
    cnt_emitted,
    cnt_applied,
    cnt_sold,
    cnt_not_sold,
    cnt_withdrawn
  } = stat;

  const cnt_emitted_percent = parseFloat(
    (cnt_emitted / total * 100).toFixed('2')
  );
  const cnt_applied_percent = parseFloat(
    (cnt_applied / total * 100).toFixed('2')
  );
  const cnt_sold_percent = parseFloat((cnt_sold / total * 100).toFixed('2'));
  const cnt_not_sold_percent = parseFloat(
    (cnt_not_sold / total * 100).toFixed('2')
  );
  const cnt_withdrawn_percent = parseFloat(
    (cnt_withdrawn / total * 100).toFixed('2')
  );
  return [
    { color: colors.cnt_emitted, percent: cnt_emitted_percent },
    { color: colors.cnt_applied, percent: cnt_applied_percent },
    { color: colors.cnt_sold, percent: cnt_sold_percent },
    { color: colors.cnt_not_sold, percent: cnt_not_sold_percent },
    { color: colors.cnt_withdrawn, percent: cnt_withdrawn_percent }
  ];
}

export function paramsFormatter(params, getState) {
  const filterParams = Excise.filterParams(getState());
  const newParams = Object.assign({}, filterParams, params);
  console.log('paramsFormatter Excise', newParams);

  if (undefined === params.gtin) delete newParams.gtin;
  if (undefined === params.packageType) newParams.packageType = 'pack';
  if (undefined === params.status || newParams.status === 'ALL')
    delete newParams.status;
  if (newParams.isMonth === false) delete newParams.month;
  return newParams;
}
