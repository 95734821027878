import React, { useMemo } from 'react';
import { PageWrapper, TableWrap } from './Esf.styled';
import Datatable from '@crpt-ui/datatable';
import { codesColumns } from './Esf.constants';

export const EsfCodes = ({ selectedDocument }) => {
  const codes = useMemo(() => {
    const products = selectedDocument.products || [];
    return products.reduce((acc, item) => {
      const marks = item.marks || [];
      return [...acc, ...marks]
    }, [])
  }, [selectedDocument]);

  return (<PageWrapper>
    <TableWrap>
      <Datatable columns={codesColumns} useSort={true} data={codes} />
    </TableWrap>
  </PageWrapper>)
};
