import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import * as actions from './ducks/FilePreview.actions';
import * as selectors from './ducks/FilePreview.selectors';
import FilePreviewView from './FilePreview.view';
import XmlFilePreview from './Viewers/XmlFilePreview';
import CsvFilePreview from './Viewers/CsvFilePreview';
import XlsxFilePreview from './Viewers/XlsxFilePreview';
import { FileInput } from './FilePreview.styled';
import { documentTypeNames } from '../../constants/documentTypes';

class FilePreview extends React.Component {
  static propTypes = {
    contentType: PropTypes.string,
    content: PropTypes.string,
    warning: PropTypes.string,
    docType: PropTypes.string,
    docNumber: PropTypes.string,
    status: PropTypes.string,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    types: PropTypes.string.isRequired,
    openFileDialog: PropTypes.bool.isRequired,
    openFile: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired
  };

  state = {
    fileInputKey: Date.now(),
  }

  static defaultProps = {
    contentType: undefined,
    content: undefined,
    status: undefined,
    docNumber: undefined,
    docType: undefined,
  };

  fileInput;

  fileName;

  componentDidUpdate(prevProps) {
    const { openFileDialog, isUpdated } = this.props;
    if (openFileDialog && openFileDialog !== prevProps.openFileDialog) {
      if (this.fileInput) this.fileInput.click();
    }
    isUpdated();
  }

  resetInput = () => {
    this.setState({
      fileInputKey: Date.now()
    });
  };

  onBlockerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onCancel = () => {
    const { onCancel, onClose } = this.props;
    onCancel();
    onClose();
    this.resetInput();
  };

  onAccept = () => {
    const { content, contentType, onAccept, onClose, file, data } = this.props;
    onAccept({ content, contentType, file, data });
    onClose();
    this.resetInput();
  };

  onFileInputChange = (e) => {
    const { openFile } = this.props;
    e.preventDefault();

    const file = get(e, 'target.files.0');
    if (file) {
      openFile(file);
      this.fileName = file.name;
    }
  };

  setFileInput = (ref) => {
    this.fileInput = ref;
  };

  render() {
    const { fileInputKey } = this.state;
    const { contentType, docNumber, content, status, warning, types, loaded, docType } = this.props;

    return (
      <React.Fragment>
        {contentType && (
          <FilePreviewView
            loaded={loaded}
            onBlockerClick={this.onBlockerClick}
            onCancel={this.onCancel}
            onAccept={this.onAccept}
            warning={warning}
            docType={docType}
            docNumber={docNumber}
            status={status}
            title={(loaded ? `${get(documentTypeNames, docType, '')} - ${contentType}` : this.fileName)}
          >
            {contentType === 'XML' && <XmlFilePreview content={content} />}
            {contentType === 'CSV' && <CsvFilePreview content={content} />}
            {contentType === 'XLSX' && <XlsxFilePreview content={content} />}
            {contentType === 'XLS' && <XlsxFilePreview content={content} />}
          </FilePreviewView>
        )}
        <FileInput
          key={fileInputKey}
          innerRef={this.setFileInput}
          type="file"
          accept={types}
          onChange={this.onFileInputChange}
        />
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  content: selectors.content(state),
  file: selectors.file(state),
  data: selectors.data(state),
  docNumber: selectors.docNumber(state),
  status: selectors.status(state),
  contentType: selectors.contentType(state),
  onAccept: selectors.getOnAccept(state),
  onCancel: selectors.getOnCancel(state),
  types: selectors.types(state),
  openFileDialog: selectors.openFileDialog(state),
  loaded: selectors.loaded(state),
  warning: selectors.warning(state),
  docType: selectors.docType(state),
});

const mapDispatch = dispatch => ({
  openFile: file => dispatch(actions.openFile(file)),
  onClose: () => dispatch(actions.close()),
  isUpdated: () => dispatch(actions.isUpdated()),
});

export default connect(
  mapState,
  mapDispatch
)(FilePreview);
