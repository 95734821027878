import { actionTypes } from '../../constants';

export function reportData(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.REPORT_LIST.REPORT_LIST_MOUNTED:
      return action.payload.reportList;
    default:
      return state;
  }
}
