import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { compose } from "redux";
import Grid from '@material-ui/core/Grid';
import * as Icons from '@crpt-ui/icons';
import { Button } from '@crpt-ui/core';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider } from "@material-ui/core";
import {get, isEmpty, isNil} from "lodash";
import { StepTitle } from '../Document/Document.styled';
import {AggregationUnitsError, InfoFieldsWrapper, UploadBtnWrap} from "./InfoFields.styled";
import {
    validateRequiredAndLength,
    validateRequiredAndMaxMin,
    validateIsNumber,
    pipe
} from '../../../../common_components/Form/utils/validations';
import { validateDate } from "../ImportDocument/ImportDocument.validations";
import { MaterialUiThemeUZ } from "../../../../common_components/_MaterialUi/ThemeUZ";
import PackagingCode from "./PackagingCode";
import DatePicker from "../../../../common_components/Form/MaterialFields/DatePicker/DatePicker";
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import AddIdentificationCodes from "../../../../common_components/AddIdentificationCodes/AddIdentificationCodes";
import { withModal } from "../../../../common_components/Modals/hocs/withModal";
import ConfirmModal from "../../../../common_components/Modals/ConfirmModal/ConfirmModal";
import { ContentErrorLoading } from "../NotificationOfEntry/Goods/Goods.constants";

export const invalidCisKey = 'invalidCis';
const aggregationUnitCapacityMax = 2147483648;
const aggregationUnitCapacityMin = 0;

const InfoFields = (props) => {
    const { onOpenModal, onSetContent, formMutators } = props;
    const { onCloseModal, formValues, finalConfirm, formErrors } = props;
    const [openPackagingCode, setOpenPackagingCode] = useState(false);
    const { t } = useTranslation();
    const { updateField } = formMutators || {};
    const sntins = get(formValues, 'aggregationUnits[0].sntins', null);
    const aggregatedItemsCount = get(formValues, 'aggregationUnits[0].aggregatedItemsCount', null);

    useEffect(()=> {
        if (sntins && aggregatedItemsCount !== sntins.length) {
            updateField('aggregationUnits[0].aggregatedItemsCount', sntins.length)
            updateField(
                'aggregationUnits[0].aggregationUnitCapacity',
                get(formValues, 'aggregationUnits[0].aggregationUnitCapacity', undefined)
            )
        }
    },[sntins, aggregatedItemsCount])

    const openLoadCisListModal = useCallback(() => {
        onSetContent(
            <AddIdentificationCodes
                codesKey="aggregationUnits[0].sntins"
                formMutators={formMutators}
                onCloseModal={onCloseModal}
                types=".xls, .xlsx"
            />
        );
        onOpenModal();
    },[onSetContent, onOpenModal, formMutators, onCloseModal])

    const aggregationUnitsError = useMemo(()=> {
        const aggregationUnitsVal = get(formValues, 'aggregationUnits[0].aggregationUnitCapacity', null)
        if (!isNil(aggregationUnitsVal) && aggregationUnitsVal !== "") {
            if (aggregationUnitsVal <= aggregationUnitCapacityMin) {
                return `${t('Не должно быть меньше')} ${aggregationUnitCapacityMin + 1}`
            }
            if (aggregationUnitsVal >= aggregationUnitCapacityMax) {
                return `${t('Не должно быть больше')} ${aggregationUnitCapacityMax - 1}`
            }
            if (!isNil(aggregatedItemsCount) && (aggregationUnitsVal < aggregatedItemsCount)) {
                return `${t('Не должно быть меньше')} ${aggregatedItemsCount}`
            }
        }
        return null
    },[formValues, formErrors, t])

    const isInvalidCisShow = useMemo(()=> !isEmpty(formValues.invalidCis),[formValues]);

    const contentErrorLoading = useMemo(()=> (
        <ContentErrorLoading>
            {
                formValues.invalidCis
                    ? formValues.invalidCis.map(item => <div>Код: {item}</div>)
                    : ''
            }
            {t('Загрузить другой файл?')}
        </ContentErrorLoading>
    ),[formValues, t]);

    return (
        <InfoFieldsWrapper>
            <MuiThemeProvider theme={MaterialUiThemeUZ}>
            <PackagingCode
                updateField={updateField}
                isOpen={openPackagingCode}
                onCancel={()=> setOpenPackagingCode(false)}
            />
                <ConfirmModal
                    isModalOpen={isInvalidCisShow}
                    onClose={() => formMutators.updateField(invalidCisKey, [])}
                    onSubmit={() => {
                        openLoadCisListModal();
                        // this.props.onReset();
                        formMutators.updateField(invalidCisKey, []);
                    }}
                    content={contentErrorLoading}
                    header={t('Ошибка')}
                    cancelButtonText={t('Отмена')}
                    submitButtonText={t('Загрузить файл')}
                    color={'error'}
                />
            <Grid container>
                <Grid>
                    <StepTitle noMarginTop>{t('Общие данные')}</StepTitle>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input
                        name="participantId"
                        placeholder={t('ИНН/ПИНФЛ УОТ')}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="submissionMethodTypeName"
                        placeholder={t('Способ подачи документа')}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DatePicker
                        disabled
                        name="dateDoc"
                        placeholder={t('Дата и время агрегации')}
                        maxDate={new Date()}
                        validate={(...vals) => {
                            return validateDate(...vals, 'DD.MM.YYYY');
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid>
                    <StepTitle noMarginTop>{t('Данные агрегации')}</StepTitle>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input
                        forceValidate
                        name="aggregationUnits[0].aggregationUnitCapacity"
                        placeholder={t('Емкость упаковки')}
                        required
                        disabled={finalConfirm}
                        type="number"
                        format={(value)=> value ? parseInt(value.replace(/[^\d]/g, ''), 10) : value}
                        validate={
                            pipe(
                                validateRequiredAndMaxMin(aggregationUnitCapacityMax, aggregationUnitCapacityMin),
                                ...!isNil(sntins) ? [validateRequiredAndMaxMin(aggregationUnitCapacityMax, sntins.length - 1)] : [],
                                validateIsNumber
                            )}
                    />
                    {aggregationUnitsError && (
                        <AggregationUnitsError>{aggregationUnitsError}</AggregationUnitsError>
                    )}
                </Grid>
                <Grid item xs={5}>
                    <Input
                        forceValidate
                        name="aggregationUnits[0].unitSerialNumber"
                        placeholder={t('Код транспортной упаковки')}
                        required
                        disabled={finalConfirm}
                        InputProps={{
                            inputProps: {
                                maxLength: 20,
                            }
                        }}
                        validate={pipe(
                            validateRequiredAndLength(20),
                            validateIsNumber
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={finalConfirm}
                        onClick={()=> setOpenPackagingCode(true)}
                    >
                        {t('Получить код упаковки')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input
                        forceValidate
                        name="aggregationUnits[0].aggregatedItemsCount"
                        placeholder={t('Фактическое количество в упаковке')}
                        disabled
                        required
                        validate={validateRequiredAndMaxMin(11001, 0)}
                    />
                    <div>
                        {t('Файл должен быть в формате "XLS" или "XLSX" и содержать не более 11 000 кодов')}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <UploadBtnWrap>
                        <Button
                            onClick={openLoadCisListModal}
                            disabled={finalConfirm}
                        >
                            <Icons.Download />
                        </Button>
                    </UploadBtnWrap>
                </Grid>
            </Grid>
            </MuiThemeProvider>
        </InfoFieldsWrapper>
    );
};

export default compose(withModal)(InfoFields);
