import axios from 'axios';
import { authInstance } from './authInstance';
import Qs from 'qs';
// TODO проверить searchByInput когда будут данные и поменять url

const CancelToken = axios.CancelToken;

export class ProductService {
  static _searchProductCancel;
  static _searchBrandCancel;
  static _getListCancel;
  static _tnVedCodeCancel;

  static changeRate (data) {
    return authInstance.request({
      url: '/api/v3/facade/product/rates',
      method: 'post',
      params: data
    });
  }

  static tariffRateChangesHistory (gtin) {
    return authInstance.request({
      url: `/api/v3/product/rates/history/${gtin}`,
      method: 'get',
    });
  }

  static getList(params) {
    const data = Object.assign({ needTotal: true }, params);

    if (data.hasOwnProperty('packageType')) {
      data.package_type = data.packageType;
      delete data.packageType;
    }

    if (ProductService._getListCancel)
      ProductService._getListCancel('PREVENT_TOAST');

    return authInstance.request({
      url: '/api/v3/facade/product/search',
      method: 'get',
      params: data,
      paramsSerializer: function (params) {
        return Qs.stringify(params, {arrayFormat: 'repeat'})
      },
      cancelToken: new CancelToken(function(c) {
        ProductService._getListCancel = c;
      }),
    });
  }

  static searchByBrand(input) {
    if (ProductService._searchBrandCancel)
      ProductService._searchBrandCancel('PREVENT_TOAST');

    return authInstance.request({
      url: `/api/v3/product/filters/brand?input=${encodeURIComponent(
        input
      )}`,
      method: 'get',
      cancelToken: new CancelToken(function(c) {
        ProductService._searchBrandCancel = c;
      })
    });
  }

  static getTnVedCodes() {
    if (ProductService._tnVedCodeCancel)
      ProductService._tnVedCodeCancel('PREVENT_TOAST');

    return authInstance.request({
      url: '/api/v3/facade/product/tn-ved',
      method: 'get',
      cancelToken: new CancelToken(function(c) {
        ProductService._tnVedCodeCancel = c;
      })
    });
  }

  static getProductDetails(id, producerId) {
    return authInstance.request({
      url: `/api/v3/product/${id}` + (producerId ? `?producerId=${producerId}` : ''),
      method: 'get'
    });
  }

  static downloadProductListCsv (filterParams) {
    return authInstance.request({
      url: '/api/v3/facade/product/csv',
      method: 'get',
      type: 'csv',
      responseType: 'arraybuffer',
      params: filterParams,
      paramsSerializer: function (params) {
        return Qs.stringify(params, {arrayFormat: 'repeat'})
      },
    });
  }

  static upProductListCsv (files, saveUnregistred) {
    const formData = new FormData();
    formData.append('content', files);
    formData.append('saveUnregistred', saveUnregistred);
    return authInstance.request({
      url: '/api/v3/facade/product/rates/csv',
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  static getProductDetailsById(id) {
    return authInstance.request({
      url: `/api/v3/facade/product/${id}`,
      method: 'get'
    })
  }

  static searchByInput(input) {
    if (ProductService._searchProductCancel)
      ProductService._searchProductCancel();

    return authInstance.request({
      url: `/api/v3/product/filters/products?input=${encodeURIComponent(
        input
      )}`,
      method: 'get',
      cancelToken: new CancelToken(function(c) {
        ProductService._searchProductCancel = c;
      })
    });
  }

  static removeProduct(id) {
    return authInstance.request({
      method: 'delete',
      url: `/api/v3/product/${id}`
    });
  }

  static updateProduct(product) {
    return authInstance.request({
      method: 'put',
      url: `/api/v3/product/`,
      data: product
    })
  }

  static createProduct(data) {
    return authInstance.request({
      url: '/api/v3/product/',
      method: 'post',
      data
    });
  }

  static uploadProductListCsv(data) {
    return authInstance.request({
      url: '/api/v3/facade/product/csv-rates',
      method: 'post',
      data
    });
  }
}
