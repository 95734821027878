import React, { Fragment } from 'react';
import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { get } from 'lodash';
import AttributeField from './AttributeField';
import { Translate } from '../../common_components/Translate/Translate';
import MilkAdditionalInfo from './milk/AdditionalInfo';
import ShoesAdditionalInfo from './shoes/ShoesAdditionalInfo';

const EAESProductAdditionalInfo = props => {
  const { selectedProduct } = props;

  const productGroupId = +get(selectedProduct, 'productGroupId', 0);
  const alcoholVol = get(selectedProduct, 'alcoholVol', false);
  const packUnitVolume = get(selectedProduct, 'packUnitVolume', false);
  const isAlcohol = productGroupId === 11 || productGroupId === 15;
  const isMilk = productGroupId === 8;
  const isShoes = productGroupId === 2;

  const alcoholAttributes = (
    <Fragment>
      <AttributeField
        label={
          <Fragment>
            {Translate('Объем продукции в единице потребительской упаковки')}
            <br />
            {Translate('(л, сл, мл, дм\u00B3, см\u00B3)')}
          </Fragment>
        }
        value={packUnitVolume}
      />
      <AttributeField
        label={Translate('Объемная доля этилового спирта (крепость)')}
        value={alcoholVol}
      />
    </Fragment>
  );

  return (
    <Card>
      <Container>
        <Column>
          {isAlcohol && alcoholAttributes}
          {isMilk && <MilkAdditionalInfo selectedProduct={selectedProduct} />}
          {isShoes && <ShoesAdditionalInfo selectedProduct={selectedProduct} />}
        </Column>
      </Container>
    </Card>
  );
};

export default EAESProductAdditionalInfo;
