import styled, { css } from 'styled-components';

export const Timezone = styled.div`
  color: #abadb6;
`;

export const Wrap = styled.div`
  min-width: ${props => props.minWidth};
  margin-bottom: ${props => props.marginBottom || '16px'};
  margin: ${({ margin }) => margin};
  white-space: normal;
  position: relative;
  & label {
    & span {
      font-size: 17px;
      color: #212C42;
      margin-top: 10px;
    }
  } 
  ${props => props.location === 'delegation' && props.customStyleIfValid 
  ? css`
    & div[class^="Placeholder__Elem"] { color: #9F9F9F; }
  `
  : null
  }
  ${
    props => props.isError
      ? css`
        & label {
          border-left-width: 3px;
          background: transparent;
        }
      `
      : css`
        & label {
          border-left-width: 3px;
          border-left-color: #60e84a;
        }
      `
  } 
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  fontWeight: 700;
  transform: translateY(-50%);
  cursor: pointer;
`;
