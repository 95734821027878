/*
 * http://auth.apps.qa.lp.crpt.tech/swagger-ui.html#/user-role-controller
 * Свагер для ролей
 **/

export const permissionConfig = {
    draft: {
      canAccess: ['ROLE_ORG_RETAIL', 'ROLE_ORG_WHOLESALER', 'ROLE_ORG_IMPORTER', 'ROLE_ORG_PRODUCER', 'ROLE_ORG_MEDICAL'],
    },
    outgoing: {
      canAccess: ['ROLE_ORG_RETAIL', 'ROLE_ORG_WHOLESALER', 'ROLE_ORG_IMPORTER', 'ROLE_ORG_PRODUCER', 'ROLE_ORG_MEDICAL'],
    },
    incoming: {
      canAccess: ['ROLE_ORG_RETAIL', 'ROLE_ORG_WHOLESALER', 'ROLE_ORG_IMPORTER', 'ROLE_ORG_PRODUCER', 'ROLE_ORG_MEDICAL'],
    },
  };
