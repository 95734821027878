import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  padding-right: 10px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  font-family: "SegoeUI", sans-serif !important;
`;

