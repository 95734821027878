export const PRELOAD = 0;
export const LOADING = 1;
export const CONVERTING = 2;
export const READY_TO_SIGN = 3;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  CONVERTING,
  READY_TO_SIGN,
  ERROR
};
