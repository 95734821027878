export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': 'Тип участника'
        },
        {
          'col1': [2],
          'col2': 'ИНН владельца товара'
        },
        {
          'col1': [3],
          'col2': 'Субъект РК юридического адреса владельца товара'
        },
        {
          'col1': [4],
          'col2': 'Юридический адрес владельца товара'
        },
        {
          'col1': [5],
          'col2': 'Наименование владельца товара'
        },
        {
          'col1': [6, 7],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [8],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [9],
          'col2': 'Максимальная розничная цена товара, тенге'
        },
        {
          'col1': [10, 12],
          'col2': 'Количество и стоимость товара (в пачках) на складе на начало периода'
        },
        {
          'col1': [11, 13],
          'col2': 'Соотношение количества и стоимости (в пачках) данного товара, находящегося в остатках на складе участника на начало периода с общим количеством и стоимостью (в пачках) всего товара, находящегося в остатках на складах всех участников на начало периода'
        },
        {
          'col1': [14, 16],
          'col2': 'Количество и стоимость товара (в пачках) на складе на конец периода'
        },
        {
          'col1': [15, 17],
          'col2': 'Соотношение количества и стоимости (в пачках) данного товара, находящегося в остатках на складе участника на конец периода с общим количеством и стоимостью (в пачках) всего товара, находящегося в остатках на складах всех участников на конец периода'
        },
        {
          'col1': [18],
          'col2': 'Общее количество полученного товара (в пачках) участником за период'
        },
        {
          'col1': [19, 20],
          'col2': 'Количество и стоимость товара (в пачках), закупленного в России за период'
        },
        {
          'col1': [21, 22],
          'col2': 'Количество и стоимость импортированного товара (в пачках) за период'
        },
        {
          'col1': [23],
          'col2': 'Количество произведённого товара (в пачках) участником за период'
        },
        {
          'col1': [24],
          'col2': 'Общее количество выбывшего товара (в пачках) у участника за период'
        },
        {
          'col1': [25, 26],
          'col2': 'Количество и стоимость товара (в пачках) оптовых продаж за период'
        },
        {
          'col1': [27, 28],
          'col2': 'Количество и стоимость товара (в пачках) розничных продаж за период'
        },
        {
          'col1': [29, 30],
          'col2': 'Количество и стоимость товара (в пачках), повторно реализованного конечному покупателю за период'
        },
        {
          'col1': [31],
          'col2': 'Количество списанного товара (в пачках) за период'
        },
      ]
    }
  ]
}
