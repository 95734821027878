import { handleActions } from 'redux-actions';
import * as actions from './Agregation.actions';
import * as cisActions from 'src/components/CisDetails/ducks/CisDetails.actions';

const statusReducer = (state, payload) => {
  const newState = new Map([...state]);
  const { id, status } = payload;
  newState.set(id, status);
  return newState;
}

const status = handleActions(
  {
    [cisActions.mounted]: () => (new Map),
    [actions.init]: (state, { payload = {} }) => statusReducer(state, { ...payload, status: 0 }),
    [actions.loaded]: (state, { payload = {} }) => statusReducer(state, { ...payload, status: 1 }),
    [actions.error]: (state, { payload = {} }) => statusReducer(state, { ...payload, status: -1 }),
  },
  new Map,
);

export default {
  status,
};