import { createActions } from 'redux-actions';

export const {
  mounted,
  unMounted,
  loaded,
  loadingEnd,
  loadingStart,
  setData,
  setMessage,
  submit,
} = createActions(
  {
    MOUNTED: payload => payload,
    UN_MOUNTED: () => {},
    LOADED: () => {},
    LOADING_END: () => {},
    LOADING_START: () => {},
    SET_DATA: payload => payload,
    SET_MESSAGE: payload => payload,
    SUBMIT: payload => payload,
  },
  {
    prefix: 'UNLOAD_SERVICE_CREATE',
  },
);
