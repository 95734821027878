import React, { useMemo } from 'react';
import moment from 'moment';
import { ComplianceDocWrap } from './ExportEAES.constants';

const ComplianceDocuments = ({ list = [] }) => {
  const documentList = useMemo(() =>
    list.map(({ docDate, docKind, docNumber }) => {
      const date = docDate ? moment(docDate).format('DD MMMM YYYY') : '-';
      const kind = docKind ? docKind === "declaration" ? "Декларация" : "Сертификат" : '—';
      return `${kind} №${docNumber} от ${date}`;
    }), [list]);
  return (
    <ComplianceDocWrap>
      {documentList.map((item) => (<li>{item}</li>))}
    </ComplianceDocWrap>
  );
}

export default ComplianceDocuments;
