import React, {useCallback} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withModal } from '../../../../common_components/Modals/hocs/withModal';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import { withRouter } from 'react-router-dom';
import AddIdentificationCodes from '../../../../common_components/AddIdentificationCodes/AddIdentificationCodes';

const AddAikBarWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: -15px;
    display: flex;
    justify-content: space-between;
    button {
      width: 200px;
    }
`;

const AikBarButtons = styled.div`
  display: flex;
  button {
    margin-left: 20px;
  }
`

const AddNotificationOfEntryFieldsBar = (props) => {
  const { onOpenModal, onSetContent, onCloseModal } = props;
  const { formMutators } = props;
  const { t } = useTranslation();

  const openLoadCisListModal = useCallback(() => {
    onSetContent(
      <AddIdentificationCodes
        formMutators={formMutators}
        onCloseModal={onCloseModal}
        types=".xls, .xlsx"
        title='Добавление кодов'
      />
    );
    onOpenModal();
  },[onSetContent, onOpenModal, formMutators, onCloseModal])

  return (
    <AddAikBarWrapper>
      <AikBarButtons>
        <PrimaryActionButton
          onClick={openLoadCisListModal}
        >
          {t('Загрузить XLS')}
        </PrimaryActionButton>
      </AikBarButtons>
    </AddAikBarWrapper>
  )
}

const mapDispatch = dispatch => ({

})

export default  compose(connect(null, mapDispatch), withModal, withRouter)(AddNotificationOfEntryFieldsBar);
