import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        },  
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': 'ИНН и наименование производителя',
        },
        {
          'col1': [3],
          'col2': 'Дата производства товара',
        },
        {
          'col1': [4],
          'col2': 'Количество уникальных товаров (GTIN), которые были произведены на фабрике за выбранный день',
        },
        {
          'col1': <strong>Максимальное значение</strong>,
          'col2': 'Максимальное количество производственных линий у производителя за выбранный период времени',
        },
      ]
    }
  ]
}