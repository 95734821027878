import {actionTypes} from '../../constants';

export const isInfinite = function (state = false, action = {}) {
  switch (action.type) {
    case actionTypes.CIS_LIST.LIST_LOADED:
      return action.value && action.value.total ? action.value.total === 2147483647 : state;
    case actionTypes.CIS_LIST.PAGES_COUNT_LOADED:
      return action.value === 2147483647;
    default:
      return state;
  }
};