import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1],
          'col2': <div>
            Уникальный идентификатор розничной торговой точки.<br />
            Каждый идентификатор может использоваться лишь однажды, вследствие чего сохраняется историчность
          </div>
        },
        {
          'col1': [2],
          'col2': 'ИНН владельца розничной торговой точки'
        },
        {
          'col1': [3],
          'col2': 'Адрес розничной торговой точки'
        },
        {
          'col1': [4],
          'col2': 'Регистрационный номер ККТ'
        },
        {
          'col1': [5],
          'col2': <div>Возможные значения: Активна/Не активна.<br />
          Идентификатор становится не активен в случае смены владельца розничной торговой точки или отсутствии продаж на протяжении длительного времени
          </div>
        }
      ]
    }
  ]
}