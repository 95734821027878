import intersection from 'lodash/intersection';
import get from 'lodash/get';
import { PARTICIPANT_TYPES } from '../constants';
import {b64DecodeUnicode, localStorageHelpers} from './localStorageHelpers';
import {getCookie} from './cookieHelpers';
import {StoreHelper} from './StoreHelper';
import { userInfo } from '../common_components/Auth/Auth.selectors';

export function getToken() {
  const count = getCookie('count');
  if (count) {
    return new Array(+count).fill(1).map((v, i) => getCookie(`tokenPart${i + 1}`)).join('');
  }
  return null;
}

export function getUserFromToken(token) {
  const user = {};
  const roles = localStorageHelpers.getRolesFromToken(token);
  Object.assign(user, roles);
  return user;
}

export function getAuthorities() {
  try {
    const user = userInfo(StoreHelper.getState());
    return get(user, 'authorities', []);
  } catch (e) {
    return [];
  }
}

export function getUserFromStore() {
  try {
    const user = userInfo(StoreHelper.getState());
    return user;
  } catch (e) {
    return {};
  }
}

export function getOrgRoles() {
  return getAuthorities()
    .filter(v => ~v.indexOf('ROLE_ORG_'))
    .map(v => v.replace('ROLE_ORG_', ''));
}

export function isFoiv() {
  try {
    return !!intersection([PARTICIPANT_TYPES.OGV], getOrgRoles()).length;
  } catch (e) {
    return false;
  }
}

export function isFoivOrOperator() {
  try {
    return !!intersection(
      [PARTICIPANT_TYPES.OGV, PARTICIPANT_TYPES.IS_MP_OPERATOR],
      getOrgRoles()
    ).length;
  } catch (e) {
    return false;
  }
}

export function isOperator() {
  try {
    return !!intersection(
      [PARTICIPANT_TYPES.IS_MP_OPERATOR],
      getOrgRoles()
    ).length;
  } catch (e) {
    return false;
  }
}

export function isAdmin() {
  const authorities = getAuthorities();
  return authorities.includes('ROLE_ADMIN');
}

export function isProducer() {
  try {
    return !!intersection([PARTICIPANT_TYPES.PRODUCER], getOrgRoles()).length;
  } catch (e) {
    return false;
  }
}

export function getCurrentParticipantId() {
  try {
    const user = userInfo(StoreHelper.getState());
    return get(user, 'pid', -1);
  } catch (e) {
    return -1;
  }
}

export function getCurrentParticipantInn() {
  const user = userInfo(StoreHelper.getState());
  return get(user, 'inn', null);
}

export function isTokenValidAndNotExpired(token) {
  if (!token) return false;
  let exp;

  try {
    const data = token.split('.')[1];
    const decoded = b64DecodeUnicode(data);
    exp = JSON.parse(decoded).exp;
  } catch (err) {
    return false;
  }

  if (exp && exp < new Date().getTime() / 1000) {
    return false;
  }

  return true;
}

