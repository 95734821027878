import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import { SingleSelect } from '@crpt/react-select';
import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import { RangePicker } from '@crpt/react-datepicker';

import GTIN from '../../../../components/TableCells/Product/WarehouseGTIN';
import { TableCell } from '../../../../components/common/Table/TableCellTooltip';
import { ParticipantSelect } from '../../../../components/Select';
import { WarehouseItem } from '../../../../components/ParticipantDetails/Table/WarehouseItem';
import { WarehouseHeader } from '../../../../components/ParticipantDetails/Table/WarehouseHeader';
import { defaultDatepickerTheme } from '../../../../theme/ru-tabaco/datepicker';
import { StoreHelper } from '../../../../utils/StoreHelper';
import { ParticipantService } from '../../../../services/ParticipantService';
import { saveAs } from 'file-saver';
import * as selectors from '../../../../selectors';
import moment from 'moment';

const ProductNameWrap = styled.div`
  white-space: normal;
  line-height: 17px;
  height: 34px;
  overflow: hidden;
`;

export default {
  actionNamespace: 'PARTICIPANT_DETAILS_WAREHOUSE_LIST',
  options: [
    {
      accessor: d => d,
      id: 'name',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <div>{otherProps.children || '—'}</div>
          </TableCell>
        );
      },
    },
    {
      accessor: d => d,
      id: 'gtin',
      render: props => {
        const { onMouseEvent, ...otherProps } = props;
        return (
          <TableCell onMouseEvent={onMouseEvent}>
            <GTIN iconDisable {...otherProps} />
          </TableCell>
        );
      },
    },
    {
      accessor: d => ({
        beginning: d._original.originBoxes,
        balance: d._original.boxes,
        inflow: d._original.inBoxes,
        outflow: d._original.outBoxes,
      }),
      id: 'boxes',
      render: props => {
        const boxes = get(props, 'options.boxes', {});
        return <WarehouseItem {...boxes} />;
      },
    },
    {
      accessor: d => ({
        beginning: d._original.originBlocks,
        balance: d._original.blocks,
        inflow: d._original.inBlocks,
        outflow: d._original.outBlocks,
      }),
      id: 'blocks',
      render: props => {
        const blocks = get(props, 'options.blocks', {});
        return <WarehouseItem {...blocks} />;
      },
    },
    {
      accessor: d => ({
        beginning: d._original.originPacks,
        balance: d._original.packs,
        inflow: d._original.inPacks,
        outflow: d._original.outPacks,
      }),
      id: 'packs',
      render: props => {
        const packs = get(props, 'options.packs', {});
        return <WarehouseItem {...packs} />;
      },
    },
  ],
  columns: [
    {
      Header: 'Наименование',
      id: 'name',
      accessor: ({ name }) => <ProductNameWrap>{name || '—'}</ProductNameWrap>,
      minWidth: 190,
    },
    {
      Header: 'GTIN',
      id: 'gtin',
      accessor: 'gtin',
      width: 190,
    },
    {
      Header: <WarehouseHeader title="Короба" />,
      id: 'boxes',
      accessor: 'boxes',
      width: 290,
    },
    {
      Header: <WarehouseHeader title="Блоки" />,
      id: 'blocks',
      accessor: 'blocks',
      width: 290,
    },
    {
      Header: <WarehouseHeader title="Пачки" />,
      id: 'packs',
      accessor: 'packs',
      width: 290,
    },
  ],
  beforeTable: {
    showGraph: false,
  },
  beforeFilterForm: {
    addButton: true,
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: [
        {
          title: 'Скачать CSV',
          onClick: () => {
            const filterParams = StoreHelper.getState().ParticipantDetails
              .Warehouse.filters;
            const { inn } = selectors.ParticipantDetails.selectedParticipant(
              StoreHelper.getState()
            );
            const {
              participantInn,
              page,
              dateFrom: filterDateFrom,
              dateTo: filterDateTo,
              ...othersFilterProps
            } = filterParams;

            const dateFrom = filterDateFrom
              ? `${moment(filterDateFrom).format('YYYY-MM-DDTHH:mm:ss')}Z`
              : `${moment('2017-01-01 00:00:00').format(
                  'YYYY-MM-DDTHH:mm:ss'
                )}Z`;

            const dateTo = filterDateTo
              ? `${moment(filterDateTo).format('YYYY-MM-DDTHH:mm:ss')}Z`
              : `${moment().format('YYYY-MM-DDTHH:mm:ss')}Z`;

            const data = {
              inn,
              limit: 10,
              page: 0,
              dateFrom,
              dateTo,
              ...othersFilterProps,
            };

            ParticipantService.getWarehouseFile(data).then(answer => {
              saveAs(
                answer.data,
                `warehouse_list_${inn}_${moment(dateFrom).format(
                  'YYYY.MM.DD'
                )}-${moment(dateTo).format('YYYY.MM.DD')}.csv`
              );
            });
          },
        },
      ],
      retailOrganisation: true,
    },
    [
      {
        component: Input,
        props: {
          name: 'search',
          placeholder: 'Поиск по товару / GTIN',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [
      {
        component: RangePicker,
        props: {
          inline: false,
          name: 'date',
          placeholder: 'Период учета',
          format: 'DD.MM.YYYY',
          positionX: 'left',
          controls: true,
          acceptText: 'Применить',
          resetText: 'Сбросить',
          theme: { DatePicker: defaultDatepickerTheme },
        },
      },
    ],
    [
      {
        component: ParticipantSelect,
        props: {
          inline: false,
          name: 'producer',
          placeholder: 'Производитель',
        },
      },
    ],
    [
      {
        component: SingleSelect,
        props: {
          inline: false,
          name: 'store',
          placeholder: 'Склад',
        },
      },
    ],
  ],
};
