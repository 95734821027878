import styled from 'styled-components';
import { get } from 'lodash';


const Item = styled.div`
  display: flex;
  align-items: center;
  height: ${props => get(props, 'theme.Dropdown.item.height', '100px')};
  font-size: ${props => get(props, 'theme.Dropdown.item.fontSize', '50px')};
  cursor: ${props => get(props, 'theme.Dropdown.item.cursor', 'default')};
  background-color: ${props => (props.active ? get(props, 'theme.Dropdown.item.active.backgroundColor', 'black') : '')};
  &:hover {
    background-color: #f7f7f7;
    font-weight: 600;
    > :last-child {
      color: #5ca6dc;
    }
  };
`;

export default Item;
