import { Translate } from '../../../common_components/Translate/Translate';

const React = require('react');
import PropTypes from 'prop-types';
import { RangePicker } from '@crpt/react-datepicker';
import moment from 'moment';
import { Wrap, SelectWrap } from '../Grid.styled';
import { gridFilterDatepickerTheme as kzTheme } from '../../../theme/kz-tobaco/datepicker/gridFilter';
import { gridFilterDatepickerTheme as uzTheme } from '../../../theme/uz-tobaco/datepicker/gridFilter';

class DateFilter extends React.Component {
  static format = 'YYYY-MM-DDTHH:mm:ss';

  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    appliedFilter: PropTypes.object, // filterTerm, operator, filterValue
    close: PropTypes.func,
  };

  constructor(props) {
    super(props);

    let from, to;

    this.props.appliedFilter.forEach(val => {
      if (val.operator === '>') {
        from = val.filterTerm;
      }

      if (val.operator === '<') {
        to = val.filterTerm;
      }
    });

    this.state = {
      theme: kzTheme,
      from: from
        ? moment(from, DateFilter.format)
            .startOf('day')
            .format(DateFilter.format)
        : null,
      to: to
        ? moment(to, DateFilter.format)
            .endOf('day')
            .format(DateFilter.format)
        : null,
    };
  }

  componentDidMount() {
    const country = window.env.ELK_COUNTRY;
    const theme = country === 'UZ' ? uzTheme : kzTheme;
    const themeEdited = {...theme}
    if (window.innerHeight < 800) {
      // для малых экранов добавить смещение календаря вверх
      themeEdited.DatePickerPanelWrap = {...themeEdited.DatePickerPanelWrap, top: '-100px'}
    }
    this.setState({ theme: themeEdited });
  }

  get period() {
    const { column, periods } = this.props;
    const { filters } = column;
    try {
      const periodsKeys = filters.map(filter => filter.column);
      const periodsVals = periodsKeys
        .filter(key => periods.hasOwnProperty(key))
        .map(key => periods[key]);
      return periodsVals.length ? Math.max(...periodsVals) : periods.default;
    } catch (e) {
      return 1;
    }
  }

  handleFilterChange = val => {
    const period = this.period;
    const { from, to } = val;

    const fromInput = moment(from)._i;

    if (typeof fromInput === 'string' && fromInput.indexOf('_') >= 0) return;

    let fromVal = from
      ? moment(from)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')
      : null;
    let toVal = to
      ? moment(to)
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')
      : null;

    if (fromVal && fromVal !== this.state.from) {
      const toCurrent = moment
        .min(moment(), moment(moment(from).toArray()).add(period, 'days'))
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');

      this.setState({
        from: fromVal,
        to: moment().diff(toCurrent, 'days') === 0 ? null : toCurrent,
      });
      return;
    }

    if (toVal && toVal !== this.state.to) {
      const fromCurrent = moment(from).isValid()
        ? moment
            .max(
              moment(from),
              moment(moment(to).toArray()).subtract(period, 'days'),
            )
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss')
        : moment(moment(to).toArray())
            .subtract(period, 'days')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
      this.setState({
        from: fromCurrent,
        to: toVal,
      });
    }
  };

  reset = () => {
    this.setState({ value: '' });
    this.props.onFilterChange(undefined);
  };

  applyFilter = () => {
    //third parameter is filterValue object, need for restored complex filters

    const { from, to } = this.state;

    const results = [];

    if (from) {
      results.push({
        filterTerm: from,
        operator: '>',
        filterValue: {
          from: from,
          to: to,
        },
      });
    }

    if (to) {
      results.push({
        filterTerm: to,
        operator: '<',
        filterValue: {
          from: from,
          to: to,
        },
      });
    }

    this.props.onFilterChange(results);
  };

  render() {
    const { theme } = this.state;

    return (
      <Wrap>
        <SelectWrap>
          <RangePicker
            noActions
            noAutoFocus
            theme={{ DatePicker: theme }}
            placeholder={this.props.placeholder}
            leftPlaceholder={Translate('От')}
            rightPlaceholder={Translate('До')}
            format={'DD.MM.YYYY'}
            from={
              this.state.from
                ? moment(this.state.from).format('DD.MM.YYYY')
                : undefined
            }
            to={
              this.state.to
                ? moment(this.state.to).format('DD.MM.YYYY')
                : undefined
            }
            onUpdate={val => this.handleFilterChange(val)}
            maxDate={moment().toDate()}
          />
        </SelectWrap>
        <button
          type="button"
          onClick={this.reset}
          className="filter-btn__cancel"
        >
          {Translate('Сбросить')}
        </button>
        <button
          type="button"
          onClick={this.applyFilter}
          className="filter-btn__apply"
        >
          {Translate('Применить')}
        </button>
      </Wrap>
    );
  }
}

export default DateFilter;
