import { countryCodes } from '../../../../constants/countryCodes';
import { typeDocument } from '../../../../motp_components/_Requests/RequestList/ImportThirdCountry/ImportThirdCountry.constants';
import moment from 'moment';

const dateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timezone: 'UTC',
};

export const columns = [
  { Header: 'ИИН/БИН получателя', cid: 'importerCode' },
  { Header: 'Наименование получателя', cid: 'importerName' },
  {
    Header: 'Страна экспорта',
    cid: 'exportCountry',
    formater: val => {
      const country = countryCodes.find(({ title, type }) => type === val);
      return country ? country.title : '-';
    },
  },

  {
    Header: 'Тип документа таможенного оформления',
    cid: 'customDocType',
    formater: val => {
      const type = typeDocument.find(({ label, value }) => value === val);
      return type ? type.label : '-';
    },
  },
  { Header: 'Регистрационный номер', cid: 'customRegNumber' },
  {
    Header: 'Дата регистрации',
    cid: 'customRegDate',
    formater: val => moment(val).format('DD.MM.YYYY'),
  },
  { Header: 'Код решения', cid: 'decisionCode' },
  {
    Header: 'Дата и время решения',
    cid: 'decisionDateTime',
    formater: val =>
      moment(val)
        .utc()
        .format('DD.MM.YYYY HH:mm'),
  },
  { Header: 'Код таможенного органа', cid: 'decisionCustomCode' },

  {
    Header: 'Тип документа, подтверждающего соответствие товаров',
    cid: 'certificateDocType',
  },
  { Header: 'Номер документа', cid: 'certificateDocNumber' },
  {
    Header: 'Дата документа',
    cid: 'certificateDocDate',
    formater: val => moment(val).format('DD.MM.YYYY'),
  },

  // { Header: 'Номер Уведомления о ввозе', cid: 'originalDocNumber' },
  // { Header: 'Дата Уведомления о ввозе', cid: 'originalDocDate', formater: val => moment(val).format('DD.MM.YYYY'), },
];
