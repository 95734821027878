import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { Base64 } from '@crpt-ui/crypto';
import moment from 'moment';
import { Toast } from 'src/utils/Toast';
import { replace } from 'react-router-redux';
import * as actions from './EaesImportDocument.actions';
import * as requestListActions from '../../ducks/RequestList.actions';
// import * as cadesActions from '../../../../../common_components/Cades/Cades.actions';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import * as CisActions from '../Cis/ducks/Cis.actions';
import Api from 'src/common_components/Api/Api';
import * as draftSelectors from '../../Draft/ducks/Draft.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import {
  numbersToStrings,
  rubelsToKopecksInProducts,
} from '../../RequestList.utils';
import { prepareDateForJSON } from 'src/utils';
import { NCALayerService } from 'src/common_components/Ncalayer/NCALayerService';
import * as selectors from 'src/common_components/App/App.selectors';
import { documentTypes } from 'src/constants/documentTypes';
import {
  buildImportDocumentBodyKG,
  buildImportTransitDocumentBody,
} from '../EaesImportDocument.utils';
import i18next from 'i18next';
import { history } from '../../../../../store';

function* mountSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id: payload },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
        __form_document_status: success.data.status,
      }),
    );
    yield put(actions.setError(success.data.downloadDesc));
  }
  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* uploadSaga(action, state) {
  const message = i18next.t(
    'Файл заявки на ввод товара в оборот (импорт) загружен',
  );
  const url = '/api/v3/facade/doc/upload';
  const userInfo = yield select(authSelectors.userInfo);

  const properties = {
    document_type: documentTypes.IMPORT_TRANSIT,
    user_id: userInfo.id,
    organisation_inn: userInfo.inn,
  };

  yield put(
    requestListActions.fileUpload({
      file: { ...action.payload, properties },
      message,
      url,
    }),
  );
}

function* submitSaga(action) {
  const { payload } = action;

  const countryCode = yield select(selectors.countryCode);

  if (countryCode === 'KZ') yield submitSagaKZ(payload);
  else yield submitSagaKG(payload);
}

function* countTotalsSagaKZ({ payload }) {
  const document = buildImportTransitDocumentBody(payload, false);
  const { status } = payload;

  const method = status === 19 ? 'put' : 'post';
  const message = status === 19 ? 'Выполняется пересчет итогов' : 'Документ создан (выполняется расчет Итогов)';

  const data = { ...document };
  //TODO СРОЧНО РЕФАКТОРИТЬ
  const locationHref = window.location.href;
  const draft_id = yield select(draftSelectors.getIdDocument);
  if ((draft_id || /draft-/.test(locationHref)) && status !== 19) {
    data.draft_id = draft_id || locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, '');
  }

  let requestPayload = {
    url: '/api/v3/facade/edo-api/outgoing-documents/json/import-transit',
    method,
    data,
  };

  const [createSuccess, createError] = yield call(Api.request, requestPayload);

  if (!createSuccess) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  } else {
    yield history.push('/documents/outcoming');
    Toast.showSuccess({
      content: message,
    });
  }
}

function* submitSagaKZ(payload) {
  let requestPayload = {
    url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/base64`,
    method: 'get',
  };

  const [contentSuccess, contentError] = yield call(
    Api.request,
    requestPayload,
  );
  if (!contentSuccess) return;

  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const signature = yield Ncalayer.signData(contentSuccess.data);

  if (!signature) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  requestPayload = {
    url: `/api/v3/facade/edo-api/outgoing-documents/${payload}/signature`,
    method: 'post',
    data: signature,
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const error = get(success, 'data.errors.0', false);
    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Уведомление успешно отправлено.',
      });
      Toast.showInfo({
        content: 'Создание уведомления может занять некоторое время.',
        autoClose: 5000,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace(`/documents/outcoming`));
    }
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }

  yield put(CisActions.finishSign());
}

function* submitSagaKG(payload) {
  const document = buildImportDocumentBodyKG(payload);

  const convertedData = Base64.encode(JSON.stringify(document));
  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');

  const sign = yield Ncalayer.signData(convertedData);

  if (!sign) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  let data = {
    registration_number: document.registration_number,
    signature: sign,
    document: convertedData,
    document_type: 'IMPORT_TRANSIT',
  };

  const locationHref = window.location.href;
  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    data = {
      ...data,
      draft_id:
        drafttId ||
        locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, ''),
    };
  }

  const requestPayload = {
    url: '/api/v3/facade/doc/signed',
    method: 'post',
    data,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  if (success) {
    const error = get(success, 'data.errors.0', false);
    console.log('success', success);
    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Уведомление о ввозе товаров ЕАЭС успешно отправлено.',
      });
      Toast.showInfo({
        content: 'Создание уведомления может занять некоторое время.',
        autoClose: 5000,
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace('/documents/list'));
    }
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }

  yield put(CisActions.finishSign());
}

function* signDecision(action) {
  const { decision, documentId } = action.payload;
  let path = 'incoming-documents';
  if (decision === 'withdraw') path = 'outgoing-documents';

  let base64 = '';
  const requestPayloadForCreateEvent = {
    url: `/api/v3/facade/edo-api/${path}/${documentId}/events/${decision}`,
    method: 'post',
  };

  const [createEventSuccess, createEventError] = yield call(
    Api.request,
    requestPayloadForCreateEvent,
  );

  if (createEventSuccess) {
    base64 = createEventSuccess.data;
  }
  if (createEventError) return;
  // yield call(Toast.showError, {
  //   content: get(
  //     createEventError,
  //     'response.data.message',
  //     'Невозможно выполнить операцию!',
  //   ),
  // });

  const Ncalayer = new NCALayerService('signDocument', 'PKCS12');
  const signature = yield Ncalayer.signData(base64);

  if (!signature) {
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  let url = `/api/v3/facade/edo-api/${path}/${documentId}/events/${decision}/signature`;
  if (decision === 'withdraw')
    url = `/api/v3/facade/edo-api/${path}/${documentId}/events/signature`;

  const requestPayloadForSignature = {
    url,
    method: 'post',
    data: signature,
    errorNotify: false,
  };

  const [success, error] = yield call(Api.request, requestPayloadForSignature);
  if (success) {
    const message = decision === 'approve' ? 'принято!' : 'отозвано!';
    yield call(Toast.showSuccess, {
      content: `Уведомление успешно ${message}`,
    });
    yield put(listActions.mounted(undefined, requestListActions));
    yield put(replace(`/documents/incoming`));
  }
  if (error) {
    const message = decision === 'approve' ? 'принятии' : 'отзыве';
    const errMessage = get(
      error,
      'response.data.description',
      `:При ${message} уведомления произошла ошибка!`,
    );
    const content = errMessage.split(':')[1];
    yield call(Toast.showError, {
      content:
        content === ' certificate inn and resident inn are not equals!'
          ? 'Подпись не действительна!'
          : content,
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.upload, uploadSaga);
  yield takeEvery(actions.submit, submitSaga);
  yield takeEvery(actions.signDecision, signDecision);
  yield takeEvery(actions.countTotalsKZ, countTotalsSagaKZ);
}
