import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@crpt-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Beer from '../../svg/beer.svg';
import styled from 'styled-components';
import { ReactComponent as Antiseptic } from "../../svg/antiseptic.svg";


const Plug =  styled.div`
 width: 1em;
 height: 1em;
 padding: 0 24px 0 0px;
`

const getIcon = productGroupId => {
  switch (productGroupId) {
    case 3:
      return <Icons.Tobacco />;
    case 2:
      return <Icons.Shoes />;
    case 7:
      return <Icons.Medicines />;
    case 8:
      return <Icons.Milk />;
    case 11:
      return <Icons.Alcohol />;
    case 13:
      return <Icons.Water />;
    case 15:
      return <SvgIcon viewBox="0 0 40 40" component={Beer} />;
    case 18:
      return <Icons.Applications />;
    case 19:
      return <SvgIcon viewBox="0 0 40 40" component={Antiseptic} />;
    default:
      return <Plug />;
  }
};

export const ProductGroupIcon = ({ productGroupId }) => getIcon(productGroupId);

ProductGroupIcon.propTypes = {
  productGroupId: PropTypes.number.isRequired,
};
