import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Toast } from '../../../../../utils/Toast';
import Api from '../../../../../common_components/Api/Api';
import * as actions from './CorInfoCm.actions';
import { signUZSaga } from '../../../../../common_components/Eimzo/Eimzo.saga';
import { documentTypes } from '../../../../../constants';
import { senderDocumentFormat } from '../constants';
import i18next from 'i18next';

function* submitSaga(action) {
  const signature = yield call(signUZSaga, {
    payload: { data: senderDocumentFormat(action.payload.values), file: false },
  });
  if (!signature) {
    yield put(actions.reset());
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  const requestPayload = {
    url: '/api/v3/facade/doc/signed',
    method: 'post',
    data: {
      registration_number: null,
      signature: signature.signature,
      document: signature.document,
      document_type: documentTypes.COR_INFO_CM,
      draft_id:
        action.payload.idDraft === 'create'
          ? undefined
          : action.payload.idDraft,
    },
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    Toast.showSuccess({
      content:
        i18next.t('Документ корректировки сведений о кодах маркировки успешно создан'),
    });
    yield put(replace('/documents/list'));
  }
  if (error) {
    yield call(Toast.showError, {
      content: get(error, 'response.data.message', 'error'),
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.submit, submitSaga);
}
