import styled from 'styled-components';

export const RoleBlock = styled.div`
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.25);
  box-sizing: border-box;
  border-radius: 3px;
  padding-bottom: 23px;
  :hover {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;
  }
`;
