import styled from 'styled-components';

export const Switcher = styled.div`
  border: 1px solid rgba(196, 196, 196, 0.25);
  box-sizing: border-box;
  border-radius: 3px;
  border-top-left-radius: ${props => (props.first ? '3px' : '0')};
  border-bottom-left-radius: ${props => (props.first ? '3px' : '0')};
  border-top-right-radius: ${props => (props.last ? '3px' : '0')};
  border-bottom-right-radius: ${props => (props.last ? '3px' : '0')};
  border-left: ${props =>
    props.first ? '1px solid rgba(196, 196, 196, 0.25)' : '0'};
  padding: 7px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${props => (props.selected ? '#F3F3F3' : '')};
`;

export const SelectWrap = styled.div`
  margin-left: 20px;
  width: 220px;
`;

export const StatisticsColumnHeader = styled.div`
  font-size: 16px;
  color: #212c42;
  margin-bottom: 14px;
  font-weight: 600;
  max-width: 186px;
  height: 54px;
`;

export const LabelWrap = styled.div`
  margin-bottom: 30px;
`;

export const LabelTitle = styled.div`
  font-size: 16px;
  color: #52535a;
`;

export const LabelContent = styled.div`
  font-size: 24px;
  color: #52535a;
  font-weight: 600;
  display: flex;
`;

export const NormalWrap = styled.div`
  font-weight: 400;
`;

export const DiaWrap = styled.div`
  width: 171px;
  min-width: 171px;
  margin-right: 32px;
  padding-top: 40px;
`;

export const ListLine = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  color: #52535a;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  white-space: nowrap;
  align-items: center;
`;

export const LineNormalWrap = styled.div`
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const RoundWrap = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  background: ${props => (props.background ? props.background : '')};
`;

export const LastColumnHeader = styled.div`
  font-size: 16px;
  color: #212c42;
  margin-bottom: 14px;
  font-weight: 600;
  max-width: 186px;
`;

export const TableHeading = styled.div`
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const FillWidth = styled.div`
  width: 100%;
`;

export const TableParam = styled.div`
  white-space: nowrap;
  margin-right: 13px;
  width: 105px;
  color: #212c42;
  font-size: 16px;
`;

export const FillCard = styled.div`
  margin-left: ${props => '-' + props.theme.paddingLeft};
  margin-right: ${props => '-' + props.theme.paddingRight};
  padding-top: 10px;
`;

export const TableTitle = styled.div`
  padding-left: ${props => props.theme.paddingLeft};
  padding-right: ${props => props.theme.paddingRight};
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(196, 196, 196, 0.25);
`;

export const CountWrap = styled.div`
  margin-left: 10px;
`;

export const PaginationWrap = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 32px;
`;

export const PaginationCentrator = styled.div`
  display: flex;
  justify-content: center;
`;

export const FilterButtonsWrap = styled.div`
  position: relative;
  top: 20px;
`;

export const Heading = styled.div`
  display: flex;
  margin-bottom: 42px;
  margin-top: ${props => (props.excise ? '-50px' : '')};
  font-size: 24px;
  color: #52535a;
  font-weight: 400;
  align-items: center;
`;

export const Content = styled.div`
  margin-top: 6px;
`;



export const WrapDivider = styled.div`
  height: 32px;
`;
