import React, { useEffect } from 'react';
import { useStyles } from './order.styled';
import clsx from 'classnames';

const articleOrders = [1,2,3,4,5,6,7,8,9,10];
const bannerOrders = [1,2,3,4,5];

export const OrderField = ({ value, onChange, type }) => {
  const styles = useStyles();

  useEffect(() => {
    onChange(1);
  }, [type])

  const orders = type === 'article' ? articleOrders : bannerOrders;
  return (
    <div className={styles.container}>
      {orders.map((item) => (
        <div
          key={item}
          onClick={() => onChange(item)}
          className={clsx(styles.item, {[styles.selected]: value === item})}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
