import { handleActions } from 'redux-actions';

import * as actions from './articles.actions';

const list = handleActions(
  {
    [actions.setList]: (state, { payload }) => payload,
    [actions.unmount]: () => [],
  },
  false,
);

const isLoading = handleActions(
  {
    [actions.loadList]: () => true,
    [actions.setList]: () => false,
    [actions.loaded]: () => false,
  },
  false,
);

export default {
  list,
  isLoading
}
