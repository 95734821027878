import React from 'react';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Отрасль',
          'col2': 'Табачная продукция'
        },
        {
          'col1': 'Производитель/ИНН',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'GTIN',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование товара',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Тип упаковки',
          'col2': <div>
            Возможность фильтрации по значениям:
            <ul>
              <li>Пачка</li>
              <li>Блок</li>
              <li>Короб</li>
            </ul>
          </div>
        },
        {
          'col1': 'Агрегация',
          'col2': <div>
            Возможность выбора агрегации:
            <ul> 
              <li>Производитель + GTIN</li>
              <li>Производитель</li>
              <li>Производитель + тип упаковки</li>
              <li>Производитель + тип упаковки за весь период (отчёт строится за весь период
                маркировки без детализации по периоду. Форма отчёта совпадает с агрегацией
                «Производитель + тип упаковки»)
              </li>  
            </ul> 
          </div>
        },
        {
          'col1': 'Отображение периода',
          'col2': <div>
            Возможность выбора отображения детализации периода:
            <ul>
              <li>День</li>
              <li>Неделя</li>
              <li>Месяц</li>
              <li>Год</li>
            </ul>
          </div>
        },
      ]
    }
  ]
}