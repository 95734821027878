import React, { useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import { Link, Modal } from '@crpt-ui/core';
import { Close } from '@crpt-ui/icons';
import {
  Box,
  makeStyles,
  MuiThemeProvider,
  Typography,
} from '@material-ui/core';

// import LightTheme from '../../../common_components/_MaterialUi/LightTheme';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';

const codesRendererUseStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  linkWrapper: {
    fontWeight: 600,
  },
  value: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '150px',
  },
  modalContentWrapper: {
    marginBottom: -20,
  },
  iconCloseWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
    lineHeight: 1,
  },
  divider: {
    height: 1,
    width: '100%',
    background: '#f2f2f2',
  },
  scrolling: {
    maxHeight: '75vh',
    overflowY: 'auto',
  },
});

export const CodesRenderer = ({ row }) => {
  const classes = codesRendererUseStyles();
  const rowCodes = useMemo(
    () =>
      get(
        row,
        'original.codes',
        get(
          row,
          'original.cises',
          get(
            row,
            'original.cis',
            get(row, 'original.icodes', get(row, 'cises', [])),
          ),
        ),
      ),
    [row],
  );
  const codes = Array.isArray(rowCodes) ? rowCodes : [rowCodes];
  const [modalIsOpen, handleModalOpen] = useState(false);

  const setModalOpen = useCallback(() => handleModalOpen(true), []);
  const setModalClose = useCallback(() => handleModalOpen(false), []);

  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <div className={classes.wrapper}>
        <div className={classes.value}>{codes[0]}</div>&nbsp;
        <div className={classes.linkWrapper}>
          <Link onClick={setModalOpen} component="span">
            +{codes.length - 1}
          </Link>
        </div>
        <Modal open={modalIsOpen} onClose={setModalClose}>
          <div className={classes.modalContentWrapper}>
            <div className={classes.iconCloseWrapper}>
              <Close onClick={setModalClose} />
            </div>

            <Typography variant="h6" color="textPrimary">
              КОДЫ ИДЕНТИФИКАЦИИ
            </Typography>

            <Box mx={-4} mt={1} mb={1}>
              <div className={classes.divider} />
            </Box>
            <div className={classes.scrolling}>
              {codes.map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </MuiThemeProvider>
  );
};
