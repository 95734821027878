/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup as RadioGroupCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

const RadioGroupAdapter = ({ input, meta, ...fieldRest }) => {
  const { placeholder, errorPlaceholder, isError, ...rest } = fieldRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  // повторяет поведение input.onChange, отключает ошибку FinalForm
  // You must pass type="radio" prop to your Field(perishable) component.
  const onChangeHandle = event => {
    input.onChange(event.target.value);
  };

  return (
    <RadioGroupCRPT
      {...input}
      onChange={onChangeHandle}
      {...rest}
    />
  );
};

RadioGroupAdapter.propTypes = {
  isError: PropTypes.func,
};

RadioGroupAdapter.defaultProps = {
  isError: meta => meta.invalid && meta.touched,
};

export default FieldHoc(RadioGroupAdapter);
