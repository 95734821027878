import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';
import FileNotifyView from './FileNotify.view';
import { TRANSLATION } from './FileNotify.constants';
import {Translate} from '../Translate/Translate';

class FileNotify extends React.Component {
  static propTypes = {
    theme: PropTypes.shape({}).isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { theme } = this.props;
    const currentTheme = JSON.parse(JSON.stringify(theme));
    currentTheme.Button.minWidth = '205px';

    this.state = {
      theme: currentTheme,
    };
  }

  translate = strings => {
    return strings.map(text => {
      let tmp = text;
      Object.keys(TRANSLATION).forEach(KEY => {
        tmp = tmp.replace(new RegExp(KEY, 'g'), TRANSLATION[KEY]);
      });
      return tmp
        .replace(/#/gs, '')
        .replace(/\s{2,}/gs, ' ')
        .replace(/\.{2,}/gs, '.');
    });
  };

  getMessage = () => {
    const { errors } = this.props;

    if (Array.isArray(errors)) {
      const translated = this.translate(errors);
      return translated;
    }
    const message = /413/.test(errors)
      ? Translate('Отправляемый документ по размеру в мегабайтах превышает максимально допустимый')
      : Translate('Загруженный файл не соответствует формату');
    return [get(errors, '0.error_message', message)];
  };

  render() {
    const { errors, onAccept, onCancel } = this.props;
    const { theme } = this.state;

    return (
      <FileNotifyView
        isOpen={!!errors.length}
        message={this.getMessage()}
        theme={theme}
        onAccept={onAccept}
        onCancel={onCancel}
      />
    );
  }
}

export default withTheme(FileNotify);
