import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import lodash from 'lodash';
import 'moment/locale/ru';
import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { TwoLineLabel } from '@crpt/react-label';
import { taxationFullNames } from '../../../constants';
import { getParticipantNamesByRoles, nano2milli } from '../DocumentDetails.utils';
import * as selectors from '../../../selectors/LoginFormKep';
import HeadingWrap from './HeadingWrap';
import HeadedLabel from './HeadedLabel';
import { FlexWrapper, Separator } from './styled';
import { documentTypeNames } from '../../../constants/documentTypes';
import { Translate } from '../../../common_components/Translate/Translate';
import { receiptStatus } from '../../../motp_components/_Requests/RequestList/RequestList.constants';
import { compose } from 'redux';
import { connect } from 'react-redux';

moment.locale('ru');

const LeftLayer = styled.div`
  width: 160px;
  min-width: 160px;
`;

const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 16px;
`;

const InfoLine = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

function ReceiptBasicInfo(props) {
  const { id, selectedDocument, isKZ, isUZ, language } = props;
  const { documentDate, receivedAt } = selectedDocument;
  const docDate = moment(nano2milli(documentDate)).lang(typeof language === 'string' ? language.toLowerCase() : 'RU');
  const docDateString = (docDate && docDate.format('DD MMMM YYYY')) || '—';
  const receivedDate = moment(nano2milli(receivedAt)).lang(typeof language === 'string' ? language.toLowerCase() : 'RU');
  const receivedDateString =
    (receivedDate && receivedDate.format('DD MMMM YYYY')) || '—';
  const type = lodash.get(selectedDocument, 'type', false);
  const status = lodash.get(selectedDocument, 'status', false);
  const senderName = lodash.get(selectedDocument, 'senderName', false);
  const senderINN = lodash.get(selectedDocument, 'senderInn', false);
  const kktRegId = lodash.get(selectedDocument, 'kktRegId', false);
  const operator = lodash.get(selectedDocument, 'operator', false);
  const taxationTypes = lodash.get(selectedDocument, 'taxationTypes', false);

  const fiscalDocumentNumber = lodash.get(
    selectedDocument,
    'fiscalDocumentNumber',
    '-',
  );

  let cashTypeArray = [];
  if (lodash.get(selectedDocument, 'isCash', false)) {
    cashTypeArray.push('Наличная');
  }
  if (lodash.get(selectedDocument, 'isECash', false)) {
    cashTypeArray.push('Электронная');
  }
  let cashType = cashTypeArray.join(', ');

  const retailPlaceAddress = lodash.get(
    selectedDocument,
    'retailPlaceAddress',
    '-',
  );
  const shiftNumber = lodash.get(selectedDocument, 'shiftNumber', '-');

  const senderRoles = lodash.get(selectedDocument, 'senderInfo.roles', []);

  const receiptType = type ? documentTypeNames[type] : '';
  const receiptTitle = (
    <Fragment>
      {Translate(receiptType)}&nbsp;{id}
    </Fragment>
  );

  const senderDocInfo = (
    <DocInfoWrap>
      <div>
        <DocumentInfoHeadingLabel>
          {Translate('Продавец')}
        </DocumentInfoHeadingLabel>
        <TwoLineLabel heading={getParticipantNamesByRoles(senderRoles)}>
          {senderName || '—'}
        </TwoLineLabel>
      </div>
      <LeftLayer/>
    </DocInfoWrap>
  );

  return (
    <React.Fragment>
      <HeadingWrap title={receiptTitle}/>
      <FlexWrapper>
        <HeadedLabel
          header={Translate('Дата документа')}
          value={docDateString}
        />
        <HeadedLabel
          header={Translate('Поступил в систему')}
          value={receivedDateString}
        />
      </FlexWrapper>

      <Separator/>

      <Card>
        <Container fillWidth gutter={32}>
          <Column>
            {senderDocInfo}

            {(senderINN || taxationTypes) && (
              <DocInfoWrap>
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Налоговые данные')}
                  </DocumentInfoHeadingLabel>
                  {senderINN && !isKZ && (
                    <InfoLine>
                      <TwoLineLabel heading={Translate('ИНН/ПИНФЛ')}>
                        {senderINN || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {senderINN && isKZ && (
                    <InfoLine>
                      <TwoLineLabel heading={Translate('ИИН/БИН')}>
                        {senderINN || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  )}

                  {taxationTypes && !isKZ && !isUZ && (
                    <TwoLineLabel
                      heading={Translate('Система налогообложения')}
                    >
                      {(taxationTypes &&
                        taxationTypes
                          .map(t => taxationFullNames[t])
                          .join(',')) ||
                      '—'}
                    </TwoLineLabel>
                  )}
                </div>
                <LeftLayer/>
              </DocInfoWrap>
            )}

            {(retailPlaceAddress || shiftNumber) && (
              <DocInfoWrap>
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Торговая точка')}
                  </DocumentInfoHeadingLabel>
                  {retailPlaceAddress && !isKZ && !isUZ && (
                    <InfoLine>
                      <TwoLineLabel
                        heading={Translate('Адрес торговой точки')}
                      >
                        {retailPlaceAddress}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {shiftNumber && (
                    <TwoLineLabel heading={Translate('Смена')}>
                      {shiftNumber}
                    </TwoLineLabel>
                  )}
                </div>
                <LeftLayer/>
              </DocInfoWrap>
            )}
          </Column>
          <Column>
            {(fiscalDocumentNumber || cashType || kktRegId || operator) && (
              <DocInfoWrap>
                <div>
                  <DocumentInfoHeadingLabel>
                    {Translate('Фискальные данные')}
                  </DocumentInfoHeadingLabel>
                  {fiscalDocumentNumber && (
                    <InfoLine>
                      <TwoLineLabel heading={Translate('Номер чека')}>
                        {fiscalDocumentNumber}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {cashType && !isUZ && !isKZ && (
                    <InfoLine>
                      <TwoLineLabel heading={Translate('Форма расчета')}>
                        {cashType}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {kktRegId && (
                    <InfoLine>
                      <TwoLineLabel
                        heading={
                          isUZ || isKZ
                            ? Translate('Номер ККМ')
                            : Translate('Номер ККТ')
                        }
                      >
                        {kktRegId || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {(status && isUZ) && (
                    <InfoLine>
                      <TwoLineLabel
                        heading={Translate('Статус')}
                      >
                        {Translate(receiptStatus[status]) || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  )}
                  {operator && !isUZ && !isKZ && (
                    <InfoLine>
                      <TwoLineLabel heading={Translate('Кассир')}>
                        {operator || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                  )}

                  {/*<TwoLineLabel heading={'Сайт проверки'}>{'—'}</TwoLineLabel>*/}
                </div>
                <LeftLayer/>
              </DocInfoWrap>
            )}
          </Column>
        </Container>
      </Card>
    </React.Fragment>
  );
}

ReceiptBasicInfo.propTypes = {
  id: PropTypes.string,
  isKZ: PropTypes.bool,
  isUZ: PropTypes.bool,
  selectedDocument: PropTypes.object,
};
const mapState = state => {
  return {
    language: selectors.language(state)
  }
}
export default compose(
  connect(
    mapState,
    null
  ),
  withRouter
)(ReceiptBasicInfo)

