import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    root: {
      outline: 'none',
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px',
    },
    icon: {
      marginRight: 8,
    },
    notification: {
      background: theme.palette.text.primary,
      color: theme.palette.common.white,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 16 / 12,
      padding: '8px 16px',
    },
    body: {},
    title: {
      fontSize: 11,
      lineHeight: 16 / 11,
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
    },
  });
