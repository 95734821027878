import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import { Button, Icon } from '@crpt-ui/core';
import { getShortDateStringFromStamp } from '../utils';


const DownloadLinkStyles = (theme) =>
  createStyles({
    downloadFile: {
      fontWeight: '600',
      color: theme.palette.secondary.main,
      textTransform: 'lowercase'
    }
  });

const DownloadLinkComponent = ({reportLink, downloadFile, classes}) => {
  const fileName = reportLink.match(/(file-)(.*)(?=\.csv\.zip)/)[0].replace('file-', '')
  return (
    <Button
      style={{fontSize: '12px', padding: '0'}}
      className={classes.downloadFile}
      size="sm"
      variant="text"
      icon={<Icon name="Attach" />}
      onClick={downloadFile}
    >
      {`${fileName}.zip`}
    </Button>
  )
}

export const DownloadLink = withStyles(DownloadLinkStyles)(DownloadLinkComponent);




