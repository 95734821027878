import React from 'react';
import { get } from 'lodash';
import { Grid } from '../Grid/index';
import { columns } from '../Grid/Grid.constants';
import { Dialog } from '@crpt/react-dialog';
import styled from 'styled-components';
import { Toast } from '../../utils/Toast';
import {
  getOrgRoles,
  getToken,
  getUserFromStore,
  isFoivOrOperator,
} from '../../utils/userUtils';
import intersection from 'lodash/intersection';
import ParticipantSelect from '../Select/GridParticipantSelect';
import ProducerSelect from '../Select/GridProducerSelect';
import { PARTICIPANT_TYPES } from '../../constants';
import {
  producerPreferences,
  ownerPreferences,
  STEPS,
} from './CISList.constants';
import { Button } from '@crpt/react-button/lib/index';
import { ContentWrapper, DialogWrapper } from '../common/styled';
import { preparePersisted } from './CISList.utils';
import { windowSize } from '@crpt/relation-tree';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from '../../common_components/Translate/Translate';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

const SelectWrapper = styled.div`
  & > label div div[height='60px'] {
    max-height: 300px;
  }
`;

class CISList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kizes: null,
      isProducer: false,
      isOwner: false,
      participantType: null,
      selectedParticipant: null,
      step: isFoivOrOperator() ? STEPS.SELECT_PARTICIPANT_TYPE : STEPS.FINAL,
      filters: [],
      windowWidth: windowSize().width,
      windowHeight: windowSize().height,
      count: 0,
      isLoading: false
    };
    this.participantSelect = React.createRef();
    this.producerSelect = React.createRef();
    this.gridComponent = React.createRef();
    this._userPreferences = null;

    if (!isFoivOrOperator()) {
      const user = getUserFromStore();
      const orgRoles = getOrgRoles();

      const { inn, full_name, company } = user;
      const name = get(company, 'shortName', full_name);

      this.state.filters = [
        {
          operator: '=',
          column: 'owner',
          filterTerm: inn,
          filterValue: {
            inn: inn,
            name: name,
          },
        },
      ];

      if (
        !!intersection(
          [PARTICIPANT_TYPES.WHOLESALER, PARTICIPANT_TYPES.RETAIL],
          orgRoles,
        ).length
      ) {
        //this.setOwnerPreferences(user.inn, user.full_name);
        this._userPreferences = ownerPreferences;
        this.state.isOwner = true;
      } else {
        if (
          !!intersection(
            [PARTICIPANT_TYPES.PRODUCER, PARTICIPANT_TYPES.IMPORTER],
            orgRoles,
          ).length
        ) {
          //this.setProducerPreferences(user.inn, user.full_name);
          this._userPreferences = producerPreferences;
          this.state.isProducer = true;
          this.state.filters = [
            {
              operator: '=',
              column: 'producerId',
              filterTerm: inn,
              filterValue: {
                inn: inn,
                name: name,
              },
            },
            {
              operator: '=',
              column: 'owner',
              filterTerm: inn,
              filterValue: {
                inn: inn,
                name: name,
              },
            },
          ];
        }
      }
    }

    const filtersPersisted = JSON.parse(
      sessionStorage.getItem('filters') || null,
    );
    if (Array.isArray(filtersPersisted)) {
      this.state.filters = preparePersisted(
        this.state.filters,
        filtersPersisted,
      );
    }
  }

  isWindowResize = () =>  {
    const { windowWidth, windowHeight } = this.state;
    const currentWidth = windowSize().width;
    const currentHeight = windowSize().height;
    let result = false;
    if (windowWidth !== currentWidth || windowHeight !== currentHeight) {
      result = true;
    }
    this.setState({
      windowWidth: currentWidth,
      windowHeight: currentHeight,
    });
    return result;
  };

  onSelectProducer = () => {
    this.setState({
      step: STEPS.SELECT_PARTICIPANT,
      participantType: 'producer',
    });
  };

  onSelectOwner = () => {
    this.setState({
      step: STEPS.SELECT_PARTICIPANT,
      participantType: 'owner',
    });
  };

  onReturnToSelectParticipantType = () => {
    this.participantSelect.current && this.participantSelect.current.clear();
    this.setState({
      step: STEPS.SELECT_PARTICIPANT_TYPE,
      participantType: null,
      selectedParticipant: null,
    });
  };

  onSelectParticipant = () => {
    this.setState({
      step: STEPS.FINAL,
    });

    this.setState({
      filters: [
        //initialDateFilter,
        {
          operator: '=',
          column:
            this.state.participantType === 'owner' ? 'owner' : 'producerId',
          filterTerm: this.state.selectedParticipant.inn,
          filterValue: {
            inn: this.state.selectedParticipant.inn,
            name: this.state.selectedParticipant.name,
          },
        },
      ],
    });
  };

  getDataFromServer = (pageSize, currentPage, sorts, filter, groups, grid) => {
    const requestUrl = '/api/km-grid/getPageData';

    this.request = new AbortController();
    const signal = this.request.signal;
    const myInit = {
      method: 'post',
      signal: signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        pageSize: pageSize,
        currentPage: currentPage,
        filters: filter ? filter : [],
        sorts: sorts,
        groupBy: groups,
      }),
    };
    const myRequest = new Request(requestUrl, myInit);
    return fetch(myRequest);
  };

  onAbort = () => {
    if (this.request) this.request.abort();
  };

  onGridRef = el => {
    this.gridComponent = el;
  };

  onFilterChangeCallback = (column, newFilters) => {
    const { filters } = this.state;
    const isResetNeeded = !filters
      .filter(item => item.column !== column.key)
      .find(item => ['producerId', 'owner'].includes(item.column));

    // действие при удаление фильтра
    if (newFilters === undefined) {
      if (isFoivOrOperator() && isResetNeeded) {
        this.setState({
          step: STEPS.SELECT_PARTICIPANT_TYPE,
          selectedParticipant: null,
          participantType: null,
          filters: [],
        });
        sessionStorage.setItem('filters', null);
      } else {
        const found = filters.find(filter => filter.column === column.key);
        if (found) {
          const updatedFilters = filters.filter(
            filter => filter.column !== column.key,
          );
          this.setState({
            filters: updatedFilters,
          });
          sessionStorage.setItem('filters', JSON.stringify(updatedFilters));
          if (
            isFoivOrOperator() &&
            ['producerId', 'owner'].includes(column.key)
          ) {
            const isResetNeeded = !updatedFilters.some(({ column }) =>
              ['producerId', 'owner'].includes(column),
            );
            if (isResetNeeded) {
              this.setState({
                step: STEPS.SELECT_PARTICIPANT_TYPE,
                selectedParticipant: null,
                participantType: null,
              });
              sessionStorage.setItem('filters', null);
            }
          }
        }
      }
      return;
    }

    // действие при добавление фильтра
    const updatedFilters = filters.filter(
      filter => filter.column !== column.key,
    );
    for (let i = 0; i < newFilters.length; i++) {
      updatedFilters.push({
        operator: newFilters[i].operator,
        column: column.key,
        filterTerm: newFilters[i].filterTerm,
        filterValue: newFilters[i].filterValue,
      });
    }
    this.setState({
      filters: updatedFilters,
    });
    sessionStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  prepareColumns = columns => {
    if (isFoivOrOperator()) {
      return columns;
    }

    const { isOwner, isProducer } = this.state;

    return columns.map(col => {
      if (col.key == 'owner') {
        col.sortableWithFilterOn = true;
        col.filterCannotBeRemoved = true;
        col.filterable = false;
      }

      if (isProducer && col.key == 'producerId') {
        col.sortableWithFilterOn = true;
        col.filterCannotBeRemoved = true;
        col.filterable = false;
      }
      return col;
    });
  };

  handleUpdate = (
    pageSize,
    page,
    sorts,
    filters,
    groups,
    component,
    onPresortPrefilterChange,
  ) => {
    const gridApplyFilters = () => {
      let appliedFilters = {};
      for (let i = 0; i < filters.length; i++) {
        appliedFilters[filters[i].column] = filters[i];
      }
      component.grid.setAppliedFilters(appliedFilters);
      const _preSort = sorts ? sorts : [];
      const _preFilter = filters ? filters : [];
      onPresortPrefilterChange({ _preSort, _preFilter });
    };
    // component.grid.loadStart();
    const requestFilters = filters
      .map(val => {
        const obj = Object.assign({}, val);
        delete obj.filterValue;
        return obj;
      })
      .map(val =>
        val.column === 'packType' && val.filterTerm === 2
          ? { ...val, operator: 'IN', filterTerm: [2, 3] }
          : val,
      );
    // this.onAbort();
    if (!this.isWindowResize()) {
      component.grid.loadStart();
      this.setState({ isLoading: true })
      this.getDataFromServer(
        pageSize,
        page,
        sorts,
        requestFilters,
        groups,
      )
        .then(res => res.json())
        .catch(e => {
          component.grid.loadEnd();
          if (e.name && e.name === 'AbortError') {
            throw new Error('PREVENT_TOAST');
          } else {
            Toast.showError({
              content: i18next.t('Сервер вернул ошибку.'),
            });
          }
          this.setState({ isLoading: false })
        })
        .then(res => {
          this.setState({ isLoading: false })
          if (!component.grid) return;
          try {
            const kizes = res.kizes.map(item => ({
              ...item,
              gtin: {
                gtin: item.gtin,
                name: item.productName,
              },
              date: item.emissionDate || item.applicationDate,
            }));
            this.setState({ kizes })
            try {
              component.grid.setData({
                rows: kizes,
                pageNum: page + 1,
                pageSize: pageSize,
              });
            } catch (e) {
              throw new TypeError('Can not read/set properties');
            }
            this.setState({ count: res.count });
            component.grid.setCount(res.count, res.accurate);
            gridApplyFilters();
            if (component.grid) component.grid.loadEnd();
          } catch (e) {
            console.log('e',e);
            if (e.message !== 'Can not read/set properties') {
              Toast.showError({
                content: i18next.t('Неизвестная ошибка'),
              });
            }
            component.grid.loadEnd();
          }
        })
        .catch(e => {
          this.setState({ isLoading: false })
          if (e.message === 'PREVENT_TOAST') {
            return;
          }
          component.grid.loadEnd();
          console.log('Запрос отменен.');
          if (e.name && e.name === 'AbortError') {
            Toast.showCancelable({
              content: i18next.t('Запрос отменен.'),
              closeOnClick: true,
              closeButton: true,
              autoClose: 3000,
            });
          } else {
            Toast.showError({
              content: i18next.t('Сервер вернул ошибку.'),
            });
          }
        });
    } else {
      !this.state.isLoading && gridApplyFilters();
      // component.grid.loadEnd();
    }
  }

  render() {
    const { countryCode } = this.props;
    const { filters, kizes } = this.state;
    const preparedColumns = this.prepareColumns(columns);
    const Container = this.state.step === STEPS.FINAL ? ContentWrapper : DialogWrapper;

    return (
      <Container countryCode={countryCode}>
        <Dialog
          isOpen={this.state.step === STEPS.SELECT_PARTICIPANT_TYPE}
          title={Translate('Начало работы')}
          // minWidth={'482px'}
          // maxWidth={'482px'}
          minWidth={482}
          maxWidth={482}
          ButtonComponent={Button}
          content={
            <div>
              <Trans>
                Для того, чтобы создать таблицу,
                <br />
                выберите ведущий раздел фильтрации
              </Trans>
            </div>
          }
          onOverlayClick={() => {}}
          buttons={[
            {
              children: Translate('Производитель'),
              marginRight: 24,
              onClick: this.onSelectProducer,
            },
            {
              children: Translate('Текущий владелец'),
              onClick: this.onSelectOwner,
            },
          ]}
        />

        <Dialog
          isOpen={this.state.step === STEPS.SELECT_PARTICIPANT}
          // minWidth={'482px'}
          // maxWidth={'482px'}
          minWidth={482}
          maxWidth={482}
          title={
            this.state.participantType === 'owner'
              ? Translate('Текущий владелец')
              : Translate('Производитель')
          }
          ButtonComponent={Button}
          content={
            <div>
              <div>{Translate('Введите или выберите из списка')}</div>
              <div style={{ marginBottom: '24px' }}>
                {Translate('наименование')}{' '}
                {this.state.participantType === 'owner'
                  ? Translate('текущего владельца')
                  : Translate('производителя')}
              </div>
              {this.state.participantType === 'owner' ? (
                <SelectWrapper>
                  <ParticipantSelect
                    onRef={el => (this.participantSelect.current = el)}
                    onSelect={val =>
                      this.setState({ selectedParticipant: val })
                    }
                    placeholder={Translate('Текущий владелец')}
                  />
                </SelectWrapper>
              ) : (
                <SelectWrapper>
                  <ProducerSelect
                    onRef={el => (this.producerSelect.current = el)}
                    onSelect={val =>
                      this.setState({ selectedParticipant: val })
                    }
                    placeholder={Translate('Производитель')}
                  />
                </SelectWrapper>
              )}
            </div>
          }
          onOverlayClick={() => {}}
          buttons={[
            {
              children: Translate('Сбросить'),
              marginRight: 24,
              onClick: this.onReturnToSelectParticipantType,
            },
            {
              children: Translate('Применить'),
              onClick: this.onSelectParticipant,
              disabled: !this.state.selectedParticipant,
            },
          ]}
        />
        {this.state.step === STEPS.FINAL && (
          <div>
            <Grid
              gridRef={this.onGridRef}
              filters={filters}
              title={Translate('Коды маркировки')}
              columns={preparedColumns}
              userPreferences={this._userPreferences}
              pageSize={20}
              onAbort={this.onAbort}
              onFilterChangeCallback={this.onFilterChangeCallback}
              onUpdate={this.handleUpdate}
              total={this.state.count}
              kizes={kizes}
            />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  countryCode: state.config.countryCode,
});

export default compose(connect(mapStateToProps), withRouter)(CISList);
