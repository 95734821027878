/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import { get, some } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { RangePicker } from '@crpt/react-datepicker';
import { defaultDatepickerTheme } from '../../theme/ru-tabaco/datepicker';
import * as actions from '../../motp_components/_Requests/ReceiptList/ducks/ReceiptList.actions';
import * as listActions from '../../common_components/List/ducks/List.actions';
import * as selectors from '../../motp_components/_Requests/ReceiptList/ducks/ReceiptList.selectors';
import * as sidebarActions from '../../common_components/Sidebar/ducks/Sidebar.actions';
// import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from '../../common_components/Preloader/ducks/Preloader.selectors';
import RequestListView from '../../motp_components/_Requests/RequestList/RequestList.view';
import {
  limit,
  columns,
  options,
} from '../../motp_components/_Requests/ReceiptList/ReceiptList.constants';
import RemoteButtons from '../Details/common/RemoteButtons';
import { ContentWrapper, Height20px } from '../common/styled';
import ElementsCount from '../common/Table/ElementsCount';
import { defaultDatepickerThemeKZ } from '../../theme/kz-tobaco/datepicker';
import { defaultDatepickerThemeUZ } from '../../theme/uz-tobaco/datepicker';
import { Translate } from '../../common_components/Translate/Translate';
import { countryCodeFlags } from '../../constants';

class Receipts extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    last: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pagesCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    filters: PropTypes.shape({}).isRequired,
    sorting: PropTypes.shape({}).isRequired,
    onMount: PropTypes.func.isRequired,
    onListMount: PropTypes.func.isRequired,
    // roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterApply: PropTypes.func.isRequired,
    onOpenSidebar: PropTypes.func.isRequired,
    // onOpenDocument: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { history, onMount, onListMount, onFilterApply, inn } = this.props;
    // eslint-disable-next-line
    if (/[\?&]{1}page=/.test(location.search)) {
      history.replace(`/participants/list/${inn}/receipts`);
    }
    onMount();
    onFilterApply({ senderInn: inn });
    //onListMount();
  }

  componentWillUnmount() {
    this.props.onUnMount();
  }

  getTdProps = (state, rowInfo, cellInfo) => {
    const { history, onOpenDocument } = this.props;
    const { id } = cellInfo;
    if (id !== 'number') return undefined;

    const type = get(rowInfo, 'original.type', '');
    const number = get(rowInfo, 'original.number', '');

    const match = type.match(/.*_(XML|CSV)/);

    let onClick = () => null;

    if (match) {
      onClick = () => onOpenDocument(number, match[1], type);
    } else if (number) {
      onClick = () => history.push(`/documents/list/${number}`);
    }

    return {
      onClick,
    };
  };

  getTheadThProps = (state, _, column) => {
    const { sorting } = this.props;
    const { order, orderColumn } = sorting;
    const { id } = column;

    if (id === orderColumn) {
      return {
        className: `-sort-${order.toLowerCase()}`,
      };
    }

    return {};
  };

  getLeftActionBarItems = () => {
    const { actionBarLeft } = this.props;
    return actionBarLeft;
  };

  // handleMenuToggle = menuName => () => {
  //   this.setState({
  //     [`is${menuName}MenuOpen`]: !this.state[`is${menuName}MenuOpen`],
  //   });
  // };

  // handleMenuClose = () => () => {
  //   this.setState({ isAddMenuOpen: false, isUploadMenuOpen: false });
  // };

  onFilterApply = params => {
    const { inn, onFilterApply, selectedPage } = this.props;
    const filterParams = {
      senderInn: inn,
      page: 1,
    };
    const { date } = params;
    const { from, to } = Array.isArray(date) && date.length ? date[0] : {};
    if (from)
      filterParams.dateFrom = moment(from, 'YYYY.MM.DD')
        .utc(true)
        .format();
    if (to)
      filterParams.dateTo = moment(to, 'YYYY.MM.DD')
        .endOf('day')
        .utc(true)
        .format();
    onFilterApply(filterParams);
  };

  onRef = el => {
    console.log('onRef', el);
    this.popoverContainer = el;
  };

  // onRowMouseEnter = e => {
  //   this.setState({ cellEvent: e });
  // };

  // onRowMouseLeave = () => this.setState({ cellEvent: null });

  render() {
    const {
      results,
      pagesCount,
      selectedPage,
      isLoading,
      last,
      portalFilterButtonId,
      total,
    } = this.props;
    const countryCode = window.env.ELK_COUNTRY;

    const columnsExceptParticipants = columns.filter(
      c => c.id !== 'senderName',
    );

    return (
      <ContentWrapper
        theme={{ Content: { backgroundColor: 'white', marginTop: '10px' } }}
        minHeight={isLoading}
      >
        <RemoteButtons
          // showDownloadListButton={showDownloadListButton}
          // popupMenu={popupMenu}
          filterFields={[
            null,
            [
              {
                component: RangePicker,
                props: {
                  inline: false,
                  name: 'date',
                  placeholder: 'Дата',
                  format: 'DD.MM.YYYY',
                  positionX: 'left',
                  controls: true,
                  acceptText: Translate('Применить'),
                  resetText: Translate('Сбросить'),
                  theme: {
                    DatePicker:
                      countryCode === 'KZ'
                        ? defaultDatepickerThemeKZ
                        : defaultDatepickerThemeUZ,
                  },
                },
              },
            ],
          ]}
          onFilterApply={this.onFilterApply}
          //downloadButtonId={portalDownloadButtonId}
          filterButtonId={portalFilterButtonId}
        />
        <Height20px />
        {isLoading || countryCodeFlags.isUZ ? null : <ElementsCount value={total} />}
        <RequestListView
          onRef={this.onRef}
          last={last}
          cellEvent={null}
          results={results}
          pagesCount={pagesCount}
          selectedPage={selectedPage}
          columns={columnsExceptParticipants}
          options={options}
          getTdProps={this.getTdProps}
          // getTrProps={(state, rowInfo) => ({
          //   onMouseEnter: () => this.onRowMouseEnter(rowInfo),
          //   onMouseLeave: this.onRowMouseLeave,
          //   ['data-row-document-id']: rowInfo.original.id,
          // })}
          getTheadThProps={this.getTheadThProps}
          notifyErrors={[]}
          meta={actions}
          isLoading={isLoading}
          pageCounterInvisible
        />
      </ContentWrapper>
    );
  }
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: 999999999999, //selectors.pagesCount(state),
    last: selectors.results(state).length < limit, //selectors.last(state),
    filters: selectors.filters(state),
    total: selectors.total(state),
    sorting: selectors.sorting(state),
    // roles: authSelectors.roles(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
  };
};

const mapDispatch = dispatch => ({
  onClear: params => dispatch(actions.filters(params)),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onErrors: val => dispatch(actions.gotErrors(val)),
  // onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
});

export default compose(connect(mapState, mapDispatch), withRouter)(Receipts);
