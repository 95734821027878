import React, { useEffect, useState, useMemo } from 'react';
import { ContentWrapper } from '../common/styled';
import { Button } from '@crpt/react-button';
import { Translate } from '../../common_components/Translate/Translate';
import { Icon } from '@crpt/react-icon';
import { Icon as MaterialIcon } from '@material-ui/core';
import ActionBar from '../common/ActionBar';
import * as actions from './ducks/articles.actions';
import * as actionsCreate from '../ArticleCreation/ducks/articlesCreation.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withPermissions from '../../common_components/hocs/withPermissions';
import { permissionConfig } from '../../motp_components/_Requests/RequestList/RequestList.permissions';
import Datatable from '@crpt-ui/datatable';
import { getColumns, INITIAL_TABLE_STATE } from './constants';
import { history } from '../../store';
import { SelectButton } from '../ArticleCreation/components/select-button';
import UZ from '../../common_components/Icons/article/uz.svg';
import RU from '../../common_components/Icons/article/ru.svg';
import { Filter } from './components/filter';

const ArticleListPage = ({ loadList, list, setItemForEdit }) => {
  const [lang, setLang] = useState('ru');
  const [openFilter, setOpenFilter] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = useState({});

  useEffect(() => {
    if (list) {
      setData([...list]);
    }
  }, [list]);

  useEffect(() => {
    const order = state.order ? state.order : null;
    const status = state.status ? state.status : null;
    const type = state.type ? state.type : null;
    const result = list ? list.filter((item) => {
      if (order && item.order !== order) return false;
      if (status && item.status !== status) return false;
      return !(type && item.itemType !== type);
    }) : [];

    setData([...result]);
  }, [state]);

  useEffect(() => {
    loadList(lang);
    setState({});
  }, [lang])

  const rightSideComponents = [
    {
      component: (
        <Button
          onClick={()=> setOpenFilter(true)}
        >
          {Translate('Фильтр')}
          <Icon type="settings" spacingLeft={16} />
        </Button>
      ),
      wrap: true,
    },
  ];
  const leftSideComponents = [
    {
      component: (
        <Button
          onClick={()=> history.push('/mobile-content/articles/create')}
        >
          {Translate('Новая статья')}
          <Icon type="plus" spacingLeft={16} />
        </Button>
      ),
      wrap: true,
    },
  ];

  const onEdit = (item) => {
    history.push(`/mobile-content/articles/update/${lang}/${item.id}`)
  }

  const onCopy = (item) => {
    history.push(`/mobile-content/articles/copy/${lang}/${item.id}`)
  }

  const onPreview = (item) => {
    history.push(`/mobile-content/articles/preview/${item.id}?lang=${lang}`)
  }

  const columns = useMemo(()=> getColumns(onCopy, onEdit, lang, onPreview),[lang]);

  return (
    <div>
      <Filter
        onFilterApply={setState}
        onClose={() => {setOpenFilter(false)}}
        open={openFilter}
        filters={state}
      />
      <div style={{ display: 'flex', gap: 10, paddingBottom: 20 }}>
        <SelectButton
          onClick={() => setLang('uz')}
          title={"Узбекский"}
          selected={lang === 'uz'}
          icon={<MaterialIcon component={UZ}/>}
        />
        <SelectButton
          onClick={() => setLang('ru')}
          title={"Русский"}
          selected={lang === 'ru'}
          icon={<MaterialIcon component={RU}/>}
        />
      </div>
      <ContentWrapper hasMrginTop>
        <ActionBar
          leftSideComponents={leftSideComponents}
          rightSideComponents={rightSideComponents}
        />
        <div className="hiddenScrollBar">
          <Datatable
            initialState={INITIAL_TABLE_STATE}
            columns={columns}
            useSort
            data={data || []}
            height={(data.length + 1) * 64}
          />
        </div>
      </ContentWrapper>
    </div>
  )
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  list: state.LP.ArticleListPage.list,
});

const mapDispatch = dispatch => ({
  loadList: (lang) => dispatch(actions.loadList(lang)),
  setItemForEdit: (item) => dispatch(actionsCreate.setItem(item)),
});


export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
)(ArticleListPage);
