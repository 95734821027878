import { createAction } from 'redux-actions';

export const subject = '[ImportDocument]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const upload = createAction(`${subject} upload`);
export const submit = createAction(`${subject} submit`);
export const setError = createAction(`${subject} setErrorInfo`);
export const openDraft = createAction(`${subject} open draft`);
