import React from 'react';
import { Translate } from '../../../../common_components/Translate/Translate';
import { AdditionalBottomBarContent } from './utls';
import MenuButton from '../../../../common_components/Buttons/MenuButton';
import { ChevronDown } from '@crpt-ui/icons';


const AdditionalBottomBar = ({ formMutators, formValues, selectedRows }) => {
  const setFlag = (val) => {
    formValues.products && formMutators.updateField('products', formValues.products.map(el => {
      if (selectedRows.includes(el.cis)) {
        return ({
          ...el,
          accepted: val === "accept"
        })
      }
      return el;
    }))
  }

  return (
    <AdditionalBottomBarContent>
      <MenuButton
        menu={[
          {
            title: Translate('Принять выбранные'),
            onClick: () => setFlag('accept'),
          },
          {
            title: Translate('Отклонить выбранные'),
            onClick: () => setFlag('reject'),
          },
        ]}
        disabled={selectedRows.length === 0}
        positionY="bottom"
        variant="contained"
        color="primary"
      >
        <span style={{ fontSize: '14px', fontWeight: '700', margin: '8px' }}>
          {Translate('ДЕЙСТВИЕ')}
        </span>
        <ChevronDown />
      </MenuButton>
    </AdditionalBottomBarContent>
  )
}

export default AdditionalBottomBar;
