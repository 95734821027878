import React from 'react';
import { columnsCisTable, GoodsWrapper } from './ExportEAES.constants';
import Datatable from '@crpt-ui/datatable';
import { TableWrap } from '../Act/Showing.styled';

const CisList = ({ list = [] }) => {
  return (
    <GoodsWrapper>
      <TableWrap>
        <Datatable
          columns={columnsCisTable}
          useSort
          data={list}
        />
      </TableWrap>
    </GoodsWrapper>
  )
}

export default CisList;
