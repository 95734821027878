import { createStyles } from '@material-ui/core';
import React from 'react';

export const CardInfoStyles = (theme) =>
  createStyles({
    overflowWrapper: {
      overflowX: 'auto',
    },
    contentWrapper: {
      maxWidth: '960px',
      minWidth: '960px',
      padding: '40px',
      display:'flex',
      flexDirection: 'column'
    },
    reformDocument: {
      fontWeight: '600',
    },
    documentInfoWrapper: {
      width: '100%',
    },
    notificationWrapper: {
      display:'flex',
      marginBottom: '40px',
    },
    groupInfoWrapper: {
      marginBottom: '30px',
    },
    groupTitleWrapper: {
      marginBottom: '20px'
    },
    groupTitleHeaderWrapper: {
      marginBottom: '40px'
    },
    groupItemWrapper: {
      marginBottom: '10px'
    },
    infoIconWrapper: {
      color: theme.palette.secondary.main,
      marginRight: '10px'
    },
    infoAttention: {
      color:theme.palette.text.primary,
    }
  });


export const FileInfoStyles = () =>
  createStyles({
    groupItemWrapper: {
      marginBottom: '10px'
    },
  });
