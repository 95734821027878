import { actionTypes, } from '../constants';

function sidebarOpen(content, theme) {
  return {
    type: actionTypes.SIDEBAR.SIDEBAR_OPEN,
    content,
    theme
  };
}

function sidebarClose() {
  return {
    type: actionTypes.SIDEBAR.SIDEBAR_CLOSE,
  };
}

function sidebarClosed() {
  return {
    type: actionTypes.SIDEBAR.SIDEBAR_CLOSED,
  };
}

function sidebarClosing() {
  return (dispatch) => {
    dispatch(sidebarClose());

    setTimeout(() => {
      dispatch(sidebarClosed());
    }, 500);
  };
}

export { sidebarOpen, sidebarClosing, };
