import styled from 'styled-components';

export const ScanningWrapper = styled.div`
`;

export const ScanResultsWrap = styled.div`
  margin: 20px 0 20px;
`;

export const ScanningTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const ActionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ScanError = styled.div`
    color: red;
    font-weight: 600;
    margin-left: 15px;
`;

export const TableWrap = styled.div`
  & div[role="rowgroup"] {
    height: auto;
  }
`;
