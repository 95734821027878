import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import InfoLabel from './InfoLabel/InfoLabel';
import { Wrap } from '../_refactor/Fields.styled';


const PureInfoLabel = memo(InfoLabel);

// const Timezone = styled.div`
//   color: #abadb6;
// `;

class InfoLabelAdapter extends React.Component {
  static propTypes = {
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  static defaultProps = {
    marginBottom: '24px',
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    dateFormat: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
  };

  state = {
    value: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    if ((state.value === undefined) || (props.fieldInput.value !== state.value)) {
      const { fieldInput = {}, defaultValue } = props;
      const { value } = fieldInput;

      const newValue = value || defaultValue;

      return { value: newValue };
    }
    return null;
  }

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dateFormat,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;
    const { value } = this.state;

    if (!value) return (null);

    return (
      <Wrap marginBottom={marginBottom}>
        <PureInfoLabel value={value} {...passProps} />
      </Wrap>
    );
  }
}

export default InfoLabelAdapter;
