import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import moment from 'moment/moment';
import styled from 'styled-components';
import { TwoLineLabel } from '@crpt/react-label';
import { Wrap } from '../_refactor/Fields.styled';

const PureTwoLineLabel = memo(TwoLineLabel);

const Timezone = styled.div`
  color: #abadb6;
`;

class TwoLineLabelAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: '24px',
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    dateFormat: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
  };

  state = {
    value: undefined,
    content: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.fieldInput.value !== state.value) {
      const {
        fieldInput = {},
        dateFormat,
        values,
        selectedId,
        defaultValue,
        changeFormatValue,
      } = props;
      const { value } = fieldInput;
      const newValue = value || defaultValue || selectedId;

      let content;

      if (newValue && values) {
        const selectedItem = values.find(v => v.type === newValue);
        content = selectedItem ? selectedItem.title : newValue;
        content = changeFormatValue ? changeFormatValue(newValue) : content;
      } else if (newValue && dateFormat) {
        if (isString(dateFormat)) {
          content = moment(newValue).format(dateFormat);
        } else {
          const date = moment(newValue).format('DD MMMM YYYY в HH:mm:ss');
          const timezone = moment(newValue).format('Z UTC');

          content = (
            <React.Fragment>
              {date}
              <Timezone>{`\u00A0${timezone}`}</Timezone>
            </React.Fragment>
          );
        }
      } else {
        content = newValue;
      }

      return {
        value: newValue,
        content,
      };
    }

    return null;
  }

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError &&
      fieldMeta &&
      fieldMeta.dirtySinceLastSubmit &&
      !fieldMeta.error
    )
      return false;
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total } = this.props;
    const isError = this.isError();
    const newPlaceholder =
      isError && errorPlaceholder ? errorPlaceholder : placeholder;

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dateFormat,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;
    const { content } = this.state;

    return (
      <Wrap marginBottom={marginBottom}>
        <PureTwoLineLabel heading={this.getPlaceholder()} {...passProps}>
          {content || '—'}
        </PureTwoLineLabel>
      </Wrap>
    );
  }
}

export default TwoLineLabelAdapter;
