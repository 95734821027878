import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Arrow from './Arrow';
import List from './List';

import {
  DropdownWrapper,
  ContentWrapper,
  ControlWrapper,
  HeaderWrapper,
} from './styled';

class Dropdown extends Component {
  blurTimeout;

  static propTypes = {
    header: PropTypes.element.isRequired,
    headerHeight: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          icon: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          active: PropTypes.bool,
          onClick: PropTypes.func,
        }),
        PropTypes.element,
      ])
    ).isRequired,
    Control: PropTypes.element,
    controlled: PropTypes.bool,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    headerHeight: null,
    Control: null,
    controlled: false,
    visible: false,
  };

  state = {
    isOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.controlled) {
      if (state.isOpen !== props.visible) {
        return { isOpen: props.visible };
      }
    }
    return null;
  }

  onClick = () => {
    if (this.blurTimeout) clearTimeout(this.blurTimeout);

    const { controlled } = this.props;
    if (controlled) return;

    this.setState(({ isOpen: oldIsOpen }) => ({ isOpen: !oldIsOpen }));
  };

  onBlur = () => {
    if (this.blurTimeout) clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => this.setState({ isOpen: false }), 200);
  };

  renderControl = () => {
    const { Control } = this.props;
    const { isOpen } = this.state;

    if (!Control) {
      return <Arrow arrowDirection={isOpen ? 'up' : 'down'} />;
    }

    return Control;
  };

  render() {
    const { isOpen } = this.state;
    const { header, headerHeight, items } = this.props;

    return (
      <DropdownWrapper
        isOpen
        onClick={this.onClick}
        onBlur={this.onBlur}
        tabIndex={0}
      >
        <ContentWrapper>
          <HeaderWrapper>{header}</HeaderWrapper>
          <ControlWrapper>{this.renderControl()}</ControlWrapper>
        </ContentWrapper>
        {isOpen && <List items={items} headerHeight={headerHeight} />}
      </DropdownWrapper>
    );
  }
}

export default Dropdown;
