import React from 'react';
import PropTypes from 'prop-types';
import Form from 'src/common_components/Form/Form';
import FormContextWrapper from 'src/common_components/Form/FormContextWrapper';
import Preloader from 'src/common_components/Preloader/Preloader';
import Preview from '../Document/Preview/Preview';
import Breadcrumbs from 'src/common_components/Breadcrumbs/Breadcrumbs';
import Information from './Information/Information';
import Goods from './Goods/Goods';
import { steps } from './ImportThirdCountry.constants';
import CisTotal from './Cis/cisTotal';

const ImportThirdCountryView = ({
  data,
  breadcrumbs,
  onSubmit,
  onPreview,
  loaded,
  isCreate,
  isLoading,
  step,
  onSetStep,
  onSaveDraft,
  onCloseNotification,
  hasPaperDoc,
  handleCheckboxChange,
}) => {
  return (
    <React.Fragment>
      <Preview onSubmit={onSubmit} />

      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <Form
        data={isCreate ? {} : data}
        onSubmit={onSubmit}
        disabled={!isCreate && !loaded}
      >
        {isLoading && <Preloader />}
        {!isLoading && (
          <FormContextWrapper>
            {
              step === steps.info ? (
                <Information
                  loaded={loaded}
                  onSetStep={onSetStep}
                  onSaveDraft={onSaveDraft}
                  onCloseNotification={onCloseNotification}
                  handleCheckboxChange={handleCheckboxChange}
                  hasPaperDoc={hasPaperDoc}
                  show
                />
              ) : <div />
            }
            {step === steps.goods ? (
              <Goods
                loaded={loaded}
                onSetStep={onSetStep}
                onSaveDraft={onSaveDraft}
                onCloseNotification={onCloseNotification}
                show
              />
            ) : <div />}
            {step === steps.cis ? (
              <CisTotal
                loaded={loaded}
                onSetStep={onSetStep}
                onSaveDraft={onSaveDraft}
                onCloseNotification={onCloseNotification}
                show
              />
            ) : <div />}
          </FormContextWrapper>
        )}
      </Form>
    </React.Fragment>
  );
};
ImportThirdCountryView.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loaded: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(Object.values(steps)).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onCloseNotification: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSetStep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
export default ImportThirdCountryView;
