import { handleActions } from 'redux-actions';
import * as actions from './CheckStatus.actions';

const error = handleActions(
  {
    [actions.error]: (_, { payload }) => payload || '',
    [actions.unmount]: () => '',
    [actions.success]: () => '',
  },
  ''
);

const succes = handleActions(
  {
    [actions.success]: () => true,
    [actions.unmount]: () => false,
  },
  false
);

export default {
  error,
  succes,
};
