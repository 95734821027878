import React from 'react';
import { Button } from '@crpt/react-button';
import { buttonTabItemTheme } from '../../../../../../../theme/ru-tabaco/button';
import {
  ResetApplyWrapper,
  ResetApplyBtns,
  ResetApplyResetBtn,
} from './ResetApply.styled';


const ResetApply = () => (
  <ResetApplyWrapper>
    <ResetApplyBtns>
      <ResetApplyResetBtn>
        Сбросить
      </ResetApplyResetBtn>
      <Button
        theme={{ Button: buttonTabItemTheme}}
        style={{pointerEvents: 'none'}}
      >
        Применить
      </Button>
    </ResetApplyBtns>
  </ResetApplyWrapper>
);

export default ResetApply;