import { css } from 'styled-components';

import TableCell from './TableCell';

const { style } = TableCell;

const TableHeaderCell = {
  style: css`
    ${style}

    position: relative;

    padding: 23px 16px;

    border-right: 1px solid rgba(196, 196, 196, 0.25);
    border-top: 1px solid rgba(196, 196, 196, 0.25);

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    &.-cursor-pointer {
      cursor: pointer;
    }

    &:last-child {
      border-right: 0;
    }
  `,
};

export default TableHeaderCell;
