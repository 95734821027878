import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Datatable from '@crpt-ui/datatable';
import { Pagination } from '@crpt-ui/core';

import * as actions from '../ducks/DocumentDetails.actions';
import * as selectors from '../ducks/DocumentDetails.selectors';
import { columns, limit } from './Codes.constants';
import { TableWrap } from '../DocumentDetails.styled';
import Preloader from 'src/common_components/Preloader/Preloader';
import { countryCodeFlags, documentTypes } from '../../../constants';
import { useTranslation } from 'react-i18next';

const docsWithIssuer = [documentTypes.MTTPS_90, documentTypes.AGGREGATION_DOCUMENT, documentTypes.WRITE_OFF]

const Codes = ({ documentId, loaded, type, codes, ...rest }) => {
  const instance = useRef();
  const {t} = useTranslation();
  const [activePage, setActivePage] = useState(1);

  const updateHandler = useCallback(() => {
    const { onUpdate } = rest;
    const params = {
      documentId,
      limit,
      offset: (activePage - 1) * limit,
      needTotal: true,
    };
    onUpdate(params);
  }, [activePage]);

  useEffect(() => {
    updateHandler();
  }, [activePage]);

  const { results, total, last } = codes;

  const handlePageChange = useCallback(pageNumber => {
    setActivePage(pageNumber);
  }, []);

  const processedCols = useMemo(() =>
    docsWithIssuer.includes(type) && countryCodeFlags.isUZ
      ? columns.map(el=> el.id === 'producerName' ? ({ ...el, Header: t('Эмитент') }): el)
      : columns,
    [type, t])

  return (
    <React.Fragment>
      {!loaded ? <Preloader isOpened={true} /> :
        (<TableWrap>
          <Datatable
            ref={instance}
            columns={processedCols}
            data={results || []}
            usePagination
            // useSort
            manualPagination={false}
            manualSorting={false}
            initialState={{
              pageSize: limit,
            }}
          />
          {total > 1 && <div style={{
            display:'flex',
            padding: '45px 25px 20px',
          }}>
            <Pagination
              page={activePage}
              pagesCount={Math.ceil((total || limit) / limit)}
              onPageChange={handlePageChange}
            />
          </div>}
        </TableWrap>)}
    </React.Fragment>
  );
};

Codes.propTypes = {
  loaded: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
  type: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: selectors.loadedCodes(state),
  codes: selectors.codes(state),
});

const mapDispatch = dispatch => ({
  onUpdate: params => dispatch(actions.updateCodes(params)),
});

export default connect(mapState, mapDispatch)(Codes);
