import { Icon } from '@crpt-ui/core';
import React from 'react';

import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const WriteOff = ({ op }) => {
  return (
    <Layout>
      <FirstCell
        icon={<Icon name="CodeDecommissioned" />}
        title={Translate('Списание')}
      />
      <SecondCell op={op} />
      <ThirdCell useLink op={op} heading={Translate('Документ о списании')} />
    </Layout>
  );
};

export default WriteOff;
