import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        },
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': 'ИНН и наименование производителя товара',
        },
        {
          'col1': [3],
          'col2': 'Код подакцизного товара из справочника',
        },
        {
          'col1': [4, 5],
          'col2': 'Уникальный код и наименование товара',
        },
        {
          'col1': [6],
          'col2': 'Максимальная розничная цена товара, тенге.',
        },
        {
          'col1': [7],
          'col2': 'Период производства товара, в котором действует указанная МРЦ',
        },
        {
          'col1': [8],
          'col2': 'Номер декларации на товары или номер заявления о ввозе товаров',
        },
        {
          'col1': [9],
          'col2': 'Количество товара по соответствующему номеру декларации на товары или номеру заявления о ввозе товаров',
        },
        {
          'col1': [10],
          'col2': 'Сумма акциза, тенге.: MAX([16], [17])',
        },
        {
          'col1': [11],
          'col2': <div>Объём реализованных табачных изделий, тыс. шт. = [12] <strong>×</strong> [13] / 1000</div>,
        },
        {
          'col1': [12],
          'col2': 'Количество единиц реализованных потребительских упаковок (пачек) за выбранный период',
        },
        {
          'col1': [13],
          'col2': 'Количество единиц сигарет в одной потребительской упаковке (пачке)',
        },
        {
          'col1': [14],
          'col2': <div>Расчётная стоимость реализованных табачных изделий, тенге. = [6] <strong>×</strong> [12]</div>,
        },
        {
          'col1': [15],
          'col2': <div>Сумма акциза, рассчитанная исходя из твёрдой налоговой ставки, тенге.: твёрдая ставка <strong>×</strong> [11]</div>,
        },
        {
          'col1': [16],
          'col2': <div>Сумма акциза, рассчитанная исходя из адвалорной налоговой ставки, тенге.: [14] <strong>×</strong> адвалорная ставка</div>,
        },
        {
          'col1': [17],
          'col2': 'Сумма акциза, рассчитанная исходя из комбинированной ставки, тенге.: [15] + [16]',
        },
        {
          'col1': [18],
          'col2': <div>Сумма акциза, рассчитанная исходя из минимальной ставки, тенге.: минимальная ставка <strong>×</strong> [11]</div>,
        },
      ]
    }
  ]
}
