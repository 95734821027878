import styled from 'styled-components';

export const UserListItemButtonsWrap = styled.div`
  position: absolute;
  right: 36px;
  top: 0px;
  bottom: 0px;
  display: none;
  align-items: center;
`;
