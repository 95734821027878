import {combineReducers} from 'redux';
import {actionTypes} from '../../constants';

function active(state = false, action = {}) {
  switch (action.type) {
    case actionTypes.SIDEBAR.SIDEBAR_OPEN:
      return true;
    case actionTypes.SIDEBAR.SIDEBAR_CLOSE:
      return false;
    default:
      return state;
  }
}

function content(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.SIDEBAR.SIDEBAR_OPEN:
      return action.content;
    case actionTypes.SIDEBAR.SIDEBAR_CLOSED:
      return null;
    default:
      return state;
  }
}

function theme(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.SIDEBAR.SIDEBAR_OPEN:
      return action.theme ? action.theme : null;
    case actionTypes.SIDEBAR.SIDEBAR_CLOSED:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  active, content, theme
});
