import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';

export default {
  title: Translate('Табличное представление при типе агрегации «Детализация по часам»:'),
  amountOfTable: 1,
  tables: [
    // table#1
    {
      preTable: [
        {
          name: '',
          width: 820
        },
        {
          name: Translate('Количество кодов'),
          width: 200
        },
      ],
      headers: [
        {
          Header: Translate('ИНН'),
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: Translate('Наименование'),
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
        },
        {
          Header: 'Час',
          accessor: 'col14',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
        },
        {
          Header: 'GTIN',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: Translate('Наименование товара'),
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
        },
        {
          Header: Translate('Тип упаковки'),
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
        },
        {
          Header: Translate('Эмитировано за текущий период'),
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col14': [14],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col14': '',
          'col4': '',
          'col5': '',
          'col6': '',
          'col7': <strong>...</strong>,
        }
      ]
    },
    // table#2
    {
      preTable: [
        {
          name: Translate('Количество кодов'),
          width: 880
        },
        {
          name: '',
          width: 140
        },
      ],
      headers: [
        {
          Header: Translate('Нанесено за текущий период'),
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
        },
        {
          Header: Translate('Нанесено и оплачено'),
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: [
            {
              Header: Translate('За текущий период'),
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 150,
            },
            {
              Header: Translate('Нанесение в предыдущем периоде, оплата в текущем периоде'),
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 320,
            },
          ]
        },
        {
          Header: '',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
              Header: Translate('Нанесено и не оплачено на конец периода (всего)'),
              accessor: 'col11',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 240,
            }
          ]
        },
        {
          Header: '',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 140,
          subheaders: [
            {
              Header: Translate('Стоимость кодов'),
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
            }
          ]
        },
      ],
      data: [
        {
          'col8': [8],
          'col9': [9],
          'col10': [10],
          'col11': [11],
          'col12': [12],
        },
        {
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
        },
      ]
    },
  ]
}
