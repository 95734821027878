import { createAction } from 'redux-actions';

export const subject = '[EaesImportDocument]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const upload = createAction(`${subject} upload`);
export const submit = createAction(`${subject} submit`);
export const setError = createAction(`${subject} setErrorInfo`);
export const openDraft = createAction(`${subject} open draft`);
export const signDecision = createAction(`${subject} sign decision`);
export const correctData = createAction(`${subject} load correct data`);
export const countTotalsKZ = createAction(`${subject} count totals KZ`);
