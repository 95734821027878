import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { RangePicker } from '@crpt/react-datepicker';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { isEmpty } from 'lodash';
import StructureItem from './StructureItem';
import * as cisPropTypes from '../altPropTypes';
import { Wrap, Title, Content } from './styled';
import SidebarContentBound from '../../../containers/Bounds/SidebarContentBound';
import FilterSidebar from './FilterSidebar';
import Filter from '../../common/Table/Buttons/Filter';
import ActionBar from '../../common/ActionBar';
import { defaultDatepickerTheme } from '../../../theme/ru-tabaco/datepicker';
import { FormSerializer } from '../../../utils/FormSerializer';

import {
  CISPackageSelect,
  CISStatusSelect,
  ParticipantSelect,
  ProductSelect,
} from '../../Select';

class StructureAndDynamic extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      Sidebar: PropTypes.shape({
        sidebarOpen: PropTypes.func.isRequired,
        sidebarClosing: PropTypes.func.isRequired,
      }).isRequired,
      AltScenarios: PropTypes.shape({}).isRequired,
    }).isRequired,
    scenarios: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(cisPropTypes.item).isRequired,
      }),
    ),
    history: PropTypes.shape({}).isRequired,
    filterParams: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      lastChangedTo: null,
      lastChangedFrom: null,
      format: 'DD.MM.YYYY',
      filterParams: { ...props.initFilterParams },
    };
  }

  static defaultProps = {
    scenarios: null,
  };

  onItemClick = scenarioCode => {
    const { history } = this.props;
    history.push(`/cis/scenario/${scenarioCode}`);
  };

  filterSidebarContent = () => {
    const {
      actions: { Sidebar },
    } = this.props;

    return (
      <FilterSidebar
        onClose={Sidebar.sidebarClosing}
        onAccept={() => console.log('accept')}
      />
    );
  };

  onFilterClick = () => {
    const {
      actions: { Sidebar },
    } = this.props;
    const content = (
      <SidebarContentBound component={this.filterSidebarContent} />
    );
    Sidebar.sidebarOpen(content);
  };

  updateFilterParams = filters => {
    this.setState({ filterParams: { ...filters } });
  };

  updateDates = date => {
    const { actions } = this.props;
    const { filterParams } = this.state;
    // const { AltScenarios: { updateFilterParams } } = actions;

    if (date.to && date.from) {
      const isFromChanged = !moment(date.from).isSame(
        moment(this.state.lastChangedFrom, this.state.format),
        'day',
      );
      const isToChanged = !moment(date.to).isSame(
        moment(this.state.lastChangedTo, this.state.format),
        'day',
      );

      if (isFromChanged) {
        let from = moment(date.from);
        let to = moment(date.to);
        const nextMonth = moment(from).add(1, 'month');
        if (to > nextMonth) {
          to = nextMonth;
        }

        const newFilterParams = {
          ...filterParams,
          dateFrom: from.format(this.state.format),
          dateTo: to.format(this.state.format),
        };

        this.updateFilterParams(newFilterParams);

        this.setState({
          lastChangedTo: to,
          lastChangedFrom: from,
        });
      } else if (isToChanged) {
        let from = moment(date.from);
        let to = moment(date.to);
        const prevMonth = moment(to).add(-1, 'month');
        if (from < prevMonth) {
          from = prevMonth;
        }

        const newFilterParams = {
          ...filterParams,
          dateFrom: from.format(this.state.format),
          dateTo: to.format(this.state.format),
        };
        this.updateFilterParams(newFilterParams);

        this.setState({
          lastChangedTo: to,
          lastChangedFrom: from,
        });
      }
    } else if (date.from) {
      let from = moment(date.from);
      let to = moment(from).add(1, 'month');
      const newFilterParams = {
        ...filterParams,
        dateFrom: from.format(this.state.format),
        dateTo: to.format(this.state.format),
      };
      this.updateFilterParams(newFilterParams);
      this.setState({
        lastChangedTo: to,
        lastChangedFrom: from,
      });
    } else if (date.to) {
      let to = moment(date.to);
      let from = moment(from).add(-1, 'month');
      const newFilterParams = {
        ...filterParams,
        dateFrom: from.format(this.state.format),
        dateTo: to.format(this.state.format),
      };
      this.updateFilterParams(newFilterParams);
      this.setState({
        lastChangedTo: to,
        lastChangedFrom: from,
      });
    }
  };

  handleFilterApply = values => {
    const { actions, isOGV, initFilterParams } = this.props;
    const {
      AltScenarios: { filterApply },
    } = actions;

    const formed = FormSerializer.serialize(values);

    const isFormClear = formed.hasOwnProperty('date') ? true : false;

    if (isFormClear) {
      this.updateFilterParams({});
    }

    filterApply({
      isOGV,
      initFilterParams: { ...initFilterParams },
      ...formed,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (isEmpty(prevState.filterParams)) {
      this.updateFilterParams(this.props.initFilterParams);
    }
  }

  render() {
    const { scenarios } = this.props;
    const { filterParams } = this.state;
    // const { AltScenarios: { filterApply } } = actions;
    if (!scenarios) return null;

    const filters = [
      {
        showDownloadListButton: false,
        popupMenu: [],
      },
      [
        {
          component: RangePicker,
          props: {
            inline: false,
            name: 'date',
            placeholder: 'Выбрать период',
            positionX: 'left',
            format: this.state.format,
            controls: true,
            acceptText: 'Применить',
            resetText: 'Сбросить',
            from: filterParams.dateFrom,
            to: filterParams.dateTo,
            onUpdate: date => {
              if (
                date.from &&
                date.from.format(this.state.format) === filterParams.dateFrom &&
                date.to &&
                date.to.format(this.state.format) === filterParams.dateTo
              ) {
                return;
              }

              if (!this.state.lastChangedFrom && !this.state.lastChangedTo) {
                this.setState(
                  {
                    lastChangedTo: moment(
                      filterParams.dateTo,
                      this.state.format,
                    ),
                    lastChangedFrom: moment(
                      filterParams.dateFrom,
                      this.state.format,
                    ),
                  },
                  () => {
                    this.updateDates(date);
                  },
                );
              } else {
                this.updateDates(date);
              }
            },
            theme: { DatePicker: defaultDatepickerTheme },
          },
        },
        {
          component: ProductSelect,
          props: {
            inline: false,
            name: 'gtin',
            placeholder: 'GTIN/Название продукта',
          },
        },
        {
          component: CISStatusSelect,
          props: {
            inline: false,
            name: 'cisStatus',
          },
        },
        {
          component: CISPackageSelect,
          props: {
            inline: false,
            name: 'cisPackageType',
          },
        },
        {
          component: ParticipantSelect,
          props: {
            inline: false,
            name: 'participantId',
            valueKey: 'id',
            placeholder: 'Имя участника',
          },
        },
      ],
    ];

    const rightSideComponents = [
      {
        component: (
          <Filter filters={filters} onFilterApply={this.handleFilterApply} />
        ),
        wrap: true,
      },
    ];

    return (
      <Wrap paddingTop="0px">
        <ActionBar rightSideComponents={rightSideComponents} />

        <Title>Нарушения</Title>
        <Content>
          <Grid container spacing={2}>
            {scenarios.map((s, i) => (
              <StructureItem
                key={i}
                title={
                  s.items[0] &&
                  s.items[0].scenarioType &&
                  s.items[0].scenarioType.descr
                    ? s.items[0].scenarioType.descr
                    : s.title
                }
                data={s.items}
                onClick={this.onItemClick}
              />
            ))}
          </Grid>
        </Content>
      </Wrap>
    );
  }
}

export default withRouter(StructureAndDynamic);
