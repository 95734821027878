import styled from 'styled-components';

export const ElementsCountValueWrapper = styled.div`
  display: inline-flex;
  margin-left: 10px;
  line-height: 19px;
`;

export const ElementsCountWrapper = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #434244;
  margin-left: 20px;
  margin-right: 24px;
  margin-top: -30px;
  padding: 20px 0;
`;
