import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  items: {
    border: '1px solid #D9D9D9',
    borderRadius: 3,
    maxHeight: 500,
    padding: 24,
    overflowY: 'auto',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 20 / 16,
  },
}));
