import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';

import Table from '@crpt/react-table';
import { Pagination } from '@crpt/react-pagination';

import { TableToolTip } from '../common/Table/TableCellTooltip';
import TableScrollWrapper from '../TableScrollWrapper';
import RemoteButtons from '../Details/common/RemoteButtons';
import ElementsCount from '../common/Table/ElementsCount';
import {
  StickContentWrapper,
  PaginationWrapper,
  ContentWrapper,
  Height20px,
} from '../common/styled';
import Preloader from '../Preloader';
import { Translate } from '../../common_components/Translate/Translate';

class Warehouse extends Component {
  static propTypes = {
    onMount: PropTypes.func.isRequired,
    onFilterSubmit: PropTypes.func.isRequired,
    onSelectPage: PropTypes.func.isRequired,
    selectedParticipant: PropTypes.shape({
      inn: PropTypes.string.isRequired,
    }).isRequired,
    portalFilterButtonId: PropTypes.string,
    portalDownloadButtonId: PropTypes.string,
    App: PropTypes.shape({
      config: PropTypes.shape({
        lists: PropTypes.shape({
          ParticipantDetails: PropTypes.shape({
            WarehouseList: PropTypes.shape({
              filters: PropTypes.array.isRequired,
              columns: PropTypes.array.isRequired,
              options: PropTypes.array.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    Warehouse: PropTypes.shape({
      results: PropTypes.array.isRequired,
      total: PropTypes.number.isRequired,
      selectedPage: PropTypes.number.isRequired,
      pagesCount: PropTypes.number.isRequired,
    }).isRequired,
    theme: PropTypes.any.isRequired,
    isPreloaderOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    portalFilterButtonId: 'containerIdIsAbsent',
    portalDownloadButtonId: 'containerIdIsAbsent',
  };

  state = {
    cellEvent: null,
    isToolTipVisible: false,
    children: <div />,
  };

  componentDidMount() {
    const { onMount, selectedParticipant } = this.props;
    const { inn } = selectedParticipant;
    onMount(inn);
  }

  onMouseEvent = ({ isToolTipVisible, children, cellEvent }) => {
    this.setState(() => ({ isToolTipVisible, children, cellEvent }));
  };

  onFilterApply = value => {
    const { onFilterSubmit, selectedParticipant } = this.props;
    const { inn } = selectedParticipant;

    onFilterSubmit(inn, value);
  };

  render() {
    const { cellEvent, children, isToolTipVisible } = this.state;
    const {
      theme,
      Warehouse: warehouse,
      App,
      onSelectPage,
      selectedParticipant,
      portalDownloadButtonId,
      portalFilterButtonId,
      isPreloaderOpen,
    } = this.props;
    const { type } = selectedParticipant;
    const {
      results = [],
      total = 0,
      selectedPage = 0,
      pagesCount = 0,
    } = warehouse;
    const warehouseListConfig = get(
      App,
      'config.lists.ParticipantDetails.WarehouseList',
      {}
    );
    const { filters, columns, options } = warehouseListConfig;
    const { showDownloadListButton, popupMenu } = filters[0];
    const filterFields = filters.slice(1);
    const optionsWithCallback = options.map(item => ({
      onMouseEvent: this.onMouseEvent,
      ...item,
    }));
    console.log(selectedParticipant);
    console.log('isPreloaderOpen', isPreloaderOpen);

    return (
      <ContentWrapper
        theme={{ Content: { backgroundColor: 'white', marginTop: '10px' } }}
        minHeight={isPreloaderOpen}
      >
        <div>
          <Preloader />
          {!isPreloaderOpen && <Fragment>
            <RemoteButtons
              type={type}
              showDownloadListButton={showDownloadListButton}
              popupMenu={popupMenu}
              filterFields={filterFields}
              onFilterApply={this.onFilterApply}
              downloadButtonId={portalDownloadButtonId}
              filterButtonId={portalFilterButtonId}
            />
            <Height20px />
            <ElementsCount value={total} />
            <TableScrollWrapper>
              <Table
                theme={theme}
                columns={columns}
                data={results}
                minRows={0}
                noDataText={Translate("Данных нет")}
                highlight
                striped
                options={optionsWithCallback}
              />
            </TableScrollWrapper>
            <PaginationWrapper>
              <Pagination
                selected={selectedPage}
                pagesCount={pagesCount}
                onSelect={val => onSelectPage(val)}
                pageCounterInvisible
                isBelowZeroDisabled
                leftPaginationText={Translate("Предыдущая")}
                rightPaginationText={Translate("Следующая")}
              />
            </PaginationWrapper>
            <TableToolTip
              event={cellEvent}
              content={children}
              isVisible={isToolTipVisible}
            />
          </Fragment>}
        </div>
      </ContentWrapper>
    );
  }
}

Warehouse.displayName = 'Warehouse';
export default withTheme(Warehouse);
