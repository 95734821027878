import * as actions from './LoginFormKep.actions';
import * as cadesActions from '../../../common_components/Cades/Cades.actions';

const loginFormKepMiddleware = ({ getStore, dispatch }) => next => action => {
  switch (action.type) {
    case `${actions.mounted}`:
      dispatch(cadesActions.load());
      break;
    default:
      next(action);
      break;
  }
};

export default loginFormKepMiddleware;
