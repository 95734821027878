import React from 'react';

export default {
  title: 'Табличное представление при выборе агрегации «Производитель + тип упаковки» и отображение периода «Год»:',
  amountOfTable: 3,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'Производитель',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Тип упаковки',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
          subheaders: []
        },
        {
          Header: 'Итого за период',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 840,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col5',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 140,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
          'col10': [10],
        },
        {
          'col1': <strong>Итого<br/> по производителю</strong>,
          'col2': '',
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <strong>Пачка</strong>,
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <strong>Блок</strong>,
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <strong>Короб</strong>,
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
        {
          'col1': <strong>ИТОГО</strong>,
          'col2': '',
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
      ]
    },
// table#2
    {
      headers: [
        {
          Header: 'Итого за год N-1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 1120,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col11',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 190,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col13',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col14',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col15',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col16',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col11': [5],
          'col12': [6],
          'col13': [7],
          'col14': [8],
          'col15': [9],
          'col16': [10],
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },    
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },   
      ]
    },
// table#3
    {
      headers: [
        {
          Header: 'Итого за год N',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 1120,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col17',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 190,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col18',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col19',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col20',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col21',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col22',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 186,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col17': [5],
          'col18': [6],
          'col19': [7],
          'col20': [8],
          'col21': [9],
          'col22': [10],
        },
        {
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
          'col19': <strong>...</strong>,
          'col20': <strong>...</strong>,
          'col21': <strong>...</strong>,
          'col22': <strong>...</strong>,
        },
        {
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
          'col19': <strong>...</strong>,
          'col20': <strong>...</strong>,
          'col21': <strong>...</strong>,
          'col22': <strong>...</strong>,
        },
        {
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
          'col19': <strong>...</strong>,
          'col20': <strong>...</strong>,
          'col21': <strong>...</strong>,
          'col22': <strong>...</strong>,
        },
        {
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
          'col19': <strong>...</strong>,
          'col20': <strong>...</strong>,
          'col21': <strong>...</strong>,
          'col22': <strong>...</strong>,
        },
        {
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
          'col19': <strong>...</strong>,
          'col20': <strong>...</strong>,
          'col21': <strong>...</strong>,
          'col22': <strong>...</strong>,
        },
      ]
    }
  ]
}