import { actionTypes } from '../../../constants';


export function producerFilter(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_PRODUCER_LOADED:
      return { loading: false, values: action.value.map(pr => ({ ...pr, producer: pr.inn, title: pr.name })) };
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_FILTER_PRODUCER_CHANGED:
      return { loading: true };
    default:
      return state;
  }
}
