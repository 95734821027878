import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { Icon } from '@crpt/react-icon';
import { ParticipantSelectWrapper, StepTitle } from '../Document/Document.styled';
import { Translate } from '../../../../common_components/Translate/Translate';
import { required } from '../../../../common_components/Form/utils/validations';
import { selectedDocument } from '../../../../components/DocumentDetails/ducks/DocumentDetails.selectors';
import { loadOnlyDocument } from '../../../../components/DocumentDetails/ducks/DocumentDetails.actions';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { Plug, SelectDocContainer, Link, getCountryNameByCode } from './utls';
import { CrossborderSelect } from '../../../../common_components/Form/Fields/Select/CrossborderSelect';

const InfoFields = (props) => {
  const { formValues, formMutators, selectedDocument, docType } = props;
  const { onMount } = props;

  useEffect(() => {
    if (Object.keys(selectedDocument).length !== 0) {
      formMutators.updateField('document_num', selectedDocument.documentNum || '');
      formMutators.updateField(
        'document_date',
        selectedDocument.documentDate
          ? moment(new Date(selectedDocument.documentDate)).format('DD.MM.YYYY')
          : '',
      );
      formMutators.updateField('sender_country_code', selectedDocument.senderCountryCode || '');
      formMutators.updateField('country', getCountryNameByCode(selectedDocument.senderCountryCode));
      formMutators.updateField('exporter_taxpayer_id', selectedDocument.exporterInn || '');
      formMutators.updateField('exporter_name', selectedDocument.exporter || '');
      formMutators.updateField('receiver_inn', selectedDocument.receiverInn || '');
      formMutators.updateField('products', selectedDocument.products.map(el => ({ ...el, accepted: docType === 'LP_ACCEPT_GOODS' ? false : 'unset' })) || []);
    }
  }, [selectedDocument]);

  const onSelect = (value) => {
    if(value) {
      setTimeout(() => {
        onMount(value.id);
      }, 100);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <StepTitle noMarginTop>{Translate('Общие данные')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle noMarginTop uppercase>{Translate('Сведения о первичном документе')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectDocContainer>
                <ParticipantSelectWrapper>
                  <CrossborderSelect
                    forceValidate
                    validate={required}
                    required
                    name="shipment_id"
                    valueKey="all"
                    list={formValues.shippingDocuments || []}
                    placeholder={Translate('Идентификатор документа отгрузки')}
                    noValuesText={Translate('Для поиска введите от 3-ёх символов')}
                    onSelect={onSelect}
                  />
                </ParticipantSelectWrapper>
                <Link
                  href={formValues.shipment_id ? `/documents/list/${formValues.shipment_id}` : undefined}
                  target="_blank"
                  disabled={Object.keys(selectedDocument).length === 0}
                >
                  <Icon type="eye"/>
                </Link>
              </SelectDocContainer>
            </Grid>
            <Grid item md={6} xs={false} />
            <Grid item xs={6}>
              <Input
                name="document_num"
                placeholder={Translate('Номер первичного документа')}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <div />
                <DatePicker
                  forceValidate
                  displayFormat="dd.MM.YYYY"
                  name="document_date"
                  placeholder={Translate('Дата первичного документа')}
                  disabled
                />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Plug/>
      <Grid container spacing={2}>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle noMarginTop uppercase>{Translate('Сведения об отправителе товара')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                name="country"
                placeholder={Translate('Код страны отправления')}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={false} />
            <Grid item xs={6}>
              <Input
                name="exporter_taxpayer_id"
                placeholder={Translate('Идентификатор экспортера в стране ЕАЭС')}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="exporter_name"
                placeholder={Translate('Наименование экспортера')}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Plug/>
      <Grid container spacing={1}>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle noMarginTop uppercase>{Translate('Сведения о получателе товара')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid item xs={6}>
            <Input
              name="receiver_inn"
              placeholder={Translate('Идентификатор получателя')}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Plug/>
      <Grid container spacing={1}>
        <Grid xs={4} alignItems="flex-start">
          <StepTitle noMarginTop uppercase>{Translate('Сведения о приемке')}</StepTitle>
        </Grid>
        <Grid xs={8}>
          <Grid item xs={6}>
            <DatePicker
                required={true}
                forceValidate
                name="acceptance_date"
                placeholder={Translate('Дата приемки товаров')}
                maxDate={new Date()}
                outFormat={'YYYY.MM.DD'}
                validate={required}
              />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapState = (state) => ({
  selectedDocument: selectedDocument(state),
});

const mapDispatch = (dispatch) => ({
  onMount: id => dispatch(loadOnlyDocument(id)),
});

export default connect(mapState, mapDispatch)(InfoFields);
