import styled from 'styled-components';

export const DayWrap = styled.div`
  background-color: white;
  margin-bottom: 32px;
  :last-child {
    margin-bottom: 0px;
  }
  padding-bottom: 32px;
`;

export const DateWrap = styled.div`
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #212c42;
  margin-bottom: 22px;
`;

export const MapWrap = styled.div`
  margin-bottom: 20px;
`;