import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Document } from '@crpt-edo/core';
import { datepickerRequiredTheme, datepickerDefaultTheme } from '../../theme/ru-edo/datepicker';

const edoTheme = {
  DatePicker: datepickerDefaultTheme,
  Required: {
    DatePicker: datepickerRequiredTheme
  }
};

const EdoDocument = props => (
  <ThemeProvider theme={edoTheme}>
    <Document.DocumentView {...props} />

  </ThemeProvider>
);

export default EdoDocument;
