import styled from 'styled-components';

export const UserListHeadingSelected = styled.div`
  padding-left: 19px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 19px;
  margin-right: 20px;
  font-size: ${props => (props.current ? '24px' : '16px')};
  color: #52535a;
  box-sizing: border-box;
  border-radius: 3px;
  border: 2px solid rgba(0, 0, 0, 0);
  :hover {
    border: 2px solid rgba(196, 196, 196, 0.25);
    cursor: pointer;
  }
  display: flex;
  align-items: center;
`;
