import React from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { withModal } from '../../../../common_components/Modals/hocs/withModal';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import AddIdentificationCodes from '../../../../common_components/AddIdentificationCodes/AddIdentificationCodes';
import { withRouter } from 'react-router-dom';


const AddCodesBar = (props) => {
    const { onOpenModal, onSetContent, onCloseModal } = props;
    const { formMutators, formValues } = props;
    const { t } = useTranslation();

    const openLoadCisListModal = () => {
        onSetContent(
            <AddIdentificationCodes
                formMutators={formMutators}
                onCloseModal={onCloseModal}
                types=".xls, .xlsx"
            />
        );
        onOpenModal();
    }

    return (
        <PrimaryActionButton
            disabled={!!formValues.aik}
            onClick={openLoadCisListModal}
        >
            {t('Загрузить XLS')}
        </PrimaryActionButton>
    )
}

export default compose(withModal, withRouter)(AddCodesBar);
