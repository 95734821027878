import { documentTypes, receiptTypes } from '../../../../constants/documentTypes';

export function getIconNameByDocType(typeName) {
    switch (typeName) {
        case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT:
            // return 'filled-blank';
            return "utd";
        case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_FIX:
            // return 'edit-filled-blank';
            return "utdi";
        case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED:
            // return 'edit-filled-blank';
            return "upd";
        case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED:
            // return 'edit-filled-blank';
            return 'ucd';
        case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT:
            return 'ucd';
        case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_FIX:
            return 'ucdi';
        case documentTypes.AGGREGATION_DOCUMENT:
        case documentTypes.AIC_AGGREGATION:
            // return 'doc-aggregation';
            return "agregation";
        case receiptTypes.RECEIPT:
        case receiptTypes.AUTO_DISAGGREGATION:
        case receiptTypes.AIC_DISAGGREGATION:
        case receiptTypes.WITHDRAW:
            return 'receipt';
        case receiptTypes.RECEIPT_RETURN:
        case receiptTypes.RETURN_TO_TURNOVER:    
            return 'receipt-return';
        case documentTypes.WRITE_OFF:
            return 'pan';
        case documentTypes.CUSTOMS_DECLARATION:
            return 'menu-document-list';
        case documentTypes.MTTPS_10:
            // return 'menu-document-list';
            return 'pan';
        case documentTypes.MTTPS_90:
            // return 'menu-document-list';
            return 'pan';
        case documentTypes.IMPORT:
            return 'menu-document-list';
        case documentTypes.IMPORT_TRANSIT:
            return 'menu-document-list';
        default:
            return 'menu-document-list';
    }
}
