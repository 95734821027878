export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [3],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [4],
          'col2': 'Страна производства товара'
        },
        {
          'col1': [5, 6],
          'col2': 'Количество и стоимость произведённого и импортированного товара (в пачках) за выбранный период'
        }
      ]
    }
  ]
}