import React from 'react';
import PropTypes from 'prop-types';
import Datatable from '@crpt-ui/datatable';
import { totalColumns } from 'src/motp_components/_Requests/RequestList/ActDocument/Cis/Cis.constants';
import { PaddingWrap } from '../DocumentDetails.styled';
import * as selectors from '../ducks/DocumentDetails.selectors';
import { connect } from 'react-redux';

const Total = ({ selectedDocument, totalData }) => {

  return (
    <React.Fragment>
      <br />
      <PaddingWrap>
        <Datatable columns={totalColumns} data={totalData} />
      </PaddingWrap>
    </React.Fragment>
  );
};

Total.propTypes = {
  selectedDocument: PropTypes.object,
};
const mapState = state => ({
  totalData: selectors.totalData(state),
});
const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(Total);
