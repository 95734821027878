import { call, takeEvery, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as actions from './articlesCreation.actions';
import Api from '../../../common_components/Api/Api';
import { Toast } from '../../../utils/Toast';
import { get } from 'lodash';

function* createSaga({ payload }) {
  const { text, body } = payload;

  const blob = new Blob([text], {type: 'text/html'});

  const formData = new FormData();
  formData.append("article", new Blob([JSON.stringify(body)], {type:"application/json"}));
  formData.append("file", blob, 'index.html');

  const requestPayload = {
    url: '/api/v1/mobile-content/articles',
    method: "post",
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    yield call(Toast.showSuccess, { content: 'Статья успешно создана!' });
    yield put(push('/mobile-content/articles'));
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* updateSaga({ payload }) {
  const { text, body, id } = payload;

  const blob = new Blob([text], {type: 'text/html'});

  const formData = new FormData();
  formData.append("article", new Blob([JSON.stringify(body)], {type:"application/json"}));
  formData.append("file", blob, 'index.html');

  const requestPayload = {
    url: '/api/v1/mobile-content/articles/' + id,
    method: "put",
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    yield call(Toast.showSuccess, { content: 'Статья успешно обновлена!' });
    yield put(push('/mobile-content/articles'));
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* loadItemSaga(action) {

}

function* getIconListSaga({ payload }) {
  const requestPayload = {
    url: `/api/v1/mobile-content/icons`,
    method: 'get',
    params: { lang: payload }
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const { data } = success;
    yield put(actions.setIconList(data))
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* uploadFileSaga({ payload }) {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("lang", payload.lang);
  formData.append("imageUsage", payload.imageUsage);

  const requestPayload = {
    url: '/api/v1/mobile-content/image',
    method: "post",
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const { data } = success;
    payload.onFinish && payload.onFinish(success.data.fileName)
    return data
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* stopSaga({ payload }) {
  const { id, lang } = payload;

  const requestPayload = {
    url: `/api/v1/mobile-content/articles/${id}/stop`,
    method: "post",
    data: {lang},
    headers: {
      ContentType: 'application/json;charset=UTF-8',
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    yield put(push('/mobile-content/articles'));
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}


export default function* watch() {
  yield takeLatest(actions.loadItem, loadItemSaga);
  yield takeLatest(actions.create, createSaga);
  yield takeLatest(actions.update, updateSaga);
  yield takeLatest(actions.getIconList, getIconListSaga);
  yield takeLatest(actions.uploadFile, uploadFileSaga);
  yield takeEvery(actions.stop, stopSaga);
}
