import { css } from 'styled-components';

const TableRow = {
  style: css`
    display: inline-flex;
    flex: 1 0 auto;
  `,
};

export default TableRow;
