import React from 'react';

export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'ИНН',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
        },
        {
          Header: 'Наименование производителя',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
        },
        {
          Header: 'Код подакцизного товара',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
        },
        {
          Header: 'Уникальный код продукта для пачки (GTIN)',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 270,
        },
        {
          Header: 'Наименование табачной продукции',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
        {
          Header: 'МРЦ, тенге',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': '',
          'col6': '',
        },
      ],
    },
// table#2
    {
      headers: [
        {
          Header: 'Период производства',
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
        },
        {
          Header: 'Импорт табачной продукции',
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 220,
        },
        {
          Header: 'Количество импортируемой табачной продукции, тыс. штук',
          accessor: 'col9',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 320,
        },
        {
          Header: 'Сумма акциза, тенге.',
          accessor: 'col10',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
        },
        {
          Header: 'Объём реализованных табачных изделий - всего, тыс.шт',
          accessor: 'col11',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 380,
        },
      ],
      data: [
        {
          'col7': [7],
          'col8': [8],
          'col9': [9],
          'col10': [10],
          'col11': [11],
        },
        {
          'col7': '',
          'col8': '',
          'col9': '',
          'col10': <strong>...</strong>,
          'col11': <strong>...</strong>,
        },
      ],
    },
// table#3
    {
      headers: [
        {
          Header: 'Количество единиц реализованных потребительских упаковок (пачек), шт.',
          accessor: 'col12',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 440,
        },
        {
          Header: 'Количество единиц сигарет в одной потребительской упаковке (пачке), шт.',
          accessor: 'col13',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 450,
        },
        {
          Header: 'Расчётная стоимость реализованных табачных изделий, тенге.',
          accessor: 'col14',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 430,
        },
      ],
      data: [
        {
          'col12': [12],
          'col13': [13],
          'col14': [14],
        },
        {
          'col12': <strong>...</strong>,
          'col13': '',
          'col14': <strong>...</strong>,
        },
      ],
    },
// table#4
    {
      headers: [
        {
          Header: 'Сумма акциза, рассчитанная исходя из твёрдой налоговой ставки, тенге.',
          accessor: 'col15',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 330,
        },
        {
          Header: 'Сумма акциза, рассчитанная исходя из адвалорной налоговой ставки, тенге.',
          accessor: 'col16',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 340,
        },
        {
          Header: 'Сумма акциза, рассчитанная исходя из комбинированной ставки, тенге.',
          accessor: 'col17',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 320,
        },
        {
          Header: 'Сумма акциза, рассчитанная исходя из минимальной ставки, тенге.',
          accessor: 'col18',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 330,
        },
      ],
      data: [
        {
          'col15': [15],
          'col16': [16],
          'col17': [17],
          'col18': [18],
        },
        {
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
          'col17': <strong>...</strong>,
          'col18': <strong>...</strong>,
        },
      ],
    },
  ]
}
