import React from 'react';
import PropTypes from 'prop-types';
import { ProductionLineListItemType } from '../ProductionType';
import { Typography, Box, Grid } from '@material-ui/core';
import * as Icons from '@crpt-ui/icons';
import { Modal } from '@crpt-ui/core';


import {
  ProductionLineWrapper,
  CloseButton,
} from './ProductionLinesTableFieldModal.styled';
import { Translate } from '../../../../common_components/Translate/Translate';

export const ProductionLinesTableFieldModal = ({
  productionLine,
  closeModal,
  isOpen,
}) => (
  <Modal open={isOpen} onClose={closeModal}>
    <Typography variant="h5">{productionLine.productionPlaceName}</Typography>

    {productionLine.productionAddress && (
      <Box marginTop={2}>
        <Grid item>
          <b>{Translate('Адрес:')}</b> {productionLine.productionAddress}
        </Grid>
      </Box>
    )}

    <Box marginTop={2}>
      <Grid item>
        <Typography variant="h6">{Translate('Производственные линии:')}</Typography>
      </Grid>

      <Grid item>
        <ProductionLineWrapper>
          {productionLine.productionLines.map((line) => (
            <Box marginTop={2} key={line}>
              <Grid item>{line}</Grid>
            </Box>
          ))}
        </ProductionLineWrapper>
      </Grid>
    </Box>

    <CloseButton>
      <Icons.Close onClick={closeModal} />
    </CloseButton>
  </Modal>
);

ProductionLinesTableFieldModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  productionLine: ProductionLineListItemType.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
