import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core';

const styles = {
  paper: {
    width: 450,
    borderRadius: 0,
  },
};

export default withStyles(styles)(({ classes, children, ...rest }) => (
  <Drawer classes={classes} anchor="right" {...rest}>
    {children}
  </Drawer>
));
