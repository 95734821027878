import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { Switch, Prompt } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { CloseIcon } from './components/Toast/CloseIcon';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';

import * as AppSelectors from './common_components/App/App.selectors';
import { Modals } from '@crpt-edo/core';
import { history, store } from './store';

import PrivateRoute from './common_components/Routing/PrivateRoute';
import Route from './common_components/Routing/Route';

import { StoreHelper } from './utils/StoreHelper';
import { HistoryHelper } from './utils/HistoryHelper';

import { MaterialUiThemeKZ } from './common_components/_MaterialUi/ThemeKZ';

import { setFavicon, setGlobalThemeClass } from './utils';
import { MaterialUiThemeUZ } from './common_components/_MaterialUi/ThemeUZ';

window.store = store;
StoreHelper.setStore(store);
HistoryHelper.setHistory(history);

class AppWrap extends Component {
  componentDidMount() {
    const { config } = this.props;
    setFavicon(config.countryCode);
    setGlobalThemeClass(config.countryCode);
  }

  renderRoutes = routes => {
    return routes.map(this.renderRoute);
  };

  renderRoute = (v, i) => {
    return v.private ? (
      <PrivateRoute
        key={i}
        exact={v.exact}
        path={v.route}
        component={v.component}
        params={v}
      />
    ) : (
      <Route
        key={i}
        exact={v.exact}
        path={v.route}
        component={v.component}
        params={v}
      />
    );
  };

  render() {
    const { config } = this.props;
    const theme = MaterialUiThemeUZ;
    const configTheme = config.themeUZ;

    return (
      <React.Fragment>
        <div style={{ height: '100%' }} data-test="component-app">
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Prompt message={'APP'} />
              <ThemeProvider theme={configTheme}>
                <React.Fragment>
                  <Switch>
                    {this.renderRoutes(config.routes.filter(v => !v.inLayout))}
                    <PrivateRoute
                      render={props => {
                        const L = config.LayoutComponent;
                        return (
                          <L
                            {...props}
                            routes={config.routes.filter(v => v.inLayout)}
                          />
                        );
                      }}
                    />
                  </Switch>
                  <ToastContainer
                    autoClose={false}
                    closeOnClick={false}
                    position={toast.POSITION.BOTTOM_RIGHT}
                    closeButton={<CloseIcon />}
                  />
                  <Modals.ModalsView />
                </React.Fragment>
              </ThemeProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  config: AppSelectors.config(state),
});

export default connect(mapState)(AppWrap);
