import React, {useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { StepTitle } from '../Document/Document.styled';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import {
    required,
} from '../../../../common_components/Form/utils/validations';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import { pgTypesNames } from '../../../../constants/productGroups';
import { Translate } from '../../../../common_components/Translate/Translate';
import DatePicker from '../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';

const InfoFields = (props) => {
    const { formValues, finalConfirm, formMutators } = props;
    const {t} = useTranslation();
    const { pg } = formValues;

    const productGroups = useMemo(()=>
        JSON.parse(window.env.TRADE_INVENTORY || "[]").map((el)=> ({
            ...el,
        })),
        []
    );

    const { minDate, maxDate } = useMemo(() => {
      if (pg) {
        const foundPgSettings = productGroups.find(el=> el.pg === pg)
        return {
          minDate: foundPgSettings.startDate,
          maxDate: foundPgSettings.endDate,
        }
      }
      return {
        minDate: null,
        maxDate: null,
      }
    }, [pg])

    const productGroupsOptions = useMemo(()=>
        productGroups.map(el=> ({ value: el.pg, label: t(pgTypesNames[el.pg]) })),
        [productGroups, t]
    )


  console.log(maxDate)
    return (
        <React.Fragment>
            <Grid container>
                <Grid>
                    <StepTitle noMarginTop>{t('Общие данные')}</StepTitle>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input
                        forceValidate
                        name="applicantInn"
                        placeholder={t('ИНН/ПИНФЛ заявителя')}
                        required
                        validate={required}
                        disabled
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Select
                        forceValidate
                        name="pg"
                        disabled={finalConfirm}
                        placeholder={t('Товарная группа')}
                        required
                        validate={required}
                        options={productGroupsOptions}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    key={maxDate}
                    required
                    disabled={!pg}
                    forceValidate
                    name="inventory_date"
                    placeholder={Translate('Дата инвентаризации')}
                    maxDate={new Date(maxDate)}
                    minDate={new Date(minDate)}
                    validate={required}
                  />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapState = state => ({
    user: authSelectors.userInfo(state),
});

export default connect(mapState, null)(InfoFields);
