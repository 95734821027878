import { participantNames, PARTICIPANT_TYPES } from '../../constants';

export const participantValues = [
  {
    id: 0,
    title: participantNames[PARTICIPANT_TYPES.PRODUCER],
    type: PARTICIPANT_TYPES.PRODUCER,
  },
  {
    id: 1,
    title: participantNames[PARTICIPANT_TYPES.OGV],
    type: PARTICIPANT_TYPES.OGV,
  },
  {
    id: 2,
    title: participantNames[PARTICIPANT_TYPES.WHOLESALER],
    type: PARTICIPANT_TYPES.WHOLESALER,
  },
  {
    id: 3,
    title: participantNames[PARTICIPANT_TYPES.RETAIL],
    type: PARTICIPANT_TYPES.RETAIL,
  },
  {
    id: 4,
    title: participantNames[PARTICIPANT_TYPES.IMPORTER],
    type: PARTICIPANT_TYPES.IMPORTER,
  },
];

export const RegistrationSteps = {
  CERTIFICATE: 0,
  CONTACTS: 1,
  DATA: 2,
  COMPLETE: 3,
};