export function getPaginatedListSelectors(namespace, params = {}) {
  const final = {};

  final[params.List || 'List'] = state => state[namespace].List;
  final[params.total || 'total'] = state => state[namespace].total;
  final[params.limit || 'last'] = state => state[namespace].last;
  final[params.selectedPage || 'selectedPage'] = state =>
    state[namespace].selectedPage;
  final[params.pagesCount || 'pagesCount'] = state =>
    state[namespace].pagesCount;
  final[params.filterParams || 'filterParams'] = state =>
    state[namespace].filterParams;
  final[params.isPageLoading || 'isPageLoading'] = state =>
    state[namespace].isPageLoading;
  final.preloaderIsOpen = state => state.Preloader.isOpen;

  return final;
}
