// reports info - выгрузка
import * as Icons from '@crpt-ui/icons';
import React from 'react';


// информация по отчёту
export const reportStatusEnum = {
  READY: 'READY',
  PREPARING: 'PREPARING',
  ERROR: 'ERROR',
  DELETED: 'DELETED'
};

export const reportStatusValuesMap = {
  [reportStatusEnum.READY]: 'Готова',
  [reportStatusEnum.PREPARING]: 'Подготовка',
  [reportStatusEnum.ERROR]:'Ошибка',
  [reportStatusEnum.DELETED]: 'Удалена'
};


export const reportStatusColorsMap = {
  [reportStatusEnum.READY]: '#2fa93b',
  [reportStatusEnum.PREPARING]: '#63666a',
  [reportStatusEnum.ERROR]:'#FF4B4B',
  [reportStatusEnum.DELETED]: '#FF4B4B'
};

export const reportStatusIconsMap = {
  [reportStatusEnum.READY]: <Icons.Success />,
  [reportStatusEnum.PREPARING]: <Icons.Refresh />,
  [reportStatusEnum.ERROR]:<Icons.Error />,
  [reportStatusEnum.DELETED]: <Icons.Trash />
};


// информация по документу
export const documentStatusEnum = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  PREPARING: 'PREPARING'
};

export const documentStatusValuesMap = {
  [documentStatusEnum.PROCESSING]: 'В обработке',
  [documentStatusEnum.COMPLETED]: 'Обработан',
  [documentStatusEnum.ERROR]: 'Ошибка',
  [documentStatusEnum.PREPARING]: 'Черновик'
};

export const documentStatusColorsMap = {
  [documentStatusEnum.COMPLETED]: '#2fa93b',
  [documentStatusEnum.PREPARING]: '#63666a',
  [documentStatusEnum.PROCESSING]: '#63666a',
  [documentStatusEnum.ERROR]: '#FF4B4B',
};

export const documentStatusBackgroundColorsMap = {
  [documentStatusEnum.COMPLETED]: 'rgba(47, 169, 59, 0.1)',
  [documentStatusEnum.PREPARING]: 'rgba(99, 102, 106, 0.1)',
  [documentStatusEnum.PROCESSING]: 'rgba(99, 102, 106, 0.1)',
  [documentStatusEnum.ERROR]: 'rgba(255, 75, 75, 0.1)',
};

export const documentStatusIconsMap = {
  [documentStatusEnum.COMPLETED]: <Icons.Success />,
  [documentStatusEnum.PREPARING]: <Icons.SendForModeration />,
  [documentStatusEnum.PROCESSING]: <Icons.Refresh />,
  [documentStatusEnum.ERROR]: <Icons.Trash />
};