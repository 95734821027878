import { Translate } from '../../../../../common_components/Translate/Translate';

export default {
  title: Translate('Фильтры по умолчанию:'),
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: Translate('Название фильтра'),
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: Translate('Значение фильтра'),
          accessor: 'col2',
          minWidth: 560,
        },
      ],
      data: [
        {
          'col1': Translate('Тип агрегации'),
          'col2': Translate('Сводный отчёт')
        },
        {
          'col1': Translate('Вариант задания периода'),
          'col2': Translate('Месяц')
        },
        {
          'col1': Translate('Период'),
          'col2': Translate('Предыдущий месяц')
        },
      ]
    }
  ]
}
