import { authInstance } from './authInstance';

export class CertificateService {
  static uploadCertificate(formData) {

    return authInstance.request({
      url: '/private-office-api/private/v2/profile/certificate_upload',
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }


}
