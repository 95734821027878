import { handleActions } from 'redux-actions';

import * as actions from './DocumentDetails.actions';

const loaded = handleActions(
  {
    [actions.mounted]: () => false,
    [actions.loaded]: (state, { payload = {} }) => !!payload || false,
    [actions.unmounted]: () => false,
  },
  false,
);

const selectedDocument = handleActions(
  {
    [actions.mounted]: () => ({}),
    [actions.loaded]: (state, { payload = {} }) => ({ ...state, ...payload }),
    [actions.disaggregationCodes]: (state, { payload }) => ({
      ...state,
      disaggregationCodes: payload,
    }),
    [actions.unmounted]: () => ({}),
  },
  {},
);

const markedListData = handleActions(
  {
    [actions.loadedMarkedList]: (state, { payload }) => payload,
    [actions.mounted]: () => ({}),
    [actions.unmounted]: () => ({}),
  },
  {},
);

const loadedMarkedList = handleActions(
  {
    [actions.mounted]: () => false,
    [actions.loadedMarkedList]: (state, { payload = {} }) => !!payload || false,
    [actions.unmounted]: () => false,
  },
  false,
);

const requestedMarkedList = handleActions(
  {
    [actions.loadMarkedList]: (state, { payload }) => true,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
  },
  false,
);

const codes = handleActions(
  {
    [actions.loadedCodes]: (state, { payload }) => payload,
    [actions.mounted]: () => ({}),
    [actions.unmounted]: () => ({}),
  },
  {},
);
const totalData = handleActions(
  {
    [actions.setTotal]: (state, { payload }) => payload,
  },
  [],
);

const loadedCodes = handleActions(
  {
    [actions.mounted]: () => false,
    [actions.updateCodes]: () => false,
    [actions.loadedCodes]: (state, { payload = {} }) => !!payload || false,
    [actions.unmounted]: () => false,
  },
  false,
);

const showingData = handleActions(
  {
    [actions.loadData]: (state, { payload = {} }) => payload,
  },
  null,
);

const disaggregationCodes = handleActions(
  {
    [actions.mounted]: () => {},
    [actions.disaggregation()]: () => {},
    [actions.disaggregationCodes]: (state, { payload = {} }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {},
);

export default {
  loaded,
  selectedDocument,
  requestedMarkedList,
  loadedMarkedList,
  markedListData,
  codes,
  loadedCodes,
  showingData,
  totalData,
  disaggregationCodes,
};
