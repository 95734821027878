import {actionTypes} from '../../constants/';

export function cisTotal(state = 0, action = {}) {
  switch (action.type) {
    case actionTypes.DOCUMENT_DETAILS.CIS_LIST_LOADED:
      return action.value.total;
    default:
      return state;
  }
}
