import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';
import Dropzone from 'react-dropzone';
import { Icon } from '@crpt/react-icon';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wrapper, Header } from '../Modal/Modal.styled';
import { UploadArea, PointerArea, ErrorZone } from './FileUploadModal.styled';
import * as fileActions from '../../File/File.actions';
import Preloader from '../../../common_components/Preloader/Preloader';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  types: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  customTitle: PropTypes.any,
  preloaderText: PropTypes.string.isRequired,
  beforeLoadText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorContent: PropTypes.any,
  fileOpenParams: PropTypes.object,
  onDrop: PropTypes.func,
};

const defaultProps = {
  isError: false,
  isLoading: false,
};

class FileUploadModalContent extends React.Component {
  getUploadArea = ({ getRootProps, getInputProps, isDragActive }) => {
    const { types, beforeLoadText } = this.props;

    return (
      <UploadArea
        isDragActive={isDragActive}
        {...getRootProps({ refKey: 'innerRef' })}
      >
        <input {...getInputProps()} accept={`${types}`} />
        <PointerArea>{beforeLoadText}</PointerArea>
      </UploadArea>
    );
  };

  onLoad = file => {
    const { onLoad } = this.props;
    onLoad(file);
  };

  onError = error => {
    const { onError } = this.props;
    onError(error);
  };

  onDrop = acceptedFiles => {
    this.props.onDrop(acceptedFiles);
    if (acceptedFiles.length) {
      this.props.loadFile(acceptedFiles[0], {
        subject: 'hello',
        success: this.onLoad,
        error: this.onError,
        ...this.props.fileOpenParams,
      });
    }
  };

  render() {
    const { onCloseModal, title, isLoading, preloaderText, errorContent, isError, customTitle } = this.props;

    return (
      <Wrapper>
        <Header>
          <div>{title}</div>
          <Icon type={'cross'} onClick={onCloseModal} />
        </Header>

        <ErrorZone visible={isError}>
          <Icon type={'warning'} fill={'#ffffff'} spacingRight={16} />
          {errorContent}
        </ErrorZone>

        {customTitle}

        <UploadArea hide={!isLoading}>
          <Preloader
            isClosed={!isLoading}
            isOpened={isLoading}
            fullHeight={false}
            text={preloaderText}
          />
        </UploadArea>
        {!isLoading && (
          <Dropzone onDrop={this.onDrop}>{this.getUploadArea}</Dropzone>
        )}
      </Wrapper>
    );
  }
}

FileUploadModalContent.defaultProps = defaultProps;
FileUploadModalContent.propTypes = propTypes;

const mapState = state => {
  return {};
};

const mapDispatch = dispatch => ({
  loadFile: (file, meta) => dispatch(fileActions.read(file, meta)),
});

export default compose(connect(mapState, mapDispatch), withTheme)(
  FileUploadModalContent,
);
