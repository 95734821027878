import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CertName, CertSource } from './CertificateSelect.styled';

const Value = (props) => {
  const { selected } = props;
  if (!selected) return (null);

  return (
    <div>
      <CertSource>{selected.source}</CertSource>
      <CertName>{selected.name}</CertName>
    </div>
  );
};

Value.propTypes = {
  selected: PropTypes.shape({
    source: PropTypes.string,
    name: PropTypes.string,
  }),
};

Value.defaultProps = {
  selected: undefined,
};


export default memo(Value);
