import styled from 'styled-components';


export const ModalContent = styled.div`
  padding-left: 32px;
  margin-bottom: 44px;
  line-height: 1.5;
  font-size: 16px;
  color: #212c42;
`;
