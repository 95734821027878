import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@crpt-ui/icons';

const propTypes = {
  arrowDirection: PropTypes.oneOf(['up', 'down']).isRequired,
};

const iconStyle = { padding: '0 30px' };

const Arrow = ({
  arrowDirection,
}) => (
  arrowDirection === 'down'
    ? <Icons.ArrowDown style={iconStyle} />
    : <Icons.ArrowUp style={iconStyle} />
);

Arrow.propTypes = propTypes;

export default Arrow;
