import { matchPropFromUrl } from '../utils/matchPropFromUrl';

export const pgTypes = {
  SHOES: 'shoes', // Обувь
  CLOTHES: 'lp', // Одежда
  TOBACCO: 'tobacco', // Табак
  PERFUMERY: 'perfumery', // Парфюм
  TIRES: 'tires', // Шины
  ELECTRONICS: 'electronics', // Фотокамеры
  BICYCLE: 'bicycle', // Велосипеды
  MEDICALS: 'medicals', // Изделия медицинского назначения
  MILK: 'milk', // Молоко
  OTP: 'otp', // Альтернативная табачная продукция - кальяны и т.д.
  WATER: 'water', // Вода - миниралка и т.д.
  FUR: 'furs', // Меха
  PHARMA: 'pharma', // лекарства
  ALCOHOL: 'alcohol',
  APPLIANCES: "appliances",
  BEER: "beer",
  ANTISEPTIC: "antiseptic",
};

export const pgTypesNames = {
  [pgTypes.SHOES]: "Обувные товары",
  [pgTypes.CLOTHES]: "Одежда",
  [pgTypes.TOBACCO]: "Табачные изделия",
  [pgTypes.PERFUMERY]: "Духи и туалетная вода",
  [pgTypes.TIRES]: "Шины и покрышки пневматические резиновые новые",
  [pgTypes.ELECTRONICS]: "Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки",
  [pgTypes.BICYCLE]: "Велосипеды и велосипедные рамы",
  [pgTypes.MEDICALS]: "Изделия медицинского назначения",
  [pgTypes.MILK]: "Молочная продукция",
  [pgTypes.OTP]: "Альтернативная табачная продукция",
  [pgTypes.WATER]: "Питьевая вода",
  [pgTypes.PHARMA]: "Лекарственные средства",
  [pgTypes.ALCOHOL]: "Алкогольная продукция",
  [pgTypes.APPLIANCES]: 'Бытовая техника',
  [pgTypes.BEER]: 'Пиво и пивные напитки',
  [pgTypes.ANTISEPTIC]: 'Спиртосодержащая непищевая продукция',
};

export const pgTypesNamesKZ = {
  [pgTypes.WATER]: "Питьевая вода",
  [pgTypes.ALCOHOL]: "Алкогольная продукция",
  [pgTypes.MILK]: "Молочная продукция",
  [pgTypes.PHARMA]: "Лекарственные средства",
  [pgTypes.TOBACCO]: "Табачная продукция",
  [pgTypes.SHOES]: "Обувные товары",
  [pgTypes.CLOTHES]: "Одежда",
};

export const pgTypesNamesUZ = {
  [pgTypes.TOBACCO]: "Табачная продукция",
  [pgTypes.PHARMA]: "Лекарственные средства",
  [pgTypes.ALCOHOL]: "Алкогольная продукция",
  [pgTypes.WATER]: "Вода и прохладительные напитки",
  [pgTypes.BEER]: 'Пиво и пивные напитки',
  [pgTypes.APPLIANCES]: 'Бытовая техника',
  [pgTypes.ANTISEPTIC]: 'Спиртосодержащая непищевая продукция',
}

const hostMapping = {
  [pgTypes.SHOES]: ['shoes', 'lp'],
  [pgTypes.CLOTHES]: ['clothes'],
  [pgTypes.TOBACCO]: ['tobacco'],
  [pgTypes.PERFUMERY]: ['perfumery'],
  [pgTypes.TIRES]: ['tires'],
  [pgTypes.ELECTRONICS]: ['electronics'],
  [pgTypes.BICYCLE]: ['bicycle'],
  [pgTypes.MEDICALS]: ['medicals'],
  [pgTypes.MILK]: ['milk'],
  [pgTypes.OTP]: ['otp'],
  [pgTypes.WATER]: ['water'],
  [pgTypes.PHARMA]: ['pharma'],
  [pgTypes.ANTISEPTIC]: ['antiseptic'],
};

export const activePG = matchPropFromUrl(hostMapping);
