import React, { useMemo } from 'react';
import moment from 'moment';
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import List from '../../../common_components/List/List';
import { HeadingId } from '../../Details/styled';
import { Title } from '../Codes/Codes.constants';
import { columns } from './CustomsDeclarationCodes.constants';

export const CustomsDeclarationCodes = ({ selectedDocument }) => {
  const list = useMemo(
    () =>
      selectedDocument.aics
        ? selectedDocument.aics.map((item, idx) => ({
            aic: item,
            idx: idx + 1,
          }))
        : [],
    [selectedDocument],
  );
  const { documentDate, documentId } = selectedDocument;

  return (
    <CrossborderWrap>
      <Title>
        Сведения о ГТД{' '}
        <span>от {moment(documentDate).format('DD.MM.YYYY')}</span>
      </Title>
      <HeadingId grey>ID {documentId}</HeadingId>
      <List
        defaultPageSize={10}
        data={list}
        columns={columns}
        showPagination={list.length > 10}
      />
    </CrossborderWrap>
  );
};
