import React from 'react';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from 'src/components/Grid/Grid.styled';
import { GoodsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';
import { Value, Wrap } from 'src/fragments/Table/Cell/value';
import { Toast } from 'src/utils/Toast';
import { packTrivialTypesKZ } from '../../../../../constants/packTypes';
import { getIconNameByPackType } from '../../../../../components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { Translate } from '../../../../../common_components/Translate/Translate';

export const actionSelectOptions = [
  {
    value: 1,
    label: Translate('Удалить'),
  },
];

export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  ERROR,
};

export const CodesRenderer = ({ row, onClick }) => {
  return (
    <Wrap>
      <Value>{row.codes ? row.codes[0] : null}</Value>&nbsp;
      <Link href={''} onClick={onClick}>
        +{row.codes ? row.codes.length - 1 : null}
      </Link>
    </Wrap>
  );
};

export const columnsKZ = [
  {
    id: 'serialNumber',
    accessor: row => (
      <div>
        <Value>{row.serialNumber}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    width: 100,
  },
  {
    id: 'gtin',
    accessor: row => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 'auto',
  },
  {
    id: 'name',
    accessor: 'name',
    disableFilters: true,
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    id: 'cisPackageType',
    accessor: row => {
      const CODE = row.cisPackageType.toLowerCase();
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{Translate(packTrivialTypesKZ[CODE])}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'cis',
    accessor: row => {
      return (
        <Link
          onClick={e => {
            e.preventDefault();
            if ((!row.userINN || row.ownerInn === row.userINN || row.isOperator) && (row.direction === 'act-in' ? row.documentStatus === 4 : row.documentStatus !== 4))
              window.open(`/cis/list/${encodeURIComponent(row.cis)}`);
            else
              Toast.showError({
                content: `Открыть карточку кода идентификации может только его текущий владелец.`,
              });
          }}
        >
          <NoWrap>{row.cis}</NoWrap>
        </Link>
      );
    },
    Header: Translate('Код идентификации'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'cisesCounts',
    accessor: 'cisesCounts',
    Header: Translate('Количество потребительских упаковок'),
    disableFilters: true,
    width: 'auto',
  },
];

export const columnsKG = [
  {
    ...GoodsColumns.name,
    disableFilters: true,
  },
  {
    ...GoodsColumns.gtin,
    disableFilters: true,
  },
  {
    id: 'codes',
    accessor: row => (
      <div>
        <Value>{row.codes[0]}</Value>click here...
      </div>
    ),
    Header: Translate('Код маркировки'),
    disableFilters: true,
  },
  {
    ...GoodsColumns.certNumber,
    disableFilters: true,
  },
  {
    ...GoodsColumns.certDate,
    disableFilters: true,
  },
  {
    ...GoodsColumns.tnved,
    disableFilters: true,
  },
  {
    ...GoodsColumns.country,
    disableFilters: true,
  },
];
