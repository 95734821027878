import React, { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { documentTypes } from '../../../constants/documentTypes';
import * as constants from './constants';
import * as actions from '../ducks/DocumentDetails.actions';
import * as selectors from '../ducks/DocumentDetails.selectors';
import { get, flatten } from 'lodash';
import { WrapImportTransit } from '../DocumentDetails.styled';
import List from 'src/common_components/List/List';
import { sortLevel2 } from 'src/utils/sort';
import { productListForNewTypeDocument } from './Utils';

const Goods = ({
  selectedDocument,
  requested,
  loaded,
  data,
  onLoad,
  disaggregation,
  ...rest
}) => {
  const instance = useRef();

  const currentConstants = type => {
    switch (type) {
      case documentTypes.IMPORT_TRANSIT:
        return constants.EasImport;
      case documentTypes.IMPORT:
        return constants.Import;
      default:
        return {};
    }
  };

  const { columns, BeforeTable, AfterTable } = currentConstants(
    selectedDocument.type,
  );

  const documentProducts = useMemo(() => {
    if (selectedDocument.type === documentTypes.IMPORT_TRANSIT) {
      return selectedDocument.importingProducts.map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }));
    } else if (selectedDocument.type === documentTypes.IMPORT) {
      return productListForNewTypeDocument(selectedDocument);
    }
  }, [selectedDocument]);

  return (
    //!loaded ? <Preloader isOpened={true} /> :
    <React.Fragment>
      {BeforeTable && (
        <BeforeTable selectedDocument={selectedDocument} {...rest} />
      )}
      <WrapImportTransit
        isIT={selectedDocument.type === documentTypes.IMPORT_TRANSIT}
      >
        <List
          ref={instance}
          columns={columns}
          data={documentProducts || []}
          defaultPageSize={10}
          showPagination={documentProducts.length > 10}
        />
      </WrapImportTransit>
      {AfterTable && (
        <AfterTable selectedDocument={selectedDocument} {...rest} />
      )}
    </React.Fragment>
  );
};

Goods.propTypes = {
  loaded: PropTypes.bool.isRequired,
  selectedDocument: PropTypes.shape({}).isRequired,
  requested: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: selectors.loadedMarkedList(state),
  data: selectors.markedListData(state),
  requested: selectors.requestedMarkedList(state),
});

const mapDispatch = dispatch => ({
  onLoad: params => dispatch(actions.loadMarkedList(params)),
  disaggregation: list => dispatch(actions.disaggregation(list)),
});

export default connect(mapState, mapDispatch)(Goods);
