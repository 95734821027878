import { takeEvery, put, call } from 'redux-saga/effects';
import { get } from 'lodash';
import { Toast } from '../../../utils/Toast';
import * as actions from './CisDetails.actions';
import Api from '../../../common_components/Api/Api';

function* loadingSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/private-office-api/private/v2/cis/info`,
    method: 'get',
    params: {
      id: payload,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(actions.loaded(success.data));
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* mountSaga(action) {
  yield call(loadingSaga, action);
}

function* updateCisSaga(action) {
  yield call(loadingSaga, action);
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.updateCis, updateCisSaga);
}
