import { selector } from '../../../../utils/selector';


export const results = state => selector(state, 'Requests.RequestList.results');
export const total = state => selector(state, 'Requests.RequestList.total');
export const last = state => selector(state, 'Requests.RequestList.last');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const sorting = state => selector(state, 'Requests.RequestList.sorting');
export const selectedPage = state => selector(state, 'Requests.RequestList.selectedPage');
export const pagination = state => selector(state, 'Requests.RequestList.pagination');
export const filters = state => selector(state, 'Requests.RequestList.filters');
export const uploadErrors = state => selector(state, 'Requests.RequestList.uploadErrors');
export const offset = state => selector(state, 'Requests.RequestList.offset');
