import { handleActions } from 'redux-actions';
import * as actions from './AcceptanceNotificationOfGoodsByTheEAES.actions';

const shippingDocuments = handleActions(
  {
    [actions.setShippingDocuments]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  [],
);

const data = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  null,
);

const loaded = handleActions(
  {
    [actions.loaded]: () => true,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
  },
  false,
);
export default {
  loaded,
  data,
  shippingDocuments
}
