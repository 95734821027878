/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldHoc from '../FieldHoc';
import { Input as InputCRPT } from '@crpt-ui/core';

const InputAdapter = ({ input, meta, ...propsRest }) => {
  const { placeholder, errorPlaceholder, isError, ...rest } = propsRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  return (
    <InputCRPT
      {...input}
      label={placeholder}
      error={meta.error}
      {...rest}
      autoComplete="off"
    />
  );
};

InputAdapter.propTypes = {
  isError: PropTypes.func,
  required: PropTypes.bool,
};

InputAdapter.defaultProps = {
  isError: meta => meta.invalid,
  required: false,
};

export default FieldHoc(InputAdapter);

