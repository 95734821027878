import React, { useRef, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useDay } from '@crpt-ui/core/lib/DatePicker/hooks';
import DatePickerContext from '../DatePicker.context';
import getColor, { getWeekendTextColor } from '@crpt-ui/core/lib/DatePicker/utils/getColor';
import { countryCodeFlags } from '../../../constants';

const COLORS = {
  main: '#F9F9F9',
  active: countryCodeFlags.isUZ ? '#67ac5c': countryCodeFlags.isKZ ? '#0287B0' : '#057EC6',
  disable: '#D9D9D9',
  defaultWeekendColor: '#F34747',
  // selectedWeekendColor: '#63666A',
  selectedWeekendColor: '#ffffff',
  // selectedTodayBgColor: '#63666A',
  selectedTodayBgColor: '#ffffff',
  selectedTodayTextColor: '#FFFFFF',
};

const WEEKEND_DAYS = ['Sat', 'Sun'];

const Day = memo(({ dayLabel, date }) => {
  const dayRef = useRef(null);
  const {
    isToday,
    focusedDate,
    isDateFocused,
    isSingleDaySelected,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstSelectedDate,
    isLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    type,
  } = useContext(DatePickerContext);
  const {
    isSelected,
    isSelectedStart,
    isSelectedEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstSelectedDate,
    isLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const isTodayDay = isToday !== undefined ? isToday(date) : false;
  const isSingleSelected = isSingleDaySelected !== undefined ? isSingleDaySelected(date) : false;

  const dayName = date.toString().split(' ')[0];

  if (!dayLabel) {
    return <div />;
  }

  const getBackgroundColorFn = getColor(
    isSingleSelected || isSelected,
    isSelectedEnd || isSelectedStart,
    isWithinHoverRange,
    disabledDate,
    isTodayDay,
  );

  const getWeekendTextColorFn = getWeekendTextColor(
    isSingleSelected || isSelected,
  );


  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type='button'
      ref={dayRef}
      style={{
        width: 40,
        height: 40,
        lineHeight: '40px',
        textAlign: 'center',
        border: 0,
        outline: 'none',
        fontSize: '10px',
        fontWeight: '600',
        borderRadius: type === 'single' ? '4px' : isSelectedEnd || isSelectedStart ? `
          ${isSelectedEnd ? 0 : 4}px
          ${isSelectedStart ? 0 : 4}px
          ${isSelectedStart ? 0 : 4}px
          ${isSelectedEnd ? 0 : 4}px
        ` : '0px',
        color: (isTodayDay && isSingleSelected) ? COLORS.selectedTodayTextColor : WEEKEND_DAYS.includes(dayName) ? getWeekendTextColorFn({
          selectedColor: COLORS.selectedWeekendColor,
          normalColor: COLORS.defaultWeekendColor,
        }) : isSingleSelected ? '#ffffff' : '#63666A',
        background: getBackgroundColorFn({
          selectedFirstOrLastColor: COLORS.active,
          normalColor: COLORS.main,
          selectedColor: COLORS.active,
          rangeHoverColor: type === 'single' ? COLORS.main : COLORS.active,
          disabledColor: COLORS.disable,
          selectedTodayColor: COLORS.active,
        }),
      }}
    >
      {dayLabel}
    </button>
  );
});

Day.propTypes = {
  dayLabel: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};

export default Day;
