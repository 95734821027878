import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  color: #55b5e7;
  font-weight: 600;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16p;
   &:hover {
      color: #55b5e7;
    }
`;

export const PageWrapper = styled.div`
  height: calc(100vh - 190px);
  padding: 34px;
`;
export const TableWrap = styled.div`
  & div[role='rowgroup'] {
    height: calc(100vh - 370px) !important;
  }
  & div[role='row']:nth-last-child(1) {
    font-weight: ${props => props.withTotal ? 600 : 100 };
  }
  & *::-webkit-scrollbar {
    // display: block !important;
  }
  // Эти стили скрывают скролбар в Firefox
  @-moz-document url-prefix() {
    & div[role='rowgroup'] {
      & > div:first-child {
        & > div {
          scrollbar-width: none !important;
        }
      }
    }
  }
`;
