import { SingleSelect } from '@crpt/react-select';
import Preloader from '../../../Preloader';
import React from 'react';
import { Translate } from '../../../../common_components/Translate/Translate';

function getDefaultProps(BuyType) {
    return {
        name: 'role',
        selectedId: 0,
        selectedIdOnClear: 0,
        placeholder: 'Роль',
        savePlaceholder: true,
        value: BuyType.SELLER,
        values: [
            {
                id: 0,
                value: BuyType.SELLER,
                role: BuyType.SELLER,
                title: Translate('Продавец'),
            },
            {
                id: 1,
                value: BuyType.BUYER,
                role: BuyType.BUYER,
                title: Translate('Покупатель'),
            },
        ],
    }
}

class AsyncRoleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultProps: null
        };
    }

    render() {
        const { defaultProps } = this.state;
        const showContent = defaultProps
            ? <SingleSelect {...defaultProps} />
            : <Preloader/>;

        return showContent;
    }

    componentDidMount() {
        import('@crpt/relation-tree')
            .then(module => {
                this.setState({
                    moduleLoaded: true,
                    defaultProps: getDefaultProps(module.BuyType)
                });
            });
    }
}

export default AsyncRoleSelect;
