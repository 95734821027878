import { Tooltip } from '@crpt-ui/core';
import { makeStyles, withStyles } from '@material-ui/core';
import React, { useRef } from 'react';

const useTooltipStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: ({ isWhiteColor }) => (isWhiteColor ? '#fff' : ''),
    maxWidth: ({ setMaxWidth }) => setMaxWidth,
    color: ({ isWhiteColor }) =>
      isWhiteColor ? theme.palette.text.primary : '#fff',
  },
}));

export const CellTooltip = withStyles({
  container: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    display: 'inline',
  },
})(
  ({
    render,
    title,
    isWhiteColor = false,
    alwaysDisplay = false,
    classes,
    setMaxWidth = null,
    arrow = true,
    placement = 'bottom',
    ...rest
  }) => {
    const overrideClasses = useTooltipStyles({ isWhiteColor, setMaxWidth });
    const wrapRef = useRef();
    const contentRef = useRef();
    const wrapWidth = _.get(wrapRef, 'current.offsetWidth', 0);
    const contentWidth = _.get(contentRef, 'current.offsetWidth', 0);
    const isShow =
      wrapWidth > 0 && contentWidth > 0 && contentWidth > wrapWidth - 10;

    const getView = () => {
      return (
        <div className={classes.container} ref={wrapRef}>
          <div className={classes.content} ref={contentRef}>
            {render()}
          </div>
        </div>
      );
    };

    return isShow || alwaysDisplay ? (
      <Tooltip
        classes={overrideClasses}
        arrow={arrow}
        title={title}
        placement={placement}
        {...rest}
      >
        {getView()}
      </Tooltip>
    ) : (
      getView()
    );
  },
);
