import React from 'react';
import * as Icons from '@crpt-ui/icons';

export const formatEnum = {
  CSV: 'CSV',
};

export const taskTypeEnum = {
  REPORT: '6f154990-796c-4eb9-b003-735ea9feb6c9',
  AGGREGATION: 'f05dde7c-d110-450d-a3fc-e25adcbf2837',
  BOX_CODES: '0416bb48-e54a-44c5-b409-6d1c503ca03e',
  CIS_CODES: 'be2aee10-8b54-43de-9956-098fce8cee5f',
};

export const taskTypeValuesMap = {
  [taskTypeEnum.REPORT]: 'Отчет',
  [taskTypeEnum.AGGREGATION]: 'Ошибки агрегации',
  [taskTypeEnum.BOX_CODES]: 'Коды коробов',
  [taskTypeEnum.CIS_CODES]: 'Коды маркировки',
};

export const taskTypeStatusEnum = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
}

export const periodEnum = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};
export const periodValuesMap = {
  [periodEnum.DAY]: 'Каждый день',
  [periodEnum.WEEK]: 'Каждую неделю',
  [periodEnum.MONTH]: 'Каждый месяц',
  [periodEnum.QUARTER]: 'Каждый квартал',
  [periodEnum.YEAR]: 'Каждый год',
};

export const periodSelect = Object.entries(periodValuesMap).map(
  (item, index) => ({
    key: index,
    value: item[0],
    label: item[1],
  }),
);

export const periodicityEnum = {
  SINGLE: 'SINGLE',
  REGULAR: 'REGULAR',
};

export const periodicityValuesMap = {
  [periodicityEnum.SINGLE]: 'Однократное',
  [periodicityEnum.REGULAR]: 'Регулярное',
};

export const periodicitySelect = Object.entries(periodicityValuesMap).map(
  (item, index) => ({
    key: index,
    value: item[0],
    label: item[1],
  }),
);

export const statusEnum = {
  ARCHIVE: 'ARCHIVE',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PREPARATION: 'PREPARATION',
};

export const statusColorsMap = {
  [statusEnum.ARCHIVE]: '#868686',
  [statusEnum.CANCELED]: '#f34747',
  [statusEnum.COMPLETED]: '#48c834',
  [statusEnum.FAILED]: '#f34747',
  [statusEnum.PREPARATION]: '#868686',
};

export const statusValuesMap = {
  [statusEnum.ARCHIVE]: 'Архив',
  [statusEnum.CANCELED]: 'Отменено',
  [statusEnum.COMPLETED]: 'Выполнено',
  [statusEnum.FAILED]: 'Ошибка',
  [statusEnum.PREPARATION]: 'Подготовка',
};

export const statusIconsMap = {
  [statusEnum.ARCHIVE]: <Icons.Archive />,
  [statusEnum.CANCELED]: <Icons.Cancel />,
  [statusEnum.COMPLETED]: <Icons.Success />,
  [statusEnum.FAILED]: <Icons.Information />,
  [statusEnum.PREPARATION]: <Icons.Time />,
};

export const downloadStatusEnum = {
  SUCCESS: 'SUCCESS',
  PREPARATION: 'PREPARATION',
  FAILED: 'FAILED',
};

export const downloadStatusValuesMap = {
  [downloadStatusEnum.SUCCESS]: 'Успешно',
  [downloadStatusEnum.PREPARATION]: 'Подготовка',
  [downloadStatusEnum.FAILED]: 'Не успешно',
};

export const downloadStatusIconsMap = {
  [downloadStatusEnum.SUCCESS]: <Icons.Success />,
  [downloadStatusEnum.PREPARATION]: <Icons.Time />,
  [downloadStatusEnum.FAILED]: <Icons.Information />,
};

export const downloadStatusColorsMap = {
  [downloadStatusEnum.SUCCESS]: '#48c834',
  [downloadStatusEnum.PREPARATION]: '#868686',
  [downloadStatusEnum.FAILED]: '#f34747',
};

export const availableEnum = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const availableValuesMap = {
  [availableEnum.AVAILABLE]: 'Да',
  [availableEnum.NOT_AVAILABLE]: 'Нет',
};

export const hardcodeRoles = [
  { code: 'OGV', name: 'ФОИВ' },
  { code: 'IS_MP_OPERATOR', name: 'Оператор' },
];

export const hostname =
  window.location.hostname === 'localhost.ru'
    ? 'https://qa04.gismt.crpt.tech'
    : '';
