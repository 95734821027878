import { useCallback, useState } from 'react';

/**
 * Custom hook to handle state of the modal window
 */
export function useModalState () {
  const [isModalOpen, handleModal] = useState(false);

  const openModal = useCallback(() => handleModal(true), []);
  const closeModal = useCallback(() => handleModal(false), []);
  
  return [isModalOpen, openModal, closeModal];
}