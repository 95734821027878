import React, { useEffect } from 'react';


export const useLoadAndUpdate = props => {
  const { onMount, onUnmount, id, decodedId, isAdmits } = props;

  useEffect(() => {
    if (!id.includes('act') && !id.includes('eaes')) {
      onMount({ id: decodedId, isAdmits });
    }
    return () => onUnmount();
  }, [id]);
};
