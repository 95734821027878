import { productionLinesHelper } from './GrayAreaCreate.reducers'

export const setDisabledSteps = state => (item, index) => {
  switch (true) {
    case index > 0 && state.information.hasErrors:
    case index === 2 && Boolean(productionLinesHelper.validator(state.productionLines)):
      return { ...item, disabled: true };
    default:
      return item;
  }
};
