import styled from 'styled-components';

export const Wrap = styled.div`
  margin-bottom: 90px;
`;

export const Element = styled.div`
  display: flex;
  justify-content: space-between;
  color: #434244;
  font-size: 16px;
  margin-bottom: 8px;
  white-space: nowrap;
`;

export const Title = styled.div`
  display: flex;  
  align-items: center;
  
  &:before {
    content: ' ';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${props => props.iconColor};
    border-radius: 50%;
    margin-right: 10px;
  }
`;

export const Count = styled.div`
  font-weight: 600;
  padding-left: 10px;
`;

export const ChartWrap = styled.div`
  margin: 0 auto 32px;
  width: ${({theme}) => theme.MultiPercentChart ? `${theme.MultiPercentChart.outerRadius * 2}px` : 'auto'};
`;

export const RightPanelWrap = styled.div`
  width: 269px;
  display:inline-block;
  vertical-align: top;
`;

export const LeftPanelWrap = styled.div`
  width: calc(100% - 270px);
  display: inline-block;
  vertical-align: top;
`;
