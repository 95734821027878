import React, { useMemo, useRef, useState } from 'react';
import DataTable from '@crpt-ui/datatable';
import { columns, parseData } from './ProcessingErrors.constants';


const ProcessingErrors = ({ selectedDocument }) => {
  const data = useMemo(()=> parseData(selectedDocument.docErrors || []),[selectedDocument.docErrors])
  const [currentList, setCurrentList] = useState(data)
  const instance = useRef();

  const onFilterApply = (values) => {
    if (values.length === 0) {
      setCurrentList(data)
    } else {
      setCurrentList(data.filter(el=> el.code.indexOf(values[0].value) !== -1))
    }
  }

  return (
    <DataTable
      columns={columns}
      data={currentList}
      initialState={{ filters: [] }}
      ref={instance}
      useFilters
      onFiltersChange={onFilterApply}
    />
  )
}
export default ProcessingErrors;
