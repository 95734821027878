import * as _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';

import * as grayAreaGtinsActions from './GrayAreaGtins.actions';

function* requestGtins({ payload }) {
  const { page = 1, ...filters } = payload;
  const requestPayload = {
    url: `/api/v3/facade/product/search`,
    method: 'GET',
    params: {
      needTotal: true,
      limit: 10,
      page: page - 1,
      ...filters,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  yield put(
    grayAreaGtinsActions.loaded({
      result: _.get(success, 'data.results', []),
      total: _.get(success, 'data.total', 0),
    }),
  );
}

export default function* watch() {
  yield takeLatest(grayAreaGtinsActions.requestGtins, requestGtins);
}
