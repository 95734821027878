import React from 'react';

export default {
  title: 'Табличное представление при типе агрегации «Общий»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Тип участника',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
          subheaders: []
        },
        {
          Header: 'Количество участников',
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'Количество ККТ',
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Количество адресов ККТ',
          accessor: 'col9',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
      ],
      data: [
        {
          'col3': [3],
          'col7': [7],
          'col8': [8],
          'col9': [9],
        },
        {
          'col3': <strong>Итого</strong>,
          'col7': '...',
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
        },
      ]
    },
  ]
}