import styled from 'styled-components';

export const Wrap = styled.div`
  margin-bottom: 24px;
`;

export const CisInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-top: 40px;
  margin-bottom: 16px;
`;

export const QRCanvas = styled.canvas``;

export const GreyWrapper = styled.div`
  color: #abadb6;
`;
