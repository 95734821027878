export const registrationTheme = {
  blocker: {
    backgroundColor: 'rgba(33, 44, 66, 0.7)',
  },

  panel: {
    width: '560px',
    backgroundColor: '#FFFFFF',
    location: 'right',
  },
}
