import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withModal from 'src/common_components/Modals/hocs/withModal';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { cisKey, contentBodyConfirm, steps, tabsKZ } from '../EaesImportDocument.constants';
import { PageWrapper, StepWrapper } from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { countTotalsKZ, submit } from '../ducks/EaesImportDocument.actions';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { Toast } from 'src/utils/Toast';
import { toast } from 'react-toastify';
import List from 'src/common_components/List/List';
import { columns } from './Total.const';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { Preloader } from '../../../../../common_components/Preloader/Preloader';
// import * as Icons from '@crpt-ui/icons';
// import AddGtinFields from '../../ActDocument/Total/AddGtin';

class Total extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    isDraft: PropTypes.bool.isRequired,
    isCorrect: PropTypes.bool.isRequired,
    goodsChanged: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedRow: null,
      activePage: 1,
      rowsPerPage: 5,
    };
  }

  componentDidMount(): void {
  }

  onSubmit = () => {
    const { submit } = this.props;
    const { key } = this.props.match.params;
    submit(key);
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  toggleDialog = () => this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  onNextStep = () => {
    const { countTotalsKZ, formValues, match } = this.props;
    const { key } = match.params;
    countTotalsKZ({ ...formValues, doc_id: key });
  };

  onPreviousStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  isNextStepDisabled = () => {
    const { show, formErrors } = this.props;
    if (!show) return true;
    const cis = this.getCis();
    return isEmpty(cis);
  };

  tabsCurrent = () => {
    const { show } = this.props;
    if (!show) return tabsKZ;
    const { onSetStep } = this.props;
    return tabsKZ.map(tab =>
      tab.id === steps.cis
        ? tab
        : {
          ...tab,
          onClick: () => {
            onSetStep(tab.id);
          },
        },
    );
  };

  getCis = () => {
    const { formValues, goodsChanged } = this.props;
    return goodsChanged ? [] : formValues[cisKey] || [];
  };

  preparedCis = cis => {
    const { activePage, rowsPerPage } = this.state;
    const page = activePage - 1;

    return cis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
  };

  onCancel = () => {
    const { history } = this.props;
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  render() {
    const { isModalOpen, } = this.state;
    const { show, formValues, isDraft, goodsChanged, isCorrect } = this.props;
    const cis = this.getCis();

    const cancelBtn = (
      <CommonActionButton
        onClick={this.toggleDialog}
        disabled={false}
      >
        {Translate('Отменить')}
      </CommonActionButton>
    );
    return (
      <PageWrapper hide={!show}>
        <ConfirmModal
          isModalOpen={isModalOpen}
          onClose={this.toggleDialog}
          onSubmit={this.onCancel}
          content={contentBodyConfirm}
          header={Translate('Предупреждение')}
          cancelButtonText={Translate('Нет')}
          submitButtonText={Translate('Да')}
          notCancelable
        />
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.cis}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          {formValues.status === 18
            ? (<Preloader isOpen={true} text="Выполняется расчет итогов"/>)
            : (
              <List
                defaultPageSize={10}
                columns={columns}
                data={cis}
                showPagination={cis.length > 10}
                noDataText=""
              />
            )
          }
          <CommandBar
            left={isDraft || formValues.status === null ? [
              cancelBtn,
              <CommonActionButton
                onClick={this.onSaveDraft}
                disabled={false}
              >
                {Translate('Сохранить как черновик')}
              </CommonActionButton>,
            ] : isCorrect ? [cancelBtn] : []}
            style={{
              padding: '20px 40px',
            }}
            right={[
              // ...((formValues.status === 19 || formValues.status === null || isCorrect) && formValues.type !== 1300) ? [
              ...(formValues.status === 19 || formValues.status === null || isCorrect) ? [
                <PrimaryActionLargeButton
                  disabled={false} //this.isNextStepDisabled}
                  onClick={this.onNextStep}
                >
                  {Translate('Рассчитать итоги')}
                </PrimaryActionLargeButton>,
              ] : [],
              ...formValues.status === 19 && !goodsChanged ? [
                <PrimaryActionLargeButton
                  disabled={false}
                  onClick={this.onSubmit}
                >
                  {Translate('Отправить')}
                </PrimaryActionLargeButton>,
              ] : [],
            ]}
          />
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapDispatch = dispatch => ({
  submit: values => dispatch(submit(values)),
  countTotalsKZ: values => dispatch(countTotalsKZ(values)),
});

export default compose(
  connect(null, mapDispatch),
  withRouter,
  withModal,
)(Total);
