import styled from 'styled-components';

export const PreviewContainer = styled.div`
  padding: 40px 24px 8px 24px;
  gap: 24px;
  border-radius: 16px;
  background-color: #F8F9FA;
  border: 8px solid #0000001F;
`;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    padding:'20px 12px 4px 12px',
    gap: '12px',
    borderRadius:'16px',
    backgroundColor: '#F8F9FA',
    border: '8px solid #0000001F',
    display: 'flex',
    flexDirection: 'column',
    maxWidth:400,
    maxHeight: 690,
    overflowY: 'auto',
    pointerEvents: 'none',
  },
  banner: {
    display: 'flex',
    overflow: 'hidden',
    gap: '12px',
    height: '150px',
    minHeight: '150px',
    borderRadius: '16px',
  },
  bannerItem: {
    borderRadius: '16px',
    backgroundColor: '#0000001F',
    height: '100%',
    position: 'relative',
    flex: '1 0 80%',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '16px',
    },
  },
  bannerTitle: {
    padding: '0px 16px 16px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    color: '#FFF',
    fontSize: '16px',
    lineHeight: '18px',
  },
  fullWidth: {
    borderRadius:'16px',
    backgroundColor: '#0000001F',
    minHeight: '33px'
  },
  article: {
    borderRadius: '16px',
    border: '1px solid #0000001F',
    padding: '10px 10px 10px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 6fr',
    lineHeight: '16px',
    fontSize: '13px',
    backgroundColor: 'white',
  },
  articleTitle: {
    backgroundColor: '#0000001F',
    height: '10px',
    margin: '3px',
  },
  articleTitleShort: {
    backgroundColor: '#0000001F',
    height: '10px',
    margin: '3px',
    width: '50%',
  },

  icon: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '& img': {
      width: 20
    }
  }
});
