import styled from 'styled-components';


export const InputWithPlusOrMinusWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: stretch;
`;

export const Wrap = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 3px;
`;

export const Closed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
