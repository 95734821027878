import { createAction } from 'redux-actions';

export const subject = '[ExciseList]';

export const mounted = createAction(`${subject} mounted`);
export const loadData = createAction(`${subject} loadData`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const setError = createAction(`${subject} setErrorInfo`);
export const setPage = createAction(`${subject} setPage`);
export const download = createAction(`${subject} download`);