import React from 'react';
import { Grid } from '@material-ui/core';
import { Plus, Trash } from '@crpt-ui/icons';
import { Button } from '@crpt-ui/core';
import * as _ from 'lodash';

import {
  Autocomplete,
  Input,
  RangeDatePicker,
  Select,
} from '../../../common_components/_refactor/Form/Fields';
import { withFormValues } from '../../../common_components/_refactor/Form/FormSpy';
import {
  periodSelect,
  periodicitySelect,
  periodicityEnum,
  taskTypeEnum,
} from '../constants';
import { isOperator } from '../_utils/utils';
import { useStyles } from './UnloadServiceCreate.styles';
import { Translate } from '../../../common_components/Translate/Translate';

const UnloadServiceCreateFields = ({
  haveOnlySinglePeriodicity,
  mutators,
  rolesSelect,
  usersValues,
  values,
}) => {
  const classes = useStyles();
  const periodicity = _.get(values, 'periodicity');
  const taskTypeId = _.get(values, 'meta.taskTypeId');
  const availablePeriods = _.get(values, 'meta.availablePeriods');

  const preparedPeriodSelect =
    availablePeriods === 'all'
      ? periodSelect
      : periodSelect.filter(item => availablePeriods.includes(item.value));

  return (
    <Grid container spacing={2} className={classes.gridWrapper}>
      <Grid item sm={12} md={6}>
        <Input name="name" placeholder={Translate("Наименование задания")} required />
      </Grid>

      <Grid item sm={12} md={6}>
        <Select
          name="periodicity"
          placeholder={Translate("Тип задания")}
          options={periodicitySelect}
          disabled={haveOnlySinglePeriodicity}
          required
        />
      </Grid>

      {Boolean(periodicity) && isOperator() && (
        <Grid item sm={12} md={12}>
          <Select
            name="sharedRoles"
            placeholder={Translate("Доступно ролям")}
            options={rolesSelect}
            multiple
          />
        </Grid>
      )}

      {periodicity === periodicityEnum.REGULAR && (
        <React.Fragment>
          <Grid item sm={12} md={6}>
            <Select
              name="period"
              placeholder={Translate("Регулярность отправления")}
              options={preparedPeriodSelect}
              required
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <RangeDatePicker
              name="departurePeriod"
              placeholder={Translate("Период отправления")}
              displayFormat="dd.MM.yyyy"
              minDate={new Date()}
              required
            />
          </Grid>
        </React.Fragment>
      )}

      {periodicity === periodicityEnum.SINGLE &&
        taskTypeId !== taskTypeEnum.REPORT && (
          <Grid item sm={12} md={12}>
            <RangeDatePicker
              name="dataPeriod"
              placeholder={Translate("Период данных")}
              displayFormat="dd.MM.yyyy"
              maxDate={new Date()}
              required
            />
          </Grid>
        )}

      {Boolean(periodicity) && (
        <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            {values.users.map((name, index) => (
              <Grid item xs={12} key={name}>
                <div className={classes.selectUserWrapper}>
                  <Autocomplete
                    name={`users.${index}.user`}
                    placeholder={Translate("Получатель")}
                    options={usersValues}
                    renderOption={option => (
                      <div className={classes.autocompleteOption}>
                        <span>{option.label}</span>{' '}
                        {option.assignedRoles
                          ? `/ ${option.assignedRoles}`
                          : null}
                      </div>
                    )}
                  />

                  {index === 0 && (
                    <Button
                      onClick={() => mutators.push('users', {})}
                      icon={<Plus />}
                      width={50}
                    />
                  )}

                  {index > 0 && (
                    <Button
                      onClick={() => mutators.remove('users', index)}
                      icon={<Trash />}
                      variant="outlined"
                      width={50}
                      style={{
                        color: '#ff4b4b',
                        borderColor: '#ff4b4b',
                      }}
                    />
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withFormValues(UnloadServiceCreateFields);
