import { createAction } from 'redux-actions';

export const subject = '[CisDetails]';

export const mounted = createAction(`${subject} mounted`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const mountedAgregation = createAction(`${subject} mounted agregation`);
export const loadedAgregation = createAction(`${subject} loaded agregation`);
export const expandedAgregation = createAction(`${subject} expanded agregation`);
export const loadedAgregationRow = createAction(`${subject} loaded agregation row`);
export const updateCis = createAction(`${subject} updateCis`);