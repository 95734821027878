import React from 'react';
import { useStyles } from './upload.styled';
import { Icon } from '@material-ui/core';
import uploadIcon from '../../../../common_components/Icons/article/upload.svg';
import { Toast } from '../../../../utils/Toast';
import CloseIcon from '../../../../common_components/List/Filters/CloseIcon';
import * as actions from '../../ducks/articlesCreation.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withPermissions from '../../../../common_components/hocs/withPermissions';
import { permissionConfig } from '../../../../motp_components/_Requests/RequestList/RequestList.permissions';
import { useTranslation } from 'react-i18next';
import { BUCKET_URL } from '../../../ArticleListPage/constants';
import { readImageFile } from '../../../../utils/fileReader';

const LIMIT = 2; // 2MB
const ACCEPT_EXTENSIONS = ['image/jpg', 'image/jpeg', 'image/png'];
const MIN_WIDTH = 800
const MIN_HEIGHT = 600

const ImageUpload = ({ setBanner, banner, uploadFile, lang }) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const styles = useStyles();
  const { t } = useTranslation();

  const onFinish = (img) => {
    if (img) {
      setBanner(img);
      setFile(file);
    } else {
      Toast.showError({ content: t('Не удалось загрузить файл')});
    }
  }

  const handleFiles = async file => {
    try {
      if (!file) return;

      if (!ACCEPT_EXTENSIONS.includes(file.type)) {
        Toast.showError({
          content: `${t(
            'Файл имеет недопустимый тип (должен быть',
          )} ${ACCEPT_EXTENSIONS.join(', ')}).`,
        });
        return;
      }

      if (file.size >= LIMIT * 1024 * 1024) {
        Toast.showError({
          content: t('Файл превышает максимально допустимый размер в 2МБ.'),
        });
        return;
      }

      const { width, height } = await readImageFile(file);

      if (width < MIN_WIDTH || height < MIN_HEIGHT) {
        Toast.showError({
          content: t(
            'Недопустимый размер изображения (должно иметь размер не менее {{width}} точек в ширину и {{height}} точек в высоту)',
            {
              width: MIN_WIDTH,
              height: MIN_HEIGHT,
            },
          ),
        });
        return;
      }

      uploadFile({ file, imageUsage: 'BANNER', onFinish, lang });
    } catch (error) {
      Toast.showError({ content: t('Не удалось загрузить файл') });
      console.log(error)
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  };

  const removeBanner = () => {
    setFile(null);
    setBanner(null);
  }

  return (
    <div>
      <label htmlFor="">{t('Загрузка изображения')}</label>
      {
        banner
          ? (
            <div className={styles.uploadedContainer}>
              <div className={styles.uploadedBanner}>
                <img src={BUCKET_URL + banner} alt=""/>
                <div>
                  <div>{file ? file.name : ''}</div>
                  <div>{file ? file.size + 'B' : ''}</div>
                </div>
              </div>
              <CloseIcon onClick={removeBanner} />
            </div>
          )
          : (
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
              <input type="file" id="input-file-upload" multiple={true} onChange={handleChange}/>
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                <div>
                  <Icon component={uploadIcon} style={{ width: 50, height: 35 }}/>
                  <p><span>{t('Нажмите для выбора')} </span>
                    {t('или перенесите файл в эту область')}</p>
                  <p>JPG, JPEG, PNG</p>
                  <p>{t('Изображение должно иметь размер не менее 800 точек в ширину и 600 точек в высоту')}</p>
                  <p> {t('Максимальный размер файла 2 Мб')}</p>
                </div>
              </label>
              {dragActive &&
                <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag}
                     onDrop={handleDrop}/>}
            </form>
          )
      }
    </div>
  )
}

const mapState = state => ({});

const mapDispatch = dispatch => ({
  uploadFile: (data) => dispatch(actions.uploadFile(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
)(ImageUpload);
