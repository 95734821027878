import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Stage, Layer, Shape } from 'react-konva';


class SmallLineChart extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      chartLine: PropTypes.shape({
        stroke: PropTypes.string.isRequired,
        strokeWidth: PropTypes.number.isRequired,
      }).isRequired,
      chartFill: PropTypes.shape({
        fill: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  };

  prepareData = () => {
    const { theme } = this.props;
    const { data } = this.props;
    if (!data.length || data.length < 2) return [];

    const maxCount = Math.max(...data);
    const minCount = Math.min(...data);
    const deltaCount = maxCount - minCount;

    const minY = minCount > 0
      ? 5
      : 0;
    const maxY = theme.height - 5;
    const deltaY = maxY - minY;

    const deltaX = Math.ceil(theme.width / (data.length - 1));

    const result = [];

    data.forEach((c, i) => {
      const scaledY = Math.ceil(minY + deltaY * ((c - minCount) / deltaCount));
      result.push([deltaX * i, theme.height - scaledY]);
    });

    return result;
  };

  renderLine = (ctx, points) => {
    for (let i = 0; i < points.length - 1; i++) {
      const [x1, y1] = points[i];
      const [x2, y2] = points[i + 1];

      const midX = Math.round((x1 + x2) / 2);
      const midY = Math.round((y1 + y2) / 2);
      const deltaX1 = Math.round((midX + x1) / 2);
      const deltaX2 = Math.round((midX + x2) / 2);

      ctx.quadraticCurveTo(deltaX1, y1, midX, midY);
      ctx.quadraticCurveTo(deltaX2, y2, x2, y2);
    }
  };

  renderShape = (ctx) => {
    const { theme } = this.props;
    const { width, height, chartLine, chartFill } = theme;
    const points = this.prepareData();
    if (!points.length) return;

    const [startX, startY] = points[0];

    ctx.fillStyle = chartFill.fill;
    ctx.strokeStyle = chartLine.stroke;
    ctx.lineWidth = chartLine.strokeWidth;

    ctx.beginPath();
    ctx.moveTo(startX, startY);
    this.renderLine(ctx, points);
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();
    ctx.moveTo(startX, startY);
    this.renderLine(ctx, points);
    ctx.stroke();
    ctx.closePath();
  };

  render() {
    const { theme } = this.props;

    return (
      <Stage width={theme.width} height={theme.height}>
        <Layer>
          <Shape sceneFunc={this.renderShape} />
        </Layer>
      </Stage>
    );
  }
}


export default withTheme(SmallLineChart);
