import { createActions } from 'redux-actions';

export const {
  requestUsers,
  clear,
  loaded,
} = createActions(
  {
    REQUEST_USERS: payload => payload,
    CLEAR: () => [],
    LOADED: payload => payload
  },
  {
    prefix: 'GRAY_AREA_USERS',
  },
);
