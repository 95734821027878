export function getPackageNameByPackageTypeInParentalCase(type, lower) {
  switch (type.toLowerCase()) {
    case 'box':
      return `${lower ? 'т' : 'Т'}ранспортную`;
    case 'blck':
      return `${lower ? 'г' : 'Г'}рупповую`;
    case 'pack':
      return `${lower ? 'п' : 'П'}отребительскую`;
    case 'pa':
      return `${lower ? 'п' : 'П'}алетту`;
    default:
      return '';
  }
}