import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { StepTitle } from '../Document/Document.styled';
import Input from '../../../../common_components/Form/MaterialFields/Input/Input';
import {
  required,
  validateRequiredAndLength,
} from '../../../../common_components/Form/utils/validations';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import Select from '../../../../common_components/Form/MaterialFields/Select/Select';
import { pgTypesNames } from '../../../../constants/productGroups';
import * as selectors from './ducks/Aik.selectors';
import { language } from '../../../../selectors/LoginFormKep';

const InfoFields = props => {
  const {
    formValues,
    user,
    finalConfirm,
    formMutators,
    tnvedAddUnits,
    currentLanguage,
  } = props;

  const { t } = useTranslation();

  const productGroupsList = user
    ? user.product_group_info
        .filter(el => el.status === '5' && el.types.includes('IMPORTER'))
        .map(el => ({ value: el.name, label: t(pgTypesNames[el.name]) }))
    : [];

  const tnvedAddUnitsOptions = tnvedAddUnits
    ? [
        { value: '-', label: t('Отсутствует') },
        ...tnvedAddUnits.values.map(tnvedAddUnitsValue => ({
          value: tnvedAddUnitsValue.code,
          label: `${
            tnvedAddUnitsValue.translations[currentLanguage.toLowerCase()]
          } (${tnvedAddUnitsValue.code})`,
        })),
      ]
    : [];

  useEffect(() => {
    if (finalConfirm) return;

    if (formValues && formValues.tnvedAddUnits === '-') {
      formMutators.updateField('tnvedAddUnitsCount', null);
    }
    if (
      formValues &&
      formValues.tnvedAddUnits &&
      !formValues.tnvedAddUnitsCount
    ) {
      formMutators.updateField('tnvedAddUnitsCount', null);
    }
  }, [formValues.tnvedAddUnits]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid>
          <StepTitle noMarginTop>{t('Общие данные')}</StepTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="applicantInn"
            placeholder={t('ИНН/ПИНФЛ заявителя')}
            required
            validate={required}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="applicantName"
            placeholder={t('Наименование заявителя')}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            forceValidate
            name="pg"
            disabled={!!formValues.aik || finalConfirm}
            placeholder={t('Товарная группа')}
            required
            validate={required}
            options={productGroupsList}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="tnved"
            disabled={!!formValues.aik || finalConfirm}
            placeholder={t('ТНВЭД')}
            format={(value)=> value ? value.replace(/[^\d]/g, '') : value}
            InputProps={{
              inputProps: {
                maxLength: 10,
              }
            }}
            required
            validate={validateRequiredAndLength(10)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="gtin"
            format={(value)=> value ? value.replace(/[^\d]/g, '') : value}
            placeholder={t('GTIN потребительской упаковки')}
            InputProps={{
              inputProps: {
                maxLength: 14,
              }
            }}
            required
            validate={validateRequiredAndLength(14)}
            disabled={!!formValues.aik || finalConfirm}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            forceValidate
            name="count"
            placeholder={t('Количество потребительских упаковок')}
            required
            validate={required}
            disabled={!!formValues.aik || finalConfirm}
            format={(value)=> {
              const currentValue = value ? value.replace(/[^\d]/g, '') : value
              return currentValue && currentValue !== "" ? Math.round(currentValue) : currentValue;
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            forceValidate
            name="tnvedAddUnits"
            placeholder={t('Дополнительная единица измерения')}
            required
            validate={required}
            options={tnvedAddUnitsOptions}
            disabled={!!formValues.aik || finalConfirm}
          />
        </Grid>
        {formValues.tnvedAddUnits && formValues.tnvedAddUnits !== '-' && (
          <Grid item xs={6}>
            <Input
              forceValidate
              name="tnvedAddUnitsCount"
              placeholder={t(
                'Количество товара в дополнительной единице измерения',
              )}
              required={
                formValues.tnvedAddUnits && formValues.tnvedAddUnits !== '-'
              }
              validate={
                formValues.tnvedAddUnits && formValues.tnvedAddUnits !== '-'
                  ? required
                  : null
              }
              disabled={!!formValues.aik || finalConfirm}
              onBlur={() => {
                const currentValue = formValues.tnvedAddUnitsCount
                  ? formValues.tnvedAddUnitsCount
                      .replace(',', '.')
                      .replace(/[^0-9.]+/g, '')
                  : formValues.tnvedAddUnitsCount;
                let result = '';
                if (currentValue) {
                  const floatValue = currentValue
                    ? parseFloat(currentValue)
                    : currentValue;
                  const fixedValue = floatValue >= 0.01 ? 2 : 6;
                  result = floatValue.toFixed(fixedValue);
                }
                formMutators.updateField('tnvedAddUnitsCount', result);
              }}
              formatOnBlur={true}
            />
          </Grid>
        )}
      </Grid>
      {finalConfirm && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input fullWidth name="aik" placeholder={t('АИК')} disabled />
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              name="productName"
              placeholder={t('Наименование товара')}
              disabled
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapState = state => ({
  user: authSelectors.userInfo(state),
  tnvedAddUnits: selectors.tnvedAddUnits(state),
  currentLanguage: language(state),
});

export default connect(mapState, null)(InfoFields);
