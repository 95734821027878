import { Link } from 'react-router-dom';
import React from 'react';
import { Translate } from '../../../common_components/Translate/Translate';
import styled from 'styled-components';

export const columns = [
  {
    id: 'aic',
    // accessor: 'aic',
    accessor: d => (
      <Link target="_blank" to={`/cis/list/${d.aic}`}>{d.aic}</Link>
    ),
    Header: Translate('АИК'),
  },
]
