import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tabs from '@crpt-ui/core/lib/Tabs/Tabs';
import { PageWrapper } from '../Act/Showing.styled';
import * as selectors from '../ducks/DocumentDetails.selectors';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';
import * as AppSelectors from 'src/common_components/App/App.selectors';
import * as actions from '../ducks/DocumentDetails.actions';
import { tabs } from './EaesImport.constants';
import { TabsWrap } from '../DocumentDetails.styled';
import Preloader from '../../../common_components/Preloader/Preloader';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { correctData } from 'src/motp_components/_Requests/RequestList/EaesImportDocument/ducks/EaesImportDocument.actions';
import { signDecision } from 'src/motp_components/_Requests/RequestList/EaesImportDocument/ducks/EaesImportDocument.actions';
import { Translate } from '../../../common_components/Translate/Translate';

const EasBasicInfo = React.lazy(() => import('./EasBasicInfo/Info'));
const Goods = React.lazy(() => import('./Goods/Goods'));
const Total = React.lazy(() => import('./EaesTotal'));

const EaesImportShow = props => {
  const {
    id,
    direction,
    selectedDocument,
    loaded,
    breadcrumbs,
    onNavClick,
    onCorrectData,
    history,
    onSignDecision,
  } = props;
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    const { onMount, onUnmount } = props;
    // onMount({ direction, id });
    return onUnmount;
  }, []);

  const mapPage = [
    <EasBasicInfo selectedDocument={selectedDocument} direction={direction} />,
    <Goods
      selectedDocument={selectedDocument}
      direction={direction}
      id={id}
      onCorrectData={onCorrectData}
      onSignDecision={onSignDecision}
      redirect={history.replace}
    />,
    <Total
      selectedDocument={selectedDocument}
      direction={direction}
      id={id}
      onCorrectData={onCorrectData}
      onSignDecision={onSignDecision}
      redirect={history.replace}
    />,
  ];

  return (
    <React.Fragment>
      <Preloader isOpened={!loaded} text={Translate('Идет загрузка...')}>
        <Breadcrumbs
          withCustomLinks
          onClick={val => onNavClick(val.href)}
          breadcrumbs={breadcrumbs}
          paddingTop="0"
          paddingBottom="17px"
        />
        <TabsWrap>
          <Tabs
            value={page}
            items={tabs.map(tab => ({
              ...tab,
              onClick: () => setPage(tab.id),
            }))}
            variant={'fullWidth'}
          />
        </TabsWrap>
        <PageWrapper style={{ minHeight: 'calc(100vh - 191px)' }}>
          <React.Suspense fallback={<Preloader isOpened={true} />}>
            {mapPage[page]}
          </React.Suspense>
        </PageWrapper>
      </Preloader>
    </React.Fragment>
  );
};

EaesImportShow.propTypes = {
  selectedDocument: PropTypes.shape({}).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onNavClick: PropTypes.func.isRequired,
  onCorrectData: PropTypes.func.isRequired,
  onSignDecision: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: selectors.loaded(state),
  selectedDocument: selectors.selectedDocument(state),
  isLoading: preloaderSelectors.loading(state),
  countryCode: AppSelectors.countryCode(state),
});

const mapDispatch = dispatch => ({
  onUnmount: () => dispatch(actions.unmounted()),
  onCorrectData: data => dispatch(correctData(data)),
  onSignDecision: params => dispatch(signDecision(params)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(EaesImportShow);
