import * as _ from 'lodash';
import moment from 'moment';

import { getOrgRoles } from '../../../utils/userUtils';
import { PARTICIPANT_TYPES } from '../../../constants/participantTypes';

export const formatDate = date => {
  const dateDMY = date && moment(`${date}Z`).format('DD.MM.YYYY');
  const dateHMS = date && moment(`${date}Z`).format('HH:mm:ss');

  if (!dateDMY && !dateHMS) {
    return ``;
  }

  return `${dateDMY} в ${dateHMS}`;
};

export const formatPeriodDate = (date, dataStartName, dataEndName) => {
  const startDate =
    _.get(date, dataStartName, '') &&
    moment(_.get(date, dataStartName, '')).format('DD.MM.YYYY');

  const endDate =
    _.get(date, dataEndName, '') &&
    moment(_.get(date, dataEndName, '')).format('DD.MM.YYYY');

  return !startDate && !endDate ? '' : `С ${startDate} по ${endDate}`;
};

export const isOperator = () => {
  const orgRoles = getOrgRoles();
  return orgRoles.includes(PARTICIPANT_TYPES.IS_MP_OPERATOR);
};

export const alphabetSort = (rowA, rowB, columnId) => {
  const a = _.get(rowA, `values[${columnId}]`, '').toUpperCase();
  const b = _.get(rowB, `values[${columnId}]`, '').toUpperCase();
  return a > b ? 1 : -1;
};

export const numberSort = (rowA, rowB, columnId) => {
  const a = Number(_.get(rowA, `values[${columnId}]`, 0));
  const b = Number(_.get(rowB, `values[${columnId}]`, 0));
  return a - b;
};

export const getOrgRolesStr = (orgRoles, allOrgRoles) =>
  _.isArray(orgRoles)
    ? orgRoles
        .map(role => allOrgRoles.find(item => item.code === role.role))
        .filter(item => item)
        .map(item => item.name)
        .join(',  ')
    : '—';

/**
 * @param {Date or ISOString} rows[i].values[key]
 * @param {Date or ISOString} value.startDate
 * @param {Date or ISOString} value.endDate
 */
export const rangeDateFilter = (rows, key, value) =>
  _.chain(rows)
    .filter(row => {
      if (!row.values[key]) {
        return false;
      }

      const { startDate, endDate } = value;
      const startDateFormat = moment(startDate).format('YYYY-MM-DD');
      const endDateFormat = moment(endDate).format('YYYY-MM-DD');
      const rowDate = moment(moment(row.values[key]).format('YYYY-MM-DD'));

      if (
        rowDate.diff(startDateFormat, 'days') < 0 ||
        rowDate.diff(endDateFormat, 'days') > 0
      ) {
        return false;
      }

      return true;
    })
    .value();
