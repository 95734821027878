import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import DefaultTableCell from '../../../theme/ru-tabaco/table/default/TableCell';

const TableBorderColor = 'rgba(196, 196, 196, .6)';

export const customContentStyles = {
  content: {
    position: 'relative',
    top: '30px',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'visible',
    width: 'calc(100% - 40px)',
    maxWidth: '1360px',
    border: 'none',
    borderRadius: '3px',
    padding: '34px 0 30px',
    marginBottom: '30px',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    overflow: 'auto',
    backgroundColor: 'rgba(82, 83, 90, 0.5)',
    zIndex: 10,
  }
};

export const ModalCloseCross = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  width: 19px;
  height: 19px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 60px 20px 28px;
`;
export const HeaderIcon = styled.div`
  margin-right: 30px;
`;
export const H1 = styled.h1`
  font-size: 26px;
  line-height: 1.1;
  color: #52535a;
  font-weight: 700;
`;
export const H2 = styled.h2`
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4;
  color: #434244;
  margin: 16px 34px 0 28px;
`;

export const WrapperTables = styled.div`
  padding-bottom: 26px;
  max-width: 100%;
  overflow-x: auto;
  margin-top: 26px;
  & strong {
    font-weight: 600;
  }
`
export const WrapperTable = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
  .rt-tr-group:last-child {
    border-bottom: 1px solid ${TableBorderColor};
  }
  .-highlight .rt-tbody .rt-tr:hover .rt-td {
    border-right: 1px solid rgba(196, 196, 196, 0);
    border-top: 1px solid rgba(196, 196, 196, 0);
  }
`
export const TableCell = {
  style: css`
    ${DefaultTableCell['style']}
    flex-wrap: wrap;
    line-height: 1.4;
    padding: 10px 28px;
    border-top: 1px solid ${TableBorderColor};
    border-right: 1px solid ${TableBorderColor};
    height: auto;
    &:first-child {
    }
    &:last-child {
      border-right: 0;
    }
    & ul {
      margin-bottom: 0;
    }
    > div {
      width: auto;
    }
  `,
};
export const TableHeaderCell = {
  style: css`
    ${TableCell['style']}
    min-height: 68px;
    position: relative;
    border-right: 1px solid ${TableBorderColor};
    border-top: 1px solid ${TableBorderColor};
    font-weight: 600;
    padding: 10px 20px;
    justify-content: center;
    text-align: center;
    &.-cursor-pointer {
      cursor: pointer;
    }
    &:last-child {
      border-right: 0;
    }
  `,
};
export const TableHeaderGroupCell = {
  style: css`
    ${TableHeaderCell['style']}
    min-height: 40px;
    padding: 5px 20px;
  `,
};

export const WrapperCircleNumber = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto !important;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`
export const CircleNumber = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(196, 196, 196, 0.25);
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin-right: 16px;
  @media (max-width: 770px) {
    margin-right: 0;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Footer = styled.div`
  margin: 4px 30px 0 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 840px) {
    margin-top: 30px;
  }
`;

export const WrapperDescription = styled.div`
  margin-bottom: 24px;
  padding: 0 28px;
`

export const DescriptionHeader = styled.div`
  color: #55B5E7;
  cursor: pointer;
  & svg {
    position: relative;
    top: 3px;
  }
`

export const DescriptionText = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
`

export const PreTable = styled.div`
  border-top: 1px solid rgba(196,196,196,.6);
  display: flex;
  min-width: ${props => props.preWidth}px;
`
export const PreTableItem = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 5px;
  text-align: center;
  flex: ${props => props.itemWidth} 0 auto;
  width: ${props => props.itemWidth}px;
  min-height: 28px;
  border-right: 1px solid rgba(196,196,196,.6);
  &:last-child {
    border-right: none;
  }
`

export const ShortDescriptionWrapper = styled.div`
  font-size: 18px;
  line-height: 1.4;
  color: #434244;
`

export const ImportantBlockWrapper = styled.div`
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  color: #434244;
  background-color: rgba(196,196,196,0.4);
  margin: 0 0 25px;
  padding: 5px 34px 5px 28px;
  strong {
    font-weight: 600;
    font-size: 20px;
  }
`;

export const ImportantBlock = ({children, ...props}) => (
  <ImportantBlockWrapper {...props}>
    <Icon type="info" spacingRight={16} fill="#434244" size={16}/>
    {children}
  </ImportantBlockWrapper>
)
