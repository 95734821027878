import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';
import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import { Grid } from '@material-ui/core';
import { DividerOfContent, DocumentTitleName } from '../../Document/Document.styled';
import { TitleWrapper } from '../CisRegistration.styles';

export const CisRegistrationInfo = () => {
  return (
    <div>
      <TitleWrapper>
        <DocumentTitleName>
          {Translate('Заявка на регистрацию кодов ИС МДЛП')}
        </DocumentTitleName>
      </TitleWrapper>
      <DividerOfContent />
      <br/>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={6}>
          <Input
            name="inn"
            placeholder={Translate('ИНН (или аналог) заявителя')}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="name"
            placeholder={Translate('Наименование заявителя')}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="pg"
            placeholder={Translate('Товарная группа')}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="country"
            placeholder={Translate('Страна эмиссии')}
            disabled
          />
        </Grid>
      </Grid>
    </div>
  )
}
