import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@crpt/react-card';
import { Column, Container } from '@crpt/react-page-layout';

import Table from '@crpt/react-table';
import { values, compact } from 'lodash';
import { PreviewWrapperCsv, WrapBlock, TitleBlock, DataBlock, TitleTable } from '../FilePreview.styled';
import { transformChunkArray, getDataTable, getBlockTitles } from '../FilePreview.utils';
import {Translate} from '../../Translate/Translate';

const styleColumn = {
  paddingLeft: '0',
};

const styleCard = {
  padding: '0 0 14px 0',
};

const CsvFilePreviewView = ({ content, theme }) => {
  const arrData = transformChunkArray(content[0], 3);
  const tovars = content.slice(2);
  const [columns, data] = getDataTable(tovars);
  const titles = getBlockTitles(content);

  let productsСount = 0;
  data.forEach(item => { if (compact(values(item)).length > 0) productsСount++; });

  return (
    <PreviewWrapperCsv>

      <Card style={styleCard}>
        <Container fillWidth gutter={32}>
          {arrData.map((items, i) => (
            <Column col={8} style={styleColumn} key={i}>
              {items.map((item, j) => (
                <WrapBlock key={j}>
                  <TitleBlock>{item}</TitleBlock>
                  <DataBlock>{(titles[item] !== '' ? titles[item] : Translate('Отсутствует'))}</DataBlock>
                </WrapBlock>
              ))}
            </Column>
          ))}
        </Container>
      </Card>

      <TitleTable>
        {Translate('Товары')} {productsСount > 0 ? ` (${productsСount} ${Translate('шт.')}` : null}
      </TitleTable>
      <Table
        theme={theme}
        columns={columns}
        data={data}
        minRows={0}
        highlight
        striped
        defaultPageSize={1000}
      />

    </PreviewWrapperCsv>
  );
};

CsvFilePreviewView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default CsvFilePreviewView;
