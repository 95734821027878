import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Warehouse from '../../components/ParticipantDetails/Warehouse';
import ConnectParts from '../ConnectParts';

class WarehouseBound extends Component {
  render() {
    return (
      <ConnectParts
        component={Warehouse}
        actions={[]}
        selectors={[]}
        {...this.props}
      />
    );
  }
}

export default withRouter(WarehouseBound);
