import styled from 'styled-components';

export const AvatarOverlay = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(82, 83, 90, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
`;
