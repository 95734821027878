import styled from 'styled-components';

export const InfoBlock = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
`;

export const InfoBlockLabel = styled.div`
  margin-right: 10px;
  color: #ada0a0;
`;

export const TableWrap = styled.div`
  & div[role="row"] {
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
  }
  & div[role="rowgroup"] {
    height: 300px !important;
  }
`;
