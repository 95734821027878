import React, { useContext, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormContext } from '../../Form.constants';

const styles = {
  firstLabel: {
    margin: '0',
  },
  lastLabel: {
    margin: '0 0 0 20px',
  },
};

const RadioGroupField = (props) => {
  const { classes, radioButtons, name } = props;

  const [value, setValue] = React.useState(props.defaultValue);
  const context = useContext(FormContext)

  useEffect(() => {
    context.formChange(name, value)
  }, [])

  const handleChange = event => {
    let { value } = event.target;
    console.log('name, value', name, value)
    if (value === 'true' || value === 'false') {
      value = value === 'true' ? true : false;
    }
    setValue(value);
    context.formChange( name, value)
  };
  return (
    <React.Fragment>
      <FormControl component="fieldset">
        <RadioGroup
          value={String(value)}
          onChange={handleChange}
        >
        {radioButtons.map((item, i) => (
          <FormControlLabel
            key={i}
            className={classes.firstLabel}
            value={String(item.value)}
            control={<Radio />}
            label={item.label}
          />
        ))}
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default withStyles(styles)(RadioGroupField);
