import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import RegistrationWrapper from '../Wrappers/RegistrationWrapper';
import ConnectParts from '../ConnectParts';
import * as selectors from '../../selectors/index';
import * as actions from '../../actions/index';

class RegistrationBound extends Component {
  render() {
    return (
      <ConnectParts
        component={RegistrationWrapper}
        selectors={[selectors.Registration]}
        actions={[actions.Registration, actions.Sidebar]}
        {...this.props}
      />
    );
  }
}

export default withRouter(RegistrationBound);
