export const foivValues = [
  { id: 1, title: 'Федеральная налоговая служба', type: 1 },
  { id: 2, title: 'Федеральная служба безопасности', type: 2 },
  {
    id: 3,
    title: 'Федеральная служба по надзору в сфере защиты прав потребителей',
    type: 3,
  },
  { id: 4, title: 'Федеральная таможенная служба', type: 4 },
];
