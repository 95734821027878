export function getAllPlainValuesAsObj(obj) {
  const plain = {};
  Object.keys(obj).forEach(key =>
    typeof obj[key] !== 'object' ? (plain[key] = obj[key]) : null,
  );
  return plain;
}

export function getThemeAsPlainObjectByKeys(theme, ...keys) {
  const plain = getAllPlainValuesAsObj(theme);

  keys.forEach(key => theme[key] && Object.assign(plain, theme[key]));

  return plain;
}

export function innerMerge(obj, ...others) {
  others.forEach(v => {
    for (const key in v) {
      if (typeof obj[key] === 'object' && typeof v[key] === 'object') {
        obj[key] = innerMerge({}, obj[key], v[key]);
      } else {
        obj[key] = v[key];
      }
    }
  });

  return obj;
}

export const toStyled = (name, target) => props =>
  name in props ? `${target}: ${props[name]};` : '';

export const onSelectBlocker = event => {
  event.stopPropagation();
};

export const getSelectedValues = (selectedIds, values) =>
  values.filter(value => selectedIds.includes(value.id));

export const getValidDefaultSelectValues = (defaultSelectValues, options) => {
  const defaultSelectValuesIds = defaultSelectValues
    .filter(value => value.id !== undefined)
    .map(value => value.id);
  return options.filter(option => defaultSelectValuesIds.includes(option.id));
};

export const getInputValueString = selectValue =>
  selectValue.map(v => v.title).join('; ');

export const isFiltered = ({ item, value }) => {
  const filteredString = item.filterString
    ? item.filterString
    : item.title && typeof item.title === 'string'
    ? item.title
    : value;
  if (typeof filteredString === 'string') {
    return (
      filteredString.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !==
      -1
    );
  }

  return false;
};

export const isOptionsSelected = (option, selectValues) =>
  selectValues.map(({ id }) => id).includes(option.id);

export const toddleSelectValues = (option, selectValues) => {
  if (isOptionsSelected(option, selectValues)) {
    return selectValues.filter(value => value.id !== option.id);
  }
  return [...selectValues, option];
};
