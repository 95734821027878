import React from 'react';
import { SingleSelect } from '@crpt/react-select';
import { FilterURLHelper } from 'src/utils/FilterURLHelper';
import { getPackageTypes } from './utils';

const packageTypes = getPackageTypes();

class ProductPackageSelect extends React.Component {
  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {

    //TODO: all lists to List.saga, all filter fill in saga

    setTimeout(() => {
      const { name } = this.props;
      const value = FilterURLHelper.getParamByKey(name);
      if (value) {
        const productPackage = packageTypes.find(v => v.package_type === value);
        this.select.current.onSelect(
          { preventDefault: () => {} },
          productPackage
        );
      }
    }, 500);

  }

  render() {
    const { onRef } = this.props;

    return (
      <SingleSelect
        values={packageTypes}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

ProductPackageSelect.displayName = 'SingleSelect';

export default ProductPackageSelect;
