export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИД розничной т.т.',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 171,
          subheaders: []
        },
        {
          Header: 'ИНН',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 93,
          subheaders: []
        },
        {
          Header: 'Адрес установки ККТ',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
        {
          Header: 'Регистрационный номер ККТ',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Статус розничной т.т.',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 218,
          subheaders: []
        }   
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5]
        }
      ]
    }
  ]
}