export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Описание',
          accessor: 'col2',
          minWidth: 630,
        }   
      ],
      data: [
        {
          'col1': [1],
          'col2': 'Наименование производителя товара'
        },
        {
          'col1': [2],
          'col2': 'Тип упаковки'
        },
        {
          'col1': [3, 4],
          'col2': 'Уникальный код и наименование товара'
        },
        {
          'col1': [5],
          'col2': 'Итоговое количество эмитированных кодов маркировки за период'
        },
        {
          'col1': [6],
          'col2': 'Итоговое количество нанесённых кодов маркировки за период'
        },
        {
          'col1': [7],
          'col2': 'Итоговое количество отгруженного товара за период'
        },
        {
          'col1': [8],
          'col2': 'Итоговое количество товара, реализованного конечному покупателю за период'
        },
        {
          'col1': [9],
          'col2': 'Итоговое количество товара, повторно реализованного конечному покупателю за период'
        },
        {
          'col1': [10],
          'col2': 'Итоговое количество списанного товара за период'
        }
      ]
    }
  ]
}