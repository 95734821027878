import { createActions } from 'redux-actions';

export const {
  requestProductionLines,
  clear,
  loaded,
} = createActions(
  {
    REQUEST_PRODUCTION_LINES: payload => payload,
    CLEAR: () => [],
    LOADED: payload => payload
  },
  {
    prefix: 'GRAY_AREA_PRODUCTIONS_LINES',
  },
);
