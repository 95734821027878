import styled from 'styled-components';


const HeadingId = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${({ grey }) => grey ? '#6e7277' : '#232c40'};
`;

export default HeadingId;
