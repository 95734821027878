import Crypto, { Base64 } from '@crpt-ui/crypto';
import { saveAs } from 'file-saver/FileSaver';
import * as _ from 'lodash';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';
import { filters2url } from 'src/utils/urlUtils';

import * as grayAreaDocumentActions from '../GrayAreaDocument/ducks/GrayAreaDocument.actions';
import * as grayAreaActions from './GrayArea.actions';
import { adaptCreateDocumentBody } from './GrayArea.adapters';
import { prepareSearchFilters, paramIdsMap } from './GrayArea.utils';
import { prepareSortParam, getSortData2url } from 'src/utils/urlUtils';
import i18next from 'i18next';

const hostname =
  window.location.hostname === 'localhost.ru'
    ? 'https://qa04.gismt.crpt.tech'
    : '';

function* onDownloadReport({ payload }) {
  const pg = 3;
  const fileId = payload
    .match(/(file-)(.*)(?=\.csv\.zip)/)[0]
    .replace('file-', '');
  const url = `${hostname}/dispenser/v1/results/${fileId}/file`;

  const [success, error] = yield call(Api.fetchFile, url, {
    params: { pg },
  });

  if (success) {
    const url = _.get(success, 'config.url');
    const filename = url
      .match(/results.+file/)[0]
      .replace(/\//g, '')
      .replace(/results/, '')
      .replace(/file/, '');

    const file = new Blob([success.data], { type: 'application/zip' });
    yield call(saveAs, file, `${filename}.zip`);
  }

  if (error) {
    yield call(Toast.showError, {
      content: _.get(error, 'message', 'error'),
    });
  }
}

function* updateUrlParams({ history, urlString }) {
  yield call(history.push, urlString);
}

function* requestDocumentsList({ payload = {} }) {
  const {
    page = 1,
    filters = [],
    history,
    updateHistory = false,
    pathname = '',
    sort,
  } = payload;

  const defaultSort = [{ id: 'createDate', desc: true }];
  const sortData = sort && sort.length ? sort : defaultSort;

  const preparedFilters = prepareSearchFilters(filters);
  const preparedSort = prepareSortParam(sortData, paramIdsMap);

  const requestPayload = {
    url: '/api/v3/gray-zone/gray-zone-document/search',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    params: {
      page: page - 1,
      size: 10,
      ...preparedSort,
    },
    data: {
      ...preparedFilters,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (error) {
    console.log('*** GRAY-AREA ERROR HAPPENS ***');
    console.log('error: ', error);
    yield put(
      grayAreaActions.setError(
        i18next.t('При заргузке данных произошла ошибка'),
      ),
    );
  }

  let documentsResults = [];

  if (success) {
    if (updateHistory && history) {
      const filtersAndSortsUrl = filters2url([
        ...filters,
        ...getSortData2url(sortData),
      ]);
      const urlString = `${pathname}?${
        filtersAndSortsUrl ? `${filtersAndSortsUrl}&` : ''
      }page=${page}`;

      yield call(updateUrlParams, { history, urlString });
    }

    documentsResults = success.data;
  }

  yield put(grayAreaActions.loaded(documentsResults));
}

function* createDocument({ payload = {} }) {
  const requestPayload = {
    url: '/api/v3/gray-zone/gray-zone-document',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    data: adaptCreateDocumentBody(payload),
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });

  // Вывод ошибок валидации
  if (_.get(error, 'response.status') === 400) {
    const messages = _.get(error, 'response.data', []).map(
      ({ message }) => message,
    );
    yield call(Toast.showError, { content: messages.join('. ') });
  }

  if (success) {
    yield call(Toast.showInfo, {
      content: i18next.t('Документ на формирование выгрузки создан'),
      position: toast.POSITION.TOP_CENTER,
    });
    yield put(push('/gray-area'));
  }

  yield put(grayAreaActions.createDocumentDone());
}

function* signDocument({ id, certData }) {
  const requestPayload = {
    url: `/api/v3/gray-zone/gray-zone-document/${id}/signing-document`,
    method: 'get',
  };
  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  if (success) {
    const jsonData = yield call(JSON.stringify, success.data);
    const base64json = yield call(Base64.encode, jsonData);

    const signature = yield call(Crypto.sign, base64json, certData, true);
    return { signature, base64json };
  }
  //TODO: handle errors here
  if (error) {
    const message = _.get(error, 'response.data.error_message', '');
    const content = `${i18next.t('Ошибка подписания')} ${
      message ? `: ${message}` : ''
    }`;
    yield call(Toast.showError, { content });
  }
}

function* signAndSendDocument({ payload = {} }) {
  const { id, onEndCb, certData, calledFrom, initialParams = {} } = payload;

  const { signature, base64json } = yield call(signDocument, { id, certData });

  const requestPayload = {
    url: `/api/v3/gray-zone/gray-zone-document/send`,
    method: 'post',
    data: {
      documentId: id,
      signature,
      content: base64json,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    preloading: false,
  });
  //TODO: uncomment this lines when backend will be ready

  if (error) {
    const messages = _.get(error, 'response.data', []).map(
      ({ message }) => message,
    );
    const content = `${i18next.t('Ошибка отправки подписанного документа')} ${
      messages ? `: ${messages}` : ''
    }`;
    yield call(Toast.showError, { content });
  }

  if (success) {
    // these actions need to update data after document is already signed
    // check from action was called and update data by this value
    // if action was fired from table - request table data again
    // if action was fired from document - put new document info from response after sign document
    if (calledFrom === 'table') {
      const { page, filters } = initialParams;
      yield put(grayAreaActions.requestDocumentsList({ page, filters }));
    } else {
      yield put(grayAreaDocumentActions.loaded(success.data));
    }
  }

  yield call(onEndCb);
}

export default function* watch() {
  yield takeLatest(grayAreaActions.requestDocumentsList, requestDocumentsList);
  yield takeLatest(grayAreaActions.createDocument, createDocument);
  yield takeLatest(grayAreaActions.signAndSend, signAndSendDocument);
  yield takeEvery(grayAreaActions.downloadReport, onDownloadReport);
}
