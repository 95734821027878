import { packTypes } from 'src/constants/packTypes';
import { documentTypeNames } from 'src/constants/documentTypes';

export const getPackType = packageType => {
  const types = [packTypes.pack, packTypes.block];
  return types.includes(packTypes[packageType]);
};

export const getDocTypeName = documentType => documentTypeNames[documentType];

export const middleDottedString = str => {
  const res =
    typeof str === 'string' && str.length > 9
      ? `${str.slice(0, 3)}***${str.slice(-3)}`
      : str;

  return res;
};
