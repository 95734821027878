export const getElementInfo = ({ elementId }) => {
  const toolTipWindowElement = document.getElementById(elementId);

  if (toolTipWindowElement) {
    const toolTipRect = toolTipWindowElement.getBoundingClientRect();
    return {
      toolTipRect,
      toolTipHalfWidth: (toolTipRect.right - toolTipRect.left) / 2,
      toolTipHeight: toolTipRect.bottom - toolTipRect.top,
      isVisible: true,
    };
  }
  return {
    toolTipRect: 0,
    toolTipHalfWidth: 0,
    isVisible: false,
  };
};

export const getArrowOffset = ({ toolTipHalfWidth, centerOfCellX, rowWidth }) => {
  let arrowOffset = toolTipHalfWidth;

  if (centerOfCellX < toolTipHalfWidth) {
    arrowOffset = centerOfCellX;
    if (arrowOffset < 20) { arrowOffset = 20; }
  }

  if (centerOfCellX + toolTipHalfWidth > rowWidth) {
    arrowOffset = 2 * toolTipHalfWidth - (rowWidth - centerOfCellX);
    if (arrowOffset > 2 * toolTipHalfWidth - 20) { arrowOffset = 2 * toolTipHalfWidth - 20; }
  }

  return arrowOffset;
};

export const getToolTipArrowDirection = ({
  height,
  rowHeight,
  heightToBack,
}) => (
  rowHeight + height > heightToBack ? 'down' : 'up'
);

export const getToolTipPositionX = ({
  toolTipHalfWidth,
  centerOfCellX,
  rowWidth,
}) => {
  if (centerOfCellX < toolTipHalfWidth) {
    return {
      left: '0px',
    };
  }

  if ((centerOfCellX + toolTipHalfWidth) > rowWidth) {
    return {
      right: '0px',
    };
  }

  return {
    left: `${centerOfCellX - toolTipHalfWidth}px`,
  };
};

export const getToolTipPositionY = ({
  offsetY,
  height,
  rowHeight,
  heightToBack,
}) => ({
  top: rowHeight + height > heightToBack
    ? `${offsetY - height - 13}px`
    : `${offsetY + rowHeight + 13}px`,
});

export const getOverflow = elem => (
  [...elem.children].reduce((isOverflow, childElement) => {
    const elementOverflow = childElement.children && childElement.children.length !== 0
      ? getOverflow(childElement)
      : (childElement.scrollWidth > childElement.clientWidth + 1);
    return isOverflow ? true : elementOverflow;
  }, false)
);
