import ButtonTabs from '../../common_components/ButtonTabs/ButtonTabs';
import { Translate } from '../../common_components/Translate/Translate';
import { Button } from '@crpt-ui/core';
import * as Icons from '@crpt-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { countryCodeFlags } from '../../constants/countryCodeFlags';


const ActionsRow = ({ resetFilters, isActionsDisabled }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  }}>
    {
      countryCodeFlags.isUZ ? (
        <ButtonTabs
          tabs={[
            {
              id: 0,
              title: Translate('Зарегистрированные участники'),
              href: '/participants/list',
              blockCounty: '',
            },
            {
              id: 1,
              title: Translate('Неавторизованные участники - нарушители регистрации'),
              href: '/participants/evaders/list',
              blockCounty: '',
            },
          ]}
        />
      ) : <div />
    }
    <Button
      onClick={resetFilters}
      disabled={isActionsDisabled}
      size="sm"
      variant="text"
      icon={<Icons.Remove/>}
      style={{ color: '#55B5E7' }}
    >
      {Translate('Сбросить фильтры')}
    </Button>
  </div>
);

ActionsRow.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  isActionsDisabled: PropTypes.bool.isRequired,
};

export default ActionsRow;
