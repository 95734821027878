import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import lodash from 'lodash';
import Modal from 'react-modal';

import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { FormLine, Form } from '@crpt/react-form';
import { SingleSelect } from '@crpt/react-select';
import { Button } from '@crpt/react-button';
import { Input } from '@crpt/react-input';

import { getPackageNameByPackageType } from './utils';
import { packTypes } from '../../constants';
import { buttonSelectedTabItemThemeKZ } from '../../theme/kz-tobaco/button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from '../../common_components/Translate/Translate';

const packageTypes = Object.keys(packTypes).map((key, i) => {
  return {
    id: i,
    title: packTypes[key],
    packageType: key,
  };
});

Modal.setAppElement('body');

moment.locale('ru');

const propTypes = {
  countryCode: PropTypes.string.isRequired,
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  overflow: 'visible',
};

const ModalClose = styled.div`
  cursor: pointer;
`;
const CardHeading = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #212c42;
  margin-bottom: 40px;
`;

const CardHeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CISInformationWrap = styled.div`
  display: flex;
`;

const QRCodeWrap = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
`;

const Image = styled.img`
  width: 128px;
  min-width: 128px;
  height: 128px;
  display: block;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
`;
//
// const CisInfoHeadingLabel = styled.div`
//   font-size: 16px;
//   font-weight: 600;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: 0.2px;
//   color: #212c42;
//   margin-bottom: 16px;
// `;
//
// const QRCanvas = styled.canvas``;

const errorTheme = {
  Button: {
    fontSize: '14px',
    fontWeight: 400,
    large: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '48px',
    },
    normal: {
      paddingLeft: '16px',
      paddingRight: '16px',
      height: '32px',
    },
    main: {
      border: '1px solid #bf2827',
      color: '#bf2827',
      background: 'rgba(0,0,0,0)',
    },
    disabled: {
      border: '1px solid #ABADB6',
      color: '#ABADB6',
      background: 'rgba(0,0,0,0)',
    },
    minWidth: '108px',
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },
};

const ModalHeading = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
`;

const ModalContent = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  max-width: 460px;
`;

class Edit extends Component {
  render() {
    const selectedProduct = this.props.selectedProduct;
    const id = this.props.id;
    console.log('selectedProduct', selectedProduct);

    const brand = lodash.get(selectedProduct, 'brand', false);
    const name = lodash.get(selectedProduct, 'name', false);
    const shortName = lodash.get(selectedProduct, 'shortName', false);
    const gtin = lodash.get(selectedProduct, 'gtin', false);
    const innerUnitCount = lodash.get(selectedProduct, 'innerUnitCount', false);
    const pack = lodash.get(selectedProduct, 'packageType', false);
    const mrp = lodash.get(selectedProduct, 'mrp', false);
    const image = lodash.get(selectedProduct, 'image', false);
    const feacn = lodash.get(selectedProduct, 'feacn', false);
    const okpd2 = lodash.get(selectedProduct, 'okpd2', false);
    const packName = getPackageNameByPackageType(pack);
    const selectedPack = packageTypes.findIndex(v => v.packageType === pack);

    return (
      <div>
        <Card>
          <CardHeadingWrap>
            <CardHeading>{Translate('Редактирование')}</CardHeading>
          </CardHeadingWrap>
          <CISInformationWrap>
            {image ? <Image src={image} /> : <QRCodeWrap />}
            <Container fillWidth>
              <Column>
                <Form
                  onSubmit={vals =>
                    this.props.actions.ProductDetails.saveProductInfo(
                      vals,
                      selectedProduct,
                      this.props.history,
                    )
                  }
                >
                  <FormLine>
                    <Input
                      name={'brand'}
                      placeholder={'Марка'}
                      defaultValue={brand || ''}
                    />
                  </FormLine>

                  <FormLine>
                    <Input
                      name={'name'}
                      placeholder={Translate('Наименование')}
                      defaultValue={name || ''}
                    />
                  </FormLine>

                  <FormLine>
                    <Input
                      name={'gtin'}
                      placeholder={'GTIN'}
                      defaultValue={gtin || ''}
                    />
                  </FormLine>

                  <FormLine>
                    <SingleSelect
                      width={'100%'}
                      name={'packageType'}
                      placeholder={Translate('Упаковка')}
                      values={packageTypes}
                      selectedId={
                        selectedPack !== -1 ? selectedPack : undefined
                      }
                    />
                  </FormLine>

                  <FormLine>
                    <Input
                      name={'innerUnitCount'}
                      placeholder={Translate('В упаковке')}
                      defaultValue={innerUnitCount + '' || ''}
                    />
                  </FormLine>

                  <FormLine>
                    <Button
                      marginRight={20}
                      type={'submit'}
                      theme={{ Button: buttonSelectedTabItemThemeKZ }}
                    >
                      {Translate('Сохранить')}
                    </Button>
                    <Button
                      onClick={e => this.props.history.push(this.props.path)}
                    >
                      {Translate('Отменить')}
                    </Button>
                  </FormLine>
                </Form>
              </Column>
              <Column />
            </Container>
          </CISInformationWrap>
        </Card>
      </div>
    );
  }
}

Edit.propTypes = propTypes;

const mapStateToProps = state => ({
  countryCode: state.config.countryCode,
});
export default compose(connect(mapStateToProps), withRouter)(Edit);
