import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { formMutators } from './utils/mutators';
import { deepOmit } from './utils';
import { RegistrationSteps } from './constants';


class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    initialValues: PropTypes.shape({}),
    isCompleted: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    initialValues: {},
  };

  constructor(props) {
    super(props);

    const { initialValues } = this.props;
    this.state = {
      step: RegistrationSteps.CERTIFICATE,
      values: initialValues || {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isCompleted && state.step !== RegistrationSteps.COMPLETE) {
      return { step: RegistrationSteps.COMPLETE };
    }
    return null;
  }

  next = (values) => {
    const { children } = this.props;

    this.setState(state => ({
      step: Math.min(state.step + 1, children.length),
      values: { ...state.values, ...values },
    }));
  };

  previous = () => this.setState(state => ({
    step: Math.max(state.step - 1, 0),
  }));

  setStep = step => this.setState({ step });

  onSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(deepOmit(values, 'key_id'), this.setStep);
  };

  render() {
    const { children } = this.props;
    const { step, values: initialValues } = this.state;

    return (
      <Form
        initialValues={initialValues}
        mutators={formMutators}
        onSubmit={this.onSubmit}
      >
        {({ handleSubmit, values, errors, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              {React.Children.map(children, c => React.cloneElement(
                c,
                { step, onNext: this.next, values, errors, mutators: form.mutators }
              ))}
            </form>
          )
        }}
      </Form>
    );
  }
}

export default Wizard;
