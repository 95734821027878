export const matchPropFromUrl = (map) => {
  for (let prop in map) {
    const propIsMatch = map[prop].find(item => {
      const reg = new RegExp(`^${item}`, 'g');
      return reg.test(window.location.hostname);
    });

    if (propIsMatch) {
      return prop;
    }
  }
};
