import React, { Component } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { WrapImg, WrapPreloader, Text } from './styled';
import { animationData } from './constants';
import { Translate } from '../../common_components/Translate/Translate';

class Preloader extends Component {
  static PropTypes = {
    isOpen: PropTypes.bool.isRequired,
    allwaysShow: PropTypes.bool,
    text: PropTypes.string,
  };

  componentDidMount() {
    const params = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    };
    lottie.loadAnimation(params);
  }

  render() {
    const { isOpen, text, allwaysShow } = this.props;
    return (
      <WrapPreloader isOpen={allwaysShow || isOpen}>
        <WrapImg id="lottie" />
        <Text>{text || Translate('Загрузка списка')}</Text>
      </WrapPreloader>
    );
  }
}

export default connect(state => ({
  isOpen: state.Preloader.isOpen,
}))(Preloader);
