import { TwoLineLabel } from '@crpt/react-label';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './headedLabel.styles';

const propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const HeadedLabel = ({ header, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.inlineWrapper}>
      <TwoLineLabel heading={header}>{value || '-'}</TwoLineLabel>
    </div>
  );
};

HeadedLabel.propTypes = propTypes;

export default HeadedLabel;
