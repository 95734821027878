import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../common_components/Form/Form';
import FormContextWrapper from '../../../../common_components/Form/FormContextWrapper';
import Preloader from '../../../../common_components/Preloader/Preloader';
import Preview from '../Document/Preview/Preview';
import Breadcrumbs from '../../../../common_components/Breadcrumbs/Breadcrumbs';
import Information from './Information/Information';
import Goods from './Goods/Goods';
import Cis from './Cis/Cis';
import { steps } from './EaesImportDocument.constants';
import Total from './Total/Total';
import { validateDateInGoodsFields } from '../ImportDocument/ImportDocument.validations';

const EaesImportDocumentView = props => {
  const [goodsChanged, handleGoodsChange] = useState(false);
  const { data, step, onSetStep, onSaveDraft, countryCode, typeDoc, isDraft } = props;
  const { breadcrumbs, onSubmit, loaded, isCreate, isLoading } = props;
  const { isCorrect } = props;

  return (
    <React.Fragment>
      <Preview onSubmit={onSubmit} />

      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <Form validate={validateDateInGoodsFields} data={data} onSubmit={onSubmit} disabled={!isCreate && !loaded}>
        {isLoading && <Preloader />}
        {!isLoading && (
          <FormContextWrapper>
            <Information
              loaded={loaded}
              onSetStep={onSetStep}
              onSaveDraft={onSaveDraft}
              show={step === steps.info}
              typeDoc={typeDoc}
              isDraft={isDraft}
              isCorrect={isCorrect}
            />
            <Goods
              loaded={loaded}
              goodsChanged={goodsChanged}
              onSetStep={onSetStep}
              onSaveDraft={onSaveDraft}
              show={step === steps.goods}
              typeDoc={typeDoc}
              handleGoodsChange={handleGoodsChange}
              isDraft={isDraft}
              isCorrect={isCorrect}
            />
            {countryCode === 'KZ' ? (
              <Total
                loaded={loaded}
                goodsChanged={goodsChanged}
                isDraft={isDraft}
                isCorrect={isCorrect}
                onSetStep={onSetStep}
                onSaveDraft={onSaveDraft}
                show={step === steps.cis}
              />
            ) : (
              <Cis
                loaded={loaded}
                onSetStep={onSetStep}
                onSaveDraft={onSaveDraft}
                show={step === steps.cis}
                typeDoc={typeDoc}
              />
            )}
          </FormContextWrapper>
        )}
      </Form>
    </React.Fragment>
  );
};

EaesImportDocumentView.propTypes = {
  data: PropTypes.shape({}).isRequired,
  loaded: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(Object.values(steps)).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onSetStep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDraft: PropTypes.bool,
  countryCode: PropTypes.string,
  forCountry: PropTypes.string,
};

export default EaesImportDocumentView;
