import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import List from 'src/common_components/List/List';
import { columnsKZ } from 'src/motp_components/_Requests/RequestList/ImportThirdCountry/Goods/Goods.constants';
import { get } from 'lodash';
import { WrapImportTransit } from '../DocumentDetails.styled';
import {Translate} from "../../../common_components/Translate/Translate";


const columns = columnsKZ.map(col=> col.id === 'gtin'
  ? ({
    Header: Translate('Товар'),
    id: 'gtin',
    accessor: d => (
      <div>
        {d.gtin || d.productName ? (
          <Fragment>
            <div>{d.gtin}</div>
            <div>{d.productName}</div>
          </Fragment>
         ) : '—'
        }
      </div>
    ),
    width: 211,
  })
  : col);

const ImportCodes = props => {
  const { selectedDocument } = props;
  let cises = get(
    selectedDocument,
    'disaggregationCodes.firstCises',
    get(selectedDocument, 'originalCises', []),
  );

  return (
    <WrapImportTransit>
      <List
        columns={columns}
        data={cises}
        defaultPageSize={10}
        showPagination={cises.length > 10}
        keyField="cis"
      />
    </WrapImportTransit>
  );
};

ImportCodes.propTypes = {
  selectedDocument: PropTypes.shape({}).isRequired,
};

export default ImportCodes;
