import styled from 'styled-components';


export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-top: 1px solid rgba(196, 196, 196, 0.25);
  margin-left: ${props => '-'+props.cardTheme.paddingLeft};
  margin-right: ${props => '-'+props.cardTheme.paddingRight};
`;
