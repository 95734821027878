import { actionTypes } from '../../constants';

const newItemState = {
  id: 0,
  gtin: '',
  tnved: '',
  serialMethod: null,
  serial: '',
  count: '',
  manufactureMethod: null,
  markOrganization: null,
  contract: null,
  obtainMethod: null,
  ci: null,
  mark: null,
  productManufacture: null,
};

const initialState = [newItemState];

let itemId = 0;

export function items(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ORDER_FORM.ADD_ITEM:
      return state.concat({ ...newItemState, id: ++itemId });
    case actionTypes.ORDER_FORM.ITEM_FIELD_CHANGED: {
      const item = { ...state[action.id], [action.key]: action.value };
      return state.filter(i => i.id !== action.id).concat(item);
    }

    case actionTypes.ORDER_FORM.CLEAR:
      return initialState;
    default:
      return state;
  }
}
