import {actionTypes} from '../../../constants/';

export function filterParams(state = {}, action = {}) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS.FILTER_APPLY_CLICKED:
    case actionTypes.PRODUCT_DETAILS.CIS_LIST_MOUNTED:
      return action.value;
    default:
      return state;
  }
}
