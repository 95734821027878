import { instance } from './utils';

const readFile = files => new Promise((resolve, reject) => {
  if (!files || !files.length) {
    resolve(null);
  } else {
    const fileReader = new FileReader();
    const { name } = files[0];

    let type = 'XLS';
    if (name.endsWith('.xml')) type = 'XML';
    else if (name.endsWith('.csv')) type = 'CSV';
    else if (name.endsWith('.7z')) type = 'SEVEN_ZIP';

    fileReader.onload = () => resolve({
      document: fileReader.result.split(',').pop(),
      document_format: type,
    });
    fileReader.onerror = reject;
    fileReader.readAsDataURL(files[0]);
  }
});

export class TNVEDService {
  static getList(params) {
    return instance.request({
      url: '/api/v3/facade/tnved/search',
      method: 'get',
      params: {
        ...params,
        offset: params.offset / 10 || 0,
        page: params.offset / 10 || 0,
        prefix: '6401,6402,6403,6404,6405',
      },
    });
  }

  static load(files) {
    return readFile(files).then(data => instance.request({
      url: '/api/v3/facade/tnved/load',
      method: 'post',
      data,
    }));
  }

  static searchByInput(query, type, params = {}) {
    return instance.request({
      url: '/api/v3/facade/tnved/suggestions',
      method: 'get',
      params: {
        query,
        type,
        prefix: '6401,6402,6403,6404,6405',
        ...params,
      },
    });
  }

  static getTnvedAddUnits() {
    return instance.request({
      url: `/api/v1/enum/units_add`,
      method: 'GET',
    });
  }
}
