import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDatepicker, START_DATE } from '@crpt-ui/core/lib/DatePicker/hooks';
import DatePickerContext from './DatePicker.context';
import Month from './components/Month';
import Controls from './components/Controls';
import Header from './components/Header';
import Footer from './components/Footer';
import { dateEquality } from '@crpt-ui/core/lib/DatePicker/utils/dateEquality';
import { isDate } from 'lodash';


const isToday = (date) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  return dateEquality(today, date);
};

const Datepicker = ({
  onSubmit,
  onClose,
  minDate,
  maxDate,
  date,
  isFilter,
  onDateChange,
  startYear,
  endYear,
}) => {
  const [state, setState] = useState({
    date: date ? date : null,
    focusedInput: START_DATE,
  });
  const handleTodayClick = newDate => {
    setState({
      ...state,
      date: newDate,
    });

    if (typeof onDateChange === 'function') {
      onDateChange(newDate);
    }
  };

  const handleDateChange = (data) => {
    setState({
      ...state,
      date: data.startDate,
    });

    if (typeof onDateChange === 'function') {
      onDateChange(data.startDate);
    }
  };

  const isTodayDisabled = useMemo(() => {
    return (isDate(minDate) ? minDate > new Date() : false)
      || (isDate(maxDate) ? maxDate < new Date() : false)
  }, []);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isSingleDateSelected,
    isDateHovered,
    isFirstSelectedDate,
    isLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    onResetDates,
    goToMonth,
  } = useDatepicker({
    startDate: state.date,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    minBookingDate: minDate,
    maxBookingDate: maxDate,
    numberOfMonths: 1,
  });

  const memoizedGoToMonth = useCallback(newDate => goToMonth(newDate), []);
  const memoizedOnSubmit = useCallback(result => onSubmit(result), []);
  const memoizedOnResetDates = useCallback(() => onResetDates(), []);

  const memoizedIsSingleDateSelected = useCallback(selectedDate => {
    if (!state.date) {
      return false;
    }
    return isSingleDateSelected(state.date, selectedDate);
  }, [state.date]);

  return (
    <div>
      <DatePickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isSingleDaySelected: memoizedIsSingleDateSelected,
          isToday,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstSelectedDate,
          isLastSelectedDate,
          onDateSelect,
          onDateFocus,
          onDateHover,
          type: 'single',
          currentDate: state.date,
        }}
      >
        <div style={{ width: 320 }}>
          <Header
            startDate={state.date}
            onClose={onClose}
            type={'single'}
            isFilter={isFilter}
          />

          <Controls
            goToMonth={memoizedGoToMonth}
            year={activeMonths[0].year}
            month={activeMonths[0].month}
            startYear={startYear}
            endYear={endYear}         
          />

          <div>
            {activeMonths.map(month => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
              />
            ))}
          </div>
          <Footer
            activeMonths={activeMonths}
            goToMonth={memoizedGoToMonth}
            onResetDates={memoizedOnResetDates}
            onSubmit={memoizedOnSubmit}
            result={state.date}
            type={'single'}
            isTodayDay={isToday(state.date)}
            isTodayDisabled={isTodayDisabled}
            onTodayDateSelect={handleTodayClick}
            isFilter={isFilter}
          />
        </div>
      </DatePickerContext.Provider>
    </div>
  );
};

Datepicker.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  startYear: PropTypes.number,
  endYear: PropTypes.number,
  /** Indicate that Datepicker use in table filter area
   * Hide close button in Header. Hide control buttons in Footer */
  isFilter: PropTypes.bool,
  onDateChange: PropTypes.func,
};

Datepicker.defaultProps = {
  onSubmit: () => { },
  onClose: () => { },
  isFilter: false,
};

export default Datepicker;
