import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isEmpty, isEqual, uniqBy, get } from 'lodash';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { Plus } from '@crpt-ui/icons';
import {
  cisKey,
  invalidCisKey,
  contentBodyConfirm,
  goodsKey,
  steps,
  tabs,
} from '../ImportThirdCountry.constants';
import {
  PageWrapper,
  Divider,
  styleCommandBar,
  FixedWrap,
  GoodsWrap,
  GoodsIconWrap,
  EmptyGoodsTextWrap,
  StepWrapper,
  Wrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import {
  columnsKZ,
  columnsKG,
  ContentErrorLoading,
  actionSelectOptionsForDelete,
} from './Goods.constants';
import GoodsCommandBar from './CommandBar';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import GoodCodesUploadModalContent from './GoodCodesUploadModalContent';
import GoodsIcon from './svg/GoodsIcon.svg';
import { Select as SelectRow } from '@crpt-ui/core';
import { connect } from 'react-redux';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import { withRouter } from 'react-router-dom';
import MenuButton from 'src/common_components/Buttons/MenuButton';
import ScanDialog from 'src/common_components/ScanDialog/ScanDialog';
import * as _ from 'lodash';
import { getGroupedProducts } from '../ImportThirdCountry.utils';
import HandAddGoodModalContent from '../../Document/HandAddGoodModalContent/HandAddGoodModalContent';
import { Translate } from 'src/common_components/Translate/Translate';

import * as actions from './ducks/Goods.actions';
import * as selectors from './ducks/Goods.selectors';

class Goods extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onDisaggregation: PropTypes.func.isRequired,
    savedProducts: PropTypes.array.isRequired,
    onFixProducts: PropTypes.func.isRequired,
    onCloseNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.instance = React.createRef();

    this.state = {
      selectedRows: [],
      isModalOpen: false,
      isOpenScanDialog: false,
      page: 0,
      commandBarLeft: [
        <CommonActionButton onClick={this.toggleDialog}>
          {Translate('Отменить')}
        </CommonActionButton>,
        <CommonActionButton onClick={this.onSaveDraft}>
          {Translate('Сохранить как черновик')}
        </CommonActionButton>,
      ],

      commandBarRight: [
        <PrimaryActionLargeButton
          disabled={this.isNextStepDisabled}
          onClick={this.onNextStep}
        >
          {Translate('Следующий шаг')}
        </PrimaryActionLargeButton>,
      ],

      topBarLeft: [
        <MenuButton
          menu={[
            {
              title: Translate('Загрузить из файла'),
              onClick: this.onAddGood,
            },
            {
              title: Translate('Выбрать из списка'),
              onClick: this.onHandAddGood,
            },
            {
              title: Translate('Сканировать коды'),
              onClick: () => this.toggleScanDialog(),
            },
          ]}
          positionY="bottom"
          variant="contained"
          color="primary"
        >
          <Plus />
          <sapn style={{ fontSize: '12px', fontWeight: '700', margin: '8px' }}>
            {Translate('ДОБАВИТЬ ТОВАР')}
          </sapn>
        </MenuButton>,
      ],

      topBarRight: [],
    };
  }

  setPage = page => {
    this.setState({ page });
  };

  onHandAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
      formValues,
    } = this.props;

    onSetContent(
      <HandAddGoodModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        formValues={formValues}
        target="thirdCountry"
      />,
    );
    onOpenModal();
  };

  getTopBarLeft = () => {
    const { countryCode } = this.props;
    const { topBarLeft } = this.state;
    const topBar = [...topBarLeft];
    if (countryCode === 'KZ' || countryCode === 'UZ') {
      topBar.push(
        <FixedWrap width={'260px'} stretch>
          <SelectRow
            options={actionSelectOptionsForDelete}
            label={Translate('Действие')}
            onChange={e => {
              const selectedRows = [...this.state.selectedRows];
              this.deleteSelectedRows(selectedRows);
              this.goodsList.clearSelected();
              if (this.goodsList.state.isAllSelected)
                this.goodsList.toggleAll();
            }}
            disabled={this.state.selectedRows.length < 1}
          />
        </FixedWrap>,
      );
    }
    return topBar;
  };

  getTopBarRight = () => {
    return [];
  };

  onConvert = () => {
    // this.props.onFixProducts();
  };

  onAddGood = () => {
    const {
      onOpenModal,
      onSetContent,
      onCloseModal,
      formMutators,
    } = this.props;

    onSetContent(
      <GoodCodesUploadModalContent
        onCloseModal={onCloseModal}
        formMutators={formMutators}
        onConvert={this.onConvert}
        types=".xls, .xlsx"
        onAccept={file => console.log('onAccept called!', file)}
      />,
    );
    onOpenModal();
  };

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.cis);
  };

  isNextStepDisabled = () => {
    const { formValues } = this.props;
    const codes = _.get(formValues, cisKey, []);
    return codes.length === 0;
  };

  getProducts = () => {
    const {
      formValues,
      formMutators,
      onDisaggregation,
      onFixProducts,
      savedProducts,
    } = this.props;
    const products = formValues[cisKey] || [];
    const productsCis = products.map(item => item.cis);
    if (!isEqual(savedProducts, productsCis) && productsCis.length > 0) {
      onFixProducts(productsCis);
      onDisaggregation({ formValues, formMutators, productsCis, productsGoods: formValues[goodsKey] || [] });
    } else if (productsCis.length === 0 && savedProducts.length > 0) {
      formMutators.updateField(goodsKey, []);
      onFixProducts();
    }

    return products;
  };

  onRowSelect = selected => {
    const selectedKeys = Object.keys(selected);
    this.setState({
      selectedRows: selectedKeys,
    });
  };

  tabsCurrent = () => {
    const { show, countryCode } = this.props;

    if (!show) return tabs;
    const { onSetStep } = this.props;
    const isNextStepDisabled = this.isNextStepDisabled();
    return tabs
      .map(tab =>
        tab.id === steps.goods
          ? tab
          : {
              ...tab,
              onClick: () => {
                onSetStep(tab.id);
              },
            },
      )
      .map(tab =>
        tab.id <= steps.goods || (tab.id === steps.cis && !isNextStepDisabled)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  toggleScanDialog = () =>
    this.setState(({ isOpenScanDialog }) => ({
      isOpenScanDialog: !isOpenScanDialog,
    }));

  onListRef = ref => {
    this.goodsList = ref;
  };

  onUpdate = () => {
    this.setState({ selectedRows: this.goodsList.state.selectedRows });
  };

  onCancel = () => {
    const { history, formValues, onCloseNotification } = this.props;
    onCloseNotification(history, formValues);
  };

  addScannedCises = scanned => {
    const { formValues } = this.props;
    const oldProducts = get(formValues, cisKey, []);
    this.updateProducts([...oldProducts, ...scanned]);
  };

  deleteSelectedRows = deletedCodesIds => {
    const allCodes = this.getProducts();
    const filtered = allCodes.filter(
      item => !deletedCodesIds.includes(item.cis),
    );
    this.setState({ selectedRows: filtered })
    this.updateProducts(filtered);
  };

  updateProducts = data => {
    const { formMutators } = this.props;
    const uniqByData = uniqBy(data, 'cis');
    const grouped = getGroupedProducts(uniqByData);
    formMutators.updateField(cisKey, uniqByData);
    // formMutators.updateField(goodsKey, grouped);   --- продукты формируются в саге после дезагрегации
  };

  render() {
    const {
      commandBarLeft,
      commandBarRight,
      isModalOpen,
      isOpenScanDialog,
      page,
    } = this.state;
    const { show, countryCode, formValues, formMutators } = this.props;

    const topBarLeft = this.getTopBarLeft();
    const topBarRight = this.getTopBarRight();

    const products = this.getProducts();
    const columns =
      countryCode === 'KZ' || countryCode === 'UZ' ? columnsKZ : columnsKG;

    let isInvalidCisShow = !isEmpty(formValues.invalidCis);
    const invalidList = formValues.invalidCis
      ? formValues.invalidCis.map(item => <div>Код: {item}</div>)
      : '';
    let contentErrorLoading = (
      <ContentErrorLoading>
        {invalidList}
        {Translate('Загрузить другой файл?')}
      </ContentErrorLoading>
    );

    return (
      <PageWrapper hide={!show}>
        <ScanDialog
          open={isOpenScanDialog}
          onClose={this.toggleScanDialog}
          onAdd={this.addScannedCises}
          docType="IMPORT"
        />
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.goods}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>
          <ConfirmModal
            isModalOpen={isModalOpen}
            onClose={this.toggleDialog}
            onSubmit={this.onCancel}
            content={contentBodyConfirm}
            header={Translate('Предупреждение')}
            cancelButtonText={Translate('Нет')}
            submitButtonText={Translate('Да')}
            notCancelable
          />
          <ConfirmModal
            isModalOpen={isInvalidCisShow}
            onClose={() => formMutators.updateField(invalidCisKey, [])}
            onSubmit={() => {
              this.onAddGood();
              formMutators.updateField(invalidCisKey, []);
            }}
            content={contentErrorLoading}
            header={Translate('Ошибка')}
            cancelButtonText={Translate('Отмена')}
            submitButtonText={Translate('Загрузить файл')}
            color={'error'}
          />
          <GoodsCommandBar left={topBarLeft} right={topBarRight} />

          <Wrapper margin="0">
            {isEmpty(products) ? (
              <React.Fragment>
                <Divider />
                <GoodsWrap>
                  <GoodsIconWrap>
                    <GoodsIcon />
                  </GoodsIconWrap>

                  <EmptyGoodsTextWrap>
                    {Translate('Нет добавленных товаров')}
                  </EmptyGoodsTextWrap>
                </GoodsWrap>
              </React.Fragment>
            ) : (
              <SelectionList
                defaultPageSize={10}
                columns={columns}
                data={products}
                onRef={this.onListRef}
                onUpdate={this.onUpdate}
                showPagination={products.length > 10}
                onPageChange={pageIdx => this.setPage(pageIdx)}
                keyField="cis"
                page={page}
                customSelectAll
              />
            )}
            <GoodsCommandBar
              left={commandBarLeft}
              style={{
                ...styleCommandBar,
                width: isEmpty(commandBarLeft) ? 'auto' : '100%',
              }}
              right={commandBarRight}
            />
          </Wrapper>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  countryCode: state.config.countryCode,
  savedProducts: selectors.savedProducts(state),
});

const mapDispatch = dispatch => ({
  onDisaggregation: params => dispatch(actions.disaggregation(params)),
  onFixProducts: cises => dispatch(actions.fixProducts(cises)),
});

export default compose(
  connect(mapState, mapDispatch),
  withModal,
  withRouter,
)(Goods);
