import React, { useEffect } from 'react';
import DocumentWrapper from '../DocumentWrapper/DocumentWrapper';
import { Translate } from '../../../../common_components/Translate/Translate';
import InfoFields from './InfoFields';
import * as selectors from './ducks/CorInfoCm.selectors';
import * as actions from './ducks/CorInfoCm.actions';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
import { connect } from 'react-redux';
import { WrapperForTable } from './constants';
import * as actionsDraft from '../Draft/ducks/Draft.actions';
import AddCodesBar from './AddCodesBar';
import * as cadesActions from '../../../../common_components/Eimzo/Eimzo.actions';
import * as preloaderSelectors from 'src/common_components/Preloader/ducks/Preloader.selectors';

const CorInfoCm = props => {
  const { match, countryCode, onSaveDraft, isLoading } = props;
  const { inn, onLoadDraft, data, onSubmit, onMountCades } = props;
  const { id } = match.params;

  const changeTabsFunc = tab =>
    tab.id === 1 ? { ...tab, label: Translate('Шаг 2. Коды') } : tab;

  useEffect(() => {
    onMountCades();
  }, [onMountCades]);

  useEffect(() => {
    if (id !== 'create') onLoadDraft(id.replace(/draft-/, ''));
  }, [id, onLoadDraft]);

  const handleOnSubmit = values => {
    onSubmit({
      values,
      idDraft: values.idDraft ? values.idDraft : id.replace(/draft-/, ''),
    });
  };

  const errorsCount = ({ formErrors }) =>
    Object.keys(JSON.parse(JSON.stringify(formErrors))).length;

  return (
    <WrapperForTable>
      <DocumentWrapper
        changeTabsFunc={changeTabsFunc}
        finalConfirmModal
        wrapperForTableInFinalConfirmModal={WrapperForTable}
        emptyItemText={'Нет добавленных кодов'}
        needViewActionsOfGoods={false}
        additionalBottomBar={AddCodesBar}
        rightDraftButton={false}
        loaded={true}
        match={match}
        productGroupIdForGoods={2}
        isCreate={id === 'create'}
        actionSelectOptions={[]}
        breadcrumbs={[
          {
            href: '/documents/list',
            title: Translate('Документы'),
            id: 1,
          },
          {
            title: Translate('Корректировка сведений о кодах маркировки'),
            id: 2,
            ...(id !== 'create' ? { href: `/documents/list/${id}` } : {}),
          },
        ]}
        countryCode={countryCode}
        isLoading={isLoading}
        onSaveDraft={onSaveDraft}
        data={
          id === 'create'
            ? {
                inn,
              }
            : {
                ...data,
              }
        }
        docType={'COR_INFO_CM'}
        codesCanBeChanged={false}
        fieldsInfo={InfoFields}
        errorsCountFunc={errorsCount}
        needAWarningAboutTheGoodsRecount={false}
        needCancelButton={true}
        needDraftButton={true}
        onSubmit={handleOnSubmit}
        title="Корректировка сведений о кодах маркировки"
        pageSizeInGoods={20}
      />
    </WrapperForTable>
  );
};

const mapState = state => {
  return {
    data: selectors.data(state),
    countryCode: state.config.countryCode,
    inn: authSelectors.userInn(state),
    shortName: authSelectors.userShortName(state),
    isLoading: preloaderSelectors.loading(state),
    user: authSelectors.userInfo(state),
  };
};

const mapDispatch = dispatch => ({
  onLoadDraft: id => dispatch(actionsDraft.loadDraft(id)),
  onSaveDraft: data => dispatch(actionsDraft.createDraft(data)),
  onMountCades: () => {
    dispatch(cadesActions.load());
  },
  onSubmit: data => dispatch(actions.submit(data)),
});

export default connect(mapState, mapDispatch)(CorInfoCm);
