import { Translate } from '../../../common_components/Translate/Translate';
import { Button } from '@crpt/react-button';
import Modal from 'react-modal';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Table from '@crpt/react-table';
import moment from 'moment';

const ModalHeading = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
`;

const ModalContent = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const Plug = styled.div``;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    maxHeight: '50%',
    overflowY: 'auto'
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: '10'
  },
  overflow: 'visible',
};

const columns = [
  {
    Header: Translate('Тариф'),
    id: 'rateType',
    accessor: data => parseInt(data.rateType) === 1 ? Translate("Полный тариф") : Translate("Нулевая тарифная ставка"),
    width: 150,
  },
  {
    Header: Translate('Дата установки тарифа'),
    id: 'rateStartDate',
    accessor: "rateStartDate",
    width: 150,
  },
  {
    Header: Translate('Автор изменений'),
    id: 'rateUpdatedBy',
    accessor: 'rateUpdatedBy',
    width: 200,
  },
  {
    Header: Translate('Комментарий к изменению тарифа'),
    id: 'rateUpdatedText',
    accessor: 'rateUpdatedText',
    width: 300,
  },
];


const TariffRateChangesHistory = (props) => {
  const { onClose, getHistory, clearHistory, history, gtin } = props;

  useEffect(()=> {
    getHistory(gtin);
    return clearHistory;
  },[getHistory])

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={Translate('Просмотр истории изменения тарифной ставки товара')}
    >
      <ModalHeading>{Translate('Просмотр истории изменения тарифной ставки товара')}</ModalHeading>
      <ModalContent>
        <Table
          columns={columns}
          data={history || []}
          minRows={0}
        />
      </ModalContent>
      <ButtonWrap>
        <Plug />
        <Button
          marginRight={20}
          onClick={onClose}
        >
          {Translate('Закрыть')}
        </Button>
      </ButtonWrap>
    </Modal>
  )
}
export default TariffRateChangesHistory;
