import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { HintedAddressInput, ADDRESS_HINT_REQUESTER_FULL } from '@crpt/react-dadata';
import { Wrap } from './styled';
import { withCountedPlaceholder, withErrorProp, withFieldWrapper } from './utils';
import {dadataTheme} from '../../../theme/ru-tabaco/select/dadata';


const emptyQuery = {
  fullAddress: '',
  postalCode: '',
  regionCode: '',
  area: '',
  city: '',
  settlement: '',
  street: '',
  house: '',
  block: '',
};

const Element = ({ dadataSettings, input, ...rest }) => (
  <Wrap onBlur={input && input.onBlur}>
    <HintedAddressInput
      onChange={(input && input.onChange) || rest.onChange}
      savePlaceholder
      apiKey={dadataSettings.apiKey}
      apiURL={dadataSettings.apiAddrURL}
      hideOptionsPanel={!dadataSettings}
      filterDisabled
      defaultText={(input && input.value) || rest.defaultValue}
      type={ADDRESS_HINT_REQUESTER_FULL}
      query={emptyQuery}
      theme={{Select: dadataTheme}}
      {...rest}
    />
  </Wrap>
);

Element.propTypes = {
  dadataSettings: PropTypes.shape({}).isRequired,
};

const enchanced = compose(
  connect(state => ({ dadataSettings: state.App.config.dadataSettings })),
  withErrorProp,
  withCountedPlaceholder,
  onlyUpdateForKeys(['isError', 'dadataSettings'])
)(Element);


export default compose(
  setDisplayName('HintedAddressInput'),
  withFieldWrapper(enchanced),
)(enchanced);
