import { actionTypes } from '../../constants/actionTypes';

const initialState = {
  isOpen: false
};

export default (state = initialState, { type }) => {
  switch (type) {
    case actionTypes.PRELOADER.START:
      return { isOpen: true };
    case actionTypes.PRELOADER.END:
      return { isOpen: false };
    default:
      return state;
  }
};
