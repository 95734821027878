import React from 'react';
import CustomField from "../../../../../common_components/Form/Fields/CustomField";
import clsx from 'classnames';
import './FloatFileld.css'

export const FloatField = ({ format, label, name, value, error, currentFormMutators }) => {
  const focusOnClick = () => {
    const el = document.querySelector(`input[name='${name}']`);
      if (el) el.focus();
  }
  const onBlurInput = () => {
    if (value) {
      let integerPart = value.split('.')[0]
      let floatPart = value.split('.')[1] || ''
      if (floatPart.length === 1) floatPart = floatPart+"0"
      if (!floatPart.length) floatPart = "00"
      currentFormMutators.updateField(name, `${integerPart}.${floatPart}`)
    }
  }

  return (
    <div className={clsx(
      'field-container_fr',
      {'withValue': value},
      {'withError': error}
    )}>
      <CustomField
        name={name}
        component="input"
        format={format}
        label={label}
        className='field-input_fr'
        onBlur={onBlurInput}
      />
      <span className='field-label_fr' onClick={focusOnClick}>{label}</span>
    </div>
  );
};

