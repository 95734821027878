import React, { useState } from 'react';
import { Input as InputCRPT } from '@crpt/react-input';
import styled from 'styled-components';

const InputWrapper = styled.div`
  & input {
    top: 0;
  }
  & [class^='Placeholder'] {
    display: ${({ $isFocused, $value }) =>
      $isFocused || $value ? 'none' : 'block'};
  }
`;

export const InputWithHiddenLabel = ({ onChange, ...props }) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = value => {
    onChange(value);
    setValue(value);
  };

  return (
    <InputWrapper $isFocused={isFocused} $value={value}>
      <InputCRPT
        onChange={handleChange}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        {...props}
      />
    </InputWrapper>
  );
};

InputWithHiddenLabel.displayName = 'Input';
