import PropTypes from 'prop-types';


export const item = PropTypes.shape({
  date: PropTypes.string.isRequired,
  countRealized: PropTypes.number.isRequired,
  countWrittenOff: PropTypes.number.isRequired,
  countCirculated: PropTypes.number.isRequired,
  countReimplementation: PropTypes.number.isRequired,
  countNonMemberSales: PropTypes.number.isRequired,
  countReturns: PropTypes.number.isRequired,
});

export const scenarioType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  descr: PropTypes.string.isRequired,
});

export const statisticType = PropTypes.shape({
  scenarioType: scenarioType.isRequired,
  countNum: PropTypes.number.isRequired,
  countPercent: PropTypes.number.isRequired,
});
