import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Arc, Text } from 'react-konva';

class MultiPercentChart extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      innerRadius: PropTypes.number.isRequired,
      outerRadius: PropTypes.number.isRequired,
      rotationDeg: PropTypes.number.isRequired,
      percentText: PropTypes.shape({
        fontSize: PropTypes.number.isRequired,
        fontFamily: PropTypes.string.isRequired,
        fill: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ).isRequired,
  };

  getArcs = () => {
    const { data, theme } = this.props;

    const total = data.reduce((a, i) => a + i.value, 0);

    let startAngle = theme.rotationDeg;

    return total
      ? data.map(i => {
          const rotationDeg = startAngle;
          const percent = (i.value / total) * 100;
          const angle = percent * 3.6;
          const fill = i.color;

          startAngle += angle;
          if (startAngle > 360) startAngle -= 360;

          return { angle, rotationDeg, fill, percent };
        })
      : [{ angle: 360, rotationDeg: 0, fill: '#000000', percent: 0 }];
  };

  getPercents = arcs => {
    const { theme } = this.props;

    const radius = 0.7 * theme.innerRadius;

    return arcs.map(i => {
      const angle = ((i.rotationDeg + i.angle / 2) * Math.PI) / 180;
      const text = `${Math.round(i.percent * 10) / 10}%`;

      const x = radius * Math.cos(angle) - (text.length * 7) / 2;
      const y = radius * Math.sin(angle) - 4;

      return { text, x, y, fill: i.fill };
    });
  };

  render() {
    const { theme } = this.props;
    const size = theme.outerRadius * 2;
    const center = theme.outerRadius;
    const arcs = this.getArcs();
    const percents = this.getPercents(arcs);

    return (
      <Stage width={size} height={size}>
        <Layer x={center} y={center}>
          {arcs.map(({ percent, ...arc }, i) => (
            <Arc
              key={i}
              innerRadius={theme.innerRadius}
              outerRadius={theme.outerRadius}
              fill="red"
              {...arc}
            />
          ))}
          {percents.map((percent, i) => (
            <Text
              key={i}
              fontSize={theme.percentText.fontSize}
              fontFamily={theme.percentText.fontFamily}
              fontStyle="bold"
              {...percent}
            />
          ))}
        </Layer>
      </Stage>
    );
  }
}

export default MultiPercentChart;
