import { handleActions } from 'redux-actions';
import * as actions from './LoadingModal.actions';

const isOpen = handleActions(
  {
    [actions.show]: () => true,
    [actions.hide]: () => false,
  },
  false
);

export default { isOpen };
