import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import Tabs from '@crpt-ui/core/lib/Tabs/Tabs';
import { Button } from '@crpt-ui/core';
import { Icon } from '@crpt/react-icon';

import Preloader from '../../../common_components/Preloader/Preloader';
import { style } from './Receipt.constants';
import { Translate } from '../../../common_components/Translate/Translate';

const ReceiptView = props => {
  const {
    currentTabIndex,
    tabs,
    breadcrumbs,
    loaded,
    onNavClick,
    onDownloadJSONClick,
    children,
  } = props;

  return (
    <React.Fragment>
      <Button
        onClick={onDownloadJSONClick}
        disabled={!loaded}
        size="sm"
        variant="outlined"
        style={{ float: 'right', color: '#1A99F4' }}
      >
        <Icon type={'download'} spacingRight={7} />
        {Translate('Скачать документ')}
      </Button>

      <Breadcrumbs
        withCustomLinks
        onClick={val => onNavClick(val.href)}
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />

      <Preloader>
        <Tabs
          value={currentTabIndex}
          items={tabs}
          variant={'fullWidth'}
          textColor="inherit"
          style={style}
        />
        {children}
      </Preloader>
    </React.Fragment>
  );
};

ReceiptView.propTypes = {
  loaded: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ReceiptView;
