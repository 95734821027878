import React from 'react';
import { Translate } from '../../../../../common_components/Translate/Translate';
import PrimaryActionButton from '../../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import CommandBar from '../../../../../common_components/CommandBar/CommandBar';
import {
  CountRecords,
  DividerOfContent,
  EmptyGoodsTextWrap,
  GoodsIconWrap,
  TableWrapper,
} from '../../Document/Document.styled';
import { EmptyCisList } from '../CisRegistration.styles';
import GoodsIcon from '../../NotificationOfEntry/Goods/svg/GoodsIcon.svg';
import { columns } from '../CisRegistration.constants';
import List from '../../../../../common_components/List/List';

export const CisRegistrationProducts = ({ cisList, openLoadCisListModal }) => {
  return (
    <div>
      <div style={{ padding: '0 34px'}}>
        <CommandBar
          right={[
            <PrimaryActionButton
              onClick={openLoadCisListModal}
            >
              {Translate('Загрузить CSV')}
            </PrimaryActionButton>,
          ]}
          left={[]}
        />
        <DividerOfContent />
      </div>
      {
        cisList.length === 0 && (
          <EmptyCisList>
            <GoodsIconWrap>
              <GoodsIcon />
            </GoodsIconWrap>
            <EmptyGoodsTextWrap>
              {Translate('Нет добавленных товаров')}
            </EmptyGoodsTextWrap>
          </EmptyCisList>
        )
      }
      {
        cisList.length > 0 && (
          <TableWrapper className="hidden">
            <List
              defaultPageSize={10}
              data={cisList}
              columns={columns}
              showPagination={cisList.length > 10}
            />
            <CountRecords>
              {Translate('Всего записей:')} {cisList.length}
            </CountRecords>
          </TableWrapper>
        )
      }
    </div>
  )
};
