import { get } from 'lodash';
import { authInstance } from './authInstance';
import { Toast } from '../utils/Toast';
import axios from 'axios';
import { countryCodeFlags } from '../constants';
import moment from "moment";
const CancelToken = axios.CancelToken;

export class DocumentService {
  static _getListCancel;

  static getList(params) {
    const data = Object.assign({}, params);
    if (DocumentService._getListCancel)
      DocumentService._getListCancel('PREVENT_TOAST');

    return authInstance.request({
      url: '/api/v3/facade/documents/search',
      method: 'post',
      data,
      cancelToken: new CancelToken(function(c) {
        DocumentService._getListCancel = c;
      }),
    });
  }

  static getListWithoutLk(params) {
    const data = Object.assign({}, params);
    if (params.dateFrom && !params.dateTo) {
      data.dateTo = moment().endOf("day").toDate().toISOString()
    }
    if (!params.dateFrom && params.dateTo) {
      data.dateFrom = new Date(0).toISOString()
    }
    if (DocumentService._getListCancel)
      DocumentService._getListCancel('PREVENT_TOAST');

    return authInstance.request({
      url: '/api/v3/facade/documents/search',
      method: 'post',
      data: {
        ...data,
        excludingTypes: [
          'LK_ADD_APP_USER',
          'LK_ADD_APP_USER_XML',
          'GRAY_ZONE_DOCUMENT',
          'LP_FTS_INTRODUCE_REQUEST',
          ...countryCodeFlags.isKZ ?
            [
              'KZ_EXPORT_NOTICE',
              'KZ_EXPORT_NOTICE_ACCEPTION',
              'EAS_CROSSBORDER',
              'LP_ACCEPT_GOODS'
            ]
            : []
        ]
      },
      cancelToken: new CancelToken(function(c) {
        DocumentService._getListCancel = c;
      }),
    });
  }

  // static getDocumentDetails(id) {
  //   return authInstance.request({
  //     url: `/api/v3/facade/documents/view`,
  //     method: 'post',
  //     data: { id },
  //   });
  // }

  // static getBase64DataById(id) {
  //   return authInstance.request({
  //     url: `/api/v3/facade/documents/download`,
  //     method: 'post',
  //     responseType: 'arraybuffer',
  //     headers: {
  //       'Content-Type': null,
  //     },
  //     data: { id },
  //   });
  // }

  static getListReport(type, data) {
    let count = 0;

    const { participantInn, senderInn, receiverInn } = data;

    return authInstance
      .request({
        url: '/private-office-api/private/v2/documents/count',
        method: 'post',
        data: { ...data, participantInn: participantInn || senderInn || receiverInn, excludingTypes: ['LK_ADD_APP_USER', 'LK_ADD_APP_USER_XML', 'GRAY_ZONE_DOCUMENT','LP_FTS_INTRODUCE_REQUEST'] },
      })
      .then(answer => {
        count = get(answer, 'data.count', 0);

        return authInstance.request({
          url: `/private-office-api/private/v2/documents/buildReport/${type}`,
          method: 'post',
          responseType: 'blob',
          data: {
            ...data,
            offset: 0,
            limit: count,
            excludingTypes: ['LK_ADD_APP_USER', 'LK_ADD_APP_USER_XML', 'GRAY_ZONE_DOCUMENT', 'LP_FTS_INTRODUCE_REQUEST'],
            participantInn: participantInn || senderInn || receiverInn,
          },
        });
      })
      .then(answer => {
        if (count > 100000) {
          Toast.showError({
            content: 'Количество документов для скачивания ограничено 100 000',
          });
        }
        return answer;
      });
  }

  static getDocumentReport(type, id) {
    return authInstance.request({
      url: `/private-office-api/private/v2/documents/${id}/buildReport/${type}`,
      method: 'get',
      responseType: 'blob',
    });
  }
}
