import { takeEvery, put } from 'redux-saga/effects';
import * as actions from './HighloadPagination.actions';
import * as listActions from '../ducks/List.actions';

function* selectedSaga(action) {
  const { payload, meta } = action;
  const { selected, pagesCount, page } = payload;

  if (page !== 1 && selected <= pagesCount) {
    const pageDir = selected > page ? 'PREV' : 'NEXT';
    yield put(listActions.paginate({ pageDir, page }, meta));
  } else {
    yield put(listActions.paginate({ page: 1 }, meta));
  }
}

export default function* watchActions() {
  yield takeEvery(actions.select, selectedSaga);
}
