import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ParticipantDetailsWrapper from '../Wrappers/ParticipantDetailsWrapper';
import ConnectParts from '../../containers/ConnectParts';
import * as actions from '../../actions/';
import * as selectors from '../../selectors/';

class ParticipantDetailsBound extends Component {
  render() {
    return (
      <ConnectParts
        component={ParticipantDetailsWrapper}
        actions={[actions.ParticipantDetails]}
        selectors={[selectors.ParticipantDetails]}
        {...this.props}
      />
    );
  }
}

export default withRouter(ParticipantDetailsBound);
