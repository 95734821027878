import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import { Wrapper, List, ListItem } from './ReactTablePagination.styled';

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.number,
  };

  componentDidUpdate(prevProps) {
    if (this.props.pages !== prevProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, this.props.pages)
      });
    }
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 11) {
      return this.filterPages([2, 3, 4, 5, 6, 7, 8, 9, 10], total);
    } else {
      if (page % 10 >= 0 && page > 7 && page + 3 < total) {
        return [page - 4, page - 3, page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4];
      } else if (page % 10 >= 0 && page > 7 && page + 3 >= total) {
        return [total - 8, total - 7, total - 6, total - 5, total - 4, total - 3, total - 2, total - 1, total];
      } else {
        return [2, 3, 4, 5, 6, 7 , 8, 9, 10];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;
    if (page === activePage) {
      return;
    }
    const visiblePages = this.getVisiblePages(page, this.props.pages);
    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });
    this.props.onPageChange(page - 1);
  }

  render() {
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;
    const PaginationEl = () => (
      <Wrapper>
        <List>
          <ListItem
            active={activePage === 1}
            onClick={this.changePage.bind(null, 1)}
          >
            1
          </ListItem>
          <ListItem
            displayNone={activePage < 8}
            onClick={() => this.changePage(activePage - 1)}            
          >
            <Icon
              type="arrow-to-left"
              size={10}
            />
          </ListItem>

          {visiblePages.map(page => {
            return (
              <ListItem
                key={page}
                active={activePage === page}
                onClick={this.changePage.bind(null, page)}
              >
                {page}
              </ListItem>
            );
          })}

          <ListItem
            displayNone={activePage + 4 >= this.props.pages}
            onClick={() => this.changePage(activePage + 1)}
          >
            <Icon
              type="arrow-to-right"
              size={10}
            />
          </ListItem>
          <ListItem
            displayNone={this.props.pages < 10 || activePage + 5 > this.props.pages }
            onClick={() => this.changePage(activePage + 5)}
          >
            ...
          </ListItem>
        </List>
      </Wrapper>
    )

    if (document.getElementById('paginationNode')) {
      return ReactDOM.createPortal(
        React.createElement(PaginationEl),
        document.getElementById('paginationNode')
      );
    } else {
      return React.createElement(PaginationEl)
    }
  }
}
