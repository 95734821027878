import {actionTypes} from '../constants';


export function loadForm(path, value) {
  return {
    type: actionTypes.FORM.LOAD_FORM,
    path,
    value,
  };
}

export function deleteForm(path) {
  return {
    type: actionTypes.FORM.DELETE_FORM,
    path,
  };
}

export function onAppendItem(path, value) {
  return {
    type: actionTypes.FORM.ITEM_APPEND,
    path,
    value,
  };
}

export function onRemoveItem(path, index) {
  return {
    type: actionTypes.FORM.ITEM_REMOVE,
    path,
    index,
  };
}

export function onActiveOnly(path, only) {
  return {
    type: actionTypes.FORM.ITEM_ACTIVE_ONLY,
    path,
    only,
  };
}

export function onActiveItem(path) {
  return {
    type: actionTypes.FORM.ITEM_ACTIVE,
    path,
  };
}

export function onItemChange(path, value) {
  return {
    type: actionTypes.FORM.ITEM_CHANGED,
    path,
    value
  };
}

export function onCheckboxToggle(path, value) {
  return {
    type: actionTypes.FORM.CHECKBOX_TOGGLED,
    path,
    value,
  };
}

export function onItemValidate(path, value) {
  return {
    type: actionTypes.FORM.ITEM_VALIDATED,
    path,
    value,
  };
}

export function onItemLoad(path, value, service, loader) {
  return dispatch => {
    dispatch(itemLoading(path));
    service(value)
      .then(answer => dispatch(itemLoaded(path, answer.data, loader)))
      .catch(err => console.log('onItemLoad error: ', err));
  };
}

function itemLoading(path) {
  return {
    type: actionTypes.FORM.ITEM_LOADING,
    path,
  };
}

function itemLoaded(path, value, loader) {
  return {
    type: actionTypes.FORM.ITEM_LOADED,
    path,
    value,
    loader,
  };
}


export function clearPaths(paths) {
  return {
    type: actionTypes.FORM.CLEAR_PATHS,
    paths,
  };
}