export const regTheme = {
  TabsWrap: {
    borderBottom: "1px solid #e0e0e0",
    padding: "0px 0px 0px 0px"
  },
  TabUnderline: {
    background: "#0287B0",
    height: "4px",
    transition: "all 0.5s ease 0s",
    bottom: '-1px'
  },
  TabWrap: {
    main: {
      color: "#434343",
      cursor: "pointer",
      background: "rgba(0,0,0,0)"
    },
    disabled: {
      color: "lightgray",
      cursor: "not-allowed"
    }
  },
  fontSize: "16px"
};
