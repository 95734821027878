import styled from 'styled-components';

export const BreadcrumbsWrap = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;
`;

export const Wrap = styled.div`
  position: relative;
  margin-top: 40px;
  padding-bottom: 10px;
`;