import { FilterURLHelper } from './FilterURLHelper';
import { HistoryHelper } from './HistoryHelper';
import {saveToSessionStorage} from '../common_components/List/List.utils';

export const modifyURLByRawDataAndFiltersConfig = (data, config) => {
  const search = FilterURLHelper.getSearchStringFromRawDataAndListFiltersConfig(
    data,
    config
  );
  saveToSessionStorage(`${search}`);
  HistoryHelper.getHistory().push(location.pathname + search);
};

export const extendURLByKeyValueList = keyValueList => {
  const params = FilterURLHelper.getAllParamsFromLocation();
  const search = FilterURLHelper.getSearchStringFromKeyValueObject(
    Object.assign(params, keyValueList)
  );
  HistoryHelper.getHistory().push(location.pathname + search);
};
