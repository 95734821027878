import { flattenDeep, get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import Api from 'src/common_components/Api/Api';
import { ParticipantService } from 'src/services/ParticipantService';
import { Toast } from 'src/utils/Toast';

import * as actions from './DocumentDetails.actions';

import { loadFile } from '../../../common_components/File/File.utils';
import { buildDocumentForPrint } from '../DocumentDetails.utils';
import { documentTypes } from '../../../constants';
import { TNVEDService } from '../../../services/TNVEDService';

function* mountSaga(action) {
  const { payload } = action;
  const { isAdmits, id } = payload;

  const requestPayload = {
    url: isAdmits ? `/api/v3/facade/edo/admits/view/${id}` : `/api/v3/facade/documents/view`,
    method: isAdmits ? 'get' : 'post',
    data: !isAdmits ? { id } : undefined,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data } = success;
    const participants = {};
    const errors = {};
    if (isAdmits) {
      data.type = 'UZ_ADMIT_APPLICATION';
    }
    try {
      if (data.type === 'IMPORT' || data.type === 'EAS_CROSSBORDER') {

        const codes = data.type === 'IMPORT'
          ? data.items.map(item => item.icode || item.iCodeFromDeclaration)
          : data.products.map(item => item.cis)

        const originalCises = yield call(
          getCisList,
          codes
        );
        data.originalCises = Object.values(originalCises);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (data.senderInn && data.type !== 'LP_ACCEPT_GOODS' && data.type !== 'EAS_CROSSBORDER') {
        const senderInfo = yield call(
          ParticipantService.getParticipantInfoByInn,
          data.senderInn,
        );

        if (isAdmits) {
          data.senderName = get(senderInfo, 'data.shortName', '');
        }
        participants.senderInfo = get(senderInfo, 'data', {});
      }
    } catch (e) {
      participants.senderInfo = '-'
      // return null;
    }

    try {
      if (data.receiverInn && data.type !== 'LP_ACCEPT_GOODS' && data.type !== 'EAS_CROSSBORDER') {
        const receiverInfo = yield call(
          ParticipantService.getParticipantInfoByInn,
          data.receiverInn,
        );
        participants.receiverInfo = get(receiverInfo, 'data', {});
      }
    } catch (e) {
      // return null;
      participants.receiverInfo = "-"
    }

    try {
      if (data.type === 'IMPORT_TRANSIT') {
        const cises = get(data, 'importingProducts', []);
        const productTotals = get(data, 'productTotals', {});
        const products = Object.keys(productTotals).map(key => ({
          gtin: key,
          count: productTotals[key],
        }));
        yield call(getPackageCount, cises, products);
      }
    } catch (e) {
      return null;
    }

    try {
      if (data.type === documentTypes.AIC_AGGREGATION) {
        const response = yield call(TNVEDService.getTnvedAddUnits);
        const tnvedAddUnitsData = get(response, 'data', {});

        data.tnvedAddUnitsData = tnvedAddUnitsData;
      }
    } catch (e) {
      yield call(Toast.showError, { content: get(error, 'message', 'error') });
    }

    try {
      if (data.type === 'KZ_UPD') {
        const cises = get(data, 'cises', []);
        const products = get(data, 'products', []);
        yield call(getActCisListData, cises.map(el=> el.code), { content: { products, cises } });
      }
    } catch (e) {
      return null;
    }
    const products = get(data, 'products', []).map((item, idx) => ({
      idx: idx + 1,
      ...item,
    }));

    data.products = products;
    yield put(
      actions.loaded({
        ...data,
        ...participants,
        ...errors,
      }),
    );
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
    yield put(actions.loaded());
  }
}

function* loadOnlyDocumentSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/documents/view`,
    method: 'post',
    data: { id: payload },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data } = success;

    data.products = get(data, 'products', []).map((item, idx) => ({
      idx: idx + 1,
      ...item,
    }));
    yield put(
      actions.loaded({
        ...data,
      }),
    );
  }

  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
    yield put(actions.loaded());
  }
}

function* getActCisListData(cises, data) {
  const cisList = yield call(getCisList, cises);
  let products = get(data, 'content.products', []).map(item => ({
    ...item,
    gtinsCounts: item.count,
  }));
  const [success, error] = yield call(Api.request, {
    url: '/api/v3/facade/product/search-by-gtins',
    method: 'post',
    data: products.map(el=> el.gtin)
  });
  if (success) {
    products = products.map(el => {
      const product = success.data.results.find(item => item.gtin === el.gtin)
      return ({
        ...el,
        name: product ? product.name : "",
        productGroupId: success.data.results.length ? success.data.results[0].productGroupId : null
      });
    })
  }
  if (error) {
    Toast.showError({
      content: `Не удалось получить название товаров.`,
    });
  }
  const km = Object.keys(cisList).map((key, idx) => {
    const item = cisList[key];
    const cisInListCises = get(data, 'content.cises', []).find(el => el.code === key)
    return {
      ...item,
      cis: key,
      idx: idx + 1,
      name: item.productName,
      cisPackageType: item.packageType,
      ...!!cisInListCises && ({cisesCounts: cisInListCises.count})
    };
  });

  // const cisesUnitsInfo = yield call(getCisesUnitsInfo, cises);
  try {
    // const [group, list] = getCodes(cisesUnitsInfo, km);
    // yield put(actions.loadData({ data: data, km: list, group }));
    yield put(actions.loadData({ data: data, km, group: products }));
  } catch (e) {
    Toast.showError({
      content: `Ответ от сервера не соответствует ожидаемому формату.`,
    });
    console.log(e, { e });
  }
}

function* getPackageCount(cises, products) {
  // const cisesUnitsInfo = yield call(
  //   getCisesUnitsInfo,
  //   cises.map(item => item.cis),
  // );

  try {
    // const unitsByGtin = get(cisesUnitsInfo, 'unitsByGtin', {});

    let wideTotal = products.map(item => ({
      ...item,
      name: get(item, 'name', '-'),
      gtinsCounts: item.count,
    }));
    // let wideTotal = Object.keys(unitsByGtin).map(key => {
    //   return {
    //     gtin: key,
    //     name: unitsByGtin[key].productName,
    //     gtinsCounts: unitsByGtin[key].count,
    //   };
    // });

    yield put(actions.setTotal(wideTotal));
  } catch (e) {
    Toast.showError({
      content: `Ответ от сервера не соответствует ожидаемому формату.`,
    });
    console.log(e, { e });
  }
}

function* downloadJSONSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/facade/documents/download`,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': null,
    },
    data: { id: payload },
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const { data, headers } = success;

    try {
      let type = 'xml';
      const disposition = headers['content-disposition'];
      if (disposition && ~disposition.indexOf('attachment')) {
        // eslint-disable-next-line
        const match = disposition.match(/filename\=.*?\.([^;\n ]+)/);
        if (match) type = match[1];
      }

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveOrOpenBlob(
          new Blob([new Uint8Array(data)], {
            type: 'text/plain;charset=utf-8;',
          }),
          `${payload}.${type}`,
        );
      } else {
        const elem = document.createElement('a');
        elem.setAttribute(
          'href',
          window.URL.createObjectURL(new Blob([new Uint8Array(data)])),
        );
        elem.setAttribute('download', `${payload}.${type}`);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    } catch (e) {
      console.log('downloadDocumentClicked error: ', e);
    }
  }

  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* mountAct(action) {
  const { direction, id } = action.payload;
  let requestPayload = {
    url:
      direction === 'act-out'
        ? `/api/v3/facade/edo-api/outgoing-documents/${id}`
        : `/api/v3/facade/edo-api/incoming-documents/${id}`,
    method: 'get',
  };
  const [successData, errorData] = yield call(Api.request, requestPayload);

  if (successData) {
    const cises = get(successData, 'data.content.cises', []).map(
      item => item.code,
    );
    yield call(getActCisListData, cises, successData.data);
    // yield call(getReconciliationDraftSaga, id);
  } else {
    Toast.showError({ content: get(errorData, 'message', 'error') });
  }
}

function getCodes(cisesUnitsInfo, products) {
  const unitsByCis = get(cisesUnitsInfo, 'unitsByCis', {});
  const unitsByGtin = get(cisesUnitsInfo, 'unitsByGtin', {});

  const wideProducts = products.map((item, idx) => ({
    ...item,
    idx: idx + 1,
    cisesCounts: unitsByCis[item.cis].count,
  }));

  let wideTotal = Object.keys(unitsByGtin).map(key => {
    return {
      gtin: key,
      name: unitsByGtin[key].productName,
      gtinsCounts: unitsByGtin[key].count,
    };
  });

  return [wideTotal, wideProducts];
}

function* unsubscribeAct(action) {}

function* printDocumentSaga({ payload }) {
  const { path, docTitle, docData } = buildDocumentForPrint(payload);

  const url = `/api/v3/pdf-generator/kz/notifications/${path}`;
  const requestPayload = {
    url,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      ContentType: 'text/xml;application/json',
    },
    preloading: false,
    data: docData,
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    loadFile({
      success: { data: success.data },
      name: docTitle,
    });
  }
  if (error) {
    Toast.showError({ content: get(error, 'message', 'При ') });
  }
}

function* disaggregationSaga({ payload }) {
  let requestPayload = {
    url: '/api/v3/facade/marked_products/disaggregate-by-cis-list',
    method: 'post',
    data: JSON.stringify(payload),
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
      } else {
        const disaggregated = get(success, 'data', {});
        const kmList = flattenDeep(
          Object.values(disaggregated.gtinGrouped).map(items =>
            items.map(item => item.cis),
          ),
        );

        const infoList = yield call(getCisList, [...kmList, ...payload]);

        const firstCises = [];
        payload.forEach(cis => {
          if (infoList[cis]) {
            firstCises.push(infoList[cis]);
          }
        });

        const disaggregateData = { ...disaggregated, infoList, firstCises };

        yield put(actions.disaggregationCodes(disaggregateData));
      }
    } catch (error) {
      Toast.showError({
        content: `Ошибка дезагрегации`,
      });
      console.log('disaggregation', { error });
    }
  }
}

function* mountEAES(action) {
  const { direction, id } = action.payload;

  let requestPayload = {
    url:
      direction === 'eaes-out'
        ? `/api/v3/facade/edo-api/outgoing-documents/${id}`
        : `/api/v3/facade/edo-api/incoming-documents/${id}`,
    method: 'get',
  };

  const [successData] = yield call(Api.request, requestPayload);

  if (successData) {
    const cisesFromSource = get(
      successData,
      'data.content.document.product_list',
      [],
    );

    let totalInfo = get(
      successData,
      'data.content.document.product_totals',
      [],
    ).map(item => ({
      ...item,
      name: get(item, 'name', '-'),
      gtinsCounts: item.count,
    }));

    const products = yield call(getCisList, cisesFromSource);

    const [success, error] = yield call(Api.request, {
      url: '/api/v3/facade/product/search-by-gtins',
      method: 'post',
      data: totalInfo.map(el=> el.gtin)
    });
    // const totalInfo= yield call(getCisesUnitsInfo, cisesFromSource);
    if (success) {
      totalInfo = totalInfo.map(el => {
        return ({
          ...el,
          name: success.data.results.find(item => item.gtin === el.gtin).name,
        });
      })
    }
    if (error) {
      Toast.showError({
        content: `Не удалось получить названия товаров.`,
      });
    }
    yield put(actions.loaded({ ...successData.data, products, totalInfo }));
  }
}

function* getCisList(cises) {
  const requestPayload = {
    url: `/api/v3/facade/cis/cis_list`,
    method: 'post',
    data: cises,
  };

  const [success] = yield call(Api.request, requestPayload);
  if (success) return success.data;
  else return {};
}

function* getCisesUnitsInfo(cises) {
  const requestPayloadTotal = {
    url: `/api/v3/facade/cis/cises-units-info`,
    method: 'post',
    data: cises,
  };

  const [success] = yield call(Api.request, requestPayloadTotal);

  if (success) return success.data;
  else return {};
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.loadOnlyDocument, loadOnlyDocumentSaga);
  yield takeEvery(actions.downloadJSON, downloadJSONSaga);
  yield takeEvery(actions.mountAct, mountAct);
  yield takeEvery(actions.mountEAES, mountEAES);
  yield takeEvery(actions.unsubscribe, unsubscribeAct);
  yield takeEvery(actions.printDocument, printDocumentSaga);
  yield takeEvery(actions.disaggregation, disaggregationSaga);
}
