import React, { useMemo } from 'react';
import { CodeColumns } from 'src/fragments/Table/Cell/Codes/columns.constants';
import { GoodsColumns } from '../../../../../fragments/Table/Cell/Goods/columns.constants';
import { renderValue, Value } from '../../../../../fragments/Table/Cell/value';
import { makeInputFilter } from '@crpt-ui/datatable';
import moment from 'moment';
import { UndefinedPhrase } from '../../../../../fragments/Table/Cell/stub';
import { Icon } from '@crpt/react-icon';
import {
  getIconByPack,
  getIconNameByCISStatus,
} from '../../../../../components/TableCells/CIS/utils';
import { cisStatuses } from '../../../../../constants';
import { CisCodeWrap } from './Cis.styled';
import { packLevels, packTypes } from '../../../../../constants/packTypes';
import { CellTooltip } from '../../../../../fragments/Table/Cell/CellTooltip';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { Trans } from 'react-i18next';

export const columns = [
  {
    ...CodeColumns.producedDate,
    disableFilters: true,
  },
  {
    ...CodeColumns.status,
    disableFilters: true,
  },
  {
    ...CodeColumns.cis,
    disableFilters: true,
  },
  {
    ...CodeColumns.type,
    disableFilters: true,
  },
  {
    ...CodeColumns.productName,
    disableFilters: true,
  },
  {
    ...CodeColumns.producerName,
    disableFilters: true,
  },
  {
    ...CodeColumns.ownerName,
    disableFilters: true,
  },
];

export const totalColumns = [
  {
    ...GoodsColumns.gtin,
    disableFilters: true,
  },
  {
    ...GoodsColumns.name,
    disableFilters: true,
  },
  {
    id: 'count',
    accessor: 'count',
    Cell: ({ row }) => {
      return <Value>{row.original.count || '-'}</Value>;
    },
    Header: Translate('Итого потребительских упаковок'),
    Filter: makeInputFilter({ label: 'Итого потребительских упаковок' }),
    width: 'auto',
  },
];

export const columnsThirdCountryKG = [
  {
    accessor: 'producedDate',
    id: 'producedDate',
    Cell: ({ row }) => {
      return (
        <Value>
          {row.producedDate ? (
            `${moment(row.producedDate).format('DD.MM.YYYY')}`
          ) : (
            <UndefinedPhrase
              text={
                <Trans>
                  Данные обновляются
                  <br />
                  пожалуйста подождите
                </Trans>
              }
            />
          )}
        </Value>
      );
    },
    Header: Translate('Дата нанесения'),
  },
  {
    accessor: 'status',
    id: 'status',
    Cell: ({ row }) => (
      <Value>
        {row.status ? (
          <div>
            <Icon type={getIconNameByCISStatus(row.status)} spacingRight={6} />
            {cisStatuses[row.status]}
          </div>
        ) : (
          <UndefinedPhrase
            text={
              <Trans>
                Данные обновляются
                <br />
                пожалуйста подождите
              </Trans>
            }
          />
        )}
      </Value>
    ),
    Header: Translate('Статус кода'),
  },
  {
    id: 'cis',
    accessor: 'cis',
    Cell: ({ row }) => (
      <Value>
        {row.cis ? (
          <CisCodeWrap
            href={`/cis/list/${encodeURIComponent(row.cis)}`}
            target={'_blank'}
          >
            {row.cis}
          </CisCodeWrap>
        ) : (
          <UndefinedPhrase
            text={
              <Trans>
                Код маркировки отсутствует
                <br />в реестре кодов маркировки
              </Trans>
            }
          />
        )}
      </Value>
    ),
    Header: Translate('Код'),
  },
  {
    accessor: 'cisPackageType',
    id: 'cisPackageType',
    Cell: ({ row }) => {
      const packageType = (row.cisPackageType || '').toLocaleLowerCase();
      const IconPack = getIconByPack(packageType);

      return (
        <Value>
          {packageType ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconPack style={{ marginRight: '6px' }} />
              {packTypes[packageType] || packLevels[packageType]}
            </div>
          ) : (
            <UndefinedPhrase
              text={
                <Trans>
                  Данные обновляются
                  <br />
                  пожалуйста подождите
                </Trans>
              }
            />
          )}
        </Value>
      );
    },
    Header: Translate('Тип упаковки'),
  },
  {
    accessor: 'productName',
    id: 'productName',
    Cell: ({ row }) => {
      const productInfoList = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.gtin || row.productName ? (
              <div>
                {row.gtin && renderValue(row.gtin, needEllipsis)}
                {row.productName && renderValue(row.productName, needEllipsis)}
              </div>
            ) : (
              <UndefinedPhrase
                text={
                  <Trans>
                    Данные обновляются
                    <br />
                    пожалуйста подождите
                  </Trans>
                }
              />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{productInfoList()}</div>;
      };
      const memoizedTitleValues = useMemo(() => productInfoList(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: 'Товар',
  },
  {
    accessor: 'producerName',
    id: 'producerName',
    Cell: ({ row }) => {
      const producerNameInfo = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.producerName || row.producerInn ? (
              <div>
                {row.producerName &&
                  renderValue(row.producerName, needEllipsis)}
                {row.producerInn && renderValue(row.producerInn, needEllipsis)}
              </div>
            ) : (
              <UndefinedPhrase
                text={
                  <Trans>
                    Данные обновляются
                    <br />
                    пожалуйста подождите
                  </Trans>
                }
              />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{producerNameInfo()}</div>;
      };
      const memoizedTitleValues = useMemo(() => producerNameInfo(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: Translate('Производитель'),
  },
  {
    accessor: 'ownerName',
    id: 'ownerName',
    Cell: ({ row }) => {
      const ownerNameInfo = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.ownerName || row.ownerInn ? (
              <div>
                {row.ownerName && renderValue(row.ownerName, needEllipsis)}
                {row.ownerInn && renderValue(row.ownerInn, needEllipsis)}
              </div>
            ) : (
              <UndefinedPhrase
                text={
                  <Trans>
                    Данные обновляются
                    <br />
                    пожалуйста подождите
                  </Trans>
                }
              />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{ownerNameInfo()}</div>;
      };
      const memoizedTitleValues = useMemo(() => ownerNameInfo(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: Translate('Текущий владелец'),
  },
];
