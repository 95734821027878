import { selector } from 'src/utils/selector';
import { limit } from '../ExportList.constants';

export const results = state => selector(state, 'Requests.ExportListUz.results');
export const total = state => selector(state, 'Requests.ExportListUz.total');
export const last = state => selector(state, 'Requests.ExportListUz.last');
export const pagesCount = state => Math.ceil(total(state) / limit);
export const sorting = state => selector(state, 'Requests.ExportListUz.sorting');
export const selectedPage = state => selector(state, 'Requests.ExportListUz.selectedPage');
export const pagination = state => selector(state, 'Requests.ExportListUz.pagination');
export const filters = state => selector(state, 'Requests.ExportListUz.filters');
export const uploadErrors = state => selector(state, 'Requests.ExportListUz.uploadErrors');
export const offset = state => selector(state, 'Requests.ExportListUz.offset');
