import { createAction } from 'redux-actions';

export const subject = '[AIK]';

export const createAik = createAction(`${subject} createAik`);
export const confirmAikDoc = createAction(`${subject} confirmAikDoc`);
export const loadTnvedAddUnits = createAction(`${subject} loadTnvedAddUnits`);
export const mounted = createAction(`${subject} mounted`);
export const documentLoaded = createAction(`${subject} documentLoaded`);
export const setError = createAction(`${subject} setErrorInfo`);
export const unmounted = createAction(`${subject} unmounted`);
