import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import { StoreHelper } from '../../../utils/StoreHelper';
import {actionTypes} from '../../../constants';

const Wrap = styled.div`
  width: 100%;
  display: flex;
`;

const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a99f4;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
`;

const NoLink = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const IconWrap = styled.div`
  margin-right: 16px;
  width: 16px;
  min-width: 16px;
`;

const GTIN = props => {
  const product = props.options.gtin;
  const { gtin } = product._original;

  const participantId = location.pathname.split('/')[3];

  return (
    <Wrap>
        <Link
          href={`/participants/list/${participantId}/cis?gtin=${gtin}`}
          onClick={e => {
            e.preventDefault();
            props.history.push(`/participants/list/${participantId}/cis?gtin=${gtin}`);
          }}
        >
          {gtin}
        </Link>
    </Wrap>
  );
};

export default withRouter(GTIN);
