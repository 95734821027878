import {handleActions} from 'redux-actions';
import * as grayAreaDocumentActions from './GrayAreaDocument.actions';

const documentResult = handleActions(
  {
    [grayAreaDocumentActions.loaded]: (state, { payload }) => payload || null,
    [grayAreaDocumentActions.unMount]: () => null,
  },
  null
);

const loaded = handleActions(
  {
    [grayAreaDocumentActions.loaded]: () => true,
    [grayAreaDocumentActions.unMount]: () => false,
  },
  false,
);

export default {
  documentResult,
  loaded
}