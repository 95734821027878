import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../_refactor/FieldsWrapper';
import RadioAdapter from '../Adapters/RadioAdapter';

const Radio = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <RadioAdapter name={name} {...passProps} validate={validate} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

File.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  accept: PropTypes.string.isRequired,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
};

File.defaultProps = {
  name: '__form_File',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  icon: undefined,
  placeholder: undefined,
};

export default Radio;
