import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paragraph2, Content, Accord, IconWrap } from './Showing.styled';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import Icon from '@crpt/react-icon';
import { Button as SButton, themes as BUTTON_THEMES } from '@crpt/react-button';
import {
  getActLinks,
  getDocumentStatusIconByDocStatus,
} from '../DocumentDetails.utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import { operationTypes } from '../../../motp_components/_Requests/RequestList/ActDocument/ActDocument.constants';
import moment from 'moment';
import {
  DividerOfContent,
  DocumentTitleName,
} from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { LinkedDocuments } from './LinkedDocuments';
import { Translate } from '../../../common_components/Translate/Translate';

const ActFields = ({
  data,
  id,
  docDate,
  countryCode,
  direction,
  showLinkedDoc,
}) => {
  const [show, setShow] = useState({
    seller: true,
    buyer: true,
    type: true,
    requisites: true,
  });

  const onShow = name => {
    setShow(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const operationType = useMemo(() => {
    if (data.content) {
      const find = operationTypes.find(
        item => item.id === data.content.operation_type,
      );
      return find ? find.label : '-';
    } else {
      return '';
    }
  }, [data]);

  const paperDocNumber =
    get(data, 'documentNumber') || get(data, 'content.origin_number', '-');
  let paperDocDate = get(data, 'origin_date');

  if (paperDocDate)
    paperDocDate = moment(new Date(paperDocDate)).format('DD.MM.YYYY');
  else paperDocDate = get(data, 'content.origin_date', '-');

  const documentNumber = get(data, 'number', '-');

  const isFixed = useMemo(
    () => (data.type === 1100 || data.correctionDoc ? 'Да' : 'Нет'),
    [data],
  );

  const links = useMemo(() => {
    return getActLinks(data, id, direction);
  }, [data]);

  return (
    <React.Fragment>
      <Grid container justify="space-between">
        <Grid item>
          <DocumentTitleName>
            {Translate('Акт приема-передачи №')}{' '}
            <span>{countryCode === 'KZ' ? documentNumber : id}</span>{' '}
            {Translate('от')} {docDate}
          </DocumentTitleName>
        </Grid>
        <Grid item>
          <SButton theme={BUTTON_THEMES.whiteTheme}>
            <Icon
              type={getDocumentStatusIconByDocStatus(data.status)}
              spacingRight={9}
            />
            {Translate(documentStatusesNames[data.status])}
          </SButton>
        </Grid>
      </Grid>
      <br />
      <DividerOfContent />

      {/*<Paragraph> Общие данные </Paragraph>*/}
      {showLinkedDoc && (
        <React.Fragment>
          <Paragraph2> {Translate('Связанные документы')} </Paragraph2>
          <Content>
            <LinkedDocuments links={links} />
          </Content>
        </React.Fragment>
      )}
      <Paragraph2> {Translate('Исправленный документ')} </Paragraph2>
      <Content> {Translate(isFixed)} </Content>
      <br />
      <DividerOfContent />
      <Accord onClick={() => onShow('seller')}>
        <span>{Translate('Продавец')}</span>
        <IconWrap>
          <Icon
            type={show.seller ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.seller && (
        <Grid container>
          <Grid item xs={4}>
            {Translate('Наименование')} <br />
            <Content>{get(data, 'content.seller.name', '-')}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('ИИН/БИН')} <br />
            <Content>{get(data, 'content.seller.inn', '-')}</Content>
          </Grid>
        </Grid>
      )}
      <br />
      <DividerOfContent />
      <Accord onClick={() => onShow('buyer')}>
        <span>{Translate('Покупатель')}</span>
        <IconWrap>
          <Icon type={show.buyer ? 'up-arrow' : 'down-arrow'} spacingLeft={6} />
        </IconWrap>
      </Accord>
      {show.buyer && (
        <Grid container>
          <Grid item xs={4}>
            {Translate('Наименование')} <br />
            <Content>{get(data, 'content.buyer.name', '-')}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('ИИН/БИН')} <br />
            <Content>{get(data, 'content.buyer.inn', '-')}</Content>
          </Grid>
        </Grid>
      )}
      <br />
      <DividerOfContent />
      <Accord onClick={() => onShow('type')}>
        <span>{Translate('Тип операции')}</span>
        <IconWrap>
          <Icon type={show.type ? 'up-arrow' : 'down-arrow'} spacingLeft={6} />
        </IconWrap>
      </Accord>
      {show.type && (
        <React.Fragment>
          {Translate('Наименование')} <br />
          <Content>{operationType}</Content>
        </React.Fragment>
      )}
      <br />
      <DividerOfContent />
      <Accord onClick={() => onShow('requisites')}>
        <span>{Translate('Прочие реквизиты документа')}</span>
        <IconWrap>
          <Icon
            type={show.requisites ? 'up-arrow' : 'down-arrow'}
            spacingLeft={6}
          />
        </IconWrap>
      </Accord>
      {show.requisites && (
        <Grid container>
          <Grid item xs={4}>
            {Translate('Номер бумажного документа')} <br />
            <Content>{paperDocNumber}</Content>
          </Grid>
          <Grid item xs={4}>
            {Translate('Дата бумажного документа')} <br />
            <Content>{paperDocDate}</Content>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
ActFields.propTypes = {
  showingData: PropTypes.object,
  unsubscribe: PropTypes.func,
};

ActFields.defaultProps = {
  hasDirection: false,
  showLinkedDoc: true,
};

export default ActFields;
