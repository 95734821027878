import { get, chunk, compact, values } from 'lodash';
import papaparse from 'papaparse';
import React from 'react';
import Table from '@crpt/react-table';
import TestRenderer from 'react-test-renderer';

export const transformChunkArray = (content, numCol) => {
  const numRows = Math.floor(content.length / numCol);
  const data = chunk(content, numRows);
  const arrData = [];

  if (data.length > numCol) {
    data.forEach((item, i) => {
      if (i >= numCol) {
        arrData[0] = arrData[0].concat(item);
      } else {
        arrData[i] = item;
      }
    });

    return arrData;
  }

  return data;
};

export const getBlockTitles = (data) => {
  const titles = {};
  data[0].forEach((item, i) => {
    if (item !== '') titles[item] = data[1][i];
  });

  return titles;
};

export const getDataTable = (tovars) => {
  // orders || document
  // так как в 3 строке для разных док-ов + заказы все  по разному - обрабатываем это дело
  const numTh = tovars[0].length === 1 ? 1 : (tovars[0][0] === 'Перечень товаров' ? 1 : 0);
  const numTr = numTh + 1;
  const data = [];
  const arrWidth = {};
  const rows = tovars.slice(numTr);

  rows.forEach((items, i) => {
    data[i] = {};
    items.forEach((item, j) => {
      const key = `row_${j}`;
      arrWidth[key] = Math.max(item.length, get(arrWidth, key, 0));
      data[i][key] = item;
    });
  });

  const columns = [];
  let tableWidth = 0;
  tovars[numTh].forEach((item, i) => {
    const key = `row_${i}`;
    if (item === '') {
      data.map((val, j) => delete data[j][key]);
    } else {
      const width = Math.max((arrWidth[key] * 10 + 20), 150);
      tableWidth += width;
      columns[i] = { Header: item,
        id: key,
        width,
        accessor: key };
    }
  });

  if (tableWidth < 1100) delete columns[columns.length - 1].width;
  return [columns, data];
};

export const isValidParsers = (content, type) => {
  if (type !== 'CSV') return true;

  try {
    const { data } = papaparse.parse(content, { header: false });
    const arrData = transformChunkArray(data[0], 3);
    const tovars = data.slice(2);
    const [columns, data_] = getDataTable(tovars);
    const titles = getBlockTitles(data);

    if (arrData.length < 1 || data_.length < 1) throw 'Error';
  } catch (e) {
    return false;
  }
  return true;
};

export const isCsvOrXml = (type) => {
  return (type === 'XML' || type === 'CSV');
};