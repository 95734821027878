export const getActions = dispatch => ({
  closeOptionsPanel: (payload = false) => {
    dispatch({ type: 'close-options-panel', payload });
  },
  openOptionsPanel: () => {
    dispatch({ type: 'open-options-panel' });
  },
  setDefaultSelectValues: payload => {
    dispatch({ type: 'set-default-select-values', payload });
  },
  clearSelectValue: () => {
    dispatch({ type: 'clear-select-value' });
  },
  setInputValue: payload => {
    dispatch({ type: 'set-input-value', payload });
  },
  toggleSelectValues: payload => {
    dispatch({ type: 'toggle-select-values', payload });
  },
});
