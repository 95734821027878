import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';
import { FormContext, formMutators } from './Form.constants';
import { deepOmit, deepRangePickerPrepare } from './Form.utils';

const FormWrapper = styled.form`
  height: calc(100% - 45px);
  box-sizing: border-box;
`;

class Form extends React.Component {
  static propTypes = {
    data: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
      .isRequired,
    disabled: PropTypes.bool,
    validate: PropTypes.func,
    decorators: PropTypes.arrayOf(PropTypes.func),
  };

  static defaultProps = {
    data: {},
    onSubmit: undefined,
    disabled: false,
    validate: undefined,
    decorators: undefined,
  };

  onSubmit = vals => {
    const { onSubmit } = this.props;
    const formName = vals.__form__nameForm;

    const preparedRangePicker = deepRangePickerPrepare(
      vals,
      /^__form_RangePicker_.*$/
    );

    const prepared = deepOmit(preparedRangePicker, [/^__form_.*$/]);

    if (onSubmit) onSubmit(prepared, { formName });
  };

  render() {
    const { data, children, disabled, validate, decorators, style } = this.props;

    return (
      <FinalForm
        initialValues={data}
        onSubmit={this.onSubmit}
        mutators={formMutators}
        validate={validate}
        decorators={decorators}
      >
        {({ handleSubmit, values, errors, form, reset }) => {
          return (
            <FormWrapper onSubmit={handleSubmit} style={style}>
              <FormContext.Provider
                value={{
                  values,
                  errors,
                  mutators: form.mutators,
                  reset: form.reset,
                  disabled,
                  formChange: form.change,
                }}
              >
                {children}
              </FormContext.Provider>
              {/* <pre style={{ maxWidth: '800px', overflowX: 'scroll' }}>{JSON.stringify(values, 0, 2)}</pre>
            <pre style={{ maxWidth: '800px', overflowX: 'scroll' }}>{JSON.stringify(errors, 0, 2)}</pre> */}
            </FormWrapper>
          );
        }}
      </FinalForm>
    );
  }
}

export default Form;
