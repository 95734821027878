import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { get } from 'lodash';

import { Button } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import Popup from '../../../Popup';
import { Action } from './styled';
import { Translate } from '../../../../common_components/Translate/Translate';


class DownloadButton extends PureComponent {
  static propTypes = {
    showDownloadListButton: PropTypes.bool.isRequired,
    popupMenu: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
    ]).isRequired,
  };

  render() {
    const buttonTheme = { Button: get(this.props, 'theme.TableFilter.buttonBar.button') };
    const iconFill = get(buttonTheme, 'Button.iconFill', '#434244');
    const { showDownloadListButton, popupMenu } = this.props;
    const SimpleDownloadButton = () => (
      <Action>
        <Button theme={buttonTheme}>
          <Icon type="download" spacingRight={16} fill={iconFill} />
          {Translate('Скачать')}
        </Button>
      </Action>
    );

    return (
      showDownloadListButton && (popupMenu
        ? (
          <Popup menu={popupMenu}>
            <SimpleDownloadButton />
          </Popup>
        )
        : <SimpleDownloadButton />
      )
    );
  }
}

export default withTheme(DownloadButton);
