import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';


const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
};

class EmptyComponent extends PureComponent {
  static displayName = "Empty component";

  static propTypes = {
    input: PropTypes.shape({}),
    value: PropTypes.any,
  };

  render () {
    const { input, value } = this.props;
    Promise.resolve().then(() => input.onChange(value));
    return null;
  }
}

const CustomField = ({ name, value, ...othersProps }) => (
  <Field name={name} render={(props) => <EmptyComponent value={value} {...props} />} {...othersProps} />
);

CustomField.propTypes = propTypes;
export default CustomField;
