import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@crpt/react-tabs';
import Icon from '@crpt/react-icon';
import { Input, Button } from '../../../Fields';
import { Line, RemoveWarehouseButton, ButtonsWrap , WarehouseName} from './styled';
import { DEFAULT_TAB_ID, tabs } from './constants';
import FiasAddress from './FiasAddress';
import { Closed } from '../styled';
import { required } from '../../../utils/validations';
import { tabsRegTheme } from '../../../../../theme/ru-tabaco/tabs';
import {
  buttonClearTheme,
  buttonWhiteTheme,
} from '../../../../../theme/ru-tabaco/button';

const iconStyle = { marginBottom: '16px' };

const displayNone = { display: 'none' };

class Warehouse extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    data: PropTypes.shape({}),
    name: PropTypes.string.isRequired,
    onActivate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  state = {
    addressTab: { id: DEFAULT_TAB_ID },
    query: {
      fullAddress: '',
      regionCode: '',
      postalCode: '',
      city: '',
      settlement: '',
      area: '',
      street: '',
      house: '',
      block: '',
      office: '',
    },
  };

  onChangeQuery = (key, value) => {
    const { onUpdate, name } = this.props;
    this.setState(
      state => ({ query: { ...state.query, [key]: value } }),
      () => {
        const { query } = this.state;
        const newAddress = [
          query.regionCode,
          query.postalCode,
          query.city,
          query.settlement,
          query.area,
          query.street,
          query.house,
          query.block,
          query.office,
        ]
          .filter(v => v)
          .join(',');
        onUpdate(`${name}.address`, newAddress);
      }
    );
  };

  onRegionCodeChange = value => this.onChangeQuery('regionCode', value);

  onPostalCodeChange = value => this.onChangeQuery('postalCode', value);

  onCityChange = value => this.onChangeQuery('city', value);

  onSettlementChange = value => this.onChangeQuery('settlement', value);

  onAreaChange = value => this.onChangeQuery('area', value);

  onStreetChange = value => this.onChangeQuery('street', value);

  onHouseChange = value => this.onChangeQuery('house', value);

  onBlockChange = value => this.onChangeQuery('block', value);

  onOfficeChange = value => this.onChangeQuery('office', value);

  onTabSelect = value => this.setState({ addressTab: value });

  onActivate = () => {
    const { onActivate, index } = this.props;
    onActivate(index);
  };

  onRemove = () => {
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  onAdd = () => {
    const { onAppend } = this.props;
    onAppend();
  };

  render() {
    const { active, name, data, length, index } = this.props;
    const { addressTab, query } = this.state;

    const callbacks = {
      onRegionCodeChange: this.onRegionCodeChange,
      onPostalCodeChange: this.onPostalCodeChange,
      onCityChange: this.onCityChange,
      onSettlementChange: this.onSettlementChange,
      onAreaChange: this.onAreaChange,
      onStreetChange: this.onStreetChange,
      onHouseChange: this.onHouseChange,
      onBlockChange: this.onBlockChange,
      onOfficeChange: this.onOfficeChange,
    };

    return (
      <React.Fragment>

        <WarehouseName>{data.code}</WarehouseName>

        <Line>
          <Tabs
            theme={{ Tabs: tabsRegTheme }}
            tabs={tabs}
            selectedId={addressTab.id}
            onSelect={this.onTabSelect}
          />
        </Line>
        {addressTab.id === 1 && (
          <FiasAddress name={`${name}.address`} query={query} {...callbacks} />
        )}
        {addressTab.id === 2 && (
          <Input
            name={`${name}.address`}
            defaultValue={data.address}
            placeholder="Код по ГАР"
          />
        )}
        {addressTab.id === 3 && (
          <Input
            name={`${name}.address`}
            defaultValue={data.address}
            placeholder="Адрес склада"
          />
        )}
        <Input
          name={`${name}.address`}
          validate={required}
          style={displayNone}
        />

        <Input
          name={`${name}.code`}
          defaultValue={data.code}
          placeholder="Код склада"
          validate={required}
        />

        <ButtonsWrap>
          {length !== 1 ? (
            <RemoveWarehouseButton onClick={this.onRemove}>
              <Icon type={'pan'} fill={'#55B5E7'} spacingRight={16} />Удалить
              склад
            </RemoveWarehouseButton>
          ) : (
            <div />
          )}
          {index === length - 1 && (
            <Button
              inline
              noBottomMargin
              onClick={this.onAdd}
              theme={{ Button: buttonWhiteTheme }}
            >
              <Icon
                type="add-plus"
                fill={'#434244'}
                width={10}
                spacingRight={19}
              />Добавить еще склад
            </Button>
          )}
        </ButtonsWrap>
      </React.Fragment>
    );
  }
}

export default Warehouse;
