import styled from 'styled-components';

export const PermissionWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 16px;
  color: ${props => props.checked ? '#52535a' : '#86878C'};
`;
