import styled from 'styled-components';

export const CisCodeWrap = styled.a`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  color: #55b5e7;
  cursor: pointer;
  display: block;
  text-decoration: none !important;
`;

export const SignModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 580px;
`;

export const ModalHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 22px;
  border-bottom: 1px solid #f2f2f2;
  color: #63666A;
  font-size: 20px;
  height: 60px;
`;

export const ModalContentWrap = styled.div`
  padding: 40px;
  padding-bottom: 20px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  height: 90px;
  border-top: 1px solid #F2F2F2;
`;