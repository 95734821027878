import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { HintedEmailInput as CrptHintedEmailInput } from '@crpt/react-dadata';
import * as EmailValidator from 'email-validator';
import { Wrap } from './styled';
import { withCountedPlaceholder, withErrorProp, withFieldWrapper } from './utils';
import HintedContent from '../Steps/Contacts/HintedContent';
import {dadataTheme} from '../../../theme/ru-tabaco/select/dadata';

const validation = val => !EmailValidator.validate(val) && 'Error';


const Element = ({ dadataSettings, input, ...rest }) => (
  <Wrap onBlur={input && input.onBlur}>
    <CrptHintedEmailInput
      onChange={(input && input.onChange) || rest.onChange}
      savePlaceholder
      apiKey={dadataSettings.apiKey}
      apiURL={dadataSettings.apiEmailURL}
      hideOptionsPanel={!dadataSettings}
      filterDisabled
      defaultText={(input && input.value) || rest.defaultValue}
      theme={{Select: dadataTheme}}
      {...rest}
    />
    <HintedContent />
  </Wrap>
);

Element.propTypes = {
  input: PropTypes.shape({}).isRequired,
  dadataSettings: PropTypes.shape({}).isRequired,
};

const enchanced = compose(
  connect(state => ({ dadataSettings: state.App.config.dadataSettings })),
  withErrorProp,
  withCountedPlaceholder,
  onlyUpdateForKeys(['name', 'placeholder', 'defaultValue', 'isError', 'query']),
)(Element);


export default compose(
  setDisplayName('HintedEmailInput'),
  withFieldWrapper(enchanced, validation),
)(enchanced);
