import { actionTypes, } from '../constants';
import { AnalyticsService } from '../services/AnalyticsService';


const mapDataLoading = () => ({
  type: actionTypes.ANALYTICS.MAP_DATA.LOADING,
});

const mapDataLoaded = (data) => ({
  type: actionTypes.ANALYTICS.MAP_DATA.LOADED,
  data,
});

const loadMapData = (region, period) => (dispatch) => {
  dispatch(mapDataLoading());

  AnalyticsService.getMapData({ region, period, }).then((answer) => {
    dispatch(mapDataLoaded(answer.data));
  });
};

export {
  loadMapData,
};
