import React from 'react';
import PropTypes from 'prop-types';

import toView from './utils';

import { Wrapper, Column } from './styled';

const propTypes = {
  beginning: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inflow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outflow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  beginning: '-',
  inflow: '-',
  outflow: '-',
  balance: '-',
};

const WarehouseItem = ({
  beginning, inflow, outflow, balance,
}) => (
  <Wrapper>
    <Column>{toView(beginning)}</Column>
    <Column>{toView(inflow)}</Column>
    <Column>{toView(outflow)}</Column>
    <Column>{toView(balance)}</Column>
  </Wrapper>
);

WarehouseItem.propTypes = propTypes;
WarehouseItem.defaultProps = defaultProps;

export { WarehouseItem };
