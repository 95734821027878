import { handleActions } from 'redux-actions';
import * as actions from './AutocompleteFilter.actions';

const loaded = handleActions(
  {
    [actions.mounted]: () => true,
    [actions.updated]: () => false,
    [actions.loaded]: (state, { payload = [] }) => !!payload || false,
    [actions.unmounted]: () => false,
    [actions.error]: () => true,
  },
  false,
);

const results = handleActions(
  {
    [actions.mounted]: () => [],
    [actions.updated]: () => [],
    [actions.loaded]: (state, { payload = [] }) => payload,
    [actions.unmounted]: () => [],
    [actions.error]: () => [],
  },
  [],
);

const error = handleActions(
  {
    [actions.mounted]: () => null,
    [actions.loaded]: () => null,
    [actions.unmounted]: () => null,
    [actions.error]: (state, { payload = 'error' }) => payload,
  },
  null,
);

export default {
  loaded,
  results,
  error,
};
