import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }   
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': <ul>
            <li>ИНН + Тип документа</li>
          </ul>,
          'col3': 'ИНН и наименование участника оборота товара',
        },
        {
          'col1': [3],
          'col2': <ul>
            <li>ИНН + Тип документа</li>
            <li>Тип документа</li>
          </ul>,
          'col3': 'Тип поступившего в систему документа',
        },
        {
          'col1': [4],
          'col2': <ul>
            <li>ИНН + Тип документа</li>
          </ul>,
          'col3': 'Дата поступления документа в систему',
        },
        {
          'col1': [5],
          'col2': <ul>
            <li>ИНН + Тип документа</li>
            <li>Тип документа</li>
          </ul>,
          'col3': 'Количество поступивших в систему документов, обработанных успешно',
        },
        {
          'col1': [6],
          'col2': <ul>
            <li>ИНН + Тип документа</li>
            <li>Тип документа</li>
          </ul>,
          'col3': 'Количество поступивших в систему документов, обработанных с ошибкой',
        },
      ]
    }
  ]
}