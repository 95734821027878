/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldHoc from '../FieldHoc';
import { DatePickerInput as DatePickerCRPT, RangeDatePickerInput as RangeDatePickerInputCRPT } from '@crpt-ui/core';

const RangeDatePicker = ({ input, meta, ...fieldRest }) => {
  const { placeholder, errorPlaceholder, isError, ...rest } = fieldRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  const submitHandle = dates => {
    input.onChange(dates);
  };

  const error = isError(meta);

  return (
    <RangeDatePickerInputCRPT
      date={input.value}
      onChange={input.onChange}
      onFocus={input.onFocus}
      onSubmit={submitHandle}
      label={placeholder}
      error={error}
      InputProps={{
        readOnly: true,
      }}
      {...rest}
    />
  );
};

RangeDatePicker.propTypes = {
  isError: PropTypes.func,
  required: PropTypes.bool,
};

RangeDatePicker.defaultProps = {
  isError: meta => meta.invalid,
  required: false,
};

export default FieldHoc(RangeDatePicker);
