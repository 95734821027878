import React, { Component } from 'react';

import { Pagination } from '@crpt/react-pagination';
import Table from '@crpt/react-table';
import { InlineWrap } from '@crpt/react-page-layout';

import { cardProfileTheme } from '../../theme/ru-tabaco/card';
import { options } from './constants';
import { tableDefaultTheme } from '../../theme/ru-tabaco/table';
import {
  FillCard,
  TableTitle,
  CountWrap,
  PaginationWrap,
  PaginationCentrator,
  TableHeading,
  TableParam,
  FillWidth
} from './styled';
import {addSpacesBetweenThousands, getRubelsAsFloatFromKopecks} from '../../utils';
// import { paramsFormatter } from './utils';
import {Translate} from '../../common_components/Translate/Translate';

const PACK_NAMES = {
  pack: 'Пачки',
  block: 'Блоки',
  box: 'Короба'
};

class   ProductsList extends Component {
  render() {
    const {
      List,
      total,
      selectedPage,
      pagesCount,
      filterParams
    } = this.props.Excise;

    const { pageSelected } = this.props.actions.Excise;

    const columns = [
      {
        Header: <TableHeading>{Translate('Наименование')}</TableHeading>,
        id: 'name',
        accessor: 'name'
      },
      {
        Header: <TableHeading>GTIN</TableHeading>,
        id: 'gtin',
        accessor: 'gtin',
        width: 190
      },
      {
        Header: (
          <TableHeading>
            <FillWidth>{Translate('Общее количество')},</FillWidth>
            <div>{Translate('пачки')}</div>
          </TableHeading>
        ),
        id: 'total',
        accessor: d => addSpacesBetweenThousands(d.total),
      },
      {
        Header: (
          <TableHeading>
            <FillWidth>{Translate(PACK_NAMES[filterParams.packageType ? filterParams.packageType : 'pack'])},</FillWidth>
            <InlineWrap flex>
              <TableParam>{Translate('Отгружено')}</TableParam>
              <TableParam>{Translate('Не отгружено')}</TableParam>
            </InlineWrap>
          </TableHeading>
        ),
        id: 'shippedGoods',
        accessor: 'shippedGoods',
        sortable: false,
      },
      {
        Header: <TableHeading>{Translate('Акциз')}</TableHeading>,
        id: 'excise',
        accessor: d => addSpacesBetweenThousands(getRubelsAsFloatFromKopecks(d.excise)),
        sortable: false,
        width: 120
      }
    ];

    return (
      <FillCard theme={cardProfileTheme}>
        {2147483647 !== total ? (
          <TableTitle theme={cardProfileTheme}>
            {Translate('Всего элементов:')}<CountWrap>{total}</CountWrap>
          </TableTitle>
        ) : null}

        <div>
          <Table
            theme={{ Table: tableDefaultTheme }}
            columns={columns}
            options={options}
            minRows={0}
            highlight
            striped
            data={List}
            noDataText={Translate("Нет данных")}
          />
        </div>
        <PaginationWrap />
        <PaginationCentrator>
          <Pagination
            leftPaginationText={Translate("Предыдущая")}
            rightPaginationText={Translate("Следующая")}
            pagesCount={pagesCount}
            selected={selectedPage}
            pageCounterInvisible
            isBelowZeroDisabled
            onSelect={v => pageSelected(v, { filterParams })}
          />
        </PaginationCentrator>
      </FillCard>
    );
  }
}

export { ProductsList };
