import React from 'react';

import { ProductionLinesTableField } from './ProductionLinesTableField/ProductionLinesTableField';
import { Translate } from '../../../common_components/Translate/Translate';

export const columnsTable = [
  {
    Header: Translate('Наименование'),
    id: 'productionPlaceName',
    accessor: 'productionPlaceName',
    width: null,
  },
  {
    Header: Translate('Страна производства'),
    id: 'productionCountryName',
    accessor: 'productionCountryName',
  },
  {
    Header: Translate('Адрес производства'),
    id: 'productionAddress',
    accessor: 'productionAddress',
  },
  {
    Header: Translate('Производственные линии'),
    id: 'productionLines',
    accessor: (row) => <ProductionLinesTableField productionLine={row} />,
  },
  // {
  //   Header: 'КПП',
  //   id: 'productionKpp',
  //   accessor: 'productionKpp',
  // },
];

export const RUSSIA_COUNTRY_CODE = '643';
