import React from 'react';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from "../../../../common_components/Buttons/MuiPrimaryActionLargeButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {Translate} from "../../../../common_components/Translate/Translate";
import DialogContent from "@material-ui/core/DialogContent";
import {Box} from "@material-ui/core";

const AcceptUploadModal = (props) => {
  const { open, onClose, formValues, onAccept } = props;

  const onAcceptHandler = () => {
    onAccept();
    onClose();
  }

  return (
    <Dialog
      minWidth={492}
      maxWidth={492}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">{Translate('Результаты загрузки')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box style={{minWidth: '400px', padding: '20px', border: '1px dashed #d9d9d9', boxSizing: 'border-box'}}>
          <Typography>
            Совпало кодов: {formValues.uploadProducts && formValues.uploadProducts.accept.length}
          </Typography>
          <Typography>
            Не совпало кодов: {formValues.uploadProducts && formValues.uploadProducts.rejectFromFile}
          </Typography>
        </Box>
      </DialogContent>
      <Box style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
        <CommonActionButton onClick={onClose}>
          {Translate('Отменить')}
        </CommonActionButton>
        <PrimaryActionLargeButton onClick={onAcceptHandler} disabled={formValues.uploadProducts && !formValues.uploadProducts.accept.length}>
          {Translate('Принять результаты')}
        </PrimaryActionLargeButton>
      </Box>
    </Dialog>
  );
};

export default AcceptUploadModal;
