import { createActions } from 'redux-actions';

export const {
  requestSearchGtins,
  clear,
  loaded,
} = createActions(
  {
    REQUEST_SEARCH_GTINS: payload => payload,
    CLEAR: () => ({}),
    LOADED: payload => payload
  },
  {
    prefix: 'GRAY_AREA_SEARCH_GTINS',
  },
);
