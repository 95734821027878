import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import InputFinalForm from '../../../../../common_components/Form/Fields/Input/Input';
import { isExciseTax } from '../../../../../common_components/Form/utils/validations';
import React, { useEffect, useRef } from 'react';
import InputMask from 'inputmask';

export const FloatInput = ({ inputNotFromMui, name, label, mask, required, forceValidate, validate, substitutes, ...rest }) => {
  const inputRef = useRef();
  const inputmask = new InputMask({
    mask,
    substitutes,
  });

  useEffect(() => {
    if (inputRef.current && !inputNotFromMui) {
      inputmask.mask(inputRef.current);
    }
  }, []);

  return (
    inputNotFromMui ? (
      <InputFinalForm
        name={name}
        mask={mask}
        placeholder={label}
        substitutes={substitutes}
        required={required}
        {...rest}
      />
    ) : (
      <Input
        inputRef={inputRef}
        name={name}
        placeholder={label}
        forceValidate={forceValidate}
        required={required}
        validate={validate ? validate : isExciseTax}
      />
    )
  );
};

FloatInput.defaultProps = {
  mask: '9{1,17}.9999',
  forceValidate: true,
  required: true,
  substitutes: {},
  inputNotFromMui: false,
};
