import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EmptyProductionLinesLogo } from '../../../images/emptyLines.svg';

import { withStyles } from '@material-ui/core';
import { styles } from './EmptyProductionLines.styles';

const EmptyProductionLinesView = ({ title, infoRows, classes }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconContainer}>
        <EmptyProductionLinesLogo />
      </div>
      <div className={classes.titleRow}>
        {title}
      </div>
      {
        infoRows.map(({ keyId, info }) => {
          return (
            <div key={keyId} className={classes.infoRow}>
              {info}
            </div>
          );
        })
      }
    </div>
  );
};

EmptyProductionLinesView.propTypes = {
  title: PropTypes.string.isRequired,
  infoRows: PropTypes.arrayOf(
    PropTypes.shape({
      keyId: PropTypes.object.isRequired,
      info: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(EmptyProductionLinesView);
