import React from 'react';
import { connect } from 'react-redux';

import AutocompleteFilter from 'src/common_components/Filters/AutocompleteFilter/AutocompleteFilter';
import * as actions from './ducks/ParticipantSelect.actions';
import { prepareOptions } from './ParticipantSelect.utils';
import { Translate } from '../../../../common_components/Translate/Translate';

const ParticipantSelect = props => {
  const label = Translate('Наименование');

  return (
    <AutocompleteFilter
      prepareOptions={prepareOptions}
      otherProps={props}
      label={label}
    />
  );
};

const mapDispatch = dispatch => ({
  onInputChange: val => dispatch(actions.updated(val)),
});

export default connect(null, mapDispatch)(ParticipantSelect);
