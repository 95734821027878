import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 14px;
`;

export const Column = styled.div`
  width: 50%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
  padding-bottom: 55px;
  border-left: 1px solid rgba(196, 196, 196, 0.25);
`;

export const TwoColumns = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const CardHeading = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #212c42;
  margin-bottom: 40px;
`;

export const QRCodeTextWrapper = styled.div`
  margin-top: 7px;
  width: 50%;
`;

export const QRCodeWrapper = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  background-image: ${props => {
    if (props.productId)
      return `url('/products/images/${props.productId}.jpg')`;
    if (props.image) return `url(${props.image})`;
    return '';
  }};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
