import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import { Card } from '@crpt/react-card';
import { Icon } from '@crpt/react-icon';

import { LetterBoard } from './LetterBoard/LetterBoard';
// import { cardInformerTheme } from '../theme/ru-tabaco/card';
import { InformerService } from '../services/InformerService';
import { Translate } from '../common_components/Translate/Translate';

const InformerLine = styled.div`
  width: 100%;
  min-height: ${props => (props.large ? '134px' : '60px')};
  padding-right: 30px;
  padding-left: 48px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
  :last-child {
    border-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

const IconWrap = styled.div`
  width: 16px;
  margin-right: 32px;
`;

const ContentWrap = styled.div`
  display: flex;
  font-size: ${props => (props.inner ? '18px' : '23px')};
  align-items: center;
  font-weight: 300;
  color: #434244;
  margin-bottom: ${props => (props.margin ? '16px' : '')};
  min-height: 60px;
`;

const InnerLinesWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentLines = styled.div`
  margin-top: auto;
  width: 100%;
`;

const ContentLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const BoardsWrap = styled.div`
  display: flex;
`;

const BoardWrap = styled.div`
  margin-right: 16px;
  :last-child {
    margin-right: 0px;
  }
`;

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  min-height: ${props =>
    props.outland ? 'calc(100vh - 20px)' : 'calc(100vh - 130px)'};
  @media (min-width: 1900px) {
    display: none;
  }
`;

const WideWrap = styled.div`
  display: none;
  align-items: stretch;
  justify-content: stretch;
  min-height: ${props =>
    props.outland ? 'calc(100vh - 20px)' : 'calc(100vh - 130px)'};
  @media (min-width: 1900px) {
    display: flex;
  }
`;

const Part = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  :first-child {
    border-right: 1px solid #e8e8e8;
  }
`;

const WideLine = styled.div`
  min-height: 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  :last-child {
    border-bottom: 0;
  }
`;

function chunkString(str, length) {
  return str.match(new RegExp('.{1,' + length + '}', 'g'));
}

function prepareCount(count) {
  const rounded = Math.round(count);
  const str = '' + (rounded === rounded ? rounded : '0');
  const emptyCount = 15 - str.length;
  const result = chunkString(new Array(emptyCount).fill(' ').join('') + str, 3);
  return result;
}

export class Informer extends Component {
  static propTypes = {
    canSeeCntParticipants: PropTypes.bool,
  };

  interval;

  constructor(props) {
    super(props);

    this.state = {
      amount_applied: prepareCount(0),
      amount_in_work: prepareCount(0),
      amount_out: prepareCount(0),
      cnt_applied: prepareCount(0),
      cnt_emitted: prepareCount(0),
      cnt_in_work: prepareCount(0),
      cnt_out: prepareCount(0),
      cnt_participants: prepareCount(0),
      amount_excise: prepareCount(0),
      amount_excise_return: prepareCount(0),
    };

    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    this.updateValues();

    this.interval = setInterval(() => {
      this.updateValues();
    }, 5000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  updateValues() {
    InformerService.getInformersData().then(answer => {
      this.setState({
        amount_applied: prepareCount(answer.data.amount_applied),
        amount_in_work: prepareCount(answer.data.amount_in_work),
        amount_excise_return: prepareCount(answer.data.amount_excise_return),
        amount_excise: prepareCount(answer.data.amount_excise),
        amount_out: prepareCount(answer.data.amount_out),
        cnt_applied: prepareCount(answer.data.cnt_applied),
        cnt_emitted: prepareCount(answer.data.cnt_emitted),
        cnt_in_work: prepareCount(answer.data.cnt_in_work),
        cnt_out: prepareCount(answer.data.cnt_out),
        cnt_participants: prepareCount(answer.data.cnt_participants),
      });
    });
  }

  render() {
    return (
      <div>
        <WideWrap outland={this.props.outland}>
          <Part>
            {this.props.canSeeCntParticipants ? (
              <WideLine>
                <ContentWrap>
                  <IconWrap>
                    <Icon type={'human-2'} />
                  </IconWrap>
                  {Translate('Участники товарооборота')}
                </ContentWrap>

                <BoardsWrap>
                  <BoardWrap>
                    <LetterBoard value={this.state.cnt_participants[0]} />
                  </BoardWrap>
                  <BoardWrap>
                    <LetterBoard value={this.state.cnt_participants[1]} />
                  </BoardWrap>
                  <BoardWrap>
                    <LetterBoard value={this.state.cnt_participants[2]} />
                  </BoardWrap>
                  <BoardWrap>
                    <LetterBoard value={this.state.cnt_participants[3]} />
                  </BoardWrap>
                  <BoardWrap>
                    <LetterBoard value={this.state.cnt_participants[4]} />
                  </BoardWrap>
                </BoardsWrap>
              </WideLine>
            ) : null}
            <WideLine>
              <ContentWrap>
                <IconWrap>
                  <Icon type={'emitted'} />
                </IconWrap>
                {Translate('Выпущенные коды')}
              </ContentWrap>

              <BoardsWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_emitted[0]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_emitted[1]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_emitted[2]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_emitted[3]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_emitted[4]} />
                </BoardWrap>
              </BoardsWrap>
            </WideLine>

            <WideLine>
              <ContentWrap>
                <IconWrap>
                  <Icon type={'print-flame'} />
                </IconWrap>
                {Translate('Нанесенные коды')}
              </ContentWrap>

              <BoardsWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_applied[0]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_applied[1]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_applied[2]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_applied[3]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.cnt_applied[4]} />
                </BoardWrap>
              </BoardsWrap>
            </WideLine>

            <WideLine>
              <ContentWrap>
                <IconWrap>
                  <Icon type={'cube-3-d'} />
                </IconWrap>
                {Translate('Стоимость произведенного товара')}
              </ContentWrap>

              <BoardsWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.amount_applied[0]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.amount_applied[1]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.amount_applied[2]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.amount_applied[3]} />
                </BoardWrap>
                <BoardWrap>
                  <LetterBoard value={this.state.amount_applied[4]} />
                </BoardWrap>
              </BoardsWrap>
            </WideLine>

            <WideLine>
              <InnerLinesWrap>
                <ContentWrap margin marginTop>
                  <IconWrap>
                    <Icon type={'cylinder'} />
                  </IconWrap>
                  {Translate('Акциз')}
                </ContentWrap>

                <ContentLines>
                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Начисленный')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_excise[0]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_excise[1]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_excise[2]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_excise[3]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_excise[4]} />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>

                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Возврат')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard
                          value={this.state.amount_excise_return[0]}
                        />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard
                          value={this.state.amount_excise_return[1]}
                        />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard
                          value={this.state.amount_excise_return[2]}
                        />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard
                          value={this.state.amount_excise_return[3]}
                        />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard
                          value={this.state.amount_excise_return[4]}
                        />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>
                </ContentLines>
              </InnerLinesWrap>
            </WideLine>
          </Part>

          <Part>
            <WideLine>
              <InnerLinesWrap>
                <ContentWrap margin>
                  <IconWrap>
                    <Icon type={'turnover'} />
                  </IconWrap>
                  {Translate('В обороте')}
                </ContentWrap>

                <ContentLines>
                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Количество')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_in_work[0]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_in_work[1]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_in_work[2]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_in_work[3]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_in_work[4]} />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>

                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Стоимость')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_in_work[0]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_in_work[1]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_in_work[2]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_in_work[3]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_in_work[4]} />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>
                </ContentLines>
              </InnerLinesWrap>
            </WideLine>

            <WideLine>
              <InnerLinesWrap>
                <ContentWrap margin>
                  <IconWrap>
                    <Icon type={'cart'} />
                  </IconWrap>
                  {Translate('Продано в розницу')}
                </ContentWrap>

                <ContentLines>
                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Количество')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_out[0]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_out[1]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_out[2]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_out[3]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.cnt_out[4]} />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>

                  <ContentLine>
                    <ContentWrap inner>
                      <IconWrap />
                      {Translate('Стоимость')}
                    </ContentWrap>

                    <BoardsWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_out[0]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_out[1]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_out[2]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_out[3]} />
                      </BoardWrap>
                      <BoardWrap>
                        <LetterBoard value={this.state.amount_out[4]} />
                      </BoardWrap>
                    </BoardsWrap>
                  </ContentLine>
                </ContentLines>
              </InnerLinesWrap>
            </WideLine>
          </Part>
        </WideWrap>

        <MainWrap outland={this.props.outland}>
          <InformerLine>
            <ContentWrap>
              <IconWrap>
                <Icon type={'human-2'} />
              </IconWrap>
              {Translate('Участники товарооборота')}
            </ContentWrap>

            <BoardsWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_participants[0]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_participants[1]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_participants[2]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_participants[3]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_participants[4]} />
              </BoardWrap>
            </BoardsWrap>
          </InformerLine>

          <InformerLine>
            <ContentWrap>
              <IconWrap>
                <Icon type={'emitted'} />
              </IconWrap>
              {Translate('Выпущенные коды')}
            </ContentWrap>

            <BoardsWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_emitted[0]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_emitted[1]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_emitted[2]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_emitted[3]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_emitted[4]} />
              </BoardWrap>
            </BoardsWrap>
          </InformerLine>

          <InformerLine>
            <ContentWrap>
              <IconWrap>
                <Icon type={'print-flame'} />
              </IconWrap>
              {Translate('Нанесенные коды')}
            </ContentWrap>

            <BoardsWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_applied[0]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_applied[1]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_applied[2]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_applied[3]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.cnt_applied[4]} />
              </BoardWrap>
            </BoardsWrap>
          </InformerLine>

          <InformerLine>
            <ContentWrap>
              <IconWrap>
                <Icon type={'cube-3-d'} />
              </IconWrap>
              {Translate('Стоимость произведенного товара')}
            </ContentWrap>

            <BoardsWrap>
              <BoardWrap>
                <LetterBoard value={this.state.amount_applied[0]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.amount_applied[1]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.amount_applied[2]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.amount_applied[3]} />
              </BoardWrap>
              <BoardWrap>
                <LetterBoard value={this.state.amount_applied[4]} />
              </BoardWrap>
            </BoardsWrap>
          </InformerLine>

          <InformerLine large>
            <InnerLinesWrap>
              <ContentWrap margin marginTop>
                <IconWrap>
                  <Icon type={'cylinder'} />
                </IconWrap>
                {Translate('Акциз')}
              </ContentWrap>

              <ContentLines>
                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Начисленный')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>

                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Возврат')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise_return[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise_return[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise_return[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise_return[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_excise_return[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>
              </ContentLines>
            </InnerLinesWrap>
          </InformerLine>

          <InformerLine large>
            <InnerLinesWrap>
              <ContentWrap margin>
                <IconWrap>
                  <Icon type={'turnover'} />
                </IconWrap>
                {Translate('В обороте')}
              </ContentWrap>

              <ContentLines>
                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Количество')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_in_work[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_in_work[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_in_work[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_in_work[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_in_work[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>

                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Стоимость')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_in_work[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_in_work[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_in_work[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_in_work[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_in_work[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>
              </ContentLines>
            </InnerLinesWrap>
          </InformerLine>

          <InformerLine large>
            <InnerLinesWrap>
              <ContentWrap margin>
                <IconWrap>
                  <Icon type={'cart'} />
                </IconWrap>
                {Translate('Продано в розницу')}
              </ContentWrap>

              <ContentLines>
                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Количество')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_out[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_out[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_out[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_out[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.cnt_out[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>

                <ContentLine>
                  <ContentWrap inner>
                    <IconWrap />
                    {Translate('Стоимость')}
                  </ContentWrap>

                  <BoardsWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_out[0]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_out[1]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_out[2]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_out[3]} />
                    </BoardWrap>
                    <BoardWrap>
                      <LetterBoard value={this.state.amount_out[4]} />
                    </BoardWrap>
                  </BoardsWrap>
                </ContentLine>
              </ContentLines>
            </InnerLinesWrap>
          </InformerLine>
        </MainWrap>
      </div>
    );
  }
}
