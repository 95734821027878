import React, { useCallback, useState } from 'react';
import * as _ from 'lodash';
import GrayAreaDocumentView from './GrayAreaDocument.view';

export const GrayAreaDocument = props => {
  const [tab, setTab] = useState(0);

  const { match, history } = props;

  const documentId = decodeURIComponent(_.get(match, 'params.id', ''));
  const returnToGrayZone = useCallback(() => history.push('/gray-area'), []);

  return (
    <GrayAreaDocumentView
      history={history}
      selectedTab={tab}
      documentId={documentId}
      setTab={setTab}
      returnToGrayZone={returnToGrayZone}
    />  
  );
};

