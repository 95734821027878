import { handleActions } from 'redux-actions';

import * as unloadServiceDetailsActions from './UnloadServiceDetails.actions';

const data = handleActions(
  {
    [unloadServiceDetailsActions.mounted]: () => ({}),
    [unloadServiceDetailsActions.setData]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [unloadServiceDetailsActions.unMounted]: () => ({}),
  },
  {},
);

const loaded = handleActions(
  {
    [unloadServiceDetailsActions.mounted]: () => false,
    [unloadServiceDetailsActions.loaded]: () => true,
    [unloadServiceDetailsActions.unMounted]: () => false,
  },
  false,
);

export default {
  data,
  loaded,
};
