import React from 'react';
import styled from 'styled-components';
import { compose } from 'redux';

import { Icon } from '@crpt/react-icon';

import { withRouter } from 'react-router-dom';
import { StoreHelper } from '../../../utils/StoreHelper';
import { getIconTypeByProductPackageType } from '../../ProductDetails/utils';
import { producerIdChanged } from '../../../actions/ProductDetails';

const getColor = props => '#55b5e7';

const Wrap = styled.div`
  width: 100%;
  display: flex;
`;

const Link = styled.a`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: ${props => getColor(props)};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
  &:hover {
    color: ${props => getColor(props)};
    text-decoration: none;
  }
`;

const NoLink = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const IconWrap = styled.div`
  margin-right: 16px;
  width: 16px;
  min-width: 16px;
`;

const GTIN = props => {
  const product = props.options.gtin;
  const { packageType, id, gtin, producerId } = product._original;
  const { iconDisable } = props;

  return (
    <Wrap>
      {iconDisable ? null : (
        <IconWrap>
          {packageType && (
            <Icon type={getIconTypeByProductPackageType(packageType)} />
          )}
        </IconWrap>
      )}
      {id ? (
        <Link
          href={`/products/list/${id}/`}
          onClick={e => {
            e.preventDefault();
            props.history.push(`/products/list/${id}/`);
            producerId &&
              StoreHelper.getStore().dispatch(producerIdChanged(producerId));
          }}
        >
          {gtin}
        </Link>
      ) : (
        <NoLink>{gtin}</NoLink>
      )}
    </Wrap>
  );
};

export default compose(withRouter)(GTIN);
