import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import HeadedLabel from './HeadedLabel';
import { LeftColumn, Wrapper, ProductImage } from './styled';
import { Translate } from '../../../../common_components/Translate/Translate';

const UzProductItem = ({ product = {} }) => {
  const vat = get(product, 'vat', '-');
  const productName = get(product, 'productName', '-');
  const unitPrice = get(product, 'unitPrice', '-');
  const unitsPriceSum = get(product, 'unitsPriceSum', '-');
  const count = get(product, 'count', '-');
  const imgUrl = get(product, 'imgUrl', '-');

  return (
    <Wrapper>
      <LeftColumn>
        <HeadedLabel header={Translate('Наименование')} value={productName} />
        <HeadedLabel header={Translate('Цена')} value={unitPrice} />
        {/*<HeadedLabel header="Количество" value={count} />*/}
        {/*<HeadedLabel header="Сумма" value={Number(unitsPriceSum).toFixed(2)} />*/}
        <HeadedLabel header={Translate('НДС')} value={Number(vat).toFixed(2)} />
      </LeftColumn>
      <ProductImage url={imgUrl} />
    </Wrapper>
  );
};

UzProductItem.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string,
    vat: PropTypes.string,
    unitPrice: PropTypes.string,
  }),
};

export default UzProductItem;
