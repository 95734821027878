import React from 'react';

import { FormContext } from '../Form/Form.constants';

import { getDisplayName } from './utils';

const withForm = Component => {
  const WrappedComponent = props => (
    <FormContext.Consumer>
      {({ values, errors, mutators, reset, disabled }) => {
        return (
          <Component
            formValues={values}
            formErrors={errors}
            formMutators={mutators}
            formReset={reset}
            formDisabled={disabled}
            {...props}
          />
      )}}
    </FormContext.Consumer>
  );

  WrappedComponent.displayName = `WithForm(${getDisplayName(Component)})`;

  return WrappedComponent;
};

export default withForm;
