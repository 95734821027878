import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DownloadButton from '../../List/FilterForm/ActionBar/DownloadButton';
import { documentDownloadTheme } from '../../../theme/ru-tabaco/tableFilter/documentDownload';
import { Translate } from '../../../common_components/Translate/Translate';


const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  display: ${props => props.visible ? 'block' : 'none'};
  transform: translateY(-50%);
`;

export const TableCellWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const theme = { TableFilter: documentDownloadTheme };

class DownloadReport extends PureComponent {
  static propTypes = {
    onDownloadClick: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    hoveredId: PropTypes.string,
  };

  static defaultProps = {
    hoveredId: null,
  };

  popupMenu = [{
    title: Translate('Скачать XLS'),
    onClick: () => this.props.onDownloadClick('xlsx', this.props.id),
  }];

  render() {
    const { id, hoveredId } = this.props;

    return (
      <Wrapper visible={id === hoveredId}>
        <DownloadButton
          showDownloadListButton={true}
          popupMenu={this.popupMenu}
          theme={theme}
        />
      </Wrapper>
    );
  }
}

export default DownloadReport;
