import React from 'react';

export const SearchIcon = ({style}) => {
  return (
    <svg
      style={style}
      width="16"
      fill="#212C42"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12C4.243 12 2 9.757 2 7ZM15.708 14.294L12.601 11.187C13.476 10.018 14 8.572 14 7C14 3.134 10.866 0 7 0C3.134 0 0 3.134 0 7C0 10.866 3.134 14 7 14C8.572 14 10.018 13.476 11.187 12.601L14.294 15.708C14.488 15.902 14.745 16 15.001 16C15.258 16 15.514 15.902 15.708 15.708C16.098 15.319 16.098 14.683 15.708 14.294Z"
      />
    </svg>
  );
};
