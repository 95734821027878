import { takeEvery, put, take, call, race } from 'redux-saga/effects';
import { get, flattenDeep } from 'lodash';
import * as actions from './Goods.actions';
import {
  goodsKey,
  cisKey,
  disaggregatedKey,
} from '../../ImportThirdCountry.constants';
import Api from 'src/common_components/Api/Api';
import * as fileActions from 'src/common_components/File/File.actions';
import { Toast } from 'src/utils/Toast';
import { sortLevel2 } from 'src/utils/sort';
import { invalidCisKey } from '../../../NotificationOfEntry/NotificationOfEntry.constants';
import { buildProductAfterDisagregationUzKz } from '../../ImportThirdCountry.utils';

function* loadSaga(action) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn } = payload;

  const fileType = get(file, 'type');

  if (!['XLS', 'XLSX'].includes(fileType)) {
    yield put(actions.reset());
    Toast.showError({
      content: `Загружаемый файл не соответствует установленному формату. Загрузите файл формата *.xls, *xlsx`,
    });
    return;
  }

  const data = {
    userId: +userId,
    organisationInn: inn,
  };

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(data)], { type: 'application/json' }),
  );

  formData.append(
    'file',
    new Blob([file.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    file.name,
  );

  const requestPayload = {
    url: '/api/v3/facade/marked_products/search-by-xls-cis-list',
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
        console.log('error', error);
      } else {
        const data = get(success, 'data.valid.results', []);
        const invalidData = get(success, 'data.invalid', []);

        formMutators.updateField(cisKey, data);
        formMutators.updateField(invalidCisKey, invalidData);
        yield call(onCloseModal);
        yield put(actions.reset());
      }
    } catch (e) {
      yield put(actions.reset());
      Toast.showError({
        content: `Ответ от сервера не соответствует ожидаемому формату.`,
      });
      console.log(e, { e });
    }
  }
  if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false);
    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
    console.log('error /api/v3/facade/marked_products/search-by-xls-cis-list', {
      error,
    });
  }
}

function* loadCisSaga(action) {
  const { payload } = action;
  const { file, onCloseModal, formMutators, userId, inn } = payload;
  yield put(
    fileActions.read(file, { subject: actions.openFile, rawWithText: true }),
  );

  const fileLoaderData = yield race({
    success: take(`${fileActions.success} ${actions.openFile}`),
    error: take(`${fileActions.error} ${actions.openFile}`),
  });
  yield put(actions.load());
  const fileData = get(fileLoaderData, 'success.payload', {
    content: null,
    data: null,
  });

  if (fileData) {
    yield put(actions.convert({ ...payload, file: fileData }));
  } else {
    yield put(actions.error('Недопустимый формат файла!'));
  }
}

export function* disaggregationSaga(action) {
  const { payload } = action;
  const { formMutators, productsCis, productsGoods, formValues } = payload;
  const { products: oldProductsList } = formValues;
  const [success, error] = yield call(Api.request, {
    url: '/api/v3/facade/cis/disaggregated_query',
    method: 'post',
    data: JSON.stringify({ cises : productsCis }),
  });

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
        console.log('error', error);
      } else {
        const disaggregated = get(success, 'data', {});
        let products = formValues[goodsKey] || [];

        const kmList = flattenDeep(
          Object.values(disaggregated.gtinGrouped).map(items =>
            items.map(item => item.cis),
          ),
        );

        if (kmList.length === 0) throw new Error();

        const [successCis] = yield call(Api.request, {
          url: `/api/v3/facade/cis/cis_list`,
          method: 'post',
          data: productsCis,
        });
        const [successGtins] = yield call(Api.request, {
          url: `/api/v3/facade/product/search-by-gtins`,
          method: 'post',
          data: Object.keys(disaggregated.gtinGrouped),
        });

        if (successCis) {
          let infoList = get(successCis, 'data', {});
          infoList = Object.values(infoList);

          let newProductList = buildProductAfterDisagregationUzKz(
            infoList,
            disaggregated,
            products,
            successGtins.data.results
          );

          sortLevel2('tnved', 'name', newProductList);

          formMutators.updateField(goodsKey, newProductList);
          formMutators.updateField(disaggregatedKey, disaggregated);
        } else throw new Error();
      }
    } catch (e) {
      console.log('e',e)
      Toast.showError({
        content: `Ошибка группировки товаров.`,
      });
    }
  }
  if (error) {
    const errorMessage = get(error, 'response.data.error_message', false);
    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }
    console.log('disaggregation', { error });
  }
}

export default function* watch() {
  yield takeEvery(actions.convert, loadSaga);
  yield takeEvery(actions.convertCis, loadCisSaga);
  yield takeEvery(actions.disaggregation, disaggregationSaga);
}
