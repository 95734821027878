import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import * as cadesActions from '../../../common_components/Cades/Cades.actions';
import * as cadesSelectors from '../../../common_components/Cades/Cades.selectors';
import * as grayAreaActions from '../ducks/GrayArea.actions';
import { GrayAreaSignatureView } from './GrayAreaSignature.view';

const GrayAreaSignatureComponent = ({
  calledFrom = 'document',
  certData,
  certLoaded,
  clear,
  documentInfo,
  loadCert,
  onClose,
  signAndSendDocument,
  initialParams,
  downloadGrayAreaReport,
  id
}) => {

  useEffect(() => {
    loadCert();
    return () => clear();
  }, []);

  const reloadCert = useCallback(() => {
    loadCert();
  }, []);

  const signDocument = useCallback(() => {
    signAndSendDocument({id, onEndCb: onClose, certData, calledFrom, initialParams});
  }, [certData]);

  const onDownload = useCallback(() => downloadGrayAreaReport(documentInfo.reportLink), [documentInfo]);

  return (
    <GrayAreaSignatureView
      certData={certData}
      loaded={certLoaded}
      onClose={onClose}
      reloadCert={reloadCert}
      signDocument={signDocument}
      onDownload={onDownload}
    />
  );
};

const mapStateToProps = state => ({
  certData: cadesSelectors.certBySerial(state),
  certLoaded: cadesSelectors.loadedCertBySerial(state),
});

const mapDispatchToProps = {
  clear: cadesActions.clearCert,
  loadCert: cadesActions.loadCert,
  signAndSendDocument: grayAreaActions.signAndSend
};

export const GrayAreaSignature = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrayAreaSignatureComponent);
