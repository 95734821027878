import React, { useMemo, useState } from 'react';
import { DocumentWrap } from './CisRegistration.styles';
import ContentWrapper from '../../../../common_components/styled/ContentWrapper';
import Breadcrumbs from '../../../../common_components/Breadcrumbs/Breadcrumbs';
import { breadcrumbs, steps, tabs } from './CisRegistration.constants';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { DividerOfContent, StepWrapper } from '../Document/Document.styled';
import { CisRegistrationInfo } from './components/CisRegistrationInfo';
import { contentBodyConfirm } from './CisRegistration.constants';
import { Translate } from '../../../../common_components/Translate/Translate';
import ConfirmModal from '../../../../common_components/Modals/ConfirmModal/ConfirmModal';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import CommandBar from '../../../../common_components/CommandBar/CommandBar';
import { CisRegistrationProducts } from './components/CisRegistrationProducts';

export const CisRegistrationView = ({ openLoadCisListModal, onCancel, onSaveDraft, onSubmit, cisList }) => {
  const [step, setStep] = useState(steps.info);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSecondStepInvalid = useMemo(() =>
    step === steps.cis && cisList.length === 0,
    [step, cisList]);

  const currentTabs = useMemo(() => {
    return tabs.map((tab) => ({
      ...tab,
      onClick: () => setStep(tab.id),
    }))
  }, [isSecondStepInvalid]);

  const onNextStep = () => {
    if(step === steps.info) {
      setStep(steps.cis)
    } else {
      onSubmit();
    }
  };

  return (
    <React.Fragment>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <ContentWrapper withoutPadding >
        <ConfirmModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={onCancel}
          content={contentBodyConfirm}
          header={Translate('Предупреждение')}
          cancelButtonText={Translate('Нет')}
          submitButtonText={Translate('Да')}
          notCancelable
        />
        <StepWrapper>
          <Steps
            value={step}
            items={currentTabs}
            variant={'fullWidth'}
          />
        </StepWrapper>
        <DocumentWrap withoutPadding={step === steps.cis}>
          <div>
            {step === steps.info && <CisRegistrationInfo />}
            {step === steps.cis && <CisRegistrationProducts openLoadCisListModal={openLoadCisListModal} cisList={cisList}/>}
          </div>
          <div>
            <DividerOfContent />
            <div style={step === steps.cis ? { padding: '0 34px'} : {}}>
              <CommandBar
                left={[
                  <CommonActionButton disabled={false} onClick={() => setIsModalOpen(true)}>
                    {Translate('Отменить')}
                  </CommonActionButton>,
                  <CommonActionButton
                    onClick={onSaveDraft}
                    disabled={false}
                  >
                    {Translate('Сохранить как черновик')}
                  </CommonActionButton>
                ]}
                right={[
                  <PrimaryActionButton
                    onClick={onNextStep}
                    disabled={isSecondStepInvalid}
                  >
                    {step === steps.info ? Translate('Следующий шаг') : Translate('Подписать и отправить')}
                  </PrimaryActionButton>,
                ]}
              />
            </div>
          </div>
        </DocumentWrap>
      </ContentWrapper>
    </React.Fragment>
  )
};
