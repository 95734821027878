import { CadesUtil } from '../utils/CadesUtil';
import { actionTypes } from '../constants';
import { RegistrationService } from '../services/RegistrationService';
import { HistoryHelper } from '../utils/HistoryHelper';
import { GetCertificates } from './LoginFormKep/GetCertificates';
import { Toast } from '../utils/Toast';

const certificatesLoaded = certs => ({
  type: actionTypes.REGISTRATION.CERTS_LOADED,
  value: certs,
});

const registrationMounted = () => {
  return async dispatch => {
    console.log('ta-da, mounted reg');
    let certificates = [];
    const notifyId = Toast.showInfo({
      content: 'Идет загрузка сертификатов...',
      autoClose: false,
    });

    try {
      const cadesCertificates = await GetCertificates.getCadesCertificates();
      certificates = certificates.concat(cadesCertificates);
    } catch (e) {
      console.log('Cannot get cades certificates:', e);
    }

    try {
      const rutokenCertificates = await GetCertificates.getRutokenCertificates();
      certificates = certificates.concat(rutokenCertificates);
    } catch (e) {
      console.log('Cannot get rutoken certificates:', e);
    }

    try {
      const jacartaCertificates = await GetCertificates.getJacartaCertificates();
      certificates = certificates.concat(jacartaCertificates);
    } catch (e) {
      console.log('Cannot get jacarta certificates:', e);
    }

    try {
      const jacarta2Certificates = await GetCertificates.getJacarta2Certificates();
      certificates = certificates.concat(jacarta2Certificates);
    } catch (e) {
      console.log('Cannot get jacarta2 certificates:', e);
    }

    Toast.closeToast(notifyId);
    Toast.showSuccess({ content: 'Загрузка сертификатов завершена.' });

    console.log('certificates', certificates);
    dispatch(certificatesLoaded([...certificates]));
  };
};

const registerSuccess = () => ({
  type: actionTypes.REGISTRATION.REGISTER_SUCCESSFULL,
});

const registerError = data => ({
  type: actionTypes.REGISTRATION.REGISTER_ERROR,
  data,
});

const onRegisterClick = values => dispatch => {
  RegistrationService.registerParticipant(values)
    .then(answer => {
      console.log('register answer', answer);
      dispatch(registerSuccess());
      HistoryHelper.getHistory().push('register/thank-you');
    })
    .catch(err => {
      console.log('register error', err);
      dispatch(registerError(err));
    });
};

const toggleCert = data => ({
  type: actionTypes.REGISTRATION.TOGGLE_CERT,
  data,
});

const toggleCertEdit = data => {
  return {
    type: actionTypes.REGISTRATION.TOGGLE_CERT_EDIT,
    data,
  };
};

const toggleContactsEdit = data => {
  return {
    type: actionTypes.REGISTRATION.TOGGLE_CONTACTS_EDIT,
    data,
  };
};

const toggleContacts = data => ({
  type: actionTypes.REGISTRATION.TOGGLE_CONTACTS,
  data,
});

const toggleData = data => ({
  type: actionTypes.REGISTRATION.TOGGLE_DATA,
  data,
});

const showContacts = () => ({
  type: actionTypes.REGISTRATION.SHOW_CONTACTS,
});

const showData = () => ({
  type: actionTypes.REGISTRATION.SHOW_DATA,
});

export {
  registrationMounted,
  certificatesLoaded,
  onRegisterClick,
  toggleCert,
  toggleCertEdit,
  toggleContacts,
  toggleData,
  showContacts,
  showData,
  toggleContactsEdit,
};
