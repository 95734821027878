import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { tabsKZ, } from './DocumentWrapperConstants';
import { PageWrapper, StepWrapper } from '../Document/Document.styled';
import { Toast } from 'src/utils/Toast';
import withModal from 'src/common_components/Modals/hocs/withModal';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import List from 'src/common_components/List/List';
import { Translate } from 'src/common_components/Translate/Translate';
import { Preloader } from '../../../../common_components/Preloader/Preloader';
import { ButtonWrap } from '../../../../components/DocumentDetails/Act/Showing.styled';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { Action } from '@crpt-ui/icons';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// заменить на импорт в компоненте, в котором будет использваться wrapper
import {cisKey, contentBodyConfirm, group, steps, } from '../ActDocument/ActDocument.constants';
import * as actions from '../ActDocument/ducks/ActDocument.actions';
import * as selectors from '../ActDocument/ducks/ActDocument.selectors';
import ReconciliationDialog from '../ActDocument/Reconciliation/ReconciliationDialog';
import { columns } from '../ActDocument/Total/Total.const';
import * as actActions from '../ActDocument/ducks/ActDocument.actions';
import { submit } from '../ActDocument/ducks/ActDocument.actions';
import { ExistReconciliationDraftDialog } from '../ActDocument/Reconciliation/components/ExistReconciliationDraftDialog';
import * as reconciliationSelectors from '../ActDocument/Reconciliation/ducks/Reconciliation.selectors';
import * as reconciliationActions from '../ActDocument/Reconciliation/ducks/Reconciliation.actions';
import FinalConfirmModal from './FinalConfirmModal';


const Total = (props) => {
  const { show, status, isCorrection, goodsChanged, isFixed, direction, isDraft } = props;
  const { recount, formValues, data, id, reconciliationDraft, onSaveDraft } = props;
  const { reject, send, onSetStep, submit, submitWaiting, mode, history } = props;
  const { getReconciliationDraft, accept, finalConfirmModal, title } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showReconciliationDraftDialog, setShowReconciliationDraftDialog] = useState(false);
  const [showReconciliationDialog, setShowReconciliationDialog] = useState(false);
  const [dataForReconciliation, setDataForReconciliation] = useState([]);

  const handleRecount = () => {
    const parentId = Object.values(data.data.links).includes(1100)
      ? Object.keys(data.data.links).find(el => data.data.links[el] === 1000)
      : null;

    recount({
      ...data.data,
      ...parentId ? { parent_id: parentId } : {},
      content: {
        ...data.data.content,
        doc_id: id,
        cises: formValues.km.map(elKm => ({
          code: elKm.cis,
          count: 0,
        })),
        products: [
          {
            gtin: '00000000000000',
            count: 0,
          },
        ],
      },
    });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSaveDraft = () => {
    onSaveDraft(formValues);
  };

  const toggleDialog = () => setIsModalOpen(!isModalOpen);

  const onNextStep = () => {
    submit(formValues);
  };

  const onPreviousStep = () => {
    onSetStep(steps.goods);
  };

  const isNextStepDisabled = () => {
    if (!show || submitWaiting) return true;
    const cis = getCis();
    return isEmpty(cis);
  };

  const tabsCurrent = () => {
    if (!show) return tabsKZ;
    return tabsKZ.map(tab =>
      tab.id === steps.cis
        ? tab
        : {
          ...tab,
          onClick: () => {
            onSetStep(tab.page);
          },
        },
    );
  };

  const getCis = () => {
    const cis = formValues[mode === 'editedAct' ? group : cisKey] || [];
    return goodsChanged && status === 19 ? [] : cis;
  };

  const preparedCis = cis => {
    const page = activePage - 1;
    return cis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };

  const onCancel = () => {
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);
    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSend = () => {
    send(id);
  };

  const startReconciliation = () => {
    getReconciliationDraft({
      id,
      onFinish: isExist => {
        if (isExist) {
          onReconciliationDraftToggle();
        } else {
          newReconciliation();
        }
      },
    });
  };

  const onReconciliationToggle = () => {
    setShowReconciliationDialog(!showReconciliationDialog);
  };

  const onReconciliationDraftToggle = () => {
    setShowReconciliationDraftDialog(!showReconciliationDraftDialog);
  };

  const continueReconciliation = () => {
    setShowReconciliationDraftDialog(false);
    setDataForReconciliation(reconciliationDraft);
    onReconciliationDraftToggle();
    onReconciliationToggle();
  };

  const newReconciliation = () => {
    setShowReconciliationDraftDialog(false);
    setShowReconciliationDialog(false);
    setDataForReconciliation(data.km);
    onReconciliationToggle();
  };

  const getColumns = () => {
    return [
      ...columns,
    ];
  };

  const approveAct = () => {
    handleClose();
    accept({ id, decision: 'approve' });
  };

  const rejectAct = () => {
    handleClose();
    reject({ id, decision: 'reject' });
  };

  const handleReconciliation = () => {
    startReconciliation();
    handleClose();
  };

  const onOpenFinalConfirmModal = () => {
    onSetContent(
      <FinalConfirmModal
        title={title}
        onCloseModal={title}
        goodsData={cis}
        goodsColumns={preparedColumns}
        onSubmit={handleSend}
      />
    )
    onOpenModal();
  }

  const cis = getCis();
  const preparedColumns = getColumns();
  const ownerInn = get(data, 'data.content.buyer.inn');
  const supplierInn = get(data, 'data.content.seller.inn');

  return (
    <PageWrapper hide={!show}>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onClose={toggleDialog}
        onSubmit={onCancel}
        content={contentBodyConfirm}
        header={Translate('Предупреждение')}
        cancelButtonText={Translate('Нет')}
        submitButtonText={Translate('Да')}
        notCancelable
      />
      <ReconciliationDialog
        onClose={onReconciliationToggle}
        open={showReconciliationDialog}
        showingData={dataForReconciliation}
        id={id}
        supplierInn={supplierInn}
        ownerInn={ownerInn}
      />
      <ExistReconciliationDraftDialog
        onClose={onReconciliationDraftToggle}
        continueReconciliation={continueReconciliation}
        newReconciliation={newReconciliation}
        open={showReconciliationDraftDialog}
      />
      <ContentWrapper>
        <StepWrapper>
          <Steps
            value={2}
            items={tabsCurrent()}
            variant={'fullWidth'}
          />
        </StepWrapper>
        {status === 18
          ? (<Preloader isOpen={true} text="Выполняется расчет итогов"/>)
          : (
            <React.Fragment>
              <List
                defaultPageSize={10}
                columns={preparedColumns}
                data={cis}
                noDataText=""
                showPagination={cis.length > 10}
              />
              {[3, 13].includes(status) && direction === 'act-in' && (
                <ButtonWrap>
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    startIcon={<Action/>}
                  >
                    {Translate("Действия")}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    autoFocus={false}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  >
                    <MenuItem onClick={approveAct}>{Translate("Принять документ")}</MenuItem>
                    <MenuItem onClick={rejectAct}>{Translate("Отклонить документ")}</MenuItem>
                    {!isFixed && (
                      <MenuItem onClick={handleReconciliation}>{Translate("Выполнить сверку")}</MenuItem>
                    )}
                  </Menu>
                </ButtonWrap>
              )}
              <CommandBar
                left={status === null || isDraft
                  ? [
                    <CommonActionButton onClick={toggleDialog}>
                      {Translate('Отменить')}
                    </CommonActionButton>,
                    <CommonActionButton onClick={handleSaveDraft}>
                      {Translate('Сохранить как черновик')}
                    </CommonActionButton>,
                  ]
                  : isCorrection
                    ? [
                      <CommonActionButton onClick={toggleDialog}>
                        {Translate('Отменить')}
                      </CommonActionButton>,
                    ]
                    : []
                }
                style={{
                  padding: '37px 40px',
                }}
                right={status === null || (status === undefined && isCorrection) || isDraft
                  ? [(
                    <PrimaryActionLargeButton
                      disabled={false}
                      onClick={onNextStep}
                    >
                      {Translate('Рассчитать итоги')}
                    </PrimaryActionLargeButton>
                  )]
                  : status === 19
                    ? [(
                      <PrimaryActionLargeButton
                        disabled={false}
                        onClick={handleRecount}
                      >
                        {Translate('Рассчитать итоги')}
                      </PrimaryActionLargeButton>
                    ), (
                      <PrimaryActionLargeButton
                        onClick={finalConfirmModal ? onOpenFinalConfirmModal : handleSend}
                        disabled={goodsChanged}
                      >
                        {Translate('Отправить')}
                      </PrimaryActionLargeButton>
                    )]
                    : []
                }
              />
            </React.Fragment>
          )
        }
      </ContentWrapper>
    </PageWrapper>
  );
};

Total.propTypes = {
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    mode: PropTypes.string,
    direction: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.number,
    isFixed: PropTypes.bool,
    isDraft: PropTypes.bool,
    history: PropTypes.shape({}).isRequired,
}

const mapState = state => ({
  submitWaiting: selectors.submitWaiting(state),
  reconciliationDraft: reconciliationSelectors.draft(state),
});

const mapDispatch = dispatch => ({
  submit: values => dispatch(submit(values)),
  send: data => dispatch(actions.send(data)),
  recount: data => dispatch(actActions.recount(data)),
  accept: data => dispatch(actActions.acceptDocument(data)),
  reject: data => dispatch(actActions.rejectDocument(data)),
  getReconciliationDraft: data => dispatch(reconciliationActions.getReconciliationDraft(data)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(Total);
