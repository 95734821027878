import { takeEvery, put, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import { Toast } from 'src/utils/Toast';
import { replace } from 'react-router-redux';
import * as actions from './CisRegistration.actions';
import * as requestListActions from '../../ducks/RequestList.actions';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import Api from 'src/common_components/Api/Api';
import * as draftSelectors from '../../Draft/ducks/Draft.selectors';
import { Base64 } from '@crpt-ui/crypto';
import { signUZSaga } from '../../../../../common_components/Eimzo/Eimzo.saga';

function* mountSaga(action) {
  const { payload } = action;
  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id: payload },
  };
  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
        __form_document_status: success.data.status,
      }),
    );
    yield put(actions.setError(success.data.downloadDesc));
  }
  if (error)
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
}

function* submitSaga(action) {
  const { payload } = action;

  const url = '/api/v3/facade/admit-application';

  const document = {
    "productGroup": 'pharma',
    "emissionCountry": 'ru',
    "applicantInn": payload.values.inn,
    "applicantName": payload.values.name,
    cisList: payload.values.cisList.map((item) => item.cis),
  };

  const signature = yield call(signUZSaga, {
    payload: { data: document, file: false },
  });

  const base64_data = Base64.encode(JSON.stringify(document));

  if (!signature) {
    yield put(actions.reset());
    Toast.showInfo({
      content: 'Документ не подписан.',
      autoClose: 5000,
    });
    return;
  }

  let data = {
    ...signature,
    document: base64_data,
  };

  const locationHref = window.location.href;
  const drafttId = yield select(draftSelectors.getIdDocument);

  if (drafttId || /draft-/.test(locationHref)) {
    data = {
      ...data,
      draft_id:
        drafttId ||
        locationHref.replace(/.+draft-(.+).*/, '$1').replace(/\?.*$/, ''),
    };
  }

  const requestPayload = {
    url,
    method: 'post',
    data,
  };

  const [success, error] = yield call(Api.request, requestPayload, {
    artificialDelay: 5000,
  });

  if (success) {
    const error = get(success, 'data.errors.0', false);
    if (error) {
      Toast.showError({ content: error });
    } else {
      Toast.showSuccess({
        content: 'Заявка на регистрацию кодов ИС МДЛП',
      });
      yield put(listActions.mounted(undefined, requestListActions));
      yield put(replace('/documents/import-uz'));
    }
  }
  if (error) {
    yield put(actions.reset());
    yield call(Toast.showError, {
      content: get(error, 'responsecertificate.data.message', 'error'),
    });
  }
}

function* loadSaga(action) {
  const { payload } = action;
  const { file, onCloseModal } = payload;

  const fileType = get(file, 'type');

  if (!['CSV'].includes(fileType)) {
    yield put(actions.reset());
    Toast.showError({
      content: `Загружаемый файл не соответствует установленному формату. Загрузите файл формата *.csv`,
    });
    return;
  }

  const formData = new FormData();
  formData.append(
    'file',
    new Blob([file.data], {
      type: 'text/csv',
    }),
    file.name,
  );

  const requestPayload = {
    url: '/api/v3/facade/marked_products/validate-by-csv-sgtin-list',
    method: 'post',
    type: 'multipart',
    data: formData,
    preloading: false
  };

  // //далее надо получить список кодов

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    try {
      const error = get(success, 'data.errors.0', false);

      if (error) {
        yield put(actions.error(error));
      } else {
        const data = success.data.kmList.map((cis) => ({ cis }));

        yield put(actions.setCisList(data));
        yield call(onCloseModal);
        yield put(actions.reset());
      }
    } catch (e) {
      yield put(actions.reset());
      Toast.showError({
        content: `Ответ от сервера не соответствует ожидаемому формату.`,
      });
      console.log(e, { e });
    }
  }
  if (error) {
    yield put(actions.reset());
    const errorMessage = get(error, 'response.data.error_message', false)
      || get(error, 'response.data.message', false);

    if (errorMessage) {
      Toast.showError({
        content: errorMessage,
      });
    }

    yield call(onCloseModal);
    yield put(actions.reset());
  }
}

function* loadCisSaga(action, state) {
  // const { payload } = action;
  // const { file, onCloseModal, formMutators, userId, inn } = payload;
  // yield put(
  //   fileActions.read(file, { subject: actions.openFile, rawWithText: true }),
  // );
  //
  // const fileLoaderData = yield race({
  //   success: take(`${fileActions.success} ${actions.openFile}`),
  //   error: take(`${fileActions.error} ${actions.openFile}`),
  // });
  // yield put(actions.load());
  // const fileData = get(fileLoaderData, 'success.payload', {
  //   content: null,
  //   data: null,
  // });
  //
  // if (fileData) {
  //   yield put(actions.convert({ ...payload, file: fileData }));
  // } else {
  //   yield put(actions.error('Недопустимый формат файла!'));
  // }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.submit, submitSaga);
  yield takeEvery(actions.convert, loadSaga);
  yield takeEvery(actions.convertCis, loadCisSaga);
}
