import { actionTypes } from '../../../constants';


export function selectedPage(state = 0, action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_LOADED:
      return action.value.offset / action.value.limit + 1;
    default:
      return state;
  }
}
