const NCALAYER_METHODS = {
  createCMS: 'createCMSSignatureFromBase64',
  getKeyInfo: 'getKeyInfo',
};

const NCALAYER_KEY_TYPES = {
  auth: 'AUTHENTICATION',
  sign: 'SIGNATURE',
};

export class NCALayerConfigBuilder {
  constructor() {
    this.method = NCALAYER_METHODS.createCMS;
    this.keyType = NCALAYER_KEY_TYPES.auth;
    this.attached = false;
  }

  useKeyInfoMethod() {
    this.method = NCALAYER_METHODS.getKeyInfo;
    return this;
  }

  useSignKeyType() {
    this.keyType = NCALAYER_KEY_TYPES.sign;
    return this;
  }

  attachSignData() {
    this.attached = true;
    return this;
  }

  configure() {
    return {
      keyType: this.keyType,
      method: this.method,
      attached: this.attached,
    };
  }
}
