import { MenuButton } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import * as Icons from '@crpt-ui/icons';
import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import EmptyList from '../../../../common_components/EmptyList/EmptyList';
import { availableEnum, periodicityEnum } from '../../constants';
import { numberSort, rangeDateFilter } from '../../_utils/utils';
import { useStyles } from '../UnloadServiceDetails.styles';
import { useColumns } from './Unload.columns';

const Unload = ({
  codes,
  downloadingStorageDays,
  onChangeResults,
  onDownloadResults,
  periodicity,
  readonly,
  task_id,
}) => {
  const instance = useRef();
  const classes = useStyles();
  const columns = useColumns();

  const [selectedRows, setSelectedRows] = useState({});

  const makeCallback = action => (...rows) => {
    switchAction({
      action: action,
      result_ids: rows.map(row => row.original.id),
    });
  };

  const isRowNotAvailable = useCallback(row => row.original.available === availableEnum.NOT_AVAILABLE, []);
  const isRowNotEditable = useCallback(row => row.original.notEditable || false, []);

  const isActionDisabled = useCallback(row => {
    const arrayOfConditions = [isRowNotAvailable(row), readonly, isRowNotEditable(row)]
    return arrayOfConditions.some(item => item === true)
  }, [isRowNotAvailable, readonly, isRowNotEditable]);

  const actions = useMemo(
    () => [
      {
        label: 'Скачать',
        callback: makeCallback('download'),
        disabled: isRowNotAvailable,
      },
      {
        label: 'Продлить',
        callback: makeCallback('extend'),
        disabled: isActionDisabled,
      },
      {
        label: 'Удалить',
        callback: makeCallback('delete'),
        disabled: isActionDisabled,
      },
    ],
    [readonly],
  );

  const bulkActions = useMemo(() => {
    const selectedFlatRows = _.get(instance, 'current.selectedFlatRows', []);

    return actions.map(action => ({
      onClick: () => action.callback(...selectedFlatRows),
      disabled: selectedFlatRows.some(action.disabled),
      label: action.label,
    }));
  }, [actions, selectedRows]);

  const bulkActionsDisabled = useMemo(() => _.keys(selectedRows).length === 0, [
    selectedRows,
  ]);

  const switchAction = useCallback(
    ({ action, result_ids }) => {
      switch (action) {
        case 'download':
          onDownloadResults(result_ids);
          break;
        case 'delete':
          onChangeResults({
            task_id,
            result_ids,
            requestBody: {
              method: 'DELETE',
              params: {
                delete_date: moment()
                  .add(1, 'hours')
                  .toISOString(),
              },
            },
          });
          break;
        case 'extend':
          onChangeResults({
            task_id,
            result_ids,
            requestBody: {
              method: 'PUT',
              data: {
                fileDeleteDate: moment()
                  .add(downloadingStorageDays, 'days')
                  .toISOString(),
              },
            },
          });
          break;
      }
    },
    [onDownloadResults, onChangeResults, task_id],
  );

  useEffect(() => {
    if (instance.current && periodicity === periodicityEnum.SINGLE) {
      instance.current.setHiddenColumns(['dataStartDate']);
    }
  }, [instance.current, periodicity]);

  return (
    <div className={classes.unloadWrapper}>
      {codes.length ? (
        <React.Fragment>
          <Box my={2} mx={4}>
            <MenuButton
              width={200}
              disabled={bulkActionsDisabled}
              items={bulkActions}
              variant="outlined"
            >
              Действие
            </MenuButton>
          </Box>
          <Datatable
            actions={actions}
            ref={instance}
            columns={columns}
            data={codes}
            useFilters
            manualFilters={false}
            usePagination
            manualPagination={false}
            useRowSelect
            useSort
            onRowSelect={setSelectedRows}
            initialState={{
              pageSize: 10,
            }}
            filterTypes={{
              rangeDateFilter,
            }}
            sortTypes={{
              numberSort,
            }}
          />
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <div className={classes.amountWrapper}>
                Всего выгрузок: {codes.length}
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <EmptyList icon={<Icons.UnloadList />}>Список выгрузок пуст</EmptyList>
      )}
    </div>
  );
};

export default Unload;
