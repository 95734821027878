import { saveAs } from 'file-saver';
import { CISService } from '../services/CISService';
import { getListActions } from '../utils/actionGenerators';
import { getLimitOffsetFormatter } from '../utils/pageFormatters';

import CISList from '../projects/motp/lists/CISList';
import {
  defaultFilterParamSerializer,
  defaultRequestParamSerializer,
  fromURLMountFilterParamsSerializer,
  fromURLPageSerializer,
} from '../utils/filterSerializers';
import {
  extendURLByKeyValueList,
  modifyURLByRawDataAndFiltersConfig,
} from '../utils/formEvents';

import { Toast } from '../utils/Toast';
import { history } from '../store';
import { StoreHelper } from '../utils/StoreHelper';
import { actionTypes, PAGINATED_LIST_CONSTANTS } from '../constants';
import {ProductService} from '../services/ProductService';

const onFilterLoad = data => {
  const filterParams = StoreHelper.getState()[CISList.selectorNamespace]
    .filterParams;

  if (filterParams.cisNumber && 1 === data.results.length) {
    const { cis } = data.results[0];
    history.push(`/cis/list/${encodeURIComponent(cis)}/`);
    StoreHelper.getStore().dispatch({
      type:
        actionTypes[CISList.actionNamespace][
          PAGINATED_LIST_CONSTANTS.CLEAR_FILTER_PARAMS
        ],
    });
  }
};

const downloadListReport = (type, data) => dispatch => {
  const instance = CISService.getListReport(type, data);

  const toastId = Toast.showCancelable({
    content: 'Идет сохранение файла...',
    onCancel: e => {
      CISService._getListReportCancel && CISService._getListReportCancel();
      Toast.closeToast(toastId);
    },
  });

  instance
    .then(answer => {
      Toast.closeToast(toastId);
      if (!answer) return;
      Toast.showSuccess({ content: 'Отчет успешно сохранен.' });
      saveAs(answer.data, `report.${type}`);
    })
    .catch(err => {
      if (!err.__CANCEL__)
        Toast.showError({ content: 'Не удалось сохранить отчет.' });
      console.log('download list error: ', { err: err });
    });
};

export default {
  ...getListActions({
    actionNamespace: CISList.actionNamespace,
    selectorNamespace: CISList.selectorNamespace,
    getCancelListRequest: () => CISService._getListCancel,
    getList: CISService.getList,
    pageFormatter: getLimitOffsetFormatter(CISList.limit),
    pageSerializer: fromURLPageSerializer,
    filterApplyFilterParamsSerializer: defaultFilterParamSerializer,
    filterApplyRequestParamsSerializer: defaultRequestParamSerializer,
    listMountFilterParamsSerializer: fromURLMountFilterParamsSerializer,
    listMountRequestParamsSerializer: fromURLMountFilterParamsSerializer,
    onFilterApplyWithoutSerializing: data =>
      modifyURLByRawDataAndFiltersConfig(
        Object.assign(data, { page: [1] }),
        CISList.filters
      ),
    onPageSelect: pageNumber => {
      extendURLByKeyValueList({ page: pageNumber });
    },
    onFilterLoad: onFilterLoad,
  }),
  downloadListReport,
};
