import { createAction } from 'redux-actions';

export const subject = '[OutcomeList]';
export const mounted = createAction(`${subject} mounted`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const updatePage = createAction(`${subject} update page`);
export const updateOffset = createAction(`${subject} update offset`);
export const getRequest = createAction(`${subject} get request`);
export const pagination = createAction(`${subject} pagination`);
export const filters = createAction(`${subject} filters`);
export const sort = createAction(`${subject} sort`);
export const setSorting = createAction(`${subject} set sorting`);
export const fileUpload = createAction(`${subject} file upload`);
export const openDocument = createAction(`${subject} open document`);
export const gotErrors = createAction(`${subject} got errors`);
export const clearErrors = createAction(`${subject} clear errors`);
export const downloadDoc = createAction(`${subject} download doc`);