const types = state => state.AltScenarios.types;
const statistic = state => state.AltScenarios.statistic;
const roundGraphStats = state => state.AltScenarios.roundGraphStats;
const taxes = state => state.AltScenarios.taxes;
const filterParams = state => state.AltScenarios.filterParams;
const scheme = state => state.AltScenarios.scheme;
const loaded = state => state.AltScenarios.loaded;

export {
  types,
  statistic,
  roundGraphStats,
  taxes,
  filterParams,
  scheme,
  loaded
};
