import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import { get, head, last } from 'lodash';
import moment from 'moment';
import { push } from 'react-router-redux';
import { saveAs } from 'file-saver/FileSaver';
import Api from '../../../../common_components/Api/Api';
import * as actions from './RequestList.actions';
import * as selectors from './RequestList.selectors';
import * as countrySelectors from 'src/common_components/App/App.selectors';

import * as listActions from '../../../../common_components/List/ducks/List.actions';
import { makeid } from '../../../../utils';
import { Toast } from '../../../../utils/Toast';
import { COMMON_EXCLUDING_TYPES, KZ_EXCLUDING_TYPES, limit } from '../RequestList.constants';

function ArrayBufferToString(buffer) {
  return BinaryToString(
    String.fromCharCode.apply(
      null,
      Array.prototype.slice.apply(new Uint8Array(buffer)),
    ),
  );
}

function BinaryToString(binary) {
  var error;

  try {
    return decodeURIComponent(escape(binary));
  } catch (_error) {
    error = _error;
    if (error instanceof URIError) {
      return binary;
    } else {
      throw error;
    }
  }
}

function* requestSaga(action = {}) {
  const { payload = {} } = action;
  const { page, dir } = payload;

  yield put(actions.updatePage(page));
  const countryCode = yield select(countrySelectors.countryCode);

  const excludingTypes = countryCode === 'KZ' ? KZ_EXCLUDING_TYPES : COMMON_EXCLUDING_TYPES;

  const newOffset = ((page || 1) - 1) * 10;
  yield put(actions.updateOffset(newOffset));

  const filters = yield select(selectors.filters);

  if (filters.dateFrom) {
    filters.dateFrom = moment(filters.dateFrom).utc().format() ;
    filters.dateTo = moment(filters.dateTo).utc().format() ;
  }

  const offset = yield select(selectors.offset);

  let paginationParams = {
    limit,
    offset,
  };

  const requestPayload = {
    url: '/api/v3/facade/documents/search',
    method: 'post',
    data: {
      excludingTypes,
      ...{ ...filters, page: undefined },
      pagination: paginationParams,
    },
  };

  yield put(listActions.gotRequest(requestPayload, actions));
}

function* fileUploadSaga(action) {
  const { payload } = action;
  const {
    file: { file, properties, content, data },
    message,
    url,
  } = payload;
  const { document_type } = properties;

  const formData = new FormData();
  formData.append(
    'properties',
    new Blob([JSON.stringify(properties)], { type: 'application/json' }),
  );
  if (typeof content !== 'string') {
    const fileType = /\.xls[x]?/.test(file.name)
      ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'multipart/form-data';
    formData.append('file', new Blob([data], { type: fileType }), file.name);
  } else {
    formData.append(
      'file',
      new Blob([content], { type: file.type }),
      file.name,
    );
  }

  const requestPayload = {
    url,
    method: 'post',
    type: 'multipart',
    data: formData,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    if (success.data.errors && success.data.errors.length) {
      const errors = get(success, 'data.errors', []);
      yield put(actions.gotErrors(errors));
    } else {
      yield call(Toast.showSuccess, { content: message });
      if (url === '/api/v3/facade/doc/upload') {
        if (document_type === 'IMPORT_TRANSIT') {
          yield put(
            push(
              `/documents/${APP_LINKS.Eaes_import}/draft-${success.data.result}`,
            ),
          );
        } else {
          yield put(push(`/documents/import/draft-${success.data.result}`));
        }
      } else {
        yield put(actions.mounted());
        yield put(listActions.mounted(undefined, actions));
      }
    }
  }
  if (error) {
    console.log(error);
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* filePreview({ payload }) {
  const { id, type, docType } = payload;

  const requestPayload = {
    url: `/api/v3/facade/doc/body`,
    method: 'post',
    data: { id },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const number = get(success, 'data.number', '');
    const status = get(success, 'data.status', '');
    const content = get(success, 'data.content', '');
    const warning = get(success, 'data.downloadDesc', '');
    if (!content) {
      put(actions.gotErrors('Сервер вернул пустой файл.'));
    } else {
      yield put(
        filePreviewActions.openString({
          number,
          content,
          status,
          type,
          warning,
          docType,
        }),
      );
    }
  }
  if (error) {
    yield put(actions.gotErrors(get(error, 'response.data', 'error')));
  }
}

function* sortSaga(action) {
  const { payload } = action;

  const sorting = yield select(selectors.sorting);
  const { order, orderColumn } = sorting;

  let newOrder = 'ASC';
  if (payload === orderColumn) {
    newOrder = order === 'ASC' ? 'DESC' : 'ASC';
  }

  yield put(actions.setSorting({ order: newOrder, orderColumn: payload }));
}

function* downloadDoc(action) {
  const { payload } = action;
  const { id } = payload;
  const decodedId = decodeURIComponent(id);

  const requestPayload = {
    url: `/api/v3/facade/documents/buildReport/xls`,
    method: 'post',
    responseType: 'blob',
    preloading: false,
    data: { id: decodedId },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield call(saveAs, success.data, `${payload.id}.${payload.type}`);
  }

  if (error) {
    console.log('error', error);
  }
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.sort, sortSaga);
  yield takeEvery(actions.fileUpload, fileUploadSaga);
  yield takeEvery(actions.openDocument, filePreview);
  yield takeEvery(actions.downloadDoc, downloadDoc);
}
