import { handleActions } from 'redux-actions';
import * as actions from './EaesImportDocument.actions';

const data = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {},
);

const loaded = handleActions(
  {
    [actions.loaded]: () => true,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
    [actions.openDraft]: () => false,
  },
  false,
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  '',
);

const correctData = handleActions(
  {
    [actions.correctData]: (state, { payload = {} }) => payload,
  },
  {},
);

export default {
  data,
  loaded,
  errorInfo,
  correctData,
};
