import React from 'react';
import PropTypes from 'prop-types';
import { LoadingWrap } from './Auth.styled';
import Preloader from '../Preloader/Preloader';
import { Translate } from '../Translate/Translate';

const AuthView = props => {
  return (
    <React.Fragment>
      <LoadingWrap>
        <Preloader isOpened={true} text={Translate('Идет загрузка...')} />
      </LoadingWrap>
    </React.Fragment>
  );
};

AuthView.propTypes = {};

AuthView.defaultProps = {};

export default AuthView;
