export const DEFAULT_TAB_ID = 1;

export const tabs = [
  {
    id: 1,
    title: 'В формате ФИАС',
  },
  {
    id: 2,
    title: 'Код по ГАР',
  },
  {
    id: 3,
    title: 'В произвольном формате',
  },
];

export const displayNone = {
  display: 'none',
};
