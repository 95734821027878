import React, { useMemo } from 'react';
import ActFields from '../Act/ActFields';
import { ActBasicInfoWrap } from '../DocumentDetails.styled';
import moment from 'moment';

const ActBasicInfo = ({ selectedDocument, id }) => {

  const docDate = useMemo(() => {
    const { documentDate } = selectedDocument;
    return documentDate ? moment(documentDate).format('DD.MM.YYYY') : "-";
  }, [selectedDocument]);

  const data = useMemo(() => ({
    ...selectedDocument,
    content: {
      operation_type: selectedDocument.operationType,
      seller: {
        name: selectedDocument.senderName,
        inn: selectedDocument.senderInn,
      },
      buyer: {
        name: selectedDocument.receiverName,
        inn: selectedDocument.receiverInn,
      },
      origin_number: selectedDocument.originalDocNumber || '-',
      origin_date: selectedDocument.originalDocDate,
    }

  }), [selectedDocument]);

  const docId = useMemo(() => {
    return id.replace('EEU_ACT_', '');
  }, [id]);

  return (
    <ActBasicInfoWrap>
      <ActFields
        data={data}
        id={docId}
        docDate={docDate}
        showLinkedDoc={false}
      />
    </ActBasicInfoWrap>
  )
};

export default ActBasicInfo;
