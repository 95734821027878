import styled from 'styled-components';

export const modalStyle = {
  content: {
    width: '500px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    maxHeight: '90vh'
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)'
  },
  overflow: 'visible'
};

export const closeModalTheme = {
  Button: {
    fontSize: '14px',
    fontWeight: 400,
    cursor: 'pointer',
    large: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '48px'
    },
    normal: {
      paddingLeft: '16px',
      paddingRight: '16px',
      height: '32px'
    },
    main: {
      border: '1px solid #abadb5',
      color: '#000',
      background: '#ffffff'
    },
    disabled: {
      border: '1px solid #b9b9b9',
      color: '#2d3d5b',
      background: '#b9b9b9'
    },
    minWidth: '108px',
    borderRadius: '2px'
  }
};

export const Wrapper = styled.div`
  margin: 40px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 540px;
  min-width: 540px;
  font-size: 20px;
  color: #63666a;
  margin-bottom: 30px;
`;
