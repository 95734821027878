import React from 'react';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'Отрасль',
          'col2': 'Табачная продукция'
        },
        {
          'col1': 'Тип участника',
          'col2': <div>Возможность фильтрации по значениям:
            <ul>
              <li>Импортёр</li>
              <li>Импортёр, Опт</li>
              <li>Оператор Информационной Системы Мониторинга Товарооборота</li>
              <li>Опт</li>
              <li>Производитель</li>
              <li>Розница</li>
              <li>Розница, Опт</li>
              <li>ФОИВ</li>
            </ul>
          </div>
        },
        {
          'col1': 'Тип агрегации',
          'col2': <div>Возможность выбора агрегации:
            <ul>
              <li>Общий</li>
              <li>Подробный</li>
            </ul>
          </div>
        },
        {
          'col1': 'Наименование участника',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'ИНН участника',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
      ]
    }
  ]
}