import { defaultTheme } from './default';
import { goodsTheme } from './goods';
import { informationTheme } from './information';


export {
  defaultTheme as inputDefaultTheme,
  goodsTheme as inputGoodsTheme,
  informationTheme as inputInformationTheme,
};
