import {combineReducers} from 'redux';
import {contact} from './contact';
import {items} from './items';
import {date} from './date';
import {isOpen} from './isOpen';
import {options} from './options';


export default combineReducers({
  contact,
  items,
  date,
  isOpen,
  options,
});
