import { createAction } from 'redux-actions';

export const subject = '[NCALAYER]';

export const sign = createAction(`${subject} sign`,payload => payload,(_, meta) => meta,);


export const verifyApplet = createAction(`${subject} verify applet`);
export const setConnectionStatus = createAction(`${subject} set Connection Status`);
export const setErrorConnectionStatus = createAction(`${subject} set Error Connection Status`);
export const setSuccessConnectionStatus = createAction(`${subject} set Success Connection Status`);

export const openApplet = createAction(`${subject} open applet`);
export const getCertificate = createAction(`${subject} get certificate`);
export const storeCertificate = createAction(`${subject} store certificate`);
export const errorStoreCertificate = createAction(`${subject} error store certificate`);

export const getKeyInfo = createAction(`${subject} get key info`);
export const getKeyInfoSuccess = createAction(`${subject} get key info success`);

export const signed = createAction(`${subject} signed`);
