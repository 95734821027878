import { countryCodeFlags } from 'src/constants/countryCodeFlags';

export const participantTypes = {
  RETAIL: 'RETAIL',
  WHOLESALER: 'WHOLESALER',
  PRODUCER: 'PRODUCER',
  FOIV: 'FOIV',
  IMPORTER: 'IMPORTER',
  OPERATOR_EDO: 'OPERATOR_EDO',
  OPERATOR_FD: 'OPERATOR_FD',
  TRADE_PARTICIPANT: 'TRADE_PARTICIPANT',
  IS_MP_OPERATOR: 'IS_MP_OPERATOR',
  ROLE_ORG_IS_MP_OPERATOR: 'ROLE_ORG_IS_MP_OPERATOR',
  LABELLING_CENTER: 'LABELLING_CENTER',
  FEDERAL_AUTHORITY: 'FEDERAL_AUTHORITY',
  UNDEFINED: 'UNDEFINED',
  ROLE_ADMIN: 'ROLE_ADMIN',
  CEM: 'CEM',
  OGV: 'OGV',
};

//LEGACY ABOVE ^^^

export const PARTICIPANT_TYPES = {
  TRADE_PARTICIPANT: 'TRADE_PARTICIPANT',
  IS_MP_OPERATOR: 'IS_MP_OPERATOR',
  ROLE_ORG_IS_MP_OPERATOR: 'ROLE_ORG_IS_MP_OPERATOR',
  PRODUCER: 'PRODUCER',
  IMPORTER: 'IMPORTER',
  WHOLESALER: 'WHOLESALER',
  RETAIL: 'RETAIL',
  CEM: 'CEM',
  OGV: 'OGV',
  ROLE_ORG_OGV: 'ROLE_ORG_OGV',
  ROLE_HEAD_OGV: 'ROLE_HEAD_OGV',
  MEDICAL: 'MEDICAL',
  CATERING: 'CATERING',
  CONTENT_MANAGER: 'ROLE_CONTENT_MANAGER',
  RMT_PUBLISHER: 'RMT_PUBLISHER',
  ROLE_RMT_PUBLISHER: 'ROLE_RMT_PUBLISHER',
  RMT_USER: 'RMT_USER',
  ROLE_RMT_USER: 'ROLE_RMT_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_REPORTS: 'ROLE_REPORTS',
  ROLE_SUPPORT: 'ROLE_SUPPORT',
  ROLE_ORG_TRADE_PARTICIPANT: 'ROLE_ORG_TRADE_PARTICIPANT',
  RMT_SUPERMODERATOR: 'RMT_SUPERMODERATOR',
  ROLE_RMT_SUPERMODERATOR: 'ROLE_RMT_SUPERMODERATOR',
  RMT_MODERATOR: 'RMT_MODERATOR',
  ROLE_RMT_MODERATOR: 'ROLE_RMT_MODERATOR',
  ROLE_HEAD_OPERATOR: 'ROLE_HEAD_OPERATOR',
  ROLE_USER: 'ROLE_USER',
};

export const PREPARED_PARTICIPANT_TYPES = Object.keys(PARTICIPANT_TYPES).reduce(
  (obj, type) => {
    obj[type] = `ROLE_ORG_${type}`;
    return obj;
  },
  {},
);

export const participantNames = {
  CEM: 'ЦЭМ',
  OGV: getParticipantNameByRole(participantTypes.OGV),
  UNDEFINED: 'Не установлен',
  PRODUCER: 'Производитель',
  MEDICAL: 'Медицинская организация',
  FOIV: 'ФОИВ',
  CATERING: 'Общественное питание',
  WHOLESALER: getParticipantNameByRole(participantTypes.WHOLESALER),
  RETAIL: getParticipantNameByRole(participantTypes.RETAIL),
  OPERATOR_EDO: 'Оператор ЭДО',
  OPERATOR_FD: 'Оператор ФД',
  IMPORTER: 'Импортер',
  ROLE_ADMIN: 'Администратор системы',
  TRADE_PARTICIPANT: 'Участник оборота',
  IS_MP_OPERATOR: getParticipantNameByRole(participantTypes.IS_MP_OPERATOR),
  ROLE_ORG_IS_MP_OPERATOR: 'Оператор ИС МП',
  FEDERAL_AUTHORITY: 'Федеральный орган исполнительной власти',
  CONTENT_MANAGER: 'Контент менеджер',
};

export const participantTypeValues = [
  { id: 0, title: participantNames.FACTORY, type: participantTypes.FACTORY },
  { id: 1, title: participantNames.FOIV, type: participantTypes.FOIV },
  {
    id: 2,
    title: participantNames.WHOLESALE_ORGANIZATION,
    type: participantTypes.WHOLESALE_ORGANIZATION,
  },
  {
    id: 3,
    title: participantNames.RETAIL_ORGANIZATION,
    type: participantTypes.RETAIL_ORGANIZATION,
  },
  {
    id: 4,
    title: participantNames.OPERATOR_EDO,
    type: participantTypes.OPERATOR_EDO,
  },
  {
    id: 5,
    title: participantNames.OPERATOR_FD,
    type: participantTypes.OPERATOR_FD,
  },
  { id: 6, title: participantNames.IMPORTER, type: participantTypes.IMPORTER },
  {
    id: 7,
    title: participantNames.TRADE_PARTICIPANT,
    type: participantTypes.TRADE_PARTICIPANT,
  },
  {
    id: 8,
    title: participantNames.LABELLING_CENTER,
    type: participantTypes.LABELLING_CENTER,
  },
  {
    id: 8,
    title: participantNames.FEDERAL_AUTHORITY,
    type: participantTypes.FEDERAL_AUTHORITY,
  },
];

function getParticipantNameByRole(role) {
  const { isKZ, isUZ } = countryCodeFlags;
  const { IS_MP_OPERATOR, WHOLESALER, OGV, RETAIL } = participantTypes;

  switch (role) {
    case IS_MP_OPERATOR:
      if (isUZ || isKZ) return 'Оператор ИСМПТ';
      return 'Оператор ИС МП';
    case WHOLESALER:
      return isKZ ? 'Оптовый реализатор' : 'Оптовая торговля';
    case OGV:
      return isKZ ? 'Государственный орган' : 'ФОИВ';
    case RETAIL:
      return isKZ ? 'Розничный реализатор' : 'Розница';
    default:
      return '';
  }
}
