import React, { useMemo } from 'react';

import { columns } from './Gtins.constants';

import {NoInfo} from 'src/fragments/Data/NoInfo';
import Datatable from '@crpt-ui/datatable';
import {Typography} from '@crpt-ui/core';

import {ReactComponent as EmptyGtinsLogo} from '../../images/emptyList.svg';

import {useStyles} from '../constants/tableContentStyles';
import { Translate } from '../../../../common_components/Translate/Translate';

export const GtinsView = ({ gtins }) => {
  const styles = useStyles();

  const EmptyIcon = useMemo(() => (
    <div className={styles.iconWrapper}>
      <EmptyGtinsLogo />
    </div>
  ), []);

  if(gtins.length === 0) {
    return <NoInfo title={Translate("Отборы по GTIN отсутствуют")} renderIcon={EmptyIcon} />
  }

  const instance = React.useRef();

  return (
    <div className={styles.contentWrapper}>
      <Datatable
        data={gtins}
        columns={columns}
        useFilters
        ref={instance}
        manualFilters={false}
        useSort
      />
      <div className={styles.footerWrapper}>
        <div>
          <Typography variant="body2">
            {Translate('Всего записей:')} {gtins.length}
          </Typography>
        </div>
      </div>
    </div>
  )
};
