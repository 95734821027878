/* eslint-disable no-shadow */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  onLoad,
} from './ducks/Production.actions';

import { getProductionData } from './ducks/Production.selectors';
import { ProductionView } from './Production.view';
import lodash from 'lodash';

const Production = ({
  productionLine,
  onLoad,
  history,
  selectedParticipant,
}) => {

  useEffect(() => {
    const participantId = lodash.get(selectedParticipant, 'id', false);
    onLoad(participantId);
  }, []);

  const productionLines = useMemo(() => productionLine ? productionLine : [], [
    productionLine,
  ]);

  const datatableActions = useMemo(() => []);

  return React.createElement(ProductionView, {
    onRowClick: () => {},
    productionLines,
    datatableActions,
    history,
  });
};

const mapStateToProps = (state) => ({
  productionLine: getProductionData(state),
  countryCode: state.config.countryCode,
});

const mapDispatchToProps = {
  onLoad,
};

Production.propTypes = {
  onLoad: PropTypes.func.isRequired,
  selectedParticipant: PropTypes.shape({}).isRequired,
};

export const ProductionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Production);
