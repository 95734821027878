import moment from 'moment';

export function composeByDay(history) {
  const days = {};

  history.forEach(op => {
    const date = moment(new Date(op.operationDate)).format('YYYY-MM-DD');
    if (days[date]) {
      days[date].push(op);
      days[date].sort((op1, op2) => op1.timestamp - op2.timestamp);
    } else days[date] = [op];
  });

  return days;
}