import React, {Component} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import 'datejs';
import moment from 'moment';
import lodash from 'lodash';
import get from 'lodash/get';
import 'moment/locale/ru';
import {Column, Container} from '@crpt/react-page-layout';
import {Card, CardLine} from '@crpt/react-card';
import {TwoLineLabel} from '@crpt/react-label';
import { getRubelsAsFloatFromKopecks, addSpacesBetweenThousands } from '../../../utils';

import HeadedLabel from './HeadedLabel';
import { FlexWrapper, Separator, TextWithIconWrapper } from './styled';
import  HeadingWrap from './HeadingWrap';
import { nano2milli } from '../DocumentDetails.utils';
import { getParticipantNamesByRoles } from '../DocumentDetails.utils';

moment.locale('ru');

const propTypes = {};

// const Timezone = styled.div`
//   color: #abadb6;
// `;

const LeftLayer = styled.div`
  width: 160px;
  min-width: 160px;
`;

const ImageWrap = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
  margin-left: 32px;
  border-radius: ${props => (props.round ? '50%' : '')};
`;

const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 16px;
`;

const InfoLine = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AfterLarge = styled.div`
  width: 100%;
  height: 15px;
`;

class UTDfBasicInfo extends Component {
  render() {
    const { id, selectedDocument } = this.props;

    console.log('selectedDocument', selectedDocument);

    const { documentDate, receivedAt } = selectedDocument;

    const docDate = moment(nano2milli(documentDate));
    const docDateString = (docDate && docDate.format('DD MMMM YYYY')) || '—';

    const receivedDate = moment(nano2milli(receivedAt));
    const receivedDateString = (receivedDate && receivedDate.format('DD MMMM YYYY')) || '—';

    const taxRaw = get(selectedDocument, 'tax', false);
    const tax = taxRaw ? getRubelsAsFloatFromKopecks(taxRaw) : false;
    const taxString = (tax && addSpacesBetweenThousands(tax) + ' ₽') || '—';

    const sum = selectedDocument && getRubelsAsFloatFromKopecks(selectedDocument.totalSum);
    const sumString = (sum && addSpacesBetweenThousands(sum) + ' ₽') || '—';

    const senderInfo = get(selectedDocument, 'senderInfo', {});
    const receiverInfo = get(selectedDocument, 'receiverInfo', {});
    const senderShortName = get(senderInfo, 'shortName', false) || get(selectedDocument, 'senderName', false);
    const senderActualAddress = get(senderInfo, 'actualAddress', false) || get(selectedDocument, 'senderAddress', false);
    const receiverShortName = get(receiverInfo, 'shortName', false) || get(selectedDocument, 'receiverName', false);
    const receiverActualAddress = get(receiverInfo, 'actualAddress', false) || get(selectedDocument, 'receiverAddress', false);
    const senderINN = get(senderInfo, 'inn', false) || get(selectedDocument, 'senderInn', false);
    const senderKPP = get(senderInfo, 'kpp', false) || get(selectedDocument, 'senderKpp', false);
    const receiverINN = get(receiverInfo, 'inn', false) || get(selectedDocument, 'receiverInn', false);
    const receiverKPP = get(receiverInfo, 'kpp', false) || get(selectedDocument, 'receiverKpp', false);
    const signers = get(selectedDocument, 'signers', []);

    const shipperName =
      selectedDocument &&
      selectedDocument.shipper &&
      `${selectedDocument.shipper.ipCertificate.fio.lastName} ${
        selectedDocument.shipper.ipCertificate.fio.firstName
        } ${selectedDocument.shipper.ipCertificate.fio.middleName}`;

    const shipperAddress = get(selectedDocument, 'shipperAddress', false);

    const shipperINN = get(selectedDocument, 'shipper.inn', false);
    const shipperKPP = get(selectedDocument, 'shipper.kpp', false);

    const recipientName =
      selectedDocument &&
      selectedDocument.recipient &&
      selectedDocument.recipient.legalEntityData.title;


    const needShipper =
      shipperName || shipperAddress || shipperINN || shipperKPP;
    const needRecipient =
      recipientName || recipientAddress || recipientINN || recipientKPP;


    const recipientAddress = lodash.get(
      selectedDocument,
      'recipientAddress',
      false
    );

    const recipientINN = lodash.get(
      selectedDocument,
      'recipient.legalEntityData.inn',
      false
    );
    const recipientKPP = lodash.get(
      selectedDocument,
      'recipient.legalEntityData.kpp',
      false
    );

    const fixNumber = lodash.get(selectedDocument, 'fixNumber', false);
    const fixNumberString = fixNumber || '—';

    const fixDateSource = lodash.get(selectedDocument, 'fixDate', false);

    console.log(
      'fixDateSource.replace(/\\./g, \'-\')',
      fixDateSource.replace(/\./g, '-')
    );

    const fixDate =
      fixDateSource &&
      moment(
        fixDateSource
          .split('.')
          .reverse()
          .join('.')
      );
    const fixDateString = fixDate.format('DD MMMM YYYY') || '—';

    const senderRoles = lodash.get(selectedDocument, 'senderInfo.roles', []);
    const receiverRoles = lodash.get(selectedDocument, 'receiverInfo.roles', []);

    return (
      <React.Fragment>
        <HeadingWrap type={selectedDocument.type} />
        <FlexWrapper>
          <HeadedLabel header="Дата документа" value={docDateString} />
          <HeadedLabel header="Номер исправления" value={fixNumberString} />
          <HeadedLabel header="Дата исправления" value={fixDateString} />
          <HeadedLabel header="Сумма по документу" value={sumString} />
          <HeadedLabel header="НДС" value={taxString} />
          <HeadedLabel header="Акциз" value={0} />
          <HeadedLabel header="Поступил в систему" value={receivedDateString} />
        </FlexWrapper>

        <Separator />

        <Card>
          <Container fillWidth gutter={32}>
            <Column col={10}>
              <DocInfoWrap>
                <TextWithIconWrapper>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Продавец
                    </DocumentInfoHeadingLabel>
                    {senderShortName && (
                      <InfoLine>
                        <TwoLineLabel heading={getParticipantNamesByRoles(senderRoles)}>
                          {senderShortName}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                    {senderActualAddress && (
                      <InfoLine>
                        <TwoLineLabel heading={'Адрес'}>
                          {senderActualAddress}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </TextWithIconWrapper>
              </DocInfoWrap>

              {(senderINN || senderKPP) && (
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Налоговые данные
                    </DocumentInfoHeadingLabel>
                    {senderINN && (
                      <InfoLine>
                        <TwoLineLabel heading="ИНН">{senderINN}</TwoLineLabel>
                      </InfoLine>
                    )}
                    {senderKPP && (
                      <InfoLine>
                        <TwoLineLabel heading="КПП">{senderKPP}</TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </DocInfoWrap>
              )}

              {signers.length && (
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Подписан
                    </DocumentInfoHeadingLabel>
                    <InfoLine>
                      <TwoLineLabel heading="Подписант">
                        {signers[0].name || '—'}
                      </TwoLineLabel>
                    </InfoLine>
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Дата подписания">*/}
                    {/*{'—'}*/}
                    {/*</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Оператор ЭДО">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                  </div>
                </DocInfoWrap>
              )}
            </Column>
            <Column>
              <DocInfoWrap>
                <ImageWrap round />
              </DocInfoWrap>
            </Column>
            <Column col={10}>
              <DocInfoWrap>
                <TextWithIconWrapper>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Покупатель
                    </DocumentInfoHeadingLabel>
                    {receiverShortName && (
                      <InfoLine>
                        <TwoLineLabel
                          heading={getParticipantNamesByRoles(receiverRoles)}
                        >
                          {receiverShortName}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                    {receiverActualAddress && (
                      <InfoLine>
                        <TwoLineLabel heading="Адрес">
                          {receiverActualAddress || '—'}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </TextWithIconWrapper>
              </DocInfoWrap>

              {(receiverINN || receiverKPP) && (
                <DocInfoWrap>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Налоговые данные
                    </DocumentInfoHeadingLabel>
                    {receiverINN && (
                      <InfoLine>
                        <TwoLineLabel heading="ИНН">{receiverINN}</TwoLineLabel>
                      </InfoLine>
                    )}
                    {receiverKPP && (
                      <InfoLine>
                        <TwoLineLabel heading="КПП">{receiverKPP}</TwoLineLabel>
                      </InfoLine>
                    )}
                  </div>
                </DocInfoWrap>
              )}

              {/*<DocInfoWrap>*/}
              {/*<div>*/}
              {/*<DocumentInfoHeadingLabel>Подписан</DocumentInfoHeadingLabel>*/}
              {/*<InfoLine>*/}
              {/*<TwoLineLabel heading="Подписант">{'—'}</TwoLineLabel>*/}
              {/*</InfoLine>*/}
              {/*<InfoLine>*/}
              {/*<TwoLineLabel heading="Дата подписания">{'—'}</TwoLineLabel>*/}
              {/*</InfoLine>*/}
              {/*<InfoLine>*/}
              {/*<TwoLineLabel heading="Оператор ЭДО">{'—'}</TwoLineLabel>*/}
              {/*</InfoLine>*/}
              {/*</div>*/}
              {/*</DocInfoWrap>*/}
            </Column>
            <Column>
              <DocInfoWrap>
                <ImageWrap round />
              </DocInfoWrap>
            </Column>
          </Container>

          <AfterLarge />

          {(needShipper || needRecipient) && (
            <Container fillWidth gutter={32}>
              <Column>
                {needShipper && (
                  <div>
                    <DocInfoWrap>
                      <div>
                        <DocumentInfoHeadingLabel>
                          Грузоотправитель
                        </DocumentInfoHeadingLabel>
                        {shipperName && (
                          <InfoLine>
                            <TwoLineLabel heading="Производитель">
                              {shipperName}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                        {shipperAddress && (
                          <InfoLine>
                            <TwoLineLabel heading="Адрес">
                              {shipperAddress}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                      </div>
                    </DocInfoWrap>

                    {(shipperINN || shipperKPP) && (
                      <DocInfoWrap>
                        <div>
                          <DocumentInfoHeadingLabel>
                            Налоговые данные
                          </DocumentInfoHeadingLabel>
                          {shipperINN && (
                            <InfoLine>
                              <TwoLineLabel heading="ИНН">
                                {shipperINN || '—'}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                          {shipperKPP && (
                            <InfoLine>
                              <TwoLineLabel heading="КПП">
                                {shipperKPP || '—'}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                        </div>
                      </DocInfoWrap>
                    )}

                    {/*<DocInfoWrap>*/}
                    {/*<div>*/}
                    {/*<DocumentInfoHeadingLabel>Подписан</DocumentInfoHeadingLabel>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Подписант">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Дата подписания">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Оператор ЭДО">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*</div>*/}
                    {/*</DocInfoWrap>*/}
                  </div>
                )}
              </Column>

              <Column>
                {needRecipient && (
                  <div>
                    <DocInfoWrap>
                      <div>
                        <DocumentInfoHeadingLabel>
                          Грузополучатель
                        </DocumentInfoHeadingLabel>
                        {recipientName && (
                          <InfoLine>
                            <TwoLineLabel heading="Опт/розница">
                              {recipientName || '—'}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                        {recipientAddress && (
                          <InfoLine>
                            <TwoLineLabel heading="Адрес">
                              {recipientAddress || '—'}
                            </TwoLineLabel>
                          </InfoLine>
                        )}
                      </div>
                    </DocInfoWrap>

                    {(recipientINN || recipientKPP) && (
                      <DocInfoWrap>
                        <div>
                          <DocumentInfoHeadingLabel>
                            Налоговые данные
                          </DocumentInfoHeadingLabel>
                          {recipientINN && (
                            <InfoLine>
                              <TwoLineLabel heading="ИНН">
                                {recipientINN}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                          {recipientKPP && (
                            <InfoLine>
                              <TwoLineLabel heading="КПП">
                                {recipientKPP}
                              </TwoLineLabel>
                            </InfoLine>
                          )}
                        </div>
                      </DocInfoWrap>
                    )}

                    {/*<DocInfoWrap>*/}
                    {/*<div>*/}
                    {/*<DocumentInfoHeadingLabel>Подписан</DocumentInfoHeadingLabel>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Подписант">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Дата подписания">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*<InfoLine>*/}
                    {/*<TwoLineLabel heading="Оператор ЭДО">{'—'}</TwoLineLabel>*/}
                    {/*</InfoLine>*/}
                    {/*</div>*/}
                    {/*</DocInfoWrap>*/}
                  </div>
                )}
              </Column>
            </Container>
          )}

          <AfterLarge />

          {/*<Container>*/}
          {/*<Column>*/}
          {/*<DocInfoWrap>*/}
          {/*<div>*/}
          {/*<DocumentInfoHeadingLabel>*/}
          {/*Грузоперевозчик*/}
          {/*</DocumentInfoHeadingLabel>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="Производитель">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="Адрес">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*</div>*/}
          {/*</DocInfoWrap>*/}

          {/*<DocInfoWrap>*/}
          {/*<div>*/}
          {/*<DocumentInfoHeadingLabel>*/}
          {/*Налоговые данные*/}
          {/*</DocumentInfoHeadingLabel>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="ИНН">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="КПП">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*</div>*/}
          {/*</DocInfoWrap>*/}

          {/*<DocInfoWrap>*/}
          {/*<div>*/}
          {/*<DocumentInfoHeadingLabel>Подписан</DocumentInfoHeadingLabel>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="Подписант">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="Дата подписания">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*<InfoLine>*/}
          {/*<TwoLineLabel heading="Оператор ЭДО">{'—'}</TwoLineLabel>*/}
          {/*</InfoLine>*/}
          {/*</div>*/}
          {/*</DocInfoWrap>*/}
          {/*</Column>*/}
          {/*<Column />*/}
          {/*</Container>*/}
        </Card>
      </React.Fragment>
    );
  }
}

UTDfBasicInfo.propTypes = propTypes;
export default withRouter(UTDfBasicInfo);
