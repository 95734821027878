import { hasOnlyDigits } from '../../../utils';

export const required = value => {
  return value
    ? Array.isArray(value) ? (value.length ? undefined : 'Required') : undefined
    : 'Required';
};

export const isPhone = value =>
  value && value.match(/^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/)
    ? undefined
    : 'Error';

export const is16digit = value =>
  hasOnlyDigits(value) && value.length === 16 ? undefined : 'Error';

export const isMasked16digit = value => {
  const unmasked = value ? value.replace(/-/g, '').replace(/_/g, '') : '';
  hasOnlyDigits(unmasked) && unmasked.length === 16 ? undefined : 'Error';
};

