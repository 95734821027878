import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selector: {
    border: '1px solid #d3d3d3',
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    outline: 'none',
    borderRadius: '4px',
    height: 45,
    position: 'relative',
    '& img': {
      width: 25
    }
  },
  selectorOpen: {},
  iconsContainer: {
    padding: 16,
  },
  tabs: {
    display: 'flex',
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B8695',
    padding: 12,
    borderBottom: '2px solid transparent',
    marginBottom: 10,
    cursor: 'pointer',
  },
  selectedTab: {
    color: '#6BAC5B',
    backgroundColor: '#6BAC5B14',
    borderBottom: '2px solid #6BAC5B'
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '4px'
  },
  icon: {
    width: '116px',
    height: '72px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 12,
    border: '1px solid #d3d3d3',
    cursor: 'pointer',
    '& img': {
      width: 40
    }
  },
  selectedIcon: {
    backgroundColor: '#6BAC5B14',
    borderColor: '#6BAC5B'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  }
});
