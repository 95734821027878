import React from 'react';
import PropTypes from 'prop-types';
import { CertInfo, CertName } from './CertificateSelect.styled';

const Option = (props) => {
  const { value } = props;
  if (!value) return (null);

  return (
    <div>
      <CertInfo>{value.source}</CertInfo>
      <CertInfo>{value.date}</CertInfo>
      <CertName>{value.name}</CertName>
    </div>
  );
};

Option.propTypes = {
  value: PropTypes.shape({
    source: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string,
  }),
};

Option.defaultProps = {
  value: undefined,
};

export default Option;
