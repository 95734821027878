import { takeEvery, put, take, call, select } from 'redux-saga/effects';
import Api from 'src/common_components/Api/Api';
import * as actions from './DiscrepancyNotice.actions';
import { Toast } from '../../../../../utils/Toast';
import { get } from 'lodash';
import { replace } from 'react-router-redux';
import { APP_LINKS } from '../../../../../constants';
import {
  addActDataToDiscrepancy,
  prepareCisList,
  prepareDataForCorrectionAct,
  prepareDiscrepancyBody,
} from '../DiscrepancyNotice.utils';
import { cisKey, goodsKey } from '../../ActDocument/ActDocument.constants';
import { getProductsAndCisesForDocument } from '../../ActDocument/Goods/ducks/Goods.saga';

function* sendSaga({ payload }) {
  console.log(payload);
  const content = prepareDiscrepancyBody(payload);
  console.log(content);

  const requestPayload = {
    url: '/api/v3/facade/edo-api/outgoing-documents/json/discrepancy',
    method: 'post',
    data: {
      type: 1005,
      content,
      for_approval: false,
    },
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    Toast.showSuccess({
      content: 'Уведомление о расхождениях отправлено',
    });
    yield put(replace(`/documents/outcoming`));
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}
function* cancelSaga({ payload }) {
  const requestPayload = {
    url: `/api/v3/facade/edo-api/incoming-documents/${payload}/events/reject`,
    method: 'post',
  };
  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    Toast.showSuccess({
      content: 'Уведомление о расхождениях отклонено',
    });
    yield put(replace(`/documents/outcoming`));
  }
  if (error) {
    yield call(Toast.showError, { content: get(error, 'message', 'error') });
  }
}

function* createFixedActSaga({ payload }) {
  const data = payload.initialData;

  const products = get(data, 'reconciliation', [])
    .filter(item => ['FOUND', 'ADDED'].includes(item.reconciliationStatus))
    .map((item, idx) => ({ ...item, idx: idx + 1, }));

  const total = [];

  const result = yield call(
    prepareDataForCorrectionAct,
    data,
    total,
    products
  );
  yield put(actions.setCorrectionAct({ ...result }));
  yield put(replace(`/documents/${APP_LINKS.Eaes_act}/correction/`));

  // const cises = products.map(item => item.cis);

  // const requestPayload = {
  //   url: `/api/v3/facade/cis/cises-units-info`,
  //   method: 'post',
  //   data: cises,
  // };

  // const [success, error] = yield call(Api.request, requestPayload);
  // if (success) {
  //   try {
  //     const { wideTotal, wideProducts } = yield call(getProductsAndCisesForDocument, success, products);
  //     const result = yield call(
  //       prepareDataForCorrectionAct,
  //       data,
  //       wideTotal,
  //       wideProducts
  //     );
  //     yield put(actions.setCorrectionAct({ ...result }));
  //     yield put(replace(`/documents/${APP_LINKS.Eaes_act}/correction/`));
  //
  //   } catch (e) {
  //     console.log(e, { e });
  //   }
  // } else if (error) {
  //   // yield put(actions.reset());
  //   const errorMessage = get(error, 'response.data.error_message', false);
  //   if (errorMessage) {
  //     Toast.showError({
  //       content: errorMessage,
  //     });
  //   }
  // }
}

function* loadDiscrepancyNoticeSaga({ payload }) {
  yield put(actions.isLoadingStart());
  const { isIncome, id } = payload;
  const direction = isIncome ? 'outgoing' : 'incoming';
  const actDirection = isIncome ? 'incoming' : 'outgoing';
  let requestPayload = {
    url:`/api/v3/facade/edo-api/${direction}-documents/${id}`,
    method: 'get',
  };
  const [successData, errorData] = yield call(Api.request, requestPayload);
  if (successData) {
    const cises = get(successData, 'data.content.cises', []);
    const onlyCises = cises.map((item) => item.code);
    const reconciliation = yield call(loadCisData, onlyCises, cises);

    const id = get(successData, 'data.content.correction_doc_id');
    const actData = yield call(loadActData, id, actDirection);

    const data = yield call(
      addActDataToDiscrepancy,
      get(successData, 'data', {}),
      actData
    );
    yield put(actions.setDiscrepancyNotice({ data, reconciliation }));
    yield put(actions.isLoadingEnd())

  } else {
    Toast.showError({ content: get(errorData, 'message', 'error') });
    yield put(actions.isLoadingEnd())
  }
}

function* loadActData(id, direction) {
  let requestPayload = {
    url:`/api/v3/facade/edo-api/${direction}-documents/${id}`,
    method: 'get',
  };
  const [successData, errorData] = yield call(Api.request, requestPayload);
  if (successData) {
    return successData.data;
  }
}

function* loadCisData(onlyCises, cises) {
  const requestPayloadCises = {
    url: `/api/v3/facade/cis/cis_list`,
    method: 'post',
    data: onlyCises,
  };
  const [success, error] = yield call(Api.request, requestPayloadCises);
  if (success) {
    const reconciliation = prepareCisList(cises, success.data);
    return reconciliation;
  }
}

function* createDiscrepancyNoticeSaga({ payload }) {
  const { id, data, list } = payload;
  yield put(actions.setDiscrepancyNotice({ ...data, reconciliation: list }));
  yield put(replace(`/documents/${APP_LINKS.Discrepancy_notice}/create/${id}`));
}

export default function* watch() {
  yield takeEvery(actions.send, sendSaga);
  yield takeEvery(actions.cancel, cancelSaga);
  yield takeEvery(actions.createCorrectionAct, createFixedActSaga);
  yield takeEvery(actions.createDiscrepancyNotice, createDiscrepancyNoticeSaga);
  yield takeEvery(actions.loadDiscrepancyNotice, loadDiscrepancyNoticeSaga);
}
