import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const InfoLabelViewStyle = {
  height: 'auto',
  margin: '10px 0 0 0',
  padding: '10px 30px',
};

export const Blocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 1100px;
  width: 90%;
  min-height: 400px;
  max-height: 800px;
  height: 80%;
  background-color: white;
  padding: 34px 34px 40px 34px;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
  background-color: white;
  border: 1px solid rgba(196, 196, 196, 0.25);
  & pre {
    margin: 0;
  }
`;

export const PreviewWrapperCsv = styled.div`
  width: 100%;
  height: calc(100% - 124px);
  overflow: auto;
  background-color: white;
  & table td:nth-child(-n+5) {
    border: 1px solid lightgray;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 32px;
  ${props => (props.vAlignTop ? css`vertical-align: top` : null)}
`;

export const ActionsWrapper = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0, 0, 0, 0);
`;

export const FileName = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 28px;
  color:#52535A;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: table;
`;


export const styleStatusWrap = {
  fontSize: '14px',
  top: '2px',
  backgroundColor: '#FFF',
  padding: '0 0',
};


export const CloseWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 30px;
  z-index: 2;
`;

export const TitleTable = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 25px;
  color:#52535A;
`;

export const WrapBlock = styled.div`
  margin-bottom: 20px;
`;

export const TitleBlock = styled.div`
  display: block;
  color: rgba(82, 83, 90, 0.7);
  font-size: 14px;
`;

export const DataBlock = styled.div`
  color: #52535A;
  font-size: 16px;
`;
