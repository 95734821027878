import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  max-height: 120px;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #fdf8c8;
  color: #52535a;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 3px;
  ${({ customStyle }) => (customStyle ? customStyle : null)}
`;

export const Description = styled.div`
  max-height: 110px;
  overflow: auto;
`;

export const ShowButton = styled.span`
  display: inline-block;
  color: #55b5e7;
  margin-left: 5px;
  cursor: pointer;
`;
