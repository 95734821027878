export const tabsReportsTheme = {
  TabsWrap: {
    borderBottom: "1px solid #e0e0e0",
    padding: "0px 0px 0px 0px"
  },
  TabUnderline: {
    background: "#F8EC31",
    height: "4px",
    transition: "all 0.5s ease 0s",
    bottom: "-1px"
  },
  TabWrap: {
    main: {
      cursor: "pointer",
      background: "rgba(0,0,0,0)",
      color: "#52535A"
    },
    disabled: {
      color: "lightgray",
      cursor: "not-allowed"
    }
  },
  fontSize: "16px"
};
