import styled from 'styled-components';

export const GlobalSearchWrapper = styled.div`
  flex-grow: 1;
`;

export const PageHeaderWrapper = styled.div`
  color: #63666A;
  background-color: #fff;
`;
