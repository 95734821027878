import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

import {
  Avatar as CrptAvatar,
  COLORS as AVATAR_COLORS,
  SIZES as AVATAR_SIZES
} from '@crpt/react-avatar';

import {AvatarBlock, TimeWrap} from './styled';


export class Avatar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  render() {
    const { abbr, lastSeen, src } = this.props;

    return (
      <AvatarBlock noHeight>
        <CrptAvatar
          abbr={abbr}
          size={AVATAR_SIZES.large}
          color={AVATAR_COLORS.yellow}
          src={src}
        />
        {lastSeen &&
          <TimeWrap>Сегодня в 12:32</TimeWrap>
        }
      </AvatarBlock>
    );
  }
}


Avatar.propTypes = {
  abbr: PropTypes.string.isRequired,
  lastSeen: PropTypes.number,
};

Avatar.displayName = 'Avatar';
