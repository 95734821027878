import Input from '../../../../../common_components/Form/MaterialFields/Input/Input';
import {
  exciseTaxDocumentNumberValidator, isExciseTaxWithEmptyValue,
} from '../../../../../common_components/Form/utils/validations';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { ExciseTaxFields } from '../../Document/Document.styled';
import React, { useEffect, useRef } from 'react';
import InputMask from 'inputmask';
import { Translate } from '../../../../../common_components/Translate/Translate';

export const GoodsFields = ({ formValues }) => {
  const inputRef = useRef(null);
  const inputmask = new InputMask({
    mask: '9{1,17}.99',
  });

  const { isChanged } = formValues;

  useEffect(() => {
    inputmask.mask(inputRef.current);
  }, []);

  return (
    <ExciseTaxFields>
      <Input
        name="excise_tax_document_number"
        placeholder={Translate('Номер квитанции об уплате акциза')}
        forceValidate
        validate={(value, arg)=> exciseTaxDocumentNumberValidator(value, arg)}
        disabled={isChanged}
      />
      <DatePicker
        name="excise_tax_document_date"
        placeholder={Translate('Дата квитанции об уплате акциза')}
        maxDate={new Date()}
        disabled={isChanged}
      />
      <Input
        inputRef={inputRef}
        name="excise_tax_sum"
        placeholder={Translate('Сумма акциза')}
        forceValidate
        validate={(value, arg)=> isExciseTaxWithEmptyValue(value, arg)}
        disabled={isChanged}
      />
    </ExciseTaxFields>
  );
};
