import {actionTypes} from '../../constants';


export function callbackModal(state = null, action = {}) {
  switch (action.type) {
    case actionTypes.PROFILE.OPEN_MODAL:
      return action.value;
    case actionTypes.PROFILE.PROFILE_EDIT_UNMOUNTED:
      return null;
    default:
      return state;
  }
}
