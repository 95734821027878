import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from '../_refactor/Select/SingleSelect';
import { instance } from '../../../../services/instance';
import HintedOption from './HintedOption';

const { CancelToken } = axios;


class ProductSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: undefined,
  };

  static cancelToken;

  state = {
    loading: false,
    values: undefined,
  };

  onChange = (val) => {
    const { onChange } = this.props;
    if (onChange) onChange(val);
    else {
      this.setState({ loading: true });

      instance.request({
        url: `/api/v3/product/suggestions/?text=${encodeURIComponent(val)}`,
        method: 'get',
        cancelToken: new CancelToken(((c) => {
          ProductSelect.cancelToken = c;
        })),
      }).then((answer) => {
        const values = answer.data.results.map((pr, i) => ({
          type: pr.gtin,
          gtin: pr.gtin,
          id: i,
          title: pr.name,
        }));

        this.setState({ loading: false, values });
      });
    }
  };

  render() {
    const { onChange, ...passProps } = this.props;
    const { values, loading } = this.state;

    return (
      <SingleSelect
        {...passProps}
        filterDisable
        truncate
        onChange={this.onChange}
        renderOption={HintedOption}
        isLoading={loading}
        values={values}
      />
    );
  }
}

export default ProductSelect;
