import { handleAction } from 'redux-actions';

import {
  setProductionData,
} from './Production.actions';

const data = handleAction(setProductionData, (_, { payload }) => payload, {
  last: false,
  result: [],
  total: 0,
});

export default {
  data,
};
