const PAGINATED_LIST_CONSTANTS = {
  LIST_LOADED: null,
  LIST_PAGE_LOADED: null,
  LIST_MOUNTED: null,
  PAGE_SELECTED: null,
  FILTER_APPLY_CLICKED: null,
  LOADING_STARTED: null,
  LOADING_FINISHED: null,
  FILTER_PARAMS_UPDATED: null,
  PAGES_COUNT_LOADED: null,
  CLEAR_FILTER_PARAMS: null,
};

const actionTypes = {
  APP: {
    CONFIG_UPDATE: null,
  },

  PRELOADER: {
    START: null,
    END: null,
  },

  AUTH: {
    LOGIN: {
      GOT_INFO: null,
      NOT_AUTHORIZED: null,
    },
  },
  FORM: {
    LOAD_FORM: null,
    ITEM_CHANGED: null,
    ITEM_VALIDATED: null,
    ITEM_LOADING: null,
    ITEM_LOADED: null,
    ITEM_ACTIVE: null,
    ITEM_ACTIVE_ONLY: null,
    ITEM_APPEND: null,
    ITEM_REMOVE: null,
    CHECKBOX_TOGGLED: null,
    DELETE_FORM: null,
    CLEAR_PATHS: null,
  },
  EXCISE: {
    ...PAGINATED_LIST_CONSTANTS,
    STAT_LOADED: null,
    DECLARATION_SELECTED: null,
    MARKING_STAT_LOADED: null,
  },
  PROFILE: {
    PROFILE_INFO_LOADED: null,
    PROFILE_INFO_UPDATED: null,
    USER_ACTIVE_LIST_LOADED: null,
    USER_REMOVED_LIST_LOADED: null,
    USER_INFO_LOADED: null,
    INFO_UNMOUNTED: null,
    IMAGE_SELECTED: null,
    CERT_SELECTED: null,
    CERT_UPLOADED: null,
    PROFILE_EDITED: null,
    PROFILE_EDIT_UNMOUNTED: null,
    COMPANY_INFO_ERROR: null,
    OPEN_MODAL: null,
    CLOSE_MODAL: null,
    PROFILE_SAVED: null,
    EXCISE_MOUNTED: null,
    EXCISE_PRODUCTS_LOADED: null,
    EXCISE_STAT_LOADED: null,
    EXCISE_MARKING_STAT_LOADED: null,
    EXCISE_DATE_CHANGED: null,
    EXCISE_IS_MONTH_SWITCHED: null,
    EXCISE_PRODUCTS_PACKAGE_TYPE_CHANGED: null,
    EXCISE_PAGE_SELECTED: null,
    EXCISE_PRODUCTS_FILTER_UPDATED: null,
    CERTIFICATES_LOADED: null,
  },

  LOGIN_FORM_KEP: {
    SET_LANGUAGE: null,
    CERTIFICATES_LOADED: null,
    CERTIFICATE_SELECTED: null,
  },
  LOGIN_FORM: {
    LOGIN_CLEARED: null,
    PASSWORD_CLEARED: null,
    FILLED_WITH_VALUES: null,
    SUBMIT_CLICKED: null,
    GOT_ERROR_LOGIN: null,
    GOT_SUCCESS_LOGIN: null,
  },
  CIS_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
  },
  DOCUMENT_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
  },
  PRODUCT_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
    PRODUCT_CREATED: null,
  },
  PARTICIPANT_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
  },
  CIS_DETAILS: {
    CIS_DETAILS_LOADED: null,
  },
  ORDER_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
  },
  EXCISE_LIST: {
    ...PAGINATED_LIST_CONSTANTS,
    CONSOLIDATED_LOADED: null,
  },
  DOCUMENT_DETAILS: {
    CLEAR: null,
    PAGE_SELECTED: null,
    FILTER_APPLY_CLICKED: null,
    DOCUMENT_DETAILS_LOADED: null,
    CIS_LIST_LOADED: null,
    CIS_LIST_PAGE_LOADED: null,
    CIS_LIST_MOUNTED: null,
  },
  PRODUCT_DETAILS: {
    PRODUCT_DETAILS_LOADED: null,
    RATE_CHANGED: null,
    RATE_CHANGES_HISTORY: null,
    RATE_CHANGES_HISTORY_CLEAR: null,
    RATE_CHANGED_FALSE: null,
    CIS_LIST_LOADED: null,
    CIS_LIST_PAGE_LOADED: null,
    CIS_LIST_MOUNTED: null,
    PAGE_SELECTED: null,
    FILTER_APPLY_CLICKED: null,
    PRODUCT_DETAILS_UPDATED: null,
    PRODUCER_ID_CHANGED: null,
  },
  PARTICIPANT_DETAILS: {
    PARTICIPANT_DETAILS_LOADED: null,
    PARTICIPANT_DETAILS_UPDATED: null,
    DOCUMENTS_LOADED: null,
    WAREHOUSE_LOADED: null,
    WAREHOUSE_MOUNTED: null,
    WAREHOUSE_FILTER_APPLY_CLICKED: null,
    WAREHOUSE_FILTER_PRODUCER_LOADED: null,
    WAREHOUSE_FILTER_PRODUCER_CHANGED: null,
  },
  REGISTRATION: {
    ITEM_CHANGED: null,
    ITEM_VALIDATED: null,
    CERTS_LOADED: null,
    CERT_SELECTED: null,
    REGISTER_SUCCESSFULL: null,
    REGISTER_ERROR: null,
    REGISTRATION_MOUNTED: null,
    REGISTER_BUTTON_CLICKED: null,
    TOGGLE_CERT: null,
    TOGGLE_CERT_EDIT: null,
    TOGGLE_CONTACTS: null,
    TOGGLE_CONTACTS_EDIT: null,
    TOGGLE_DATA: null,
    SHOW_CONTACTS: null,
    SHOW_DATA: null,
  },
  SIDEBAR: {
    SIDEBAR_OPEN: null,
    SIDEBAR_CLOSE: null,
    SIDEBAR_CLOSED: null,
  },
  ORDER_FORM: {
    ORDER_FORM_SHOW: null,
    ORDER_FORM_CLOSE: null,
    ADD_ITEM: null,
    CONTACT_FIELD_CHANGED: null,
    ITEM_FIELD_CHANGED: null,
    DATE_FIELD_CHANGED: null,
    CLEAR: null,
  },
  ANALYTICS: {
    MAP_DATA: {
      LOADING: null,
      LOADED: null,
    },
  },
  ALT_SCENARIOS: {
    FILTER_PARAMS_UPDATED: null,
    SCHEME_LOADED: null,
    TYPES: {
      LOADING: null,
      LOADED: null,
      LOAD_ERROR: null,
    },
    STATISTIC: {
      LOADING: null,
      LOADED: null,
      LOAD_ERROR: null,
    },
    ROUND_GRAPH: {
      LOADING: null,
      LOADED: null,
      LOAD_ERROR: null,
    },
    TAXES: {
      LOADING: null,
      LOADED: null,
      LOAD_ERROR: null,
    },
    ALL: {
      LOADING: null,
      LOADED: null,
    },
    UNMOUNTED: null,
  },
  REPORT_LIST: {
    REPORT_LIST_MOUNTED: null,
    LIST_MOUNTED: null,
  },
  REPORT_DETAILS: {
    REPORT_SELECTED: null,
  },
};

function updateKey(obj, key, value) {
  if (obj[key] === null) {
    return value;
  }

  if (typeof obj[key] === 'string') {
    return obj[key];
  }

  const answer = {};

  for (const innerKey in obj[key]) {
    answer[innerKey] = updateKey(obj[key], innerKey, value + '_' + innerKey);
  }

  return answer;
}

for (const key in actionTypes) {
  actionTypes[key] = updateKey(actionTypes, key, key);
}

for (const key in PAGINATED_LIST_CONSTANTS) {
  PAGINATED_LIST_CONSTANTS[key] = updateKey(PAGINATED_LIST_CONSTANTS, key, key);
}

export { actionTypes, PAGINATED_LIST_CONSTANTS };
