import { createAction } from 'redux-actions';

export const subject = '[List]';

export const mounted = createAction(
  `${subject} mounted`,
  payload => payload,
  (_, meta) => meta,
);

export const filter = createAction(
  `${subject} filter`,
  payload => payload,
  (_, meta) => meta,
);

export const paginate = createAction(
  `${subject} paginate`,
  payload => payload,
  (_, meta) => meta,
);

export const gotRequest = createAction(
  `${subject} got request`,
  payload => payload,
  (_, meta) => meta,
);

export const sort = createAction(
  `${subject} sort`,
  payload => payload,
  (_, meta) => meta,
);
