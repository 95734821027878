import React, { Fragment, useCallback, useState } from 'react';

import { ProductionLinesTableFieldModal } from './ProductionLinesTableFieldModal';
import { CountItems } from './ProductionLinesTableField.styled';
import { ProductionLineListItemType } from '../ProductionType';

export const ProductionLinesTableField = ({ productionLine }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const handlerOpenModal = useCallback(() => {
    setOpenModal(true);
  });

  const handlerCloseModal = useCallback(() => {
    setOpenModal(false);
  });

  return (
    <Fragment>
      {productionLine.productionLines[0]}{' '}
      {productionLine.productionLines.length > 1 && (
        <Fragment>
          <CountItems onClick={handlerOpenModal}>
            +{productionLine.productionLines.length - 1}
          </CountItems>

          <ProductionLinesTableFieldModal
            closeModal={handlerCloseModal}
            productionLine={productionLine}
            isOpen={isOpenModal}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

ProductionLinesTableField.propTypes = {
  productionLine: ProductionLineListItemType,
};
