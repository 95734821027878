import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from './HandAdd.actions';
import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';

const getKmStatusFilter = docType => {
  switch (docType) {
    case 'KZ_INTRODUCE_GOODS':
      return ['WITHDRAWN'];
    case 'MTTPS_90':
      return ['INTRODUCED', 'INTRODUCED_RETURNED', 'APPLIED'];
    case 'act':
      return ['APPLIED', 'INTRODUCED', 'INTRODUCED_RETURNED'];
    case 'KZ_EXPORT_NOTICE':
      return ['APPLIED', 'INTRODUCED', 'INTRODUCED_RETURNED'];
    default:
      return ['APPLIED'];
  }
};

function* getKMList({ payload }) {
  const { page, target, filterPackageTypeForGoods, productGroupIdForGoods} = payload;
  yield put(actions.isLoaded(true));
  const cisStatus = getKmStatusFilter(target);
  const body = {
    page,
    limit: 10,
    order: 'EMISSION_DATE',
    cisStatus,
    ...productGroupIdForGoods !== undefined ? { productGroupId: productGroupIdForGoods } : {},
    ...filterPackageTypeForGoods ? { cisPackageType: filterPackageTypeForGoods  } : {},
  };

  const requestPayload = {
    url: '/api/v3/facade/marked_products/search',
    method: 'post',
    data: body,
    preloading: false,
  };

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const codes = success.data.results.map(item => ({
      id: item.cis,
      ...item,
    }));
    yield put(actions.setKM(codes));
    yield put(actions.setTotal(success.data.total));
    yield put(actions.isLoaded(false));
  }
  if (error) {
    yield put(actions.isLoaded(false));
    Toast.showError({
      content: error.response.statusText,
    });
  }
}

export default function* watch() {
  yield takeEvery(actions.getKM, getKMList);
}
