import { intersection, isString, isUndefined } from 'lodash';

export const getColumnsByRole = (columns, roles) =>
  columns.filter(column => {
    const { accessRoles } = column;
    return !(accessRoles && !intersection(accessRoles, roles).length);
  });

export const getFilterParams = (savedString = null) => {

  const filters = {};
  const params = savedString
    ? savedString.slice(1).split('&')
    : window.location.search.slice(1).split('&');

  if (params.length) {
    params.forEach(p => {
      if (isString(p) && p.length) {
        const [key, value] = p.split('=');
        let prepared = decodeURIComponent(value);

        switch (prepared) {
          case 'true':
            prepared = true;
            break;
          case 'false':
            prepared = false;
            break;
          default:
            break;
        }

        filters[key] = prepared;
      }
    });
  }

  return filters;
};

export const setFilterParamsToUrl = params => {
  let result = '';

  Object.keys(params).forEach(k => {
    if (k && !isUndefined(params[k])) {
      if (result.length) result += '&';
      result += `${k}=${params[k]}`;
    }
  });

  return result;
};

export const saveToSessionStorage = (searchString, pathname = null) => {
  const key = pathname || window.location.pathname;
  sessionStorage.setItem(key, searchString);
};

export const getSearchString = (pathname = null) => {
  const key = pathname || window.location.pathname;
  const search = sessionStorage.getItem(key);
  if (!search) return window.location.search;
  if (window.location.search && search !== window.location.search)
    return window.location.search;
  return search;
};
