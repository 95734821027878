import styled from 'styled-components';

const PaginationWrapper = styled.div`
height: 60px;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export default PaginationWrapper;
