import styled from 'styled-components';

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 24px;
  color: #52535a;
`;

export const Header = styled.div`
  js-display: flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  white-space: normal;
  align-items: flex-start;
`;

export const HeaderText = styled.div`
  font-size: 24px;
`;

export const ActionBarWrap = styled.div`
  position: relative;
  top: -20px;
`;

export const Wrap = styled.div`
  position: relative;
  background: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  overflow: visible;
`;

export const ButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: 32px;
  left: 32px;
  height: 40px;
  transform: translateY(-50%);
  z-index: 20;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: 10px;
`;

export const CheckboxIconWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  border: 1px solid #52535a;
  cursor: pointer;
`;

export const CheckboxTitle = styled.div`
  font-size: 14px;
  color: #52535a;
  cursor: pointer;
`;

export const Controls = styled.div`
  js-display: flex;
  display: flex;
  justify-content: space-between;
  white-space: normal;
  align-items: flex-start;
`;

export const AcceptButtonWrap = styled.div`
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

export const HeadingWrap = styled.div`
  padding: 32px;
`;

export const DateSelectWrap = styled.div`
  min-width: 327px;
  width: 327px;
`;

export const YearMonthSelectorWrap = styled.div`
  width: 327px;
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  background: #fbfbfb;
  border-radius: 3px;
  z-index: 8;
  margin-bottom: 24px;
`;

export const YMContent = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #434244;
  z-index: 10;
  position: relative;
  cursor: ${props => (props.selected ? '' : 'pointer')};
`;

export const PaginationWrap = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 32px;
`;

export const MovingPart = styled.div`
  background: #f8ec31;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 164px;
  height: 40px;
  top: -5px;
  position: absolute;
  left: ${props => (props.isMonth ? '0' : '50%')};
  transition: 0.3s left linear;
  pointer-events: none;
`;

export const ValueHeading = styled.div`
  line-height: 18px;
  font-weight: 600;
  font-size: 16px;
  color: #434244;
  min-width: 200px;
  min-height: 38px;
  margin-bottom: 18px;
`;

export const ValueContent = styled.div`
  font-size: 28px;
  color: #55b5e7;
  display: flex;
  align-items: center;
  height: 60px;
`;

export const Rubel = styled.div`
  font-size: 16px;
  margin-left: 6px;
  padding-top: 10px;
`;

export const FilterWrap = styled.div`
  position: relative;
  top: 20px;
`;

export const PaginationCentrator = styled.div`
  display: flex;
  justify-content: center;
`;

export const filterButtonTheme = {
  Button: {
    fontSize: '16px',
    fontWeight: 400,
    large: {
      paddingLeft: '16px',
      paddingRight: '21px',
      height: '40px',
    },
    main: {
      border: 'none',
      color: '#434244',
      background: 'rgba(0,0,0,0)',
    },
    disabled: {
      color: '#ABADB6',
      background: 'rgba(0,0,0,0)',
    },
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
};

export const Years = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const Year = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #52535a;
  :hover {
    background: #f8ec31;
  }
`;
