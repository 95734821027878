import styled from 'styled-components';

export const Wrap = styled.div`
  height: 70px;
  background: #f9f9f9;
  padding-left: 40px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 12px;
  height: 100%;
`;

export const TextWrap = styled.div`
  margin-right: 40px;
  color: #63666a;
  font-size: 12px;
  min-width: 320px;
`;
