import { Modal } from '@crpt-ui/core';
import Tabs from '@crpt-ui/core/lib/Tabs/Tabs';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
// import LightTheme from 'src/common_components/_MaterialUi/LightTheme';
import Preloader from 'src/common_components/Preloader/Preloader';
import { PARTICIPANT_TYPES } from 'src/constants/participantTypes';

import { hardcodeRoles } from '../constants';
import * as actions from './ducks/UnloadServiceDetails.actions';
import * as selectors from './ducks/UnloadServiceDetails.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import EditRoles from './EditRoles/EditRoles';
import Info from './Info/Info';
import Unload from './Unload/Unload';
import { useStyles } from './UnloadServiceDetails.styles';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';
import { Translate } from '../../../common_components/Translate/Translate';

const UnloadServiceDetails = ({
  data: propsData,
  history,
  loaded,
  match,
  onMounted,
  onChangeResults,
  onChangeTask,
  onDownloadResults,
  onUnmount,
  isUserOGV
}) => {
  //this is temporal hack to disable unload service for OGV
  // cause OGV have 2 roles now: OPERATOR and OGV.
  // by role operator user have access to this route
  // but this is the hack on the backend...
  if(isUserOGV) {
    return (
      <Redirect to="/404" />
    )
  }
  const classes = useStyles();

  const task_id = _.get(match, 'params.id', '');

  const [tabTo, setTabTo] = useState('info');

  const data = useMemo(() => propsData, [propsData]);
  const codes = useMemo(() => _.get(data, 'list', []), [data]);
  const periodicity = useMemo(() => _.get(data, 'periodicity', ''), [data]);
  const downloadingStorageDays = useMemo(
    () => _.get(data, 'taskType.downloadingStorageDays', 30),
    [data],
  );
  const readonly = useMemo(() => _.get(data, 'readonly', false), [data]);

  const breadcrumbs = useMemo(
    () => [
      {
        href: '/unload-service/list',
        title: Translate('Выгрузки'),
        id: 1,
      },
      {
        href: `/unload-service/list/${task_id}`,
        title: (
          <span style={{ fontWeight: 600 }}>{_.get(data, 'name', '')}</span>
        ),
        id: 2,
      },
    ],
    [data, task_id],
  );

  const tabs = useMemo(
    () => [
      { id: 0, label: Translate('Общая информация'), to: 'info' },
      { id: 1, label: Translate('Выгрузки'), to: 'unload' },
    ],
    [],
  );

  const lastDownloadAvailableID = useMemo(() => {
    const lastDownloadAvailable = codes
      .filter(item => item.available === 'AVAILABLE')
      .slice(-1);
    return lastDownloadAvailable.length ? lastDownloadAvailable[0].id : false;
  }, [codes]);

  const currentTab = useMemo(() => tabs.find(tab => tab.to === tabTo), [
    tabs,
    tabTo,
  ]);

  const onTabClick = useCallback(to => setTabTo(to), []);

  const handleDownloadClick = useCallback(() => {
    if (lastDownloadAvailableID) {
      onDownloadResults([lastDownloadAvailableID]);
    }
  }, [lastDownloadAvailableID]);

  const rolesSelect = useMemo(
    () =>
      [
        ..._.get(data, 'availableOrgRoles', []),
        ...hardcodeRoles.filter(
          role => role.code !== PARTICIPANT_TYPES.IS_MP_OPERATOR,
        ),
      ].map((role, index) => ({
        key: index,
        label: role.name,
        value: role.code,
      })),
    [data],
  );

  const [isModalOpen, setModal] = useState(false);

  const setModalOpen = () => setModal(true);
  const setModalClose = () => setModal(false);

  const editRolesFormValues = useMemo(
    () => ({
      sharedRoles: [..._.get(data, 'sharedRoles', []).map(role => role.role)],
    }),
    [data],
  );

  const handleEditRoles = useCallback(values => {
    onChangeTask({ task_id, taskDto: { ...values } });
    setModalClose();
  }, []);

  useEffect(() => {
    onMounted({ task_id });
    return () => {
      onUnmount();
    };
  }, []);

  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <div className={classes.wrapper}>
        <Preloader isOpened={!loaded} isClosed={loaded}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Breadcrumbs
                withCustomLinks
                onClick={val => history.push(val.href)}
                breadcrumbs={breadcrumbs}
                paddingTop="0"
                paddingBottom="17px"
              />
            </Grid>

            {/* {lastDownloadAvailableID && (
              <Grid item>
                <div className={classes.downloadWrapper}>
                  <Link icon={<Download />} onClick={handleDownloadClick}>
                    СКАЧАТЬ
                  </Link>
                </div>
              </Grid>
            )} */}
          </Grid>

          <Tabs
            value={currentTab.id}
            items={tabs.map(tab => ({
              ...tab,
              onClick: () => onTabClick(tab.to),
            }))}
            variant="fullWidth"
          />

          <div className={classes.contentWrapper}>
            <Container show={tabTo === 'info'}>
              <Info data={data} onEditRoles={setModalOpen} />
            </Container>

            <Container show={tabTo === 'unload'}>
              <Unload
                codes={codes}
                downloadingStorageDays={downloadingStorageDays}
                onChangeResults={onChangeResults}
                onDownloadResults={onDownloadResults}
                periodicity={periodicity}
                readonly={readonly}
                task_id={task_id}
              />
            </Container>
          </div>
        </Preloader>
      </div>

      <Modal
        onClose={setModalClose}
        open={isModalOpen}
        fullWidth={true}
        maxWidth="lg"
      >
        <EditRoles
          formValues={editRolesFormValues}
          onClose={setModalClose}
          rolesSelect={rolesSelect}
          onSubmit={handleEditRoles}
        />
      </Modal>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => ({
  isUserOGV: authSelectors.isUserOGV(state),
  data: selectors.data(state),
  loaded: selectors.loaded(state),
});

const mapDispatchToProps = {
  onMounted: actions.mounted,
  onDownloadResults: actions.downloadResults,
  onChangeResults: actions.changeResults,
  onChangeTask: actions.changeTask,
  onUnmount: actions.unMounted,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(UnloadServiceDetails);

const Container = ({ show, children }) => {
  const classes = useStyles();
  return (
    <div
      style={{ display: show ? 'flex' : 'none' }}
      className={classes.container}
    >
      {children}
    </div>
  );
};
