import React from 'react';
import { ImportantBlock } from '../../ReportHelp.styled';

export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  afterTable: <ImportantBlock><strong>Важно!</strong> В отчёт выводятся первые 1000 строк</ImportantBlock>,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }   
      ],
      data: [
        {
          'col1': 'ИД розничной т.т.',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'ИНН',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Адрес установки контрольно- кассовой техники',
          'col2': 'Возможность фильтрации по доступным значениям'
        }
      ]
    }
  ]
}