import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Table from '@crpt/react-table';
import { withTheme } from 'styled-components';
import * as actions from './ducks/List.actions';
import TableScrollWrapper from './TableScrollWrapper/TableScrollWrapper';
import Pagination from './ReactTablePagination/ReactTablePagination';
import { Translate } from '../Translate/Translate';

class List extends React.Component {
  static propTypes = {
    theme: PropTypes.shape({}).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    options: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    getTableProps: PropTypes.func,
    getTbodyProps: PropTypes.func,
    getTrProps: PropTypes.func,
    getTdProps: PropTypes.func,
    style: PropTypes.shape({}),
    noDataText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    sortable: PropTypes.bool,
    meta: PropTypes.shape({}),
    sort: PropTypes.func.isRequired,
    getTheadThProps: PropTypes.func,
    height: PropTypes.string,
    defaultPageSize: PropTypes.number,
    minRows: PropTypes.number,
    hidden: PropTypes.bool,
    sortableLavel: PropTypes.bool,
    striped: PropTypes.bool,
    highlight: PropTypes.bool,
  };

  static defaultProps = {
    columns: [],
    options: [],
    data: [],
    getTableProps: undefined,
    getTbodyProps: undefined,
    getTrProps: undefined,
    getTdProps: undefined,
    style: undefined,
    noDataText: Translate('Нет данных для отображения'),
    sortable: false,
    meta: undefined,
    getTheadThProps: () => ({}),
    height: undefined,
    defaultPageSize: undefined,
    minRows: 0,
    hidden: false,
    showPagination: false,
    sortableLavel: false,
    striped: true,
    highlight: true,
  };

  state = {
    data: null,
  };

  sort = (a, b, id, direct) => {
    const first = a[id] || '';
    const second = b[id] || '';
    if (first > second) return 1 * direct;
    if (first < second) return -1 * direct;
    return 0;
  };

  sortDependence = (a, b, id, direct, sortDependence) => {
    if (a[sortDependence] === b[sortDependence] && this.state[sortDependence]) {
      return this.sort(a, b, id, direct);
    }
  };

  lavelSorting = (state, columnInfo) => {
    const { id, sortDependence } = columnInfo;
    const direct = this.state[id] ? this.state[id] * -1 : 1;
    const data = sortDependence
      ? this.state.data
        ? [...this.state.data]
        : [...this.props.data]
      : [...this.props.data];

    this.setState(prev => ({
      [id]: direct,
      data: data.sort((a, b) =>
        sortDependence && prev[sortDependence]
          ? this.sortDependence(a, b, id, direct, sortDependence)
          : this.sort(a, b, id, direct),
      ),
    }));
  };

  getTheadThProps = (state, a, columnInfo, listInfo) => {
    const { meta, sort, getTheadThProps } = this.props;

    if (this.props.sortableLavel && columnInfo.sortable) {
      return {
        ...getTheadThProps(state, a, columnInfo, listInfo),
        toggleSort: () => this.lavelSorting(state, columnInfo),
      };
    }

    if (!meta) return {};
    const { id, sortable } = columnInfo;

    return {
      ...getTheadThProps(state, a, columnInfo, listInfo),
      toggleSort: () => sortable !== false && sort(id, meta),
    };
  };

  render() {
    const {
      style,
      columns,
      options,
      data,
      getTableProps,
      getTbodyProps,
      getTrProps,
      getTdProps,
      theme,
      noDataText,
      sortable,
      height,
      defaultPageSize,
      minRows,
      hidden,
      fullHeight,
      showPagination,
      onPageChange,
      highlight,
      striped,
    } = this.props;

    return (
      <TableScrollWrapper
        height={height}
        hidden={hidden}
        fullHeight={fullHeight}
      >
        <Table
          theme={theme}
          style={style}
          columns={columns}
          options={options}
          data={this.state.data ? this.state.data : data}
          getTableProps={getTableProps}
          getTbodyProps={getTbodyProps}
          getTrProps={getTrProps}
          getTdProps={getTdProps}
          getTheadThProps={this.getTheadThProps}
          minRows={minRows}
          sortable={sortable}
          highlight={highlight}
          striped={striped}
          noDataText={noDataText}
          defaultPageSize={defaultPageSize}
          showPagination={showPagination}
          PaginationComponent={Pagination}
          onPageChange={onPageChange}
        />
      </TableScrollWrapper>
    );
  }
}

const mapDispatch = dispatch => ({
  sort: (column, meta) => dispatch(actions.sort(column, meta)),
});

export default compose(connect(null, mapDispatch), withTheme)(List);
