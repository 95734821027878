import { Icon } from '@crpt-ui/core';
import React from 'react';
import { Link } from 'src/fragments/Link';

import { useStyles } from '../Operation.styles';
import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';
import FourthCell from './components/FourthCell';

const Disaggregation = ({ op }) => {
  const classes = useStyles();

  return (
    <Layout>
      <FirstCell
        icon={<Icon name="Disaggregation" />}
        title={Translate('Дезагрегирование')}
      />
      <SecondCell firstHeading="Опт" op={op} />
      <ThirdCell useLink op={op} heading={Translate('Документ')} />
      {
        op.disaggregationCis ? (
          <FourthCell heading={Translate('Из блока с кодом')}>
              <Link
                className={classes.link}
                to={`/cis/list/${encodeURIComponent(
                  op.disaggregationCis,
                )}/basic-info`}
              >
                {op.disaggregationCis}
              </Link>
          </FourthCell>
        ) : null
      }
    </Layout>
  );
};

export default Disaggregation;
