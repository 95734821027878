import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';


const style = {
  width: 'auto',
  flexWrap: 'nowrap',
};

const CommandBar = ({ left, right, flexBasis }) => (
  <Grid container justify="space-between" alignItems="center" style={{flexBasis: flexBasis, ...style}}>
    <Grid item>
      <Grid container spacing={5} alignItems="center" flexWrap="nowrap">
        {left.map((item, index) => (
          <Grid item key={index}>
            {item}
          </Grid>
        ))}
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={5} alignItems="center" justify="flex-end" flexWrap="nowrap">
        {right.map((item, index) => (
          <Grid item key={index}>
            {item}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

CommandBar.propTypes = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
};

CommandBar.defaultProps = {
  left: [],
  right: [],
};

export default CommandBar;
