import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../_refactor/FieldsWrapper';
import DatePickerAdapter from '../_refactor/Adapters/DatePickerAdapter';
import TwoLineLabelAdapter from '../Adapters/TwoLineLabelAdapter';

const DatePicker = props => {
  const {
    name,
    format,
    validate,
    parse,
    visibility,
    disabled,
    labelOnDisabled,
    placeholder,
    ...passProps
  } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        {params => {
          if (params.disabled && labelOnDisabled) {
            return (
              <TwoLineLabelAdapter name={name} {...passProps} {...params} />
            );
          }

          return <DatePickerAdapter name={name} {...passProps} {...params} />;
        }}
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.string,
  labelOnDisabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  placeholder: undefined,
  errorPlaceholder: undefined,
  labelOnDisabled: false,
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
