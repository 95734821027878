import React, { Fragment, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import DataTable from '@crpt-ui/datatable';
import { Pagination } from '@crpt-ui/core';
import { ContentWrapper } from '../common/styled';
import { Preloader } from 'src/fragments/Table/Preloader';
import { ParticipantListFooter, ParticipantListCount } from './ParticipantList.styled';
import { Translate } from '../../common_components/Translate/Translate';
import ActionsRow from './Actions';

const ParticipantListView = memo((props) => {
  const { results, total, currentPage, pagesCount, initialState, onFilterApply, onPageChange, loaded, columns } =  props
  const instance = useRef();
  const resetFilters = () => instance.current.setAllFilters([]);

  return (
    <Fragment>
      <ActionsRow resetFilters={resetFilters} isActionsDisabled={!loaded}/>
      <ContentWrapper>
        <DataTable
          columns={columns}
          data={loaded ? results : []}
          initialState={initialState}
          ref={instance}
          useFilters
          onFiltersChange={onFilterApply}
        />
        <Preloader isOpen={!loaded}/>
        <ParticipantListFooter>
          <div>
            {(results.length > 0 && pagesCount > 1) && <Pagination
              page={currentPage}
              onPageChange={page => onPageChange(page, instance.current.state.filters)}
              pagesCount={pagesCount}
            />}
          </div>
          <ParticipantListCount>
            {Translate('Всего участников:')}{' '} {total}
          </ParticipantListCount>
        </ParticipantListFooter>
      </ContentWrapper>
    </Fragment>
  );
});

ParticipantListView.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  initialState: PropTypes.shape({
    filters: PropTypes.array,
  }).isRequired,
  onFilterApply: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default ParticipantListView;
