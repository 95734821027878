import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';

import FileUploadModalContent from '../../../../../common_components/Modals/FileUploadModal/FileUploadModal.content';
import * as actions from './ducks/Goods.actions';
import * as selectors from './ducks/Goods.selectors';
import * as authSelectors from '../../../../../common_components/Auth/Auth.selectors';
import { STEPS } from './Goods.constants';
import { getFilenameWithoutExtension } from '../../../../../utils';
import { ModalTitleWrapper, ModalTitle } from '../../Document/Document.styled';
import { Translate } from 'src/common_components/Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onConvert: PropTypes.func.isRequired,
  types: PropTypes.string.isRequired,
  step: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  errorText: PropTypes.string,
  formMutators: PropTypes.object.isRequired,
  json: PropTypes.string,
  file: PropTypes.object,
  inn: PropTypes.string,
  userId: PropTypes.any,
};

const defaultProps = {};

class GoodCodesUploadModalContent extends React.Component {
  onLoad = file => {
    console.log('onLoad', file);
    const { onCloseModal, formMutators, inn, userId, mode, formValues } = this.props;
    this.props.convert({ file, onCloseModal, formMutators, inn, userId, mode, formValues });
    this.props.onConvert();
  };

  onError = error => {
    console.log('onError', error);
  };

  onDrop = files => {
    this.props.load();
  };

  onCancelSign = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  onClose = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  getQty = file => {
    try {
      const qty = JSON.parse(file.content);
      return qty.filter(
        ({ receipt }) => receipt.code === 3 || receipt.code === 31,
      ).length;
    } catch (e) {
      return 0;
    }
  };

  render() {
    const { onCloseModal, types, step, errorText, file } = this.props;

    return (
      <FileUploadModalContent
        isError={step === STEPS.ERROR}
        errorContent={<div>{errorText}</div>}
        onCloseModal={this.onClose}
        types={types}
        isLoading={step === STEPS.LOADING}
        onError={this.onError}
        onLoad={this.onLoad}
        onDrop={this.onDrop}
        fileOpenParams={{
          asArrayBuffer: true,
        }}
        title={Translate('Добавление товара')}
        preloaderText={Translate('Загрузка файла. Пожалуйста, подождите')}
        customTitle={
          step === STEPS.PRELOAD || step === STEPS.LOADING ? (
            <ModalTitleWrapper>
              <ModalTitle>
                {Translate('Загрузка кодов маркировки товара')}
              </ModalTitle>
            </ModalTitleWrapper>
          ) : null
        }
        beforeLoadText={Translate(
          'Перетащите или выберите файл формата *.xls, *.xlsx',
        )}
      />
    );
  }
}

GoodCodesUploadModalContent.defaultProps = defaultProps;
GoodCodesUploadModalContent.propTypes = propTypes;

const mapState = state => {
  return {
    step: selectors.step(state),
    errorText: selectors.errorText(state),
    inn: authSelectors.userInn(state),
    userId: authSelectors.userId(state),
  };
};

const mapDispatch = dispatch => ({
  convert: file => dispatch(actions.convert(file)),
  load: () => dispatch(actions.load()),
  reset: () => dispatch(actions.reset()),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(GoodCodesUploadModalContent);
