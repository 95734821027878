import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { Icon } from '@crpt/react-icon';
import { Button, Select as SingleSelect } from '@crpt-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './filter.styled';
import { ARTICLE_ORDER_OPTIONS, ARTICLE_STATUS_OPTIONS, ARTICLE_TYPE_OPTIONS } from '../../constants';

export const Filter = ({ onFilterApply, open, onClose, filters }) => {
  const [state, setState] = useState({});
  const { t } = useTranslation();
  const styles = useStyles();

  useEffect(() => {
    setState({ ...filters });
  }, [filters])

  const onSubmit = () => {
    onFilterApply(state);
    onClose();
  }

  const onReset = () => {
    setState({});
    onFilterApply({});
    onClose();
  }

  const handleChange = (e, key) => {
    const newState = {
      ...state,
      [key]: e.target.value,
    }
    setState(newState);
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={styles.drawer}
      sx={{ width: 300 }}
    >
      <div className={styles.filterContainer}>
        <div className={styles.filterContent}>
          <div className={styles.filterHeader}>
            <h2>
              {t('Фильтр')}
            </h2>
            <Icon
              type="cross"
              width={19}
              height={19}
              onClick={onClose}
              fill="#52535a"
            />
          </div>
          <div className={styles.filters}>
            <SingleSelect
              onChange={(e) => handleChange(e, 'type')}
              value={state.type}
              label={t('Тип статьи')}
              options={ARTICLE_TYPE_OPTIONS}
            />
            <SingleSelect
              onChange={(e) => handleChange(e, 'status')}
              value={state.status}
              options={ARTICLE_STATUS_OPTIONS}
              label={t('Статус статьи')}
            />
            <SingleSelect
              onChange={(e) => handleChange(e, 'order')}
              value={state.order}
              options={ARTICLE_ORDER_OPTIONS}
              label={t('Порядковый номер')}
            />
          </div>
        </div>

        <div className={styles.filterActions}>
          <Button
            type="button"
            variant="text"
            size="md"
            onClick={onReset}
          >
            {t('Сбросить')}
          </Button>
          <Button
            type="button"
            variant="contained"
            size="md"
            icon={<Icon name='send'/>}
            onClick={onSubmit}
            className={"whiteColor"}
          >
            {t('Применить')}
          </Button>
        </div>
      </div>
    </Drawer>
  )
}
