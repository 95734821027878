import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Icon } from '@crpt/react-icon';
import FileLoader from '../../../FileLoader/FileLoader';
import Button from '../Button/Button';
import Input from '../_refactor/Input/Input';
import { downloadFile } from '../../../File/File.utils';

const displayNone = { display: 'none' };
const wrapperStyle = { display: 'flex' };

export const theme = {
  Button: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: '"SegoeUI", sans-serif',
    large: {
      paddingLeft: '24px',
      paddingRight: '24px',
      height: '48px',
    },
    normal: {
      paddingLeft: '19px',
      paddingRight: '19px',
      height: '40px',
    },
    main: {
      border: '0',
      color: '#52535A',
    },
    disabled: {
      border: '0',
      color: '#2D3D5B',
      background: '#e6e6e6',
    },
    minWidth: '108px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
};


class File extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    errorPlaceholder: PropTypes.string,
    formatPlaceholder: PropTypes.func,
    isError: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.func,
    accept: PropTypes.string.isRequired,
    name: PropTypes.string,
    validate: PropTypes.func,
    asText: PropTypes.bool,
    raw: PropTypes.bool,
    checkExists: PropTypes.bool,
  };

  static defaultProps = {
    name: '__form_File',
    icon: undefined,
    placeholder: 'Прикрепить файл',
    children: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    formMutators: undefined,
    errorPlaceholder: undefined,
    formatPlaceholder: undefined,
    isError: false,
    onChange: undefined,
    validate: undefined,
    raw: false,
    asText: false,
    checkExists: false,
  };

  state = {
    fileName: undefined,
  };

  onLoad = (file) => {
    const { fieldInput, onChange, raw } = this.props;
    const { content, name } = file;

    this.setState({ fileName: name });

    if (!raw && fieldInput) setTimeout(() => fieldInput.onChange(content), 0);
    if (onChange) onChange(file);
  };

  onError = error => console.log(error);

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError
      && fieldMeta
      && fieldMeta.dirtySinceLastSubmit
      && !fieldMeta.error
    ) return false;

    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  onClear = () => {
    const { fieldInput, onChange } = this.props;

    this.setState({ fileName: undefined });
    if (fieldInput) setTimeout(() => fieldInput.onChange(undefined), 0);
    if (onChange) onChange(undefined);
  };

  placeholderFormat = (value) => {
    const { formatPlaceholder } = this.props;
    return formatPlaceholder ? formatPlaceholder(value) : value;
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, fieldInput } = this.props;
    const { fileName } = this.state;
    const { value } = fieldInput;

    const isError = this.isError();
    if (fileName) return this.placeholderFormat(fileName);
    if (isError && errorPlaceholder) return errorPlaceholder;

    if (value) return 'file.pdf';
    return placeholder;
  };

  onDownloadClick = () => {
    const { fieldInput, formValues } = this.props;
    const { value } = fieldInput;
    const filename = get(formValues, '__form_document_number', 'file');
    downloadFile(value, `${filename}.pdf`);
  };

  render() {
    const {
      name,
      icon,
      accept,
      children,
      validate,
      disabled,
      fieldInput,
      raw,
      asText,
      checkExists,
      ...passProps
    } = this.props;
    const { value } = fieldInput;
    const { fileName } = this.state;
    const showDownload = disabled && !!value;
    const isNotExists = disabled && !value;

    return (
      <React.Fragment>
        {showDownload && (
          <Button
            fill="#55B5E7"
            color="#55B5E7"
            theme={theme}
            {...passProps}
            disabled={false}
            icon={icon}
            onClick={this.onDownloadClick}
          >
            {'Скачать PDF'}
          </Button>
        )}
        {!showDownload && (
          <FileLoader onLoad={this.onLoad} onError={this.onError} accept={accept} raw={raw} asText={asText}>
            {({ loading, onClick }) => {
              // если есть флаг проверки и файла нет, то кнопка не отображается
              if (checkExists && isNotExists) return '';
              if (children) return children({ loading, onClick, fileName });
              const placeholder = loading ? 'Загружается...' : this.getPlaceholder();

              return (
                <div style={wrapperStyle}>
                  <Button
                    fill="#55B5E7"
                    color="#55B5E7"
                    theme={theme}
                    marginBottom="0"
                    {...passProps}
                    icon={icon}
                    onClick={onClick}
                  >
                    {placeholder}
                  </Button>
                  {!!value && (<Icon type="cross" onClick={this.onClear} fill="#55B5E7" />)}
                </div>
              );
            }}
          </FileLoader>
        )}
        <div style={displayNone}>
          <Input name={name} disabled validate={validate} />
        </div>
      </React.Fragment>
    );
  }
}

export default File;
