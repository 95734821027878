import styled from 'styled-components';

const Header = styled.div`
  padding: 32px;
  background-color: white;
  font-size: 24px;
  color: #212c42;
`;

export { Header };
