import React, {useMemo, useState} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Select } from '@crpt-ui/core';
import { MuiThemeProvider } from "@material-ui/core";
import { ButtonWrapper, PackagingCodeContent, PackagingCodeTitle } from "./PackagingCode.styled";
import Modal from "../../../../common_components/Modals/Modal/Modal";
import { transportCodesGenerate } from './ducks/Aggregation.actions';
import {MaterialUiThemeUZ } from "../../../../common_components/_MaterialUi/ThemeUZ";

const PackagingCode = ({ isOpen, onCancel, generateCodes, updateField }) => {
    const [packageType, setPackageType] = useState(null);
    const { t } = useTranslation();

    const generateCode = () => {
        generateCodes({ packageType, updateField})
    }

    const options = useMemo(() => [
        {
            value: 'BOX',
            label: `${t('Короб')} (0)`
        },
        {
            value: 'PALLET',
            label: `${t('Палетта')} (1)`
        },
    ],[t])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onCancel}
            contentLabel={t("Выбор типа транспортной упаковки")}
        >
            <MuiThemeProvider theme={MaterialUiThemeUZ}>
                <div>
                    <PackagingCodeTitle>
                        {t("Выбор типа транспортной упаковки")}
                    </PackagingCodeTitle>
                    <PackagingCodeContent>
                        <Select
                            value={packageType}
                            label={t("Тип упаковки")}
                            onChange={(e)=> setPackageType(e.target.value)}
                            options={options}
                        />
                        <ButtonWrapper>
                            <Button
                                onClick={onCancel}
                            >
                                {t('Отмена')}
                            </Button>
                            <Button
                                onClick={generateCode}
                            >
                                {t('Подтвердить')}
                            </Button>
                        </ButtonWrapper>
                    </PackagingCodeContent>
                </div>
            </MuiThemeProvider>
        </Modal>
    )
}

const mapState = state => ({
    countryCode: state.config.countryCode,
});

const mapDispatch = dispatch => ({
    generateCodes: packageType => dispatch(transportCodesGenerate(packageType)),
});

export default connect(mapState, mapDispatch)(PackagingCode);
