import React from 'react';
import IconSelector from '../icon-selector';
import ImageUpload from '../image-upload';

export const ImageSelector = ({ lang, type, icon, setIcon, setBanner, banner }) => {
  return (
    <div>
      {
        type === 'article' && (<IconSelector lang={lang} icon={icon} setIcon={setIcon} />)
      }
      {
        type === 'carousel' && (<ImageUpload lang={lang} banner={banner} setBanner={setBanner} />)
      }
    </div>
  )

}
