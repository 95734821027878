export default {
  title: 'Табличное представление при типе агрегации «По городу» и «По GTIN»:',
  amountOfTable: 2,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'Торговый канал',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: 'География',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 450,
          subheaders: [
            {
              Header: 'Регион',
              accessor: 'col2',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: 'Город',
              accessor: 'col3',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: 'Численность населённого пункта',
              accessor: 'col4',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 210,
            },
          ]
        },
        {
          Header: 'Товар',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 410,
          subheaders: [
            {
              Header: 'Бренд',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: 'GTIN',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: 'Наименование товара',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
            },
          ]
        },
        {
          Header: 'Численная дистрибуция продаж, %',
          accessor: 'col8',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
        }
      ]
    },
// table#2
    {
      headers: [
        {
          Header: 'Взвешенная дистрибуция продаж',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
            Header: '% в шт.',
            accessor: 'col9',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 120,
            },
            {
              Header: '% в тенге.',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
          ]
        },
        {
        Header: 'Численная дистрибуция наличия в ассортименте, %',
        accessor: 'col11',
        style: {
          textAlign: 'center',
          justifyContent: 'center',
        },
        minWidth: 260,
        },
        {
          Header: 'Взвешенная дистрибуция наличия в ассортименте',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
              Header: '% в шт.',
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: '% в тенге.',
              accessor: 'col13',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
          ]
        },
        {
          Header: 'Численная дистрибуция отсутствия запасов, %',
          accessor: 'col14',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
        {
          Header: 'Взвешенная дистрибуция отсутствия запасов',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 240,
          subheaders: [
            {
              Header: '% в шт.',
              accessor: 'col15',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
            {
              Header: '% в тенге.',
              accessor: 'col16',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 120,
            },
          ]
        },
      ],
      data: [
        {
          'col9': [9],
          'col10': [10],
          'col11': [11],
          'col12': [12],
          'col13': [13],
          'col14': [14],
          'col15': [15],
          'col16': [16],
        }
      ]
    },
  ]
}
