import React from 'react';
import Button from '../../Form/Fields/Button/Button';
import { FormContext } from '../../Form/Form.constants';
import { ModalButtonWrapper } from './Buttons.style';

export default props => (
  <ModalButtonWrapper>
    <FormContext.Consumer>
      {({ reset }) => <Button name="__form_clear" marginBottom="0" onClick={() => reset()} {...props} />}
    </FormContext.Consumer>
  </ModalButtonWrapper>
);
