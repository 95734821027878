import {actionTypes} from '../../../constants';
import {ReportsService} from '../../../services/ReportsService';

export const onMount = tab => async dispatch => {
  if (!tab.getList) return;

  const list = await tab.getList();
  dispatch({
    type: actionTypes.REPORT_LIST.LIST_MOUNTED,
    value: {
      url: tab.url,
      list,
    }
  })
}