import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import * as grayAreaGtinsActions from '../../../GrayAreaAdditionalInfo/GrayAreaGtins/ducks/GrayAreaGtins.actions';
import * as grayAreaGtinsSelectors from '../../../GrayAreaAdditionalInfo/GrayAreaGtins/ducks/GrayAreaGtins.selectors';
import { manualDataKey } from '../Gtins.constants';
import GtinsModalView from './GtinsModal.view';
import { adaptFilters } from '../Gtins.utils';

const GtinsModal = ({
  gtins,
  loaded,
  onClose,
  onUnmount,
  onUpdateGtins,
  page,
  pagesCount,
  preselectedData,
  requestGtins,
  total,
}) => {
  const tableRef = useRef();

  // ID строк, которые выделены по умолчанию
  const preselectedDataIds = useMemo(() => preselectedData.map(item => item[manualDataKey]), [
    preselectedData,
  ]);

  const initSelectedRowIds = useMemo(
    () => Object.fromEntries(preselectedDataIds.map(id => [id, true])),
    [preselectedDataIds],
  );

  const initialDatatableState = {
    selectedRowIds: initSelectedRowIds,
  };

  const setCustomIdsToRows = useCallback(row => row[manualDataKey], []);

  /** Т.к. в таблице хранится только часть данных с бэка
   * сохраняем данные о выбранных строках в локальном стейте - selectedRows */
  const [selectedRows, setSelectedRows] = useState(preselectedData);
  const [isAddDisabled, setAddDisabled] = useState(true);

  /** При изменении выделенных ячеек изменяем локальный стейт (selectedRows)
   * и изменяем состояние кнопки "Добавить" */
  const onRowSelectHandle = useCallback(selectedRowIds => {
    const originalRows = _.get(tableRef, 'current.selectedFlatRows', []).map(
      selectedFlatRow => selectedFlatRow.original,
    );

    const rawSelectedRows = [...selectedRows, ...originalRows]
      .filter(row => Object.keys(selectedRowIds).includes(row[manualDataKey]));

    const uniqSelectedRows = _.uniqBy(rawSelectedRows, manualDataKey);

    setSelectedRows(uniqSelectedRows);

    const isEqualInitialAndSelected = _.isEqual(
      selectedRowIds, initSelectedRowIds,
    );
    setAddDisabled(isEqualInitialAndSelected || !uniqSelectedRows.length);
  }, [initSelectedRowIds, selectedRows]);

  const onAddHandle = useCallback(() => {
    onClose();
    onUpdateGtins(selectedRows);
  }, [selectedRows]);

  const onFiltersApply = useCallback(filters => {
    requestGtins({
      ...adaptFilters(filters),
    })
  }, []);

  const onPageChange = useCallback(page => {
    const filters = _.get(tableRef, 'current.state.filters', []);

    requestGtins({
      page,
      ...adaptFilters(filters),
    })
  }, [tableRef]);

  useEffect(() => {
    requestGtins();
    return () => onUnmount();
  }, []);

  return (
    <GtinsModalView
      gtins={gtins}
      initialDatatableState={initialDatatableState}
      isAddDisabled={isAddDisabled}
      loaded={loaded}
      onAddHandle={onAddHandle}
      onClose={onClose}
      onFiltersApply={onFiltersApply}
      onPageChange={onPageChange}
      onRowSelectHandle={onRowSelectHandle}
      page={page}
      pagesCount={pagesCount}
      setCustomIdsToRows={setCustomIdsToRows}
      tableRef={tableRef}
      total={total}
    />
  );
};

const mapStateToProps = state => ({
  gtins: grayAreaGtinsSelectors.result(state),
  loaded: grayAreaGtinsSelectors.loaded(state),
  page: grayAreaGtinsSelectors.page(state),
  pagesCount: grayAreaGtinsSelectors.pagesCount(state),
  total: grayAreaGtinsSelectors.total(state),
});

const mapDispatchToProps = {
  onUnmount: grayAreaGtinsActions.clear,
  requestGtins: grayAreaGtinsActions.requestGtins,
};

export default connect(mapStateToProps, mapDispatchToProps)(GtinsModal);
