import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { MuiThemeProvider } from '@material-ui/core';
import {
  SignModalWrap,
  ModalHeading,
  ModalContentWrap,
  ButtonWrap,
} from './Cis.styled';
import Input from 'src/common_components/Form/MaterialFields/Input/Input';
import { InfoFields } from '../../Document/Document.styled';
import {
  solveCodeValues,
  typeDocument,
} from '../ImportThirdCountry.constants';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionLargeButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import { submit } from '../ducks/ImportThirdCountry.actions';
import CountrySelect from 'src/common_components/Form/MaterialFields/Select/CountrySelect';
import Select from 'src/common_components/Form/MaterialFields/Select/Select';
import { isSignButtonDisabled } from './ducks/Cis.selectors';
import { MaterialUiThemeKZ } from 'src/common_components/_MaterialUi/ThemeKZ';
import { required } from 'src/common_components/Form/utils/validations';
import { validateDate } from '../../ImportDocument/ImportDocument.validations';
import DatePicker from '../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import TableCard from './SignModalContent/TableCard';
import { Translate } from 'src/common_components/Translate/Translate';
import { getProductItems } from '../ImportThirdCountry.utils';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  formMutators: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

const defaultProps = {};

const SignModalContent = (props) => {
  const { onCloseModal, formMutators } = props;
  const { isSignButtonDisabled, values, submit } = props;
  const [productItems, setProductItems]= useState([]);
  let currentProductItems = []
  let setTimeoutArray = [];
  useEffect(() => {
    formMutators.updateField('solve_date_time', values.solve_date + ' ' + values.solve_time);
    formMutators.updateField('recipient_inn_name', values.recipient_inn + ' ' + values.recipient_name);
  }, [values.solve_date, values.recipient_inn, values.recipient_name]);
  // const productItems = getProductItems(payload);

  const onSubmit = useCallback(() => submit({ ...values, productItems }), [values, productItems]);

  useEffect(() => {
    let size = 3;
    let partsOfCisArray = [];
    for (let i = 0; i < Math.ceil(values.cis.length / size); i++) {
      partsOfCisArray[i] = values.cis.slice((i * size), (i * size) + size);
    }
    setTimeoutArray = partsOfCisArray.map(chunk=> setTimeout(() => {
      // console.log(`Подготовлено кодов ${productItems.length} из ${values.cis.length}`);
      currentProductItems = [...currentProductItems, ...getProductItems({ ...values, cis: chunk })]
      setProductItems([...currentProductItems])
      },0)
    );
  },[])

  const closeModal = () => {
    setTimeoutArray.forEach(el=> clearTimeout(el))
    onCloseModal()
  }

  // console.log(`Подготовлено кодов ${productItems.length} из ${values.cis.length}`);
  const isDone = productItems.length === values.cis.length;
  return (
    <MuiThemeProvider theme={MaterialUiThemeKZ}>
      <SignModalWrap>
        <ModalHeading>
          {Translate('Заявление о ввозе товаров (третьи страны)')}
          <Icon type={'cross'} onClick={closeModal}/>
        </ModalHeading>
        <ModalContentWrap>
          <div>
            <InfoFields>
              <Input
                name="recipient_inn_name"
                placeholder={Translate('ИНН/БИН и наименование получателя')}
                disabled
                validate={required}
              />
              <Select
                placeholder={Translate(
                  'Тип документа таможенного оформления',
                )}
                name="type_document"
                options={typeDocument}
                defaultValue={typeDocument[0].value}
                disabled
                forceValidate
                required
                validate={required}
              />
              <CountrySelect
                placeholder={Translate('Страна экспорта')}
                name="country_export"
                disabled
                forceValidate
                required
                validate={required}
              />
              <DatePicker
                disabled
                required
                forceValidate
                name="registration_date"
                placeholder={Translate('Дата регистрации')}
                maxDate={new Date()}
                validate={(...vals) => {
                  return validateDate(...vals, 'DD.MM.YYYY');
                }}
              />
              <Select
                placeholder={Translate('Код решения')}
                name="solve_code"
                options={solveCodeValues}
                disabled
                forceValidate
                required
                validate={required}
              />
              <Input
                name="registration_number"
                placeholder={Translate('Регистрационный номер')}
                disabled
                forceValidate
                required
                validate={required}
              />
              <Input
                name="solve_date_time"
                placeholder={Translate('Дата и время решения')}
                disabled
                forceValidate
                required
                validate={required}
              />
              <Input
                name="customs_organization_code"
                placeholder={Translate('Код таможенного органа')}
                errorPlaceholder={Translate('Код таможенного органа')}
                disabled
                forceValidate
                required
                validate={required}
              />
            </InfoFields>
          </div>
        </ModalContentWrap>
        <TableCard values={values}/>
        <ButtonWrap>
          <PrimaryActionLargeButton
            disabled={isSignButtonDisabled || !isDone}
            onClick={onSubmit}
          >
            {isDone ? Translate('Подписать и отправить') : Translate('Идет загрузка...')}
          </PrimaryActionLargeButton>
          <CommonActionButton onClick={closeModal}>
            {Translate('Отменить')}
          </CommonActionButton>
        </ButtonWrap>
      </SignModalWrap>
    </MuiThemeProvider>
  );
};

SignModalContent.defaultProps = defaultProps;
SignModalContent.propTypes = propTypes;

const mapState = state => {
  return {
    isSignButtonDisabled: isSignButtonDisabled(state),
  };
};

const mapDispatch = dispatch => ({
  submit: values => dispatch(submit(values)),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(SignModalContent);
