import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: #434244;
  margin-bottom: 56px;
`;

export const Title = styled.span`
  font-size: 14px;
  margin-right: 10px;
`;

export const Count = styled.span`
  font-size: 24px;
  font-weight: bold;
`;
