import {actionTypes} from '../../constants/';

export function loginButtonDisabled(state = true, action = {}) {
  switch (action.type) {
    case actionTypes.LOGIN_FORM_KEP.CERTIFICATE_SELECTED:
      return false;

    default:
      return state;
  }
}
