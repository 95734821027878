import { handleActions } from 'redux-actions';
import * as actions from './Preloader.actions';

const isLoading = handleActions(
  {
    [actions.start]: () => true,
    [actions.end]: () => false,
  },
  false
);

export default { isLoading };
