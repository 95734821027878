import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import {
  Select as MaterialSelect,
  FormControl,
  MenuItem,
  InputLabel,
  FilledInput,
} from '@material-ui/core';

import { WrapIcon, WrapIcons } from './Select.style';

const Select = ({
  displayAllIcon,
  onChange,
  label,
  name,
  fields,
  defaultValue,
  children,
  options,
  menuProps,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = event => {
    const value = event.target.value;
    onChange(value);
    setValue(value);
  };

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <MaterialSelect
        value={value}
        onChange={handleChange}
        input={<FilledInput name={name} />}
        renderValue={selected => (options ? options(selected) : selected)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          ...menuProps,
        }}
        IconComponent={() => (
          <WrapIcons>
            {(value || (displayAllIcon && value)) && (
              <WrapIcon onClick={handleChange} isClicked>
                <Icon type="cross" fill="#434343" />
              </WrapIcon>
            )}
            {(!value || displayAllIcon) && (
              <WrapIcon>
                <Icon type="down-arrow" fill="#434343" />
              </WrapIcon>
            )}
          </WrapIcons>
        )}
        {...props}
      >
        {children
          ? children
          : fields.map(({ value, text, ...props }) => (
              <MenuItem key={value} value={value} {...props}>
                {text}
              </MenuItem>
            ))}
      </MaterialSelect>
    </FormControl>
  );
};

export default Select;

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  menuProps: PropTypes.object,
  options: PropTypes.func,
  displayAllIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  fields: PropTypes.array,
};

Select.defaultProps = {
  onChange: () => {},
  defaultValue: '',
  displayAllIcon: undefined,
  name: 'select',
  label: 'Название роли',
  fields: [
    { value: 'test1', a: 'a', text: 'Кладовщик0' },
    { value: 'test2', text: 'Кладовщик1' },
    { value: 'test3', text: 'Кладовщик2' },
    { value: 'test4', text: 'Кладовщик3' },
  ],
};
