import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { Translate } from '../../../common_components/Translate/Translate';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { TwoLineLabel } from '@crpt/react-label';
import React, { Fragment } from 'react';
import { documentTypeNames } from '../../../constants';
import { pgTypesNames } from '../../../constants/productGroups';


const InventoryInfo = ({ selectedDocument }) => {
    const { inventoryDate, inn, pg } = selectedDocument;
    const { status, receivedAt, documentId, docErrors, type } = selectedDocument;

    return (
        <Fragment>
            <HeadingWrap
                title={documentTypeNames[type]}
                id={documentId}
                status={status}
                date={receivedAt}
                docType={documentTypeNames[type]}
                needDocTitle={false}
                bottomId={true}
            />
            <Container>
                <Column>
                    <DocumentInfoBlock title={Translate('Общие данные')}>
                        <TwoLineLabel heading={Translate('ИНН/ПИНФЛ заявителя')}>{inn}</TwoLineLabel>
                        <TwoLineLabel heading={Translate('Товарная группа')}>{pg ? Translate(pgTypesNames[pg.toLowerCase()]) : '-'}</TwoLineLabel>
                        <TwoLineLabel heading={Translate('Дата инвентаризации')}>{inventoryDate}</TwoLineLabel>
                    </DocumentInfoBlock>
                  {(docErrors && docErrors.length !== 0) && (
                    <DocumentInfoBlock title={Translate('Ошибки')}>
                        {
                          docErrors.map((item) => (
                            <p>{item.join(' ')}</p>
                          ))
                        }
                    </DocumentInfoBlock>
                  )}

                </Column>
            </Container>
        </Fragment>
    )
}

export default InventoryInfo;
