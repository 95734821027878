import React from 'react';
import PropTypes from 'prop-types';
import { useMonth } from '@crpt-ui/core/lib/DatePicker/hooks';
import Day from './Day';
import { useTranslation } from 'react-i18next';

function Month({ year, month, firstDayOfWeek }) {
  const { days, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  const { t } = useTranslation()

  return (
    <div
      style={{
        borderTop: '1px solid #F2F2F2',
        padding: '16px',
      }}
    >
      <div style={{
        lineHeight: '20px',
        color: '#63666A',
        fontFamily: "'SegoeUI', sans-serif",
      }}>
        <span style={{
          fontSize: '12px',
          fontWeight: '600',
          textTransform: 'none',
        }}>{t(monthLabel)}</span>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyContent: 'center',
        gap: '1px',
      }}
      >
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }
          return <div key={index} />;
        })}
      </div>
    </div >
  );
}

Month.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  firstDayOfWeek: PropTypes.number,
};

export default Month;
