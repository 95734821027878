import React from 'react';
import { compose, branch, pure, renderNothing } from 'recompact';
import { Link, Links, Text, Title, Wrap } from './styled';
import { RegistrationSteps } from '../../constants';


const Complete = () => (
  <Wrap>
    <Title>Спасибо за регистрацию</Title>
    <Text>
      {'Ваше заявление отправлено.'}
      <br />
      {'Результат рассмотрения заявки будет выслан на указанную при регистрации электронную почту в течение 24 часов.'}
    </Text>
    <Links>
      <Link href="/">Перейти на сайт эксперимента</Link>
      <Link href="/">Выйти</Link>
    </Links>
  </Wrap>
);

export default compose(
  branch(({ step }) => step !== RegistrationSteps.COMPLETE, renderNothing),
)(Complete);
