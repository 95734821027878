import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@crpt/react-icon';
import { isUndefined, get } from 'lodash';
import { Wrap } from '../_refactor/Fields.styled';

const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  height: 100%;
  margin-bottom: ${props => props.marginBottom || '16px'};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin-right: 16px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const PlaceholderWrapper = styled.div`
  font-size: 16px;
  line-height: 1.57;
  color: ${props => (props.disabled ? '#b9b9b9' : '#212c42')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const Checkbox = props => {
  const { onClick, checked, placeholder, disabled } = props;
  let fillColor = '#0287B0';
  if (!checked) fillColor = '#52535A';
  if (disabled) fillColor = '#b9b9b9';

  return (
    <Wrapper>
      <IconWrapper onClick={onClick} disabled={disabled}>
        {checked && (
          <Icon type="checkbox-selected" size={16} fill={fillColor} />
        )}
        {!checked && <Icon type="checkbox-empty" size={16} fill={fillColor} />}
      </IconWrapper>
      <PlaceholderWrapper onClick={onClick} disabled={disabled}>
        {placeholder}
      </PlaceholderWrapper>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
};

const PureCheckbox = memo(Checkbox);

class CheckboxAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    formValues: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    onClick: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    formMutators: undefined,
    formValues: undefined,
    checked: undefined,
    disabled: false,
  };

  componentDidMount() {
    const { checked, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value)) {
      formMutators.updateField(name, !!checked);
    }
  }

  onClick = e => {
    const {
      fieldInput,
      onClick,
      disabled,
      formMutators,
      formValues,
    } = this.props;
    const value = !get(fieldInput, 'value', false);

    if (disabled) return;
    if (fieldInput) fieldInput.onChange(value);
    if (onClick) onClick(e, value, formMutators, formValues);
  };

  isError = () => {
    const { isError, fieldMeta } = this.props;

    if (
      isError &&
      fieldMeta &&
      fieldMeta.dirtySinceLastSubmit &&
      !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error && fieldMeta.touched);
  };

  getPlaceholder = () => {
    const { placeholder, errorPlaceholder, index, total } = this.props;
    const isError = this.isError();
    const newPlaceholder =
      isError && errorPlaceholder ? errorPlaceholder : placeholder;

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      disabled,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;

    return (
      <Wrap
        onBlur={fieldInput && fieldInput.onBlur}
        marginBottom={marginBottom}
      >
        <PureCheckbox
          {...passProps}
          onClick={this.onClick}
          checked={fieldInput && fieldInput.value}
          placeholder={this.getPlaceholder()}
          disabled={disabled}
        />
      </Wrap>
    );
  }
}

export default CheckboxAdapter;
