export default {
  title: 'Табличное представление при типе агрегации «По владельцу товара»:',
  amountOfTable: 3,
  tables: [
// table #1
    {
      headers: [
        {
          Header: 'GTIN',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Месяц производства',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Данные о текущем владельце',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 420,
          subheaders: [
            {
              Header: 'Тип участника',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 160,
              subheaders: []
            },
            {
              Header: 'ИНН',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 90,
              subheaders: []
            },
            {
              Header: 'Наименование',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
          ]
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
        }
      ]
    },
// table #2
    {
      headers: [
        {
          Header: 'Данные о текущем владельце',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 560,
          subheaders: [
            {
              Header: 'Юридический адрес',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 180,
              subheaders: []
            },
            {
              Header: 'ИД розничной т.т.',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 190,
              subheaders: []
            },
            {
              Header: 'Населённый пункт (по ФИАС)',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 190,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Статус',
          accessor: 'col11',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Количество месяцев с даты производства',
          accessor: 'col12',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 220,
          subheaders: []
        },
        {
          Header: 'Количество, шт',
          accessor: 'col13',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
      ],
      data: [
        {
          'col8': [8],
          'col9': [9],
          'col10': [10],
          'col11': [11],
          'col12': [12],
          'col13': [13],
        }
      ]
    },
  ]
}