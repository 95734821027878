import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: -3px;
`;

const ItemWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


const CISProducerName = ({ options }) => {
  const { _original } = options.producerName;
  const { senderName, receiverName } = _original;

  return (
    <Wrapper>
      <ItemWrapper>{senderName}</ItemWrapper>
      <ItemWrapper>{receiverName}</ItemWrapper>
    </Wrapper>
  );
};

export default CISProducerName;
