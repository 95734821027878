import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      boxShadow: '0 1px 0 #f2f2f2',
      display: 'flex',
      padding: '12px 16px',
    },
    icon: {
      width: 32,
      height: 32,
      background: '#f2f2f2',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 16,
    },
    label: {
      fontSize: 12,
      lineHeight: 16 / 12,
      letterSpacing: '0.02em',
    },
    value: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 16 / 14,
      letterSpacing: '0.02em',
    },
  });
