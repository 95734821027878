import { makeRadioGroupFilter } from '@crpt-ui/datatable';

import { reportStatusEnum, reportStatusValuesMap } from '../../constants';

const options = Object.keys(reportStatusEnum)
  .map(status => ({
    label: reportStatusValuesMap[status],
    value: reportStatusEnum[status]
  }));

export const ReportStatus = makeRadioGroupFilter({options});
