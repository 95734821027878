/* eslint-disable react/prop-types */
import React from 'react';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import Icon from '@crpt/react-icon';
import { useField } from 'react-final-form';

function InfoLabel(props) {
  const { visibility, customStyle } = props;

  const { input } = useField(name, props);

  if (!input.value || visibility) {
    return null;
  }

  return (
    <Wrapper customStyle={customStyle}>
      <Icon type="notification" size={24} fill="#52535A" spacingRight={16} />
      <Description>{input.value}</Description>
    </Wrapper>
  );
}

InfoLabel.defaultProps = {
  visibility: true,
};

export default withTheme(InfoLabel);

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  max-height: 120px;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #fdf8c8;
  color: #52535a;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 3px;
  margin-bottom: 20px;
  ${({ customStyle }) => customStyle}
`;

export const Description = styled.div`
  max-height: 110px;
  overflow: auto;
`;

export const ShowButton = styled.span`
  display: inline-block;
  color: #55b5e7;
  margin-left: 5px;
  cursor: pointer;
`;
