import React from 'react';
import PropTypes from 'prop-types';

import FieldsWrapper from '../FieldsWrapper';
import { getDisplayName } from '../../../hocs/utils';

const propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.string,
};

const defaultProps = {
  name: '__defaultForm',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  placeholder: undefined,
  errorPlaceholder: undefined,
};

const withInput = Component => {
  const WrappedComponent = props => {
    const { name, format, parse, validate, visibility, disabled, ...passProps } = props;

    return (
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
        {...passProps}
      >
        <Component name={name} {...passProps} />
      </FieldsWrapper>
    );
  };

  WrappedComponent.propTypes = propTypes;
  WrappedComponent.defaultProps = defaultProps;
  WrappedComponent.displayName = `WithInput(${getDisplayName(Component)})`;

  return WrappedComponent;
};

export default withInput;
