import React from 'react';
import {withRouter} from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    // maxWidth: '640px',
    display: 'inline-block',
    position: 'static',
    maxWidth: '90vw',
    maxHeight: '98vh',
    padding: '0',
    overflow: 'auto',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: '100',
  },
  overflow: 'visible'
};

// const ModalClose = styled.div`
//   cursor: pointer;
// `;

const ModalWrap = props => {
  const { children, style, ...other } = props;

  return (
    <Modal style={{...customStyles, ...style}} {...other}>
      {children}
    </Modal>
  );
};

export default withRouter(ModalWrap);
