export default {
  title: 'Настраиваемые фильтры:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Название фильтра',
          accessor: 'col1',
          minWidth: 200,
        },
        {
          Header: 'Значение фильтра',
          accessor: 'col2',
          minWidth: 560,
        }   
      ],
      data: [
        {
          'col1': 'Начало периода',
          'col2': 'По желанию можно выбрать любую дату в календаре'
        },
        {
          'col1': 'Окончание периода',
          'col2': 'По желанию можно выбрать любую дату в календаре (не ранее даты начала периода)'
        },
        {
          'col1': 'GTIN',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Наименование товара',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Производитель',
          'col2': 'Возможность фильтрации по доступным значениям'
        },
        {
          'col1': 'Страна производства',
          'col2': 'Возможность фильтрации по доступным значениям'
        }
      ]
    }
  ]
}