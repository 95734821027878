import { handleActions } from 'redux-actions';
import * as actions from './AikDisbandment.actions';


const data = handleActions(
  {
    [actions.loaded]: (state, { payload = null }) => payload,
    [actions.unmounted]: () => null,
  },
  null,
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  ''
);

export default {
  errorInfo,
  data
};
