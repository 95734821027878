import styled from 'styled-components';


export const ModalButtonWrap = styled.div`
  height: 70px;
  background-color: #f7f9f9;
  padding: 19px 32px 19px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;
