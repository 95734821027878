import { Button, Link, Typography } from '@crpt-ui/core';
import { Send } from '@crpt-ui/icons';
import {Signature} from '@crpt-ui/core'
import React from 'react';
import Preloader from 'src/common_components/Preloader/Preloader';
import { NoCertView } from 'src/fragments/Data/Certificate/NoCert';

import { useStyles } from './GrayAreaSignature.styles';
import { Translate } from '../../../common_components/Translate/Translate';

export const GrayAreaSignatureView = ({
  certData,
  signDocument,
  onClose = null,
  loaded,
  reloadCert,
  onDownload,
}) => {
  const styles = useStyles();
  return (
    <div className={styles.main}>
      <Preloader isOpened={!loaded} isClosed={loaded} fullHeight>
        {loaded && !certData &&
          <NoCertView reloadCert={reloadCert} />
        }
        <div className={styles.infoContentWrapper}>
          <div className={styles.aboutWrapper}>
            <Typography variant="body1">
              {Translate('Перед подписанием и отправкой уведомления о временно не прослеживаемых кодах идентификации, скачайте и ознакомьтесь со сформированной выгрузкой.')}
               <Link onClick={onDownload}>Скачать</Link>
            </Typography>
          </div>
          <div className={styles.notificationWrapper}>
            <Typography style={{fontWeight: '600'}} variant="body1">
              {Translate('После успешной обработки уведомления не агрегированные и не списанные коды будут отнесены к серой зоне, согласно периоду выгрузки. Вы уверенны в отправке уведомления о временно не прослеживаемых кодах идентификации?')}

            </Typography>
          </div>
        </div>
        <div className={styles.certInfoWrapper}>
          {certData &&
            <Signature certificate={certData} />
          }
        </div>
        <div className={styles.actionsWrapper}>
          <Button onClick={signDocument} disabled={certData === null} icon={<Send/>} size="md" variant="contained">
            {Translate('Подписать и отправить')}
          </Button>

          {onClose && (
            <Button onClick={onClose} size="md" variant="outlined">
              {Translate('Отмена')}
            </Button>
          )}
        </div>
      </Preloader>
    </div>
  );
};



