import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import StyledPopup from '../Popup/StyledPopup/StyledPopup';


const propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({})),
  positionY: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  menu: undefined,
  positionY: 'top',
  disabled: false,
};

const MenuButton = props => {
  const { menu, positionY, disabled, children, ...pass } = props;

  return (
    <StyledPopup menu={menu} positionY={positionY} disabled={disabled} {...pass}>
      <Button disabled={disabled} {...pass}>{children}</Button>
    </StyledPopup>
  );
};


MenuButton.propTypes = propTypes;
MenuButton.defaultProps = defaultProps;

export default MenuButton;
