import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import * as actions from './ducks/Draft.actions';
import * as selectors from './ducks/Draft.selectors';
import * as authSelectors from '../../../../common_components/Auth/Auth.selectors';
// import * as preloaderSelectors from '../../../_Preloader/ducks/Preloader.selectors';
import * as listActions from '../../../../common_components/List/ducks/List.actions';
import * as sidebarActions from '../../../../common_components/Sidebar/ducks/Sidebar.actions';
import Filters from '../../../../common_components/List/Filters/Filters';
import FiltersButton from '../../../../common_components/Buttons/FiltersButton';
import { prepareFilters, prepareToRange } from './Draft.utils';
import DraftView from './Draft.view';
import { /*AppendButton, EditButton, */ DeleteButton } from './Draft.commons';
import { columns, options } from './Draft.constants';
import DraftFilters from './DraftFilters';
import { openFile } from '../../../../common_components/File/File.utils';

class Draft extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    results: PropTypes.shape({}).isRequired,
    filters: PropTypes.shape({}).isRequired,
    sorting: PropTypes.shape({}).isRequired,
    pagesCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    onMount: PropTypes.func.isRequired,
    onListMount: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onOpenSidebar: PropTypes.func.isRequired,
    onFilterApply: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    actionBarLeft: PropTypes.arrayOf(PropTypes.element).isRequired,
    // isLoading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  listRef;

  state = {
    update: undefined,
    preparedFilters: null,
    filters: null,
  };

  componentDidMount() {
    const { onMount, onListMount, setSorting } = this.props;
    setSorting({
      orderDirection: 'DESC',
      orderColumn: 'dateSaved',
    });
    onMount();
    onListMount();
  }

  componentWillUnmount() {
    this.props.onUnMount();
  }

  onReset = () => {
    const { onOpenSidebar, countryCode } = this.props;
    onOpenSidebar(
      <Filters
        onClear={this.onReset}
        data={{}}
        onSubmit={this.onFilterApply}
        filters={<DraftFilters countryCode={countryCode} />}
      />,
    );
  };

  onOpenFiltersSidebar = () => {
    const { onOpenSidebar, filters, countryCode } = this.props;
    const preparedFilters = prepareToRange(filters);

    onOpenSidebar(
      <Filters
        onClear={this.onReset}
        data={preparedFilters}
        onSubmit={this.onFilterApply}
        filters={<DraftFilters countryCode={countryCode} />}
      />,
    );
  };

  onFilterApply = params => {
    const { onFilterApply } = this.props;
    const prepared = prepareFilters(params);

    onFilterApply(prepared);
  };

  getActionBarRight = () => {
    return [
      <FiltersButton onClick={this.onOpenFiltersSidebar} />,
      // <DownloadButton
      //   items={[
      //     {
      //       title: 'Скачать CSV',
      //       onClick: this.onDownloadItems,
      //     },
      //   ]}
      // />,
    ];
  };

  onListRef = ref => {
    this.listRef = ref;
  };

  onUpdate = () => {
    this.setState({ update: new Date().getTime() });
  };

  // onAppendItem = () => {
  //   const { history } = this.props;
  //   history.push('/registry/delegation/new');
  // };

  getSelectedIds = () => {
    const { results } = this.props;
    const { selectedRows } = this.listRef.state;
    const indices = selectedRows.map(v =>
      parseInt(('' + v).replace('select-', ''), 10),
    );

    const rows = indices.map(i => results.data.result[i]);
    return rows.map(item => item.draft_uuid);
  };

  // onEditItem = () => {
  //   const { history } = this.props;
  //   const selectedIds = this.getSelectedIds();
  //   history.push(`/registry/delegation/${selectedIds[0]}`);
  // };

  onDeleteItems = () => {
    const { onRemove } = this.props;
    const selectedIds = this.getSelectedIds();

    this.listRef.clearSelected();
    onRemove(selectedIds);
  };

  // onDownloadItems = () => {
  //   const { onDownload } = this.props;
  //   const selectedIds = this.getSelectedIds();
  //   onDownload(selectedIds);
  // };

  onShowPdf = id => {
    const { results } = this.props;
    const row = results.data.result.find(i => i.id === id);
    const { pdf } = row;
    openFile({ success: { payload: { data: pdf } } });
  };

  getCommandBarLeft = () => {
    const { isAdmin } = this.props;
    const { selectedRows = [] } = (this.listRef && this.listRef.state) || {};
    if (!isAdmin) return [];
    return [
      <DeleteButton
        onClick={this.onDeleteItems}
        disabled={selectedRows.length > 0 ? false : true}
      />,
    ];
    // return [
    //   // <AppendButton onClick={this.onAppendItem} />,
    //   // ...(selectedRows.length === 1 && [
    //   //   <EditButton onClick={this.onEditItem} />,
    //   // ]),
    //   ...(selectedRows.length > 0 && [
    //     <DeleteButton onClick={this.onDeleteItems} disabled={true}/>,
    //   ]),
    // ];
  };

  getTdProps = (_, rowInfo) => {};

  getTheadThProps = (state, _, column) => {
    const { sorting } = this.props;
    const { orderDirection, orderColumn } = sorting;
    const { id } = column;

    if (id === orderColumn) {
      return {
        className: `-sort-${orderDirection.toLowerCase()}`,
      };
    }

    return {};
  };

  render() {
    const {
      pagesCount,
      actionBarLeft,
      selectedPage,
      // isLoading,
      results,
    } = this.props;

    return (
      <DraftView
        data={results.data.result}
        columns={columns}
        options={options}
        getTdProps={this.getTdProps}
        getTheadThProps={this.getTheadThProps}
        // isLoading={isLoading}
        pagesCount={pagesCount}
        selectedPage={selectedPage}
        actionBarLeft={actionBarLeft}
        actionBarRight={this.getActionBarRight()}
        commandBarLeft={this.getCommandBarLeft()}
        onListRef={this.onListRef}
        onUpdate={this.onUpdate}
      />
    );
  }
}

const mapState = state => ({
  results: selectors.results(state),
  pagesCount: selectors.pagesCount(state),
  selectedPage: selectors.selectedPage(state),
  sorting: selectors.sorting(state),
  filters: selectors.filters(state),
  // isLoading: preloaderSelectors.loading(state),
  isAdmin: authSelectors.isAdmin(state),
  countryCode: state.config.countryCode,
});

const mapDispatch = dispatch => ({
  setSorting: payload => dispatch(actions.setSorting(payload)),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted(undefined, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onRemove: ids => dispatch(actions.remove(ids)),
  onDownload: ids => dispatch(actions.download(ids)),
});

export default compose(connect(mapState, mapDispatch), withRouter)(Draft);
