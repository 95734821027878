import { takeEvery, put, select } from 'redux-saga/effects';
import { get, last, head } from 'lodash';
import * as actions from './CisList.actions';
import * as selectors from './CisList.selectors';
import * as listActions from '../../../common_components/List/ducks/List.actions';

const sortColumnMapper = columnName => {
  switch (columnName) {
    case 'emd':
      return 'emissionDate';
    default:
      return columnName;
  }
};

function* requestSaga(action) {
  const { payload = {} } = action;
  const { page } = payload;

  if (page) {
    yield put(actions.updatePage(page));

    const paginationParams = {
      page,
    };

    yield put(actions.pagination(paginationParams));
  } else {
    yield put(actions.pagination({}));
  }
  const sorting = yield select(selectors.sorting);
  const filters = yield select(selectors.filters);

  const params = new URLSearchParams();
  params.append('limit', '10');
  params.append('order', 'EMISSION_DATE');
  params.append('page', page ? "" + (page - 1) : "0");
  Object.keys(filters).forEach(key => {
    const value = filters[key];

    if (Array.isArray(value)) {
      value.forEach(val => params.append(key, val));
    } else {
      params.append(key, value);
    }
  });

  console.log('params', params);
  console.log('sorting', sorting);

  const requestPayload = {
    url: '/api/v3/facade/marked_products/list',
    method: 'get',
    params,
  };

  console.log('requestPayload for CisList', requestPayload);

  yield put(listActions.gotRequest(requestPayload, actions));
}

function* sortSaga(action) {
  const { payload } = action;

  const sorting = yield select(selectors.sorting);
  const { order, orderColumn } = sorting;

  let newOrder = 'ASC';
  if (payload === orderColumn) {
    newOrder = order === 'ASC' ? 'DESC' : 'ASC';
  }

  yield put(actions.setSorting({ order: newOrder, orderColumn: payload }));
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.sort, sortSaga);
}
