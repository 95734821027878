import { takeEvery, call, put, select } from 'redux-saga/effects';
import { get, intersection } from 'lodash';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { AuthService } from '../../../services/AuthService';
import {
  saveToken,
  saveCertSerial,
  saveCertSerialType,
} from '../../../utils/cookieHelpers';
import * as actions from './LoginFormKep.actions';
import * as selectors from './LoginFormKep.selectors';

import { Toast } from '../../../utils/Toast';
import { getUserFromToken } from '../../../utils/userUtils';
import { PARTICIPANT_TYPES } from '../../../constants/participantTypes';

function* loginSaga(action) {
  const { payload } = action;
  const apiUrl = yield select(selectors.getAuthURL);

  try {
    const answer = yield call(AuthService.loginByKep, payload.cert, apiUrl);
    const url = answer.request.responseURL || '';

    if (url.indexOf('Error') !== -1) {
      yield put(actions.errors());
    } else {
      yield call(saveToken, answer.data.token);
      const serial = yield call(get, payload, 'cert.info.3');
      //const certType = yield call(get, payload, 'cert.certType'); not used in ELK
      yield call(saveCertSerial, serial);
      //yield call(saveCertSerialType, certType); not used in ELK
      yield put(actions.errors());
      yield put(push('/'));

      const user = yield call(getUserFromToken, answer.data.token);
      const orgRoles = user.authorities
        .filter(v => ~v.indexOf('ROLE_ORG_'))
        .map(v => v.replace('ROLE_ORG_', ''));

      if (
        !intersection(orgRoles, [
          PARTICIPANT_TYPES.IS_MP_OPERATOR,
          PARTICIPANT_TYPES.OGV,
        ]).length
      ) {
        Toast.showInfo({
          content: `Уважаемый участник оборота товаров! Вам необходимо будет заключить договор с Оператором ЦРПТ. Договор будет доступен в личном кабинете позже. 
        Смотрите за новостями.`,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 60000,
        });
      }
    }
  } catch (e) {
    console.log({ e });
    const message = yield call(
      get,
      e,
      'response.data.error_message',
      'Функциональность регистрации и авторизации в ИС МП в данный момент недоступна',
    );
    yield put(actions.errors(message));
  }
}

export default function* watchActions() {
  yield takeEvery(actions.submit, loginSaga);
}
