import styled from 'styled-components';

export const Wrap = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 50px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  line-height: 24px;
  color: #52535a;
`;

export const Text = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #212c42;
  max-width: 444px;
  font-weight: 300;
`;

export const Link = styled.a`
  font-size: 16px;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #55b5e7;
  margin-right: 50px;
  &:last-child {
    margin-right: 0px;
  }
  &:first-child {
    margin-left: auto;
  }
`;

export const Links = styled.div`
  display: flex;
`;
