import { compose } from 'redux';
import * as _ from 'lodash';
import moment from 'moment';
import { convertDateTimeToISOString } from '../../ducks/GrayArea.utils';
import i18next from 'i18next';

class InformationHelper {
  update({ state, payload }) {
    return ({
      ...state,
      ...payload,
    })
  }

  setErrors({ state, payload }) {
    return ({
      ...state,
      hasErrors: payload,
    })
  }

  formValidator(values) {
    return compose(
      requiredFields(values),
      closingPeriodRules(values),
    )({})
  }
}

const validateTimeObject = (time) => {
  return time && time.hours !== '' && time.minutes !== '' && time.seconds !== '';
}

const requiredFields = (values) => errors => {
  const { information: { fromDate, toDate, fromTime, toTime } = {} } = values;

  if (!fromDate) {
    _.set(errors, 'information.fromDate', i18next.t('Начало периода не указано'))
  }

  if (!toDate) {
    _.set(errors, 'information.toDate', i18next.t('Окончание периода не указано'))
  }

  if(!validateTimeObject(toTime)) {
    _.set(errors, 'information.toTime', i18next.t('Время окончания периода не указано'))
  }

  if(!validateTimeObject(fromTime)) {
    _.set(errors, 'information.fromTime', i18next.t('Время начала периода не указано'))
  }

  return errors;
};

const closingPeriodRules = values => errors => {
  const { information: { fromDate, toDate, fromTime, toTime } = {} } = values;
  const fieldsDate = ['information.fromDate', 'information.toDate'];

  if (!fromDate || !toDate) {
    return errors;
  }

  const fromDateTimeMoment = moment(convertDateTimeToISOString(fromDate, fromTime));
  const toDateTimeMoment = moment(convertDateTimeToISOString(toDate, toTime));
  const diffMs = toDateTimeMoment.diff(fromDateTimeMoment);
  const maxDurationMs = 31 * 24 * 60 * 60 * 1000;

  if ( diffMs <= 0 ) {
    const message = i18next.t('Начало периода не может быть больше окончания периода');
    return setEqualErrors({ fields: [...fieldsDate], errors, message });
  }

  if ( diffMs > maxDurationMs ) {
    const message = i18next.t('Формирование выгрузки доступно для периода 31 день и менее');
    return setEqualErrors({ fields: [...fieldsDate], errors, message });
  }

  return errors;
}


const setEqualErrors = ({ fields, errors, message }) => {
  fields.forEach(field => _.set(errors, field, message));
  return errors;
}

export default InformationHelper;
