export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Производитель',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'Доля рынка по реализации товаров',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 360,
          subheaders: [
            {
              Header: '% в штуках',
              accessor: 'col2',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 180,
              subheaders: []
            },
            {
              Header: '% в тенге (по цене продажи)',
              accessor: 'col3',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 180,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
        }
      ]
    }
  ]
}
