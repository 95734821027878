import { authInstance } from './authInstance';
import { CadesUtil } from '../utils/CadesUtil';
import Crypto, { Base64 } from '@crpt-ui/crypto';

import { ROLES } from '../constants/auth';
import { b64DecodeUnicode, b64EncodeUnicode } from '../utils/base64';
import { hexMaskToArrayItems } from '../utils/token';
import { Toast } from '../utils/Toast';
import { Signer } from '../utils/Signer';
import {getToken} from '../utils/userUtils';
import {getCertSerial} from '../utils/cookieHelpers';

export class AuthService {
  static login(data, apiUrl) {
    return authInstance.request({
      timeout: 60000,
      url: apiUrl ? apiUrl : '/api/v3/facade/auth/login',
      method: 'post',
      data: {
        ...data,
      },
    });
  }

  static getUserToken() {
    return getToken();
  }

  static getRoles() {
    const token = AuthService.getUserToken();
    const payload = token.split('.')[1];
    const payloadObj = JSON.parse(b64DecodeUnicode(payload));
    payloadObj.roles = hexMaskToArrayItems(
      payloadObj.perms,
      Object.keys(ROLES)
    );

    console.log('payloadObj', payloadObj);

    return payloadObj;
  }

  static getCertKey(apiUrl) {
    return authInstance.request({
      url: apiUrl ? apiUrl : '/api/v3/facade/auth/key',
      method: 'get',
      headers: {
        Authorization: null,
        Pragma: "no-cache",
        "Cache-Control": "no-cache"
      }
    });
  }

  static getTokenBySignedMessage(data, url) {
    return authInstance.request({
      url: url || '/api/v3/facade/auth',
      method: 'post',
      data,
      headers: {
        Authorization: null,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
    });
  }

  static async loginByKep(cert, url) {
    const keyAnswer = await AuthService.getCertKey();
    const uuid = keyAnswer.data.uuid;
    const data = keyAnswer.data.data;
    let signed;
    try {
      const base64_data = Base64.encode(data);
      signed = await Crypto.sign(base64_data, cert, false);
    } catch (e) {
      const message = CadesUtil.prepareError(e);
      if (message) {
        Toast.showError({ content: message });
        return Promise.reject('error while signing');
      } else {
        return Promise.reject(e);
      }
    }

    try {
      const answer = await AuthService.getTokenBySignedMessage({
        uuid: uuid,
        data: signed,
      }, url);
      return Promise.resolve(answer);
    } catch (e) {
      console.log('error while getting token', {e});
      return Promise.reject(e);
    }
  }
}
