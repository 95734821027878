import { get, isArray, isUndefined } from 'lodash';
import { Toast } from '../../utils/Toast';

const ignoreGenericMessageUrls = ['/api/v3/facade/doc/transform'];

const notifyProcess = (message, status, url) => {
  // 401 - ошибка авторизации, при ней перекидывает на логин
  const ignore = ignoreGenericMessageUrls.some(ignore => url && ~url.indexOf(ignore));

  if (
    typeof message === 'string' &&
    /^[34]/.test(`${status}`) &&
    status !== 401
  ) {
    /* кроссбраузерность для IE */
    const browser = window.navigator.userAgent.toLowerCase();
    if (/trident/gi.test(browser) || /msie/gi.test(browser)) {
      Toast.showError({ content: message });
    }

    if (url.indexOf('/products/list/') !== -1 && status === 404) {
      Toast.showError({ content: 'Товар не найден' });
    } else if (
      message.indexOf('Organisation with inn') !== -1 &&
      status === 404
    ) {
      Toast.showError({
        content: `2920: Организация ( ИНН ${message.match(/\d+/)} ) не найдена`,
      });
    } else if (
      message.indexOf('Requested entity was not found') !== -1 &&
      status === 404
    ) {
      Toast.showError({ content: '2920: Организация не найдена' });
    } else {
      Toast.showError({ content: message });
    }
  }
  if (isUndefined(status)) {
    !ignore && Toast.showError({ content: 'Сервер временно недоступен.' });
  }
  if (status === 503) {
    !ignore && Toast.showError({ content: 'Сервер недоступен' });
  }
  if (status === 500) {
    !ignore &&
      Toast.showError({
        content: 'Невозможно выполнить запрос. Внутренняя ошибка сервера.',
      });
  }
};

export const notifyMessageHelper = error => {
  let message;
  let description;
  const type = get(error, 'response.data');
  const status = get(error, 'response.status', '');
  const url = get(error, 'response.request.responseURL');

  if (type instanceof Blob) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      try {
        message = get(JSON.parse(fileReader.result), 'error_message', 'error');
      } catch (e) {
        message = 'wrong blob';
      }
      notifyProcess(message, status, url);
    };
    fileReader.onerror = () => {
      message = 'wrong blob';
      notifyProcess(message, status, url);
    };

    fileReader.readAsText(type);
  } else if (isArray(type)) {
    // TODO: remove double check when back-end on LP and EDO will decide, who should fix it
    message =
      get(error, 'response.data[0].error_message') ||
      get(error, 'response.data[0].errorMessage');
    notifyProcess(message, status, url);
  } else {
    // TODO: remove double check when back-end on LP and EDO will decide, who should fix it
    message =
      get(error, 'response.data.error_message') ||
      get(error, 'response.data.errorMessage');
    description = get(error, 'response.data.description');
    if (description) message = `${message ? `${message}.` : ""} ${description || ""}`;
    notifyProcess(message, status, url);
  }
};
