import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';

import * as grayAreaSearchGtinsActions
  from '../../GrayAreaAdditionalInfo/GrayAreaSearchGtins/ducks/GrayAreaSearchGtins.actions';
import * as grayAreaSearchGtinsSelectors
  from '../../GrayAreaAdditionalInfo/GrayAreaSearchGtins/ducks/GrayAreaSearchGtins.selectors';
import { actionTypes } from '../GrayAreaCreate.constants';
import { manualDataKey, modalActionTypes } from './Gtins.constants';
import { initialModalState, reducerModal } from './Gtins.reducers';

import GtinsView from './Gtins.view';
import { Translate } from '../../../../common_components/Translate/Translate';

const Gtins = ({
  clearSearchGtins,
  data,
  dispatch,
  requestSearchGtins,
  searchGtins,
}) => {
  const tableRef = useRef();

  const [stateModal, dispatchModal] = useReducer(reducerModal, initialModalState);

  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [gtinsFromFile, setGtinsFromFile] = useState([]);
  const setCustomIdsToRows = row => row[manualDataKey];

  const makeAction = useCallback(
    ({ actionType, result_ids }) => {
      dispatch({ type: actionType, payload: result_ids });
    },
    [],
  );

  const makeCallback = actionType => (...rows) => {
    makeAction({
      actionType,
      result_ids: rows.map(row => row.original[manualDataKey]),
    });
  };

  const actions = [
    {
      label: Translate('Удалить'),
      callback: makeCallback(actionTypes.GTINS.DELETE),
    },
  ];

  const bulkActions = useMemo(() => {
    const selectedFlatRows = _.get(tableRef, 'current.selectedFlatRows', []);

    return actions.map(action => ({
      onClick: () => action.callback(...selectedFlatRows),
      label: action.label,
    }));
  }, [selectedRowIds, tableRef.current]);

  const bulkActionsDisabled = useMemo(() => _.keys(selectedRowIds).length === 0, [
    selectedRowIds,
  ]);

  const onUpdateGtins = useCallback(selectedRows => {
    dispatch({ type: actionTypes.GTINS.UPDATE, payload: selectedRows });
  }, []);

  const onReadParseFileSuccess = useCallback(result => {
    dispatchModal({ type: modalActionTypes.UPLOAD_GTINS_MODAL.OPEN });
    setGtinsFromFile(result);
  }, []);

  const onUploadGtins = useCallback(() => {
    dispatchModal({ type: modalActionTypes.UPLOAD_GTINS_MODAL.CLOSE });
    const newGtins = gtinsFromFile.filter(newGtin => !data.find(({ gtin }) => gtin === newGtin));
    if (newGtins.length) {
      requestSearchGtins(newGtins);
    }
  }, [gtinsFromFile]);

  // Если с бэка пришёл новый список gtin, то обновляем локальный стейт
  useEffect(() => {
    if (!_.isEmpty(searchGtins)) {
      dispatch({ type: actionTypes.GTINS.ADD, payload: searchGtins });
    }
  }, [searchGtins]);

  // При unMount таблицы переводим selectedRowIds в значение по умолчания
  useEffect(() => {
    if (data.length === 0) {
      setSelectedRowIds({});
    }
  }, [data]);

  useEffect(() => {
    return () => {
      clearSearchGtins();
    };
  }, []);

  return (
    <GtinsView
      actions={actions}
      bulkActions={bulkActions}
      bulkActionsDisabled={bulkActionsDisabled}
      data={data}
      dispatchModal={dispatchModal}
      gtinsFromFile={gtinsFromFile}
      onReadParseFileSuccess={onReadParseFileSuccess}
      onUpdateGtins={onUpdateGtins}
      onUploadGtins={onUploadGtins}
      setCustomIdsToRows={setCustomIdsToRows}
      setSelectedRowIds={setSelectedRowIds}
      stateModal={stateModal}
      tableRef={tableRef}
    />
  );
};

const mapStateToProps = state => ({
  searchGtins: grayAreaSearchGtinsSelectors.result(state),
});

const mapDispatchToProps = {
  clearSearchGtins: grayAreaSearchGtinsActions.clear,
  requestSearchGtins: grayAreaSearchGtinsActions.requestSearchGtins,
};

Gtins.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  onUnmount: PropTypes.func.isRequired,
  requestUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gtins);
