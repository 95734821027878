import { actionTypes } from '../constants/';
import { ProductService } from '../services/ProductService';
import { CISService } from '../services/CISService';
import { FormSerializer } from '../utils/FormSerializer';
import {endPreloader, startPreloader} from './Preloader';
import {Toast} from '../utils/Toast';

function ProductDetailsUpdated(value) {
  return {
    type: actionTypes.PRODUCT_DETAILS.PRODUCT_DETAILS_UPDATED,
    value
  };
}

function ProductDetailsMounted(id) {
  return dispatch => {
    ProductService.getProductDetailsById(id)
      .then(answer => {
        dispatch(ProductInfoLoaded(answer.data));
      })
      .catch(err => {
        console.error('error getting product', err);
      });
  };
}

function ChangeRate(data) {
  return dispatch => {
    ProductService.changeRate(data)
      .then(response => {
        const { data } = response;
        dispatch({
          type: actionTypes.PRODUCT_DETAILS.RATE_CHANGED,
          value: data.rateType
        });
      })
      .catch(err => {
        console.error('error getting product', err);
      });
  };
}

function getTariffRateChangesHistory(gtin) {
  return dispatch => {
    ProductService.tariffRateChangesHistory(gtin)
      .then(response => {
        const { data } = response;
        dispatch({
          type: actionTypes.PRODUCT_DETAILS.RATE_CHANGES_HISTORY,
          value: data
        });
      })
      .catch(err => {
        console.error('error getting product', err);
      });
  };
}

function clearTariffRateChangesHistory() {
  return {
      type: actionTypes.PRODUCT_DETAILS.RATE_CHANGES_HISTORY_CLEAR,
      value: null
  }
}

function ProductInfoLoaded(data) {
  return {
    type: actionTypes.PRODUCT_DETAILS.PRODUCT_DETAILS_LOADED,
    value: data
  };
}

function CISListMounted(gtin) {
  return dispatch => {
    console.log('cis list mounted, request list');
    const params = { limit: 10, offset: 0, gtin };

    dispatch({
      type: actionTypes.PRODUCT_DETAILS.CIS_LIST_MOUNTED,
      value: params
    });

    CISService.getList(params).then(answer => {
      console.log('cis list', answer.data);
      dispatch(CISListLoaded(answer.data, params.limit));
    });
  };
}

function CISListLoaded(data, limit) {
  return {
    type: actionTypes.PRODUCT_DETAILS.CIS_LIST_LOADED,
    value: Object.assign(data, { limit })
  };
}

function CISListPageLoaded(data) {
  return {
    type: actionTypes.PRODUCT_DETAILS.CIS_LIST_PAGE_LOADED,
    value: data
  };
}

function CISPageSelected(pageNumber, cis) {
  return dispatch => {
    console.log('pageNumber', pageNumber);
    const limit = cis.filterParams.limit;
    console.log('limit', limit);
    console.log('filterParams', cis.filterParams);
    const params = Object.assign({}, cis.filterParams, {
      offset: limit * (pageNumber - 1),
      needTotal: false
    });

    CISService.getList(params).then(answer => {
      console.log('cis list', answer.data);
      dispatch({
        type: actionTypes.PRODUCT_DETAILS.PAGE_SELECTED,
        value: pageNumber
      });
      dispatch(CISListPageLoaded(answer.data));
    });
  };
}

function removeProductClicked(id, name, history) {
  return dispatch => {
    ProductService.removeProduct(id).then(answer => {
      Toast.showSuccess({
        content: `Товар ${name} успешно удален.`
      });
      history.push('/products/list');
    });
  };
}

function FilterClicked(data, paramsFormatter, preSendFormatter) {
  return (dispatch, getState) => {
    console.log(`filter PRODUCT_DETAILS clicked`, data);
    const params = { limit: 10, offset: 0 };
    const prepared = FormSerializer.serialize(data);
    console.log('params', params);
    console.log('prepared', prepared);
    const _data = Object.assign(params, prepared);
    const filterParams = paramsFormatter ? paramsFormatter(_data, getState) : _data;
    dispatch({
      type: actionTypes.PRODUCT_DETAILS.FILTER_APPLY_CLICKED,
      value: filterParams,
    });
    dispatch(startPreloader());

    const listParams = preSendFormatter ? preSendFormatter(filterParams, getState) : filterParams;

    CISService
      .getList(listParams)
      .then(answer => {
        console.log(`$PRODUCT_DETAILS list loaded`, answer.data);
        dispatch(CISListLoaded(answer.data, params.limit));
        dispatch(endPreloader());
      })
      .catch(err => {
        console.log('err', { err: err });
        dispatch(endPreloader());
        if (err.response && err.response.status === 500) {
          dispatch(
            CISListLoaded({ results: [], total: params.total }, params.limit)
          );
          Toast.showError({
            content: 'Сервер вернул 500 ошибку.'
          });
        }
      });
  };
}

function saveProductInfo(data, product, history) {
  return dispatch => {
    const formed = FormSerializer.serialize(data);
    const updatedProduct = Object.assign({}, product, formed);
    ProductService.updateProduct(updatedProduct)
      .then(answer => {
        console.log('update success', answer);
        if (answer.success) {
          Toast.showSuccess({
            content: `Товар ${updatedProduct.name || updatedProduct.gtin} успешно обновлен.`
          });
        }
        else if (answer.errors && answer.errors.length) {
          Toast.showError({
            content: `Ошибка при сохранении товара ${updatedProduct.name}: ${answer.errors.join(', ')}`
          });
        }
        history.push(`/products/list/${updatedProduct.id}/basic-info`);
        dispatch(ProductDetailsUpdated(answer.data.entity));
      })
  };
}

export function producerIdChanged(producerId) {
  return {
    type: actionTypes.PRODUCT_DETAILS.PRODUCER_ID_CHANGED,
    value: producerId
  }
}

function rateTypeChangedFalse() {
  return {
    type: actionTypes.PRODUCT_DETAILS.RATE_CHANGED_FALSE,
    value: false
  }
}

export {
  ProductDetailsMounted,
  CISListMounted,
  CISPageSelected,
  removeProductClicked,
  saveProductInfo,
  FilterClicked,
  ChangeRate,
  rateTypeChangedFalse,
  getTariffRateChangesHistory,
  clearTariffRateChangesHistory
};
