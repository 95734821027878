import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import Icon from '@crpt/react-icon';
import { documentStatusesNames, documentTypeNames } from '../../../constants';
import { Translate } from '../../../common_components/Translate/Translate';
import { discrepancyIncomeStatusesNames, exportStatusesNames } from '../../../constants/documentStatuses';
import { getIconNameByDocType } from '../../../common_components/Table/TableCells/Document/utils';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';

const DocTypeWrap = styled.div`
  display: flex;
`;

export const TableHeading = styled.div`
  
`;

const DraftNumWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 36px;
  height: 70px;
`;

export const getListData = (results, selectedRows, userInfo) => {
  const list = results.map(item => {
    return {
      ...item,
      type: item.documentType,
      date: item.updateDate,
      documentId: item.documentId,
    };
  });
  // selectedRows.forEach(key => {
  //   const id = key.replace('select-', '');
  //   const find = list.find(item => item.id === id);
  //   if (find) {
  //     const idx = list.findIndex(item => item.id === id);
  //     const documents = get(find, 'documents', []);
  //     const discrepancy = documents.find(item => [1005].includes(item.type));
  //     const correction = documents.find(item =>
  //       [1100, 1300].includes(item.type),
  //     );
  //     if (correction && correction.status > 0) {
  //       const data = getRowData(correction, userInfo);
  //       list.splice(idx + 1, 0, {
  //         ...data,
  //         type: find.type === 1000 ? 1100 : 1300,
  //         recipient: {
  //           name: get(find, 'recipient.name', '-'),
  //         },
  //       });
  //     }
  //     if (discrepancy) {
  //       const data = getRowData(discrepancy, userInfo);
  //       list.splice(idx + 1, 0, {
  //         ...data,
  //         type: 1005,
  //         recipient: {
  //           name: get(userInfo, 'company.shortName', '-'),
  //         },
  //       });
  //     }
  //   }
  // });

  return list;
};

export const limit = 20;

export const documentStatusValues = [
  {
    id: '1',
    title: 'Проверяется',
    type: 'CHECKING',
  },
  {
    id: '2',
    title: 'Отправлен в ГИС МТ',
    type: 'SENT',
  },
  {
    id: '3',
    title: 'Ошибка',
    type: 'ERROR',
  },
  {
    id: '4',
    title: 'Ожидает приемки',
    type: 'WAIT_ACCEPTANCE',
  },
  {
    id: '5',
    title: 'Принят',
    type: 'ACCEPTED',
  }
];

export const columns = [
  {
    Header: Translate('Регистрационный номер'),
    id: 'documentId',
    accessor: 'documentId',
    width: 200,
  },
  {
    Header: Translate('Тип'),
    id: 'type',
    accessor: type => documentTypeNames[type],
    width: 'auto',
  },
  {
    Header: Translate('Наименование получателя'),
    id: 'receiverName',
    accessor: 'receiverName',
    width: 'auto',
  },
  {
    Header: Translate('Дата документа'),
    id: 'createDate', // на id завязана логика сортировки
    accessor: data => `${moment(new Date(data.createDate)).format('DD.MM.YYYY')}`,
    width: 120,
  },
  {
    Header: Translate('Дата обновления статуса'),
    id: 'date', // на id завязана логика сортировки
    accessor: data => `${moment(new Date(data.date)).format('DD.MM.YYYY')}`,
    width: 120,
  },
  {
    Header: Translate('Статус'),
    id: 'status',
    accessor: data => Translate(exportStatusesNames[data.status]),
    width: 'auto',
  },
];

export const options = [
  {
    accessor: 'type',
    id: 'type',
    render: (props, rowInfo) => {
      if (!rowInfo) return <React.Fragment />;
      const docType = get(rowInfo, 'original.type', '');
      return (
        <DocTypeWrap>
          <Icon
            type={getIconNameByDocType(docType)}
            spacingRight={12}
            fill="rgb(82, 83, 90)"
            size={16}
          />
          <div>{Translate(documentTypeNames[docType] || docType)}</div>
        </DocTypeWrap>
      );
    },
  },
  {
    accessor: d => d,
    id: 'documentId',
    render: (_, rowInfo) => {
      const uuid = get(rowInfo, 'original.documentId', '');
      const uuidDocNumber = get(rowInfo, 'original.number', '');
      const document_type = get(rowInfo, 'original.type', '');
      let path = '';
      switch (document_type) {
        case 1000:
          path = `/documents/list/act-in/${uuid}`;
          break;
        case 1100:
          path = `/documents/list/act-in/${uuid}`;
          break;
        case 1005:
          path = `/documents/discrepancy_notice/view/${uuid}`;
          break;
        case 1200:
        case 1300:
          path = `/documents/list/eaes-in/${uuid}`;
          break;
        case "KZ_EXPORT_NOTICE":
          path = `/documents/export/${uuid}`;
          break;
      }

      return (
        <div
          style={{
            paddingLeft: [1005, 1100, 1300].includes(document_type) ? 30 : 0,
          }}
        >
          <DraftNumWrap>
            <Tooltip
              text={uuid}
              textComponent={
                <Wrapper>
                  <StyledLink to={path}>{uuid.slice(-6)}</StyledLink>
                </Wrapper>
              }
              sliceTitleStart={-6}
              placement="bottom-start"
              textColor="#55b5e7"
              interactive
              showCopy
              showMail
              noWrap
            />
          </DraftNumWrap>
        </div>
      );
    },
  },
];
