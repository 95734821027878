import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MenuItemView } from './MenuItem.view';

export const MenuItem = memo(({ item, active, onItemClick }) => (
  <MenuItemView onItemClick={onItemClick} item={item} active={active} />
));

MenuItem.propTypes = {
  item: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    testRoute: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired
};
