/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldHoc from '../FieldHoc';
import { DatePickerInput as DatePickerCRPT } from '@crpt-ui/core';

const DatePicker = ({ input, meta, ...fieldRest }) => {
  const { placeholder, errorPlaceholder, isError, ...rest } = fieldRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  const submitHandle = dates => {
    input.onChange(dates);
  };

  const error = isError(meta);

  return (
    <DatePickerCRPT
      date={input.value}
      onChange={input.onChange}
      onFocus={input.onFocus}
      onSubmit={submitHandle}
      label={placeholder}
      error={meta.error}
      readOnly={true}
      InputProps={{
        readOnly: true,
      }}
      {...rest}
    />
  );
};

DatePicker.propTypes = {
  isError: PropTypes.func,
  required: PropTypes.bool,
};

DatePicker.defaultProps = {
  isError: meta => meta.invalid,
  required: false,
};

export default FieldHoc(DatePicker);
