export const defaultTheme = {
  marginTop: '20px',
  paddingLeft: '26px',
  paddingRight: '32px',
  paddingTop: '16px',
  paddingBottom: '19px',
  backgroundColor: '#FFFFFF',
  shadowX: '1px',
  shadowY: '2px',
  shadowBlur: '5px',
  shadowColor: 'rgba(0, 0, 0, 0.25)',
};
