import React from 'react';
import { Grid } from '@material-ui/core';
import Input from '../../../../../../common_components/Form/MaterialFields/Input/Input';
import { isExciseTaxRequired, required } from '../../../../../../common_components/Form/utils/validations';
import CountrySelect from '../../../../../../common_components/Form/MaterialFields/Select/CountrySelect';
import { validateDate } from '../../../ImportDocument/ImportDocument.validations';
import DatePicker from '../../../../../../common_components/Form/MaterialFields/DatePicker/DatePicker';
import { FloatInput } from '../../Information/FloatInput';


export const GoodsEditUzFields = ({ index }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Input
          required
          name={`[products][${index}]name`}
          placeholder="Наименование товара"
          disabled
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          disabled
          name={`[products][${index}]gtin`}
          placeholder="GTIN"
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          disabled
          name={`[products][${index}]tnved`}
          placeholder="Код ТН ВЭД"
          marginBottom="0"
        />
      </Grid>
      <Grid item xs={6}>
        <FloatInput
          required
          forceValidate
          name={`[products][${index}]custom_cost`}
          label="Таможенная стоимость ввезенного товара"
          validate={isExciseTaxRequired}
          mask='9{1,17}.99'
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          forceValidate
          name={`[products][${index}]numberDT`}
          placeholder="Номер товара в ДТ"
          marginBottom="0"
          type="number"
          validate={required}
        />
      </Grid>
      <Grid item xs={6}>
        <FloatInput
          required
          forceValidate
          name={`[products][${index}]cis_tax`}
          label="Сумма акциза"
          validate={isExciseTaxRequired}
          mask='9{1,17}.99'
        />
      </Grid>
      <Grid item xs={12}>
        <CountrySelect
          forceValidate
          placeholder="Страна производства"
          name={`[products][${index}]country`}
          required
          eas={false}
          validate={required}
        />
      </Grid>
      <Grid item xs={6}>
        <FloatInput
          required
          forceValidate
          name={`[products][${index}]vat_tax`}
          label="Сумма НДС"
          validate={isExciseTaxRequired}
          mask='9{1,17}.99'
        />
      </Grid>
      <Grid item xs={6}>
        <FloatInput
          required
          forceValidate
          name={`[products][${index}]item_price`}
          label="Цена единицы товара"
          validate={isExciseTaxRequired}
          mask='9{1,17}.99'
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          required
          forceValidate
          name={`[products][${index}]permit_document`}
          placeholder="Номер разрешительного документа"
          marginBottom="0"
          validate={required}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          required
          forceValidate
          name={`[products][${index}]doc_allow_date`}
          placeholder="Дата разрешительного документа"
          maxDate={new Date}
          validate={(...vals) => {
            return validateDate(...vals, 'DD.MM.YYYY');
          }}
        />
      </Grid>
    </Grid>
  )
}
