import { takeEvery, take, select, put, call } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import * as actions from './List.actions';
import * as appSelectors from '../../App/App.selectors';
import {
  getFilterParams,
  setFilterParamsToUrl,
  saveToSessionStorage,
  getSearchString,
} from '../List.utils';
import Api from '../../Api/Api';

function* loadingSaga(action) {
  const { payload, meta } = action;
  const { getRequest, loaded, subject, last } = meta;

  yield put(getRequest(payload));

  const listRequest = yield take(
    a => a.type === `${actions.gotRequest}` && a.meta.subject === subject,
  );
  const { payload: requestPayload } = listRequest;

  const [success, error, timeout] = yield call(Api.request, requestPayload);
  if (success && last) {
    yield put(last(success.data.length <= 10));
  }
  if (success) yield put(loaded(success.data));
  if (error) console.log(error);
  if (timeout) console.log(timeout);
}

function* filterSaga(action) {
  const { payload, meta } = action;
  const { filters, pagination, updatePage } = meta;

  yield put(filters(payload));
  yield put(pagination({}));
  yield put(updatePage(1));
  yield call(loadingSaga, action);

  const search = yield call(setFilterParamsToUrl, { ...payload, page: 1 });
  //yield call(saveToSessionStorage, `?${search}`);
  yield put(replace({ search }));
}

function* paginateSaga(action) {
  const { payload, meta } = action;
  const { updatePage } = meta;
  const { page } = payload;

  const filterParams = yield call(getFilterParams);
  const search = yield call(setFilterParamsToUrl, { ...filterParams, page });
  // page > 1
  //   ? yield call(setFilterParamsToUrl, { ...filterParams, page })
  //   : yield call(setFilterParamsToUrl, filterParams);
  //yield call(saveToSessionStorage, `?${search}`);
  yield put(replace({ search }));
  yield put(updatePage(payload));

  yield call(loadingSaga, action);
}

function* sortSaga(action) {
  const { payload, meta } = action;
  const { sort, pagination, updatePage } = meta;

  yield put(pagination({}));
  yield put(updatePage(1));
  yield put(sort(payload));
  yield call(loadingSaga, action);
}

function* mountedSaga(action) {
  const { meta, payload } = action;
  const { filters, pagination, updatePage, setSorting } = meta;
  const fromSameSubject = yield select(appSelectors.fromSameSubject);
  const search = yield call(getSearchString);
  const params = yield call(getFilterParams, search);
  if (!window.location.search) {
    yield put(replace({ search }));
  }
  const { page } = params;
  delete params.page;
  yield put(filters(params));

  if (!fromSameSubject) {
    yield put(setSorting());
    yield put(pagination({ page }));
    yield put(updatePage(page));
  }

  const newAction = { ...action };

  if (payload) newAction.payload = { ...payload, page: +page || payload.page };
  else newAction.payload = { page: +page };

  yield call(loadingSaga, newAction);
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountedSaga);
  yield takeEvery(actions.filter, filterSaga);
  yield takeEvery(actions.paginate, paginateSaga);
  yield takeEvery(actions.sort, sortSaga);
}
