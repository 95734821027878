import styled from 'styled-components';
import { withTheme } from '@material-ui/core';

export const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const MenuItem = withTheme( styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  background: #fff;
  color: black;
  transition: background 0.2s ease;
  &:hover {
    background: ${props => props.theme.palette.primary.main ||  '#fff'};
    color: #fff;
  }
`);

export const Panel = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  border-radius: 3px;
  ${({ positionY }) => positionY === 'top' ? `bottom: 50px;` : `top: 50px;`}
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;
