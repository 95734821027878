import { selector } from 'src/utils/selector';
import { limit } from '../IncometList.constants';

export const results = state => selector(state, 'Requests.IncomeList.results');
export const total = state => selector(state, 'Requests.IncomeList.total');
export const last = state => selector(state, 'Requests.IncomeList.last');
export const pagesCount = state => Math.ceil(total(state) / limit);
export const sorting = state => selector(state, 'Requests.IncomeList.sorting');
export const selectedPage = state => selector(state, 'Requests.IncomeList.selectedPage');
export const pagination = state => selector(state, 'Requests.IncomeList.pagination');
export const filters = state => selector(state, 'Requests.IncomeList.filters');
export const uploadErrors = state => selector(state, 'Requests.IncomeList.uploadErrors');
export const offset = state => selector(state, 'Requests.IncomeList.offset');
