import styled from 'styled-components';

export const FilterWrapper = styled.div`
`
export const FilterHeader = styled.div`
  border-radius: 2px;
  border: 1px solid rgba(196, 196, 196, 0.25);
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 55px;
`
export const FilterHeaderItem = styled.div`
  width: 90px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  &:nth-child(2) {
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
  }
`
export const FilterInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const FilterInfoItem = styled.div`
  width: 214px;
  @media screen and (max-width: 600px) {
    width: 100%;
    :not(:last-child){
      margin-bottom: 14px;
    }
  }
`
export const FilterInfoHeader = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
`
export const FilterInfoDesc = styled.div`
  font-size: 14px;
  line-height: 1.5;
  @media screen and (max-width: 600px) {
    br {
      display: none;
    }
  }
`