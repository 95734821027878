import Icon from '@crpt-ui/core';
import React from 'react';
import { exportStatuses, importStatuses } from './documentTypes';

export const documentStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  CHECKED_OK: 'CHECKED_OK',
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  // PROCESSING_ERROR: 'PROCESSING_ERROR',
  // UNDEFINED: 'UNDEFINED',
  // DRAFT: 'DRAFT',
  // READY_FOR_EXTERNAL_PROCESSING: 'READY_FOR_EXTERNAL_PROCESSING',
  // COMPLETED: 'COMPLETED',
  // ERROR: 'ERROR'
};


const discrepancyStatusesNames = {
  5: 'Отклонено',
};

export const discrepancyIncomeStatusesNames = {
  2: 'Ожидает подтверждения',
  3: 'Ожидает подтверждения',
  4: 'Принято',
  10: 'Ошибка доставки',
  17: 'Аннулирован',
  18: 'Расчет итогов',
  19: 'Готов к регистрации',
  20: 'Обработка документа',
  ...discrepancyStatusesNames,
};

export const discrepancyOutcomeStatusesNames = {
  2: 'Ожидает подтверждения',
  3: 'Ожидает подтверждения',
  4: 'Принято',
  10: 'Ошибка доставки',
  17: 'Аннулирован',
  18: 'Расчет итогов',
  19: 'Готов к регистрации',
  20: 'Обработка документа',
  ...discrepancyStatusesNames,
};

export const exportStatusesNames = {
  [exportStatuses.CHECKING]: 'Проверяется (документ прошел валидацию ЭЦП и первичный ФЛК, выполняются бизнес-проверки)',
  [exportStatuses.SENT]: 'Отправлен',
  [exportStatuses.ERROR]: 'Обработан с ошибками',
  [exportStatuses.WAIT_ACCEPTANCE]: 'Ожидает приемки',
  [exportStatuses.ACCEPTED]: 'Принят',
}

export const importStatusesNames = {
  [importStatuses.IN_PROGRESS]: 'Проверяется',
  [importStatuses.CHECKED_NOT_OK]: 'Обработан с ошибками',
  [importStatuses.ACCEPTED]: 'Принят',
  [importStatuses.CHECKED_OK]: 'Обработан успешно',
  [importStatuses.WAIT_ACCEPTANCE]: 'Ожидает приемку',
}

export const getDocumentExportEAESStatus = (type) => {
  switch (type) {
    case 'CHECKING' :
      return 'checking';
    case 'SENT' :
      return 'issued';
    case 'ERROR' :
      return 'error-status';
    default:
      return '';
  }
}

export const documentStatusesNames = {
  [documentStatuses.IN_PROGRESS]: 'Проверяется',
  [documentStatuses.CHECKED_OK]: 'Обработан успешно',
  [documentStatuses.CHECKED_NOT_OK]: 'Обработан с ошибками',
  // [documentStatuses.PROCESSING_ERROR]: 'При обработке возникла ошибка',
  // [documentStatuses.UNDEFINED]: 'Неопределен',
  0: 'Создан',
  1: 'Отправлен',
  2: 'Доставлен, подпись не требуется',
  3: 'Ожидает подтверждения',
  4: 'Подписан получателем',
  5: 'Отклонён',
  8: 'Запрошено уточнение',
  10: 'Ошибка доставки',
  11: 'Подписан (ожидает отправки)',
  12: 'Просмотрен, подпись не требуется',
  13: 'Просмотрен, ожидается подпись',
  14: 'Документ, к которому запрошено уточнение (Clarified), просмотрен',
  15: 'Отклонённый документ (Rejected) просмотрен',
  16: 'Отозван',
  17: 'Аннулирован',
  18: 'Расчет итогов',
  19: 'Готов к регистрации',
  20: 'Обработка документа',
  // DRAFT: 'Черновик',
  // READY_FOR_EXTERNAL_PROCESSING: 'Создан',
  // COMPLETED: 'Обработан',
  // ERROR: 'Обработан с ошибками'
};

export const documentStatusesColorsMap = {
  [documentStatuses.CHECKED_OK]: '#2FA93B',
  [documentStatuses.CHECKED_NOT_OK]: '#FF4B4B',
  [documentStatuses.IN_PROGRESS]: '#63666A',
};

export const documentStatusesIconsMap = {
  [documentStatuses.CHECKED_OK]: <Icon name="published" />,
  [documentStatuses.CHECKED_NOT_OK]: <Icon name="errorDocument" />,
  [documentStatuses.IN_PROGRESS]:  <Icon name="documentProcessing" />
};
