import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { RelativeWrap } from './styled';
import Preloader from '../../Preloader';
import Table from '@crpt/react-table';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import { Pagination } from '@crpt/react-pagination';
import BreadcrumbsWrap from '../../Details/styled/BreadcrumbsWrap';
import { PaginationWrapper, ContentWrapper } from '../../common/styled';
import ActionBar from '../../common/ActionBar';
import ElementsCount from '../../common/Table/ElementsCount';
import TableScrollWrapper from '../../TableScrollWrapper';
import { TableToolTip } from '../../common/Table/TableCellTooltip';

import DownloadButton from '../../common/Table/Buttons/DownloadButton';
import Filter from '../../common/Table/Buttons/Filter';
import { Translate } from '../../../common_components/Translate/Translate';

class CisListView extends Component {
  static propTypes = {
    breadcrumbs: PropTypes.array,
    isInfinite: PropTypes.bool.isRequired,
    preloaderIsOpen: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onPageSelect: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    onFilterApplyClick: PropTypes.func.isRequired,
    selectedPage: PropTypes.number.isRequired,
    pagesCount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      cellEvent: null,
      isToolTipVisible: false,
      children: <div />,
    };
  }

  onMouseEvent = ({ isToolTipVisible, children, cellEvent }) => {
    this.setState(() => ({ isToolTipVisible, children, cellEvent }));
  };

  render() {
    const {
      breadcrumbs,
      isInfinite,
      preloaderIsOpen,
      columns,
      data,
      options,
      onPageSelect,
      total,
      theme,
      filters,
      onFilterApplyClick,
      selectedPage,
      pagesCount,
      filterParams,
    } = this.props;

    const { isToolTipVisible, cellEvent, children } = this.state;

    const optionsWithCallback = options.map(item => ({
      onMouseEvent: this.onMouseEvent,
      ...item,
    }));

    const { popupMenu, showDownloadListButton } = filters[0];

    const rightSideComponents = [
      {
        component: (
          <Filter
            filters={filters}
            filterParams={filterParams}
            onFilterApply={onFilterApplyClick}
          />
        ),
        wrap: true,
      },
      {
        component: (
          <DownloadButton
            popupMenu={popupMenu}
            showDownloadListButton={showDownloadListButton}
          />
        ),
        wrap: true,
      },
    ];

    return (
      <RelativeWrap>
        <Preloader />
        {breadcrumbs ? (
          <BreadcrumbsWrap>
            <Breadcrumbs
              withCustomLinks
              onClick={val => this.props.history.push(val.href)}
              breadcrumbs={breadcrumbs}
            />
          </BreadcrumbsWrap>
        ) : null}
        <ContentWrapper minHeight={preloaderIsOpen}>
          <ActionBar rightSideComponents={rightSideComponents} />
          {isInfinite || preloaderIsOpen ? null : (
            <ElementsCount value={total} />
          )}
          {!preloaderIsOpen && (
            <TableScrollWrapper>
              <Table
                theme={theme}
                columns={columns}
                data={data}
                minRows={0}
                noDataText={Translate('Данных нет')}
                highlight
                striped
                options={optionsWithCallback}
              />
            </TableScrollWrapper>
          )}
          <PaginationWrapper>
            <Pagination
              selected={selectedPage}
              pagesCount={data.length ? pagesCount : 1}
              onSelect={v => onPageSelect(v)}
              pageCounterInvisible
              isBelowZeroDisabled
              leftPaginationText={Translate('Предыдущая')}
              rightPaginationText={Translate('Следующая')}
            />
          </PaginationWrapper>
          <TableToolTip
            event={cellEvent}
            content={children}
            isVisible={isToolTipVisible}
          />
        </ContentWrapper>
      </RelativeWrap>
    );
  }
}

export default withTheme(withRouter(CisListView));
