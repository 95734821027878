import React from 'react';
import {withRouter} from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    padding: '0',
    maxWidth: '608px'
  },
  overlay: {
    overflow: 'visible',
    backgroundColor: 'rgba(82, 83, 90, 0.5)'
  },
  overflow: 'visible'
};

// const ModalClose = styled.div`
//   cursor: pointer;
// `;

const ModalWrap = props => {
  const { children, style, ...other } = props;

  return (
    <Modal style={{...customStyles, ...style}} {...other}>
      {children}
    </Modal>
  );
};

export default withRouter(ModalWrap);
