import {actionTypes} from '../../../constants/';

export function pagesCount(state = 1, action = {}) {
  switch (action.type) {
    case actionTypes.PRODUCT_DETAILS.CIS_LIST_LOADED:
      return Math.ceil(action.value.total/action.value.limit);
    default:
      return state;
  }
}
