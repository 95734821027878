import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Field } from 'react-final-form';
import {
  compose,
  branch,
  withHandlers,
  pickProps,
  onlyUpdateForKeys,
  setDisplayName,
} from 'recompact';
import Icon from '@crpt/react-icon';
import { CheckboxIconWrap, CheckboxTitle, CheckboxWrap } from './styled';

const Element = ({ width, active, title, onToggle }) => (
  <CheckboxWrap width={width} onClick={onToggle}>
    <Icon type={active ? 'selected-checkbox' : 'empty-checkbox'} size={16} spacingRight={15} />
    <CheckboxTitle>{title}</CheckboxTitle>
  </CheckboxWrap>
);

Element.propTypes = {
  width: PropTypes.string,
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

Element.defaultProps = {
  width: null,
  active: false,
};

const Composed = compose(
  withHandlers({
    onToggle: ({ item, onToggle }) => () => onToggle && onToggle(item),
  }),
  pickProps(['width', 'active', 'title', 'onToggle', 'name'])
)(Element);

const Wrapper = props => {
  const { name } = props;

  if (name) return <Field {...props} component={Composed} />;
  return <Composed {...props} />;
};

export default compose(
  setDisplayName('Checkbox'),
  onlyUpdateForKeys(['width', 'active', 'title', 'name'])
)(Wrapper);
