import {actionTypes} from '../../constants/';

export function cisPagesCount(state = 1, action = {}) {
  switch (action.type) {
    case actionTypes.DOCUMENT_DETAILS.CIS_LIST_LOADED:
      return Math.ceil(action.value.total/action.value.limit);
    default:
      return state;
  }
}
