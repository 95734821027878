import styled from 'styled-components';

export const Wrap = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #232c40;
  display: flex;
  align-items: center;
  cursor: ${props => (props.onClick ? 'pointer' : '')};
`;

export const Card = styled.div`
  padding: 25px 50px;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
`;

export const ContentWrap = styled.div`
  margin: 24px 0 24px;
  display: ${props => (props.hidden ? 'none' : '')};
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: top;
  width: 100%;
`;

export const SidebarInfo = styled.div`
  color: #55b5e7;
  min-width: 140px;
  white-space: nowrap;
  font-size: 16px;
  margin-left: 56px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const CertPreview = styled.div`
  margin-bottom: 31px;
`;

export const CertCompany = styled.div`
  font-size: 14px;
  color: rgba(82, 83, 90, 0.7);
`;

export const CertName = styled.div`
  font-size: 16px;
  color: #52535a;
`;
