import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  getElementInfo,
  getArrowOffset,
  getToolTipPositionX,
  getToolTipPositionY,
  getToolTipArrowDirection,
} from '../utils';
import Arrow from './Arrow/index';
import { TooltipWrapper } from './styled/index';


export default class Tooltip extends Component {
  static propTypes = {
    offsetX: PropTypes.number.isRequired,
    offsetY: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rowWidth: PropTypes.number.isRequired,
    heightToBack: PropTypes.number.isRequired,
    content: PropTypes.any.isRequired,
  };

  componentDidMount() {
    /**
     * First render for calculate parameters of hint
     * Second render for move to position
     */
    this.forceUpdate();
  }

  getPosition = () => {
    const {
      offsetX, offsetY, width, height: rowHeight, rowWidth, heightToBack,
    } = this.props;

    const centerOfCellX = offsetX + width / 2;

    const toolTipInfo = getElementInfo({ elementId: 'toolTipWindow' });
    const arrowOffset = getArrowOffset({
      toolTipHalfWidth: toolTipInfo.toolTipHalfWidth,
      centerOfCellX,
      rowWidth,
    });

    const toolTipPositionX = getToolTipPositionX({
      toolTipHalfWidth: toolTipInfo.toolTipHalfWidth,
      centerOfCellX,
      rowWidth,
    });

    const toolTipPositionY = getToolTipPositionY({
      offsetY,
      height: toolTipInfo.toolTipHeight,
      rowHeight,
      heightToBack,
    });

    const arrowDirection = getToolTipArrowDirection({
      height: toolTipInfo.toolTipHeight,
      rowHeight,
      heightToBack,
    });

    return {
      ...toolTipPositionX,
      ...toolTipPositionY,
      arrowOffset,
      arrowDirection,
      isVisible: toolTipInfo.isVisible,
    };
  };

  render() {
    const { content } = this.props;
    const {
      isVisible, arrowOffset, arrowDirection, ...toolTipPosition
    } = this.getPosition();
    return (
      <TooltipWrapper
        {...toolTipPosition}
        isVisible={isVisible}
        id="toolTipWindow"
      >
        <Arrow
          direction={arrowDirection}
          position={arrowOffset}
        />
        {content}
      </TooltipWrapper>
    );
  }
}
