import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';

import FileUploadModalContent from '../../../../common_components/Modals/FileUploadModal/FileUploadModal.content';
import FileSignModalContent from '../../../../common_components/Modals/FileSignModal/FileSignModal.content';
import * as actions from './ducks/FiscalDocument.actions';
import * as selectors from './ducks/FiscalDocument.selectors';
import { STEPS } from './FiscalDocument.constants';
import { getFilenameWithoutExtension } from '../../../../utils';
import { Translate } from '../../../../common_components/Translate/Translate';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  types: PropTypes.string.isRequired,
  step: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  errorText: PropTypes.string,
  json: PropTypes.string,
  file: PropTypes.object,
};

const defaultProps = {};

class FiscalUploadModalContent extends React.Component {
  onLoad = file => {
    console.log('onLoad FiscalModalContent', file);
    this.props.convert(file);
  };

  onError = error => {
    console.log('onError FiscalModalContent', error);
  };

  onDrop = files => {
    this.props.load();
  };

  onCancelSign = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  onSign = () => {
    this.props.sign(this.props.onCloseModal);
  };

  onClose = e => {
    this.props.reset();
    this.props.onCloseModal();
  };

  getQty = file => {
    try {
      const qty = JSON.parse(file.content);
      return qty.length;
    } catch (e) {
      return 0;
    }
  };

  render() {
    const { onCloseModal, types, step, errorText, file } = this.props;

    if (step === STEPS.READY_TO_SIGN) {
      return (
        <FileSignModalContent
          onSign={this.onSign}
          onCancel={this.onCancelSign}
          onCloseModal={this.onClose}
          title={Translate('Загрузка чеков из фискального накопителя')}
          file={{
            ...file,
            name: `${getFilenameWithoutExtension(file.name)}.json`,
          }}
          description={
            Translate(
              'Файл загружен и конвертирован. В раздел «Документы» будут добавлены загруженные чеки:',
            ) +
            ' ' +
            this.getQty(file) +
            ' ' +
            Translate('штук') +
            '. ' +
            Translate(
              'После подписания и отправки документа, коды маркировки будут обработаны в соответствующем порядке (выведены из оборота, возвращены в оборот, иное).',
            )
          }
        />
      );
    }

    return (
      <FileUploadModalContent
        isError={step === STEPS.ERROR}
        errorContent={<div>{errorText}</div>}
        onCloseModal={this.onClose}
        types={types}
        isLoading={step === STEPS.LOADING || step === STEPS.CONVERTING}
        onError={this.onError}
        onLoad={this.onLoad}
        onDrop={this.onDrop}
        fileOpenParams={{
          asArrayBuffer: true,
        }}
        title={Translate('Загрузка чеков из фискального накопителя')}
        preloaderText={
          step === STEPS.LOADING
            ? Translate('Загрузка файла. Пожалуйста, подождите')
            : step === STEPS.CONVERTING
            ? Translate('Конвертация файла. Пожалуйста, подождите')
            : ''
        }
        beforeLoadText={Translate(
          'Перетащите или выберите файл формата *.zip или *.crpt',
        )}
      />
    );
  }
}

FiscalUploadModalContent.defaultProps = defaultProps;
FiscalUploadModalContent.propTypes = propTypes;

const mapState = state => {
  return {
    step: selectors.step(state),
    errorText: selectors.errorText(state),
    file: selectors.file(state),
    json: selectors.json(state),
  };
};

const mapDispatch = dispatch => ({
  convert: file => dispatch(actions.convert(file)),
  load: () => dispatch(actions.load()),
  reset: () => dispatch(actions.reset()),
  sign: onCloseModal => dispatch(actions.sign(onCloseModal)),
});

export default compose(
  connect(mapState, mapDispatch),
  withTheme,
)(FiscalUploadModalContent);
