import React from 'react';

export default {
  title: 'Табличное представление отчёта при типе агрегации «Подробный по товарам»:',
  amountOfTable: 1,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'ИНН производителя',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Наименование производителя',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Код товара (GTIN)',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 130,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
          subheaders: []
        },
        {
          Header: 'Дата, с которой устанавливается МРЦ',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 260,
          subheaders: []
        },
        {
          Header: 'МРЦ',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 100,
          subheaders: []
        },
        {
          Header: 'Вид упаковки',
          accessor: 'col7',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 210,
          subheaders: []
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
        },
      ]
    },
  ]
}