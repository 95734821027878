import styled from 'styled-components';

export const buttonTheme = {
  Button: {
    fontSize: '16px',
    fontWeight: 400,
    large: {
      paddingLeft: '16px',
      paddingRight: '21px',
      height: '40px',
    },
    main: {
      border: 'none',
      color: '#434244',
      background: 'rgba(0,0,0,0)',
    },
    disabled: {
      color: '#ABADB6',
      background: 'rgba(0,0,0,0)',
    },
    minWidth: '126px',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
};

export const Wrap = styled.div`
  position: relative;
  padding: 19px 42px;
  padding-top: ${props => props.paddingTop ? props.paddingTop : ''};
  background: #FFF;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.05);
  border-radius: 3px;
`;

export const Title = styled.div`
  color: #52535A;
  font-size: 24px;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  padding-right: 16px;
  box-sizing: border-box;
  z-index: 20;
`;

export const ButtonWrap = styled.div`
  margin-right: 16px;
  height: 40px;
  width: 126px;
  background-color: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

export const ItemWrap = styled.div`
  display: flex;
  position: relative;
  margin-top: 30px;
  flex-basis: 25%;
  height: ${props => props.height};
  max-width: 25%;
`;

export const ItemContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ItemTitle = styled.div`
  color: #52535A;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Count = styled.span`
  color: #52535A;
  font-size: 24px;
  font-weight: 700;
  margin-right: 5px;
`;

export const ChangeUp = styled.span`
  color: #189552;
  font-size: 12px;
  
  &:before {
    content: '↑ + ';
  }
`;

export const ChangeDown = styled.span`
  color: #d71010;
  font-size: 12px;

  &:before {
    content: '↓ ';
  }
`;

export const SmallChartWrap = styled.div`
  margin-top: 10px;
`;
