import { ProductionLinesColumns } from 'src/fragments/Table/Cell/ProductionLines/columns.constants';

export const columns = [
  {
    ...ProductionLinesColumns.placeName,
  },
  {
    ...ProductionLinesColumns.countryName,
  },
  {
    ...ProductionLinesColumns.line,
  },
];

export const sizedModalColumns = [
  {
    ...ProductionLinesColumns.placeName,
    width: 200,
  },
  {
    ...ProductionLinesColumns.countryName,
    width: 200,
  },
  {
    ...ProductionLinesColumns.line,
    width: 200,
  },
];

export const getSelectedRows = instance => instance && instance.current
  ? instance.current.selectedFlatRows
    .map(row => row.original)
  : [];
