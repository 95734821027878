import styled from 'styled-components';


const ProductImage = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  background-image: ${props => {
    const {url, productId} = props;
    if (url) return `url(${url})`;
    if (productId) 
      return `url('/products/images/${productId}.jpg')`
    return '';
  }};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #e6e6e6;
  margin-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ProductImage;
