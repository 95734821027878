import { MaterialUiThemeUZ } from '../../../../common_components/_MaterialUi/ThemeUZ';
import { MuiThemeProvider } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  ButtonWrap, CertWrap,
  ErrorWrap,
  ModalContentWrap,
  ModalHeading,
  SignModalWrap,
} from '../ImportThirdCountry/Cis/Cis.styled';
import { Icon } from '@crpt/react-icon';
import { IconWrap, TableWrap, TitleTable } from '../Document/Preview/Preview.styled';
import { Translate } from '../../../../common_components/Translate/Translate';
import Table from '@crpt/react-table';
import PrimaryActionLargeButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionButton';
// import { InfoFields } from '../Document/Document.styled';
import { getCertSerial } from '../../../../utils/cookieHelpers';
// import { isSignButtonDisabled } from '../ImportThirdCountry/Cis/ducks/Cis.selectors';
import * as cadesSelectors from '../../../../common_components/Eimzo/Eimzo.selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import * as AppSelectors from '../../../../common_components/App/App.selectors';
import { FieldsWrapper } from './DocumentWrapperConstants';


const FinalConfirmModal = (props) => {
  const { title, onCloseModal, goodsColumns, goodsData, FieldsInfo, countryCode, cadesCerts } = props;
  const { cisData, cisColumns, onSubmit, formValues, typeDoc, certificates, WrapperForTable } = props;

  const [cisOpen, setCisOpen] = useState(false);
  const [goodsOpen, setGoodsOpen] = useState(false);
  const [cert, setCert] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (countryCode === 'UZ') {
      const serial = getCertSerial();
      const find = certificates.find(item => item.serialNumber === serial);
      if (find) {
        setCert(find);
        setError(false);
      } else {
        setError('Сертификат не найден');
      }
    }
  }, [countryCode]);

  const handleOpenCis = () => setCisOpen(!cisOpen);
  const handleOpenGoods = () => setGoodsOpen(!goodsOpen);

  const table = WrapperForTable ? (
    <WrapperForTable>
      <Table
        columns={cisColumns}
        data={cisData}
        minRows={0}
        highlight
        striped
        defaultPageSize={1000}
      />
    </WrapperForTable>
  ): (
    <Table
      columns={cisColumns}
      data={cisData}
      minRows={0}
      highlight
      striped
      defaultPageSize={1000}
    />
  )
  const translatedTitle = typeof title === 'string' ? Translate(title) : title;
  const translatedError = typeof error === 'string' ? Translate(error) : error;
  return (
    <MuiThemeProvider theme={MaterialUiThemeUZ}>
      <SignModalWrap>
        <ModalHeading>
          {translatedTitle}
          <Icon type={'cross'} onClick={onCloseModal}/>
        </ModalHeading>
        <ModalContentWrap>
          {(error && countryCode === 'UZ') && <ErrorWrap>{translatedError}</ErrorWrap>}
          {(cert && countryCode === 'UZ') && (
            <CertWrap>
              <span>{Translate('Документ будет подписан сертификатом')}:</span>
              {cert.name}
            </CertWrap>
          )}
          <FieldsWrapper>
            <FieldsInfo
              docType={typeDoc}
              formValues={formValues}
              finalConfirm
            />
          </FieldsWrapper>
        </ModalContentWrap>
      </SignModalWrap>
      {
        cisColumns && (
          <Fragment>
            <TableWrap onClick={handleOpenCis}>
              <TitleTable>
                {Translate('Коды')}{' '}
                {cisData && cisData.length > 0 ? (
                  <React.Fragment>
                    {cisData.length} {Translate('шт.')}
                  </React.Fragment>
                ) : null}
              </TitleTable>
              <IconWrap>
                <Icon
                  type={cisOpen ? 'up-arrow' : 'down-arrow'}
                  spacingLeft={6}
                />
              </IconWrap>
            </TableWrap>
            {cisOpen ? table : null}
            <br/>
          </Fragment>
        )
      }
      {
        goodsColumns && (
          <Fragment>
            <TableWrap onClick={handleOpenGoods}>
              <TitleTable>
                {Translate('Товары')}{' '}
                {goodsData && goodsData.length > 0 ? (
                  <React.Fragment>
                    {goodsData.length} {Translate('шт.')}
                  </React.Fragment>
                ) : null}
              </TitleTable>
              <IconWrap>
                <Icon
                  type={goodsOpen ? 'up-arrow' : 'down-arrow'}
                  spacingLeft={6}
                />
              </IconWrap>
            </TableWrap>
            {goodsOpen && (
              <Table
                columns={goodsColumns}
                data={goodsData}
                minRows={0}
                highlight
                striped
                defaultPageSize={1000}
              />
            )}
          </Fragment>
        )
      }
      <ButtonWrap>
        <PrimaryActionLargeButton
          disabled={countryCode === 'UZ' && error}
          onClick={onSubmit}
        >
          {Translate('Подписать и отправить')}
        </PrimaryActionLargeButton>
        <CommonActionButton onClick={onCloseModal}>
            {Translate('Отменить')}
        </CommonActionButton>
      </ButtonWrap>
    </MuiThemeProvider>
  );
};

const mapState = state => {
  return {
    certificates: cadesSelectors.certificates(state),
    countryCode: AppSelectors.countryCode(state),
  };
};

export default compose(
  connect(mapState, null),
  withTheme,
)(FinalConfirmModal);
