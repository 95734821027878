import styled from 'styled-components';


export const IconWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TextWrap = styled.div`
  width: 247px;
  display: inline-block;
`;
