import { takeEvery, call, put, select, take } from 'redux-saga/effects';
import * as actions from './Auth.actions';
import { replace } from 'react-router-redux';
import {
  isTokenValidAndNotExpired,
  getUserFromToken,
} from '../../utils/userUtils';
import * as authActions from './Auth.actions';
import * as authSelectors from './Auth.selectors';
import { ProfileService } from '../../services/ProfileService';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import { PARTICIPANT_TYPES } from '../../constants';
import * as CADESActions from '../Cades/Cades.actions';
import { getCertBySerialFromCerts } from '../../services/utils';
import { getToken } from '../../utils/userUtils';
import { getCertSerial, getCertSerialType } from '../../utils/cookieHelpers';
import {
  getRedirectUrl,
  getProfileUrl,
  getAllowedCategories,
  getAllowedOGVCategories,
} from './Auth.utils';
import { activePG } from '../../constants/productGroups';

function* loadedSaga(action) {
  if (window.location.hostname === 'ismotp.crptech.ru') {
    const url = call(getRedirectUrl);
    window.location.href = url;
  }

  const token = yield call(getToken);
  const valid = yield call(isTokenValidAndNotExpired, token);
  const from = yield select(authSelectors.startRoute);
  if (valid) {
    const user = yield call(getUserFromToken, token);
    const groupsResult = yield call(ProfileService.getProductGroups);
    const productGroups = get(groupsResult, 'data.result', []);

    if (productGroups) yield put(authActions.productGroups(productGroups
      .reduce((filtered, el) => {
        if (el.productGroupStatus === 'COMMERCIAL' || el.productGroupStatus === 'EXPERIMENT') filtered.push(el.code);
        return filtered;
      }, [])));

    user.allowedCategories = getAllowedCategories(
      productGroups,
      user.product_group_info,
    );

    try {
      if (user.organisation_status === 'PRE_REGISTERED_ISSUER') {
        /*eslint-disable */
        const url = call(getProfileUrl);
        window.location.href = url;
        /*eslint-enable */
      }
    } catch (e) {
      console.log('cannot parse user');
    }

    //get balance
    try {
      const orgRoles = user.authorities
        .filter(v => ~v.indexOf('ROLE_ORG_'))
        .map(v => v.replace('ROLE_ORG_', ''));
      if (
        intersection(orgRoles, [
          PARTICIPANT_TYPES.IMPORTER,
          PARTICIPANT_TYPES.PRODUCER,
        ]).length > 0
      ) {
        const balance = yield call(ProfileService.getCompanyBalance);
        user.balance = balance.data;
      }
    } catch (e) {
      console.log('cannot get balance', e);
    }

    //end balance

    //get company shortName
    try {
      const company = yield call(ProfileService.getCompanyProfile2);
      if (company.status === 200) {
        yield put(authActions.saveCompany(company.data));
      }
      user.company
        ? (user.company.shortName = get(company, 'data.shortName', ''))
        : (user.company = { shortName: get(company, 'data.shortName', '') });
    } catch (e) {
      console.log('cannot load company info', e);
    }

    //end company shortName

    yield put(authActions.gotInfo(user));
    const replaceWith = action.payload
      ? { pathname: action.payload.pathname, search: action.payload.search }
      : from.pathname
        ? { pathname: from.pathname, search: from.search }
        : '/home';
    yield put(replace(replaceWith));
    yield put(authActions.gotStartRoute({}));

    //get image
    try {
      if (user.pid) {
        const image = yield call(ProfileService.getParticipantImage, user.pid);

        if (image) {
          const base64String = btoa(
            String.fromCharCode(...new Uint8Array(image.data)),
          );
          user.company
            ? (user.company.image = 'data:image/png;base64,' + base64String)
            : (user.company = {
              image: 'data:image/png;base64,' + base64String,
            });
        }
      }
      yield put(authActions.gotInfo(user));
    } catch (e) {
      console.error('cannot load image:', { e });
    }

    //end image

    yield put(authActions.loadCert());
  } else {
    yield put(authActions.clear());
    if (action.payload) yield put(authActions.gotStartRoute(action.payload));
    yield put(replace('/login-kep'));
  }
}

function* loadCertSaga() {
  try {
    const certSerial = yield call(getCertSerial);
    const certType = yield call(getCertSerialType);

    if (certSerial) {
      switch (certType) {
        case 'CADES':
        default: {
          yield put(CADESActions.load());
          const { payload } = yield take(`${CADESActions.loaded}`);
          const cert = yield call(
            getCertBySerialFromCerts,
            certSerial,
            payload,
          );
          yield put(authActions.certLoaded(cert));
          break;
        }
      }
    }
  } catch (e) {
    console.log('cannot load certificate');
  }
}

export default function* watchAuthCalls() {
  yield takeEvery(actions.loaded, loadedSaga);
  yield takeEvery(actions.loadCert, loadCertSaga);
}
