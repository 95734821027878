import { combineReducers } from 'redux';
import moment from 'moment/moment';
import { actionTypes } from '../../constants';
import { get } from 'lodash';

const defaultTypeState = [];
const types = (state = defaultTypeState, action) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.TYPES.LOADING:
      // case actionTypes.ALT_SCENARIOS.TYPES.LOAD_ERROR:
      return defaultTypeState.slice();
    case actionTypes.ALT_SCENARIOS.TYPES.LOADED:
      return action.values;
    default:
      return state;
  }
};

const monthStart = moment().startOf('month');
const monthBefore = moment(monthStart).add(-6, 'month');

const filterParams = (
  state = {
    documentDateFrom: monthBefore.format('DD.MM.YYYY'),
    documentDateTo: monthStart.format('DD.MM.YYYY'),
  },
  action = {}
) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.FILTER_PARAMS_UPDATED:
      return action.value;
    default:
      return state;
  }
};

const defaultStatisticState = {};
const statistic = (state = defaultStatisticState, action) => {
  const temp = {};
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.STATISTIC.LOADING:
    case actionTypes.ALT_SCENARIOS.STATISTIC.LOAD_ERROR:
      return defaultStatisticState;
    case actionTypes.ALT_SCENARIOS.STATISTIC.LOADED:
      action.values.forEach((s) => {
        const code = get(s, 'scenarioType.code', null);
        if (!code) return;
        if (!temp[code]) temp[code] = [];
        temp[code].push(s);
      });
      Object.keys(state).forEach((k) => {
        if (!temp[k]) temp[k] = state[k];
        else {
          temp[k] = [].concat(temp[k], state[k]);
        }
      });
      return temp;
    default:
      return state;
  }
};

const roundGraphStats = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.ROUND_GRAPH.LOADING:
    case actionTypes.ALT_SCENARIOS.ROUND_GRAPH.LOAD_ERROR:
      return state;
    case actionTypes.ALT_SCENARIOS.ROUND_GRAPH.LOADED:
      return action.values;
    default:
      return state;
  }
};

const taxes = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.TAXES.LOADING:
    case actionTypes.ALT_SCENARIOS.TAXES.LOAD_ERROR:
      return state;
    case actionTypes.ALT_SCENARIOS.TAXES.LOADED:
      return action.values;
    default:
      return state;
  }
};

const loaded = (state = false, action = {}) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.ALL.LOADING:
    case actionTypes.ALT_SCENARIOS.UNMOUNTED:
      return false;
    case actionTypes.ALT_SCENARIOS.ALL.LOADED:
      return true;
    default:
      return state;
  }
};

const scheme = (state = null, action = {}) => {
  switch (action.type) {
    case actionTypes.ALT_SCENARIOS.SCHEME_LOADED:
      return action.value;
    default:
      return state;
  }
};

export default combineReducers({
  types,
  statistic,
  roundGraphStats,
  taxes,
  filterParams,
  scheme,
  loaded,
});
