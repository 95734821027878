import * as _ from 'lodash';
import { compose } from 'redux';
import { Translate } from '../../../../common_components/Translate/Translate';

export const formValidator = initFormValues => values =>
  compose(
    rolesEqual(values, initFormValues),
  )({});

const rolesEqual = (values, initFormValues) => errors => {
  if (_.isEmpty(_.get(values, 'sharedRoles'))) {
    return errors;
  }

  if (
    _.isEmpty(
      _.xor(
        _.get(values, 'sharedRoles', []),
        _.get(initFormValues, 'sharedRoles', []),
      ),
    )
  ) {
    return {
      ...errors,
      sharedRoles: Translate('Роли не были изменены'),
    };
  }

  return errors;
};
