import styled from 'styled-components';


export const ErrorNotification = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  color: #ff3c21;
  margin-left: 50px;
`;

export const EnterWrap = styled.div`
  line-height: 32px;
  font-size: 32px;
  color: #52535a;
  margin-bottom: 26px;
`;

export const ContentWrap = styled.div`
  margin-top: -100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const IconWrap = styled.div`
  height: 129px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Outer = styled.div`
  display: table-row;
  height: auto;
  background: #fcfcfc;
`;

export const Inner = styled.div`
  display: table-cell;
  height: auto;
  box-sizing: border-box;
`;

export const SelectWrap = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 53px 50px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;