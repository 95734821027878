import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from '../Translate/Translate';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
  };

  state = {
    isError: false,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ isError: true, info: info.componentStack });
  }

  render() {
    const { children } = this.props;
    const { isError, info } = this.state;

    if (isError) {
      return (
        <React.Fragment>
          {Translate('Произошла ошибка.')}
          <div style={{ display: 'none' }}>{info}</div>
        </React.Fragment>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
