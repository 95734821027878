import { inputGoodsTheme, inputInformationTheme } from '../../../../theme/ru-light/input';
import { datepickerGoodsTheme, datepickerInformationTheme } from '../../../../theme/ru-light/datepicker';
import { selectGoodsTheme, selectInformationTheme } from '../../../../theme/ru-light/select';


export const previewType = {
  CANCEL: 0,
  CONFIRMATION: 1,
};

export const documentSteps = {
  COMMON: 0,
  GOODS: 1,
  CIS: 2,
};

export const displayNone = { display: 'none' };

export const visibilityHidden = () => null;

export const informationTheme = {
  Input: inputInformationTheme,
  DatePicker: datepickerInformationTheme,
  Select: selectInformationTheme,
};

export const goodsTheme = {
  Input: inputGoodsTheme,
  DatePicker: datepickerGoodsTheme,
  Select: selectGoodsTheme,
};
