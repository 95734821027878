import React from 'react';
import { actionTypes } from '../constants';
import { ProfileService } from '../services/ProfileService';
import { loadForm, onItemChange } from './Form';
import { CertificateService } from '../services/CertificateService';
import * as ProfileSelectors from '../selectors/Profile';
import { clearMaskedPhone } from '../utils';
import { CadesUtil } from '../utils/CadesUtil';
import { Toast } from '../utils/Toast';
import { showAnyError } from '../utils/requestErrorHandlers';
import { pull, isNull } from 'lodash';
import {startPreloader, endPreloader} from './Preloader';
import {getCurrentParticipantId} from '../utils/userUtils';
import {userInn} from '../common_components/Auth/Auth.selectors';

export function companyFormMounted() {
  return async dispatch => {

    try {
      dispatch(startPreloader());
      const answer = await ProfileService.getCompanyProfile();
      dispatch(companyProfileLoaded(answer.data));
      const image = await ProfileService.getParticipantImage(answer.data.id);
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(image.data))
      );
      dispatch(
        companyInfoUpdated({ image: 'data:image/png;base64,' + base64String })
      );
    } catch (e) {
      console.log('loading profile info error: ', e);
      dispatch(companyFormError('Ошибка загрузки информации о компании.'));
    }

    finally {
      dispatch(endPreloader());
    }
  };
}

export function companyFormError(error) {
  return {
    type: actionTypes.PROFILE.COMPANY_INFO_ERROR,
    value: error
  }
}

export function companyInfoUpdated(value) {
  return {
    type: actionTypes.PROFILE.PROFILE_INFO_UPDATED,
    value,
  };
}

export function companyInfoMounted() {
  return async dispatch => {
    try {
      const answer = await ProfileService.getCompanyProfile();
      console.log('info mounted', answer);
      dispatch(companyProfileLoaded(answer.data));

      const image = await ProfileService.getParticipantImage(answer.data.id);
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(image.data))
      );
      dispatch(
        companyInfoUpdated({ image: 'data:image/png;base64,' + base64String })
      );
    } catch (err) {
      console.log('loading profile info error: ', err);
    }
  };
}

export function removeUser(id) {
  return dispatch => {
    ProfileService.removeUserById(id)
      .then(answer => {
        Toast.showSuccess({
          content: 'Пользователь успешно удален.',
        });
      })
      .catch(err => {
        Toast.showError({ content: 'Ошибка при удалении пользователя' });
      });
  };
}

export function changeUserStatus(id, status) {
  return dispatch => {
    ProfileService.changeUserStatus(id, status).then(answer => {
      Toast.showSuccess({
        content: 'Пользователь успешно восстановлен.',
      });
      dispatch(userListMounted());
    });
  };
}

export function profileEditUnmounted() {
  return {
    type: actionTypes.PROFILE.PROFILE_EDIT_UNMOUNTED,
  };
}

export function profileEdited() {
  return {
    type: actionTypes.PROFILE.PROFILE_EDITED,
  };
}

export function openModal(callback, token) {
  return {
    type: actionTypes.PROFILE.OPEN_MODAL,
    value: {callback, token},
  };
}

export function closeModal() {
  return {
    type: actionTypes.PROFILE.CLOSE_MODAL,
  };
}

function companyProfileLoaded(value) {
  return {
    type: actionTypes.PROFILE.PROFILE_INFO_LOADED,
    value,
  };
}

export function userFormMounted(path, id) {
  return async (dispatch, getState) => {
    try {
      const request = id
        ? ProfileService.getUserProfile(id)
        : ProfileService.getCurrentUser();

      const answer = await request;

      try {
        const image = await ProfileService.getParticipantImage(
          answer.data.user.id
        );
        const base64String = btoa(
          String.fromCharCode(...new Uint8Array(image.data))
        );
        dispatch(onItemChange(`${path}.image`, base64String));
      } catch (e) {
        console.log('cannot load image', e);
      }

      dispatch(loadForm(path, answer.data));
      try {
        const [f, i, o] = answer.data.user.fullName.split(' ');
        dispatch(onItemChange(`${path}.user.firstName`, i));
        dispatch(onItemChange(`${path}.user.lastName`, f));
        dispatch(onItemChange(`${path}.user.middleName`, o));
      } catch (e) { null; }

      const toastLoadId = Toast.showCancelable({
        content: 'Идет загрузка ролей...',
      });

      try {
        const roleList = await ProfileService.getUserRoleListWithPerms();
        dispatch(onItemChange(`${path}.availableRoles`, roleList.roles));
        dispatch(onItemChange(`${path}.availablePerms`, roleList.perms));
        Toast.showSuccess({ content: 'Роли загружены' });
      } catch (e) {
        Toast.showError({ content: 'Не удалось загрузить роли' });
      } finally {
        Toast.closeToast(toastLoadId);
      }
    } catch (err) {
      console.log('user info loading error:', err);
    }
  };
}

export const certificatesLoaded = certificates => ({
  type: actionTypes.PROFILE.CERTIFICATES_LOADED,
  value: certificates,
});

export function newUserFormMounted(path, id) {
  const newUserData = {
    fullName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    participantId: id,
    email: '',
    phones: [''],
    assignedRoles: [],
    availableRoles: [],
    availablePerms: [],
  };

  return async dispatch => {
    dispatch(loadForm(path, newUserData));

    CadesUtil.getFinalCertsArray().then(certificates => {
      dispatch(certificatesLoaded(certificates));
    });

    const toastLoadId = Toast.showCancelable({
      content: 'Идет загрузка ролей...',
    });

    try {
      const roleList = await ProfileService.getUserRoleListWithPerms();
      dispatch(onItemChange(`${path}.availableRoles`, roleList.roles));
      dispatch(onItemChange(`${path}.availablePerms`, roleList.perms));
      Toast.showSuccess({ content: 'Роли загружены' });
    } catch (e) {
      showAnyError(e, 'Не удалось загрузить роли: ');
    } finally {
      Toast.closeToast(toastLoadId);
    }
  };
}

export function onCompanySaveClick(val, onSave, token) {
  return async (dispatch, getState) => {
    const toastId = Toast.showInfo({
      autoClose: false,
      content: 'Идет сохранение профиля...',
    });

    try {
      const answer = await ProfileService.updateCompanyProfile(val,token);
      const uploadedImage = ProfileSelectors.uploadedImage(getState());
      const params = {};

      if (uploadedImage) {
        const imageToastId = Toast.showInfo({
          autoClose: false,
          content: 'Идет сохранение изображения...',
        });

        try {
          params.image = uploadedImage.file;
          const uploadAnswer = await ProfileService.uploadImage(params);
          Toast.showSuccess({ content: 'Изображение успешно загружено.' });
          dispatch(imageSelected(null));
          dispatch(companyInfoMounted());
        } catch (e) {
          showAnyError(e, 'Не удалось загрузить изображение: ');
        } finally {
          Toast.closeToast(imageToastId);
        }
      }

      Toast.showSuccess({ content: 'Профиль успешно сохранен.' });
      onSave();
    } catch (e) {
      const { response: { data } } = e;
      let arrError = [];
      if (data.error_message) {
        arrError = data.error_message.match( /(]]; default message \[).+?]/ig ) || [];
        pull(arrError, ']]; default message []]');
      }
      const text = arrError.map((v,i) => <div key={i}>{v.replace(/(]]; default message \[)(.+?)]/, '$2')}</div>) || [];
      !isNull(text) &&
      Toast.showError({
        content: (
          <div>
            Ошибки при сохранении профиля: <br />
            {text}
          </div>
        ),
      });
    } finally {
      Toast.closeToast(toastId);
    }
  };
}

export function onUserSaveClick(isNew, onSave) {
  return async (dispatch, getState) => {
    const state = getState();

    const values = state.Form.fields.UserProfile;
    values.user.phones = values.user.phones.map(f => clearMaskedPhone(f));

    if (isNew) {
      const toastId = Toast.showInfo({
        content: 'Идет создание пользователя...',
        autoClose: false,
      });

      values.user.user_name = `${values.user.lastName} ${
        values.user.firstName
      } ${values.user.middleName}`;
      delete values.user.lastName;
      delete values.user.firstName;
      delete values.user.middleName;
      values.user.cert_number = values.user.serial;
      delete values.user.serial;
      values.user.trust_center_inn = values.user.inn;
      delete values.user.inn;

      const data = {
        ...values.user,
        sender_inn: userInn(state),
        user_role: values.assignedRoles[0]
          ? values.assignedRoles[0].role.name
          : 'USER',
      };

      try {
        const certs = await CadesUtil.getFinalCertsArray();
        const certificate = CadesUtil.getCertBySerial(certs);

        const document = CadesUtil.Base64.encode(JSON.stringify(data));
        const signature = await CadesUtil.signMessage(
          document,
          certificate,
          true,
          true
        );

        const signedData = {
          signature,
          product_document: document,
          document_format: 'MANUAL',
        };

        try {
          const answer = await ProfileService.saveNewUserWithLk(signedData);
          Toast.showSuccess({
            content:
              'Новый пользователь успешно добавлен. Информация о новом пользователе направлена в службу технической поддержки',
          });
        } catch (e) {
          Toast.showError({
            content: `Не удалось добавить пользователя. ${
              e.message ? `Причина: ${e.message}` : ''
            }`,
          });
        }
      } catch (e) {
        Toast.showError({
          content: 'Не удалось получить сертификат или подписать данные.',
        });

        console.log('error:', {e});

      } finally {
        Toast.closeToast(toastId);
        onSave();
      }
    } else {
      const toastSaveUserId = Toast.showCancelable({
        content: 'Идет обновление пользователя...',
      });

      const roles = values.assignedRoles.map(({role}) => role.name);

      try {
        values.user.fullName = `${values.user.lastName} ${
          values.user.firstName
        } ${values.user.middleName}`;
        delete values.user.lastName;
        delete values.user.firstName;
        delete values.user.middleName;
        const finalValues = { ...values.user, roles };
        console.log('finalValues', finalValues);

        const answer = await ProfileService.updateUserProfile(finalValues);
        console.log('answer save user', answer);
        Toast.showSuccess({
          content: 'Пользователь успешно обновлен.',
        });
      } catch (e) {
        showAnyError(e, 'Не удалось обновить пользователя:');
      } finally {
        Toast.closeToast(toastSaveUserId);
      }
    }
  };
}

export function requestActiveUsers() {
  return dispatch => {
    ProfileService.getActiveUsers()
      .then(answer => dispatch(userActiveListLoaded(answer.data)))
      .catch(err => console.log('user list loading error:', err));
  };
}

export function requestRemovedUsers() {
  return dispatch => {
    ProfileService.getRemovedUsers()
      .then(answer => dispatch(userRemovedListLoaded(answer.data)))
      .catch(err => console.log('user list loading error:', err));
  };
}

function userActiveListLoaded(value) {
  return {
    type: actionTypes.PROFILE.USER_ACTIVE_LIST_LOADED,
    value,
  };
}

function userRemovedListLoaded(value) {
  return {
    type: actionTypes.PROFILE.USER_REMOVED_LIST_LOADED,
    value,
  };
}

export function certSelected(value) {
  return {
    type: actionTypes.PROFILE.CERT_SELECTED,
    value,
  };
}

function certUploaded(message) {
  return { type: actionTypes.PROFILE.CERT_UPLOADED, value: message };
}

export function certUpload(cert) {
  return dispatch => {
    const formData = new FormData();
    formData.append('file', cert);
    CertificateService.uploadCertificate(formData)
      .then(answer => {
        console.log('answer upload', answer);
        dispatch(certUploaded(answer.data.message));
      })
      .catch(err => console.log('error upload', err));
  };
}

export function refreshUploading() {
  return dispatch => {
    dispatch(certSelected(null));
    dispatch(certUploaded(null));
  };
}

export function imageSelected(image) {
  return dispatch => {
    if (!image)
      return dispatch({
        type: actionTypes.PROFILE.IMAGE_SELECTED,
        value: null,
      });

    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = function() {
      const result = reader.result;
      dispatch({
        type: actionTypes.PROFILE.IMAGE_SELECTED,
        value: { file: image, base64: result },
      });
    };
  };
}

export function onImageUpload() {
  return (dispatch, getState) => {
    console.log('onImageUpload');
    const uploadedImage = ProfileSelectors.uploadedImage(getState());
    dispatch(companyInfoUpdated({ image: uploadedImage.base64 }));
  };
}
