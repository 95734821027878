import React from 'react';
import { statusNames } from './ExportList.constants';
import { Translate } from '../../../common_components/Translate/Translate';
import { Input, RangePicker, SingleSelect } from '../../../common_components/Form/Fields/Fields';
import { isOperator } from '../../../utils/userUtils';
import { useTranslation } from 'react-i18next';

const ExportListFilters = () => {
  const {t} = useTranslation();
  const statusesList = Object.keys(statusNames).map(el=> ({
    title: t(statusNames[el]),
    type: el,
    id: el,
  }))

  const hasPermission = isOperator();

  return (
    <React.Fragment>
      <RangePicker
        name="date"
        leftPlaceholder={Translate('Период получения от')}
        rightPlaceholder={Translate('Период получения до')}
      />
      {
        hasPermission && (
          <Input name="senderInn" placeholder={Translate('ИНН отправителя')}/>
        )
      }
      <SingleSelect
        name="status"
        placeholder={Translate('Статус')}
        values={statusesList}
      />
    </React.Fragment>
  );
};

export default ExportListFilters;
