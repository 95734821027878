import React from 'react';

import { SingleSelect } from '@crpt/react-select';
import { FilterURLHelper } from '../../utils/FilterURLHelper';
import { PARTICIPANT_TYPES, participantNames } from '../../constants';
import { Translate } from '../../common_components/Translate/Translate';

const types = [
  {
    id: 0,
    value: participantNames[PARTICIPANT_TYPES.TRADE_PARTICIPANT],
    title: participantNames[PARTICIPANT_TYPES.TRADE_PARTICIPANT],
    type: PARTICIPANT_TYPES.TRADE_PARTICIPANT,
  },
  {
    id: 1,
    value: participantNames[PARTICIPANT_TYPES.IMPORTER],
    title: participantNames[PARTICIPANT_TYPES.IMPORTER],
    type: PARTICIPANT_TYPES.IMPORTER,
  },
  {
    id: 2,
    value: participantNames[PARTICIPANT_TYPES.PRODUCER],
    title: participantNames[PARTICIPANT_TYPES.PRODUCER],
    type: PARTICIPANT_TYPES.PRODUCER,
  },
  {
    id: 3,
    value: participantNames[PARTICIPANT_TYPES.WHOLESALER],
    title: participantNames[PARTICIPANT_TYPES.WHOLESALER],
    type: PARTICIPANT_TYPES.WHOLESALER,
  },
  {
    id: 4,
    value: participantNames[PARTICIPANT_TYPES.RETAIL],
    title: participantNames[PARTICIPANT_TYPES.RETAIL],
    type: PARTICIPANT_TYPES.RETAIL,
  },
  {
    id: 5,
    value: participantNames[PARTICIPANT_TYPES.OGV],
    title: participantNames[PARTICIPANT_TYPES.OGV],
    type: PARTICIPANT_TYPES.OGV,
  },
];

class ParticipantTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const value = FilterURLHelper.getParamByKey(name);
    if (value) {
      const status = types.find(v => v.type === value);
      this.select.current.onSelect({ preventDefault: () => {} }, status);
    }
  }

  render() {
    const { onRef } = this.props;

    return (
      <SingleSelect
        name="type"
        placeholder={Translate("Тип участника")}
        values={types}
        {...this.props}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

ParticipantTypeSelect.displayName = 'SingleSelect';

export default ParticipantTypeSelect;
