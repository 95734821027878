import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { FlexWrapper } from './styled';
import HeadedLabel from './HeadedLabel';
import { Translate } from '../../../common_components/Translate/Translate';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { TwoLineLabel } from '@crpt/react-label';
import React, { Fragment } from 'react';
import { documentTypeNames } from '../../../constants';
import { pgTypesNames } from '../../../constants/productGroups';
import { connect } from 'react-redux';
import { language } from '../../../selectors/LoginFormKep';
import { useTranslation } from 'react-i18next';

const AicBasicInfo = ({ selectedDocument, currentLanguage }) => {
  const {
    senderName,
    senderInn,
    pg,
    tnved,
    gtin,
    cisesCount,
  } = selectedDocument;
  
  const {
    status,
    documentDate,
    documentId,
    gtkApproved,
    tnvedAddUnits,
    tnvedAddUnitsCount,
    tnvedAddUnitsData,
  } = selectedDocument;

  const {t} = useTranslation()

  const tnvedAddUnitsText = tnvedAddUnits
    ? tnvedAddUnitsData.values.find(
        tnvedAddUnitsValue => tnvedAddUnitsValue.code === tnvedAddUnits,
      ).translations[currentLanguage.toLowerCase()] +
      ' ' +
      `(${tnvedAddUnits})`
    : t('Отсутствует');

  return (
    <Fragment>
      <HeadingWrap
        title={documentTypeNames.AIC_AGGREGATION}
        id={documentId}
        gtkApproved={gtkApproved}
        status={status}
        date={documentDate}
        docType={documentTypeNames.AIC_AGGREGATION}
        needDocTitle={false}
        bottomId={true}
      />
      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Общие данные')}>
            <TwoLineLabel heading={Translate('ИНН/ПИНФЛ заявителя')}>{senderInn}</TwoLineLabel>
            <TwoLineLabel heading={Translate('Наименование заявителя')}>{senderName}</TwoLineLabel>
            <TwoLineLabel heading={Translate('Товарная группа')}>{pg ? pgTypesNames[pg.toLowerCase()] : '-'}</TwoLineLabel>
          </DocumentInfoBlock>
        </Column>
      </Container>
      <FlexWrapper>
        <HeadedLabel header={Translate('ТНВЭД')} value={tnved} />
        <HeadedLabel header={Translate('GTIN потребительской упаковки')} value={gtin} />
        <HeadedLabel header={Translate('Количество потребительских упаковок')} value={cisesCount} />
      </FlexWrapper>
      <FlexWrapper>
        <HeadedLabel header={Translate('Дополнительная единица измерения')} value={tnvedAddUnitsText} />
        <HeadedLabel header={Translate('Количество товара в дополнительной единице измерения')} value={tnvedAddUnitsCount} />
      </FlexWrapper>
    </Fragment>
  );
};

const mapState = state => ({
  currentLanguage: language(state),
});

export default connect(mapState, null)(AicBasicInfo);
