import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: white;
  min-width: 460px;
`;

export const Container = styled.div`
  position: relative;
  max-width: 100%;
`;


export const Content = styled.div`
  max-height: 75vh;
  margin: 32px 71px 32px 71px;
  line-height: 1.5;
  font-size: 16px;
  color: #434343;
  text-align: center;
  overflow-y: auto;
`;

export const Message = styled.div`
  text-align: left;
  font-size: 14px;
  & div {
    margin-bottom: 4px;
  }
`;

export const Caption = styled.div`
  position: relative;
  background-color: #FF3D00;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  padding: 19px 32px 19px 32px;
`;

export const IcoWrap = styled.div`
  position: absolute;
  right: 23px;
  top: 23px;
  font-size: 20px;
  font-weight: 600;
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 24px;
`;

export const ButtonsWrapper = styled.div`
  height: 70px;
  padding: 19px 32px 19px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ActionsWrapper = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 100%;
`;
