import styled from 'styled-components';
import { get } from 'lodash';

export const PageWrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 145px);
  padding: 34px;
  overflow: visible !important;
  z-index: 0;
  background: ${props => get(props, 'theme.Content.backgroundColor', 'white')};
  margin-bottom: 0;
  box-shadow: ${props => get(props, 'theme.Content.shadowX', '0')}
    ${props => get(props, 'theme.Content.shadowY', '0')}
    ${props => get(props, 'theme.Content.shadowBlur', '0')}
    ${props => get(props, 'theme.Content.shadowColor', 'black')}
    ${({ disabledBoxShadow }) => {
      if (disabledBoxShadow) return `box-shadow: none;`;
    }};
`;
export const CardHeading = styled.div`
  font-size: 24px;
`;
export const Paragraph = styled.div`
  padding-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
`;
export const Paragraph2 = styled.div`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #63666a;
  font-weight: 600;
`;

export const Content = styled.div`
  font-size: 16px;
`;

export const Accord = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  color: #63666a;
  font-weight: 600;
`;

export const AccordTitle = styled.div`
  display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-width:${props => props.withWidth ? '190px' : 'auto'};
`;

export const IconWrap = styled.div`
  padding-top: 3px;
`;

export const TableWrap = styled.div`
  & div[role='rowgroup'] {
    height: calc(100vh - 410px) !important;
  }
  // Эти стили скрывают скролбар в Firefox
  @-moz-document url-prefix() {
    & div[role='rowgroup'] {
      & > div:first-child {
        & > div {
          scrollbar-width: none !important;
        }
      }
    }
  }
`;

export const ButtonWrap = styled.div`
    position: absolute;
    bottom: 18px;
    right: 34px;
`;

export const CalculationOfTotalsAndSending = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    color: white;
    margin-left: 20px;
  }
`;
