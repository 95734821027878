import React, { useMemo } from 'react';
import papaparse from 'papaparse';
import { withTheme } from 'styled-components';
import CsvFilePreviewView from './CsvFilePreview.view';


const CsvFilePreview = (props) => {
  const { content, ...pass } = props;

  const data = useMemo(() => papaparse.parse(content, { header: false }).data, [content]);

  return (
    <CsvFilePreviewView content={data} {...pass} />
  );
};

export default withTheme(CsvFilePreview);
