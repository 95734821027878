import React, { Fragment } from 'react';
import { get } from 'lodash';

import { FormLine } from '@crpt/react-form';
import { TwoLineLabel } from '@crpt/react-label';
import { packTypesLegacy } from '../../../../constants';

import {
  CardHeading,
  QRCodeWrapper,
  QRCodeTextWrapper,
  TwoColumns,
} from '../BasicInfo.styled';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { Translate } from '../../../../common_components/Translate/Translate';
import { pgTypesNames } from '../../../../constants/productGroups';

const ProductInfo = props => {
  const goodImage = get(props, 'data.product.goodImage', null);
  const brand = get(props, 'data.product.brand', null);
  const productName = get(props, 'data.product.productName', null);
  const gtin = get(props, 'data.product.gtin', null);
  const packageType = get(props, 'data.product.packageType', null);
  const innerUnitCount = get(props, 'data.product.innerUnitCount', null);
  const producerName = get(props, 'data.product.producerName', null);
  const tnved = get(props, 'data.tnved', null) || get(props, 'data.tnved10', '-');
  const productGroup = get(props, 'data.productGroup', '-');
  const { countryCode }= props

  return (
    <React.Fragment>
      <CardHeading>{Translate('Информация о товаре')}</CardHeading>

      <TwoColumns>
        <QRCodeWrapper image={goodImage} />
        <QRCodeTextWrapper>
          <FormLine>
            <TwoLineLabel heading={Translate('Марка')}>
              {brand || '—'}
            </TwoLineLabel>
          </FormLine>

          <FormLine>
            <TwoLineLabel heading={Translate('Наименование')}>
              {productName || '—'}
            </TwoLineLabel>
          </FormLine>
        </QRCodeTextWrapper>
      </TwoColumns>
      <FormLine>
        <TwoLineLabel heading={'GTIN'}>{gtin || '—'}</TwoLineLabel>
      </FormLine>
      {
        countryCode === 'UZ' && packageType === 'aic' && (
          <Fragment>
            <FormLine>
              <TwoLineLabel heading={'Товарная группа'}>{Translate(pgTypesNames[productGroup.toLowerCase()] || '—')}</TwoLineLabel>
            </FormLine>
            <FormLine>
              <TwoLineLabel heading={'ТНВЭД'}>{tnved}</TwoLineLabel>
            </FormLine>
          </Fragment>
        )
      }
      <FormLine>
        <TwoLineLabel heading={Translate('Упаковка')}>
          {Translate(packageType
            ? packTrivialTypesKZ[packageType] || packTypesLegacy[packageType]
            : '—')}
        </TwoLineLabel>
      </FormLine>

      <FormLine>
        <TwoLineLabel heading={Translate('В упаковке')}>
          {innerUnitCount || '—'}
        </TwoLineLabel>
      </FormLine>

      <FormLine>
        <TwoLineLabel heading={Translate('Производитель')}>
          {producerName || '—'}
        </TwoLineLabel>
      </FormLine>
    </React.Fragment>
  );
};

export default ProductInfo;
