import React from 'react';
import PropTypes from 'prop-types';

import EmptyProductionLinesView from './EmptyProductionLines.view';
import { Translate } from 'src/common_components/Translate/Translate';

const EmptyProductionLines = ({ type }) => {
  const getEmptyProductionLinesData = type => {
    switch(type) {
      case 'all':
        return {
          title: Translate('Список производственных линий отсутствует'),
          info: [],
        };
      case 'list':
      default:
        return {
          title: Translate('Список производственных линий пуст'),
          info: [
            {
              keyId: 1,
              info: Translate('Данный шаг не является обязательным для заполнения,'),
            },
            {
              keyId: 2,
              info: Translate('Вы можете пропустить добавление производственных линий и перейти к следующему шагу'),
            },
          ]
        };
    }
  };

  const { title, info } = getEmptyProductionLinesData(type);

  return (
    <EmptyProductionLinesView title={title} infoRows={info} />
  );
};

EmptyProductionLines.propTypes = {
  type: PropTypes.string.isRequired,
};

EmptyProductionLines.defaultProps = {
  type: 'list',
};

export default EmptyProductionLines;
