import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import Api from '../../../../../../common_components/Api/Api';
import * as actions from './DraftItem.actions';

function* mountSaga(action) {
  const { payload } = action;
  if (!payload) return;

  const requestPayload = {
    url: `/api/v3/delegation/${payload}`,
    method: 'get',
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    yield put(
      actions.loaded({
        ...success.data.body,
        __form_document_number: payload,
      }),
    );
  }
  if (error) console.log(error);
}

function* updateSaga(action) {
  const { payload } = action;
  const { id } = payload;

  let requestPayload;

  if (id) {
    requestPayload = {
      url: `/api/v3/delegation/${id}`,
      method: 'put',
      data: {
        delegation: payload,
        id,
      },
    };
  } else {
    const { pdf } = payload;
    delete payload.pdf;

    requestPayload = {
      url: '/api/v3/delegation',
      method: 'post',
      data: {
        delagation: payload,
        file: pdf,
      },
    };
  }

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    console.log(success);
    yield put(push('/registry/delegation'));
  }
  if (error) {
    console.log(error);
    yield put(push('/registry/delegation'));
  }
}

function* removeSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: `/api/v3/delegation/${payload}`,
    method: 'delete',
    params: {
      id: payload,
    },
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    console.log(success);
    yield put(push('/registry/delegation'));
  }
  if (error) {
    console.log(error);
    yield put(push('/registry/delegation'));
  }
}

export default function* watch() {
  yield takeEvery(actions.mounted, mountSaga);
  yield takeEvery(actions.update, updateSaga);
  yield takeEvery(actions.remove, removeSaga);
}
