import React from 'react';

export default {
  title: 'Табличное представление при выборе агрегации «Производитель» и отображение периода «Месяц»:',
  amountOfTable: 4,
  tables: [
// table#1
    {
      headers: [
        {
          Header: 'Производитель',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 180,
          subheaders: []
        },
        {
          Header: 'Итого за период',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 840,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col5',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 150,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 130,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 130,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 150,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col9',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 150,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col10',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 130,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
          'col9': [9],
          'col10': [10],
        },
        {
          'col1': <strong>Итого</strong>,
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
          'col7': <strong>...</strong>,
          'col8': <strong>...</strong>,
          'col9': <strong>...</strong>,
          'col10': <strong>...</strong>,
        },
      ]
    },
// table#2
    {
      headers: [
        {
          Header: 'Итого за N-1 месяц',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 1020,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col11',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 170,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col13',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col14',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col15',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col16',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col11': [5],
          'col12': [6],
          'col13': [7],
          'col14': [8],
          'col15': [9],
          'col16': [10],
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },
      ]
    },
// table#3
    {
      headers: [
        {
          Header: 'Итого за N месяц',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 1020,
          subheaders: [
            {
            Header: 'Эмитирован',
            accessor: 'col11',
            style: {
              textAlign: 'center',
              justifyContent: 'center',
            },
            minWidth: 170,
            subheaders: []
            },
            {
              Header: 'Нанесён',
              accessor: 'col12',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Отгружен',
              accessor: 'col13',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Реализация через кассу',
              accessor: 'col14',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Повторные продажи',
              accessor: 'col15',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            },
            {
              Header: 'Списание',
              accessor: 'col16',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 170,
              subheaders: []
            }
          ]
        },
      ],
      data: [
        {
          'col11': [5],
          'col12': [6],
          'col13': [7],
          'col14': [8],
          'col15': [9],
          'col16': [10],
        },
        {
          'col11': <strong>...</strong>,
          'col12': <strong>...</strong>,
          'col13': <strong>...</strong>,
          'col14': <strong>...</strong>,
          'col15': <strong>...</strong>,
          'col16': <strong>...</strong>,
        },
      ]
    },
  ]
}