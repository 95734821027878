import { Icon } from '@crpt-ui/core';
import React from 'react';

import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import { getDocTypeName } from './constants';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';

const DocumentCorrection = ({ op }) => (
  <Layout>
    <FirstCell
      icon={<Icon name="Correction" />}
      title={Translate('Корректировка')}
    />
    <SecondCell
      firstHeading={Translate('Продавец')}
      secondHeading={Translate('Покупатель')}
      op={op}
    />
    <ThirdCell useLink op={op} heading={Translate(getDocTypeName(op.documentType))} />
  </Layout>
);

export default DocumentCorrection;
