import { Translate } from '../../../../common_components/Translate/Translate';
import { Value, Wrap } from '../../../../fragments/Table/Cell/value';
import { IconWrapper, Link, NoWrap, OptionWrap } from '../../../Grid/Grid.styled';
import React from 'react';
import { getIconNameByPackType } from '../../../TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { Toast } from '../../../../utils/Toast';
import { Tooltip } from '@crpt/react-tooltip';

export const CodesRenderer = ({ row, onClick }) => {
  return (
    <Wrap>
      <Value>{row.marks ? row.marks[0].kiz : '-'}</Value>&nbsp;
      {
        row.marks && (
          <Link href={''} onClick={onClick}>
            +{row.marks ? row.marks.length - 1 : null}
          </Link>
        )
      }
    </Wrap>
  );
};

export const columns = [
  {
    Header: Translate('№ п/п'),
    id: 'item',
    accessor: row => {
      return <Value>{row.item || '-'}</Value>;
    },
    width: 70,
    disableSortBy: true,
  },
  {
    id: 'name',
    accessor: row => {
      return <Value>{row.name || '-'}</Value>;
    },
    disableFilters: true,
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    Header: Translate('Код идентификации'),
    id: 'codes',
    accessor: 'codes',
    width: 'auto',
  },
  {
    id: 'catalogCode',
    accessor: row => {
      return (
        <Tooltip
          content={(<Value>{row.catalogCode || '-'}</Value>)}
        >
          <Value>{row.catalogCode || '-'}</Value>
        </Tooltip>
      )
    },
    Header: Translate('Код товара по ИКПУ'),
    width: 'auto',
  },
  {
    id: 'barCode',
    accessor: row => {
      return <Value>{row.barCode || '-'}</Value>;
    },
    Header: Translate('Штрих-код товара'),
    width: 'auto',
  },
  {
    id: 'mesureId',
    accessor: row => {
      return <Value>{measures[row.mesureId] || '-'}</Value>;
    },
    Header: Translate('Единица измерения'),
    width: 'auto',
  },
  {
    id: 'count',
    accessor: row => {
      return <Value>{row.count || '-'}</Value>;
    },
    Header: Translate('Количество'),
    width: 'auto',
  },
  {
    id: 'price',
    accessor: row => {
      return <Value>{row.price || '-'}</Value>;
    },
    Header: Translate('Цена за ед. (без НДС)'),
    width: 'auto',
  },
  {
    id: 'sum',
    accessor: row => {
      return <Value>{row.sum || '-'}</Value>;
    },
    Header: Translate('Стоимость поставки (без НДС)'),
    width: 'auto',
  },
  {
    id: 'vatRate',
    accessor: row => {
      return <Value>{row.vatRate || '-'}</Value>;
    },
    Header: Translate('Ставка НДС'),
    width: 'auto',
  },
  {
    id: 'vatSum',
    accessor: row => {
      return <Value>{row.vatSum || '-'}</Value>;
    },
    Header: Translate('Сумма НДС'),
    width: 'auto',
  },
  {
    id: 'exciseSum',
    accessor: row => {
      return <Value>{row.exciseSum || '-'}</Value>;
    },
    Header: Translate('Сумма акциз'),
    width: 'auto',
  },
  {
    id: 'exciseRate',
    accessor: row => {
      return <Value>{row.exciseRate || '-'}</Value>;
    },
    Header: Translate('Ставка акциз'),
    width: 'auto',
  },
  {
    id: 'totalSum',
    accessor: row => {
      return <Value>{row.totalSum || '-'}</Value>;
    },
    Header: Translate('Стоимость поставки с учетом НДС и акциза'),
    width: 'auto',
  },

];

export const codesColumns = [
  {
    id: 'date',
    accessor: row => {
      return <Value>{row.date || '-'}</Value>;
    },
    disableFilters: true,
    Header: Translate('Дата нанесения'),
    width: 'auto',
  },
  {
    id: 'kiz',
    accessor: row => {
      return (
        <Link
          onClick={e => {
            e.preventDefault();
            if (row.ownerInn === row.userINN)
              window.open(`/cis/list/${encodeURIComponent(row.kiz)}`);
            else
              Toast.showError({
                content: `Открыть карточку кода идентификации может только его текущий владелец.`,
              });
          }}
        >
          <NoWrap>{row.kiz}</NoWrap>
        </Link>
      );
    },
    Header: Translate('Код идентификации'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'name',
    accessor: row => row.name || '—',
    disableFilters: true,
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    id: 'packageType',
    accessor: row => {
      const pt = row.packageType ? row.packageType.toLowerCase() : '';
      const CODE = ['trade-unit', 'inner-pack'].includes(pt) ? pt : 'pallet';
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{packTrivialTypesKZ[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    disableFilters: true,
    width: 'auto',
  },
  {
    id: 'producer',
    accessor: 'producer',
    disableFilters: true,
    Header: Translate('Производитель'),
    width: 'auto',
  },
];


export const measures = {
  0:	'другой',
  1:	'штук',
  2:	'пачка',
  3:	'миллиграмм',
  4:	'грамм',
  5:	'килограмм',
  6:	'центнер',
  7:	'тонна',
  8:	'миллиметр',
  9:	'сантиметр',
  10:	'дециметр',
  11:	'метр',
  12:	'километр',
  13:	'кв. миллиметр',
  14:	'кв. сантиметр',
  15:	'кв. дециметр',
  16:	'кв. метр',
  17:	'1000 куб. метр',
  18:	'кв. километр',
  19:	'куб. дециметр',
  20:	'куб. метр',
  21:	'куб. километр',
  22:	'миллилитр',
  23:	'литр',
  24:	'дал',
  25:	'сум',
  26:	'комплект',
  27:	'сутка',
  28:	'час',
  29:	'пар',
  30:	'флакон',
  31:	'размещение',
  32:	'показы',
  33:	'коробка',
  34:	'моточас',
  35:	'Гкал',
  36:	'кВтч',
  37:	'рейс',
  38:	'упаковка',
  39:	'минут',
  40:	'норма/час',
  41:	'баллон',
  42:	'день',
  43:	'месяц',
  44:	'мегабайт',
  45:	'серия',
  46:	'бутылка',
  47:	'кега',
  48:	'публикация',
  49:	'рулон',
  50:	'порция',
  51:	'погонный метр',
  52:	'га',
  53:	'кВарч',
  54:	'человек/час',
  55:	'кВар',
  56:	'кВт',
  57:	'год',
  58:	'лист',
  59:	'блок',
  60:	'машина/час',
  61:	'тонна/километр',
  62:	'ящик',
  63:	'ампула',
  64:	'базовая тонна',
  65:	'банка',
  66:	'баррель',
  67:	'бобина',
  68:	'бочка',
  69:	'вагон',
  70:	'галлон',
  71:	'декалитр',
  72:	'доза',
  73:	'работа',
  74:	'канистр',
  75:	'Ккалл',
  76:	'Мешок',
  77:	'млн.т.',
  78:	'млн.шт.',
  79:	'набор',
  80:	'объект',
  81:	'проба',
  82:	'пучок',
  83:	'тыс.т.',
  84:	'тыс.шт.',
  85:	'услуга',
  86:	'шлиф',
}
