import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@crpt/react-icon';
import { Button, SIZES, themes } from '@crpt/react-button';
import { FormLine } from '@crpt/react-form';
import {
  AcceptButtonWrap,
  Controls,
  Header,
  HeaderText,
  SidebarWrap,
} from './styled';
import { buttonTheme } from '../styled';


export default class SidebarContent extends Component {
  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  onAccept = () => {
    const { onAccept, onClose } = this.props;
    onAccept(this.state);
    onClose();
  };

  render() {
    const { onClose } = this.props;

    return (
      <SidebarWrap>
        <Header>
          <HeaderText>Фильтр</HeaderText>
          <Icon
            type="cross"
            width={19}
            height={19}
            onClick={onClose}
            fill="#52535a"
          />
        </Header>
        <Controls>
          <Button
            size={SIZES.large}
            theme={themes.whiteTheme}
            onClick={this.onClear}
          >
            Сбросить
          </Button>
          <AcceptButtonWrap>
            <Button
              size={SIZES.large}
              theme={buttonTheme}
              onClick={this.onAccept}
            >
              Применить
            </Button>
          </AcceptButtonWrap>
        </Controls>
      </SidebarWrap>
    );
  }
}