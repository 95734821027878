import React, { useEffect, useState } from 'react';
import { useStyles } from './icons-selector.styled';
import { Button } from '@crpt-ui/core';
import { Dialog, DialogActions } from '@material-ui/core';
import clsx from 'classnames';
import CloseIcon from '../../../../common_components/List/Filters/CloseIcon';
import * as actions from '../../ducks/articlesCreation.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BUCKET_URL } from '../../../ArticleListPage/constants';

const IconSelector = ({ icon, setIcon, getIconList, icons, lang }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [selected, setSelected] = useState(null);

  const styles = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    getIconList(lang);
  }, [lang])

  const handleClose = () => {
    setSelected(null);
    setOpen(false);
    setTab(0);
  }

  const handleSubmit = () => {
    setIcon(selected);
    setOpen(false);
    setTab(0);
    setSelected(null);
  }

  const onChangeTab = (val) => {
    setTab(val);
    setSelected(null);
  }

  const objects = icons.objects || [];
  const productGroups = icons.productGroups || [];
  const iconList = tab === 0 ? [...objects] : [...productGroups];

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <div className={styles.header}>
          <div className={styles.title}>{t('Выбор изображения')}</div>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.iconsContainer}>
          <div className={styles.tabs}>
            <div
              className={clsx(styles.tab, {[styles.selectedTab]: tab === 0})}
              onClick={() => onChangeTab(0)}
            >
              {t('Обьекты')}
            </div>
            <div
              className={clsx(styles.tab, {[styles.selectedTab]: tab === 1})}
              onClick={() => onChangeTab(1)}
            >
              {t('Товарные группы')}
            </div>
          </div>
          <div className={styles.iconsGrid}>
            { iconList && iconList.map((item) => (
              <div
                className={clsx(styles.icon, {[styles.selectedIcon]: item === selected })}
                onClick={() => {setSelected(item)}}
              >
                <img src={`${BUCKET_URL}${item}`} alt=""/>
              </div>
            ))
            }
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            {t('Отмена')}
          </Button>
          <Button className={"whiteColor"}  variant="contained" onClick={handleSubmit}>
            {t('Сохранить')}
          </Button>
        </DialogActions>
      </Dialog>
      <label htmlFor="">{t('Изображение')}</label>
      <div className={styles.selector} onClick={() => setOpen(true)}>
        {icon ? <img src={`${BUCKET_URL}${icon}`} alt=""/> : t('Выберите изображение') }
      </div>
    </div>
  )
}

const mapState = state => ({
  icons: state.LP.ArticleCreation.iconList,
});

const mapDispatch = dispatch => ({
  getIconList: (lang) => dispatch(actions.getIconList(lang)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(IconSelector);
