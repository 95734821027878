import React from 'react';
import PropTypes from 'prop-types';
import { TwoLineLabel } from '@crpt/react-label';
import { FormLine } from '@crpt/react-form';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Translate } from '../../../common_components/Translate/Translate';

const ShoesAdditionalInfo = ({ selectedProduct }) => {
  const productTypeDesc = get(selectedProduct, 'productTypeDesc', null);
  const model = get(selectedProduct, 'model', null);
  const materialUpper = get(selectedProduct, 'materialUpper', null);
  const color = get(selectedProduct, 'color', null);
  const materialLining = get(selectedProduct, 'materialLining', null);
  const productSize = get(selectedProduct, 'productSize', null);
  const materialDown = get(selectedProduct, 'materialDown', null);

  return (
    <Grid container>
      <Grid item xs={4}>
        <FormLine>
          <TwoLineLabel heading={Translate('Вид обуви')}>
            {productTypeDesc || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
      <Grid item xs={6}>
        <FormLine>
          <TwoLineLabel heading={Translate('Модель производителя')}>
            {model || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>

      <Grid item xs={4}>
        <FormLine>
          <TwoLineLabel heading={Translate('Вид материала верха')}>
            {materialUpper || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
      <Grid item xs={6}>
        <FormLine>
          <TwoLineLabel heading={Translate('Цвет')}>
            {color || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>

      <Grid item xs={4}>
        <FormLine>
          <TwoLineLabel heading={Translate('Вид материала подкладки')}>
            {materialLining || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
      <Grid item xs={6}>
        <FormLine>
          <TwoLineLabel heading={Translate('Размер в штихмассовой системе')}>
            {productSize || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>

      <Grid item xs={4}>
        <FormLine>
          <TwoLineLabel heading={Translate('Вид материала низа/подошвы')}>
            {materialDown || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
    </Grid>
  );
};

ShoesAdditionalInfo.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
};

export default ShoesAdditionalInfo;
