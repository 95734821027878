import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from '../_refactor/Select/SingleSelect';
import { instance } from '../../../../services/instance';
import HintedOption from './HintedOption';

const { CancelToken } = axios;


class InnSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: undefined,
  };

  static cancelToken;

  state = {
    loading: false,
    values: undefined,
  };

  onChange = (val) => {
    const { onChange } = this.props;
    if (onChange) onChange(val);
    else {
      if (!val || val.length < 3) return;

      this.setState({ loading: true });

      instance.request({
        url: '/api/v3/facade/participants/search',
        method: 'get',
        params: {
          inn: val,
          limit: 100,
        },
        cancelToken: new CancelToken(((c) => {
          InnSelect.cancelToken = c;
        })),
      }).then((answer) => {
        const values = answer.data.results.map((pr) => {
          const name = pr.shortName || pr.name || pr.fullName;

          return ({
            type: pr.inn,
            id: pr.id,
            title: `${pr.inn}${name ? ` - ${name}` : ''}`,
          });
        });

        this.setState({ loading: false, values });
      });
    }
  };

  render() {
    const { onChange, ...passProps } = this.props;
    const { values, loading } = this.state;

    return (
      <SingleSelect
        {...passProps}
        filterDisable
        truncate
        renderOption={HintedOption}
        onChange={this.onChange}
        isLoading={loading}
        values={values}
      />
    );
  }
}

export default InnSelect;
