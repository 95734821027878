import { createAction } from 'redux-actions';

export const subject = '[DraftItem]';

export const mounted = createAction(`${subject} mounted`);
export const update = createAction(`${subject} update`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const gotErrors = createAction(`${subject} got errors`);
export const remove = createAction(`${subject} remove`);
