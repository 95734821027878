import { handleActions } from 'redux-actions';
import * as actions from './CisRegistration.actions';
import { STEPS } from '../../NotificationOfEntry/Goods/Goods.constants';

const data = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {}
);

const loaded = handleActions(
  {
    [actions.loaded]: () => true,
    [actions.submit]: () => true,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
    [actions.openDraft]: () => false,
    [actions.reset]: () => false,
  },
  false
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  ''
);

const cisList = handleActions(
  {
    [actions.setCisList]: (state, { payload = [] }) => payload,
    [actions.unmounted]: () => [],
  },
  []
);

const docType = handleActions(
  {
    [actions.setDocType]: (state, { payload = '' }) => payload,
  },
  ''
);

const step = handleActions(
  {
    [actions.load]: () => STEPS.LOADING,
    [actions.error]: () => STEPS.ERROR,
    [actions.reset]: () => STEPS.PRELOAD,
  },
  STEPS.PRELOAD,
);

const errorText = handleActions(
  {
    [actions.error]: (state, { payload = null }) => payload,
    [actions.reset]: () => null,
  },
  null,
);

export default {
  data,
  loaded,
  errorInfo,
  docType,
  step,
  errorText,
  cisList
};
