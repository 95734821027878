/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AutocompleteCRPT from '@crpt-ui/core/lib/Autocomplete/Autocomplete';
import FieldHoc from '../FieldHoc';

const AutocompleteAdapter = ({ input, meta, ...fieldRest }) => {
  const { placeholder, errorPlaceholder, isError, options, ...rest } = fieldRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  const onChangeHandle = (event, option) => {
    option ? input.onChange(option.value) : input.onChange(undefined);
  };

  const propsValue = options.find(item => item.value === input.value);

  const error = isError(meta);

  return (
    <AutocompleteCRPT
      {...input}
      onChange={onChangeHandle}
      label={placeholder}
      error={error}
      value={propsValue || null}
      options={options}
      {...rest}
    />
  );
};

AutocompleteAdapter.propTypes = {
  isError: PropTypes.func,
};

AutocompleteAdapter.defaultProps = {
  isError: meta => meta.invalid,
};

export default FieldHoc(AutocompleteAdapter);
