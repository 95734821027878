import { TwoLineLabel } from '@crpt/react-label';
import { Column } from '@crpt/react-page-layout';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'src/fragments/Link';

import { useStyles, Id } from '../../Operation.styles';

const ThirdCell = ({ heading, children, op, useLink }) => {
  const classes = useStyles();
  return (
    <Column col={6}>
      <div className={classes.fullWidthWrapper}>
        <TwoLineLabel heading={heading}>
          {children ? (
            children
          ) : useLink ? (
                <Link
                  className={classes.link}
                  // to={`/documents/list/${encodeURIComponent(op.docId)}/basic-info`}
                  to={`/documents/list/${encodeURIComponent(op.docId)}`}
                >
                  {op.docId}
                </Link>
              ) : (
                <Id>{op.docId}</Id>
              )
          }
        </TwoLineLabel>
      </div>
    </Column>
  );
};

ThirdCell.propTypes = {
  heading: PropTypes.string.isRequired,
  op: PropTypes.object,
};

export default ThirdCell;
