import { takeEvery, put } from 'redux-saga/effects';
import * as actions from './StyledPagination.actions';


function* selectedSaga(action) {
  const { payload, meta } = action;
  yield put({ type: `${actions.select} ${meta.subject}`, payload });
}

export default function* watchActions() {
  yield takeEvery(actions.select, selectedSaga);
}
