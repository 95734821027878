import React from 'react';
import clsx from 'classnames';
import { useStyles } from './button.styled';
import { useTranslation } from 'react-i18next';


export const SelectButton = ({ icon, title, selected, onClick, disabled }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div
      onClick={disabled ? () => {} : () => onClick()}
      className={clsx(styles.button, {[styles.buttonSelected]: selected, [styles.disabled]: disabled})}
    >
      {icon}
      <div>{t(title)}</div>
    </div>
  )
}
