import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getParentByRole } from '../utils';
import { TableCellWrapper } from './styled/index';


export default class TableCell extends Component {
  static propTypes = {
    onMouseEvent: PropTypes.func,
    children: PropTypes.any,
  }

  static defautProps = {
    onMouseEvent: () => {},
    children: <div />,
  }

  state = {
    isToolTipVisible: false,
    children: null,
  }

  sendState = () => {
    const { onMouseEvent } = this.props;
    if (!onMouseEvent) { return; }
    onMouseEvent(this.state);
  }

  onMouseEnter = (event) => {
    const { children } = this.props;

    const cellEvent = getParentByRole({ elem: event.currentTarget, role: 'gridcell' });
    this.setState({
      isToolTipVisible: true,
      children,
      cellEvent,
    },
    this.sendState);
  }

  onMouseLeave = () => {
    const { children } = this.props;

    this.setState({
      isToolTipVisible: false,
      children,
    },
    this.sendState);
  }

  render() {
    const { children } = this.props;
    return (
      <TableCellWrapper
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {children}
      </TableCellWrapper>
    );
  }
}
