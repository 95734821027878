import { createAction } from 'redux-actions';

export const subject = '[FiscalDocument]';

export const convert = createAction(`${subject} convert`);
export const load = createAction(`${subject} load`);
export const converted = createAction(`${subject} converted`);
export const error = createAction(`${subject} error`);
export const reset = createAction(`${subject} reset`);
export const sign = createAction(`${subject} sign`);

