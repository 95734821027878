import React from 'react';
import {
  ParticipantSelect,
  RangePicker,
  SingleSelect,
  Input,
} from '../../../common_components/Form/Fields/Fields';
import {
  // documentFormatValues,
  documentStatusValues,
  // documentInputTypeValues,
  // documentNameValues,
  documentTypes,
} from './IncometList.constants';
import { Translate } from '../../../common_components/Translate/Translate';

const IncomeListFilters = ({ countryCode }) => {
  const totalStatusValues = countryCode === 'KZ'
    ? documentStatusValues.filter(el=> el.type !== "17")
    : documentStatusValues
  return (
    <React.Fragment>
      <Input name="number" placeholder={Translate('Номер')}/>
      <RangePicker
        name="date"
        leftPlaceholder={Translate('Период получения от')}
      />
      <ParticipantSelect
        name="partner_inn"
        valueKey="inn"
        placeholder={Translate('Отправитель')}
      />
      <SingleSelect
        name="status"
        placeholder={Translate('Статус')}
        values={totalStatusValues}
      />
      <SingleSelect
        name="type"
        placeholder={Translate("Тип документа")}
        values={countryCode === 'KZ' ? documentTypes : [] }
      />
    </React.Fragment>
  );
};
export default IncomeListFilters;
