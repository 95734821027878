import { actionTypes } from '../../constants';


export const errors = (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.REGISTER_ERROR:
      return action.value || [];
    case actionTypes.REGISTRATION.REGISTRATION_MOUNTED:
      return [];
    default:
      return state;
  }
};
