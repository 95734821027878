import React from 'react';
import PropTypes from 'prop-types';
import FormContextWrapper from '../../../FormContextWrapper';
import FieldsWrapper from '../FieldsWrapper';
import SingleSelectAdapter from '../../Adapters/SingleSelectAdapter';


const SingleSelect = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;
  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <SingleSelectAdapter name={name} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

SingleSelect.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.string,
};

SingleSelect.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
  placeholder: undefined,
  errorPlaceholder: undefined,
};

SingleSelect.displayName = 'SingleSelect';


export default SingleSelect;
