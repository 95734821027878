import { actionTypes, strings } from '../../constants';

export const dataVisible = (state = false, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.TOGGLE_DATA:
      return !state;
    case actionTypes.REGISTRATION.SHOW_DATA:
      return true;
    default:
      return state;
  }
};
