export const defaultTheme = {
  backgroundColor: '#FFFFFF',
  color: '#434244',
  shadowX: '1px',
  shadowY: '2px',
  shadowBlur: '5px',
  shadowColor: 'rgba(0, 0, 0, 0.25)',
  item: {
    height: '46px',
    fontSize: '16px',
    cursor: 'pointer',
    hover: {
      backgroundColor: '#f7f7f7',
    },
    active: {
      backgroundColor: '#67ac5c',
      color:'#ffffff'
    },
  },
};
