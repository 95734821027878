import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {getSelected} from '../utils';

import * as actions from '../actions/';
import * as selectors from '../selectors/';

function recursiveBinder(action, dispatch) {
  const bound = bindActionCreators(action, dispatch);

  Object.keys(action).forEach(key => {
    if (typeof action[key] === 'object') {
      bound[key] = recursiveBinder(action[key], dispatch);
    }
  });

  return bound;
}

const propTypes = {};

class ConnectParts extends Component {
  constructor(props) {
    super(props);

    this.bound = {};

    this.props.actions.forEach(action => {
      for (const key in actions) {
        if (actions[key] === action) {
          this.bound[key] = recursiveBinder(action, this.props.dispatch);
        }
      }
    });
  }

  render() {
    const Component = this.props.component;

    const { selected, actions, selectors, ...otherProps } = this.props;

    return <Component actions={this.bound} {...selected} {...otherProps} />;
  }
}

const mapStateToProps = (state, props) => {
  const _selectors = {};

  props.selectors.forEach(sel => {
    let found = false;
    for (const key in selectors) {
      if (selectors[key] === sel) {
        _selectors[key] = getSelected(state, sel);
        found = true;
      }
    }

    if (!found) {
      const selected = getSelected(state, sel);
      for (const key in selected) {
        _selectors[key] = selected[key];
      }
    }
  });

  _selectors.App = {
    config: selectors.App.config(state),
    translate: selectors.App.translate(state)
  };

  return {
    selected: { ..._selectors }
  };
};

ConnectParts.propTypes = propTypes;
export default withRouter(connect(mapStateToProps)(ConnectParts));
