const step = state => state.Registration.step;
const certificates = state => state.Registration.certificates;
const errors = state => state.Registration.errors;
const certVisible = state => state.Registration.certVisible;
const contactsVisible = state => state.Registration.contactsVisible;
const dataVisible = state => state.Registration.dataVisible;
const certEditable = state => state.Registration.certEditable;
const contactsEditable = state => state.Registration.contactsEditable;

export {
  step,
  certificates,
  errors,
  certVisible,
  contactsVisible,
  dataVisible,
  certEditable,
  contactsEditable,
};
