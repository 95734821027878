import { handleActions } from 'redux-actions';
import * as actions from './CorInfoCm.actions';

const data = handleActions(
  {
    [actions.documentLoaded]: (state, { payload = null }) => {
      return payload;
    },
    [actions.unmounted]: () => null,
  },
  null,
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  '',
);

export default {
  errorInfo,
  data,
};
