import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from '@crpt-ui/core';
import { useTranslation } from 'react-i18next';

export const CancelDialog = ({ isOpen, handleCLose, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('Остановить действие статьи?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('С текущего момента статья станет не доступна для приложения ASL BELGISI.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="success">{t('Отмена')}</Button>
        <Button variant="contained" color="error" onClick={handleCLose} autoFocus>
          {t('Остановить')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
