import { compose, withProps, branch, renderComponent } from 'recompact';
import { Field } from 'react-final-form';


export default (Component, validate) => branch(
  ({ name }) => name,
  compose(
    withProps({ component: Component, ...validate && { validate } }),
    renderComponent(Field),
  )
);
