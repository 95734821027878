import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const FormWrap = styled.div`
  width: 100%;
  background-color: white;
  margin: 16px 0;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 3px 6px 0px #00000014;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 33.2px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #212121;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
`;

export const Text = styled.div`
color: var(--text-primary, #212121);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18.672px;
letter-spacing: 0.15px;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PreviewSection = styled.div`
  margin-right: 10px;
  width: 400px;
`;

export const IconWrap = styled.div`
  display: flex;
  color: #D22F2F;
`;
export const ErrorTextWrap = styled.div`
  margin: 2px 10px;
`;

export const Breadcrumb = styled.a`
  color: #6BAC5B;
  font-weight: 400;
  font-size: 16px;
  ine-height: 18.67px;
  letter-spacing: 0.15px;
  cursor: pointer;
`;

export const useStyles = makeStyles({
  flex: {
    display: 'flex',
    gap: 10,
  },

});
