import styled, { css } from 'styled-components';

//  height: ${props => props.height};
export const TableWrap = styled.div`
  display: ${props => props.hidden ? 'none' : 'table'};
  width: 100%;
  ${props => props.fullHeight === 'modalMarkedList'
    ? css`max-height: 584px; min-height: 184px;`
    : null
  }

  /* кроссбраузерность для IE*/
  ${() => {
    const browser = window.navigator.userAgent.toLowerCase();
    if (/trident/gi.test(browser) || /msie/gi.test(browser)) {
      return;
    } else {
      return `table-layout: fixed;`
    }
  }}
`;

export const CellWrap = styled.div`
  display: table-cell;
  position: relative;
`;

// padding-bottom: ${props => (props.height ? '0' : '10px')};
// padding-top: 10px;
//height: ${props => props.height};
//  ${props => props.height ? 'overflow-y: auto' : 'overflow-y: hidden'};
//  position: ${props => (props.height ? 'absolute' : 'inherit')};
export const CellContentWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  & .rt-tbody {
    ${props => props.height ? 'overflow-y: overlay; overflow-x: hidden; height: '+props.height+';' : ''};
  }  
  overflow-x: auto; 
  overflow-y: hidden; 
  width: 100%;
  ${props => props.fullHeight === 'modalMarkedList' 
    ? css`max-height: 584px; min-height: 184px; padding: 0;`
    : null }
`;


/* кроссбраузерность для IE*/
/* ${({ height }) => {
  const browser = window.navigator.userAgent.toLowerCase();
  if (
    Boolean(height) &&
    (/trident/gi.test(browser) || /msie/gi.test(browser))
  ) {
    return `position: inherit`;
  }
}} */