import React from 'react';
import styled from 'styled-components';

import {Icon} from '@crpt/react-icon';

import {withRouter} from 'react-router-dom';
import {getIconNameByPackType} from './utils';


const ProductLine = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 3px;
`;

const ColumnWrap = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: -3px;
`;

const IconWrap = styled.div`
  min-width: 16px;
  margin-right: 16px;
  padding-bottom: 0px;
  width: 16px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CISProductName = props => {
  const cis = props.cis ? props.cis : props.options.productName;

  const packageType = props.packageType ? props.packageType : cis._original.packageType;
  const gtin = props.gtin ? props.gtin : cis._original.gtin;
  const productName = props.productName ? props.productName : cis._original.productName;

  return (
    <Wrap>
      <IconWrap>
        <Icon type={getIconNameByPackType(packageType)} />
      </IconWrap>

      <ColumnWrap>
        <ProductLine>{gtin}</ProductLine>
        <ProductLine>{productName}</ProductLine>
      </ColumnWrap>
    </Wrap>
  );
};

export default withRouter(CISProductName);
