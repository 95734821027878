import {cisStatuses, CIS_STATUS_NAMES} from '../../../constants';
import {packTypes, packLevels} from '../../../constants/packTypes';
import * as Icons from '@crpt-ui/icons';

const statuses = {};
Object.keys(cisStatuses).map(k => statuses[k] = k);

const packs = {};
Object.keys({...packTypes, ...packLevels}).map(k => packs[k] = k);

export function getIconNameByCISStatus(status) {
  switch (status) {
    case statuses.WITHDRAWN:
      return 'sold-out';
    case statuses.WITHDRAW:
      return 'sold-out';
    case statuses.WRITTEN_OFF:
      return 'pan';
    case statuses.INTRODUCED:
      return 'turnover';
    case statuses.APPLIED:
      return 'print-flame';
    case statuses.EMITTED:
      return 'emitted';
    case statuses.EXPORTED:
      return 'opposite-arrows';
    case statuses.INTRODUCED_RETURNED:
      return 'introduced-returned';
    default:
      return '';
  }
}

//TODO: перейти на "@crpt-ui/icons
/**
 * OLD DEPRECATED METHOD. Use getIconByPack instead that return icon instead type of Icon
 */
export function getIconNameByPackType(pack) {
  switch (pack) {
    case packs.pack:
    case 'trade-unit':
      return 'pack';
    case packs.block:
    case 'inner-pack':
      return 'block';
    case packs.box:
    case packs.pallet:
      return 'box';
    case packs.unit:
      return 'pack';
    case packs.level1:
      return 'block';
    case packs.level2:
    case packs.level3:
      return 'box';
    default:
      return '';
  }
}

export function getIconByPack(pack) {
  switch(pack) {
    case packs.pack:
    case packs.unit:
      return Icons.PackageSingle;
    case packs.block:
    case packs.level1:
      return Icons.PackageMultiple;
    case packs.box:
    case packs.level2:
    case packs.pallet:
    case packs.level3:
      return Icons.PackageTransport;
    default:
      return Icons.PackageSingle;
  }
}

export function getStatusName(status) {
  return CIS_STATUS_NAMES[status];
}
