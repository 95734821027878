import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from '@crpt/react-icon';

import { documentName } from '../RequestList.constants';
import Tooltip from '../../../../common_components/_MaterialUi/Tooltip/Tooltip';
import { getSenderOrBuyerName, type2path } from './Draft.utils';
import { Translate } from '../../../../common_components/Translate/Translate';
import { countryCodeFlags } from '../../../../constants';

const DraftNumWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const documentTypeValues = [
  { id: 0, title: documentName.LP_ACCEPT_GOODS, type: 'LP_ACCEPT_GOODS' },
  { id: 102, title: documentName.KZ_EXPORT_NOTICE, type: 'KZ_EXPORT_NOTICE' },
  // { id: 1, title: documentName.LP_INTRODUCE_GOODS, type: 'LP_INTRODUCE_GOODS' },
  // {
  //   id: 2,
  //   title: documentName.LK_INDI_COMMISSIONING,
  //   type: 'LK_INDI_COMMISSIONING',
  // },
  // {
  //   id: 3,
  //   title: documentName.LK_CONTRACT_COMMISSIONING,
  //   type: 'LK_CONTRACT_COMMISSIONING',
  // },
  { id: 12, title: documentName.KZ_UPD, type: 'KZ_UPD' },
  // { id: 5, title: documentName.LP_SHIP_GOODS, type: 'LP_SHIP_GOODS' },
  // { id: 6, title: documentName.LK_RECEIPT, type: 'LK_RECEIPT' },
  // { id: 7, title: documentName.LK_KM_CANCELLATION, type: 'LK_KM_CANCELLATION' },
  // { id: 8, title: documentName.LK_REMARK, type: 'LK_REMARK' },
  { id: 9, title: documentName.IMPORT_TRANSIT, type: 'IMPORT_TRANSIT' },
  { id: 4, title: documentName.IMPORT, type: 'IMPORT' },
  { id: 10, title: documentName.MTTPS_90, type: 'MTTPS_90' },
  {
    id: 11,
    title: documentName.KZ_INTRODUCE_GOODS,
    type: 'KZ_INTRODUCE_GOODS',
  },
];

export const documentTypeValuesUZ = [
  { id: 4, title: documentName.IMPORT_UZ, type: 'IMPORT' },
  { id: 136, title: documentName.AIC_AGGREGATION, type: 'AIC_AGGREGATION' },
  { id: 127, title: documentName.AIC_DISAGGREGATION, type: 'AIC_DISAGGREGATION' },
  { id: 131, title: documentName.UZ_ADMIT_APPLICATION, type: 'UZ_ADMIT_APPLICATION' },
  { id: 10, title: documentName.MTTPS_90, type: 'MTTPS_90' },
  { id: 1, title: documentName.INVENTORY, type: 'INVENTORY' },
  { id: 2, title: documentName.TRADE_INVENTORY, type: 'TRADE_INVENTORY' },
  { id: 150, title: documentName.COR_INFO_CM, type: 'COR_INFO_CM' },
];

export const options = [
  {
    accessor: value => value,
    id: 'errors',
    render: (_, rowInfo) => {
      const error = get(rowInfo, 'original.errors', '');
      return (
        <React.Fragment>
          {error && (
            <Tooltip
              text={error}
              mainText={error ? Translate('Обработано с ошибками') : ''}
              textColor="#ff3d00"
              setMaxWidth={300}
              placement="bottom-end"
            />
          )}
          {rowInfo && !error && <span>&mdash;</span>}
        </React.Fragment>
      );
    },
  },
  {
    accessor: value => value,
    id: 'userId',
    render: (_, rowInfo) => {
      const user_name = get(rowInfo, 'original.user_name', '');

      return (
        <Tooltip text={user_name} setMaxWidth={300} placement="bottom-end" />
      );
    },
  },
  {
    accessor: d => d,
    id: 'id',
    render: (_, rowInfo) => {
      const uuid = get(rowInfo, 'original.draft_uuid', '');
      const document_type = get(rowInfo, 'original.document_type', '');
      const path = type2path(document_type);

      return (
        <DraftNumWrap>
          <Tooltip
            text={uuid}
            textComponent={
              <Wrapper>
                {/* <Icon type="edit" spacingRight={12} fill="rgb(82, 83, 90)" size={16} /> */}
                <StyledLink to={`/documents/${path}/draft-${uuid}`}>
                  {uuid.slice(-6)}
                </StyledLink>
              </Wrapper>
            }
            sliceTitleStart={-6}
            placement="bottom-start"
            textColor="#55b5e7"
            interactive
            showCopy
            showMail
            noWrap
          />
        </DraftNumWrap>
      );
    },
  },
];

export const columns = [
  {
    Header: Translate('Рег № документа'),
    id: 'id',
    accessor: 'draft_uuid',
    width: 150,
  },
  {
    Header: Translate('Дата регистрации'),
    id: 'registrationDate',
    accessor: ({ registration_date }) =>
      moment(registration_date).format('DD.MM.YYYY HH:mm:ss'),
    width: 220,
  },
  {
    Header: Translate('Дата сохранения'),
    id: 'dateSaved',
    accessor: ({ date_saved }) =>
      moment(date_saved).format('DD.MM.YYYY HH:mm:ss'),
    width: 220,
  },
  {
    Header: Translate('Имя пользователя'),
    id: 'userId',
    accessor: 'user_name',
    width: 260,
  },
  {
    Header: Translate('Тип документа'),
    id: 'documentType',
    accessor: ({ document_type }) => {
      return countryCodeFlags.isUZ && document_type === 'IMPORT'
        ? Translate(documentName['IMPORT_UZ'])
        : Translate(documentName[document_type])
    },
    width: 300,
  },
  // {
  //   Header: 'Отправитель',
  //   id: 'sender',
  //   width: 300,
  //   accessor: ({ document_body, document_type }) => getSenderOrBuyerName(document_body, document_type, 'sender'),
  // },
  // {
  //   Header: 'Получатель',
  //   id: 'buyer',
  //   accessor: ({ document_body, document_type }) => getSenderOrBuyerName(document_body, document_type, 'buyer'),
  //   width: 300,
  // },
];
