import { makeRadioGroupFilter } from '@crpt-ui/datatable';
import { participantStatuses } from '../../../constants';
import { Translate } from '../../../common_components/Translate/Translate';

const options = Object.keys(participantStatuses).map(status => ({
  value: status,
  label: Translate(participantStatuses[status])
}));

export const ParticipantStatus = makeRadioGroupFilter({options});
