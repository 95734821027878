import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  datatableWrapper: {
    background: '#fff',
    boxShadow: '0px 1px 8px rgba(41, 49, 53, 0.1)',
  },
  tasksAmount: {
    color: '#b8b8b8',
    fontSize: 12,
    fontWeight: 600,
    marginRight: 50,
    marginTop: 40,
  },
  completedStatus: {
    color: '#2fa93b',
  },
  preparationStatus: {
    color: '#63666a',
  },
  canceledStatus: {
    color: '#ff4b4b',
  },
  archiveStatus: {
    color: '#63666a',
  },
  failedStatus: {
    color: '#f34747',
  },
  defaultStatus: {
    color: '#63666a',
  }
});
