import React, { Fragment } from 'react';
import { ParticipantStatus } from './filters/ParticipantStatus';
import ParticipantSelect from './filters/ParticipantSelect/ParticipantSelect';
import * as Icons from '@crpt-ui/icons';
import { makeCell, makeInputFilter, makeRadioGroupFilter } from '@crpt-ui/datatable';
import { Link } from 'src/fragments/Link';
import {
  // participantNames,
  // participantStatuses,
  countryCodeFlags,
} from 'src/constants';
import { Translate } from '../../common_components/Translate/Translate';
import { pgTypesNames, pgTypesNamesKZ, pgTypesNamesUZ } from '../../constants/productGroups';
// import { ParticipantType } from './filters/ParticipantType';
// import { CellTooltip } from 'src/fragments/Table/Cell/CellTooltip';
// import { Value } from 'src/fragments/Table/Cell/value';

const UserStatusValuesMap = {
  'NOT_REGISTERED': Translate('Не зарегистрирован'),
  'PRE_REGISTERED_TRADER': Translate('В процессе регистрации опт/розница'),
  'PRE_REGISTERED': Translate('В процессе регистрации'),
  'BLOCKED_EVADER': Translate('Нарушитель, заблокирован'),
  'CHECKED_EVADER': Translate('Нарушитель, проверен'),
  'REGISTERED_EVADER': Translate('Зарегистрирован как нарушитель'),
  'PRE_REGISTERED_ISSUER': Translate('В процессе регистрации производитель'),
  'REGISTERED': Translate('Зарегистрирован'),
  'RESTORED': Translate('Восстановлен'),
  'BLOCKED': Translate('Заблокирован'),
  'REMOVED': Translate('Удалён'),
};

const UserStatusIconsMap = {
  'NOT_REGISTERED': <Icons.MemberIsWaiting/>,
  'REGISTERED': <Icons.UserActive/>,
  'RESTORED': <Icons.UserActive/>,
  'PRE_REGISTERED': <Icons.MemberIsWaiting/>,
  'PRE_REGISTERED_TRADER': <Icons.MemberIsWaiting/>,
  'PRE_REGISTERED_ISSUER': <Icons.MemberIsWaiting/>,
  'BLOCKED': <Icons.MemberIsBlocked/>,
  'BLOCKED_EVADER': <Icons.MemberIsBlocked/>,
  'CHECKED_EVADER': <Icons.MemberIsBlocked/>,
  'REGISTERED_EVADER': <Icons.MemberIsBlocked/>,
  'REMOVED': <Icons.UserRemove/>,
};

const UserStatusColorsMap = {
  'NOT_REGISTERED': '#868686',
  'REGISTERED': '#48C834',
  'RESTORED': '#F5A020',
  'PRE_REGISTERED_TRADER': '#F5A020',
  'PRE_REGISTERED_ISSUER': '#F5A020',
  'PRE_REGISTERED': '#F5A020',
  'BLOCKED': '#F34747',
  'BLOCKED_EVADER': '#F34747',
  'CHECKED_EVADER': '#F34747',
  'REGISTERED_EVADER': '#F34747',
  'REMOVED': '#868686',
};

export const statusesNotEvaders = ['NOT_REGISTERED', 'PRE_REGISTERED', 'PRE_REGISTERED_ISSUER', 'PRE_REGISTERED_TRADER', 'READY_TO_REGISTER', 'REGISTERED', 'REMOVED', 'RESTORED', 'NOT_ACTIVATED', 'BLOCKED'];
const typesPgByName = countryCodeFlags.isUZ ? pgTypesNamesUZ : pgTypesNames;

export const evadersColumns = [
  {
    accessor: 'fullName',
    id: 'name',
    Header: Translate('Компания'),
    Cell: ({ row }) => {
      return <Link to={`/participants/list/${row.original.tin}`}>{row.original.fullName}</Link>;
    },
    width: 450,
    Filter: ParticipantSelect,
  },
  {
    accessor: 'tin',
    id: 'tin',
    Header: Translate('ИНН/ПИНФЛ'),
    Filter: makeInputFilter({ label: Translate('ИНН/ПИНФЛ') }),
  },
]

export const dataTableColumns = [
  {
    accessor: 'fullName',
    id: 'name',
    Header: Translate('Компания'),
    Cell: ({ row }) => {
      return <Link to={`/participants/list/${countryCodeFlags.isKZ ? row.original.inn : row.original.tin}`}>{row.original.fullName}</Link>;
    },
    width: 450,
    Filter: ParticipantSelect,
  },
  {
    accessor: countryCodeFlags.isKZ ? 'inn' : 'tin',
    id: countryCodeFlags.isKZ ? 'inn' : 'tin',
    Header: countryCodeFlags.isKZ ? Translate('ИИН/БИН') : Translate('ИНН/ПИНФЛ'),
    Filter: makeInputFilter({ label: Translate(countryCodeFlags.isKZ ? 'ИНН' : 'ИНН/ПИНФЛ') }),
  },
  // {
  //     accessor: 'inn',
  //     id: 'type',
  //     Header: Translate('Тип участника'),
  //     Cell: ({row}) => {
  //         const roles = row.original.roles;
  //         const roleNames = roles
  //           .filter(role => participantNames.hasOwnProperty(role))
  //           .map(role => participantNames[role]);
  //
  //         const listOfRoleNames = () => {
  //             return roleNames.map((roleName, key) => {
  //                 const haveSeparate = key !== roleNames.length -1;
  //                 return (<span key={key}>{roleName}{haveSeparate && ', '}</span>)
  //             })
  //         };
  //
  //         const renderValues = () => {
  //             return (
  //               <Value>
  //                   {listOfRoleNames()}
  //               </Value>
  //             )
  //         };
  //         const memoizedTitleValues = useMemo(() => listOfRoleNames(), []);
  //         return <CellTooltip arrow={false} alwaysDisplay title={memoizedTitleValues} render={renderValues}/>;
  //     },
  //     Filter: ParticipantType,
  // },
  {
    accessor: ({ productGroupsAndRoles }) => {
      return (
        <Fragment>
          {productGroupsAndRoles.length === 0 && "—"}
          {productGroupsAndRoles.map((el, i) => (
            <Fragment>
              {countryCodeFlags.isKZ ? Translate(pgTypesNamesKZ[el.code]) : Translate(pgTypesNames[el.code])}
              {productGroupsAndRoles[i + 1] ? ', ' : ''}
            </Fragment>
          ))}
        </Fragment>
      )
    },
    id: 'productGroupCode',
    Header: Translate('Товарные группы участника'),
    Filter: makeRadioGroupFilter({
      options: Object.keys(typesPgByName).map((key, i) => ({
        id: i,
        value: key,
        label: Translate(typesPgByName[key]),
        title: typesPgByName[key],
      })),
    }),
  },
  {
    accessor: 'status',
    id: 'status',
    Header: Translate('Статус участника'),
    Cell: makeCell({
      icons: UserStatusIconsMap,
      values: UserStatusValuesMap,
      colors: UserStatusColorsMap,
    }),
    Filter: ParticipantStatus,
  },
  {
    accessor: ({ addressRegion, addressDistrict }) => {
      return addressRegion && addressDistrict ? `${addressRegion || ''} ${addressDistrict || ''}` : '—';
    },
    id: 'address',
    Header: Translate('Регион и район регистрации'),

  },
];
