import React from 'react';

export default {
  title: 'Табличное представление при выборе типа агрегации «По населённому пункту»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Субъект РК',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 130,
          subheaders: []
        },
        {
          Header: 'Населённый пункт',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
          subheaders: []
        },
        {
          Header: 'Численность населённого пункта',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 200,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 90,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 160,
          subheaders: []
        },
        {
          Header: 'Объём товаров в обороте',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 280,
          subheaders: [
            {
              Header: 'в штуках',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
            {
              Header: 'в тенге',
              accessor: 'col8',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 140,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
          'col8': [8],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': '',
          'col6': '',
          'col7': '...',
          'col8': '...',
        },
      ]
    },
  ]
}
