import React, { useEffect } from 'react';
import ActionBar from '../../../common_components/ActionBar/ActionBar';
import FiltersButton from '../../../common_components/Buttons/FiltersButton';
import { ContentWrapper } from '../../../common_components/styled';
import Preloader from '../../../common_components/Preloader/Preloader';
import List from '../../../common_components/List/List';
import { columns, limit, options } from './ImportList.constants';
import * as selectors from './ducks/ImportList.selectors';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as AppSelectors from '../../../common_components/App/App.selectors';
import * as actions from './ducks/ImportList.actions';
import * as listActions from '../../../common_components/List/ducks/List.actions';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as filePreviewActions from '../../../common_components/FilePreview/ducks/FilePreview.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withModal } from '../../../common_components/Modals/hocs/withModal';
import CommandBar from '../../../common_components/CommandBar/CommandBar';
import Pagination from '../../../common_components/List/Pagination/Pagination';
import { FooterWrapper } from '../ExportList/ExportList.constants';
import { prepareFromRange, prepareToRange } from '../../../common_components/Form/Form.utils';
import Filters from '../../../common_components/List/Filters/Filters';
import ImportListFilters from './importListFilters';

const ImportList = (props) => {
  const { actionBarLeft, onClear, onOpenSidebar, filters, countryCode } = props;
  const { onFilterApply, last, pagesCount, selectedPage, onUnMount } = props;
  const { results, isLoading, onMount, onListMount } = props;

  useEffect(()=> {
    onMount()
    onListMount();
  },[])

  useEffect(()=> onUnMount,[onUnMount])

  const handleFilterApply = params => {
    onFilterApply(prepareFromRange({ ...params, offset: 0 }));
  };

  const onClearFilter = () => {
    onClear();
    setTimeout(() => handleOpenFiltersSidebar('clear'), 0);
  };

  const handleOpenFiltersSidebar = (values) => {
    const data = values === "clear" ? {} : filters;
    onOpenSidebar(
      <Filters
        onClear={onClearFilter}
        data={prepareToRange(data)}
        onSubmit={handleFilterApply}
        filters={<ImportListFilters countryCode={countryCode} />}
      />,
    );
  };

  return (
    <ContentWrapper>
      <ActionBar
        left={actionBarLeft}
        right={[
          <FiltersButton
            onClick={handleOpenFiltersSidebar}
          />
        ]}
      />
      <Preloader />
      <List
        columns={columns}
        options={options}
        height="calc(100vh - 370px)"
        data={results}
        hidden={isLoading}

      />
      <FooterWrapper>
        <CommandBar left={[]} flexBasis="40%" />
        <Pagination
          pageCounterInvisible={true}
          meta={actions}
          last={last}
          pagesCount={pagesCount}
          selected={selectedPage}
        />
        <div style={{ flexBasis: '30%', textAlign: 'right' }} />
      </FooterWrapper>
    </ContentWrapper>
  )
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: 999999, //selectors.pagesCount(state),
    totalCount: selectors.total(state),
    last: selectors.results(state).length < limit, //selectors.last(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    roles: authSelectors.roles(state),
    userInfo: authSelectors.userInfo(state),
    uploadErrors: selectors.uploadErrors(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
    isUserOGV: authSelectors.isUserOGV(state),
    countryCode: AppSelectors.countryCode(state),
  };
};

const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onOpenDocument: (id, type, docType) =>
    dispatch(actions.openDocument({ id, type, docType })),
  onOpenNewFile: (obj, meta) =>
    dispatch(filePreviewActions.openFile(obj, meta)),
  onClearErrors: () => dispatch(actions.clearErrors()),
  onErrors: val => dispatch(actions.gotErrors(val)),
  onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
  downloadDoc: id => dispatch(actions.downloadDoc(id)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(ImportList);
