import styled from 'styled-components';

const IconArrowRightWrapper = styled.div`
  padding-right: 22px;
  display: flex;
  align-items: center;
  > svg {
    width: 14px;
    height: 14px;
    transform: rotate(-45deg);
  }
`;

export default IconArrowRightWrapper;
