import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Icons from '@crpt-ui/icons';

import * as actions from '../ducks/PageHeader.actions';
import * as actionsAuth from 'src/common_components/Auth/Auth.selectors';
import { getItems } from './MenuApps.utils';
import MenuAppsView from './MenuApps.view';

const MenuAppsComponent = memo(
  (props) => {
    const { productGroupInfo, countryCode, fetchAuthenticateSun, transferToRmt } = props;
    const { inn = '', isUserOGV, isOperator, fetchElkCode, fetchSuzSignIn } = props;
    const items = getItems({
      inn,
      transferToRmt,
      isUserOGV,
      isOperator,
      fetchElkCode,
      fetchSuzSignIn,
      fetchAuthenticateSun,
      countryCode,
      productGroupsAndRoles: productGroupInfo ? productGroupInfo : []
    });

    return items.length ? (
      <MenuAppsView
        icon={<Icons.Applications />}
        items={items}
        company={productGroupInfo}
      />
    ) : null;
  },
);

MenuAppsComponent.propTypes = {
  inn: PropTypes.string.isRequired,
  isUserOGV: PropTypes.bool.isRequired,
  isOperator: PropTypes.bool.isRequired,
  fetchElkCode: PropTypes.func.isRequired,
  fetchSuzSignIn: PropTypes.func.isRequired,
  company: PropTypes.shape({}).isRequired,
};

const mapDispatch = {
  fetchElkCode: actions.fetchElkCode,
  fetchSuzSignIn: actions.fetchSuzSignIn,
  fetchAuthenticateSun: actions.fetchAuthenticateSun,
};

const mapState = state => ({
  productGroupInfo: state.COMMON.Auth.userInfo.product_group_info,
  countryCode: state.config.countryCode,
});

/**
 * Render Main Menu Links
 */
export const MenuApps = connect(mapState, mapDispatch)(MenuAppsComponent);
