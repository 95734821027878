import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldHoc from 'src/common_components/_refactor/Form/FieldHoc';

const OnChange = ({ input: { value }, onChange }) => {
  const [prev, setPrev] = useState(null);

  useEffect(() => {
    if (prev !== value) {
      setPrev(value);
      onChange(value);
    }
  }, [value]);

  return null;
};

OnChange.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FieldHoc(OnChange);
