import React from 'react';
import moment from 'moment';
import { get, isNil } from 'lodash';

import { FormLine } from '@crpt/react-form';
import { Icon } from '@crpt/react-icon';
import { TwoLineLabel } from '@crpt/react-label';
import { cisStatuses } from '../../../../constants';
import ParticipantNameBound from '../../../../containers/Bounds/ParticipantNameBound';
import { getIconNameByCISStatus } from '../../../TableCells/CIS/utils';
import { addSpacesBetweenThousands } from '../../../../utils';

import {
  CardHeading,
  QRCodeWrapper,
  QRCodeTextWrapper,
  TwoColumns,
} from '../BasicInfo.styled';
import {
  Wrap,
  QRCanvas,
  CisInfoHeadingLabel,
  GreyWrapper,
} from './CisInfo.styled';
import { getPackageNameByPackageTypeInParentalCase } from '../../CisDetails.utils';
import { isFoivOrOperator } from '../../../../utils/userUtils';
import { Translate } from '../../../../common_components/Translate/Translate';
import { statusesExtNames } from '../../../Grid/Grid.constants';
import { countryCodeFlags } from "../../../../constants";

const CisInfo = props => {
  const { data, countryCode, currentLanguage } = props;
  const cis = get(props, 'data.cis', '-');
  const statusEx = get(props, 'data.statusEx', null);
  const partialQuantity = get(props, 'data.specAttr.partialQuantity', null);
  const isHoreca = get(props, 'data.specAttr.isHoreca', false);
  const cisStatusKey = get(props, 'data.status', 'withoutStatus');
  const iconName = getIconNameByCISStatus(cisStatusKey);
  const cisStatusValue = get(cisStatuses, `${cisStatusKey}`, '-');
  const emissionDate = get(props, 'data.emissionDate', null);
  const producedDate = get(props, 'data.producedDate', null);
  const emissionHistory = data && data.history.find(v => v.type === 'EMISSION');
  const productionDate = get(props, 'data.productionDate', null);
  const expirationDate = get(props, 'data.expirationDate', null);
  const productionSerialNumber = get(props, 'data.productionSerialNumber', null);
  const parent = data.parents && data.parents.length ? data.parents[0] : null;
  const printQualityClass = get(props, 'data.specAttr.printQualityClass', null);

  const aggregated =
    data &&
    data.history
      .slice()
      .reverse()
      .find(v => v.type === 'AGGREGATION' && v.aggregationCis === parent);

  const aggregatedDate =
    aggregated && moment(new Date(aggregated.operationDate));

  const aggregatedCode = aggregated && aggregated.aggregationCis;
  const aggregatedTotal = aggregated && aggregated.aggregatedTotal;
  const parentCisPackageType = aggregated && aggregated.parentCisPackageType;
  const mrp = get(props, 'data.specAttr.mrp', null)
    ? addSpacesBetweenThousands(props.data.specAttr.mrp)
    : null;

  const formatedMRP = mrp
    ? (+('' + mrp).replace(' ', '') / 100).toFixed(2).replace('.', ',')
    : '—';

  return (
    <React.Fragment>
      <CardHeading>{Translate('Информация о коде маркировки')}</CardHeading>

      <TwoColumns>
        <QRCodeWrapper>
          <QRCanvas id="qr-canvas" width={72} height={72} />
        </QRCodeWrapper>

        <QRCodeTextWrapper>
          <FormLine>
            <TwoLineLabel heading={Translate('Код')}>{cis}</TwoLineLabel>
          </FormLine>
          <FormLine>
            <TwoLineLabel heading={Translate('Статус')}>
              {iconName && <Icon type={iconName} spacingRight={12} />}
              {Translate(cisStatusValue || "")}
            </TwoLineLabel>
          </FormLine>
          {
            isHoreca && (
              <FormLine style={{ flexDirection: 'column' }} className="hovered">
                <GreyWrapper>
                  {Translate('Товар реализован предприятием общественного питания, гостиницей, или продается в розлив')}
                </GreyWrapper>
              </FormLine>
            )
          }
          {
            (countryCode === "UZ" && !isNil(statusEx)) && (
              <TwoLineLabel heading={Translate('Расширенный статус')}>
                {Translate(statusesExtNames[statusEx] || "")}
              </TwoLineLabel>
            )
          }
          {
            (countryCode === "UZ" && !isNil(partialQuantity)) && (
              <TwoLineLabel heading={Translate('Вскрытая упаковка')}>
                {`${partialQuantity}%`}
                {' '}
                {Translate('упаковки реализовано')}
              </TwoLineLabel>
            )
          }
        </QRCodeTextWrapper>
      </TwoColumns>

      <div>
        <CisInfoHeadingLabel>{Translate('Код')}</CisInfoHeadingLabel>
          { printQualityClass && (
              <FormLine>
                <TwoLineLabel heading={Translate('Класс качества печати')}>
                  {printQualityClass}
                </TwoLineLabel>
              </FormLine>
            )
          }

        <FormLine>
          <TwoLineLabel heading={Translate('Эмитирован')}>
            {(emissionDate &&
              moment(emissionDate).lang(typeof currentLanguage === 'string' ? currentLanguage.toLowerCase() : 'RU').format('DD MMMM YYYY в HH:mm:ss')) ||
              '—'}
            <GreyWrapper>
              &nbsp;{emissionDate && moment(emissionDate).format('Z UTC')}
            </GreyWrapper>
          </TwoLineLabel>
        </FormLine>

        <FormLine>
          <TwoLineLabel heading={Translate('Нанесен')}>
            {(producedDate &&
              moment(producedDate).format('DD MMMM YYYY в HH:mm:ss')) ||
              '—'}
            <GreyWrapper>
              &nbsp;{producedDate && moment(producedDate).format('Z UTC')}
            </GreyWrapper>
          </TwoLineLabel>
        </FormLine>

        <Wrap>
          <TwoLineLabel heading={Translate('Агрегирован')}>
            {(aggregatedDate &&
              aggregatedDate.format('DD MMMM YYYY в HH:mm:ss')) ||
              '—'}
            <GreyWrapper>
              &nbsp;{aggregatedDate && aggregatedDate.format('Z UTC')}
            </GreyWrapper>
          </TwoLineLabel>
          <div>
            {aggregatedCode && (
              <React.Fragment>
                {Translate('В')}{' '}
                {parentCisPackageType &&
                Translate(
                  getPackageNameByPackageTypeInParentalCase(
                    parentCisPackageType,
                    true,
                  ),
                )
                  ? Translate(
                      getPackageNameByPackageTypeInParentalCase(
                        parentCisPackageType,
                        true,
                      ),
                    )
                  : ''}{' '}
                {Translate('упаковку с кодом')} {aggregatedCode}
                {aggregatedTotal && (
                  <React.Fragment>
                    {Translate('Единиц внутри')} {aggregatedTotal}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </Wrap>

        {/*{showItem.mrp*/}
        {/*  ? <FormLine>*/}
        {/*      <TwoLineLabel heading={'МРЦ, Р'}>*/}
        {/*        {formatedMRP}*/}
        {/*      </TwoLineLabel>*/}
        {/*    </FormLine>        */}
        {/*  : null*/}
        {/*}*/}

        {data.aggregatedTotal ? (
          <FormLine>
            <TwoLineLabel heading={Translate('Единиц внутри')}>
              {(data && data.aggregatedTotal) || '—'}
            </TwoLineLabel>
          </FormLine>
        ) : null}

        {productionDate || expirationDate || productionSerialNumber ?
          <React.Fragment>
            <CisInfoHeadingLabel>
              {Translate('Данные производства')}
            </CisInfoHeadingLabel>

            {productionDate ?
              <FormLine>
                <TwoLineLabel heading={Translate('Дата производства')}>
                  {(
                    moment.utc(productionDate).format('DD MMMM YYYY в HH:mm:ss')) ||
                  '—'}
                </TwoLineLabel>
              </FormLine> : null}

            {expirationDate ?
              <FormLine>
                <TwoLineLabel heading={Translate('Дата истечения срока годности')}>
                  {(
                    moment.utc(expirationDate).format('DD MMMM YYYY в HH:mm:ss')) ||
                  '—'}
                </TwoLineLabel>
              </FormLine> : null}

            {productionSerialNumber ?
              <FormLine>
                <TwoLineLabel heading={Translate('Номер производственной серии')}>
                  {productionSerialNumber}
                </TwoLineLabel>
              </FormLine> : null}
          </React.Fragment> : null}

        <CisInfoHeadingLabel>
          {Translate('Участники оборота')}
        </CisInfoHeadingLabel>

        <FormLine style={{ flexDirection: 'column' }} className="hovered">
          <TwoLineLabel heading={Translate(countryCodeFlags.isUZ ? 'Производитель' : 'Эмитент')}>
            {emissionHistory && (
              <ParticipantNameBound
                name={emissionHistory.participant1Name}
                id={isFoivOrOperator() && emissionHistory.participant1Id}
                inn={emissionHistory.participant1Inn}
                wrap
              />
            )}
          </TwoLineLabel>
        </FormLine>

        <FormLine style={{ flexDirection: 'column' }} className="hovered">
          <TwoLineLabel heading={Translate('Текущий владелец')}>
            {data && data.ownerName && (
              <ParticipantNameBound
                name={data.ownerName}
                id={isFoivOrOperator() && data.ownerInn}
                inn={data.ownerInn}
                wrap
              />
            )}
          </TwoLineLabel>
        </FormLine>
      </div>
    </React.Fragment>
  );
};

export default CisInfo;
