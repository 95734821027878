import React from 'react';
import PropTypes from 'prop-types';
import InfoLabelAdapter from './Adapters/InfoLabelAdapter';
import FormContextWrapper from '../FormContextWrapper';
import FieldsWrapper from './_refactor/FieldsWrapper';


const InfoLabel = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <InfoLabelAdapter name={name} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

InfoLabel.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

InfoLabel.defaultProps = {
  name: '_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};


export default InfoLabel;
