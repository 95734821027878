import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { flatten as flat, unflatten } from 'flat';
import {
  get,
  isArray,
  isEmpty,
  isNaN,
  isNull,
  isObject,
  isString,
  isUndefined,
  toNumber,
  transform,
  unset,
  isEqual,
} from 'lodash';
import Form from './Form';
import { FormContext } from './Form.constants';
import { check, each, exist } from './Form.validations';
import { required } from './utils/validations';

export const withForm = ({ disabled = false, validate, decorators } = {}) => Component => {
  let validateTimeout;
  let mutators;
  let oldValues = {};
  let oldErrors = {};

  const validateFn = values => new Promise(resolve => {
    const fn = () => {
      const errors = {};

      const validators = {
        each: each(values),
        exist: exist(values),
        check: check(values, oldValues, errors, oldErrors, mutators),
      };

      oldErrors = validate(values, errors, mutators, validators);
      oldValues = values;
      resolve(oldErrors);
    };

    if (validateTimeout) validateTimeout();

    const timeoutId = setTimeout(() => {
      fn();
      validateTimeout = undefined;
    }, 200);

    validateTimeout = () => {
      clearTimeout(timeoutId);
      resolve(oldErrors);
    };
  });

  const Wrapper = props => {
    const { data } = props;

    return (
      <Form
        data={data}
        disabled={disabled}
        validate={validate ? validateFn : undefined}
        decorators={decorators}
      >
        <FormContext.Consumer>
          {({ mutators: mt }) => {
            mutators = mt;
          }}
        </FormContext.Consumer>
        <Component {...props} />
      </Form>
    );
  };

  Wrapper.displayName = `WithForm(${Component.displayName})`;

  Wrapper.propTypes = {
    data: PropTypes.shape({}),
  };

  Wrapper.defaultProps = {
    data: undefined,
  };

  return Wrapper;
};

export const flatten = (source, flattened = {}, key = '') => {
  const nextKey = k => `${key}${key ? '.' : ''}${k}`;

  if (typeof source === 'object') {
    if (!isEmpty(source)) {
      Object.keys(source).forEach(k => flatten(source[k], flattened, nextKey(k)));
    } else {
      // eslint-disable-next-line no-param-reassign
      flattened[key] = source;
    }
  } else {
    // eslint-disable-next-line no-param-reassign
    flattened[key] = source;
  }

  return flattened;
};

export const cleanErrors = obj => {
  if (!isObject(obj)) return obj;

  const cleaned = flat(obj);

  Object.keys(cleaned).forEach(key => {
    const val = cleaned[key];
    if (isUndefined(val) || isNull(val) || isEmpty(val)) unset(cleaned, key);
  });

  return unflatten(cleaned);
};

export const withFormContext = params => Component => props => {
  const formContext = useContext(FormContext);
  const { values, errors, mutators, disabled } = formContext;

  const errorsRef = useRef();
  if (!isEqual(errorsRef.current, errors)) errorsRef.current = errors;

  return (
    <Component
      formValues={params.values && values}
      formErrors={params.errors && errorsRef.current}
      formMutators={params.mutators && mutators}
      formDisabled={params.disabled && disabled}
      {...props}
    />
  );
};
export const setVisibilityByIssetField = field => (values) => {
  for (const item in values) {
    if (isArray(values[item])) {
      for (const subItem in values[item][0]) {
        if (isString(values[item][0][subItem])
          && field === subItem
          && values[item][0][subItem] !== '') return true;
      }
    } else if (isString(values[item])
      && field === item
      && values[item] !== '') return true;
  }

  return false;
};

export const setVisibilityByValues = (name, vals, hidden) => (values) => {
  const current = get(values, name, '');
  const visible = vals.indexOf(current) !== -1;

  if (!visible && hidden) return null;
  return visible;
};

export const requiredByValues = (name, vals) => (value, values) => {
  const current = get(values, name, '');
  const require = vals.indexOf(current) !== -1;

  if (require) return required(value);
  return undefined;
};

export const parseNumber = (value) => {
  if (isNaN(toNumber(value))) {
    return value;
  }
  return toNumber(value);
};

export const setFieldValue = (name, newValue, controlValues) => (
  value,
  mutators,
  controlName
) => {
  if (controlValues.includes(value)) {
    const partsName = controlName.split('.', 2);

    if (partsName.length === 2) {
      partsName.push(name);
      mutators.updateField(partsName.join('.'), newValue);
    } else {
      mutators.updateField(name, newValue);
    }
  }

  return value;
};

export const disabledByValues = (checkName, vals) => (name, values, errors, disabled) => {
  let checked = '';
  if (disabled) return true;

  const lastPoint = name.lastIndexOf('.');
  if (lastPoint !== -1) checked = `${name.substring(0, lastPoint)}.${checkName}`;
  else checked = checkName;

  const value = get(values, checked);

  if (isUndefined(value)) return false;

  if (!isArray(vals)) return value === vals;
  return vals.indexOf(value) !== -1;
};

export const notDisabledByValues = (checkName, vals) => (name, values, errors, disabled) => {
  let checked = '';
  if (disabled) return true;

  const lastPoint = name.lastIndexOf('.');
  if (lastPoint !== -1) checked = `${name.substring(0, lastPoint)}.${checkName}`;
  else checked = checkName;

  const value = get(values, checked);

  if (isUndefined(value)) return true;

  if (!isArray(vals)) return value !== vals;
  return vals.indexOf(value) === -1;
};

export const notDisabledByPropAndValues = (checkName, vals) => (name, values, errors, disabled) => {
  if (disabled) return true;

  const value = get(values, checkName);

  if (isUndefined(value)) return true;

  if (!isArray(vals)) return value !== vals;
  return vals.indexOf(value) === -1;
};

export const deepOmit = (object, keysToOmit) => {
  const keysToOmitPrepared = isArray(keysToOmit) ? keysToOmit : [keysToOmit];

  const omitFromObject = obj => transform(obj, (result, value, key) => {
    let omited = false;

    if (isString(key)) {
      keysToOmitPrepared.forEach(k => {
        if (key.match(k)) omited = true;
      });
    }

    if (omited) return;

    // Исключение надо для IE11, иначе регистрация не проходит
    if (key === 'cert' || value instanceof Blob) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = isObject(value) ? omitFromObject(value) : value;
    }
  });

  return omitFromObject(object);
};

export const deepRangePickerPrepare = (object, rpPrename) => {
  const recursivePrepare = obj => transform(obj, (result, value, key) => {
    if (isString(key)) {
      if (key.match(rpPrename)) {
        const { from, to } = value;
        const preparedKey = key.replace('__form_RangePicker_', '');

        // eslint-disable-next-line no-param-reassign
        result[`${preparedKey}From`] = from;
        // eslint-disable-next-line no-param-reassign
        result[`${preparedKey}To`] = to;
        return;
      }
    }

    // Исключение надо для IE11, иначе регистрация не проходит
    if (key === 'cert' || value instanceof Blob) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = isObject(value) ? recursivePrepare(value) : value;
    }
  });

  return recursivePrepare(object);
};

export const prepareToRange = params => transform(params, (result, value, key) => {
  const match = key.match(/(.+)(From|To)$/);
  if (match) {
    const newKey = `${match[1]}_${match[2].toLowerCase()}`;

    // eslint-disable-next-line no-param-reassign
    result[newKey] = value;
  } else {
    // eslint-disable-next-line no-param-reassign
    result[key] = value;
  }
});

export const prepareFromRange = params => transform(params, (result, value, key) => {
  const match = key.match(/(.*)_(from|to)$/);
  if (match) {
    const type = match[2].charAt(0).toUpperCase() + match[2].slice(1);

    // eslint-disable-next-line no-param-reassign
    result[`${match[1]}${type}`] = value;
  } else {
    // eslint-disable-next-line no-param-reassign
    result[key] = value;
  }
});

export const isFormError = (formErrors) => {
  if (isEmpty(formErrors)) return false;
  for (const field in formErrors) {
    if (!isObject(formErrors[field])
      && formErrors[field]
      && formErrors[field].toUpperCase().indexOf('НЕ УКАЗАН') === -1
      && formErrors[field] !== 'Error'
      && formErrors[field].length > 1) {
      return true;
    }
  }
  return false;
};
