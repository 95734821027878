import { get } from 'lodash';

function replaceCommasWithDots(str) {
  return ('' + str).replace(/,/g, '.');
}

export function rubelsToKopecksInProducts(products = []) {
  return products.map(product => ({
    ...product,
    custom_cost: +(+replaceCommasWithDots(get(product, 'custom_cost', 0)) * 100).toFixed(0),
    item_price: +(+replaceCommasWithDots(get(product, 'item_price', 0)) * 100).toFixed(0),
    mrp: +(+replaceCommasWithDots(get(product, 'mrp', 0)) * 100).toFixed(0),
    cis_tax: +(+replaceCommasWithDots(get(product, 'cis_tax', 0)) * 100).toFixed(0),
    vat_tax: +(+replaceCommasWithDots(get(product, 'vat_tax', 0)) * 100).toFixed(0),
    //currency_rate не переводим в копейки, т.к. курс валюты не является денежным значением MOTP-4679
    //но необходимо заменить запятые на точки, поэтому остальную часть оставляем
    currency_rate:
      +(+replaceCommasWithDots(get(product, 'currency_rate', 0))).toFixed(4),
  }));
}

export function kopecksToRubelsInProducts(products = []) {
  return products.map(product => ({
    ...product,
    custom_cost: (+replaceCommasWithDots(get(product, 'custom_cost', 0)) / 100).toFixed(2),
    item_price: (+replaceCommasWithDots(get(product, 'item_price', 0)) / 100).toFixed(2),
    mrp: (+replaceCommasWithDots(get(product, 'mrp', 0)) / 100).toFixed(2),
    cis_tax: (+replaceCommasWithDots(get(product, 'cis_tax', 0)) / 100).toFixed(2),
    vat_tax: (+replaceCommasWithDots(get(product, 'vat_tax', 0)) / 100).toFixed(2),
    //currency_rate не переводим в рубли, т.к. курс валюты не является денежным значением MOTP-4679
    //но необходимо заменить запятые на точки, поэтому остальную часть оставляем
    currency_rate:
      (+replaceCommasWithDots(get(product, 'currency_rate', 0))).toFixed(4),
  }));
}

export const kopecksToRubels = val => {
  return val ? (+replaceCommasWithDots(val) / 100).toFixed(2) : val;
}

export const rubelsToKopecks = val => {
  return val ? Math.ceil(+replaceCommasWithDots(val) * 100) : val;
}

export function numbersToStrings(products = []) {
  return products.map(product => ({
    ...Object.keys(product).reduce((obj, key) => {
      if (typeof product[key] === 'number' && !isNaN(product[key]))
        obj[key] = '' + product[key];
      else if (typeof product[key] === 'number' && isNaN(product[key]))
        obj[key] = 0;
      else obj[key] = product[key];
      return obj;
    }, {}),
  }));
}

export function formatAddZeros(value, name) {
  const fixed = name && name.length && name.indexOf('currency_rate') !== -1 ? 4 : 2;
   // eslint-disable-next-line
  return /^[\d]+[\.,]?[\d]{0,9}$/.test(value) ? `${(+(value.replace(',', '.'))).toFixed(fixed)}` : value;
}
