import React from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './Form.constants';


class FormContextWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element, PropTypes.arrayOf(PropTypes.element)
    ]),
  };

  static defaultProps = {
    children: null,
  };

  renderConsumer = ({ values, errors, mutators, reset, disabled }) => {
    const { children } = this.props;

    return React.Children.map(children, child => React.cloneElement(child, {
      formValues: values,
      formErrors: errors,
      formMutators: mutators,
      formReset: reset,
      formDisabled: disabled,
    }));
  };

  render() {
    return (
      <FormContext.Consumer>
        {this.renderConsumer}
      </FormContext.Consumer>
    );
  }
}

export default FormContextWrapper;
