import React from 'react';

export const BottomArrowIcon = ({style}) => {
  return (
    <svg
      style={style}
      width="10"
      height="6"
      fill="#212C42"
      viewBox="0 0 10 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bottom-arrowCanvas">
        <g id="Fill 3.3">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 1.88672 0.331075C 1.45462 -0.110358 0.756182 -0.110358 0.324078 0.331075C -0.108026 0.772509 -0.108026 1.48603 0.324078 1.92746L 3.33223 5.00056L 0.324078 8.07254C -0.108026 8.51397 -0.108026 9.22862 0.324078 9.66892C 0.756182 10.1104 1.45462 10.1104 1.88672 9.66892L 5.6762 5.79876C 5.8917 5.57748 6 5.28959 6 5.00056C 6 4.71154 5.8917 4.42252 5.6762 4.20237L 1.88672 0.331075Z"
            transform="matrix(0 1 -1 0 10 0)"
          />
        </g>
      </g>
    </svg>
  );
};
