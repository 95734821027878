import { useCallback } from 'react';
import { Toast } from 'src/utils/Toast';
import i18next from 'i18next';
/**
 * parsedData - массив для сохранения данных парсинга
 * results.data - данные строки
 * results.errors - ошибки, возникшие при парсинге строки
 */
export const rowHandler = parsedData => (results, parser) => {
  if (results.errors.length) {
    parser.abort();
    const errorEntry = Object.entries(results.errors[0]);
    const message = errorEntry.map(([key, value]) => ` ${key}: ${value}`);
    Toast.showError({ content: message });
    throw new Error(`${i18next.t('Некорректные данные')}, ${message}`);
  }

  // currentRow - массив первым элементом которого является массив значений ячеек строки
  const currentRow = results.data;

  // Корректный формат - строка содержит одну ячейку с данными
  if (currentRow[0].length > 1) {
    parser.abort();
    Toast.showError({ content: i18next.t('Данные не соответствуют формату') });
    throw new Error(i18next.t('Данные не соответствуют формату'));
  }

  if (currentRow[0][0]) {
    parsedData.push(currentRow[0][0]);
  }
};

export const adaptFilters = filters => {
  return filters.reduce((previousValue, currentValue) => {
    previousValue[currentValue.id] = currentValue.value;
    return previousValue;
  }, {})
};
