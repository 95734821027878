import React from 'react';
import { Icon } from '@crpt/react-icon';
import { StyledLink } from '../../../motp_components/_Requests/OutcomeList/OutcometList.constants';
import { ParentActWrap } from '../../../motp_components/_Requests/RequestList/Document/Document.styled';
import { useTranslation } from 'react-i18next';

export const LinkedDocuments = ({ links }) => {
  const { t } = useTranslation();

  return links.length > 0
    ? (
      links.map(
        (item) => (
          <ParentActWrap key={item.id}>
            <div style={{ marginRight: 5, paddingTop: 5 }}>
              <Icon type={'info'} />
            </div>
            <StyledLink to={item.link} target="_blank">{t(item.label)}</StyledLink>
          </ParentActWrap>
        )
      )
    )
    : (<span>-</span>)
};
