import React from 'react';
import CommonDescription from '../CommonDescription/CommonDescription';
import defaultParameters from './defaultParameters';
import customParameters from './customParameters';
import graphicPresentation from './graphicPresentation';
import indicatorsDescription from './indicatorsDescription';

export default {
  h1: 'Динамика кодов маркировки',
  maxWidth: '1360px',
  description: <CommonDescription />,
  tables: [
    defaultParameters,
    customParameters,
    graphicPresentation,
    indicatorsDescription
  ]
}
