export const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  paginationWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '30px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paginationCount: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#b8b8b8',
    width: '100%',
    textAlign: 'right',
  },
};
