import React, { Component } from 'react';
import moment from 'moment';
// import { InlineWrap } from '@crpt/react-page-layout';
import { DatePicker } from '@crpt/react-datepicker';
import { exciseDatepickerTheme } from '../../theme/ru-tabaco/datepicker';
// import { Switcher, SelectWrap } from './styled';
import { paramsFormatter } from './utils';
import styled from 'styled-components';
import {SingleSelect} from '@crpt/react-select';
import {Icon} from '@crpt/react-icon';
import {exciseYearTheme} from '../../theme/ru-tabaco/select/exciseYear';
import {Translate} from '../../common_components/Translate/Translate';

const DateSelectWrap = styled.div`
  min-width: 327px;
  width: 327px;
`;

const YearMonthSelectorWrap = styled.div`
  width: 327px;
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  background: #fbfbfb;
  border-radius: 3px;
  z-index: 8;
  margin-bottom: 24px;
`;

const YMContent = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #434244;
  z-index: 10;
  cursor: ${props => (props.selected ? '' : 'pointer')};
`;

const MovingPart = styled.div`
  background: #f8ec31;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 164px;
  height: 40px;
  position: absolute;
  left: ${props => (props.isMonth ? '0' : '50%')};
  transition: 0.3s left linear;
  pointer-events: none;
`;

class MonthYearDateSwitcher extends Component {

  dateFormat = 'YYYY-MM-DD';

  componentDidMount() {
    const now = moment().format('YYYY-MM');
    const [year, month] = now.split('-');
    this.props.actions.Excise.FilterParamsUpdated({
      year: +year,
      month: +month,
      isMonth: true
    });
  }

  render() {
    const { filterParams } = this.props.Excise;

    const {
      updateExciseStat,
      updateMarkingStat,
      FilterParamsUpdated,
      rawListUpdated
    } = this.props.actions.Excise;

    const { isMonth } = filterParams;

    const startYear =
      moment(filterParams.dateTo).year() > moment().year()
        ? moment(filterParams.dateTo)
        : moment();

    const yearsCount = moment(startYear).diff('2018-01-01', 'years');

    const currentYear = startYear.year();
    const years = new Array(yearsCount + 1)
      .fill(null)
      .map((v, i) => {
        const val = { date: moment(`${currentYear - i}-01-01`, 'YYYY-MM-DD') };
        val.title = val.date.format('YYYY');
        val.id = i;
        return val;
      })
      .reverse()
      .map((v, i) => ({ ...v, id: i }));

    const selectedId = years.findIndex(v =>
      v.date.isSame(moment(filterParams.dateTo), 'year')
    );

    return (
      <DateSelectWrap>
        <YearMonthSelectorWrap>
          <YMContent
            selected={isMonth}
            onClick={() => {
              FilterParamsUpdated({
                isMonth: true
              });
              updateExciseStat();
              updateMarkingStat();
              rawListUpdated(
                Object.assign({}, filterParams, { isMonth: true }),
                paramsFormatter
              );
            }}
          >
            {Translate('Месяц')}
          </YMContent>
          <YMContent
            selected={!isMonth}
            onClick={() => {
              FilterParamsUpdated({
                isMonth: false
              });
              updateExciseStat();
              updateMarkingStat();
              rawListUpdated(
                Object.assign({}, filterParams, { isMonth: false }),
                paramsFormatter
              );
            }}
          >
            {Translate('Год')}
          </YMContent>
          <MovingPart isMonth={isMonth} />
        </YearMonthSelectorWrap>

        <div>
          {isMonth ? (
            <DatePicker
              monthView
              inline={false}
              date={moment(filterParams.dateTo).format(this.dateFormat)}
              onClearDate={moment().format(this.dateFormat)}
              format={this.dateFormat}
              outFormat={isMonth ? 'MMMM YYYY' : 'YYYY'}
              onChange={val => {
                console.log('MonthYearDateSwitcher', val);
                const [year, month] = val.date.format('YYYY-MM').split('-');
                FilterParamsUpdated({ year: +year, month: +month });
                updateExciseStat();
                updateMarkingStat();
                rawListUpdated({}, paramsFormatter);
              }}
              rightIconReplacer={<Icon type={'down-arrow'} />}
              theme={{ DatePicker: exciseDatepickerTheme }}
            />
          ) : (
            <SingleSelect
              rightIconReplacer={<Icon type={'down-arrow'} />}
              values={years}
              theme={{ Select: exciseYearTheme }}
              selectedId={
                selectedId !== -1 ? selectedId : years.length - 1
              }
              onSelect={val => {
                console.log('selected', val);
                const [year, month] = val.date.format('YYYY-MM').split('-');
                FilterParamsUpdated({ year: +year, month: +month });
                updateExciseStat();
                updateMarkingStat();
                rawListUpdated({}, paramsFormatter);
              }}
            />
          )}
        </div>

      </DateSelectWrap>
    );
  }
}

export { MonthYearDateSwitcher };
