import Crypto, { Base64 } from '@crpt-ui/crypto';
import { isString } from 'lodash';
import moment from 'moment/moment';
import { call, put, takeEvery } from 'redux-saga/effects';
import {logoutUser} from 'src/common_components/Auth/Auth.utils';

import { CadesUtil } from '../../utils/CadesUtil';
import { getCertSerial } from '../../utils/cookieHelpers';
import { Toast } from '../../utils/Toast';
import * as actions from './Cades.actions';
import { prepareCertificates } from './Cades.utils';
// import { toast } from 'react-toastify';

function* loadSaga() {
  try {
    const certificates = yield call(Crypto.enumerateCertificates);
    const preparedDateCertificates = certificates.map((c) => {
      const info = { ...c.info };
      if (!isString(info[1])) info[1] = moment(new Date(info[1])).toISOString();
      if (!isString(info[2])) info[2] = moment(new Date(info[2])).toISOString();
      return {
        ...c,
        info,
        certType: 'CADES'
      };
    });

    yield put(actions.loaded(preparedDateCertificates));
    const prepared = yield call(prepareCertificates, preparedDateCertificates);
    yield put(actions.prepared(prepared));
  } catch (err) {
    console.log('Ошибка загрузки сертификатов');
  }
}

function* getCertBySerial() {
  const serial = yield call(getCertSerial);

  // this mean that user session is expired and we have no info about cerial number of certificate
  // user should re-authorize
  if(!serial) {
    yield call(logoutUser);
    return;
  }

  try {
    const certificate = yield call(Crypto.findCertificateBySerialNumber, serial);
    yield put(actions.loadedCert(certificate));
  } catch (error) {
    yield put(actions.errorCert(error));
  }
}

function* signSaga(action) {
  const { payload, meta } = action;
  const { data = {}, file } = payload;

  const serial = yield call(getCertSerial);
  const certificate = yield call(Crypto.findCertificateBySerialNumber, serial);

  try {
    let signedData = null;
    if (file) {
      const { type, content } = file;
      const signature = yield call(Crypto.sign, content, certificate, true);

      signedData = {
        signature,
        document: content,
        format: type,
      };
    } else {
      const base64_data = Base64.encode(JSON.stringify(data));
      const signature = yield call(Crypto.sign, base64_data, certificate, true);

      signedData = {
        signature,
        document: base64_data,
        format: 'MANUAL',
      };
    }

    yield put({ type: `${actions.signed} ${meta.subject}`, payload: signedData, meta });
  } catch (error) {
    yield call(Toast.showError, {content: CadesUtil.prepareError(error)});
    yield put({ type: `${actions.error} ${meta.subject}` });
  }
}

function* signByteArraySaga(action) {
  const { payload: data, meta } = action;

  if (!data) { return; }

  const serial = yield call(getCertSerial);
  const certificate = yield call(Crypto.findCertificateBySerialNumber, serial);

  try {
    const signature = yield call(Crypto.signBytearray, data, certificate);
    yield put({ type: `${actions.signedByteArray} ${meta.subject}`, payload: signature, meta });
  } catch (error) {
    yield call(Toast.showError, {content:CadesUtil.prepareError(error)});
    yield put({ type: `${actions.error} ${meta.subject}` });
  }
}

export default function* watchSignCalls() {
  yield takeEvery(actions.sign, signSaga);
  yield takeEvery(actions.signByteArray, signByteArraySaga);
  yield takeEvery(actions.load, loadSaga);
  yield takeEvery(actions.loadCert, getCertBySerial);
}
