/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as SelectCRPT } from '@crpt-ui/core';
import FieldHoc from '../FieldHoc';

const SelectAdapter = ({ input, meta, ...fieldRest }) => {
  const { placeholder, isError, ...rest } = fieldRest;

  useEffect(() => {
    return () => input.onChange();
  }, []);

  return (
    <SelectCRPT
      {...input}
      label={placeholder}
      error={isError(meta)}
      {...rest}
    />
  );
};

SelectAdapter.propTypes = {
  isError: PropTypes.func,
  required: PropTypes.bool,
};

SelectAdapter.defaultProps = {
  isError: meta => meta.invalid,
  required: false,
};

export default FieldHoc(SelectAdapter);
