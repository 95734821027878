import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isOptionsSelected } from '../utils';
import { PanelContainer } from './PanelContainer';
import { LoadingMessage } from './LoadingMessage';
import { Option } from './Option';
import { SelectText } from './SelectText';
import { SelectedOptionsIcon } from './SelectedOptionIcon';
import { Translate } from '../../Translate/Translate';

export const Panel = ({
  isLoading,
  theme,
  isOpen,
  disabled,
  options,
  noValuesText,
  inputValue,
  selectValues,
  onOptionClick,
}) => {
  const renderOptions = useCallback(() => {
    const emptyOption = <Option theme={theme}>{noValuesText}</Option>;
    if (isLoading) {
      return <LoadingMessage theme={theme} multi={false} disabled={disabled} />;
    } else if (!options.length) {
      return emptyOption;
    }
    const filteredOptions = options
      .filter(({ title }) => title.includes(inputValue.toLocaleLowerCase()))
      .map((option, i) => {
        const { id, disabled, title } = option;
        return (
          <Option
            onClick={() => {
              onOptionClick(option);
            }}
            theme={theme}
            key={`Key${i + id}`}
            disabled={disabled}
          >
            <SelectText>{typeof title === "string" ? Translate(title) : title}</SelectText>
            {isOptionsSelected(option, selectValues) && <SelectedOptionsIcon />}
          </Option>
        );
      });
    return filteredOptions.length ? filteredOptions : emptyOption;
  }, [options, isLoading, inputValue, selectValues]);

  return (
    <PanelContainer
      isOpen={isOpen}
      theme={theme}
      disabled={disabled}
    >
      {renderOptions()}
    </PanelContainer>
  );
};

Panel.propTypes = {
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      SelectOptionsPanel: PropTypes.object,
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onOptionClick: PropTypes.func.isRequired,
  noValuesText: PropTypes.string.isRequired,
  selectValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  inputValue: PropTypes.string.isRequired,
};
