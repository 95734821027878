import React, { Component } from 'react';

import { SingleSelect } from '@crpt/react-select';

import { ProductService } from '../../services/ProductService';
import {FilterURLHelper} from '../../utils/FilterURLHelper';
import { Translate } from '../../common_components/Translate/Translate';

class ProductBrandSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      values: [],
      isLoading: false
    };

    this.onChange = this.onChange.bind(this);
    this.select = React.createRef();
  }

  componentDidMount() {

    //TODO: all lists to List.saga, all filter fill in saga

    setTimeout(() => {
      const {name} = this.props;
      const value = FilterURLHelper.getParamByKey(name);

      if (value) {
        const brand = {
          id: 0,
          title: value,
          name: value,
          [this.props.name || 'brand']: value
        };

        this.setState({
          values: [brand]
        });

        this.select.current.onSelect({preventDefault: () => {}}, brand);
      }
    }, 500);

  }

  onChange(val) {
    this.setState({
      isLoading: true
    });

    ProductService.searchByBrand(val)
      .then(answer => {
        this.setState({
          isLoading: false,
          values: answer.data.map((pr, i) =>
            Object.assign({
              [this.props.name || 'brand']: pr,
              id: i,
              title: pr,
            })
          ),
        });
      })
      .catch(err => {
        console.warn('canceled or err', err);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { onChange, isLoading, values, onRef, ...other } = this.props;

    return (
      <SingleSelect
        {...other}
        onChange={this.onChange}
        noValuesText={Translate('<пусто>')}
        isLoading={this.state.isLoading}
        values={this.state.values}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
      />
    );
  }
}

ProductBrandSelect.displayName = 'SingleSelect';

export default ProductBrandSelect;
