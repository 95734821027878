import React from 'react';
import { connect } from 'react-redux';
import { intersection } from 'lodash';
import { roles } from '../Auth/Auth.selectors';

const mapStateToProps = state => ({
  roles: roles(state),
});

const withPermissions = config => {
  return Component => {
    return connect(mapStateToProps)(props => {
      const permissions = {};

      Object.keys(config).forEach(key => {
        if (typeof config[key] !== 'object') {
          if (Array.isArray(config[key])) {
            permissions[key] = !!intersection(config[key], props.roles).length;
          } else {
            permissions[key] =
              config[key] === true || props.roles.includes(config[key]);
          }
        } else {
          const section = {};

          const configValue = config[key];

          Object.keys(configValue).forEach(sectionKey => {
            if (Array.isArray(configValue[sectionKey])) {
              section[sectionKey] = !!intersection(
                configValue[sectionKey],
                props.roles,
              ).length;
            } else {
              section[sectionKey] =
                configValue[sectionKey] === true ||
                props.roles.includes(configValue[sectionKey]);
            }
          });
          permissions[key] = section;
        }
      });

      return <Component {...{ ...props, permissions }} />;
    });
  };
};

export default withPermissions;
