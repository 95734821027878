import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        },  
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Бренду</li>
          </ul>,
          'col3': 'Тип участника, реализующего товар конечному покупателю',
        },
        {
          'col1': [2],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Регион, в котором была совершена продажа товара',
        },
        {
          'col1': [3],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По городу и Брендy</li>
          </ul>,
          'col3': 'Населённый пункт, в котором была совершена продажа товара',
        },
        {
          'col1': [4],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По городу и Брендy</li>
          </ul>,
          'col3': 'Диапазон численности населённого пункта',
        },
        {
          'col1': [5],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Бренд',
        },
        {
          'col1': [6, 7],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
          </ul>,
          'col3': 'Уникальный код и наименование товара',
        },
        {
          'col1': [8],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Количество торговых точек, в которых была продажа товара за выбранный период в данном городе/регионе / Количество торговых точек ВСЕГО, в которых были продажи табачной продукции за выбранный период в данном городе/регионе, %',
        },
        {
          'col1': [9, 10],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Общий объём продаж табачной продукции тех торговых точек, в которых была продажа товара за выбранный период в данном городе/регионе / Общий объём продаж табачной продукции во всех торговых точках за выбранный период в данном городе/регионе, %',
        },
        {
          'col1': [11],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Доля товара от общего ассортимента товаров, реализованных в данном городе/регионе за выбранный период, %',
        },
        {
          'col1': [12, 13],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Общий объем продаж товара за выбранный период в данном городе/регионе / Общий объём продаж табачной продукции в данном городе/регионе за выбранный период, %',
        },
        {
          'col1': [14],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Количество торговых точек, в которых продавался товар в предыдущем периоде, и отсутствуют продажи товара за текущий период в данном городе/регионе / Общее количество торговых точек, реализующих табачную продукцию за выбранный период в данном городе/регионе, %',
        },
        {
          'col1': [15, 16],
          'col2': <ul>
            <li>По городу и GTIN</li>
            <li>По региону и GTIN</li>
            <li>По городу и Брендy</li>
            <li>По региону и Брендy</li>
          </ul>,
          'col3': 'Объём продаж торговых точек, в которых продавался товар в предыдущем периоде, и отсутствуют продажи товара за текущий период в данном городе/регионе / Общий объём продаж торговых точек, реализующих табачную продукцию за выбранный период в данном городе/регионе, %',
        },
      ]
    }
  ]
}