export const getTableData = (declarationDocument) => {
  const tableData = [];
  
  for(let i = 0; i < declarationDocument.markedProducts.length; i++) {
    const productInfo = declarationDocument.markedProducts[i];
    const omitedProductInfo = {
      ...productInfo,
      cisInfoList: null
    };
    for(let j = 0; j < productInfo.cisInfoList.length; j++) {
      const cisDto = productInfo.cisInfoList[j];
      const markingLevelType = cisDto.markingLevelType;
      
      for(let h = 0; h < cisDto.cisDtoList.length; h++) {
        const cisInfo = cisDto.cisDtoList[h];
        const fullCisData = {
          ...cisInfo,
          markingLevelType,
          productInfo: omitedProductInfo
        };
        
        tableData.push(fullCisData);
      }
    }
  }
  
  return tableData;
}