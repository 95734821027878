import { actionTypes, strings } from '../../constants';

export const contactsVisible = (state = false, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.TOGGLE_CONTACTS:
      return !state;
    case actionTypes.REGISTRATION.SHOW_CONTACTS:
      return true;
    default:
      return state;
  }
};
