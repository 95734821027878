import { FormSerializer } from './FormSerializer';
import moment from 'moment';
import { FilterURLHelper } from './FilterURLHelper';
import { getSearchString } from '../common_components/List/List.utils';
import { HistoryHelper } from './HistoryHelper';

export const defaultFilterParamSerializer = (formVals) =>
  FormSerializer.serialize(formVals);

export const defaultRequestParamSerializer = (formVals, pageParams) => {
  const final = { ...FormSerializer.serialize(formVals), ...pageParams };
  delete final.page;
  return final;
};

export const defaultDateFilterParamSerializer = formVals => {
  const serialized = FormSerializer.serialize(formVals);
  const { dateFrom, dateTo } = serialized;
  if (dateFrom) serialized.dateFrom = moment(dateFrom).utc(true).format();
  if (dateTo) serialized.dateTo = moment(dateTo).endOf('day').utc(true).format();
  return serialized;
};

export const exciseListMountSerializer = () => {
  const now = moment();
  return {
    dateFrom: now.startOf('month').toISOString(),
    dateTo: now.endOf('month').toISOString(),
  };
};

export const fromURLMountFilterParamsSerializer = params => {
  const search = getSearchString();
  const filterParams = FilterURLHelper.getParamsFromLocation(search);
  if (window.location.search !== search)
    HistoryHelper.getHistory().replace({ search });
  const final = { ...params, ...filterParams };
  delete final.page;
  return final;
};

export const fromURLPageSerializer = page => {
  const filterParams = FilterURLHelper.getParamsFromLocation();
  const filterParamsPage = filterParams.page;
  return filterParamsPage !== undefined ? +filterParamsPage : page;
};

export const ParticipantDetailsCisListFilterParamSerializer = (
  formVals,
  pageParams,
  getState,
) => {
  const filterParams = getState().CISList.filterParams;
  return {
    participantId: filterParams.participantId,
    ...FormSerializer.serialize(formVals),
  };
};

export const ParticipantDetailsCisListRequestParamSerializer = (
  formVals,
  pageParams,
  getState,
) => {
  const filterParams = getState().CISList.filterParams;
  return {
    participantId: filterParams.participantId,
    ...FormSerializer.serialize(formVals),
    ...pageParams,
  };
};

export const ProductListRequestParamSerializer = (formVals, pageParams) => {
  const serialized = {
    ...FormSerializer.serialize(formVals),
    ...pageParams,
  };
  const { gtin, name } = serialized;
  if (gtin !== name) serialized.name = gtin;
  return serialized;
};
