import { FormSerializer } from './FormSerializer';
import moment from 'moment/moment';
import Qs from 'qs';

export class FilterURLHelper {
  static getParamsFromLocation(searchString = null) {
    const search = searchString || window.location.search;
    return search
      .slice(1)
      .split('&')
      .reduce((searchParametersConfig, searchParameterString) => {
        const [searchParameterName, searchParameterValue] = searchParameterString.split('=');
        if (searchParameterName[0] && searchParameterName[0] !== '_') {
          const prevSearchParameterValue = searchParametersConfig[searchParameterName];
          if (!!prevSearchParameterValue) {
            if (Array.isArray(prevSearchParameterValue)) {
              searchParametersConfig[searchParameterName] = [...prevSearchParameterValue, searchParameterValue];
            } else {
              searchParametersConfig[searchParameterName] = [prevSearchParameterValue, searchParameterValue];
            }

          } else {
            searchParametersConfig[searchParameterName] = decodeURIComponent(searchParameterValue);
          }
        }
        return searchParametersConfig;
      }, {});
  }

  static getAllParamsFromLocation(searchString = null) {
    const search = searchString || window.location.search;

    return search
      .split('&')
      .map((v, i) => (i === 0 ? v.slice(1) : v))
      .reduce((obj, keyValue) => {
        const [key, value] = keyValue.split('=');
        if (value && !obj[key]) obj[key] = decodeURIComponent(value);
        return obj;
      }, {});
  }

  static getParamByKey(key) {
    return FilterURLHelper.getParamsFromLocation()[key];
  }

  static getPairSearchParamByKey(key) {
    try {
      const pair = window.location.search
        .split('&')
        .map((v, i) => (i === 0 ? v.slice(1) : v))
        .filter(v => ~v.indexOf(`_${key}`))[0];
      return decodeURIComponent(pair.split('=')[1]);
    } catch (e) {
      return null;
    }
  }

  static getInitialValue(filterParams, value) {
    const displayName = value.component.displayName;
    const initial = {};

    if (!filterParams) return initial;

    switch (displayName) {
      case 'WithTheme(SingleSelect)':
      case 'SingleSelect':
        break;

      case 'WithTheme(Input)':
      case 'Input':
        if (filterParams[value.props.name])
          initial.defaultValue = filterParams[value.props.name];
        break;

      case 'WithTheme(RangePicker)':
      case 'RangePicker':
        if (filterParams.dateFrom)
          initial.from = moment(new Date(+filterParams.dateFrom)).format(
            value.props.format
          );
        if (filterParams.dateTo)
          initial.to = moment(new Date(+filterParams.dateTo)).format(
            value.props.format
          );
        break;

      default:
        break;
    }

    return initial;
  }

  static getSearchStringFromKeyValueObject(parsed) {
    const searchArgs = [];
    Object.keys(parsed).forEach(key => {
      try {
        if (parsed[key]) searchArgs.push({ key, value: parsed[key] });
      } catch (e) {
        console.warn('getSearchStringFromRawDataAndListFiltersConfig err:', e);
      }
    });

    return '?' + searchArgs.map(arg => `${arg.key}=${arg.value}`).join('&');
  }

  static getSearchStringFromRawDataAndListFiltersConfig(data, filtersConfig) {
    const parsed = FormSerializer.serialize(data);
    const searchArgs = [];
    Object.keys(parsed).forEach(key => {
      try {
        if (parsed[key]) searchArgs.push({ key, value: parsed[key] });
        const sliced = filtersConfig.slice(1);
        const current = sliced.find(filter => filter[0].props.name === key)[0];
        const searchParam = current.component.searchParam;
        if (searchParam) {
          const necessaryValue = data[key][0][searchParam];
          necessaryValue && searchArgs.push({ key: `_${key}`, value: necessaryValue });
        }
      } catch (e) { null; }
    });

    return '?' + searchArgs.map(({key, value}) => {
      if (Array.isArray(value)) {
        return Qs.stringify({[key]: value}, {arrayFormat: 'repeat'})
      }
      return `${key}=${value}`;
    }).join('&');
  }

}
