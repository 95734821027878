import lottie from 'lottie-web';
import React, { useEffect } from 'react';
import { animationData } from 'src/common_components/Preloader/Preloader.constans';
import { useStyles } from './Preloader.styles';


export const PreloaderView = ({isOpen, text}) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("animation"),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, [isOpen]);
  
  const styles = useStyles();
  if(!isOpen) return null;

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imageWrapper} id='animation'/>
      <div className={styles.textWrapper}>
        {text}
      </div>
    </div>
  );
};