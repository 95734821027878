import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import { ThemeProvider } from 'styled-components';
import Modal from '../Modals/Modal/Modal';
import {
  Container,
  IcoWrap,
  ActionsWrapper,
  ButtonsWrapper,
  ButtonWrapper,
  Caption,
  Content,
  Wrapper,
  Message,
} from './FileNotify.styled';
import {Translate} from '../Translate/Translate';

const FileNotifyView = props => {
  const { isOpen, message, theme, onCancel, onAccept } = props;

  return (
    <ThemeProvider theme={theme}>
      <Modal isOpen={isOpen} onRequestClose={onCancel} contentLabel={Translate("Ошибка")}>
        <Container>
        <IcoWrap>
            <Icon type={'cross'} fill={"#ffffff"} onClick={onCancel} />
          </IcoWrap>
        <Wrapper>
          <Caption>{Translate('Ошибка')}</Caption>

          <Content>
            <Message>{message.map(text => <div>{text}</div>)}</Message>
            <br />
            {Translate('Загрузите другой файл')}
          </Content>
        </Wrapper>

        <ButtonsWrapper>
          <ActionsWrapper>
            <ButtonWrapper>
              <Button onClick={onCancel} variant="contained">
                {Translate('Отмена')}
              </Button>
            </ButtonWrapper>

            <Button onClick={onAccept} variant="contained">
              {Translate('Загрузить файл')}
            </Button>
          </ActionsWrapper>
        </ButtonsWrapper>
        </Container>
      </Modal>
    </ThemeProvider>
  );
};

FileNotifyView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(PropTypes.string).isRequired,
  theme: PropTypes.shape({}).isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FileNotifyView;
