import { handleActions } from 'redux-actions';
import * as actions from './ExportEaes.actions';

const loaded = handleActions(
    {
      [actions.mounted]: () => false,
      [actions.loaded]: (state, { payload = {} }) => !!payload || false,
      [actions.unmounted]: () => false,
    },
    false,
);

const selectedDocument = handleActions(
  {
    [actions.mounted]: () => ({}),
    [actions.loaded]: (state, { payload = {} }) => payload || {},
    [actions.unmounted]: () => ({}),
  },
  {},
);


const codes = handleActions(
  {
    [actions.loadedCodes]: (state, { payload }) => payload,
    [actions.mounted]: () => ({}),
    [actions.unmounted]: () => ({}),
  },
  {}
);


const loadedCodes = handleActions(
  {
    [actions.mounted]: () => false,
    [actions.updateCodesPage]: () => false,
    [actions.loadedCodes]: (state, { payload = {} }) => !!payload || false,
    [actions.unmounted]: () => false,
  },
  false,
);

const codesPage = handleActions(
  {
    [actions.updateCodesPage]: (state, { payload }) => payload,
    [actions.mounted]: () => (1),
    [actions.unmounted]: () => (1),
  },
  1,
);

export default {
    loaded,
    selectedDocument,
    codes,
    loadedCodes,
    codesPage,
};
