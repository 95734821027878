import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { withModal } from '../../../../common_components/Modals/hocs/withModal';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import { withRouter } from 'react-router-dom';
import AddIdentificationCodes from '../../../../common_components/AddIdentificationCodes/AddIdentificationCodes';
import { AddCodesBarWrapper, AddCodesButtons } from './constants';

const AddCodesBar = props => {
  const { onOpenModal, onSetContent, onCloseModal, formMutators } = props;

  const { t } = useTranslation();

  const openLoadCisListModal = useCallback(() => {
    onSetContent(
      <AddIdentificationCodes
        formMutators={formMutators}
        onCloseModal={onCloseModal}
        types=".xls, .xlsx"
        title="Добавление кодов"
      />,
    );
    onOpenModal();
  }, [onSetContent, onOpenModal, formMutators, onCloseModal]);

  return (
    <AddCodesBarWrapper>
      <AddCodesButtons>
        <PrimaryActionButton onClick={openLoadCisListModal}>
          {t('Загрузить XLS')}
        </PrimaryActionButton>
      </AddCodesButtons>
    </AddCodesBarWrapper>
  );
};

export default compose(connect(null), withModal, withRouter)(AddCodesBar);
