import * as _ from 'lodash';
import { createSelector } from 'reselect';

const getRoot = state => _.get(state, 'LP.UnloadService.UnloadServiceList');

export const tasks = createSelector(getRoot, root =>
  _.get(root, 'data.tasks', []),
);

export const taskTypes = createSelector(getRoot, root =>
  _.get(root, 'data.taskTypes', []),
);

export const availableOrgRoles = createSelector(getRoot, root =>
  _.get(root, 'data.availableOrgRoles', []),
);

export const loaded = createSelector(getRoot, root => _.get(root, 'loaded'));
