export const getUrlPage = () => {
  const matches = location.search.match(/page=(\d)+/);
  if (matches && matches.length) {
    return parseInt(matches[1]) || 1;
  }
  return 1;
}

export const getLimitOffsetFormatter = limit => page => {
  return {
    limit,
    offset: limit * (page - 1),
  };
};

export const getPageFormatter = limit => page => {
  return {
    limit,
    page: page - 1,
  }
}
