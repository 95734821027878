import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#f6f42e',
  },
  secondary: {
    main: '#4aa5e8',
  },
  text: {
    primary: '#63666a',
    secondary: '#868686',
    light: '#b8b8b8',
    documentHeadingLabel: '#212c42',
  },
  border: {
    light: '#f2f2f2',
    secondary: '#e6e6e6',
  },
  error: {
    main: '#ff4b4b',
  },
  success: {
    main: '#60e84a',
  },
};

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#63666a'
        },
      },
    },
    MuiDrawer: {
      paper: {
        width: '368px',
      },
    },
    MuiFilledInput: {
      root: {
        '&:hover': {
          backgroundColor: 'white',
        },
        '&$focused': {
          backgroundColor: 'white',
        },
        backgroundColor: 'white',
        borderRadius: '3px',
        overflow: 'hidden',
      },
      underline: {
        '&:after': {
          borderBottom: '2px solid #f6f42e',
          transition: 'transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 20 / 14,
        margin: '0 0 0 10px',
      },
      root: {
        '&:last-child': {
          marginBottom: '0',
        },
        alignItems: 'center',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: '10px',
        verticalAlign: 'top',
        userSelect: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        '&:focus': {
          outline: 0,
        },
      },
    },
    MuiInput: {},
    MuiInputLabel: {},
    MuiFormLabel: {
      root: {
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingBottom: '5px',
        paddingTop: '5px',
      },
    },
    MuiListItem: {
      button: {
        '&:focus': {
          backgroundColor: palette.primary.main,
        },
        '&:hover': {
          backgroundColor: '#f7f7f7',
        },
      },
      root: {
        '&:hover': {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      root: {
        '&$selected': {
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
        },
        '&:hover': {
          backgroundColor: '#f7f7f7',
        },
        alignItems: 'center',
        borderRadius: 0,
        display: 'flex',
        fontSize: '16px',
        height: 'auto',
        minHeight: '40px',
      },
    },
    MuiPaper: {
      root: {
        background: '#fff',
        borderRadius: '3px',
        boxSizing: 'border-box',
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'top',
        fontSize: 24,
      },
    },
    MuiTooltip: {
      popper: {
        opacity: '1',
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        borderRadius: '3px',
        minWidth: 'auto',
        color: 'white',
        padding: '10px 15px',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 16,
        lineHeight: 24 / 16,
      },
      h1: {
        fontSize: 32,
        fontWeight: 700,
        letterSpacing: '1px',
        lineHeight: 40 / 32,
        textTransform: 'uppercase',
      },
      h2: {
        fontSize: 20,
        lineHeight: 30 / 20,
        fontWeight: 600,
      },
      h3: {
        fontSize: 16,
        lineHeight: 20 / 16,
        fontWeight: 600,
      },
      h4: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '30px',
        marginTop: 20,
        marginBottom: 20,

        '&:first-child': {
          marginTop: 0,
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
      h5: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1.5,
      },
    },
    MuiTable: {
      root: {
        boxShadow: 'inset 0px 1px 0px #f2f2f2, inset 1px 0px 0px #f2f2f2',
        tableLayout: 'fixed',
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 390,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '20px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .2)',
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        userSelect: 'none',

        '&:hover': {
          background: 'transparent',
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        userSelect: 'none',

        '&:hover': {
          background: 'transparent',
        },
      },
    },
  },
  palette,
  modal:{
    padding:'0px 40px',
  },
  spacing: 8,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});
