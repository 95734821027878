import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { isArray, isFunction, isUndefined } from 'lodash';

import { displayNone } from '../../../theme/ru-tabaco/common/index';

class FieldsWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    name: PropTypes.string.isRequired,
    formValues: PropTypes.shape({}),
    formErrors: PropTypes.shape({}),
    formMutators: PropTypes.shape({}),
    formDisabled: PropTypes.bool,
    parse: PropTypes.func,
    format: PropTypes.func,
    validate: PropTypes.func,
    visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  };

  static defaultProps = {
    formValues: undefined,
    formErrors: undefined,
    formMutators: undefined,
    formDisabled: false,
    parse: undefined,
    format: undefined,
    validate: undefined,
    visibility: undefined,
    disabled: undefined,
  };

  parse = value => {
    const { parse, name, formMutators, formValues } = this.props;
    return parse ? parse(value, formMutators, name, formValues) : value;
  };

  validate = (...args) => {
    const { validate, name } = this.props;
    return validate ? validate(...args, name) : undefined;
  };

  format = value => {
    const { format, name } = this.props;
    return format ? format(value, name) : value;
  };

  isVisible = () => {
    const { visibility, name, formValues } = this.props;

    if (!visibility) {
      return true;
    }

    if (isArray(visibility)) {
      return visibility.reduce((result, fn) => fn(formValues, name) && result, true);
    }

    return visibility(formValues, name);
  };

  disabled = () => {
    const { disabled, name, formValues, formErrors, formDisabled } = this.props;

    if (isFunction(disabled)) {
      return disabled(name, formValues, formErrors, formDisabled);
    }

    return !isUndefined(disabled) ? disabled : formDisabled;
  };

  renderConsumer = ({ input, meta }) => {
    const { children, formValues, formErrors, formMutators } = this.props;

    return React.cloneElement(children, {
      fieldInput: input,
      fieldMeta: meta,
      formValues,
      formErrors,
      formMutators,
      disabled: this.disabled(),
    });
  };

  render() {
    const { name, parse, validate, format } = this.props;
    const visible = this.isVisible();

    return (
      <div style={visible ? undefined : displayNone}>
        <Field
          name={name}
          parse={parse ? this.parse : undefined}
          validate={validate ? this.validate : undefined}
          format={format ? this.format : undefined}
          validateFields={[]}
        >
          {this.renderConsumer}
        </Field>
      </div>
    );
  }
}

export default FieldsWrapper;
