import { handleActions } from 'redux-actions';
import * as actions from './Goods.actions';
import { STEPS } from '../Goods.constants';

const step = handleActions(
  {
    [actions.load]: () => STEPS.LOADING,
    [actions.error]: () => STEPS.ERROR,
    [actions.reset]: () => STEPS.PRELOAD,
  },
  STEPS.PRELOAD,
);

const errorText = handleActions(
  {
    [actions.error]: (state, { payload = null }) => payload,
    [actions.reset]: () => null,
  },
  null,
);

const savedProducts = handleActions(
  {
    [actions.fixProducts]: (state, { payload = [] }) => payload,
  },
  [],
);

export default {
  step,
  errorText,
  savedProducts,
};
