import { Icon } from '@crpt/react-icon';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  getIconByPack,
  getIconNameByCISStatus,
} from 'src/components/TableCells/CIS/utils';
import {cisStatuses, countryCodeFlags} from 'src/constants';
import { packTypes, packLevels } from 'src/constants/packTypes';
import { CellTooltip } from 'src/fragments/Table/Cell/CellTooltip';
import { CisCodeWrap } from 'src/motp_components/_Requests/RequestList/EaesImportDocument/Cis/Cis.styled';
import { UndefinedPhrase } from '../stub';
import { Value, renderValue } from '../value';
import { Translate } from 'src/common_components/Translate/Translate';
import { Trans } from 'react-i18next';

export const CodeColumns = {
  cis: {
    id: 'cis',
    accessor: 'cis',
    Cell: ({ row }) => (
      <Value>
        {row.original.cis ? (
          <CisCodeWrap
            href={`/cis/list/${encodeURIComponent(row.original.cis)}`}
            target={'_blank'}
          >
            {row.original.cis}
          </CisCodeWrap>
        ) : (
          <UndefinedPhrase
            text={
              <Trans>
                Код маркировки отсутствует
                <br />в реестре кодов маркировки
              </Trans>
            }
          />
        )}
      </Value>
    ),
    Header: Translate('Код'),
  },
  type: {
    accessor: 'packageType',
    id: 'packageType',
    Cell: ({ row }) => {
      const packageType = (
        row.original.packageType ||
        row.original.cisPackageType ||
        ''
      ).toLocaleLowerCase();
      const IconPack = getIconByPack(packageType);

      return (
        <Value>
          {packageType ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconPack style={{ marginRight: '6px' }} />
              {Translate(packTypes[packageType] || packLevels[packageType])}
            </div>
          ) : (
            <UndefinedPhrase
              text={
                <Trans>
                  Данные обновляются
                  <br />
                  пожалуйста подождите
                </Trans>
              }
            />
          )}
        </Value>
      );
    },
    Header: Translate('Тип упаковки'),
  },
  status: {
    accessor: 'status',
    id: 'status',
    Cell: ({ row }) => (
      <Value>
        {row.original.status ? (
          <div>
            <Icon
              type={getIconNameByCISStatus(row.original.status)}
              spacingRight={6}
            />
            {Translate(cisStatuses[row.original.status])}
          </div>
        ) : (
          <UndefinedPhrase
            text={
              <Trans>
                Данные обновляются
                <br />
                пожалуйста подождите
              </Trans>
            }
          />
        )}
      </Value>
    ),
    Header: Translate('Статус кода'),
  },
  producedDate: {
    accessor: 'producedDate',
    id: 'producedDate',
    Cell: ({ row }) => {
      return (
        <Value>
          {row.original.producedDate ? (
            `${moment(row.original.producedDate).format('DD.MM.YYYY')}`
          ) : (
            <UndefinedPhrase
              text={
                <Trans>
                  Данные обновляются
                  <br /> пожалуйста подождите
                </Trans>
              }
            />
          )}
        </Value>
      );
    },
    Header: Translate('Дата нанесения'),
  },
  productName: {
    accessor: 'productName',
    id: 'productName',
    Cell: ({ row }) => {
      const productInfoList = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.original.gtin || row.original.productName ? (
              <div>
                {row.original.gtin &&
                  renderValue(row.original.gtin, needEllipsis)}
                {row.original.productName &&
                  renderValue(row.original.productName, needEllipsis)}
              </div>
            ) :
              countryCodeFlags.isUZ
                ? "—"
                : (
                <UndefinedPhrase
                  text={
                    <Trans>Данные обновляются<br />пожалуйста подождите</Trans>
                  }
                />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{productInfoList()}</div>;
      };
      const memoizedTitleValues = useMemo(() => productInfoList(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: Translate('Товар'),
  },
  producerName: {
    accessor: 'producerName',
    id: 'producerName',
    Cell: ({ row }) => {
      const producerNameInfo = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.original.producerName || row.original.producerInn ? (
              <div>
                {row.original.producerName &&
                  renderValue(row.original.producerName, needEllipsis)}
                {row.original.producerInn &&
                  renderValue(row.original.producerInn, needEllipsis)}
              </div>
            ) : (
              <UndefinedPhrase
                text={
                  <Trans>
                    Данные обновляются
                    <br />
                    пожалуйста подождите
                  </Trans>
                }
              />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{producerNameInfo()}</div>;
      };
      const memoizedTitleValues = useMemo(() => producerNameInfo(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: Translate('Производитель'),
  },
  ownerName: {
    accessor: 'ownerName',
    id: 'ownerName',
    Cell: ({ row }) => {
      const ownerNameInfo = (needEllipsis = true) => {
        return (
          <React.Fragment>
            {row.original.ownerName || row.original.ownerInn ? (
              <div>
                {row.original.ownerName &&
                  renderValue(row.original.ownerName, needEllipsis)}
                {row.original.ownerInn &&
                  renderValue(row.original.ownerInn, needEllipsis)}
              </div>
            ) : (
              <UndefinedPhrase
                text={
                  <Trans>
                    Данные обновляются
                    <br />
                    пожалуйста подождите
                  </Trans>
                }
              />
            )}
          </React.Fragment>
        );
      };
      const renderValues = () => {
        return <div>{ownerNameInfo()}</div>;
      };
      const memoizedTitleValues = useMemo(() => ownerNameInfo(false), []);
      return (
        <CellTooltip
          arrow={false}
          alwaysDisplay
          title={memoizedTitleValues}
          render={renderValues}
        />
      );
    },
    Header: Translate('Текущий владелец'),
  },
};
