import styled from 'styled-components';

export const RoleHeading = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #52535a;
  margin-bottom: 12px;
  position: relative;
  background: ${props => props.checked ? '#F8EC31' : '#FFFFFF'};
`;
