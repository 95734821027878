import React, { Component } from 'react';
import styled from 'styled-components';
import { addSpacesBetweenThousands } from '../../utils';
import { Container, Column, InlineWrap } from '@crpt/react-page-layout';
// import { Icon } from '@crpt/react-icon';
import SidebarContent from './SidebarContent';
import SidebarContentBound from '../../containers/Bounds/SidebarContentBound';
import {sidebarExciseTheme} from '../../theme/ru-tabaco/sidebar';
import { MonthYearDateSwitcher } from './MonthYearDateSwitcher';
import { Translate } from '../../common_components/Translate/Translate';

const ValueHeading = styled.div`
  font-size: 16px;
  color: #434244;
  min-width: 200px;
  min-height: 38px;
  margin-bottom: 23px;
  font-weight: 600;
`;

const ValueContent = styled.div`
  font-size: 28px;
  color: #55b5e7;
  display: flex;
  align-items: flex-end;
`;

const Rubel = styled.div`
  font-size: 16px;
  margin-left: 6px;
  padding-bottom: 3px;
`;

const ValueWrap = styled.div`
  min-height: 46px;
  margin-bottom: 34px;
`;

const ValueLabel = styled.div`
  font-size: 16px;
  color: #52535a;
`;

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.openSidebar = this.openSidebar.bind(this);
  }

  openSidebar() {
    const content = <SidebarContentBound component={SidebarContent} />;
    this.props.actions.Sidebar.sidebarOpen(content, sidebarExciseTheme);
  }

  render() {
    const { stat, markingStat } = this.props.Excise;

    const { forProduced, forShipped, inDeclaration } = stat;
    const {
      cnt_applied,
      cnt_emitted,
      cnt_sold,
      cnt_withdrawn,
    } = markingStat;

    const total = cnt_sold + cnt_applied + cnt_emitted + cnt_withdrawn;
    const cnt_not_sold =  cnt_applied - (cnt_sold + cnt_withdrawn);

    console.log('markingStat markingStat', markingStat);

    return (


      <Container gutter={40}>
        <Column>
          <InlineWrap flex>
            <MonthYearDateSwitcher
              Excise={this.props.Excise}
              actions={this.props.actions}
            />
          </InlineWrap>
        </Column>
        <Column>
          <ValueWrap>
            <ValueHeading>{Translate('По произведенной продукции')}</ValueHeading>
            <ValueLabel>{Translate('Исчисленный акциз')}</ValueLabel>
            <ValueContent>
              {forProduced && forProduced.estimated !== undefined
                ? addSpacesBetweenThousands(forProduced.estimated)
                : '-'} <Rubel>₽</Rubel>
            </ValueContent>
          </ValueWrap>
        </Column>
        <Column>
          <ValueWrap>
            <ValueHeading>{Translate('По отгруженной продукции')}</ValueHeading>
            <ValueLabel>{Translate('Исчисленный акциз')}</ValueLabel>
            <ValueContent>
              {forShipped && forShipped.estimated !== undefined
                ? addSpacesBetweenThousands(forShipped.estimated)
                : '-'} <Rubel>₽</Rubel>
            </ValueContent>
          </ValueWrap>

          <ValueWrap>
            <ValueLabel>{Translate('Акциз к вычету')}</ValueLabel>
            <ValueContent>
              {forShipped && forShipped.toDeduction !== undefined
                ? addSpacesBetweenThousands(forShipped.toDeduction)
                : '-'} <Rubel>₽</Rubel>
            </ValueContent>
          </ValueWrap>

        </Column>
        <Column>
          <ValueWrap>
            <ValueHeading>{Translate('По данным декларации')}</ValueHeading>
            <ValueLabel>{Translate('Исчисленный акциз')}</ValueLabel>
            <ValueContent>
              {inDeclaration && inDeclaration.estimated !== undefined
                ? addSpacesBetweenThousands(inDeclaration.estimated)
                : '-'} <Rubel>₽</Rubel>
            </ValueContent>
          </ValueWrap>
          <ValueWrap>
            <ValueLabel>{Translate('Акциз к вычету')}</ValueLabel>
            <ValueContent>
              {inDeclaration && inDeclaration.toDeduction !== undefined
                ? addSpacesBetweenThousands(inDeclaration.toDeduction)
                : '-'} <Rubel>₽</Rubel>
            </ValueContent>
          </ValueWrap>
        </Column>
      </Container>

    );
  }
}

export { Statistics };
