import React from 'react';
import { Value } from 'src/fragments/Table/Cell/value';
import { Translate } from 'src/common_components/Translate/Translate';

export const columns = [
  {
    id: 'gtin',
    accessor: row => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: 'GTIN',
    width: 'auto',
  },
  {
    id: 'name',
    accessor: 'name',
    disableFilters: true,
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    id: 'gtinsCounts',
    accessor: 'gtinsCounts',
    Header: Translate('Итого потребительских упаковок'),
    width: 'auto',
  },
];
