import { Column } from '@crpt/react-page-layout';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from '../../Operation.styles';

const FirstCell = ({ icon, title }) => {
  const classes = useStyles();
  return (
    <Column col={4}>
      <div className={classes.emptyHeading}>
        <div className={classes.icon}>{icon}</div>
        {title}
      </div>
    </Column>
  );
};

FirstCell.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default FirstCell;
