import React from 'react';
import styled from 'styled-components';

import { Icon } from '@crpt/react-icon';

import { withRouter } from 'react-router-dom';

import { getIconNameByDocType } from '../../../common_components/Table/TableCells/Document/utils';
import { documentTypeNames } from '../../../constants/documentTypes';
import { cisStatuses } from '../../../constants/cisStatuses';
import { Translate } from '../../../common_components/Translate/Translate';

const Name = styled.div`
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: #212c42;
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
`;

// TODO PropTypes are absent

const DocumentType = props => {
  const document = props.document ? props.document : props.options.type;
  const type = props.type ? props.type : document._original.type;

  return (
    <Wrap>
      <Icon type={getIconNameByDocType(type)} spacingRight={16} />
      <Name>{Translate(documentTypeNames[type] || cisStatuses[type])}</Name>
    </Wrap>
  );
};

export default withRouter(DocumentType);
