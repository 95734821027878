import { selector } from '../../../../../utils/selector';

export const results = state =>
  selector(state, 'Requests.RequestList.Draft.results');
export const total = state =>
  selector(state, 'Requests.RequestList.Draft.total');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const selectedPage = state =>
  selector(state, 'Requests.RequestList.Draft.selectedPage');
export const sorting = state =>
  selector(state, 'Requests.RequestList.Draft.sorting');
export const filters = state =>
  selector(state, 'Requests.RequestList.Draft.filters');
export const bodyDraft = state =>
  selector(state, 'Requests.RequestList.Draft.bodyDraft');
export const getIdDocument = state =>
  selector(state, 'Requests.RequestList.Draft.IdDocument');
