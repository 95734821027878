import { createAction } from 'redux-actions';
export const subject = '[DocumentDetails]';

export const mounted = createAction(`${subject} mounted`);
export const loadOnlyDocument = createAction(`${subject} load only document`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const loadMarkedList = createAction(`${subject}  load marked list`);
export const requestedMarkedList = createAction(
  `${subject} requested marked list`,
);
export const loadedMarkedList = createAction(`${subject} loaded marked list`);
export const updateCodes = createAction(`${subject}  update codes`);
export const loadedCodes = createAction(`${subject} loaded codes`);
export const downloadJSON = createAction(`${subject} download JSON`);
export const mountAct = createAction(`${subject} mount Act`);
export const mountEAES = createAction(`${subject} mount EAES`);
export const loadData = createAction(`${subject} load data`);
export const unsubscribe = createAction(`${subject} unsubscribe`);
export const setTotal = createAction(`${subject} set total`);
export const printDocument = createAction(`${subject} print document`);

export const disaggregation = createAction(`${subject} disaggregation`);
export const disaggregationCodes = createAction(
  `${subject} load disaggregation codes`,
);
