import 'datejs';
import 'moment/locale/ru';

import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Grid, withTheme } from '@material-ui/core';
import { documentTypeNames } from 'src/constants/documentTypes';
import {
  reasonEntry,
  reasonCancellation,
} from 'src/motp_components/_Requests/RequestList/NotificationOfEntry/NotificationOfEntry.constants';
import { Button, themes as BUTTON_THEMES } from '@crpt/react-button';
import { Icon } from '@crpt/react-icon';
import { getDocumentStatusIconByDocStatus } from '../DocumentDetails.utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import * as actions from '../ducks/DocumentDetails.actions';
import { connect } from 'react-redux';
import { Translate } from '../../../common_components/Translate/Translate';

moment.locale('ru');

export const PageWrapper = styled.div`
  min-height: calc(100vh - 190px);
  padding: 34px;
`;

export const CardHeading = styled.div`
  font-size: 24px;
`;

export const ParagraphHeading = styled.div`
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const BorderBottomWrapper = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
`;

export const PrintButton = withTheme(styled.button`
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.13), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 6px 16px;
  font-size: 16px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: SegoeUI;
  font-weight: 400;
  line-height: 1.75;
  border: 0;
  cursor: pointer;
  outline: none;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.palette.primary.main};
    color: white;
  }
`);

class InOutputBasicInfo extends Component {
  onPrint = () => {
    const { printDocument, id, selectedDocument } = this.props;
    printDocument({ id, selectedDocument });
  };

  render() {
    const { id, selectedDocument } = this.props;

    const { documentDate, senderInn, senderName, type } = selectedDocument;
    const docDate = moment(new Date(documentDate)).format('DD.MM.YYYY');
    // moment(new Date(documentDate)); //moment(nano2milli(documentDate));
    // const docDateString = (docDate && docDate.format('DD MMMM YYYY')) || '—';

    const mainInfo = _.get(selectedDocument, 'body.document.mainInfo', null);
    const originalDocInfo = _.get(mainInfo, 'originalDocInfo', null);
    const reason = _.get(mainInfo, 'reason', null);
    const reasonLabel =
      type === 'MTTPS_90'
        ? reasonCancellation.find(item => item.value === reason)
        : reasonEntry.find(item => item.value === reason);

    return (
      <PageWrapper>
        <Grid container justify="space-between">
          <Grid item>
            <CardHeading>
              <Box fontWeight={600}>
                {Translate(documentTypeNames[type])} {Translate('от')} {docDate}{' '}
              </Box>
              ID {id}
            </CardHeading>
          </Grid>

          <Grid item pr={0}>
            <Button theme={BUTTON_THEMES.whiteTheme}>
              <Icon
                type={getDocumentStatusIconByDocStatus(selectedDocument.status)}
                spacingRight={9}
              />
              {Translate(documentStatusesNames[selectedDocument.status])}
            </Button>
            <PrintButton onClick={this.onPrint}>
              {Translate('Печать')}
            </PrintButton>
          </Grid>
        </Grid>

        <Box width="75%">
          <ParagraphHeading>{Translate('Общие данные')}</ParagraphHeading>
          <BorderBottomWrapper>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <Box fontWeight={600}>
                  {Translate('ИИН/БИН/Наименование Организации')}
                  {type !== 'MTTPS_90' && (
                    <React.Fragment>
                      , <br />
                      {Translate('осуществляющей ввод в оборот')}
                    </React.Fragment>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                {senderInn + ', ' + senderName}
              </Grid>
            </Grid>
          </BorderBottomWrapper>

          <BorderBottomWrapper>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <Box fontWeight={600}>
                  {type === 'MTTPS_90'
                    ? Translate('Причина выбытия')
                    : Translate('Причина')}
                </Box>
              </Grid>
              <Grid item xs={6}>
                {Translate(_.get(reasonLabel, 'label', '-'))}
              </Grid>
            </Grid>
          </BorderBottomWrapper>

          <ParagraphHeading>
            {Translate('Данные документа основания')}
          </ParagraphHeading>

          <BorderBottomWrapper>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <Box fontWeight={600}>
                  {Translate('Наименование документа основания')}
                </Box>
              </Grid>
              <Grid item xs={6}>
                {_.get(originalDocInfo, 'docName', '-')}
              </Grid>
            </Grid>
          </BorderBottomWrapper>

          <BorderBottomWrapper>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <Box fontWeight={600}>
                  {Translate('Номер документа основания')}
                </Box>
              </Grid>
              <Grid item xs={6}>
                {_.get(originalDocInfo, 'docNumber', '-')}
              </Grid>
            </Grid>
          </BorderBottomWrapper>
          <BorderBottomWrapper>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <Box fontWeight={600}>
                  {Translate('Дата документа основания')}
                </Box>
              </Grid>
              <Grid item xs={6}>
                {_.get(originalDocInfo, 'docDate', '-')}
              </Grid>
            </Grid>
          </BorderBottomWrapper>
        </Box>
      </PageWrapper>
    );
  }
}

InOutputBasicInfo.propTypes = {};

const mapState = state => ({});
const mapDispatch = dispatch => ({
  printDocument: data => dispatch(actions.printDocument(data)),
});

export default connect(mapState, mapDispatch)(InOutputBasicInfo);
