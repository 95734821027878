import { Toast } from '../../utils/Toast';
import { Translate } from '../Translate/Translate';

export class EimzoService {
  static webSocket;
  static callback;
  static webSocketUrl = 'ws://127.0.0.1:64646/service/cryptapi';
  static EIMZO_MAJOR = 3;
  static EIMZO_MINOR = 37;

  static API_KEYS = [
    'localhost',
    '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
    '127.0.0.1',
    'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
    'null',
    'E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D',
    'dls.yt.uz',
    'EDC1D4AB5B02066FB3FEB9382DE6A7F8CBD095E46474B07568BC44C8DAE27B3893E75B79280EA82A38AD42D10EA0D600E6CE7E89D1629221E4363E2D78650516',
    'asllikbelgisi.uz',
    '087D1F465DFFBFC3C05809F4A31A9AE95144DC1355455A63B8A1FCC9FC517726D91938F1D2C0D5F39643AEDF7762608BBABF481F6FE0C6D8A15C4E7461B72870',
    'goods.asllikbelgisi.uz',
    '4168EB17CB7FA605EC675C958BE89A5631C188CC9DC04FB3883CA9BE9D7F24BD9136FCDA2C7C33BF7B7FAD0219FD6F454357054AFADD8FBD567868C37703A594',
    'aslbelgisi.uz',
    'D619B1B8BEC0AAC90A00AE788703A86334AB2F9E7D7732279A0DDF499440989A233316DC5BB3631A557A4B29153C91D9E78E0BED8A73FE487F16DF8A9E84E7A3',
    'goods.aslbelgisi.uz',
    'C9DC8F6CB11447457535AD1946C3C3237B903575B2625D6054B51B2C69F899336E4DAF1C7F4F8ED092917F9C9CEB8D234A7686BC3A6F1B357B9D48C8ECC2CA2A',
  ];

  static enumerateCertificates(name, plugin, args) {
    return new Promise(resolve => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = result => resolve(result);
        const data = { plugin, name };
        if (args) {
          data.arguments = args;
        }
        EimzoService.webSocket.send(JSON.stringify(data));
      }
    });
  }

  static getVersion() {
    return new Promise(resolve => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = result => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'version',
          }),
        );
      }
    });
  }

  static installApiKeys() {
    return new Promise(resolve => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = result => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            arguments: EimzoService.API_KEYS,
            name: 'apikey',
          }),
        );
      }
    });
  }

  static isConnectionAlive() {
    return EimzoService.webSocket.readyState === 1;
  }

  static isNotAvailable() {
    return (
      EimzoService.webSocket === undefined ||
      EimzoService.webSocket.readyState === 0 ||
      EimzoService.webSocket.readyState === 2 ||
      EimzoService.webSocket.readyState === 3
    );
  }

  static isSystemMessage(message) {
    // eslint-disable-next-line no-prototype-builtins
    return message.result && message.result.hasOwnProperty('version');
  }

  static loadKey(itemObject) {
    const vo = itemObject;
    const args =
      vo.type === 'certkey'
        ? [vo.disk, vo.path, vo.certName, vo.serialNumber]
        : vo.type === 'pfx'
        ? [vo.disk, vo.path, vo.certName, vo.alias]
        : [vo.cardUID];

    return new Promise(resolve => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = result => resolve(result);

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'load_key',
            plugin: vo.type,
            arguments: args,
          }),
        );
      }
    });
  }

  static sign(id, data, isConvertered = false) {
    return new Promise(resolve => {
      if (EimzoService.isConnectionAlive()) {
        EimzoService.callback = result => resolve(result);
        const signData = isConvertered ? data : btoa(data);
        const args = [signData, id, 'no'];

        EimzoService.webSocket.send(
          JSON.stringify({
            name: 'create_pkcs7',
            plugin: 'pkcs7',
            arguments: args,
          }),
        );
      }
    });
  }

  static async getTimestampSign(pkcs7_64) {
    const result = await fetch(`${window.location.origin}/api/v3/eimzo/frontend/timestamp/pkcs7`, {
      method: 'post',
      body: pkcs7_64,
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then((data) => data.json())
      .then((data) => data)
      .catch((e) => console.error(e));

    if (result) {
      return result;
    }
  }

  static init() {
    return new Promise((resolve, reject) => {
      EimzoService.webSocket = new WebSocket(EimzoService.webSocketUrl);

      EimzoService.webSocket.onopen = () =>
        EimzoService.getVersion().then(() =>
          EimzoService.installApiKeys().then(() => resolve()),
        );

      EimzoService.webSocket.onerror = () => {
        if (EimzoService.isNotAvailable()) {
          Toast.showError({ content: Translate('E-IMZO не доступен') });
          reject();
        }
      };

      EimzoService.webSocket.onmessage = event => {
        const response = JSON.parse(event.data);
        if (response != null && !EimzoService.isSystemMessage(response)) {
          if (response.minor && response.major) {
            const { minor, major } = response;
            const newVersion =
              EimzoService.EIMZO_MAJOR * 100 + EimzoService.EIMZO_MINOR;
            const installedVersion = parseInt(major) * 100 + parseInt(minor);

            if (installedVersion < newVersion) {
              Toast.showError({
                content: Translate(
                  'ВНИМАНИЕ!!! Установите новую версию приложения E-IMZO.',
                ),
              });
              EimzoService.close();
              reject();
            }
          }
          if (response.success) {
            const result = {
              ...response,
            };
            EimzoService.callback && EimzoService.callback(result);
          } else {
            Toast.showError({ content: response.reason });
            EimzoService.close();
            reject();
          }
        }
      };
    });
  }

  static close() {
    EimzoService.webSocket.close();
  }
}
