import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationRU from './RU.json';
import translationKZ from './KZ.json';
import translationEN from './EN.json';
import translationEN_UZ from './EN_UZ.json';
import translationUZ from './UZ.json';
import { getCookie, setLanguage } from 'src/utils/cookieHelpers';
import { countryCodeFlags } from '../constants';

const language = getCookie('language') || 'RU';
setLanguage(language);

const resources = {
  RU: {
    translation: translationRU,
  },
  KZ: {
    translation: translationKZ,
  },
  UZ: {
    translation: translationUZ,
  },
  EN: {
    translation: countryCodeFlags.isUZ ? translationEN_UZ : translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: 'RU',
  keySeparator: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
