import styled from 'styled-components';
import { get } from 'lodash';

const Action = styled.div`
  box-shadow: ${props => get(props, 'theme.TableFilter.buttonBar.button.boxShadow')};
  border-top-left-radius: ${props => get(props, 'theme.TableFilter.buttonBar.button.borderTopLeftRadius')};
  border-top-right-radius: ${props => get(props, 'theme.TableFilter.buttonBar.button.borderTopRightRadius')};
  border-bottom-left-radius: ${props => get(props, 'theme.TableFilter.buttonBar.button.borderBottomLeftRadius')};
  border-bottom-right-radius: ${props => get(props, 'theme.TableFilter.buttonBar.button.borderBottomRightRadius')};
  margin-right: ${props => get(props, 'theme.TableFilter.buttonBar.button.marginRight')};
  margin-left: ${props => get(props, 'theme.TableFilter.buttonBar.button.marginLeft')};
`;

export default Action;
