import React from 'react';
import * as Icons from '@crpt-ui/icons';
import { iconStyle, participantExceptions } from './MenuApps.constants';
import { Translate } from '../../Translate/Translate';
import { getToken } from '../../../utils/userUtils';
import { b64DecodeUnicode } from '../../../utils/base64';
import { canSeeRMT, canSeeSUN } from '../../../projects/motp/permissions';
import { PARTICIPANT_TYPES } from '../../../constants';


export const getItems = (props) => {
  const { productGroupsAndRoles, isUserOGV, isOperator, fetchAuthenticateSun } = props
  const { transferToRmt, inn, fetchElkCode, fetchSuzSignIn, countryCode } = props

  const fullToken = getToken();
  const token = JSON.parse(b64DecodeUnicode(fullToken.split('.')[1]));
  const authorities = token.authorities || [];

  const haveLocalSuz = Boolean(
    participantExceptions.find(participant => participant.inn === inn),
  );

  const goToIcom = () => {
    window.open(`${window.env.REACT_APP_ICOM_URL}/#/orders`, "_blank")
  }

  const goToRmt = () => {
    const isModerator = authorities.includes('RMT-PRODUCT-CHECK.WRITE') && authorities.includes('RMT-PRODUCT-CHECK.READ')
    const isSuperModerator = authorities.includes('RMT-PRODUCT-CHECK.ADMINISTRATION') && authorities.includes('RMT-PRODUCT-CHECK.READ')
    if (isModerator || isSuperModerator) {
      window.open(`${window.env.REACT_APP_RMT_URL}/tasks`, "_blank")
    } else {
      window.open(`${window.env.REACT_APP_RMT_URL}/products`, "_blank")
    }  }

  const suzProductGroup = (window.env.SUZ_PRODUCT_GROUPS || "").split(",");
  const availableGroups = productGroupsAndRoles
    .filter(pg=> pg.name === "appliances" ? pg : pg.types.includes("PRODUCER") || pg.types.includes("IMPORTER"))
    .filter(pg => pg.status === '5' || pg.status === '4')
    .map(pg=> pg.name)
  const suzProductGroupInclude = suzProductGroup.filter((item) => availableGroups.indexOf(item) !== -1)
  const suzProductGroupNotInclude = availableGroups.filter((item) => suzProductGroup.indexOf(item) === -1)

  return [
    // {
    //   key: 'elk',
    //   label: Translate('Маркировка товаров'),
    //   icon: (
    //     <div style={iconStyle}>
    //       <Icons.Elk />
    //     </div>
    //   ),
    //   component: 'a',
    //   href: window.env.REACT_APP_ELK_URL,
    // },
    {
      key: 'nationalCatalog',
      label: Translate('Национальный каталог'),
      icon: (
        <div style={iconStyle}>
          <Icons.Catalog />
        </div>
      ),
      onClick: fetchElkCode,
    },
    // {
    //   key: 'suz',
    //   label: Translate('Станция управления заказами'),
    //   icon: (
    //     <div style={iconStyle}>
    //       <Icons.Suz />
    //     </div>
    //   ),
    //   onClick: fetchSuzSignIn,
    // },
    {
      icon: (
        <div style={iconStyle}>
          <Icons.Suz />
        </div>
      ),
      id: 'icom',
      key: 'icom',
      label: Translate('Заказ кодов'),
      onClick: goToIcom,
    },
    {
      icon: (
        <div style={iconStyle}>
          <Icons.Suz />
        </div>
      ),
      id: 'sun',
      key: 'sun',
      label: Translate('Общественный контроль'),
      onClick: fetchAuthenticateSun,
    },
    {
      icon: (
          <div style={iconStyle}>
            <Icons.Catalog />
          </div>
      ),
      id: 'rmt',
      key: 'rmt',
      label: Translate('Реестр маркированных товаров'),
      onClick: goToRmt
    }
    // {
    //   key: 'support',
    //   label: 'Техническая поддержка',
    //   icon: (
    //     <div style={iconStyle}>
    //       <Icons.Support />
    //     </div>
    //   ),
    //   component: 'a',
    //   href: window.env.REACT_APP_ELK_URL + '/support',
    // },
  ].filter(item => {
    if ((isUserOGV || isOperator) && (item.id === 'icom' || item.id === 'suz' || item.id === 'nationalCatalog')) return false
    if (item.key === 'suz') {
      if (!window.env.REACT_APP_SUZ_URL) return false
      if (countryCode !== "UZ") return haveLocalSuz ? item.key !== 'suz' : true;
      // return isUserOGV ? false : suzProductGroupInclude.length > 0
      return false
    }
    if (item.key === 'nationalCatalog' && countryCode === "UZ") {
      if (!window.env.REACT_APP_NATIONAL_CATALOG_URL) return false
      // return !isUserOGV
      return false
    }
    if (item.id === 'rmt') {
      if (!window.env.REACT_APP_RMT_URL) return false
      if (countryCode === "KZ") return false
      return canSeeRMT(authorities)
    }
    if (item.key === 'sun') {
      if (!window.env.REACT_APP_SUN_FRONTEND_URL) return false
      if (countryCode === "KZ") return false;
      return canSeeSUN(authorities)
    }
    if (item.key === 'icom') {
      if (!window.env.REACT_APP_ICOM_URL) return false
      if (countryCode !== "UZ") return false
      return authorities.includes(PARTICIPANT_TYPES.ROLE_ORG_TRADE_PARTICIPANT) && availableGroups.length > 0
    }
    return true
  });
};
