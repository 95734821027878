import { handleAction } from 'redux-actions';
import * as actions from './App.actions';
import { config as currentConfig } from '../../App.config';
import { innerMerge } from '../../utils';
import { translate as defaultTranslate } from '../../translate';


const config = handleAction(
  actions.loadConfig,
  (state, { payload }) => payload || {},
  currentConfig,
);


const translate = handleAction(
  actions.loadTranslate,
  (state, { payload }) => payload || {},
  innerMerge(defaultTranslate.json, currentConfig.translate.RU.json)
);

const preheader = handleAction(
  actions.setPreheader,
  (state, { payload }) => payload || null,
  null
);

export default {
  config,
  translate,
  preheader,
};
