import { authInstance } from './authInstance';
import axios from 'axios';
// TODO: 02.08.2018 АПИ для данного сервиса не реализован, /api/v3/auth/users/ нету таких брекпоинтов есть /api/v3/auth/user/ без S на конце, разобраться с методами.

export class ProfileService {
  static getCurrentUser() {
    return authInstance
      .request({
        url: '/api/v3/auth/company/user',
        method: 'GET',
      })
      .then(answer => {
        if (!answer.data) {
          console.error('server route private/v2/profile/user not working yet');
        }
        return answer;
      });
  }

  static removeUserById(id) {
    return authInstance.request({
      method: 'PUT',
      url: `/api/v3/facade/user/remove/${id}`,
    });
  }

  static getCompanyProfile() {
    return authInstance.request({
      url: '/api/v3/facade/profile/company2',
      method: 'GET',
    });
  }

  static getCompanyProfile2() {
    return authInstance.request({
      url: '/api/v3/facade/profile/company2',
      method: 'GET',
    });
  }

  static getCompanyBalance() {
    return authInstance.request({
      url: `/api/v3/facade/product-groups/balance/all`,
      method: 'GET',
    });
  }

  static getProductGroups() {
    return authInstance.request({
      url: `/api/v3/org/api/product-groups/search?limit=15`,
      method: 'GET',
    });
  }

  static updateCompanyProfile(data, token) {
    console.log('updateCompanyProfile', data);

    const headers = token
      ? {
        Authorization: `Bearer ${token}`,
      }
      : {};

    return authInstance.request({
      url: '/api/v3/facade/profile/company2',
      method: 'PUT',
      data,
      headers,
    });
  }

  static getActiveUsers() {
    return authInstance.request({
      url: '/api/v3/facade/user/list/active',
    });
  }

  static getRemovedUsers() {
    return authInstance.request({
      url: '/api/v3/facade/user/list/removed',
    });
  }

  static getUserProfile(id) {
    return authInstance.request({
      url: `/api/v3/auth/company/users/${id}`,
      method: 'GET',
    });
  }

  static getParticipantImage(id) {
    // if (id) {
    //   return authInstance.request({
    //     url: `/private-office-api/private/v2/participants/${id}/image`,
    //     method: 'GET',
    //     timeout: 5000,
    //     responseType: 'arraybuffer',
    //   });
    // }
    return Promise.resolve();
  }

  static updateUserProfile(data) {
    console.log('updateUserProfile', data);
    return authInstance.request({
      url: `/api/v3/auth/user/`,
      method: 'PUT',
      data,
    });
  }

  static updateUserProfileUsingTemporarySolution(data, isNew) {
    return authInstance.request({
      url: '/api/v3/auth/front/user/',
      method: isNew ? 'POST' : 'PUT',
      data: {
        ...data,
        roles: data.roles,
      },
    });
  }

  static saveNewUserWithLk(data) {
    return authInstance.request({
      method: 'POST',
      data,
      url: '/api/v3/lk/registration/app-user/add/send',
    });
  }

  static getEdoOperators(input) {
    // return Promise.resolve({
    //   data: [
    //     { id: 123, name: 'Тест', fullName: 'Тестовый ЭДО', order: 0 },
    //     { id: 124, name: 'Тест 2', fullName: 'Тестовый ЭДО 2', order: 1 },
    //     { id: 125, name: 'Тест 3', fullName: 'Тестовый ЭДО 3', order: 2 },
    //     { id: 126, name: 'Тест 4', fullName: 'Тестовый ЭДО 5', order: 3 },
    //     { id: 127, name: 'Тест 5', fullName: 'Тестовый ЭДО 6', order: 4 },
    //     { id: 128, name: 'Тест 6', fullName: 'Тестовый ЭДО 7', order: 5 },
    //     { id: 129, name: 'Тест 7', fullName: 'Тестовый ЭДО 8', order: 6 },
    //   ],
    // });

    return axios.request({
      url: '/private-office-api/private/v2/profile/filters/edos',
      method: 'GET',
      params: {
        input,
      },
    });
  }

  static getOfdOperators(input) {
    // return Promise.resolve({
    //   data: [
    //     { id: 123456, name: 'Тест', fullName: 'Тестовый ОФД', order: 0 },
    //     { id: 123457, name: 'Тест 2', fullName: 'Тестовый ОФД 2', order: 1 },
    //     { id: 123458, name: 'Тест 3', fullName: 'Тестовый ОФД 3', order: 2 },
    //   ],
    // });

    return axios.request({
      url: '/private-office-api/private/v2/profile/filters/ofds',
      method: 'GET',
      params: {
        input,
      },
    });
  }

  static getRoleInfo(id, roleType) {
    return authInstance.request({
      url: `/api/v3/auth/role/${id}`,
      method: 'GET',
      params: { roleType },
    });
  }

  static getRolePerms(id, roleType) {
    return authInstance.request({
      url: `/api/v3/auth/role/${id}/permissions`,
      method: 'GET',
      params: { roleType },
    });
  }

  static getRoleList(roleType) {
    return authInstance.request({
      url: '/api/v3/auth/role/list',
      method: 'GET',
      params: { roleType },
    });
  }

  static getPermissionList() {
    return authInstance.request({
      url: '/api/v3/auth/permission/list',
      method: 'GET',
    });
  }

  static changeUserStatus(id, status) {
    return authInstance.request({
      url: `/private-office-api/private/v2/profile/users/${id}/status?status=${status}`,
      method: 'PUT',
    });
  }

  static async getUserRoleListWithPerms() {
    return new Promise(async (resolve, reject) => {
      try {
        const roleList = [];
        const roleListAnswer = await ProfileService.getRoleList('USER');
        const permListAnswer = await ProfileService.getPermissionList();
        for (let i = 0; i < roleListAnswer.data.length; i++) {
          let perm = roleListAnswer.data[i];
          const permIds = await ProfileService.getRolePerms(perm.id, 'USER');
          const roleObj = {
            ...perm,
            perms: permIds.data.map(id =>
              permListAnswer.data.find(perm => perm.id === id),
            ),
          };
          roleList.push(roleObj);
        }
        resolve({ roles: roleList, perms: permListAnswer.data });
      } catch (e) {
        reject(e);
      }
    });
  }

  static uploadImage(params) {
    const formData = new FormData();
    formData.append('image', params.image);
    return authInstance({
      url: `/private-office-api/private/v2/participants/${
        params.id ? params.id : 'me'
        }/image`,
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
