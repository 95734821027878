import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, groupBy } from 'lodash';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ContentWrapper } from '../../common/styled';
import { TilesWrapper, Header, FlexWrapper } from './styled';
import UzProductItem from './ProductItem/UzProductItem';
import 'datejs';
import { Translate } from '../../../common_components/Translate/Translate';

const UzProductList = ({ selectedDocument = {} }) => {
  const productList = useMemo(() => {
    const products = get(selectedDocument, 'items', [])
      .map(product => product.products)
      .flat();

    let uniqueProducts = [];
    let uniqQtinSum = [];

    for (let i = 0; i < products.length; i++) {
      if (!uniqQtinSum.includes(i)) {
        const count = products[i].iCodes ? products[i].iCodes.length : 0;
        let temporary = {
          ...products[i],
          count,
          unitsPriceSum: Number(count * products[i].unitPrice).toFixed(2),
        };

        for (let j = i + 1; j < products.length; j++) {
          if (
            !uniqQtinSum.includes(j) &&
            temporary.gtin == products[j].gtin &&
            temporary.unitPrice == products[j].unitPrice
          ) {
            uniqQtinSum.push(j);

            const countJ = products[j].iCodes ? products[j].iCodes.length : 0;

            temporary = {
              ...temporary,
              count: temporary.count + countJ,
              unitsPriceSum: (
                Number(temporary.unitsPriceSum) +
                Number(countJ * products[j].unitPrice)
              ).toFixed(2),
            };
          }
        }

        uniqueProducts.push(temporary);
      }
    }

    return uniqueProducts.map((product, i) => {
      return <UzProductItem product={product} key={i} />;
    });
  }, [selectedDocument]);

  return (
    <ContentWrapper>
      <FlexWrapper>
        <Header>{Translate('Товар')}</Header>
        <TilesWrapper>{productList}</TilesWrapper>
      </FlexWrapper>
    </ContentWrapper>
  );
};

UzProductList.propTypes = {
  selectedDocument: PropTypes.shape({
    items: PropTypes.arrayOf([
      PropTypes.shape({
        productName: PropTypes.string,
        vat: PropTypes.string,
        unitPrice: PropTypes.string,
      }),
    ]),
  }),
};

export default withTheme(withRouter(UzProductList));
