import { actionTypes } from '../../../constants';


export function results(state = [], action = {}) {
  switch (action.type) {
    case actionTypes.PARTICIPANT_DETAILS.WAREHOUSE_LOADED:
      return action.value.results;
    default:
      return state;
  }
}
