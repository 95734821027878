import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { Translate } from '../../../../common_components/Translate/Translate';

export const breadcrumbs = [
  {
    href: '/documents/list',
    title: Translate('Документы'),
    id: 1,
  },
  {
    title: Translate('Акт приема-передачи товаров'),
    id: 2,
  },
];

export const cisKey = 'cis';
export const goodsKey = 'products';
export const codesMark = 'km';
export const group = 'group';
export const invalidCisKey = 'invalidCis';

export const tabsKZ = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
  { id: 2, label: Translate('Шаг 3. Итоги') },
];

export const tabsKG = [
  { id: 0, label: Translate('Шаг 1. Общая информация') },
  { id: 1, label: Translate('Шаг 2. Товары') },
  { id: 2, label: Translate('Шаг 3. Коды') },
];

export const steps = {
  info: 0,
  goods: 1,
  cis: 2,
};

export const documentNameOptions = [
  {
    label: Translate('Договор (контракт)'),
    value: Translate('Договор (контракт)'),
  },
  {
    label: Translate('Транспортная накладная'),
    value: Translate('Транспортная накладная'),
  },
  {
    label: Translate('Счет-фактура'),
    value: Translate('Счет-фактура'),
  },
];

export const operationTypes = [
  { value: 'realisation', label: Translate('Реализация'), id: 1 },
  { value: 'trade', label: Translate('Комиссионная/Агентская торговля'), id: 2 },
];

export const contentBodyConfirm = (
  <div>
    {Translate('Вы действительно хотите отменить отправку?')}
    <br />
    {Translate('Все введенные данные будут потеряны')}
  </div>
);

export const contentBodyEditGoods = (
  <div>
    {Translate('После добавления новых кодов раздел Итоги будет пустым (его необходимо будет пересчитать заново).')}
    <br />
    {Translate('Подтвердить действие?')}
  </div>
);

export const contentBodyDeleteGoods = (
  <div>
    {Translate('После удаления кодов раздел Итоги будет пустым (его необходимо будет пересчитать заново).')}
    <br />
    {Translate('Подтвердить действие?')}
  </div>
);
