import React, { useMemo } from 'react';
import {
  Input,
  SingleSelect,
  RangePicker,
  ParticipantSelect,
} from '../../../../common_components/Form/Fields/Fields';
import UserNameSelect from '../../../../common_components/Form/Fields/Select/UserNameSelect';
import { documentTypeValues, documentTypeValuesUZ } from './Draft.constants';
import { Translate } from '../../../../common_components/Translate/Translate';
import { useTranslation } from 'react-i18next';

const DelegationListFilters = ({ countryCode }) => {
  const { t } = useTranslation();
  const values = useMemo(
    () => (countryCode === 'KZ' ? documentTypeValues : documentTypeValuesUZ)
      .map((item) => ({ ...item, title: t(item.title)})),
    [countryCode],
  );
  return (
    <React.Fragment>
      <Input name="order_number" placeholder={Translate('Номер документа')} />
      <RangePicker
        name="register_date_"
        leftPlaceholder={Translate('Дата регистрации от')}
        rightPlaceholder={Translate('Дата регистрации до')}
        placeholder={Translate('Дата регистрации')}
      />
      <RangePicker
        name="date_saved_"
        leftPlaceholder={Translate('Дата сохранения от')}
        rightPlaceholder={Translate('Дата сохранения до')}
        placeholder={Translate('Дата сохранения')}
      />
      <SingleSelect
        name="document_types"
        placeholder={Translate('Тип документа')}
        values={values}
      />
      {/*<UserNameSelect name="userId" placeholder="Имя пользователя" />*/}
      {/*<ParticipantSelect name="userId" valueKey="inn" placeholder="Имя пользователя" />*/}
    </React.Fragment>
  );
};

DelegationListFilters.defaultProps = {
  countryCode: 'KZ',
};

export default DelegationListFilters;
