import React, { useMemo } from 'react';
import { CrossborderWrap } from '../EasCrossborder/EasCrossborder.styles';
import List from '../../../common_components/List/List';
import { columns } from '../CisRegistration/constants';
import { HeadingId } from '../../Details/styled';
import { AicGoodsAic, AicGoodsTitle, TableRoot } from './AicGoods.styles';
import { Link } from 'react-router-dom';
import moment from 'moment';

const AicGoods = ({ selectedDocument }) => {
  const list = useMemo(() =>
    selectedDocument
      ? selectedDocument.cises.map((item, idx) => ({ cis: item, idx: idx + 1 }))
      : [],
    [selectedDocument]);
  const { documentDate, documentId, aic } = selectedDocument;

  return (
    <CrossborderWrap>
      <AicGoodsTitle>Формирование АИК <span>от {moment(documentDate).format('DD.MM.YYYY')}</span></AicGoodsTitle>
      <HeadingId grey>ID {documentId}</HeadingId>
      <AicGoodsAic>
        АИК <Link target="_blank" to={`/cis/list/${aic}`}>{aic}</Link>
      </AicGoodsAic>
      <TableRoot>
        <List
          defaultPageSize={20}
          data={list}
          columns={columns}
          showPagination={list.length > 20}
        />
      </TableRoot>
    </CrossborderWrap>
  );
};

export default AicGoods;
