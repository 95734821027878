export const Colors = {
  countRealized: '#4510C6',
  countWrittenOff: '#6C72FF',
  countCirculated: '#81CAFF',
};

export const COLUMN_TYPES = {
  DATE: 'date',
  CIS: 'cis',
  PRODUCT: 'product',
  PARTICIPANT: 'participant',
  DOCUMENT: 'document',
};

export const FORMAT = 'DD.MM.YYYY';

export const WIDTHS = {
  [COLUMN_TYPES.DATE]: 105,
  [COLUMN_TYPES.CIS]: 270,
  [COLUMN_TYPES.PRODUCT]: 270,
  [COLUMN_TYPES.PARTICIPANT]: 270,
  [COLUMN_TYPES.DOCUMENT]: 270
};

export const NO_OPTION_NEEDED_COLUMNS = [COLUMN_TYPES.DATE];

const RETURN_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Возврат',
      width: 105,
      fields: {
        value: 'returnDate',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Покупатель',
      fields: {
        value: 'buyerName',
        inn: 'buyerInn',
      },
    },
    {
      type: COLUMN_TYPES.DOCUMENT,
      title: 'Основание',
      fields: {
        value: 'documentId',
        type: 'documentType',
        date: 'emissionDate',
        status: 'status',
        format: 'DD.MM.YYYY',
      },
    },
  ],
};

const SHORT_RETURN_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Возврат',
      width: 105,
      fields: {
        value: 'returnDate',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
    {
      type: COLUMN_TYPES.DOCUMENT,
      title: 'Основание',
      fields: {
        value: 'documentId',
        type: 'documentType',
        date: 'emissionDate',
        status: 'status',
        format: 'DD.MM.YYYY',
      },
    },
  ],
};

const DEFECT_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Дата',
      width: 105,
      fields: {
        value: 'defectDate',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
    {
      type: COLUMN_TYPES.DOCUMENT,
      title: 'Основание',
      fields: {
        value: 'documentId',
        type: 'documentType',
        date: 'emissionDate',
        status: 'status',
        format: 'DD.MM.YYYY',
      },
    },
  ],
};

const OUT_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Вывод',
      width: 105,
      fields: {
        value: 'emissionDate',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
  ],
};

const DEFAULT_CASE_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Продажа',
      width: 105,
      fields: {
        value: 'date',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.DOCUMENT,
      title: 'Основание',
      fields: {
        value: 'documentId',
        type: 'documentType',
        date: 'emissionDate',
        status: 'status',
        format: 'DD.MM.YYYY',
      },
    },
  ],
};

const DOUBLE_SELL_INFO = {
  scheme: [
    {
      type: COLUMN_TYPES.DATE,
      title: 'Продажа',
      width: 105,
      fields: {
        value: 'sellDate',
        format: 'DD.MM.YYYY',
      },
    },
    {
      type: COLUMN_TYPES.CIS,
      title: 'Код',
      width: 270,
      fields: {
        value: 'cis',
      },
    },
    {
      type: COLUMN_TYPES.PRODUCT,
      title: 'Товар',
      width: 270,
      fields: {
        value: 'productName',
        packageType: 'packageType',
        gtin: 'gtin',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Производитель',
      width: 270,
      fields: {
        value: 'ownerName',
        inn: 'ownerInn',
      },
    },
    {
      type: COLUMN_TYPES.PARTICIPANT,
      title: 'Покупатель',
      fields: {
        value: 'buyerName',
        inn: 'buyerInn',
      },
    },
    {
      type: COLUMN_TYPES.DOCUMENT,
      title: 'Основание',
      fields: {
        value: 'documentId',
        type: 'documentType',
        date: 'emissionDate',
        status: 'status',
        format: 'DD.MM.YYYY',
      },
    },
  ]
};

export const scenariosInfo = {
  '14': DOUBLE_SELL_INFO,
  '13': {
    scheme: [
      {
        type: COLUMN_TYPES.DATE,
        title: 'Дата',
        width: 105,
        fields: {
          value: 'date',
          format: 'DD.MM.YYYY',
        },
      },
      {
        type: COLUMN_TYPES.CIS,
        title: 'Код был',
        width: 270,
        fields: {
          value: 'cisWas',
        },
      },
      {
        type: COLUMN_TYPES.CIS,
        title: 'Код стал',
        width: 270,
        fields: {
          value: 'cisBecome',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Производитель',
        width: 270,
        fields: {
          value: 'ownerName',
          inn: 'ownerInn',
        },
      },
      {
        type: COLUMN_TYPES.DOCUMENT,
        title: 'Основание',
        fields: {
          value: 'documentId',
          type: 'documentType',
          date: 'emissionDate',
          status: 'status',
          format: 'DD.MM.YYYY',
        },
      },
    ],
  },
  '12': SHORT_RETURN_INFO,
  '11.2': OUT_INFO,
  '11.1': OUT_INFO,
  '10': SHORT_RETURN_INFO,
  '9.2': SHORT_RETURN_INFO,
  '9.1.2': DOUBLE_SELL_INFO,
  '9.1.1': SHORT_RETURN_INFO,
  '8.3': DEFECT_INFO,
  '8.2': DEFECT_INFO,
  '8.1': DEFECT_INFO,
  '7.3': DEFECT_INFO,
  '7.2': DEFECT_INFO,
  '7.1': DEFECT_INFO,
  '6': DEFAULT_CASE_INFO,
  '5': DEFECT_INFO,
  '4.2': RETURN_INFO,
  '4.1': RETURN_INFO,
  '4': DEFAULT_CASE_INFO,
  '3': RETURN_INFO,
  '2.2': {
    scheme: [
      {
        type: COLUMN_TYPES.DATE,
        title: 'Продажа',
        width: 105,
        fields: {
          value: 'sellDate',
          format: 'DD.MM.YYYY',
        },
      },
      {
        type: COLUMN_TYPES.CIS,
        title: 'Код',
        width: 270,
        fields: {
          value: 'cis',
        },
      },
      {
        type: COLUMN_TYPES.PRODUCT,
        title: 'Товар',
        width: 270,
        fields: {
          value: 'productName',
          packageType: 'packageType',
          gtin: 'gtin',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Производитель',
        width: 270,
        fields: {
          value: 'ownerName',
          inn: 'ownerInn',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Покупатель',
        fields: {
          value: 'buyerName',
          inn: 'buyerInn',
        },
      },
      {
        type: COLUMN_TYPES.DOCUMENT,
        title: 'Основание',
        fields: {
          value: 'documentId',
          type: 'documentType',
          date: 'emissionDate',
          status: 'status',
          format: 'DD.MM.YYYY',
        },
      },
    ]
  },
  '2.1': {
    scheme: [
      {
        type: COLUMN_TYPES.DATE,
        title: 'Продажа',
        width: 105,
        fields: {
          value: 'emissionDate',
          format: 'DD.MM.YYYY',
        },
      },
      {
        type: COLUMN_TYPES.CIS,
        title: 'Код',
        width: 270,
        fields: {
          value: 'cis',
        },
      },
      {
        type: COLUMN_TYPES.PRODUCT,
        title: 'Товар',
        width: 270,
        fields: {
          value: 'productName',
          packageType: 'packageType',
          gtin: 'gtin',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Производитель',
        width: 270,
        fields: {
          value: 'ownerName',
          inn: 'ownerInn',
        },
      },
      {
        type: COLUMN_TYPES.DOCUMENT,
        title: 'Основание',
        fields: {
          value: 'documentId',
          type: 'documentType',
          date: 'emissionDate',
          status: 'status',
          format: 'DD.MM.YYYY',
        },
      },
    ]
  },
  '1-2': DEFAULT_CASE_INFO,
  '1.1': {
    scheme: [
      {
        type: COLUMN_TYPES.DATE,
        title: 'Эмиссия',
        width: 105,
        fields: {
          value: 'emissionDate',
          format: 'DD.MM.YYYY',
        },
      },
      {
        type: COLUMN_TYPES.DATE,
        title: 'Продажа',
        width: 105,
        fields: {
          value: 'sellDate',
          format: 'DD.MM.YYYY',
        },
      },
      {
        type: COLUMN_TYPES.CIS,
        title: 'Код',
        width: 270,
        fields: {
          value: 'cis',
        },
      },
      {
        type: COLUMN_TYPES.PRODUCT,
        title: 'Товар',
        width: 270,
        fields: {
          value: 'productName',
          packageType: 'packageType',
          gtin: 'gtin',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Производитель',
        width: 270,
        fields: {
          value: 'ownerName',
          inn: 'ownerInn',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Покупатель',
        fields: {
          value: 'buyerName',
          inn: 'buyerInn',
        },
      },
      {
        type: COLUMN_TYPES.PARTICIPANT,
        title: 'Текущий владелец',
        fields: {
          value: 'ownerName',
          inn: 'ownerInn',
        },
      },
      {
        type: COLUMN_TYPES.DOCUMENT,
        title: 'Основание',
        fields: {
          value: 'documentId',
          type: 'documentType',
          date: 'emissionDate',
          status: 'status',
          format: 'DD.MM.YYYY',
        },
      },
    ],
  },
};
