import React from 'react';

import { useEventCallback } from '@material-ui/core';
import fp from 'lodash/fp';

import { deletingCryptoEnd } from '../../../motp_components/_Requests/RequestList/ActDocument/Reconciliation/Reconciliation.utils';

const GROUP_SEPARATOR_SYMBOL = '';
let asciiCode = '';

const addSingleGS = value =>
  value.endsWith(GROUP_SEPARATOR_SYMBOL)
    ? value
    : `${value}${GROUP_SEPARATOR_SYMBOL}`;

const removeDuplicateGS = value =>
  value
    .split(GROUP_SEPARATOR_SYMBOL)
    .filter(Boolean)
    .join(GROUP_SEPARATOR_SYMBOL);

const removeEmptyLines = value =>
  value
    .split('\n')
    .filter(Boolean)
    .join('\n');

const stopEvent = e => {
  e.stopPropagation();
  e.preventDefault();
};

const handleBlur = e => e.target.focus();

const isChar = key => (key || '').length === 1;

const changeString = string =>
  string
    .split('')
    .map(el => (el.charCodeAt(0) === 29 ? `` : el))
    .join('');

export const ScanFiled = ({ value, onChange, errors }) => {
  const textareaRef = React.useRef();
  const divRef = React.useRef();
  const [isScanInProcess, setScanInProcess] = React.useState(false);
  const [scanFinishTimeout, setScanFinishTimeout] = React.useState();

  const errorList = React.useMemo(
    () =>
      errors
        ? errors
            .substring(errors.indexOf('[') + 1, errors.lastIndexOf(']'))
            .split(', ')
        : null,
    [errors],
  );

  const handleScanFinish = useEventCallback(() => {
    setScanInProcess(false);
    onChange(`${fp.pipe(removeEmptyLines, removeDuplicateGS)(value)}\n`);
    divRef.current.scrollLeft = 0;
    textareaRef.current.scrollLeft = 0;
    console.debug('**** Scanning Finish ****');
  });

  const handleKeyDown = useEventCallback(e => {
    const { key, altKey } = e;
    if (value.length === 0) {
      console.debug('**** Scanning Start ****');
    }
    console.debug(
      'isScanInProcess: ',
      isScanInProcess,
      '| key: ',
      key,
      '| altKey: ',
      altKey,
      '| value: ',
      value,
    );

    if (isScanInProcess) {
      stopEvent(e);
    }

    if (altKey === true) {
      setScanInProcess(true);
    }

    const isAltKey = altKey && key === 'Alt';
    const isSpecialSymbol = !altKey && !isChar(key);
    const isCharSymbol = !altKey && isChar(key);

    // Когда спец символ начинается с alt, в том числе и alt+xxx || alt+xxxx mode, где xxx код аски
    if ((isAltKey || isSpecialSymbol) && asciiCode.length) {
      const asciiSymbol = String.fromCharCode(asciiCode);
      onChange(`${value}${asciiSymbol}`);
      asciiCode = '';
    } else if (altKey && key !== 'Alt') {
      asciiCode = String(asciiCode) + String(key);
    } else if (isCharSymbol && asciiCode.length) {
      const asciiSymbol = String.fromCharCode(asciiCode);
      onChange(`${value}${asciiSymbol}${key}`);
      asciiCode = '';
    } else if (isScanInProcess && isChar(key)) {
      onChange(`${value}${key}`);
    }

    // Когда спец символ начинается с F8 - Mertech, заводские настройки
    if (key === 'F8') {
      onChange(addSingleGS(value));
    }

    if (key === 'Enter') handleScanFinish();

    if (isScanInProcess) {
      clearTimeout(scanFinishTimeout);
      if (key !== 'Enter') {
        setScanFinishTimeout(setTimeout(handleScanFinish, 100));
      }
    }
  });

  const handleTextareaWheel = useEventCallback(e => {
    if (textareaRef.current && divRef.current) {
      divRef.current.scrollTop += e.deltaY;
      divRef.current.scrollLeft += e.deltaX;
      textareaRef.current.scrollTop = divRef.current.scrollTop;
      textareaRef.current.scrollLeft = divRef.current.scrollLeft;
    }
  });

  const handleDivScroll = useEventCallback(e => {
    stopEvent(e);
    if (textareaRef.current && divRef.current) {
      textareaRef.current.scrollTop = divRef.current.scrollTop;
      textareaRef.current.scrollLeft = divRef.current.scrollLeft;
    }
  });

  const handleTextareaScroll = useEventCallback(e => {
    stopEvent(e);
    if (textareaRef.current && divRef.current) {
      divRef.current.scrollTop = textareaRef.current.scrollTop;
      divRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  });

  const handleChange = useEventCallback(e => {
    if (!isScanInProcess) {
      onChange(e.target.value);
    }
  });

  return (
    <div className="scanningContainer">
      <div
        ref={divRef}
        className="scanningContainer__text"
        onScroll={handleDivScroll}
      >
        <textarea
          tabIndex={0}
          autoComplete="off"
          autoCorrect="off"
          autoFocus={true}
          spellCheck={false}
          id="scanField"
          ref={textareaRef}
          className="editable scanning-field"
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
          onScroll={handleTextareaScroll}
          onWheel={handleTextareaWheel}
        />
        {value.split('\n').map((code, codeIndex) => {
          const newValue = errorList
            ? errorList.reduce((acc, item) => {
                return typeof acc === 'string'
                  ? acc.split(' ').map(elStr => {
                      return elStr === item ||
                        (typeof elStr === 'string' &&
                          item ===
                            deletingCryptoEnd(
                              elStr.replace(//g, GROUP_SEPARATOR_SYMBOL),
                            ).join('')) ? (
                        <span style={{ color: 'red' }}>
                          {changeString(elStr)}{' '}
                        </span>
                      ) : (
                        changeString(elStr)
                      );
                    })
                  : acc.map(elStr => {
                      return elStr === item ||
                        (typeof elStr === 'string' &&
                          item ===
                            deletingCryptoEnd(
                              elStr.replace(//g, GROUP_SEPARATOR_SYMBOL),
                            ).join('')) ? (
                        <span style={{ color: 'red' }}>
                          {changeString(elStr)}{' '}
                        </span>
                      ) : (
                        elStr
                      );
                    });
              }, code)
            : changeString(code);
          return (
            <React.Fragment key={`code-${codeIndex}`}>
              {typeof newValue === 'string'
                ? changeString(newValue)
                : newValue.map(el =>
                    typeof el === 'string' ? `${changeString(el)} ` : el,
                  )}
              <br />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
