import { Translate } from '../../common_components/Translate/Translate';

export const tabs = [
  {
    id: 0,
    title: Translate('Документы'),
    href: '/documents/list',
    blockCounty: '',
  },
  {
    id: 1,
    title: Translate('Чеки'),
    href: '/documents/receipts',
    blockCounty: '',
  },
  {
    id: 2,
    title: Translate('Черновики'),
    href: '/documents/draft',
    blockCounty: '',
  },
  {
    id: 3,
    title: Translate('Отгрузка (УПД)'),
    href: '/documents/outgoing/list',
    blockCounty: 'KZ, UZ',
  },
  {
    id: 4,
    title: Translate('Приемка (УПД)'),
    href: '/documents/incoming/list',
    blockCounty: 'KZ, UZ',
  },
  {
    id: 5,
    title: Translate('Исходящие'),
    href: '/documents/outcoming',
    blockCounty: 'UZ',
  },
  {
    id: 6,
    title: Translate('Входящие'),
    href: '/documents/incoming',
    blockCounty: 'UZ',
  },
  {
    id: 7,
    title: Translate('Экспорт'),
    href: '/documents/export',
    blockCounty: 'UZ',
  },
  {
    id: 7,
    title: Translate('Импорт'),
    href: '/documents/import-uz',
    blockCounty: 'KZ',
  },
  {
    id: 8,
    title: Translate('Импорт'),
    href: '/documents/import',
    blockCounty: 'UZ',
  },
];
