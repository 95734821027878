export const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  iconContainer: {
    paddingBottom: '30px',
  },
  titleRow: {
    textAlign: 'center',
    paddingBottom: '20px',
    color: '#63666a',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
  },
  infoRow: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#868686',
  },
};
