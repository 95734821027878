import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import ParticipantName from '../../components/ParticipantName';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';
import * as selectors from '../../selectors/index';


class ParticipantNameBound extends Component {
  render() {
    return (
      <ConnectParts
        component={ParticipantName}
        actions={[actions.ParticipantDetails]}
        selectors={[selectors.ParticipantDetails]}
        {...this.props}
      />
    );
  }
}

export default withRouter(ParticipantNameBound);