import { handleActions } from 'redux-actions';
import * as actions from './IncomeList.actions';

const results = handleActions(
  {
    [actions.mounted]: () => [],
    [actions.loaded]: (state, { payload = {} }) => {
      return payload.items || [];
    },
    [actions.unmounted]: () => [],
  },
  [],
);
const total = handleActions(
  {
    [actions.mounted]: () => 0,
    [actions.loaded]: (state, { payload = {} }) => payload.count !== -1 ? payload.count : 2145678,
    [actions.unmounted]: () => 0,
  },
  0,
);
const last = handleActions(
  {
    [actions.mounted]: () => false,
    [actions.loaded]: (state, { payload = {} }) => !!payload.last || false,
    [actions.unmounted]: () => false,
  },
  false,
);
const offset = handleActions(
  {
    [actions.updateOffset]: (state, { payload }) => payload,
    [actions.unmounted]: () => 0,
  },
  0,
);
const selectedPage = handleActions(
  {
    [actions.updatePage]: (state, { payload }) => payload,
    [actions.unmounted]: () => 1,
  },
  1,
);
const pagination = handleActions(
  {
    [actions.pagination]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {},
);
const filters = handleActions(
  {
    [actions.filters]: (state, { payload = {} }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {},
);
const uploadErrors = handleActions(
  {
    [actions.gotErrors]: (state, { payload }) => payload || [],
    [actions.clearErrors]: () => [],
    [actions.fileUpload]: () => [],
    [actions.unmounted]: () => [],
  },
  [],
);
const defaultSorting = {
  order: 'DESC',
  orderColumn: 'docDate',
};
const sorting = handleActions(
  {
    [actions.setSorting]: (state, { payload }) => payload || defaultSorting,
  },
  defaultSorting,
);
export default {
  results,
  last,
  total,
  selectedPage,
  pagination,
  filters,
  sorting,
  offset,
  uploadErrors,
};
