import React from 'react';
import Table from '@crpt/react-table';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Icon } from '@crpt/react-icon';
import * as actions from '../../../Document/Preview/ducks/Preview.actions';
import * as selectors from '../../../Document/Preview/ducks/Preview.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import {
  TitleTable,
  TableWrap,
  IconWrap,
} from '../../../Document/Preview/Preview.styled';
import * as previewActions from '../../../Document/Preview/ducks/Preview.actions';
import { columnsKZ as cisColumns } from '../../Goods/Goods.constants';
import { columnsKZ as goodsColumns } from '../Cis.constants';
import { goodsKey, cisKey } from '../../ImportThirdCountry.constants';
import { Translate } from '../../../../../../common_components/Translate/Translate';

class TableCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCisOpen: false,
      isGoodsOpen: false,
    };
  }

  toggleCis = () => this.setState({ isCisOpen: !this.state.isCisOpen });
  toggleGoods = () => this.setState({ isGoodsOpen: !this.state.isGoodsOpen });

  render() {
    const { values } = this.props;
    const { isCisOpen, isGoodsOpen } = this.state;
    const goodsData = get(values, goodsKey);
    const cisData = get(values, cisKey);
    return (
      <React.Fragment>
        <TableWrap onClick={this.toggleCis}>
          <TitleTable>
            {Translate('Коды')}{' '}
            {cisData && cisData.length > 0 ? (
              <React.Fragment>
                {cisData.length} {Translate('шт.')}
              </React.Fragment>
            ) : null}
          </TitleTable>
          <IconWrap>
            <Icon
              type={isCisOpen ? 'up-arrow' : 'down-arrow'}
              spacingLeft={6}
            />
          </IconWrap>
        </TableWrap>
        {isCisOpen && (
          <Table
            columns={cisColumns}
            data={cisData}
            minRows={0}
            highlight
            striped
            defaultPageSize={1000}
          />
        )}
        <br />
        <TableWrap onClick={this.toggleGoods}>
          <TitleTable>
            {Translate('Товары')}{' '}
            {goodsData && goodsData.length > 0 ? (
              <React.Fragment>
                {goodsData.length} {Translate('шт.')}
              </React.Fragment>
            ) : null}
          </TitleTable>
          <IconWrap>
            <Icon
              type={isGoodsOpen ? 'up-arrow' : 'down-arrow'}
              spacingLeft={6}
            />
          </IconWrap>
        </TableWrap>
        {isGoodsOpen && (
          <Table
            columns={goodsColumns}
            data={goodsData}
            minRows={0}
            highlight
            striped
            defaultPageSize={1000}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  isOpen: selectors.isOpen(state),
  content: selectors.content(state),
  userInfo: authSelectors.userInfo(state),
});
const mapDispatch = dispatch => ({
  onClose: () => dispatch(actions.close()),
  reset: () => dispatch(actions.setContent({})),
  setPreview: data => dispatch(previewActions.setContent(data)),
});
export default connect(mapState, mapDispatch)(withTheme(TableCard));
