import { get, cloneDeep } from 'lodash';
import { operationTypes } from '../ActDocument/ActDocument.constants';
import moment from 'moment';

const getCommonData = data => {
  const sending_date = get(data, 'data.content.sending_date', '');
  const operation_type_value = get(data, 'data.content.operation_type', '-');
  const find = operationTypes.find(item => item.id === operation_type_value);
  const operation_type = find ? find.label : '-';

  return {
    sending_date: sending_date
      ? moment(sending_date * 1000).format('DD.MM.YYYY')
      : '-',
    origin_date: get(data, 'data.content.origin_date', '-'),
    origin_number: get(data, 'data.content.origin_number', '-'),
    operation_type,
  };
};

const getParticipantData = (
  data,
  path = '',
  seller = 'seller',
  buyer = 'buyer',
) => {
  const seller_name = get(data, `data.content.${path}${seller}.name`, '-');
  const seller_inn = get(data, `data.content.${path}${seller}.inn`, '-');
  const buyer_name = get(data, `data.content.${path}${buyer}.name`, '-');
  const buyer_inn = get(data, `data.content.${path}${buyer}.inn`, '-');
  return {
    seller: `${seller_name}, ИИН/БИН ${seller_inn}`,
    buyer: `${buyer_name}, ИИН/БИН ${buyer_inn}`,
    seller_name,
    seller_inn,
    buyer_name,
    buyer_inn,
  };
};

export const getDiscrepancyData = data => {
  const commonData = getCommonData(data);
  const participantData = getParticipantData(
    data,
    'main_info.',
    'consignee',
    'supplier',
  );
  return {
    id: get(data, 'data.content.correction_doc_id', '-'),
    act_number: get(data, 'data.content.act_number', '-'),
    ...commonData,
    ...participantData,
    initialData: data,
    reconciliation: get(data, 'reconciliation', []),
  };
};

export const getActData = (data, id) => {
  const commonData = getCommonData(data);
  const participantData = getParticipantData(data);
  return {
    id,
    ...commonData,
    ...participantData,
    act_number: get(data, 'data.number', '-'),
    reconciliation: get(data, 'reconciliation', []),
  };
};

export const prepareDiscrepancyBody = data => {
  return {
    doc_id: data.id,
    version: '1.0',
    sending_date: Math.round(new Date() / 1000),
    correction_doc_id: data.id,
    main_info: {
      supplier: {
        name: data.buyer_name,
        inn: data.buyer_inn,
      },
      consignee: {
        name: data.seller_name,
        inn: data.seller_inn,
      },
    },
    cises: data.reconciliation.map(item => ({
      status: item.reconciliationStatus,
      code: item.cis,
    })),
  };
};

export const prepareCisList = (cises, list) => {
  return Object.keys(list).map(key => {
    const item = list[key];
    const code = cises.find(item => item.code === key);
    const reconciliationStatus = code.status;
    return {
      ...item,
      reconciliationStatus,
      name: item.productName,
      cisPackageType: item.packageType,
    };
  });
};

export const addActDataToDiscrepancy = (discrepansy, act) => {
  const content = get(discrepansy, 'content', {});
  content.operation_type = get(act, 'content.operation_type', '');
  content.origin_date = get(act, 'content.origin_date');
  content.origin_number = get(act, 'content.origin_number');
  content.act_number = get(act, 'number');
  const result = cloneDeep(discrepansy);
  result.content = { ...content };
  return result;
};

export const prepareDataForCorrectionAct = (data, wideTotal, wideProducts) => {
  const content = get(data, 'data.content', {});

  const sender_name = get(content, `main_info.consignee.name`, '');
  const sender_inn = get(content, `main_info.consignee.inn`, '');
  const applicant_inn = get(content, `main_info.consignee.inn`, '');

  const buyer_name = get(content, `main_info.supplier.name`, '');
  const buyer_inn = get(content, `main_info.supplier.inn`, '');
  const operation_type = get(content, 'operation_type');
  const origin_date = get(content, 'origin_date');
  const origin_number = get(content, 'origin_number');
  const parent_id = get(content, 'correction_doc_id');
  const isHasPaperDocument = !!origin_date && !!origin_number;

  let origin = {};
  if (isHasPaperDocument) {
    origin = { origin_number, origin_date };
  }

  return {
    applicant_inn,
    sender_name_full: `${sender_name} / ${sender_inn}`,
    sender_name,
    sender_inn,
    operation_type: operation_type === 1 ? 'realisation' : 'trade',
    isHasPaperDocument: isHasPaperDocument,
    ...origin,
    buyer: `${buyer_name} / ${buyer_inn}`,
    buyer_name,
    buyer_inn,
    products: [...wideProducts],
    cis: [...wideTotal],
    parent_id,
    fixed: true,
  };
};
