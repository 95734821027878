import { css } from 'styled-components';

import TableInput from './TableInput';
import TableSelect from './TableSelect';

const { style: inputStyle } = TableInput;
const { style: selectStyle } = TableSelect;

const TablePagination = {
  style: css`
    /* put your styles here... */
  
    input {
      ${inputStyle}
    }

    select {
      ${selectStyle}
    }
  `,
};

export default TablePagination;
