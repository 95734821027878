import { css } from 'styled-components';

const TableCell = {
  style: css`
    overflow-x: hidden;
    flex: 1 0 0px;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    color: #434244;

    font-size: 16px;
    line-height: 19px;
    height: 60px;

    border-right: 1px solid rgba(196, 196, 196, 0.25);
    
    &:first-child {}
    &:last-child {
      border-right: 0;
    }
    
    &.-hidden {
      padding: 0 !important;
      min-width: 0 !important;
      width: 0 !important;
      border:0 !important;
      opacity: 0 !important;
    }
    
  `,
};

export default TableCell;
