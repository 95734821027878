import HeadingWrap from '../BasicInfoFabric/HeadingWrap';
import { Translate } from '../../../common_components/Translate/Translate';
import { Column, Container } from '@crpt/react-page-layout';
import DocumentInfoBlock from './ImportBasicInfo/DocumentInfoBlock';
import { TwoLineLabel } from '@crpt/react-label';
import React, { Fragment } from 'react';
import {
  MARKING_CODES_INFO_TYPES_NAMES,
  MARKING_CODES_INFO_TYPES,
  documentTypeNames,
} from '../../../constants';
import { pgTypesNames } from '../../../constants/productGroups';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import styled from 'styled-components';

const EMISSION_TYPE_VALUES = {
  PRODUCTION: Translate('Производство'),
  IMPORT: Translate('Импорт'),
};

const {
  EMISSION_TYPE,
  EXPIRATION_DATE,
  PRODUCTION_DATE,
  PRODUCTION_SERIAL_NUMBER,
} = MARKING_CODES_INFO_TYPES;

const formatDate = (date, outputFormat = 'DD-MM-yyyy') =>
  moment.utc(date).format(outputFormat);

const getChangedInfo = document => {
  switch (true) {
    case !!document.emissionType:
      return {
        type: EMISSION_TYPE,
        value: EMISSION_TYPE_VALUES[document[EMISSION_TYPE]],
      };
    case !!document.productionDate:
      return {
        type: PRODUCTION_DATE,
        value: formatDate(document[PRODUCTION_DATE]),
      };
    case !!document.expirationDate:
      return {
        type: EXPIRATION_DATE,
        value: formatDate(document[EXPIRATION_DATE]),
      };
    case !!document.productionSerialNumber:
      return {
        type: PRODUCTION_SERIAL_NUMBER,
        value: document[PRODUCTION_SERIAL_NUMBER],
      };
    default:
      return null;
  }
};

const Row = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const CorInfoCmInfo = ({ selectedDocument, id }) => {
  const {
    senderInn,
    productGroup,
    docErrors,
    status,
    documentDate,
  } = selectedDocument;

  const changedInfo = getChangedInfo(selectedDocument);

  return (
    <Fragment>
      <HeadingWrap
        title={documentTypeNames.COR_INFO_CM}
        id={id}
        status={status}
        date={documentDate}
        docType={documentTypeNames.COR_INFO_CM}
        needDocTitle={false}
        bottomId={true}
      />
      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Общие данные')}>
            <TwoLineLabel heading={Translate('ИНН/ПИНФЛ заявителя')}>
              {senderInn}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('Товарная группа')}>
              {Translate(
                productGroup ? pgTypesNames[productGroup.toLowerCase()] : '-',
              )}
            </TwoLineLabel>
            {changedInfo && (
              <TwoLineLabel heading={Translate('Измененные сведения')}>
                <Row>
                  <Row>
                    <Title>{Translate('Тип сведений')}:</Title>
                    <Grid>
                      {Translate(
                        MARKING_CODES_INFO_TYPES_NAMES[changedInfo.type],
                      )}
                    </Grid>
                  </Row>
                  <Row>
                    <Title>{Translate('Значение')}:</Title>
                    <Grid>{changedInfo.value}</Grid>
                  </Row>
                </Row>
              </TwoLineLabel>
            )}
          </DocumentInfoBlock>
          {docErrors && docErrors.length !== 0 && (
            <DocumentInfoBlock title={Translate('Ошибки')}>
              {docErrors.map(item => (
                <p>{item.join(' ')}</p>
              ))}
            </DocumentInfoBlock>
          )}
        </Column>
      </Container>
    </Fragment>
  );
};

export default CorInfoCmInfo;
