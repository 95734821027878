/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, some } from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Icon } from '@crpt/react-icon';
import * as actions from './ducks/RequestList.actions';
import * as listActions from '../../../common_components/List/ducks/List.actions';
import * as selectors from './ducks/RequestList.selectors';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import * as filePreviewActions from '../../../common_components/FilePreview/ducks/FilePreview.actions';
import * as importDocumentActions from './ImportDocument/ducks/ImportDocument.actions';
import * as eaesImportDocumentActions from './EaesImportDocument/ducks/EaesImportDocument.actions';
import * as fiscalDocumentActions from './FiscalDocument/ducks/FiscalDocument.actions';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import RequestListView from './RequestList.view';
import { limit, options, getColumns } from './RequestList.constants';
import RequestListFilters from './RequestListFilters';
import Filters from '../../../common_components/List/Filters/Filters';
import FiltersButton from '../../../common_components/Buttons/FiltersButton';
import {
  prepareFromRange,
  prepareToRange,
} from '../../../common_components/Form/Form.utils';
import { menuOnlyStandQA } from './Document/Document.utils';
import { permissionConfig } from './RequestList.permissions';
import withPermissions from '../../../common_components/hocs/withPermissions';
import { withModal } from 'src/common_components/Modals/hocs/withModal';
import FiscalUploadModalContent from './FiscalDocument/FiscalUploadModalContent';
import { APP_LINKS } from 'src/constants';
import { Translate } from 'src/common_components/Translate/Translate';

const types = {
  IMPORT: 6,
  FISCAL_STORAGE: 11,
  EAES_IMPORT: 12,
};

const createMenuItems = menuOnlyStandQA(
  [
    {
      title: Translate('Акт приема-передачи товаров'),
      link: APP_LINKS.Eaes_act,
      country: ['KZ'],
    },
    {
      title: Translate('Уведомление о ввозе товаров (ЕАЭС)'),
      link: APP_LINKS.Eaes_import,
      country: ['KZ'],
    },
    {
      title: Translate('Уведомление о ввозе товаров (третьи страны)'),
      link: APP_LINKS.ImportThirdCountry,
      country: ['KZ'],
    },
    {
      title: Translate('Уведомление о ввозе товаров (импорт)'),
      link: APP_LINKS.ImportThirdCountryKG,
      country: [],
    },
    {
      title: Translate('Уведомление о вводе в оборот'),
      link: APP_LINKS.NotificationOfEntry,
      country: ['KZ'],
    },
    {
      title: Translate('Уведомление о выводе из оборота'),
      link: APP_LINKS.NotificationOfCancellation,
      country: ['KZ', 'UZ'],
    },
    {
      title: Translate('Уведомление об экспорте в ЕАЭС'),
      link: APP_LINKS.ExportNotificationToTheEaes,
      country: ['KZ'],
    },
    {
      title: Translate('Уведомление о приемке товаров ЕАЭС'),
      link: APP_LINKS.AcceptanceNotificationOfGoodsByTheEAES,
      country: ['KZ'],
    },
    {
      title: Translate('Заявка на регистрацию кодов ИС МДЛП'),
      link: APP_LINKS.CisRegistry,
      country: ['UZ'],
    },
    {
      title: Translate('Формирование АИК'),
      link: APP_LINKS.aik,
      country: ['UZ'],
    },
    {
      title: Translate('Уведомление о товарных остатках (производство)'),
      link: APP_LINKS.inventory,
      country: ['UZ'],
    },
    {
      title: Translate('Уведомление о товарных остатках (опт, розница)'),
      link: APP_LINKS.tradeInventory,
      country: ['UZ'],
    },
    {
      title: Translate('Расформирование АИК'),
      link: APP_LINKS.aik_disaggregation,
      country: ['UZ'],
    },
    {
      title: Translate('Агрегирование'),
      link: APP_LINKS.aggregation,
      country: ['UZ'],
    },
    {
      title: Translate('Корректировка сведений о кодах маркировки'),
      link: APP_LINKS.corInfoCm,
      country: ['UZ'],
    },
  ],
  [],
);

const uploadMenuItems = menuOnlyStandQA(
  [
    {
      type: types.IMPORT,
      title: Translate('Уведомление о ввозе (импорт)'),
      link: APP_LINKS.ImportThirdCountry,
      country: ['UZ'],
    },
    {
      type: types.EAES_IMPORT,
      title: Translate('Уведомление о ввозе товаров (ЕАЭС)'),
      link: APP_LINKS.Eaes_import,
    },
    {
      type: types.FISCAL_STORAGE,
      title: Translate('Загрузка чеков из ФН'),
      link: 'fiscal',
    },
  ],
  [],
);

class RequestList extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    last: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pagesCount: PropTypes.number.isRequired,
    selectedPage: PropTypes.number.isRequired,
    filters: PropTypes.shape({}).isRequired,
    sorting: PropTypes.shape({}).isRequired,
    onMount: PropTypes.func.isRequired,
    onListMount: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterApply: PropTypes.func.isRequired,
    onOpenSidebar: PropTypes.func.isRequired,
    onImportFileUpload: PropTypes.func.isRequired,
    onEaesImportFileUpload: PropTypes.func.isRequired,
    onOpenDocument: PropTypes.func.isRequired,
    onOpenNewFile: PropTypes.func.isRequired,
    uploadErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClearErrors: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSetContent: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      actionBarLeft: this.getLeftActionBarItems(),
      actionBarRight: [<FiltersButton onClick={this.onOpenFiltersSidebar} />],
      isAddMenuOpen: false,
      isUploadMenuOpen: false,
      cellEvent: null,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (location.pathname === '/documents') {
      history.replace('/documents/list');
    }
    const { onMount, onListMount } = this.props;
    onMount();
    onListMount();
  }

  static getDerivedStateFromProps(props, state) {
    const { location, selectedPage, onGetProps } = props;
    // eslint-disable-next-line
    if (!/[\?&]{1}page=/.test(location.search) && selectedPage > 1) {
      onGetProps();
    }
    return state;
  }

  componentWillUnmount() {
    this.props.onUnMount();
  }

  getTdProps = (state, rowInfo, cellInfo) => {
    const { history, onOpenDocument } = this.props;
    const { id } = cellInfo;
    if (id !== 'number') return undefined;

    const type = get(rowInfo, 'original.type', '');
    const number = get(rowInfo, 'original.number', '');

    const match = type.match(/.*_(XML|CSV)/);

    let onClick = () => null;

    if (match) {
      onClick = () => onOpenDocument(number, match[1], type);
    } else if (number) {
      onClick = () => history.push(`/documents/list/${number}`);
    }

    return {
      onClick,
    };
  };

  getTheadThProps = (state, _, column) => {
    const { sorting } = this.props;
    const { order, orderColumn } = sorting;
    const { id } = column;

    if (id === orderColumn) {
      return {
        className: `-sort-${order.toLowerCase()}`,
      };
    }

    return {};
  };

  getLeftActionBarItems = () => {
    const { actionBarLeft } = this.props;
    return actionBarLeft;
  };

  handleMenuToggle = menuName => () => {
    this.setState({
      [`is${menuName}MenuOpen`]: !this.state[`is${menuName}MenuOpen`],
    });
  };

  handleMenuClose = () => () => {
    this.setState({ isAddMenuOpen: false, isUploadMenuOpen: false });
  };

  getBottomActionBarItems = () => {
    const { history, permissions, countryCode, roles } = this.props;
    const TradeInventoryEnvData = JSON.parse(
      window.env.TRADE_INVENTORY || '[]',
    )[0];

    const InventoryEnvData = JSON.parse(window.env.INVENTORY || '[]')[0];

    const isCurrentDateBeforeInventoryEnvData = moment().isBefore(
      InventoryEnvData.endDate,
    );
    const isCurrentDateBeforeTradeInventoryEnvData = moment().isBefore(
      TradeInventoryEnvData.endDate,
    );

    const createItems =
      roles.indexOf('ROLE_ORG_IS_MP_OPERATOR') === -1
        ? createMenuItems
            .reduce((acc, current) => {
              if (
                current.link !== 'tradeInventory' &&
                current.link !== 'inventory'
              ) {
                acc.push(current);
              }
              //Завязываемся на дату скрытия если она до, то все видно, позже должна быть скрыта
              if (
                current.link === 'inventory' &&
                isCurrentDateBeforeInventoryEnvData
              ) {
                acc.push(current);
              }

              if (
                current.link === 'tradeInventory' &&
                isCurrentDateBeforeTradeInventoryEnvData
              ) {
                acc.push(current);
              }

              return acc;
            }, [])
            .filter(menuItem => {
              const itemIndex = menuItem.link;
              return (
                get(permissions[itemIndex], 'canCreate', false) &&
                (menuItem.country.includes('all') ||
                  menuItem.country.includes(countryCode))
              );
            })
            .map((menuItem, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    let { link } = menuItem;

                    if (link.match(/^\//)) {
                      history.push(link);
                    } else {
                      history.push(`/documents/${link}/create`);
                    }
                  }}
                >
                  {menuItem.title}
                </MenuItem>
              );
            })
        : [];

    const uploadItems = uploadMenuItems
      .filter(menuItem => {
        const itemIndex =
          menuItem.link === 'withdrawing' ? 'loan' : menuItem.link;
        return (
          get(permissions[itemIndex], 'canUpload', false) &&
          (menuItem.country.includes('all') ||
            menuItem.country.includes(countryCode))
        );
      })
      .map((menuItem, index) => {
        return (
          <MenuItem key={index} onClick={this.onUploadClick(menuItem.type)}>
            {menuItem.title}
          </MenuItem>
        );
      });

    return [
      uploadItems.length ? (
        <div>
          <Button
            aria-owns={
              this.state.isUploadMenuOpen ? 'simple-popper' : undefined
            }
            aria-haspopup="true"
            variant="contained"
            color={this.state.isUploadMenuOpen ? 'primary' : undefined}
            onClick={this.handleMenuToggle('Upload')}
            buttonRef={node => {
              this.anchorUploadButton = node;
            }}
          >
            <Icon type="upload" spacingRight={16} /> {Translate('Загрузить')}
          </Button>
          <Popover
            open={this.state.isUploadMenuOpen}
            anchorEl={this.anchorUploadButton}
            onClose={this.handleMenuClose()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            container={() => this.popoverContainer}
          >
            <Paper>
              <MenuList>{uploadItems}</MenuList>
            </Paper>
          </Popover>
        </div>
      ) : null,
      createItems.length ? (
        <div>
          <Button
            aria-owns={this.state.isAddMenuOpen ? 'simple-popper' : undefined}
            aria-haspopup="true"
            variant="contained"
            color={this.state.isAddMenuOpen ? 'primary' : undefined}
            onClick={this.handleMenuToggle('Add')}
            buttonRef={node => {
              this.anchorAddButton = node;
            }}
          >
            <Icon type="template-add" spacingRight={16} />{' '}
            {Translate('Добавить документ')}
          </Button>
          <Popover
            open={this.state.isAddMenuOpen}
            anchorEl={this.anchorAddButton}
            onClose={this.handleMenuClose()}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            container={() => this.popoverContainer}
          >
            <Paper>
              <MenuList>{createItems}</MenuList>
            </Paper>
          </Popover>
        </div>
      ) : null,
    ];
  };

  onClear = () => {
    const { onClear } = this.props;
    onClear();
    setTimeout(() => this.onOpenFiltersSidebar(), 0);
  };

  onOpenFiltersSidebar = () => {
    const { onOpenSidebar, filters, countryCode } = this.props;

    onOpenSidebar(
      <Filters
        onClear={this.onClear}
        data={prepareToRange(filters)}
        onSubmit={this.onFilterApply}
        filters={<RequestListFilters countryCode={countryCode} />}
      />,
    );
  };

  onFilterApply = (params = {}) => {
    const { onFilterApply, selectedPage } = this.props;
    //onFilterApply(prepareFromRange({ ...params, page: selectedPage }));
    if (params.search !== undefined && params.search.trim() === '')
      delete params.search;
    onFilterApply(prepareFromRange({ ...params, page: 1 }));
  };

  onUploadClick = type => () => {
    const {
      onOpenNewFile,
      onErrors,
      onOpenModal,
      onSetContent,
      onCloseModal,
    } = this.props;

    switch (type) {
      case types.FISCAL_STORAGE: {
        onSetContent(
          <FiscalUploadModalContent
            onCloseModal={onCloseModal}
            types=".crpt,.zip"
            onAccept={file => this.onAccept(type, file)}
          />,
        );
        onOpenModal();
        break;
      }

      default: {
        onOpenNewFile(null, {
          onAccept: file => this.onAccept(type, file),
          types: '.xlsx, .xml, .xls',
          gotErrors: val => onErrors(val),
        });
      }
    }

    this.setState({ type, isUploadMenuOpen: false });
  };

  onAccept = (type, file) => {
    switch (type) {
      case types.FISCAL_STORAGE: {
        const { onFiscalDocumentUpload } = this.props;
        onFiscalDocumentUpload(file);
        break;
      }
      case types.IMPORT: {
        const { onImportFileUpload } = this.props;
        onImportFileUpload(file);
        break;
      }

      case types.EAES_IMPORT: {
        const { onEaesImportFileUpload } = this.props;
        onEaesImportFileUpload(file);
        break;
      }

      default:
        break;
    }
  };

  onRef = el => {
    this.popoverContainer = el;
  };

  onRowMouseEnter = e => {
    this.setState({ cellEvent: e });
  };

  onRowMouseLeave = () => this.setState({ cellEvent: null });

  onDownloadClick = (type, id) => {
    this.props.downloadDoc({ type, id });
  };

  render() {
    const {
      results,
      pagesCount,
      uploadErrors,
      onClearErrors,
      selectedPage,
      isLoading,
      last,
      isModalOpen,
      onCloseModal,
      isUserOGV,
      userInn,
    } = this.props;
    const { actionBarLeft, actionBarRight, type, cellEvent } = this.state;

    const columns2 = getColumns(userInn);

    return (
      <RequestListView
        isUserOGV={isUserOGV}
        onRef={this.onRef}
        last={last}
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        cellEvent={cellEvent}
        results={results}
        pagesCount={pagesCount}
        selectedPage={selectedPage}
        columns={columns2}
        options={options}
        getTdProps={this.getTdProps}
        getTrProps={(state, rowInfo) => ({
          // onMouseEnter: () => this.onRowMouseEnter(rowInfo),
          // onMouseLeave: this.onRowMouseLeave,
          ['data-row-document-id']: rowInfo.original.id,
        })}
        onDownloadClick={this.onDownloadClick}
        getTheadThProps={this.getTheadThProps}
        actionBarLeft={actionBarLeft}
        actionBarBottom={this.getBottomActionBarItems()}
        actionBarRight={actionBarRight}
        notifyErrors={uploadErrors}
        onNotifyAccept={this.onUploadClick(type)}
        onNotifyCancel={onClearErrors}
        isLoading={isLoading}
        pageCounterInvisible={true}
      />
    );
  }
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: 999999, //selectors.pagesCount(state),
    last: selectors.last(state), //selectors.last(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    roles: authSelectors.roles(state),
    uploadErrors: selectors.uploadErrors(state),
    selectedPage: selectors.selectedPage(state),
    userInn: authSelectors.userInn(state),
    isLoading: preloaderSelectors.loading(state),
    isUserOGV: authSelectors.isUserOGV(state),
    countryCode: state.config.countryCode,
  };
};

const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onOpenDocument: (id, type, docType) =>
    dispatch(actions.openDocument({ id, type, docType })),
  onOpenNewFile: (obj, meta) =>
    dispatch(filePreviewActions.openFile(obj, meta)),
  onImportFileUpload: file => dispatch(importDocumentActions.upload(file)),
  onEaesImportFileUpload: file =>
    dispatch(eaesImportDocumentActions.upload(file)),
  onFiscalDocumentUpload: file => dispatch(fiscalDocumentActions.upload(file)),
  onClearErrors: () => dispatch(actions.clearErrors()),
  onErrors: val => dispatch(actions.gotErrors(val)),
  onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
  downloadDoc: id => dispatch(actions.downloadDoc(id)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withPermissions(permissionConfig),
  withModal,
)(RequestList);
