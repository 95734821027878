import moment from 'moment';

export const getShortDateStringFromStamp = (timeStamp, withoutMinutesInfo = false, utc = false) =>
{
  if(withoutMinutesInfo) {
    return moment(timeStamp).format('DD.MM.YYYY');
  }
  if(utc) {
    return  moment(timeStamp).format('DD.MM.YYYY, HH:mm:ss Z UTC');
  }
  return  moment(timeStamp).format('DD.MM.YYYY, HH:mm:ss');
}

export const getFormattedDateStringFromStamp = timeStamp =>
  moment(timeStamp).format('DD MMMM YYYY в HH:mm:ss Z UTC');
