import { Button, Link } from '@crpt-ui/core';
import React from 'react';
import { CellTooltip } from 'src/fragments/Table/Cell/CellTooltip';
import { CodeColumns } from 'src/fragments/Table/Cell/Codes/columns.constants';
import { UndefinedPhrase } from 'src/fragments/Table/Cell/stub';
import { Value } from 'src/fragments/Table/Cell/value';

import { useStyles } from './Agregation.styles';
import Expanded from './Expanded';
import { Translate } from '../../../common_components/Translate/Translate';

export const limit = 10;

export const columns = [
  {
    accessor: 'cis',
    id: 'cis',
    width: 310,
    Header: Translate('Код'),
    disableFilters: true,
    Cell: ({ row, cell }) => {
      const styles = useStyles(row);
      if (!cell.value) {
        return <UndefinedPhrase text={<span>Данные обновляются<br /> пожалуйста подождите</span>} />
      }

      const cellLink = () => {
        return (
          <Link
            className={styles.linkStyles}
            href={`/cis/list/${encodeURIComponent(cell.value)}`}
            target={"_blank"}
          >
            {cell.value}
          </Link>
        )
      };

      const renderValues = () => {
        return (
          <div className={styles.itemWrapper}>
            {row.canExpand ? (
              <Button
                className={styles.button}
                variant='text'
                {...row.getToggleRowExpandedProps({
                })}
              >
                <Expanded row={row} />
              </Button>
            ) : <div className={styles.plug} />}
            <Value>
              {cellLink()}
            </Value>
          </div>
        )
      };
      return <CellTooltip arrow={false} alwaysDisplay title={cell.value} render={renderValues} />;
    },
  },
  {
    ...CodeColumns.type,
    disableFilters: true,
  },
  {
    ...CodeColumns.status,
    disableFilters: true,
  },
  {
    ...CodeColumns.producedDate,
    width: 95
  },
  {
    ...CodeColumns.productName,
    disableFilters: true,
  },
  {
    accessor: 'childs',
    id: 'childs',
    Cell: ({ row }) => {
      return <Value>{Array.isArray(row.values.childs) ? <div>{row.values.childs.length}</div> : '-'}</Value>
    },
    Header: Translate('Ед. внутри'),
    width: 100
  },
  {
    ...CodeColumns.producerName,
    disableFilters: true,
  },
  {
    ...CodeColumns.ownerName,
    disableFilters: true,
  },
];
