import { handleActions } from 'redux-actions';

import * as actions from './articlesCreation.actions';

const item = handleActions(
  {
    [actions.setItem]: (state, { payload }) => payload,
    [actions.unmount]: () => {},
  },
  null,
);

const iconList = handleActions(
  {
    [actions.setIconList]: (state, { payload }) => payload,
    [actions.unmount]: () => {},
  },
  [],
);

const isLoading = handleActions(
  {
    [actions.loadItem]: () => true,
    [actions.setItem]: () => false,
    [actions.loaded]: () => false,
  },
  false,
);

export default {
  item,
  iconList,
  isLoading
}
