import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SingleSelect } from '@crpt/react-select';
import { ProductService } from '../../services/ProductService';
import { FilterURLHelper } from '../../utils/FilterURLHelper';

class ProductSelect extends Component {
  static displayName = 'SingleSelect';

  static searchParam = 'name';
  select;

  static propTypes = {
    name: PropTypes.string,
  };

  static defaultProps = {
    name: null,
  };

  state = {
    values: [],
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.select = React.createRef();
  }

  componentDidMount() {
    const { name } = this.props;
    const searchParam = FilterURLHelper.getPairSearchParamByKey(name);
    const value = FilterURLHelper.getParamByKey(name);

    if (searchParam || value) {
      const product = {
        id: 0,
        title: searchParam ? searchParam : value,
        name: searchParam ? searchParam : value,
        [this.props.name || 'gtin']: value,
      };

      this.setState({
        values: [product],
        isLoading: false,
      });



      this.select.current.onSelect({ preventDefault: () => {} }, product);
    }
  }

  onChange = val => {
    const { name } = this.props;
    this.setState({ isLoading: true });

    ProductService.searchByInput(val)
      .then(answer => {
        this.setState({
          isLoading: false,
          values: answer.data.map((pr, i) =>
            Object.assign(pr, {
              [name || 'gtin']: pr.gtin,
              id: i,
              title: +val
                ? `${pr.gtin}: ${pr.name}`
                : pr.name ? pr.name : pr.brand ? pr.brand : pr.gtin,
            })
          ),
        });
      })
      .catch(err => console.warn('canceled or err', err));
  };

  render() {
    const { onChange, isLoading: _1, values: _2, onRef, ...other } = this.props;
    const { isLoading, values } = this.state;

    return (
      <SingleSelect
        {...other}
        onChange={this.onChange}
        onRef={el => {
          this.select.current = el;
          onRef && onRef(el);
        }}
        isLoading={isLoading}
        values={values}
      />
    );
  }
}

export default ProductSelect;
