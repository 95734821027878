import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { InlineWrap, Layout } from '@crpt/react-page-layout';
import HeaderLogoWithText from './header-logo.svg';
import LogoWithText from './logo.svg';
import Form from '../../common_components/Form/Form';
import Fields from './Fields/Fields';
import {
  ContentWrap,
  EnterWrap,
  IconWrap,
  Inner,
  Outer,
  SelectWrap,
} from './LoginForm.styled';
import LanguageMenu from '../Language/Menu';
import { LanguageWrap } from '../LoginFormKep/LoginFormKep.styled';
import { Translate } from '../../common_components/Translate/Translate';

const LoginFormView = ({ theme, onSubmit }) => {
  const { host } = location;
  if (!/^localhost/i.test(host)) {
    location = 'https://markirovka.crpt.ru/login';
    return;
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout filled>
        <Outer>
          <Inner>
            <InlineWrap
              flex
              height="360px"
              width="100%"
              background="#F8EC31"
              justifyContent="center"
            >
              <LanguageWrap>
                <LanguageMenu/>
              </LanguageWrap>
              <IconWrap>
                <HeaderLogoWithText />
                <LogoWithText />
              </IconWrap>
            </InlineWrap>

            <ContentWrap>
              <InlineWrap flex justifyContent="center" width="618px">
                <InlineWrap
                  flex
                  flexDirection="column"
                  alignItems="stretch"
                  width="100%"
                >
                  <EnterWrap>{Translate('Вход')}</EnterWrap>

                  <SelectWrap>
                    <Form onSubmit={onSubmit}>
                      <Fields />
                    </Form>
                  </SelectWrap>
                </InlineWrap>
              </InlineWrap>
            </ContentWrap>
          </Inner>
        </Outer>
      </Layout>
    </ThemeProvider>
  );
};

LoginFormView.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginFormView;
