const submitButtonDisabled = state => state.LoginForm.submitButtonDisabled;
const passwordDisabled = state => state.LoginForm.passwordDisabled;
const loginDisabled = state => state.LoginForm.loginDisabled;
const loginError = state => state.LoginForm.loginError;
const passwordError = state => state.LoginForm.passwordError;

export {
  submitButtonDisabled,
  passwordDisabled,
  loginDisabled,
  loginError,
  passwordError
};
