import { combineReducers } from 'redux';

import { filters } from './filters';
import { results } from './results';
import { total } from './total';
import { selectedPage } from './selectedPage';
import { pagesCount } from './pagesCount';
import { producerFilter } from './producerFilter';
import { isLoading } from './isLoading';


export default combineReducers({
  filters,
  results,
  total,
  selectedPage,
  pagesCount,
  producerFilter,
  isLoading
});