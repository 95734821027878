export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'GTIN',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 96,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 174,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 164,
          subheaders: []
        },
        {
          Header: 'Доля рынка по произведённому товару',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 508,
          subheaders: [
            {
              Header: '% в штуках',
              accessor: 'col4',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 254,
              subheaders: []
            },
            {
              Header: '% в тенге',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 254,
              subheaders: []
            },
          ]
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
        }
      ]
    }
  ]
}
