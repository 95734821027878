export const defaultTheme = {
  bar: {
    marginLeft: '24px',
    marginRight: '24px',
    bottom: '20px',
  },
  actionGroup: {
  },
  action: {
    marginLeft: '8px',
    marginRight: '8px',
  },
};
