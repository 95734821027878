import HeadingWrap from './HeadingWrap';
import HeadingSmallTitle from './HeadingSmallTitle';
import HeadingId from './HeadingId';
import BreadcrumbsWrap from './BreadcrumbsWrap';
import BottomPart from './BottomPart';

export {
  HeadingWrap,
  HeadingSmallTitle,
  HeadingId,
  BreadcrumbsWrap,
  BottomPart,
};
