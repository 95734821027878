import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';

import { Select as SingleSelect } from '@crpt-ui/core';

import { Wrap } from '../../Fields/Fields.styled';
import { MaterialUiThemeUZ } from "../../../_MaterialUi/ThemeUZ";
import { MuiThemeProvider } from "@material-ui/core";
import { MaterialUiThemeKZ } from "../../../_MaterialUi/ThemeKZ";
import { countryCodeFlags } from "../../../../constants";

const PureSingleSelect = memo(SingleSelect);


class SingleSelectAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    formMutators: PropTypes.shape({}).isRequired,
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    isError: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ),
    onSelect: PropTypes.func,
    selectedId: PropTypes.number,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    isError: false,
    options: [],
    onSelect: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
    selectedId: undefined,
    defaultValue: undefined,
    onChange: () => null,
  };

  componentDidMount() {
    const {
      selectedId,
      defaultValue,
      values,
      formMutators,
      fieldInput = {},
      onChange,
    } = this.props;
    const { value, name } = fieldInput;

    if (!value) {
      if (selectedId) {
        const selectedItem = values.find(i => i.id === selectedId);
        if (selectedItem) {
          setTimeout(
            () =>
              formMutators.updateField(name, selectedItem.type || selectedItem),
            0,
          );
        }
      } else if (defaultValue) {
        setTimeout(() => formMutators.updateField(name, defaultValue), 0);
      }
    } else if (!selectedId) {
      onChange(value);
    }
  }

  onSelect = event => {
    const { fieldInput, onSelect } = this.props;
    const value = event.target.value;

    if (fieldInput) {
      fieldInput.onChange(!isNull(value) ? value : undefined);
    }
    if (onSelect) onSelect(value);
  };

  isError = () => {
    const { isError, fieldMeta, forceValidate } = this.props;

    if (isError) return true;

    if (
      fieldMeta
      && fieldMeta.dirtySinceLastSubmit
      && !fieldMeta.error
    ) {
      return false;
    }
    return isError || (fieldMeta && fieldMeta.error && (fieldMeta.touched || forceValidate));
  };

  getPlaceholder = () => {
    const {
      placeholder,
      errorPlaceholder,
      index,
      total,
      fieldMeta,
    } = this.props;
    const isError = this.isError();

    let newPlaceholder = placeholder;
    if (isError) {
      if (errorPlaceholder) newPlaceholder = errorPlaceholder;
      else if (fieldMeta.error !== 'Error') newPlaceholder = fieldMeta.error;
    }

    return index && total > 1 ? `${newPlaceholder} ${index}` : newPlaceholder;
  };

  getValue = () => {
    const { fieldInput = {}, options } = this.props;
    const { value } = fieldInput;

    return !isNull(value) ? value : undefined;
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      disabled,
      placeholder,
      errorPlaceholder,
      ...passProps
    } = this.props;

    return (
      <MuiThemeProvider theme={countryCodeFlags.isKZ? MaterialUiThemeKZ : MaterialUiThemeUZ}>
        <Wrap
            onBlur={fieldInput && fieldInput.onBlur}
            marginBottom={marginBottom}
        >
          <PureSingleSelect
            {...passProps}
            disabled={disabled}
            error={this.isError()}
            label={this.getPlaceholder()}
            onChange={this.onSelect}
            value={this.getValue()}
          />
        </Wrap>
      </MuiThemeProvider>
    );
  }
}

export default SingleSelectAdapter;
