import { Button, Pagination } from '@crpt-ui/core';
import Datatable from '@crpt-ui/datatable';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { Preloader } from 'src/fragments/Table/Preloader';

// import { Divider } from '../../GrayAreaCreate.styles';
import { columns } from '../Gtins.constants';
import { Translate } from '../../../../../common_components/Translate/Translate';
import { Trans } from 'react-i18next';

const GtinsModalView = ({
  gtins,
  initialDatatableState,
  isAddDisabled,
  loaded,
  onAddHandle,
  onClose,
  onFiltersApply,
  onPageChange,
  onRowSelectHandle,
  page,
  pagesCount,
  setCustomIdsToRows,
  tableRef,
  total,
}) => {
  return (
    <React.Fragment>
      <div style={{ position: 'relative', backgroundColor: '#fff' }}>
        <Preloader isOpen={!loaded} />

        <ActionsRow
          isAddDisabled={isAddDisabled}
          onAddHandle={onAddHandle}
          onClose={onClose}
        />

        <Datatable
          ref={tableRef}
          data={gtins}
          columns={columns}
          pageCount={pagesCount}
          onRowSelect={onRowSelectHandle}
          getRowId={setCustomIdsToRows}
          initialState={initialDatatableState}
          autoResetSelectedRows={false}
          autoResetFilters={false}
          onFiltersChange={onFiltersApply}
          useRowSelect
          useRowState
          useFilters
        />

        <Box px={5} py={2}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Pagination page={page + 1} pagesCount={pagesCount} onPageChange={onPageChange}/>
            </Grid>
            <Grid item>
              <Box color="grey.600" fontSize={12} fontWeight={600}>
                {Translate('Всего записей:')}&nbsp;{total}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};
export default GtinsModalView;

const ActionsRow = ({ isAddDisabled, onAddHandle, onClose }) => {
  return (
    <Box px={5} py={2}>
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Button
            onClick={onAddHandle}
            disabled={isAddDisabled}
          >
            {Translate('Добавить')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onClose}>
            {Translate('Отменить')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
