import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Toast } from '../../../../utils/Toast';
import * as _ from 'lodash';

import Api from '../../../../common_components/Api/Api';
import { hostname } from '../../constants';
import { isOperator } from '../../_utils/utils';
import * as unloadServiceListActions from './UnloadServiceList.actions';

function* onMounted() {
  //TODO: получать из url код товарной группы
  const pg = 3;
  const pageParams = {
    page: 0,
    size: 10000,
  };

  const {
    tasksList: [tasksListResponse, tasksListError],
    taskTypesList: [taskTypesListResponse, taskTypesListError],
  } = yield all({
    tasksList: call(fetchTasksList, { params: { pg, ...pageParams } }),
    taskTypesList: call(fetchTasktypesList, { params: { ...pageParams } }),
  });

  let availableOrgRoles = [];
  if (isOperator()) {
    const requestRoles = {
      url: `/api/v3/facade/profile/available-org-roles`,
      method: 'GET',
    };
    const [successRoles, errorRoles] = yield call(Api.request, requestRoles);

    if (errorRoles) {
      yield call(Toast.showError, { content: 'Роли не были получены' });
    }

    availableOrgRoles = _.get(successRoles, 'data', []);
  }

  if (tasksListError || taskTypesListError) {
    const errors = [tasksListError, taskTypesListError]
      .map(item => _.get(item, 'message'))
      .filter(item => item);

    yield call(Toast.showError, { content: errors.join('\n\n') });
  }

  yield put(
    unloadServiceListActions.setData({
      tasks: _.get(tasksListResponse, 'data.list', []),
      taskTypes: _.get(taskTypesListResponse, 'data.list', []),
      availableOrgRoles,
    }),
  );
}

function* putNewStatus({ payload }) {
  const { action, ids } = payload;

  //TODO: получать из url код товарной группы
  const pg = 3;
  const requestBody = {
    params: { pg },
    data: { newStatus: action },
  };

  const requestArr = ids.map(id => call(fetchNewStatus, id, requestBody));

  const result = yield all(requestArr);

  const resultData = result
    .filter(([success]) => success)
    .map(([success]) => _.get(success, 'data', ''))
    .filter(item => item);

  const resultErrors = result
    .filter(([success, error]) => error)
    .map(([success, error]) => _.get(error, 'message', 'error'));

  if (resultData.length) {
    yield put(unloadServiceListActions.getData());
    yield call(Toast.showSuccess, { content: 'Статус изменен' });
  }

  if (resultErrors.length) {
    yield call(Toast.showError, { content: resultErrors.join('\n\n') });
  }
}

export default function* watch() {
  yield takeEvery(
    [unloadServiceListActions.mounted, unloadServiceListActions.getData],
    onMounted,
  );
  yield takeEvery(unloadServiceListActions.putNewStatus, putNewStatus);
}

function* fetchNewStatus(id, { data, params }) {
  return yield call(Api.request, {
    url: `${hostname}/dispenser/v1/tasks/${id}`,
    method: 'put',
    data,
    params,
  });
}

function* fetchTasksList({ params }) {
  return yield call(
    Api.request,
    {
      url: `${hostname}/dispenser/v1/tasks`,
      method: 'get',
      params,
    },
    { timeout: 200000, preloading: false },
  );
}

function* fetchTasktypesList({ params }) {
  return yield call(Api.request, {
    url: `${hostname}/dispenser/v1/tasktypes`,
    method: 'get',
    params,
  });
}
