import React, { useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ActionBar from '../../../common_components/ActionBar/ActionBar';
import FiltersButton from '../../../common_components/Buttons/FiltersButton';
import Preloader from '../../../common_components/Preloader/Preloader';
import Form from '../../../common_components/Form/Form';
import TreeList from '../../../common_components/List/TreeList/TreeList';
import Pagination from '../../../common_components/List/Pagination/Pagination';
import Filters from '../../../common_components/List/Filters/Filters';
import ExportListFilters from './ExportListFilters';
import { columns, FooterWrapper, limit, options } from './ExportList.constants';
import { withModal } from '../../../common_components/Modals/hocs/withModal';
import { ContentWrapper } from '../../../common_components/styled';
import { prepareFromRange, prepareToRange } from '../../../common_components/Form/Form.utils';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as listActions from '../../../common_components/List/ducks/List.actions';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as actions from './ducks/ExportList.actions';
import * as selectors from './ducks/ExportList.selectors';


const ExportList = (props) => { // название ExportList заменить на ImportList
  const { actionBarLeft, selectedPage, results, isLoading } = props;
  const { onFilterApply, onClear, onOpenSidebar, onUnMount } = props;
  const { setSorting, onMount, onListMount, pagesCount } = props;

  const onRef = useRef(null);

  useEffect(()=> {
    setSorting({
      asc: false,
      sortBy: 'createdAt',
    })
    onMount()
    onListMount();
  },[setSorting, onMount, onListMount])

  useEffect(()=> onUnMount,[onUnMount])

  const handleOnClear = () => {
    onClear();
    handleOpenFiltersSidebar("clear")
  };

  const handleFilterApply = params => {
    onFilterApply(prepareFromRange({ ...params, offset: 0 }));
  };

  const handleOpenFiltersSidebar = (values) => {
    const data = prepareToRange(values === "clear" ? {} : props.filters);
    if (data.date_from) {
      data.date_from = moment(data.date_from).utc().format()
    }
    if (data.date_to) {
      data.date_to = moment(data.date_to).utc().format();
    }
    onOpenSidebar(
      <Filters
        onClear={handleOnClear}
        data={data}
        onSubmit={handleFilterApply}
        filters={<ExportListFilters />}
      />,
    );
  };

  return (
    <ContentWrapper>
      <ActionBar
        left={actionBarLeft}
        right={[
          <FiltersButton
            onClick={handleOpenFiltersSidebar}
          />
        ]}
      />
      <Preloader />
      <Form data={results}>
        <TreeList
          data={results}
          columns={columns}
          options={options}
          onRef={onRef}
          hidden={isLoading}
          height="calc(100vh - 320px)"
          showToggle={false}
        />
      </Form>
      <FooterWrapper>
        <Pagination
          pageCounterInvisible={true}
          meta={actions}
          pagesCount={pagesCount}
          selected={selectedPage}
        />
      </FooterWrapper>
    </ContentWrapper>
  )
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: selectors.pagesCount(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
  };
};

const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  setSorting: (payload) => dispatch(actions.setSorting(payload)),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(ExportList);
