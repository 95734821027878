import React from 'react';
import moment from 'moment';
import { formatUZPhoneWithPrefix } from '../../utils/index';
import { get } from 'lodash';
import { Translate } from '../../common_components/Translate/Translate';
import { Value } from '../../fragments/Table/Cell/value';
import { Icon as MuiIcon } from '@material-ui/core';
import Icons from '../../common_components/Icons/Icons';
import { InputWithHiddenLabel } from '../Form/InputWithHiddenLabel';
import { Icon } from '@crpt/react-icon';
import { MultiSelect } from '@crpt/react-select';
import { validateRequiredAndLength } from '../../common_components/Form/utils/validations';
import { RangePicker } from '@crpt/react-datepicker';
import { defaultDatepickerTheme } from '../../../src/theme/ru-tabaco/datepicker';


const APPEALS_STATUSES = {
    NOT_PROCESSED: Translate('Зарегистрировано'),
    IN_WORK: Translate('В обработке'),
    ENDED_UNCONFIRMED: Translate('Отклонено'),
    ENDED_CONFIRMED: Translate('Подтверждено'),
};
const APPEALS_STATUSES_WITHOUT_TRANSLATE = {
    NOT_PROCESSED: 'Зарегистрировано',
    IN_WORK: 'В обработке',
    ENDED_UNCONFIRMED: 'Отклонено',
    ENDED_CONFIRMED: 'Подтверждено',
};

const APPEALS_COLORS = {
    NOT_PROCESSED: '#4678F9',
    IN_WORK: '#EB910A',
    ENDED_UNCONFIRMED: '#FF3B21',
    ENDED_CONFIRMED: '#14C25E',
};

const iconStyle = {
    marginTop: '5px',
    marginRight: '10px',
    width: '16px',
    height: '16px',
};

const APPEALS_ICONS = {
    NOT_PROCESSED: <MuiIcon component={Icons.DocText} style={iconStyle} fill={APPEALS_COLORS.NOT_PROCESSED}/>,
    IN_WORK: <MuiIcon component={Icons.WaitSending} style={iconStyle} fill={APPEALS_COLORS.IN_WORK}/>,
    ENDED_UNCONFIRMED: <MuiIcon component={Icons.Cross} style={iconStyle} fill={APPEALS_COLORS.ENDED_UNCONFIRMED}/>,
    ENDED_CONFIRMED: <MuiIcon component={Icons.Accepted} style={iconStyle} fill={APPEALS_COLORS.ENDED_CONFIRMED}/>,
};

export const getFilters = (
    goodsGroups,
    language,
    t,
    territorialUnits,
    violationCategories,
    violationTypes
) => [
    {
        showDownloadListButton: false,
        popupMenu: false,
    },
    [
        {
            component: InputWithHiddenLabel,
            props: {
                name: 'number',
                type: 'number',
                placeholder: Translate('Поиск по номеру нарушения'),
                rightIcon: <Icon type="search" />,
                validate: validateRequiredAndLength(16),
                },
        },
    ],
    [
        {
            component: RangePicker,
            props: {
                inline: false,
                name: 'updateDay',
                placeholder: Translate('Дата проверки'),
                format: 'DD.MM.YYYY',
                positionX: 'left',
                controls: true,
                acceptText: Translate('Применить'),
                resetText: Translate('Сбросить'),
                theme: { DatePicker: defaultDatepickerTheme },
            },
        },
    ],
    [
        {
            component: MultiSelect,
            props: {
                name: 'statuses',
                placeholder: Translate('Статус'),
                values: Object.keys(APPEALS_STATUSES_WITHOUT_TRANSLATE).map(item => ({id: item, title: t(APPEALS_STATUSES_WITHOUT_TRANSLATE[item]) })),
            },
        },
    ],
    [
        {
            component: MultiSelect,
            props: {
                name: 'groupGoodCode',
                placeholder: Translate('Товарная группа'),
                values: goodsGroups.length && goodsGroups.map(item => ({...item, title: item.name, id: item.code }))
            },
        },
    ],
    [
        {
        component: InputWithHiddenLabel,
        props: {
            name: 'phoneNumber',
            type: 'number',
            placeholder: Translate('Поиск по номеру телефона'),
            rightIcon: <Icon type="search" />,
            validate: validateRequiredAndLength(13),
            },
        },
    ],
    [
        {
            component: MultiSelect,
            props: {
                name: 'violationType',
                placeholder: Translate('Тип нарушения'),
                values: violationTypes.length && violationTypes.reduce((acc, curr) => {
                    const lang = language === 'RU' ? `${capitalize(language)}s` : capitalize(language);
                    const isCurrentUniq = acc.find(({title}) => title ===  get(curr,`name${lang}`));

                    if (!isCurrentUniq) {
                        acc.push({
                            id: curr.code,
                            title: get(curr,`name${lang}`),
                        })
                    }
                    return acc
                }, []),
            },
        },
    ],
    [
        {
            component: MultiSelect,
            props: {
                name: 'violationCategory',
                placeholder: Translate('Категория нарушения'),
                values: violationCategories.length && violationCategories.reduce((acc, curr) => {
                    const lang = language === 'RU' ? `${capitalize(language)}` : capitalize(language);
                    const isCurrentUniq = acc.find(({title}) => title ===  get(curr,`name${lang}`));

                    if (!isCurrentUniq) {
                        acc.push({
                            id: curr.code,
                            title: get(curr,`name${lang}`),
                        })
                    }
                    return acc
                }, []),
            },
        },
    ],
    // [
    //     {
    //         component: MultiSelect,
    //         props: {
    //             name: 'violationDistrictIds',
    //             placeholder: Translate('Регион нарушения'),
    //             values: territorialUnits.length && territorialUnits.reduce((acc, curr) => {
    //                 const lang = language === 'RU' ? `${capitalize(language)}s` : capitalize(language);
    //                 const isCurrentUniq = acc.find(({title}) => title ===  get(curr,`regionName${lang}`));

    //                 if (!isCurrentUniq) {
    //                     acc.push({
    //                         id: curr.regionCode,
    //                         title: get(curr,`regionName${lang}`),
    //                     })
    //                 }

    //                 return acc;
    //             }, [])
    //         },
    //     },
    // ],

    [
        {
            component: MultiSelect,
            props: {
                name: 'violationDistrictIds',
                placeholder: Translate('Район нарушения'),
                values: territorialUnits.length && territorialUnits.reduce((acc, curr) => {
                    const lang = language === 'RU' ? `${capitalize(language)}s` : capitalize(language);
                    const isCurrentUniq = acc.find(({title}) => title ===  get(curr,`districtName${lang}`));

                    if (!isCurrentUniq) {
                        
                        acc.push({
                            id: curr.id,
                            title: get(curr,`districtName${lang}`),
                        })
                    }

                    return acc;
                }, [])
            },
        },
    ],
    [
        {
          component: RangePicker,
          props: {
                inline: false,
                name: 'fixationDate',
                placeholder: Translate('Дата проверки'),
                format: 'DD.MM.YYYY',
                positionX: 'left',
                controls: true,
                acceptText: Translate('Применить'),
                resetText: Translate('Сбросить'),
                theme: { DatePicker: defaultDatepickerTheme },
          },
        },
    ],
];

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();


export const getColumns = (
    language,
    violationCategories,
    territorialUnits,
    t
) => [
    {
        Header: Translate('Дата проверки'),
        id: 'checkDate',
        accessor: ({ checkDate }) => `${moment(new Date(checkDate)).format('DD.MM.YYYY')}`,
        width: 100,
    },
    {
        Header: Translate('Номер нарушения'),
        id: 'complaintNumber',
        accessor: 'complaintNumber',
        width: 200,
    },
    {
        Header: Translate('Номер телефона'),
        id: 'phoneNumber',
        accessor: ({ phoneNumber }) => `${formatUZPhoneWithPrefix(`+${phoneNumber}`)}`,
        width: 150,
    },
    {
        Header: Translate('Категория нарушения'),
        id: 'violationCategory',
        accessor: ({ violationCategory }) => {
            const item = violationCategories.length && violationCategories.find(({id}) => id === violationCategory);
            if (item) {
                return get(item,`name${capitalize(language)}`);
            }
            return '-';
        },
        width: 'auto',
    },
    {
        Header: Translate('Товарная группа'),
        id: 'goodsGroupName',
        accessor: ({ goodsGroupName }) => `${t(`${goodsGroupName}`)}`,
        width: 'auto',
    },
    {
        Header: Translate('Регион'),
        id: 'regionName',
        disableSortBy: true,
        accessor: ({ violationDistrictId }) => {
            const item = territorialUnits.length && territorialUnits.find(({id}) =>  Number(id) === Number(violationDistrictId));
            if (item) {
                const lang = language === 'RU' ? `${capitalize(language)}s` : capitalize(language)
                return get(item,`regionName${lang}`);
            }
            return '-';
        },
        width: 'auto',
    },
    {
        Header: Translate('Район'),
        id: 'districtName',
        disableSortBy: true,
        accessor: ({ violationDistrictId }) => {
            const item = territorialUnits.length && territorialUnits.find(({id}) =>  Number(id) === Number(violationDistrictId));
            if (item) {
                const lang = language === 'RU' ? `${capitalize(language)}s` : capitalize(language)
                return get(item,`districtName${lang}`);
            }
            return '-';
        },
        width: 'auto',
    },
    {
        Header: Translate('Статус'),
        id: 'status',
        accessor: ({status}) => {
            return (
            <div style={{ display: 'flex', alignItems: 'center', color: APPEALS_COLORS[status]}}>
                <div>{APPEALS_ICONS[status]}</div>
                <Value>{APPEALS_STATUSES[status]}</Value>
            </div>
          )
        },
        width: 150,
    },
    {
        Header: Translate('Дата фиксации нарушения'),
        id: 'fixationDateTime',
        accessor: ({fixationDateTime}) => `${moment(new Date(fixationDateTime)).format('DD.MM.YYYY')}`,
        width: 150,
    },
];