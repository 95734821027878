import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from './ducks/ParticipantList.actions';
import * as selectors from './ducks/ParticipantList.selectors';
import * as authSelectors from '../../common_components/Auth/Auth.selectors';
import { initFromUrl } from 'src/utils/urlUtils';
import { dataTableColumns as columns, statusesNotEvaders } from './ParticipantList.constants';
import ParticipantListView from './ParticipantList.view';
import { countryCodeFlags } from '../../constants';
import { makeRadioGroupFilter } from "@crpt-ui/datatable";
import { pgTypesNamesKZ } from "../../constants/productGroups";
import { Translate } from "../../common_components/Translate/Translate";

class ParticipantList extends Component {
    static propTypes = {
        results: PropTypes.arrayOf(PropTypes.object),
        total: PropTypes.number,
        currentPage: PropTypes.number,
        pagesCount: PropTypes.number,
        loaded: PropTypes.bool,
        fetchData: PropTypes.func.isRequired,
        onUnmount: PropTypes.func.isRequired,
        pgList: PropTypes.arrayOf(PropTypes.object),
    };

    constructor(props) {
        super(props);
        const filterNames = columns.map(({ id }) => id);
        if (countryCodeFlags.isKZ) {
            const pgCodeElem = columns.find(el => el.id === "productGroupCode")
            if (pgCodeElem) pgCodeElem.Filter = makeRadioGroupFilter({
                options: this.props.pgList.map((key, i) => ({
                    id: i,
                    value: key,
                    label: Translate(pgTypesNamesKZ[key]),
                    title: pgTypesNamesKZ[key],
                })),
            })
        }

        this.state = {
            initialParams: initFromUrl(this.props.location.search, filterNames)
        };
      }

      componentWillUnmount() {
        this.props.onUnmount();
      }

    componentDidMount() {
        const { initialParams: { page, filters } } = this.state;
        this.props.fetchData({
            filters,
            ...page,
            ...countryCodeFlags.isUZ ? {
                status: statusesNotEvaders
            } : {}
        });
    }

    onPageChange = (...params) => {
        const [page, filters = []] = params;
        const { location, history, fetchData } = this.props;

        fetchData({
            filters,
            page,
            updateHistory: true,
            pathname: location.pathname,
            history,
            ...countryCodeFlags.isUZ ? {
                status: statusesNotEvaders
            } : {}
        });
    };

    onFilterApply = (filters = []) => {
        const { location, history, fetchData } = this.props;
        fetchData({
            filters, page: 1, updateHistory: true, pathname: location.pathname, history,
            ...countryCodeFlags.isUZ && !filters.find(el=> el.id === "status") ? {
                status: statusesNotEvaders
            } : {}
        });
    };

    removeAddressColumn = (cols) => countryCodeFlags.isKZ ? cols.filter(col => col.id !== 'address') : cols

    render() {
        const { loaded, results, total, currentPage, pagesCount } = this.props;
        return (
            <ParticipantListView
                results={results}
                total={total}
                columns={this.removeAddressColumn(columns)}
                currentPage={currentPage}
                pagesCount={pagesCount}
                initialState={{ filters: this.state.initialParams.filters }}
                onFilterApply={this.onFilterApply}
                onPageChange={this.onPageChange}
                loaded={loaded}
            />
        );
    }
}
const mapState = state => ({
    results: selectors.results(state),
    total: selectors.total(state),
    currentPage: selectors.page(state),
    pagesCount: selectors.pagesCount(state),
    loaded: selectors.loaded(state),
    pgList: authSelectors.productGroups(state),
});

const mapDispatch = dispatch => ({
    fetchData: params => dispatch(actions.getRequest(params)),
    onUnmount: () => dispatch(actions.unmounted()),
});

export default compose(
    withTheme,
    connect(mapState, mapDispatch),
)(ParticipantList);
