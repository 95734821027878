export const usualTheme = {
  width: 60,
  height: 35,
  chartLine: {
    stroke: '#474648',
    strokeWidth: 2,
  },
  chartFill: {
    fill: '#dfdfdf',
  },
};
