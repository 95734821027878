import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import ConnectParts from '../ConnectParts';
import * as actions from '../../actions/index';


class SidebarContentBound extends Component {
  render() {
    return (
      <ConnectParts
        actions={[actions.Sidebar]}
        selectors={[]}
        {...this.props}
      />
    );
  }
}

SidebarContentBound.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default withRouter(SidebarContentBound);