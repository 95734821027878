import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from './Rutoken.actions';
import { getRutokenCertificates } from './Rutoken.utils';

import {Toast} from '../../utils/Toast';

function* loadSaga() {
  try {
    const certificates = yield call(getRutokenCertificates);
    yield put(actions.loaded(certificates));
  } catch (e) {
    console.log('Cannot get rutoken certificates:', {e});
    try {
      if (~e.message.indexOf('Подключено')) {
        Toast.showError({content: e.message});
      }
    }

    catch(e) {void 0;}
  }
}

export default function* watchRutokenCalls() {
  yield takeEvery(actions.load, loadSaga);
}
