import axios from 'axios';
import {authInstance} from '../../../services/authInstance';

class TaxService {
  static req = axios.create({
    timeout: 5000,
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  });

  static getTaxInfo(data) {
    return authInstance.request({
      method: 'POST',
      url: '/api/v3/tax/calc',
      data
    });
  }
}

export { TaxService };
