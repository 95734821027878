import { handleActions } from 'redux-actions';
import * as grayAreaUsersActions from './GrayAreaUsers.actions';

const result = handleActions(
  {
    [grayAreaUsersActions.loaded]: (state, { payload }) => payload.result || [],
    [grayAreaUsersActions.clear]: () => [],
  },
  []
);


const total = handleActions(
  {
    [grayAreaUsersActions.loaded]: (state, { payload = {} }) => payload.total !== undefined ? payload.total : 0,
    [grayAreaUsersActions.clear]: () => 0,
  },
  0,
);

const loaded = handleActions(
  {
    [grayAreaUsersActions.loaded]: () => true,
    [grayAreaUsersActions.clear]: () => false,
  },
  false,
);

export default {
  result,
  loaded,
  total,
}
