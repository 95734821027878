import { Translate } from '../../../common_components/Translate/Translate';

const React = require('react');
import PropTypes from 'prop-types';
import i18next from 'i18next';

class TextFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    appliedFilter: PropTypes.object, // filterTerm, operator, filterValue
    close: PropTypes.func,
    title: PropTypes.string,
    onlyDigits: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].filterTerm
          : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    };
  }

  handleFilterChange = e => {
    const { onlyDigits } = this.props
    const { value } = e.target
    this.setState({
      value: value
        ? onlyDigits
          ? value.replace(/[^\d]/g, '')
          : value
        : value
    });
  };

  reset = () => {
    this.setState({ value: '' });
    this.props.onFilterChange(undefined);
  };

  applyFilter = () => {
    //third parameter is filterValue object, need for restored complex filters
    this.props.onFilterChange([
      {
        filterTerm: this.state.value,
        operator: this.state.operator,
        filterValue: {},
      },
    ]);
  };

  setFilter = filter => {
    this.setState({
      value: filter && filter.length > 0 ? filter[0].filterTerm : '',
      operator:
        this.props.appliedFilter && this.props.appliedFilter.length > 0
          ? this.props.appliedFilter[0].operator
          : '=',
    });
  };

  render() {
    const { title } = this.props;
    return (
      <div className="react-grid__filter-simple">
        <input
          type="text"
          placeholder={i18next.t(title || 'Товар')}
          value={this.state.value}
          onChange={e => this.handleFilterChange(e)}
        />
        <button
          type="button"
          onClick={this.reset}
          className="filter-btn__cancel"
        >
          {Translate('Сбросить')}
        </button>
        <button
          type="button"
          disabled={!this.state.value}
          onClick={this.applyFilter}
          className="filter-btn__apply"
        >
          {Translate('Применить')}
        </button>
      </div>
    );
  }
}

export default TextFilter;
