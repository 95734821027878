import { handleActions } from 'redux-actions';
import * as actions from './CisDetails.actions';

const data = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload,
    [actions.updateCis]: () => ({}),
    [actions.unmounted]: () => ({}),
  },
  {}
);

const loaded = handleActions(
  {
    [actions.loaded]: () => true,
    [actions.updateCis]: () => false,
    [actions.unmounted]: () => false,
  },
  false
);

const agregation = handleActions(
  {
    [actions.loadedAgregation]: (state, { payload }) => payload,
    [actions.loadedAgregationRow]: (state, { payload = {} }) => {
      const { rowAgregation = [], path = [] } = payload;
      const newState = [...state];
      const level = path.length;
      switch (level) {
        case 1:
          newState[path[0]]['subRows'] = rowAgregation;
          break;
        case 2:
          newState[path[0]]['subRows'][path[1]]['subRows'] = rowAgregation;
          break;
        case 3:
          newState[path[0]]['subRows'][path[1]]['subRows'][path[2]]['subRows'] = rowAgregation;
          break;
        case 4:
          newState[path[0]]['subRows'][path[1]]['subRows'][path[2]]['subRows'][path[3]]['subRows'] = rowAgregation;
          break;
        case 5:
          newState[path[0]]['subRows'][path[1]]['subRows'][path[2]]['subRows'][path[3]]['subRows'][path[4]]['subRows'] = rowAgregation;
          break;
        // Такие пути пришлось прописывать потому что уровень вложенности в агрегации может быть достаточно большим (Палета - Короб - Блок - Пачка)
        default:
          break;
      }
      return newState;
    },
    [actions.updateCis]: () => ([]),
    [actions.mounted]: () => ([]),
    [actions.unmounted]: () => ([]),
  },
  []
);

const agregationLoaded = handleActions(
  {
    [actions.loadedAgregation]: () => true,
    [actions.updateCis]: () => false,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
  },
  false
);

export default {
  data,
  loaded,
  agregation,
  agregationLoaded,
};
