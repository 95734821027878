import React from 'react';
import moment from 'moment';
import Icon from '@crpt/react-icon';
import { get } from 'lodash';
import styled from 'styled-components';
import { OrderId } from 'src/common_components/Table/TableCells/Order/OrderId';
//import { generateValuesFromDict } from '../../../common_components/Form/Fields/Select/SingleSelect.utils';
import { documentTypeNames } from 'src/constants/documentTypes';
//import {addSpacesBetweenThousands} from '../../../utils';
import { getIconNameByDocType } from 'src/common_components/Table/TableCells/Document/utils';
import { Translate } from '../../../common_components/Translate/Translate';

export const limit = 10;

const DocTypeWrap = styled.div`
  display: flex;
`;

export const docStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  CHECKED_OK: 'CHECKED_OK',
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  UNDEFINED: 'UNDEFINED',
  CANCELLED: 'CANCELLED',
  ACCEPTED: 'ACCEPTED',
  WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
  WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
};

export const documentStatus = {
  IN_PROGRESS: 'Проверяется',
  CHECKED_OK: 'Обработан успешно',
  CHECKED_NOT_OK: 'Обработан с ошибками',
  PROCESSING_ERROR: 'Ошибка при обработке',
  UNDEFINED: 'Не определен',
  // CANCELLED: 'Аннулирован',
  // ACCEPTED: 'Принят',
  // WAIT_ACCEPTANCE: 'Ожидает приемку',
  // WAIT_PARTICIPANT_REGISTRATION: 'Ожидаем регистрации участника в ИС МП',
};

export const iconNamesByDocStatus = {
  CANCELLED: 'doc-error',
  IN_PROGRESS: 'checking',
  CHECKED_OK: 'issued',
  CHECKED_NOT_OK: 'doc-error',
  PROCESSING_ERROR: 'doc-error',
  UNDEFINED: 'checking',
  ACCEPTED: 'issued',
  WAIT_ACCEPTANCE: 'checking',
};

export const options = [
  {
    accessor: d => d,
    id: 'did',
    render: (props, rowInfo) => {
      const lastElement = get(rowInfo, 'index', 0) === 9;
      const docStatus = get(rowInfo, 'original.status', '');
      const iconType = get(iconNamesByDocStatus, docStatus, 'checking');
      const receiptId = get(rowInfo, 'original.did', '');

      return (
        <OrderId
          {...props}
          isReceipt
          onTop={lastElement}
          showIcon
          iconType={iconType}
          href={`/documents/receipt/${encodeURIComponent(receiptId)}`}
        />
      );
    },
  },
  {
    accessor: d => d,
    id: 'type',
    render: (props, rowInfo) => {
      const docType = get(rowInfo, 'original.type', '');
      return (
        <DocTypeWrap>
          <Icon
            type={getIconNameByDocType(docType)}
            spacingRight={12}
            fill="rgb(82, 83, 90)"
            size={16}
          />
          <div>{Translate(documentTypeNames[docType] || docType)}</div>
        </DocTypeWrap>
      );
    },
  },
];

export const columns = [
  {
    Header: Translate('Дата'),
    id: 'receiptDate',
    accessor: d => `${moment(new Date(d.receiptDate)).format('DD.MM.YYYY')}`,
    width: 105,
  },
  {
    Header: Translate('Тип документа'),
    id: 'type',
    accessor: 'type',
    width: 300,
  },
  {
    Header: Translate('Номер документа'),
    id: 'did',
    accessor: 'did',
    // width: 250,
    minWidth: 250,
    style: {
      position: 'relative',
      overflow: 'visible',
      padding: '0',
    },
  },
  // {
  //   Header: 'Сумма, Р',
  //   id: 'total',
  //   accessor: d => !d.total ? '-' : addSpacesBetweenThousands(d.total.toFixed(2) / 100),
  //   width: 200,
  // },
  {
    Header: Translate('Участник(и)'),
    id: 'senderName',
    accessor: d => <div>{d.senderName}</div>,
    minWidth: 400,
  },
];
