import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import {
  cisKey,
  goodsKey,
  steps,
  tabs,
  contentBodyConfirm,
} from '../ImportThirdCountry.constants';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import * as selectors from '../ducks/ImportThirdCountry.selectors';
import {
  PageWrapper,
  Content,
  DividerOfContent,
  StepWrapper,
} from '../../Document/Document.styled';
import ContentWrapper from 'src/common_components/styled/ContentWrapper';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
import { TitleAndWarning } from './TitleAndWarning';
import CommonActionButton from 'src/common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from 'src/common_components/Buttons/MuiPrimaryActionLargeButton';
import ConfirmModal from 'src/common_components/Modals/ConfirmModal/ConfirmModal';
import { KzFields } from './KzFields';
import { UzFields } from './UzFileds';
import { Translate } from 'src/common_components/Translate/Translate';

class Information extends React.Component {
  static propTypes = {
    inn: PropTypes.string.isRequired,
    history: PropTypes.shape({}).isRequired,
    loaded: PropTypes.bool.isRequired,
    formErrors: PropTypes.shape({}).isRequired,
    formMutators: PropTypes.shape({}).isRequired,
    onSetStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    onCloseNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isChecked: false,
    };
  }

  onSaveDraft = () => {
    const { onSaveDraft, formValues } = this.props;
    onSaveDraft(formValues);
  };

  onNextStep = () => {
    const { onSetStep } = this.props;
    onSetStep(steps.goods);
  };

  errorsCount = () => {
    const { formErrors } = this.props;
    const prepared = JSON.parse(JSON.stringify(formErrors));
    delete prepared[cisKey];
    delete prepared[goodsKey];
    delete prepared['vat'];
    return Object.keys(prepared).length;
  };

  isNextStepDisabled = () => {
    const { show } = this.props;
    if (!show) return true;
    return this.errorsCount() > 0;
  };

  componentDidMount() {
    const { inn, shortName, formMutators } = this.props;
    formMutators.updateField('recipient_inn', inn);
    formMutators.updateField('recipient_name', shortName);
  }

  tabsCurrent = () => {
    const { show } = this.props;
    if (!show) return tabs;
    const isNextStepDisabled = this.isNextStepDisabled();
    const { onSetStep } = this.props;
    return tabs
      .map(tab =>
        tab.id === steps.info
          ? tab
          : {
              ...tab,
              onClick: () => {
                onSetStep(tab.id);
              },
            },
      )
      .map(tab =>
        tab.id === steps.info || (tab.id === steps.goods && !isNextStepDisabled)
          ? tab
          : { ...tab, disabled: true },
      );
  };

  toggleDialog = () =>
    this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

  onCancel = () => {
    const { history, formValues, onCloseNotification } = this.props;
    onCloseNotification(history, formValues);
  };

  render() {
    const { isModalOpen } = this.state;
    const {
      show,
      countryCode,
      hasPaperDoc,
      handleCheckboxChange,
      formValues,
      formMutators,
    } = this.props;
    const errorsCount = this.errorsCount();

    if (
      !hasPaperDoc &&
      (formValues.notification_number || formValues.notification_data)
    ) {
      formMutators.updateField('notification_number', null);
      formMutators.updateField('notification_data', null);
    }

    return (
      <PageWrapper hide={!show}>
        <ContentWrapper>
          <StepWrapper>
            <Steps
              value={steps.info}
              items={this.tabsCurrent()}
              variant={'fullWidth'}
            />
          </StepWrapper>

          <Content>
            <ConfirmModal
              isModalOpen={isModalOpen}
              onClose={this.toggleDialog}
              onSubmit={this.onCancel}
              content={contentBodyConfirm}
              header={Translate('Предупреждение')}
              cancelButtonText={Translate('Нет')}
              submitButtonText={Translate('Да')}
              notCancelable
            />
            <TitleAndWarning
              countryCode={countryCode}
              left={
                errorsCount > 0
                  ? Math.ceil(Math.round((errorsCount / 7) * 100) / 5) * 5
                  : 0
              }
            />
            <DividerOfContent />
            <br />
            {countryCode === 'KZ' ? (
              <KzFields
                handleCheckboxChange={handleCheckboxChange}
                hasPaperDoc={hasPaperDoc}
              />
            ) : (
              <UzFields />
            )}
            <DividerOfContent />
            <CommandBar
              left={[
                <CommonActionButton onClick={this.toggleDialog}>
                  {Translate('Отменить')}
                </CommonActionButton>,
                <CommonActionButton onClick={this.onSaveDraft}>
                  {Translate('Сохранить как черновик')}
                </CommonActionButton>,
              ]}
              right={[
                <PrimaryActionButton
                  disabled={this.isNextStepDisabled}
                  onClick={this.onNextStep}
                >
                  {Translate('Следующий шаг')}
                </PrimaryActionButton>,
              ]}
            />
          </Content>
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

const mapState = state => ({
  inn: authSelectors.userInn(state),
  shortName: authSelectors.userShortName(state),
  data: selectors.data(state),
  countryCode: state.config.countryCode,
});
export default compose(connect(mapState), withRouter)(Information);
