import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TwoLineLabel } from '@crpt/react-label';
import { FormLine } from '@crpt/react-form';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Trans } from 'react-i18next';

const MilkAdditionalInfo = ({ selectedProduct }) => {
  const packUnitVolume = get(selectedProduct, 'packUnitVolume', null);
  const milkProductType = get(selectedProduct, 'milkProductType', null);

  return (
    <Grid container>
      <Grid item xs={8}>
        <FormLine>
          <TwoLineLabel
            heading={
              <Trans>
                Нетто продукции в<br />
                единице потребительской упаковки
              </Trans>
            }
          >
            {packUnitVolume || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
      <Grid item xs={8}>
        <FormLine>
          <TwoLineLabel heading={<Trans>Вид продукции</Trans>}>
            {milkProductType || '—'}
          </TwoLineLabel>
        </FormLine>
      </Grid>
    </Grid>
  );
};

MilkAdditionalInfo.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
};

export default MilkAdditionalInfo;
