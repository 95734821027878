import { actionTypes, strings } from '../../constants';

export const certVisible = (state = true, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.TOGGLE_CERT:
      return !state;
    default:
      return state;
  }
};
