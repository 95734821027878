import { get } from 'lodash';
import { CERTIFICATE_TYPES } from './Crossborder.constants';
import moment from 'moment';
import i18next from '../../../languages/index';

export const prepareCisList = (products) => {
  return products ? products.map((item, idx) => {
    const cert = item.certificateDocumentData[0];
    const date = get(cert, 'certificateDate', null);
    const certificateNum = get(cert, 'certificateNum', '—');
    const certificateType = get(cert, 'certificateType', '—');
    const certificateDate = date ? moment(date).format('DD.MM.YYYY') : '—';

    return {
      ...item,
      idx: idx + 1,
      certificateDocuments: `${i18next.t(CERTIFICATE_TYPES[certificateType]) || '—'} № ${certificateNum} ${i18next.t('от')} ${certificateDate}`
    }
  }) : []
}
