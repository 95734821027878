import {
  makeCell,
  makeCheckboxGroupFilter,
  makeInputFilter,
  makeRadioGroupFilter,
} from '@crpt-ui/datatable';
import { RangeDatepickerFilter } from '@crpt-ui/datatable/lib/Filters/RangeDatepickerFilter';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import {
  availableEnum,
  availableValuesMap,
  downloadStatusColorsMap,
  downloadStatusIconsMap,
  downloadStatusValuesMap,
} from '../../constants';
import { formatDate, formatPeriodDate } from '../../_utils/utils';
import { convertByteToMb } from 'src/utils/convertByteToMb'

export const useColumns = () =>
  useMemo(
    () => [
      {
        accessor: 'generationEndDate',
        id: 'generationEndDate',
        Cell: ({ cell }) => formatDate(_.get(cell, 'value', '')) || '—',
        filter: 'rangeDateFilter',
        Filter: RangeDatepickerFilter,
        Header: 'Дата готовности выгрузки',
        width: 220,
      },
      {
        accessor: 'fileDeleteDate',
        id: 'fileDeleteDate',
        Cell: ({ cell }) => formatDate(_.get(cell, 'value', '')) || '—',
        filter: 'rangeDateFilter',
        Filter: RangeDatepickerFilter,
        Header: 'Запланир. удаление',
        width: 200,
      },
      {
        accessor: 'executorVersion',
        id: 'executorVersion',
        Filter: makeInputFilter({
          label: 'Версия исполнит.',
        }),
        Header: 'Версия исполнит.',
        width: 200,
      },
      {
        accessor: 'fileLines',
        id: 'fileLines',
        filter: (rows, key, value) =>
          _.chain(rows)
            .filter(row => row.values[key] === Number(value))
            .value(),
        Filter: makeInputFilter({
          label: 'Колич. строк',
        }),
        sortType: 'numberSort',
        Header: 'Колич. строк',
        width: 200,
      },
      {
        accessor: 'dataStartDate',
        id: 'dataStartDate',
        Cell: ({ cell }) =>
          formatPeriodDate(
            _.get(cell, 'row.original', ''),
            'dataStartDate',
            'dataEndDate',
          ) || '—',
        filter: (rows, key, value) =>
          _.chain(rows)
            .filter(row => {
              if (!row.original.dataStartDate || !row.original.dataEndDate) {
                return false;
              }

              const { startDate, endDate } = value;
              const startDateFormat = moment(startDate).format('YYYY-MM-DD');
              const endDateFormat = moment(endDate).format('YYYY-MM-DD');
              const rowDateStart = moment(
                moment(row.original.dataStartDate).format('YYYY-MM-DD'),
              );
              const rowDateEnd = moment(
                moment(row.original.dataEndDate).format('YYYY-MM-DD'),
              );

              if (
                rowDateStart.diff(startDateFormat, 'days') >= 0 &&
                rowDateEnd.diff(endDateFormat, 'days') <= 0
              ) {
                return true;
              }

              return false;
            })
            .value(),
        Filter: RangeDatepickerFilter,
        Header: 'Период данных выгрузки',
        width: 250,
      },
      {
        accessor: 'archiveSize',
        id: 'archiveSize',
        Cell: ({ cell }) => {
          const available = _.get(cell, 'row.original.available');
          if (_.isEmpty(available) || available !== availableEnum.AVAILABLE) {
            return '—';
          }

          return convertByteToMb(cell.value);
        },
        filter: (rows, key, value) =>
          _.chain(rows)
            .filter(row => convertByteToMb(row.values[key]) === value)
            .value(),
        Filter: makeInputFilter({
          label: 'Размер файла, мб',
        }),
        sortType: 'numberSort',
        Header: 'Размер файла, мб',
        width: 200,
      },
      {
        accessor: 'downloadStatus',
        id: 'downloadStatus',
        Cell: ({ cell }) => <DownloadStatus cell={cell} />,
        filter: (rows, key, value) =>
          _.chain(rows)
            .filter(row => value.includes(row.values[key]))
            .value(),
        Filter: makeCheckboxGroupFilter(
          _.map(downloadStatusValuesMap, (label, value) => ({
            label: label,
            value: value,
          })),
        ),
        Header: 'Статус',
        width: 200,
      },
      {
        accessor: 'available',
        id: 'available',
        Cell: ({ cell }) => availableValuesMap[_.get(cell, 'value', '')],
        filter: (rows, key, value) =>
          _.chain(rows)
            .filter(row => value === row.values[key])
            .value(),
        Filter: makeRadioGroupFilter(
          _.map(availableValuesMap, (label, value) => ({
            label: label,
            value: value,
          })),
        ),
        Header: 'Возмож. скачать файл',
        width: 'auto',
      },
    ],
    [],
  );

const DownloadStatus = ({ cell }) => {
  const errorMessage = _.get(cell, 'row.original.errorMessage', '');
  return (
    <Tooltip title={errorMessage}>
      {makeCell({
        colors: downloadStatusColorsMap,
        icons: downloadStatusIconsMap,
        values: downloadStatusValuesMap,
      })({ cell })}
    </Tooltip>
  );
};
