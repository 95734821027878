import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  actionWrapper: {
    paddingBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > button': {
      backgroundColor: '#0287B0',
      color: 'white',
      '&:hover': {
        boxShadow: '0px 4px 32px rgba(2, 135, 176, 0.5)'
      }
    }
  },
  contentWrapper: {
    color: theme.palette.text.primary,
    height: '100%',
    padding: '0 16px'
  },
  datatableWrapper: {
    height: 'calc(100% - 64px)',
    marginTop: 0
  },
  iconWrapper: {
    marginBottom: '32px'
  },
  paginationContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: '32px 40px',
  },
  paginationCount: {
    color: theme.palette.text.light,
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
    textAlign: 'right',
    width: '100%',
  },
  actionsRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
}));
