import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  button:first-child {
    margin-right: 30px;
  }
  button {
    color: white;
  }
`;

export const PackagingCodeTitle = styled.div`
  padding: 11px 35px;
  background: #67ac5c;
  color: white;
  font-size: 20px;
`;

export const PackagingCodeContent = styled.div`
  padding: 30px;
`;
