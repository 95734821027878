export const ownerPreferences = [
  {
    key:'emissionDate',
    width: 80
  },
  {
    key:'status',
    width: 180
  },
  {
    key:'statusExt',
    width: 180
  },
  {
    key:'cis',
    width: 220
  },
  {
    key:'packType',
    width: 150
  },
  {
    key:'gtin',
    width: 120
  },
  /*{
      key:'mrc'
      //width: 100
  },*/
  {
    key:'producerId',
    width: 200
  },
  {
    key:'childrenCount',
    width: 80
  },
  {
    key: 'owner',
    width: 200
  }
];

export const producerPreferences = [
  {
    key:'emissionDate'
    //width: 200
  },
  {
    key:'status',
    width: 250
  },
  {
    key:'statusExt',
    width: 300
  },
  {
    key:'cis',
    width: 300
  },
  {
    key:'packType',
    width: 200
  },
  {
    key:'gtin',
    width: 300
  },
  /*{
      key:'mrc'
      //width: 100
  },*/
  {
    key:'producerId',
    width: 300
  },
  {
    key:'childrenCount',
    width: 200
  },
  {
    key:'owner',
    width: 200
  },
];

export const STEPS = {
  FINAL: 0,
  SELECT_PARTICIPANT_TYPE: 1,
  SELECT_PARTICIPANT: 2,
};
