import {combineReducers} from 'redux';
import {selectedDocument} from './selectedDocument';
import {CISList} from './CISList';
import {cisTotal} from './cisTotal';
import {filterParams} from './filterParams';
import {cisSelectedPage} from './cisSelectedPage';
import {cisPagesCount} from './cisPagesCount';
import {isInfinite} from './isInfinite';

export default combineReducers({
  selectedDocument,
  CISList,
  cisTotal,
  filterParams,
  cisSelectedPage,
  cisPagesCount,
  isInfinite
});
