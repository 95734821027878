import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DividerOfContent, FieldsInfoContainer } from '../Document/Document.styled';
import { ContentWrapper, steps } from './DocumentWrapperConstants';
import { Translate } from '../../../../common_components/Translate/Translate';
import ConfirmModal from '../../../../common_components/Modals/ConfirmModal/ConfirmModal';
import { MaterialUiThemeKZ } from '../../../../common_components/_MaterialUi/ThemeKZ';
import LightTheme from '../../../../common_components/_MaterialUi/LightTheme';
import CommandBar from '../../../../common_components/CommandBar/CommandBar';
import CommonActionButton from '../../../../common_components/Buttons/MuiCommonActionButton';
import PrimaryActionButton from '../../../../common_components/Buttons/MuiPrimaryActionLargeButton';
import { Toast } from '../../../../utils/Toast';
import { TitleAndWarning } from './TitleAndWarning';
import { MuiThemeProvider } from '@material-ui/core';
// заменить на импорт в компоненте, в котором будет использваться wrapper
import * as selectors from '../EaesImportDocument/ducks/EaesImportDocument.selectors';
import { contentBodyConfirm } from '../EaesImportDocument/EaesImportDocument.constants';
import FinalConfirmModal from './FinalConfirmModal';
import { withModal } from '../../../../common_components/Modals/hocs/withModal';


const Info = (props) => {
  const { show, onSetStep, countryCode, handleChangeInfoErrors, errorsCountFunc, formErrors, history, typeDoc } = props;
  const { FieldsInfo, title, needCancelButton, needDraftButton, onSaveDraft, formValues, formMutators } = props;
  const { thereAreCodes, onSubmit, rightDraftButton, onCloseModal, onSetContent, finalConfirmModal, onOpenModal } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    handleChangeInfoErrors(formErrors);
  }, [formErrors]);

  const toggleDialog = () => setIsModalOpen(!isModalOpen);

  const onCancel = () => {
    const locationHref = window.location.href;
    const isDraft = /draft-/.test(locationHref);

    history.push(isDraft ? '/documents/draft' : '/documents/list');
    Toast.showInfo({
      content: isDraft
        ? Translate('Редактирование документа отменено.')
        : Translate('Создание документа отменено.'),
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorsCountInfo = errorsCountFunc({ formErrors, formValues });

  const isNextStepDisabled = errorsCountInfo > 0;

  const handleOnSubmit = () => {
    onSubmit({ ...formValues, docType: typeDoc })
  }

  const onOpenFinalConfirmModal = () => {
    onSetContent(
      <FinalConfirmModal
        formValues={formValues}
        title={title}
        typeDoc={typeDoc}
        onCloseModal={onCloseModal}
        onSubmit={handleOnSubmit}
        FieldsInfo={FieldsInfo}
      />
    )
    onOpenModal();
  }

  return (
    <ContentWrapper hide={!show}>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onClose={toggleDialog}
        onSubmit={onCancel}
        content={contentBodyConfirm}
        header={Translate('Предупреждение')}
        cancelButtonText={Translate('Нет')}
        submitButtonText={Translate('Да')}
        notCancelable
      />
      <TitleAndWarning
        showLeft={false}
        typeDoc={typeDoc}
        title={title}
        left={
          errorsCountInfo > 0
            ? Math.ceil(Math.round((errorsCountInfo / 7) * 100) / 5) * 5
            : 0
        }
      />
      <DividerOfContent/>
      {/*<StepTitle>{Translate('Общие данные')}</StepTitle>*/}
      <FieldsInfoContainer>
        <MuiThemeProvider
          theme={countryCode === 'KZ' ? MaterialUiThemeKZ : LightTheme}
        >
          <FieldsInfo
            docType={typeDoc}
            formValues={formValues}
            formErrors={formErrors}
            formMutators={formMutators}
          />
        </MuiThemeProvider>
      </FieldsInfoContainer>
      <CommandBar
        left={[
          ...needCancelButton ? [
          <CommonActionButton onClick={toggleDialog} disabled={false}>
            {Translate('Отменить')}
          </CommonActionButton>,
          ] : [],
          ...(needDraftButton && !rightDraftButton)
            ? [
              <CommonActionButton
                onClick={() => onSaveDraft(formValues)}
                disabled={false}
              >
                {Translate('Сохранить как черновик')}
              </CommonActionButton>,
            ] : [],
        ]}
        right={[
          ...needDraftButton && rightDraftButton
            ? [
              <CommonActionButton
                onClick={() => onSaveDraft(formValues)}
                disabled={false}
              >
                {Translate('Сохранить как черновик')}
              </CommonActionButton>,
            ] : [],
          <PrimaryActionButton
            onClick={() => thereAreCodes ? onSetStep(steps.goods) : finalConfirmModal ? onOpenFinalConfirmModal() : handleOnSubmit()}
            disabled={isNextStepDisabled}
          >
            {Translate(thereAreCodes ? 'Следующий шаг' : finalConfirmModal ? 'Отправить' : 'Подписать и отправить')}
          </PrimaryActionButton>,
        ]}
      />
    </ContentWrapper>
  );
};

const mapState = state => ({
  data: selectors.data(state),
  countryCode: state.config.countryCode,
});

export default compose(connect(mapState, null), withRouter, withModal)(Info);
