import styled from 'styled-components';
import { checkboxState } from './ToggleCheckbox';


export const PlusWrap = styled.div`
    cursor: pointer;
    border: 2px solid #434244;
    font-weight: 600;
    width: 17px;
    height: 18px;
    line-height: 8px;
    font-size: 20px;
    padding-right: 7px;
    color: #434244;
    border-radius: 4px;
`;

export const MinusWrap = styled.div`
    cursor: pointer;
    border: 2px solid #434244;
    color: #434244;
    font-weight: 600;
    width: 17px;
    height: 18px;
    line-height: 8px;
    font-size: 20px;
    border-radius: 4px;
    padding-top: 1px;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${props => props.width || '100%'};
  ${props => (props.state === checkboxState.EDITED ? 'margin-top: 10px;' : 'height: 100%;')};
  cursor: pointer;
`;
