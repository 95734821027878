import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import defaultTheme from '../theme';
import { getThemeAsPlainObjectByKeys, innerMerge } from '../utils';

const StyledWrapper = styled.div`
  position: relative;
  min-width: 100%;
  margin-top: 0;
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  js-display: flex;
  display: flex;
  overflow-y: auto;
  max-height: 270px;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  z-index: 3;
  background: ${props => props.background};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : '')};
  overflow-y: auto;
`;

export const PanelContainer = ({
  children,
  theme: themeFromProps,
  isOpen,
  disabled,
}) => {
  const themeFromPropsHasSelect = themeFromProps && themeFromProps.Select;

  const merged = innerMerge(
    {},
    defaultTheme.Select,
    themeFromPropsHasSelect || {},
  );

  const theme = getThemeAsPlainObjectByKeys(
    merged,
    disabled ? 'disabled' : 'main',
  );

  const mergedSelectOptionsPanel = innerMerge(
    {},
    defaultTheme.Select.SelectOptionsPanel,
    (themeFromPropsHasSelect && themeFromProps.Select.SelectOptionsPanel) || {},
  );

  Object.assign(theme, getThemeAsPlainObjectByKeys(mergedSelectOptionsPanel));

  if (isOpen) {
    Object.assign(
      theme,
      getThemeAsPlainObjectByKeys(mergedSelectOptionsPanel, 'open'),
    );
  }

  return <StyledWrapper {...theme}>{children}</StyledWrapper>;
};

PanelContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.elementType),
  ]),
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    Select: PropTypes.shape({
      SelectOptionsPanel: PropTypes.object,
    }),
  }).isRequired,
};
