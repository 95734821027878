import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import * as sidebarActions from '../common_components/Sidebar/ducks/Sidebar.actions';

import { Icon } from '@crpt/react-icon';
import ParticipantSidebarContentBound from '../containers/Bounds/ParticipantSidebarContentBound';

const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 3px;
`;

const ProducerName = styled.div`
  width: 100%;
  white-space: ${props => props.wrap ? 'normal' : 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconWrap = styled.div`
  visibility: hidden;

  .hovered & {
    visibility: visible;
  }
`;

class ParticipantName extends Component {
  static propTypes = {
    theme: PropTypes.shape({
      Sidebar: PropTypes.object.isRequired,
    }).isRequired,
  };

  openSidebar = (inn) => {
    const { theme } = this.props;
    const content = <ParticipantSidebarContentBound id={inn} />;
    this.props.actions.ParticipantDetails.ParticipantDetailsMounted(inn)
      .then(() => this.props.dispatch(sidebarActions.open(content)));
  };

  render() {
    const { name, inn, wrap } = this.props;
    return (
      <Wrap>
        <ProducerName wrap={wrap}>
          {name || '-'}
        </ProducerName>
        {inn &&
          <IconWrap>
            <Icon
              type="info"
              size={16}
              onClick={e => this.openSidebar(inn)}
            />
          </IconWrap>
        }
      </Wrap>
    );
  }
}

export default withTheme(withRouter(ParticipantName));
