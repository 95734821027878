import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
          </ul>,
          'col3': 'Субъект РК, в котором был реализован товар'
        },
        {
          'col1': [2],
          'col2': <ul>
            <li>По населённому пункту</li>
          </ul>,
          'col3': 'Наименование населённого пункта (по ФИАС), в котором был реализован товар'
        },
        {
          'col1': [3],
          'col2': <ul>
            <li>По населённому пункту</li>
          </ul>,
          'col3': 'Диапазон численности населённого пункта'
        },
        {
          'col1': [4, 5],
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
            <li>По товару</li>
          </ul>,
          'col3': 'Уникальный код и наименование товара'
        },
        {
          'col1': [6],
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
            <li>По товару</li>
          </ul>,
          'col3': 'Наименование производителя товара'
        },
        {
          'col1': [7],
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
            <li>По товару</li>
          </ul>,
          'col3': 'Количество розничных торговых точек, в которых была осуществлена реализация товара'
        },
        {
          'col1': [8, 9],
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
            <li>По товару</li>
          </ul>,
          'col3': 'Количество и стоимость реализованного товара (в пачках) конечному покупателю за указанный период'
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <ul>
            <li>По населённому пункту</li>
            <li>По субъекту РК</li>
            <li>По товару</li>
          </ul>,
          'col3': 'Подсчёт итогового значения происходит по всем записям, без ограничения'
        },
      ]
    }
  ]
}
