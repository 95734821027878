import React from 'react';
import {
  Loupe,
} from './CommonDescription.styled';
import Filter from './components/Filter/';
import ResetApply from './components/ResetApply';

import step01 from './img/step01.png';
import step02 from './img/step02.png';
import step06 from './img/step06.png';

export default [
  {
    text: 'В отчётах и справочниках в левом блоке представлены фильтры',
    visual: <img src={step01} alt="Отчёты" style={{'width': '806px'}} />,
  },
  {
    text: 'С помощью фильтра вы можете настроить подходящие критерии поиска',
    visual: <img src={step02} alt="Отчёты" style={{'width': '333px'}} />,
  },
  {
    text: 'В фильтрах есть поиск по списку, используйте его для того, чтобы быстро найти необходимый критерий',
    visual: <Loupe />,
  },
  {
    text: 'В фильтрах можно использовать быстрый выбор критериев',
    visual: <Filter />,
  },
  {
    text: 'Для активации выбранных фильтров нажмите кнопку «Применить». Для того чтобы отменить выбранные настройки фильтров используйте кнопку «Сбросить»',
    visual: <ResetApply />,
  },
  {
    text: 'В верхнем углу отчета располагается кнопка «Скачать». Любой отчёт пользователь может скачать в указанных форматах: PDF, CSV, XLSX, DOCX, PPTX (Презентация)',
    visual: <img src={step06} alt="Отчёты" style={{'width': '1100px'}} />,
  },
]