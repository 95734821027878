export const required = {
  Placeholder: {
    fontWeight: 200,
    main: {
      color: 'rgba(82, 83, 90, 0.7)',
    },
    error: {
      color: '#FF3D00',
    },
    disabled: {
      color: 'rgba(82, 83, 90, 0.5)',
    },
    normal: {
      fontSize: '16px',
      height: '24px',
      top: '19px',
      color: '#52535A',
    },
    focused: {
      top: '11px',
      fontSize: '14px',
      height: '14px',
    },
  },
  InputWrap: {
    main: {
      border: '1px solid rgba(196, 196, 196, 0.25)',
      borderLeft: '3px solid #F8EC31',
    },
    disabled: {
      background: 'white',
      border: '1px solid #ABADB5',
      cursor: 'not-allowed',
    },
    error: {
      border: '1px solid rgba(196, 196, 196, 0.25)',
      borderLeft: '3px solid #FF3D00',
      background: 'white',
    },
    height: '60px',
  },
  Day: {
    current: {
      background: 'rgba(248, 236, 49, 0.3)',
    },
  },
};
