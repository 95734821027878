import React from 'react';
import PropTypes from 'prop-types';
import InfoLabelView from './InfoLabel.view';
import { ShowButton } from './InfoLabel.styled';
import {Translate} from '../../../../Translate/Translate';

const displayNone = { display: 'none' };

class InfoLabel extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    customStyle: PropTypes.shape({}),
  };

  static defaultProps = {
    value: '',
    customStyle: undefined,
  };

  state = {
    showDescription: false,
  };

  onToggle = () => this.setState(({ showDescription }) => ({ showDescription: !showDescription }));

  render() {
    const { value, customStyle } = this.props;
    const { showDescription } = this.state;

    let short;
    let long;
    let prepared;
    let index;
    if (value.indexOf('Подробнее:') !== -1) {
      index = value.indexOf('Подробнее:');
      short = value.substring(0, index);
      long = value.substring(index + 10);
    } else if (value.indexOf('.') !== -1) {
      index = value.indexOf('.');
      short = value.substring(0, index + 1);
      long = value.substring(index + 2);
    }

    if (index && long) {
      prepared = (
        <React.Fragment>
          <span>{short}</span>
          <ShowButton
            onClick={this.onToggle}
            style={showDescription ? displayNone : undefined}
          >
            {Translate('Подробнее')}
          </ShowButton>
          <div style={showDescription ? undefined : displayNone}>{long}</div>
        </React.Fragment>
      );
    } else {
      prepared = value;
    }

    return (
      <InfoLabelView value={prepared} customStyle={customStyle}/>
    );
  }
}

export default InfoLabel;
