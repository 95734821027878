import { Button } from '@crpt-ui/core';
import * as Icons from '@crpt-ui/icons';
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from './UploadGtinsModal.styles';
import {Translate} from '../../../../../common_components/Translate/Translate';

const UploadGtinsModal = ({ gtinsFromFile, onClose, onUploadGtins }) => {
  const classes = useStyles();

  return (
    <Box px={5} pb={3}>
      <Box py={2}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Button
              onClick={onUploadGtins}
              icon={<Icons.Upload />}
            >
              {Translate('Загрузить')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onClose}
              variant="outlined"
            >
              {Translate('Отменить')}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <div className={classes.items}>
        {gtinsFromFile.map((item, index) => <div key={index}>{item}</div>)}
      </div>
    </Box>
  )
}

export default UploadGtinsModal;
