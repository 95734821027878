import styled from 'styled-components';

export const DocWrap = styled.div`
  padding: ${props => (props.withoutPadding ? '' : '34px')};
  min-height: calc(100vh - 190px);
`;

export const IdValue = styled.p`
  margin: 0;
  text-transform: initial;
`;
