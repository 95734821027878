/* eslint require-atomic-updates: 0 */
import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { get, flattenDeep, omit, isEmpty } from 'lodash';
import moment from 'moment';
import { push, replace } from 'react-router-redux';
import * as actions from './Draft.actions';
import * as importActions from '../../ImportDocument/ducks/ImportDocument.actions';
import * as EASImportActions from '../../EaesImportDocument/ducks/EaesImportDocument.actions';
import * as AcceptanceNotificationOfGoodsByTheEAESActions  from '../../AcceptanceNotificationOfGoodsByTheEAES/ducks/AcceptanceNotificationOfGoodsByTheEAES.actions';
import * as ImportThirdCountryActions from '../../ImportThirdCountry/ducks/ImportThirdCountry.actions';
import * as NotificationOfEntryActions from '../../NotificationOfEntry/ducks/NotificationOfEntry.actions';
import * as ActDocumentActions from '../../ActDocument/ducks/ActDocument.actions';
import * as AikActions from '../../Aik/ducks/Aik.actions';
import * as InventoryActions from '../../Inventory/ducks/Inventory.actions';
import * as TradeInventoryActions from '../../TradeInventory/ducks/TradeInventory.actions';
import * as AggregationActions from '../../Aggregation/ducks/Aggregation.actions';
import * as CorInfoCmActions from '../../CorInfoCm/ducks/CorInfoCm.actions';
import * as AikDisbandmentActions from '../../AikDisbandment/ducks/AikDisbandment.actions';
import * as ExportNotificationToTheEaesActions from '../../ExportNotificationToTheEaes/ducks/ExportNotificationToTheEaes.actions';
import * as CisRegistrationActions from '../../CisRegistration/ducks/CisRegistration.actions';
import * as selectors from './Draft.selectors';
import * as listActions from 'src/common_components/List/ducks/List.actions';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';
import {
  generateUUID,
  confirmCurrency,
  prepareUZImportDraft,
} from '../Draft.utils';
// import { prepareDateForJSON } from 'src/utils';
// import {
//   kopecksToRubels,
//   rubelsToKopecks,
//   numbersToStrings,
// } from '../../RequestList.utils';
import { documentTypes } from 'src/constants/documentTypes';
import { APP_LINKS } from 'src/constants';
import { countryCodeFlags } from '../../../../../constants/countryCodeFlags';
import i18next from 'i18next';
// import ActDocument from '../../ActDocument/ActDocument';

function* __getCodesAndProducts(payload, meta = {}) {
  const { propProducts, preloading = true } = payload;
  if (isEmpty(propProducts)) {
    return { error: i18next.t('Товары не были загружены') };
  }

  const parsedPropProducts = propProducts.map(item =>
    item.codes
      ? {
          ...item,
          codes: item.codes.map(code => code.trim()),
        }
      : {
          ...item,
          cises: item.cises.map(code => code.trim()),
        },
  );

  const codes = flattenDeep(
    propProducts.map(product =>
      product.codes ? product.codes : product.cises,
    ),
  ).map(code => ({
    gtin: '',
    sgtin: '',
    tnvedGroup: '',
    productName: '',
    ownerName: '',
    ownerInn: '',
    producerName: '',
    producerInn: '',
    status: '',
    emissionDate: '',
    producedDate: '',
    name: '',
    brand: '',
    model: '',
    statusEx: '',
    cis: code.trim(),
  }));

  const onlyCodes = codes.map(code => code.cis).filter(code => code);

  try {
    if (onlyCodes.length) {
      const requestSize = Math.ceil(onlyCodes.length / 100) || 1;

      const finalResult = {};

      for (let i = 0; i < requestSize; i++) {
        const codesToRequest = onlyCodes.slice(i * 100, (i + 1) * 100);

        if (codesToRequest.length) {
          const requestPayload = {
            url: '/api/v3/facade/marked_products/search-by-cis-list-extended',
            method: 'POST',
            data: codesToRequest,
            preloading,
          };

          const [success, error] = yield call(Api.request, requestPayload);
          if (success) {
            success.data.results.forEach(
              code => (finalResult[code.cis] = code),
            );
            const invalid = get(success, 'data.invalid', []);
            if (invalid.length > 0) {
              const errorCisData = `Коды не найдены: ${invalid.join(',')}`;
              yield call(Toast.showError, { content: errorCisData });
            }
          }

          if (error) {
            const errorCisData = get(error, 'response.data.description');
            yield call(Toast.showError, { content: errorCisData });

            console.log(
              '/api/v3/facade/marked_products/search-by-cis-list error',
              error,
            );
          }
        }
      }

      const resultCodes = codes.map(code => get(finalResult, code.cis, code));
      const resultProducts = parsedPropProducts.map(product => {
        try {
          const filtered = resultCodes.find(
            code =>
              (product.cises && product.cises.includes(code.cis)) ||
              (product.codes && product.codes.includes(code.cis)),
          );
          product.name =
            filtered && filtered.productName
              ? filtered.productName
              : product.name;
          product.gtin =
            filtered && filtered.gtin ? filtered.gtin : product.gtin;
          return product;
        } catch (e) {
          console.log('error', e);
          return product;
        }
      });

      return { resultCodes, resultProducts };
    }
  } catch (e) {
    console.log('cannot request codes info', { e });
    return { error: e };
  } //finally { }
}

function* requestSaga(action) {
  const { payload = {} } = action;
  const { page } = payload;

  if (page) yield put(actions.updatePage(page));
  const sorting = yield select(selectors.sorting);
  const filters = yield select(selectors.filters);
  const selectedPage = yield select(selectors.selectedPage);
  const sort = {
    document_name: sorting.orderDirection,
    order_column: sorting.orderColumn,
  }
  const requestPayload = {
    url: '/api/v3/facade/draft/search',
    method: 'get',
    params: {
      limit: 10,
      page: selectedPage - 1,
      ...sort,
      ...filters,
    },
  };

  yield put(listActions.gotRequest(requestPayload, actions));
}

function* sortSaga(action) {
  const { payload } = action;

  const sorting = yield select(selectors.sorting);
  const { orderColumn, orderDirection } = sorting;

  let newOrder = 'ASC';
  if (payload === orderColumn) {
    newOrder = orderDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  yield put(
    actions.setSorting({ orderDirection: newOrder, orderColumn: payload }),
  );
}

function* removeSaga(action) {
  const { payload } = action;

  const requestPayload = {
    url: '/api/v3/facade/draft',
    method: 'delete',
    data: payload,
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) yield put(listActions.mounted(undefined, actions));
  if (error) console.log(error);
}

function* loadDraftSaga({ payload }) {
  const requestPayload = {
    url: `/api/v3/facade/draft/${payload}`,
    method: 'get',
  };

  const [success, error] = yield call(Api.request, requestPayload);

  if (success) {
    const data = get(success, 'data', {});
    const document_body = JSON.parse(data.document_body);
    yield put(actions.loadBodyDraft(data));

    const dataType = data.document_type;

    switch (dataType) {
      case documentTypes.IMPORT_TRANSIT:
        yield put(EASImportActions.loaded(document_body));
        yield put(EASImportActions.setError(''));
        break;
      case documentTypes.AIC_DISAGGREGATION:
        yield put(AikDisbandmentActions.loaded(document_body));
        yield put(AikDisbandmentActions.setError(''));
        break;
      case documentTypes.LP_ACCEPT_GOODS:
        yield put(AcceptanceNotificationOfGoodsByTheEAESActions.loaded(document_body));
        yield put(AcceptanceNotificationOfGoodsByTheEAESActions.setError(''));
        break;
      case documentTypes.IMPORT:
        if (countryCodeFlags.isUZ && !document_body.isDraft) {
          // eslint-disable-next-line no-case-declarations
          let products = get(document_body, 'products', []);
          document_body.products = products.map(product => {
            if (!product.codes) {
              if (product.cises) {
                return { ...product, codes: product.cises };
              }
            }
            return product;
          });

          // eslint-disable-next-line no-case-declarations
          const resultData = yield call(__getCodesAndProducts, {
            propProducts: document_body.products,
          });

          document_body.products = get(
            resultData,
            'resultProducts',
            document_body.products,
          );
          document_body.cis = get(resultData, 'resultCodes', []);

          // eslint-disable-next-line no-case-declarations
          let doc = prepareUZImportDraft(document_body);

          yield put(ImportThirdCountryActions.loaded(doc));
          yield put(ImportThirdCountryActions.setError(''));
        } else {
          yield put(ImportThirdCountryActions.loaded(document_body));
          yield put(ImportThirdCountryActions.setError(''));
        }
        break;
      case documentTypes.MTTPS_90:
        yield put(NotificationOfEntryActions.loaded(document_body));
        yield put(NotificationOfEntryActions.setError(''));
        break;
      case documentTypes.KZ_INTRODUCE_GOODS:
        yield put(NotificationOfEntryActions.loaded(document_body));
        yield put(NotificationOfEntryActions.setError(''));
        break;
      case documentTypes.KZ_EXPORT_NOTICE:
        yield put(ExportNotificationToTheEaesActions.loaded(document_body));
        yield put(ExportNotificationToTheEaesActions.setError(''));
        break;
      case documentTypes.AIC_AGGREGATION:
        yield put(AikActions.documentLoaded(document_body));
        yield put(AikActions.setError(''));
        break;
      case documentTypes.INVENTORY:
        yield put(InventoryActions.documentLoaded(document_body));
        yield put(InventoryActions.setError(''));
        break;
      case documentTypes.TRADE_INVENTORY:
        yield put(TradeInventoryActions.documentLoaded(document_body));
        yield put(TradeInventoryActions.setError(''));
        break;
      case documentTypes.AGGREGATION_DOCUMENT:
        yield put(AggregationActions.documentLoaded(document_body));
        break;
      case documentTypes.COR_INFO_CM:
        yield put(CorInfoCmActions.documentLoaded(document_body));
        break;
      case documentTypes.UZ_ADMIT_APPLICATION:
        yield put(CisRegistrationActions.loaded(document_body));
        yield put(CisRegistrationActions.setCisList(document_body.cisList || []));
        yield put(CisRegistrationActions.setError(''));
        break;
      case documentTypes.KZ_UPD:
        yield put(ActDocumentActions.loaded({
          ...document_body,
          km: document_body.products
        }));
        yield put(ActDocumentActions.setError(''));
        break;
      default:
        break;
    }
  }
  if (error) console.log(error);
}

function* createDraftSaga(action) {
  const {
    values,
    match: {
      path,
      params: { id },
    },
  } = action.payload;

  const typeDocument = path.replace(/\/.+?\/(\w+).+/, '$1');

  let type;
  const goodsKey = 'products';
  let cisKey = 'cis';
  const document = {
    ...values,
  };

  switch (typeDocument) {
    case 'comissioning':
      type = 'LP_INTRODUCE_GOODS';
      break;
    case 'import':
      type = documentTypes.IMPORT;
      break;
    case 'cis_registry':
      type = documentTypes.UZ_ADMIT_APPLICATION;
      break;
    case APP_LINKS.Eaes_import:
      type = documentTypes.IMPORT_TRANSIT;
      break;
    case APP_LINKS.NotificationOfCancellation:
      type = documentTypes.MTTPS_90;
      break;
    case APP_LINKS.NotificationOfEntry:
      type = documentTypes.KZ_INTRODUCE_GOODS;
      break;
    case APP_LINKS.Eaes_act:
      type = documentTypes.KZ_UPD;
      break;
    case APP_LINKS.ExportNotificationToTheEaes:
      type = documentTypes.KZ_EXPORT_NOTICE;
      break;
    case APP_LINKS.AcceptanceNotificationOfGoodsByTheEAES:
      type = documentTypes.LP_ACCEPT_GOODS;
      break;
    case APP_LINKS.aik:
      type = documentTypes.AIC_AGGREGATION;
      break;
    case APP_LINKS.inventory:
      type = documentTypes.INVENTORY;
      break;
    case APP_LINKS.tradeInventory:
      type = documentTypes.TRADE_INVENTORY;
      break;
    case APP_LINKS.aik_disaggregation:
      type = documentTypes.AIC_DISAGGREGATION;
      break;
    case APP_LINKS.aggregation:
      type = documentTypes.AGGREGATION_DOCUMENT;
      break;
    case APP_LINKS.corInfoCm:
      type = documentTypes.COR_INFO_CM;
      break;
    default:
      break;
  }

  const document_body = JSON.stringify(document);

  let requestPayload;
  if (/draft-/.test(id)) {
    const documentId = id.replace(/draft-/, '');
    const bodyDraft = yield select(selectors.bodyDraft);
    requestPayload = {
      url: `/api/v3/facade/draft/${documentId}`,
      method: 'put',
      data: {
        ...bodyDraft,
        date_saved: `${moment().toISOString(false)}`,
        document_body,
      },
    };
  } else {
    const authData = yield select(authSelectors.userInfo);
    requestPayload = {
      url: '/api/v3/facade/draft/',
      method: 'post',
      data: {
        date_saved: `${moment().toISOString(false)}`,
        document_body,
        document_type: type,
        document_uuid: generateUUID(),
        draft_uuid: '',
        error_list: [],
        organisation_inn: `${authData.inn}`,
        registration_date: `${moment().toISOString(false)}`,
        user_id: authData.id,
      },
    };
  }

  const [success, error] = yield call(Api.request, requestPayload);
  if (success) {
    const data = get(success, 'data', {});
    yield put(actions.loadBodyDraft(data));
    Toast.showSuccess({ content: i18next.t('Черновик успешно сохранен') });
    yield put(push('/documents/draft'));
  }

  if (error) console.log('create draft error: ', error);
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.createDraft, createDraftSaga);
  yield takeEvery(actions.sort, sortSaga);
  yield takeEvery(actions.remove, removeSaga);
  yield takeLatest(actions.loadDraft, loadDraftSaga);
  // yield takeEvery(actions.download, downloadSaga);
}
