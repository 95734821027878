import React, { PureComponent, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { MultiPercentChart } from '../Charts/index';
import { Colors } from '../constants';
import {
  ChartWrap,
  Count,
  Element,
  LeftPanelWrap,
  RightPanelWrap,
  Title,
  Wrap,
} from './styled';
const SmoothLineChart = lazy(() => import('../Charts/SmoothLineChart'));
import { addSpacesBetweenThousands } from '../../../utils';
import { Preloader } from '../../../common_components/Preloader/Preloader';

class Statistic extends PureComponent {
  static propTypes = {
    theme: PropTypes.shape({
      MultiPercentChart: PropTypes.object.isRequired,
    }).isRequired,
  };

  prepareChartData = cisPercents => {
    const [countRealized, countCirculated, countWrittenOff] = cisPercents;

    return [
      { color: Colors.countRealized, value: countRealized.cnt },
      { color: Colors.countWrittenOff, value: countWrittenOff.cnt },
      { color: Colors.countCirculated, value: countCirculated.cnt },
    ];
  };

  render() {
    const {
      theme,
      roundGraphStats,
      roundGraphStats: { cis_percents },
    } = this.props;
    if (!cis_percents) return null;

    const [countRealized, countCirculated, countWrittenOff] = cis_percents;

    const totalCount =
      countRealized.cnt + countWrittenOff.cnt + countCirculated.cnt;

    return (
      <Wrap>
        <LeftPanelWrap>
          <Suspense fallback={<Preloader isOpened={true} text="Загрузка" />}>
            <SmoothLineChart roundGraphStats={roundGraphStats} />
          </Suspense>
        </LeftPanelWrap>
        <RightPanelWrap>
          <ChartWrap>
            <MultiPercentChart
              data={this.prepareChartData(cis_percents)}
              theme={theme.MultiPercentChart}
            />
          </ChartWrap>
          <div>
            <Element>
              <Title>Всего</Title>
              <Count>{addSpacesBetweenThousands(totalCount)}</Count>
            </Element>
            <Element>
              <Title iconColor={Colors.countRealized}>Реализованные</Title>
              <Count>{addSpacesBetweenThousands(countRealized.cnt)}</Count>
            </Element>
            <Element>
              <Title iconColor={Colors.countWrittenOff}>Списанные</Title>
              <Count>{addSpacesBetweenThousands(countWrittenOff.cnt)}</Count>
            </Element>
            <Element>
              <Title iconColor={Colors.countCirculated}>В обороте</Title>
              <Count>{addSpacesBetweenThousands(countCirculated.cnt)}</Count>
            </Element>
          </div>
        </RightPanelWrap>
      </Wrap>
    );
  }
}

export default withTheme(Statistic);
