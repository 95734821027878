import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrap } from './ErrorPlugin.styled';


const ErrorPlugin = (props) => {
  const { displayTimeError } = props;
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (window.cadesplugin.catch) {
      window.cadesplugin.catch(() => {
        setDisplayError(true);
        setTimeout(() => setDisplayError(false), displayTimeError || 5000);
      });
    }
  }, []);

  if (!displayError) return (null);

  return (
    <Wrap>Ошибка: Плагин недоступен</Wrap>
  );
};

ErrorPlugin.propTypes = {
  displayTimeError: PropTypes.number,
};

ErrorPlugin.defaultProps = {
  displayTimeError: undefined,
};

export default ErrorPlugin;
