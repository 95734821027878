import { createAction } from 'redux-actions';

export const subject = '[DocumentDetails.Receipt]';

export const mounted = createAction(`${subject} mounted`);
export const unmounted = createAction(`${subject} unmounted`);
export const loaded = createAction(`${subject} loaded`);
export const updateCodes = createAction(`${subject} update codes`);
export const updateCodesPage = createAction(`${subject} update codesPage`);
export const loadedCodes = createAction(`${subject} loaded codes`);
export const downloadJSON = createAction(`${subject} download JSON`);
