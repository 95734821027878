import React, { memo } from 'react';

import { CardInformationView } from './CardInformation.view';

export const CardInformation = memo(
  ({ documentInfo, downloadGrayAreaReport, titleCardInfo }) => {
    return (
      <CardInformationView
        documentInfo={documentInfo}
        titleCardInfo={titleCardInfo}
        downloadGrayAreaReport={downloadGrayAreaReport}
      />
    );
  },
);
