import React from 'react';
import PropTypes from 'prop-types';
import ButtonAdapter from '../Adapters/ButtonAdapter';
import FormContextWrapper from '../../FormContextWrapper';
import FieldsWrapper from '../_refactor/FieldsWrapper';


const Button = (props) => {
  const { name, format, validate, parse, visibility, disabled, ...passProps } = props;

  return (
    <FormContextWrapper>
      <FieldsWrapper
        name={name}
        format={format}
        parse={parse}
        validate={validate}
        visibility={visibility}
        disabled={disabled}
      >
        <ButtonAdapter name={name} {...passProps} />
      </FieldsWrapper>
    </FormContextWrapper>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  validate: PropTypes.func,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

Button.defaultProps = {
  name: '__form_',
  parse: undefined,
  format: undefined,
  validate: undefined,
  visibility: undefined,
  disabled: undefined,
};


export default Button;
