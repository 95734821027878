import React from 'react';
import { Translate } from '../../../../common_components/Translate/Translate';
import { AdditionalBottomBarContent } from './utls';
import MenuButton from '../../../../common_components/Buttons/MenuButton';
import { ChevronDown } from '@crpt-ui/icons';


const AdditionalBottomBarLPAccept = ({ onAcceptAll, onRejectAll, formValues }) => {

  return (
    <AdditionalBottomBarContent>
      <MenuButton
        menu={[
          {
            title: Translate(`Принять все (${formValues.products && formValues.products.length || 0} шт.)`),
            onClick: () => onAcceptAll(),
          },
          {
            title: Translate(`Отклонить все (${formValues.products && formValues.products.length || 0} шт.)`),
            onClick: () => onRejectAll(),
          },
        ]}
        positionY="bottom"
        variant="contained"
        color="primary"
        style={{minWidth: '200px'}}
      >
        <span style={{ fontSize: '14px', fontWeight: '700', margin: '8px' }}>
          {Translate('ДЕЙСТВИЕ')}
        </span>
        <ChevronDown />
      </MenuButton>
    </AdditionalBottomBarContent>
  )
}

export default AdditionalBottomBarLPAccept;
