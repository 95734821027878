import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { Column, Container } from '@crpt/react-page-layout';
import { TwoLineLabel } from '@crpt/react-label';
import HeadingWrap from '../HeadingWrap';
import HeadedLabel from '../HeadedLabel';
import DocumentInfoBlock from './DocumentInfoBlock';
import { FlexWrapper } from '../styled';
import { countryCodes, documentTypeNames } from 'src/constants';
import { nano2milli } from '../../DocumentDetails.utils';
import { addSpacesBetweenThousands } from 'src/utils';
import { Translate } from '../../../../common_components/Translate/Translate';

const ImportUzBasicInfo = ({ id, selectedDocument }) => {
  const {
    status,
    documentDate,
    commonPrice = '-',
    commonVat = '-',
    commonExcise = '-',
    currency = '-',
    currencyRate = '-',
    receivedAt,
    senderInn = '-',
    declarationNumber = '-',
    declarationDate,
    exportCountry: exportCountryCode,
  } = selectedDocument;
  const documentDateLabel =
    (documentDate && moment(nano2milli(documentDate)).format('DD MMMM YYYY')) ||
    '-';
  const documentSum = addSpacesBetweenThousands(Number(commonPrice).toFixed(2));
  const taxSum = addSpacesBetweenThousands(Number(commonVat).toFixed(2));
  const exciseTaxSum = addSpacesBetweenThousands(
    Number(commonExcise).toFixed(2),
  );
  const receiptDate =
    (receivedAt && moment(nano2milli(receivedAt)).format('DD MMMM YYYY')) ||
    '-';
  const country = get(
    countryCodes.find(({ type }) => type === exportCountryCode),
    'title',
    '-',
  );

  return (
    <Fragment>
      <HeadingWrap
        title={documentTypeNames.IMPORT}
        id={id}
        status={status}
        isUzImportDocument
      />

      <FlexWrapper>
        <HeadedLabel
          value={documentDateLabel}
          header={Translate('Дата документа')}
        />
        {/*<HeadedLabel header="Сумма по документу" value={documentSum} />*/}
        <HeadedLabel header={Translate('Сумма НДС')} value={taxSum} />
        <HeadedLabel header={Translate('Сумма акциза')} value={exciseTaxSum} />
        <HeadedLabel header={Translate('Валюта')} value={currency} />
        <HeadedLabel header={Translate('Курс валюты')} value={currencyRate} />
        <HeadedLabel
          header={Translate('Поступил в систему')}
          value={receiptDate}
        />
      </FlexWrapper>

      <Container>
        <Column>
          <DocumentInfoBlock title={Translate('Налоговые данные импортера')}>
            <TwoLineLabel heading={Translate('ИНН/ПИНФЛ')}>{senderInn}</TwoLineLabel>
          </DocumentInfoBlock>
          <DocumentInfoBlock
            title={Translate('Сведения о декларации на товары')}
          >
            <TwoLineLabel heading={Translate('Регистрационный номер ГТД')}>
              {declarationNumber}
            </TwoLineLabel>
            <TwoLineLabel heading={Translate('Дата декларации на товары')}>
              {declarationDate}
            </TwoLineLabel>
          </DocumentInfoBlock>
          <DocumentInfoBlock title={Translate('Прочие сведения')}>
            <TwoLineLabel heading={Translate('Страна отправления')}>
              {country}
            </TwoLineLabel>
          </DocumentInfoBlock>
        </Column>
      </Container>
    </Fragment>
  );
};

ImportUzBasicInfo.propTypes = {
  id: PropTypes.string.isRequired,
  selectedDocument: PropTypes.shape({
    status: PropTypes.string,
    documentDate: PropTypes.string,
    price: PropTypes.string,
    tax: PropTypes.string,
    excise: PropTypes.string,
    currency: PropTypes.string,
    currencyRate: PropTypes.string,
    receivedAt: PropTypes.string,
    senderInn: PropTypes.string,
    declarationNumber: PropTypes.string,
    declarationDate: PropTypes.string,
    exportCountry: PropTypes.string,
  }),
};

export default ImportUzBasicInfo;
