import { withProps } from 'recompact';


export default withProps(
  ({ index, total, isError, errorPlaceholder, placeholder }) => {
    const newPlaceholder = (isError && errorPlaceholder) ? errorPlaceholder : placeholder;

    return {
      placeholder: (index && (total > 1)) ? `${newPlaceholder} ${index}` : newPlaceholder,
    };
  }
);
