import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  drawer: {
    width: 400,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '20px',
    width: '100%',
  },
  filterContent: {
    width: '100%',
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 15,
  },
});
