import React, { Component, } from 'react';
import styled from 'styled-components';
// import AnalyticMapBound from '../containers/Bounds/AnalyticMapBound';


const Wrap = styled.div`
  position: relative;
  margin-top: 16px;
  padding-bottom: 10px;
`;


class App extends Component {
  render() {
    return (
      <Wrap>
        {/*<AnalyticMapBound />*/}
      </Wrap>
    );
  }
}

export default App;
