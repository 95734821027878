import styled from 'styled-components';

const PointerUp = styled.div`
  background: #ffffff;
  width: 18px;
  height: 18px;
  bottom: calc(100% - 10px);
  position: absolute;
  transform: scaleX(0.7) rotate(-45deg);
  border-radius: 3px;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.13);
  left: calc(${props => props.position}px - 9px);
`;

export default PointerUp;
