import { handleActions } from 'redux-actions';
import * as actions from './DiscrepancyNotice.actions';

const draft = handleActions(
  {
    [actions.setDiscrepancyNotice]: (state, { payload }) => payload,
    [actions.clearDiscrepancyNotice]: (state, { payload }) => null,
  },
  null
);

const correction = handleActions(
  {
    [actions.setCorrectionAct]: (state, { payload }) => payload,
    [actions.clearCorrectionAct]: (state, { payload }) => null,
  },
  null
);
const isLoading = handleActions(
  {
    [actions.isLoadingStart]: (state, { payload }) => true,
    [actions.isLoadingEnd]: (state, { payload }) => false,
  },
  false
);


export default {
  draft,
  correction,
  isLoading,
};
