import { CadesUtil } from './CadesUtil';
import { RuToken, JaCarta, JaCarta2 } from '../packages/RusCryptoJS/src/index';

export class Signer {
  static async signMessage(data, cert, params) {
    return Signer.cert_types[cert.certType](data, cert, params);
  }

  static cert_types = {
    CADES: async (data, cert) => {
      const { certificate } = cert;
      return CadesUtil.signMessage(data, certificate);
    },
    RUTOKEN: async (data, cert, params) => {
      const { serial } = cert;
      const rutoken = new RuToken();
      await rutoken.init();
      await rutoken.bind();
      const toSign = btoa(data);
      const signed = await rutoken.signData(toSign, serial, params);
      await rutoken.unbind();
      return Promise.resolve(signed);
    },
    JACARTA: async (data, cert) => {
      const { serial } = cert;
      const jacarta = new JaCarta();
      await jacarta.init();
      await jacarta.bind();
      const toSign = btoa(data);
      const signed = await jacarta.signData(toSign, serial);
      await jacarta.unbind();
      return Promise.resolve(signed);
    },
    JACARTA2: async (data, cert) => {
      const { serial } = cert;
      const jacarta = new JaCarta2();
      await jacarta.init();
      await jacarta.bind();
      const toSign = btoa(data);
      const signed = await jacarta.signData(toSign, serial);
      await jacarta.unbind();
      return Promise.resolve(signed);
    }
  };
}
