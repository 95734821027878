import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ContentWrapper } from '../../../common_components/styled';
import FiltersButton from '../../../common_components/Buttons/FiltersButton';
import ActionBar from '../../../common_components/ActionBar/ActionBar';
import { prepareFromRange, prepareToRange } from '../../../common_components/Form/Form.utils';
import moment from 'moment';
import Filters from '../../../common_components/List/Filters/Filters';
import ExportListFilters from './ExportListFilters';
import * as actions from './ducks/ExportList.actions';
import * as listActions from '../../../common_components/List/ducks/List.actions';
import * as sidebarActions from '../../../common_components/Sidebar/ducks/Sidebar.actions';
import * as filePreviewActions from '../../../common_components/FilePreview/ducks/FilePreview.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withModal } from '../../../common_components/Modals/hocs/withModal';
import * as selectors from './ducks/ExportList.selectors';
import {
  limit,
  columns,
  options,
  FooterWrapper,
  getListData,
  TableHeading, /*TableHeading*/
} from './ExportList.constants';
import * as authSelectors from '../../../common_components/Auth/Auth.selectors';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import * as AppSelectors from '../../../common_components/App/App.selectors';
import Preloader from '../../../common_components/Preloader/Preloader';
import CommandBar from '../../../common_components/CommandBar/CommandBar';
import Pagination from '../../../common_components/List/Pagination/Pagination';
import { Translate } from '../../../common_components/Translate/Translate';
import TreeList from '../../../common_components/List/TreeList/TreeList';
import Form from '../../../common_components/Form/Form';


const ExportList = (props) => {
  const { actionBarLeft, onClear, onOpenSidebar, filters, countryCode } = props;
  const { onFilterApply, last, pagesCount, selectedPage, totalCount, onUnMount } = props;
  const { results, setSorting, userInfo, isLoading, onMount, onListMount } = props;
  const { sorting } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const onRef = useRef(null);

  useEffect(()=> {
    setSorting({
      asc: false,
      sortBy: 'createdAt',
    })
    onMount()
    onListMount();
  },[])

  useEffect(()=> onUnMount,[onUnMount])

  const handleOnClear = () => {
    onClear();
    handleOpenFiltersSidebar("clear")
  };

  const handleFilterApply = params => {
    onFilterApply(prepareFromRange({ ...params, offset: 0 }));
  };

  const handleOpenFiltersSidebar = (values) => {
    const data = prepareToRange(values === "clear" ? {} : props.filters);
    if (data.date_from) {
      data.date_from = moment(data.date_from, 'X')
        .utc()
        .format();
    }
    if (data.date_to) {
      data.date_to = moment(data.date_to, 'X')
        .utc()
        .format();
    }
    onOpenSidebar(
      <Filters
        onClear={handleOnClear}
        data={data}
        onSubmit={handleFilterApply}
        filters={<ExportListFilters countryCode={countryCode} />}
      />,
    );
  };

  const listData = useMemo(() => {
    return getListData(results, selectedRows, userInfo)
  }, [results, selectedRows]);

  const showToggle = useMemo(() => listData.filter(
    (item) => item.documents && item.documents.length > 1
  ).length > 0 , [listData]);

  const changeSorting = (column) => {
    setSorting({
      asc: column === sorting.sortBy ? !sorting.asc : true,
      sortBy: column,
    });
    onListMount();
  }

  // тут будет сортировка, когда ее сделают на беке
  const preparedCols = columns.map(el=> {
    if (el.id === 'createDate') {
      el.Header = <TableHeading onClick={()=> changeSorting('createdAt')}>{Translate('Дата документа')}</TableHeading>
    }
    if (el.id === 'date') {
      el.Header= <TableHeading onClick={()=> changeSorting('updatedAt')}>{Translate('Дата обновления статуса')}</TableHeading>
    }
    return el
  })

  return (
    <ContentWrapper>
      <ActionBar
        left={actionBarLeft}
        right={[
          <FiltersButton
            onClick={handleOpenFiltersSidebar}
          />
        ]}
      />
      <Preloader />
      <Form data={results}>
        <TreeList
          defaultPageSize={1000}
          data={listData}
          columns={preparedCols}
          options={options}
          onRef={onRef}
          hidden={isLoading}
          height="calc(100vh - 320px)"
          showToggle={showToggle}
        />
      </Form>
      <FooterWrapper>
        <CommandBar left={[]} flexBasis="40%" />
        <Pagination
          pageCounterInvisible={true}
          meta={actions}
          last={last}
          pagesCount={pagesCount}
          selected={selectedPage}
        />
        <div style={{ flexBasis: '30%', textAlign: 'right' }}>
          {Translate('Всего документов')}: {totalCount}
        </div>
      </FooterWrapper>
    </ContentWrapper>
  )
}

const mapState = state => {
  return {
    results: selectors.results(state),
    pagesCount: selectors.pagesCount(state),
    totalCount: selectors.total(state),
    last: selectors.results(state).length < limit, //selectors.last(state),
    filters: selectors.filters(state),
    sorting: selectors.sorting(state),
    roles: authSelectors.roles(state),
    userInfo: authSelectors.userInfo(state),
    uploadErrors: selectors.uploadErrors(state),
    selectedPage: selectors.selectedPage(state),
    isLoading: preloaderSelectors.loading(state),
    isUserOGV: authSelectors.isUserOGV(state),
    countryCode: AppSelectors.countryCode(state),
  };
};

const mapDispatch = dispatch => ({
  onClear: () => dispatch(actions.filters({})),
  onMount: () => dispatch(actions.mounted()),
  setSorting: (payload) => dispatch(actions.setSorting(payload)),
  onUnMount: () => dispatch(actions.unmounted()),
  onListMount: () => dispatch(listActions.mounted({ page: 1 }, actions)),
  onFilterApply: params => dispatch(listActions.filter(params, actions)),
  onOpenSidebar: content => dispatch(sidebarActions.open(content)),
  onOpenDocument: (id, type, docType) =>
    dispatch(actions.openDocument({ id, type, docType })),
  onOpenNewFile: (obj, meta) =>
    dispatch(filePreviewActions.openFile(obj, meta)),
  onClearErrors: () => dispatch(actions.clearErrors()),
  onErrors: val => dispatch(actions.gotErrors(val)),
  onGetProps: () => dispatch(listActions.paginate({ page: 1 }, actions)),
  downloadDoc: id => dispatch(actions.downloadDoc(id)),
});

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
  withModal,
)(ExportList);
