import { Icon } from '@crpt-ui/core';
import React from 'react';

import FirstCell from './components/FirstCell';
import SecondCell from './components/SecondCell';
import ThirdCell from './components/ThirdCell';
import Layout from './Layout';
import { Translate } from '../../../../../common_components/Translate/Translate';
import FourthCell from './components/FourthCell';
import { useTranslation } from 'react-i18next';
import { MARKING_CODES_INFO_TYPES_NAMES } from '../../../../../constants';
import { GreyWrapper } from '../../../BasicInfo/CisInfo/CisInfo.styled';

const CorInfoCm = ({ op }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <FirstCell
        icon={<Icon name="Edit" />}
        title={Translate('Корректировка сведений о кодах маркировки')}
      />
      <SecondCell op={op} />
      <ThirdCell
        useLink
        op={op}
        heading={Translate('Документ о корректировке сведений')}
      />
      <FourthCell heading="">
        <GreyWrapper>
          {t('Изменено значение параметра {{parameter}}', {
            parameter: t(MARKING_CODES_INFO_TYPES_NAMES[op.changed]),
          })}
        </GreyWrapper>
      </FourthCell>
    </Layout>
  );
};

export default CorInfoCm;
