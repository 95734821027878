import { actionTypes, strings } from '../../constants';

export const contactsEditable = (state = true, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.TOGGLE_CONTACTS_EDIT:
      return !state;
    case actionTypes.REGISTRATION.SHOW_DATA:
      return false;
    default:
      return state;
  }
};
