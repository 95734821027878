import * as _ from 'lodash';
import moment from 'moment';
import { compose } from 'redux';

import { periodicityEnum, taskTypeEnum } from '../constants';
import { Translate } from '../../../common_components/Translate/Translate';

export const formValidator = values =>
  compose(
    requiredFields(values, ['name', 'periodicity']),
    onlySpacesError(values, ['name']),
    periodError(values),
    departurePeriodErrors(values),
    dataPeriodErrors(values),
  )({});

const requiredFields = (values, fields) => errors => {
  fields.forEach(item => {
    if (!values[item]) {
      errors[item] = Translate('Пустое значение');
    }
  });

  return errors;
};

const onlySpacesError = (values, fields) => errors => {
  fields.forEach(item => {
    if (values[item] && /^\s+$/i.test(values[item])) {
      errors[item] = Translate('Значение не может содержать только пробелы');
    }
  });

  return errors;
};

const periodError = values => errors => {
  const { periodicity, period } = values;
  return periodicity === periodicityEnum.REGULAR && !period
    ? {
        ...errors,
        period: Translate('Пустое значение'),
      }
    : errors;
};

const departurePeriodErrors = values => errors => {
  const { periodicity, period } = values;

  if (_.isUndefined(periodicity) || periodicity === periodicityEnum.SINGLE) {
    return errors;
  }

  const startDate = _.get(values, 'departurePeriod.startDate');
  const endDate = _.get(values, 'departurePeriod.endDate');

  if (!startDate || !endDate) {
    return {
      ...errors,
      departurePeriod: {
        startDate: Translate('Пустое значение'),
        endDate: Translate('Пустое значение'),
      },
    };
  }

  const duration = moment.duration(moment(endDate).diff(moment(startDate)));

  switch (true) {
    case period === 'DAY' && duration.asDays() < 2:
    case period === 'WEEK' && duration.asWeeks() < 2:
    case period === 'MONTH' && duration.asMonths() < 2:
    case period === 'QUARTER' && duration.asMonths() < 6:
    case period === 'YEAR' && duration.asYears() < 2:
      return {
        ...errors,
        departurePeriod: {
          startDate: Translate('Ошибка в диапазоне дат'),
          endDate: Translate('Ошибка в диапазоне дат'),
        },
      };
  }

  return errors;
};

const dataPeriodErrors = values => errors => {
  const taskTypeId = _.get(values, 'meta.taskTypeId');
  const { dataPeriod, periodicity } = values;

  if (_.isUndefined(periodicity) || periodicity === periodicityEnum.REGULAR) {
    return errors;
  }

  return taskTypeId !== taskTypeEnum.REPORT && !dataPeriod
    ? {
        ...errors,
        dataPeriod: {
          startDate: Translate('Пустое значение'),
          endDate: Translate('Пустое значение'),
        },
      }
    : errors;
};
