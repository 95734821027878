import React from 'react';
import { LightTheme } from '@crpt-ui/core';
import { MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from './ducks/PageMenu.selectors';
import PageMenuView from './PageMenu.view';
import { translate } from '../App/App.selectors';
import * as authSelectors from 'src/common_components/Auth/Auth.selectors';
import { MaterialUiThemeKZ } from '../_MaterialUi/ThemeKZ';
import Header from './Header/Header';
import { MaterialUiThemeUZ } from '../_MaterialUi/ThemeUZ';
import { Translate } from '../Translate/Translate';

const CrptUiTheme = createMuiTheme({
  ...LightTheme,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButtonBase: {
      root: {
        fontFamily: "'SegoeUI', sans-serif",
      },
    },
  },
});

const CrptUiThemeKZ = createMuiTheme({
  ...MaterialUiThemeKZ,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButtonBase: {
      root: {
        fontFamily: "'SegoeUI', sans-serif",
      },
    },
  },
});

const CrptUiThemeUZ = createMuiTheme({
  ...MaterialUiThemeUZ,
  typography: {
    fontFamily: ['"SegoeUI"', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButtonBase: {
      root: {
        fontFamily: "'SegoeUI', sans-serif",
      },
    },
  },
});

class PageMenu extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        route: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        titleKey: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        testRoute: PropTypes.string,
      }),
    ).isRequired,
    translations: PropTypes.object.isRequired,
    isUserOGV: PropTypes.bool.isRequired,
    countryCode: PropTypes.string.isRequired,
  };

  getPreparedItems = () => {
    return this.props.items
      .map(item => ({
        ...item,
        title: Translate(item.title),
      }))
      .filter(item => {
        //this is temporal hack to disable unload service for OGV
        // cause OGV have 2 roles now: OPERATOR and OGV.
        // by role operator user have access to this route
        // but this is the hack on the backend...
        // if (item.route === '/reports' && countryCode === "UZ") {
        //   return false;
        // }
        return !(this.props.isUserOGV && item.titleKey === 'DOCUMENT_LIST.HEADING');
        // switch (item.route) {
        //   case '/unload-service/list':
        //     return false;
        //   default:
        //     return true;
        // }
      });
  };

  render() {
    const { countryCode } = this.props;
    return (
      <MuiThemeProvider
        theme={countryCode === 'KZ' ? CrptUiThemeKZ : CrptUiThemeUZ}
      >
        <PageMenuView Header={Header} items={this.getPreparedItems()} />
      </MuiThemeProvider>
    );
  }
}

const mapState = state => ({
  items: selectors.itemsSelector(state),
  isUserOGV: authSelectors.isUserOGV(state),
  translations: translate(state),
  countryCode: state.config.countryCode,
});

export default connect(mapState)(PageMenu);
