import styled from 'styled-components';

export const UserListItemContent = styled.div`
  border-right: ${props => props.last ? '0' : '1px solid rgba(196, 196, 196, 0.25)'};
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: ${props => props.first ? '0' : '20px'};
  font-size: 16px;
  font-weight: 300;
  color: #52535A;
`;