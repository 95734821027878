import React from 'react';
import { compose, withHandlers, onlyUpdateForKeys, setDisplayName } from 'recompact';
import { SingleSelect as CrptSingleSelect } from '@crpt/react-select';
import { Wrap } from './styled';
import { withCountedPlaceholder, withErrorProp, withFieldWrapper } from './utils';


const Element = ({ input, ...rest }) => (
  <Wrap onBlur={input && input.onBlur}>
    <CrptSingleSelect {...rest} />
  </Wrap>
);

const enchanced = compose(
  withErrorProp,
  withCountedPlaceholder,
  withHandlers({
    onSelect: ({ input, onSelect }) => (val) => {
      if (input && input.onChange) {
        input.onChange((val && val.type) || val);
      }
      if (onSelect) {
        onSelect((val && val.type) || val);
      }
    },
  }),
  onlyUpdateForKeys(['name', 'placeholder', 'values', 'selectedId', 'savePlaceholder']),
)(Element);


export default compose(
  setDisplayName('SingleSelect'),
  withFieldWrapper(enchanced),
)(enchanced);
