import { createAction, createActions } from 'redux-actions';

/**
 * Production actions
 */

export const subject = '[ParticipantProductions]';

export const onLoad = createAction(`${subject} on load`);
export const setProductionData = createAction(`${subject} set production data`);
