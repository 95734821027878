import React from 'react';

export default {
  title: 'Табличное представление при типе агрегации «ИНН + Тип документа»:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИНН',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 120,
        },
        {
          Header: 'Наименование',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
        },
        {
          Header: 'Тип документа',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 150,
        },
        {
          Header: 'Дата документа',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 170,
        },
        {
          Header: 'Количество документов, обработанных успешно',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
        {
          Header: 'Количество документов, обработанных с ошибкой',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 250,
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
        },
        {
          'col1': '',
          'col2': '',
          'col3': <strong>Итого</strong>,
          'col4': '',
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
        }, 
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '',
          'col5': <strong>...</strong>,
          'col6': <strong>...</strong>,
        },        
      ]
    },
  ]
}