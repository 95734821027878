import React from 'react';
import moment from 'moment';
import Icon from '@crpt/react-icon';
import { get } from 'lodash';
import styled from 'styled-components';
import { generateValuesFromDict } from '../../../common_components/Form/Fields/Select/SingleSelect.utils';
import {
  documentTypeNames,
} from 'src/constants/documentTypes';
import { getIconNameByDocType } from '../../../common_components/Table/TableCells/Document/utils';
import { documentStatusesNames } from 'src/constants/documentStatuses';
import Tooltip from '../../../common_components/_MaterialUi/Tooltip/Tooltip';
import { Link } from 'react-router-dom';
import { Translate } from '../../../common_components/Translate/Translate';
import { discrepancyIncomeStatusesNames } from '../../../constants/documentStatuses';
import { KZdocumentTypesNumbers } from '../../../constants/documentTypes';

export const limit = 20;

const DocTypeWrap = styled.div`
  display: flex;
`;

const ListNameWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const SenderNameWrap = styled.div`
  padding-right: 64px;
`;

const DraftNumWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: auto !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  color: #55b5e7;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;

export const documentName = {
  LP_INTRODUCE_GOODS: 'Ввод в оборот',
  LP_SHIP_GOODS: 'Отгрузка',
  LP_ACCEPT_GOODS: 'Приемка',
  LP_INTRODUCE_GOODS_CSV: 'Ввод в оборот',
  LP_INTRODUCE_GOODS_XML: 'Ввод в оборот',
  LP_SHIP_GOODS_CSV: 'Отгрузка',
  LP_SHIP_GOODS_XML: 'Отгрузка',
  LP_ACCEPT_GOODS_XML: 'Приемка',
  LK_REMARK: 'Перемаркировка',
  LK_REMARK_CSV: 'Перемаркировка',
  LK_REMARK_XML: 'Перемаркировка',
  LK_REGISTRATION: 'Регистрация',
  LK_REGISTRATION_XML: 'Регистрация',
  LK_RESUME_ACCESS: 'Возобновление доступа в ИС МП',
  LK_RESUME_ACCESS_XML: 'Возобновление доступа в ИС МП',
  LK_CHANGE_EMAIL_XML: 'Заявка на изменение',
  LK_CHANGE_EMAIL: 'Заявка на изменение',
  LK_LOAN_RECEIPT: 'Вывод из оборота',
  LK_LOAN_RECEIPT_CSV: 'Вывод из оборота',
  LK_RECEIPT_XML: 'Вывод из оборота',
  LK_BLOCKING: 'Заявка на блокировку',
  LK_BLOCKING_XML: 'Заявка на блокировку',
  LK_RECEIPT_CSV: 'Вывод из оборота',
  LK_RECEIPT: 'Вывод из оборота',
  LK_LOAN_RECEIPT_XML: 'Вывод из оборота',
  UNIVERSAL_TRANSFER_DOCUMENT: 'Отгрузка',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'Отгрузка',
  RECEIPT: 'Вывод из оборота (ОФД)',
  IMPORT: 'Уведомление о ввозе товаров (не ЕАЭС)',
  IMPORT_TRANSIT: 'Уведомление о ввозе товаров (ЕАЭС)',
  LP_GOODS_IMPORT: 'Импорт',
  LP_GOODS_IMPORT_XML: 'Импорт',
  LP_GOODS_IMPORT_CSV: 'Импорт',
  CONTRACT: 'Контракт',
  LK_CONTRACT_COMMISSIONING: 'Ввод в оборот (контракт)',
  LK_CONTRACT_COMMISSIONING_CSV: 'Ввод в оборот (контракт)',
  LK_CONTRACT_COMMISSIONING_XML: 'Ввод в оборот (контракт)',
  INDI: 'Физ.лицо',
  LK_INDI_COMMISSIONING: 'Ввод в оборот (физ.лицо)',
  LK_INDI_COMMISSIONING_CSV: 'Ввод в оборот (физ.лицо)',
  LK_INDI_COMMISSIONING_XML: 'Ввод в оборот (физ.лицо)',
  LK_KM_CANCELLATION: 'Списание КМ', // (ненанесенные на товар)
  LK_KM_CANCELLATION_XML: 'Списание КМ', // (ненанесенные на товар)
  LK_KM_CANCELLATION_CSV: 'Списание КМ', // (ненанесенные на товар)
  LK_APPLIED_KM_CANCELLATION: 'Списание КМ', // (нанесенные на товар)
  LK_APPLIED_KM_CANCELLATION_XML: 'Списание КМ', // (нанесенные на товар)
  LK_APPLIED_KM_CANCELLATION_CSV: 'Списание КМ', // (нанесенные на товар)
};

export const documentTypes = Object.keys(KZdocumentTypesNumbers)
  .map((v, i) => {
    return {
      id: i,
      title: Translate(documentTypeNames[v]),
      documentType: v,
      type: v,
    };
  })

export const documentNameValues = [
  {
    id: 1,
    title: documentName.LP_INTRODUCE_GOODS,
    type: 'LP_INTRODUCE_GOODS,LP_INTRODUCE_GOODS_CSV,LP_INTRODUCE_GOODS_XML',
  },
  {
    id: 2,
    title: documentName.LP_SHIP_GOODS,
    type:
      'LP_SHIP_GOODS,LP_SHIP_GOODS_CSV,LP_SHIP_GOODS_XML,UNIVERSAL_TRANSFER_DOCUMENT,UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED',
  },
  {
    id: 3,
    title: documentName.LP_ACCEPT_GOODS,
    type: 'LP_ACCEPT_GOODS,LP_ACCEPT_GOODS_XML',
  },
  {
    id: 4,
    title: documentName.LK_REMARK,
    type: 'LK_REMARK,LK_REMARK_CSV,LK_REMARK_XML',
  },
  {
    id: 5,
    title: documentName.LK_RECEIPT,
    type: 'LK_RECEIPT,LK_RECEIPT_XML,LK_RECEIPT_CSV',
  },
  {
    id: 5,
    title: 'Вывод из оборота с рассрочкой',
    type: 'LK_LOAN_RECEIPT,LK_LOAN_RECEIPT_CSV,LK_LOAN_RECEIPT_XML',
  },
  { id: 6, title: documentName.RECEIPT, type: 'RECEIPT' },
  {
    id: 7,
    title: documentName.LP_GOODS_IMPORT,
    type: 'LP_GOODS_IMPORT,LP_GOODS_IMPORT_XML,LP_GOODS_IMPORT_CSV',
  },
  {
    id: 8,
    title: documentName.CONTRACT,
    type:
      'LK_CONTRACT_COMMISSIONING,LK_CONTRACT_COMMISSIONING_XML,LK_CONTRACT_COMMISSIONING_CSV',
  },
  {
    id: 9,
    title: documentName.INDI,
    type:
      'LK_INDI_COMMISSIONING,LK_INDI_COMMISSIONING_XML,LK_INDI_COMMISSIONING_CSV',
  },
  {
    id: 10,
    title: documentName.LK_REGISTRATION,
    type: 'LK_REGISTRATION,LK_REGISTRATION_XML',
  },
  {
    id: 11,
    title: documentName.LK_KM_CANCELLATION,
    type:
      'LK_KM_CANCELLATION,LK_KM_CANCELLATION_XML,LK_KM_CANCELLATION_CSV,LK_APPLIED_KM_CANCELLATION,LK_APPLIED_KM_CANCELLATION_CSV,LK_APPLIED_KM_CANCELLATION_XML',
  },
];

const documentReceivedFrom = {
  LP_INTRODUCE_GOODS: 'Ручной ввод',
  LP_SHIP_GOODS: 'Ручной ввод',
  LP_ACCEPT_GOODS: 'Ручной ввод',
  LP_INTRODUCE_GOODS_CSV: 'CSV',
  LP_INTRODUCE_GOODS_XML: 'XML',
  LP_SHIP_GOODS_CSV: 'CSV',
  LP_SHIP_GOODS_XML: 'XML',
  LP_ACCEPT_GOODS_XML: 'XML',
  LK_REMARK: 'Ручной ввод',
  LK_REMARK_CSV: 'CSV',
  LK_REMARK_XML: 'XML',
  LK_REGISTRATION: 'Ручной ввод',
  LK_REGISTRATION_XML: 'XML',
  LK_RESUME_ACCESS: '???',
  LK_RESUME_ACCESS_XML: 'XML',
  LK_CHANGE_EMAIL_XML: 'XML',
  LK_CHANGE_EMAIL: 'Ручной ввод',
  LK_LOAN_RECEIPT: 'Ручной ввод',
  LK_LOAN_RECEIPT_CSV: 'CSV',
  LK_RECEIPT_XML: 'XML',
  LK_BLOCKING: 'Ручной ввод',
  LK_BLOCKING_XML: 'XML',
  LK_RECEIPT_CSV: 'CSV',
  LK_RECEIPT: 'Ручной ввод',
  LK_LOAN_RECEIPT_XML: 'XML',
  UNIVERSAL_TRANSFER_DOCUMENT: 'УПД',
  UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED: 'УПД',
  RECEIPT: 'Чек (ОФД)',
  LP_GOODS_IMPORT: 'Ручной ввод',
  LP_GOODS_IMPORT_CSV: 'CSV',
  LP_GOODS_IMPORT_XML: 'XML',
  LK_INDI_COMMISSIONING: 'Ручной ввод',
  LK_INDI_COMMISSIONING_CSV: 'CSV',
  LK_INDI_COMMISSIONING_XML: 'XML',
  LK_CONTRACT_COMMISSIONING: 'Ручной ввод',
  LK_CONTRACT_COMMISSIONING_CSV: 'CSV',
  LK_CONTRACT_COMMISSIONING_XML: 'XML',
  LK_KM_CANCELLATION_CSV: 'CSV',
  LK_KM_CANCELLATION: 'Ручной ввод',
  LK_KM_CANCELLATION_XML: 'XML',
  LK_APPLIED_KM_CANCELLATION_CSV: 'CSV',
  LK_APPLIED_KM_CANCELLATION: 'Ручной ввод',
  LK_APPLIED_KM_CANCELLATION_XML: 'XML',
};

export const docStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  CHECKED_OK: 'CHECKED_OK',
  CHECKED_NOT_OK: 'CHECKED_NOT_OK',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  UNDEFINED: 'UNDEFINED',
  CANCELLED: 'CANCELLED',
  ACCEPTED: 'ACCEPTED',
  WAIT_ACCEPTANCE: 'WAIT_ACCEPTANCE',
  WAIT_PARTICIPANT_REGISTRATION: 'WAIT_PARTICIPANT_REGISTRATION',
};

export const documentStatus = {
  IN_PROGRESS: 'Проверяется',
  CHECKED_OK: 'Обработан успешно',
  CHECKED_NOT_OK: 'Обработан с ошибками',
  PROCESSING_ERROR: 'Ошибка при обработке',
  UNDEFINED: 'Не определен',
  0: 'Создан (черновик)',
  1: 'Отправлен',
  2: 'Доставлен, подпись не требуется',
  3: 'Доставлен, требуется подпись',
  4: 'Подписан получателем',
  5: 'Отклонён',
  8: 'Запрошено уточнение',
  10: 'Ошибка доставки',
  11: 'Подписан (ожидает отправки)',
  12: 'Просмотрен, подпись не требуется',
  13: 'Просмотрен, ожидается подпись',
  14: 'Документ, к которому запрошено уточнение (Clarified), просмотрен',
  15: 'Отклонённый документ (Rejected) просмотрен',
  16: 'Отозван',
  // CANCELLED: 'Аннулирован',
  // ACCEPTED: 'Принят',
  // WAIT_ACCEPTANCE: 'Ожидает приемку',
  // WAIT_PARTICIPANT_REGISTRATION: 'Ожидаем регистрации участника в ИС МП',
};

export const documentFilterStatus = {
  // 0: 'Создан (черновик)',
  3: 'Ожидает подтверждения',
  '4, 61': 'Подписан получателем',
  5: 'Отклонён',
  8: 'Запрошено уточнение',
  10: 'Ошибка доставки',
  16: 'Отозван',
  17: 'Аннулирован',
  19: 'Готов к регистрации',
};

export const iconNamesByDocStatus = {
  CANCELLED: 'doc-error',
  IN_PROGRESS: 'checking',
  CHECKED_OK: 'issued',
  CHECKED_NOT_OK: 'doc-error',
  PROCESSING_ERROR: 'doc-error',
  UNDEFINED: 'checking',
  ACCEPTED: 'issued',
  WAIT_ACCEPTANCE: 'checking',
};

const documentStatusRegistration = {
  CHECKED_OK: 'Выполнена',
  CHECKED_NOT_OK: 'Отказ',
  IN_PROGRESS: 'На рассмотрении ',
};

export const documentStatusValues = generateValuesFromDict(
  documentFilterStatus,
);

export const documentFormat = {
  MANUAL: 'ручной ввод',
  XML: 'xml',
  CSV: 'csv',
  UPD: 'упд',
};

export const documentFormatValues = generateValuesFromDict(documentFormat);

export const documentInputType = {
  true: 'Входящий',
  false: 'Исходящий',
};

export const documentInputTypeValues = generateValuesFromDict(
  documentInputType,
);

export const options = [
  {
    accessor: d => d,
    id: 'type',
    render: (props, rowInfo) => {
      if (!rowInfo) return <React.Fragment />;
      const docType = get(rowInfo, 'original.type', '').toString();
      return (
        <DocTypeWrap>
          <Icon
            type={getIconNameByDocType(docType)}
            spacingRight={12}
            fill="rgb(82, 83, 90)"
            size={16}
          />
          <div>{Translate(documentTypeNames[docType] || docType)}</div>
        </DocTypeWrap>
      );
    },
  },
  {
    accessor: d => d,
    id: 'id',
    render: (_, rowInfo) => {
      const uuid = get(rowInfo, 'original.id', '');
      const uuidDocNumber = get(rowInfo, 'original.number', '');
      const document_type = get(rowInfo, 'original.type', '');
      let path = '';
      switch (document_type) {
        case 1000:
          path = `/documents/list/act-in/${uuid}`;
          break;
        case 1100:
          path = `/documents/list/act-in/${uuid}`;
          break;
        case 1005:
          path = `/documents/discrepancy_notice/view/${uuid}`;
          break;
        case 1200:
        case 1300:
          path = `/documents/list/eaes-in/${uuid}`;
          break;
      }

      return (
        <div
          style={{
            paddingLeft: [1005, 1100, 1300].includes(document_type) ? 30 : 0,
          }}
        >
          <DraftNumWrap>
            <Tooltip
              text={uuidDocNumber}
              textComponent={
                <Wrapper>
                  <StyledLink to={path}>{uuidDocNumber.slice(-6)}</StyledLink>
                </Wrapper>
              }
              sliceTitleStart={-6}
              placement="bottom-start"
              textColor="#55b5e7"
              interactive
              showCopy
              showMail
              noWrap
            />
          </DraftNumWrap>
        </div>
      );
    },
  },
];

export const columns = [
  {
    Header: Translate('Номер документа'),
    id: 'id',
    accessor: 'id',
    width: 200,
  },
  {
    Header: Translate('Тип документа'),
    id: 'type',
    accessor: 'type',
    width: 'auto',
  },
  {
    Header: Translate('Отправитель'),
    id: 'sender',
    accessor: d => get(d, 'sender.name', ''),
    width: 'auto',
  },
  {
    Header: Translate('Дата'),
    id: 'date',
    accessor: d => `${moment(new Date(d.date * 1000)).format('DD.MM.YYYY')}`,
    width: 120,
  },
  {
    Header: Translate('Дата получения'),
    id: 'created_at',
    accessor: d =>
      `${moment(new Date(d.created_at * 1000)).format('DD.MM.YYYY')}`,
    width: 120,
  },
  {
    Header: Translate('Статус'),
    id: 'status',
    accessor: d =>
      [1000, 1200, 1100].includes(d.type)
        ? Translate(get(documentStatusesNames, d.status, ''))
        : Translate(get(discrepancyIncomeStatusesNames, d.status, '')),
    width: 'auto',
  },
];
