import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import 'datejs';
import get from 'lodash/get';

import moment from 'moment';

import 'moment/locale/ru';
import { Column, Container } from '@crpt/react-page-layout';
import { Card } from '@crpt/react-card';
import { TwoLineLabel } from '@crpt/react-label';
import {
  addSpacesBetweenThousands,
  getRubelsAsFloatFromKopecks,
} from '../../../utils';
import { nano2milli } from '../DocumentDetails.utils';
import HeadedLabel from './HeadedLabel';

import { FlexWrapper, Separator, TextWithIconWrapper } from './styled';
import { oksm } from '../../../data/oksm';

// TODO: Refactoring is needed

moment.locale('ru');

const propTypes = {};

const ImageWrap = styled.div`
  width: 128px;
  min-width: 128px;
  height: 128px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
  margin-left: 32px;
  border-radius: ${props => (props.round ? '50%' : '')};
`;

const DocInfoWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const DocumentInfoHeadingLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #212c42;
  margin-bottom: 16px;
`;

const InfoLine = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const AfterLarge = styled.div`
  width: 100%;
  height: 15px;
`;

class TransgranBasicInfo extends Component {
  render() {
    const selectedDocument = this.props.selectedDocument;

    const { documentDate, receivedAt } = selectedDocument;

    const docDate = moment(nano2milli(documentDate));
    const docDateString = (docDate && docDate.format('DD MMMM YYYY')) || '—';

    // const receivedDate = moment(nano2milli(receivedAt));
    // const receivedDateString = (receivedDate && receivedDate.format('DD MMMM YYYY')) || '—';

    const id                   = get(selectedDocument, 'id', '-');
    const exporter             = get(selectedDocument, 'exporter', {});
    const importer             = get(selectedDocument, 'importer', {});
    const exporterCountryCode  = get(exporter, 'country', '');
    const exporterCountryName  = get(oksm, exporterCountryCode  + '.FULLNAME', '');
    const importerCountryCode  = get(importer, 'country', '');
    const importerCountryName  = get(oksm, importerCountryCode + '.FULLNAME', '');
    const exporterName         = get(exporter, 'taxpayer.name', '');
    const importerName         = get(importer, 'taxpayer.name', '');
    const importerId           = get(importer, 'taxpayer.id', '');
    const exporterId           = get(exporter, 'taxpayer.id', '');


    return (
      <React.Fragment>
        <Separator />

        <Card>
          <Container fillWidth gutter={32}>
            <Column col={24}>
              <DocInfoWrap>
                <div>
                  <InfoLine>
                    <TwoLineLabel heading={'Идентификатор'}>{id}</TwoLineLabel>
                  </InfoLine>

                  <InfoLine>
                    <TwoLineLabel heading={'Дата документа'}>
                      {docDateString}
                    </TwoLineLabel>
                  </InfoLine>
                </div>
              </DocInfoWrap>
            </Column>
          </Container>

          <Container fillWidth gutter={32}>
            <Column col={10}>
              <DocInfoWrap>
                <TextWithIconWrapper>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Отправитель (продавец)
                    </DocumentInfoHeadingLabel>
                    {exporterName && (
                      <InfoLine>
                        <TwoLineLabel heading={'Наименование'}>
                          {exporterName}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                    <InfoLine>
                      <TwoLineLabel heading={exporterCountryCode == '643' ? 'ИНН' : 'Идентификатор налогоплательщика'}>
                        {exporterId}
                      </TwoLineLabel>
                    </InfoLine>
                    <InfoLine>
                      <TwoLineLabel heading={'Страна'}>
                        {exporterCountryName}
                      </TwoLineLabel>
                    </InfoLine>
                    <InfoLine>
                      <TwoLineLabel heading={'Код страны'}>
                        {exporterCountryCode}
                      </TwoLineLabel>
                    </InfoLine>
                  </div>
                </TextWithIconWrapper>
              </DocInfoWrap>
            </Column>
            <Column>
              <DocInfoWrap>
                <ImageWrap round />
              </DocInfoWrap>
            </Column>
            <Column col={10}>
              <DocInfoWrap>
                <TextWithIconWrapper>
                  <div>
                    <DocumentInfoHeadingLabel>
                      Получатель (покупатель)
                    </DocumentInfoHeadingLabel>
                    {importerName && (
                      <InfoLine>
                        <TwoLineLabel heading={'Наименование'}>
                          {importerName}
                        </TwoLineLabel>
                      </InfoLine>
                    )}
                    <InfoLine>
                      <TwoLineLabel heading={importerCountryCode == '643' ? 'ИНН' : 'Идентификатор налогоплательщика'}>
                        {importerId || '-'}
                      </TwoLineLabel>
                    </InfoLine>
                    <InfoLine>
                      <TwoLineLabel heading={'Страна'}>
                        {importerCountryName}
                      </TwoLineLabel>
                    </InfoLine>
                    <InfoLine>
                      <TwoLineLabel heading={'Код страны'}>
                        {importerCountryCode}
                      </TwoLineLabel>
                    </InfoLine>
                  </div>
                </TextWithIconWrapper>
              </DocInfoWrap>

            </Column>
            <Column>
              <DocInfoWrap>
                <ImageWrap round />
              </DocInfoWrap>
            </Column>
          </Container>

          <AfterLarge />

        </Card>
      </React.Fragment>
    );
  }
}

TransgranBasicInfo.propTypes = propTypes;
export default withRouter(TransgranBasicInfo);
