import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AltScenarios from '../../components/AltScenarios';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions';
import * as selectors from '../../selectors';


class AltScenariosBound extends Component {
  render() {
    return (
      <ConnectParts
        component={AltScenarios}
        actions={[actions.AltScenarios, actions.Sidebar]}
        selectors={[selectors.AltScenarios]}
        {...this.props}
      />
    );
  }
}


export default withRouter(AltScenariosBound);
