import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  contentWrapper: {
    maxWidth: '820px',
    minWidth: '820px',
    padding: '40px',
  },
  main: {
    display: 'flex',
    justifyContent: 'center'
  },
  groupInfoWrapper: {
    paddingBottom: '30px',
  },
  groupTitleHeaderWrapper: {
    paddingBottom: '40px'
  },
  groupItemWrapper: {
    paddingBottom: '10px'
  },
  groupTitleWrapper: {
    paddingBottom: '20px'
  },

}));
