import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Breadcrumbs as CrptBreadcrumbs } from '@crpt/react-breadcrumbs';
import { Wrapper } from './Breadcrumbs.styled';


const Breadcrumbs = (props) => {
  const { history, paddingTop, paddingBottom, breadcrumbs } = props;
  const onClick = useCallback(val => history.push(val.href), [history]);

  return (
    <Wrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <CrptBreadcrumbs onClick={onClick} withCustomLinks breadcrumbs={breadcrumbs} />
    </Wrapper>
  );
};

Breadcrumbs.propTypes = {
  history: PropTypes.shape({}).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  paddingTop: undefined,
  paddingBottom: undefined,
};

export default withRouter(Breadcrumbs);
