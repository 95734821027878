import { all, call, delay, spawn } from 'redux-saga/effects';
import { notify } from 'react-notify-toast';
import { get, values } from 'lodash';


const getMainSaga = (order, sagas) => {
  const temp = {};

  order.forEach(project => {
    const obj = sagas.find(s => s.id === project).data;

    obj.keys().forEach(f => {
      const saga = obj(f).default;
      if (saga) temp[f] = saga;
    });
  });

  const allSagas = values(temp);

  function* mainSaga() {
    const sagas = [...allSagas];

    yield all(sagas.map(saga => spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.log(e);
          if (/\(0x000004C7\)/.test(get(e, 'message', ''))) {
            notify.show('Операция была отменена пользователем', 'error', 3000);
          }
          yield delay(1000);
        }
      }
    })));
  }

  return mainSaga;
};

export default getMainSaga;
