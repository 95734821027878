import { handleAction, handleActions } from 'redux-actions';
import * as actions from './HandAdd.actions';
import { uniqBy } from 'lodash';

const content = handleActions(
  {
    [actions.setKM]: (state, { payload = [] }) => payload,
    [actions.onCloseModal]: () => [],
  }, [],
);

const contentAll = handleActions(
  {
    [actions.setKM]: (state, { payload = [] }) => uniqBy([...state, ...payload], 'id'),
  }, [],
);

const isLoading = handleAction(
  actions.isLoaded, (state, { payload }) => payload,
  false,
);
const total = handleActions({
    [actions.setTotal]: (state, { payload }) => payload,
    [actions.onCloseModal]: () => 0,
  }, 0,
);

export default {
  content,
  contentAll,
  isLoading,
  total,
};
