import React from 'react';
import PropTypes from 'prop-types';

import { addSpacesBetweenThousands } from '../../../../utils/index';

import {
  ElementsCountWrapper,
  ElementsCountValueWrapper,
} from './styled/index';
import { Translate } from '../../../../common_components/Translate/Translate';

const propTypes = {
  value: PropTypes.number,
};

const defaultProps = {
  value: 0,
};

const ElementsCount = ({ value }) => (
  <ElementsCountWrapper>
    {Translate('Всего элементов:')}
    <ElementsCountValueWrapper>
      {addSpacesBetweenThousands(value)}
    </ElementsCountValueWrapper>
  </ElementsCountWrapper>
);

ElementsCount.propTypes = propTypes;
ElementsCount.defaultProps = defaultProps;
export default ElementsCount;
