import React from 'react';
import {
  RangePicker,
  SingleSelect,
  Input, ParticipantSelect,
} from '../../../common_components/Form/Fields/Fields';
import {
  docTypes,
  documentStatusValues,
} from './ImportList.constants';
import { Translate } from '../../../common_components/Translate/Translate';

const ImportListFilters = () => {
  return (
    <React.Fragment>
      <Input name="search" placeholder={Translate('Регистрационный номер документа')}/>
      <RangePicker
        name="date"
        leftPlaceholder={Translate('От')}
        rightPlaceholder={Translate('До')}
      />
      <ParticipantSelect
        name="senderInn"
        valueKey="inn"
        placeholder={Translate('Отправитель')}
      />
      <SingleSelect
        name="documentStatus"
        placeholder={Translate('Статус')}
        values={documentStatusValues}
      />
      <SingleSelect
        name="documentType"
        placeholder={Translate('Тип документа')}
        values={docTypes}
      />
    </React.Fragment>
  );
};
export default ImportListFilters;
