import { takeEvery, put, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver/FileSaver';
import Api from 'src/common_components/Api/Api';
import { filters2url, filters2params } from 'src/utils/urlUtils';

import * as actions from './ParticipantList.actions';
import * as qs from 'qs';
import { countryCodeFlags } from '../../../constants';

function* updateUrlParams({ history, urlString }) {
  yield call(history.push, urlString);
}

function* requestSaga(action) {
  const { payload = {} } = action;

  const {
    page = 1,
    filters,
    history,
    updateHistory = false,
    pathname = '',
    status
  } = payload;

  const filterParams = filters2params(filters);

  const requestPayload = {
    url: `/api/${countryCodeFlags.isKZ ? 'v3' : 'v4'}/facade/participants/search`,
    method: 'get',
    params: {
      ...filterParams,
      ...status ? { status } : {},
      limit: 10,
      page: page - 1,
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  };

  // Error field does not used here cause there are no designs for catching error in datatable. Instead of it we have global error catching - toasts.
  const [success] = yield call(Api.request, requestPayload);

  yield put(actions.getRequest);
  if (success) {
    if (updateHistory && history) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, { history, urlString });
    }
    if (page) yield put(actions.updatePage(page));
    yield put(actions.loaded(success.data));
  }
}

function* requestEvadersSaga(action) {
  const { payload = {} } = action;
  const { page = 1, filters, history, updateHistory = false, pathname = '' } = payload;
  const filterParams = filters2params(filters);
  const requestPayload = {
    url: '/api/v4/facade/participants/search',
    method: 'get',
    params: {
      ...filterParams,
      limit: 10,
      page: page - 1,
      status: ['REGISTERED_EVADER', 'CHECKED_EVADER', 'BLOCKED_EVADER'],
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  };
  // Error field does not used here cause there are no designs for catching error in datatable. Instead of it we have global error catching - toasts.
  const [success] = yield call(Api.request, requestPayload);
  yield put(actions.getRequestEvaders);
  if (success) {
    if (updateHistory && history) {
      const filtersUrl = filters2url(filters);
      const urlString =
        pathname + '?' + (filtersUrl ? filtersUrl + '&' : '') + `page=${page}`;
      yield call(updateUrlParams, { history, urlString });
    }
    if (page) yield put(actions.updatePageEvaders(page));
    yield put(actions.evadersLoaded(success.data));
  }
}

/**
 * Old function that do not use in current time, but may be needed in future plans. Do not remove.
 * @param action
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, ?>}
 */
function* downloadSaga(action) {
  const { payload = {} } = action;
  const { reportType = 'xls', page = 1, ...filters } = payload;

  const requestPayload = {
    url: `/private-office-api/private/v2/participants/buildReport/${reportType}`,
    method: 'post',
    responseType: 'blob',
    data: { ...filters, limit: 10, page: page - 1 },
  };

  const [success] = yield call(Api.request, requestPayload);

  if (success) {
    saveAs(success.data, `participant_list.xls`);
  }
}

export default function* watch() {
  yield takeEvery(actions.getRequest, requestSaga);
  yield takeEvery(actions.getRequestEvaders, requestEvadersSaga);
  yield takeEvery(actions.download, downloadSaga);
}
