import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import { Wrap } from './Tab.styled';
import { style } from './ButtonTabs.constants';


const Tab = (props) => {
  const { onSelect, data, disabled, theme, selectedTab } = props;
  const { icon, title } = data;
  const onClick = useCallback(() => onSelect(data), [onSelect, data]);

  return (
    <Wrap>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant="contained"
        color={selectedTab ? 'primary' : undefined}
      >
        {icon && <Icon type={icon} size={16} spacingRight={14} style={style} />}
        {title}
      </Button>
    </Wrap>
  );
};

Tab.propTypes = {
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

Tab.defaultProps = {
  disabled: undefined,
};

export default Tab;
