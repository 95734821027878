import styled from 'styled-components';
import { get } from 'lodash';

const ContentWrapper = styled.div`
  overflow: visible !important;
  z-index: 0;
  position: relative;
  padding-bottom: ${props => (props.withoutPadding ? '' : '25px')};
  background: ${props => get(props, 'theme.Content.backgroundColor', 'black')};
  margin-bottom: 0px;
  margin-top: ${props => get(props, 'theme.Content.marginTop', '100px')};
  display: flex;
  flex-direction: column;
  box-shadow: ${props => get(props, 'theme.Content.shadowX', '0')}
    ${props => get(props, 'theme.Content.shadowY', '0')}
    ${props => get(props, 'theme.Content.shadowBlur', '0')}
    ${props => get(props, 'theme.Content.shadowColor', 'black')}
  ${({ disabledBoxShadow }) => {
    if (disabledBoxShadow)
      return `
			box-shadow: none;
		`;
  }}
`;

export default ContentWrapper;
