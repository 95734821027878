import styled, { css } from 'styled-components';

export const WrapImg = styled.div`
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
  position: relative;
  z-index: 1001;
  height: 32px;
  width: 32px;
`;

export const Text = styled.p`
  margin: 10px 0 0 0;
  color: #434244;
  font-weight: 200;
  font-size: 14px;
`;

export const WrapPreloader = styled.div`
  pointer-events: none;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  ${({ fullHeight }) => (fullHeight ? css`
    height: calc(100% - 100px);
    min-height: 50vh;
` : undefined)}
`;
