import { call, put, takeEvery } from 'redux-saga/effects';
import crypto, { Base64 } from '@crpt-ui/crypto';
import { find } from 'lodash/fp';


import * as actions from './Eimzo.actions';
import { AuthServiceUZ } from '../../services/AuthServiceUZ';
import { getCertSerial } from '../../utils/cookieHelpers';
import { CadesUtil } from '../../utils/CadesUtil';
import { Toast } from '../../utils/Toast';

function* loadSaga() {
  const certificates = yield call(
    AuthServiceUZ.enumerateCertificates,
  );

  yield put(actions.loaded(certificates));
  yield put(actions.prepared(certificates));
}

export function* getCertBySerial(certs) {
  const serial = yield call(getCertSerial);
  try {
    return find((i) => i.serialNumber === serial, certs);
  } catch (e) {
    throw new Error('cert not found');
  }
}

export function* signUZSaga(action) {
  const { payload } = action;
  const { data = {}, file } = payload;

  const certificates = yield call(
    AuthServiceUZ.enumerateCertificates,
  );

  const certificate = yield call(getCertBySerial, certificates);

  try {
    let signedData;
    if (file) {
      const { type, content } = file;
      const signature = yield call(
        crypto.sign,
        content,
        certificate,
        true,
        true,
      );

      signedData = {
        signature,
        document: content,
        format: type,
      };
    } else {
      const convertedData = yield call(Base64.encode, JSON.stringify(data));

      const signature = yield call(
        AuthServiceUZ.sign,
        certificate,
        convertedData,
        true,
      );

      signedData = {
        signature,
        document: convertedData,
        format: 'MANUAL',
      };
    }

    return signedData;
  } catch (error) {
    console.log('error',error);
    const errorContent = CadesUtil.prepareError(error);

    yield call(Toast.showError, { content: errorContent });
  }
}

function* signByteArraySaga(action) {
  const { payload: data, meta } = action;
  let { certificate } = meta;

  if (!data) {
    return;
  }

  if (!certificate) {
    const certificates = yield call(crypto.enumerateCertificates);
    certificate = yield call(getCertBySerial, certificates);
  }

  try {
    const signature = yield call(crypto.signBytearray, data, certificate, true);
    yield put({
      type: `${actions.signedByteArray} ${meta.subject}`,
      payload: signature,
      meta,
    });
  } catch (error) {
    const errorContent = CadesUtil.prepareError(error);

    yield call(Toast.showError, { content: errorContent });
    yield put({ type: `${actions.error} ${meta.subject}` });
  }
}

export default function* watchSignCalls() {
  yield takeEvery(actions.sign, signUZSaga);
  yield takeEvery(actions.signByteArray, signByteArraySaga);
  yield takeEvery(actions.load, loadSaga);
}
