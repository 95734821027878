import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    width:'100%',
    height:'100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    flexDirection: 'column',
    fontSize: '16px'
  },
  error: {
    fontSize: '160px',
    fontWeight: '600',
    lineHeight: '1'
  },
  notFound: {
    fontSize: '40px',
    lineHeight: '1.25',
    marginBottom: '16px'
  },
  message: {
    marginBottom:'32px'
  }
});