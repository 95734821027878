import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    boxSizing: 'border-box',
    position: 'relative',
    minWidth: 770,
    width: 900,
    maxHeight: '95vh',
    padding: '20px 40px 0',
    borderRadius: 4,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 40px',
    '& > button': {
      width: 240,
    },
  },
  iconCloseWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  divider: {
    height: 1,
    width: '100%',
    background: '#f2f2f2',
  },
});
export default useStyles;
