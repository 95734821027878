import React from 'react';
import PropTypes from 'prop-types';

import { ActionBarWrapper, ActionGroup, ActionWrapper } from './styled';


const propTypes = {
  leftSideComponents: PropTypes.array,
  rightSideComponents: PropTypes.array,
};

const defaultProps = {
  leftSideComponents: [],
  rightSideComponents: [],
};

const ActionBar = ({ leftSideComponents, rightSideComponents }) => (
  <ActionBarWrapper>
    <ActionGroup>
      {
        leftSideComponents === []
          ? <div style={{width: '5px'}}>test</div>
          : leftSideComponents.map((item, i) => (
            item.wrap ? <ActionWrapper key={`action${i}`}>{item.component}</ActionWrapper> : item
          ))
      }
    </ActionGroup>

    <ActionGroup>
      {rightSideComponents.map((item, i) => (
        item.wrap ? <ActionWrapper key={`action${i}`}>{item.component}</ActionWrapper> : item
      ))}
    </ActionGroup>
  </ActionBarWrapper>
);


ActionBar.propTypes = propTypes;
ActionBar.defaultProps = defaultProps;

export default ActionBar;
