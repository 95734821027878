import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@crpt/react-icon';

import { Wrapper, IconWrapper, PlaceholderWrapper } from './Checkbox.styled';


const propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  checked: false,
  disabled: false,
};

const Checkbox = ({ onClick, checked, placeholder, disabled }) => (
  <Wrapper>
    <IconWrapper onClick={onClick} disabled={disabled} checked={checked}>
      {checked && <Icon type="checkmark" size={16} fill='#52535A' />}
    </IconWrapper>
    <PlaceholderWrapper
      onClick={onClick}
      disabled={disabled}
    >
      {placeholder}
    </PlaceholderWrapper>
  </Wrapper>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
