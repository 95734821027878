import React from 'react';

export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИНН',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 96,
          subheaders: []
        },
        {
          Header: 'Наименование участника',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 174,
          subheaders: []
        },
        {
          Header: 'ИД розничной торговой точки',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 164,
          subheaders: []
        },
        {
          Header: 'Поступило в розницу',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 324,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col4',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 162,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col5',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 162,
              subheaders: []
            },
          ]
        },
        {
          Header: 'Продажа покупателю',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 324,
          subheaders: [
            {
              Header: 'Шт.',
              accessor: 'col6',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 162,
              subheaders: []
            },
            {
              Header: 'тенге.',
              accessor: 'col7',
              style: {
                textAlign: 'center',
                justifyContent: 'center',
              },
              minWidth: 162,
              subheaders: []
            },
          ]
        },
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
          'col7': [7],
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': '',
          'col3': '',
          'col4': '...',
          'col5': '...',
          'col6': '...',
          'col7': '...',
        },
      ]
    }
  ]
}
