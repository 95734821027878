import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }  
      ],
      data: [
        {
          'col1': [1, 2],
          'col2': <ul>
            <li>Подробный по товарам</li>
            <li>По товарам в разрезе производителей</li>
          </ul>,
          'col3': 'ИНН и наименование производителя товара'
        },
        {
          'col1': [3, 4],
          'col2': <ul>
            <li>Подробный по товарам</li>
          </ul>,
          'col3': 'Уникальный код и наименование товара'
        },
        {
          'col1': [5],
          'col2': <ul>
            <li>Подробный по товарам</li>
          </ul>,
          'col3': 'Дата, с которой была установлена максимальная розничная цена'
        },
        {
          'col1': [6],
          'col2': <ul>
            <li>Подробный по товарам</li>
          </ul>,
          'col3': 'Максимальная розничная цена товара'
        },
        {
          'col1': [7],
          'col2': <ul>
            <li>Подробный по товарам</li>
          </ul>,
          'col3': 'Тип упаковки товара'
        },
        {
          'col1': [8],
          'col2': <ul>
            <li>По товарам в разрезе производителей</li>
          </ul>,
          'col3': 'Количество зарегистрированного товара у производителя'
        },
      ]
    }
  ]
}