import React from 'react';
import {withTheme} from 'styled-components';
import ProductDetails from '../../components/ProductDetails';

class ProductDetailsWrapper extends React.Component {
  render() {
    const { config } = this.props.App;

    const Component = config.ProductDetails && config.ProductDetails.component;

    return Component ? <Component {...this.props} /> : <ProductDetails {...this.props} />;
  }
}

export default withTheme(ProductDetailsWrapper);
