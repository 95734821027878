import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionRow: {
    paddingTop: '16px'
  },
  main: {
    padding: '0 40px 40px 40px'
  },
  wrapper: {
    backgroundColor: theme.palette.text.primary,
    borderRadius: '6px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    fontWeight: '600',
    justifyContent: 'space-between',
    lineHeight: '20px',
    padding: '16px',
    width: '100%'
  }
}));
