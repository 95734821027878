import React from 'react';
import styled from 'styled-components';
import { Value } from '../../../../fragments/Table/Cell/value';
import { Translate } from '../../../../common_components/Translate/Translate';
import moment from 'moment';
// import { DatePicker } from '../../../../common_components/Form/Fields/Fields';


export const Plug = styled.div`
    height: 1px;
    width: calc(100% + 111px);
    margin-left: -55px;
    margin-right: -55px;
    margin-bottom: 30px;
    margin-top: 20px;
    background: #f2f2f2;
`;

export const Link = styled.a`
    min-width: 46px;
    margin-left: 10px;
    height: 46px;
    box-shadow: 0px 3px 4px rgba(0,0,0,0.13), 0px 1px 3px rgba(0,0,0,0.1);
    cursor: ${(props)=> props.disabled ? "initial" : "pointer"};
    border: 0;
    background: ${(props)=> props.disabled ? "rgb(238, 238, 238)" : "#FFFFFF"};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      max-width: 16px;
    }
`;

export const SelectDocContainer = styled.div`
    display: flex;
    div {
      &:first-child {
        flex: auto;
      }
    }
`;

export const DatePickerWrapper = styled.div`
    label {
      background: ${(props)=> props.disabled ? "#eeeeee" : "#fff"};
      height: 46px;
      border-radius: 4px;
    }
`;

export const AdditionalBottomBarContent = styled.div`
    margin-bottom: 20px;
    margin-top: -15px;
    display: flex;
    justify-content: flex-start;
    button {
      width: 200px;
    }
`;

export const columnsAcceptanceOfEaesGoods = [
  {
    id: 'serialNumber',
    accessor: row => (
      <div>
        <Value>{row.serialNumber}</Value>
      </div>
    ),
    Header: Translate('№ п/п'),
    disableFilters: true,
    width: 100,
  },
  {
    id: 'cis',
    accessor: 'cis',
    Cell: ({ row }) => {
      return <Value>{row.cis || '-'}</Value>;
    },
    Header: Translate('Код идентификации'),
    width: 'auto',
  },
];

export const goodsAcceptanceResultOptions = [
  { label: 'Принят', value: true },
  { label: 'Не принят', value: false },
  { label: '—', value: "unset" }
]



export const senderDocumentFormat = (documentData) => {
  return {
    document_num: documentData.document_num,
    shipment_id: documentData.shipment_id,
    acceptance_date: moment(documentData.acceptance_date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    request_type: documentData.request_type,
    document_date: moment(documentData.document_date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    sender_country_code: documentData.sender_country_code,
    exporter_taxpayer_id: documentData.exporter_taxpayer_id,
    exporter_name: documentData.exporter_name,
    receiver_inn: documentData.receiver_inn,
    products: documentData.products.map(el=> ({
      cis: el.cis,
      accepted: el.accepted,
    })),
    reject_all: !documentData.products.reduce((acc, item) => item.accepted && acc , true)
  }
}

export const getCountryNameByCode = (code) => {
  switch (code) {
    case '051': return '051 - Республика Армения';
    case '112': return '112 - Республика Беларусь';
    case '417': return '417 - Киргизская Республика';
    case '643': return '643 - Российская Федерация';
    default: return '-'
  }
}

export const actionSelectOptions = [
  {
    value: 'accept',
    label: Translate('Принять выбранные'),
  },
  {
    value: 'reject',
    label: Translate('Отклонить выбранные'),
  },
];
