import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import DownloadButton from './DownloadButton';
import Filter from './Filter';
import ActionWrapper from '../../../common/ActionBar/styled/ActionWrapper';

const propTypes = {
  showDownloadListButton: PropTypes.bool.isRequired,
  popupMenu: PropTypes.bool.isRequired,
  filterFields: PropTypes.array,
  type: PropTypes.string,
  onFilterApply: PropTypes.func,
  downloadButtonId: PropTypes.string.isRequired,
  filterButtonId: PropTypes.string,
};

const RemoteButtons = ({
  showDownloadListButton,
  popupMenu,
  filterFields,
  type,
  downloadButtonId,
  filterButtonId,
  onFilterApply,
  handleDownload,
}) => {
  const downloadContainer = document.getElementById(downloadButtonId);
  const filterContainer = document.getElementById(filterButtonId);

  return (
    <React.Fragment>
      {downloadContainer && showDownloadListButton && ReactDOM.createPortal(
        <ActionWrapper>
          <DownloadButton popupMenu={popupMenu} handleDownload={handleDownload} />
        </ActionWrapper>,
        downloadContainer,
      )}

      {filterContainer && filterFields && ReactDOM.createPortal(
        <ActionWrapper>
          <Filter
            type={type}
            onFilterApply={onFilterApply}
            filters={filterFields}
          />
        </ActionWrapper>,
        filterContainer,
      )}
    </React.Fragment>
  );
};

RemoteButtons.propTypes = propTypes;

export default RemoteButtons;
