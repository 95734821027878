import { createAction } from 'redux-actions';

export const subject = '[NotificationOfEntry]';

export const mounted = createAction(`${subject} mounted`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const submit = createAction(`${subject} submit`);
export const submitUz = createAction(`${subject} submitUz`);
export const setError = createAction(`${subject} setErrorInfo`);
export const openDraft = createAction(`${subject} open draft`);
export const reset = createAction(`${subject} reset`);
export const setDocType = createAction(`${subject} set docType`);

