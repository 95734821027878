import React, {Component} from 'react';
import CrptSidebar from '@crpt/react-sidebar';

// const sidebarTheme = {
//   Sidebar: {
//     blocker: {
//       backgroundColor: 'rgba(33, 44, 66, 0.7)',
//     },
//
//     panel: {
//       width: '560px',
//       backgroundColor: '#FFFFFF',
//       location: 'right'
//     }
//   }
// };

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.actions.Sidebar.sidebarClosing();
  }

  render() {
    return (
      <CrptSidebar
        active={this.props.Sidebar.active}
        onClose={this.onClose}
        theme={this.props.Sidebar.theme ? {Sidebar: this.props.Sidebar.theme} : null}
      >
        {this.props.Sidebar.content}
      </CrptSidebar>
    );
  }
}

export default Sidebar;
