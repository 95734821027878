import React from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from '@crpt-ui/header';
import { getMenuItems, getUserIcon, makeShortName } from './UserProfile.utils';
import * as actions from '../ducks/PageHeader.actions';
import { connect } from 'react-redux';

/**
 * Render user info ( balance accounts ) and navigation links with profile actions
 */
const UserProfileComponent = ({ userName = '', balanceAccounts, isUserOGV, isUserFoiv, countryCode, fetchSignOut }) => (
  <MenuButton
    style={{
      paddingRight: '32px'
    }}
    icon={getUserIcon(isUserFoiv, countryCode)}
    items={getMenuItems(balanceAccounts, isUserOGV, fetchSignOut, countryCode)}
  >
    {makeShortName(userName)}
  </MenuButton>
)

UserProfileComponent.propTypes = {
  isUserOGV: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  isUserFoiv: PropTypes.bool.isRequired,
  balanceAccounts: PropTypes.array
};

const mapDispatch = dispatch => ({
  fetchSignOut: () => dispatch(actions.fetchSignOut()),
});

export const UserProfile = connect(null, mapDispatch)(UserProfileComponent);
