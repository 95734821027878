import React from 'react';
import { Icon } from '@crpt/react-icon';
import PropTypes from 'prop-types';
import { Wrapper } from './DocumentStatusLabel.styled';
import { documentStatusesNames } from '../../constants/documentStatuses';
import { getDocumentStatusIconByDocStatus } from '../../components/DocumentDetails/DocumentDetails.utils';

const DocumentStatusLabel = ({ status, style }) => (
  <Wrapper {...style}>
    <Icon
      type={status && getDocumentStatusIconByDocStatus(status)}
      spacingRight={12}
    />
    {status && documentStatusesNames[status]}
  </Wrapper>
);

DocumentStatusLabel.propTypes = {
  status: PropTypes.string,
  style: PropTypes.shape({}),
};

DocumentStatusLabel.defaultProps = {
  status: undefined,
  style: undefined,
};

export default DocumentStatusLabel;
