import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentWrapper } from '../../../common_components/styled/index';
import * as actions from './ducks/IncomeList.actions';
// import HighloadPagination from '../../../common_components/List/HightloadPagination/HighloadPagination';
import Preloader from 'src/common_components/Preloader/Preloader';
import ActionBar from 'src/common_components/ActionBar/ActionBar';
import CommandBar from 'src/common_components/CommandBar/CommandBar';
// import { makeShortName } from '../../../common_components/PageHeader/PageHeader.utils';
// import SelectionList from 'src/common_components/List/SelectionList/SelectionList';
import Form from 'src/common_components/Form/Form';
import List from 'src/common_components/List/List';
import { get } from 'lodash';
import SelectionList from '../../../common_components/List/SelectionList/SelectionList';
import TreeList from '../../../common_components/List/TreeList/TreeList';
import { getListData } from './IncomeList.utils';
import Pagination from '../../../common_components/List/Pagination/Pagination';
import { Translate } from '../../../common_components/Translate/Translate';

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto 36px;
  height: 70px;
`;

const IncomeListView = props => {
  const {
    results,
    columns = [],
    options,
    actionBarLeft,
    actionBarRight,
    actionBarBottom,
    isLoading,
    onRef,
    isUserOGV,
    meta,
    onUpdate,
    pageCounterInvisible,
    last,
    pagesCount,
    selectedPage,
    totalCount,
    selectedRows,
    userInfo,
  } = props;

  const columnsList = useMemo(() => {
    if (isUserOGV) {
      return columns.filter(({ id }) => id !== 'total');
    }
    return columns;
  }, [columns]);

  const listData = useMemo(() => {
    const list = getListData(results, selectedRows, userInfo)
    return list;
  }, [results, selectedRows]);

  const showToggle = useMemo(() => listData.filter(
    (item) => item.documents && item.documents.length > 1
  ).length > 0 , [listData]);

  return (
    <ContentWrapper
      data-selector={'request-list-relative-container'}
    >
      <ActionBar left={actionBarLeft} right={actionBarRight} />
      <Preloader />
      <Form data={results}>
        <TreeList
          defaultPageSize={1000}
          data={listData}
          columns={columnsList}
          options={options}
          onRef={onRef}
          onUpdate={onUpdate}
          hidden={isLoading}
          height="calc(100vh - 320px)"
          showToggle={showToggle}
        />
      </Form>

      <FooterWrapper>
        <CommandBar left={actionBarBottom} flexBasis="40%" />
        <Pagination
          pageCounterInvisible={pageCounterInvisible}
          meta={meta || actions}
          last={last}
          pagesCount={pagesCount}
          selected={selectedPage}
        />
        <div style={{ flexBasis: '30%', textAlign: 'right' }}>
          {Translate('Всего документов')}: {totalCount}
        </div>
      </FooterWrapper>
    </ContentWrapper>
  );
};

IncomeListView.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagesCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actionBarLeft: PropTypes.arrayOf(PropTypes.element),
  actionBarRight: PropTypes.arrayOf(PropTypes.element),
  onRef: PropTypes.func.isRequired,
  notifyErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  last: PropTypes.bool,
  isUserOGV: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  getTrProps: PropTypes.func.isRequired,
};
IncomeListView.defaultProps = {
  actionBarLeft: [],
  actionBarRight: [],
};
export default IncomeListView;
