import React, { useEffect, useRef } from 'react';
import { useStyles } from './preview.styled';
import Icons from '../../../../common_components/Icons/Icons';
import { Icon } from '@material-ui/core';
import { BUCKET_URL } from '../../../ArticleListPage/constants';

const ARTICLE_ORDERS_QUANTITY = 10;
const DEFAULT_ORDERS_QUANTITY = 7;

export const ListPreview = ({ type, title, icon, image, order }) => {
  const styles = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    if (order > 7 && type === 'article') {
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  }, [order, type, ref.current]);

  const list = Array(
    type === 'article' && order > 7
      ? ARTICLE_ORDERS_QUANTITY
      : DEFAULT_ORDERS_QUANTITY,
  ).fill(null);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.banner}>
        {order === 1 ? (
          <React.Fragment>
            <div className={styles.bannerItem}>
              {image && type !== 'article' && (
                <img src={BUCKET_URL + image} alt="" />
              )}
              {type !== 'article' && (
                <div className={styles.bannerTitle}>{title}</div>
              )}
            </div>
            <div className={styles.bannerItem} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={styles.bannerItem} />
            <div className={styles.bannerItem}>
              {image && type !== 'article' && (
                <img src={BUCKET_URL + image} alt="" />
              )}
              {type !== 'article' && (
                <div className={styles.bannerTitle}>{title}</div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={styles.fullWidth} />
      {list.map((_, index) =>
        index + 1 === order && type === 'article' ? (
          <div className={styles.article} key={index}>
            <div className={styles.icon}>
              {icon ? (
                <img src={BUCKET_URL + icon} alt="" />
              ) : (
                <Icon component={Icons.Box} style={{ width: 19 }} />
              )}
            </div>
            <div>
              {title ? (
                title
              ) : (
                <React.Fragment>
                  <div className={styles.articleTitle} />
                  <div className={styles.articleTitleShort} />
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.article} key={index}>
            <div className={styles.icon}>
              <Icon component={Icons.Box} style={{ width: 19 }} />
            </div>
            <div>
              <div className={styles.articleTitle} />
              <div className={styles.articleTitleShort} />
            </div>
          </div>
        ),
      )}
    </div>
  );
};
