import React from 'react';

export default {
  title: 'Описание показателей:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'Номер показателя',
          accessor: 'col1',
          minWidth: 150,
        },
        {
          Header: 'Тип агрегации',
          accessor: 'col2',
          minWidth: 230,
        },
        {
          Header: 'Описание',
          accessor: 'col3',
          minWidth: 400,
        }
      ],
      data: [
        {
          'col1': [1],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Субъект РК, в котором был реализован товар'
        },
        {
          'col1': [2],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Населённый пункт РК, в котором был реализован товар'
        },
        {
          'col1': [3],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Диапазон численности населённого пункта, в котором был реализован товар'
        },
        {
          'col1': [4],
          'col2': <ul>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Уникальный идентификатор торговой точки из справочника розничных торговых точек'
        },
        {
          'col1': [5, 6],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'ИНН и наименование продавца'
        },
        {
          'col1': [7, 8],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Уникальный код и наименование товара'
        },
        {
          'col1': [9],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Наименование производителя товара'
        },
        {
          'col1': [10],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Максимальная розничная цена товара'
        },
        {
          'col1': [11, 12],
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Объём товара (в пачках), реализованного конечному покупателю за указанный период'
        },
        {
          'col1': <strong>Итого</strong>,
          'col2': <ul>
              <li>По ИНН участника (владельца)</li>
              <li>По ИД торговой точки</li>
            </ul>,
          'col3': 'Подсчёт итогового значения происходит по всем записям, без ограничения'
        },
      ]
    }
  ]
}
