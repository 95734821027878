import reportTotalMarket from './reportTotalMarket';
import reportProductionTotal from './reportProductionTotal';
import reportProductionPart from './reportProductionPart';
import reportTotalManufacture from './reportTotalManufacture';
import reportTotalPartImport from './reportTotalPartImport';
import reportRealizationTotal from './reportRealizationTotal';
import reportPartRealized from './reportPartRealized';
import reportRealizationPart from './reportRealizationPart';
import reportTotalVolumes from './reportTotalVolumes';
import reportRotatingTobaco from './reportRotatingTobaco';
import reportRemainderIncomeOutcome from './reportRemainderIncomeOutcome';
import reportEstimateRealization from './reportEstimateRealization';
import reportGlossary from './reportGlossary';
import reportCisStatus from './reportCisStatus';
import cisDynamics from './cisDynamics';
import registeredProducts from './registeredProducts';
import writeOffProducts from './writeOffProducts';
import registrationDynamics from './registrationDynamics';
import participantsReport from './participantsReport';
import reportRetail from './reportRetail';
import reportDistribution from './reportDistribution';
import productionLine from './productionLine';
import exciseReports from './exciseReports';
import accrualsForCis from './accrualsForCis';
import reportEstimateRealizationOnlyforfactory from './reportEstimateRealizationOnlyforfactory';
import reportDoc from './reportDoc';
import reportVerifyPayForCis from './reportVerifyPayForCis';


export default {
  'pay-for-cis': accrualsForCis,
  'dynamic-of-cis': cisDynamics,
  'taxes': exciseReports,
  'catalog-of-participants': participantsReport,
  'line-of-manufactory': productionLine,
  'catalog-of-products': registeredProducts,
  'dynamic-of-participants': registrationDynamics,
  'cis-status': reportCisStatus,
  'distribution': reportDistribution,
  'documents': reportDoc,
  'time-to-sale': reportEstimateRealization,
  'time-to-sale-for-factory': reportEstimateRealizationOnlyforfactory,
  'catalog-of-sales-points': reportGlossary,
  'product-sale-part': reportPartRealized,
  'products-production-part': reportProductionPart,
  'production-import-total': reportProductionTotal,
  'sale-part': reportRealizationPart,
  'sale-total': reportRealizationTotal,
  'balance-income-outcome': reportRemainderIncomeOutcome,
  'retail-sales': reportRetail,
  'product-turnover': reportRotatingTobaco,
  'production-part': reportTotalManufacture,
  'total-market': reportTotalMarket,
  'production-import-part': reportTotalPartImport,
  'total-volumes-in-circulation': reportTotalVolumes,
  'verify-pay-for-cis': reportVerifyPayForCis,
  'write-off-products': writeOffProducts,
}