import { handleActions } from 'redux-actions';

import * as unloadServiceCreateActions from './UnloadServiceCreate.actions';

const data = handleActions(
  {
    [unloadServiceCreateActions.mounted]: () => ({}),
    [unloadServiceCreateActions.setData]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [unloadServiceCreateActions.unMounted]: () => ({}),
  },
  {},
);

const message = handleActions(
  {
    [unloadServiceCreateActions.mounted]: () => '',
    [unloadServiceCreateActions.setMessage]: (state, { payload }) => payload,
    [unloadServiceCreateActions.unMounted]: () => '',
  },
  '',
);

const loaded = handleActions(
  {
    [unloadServiceCreateActions.loaded]: () => true,
    [unloadServiceCreateActions.unMounted]: () => false,
  },
  false,
);

const loading = handleActions(
  {
    [unloadServiceCreateActions.loadingStart]: () => true,
    [unloadServiceCreateActions.loadingEnd]: () => false,
    [unloadServiceCreateActions.unMounted]: () => false,
  },
  false,
);

export default {
  data,
  message,
  loaded,
  loading,
};
