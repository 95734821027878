import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Icon } from '@crpt/react-icon';
import { Wrap } from '../_refactor/Fields.styled';

const PureButton = memo(Button);

const ButtonAdapter = props => {
  const {
    marginBottom,
    minWidth,
    margin,
    formErrors,
    formValues,
    formMutators,
    fieldInput,
    fieldMeta,
    icon,
    children,
    onClick,
    style,
    disabled,
    size = 16,
    ...pass
  } = props;

  return (
    <Wrap marginBottom={marginBottom} margin={margin} minWidth={minWidth}>
      <PureButton onClick={onClick} style={style} disabled={disabled} {...pass}>
        {icon && <Icon size={size} spacingRight={16} {...pass} type={icon} />}
        {children}
      </PureButton>
    </Wrap>
  );
};

ButtonAdapter.propTypes = {
  marginBottom: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ButtonAdapter.defaultProps = {
  marginBottom: undefined,
  icon: undefined,
  children: '',
  onClick: undefined,
  disabled: false,
  variant: 'contained',
};

export default ButtonAdapter;
