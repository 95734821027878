import React from 'react';
import { kopecksToRubels } from '../../DocumentDetails.utils';
import { Value } from '../../DocumentDetails.styled';
import { GoodsColumns } from 'src/fragments/Table/Cell/Goods/columns.constants';
import {
  IconWrapper,
  Link,
  NoWrap,
  OptionWrap,
} from '../../../Grid/Grid.styled';
import { getIconNameByPackType } from '../../../TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from '../../../../constants/packTypes';
import { countryCodes } from '../../../../constants/countryCodes';
import { CodesRenderer } from '../CodesRenderer';
import { Translate } from '../../../../common_components/Translate/Translate';

export const productsKey = 'products';

export const columns = [
  {
    id: 'idx',
    accessor: row => (
      <div>
        <Value>{row.idx}</Value>
      </div>
    ),
    Header: '№ п/п',
    sortable: false,
    width: 60,
  },
  {
    id: 'tnved',
    accessor: 'tnved',
    Cell: ({ row }) => {
      return <Value>{row.tnved || '-'}</Value>;
    },
    Header: Translate('ТН ВЭД'),
    sortable: false,
    width: 200,
  },
  {
    id: 'name',
    accessor: 'name',
    Cell: ({ row }) => {
      return <Value>{row.name || '-'}</Value>;
    },
    Header: Translate('Наименование товара'),
    sortable: false,
    sortDependence: 'tnved',
    width: 350,
  },
  {
    id: 'gtin',
    accessor: 'gtin',
    Cell: ({ row }) => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    sortable: false,
    width: 200,
  },
  {
    id: 'numberDT',
    accessor: 'numberDT',
    Cell: ({ row }) => {
      return <Value>{row.numberDT || '-'}</Value>;
    },
    Header: Translate('Номер товара в ДТ'),
    sortable: false,
    width: 200,
  },
  {
    id: 'country',
    accessor: 'country',
    Cell: ({ row }) => {
      const country = countryCodes.find(item => item.type === row.country);
      return <Value>{country ? country.title : ''}</Value>;
    },
    Header: Translate('Страна производства'),
    sortable: false,
    width: 350,
  },
  {
    id: 'cises',
    accessor: row => {
      return <CodesRenderer row={row} />;
    },
    Header: Translate('Код маркировки'),
    sortable: false,
    width: 200,
  },
];
