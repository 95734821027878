import { Close } from '@crpt-ui/icons';
import axios from 'axios';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { authInstance } from '../../services/authInstance';
import { Toast } from '../../utils/Toast';
import Preloader from '../Preloader/Preloader';
import Day from './Day/Day';
import { useStyles } from './ScanHistory.styles';
import {Translate} from '../Translate/Translate';

const { CancelToken } = axios;

const ScanHistory = ({ onClose }) => {
  const classes = useStyles();
  let cancelRequest;
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(true);

  const days = useMemo(() => {
    const rawDays = {};

    historyList.forEach(code => {
      const date = moment(code.checkDate).format('YYYY-MM-DD');
      if (rawDays[date]) {
        rawDays[date].push(code);
        rawDays[date].sort((code1, code2) => code2.checkDate - code1.checkDate);
      } else rawDays[date] = [code];
    });

    return rawDays;
  }, [historyList]);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await authInstance.request({
        cancelToken: new CancelToken(
          cancelToken => (cancelRequest = cancelToken),
        ),
        method: 'get',
        url: `/api/v3/facade/profile/ogv/check/history`,
      });
      setLoading(false);
      setHistoryList(_.get(response, 'data', []));
    } catch (error) {
      Toast.showError({
        content:
          _.get(error, 'response.data.error_message') ||
          _.get(error, 'message', 'error'),
      });
      cancelRequest();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cancelRequest) {
      cancelRequest();
    }
    fetch();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.closeWrapper}>
          <Close onClick={onClose} />
        </div>

        <div className={classes.root}>
          <div
            style={{
              marginLeft: -40,
              marginRight: -40,
              padding: '24px 40px',
            }}
          >
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                lineHeight: 24 / 20,
              }}
            >
              {Translate('История')}
            </div>
          </div>

          {loading && <Preloader isOpened={loading} isClosed={!loading} />}

          {!loading &&
            (Object.keys(days).length
              ? Object.keys(days)
                  .sort(
                    (d1, d2) =>
                      moment(d2, 'YYYY-MM-DD') - moment(d1, 'YYYY-MM-DD'),
                  )
                  .map((date, index) => (
                    <Day date={date} data={days[date]} key={index} />
                  ))
              : Translate('Данных нет'))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScanHistory;
