import { handleActions } from 'redux-actions';
import * as actions from './FiscalDocument.actions';
import { STEPS } from '../FiscalDocument.constants';

const step = handleActions(
  {
    [actions.load]: () => STEPS.LOADING,
    [actions.convert]: () => STEPS.CONVERTING,
    [actions.converted]: () => STEPS.READY_TO_SIGN,
    [actions.error]: () => STEPS.ERROR,
    [actions.reset]: () => STEPS.PRELOAD,
  },
  STEPS.PRELOAD,
);

const errorText = handleActions(
  {
    [actions.error]: (state, { payload = null }) => payload,
    [actions.reset]: () => null,
  },
  null,
);

const json = handleActions(
  {
    [actions.converted]: (state, { payload = {} }) => payload.content,
    [actions.reset]: () => null,
  },
  null,
);

const file = handleActions(
  {
    [actions.converted]: (state, { payload = {} }) => payload,
    [actions.reset]: () => null,
  },
  null,
);


export default {
  step,
  errorText,
  json,
  file,
};
