import { get } from 'lodash';
import { Item } from './Item';
import { CISService } from '../../../services/CISService';
import { CISList } from '../../../actions';
import i18next from 'i18next';

const isMaybeCis = /^(?!(ON_CHR|ON_KOR|unit_pac|MTTPS))[A-Za-z0-9!"%&’`*+\-./_,:;=<>?(')\]]+$/;
const isCisRegexp = /^01\d{14}21[\da-zA-Z!"%&'`*+\-./_,:;=<>?’\]]+/;
const isCisRegexpParentheses = /^\(01\)\d{14}\(21\)[\da-zA-Z!"`%&'*+\-./_,:;=<>?’\]]+/;
const cisLength = [41, 35, 37, 25, 45, 39, 29, 55, 49];

export class CisItem extends Item {
  icon = 'menu-cis-list';

  title = 'Коды';

  strictTest = () => {
    if (
      this.value.match(isCisRegexp) &&
      cisLength.indexOf(this.value.length) !== -1
    ) {
      return true;
    }

    if (
      this.value.match(isCisRegexpParentheses) &&
      cisLength.indexOf(this.value.length - 4) !== -1
    ) {
      return true;
    }

    return this.value.length >= 14 && this.value.match(isMaybeCis);
  };

  onClick = async () => {
    if (!this.strictTest()) {
      this.onFailure(
        i18next.t('Неверно указан формат кода (должен содержать не менее 21 символа)')
      );
      return;
    }

    try {
      this.onFailure(i18next.t('Идет поиск кодов...'));
      const answer = await CISService.getByCis(this.value);

      const results = get(answer, 'data.kizes', []);
      if (results && results.length) {
        if (results.length === 1) {
          this.history.push(
            `/cis/list/${encodeURIComponent(this.value)}/basic-info`
          );
        } else {
          this.history.push('/cis/list');
          this.dispatch(
            CISList.onFilterApplyClick({
              cisNumber: [this.value],
            })
          );
        }

        this.onSuccess();
        return;
      }
    } catch (err) {
      console.log('error: ', err);
    }

    this.onFailure(i18next.t('Поиск не дал результатов'));
  };
}
