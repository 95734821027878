import styled from 'styled-components';
import React from 'react';

export const MenuItemStyles = {
  MenuItem: {
    opacity: '1',
    backgroundColor: 'inherit',
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  MenuItemOdd: {
    backgroundColor: '#F7F7F7',
  },
};

export const Wrap = styled.div`
  padding: 20px 22px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
`;

export const TextWrap = styled.div`
  overflow: hidden;
`;

export const IconWrap = styled.div`
  height: 100%;
  padding: 0 20px 0 0;
`;

export const Text = styled.p`
  margin: ${({ setMargin }) => (setMargin ? setMargin : '0')};
  font-size: ${({ setFontSize }) => (setFontSize ? setFontSize : '14px')};
  font-weight: ${({ setFontWeight }) =>
    setFontWeight ? setFontWeight : '400'};
  color: ${({ isDisabled }) => (isDisabled ? '#FF4B4B' : '#63666A')};
  font-family: 'Segoe UI';
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SvgIconError = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#FF4B4B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 8C2 4.68629 4.68629 2 8 2C8.79751 2 9.55634 2.15507 10.2498 2.43577C10.7617 2.64299 11.3447 2.39598 11.5519 1.88404C11.7592 1.37211 11.5121 0.789115 11.0002 0.58189C10.0724 0.206321 9.05906 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"
      fill="#FF4B4B"
    />
    <path
      d="M11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711Z"
      fill="#FF4B4B"
    />
  </svg>
);

export const SvgIconSuccess = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#60E84A"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49997 7.08579C3.89049 6.69526 4.52366 6.69526 4.91418 7.08579L7.70708 9.87868C8.0976 10.2692 8.0976 10.9024 7.70708 11.2929C7.31655 11.6834 6.68339 11.6834 6.29287 11.2929L3.49997 8.5C3.10945 8.10948 3.10945 7.47631 3.49997 7.08579Z"
      fill="#60E84A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29287 11.2929C5.90234 10.9024 5.90234 10.2692 6.29287 9.87868L12.8787 3.29289C13.2692 2.90237 13.9023 2.90237 14.2929 3.29289C14.6834 3.68342 14.6834 4.31658 14.2929 4.70711L7.70708 11.2929C7.31655 11.6834 6.68339 11.6834 6.29287 11.2929Z"
      fill="#60E84A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C9.05906 0 10.0724 0.206321 11.0002 0.58189C11.5121 0.789115 11.7592 1.37211 11.5519 1.88404C11.3447 2.39598 10.7617 2.64299 10.2498 2.43577C9.55634 2.15507 8.79751 2 8 2Z"
      fill="#60E84A"
    />
  </svg>
);
