import React from 'react';
import moment from 'moment';
import { Input } from '@crpt/react-input';
import { Icon } from '@crpt/react-icon';
import { RangePicker } from '@crpt/react-datepicker';
import { ParticipantSelect } from '../../../../components/Select';
import CIScis from '../../../../components/TableCells/CIS/CIScis';
import CISstatus from '../../../../components/TableCells/CIS/CISstatus';
import CISStatusSelect from '../../../../components/Select/CISStatusSelect';
import EllipsisString from '../../../../components/common/EllipsisString';
import { TableCell } from '../../../../components/common/Table/TableCellTooltip';
import { defaultDatepickerTheme } from '../../../../theme/ru-tabaco/datepicker';


export default {
  actionNamespace: 'PRODUCT_DETAILS_CIS_LIST',
  options: [{
    accessor: d => d,
    id: 'status',
    render: (props) => {
      const { onMouseEvent, ...otherProps } = props;
      return (
        <TableCell onMouseEvent={onMouseEvent}>
          <CISstatus {...otherProps} />
        </TableCell>
      );
    },
  }, {
    accessor: d => d,
    id: 'cis',
    render: (props) => {
      const { onMouseEvent, ...otherProps } = props;
      return (
        <TableCell onMouseEvent={onMouseEvent}>
          <CIScis {...otherProps} />
        </TableCell>
      );
    },
  }, {
    accessor: d => d,
    id: 'ownerName',
    render: (props) => {
      const { onMouseEvent, ...otherProps } = props;
      return (
        <TableCell onMouseEvent={onMouseEvent}>
          {otherProps.children}
        </TableCell>
      );
    },
  }],
  columns: [{
    Header: 'Дата',
    id: 'producedDate',
    accessor: d => d.producedDate ? moment(new Date(d.producedDate)).format('DD.MM.YYYY') : '-',
    width: 105,
  },{
    Header: 'Статус кода',
    id: 'status',
    accessor: 'status',
    width: 250,
  }, {
    Header: 'Код',
    id: 'cis',
    accessor: 'cis',
    width: 490,
  }, {
    Header: 'Текущий владелец',
    id: 'ownerName',
    accessor: ({ ownerName }) => <EllipsisString value={ownerName} />,
  }],
  beforeTable: {
    showGraph: false,
  },
  beforeFilterForm: {
    addButton: true,
  },
  filters: [
    {
      showDownloadListButton: true,
      popupMenu: true,
    },
    [
      {
        component: Input,
        props: {
          name: 'cisNumber',
          placeholder: 'Номер кода',
          rightIcon: <Icon type="search" />,
        },
      },
    ],
    [{
      component: RangePicker,
      props: {
        inline: false,
        name: 'date',
        placeholder: 'Дата',
        format: 'DD.MM.YYYY',
        positionX: 'left',
        controls: true,
        acceptText: 'Применить',
        resetText: 'Сбросить',
        theme: { DatePicker: defaultDatepickerTheme },
      },
    }],
    [{
      component: CISStatusSelect,
      props: {
        inline: false,
        name: 'cisStatus',
        placeholder: 'Статус',
      },
    }],
    [{
      component: ParticipantSelect,
      props: {
        inline: false,
        name: 'participantId',
        valueKey: 'id',
        placeholder: 'Владелец',
      },
    }],
  ],
};
