import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import { get } from 'lodash';


export const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
  // height: 100%;
  margin-bottom: ${props => props.marginBottom || '16px'};
`;

export const IconWrapper = withTheme(styled.div`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 16px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border: ${({ checked }) => checked ? undefined : '2px solid #D9D9D9'};
  background-color: ${({ checked }) => checked ? props => get(props, 'theme.palette.primary.main') : undefined};
  border-radius: 3px;
  & path {
    fill: white;
  }
`);

export const PlaceholderWrapper = styled.div`
  font-size: 18px;
  line-height: 1.57;
  color: ${props => (props.disabled ? '#b9b9b9' : '#212c42')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
