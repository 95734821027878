import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import SingleSelect from '../_refactor/Select/SingleSelect';
import { instance } from '../../../../services/instance';
import HintedOption from './HintedOption';
import { Translate } from '../../../Translate/Translate';

const { CancelToken } = axios;


class ParticipantSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    valueKey: PropTypes.string,
  };

  static defaultProps = {
    onChange: undefined,
    valueKey: 'inn',
    hiddenParticipant: false,
  };

  static cancelToken;
  currentValue = ''

  state = {
    loading: false,
    values: [],
  };

  // static getDerivedStateFromProps(props, state) {
  //   const { name } = props;
  //   const { values } = state;
  //
  //   if (Array.isArray(values) && values.length) return state;
  //
  //     try {
  //       const valuesJson = sessionStorage.getItem(`ParticipantSelect__${name}`);
  //       if (valuesJson) {
  //         return { values: JSON.parse(valuesJson) };
  //       }
  //     } catch (e) { return state }
  //
  //   return state;
  // }

  componentWillUnmount() {
    const { name } = this.props;
    const { values } = this.state;

    // if (Array.isArray(values)) {
    //   try {
    //     sessionStorage.setItem(`ParticipantSelect__${name}`, JSON.stringify(values));
    //   } catch (e) { console.log(e) }
    // } else {
    //   sessionStorage.removeItem(`ParticipantSelect__${name}`);
    // }
  }

  onChange = (vv) => {
    const { onChange, valueKey, hiddenParticipant } = this.props;
    const isProducer = this.props.name === 'producerInn';
    const val = vv.includes('/') ? vv.split('/')[0] : vv;

    this.currentValue = val;
    if (onChange) onChange(val);
    else if(val.length > 2){
      this.setState({ loading: true });

      instance.request({
        url: isProducer ? `/api/v3/auth/org/suggestions?name=${encodeURIComponent(val)}&limit=10` : `/api/v3/facade/participants/suggestions/short?input=${encodeURIComponent(val)}`,
        method: 'get',
        cancelToken: new CancelToken(((c) => {
          ParticipantSelect.cancelToken = c;
        })),
      }).then((answer) => {
        const list = hiddenParticipant ? answer.data.filter((item) => item.inn !== hiddenParticipant) : [...answer.data];
        const values = list.map((pr, i) => ({
          type: valueKey === 'all' ? pr.name + ' / ' + pr.inn : pr[valueKey],
          id: i,
          title: pr.name + ' / ' + pr.inn,
        }));
        this.setState({ loading: false, values });
      });
    }
    else this.setState({ loading: false, values: [] });
  };

  onBlur = () => {
    this.currentValue = '';
  }

  render() {
    const { onChange, noValuesText, ...passProps } = this.props;
    const { values, loading } = this.state;

    let textEmpty = noValuesText ? noValuesText : Translate('пусто');
    if (loading) textEmpty = 'Загрузка'
    if (this.currentValue.length > 2 && values.length === 0) {
      textEmpty = <span style={{color: 'red'}}>{Translate('Не найдено ни одной организации, попадающей под критерий поиска!')}</span>
    }

    return (
      <SingleSelect
        {...passProps}
        // filterDisable
        noValuesText={textEmpty}
        onBlur={this.onBlur}
        truncate
        renderOption={HintedOption}
        onChange={this.onChange}
        isLoading={loading}
        values={values}
      />
    );
  }
}

export default ParticipantSelect;
