import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { isUndefined } from 'lodash';
import DatePickerInput from '../../../DatePicker/DatePickerInput';

import { Wrap } from '../../Fields/Fields.styled';
import { countryCodeFlags } from "../../../../constants";
import { MaterialUiThemeKZ } from "../../../_MaterialUi/ThemeKZ";
import { MaterialUiThemeUZ } from "../../../_MaterialUi/ThemeUZ";
import { MuiThemeProvider } from "@material-ui/core";

const PureDatePickerInput = memo(DatePickerInput);

const onKeyDown = (e) => {
  e.preventDefault()
}

class DatePickerAdapter extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
    marginBottom: PropTypes.string,
    fieldInput: PropTypes.shape({}),
    fieldMeta: PropTypes.shape({}),
    placeholder: PropTypes.string,
    errorPlaceholder: PropTypes.string,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    total: 1,
    marginBottom: undefined,
    placeholder: undefined,
    errorPlaceholder: undefined,
    onUpdate: undefined,
    fieldInput: undefined,
    fieldMeta: undefined,
  };

  componentDidMount() {
    const { defaultValue, formMutators, fieldInput = {} } = this.props;
    const { value, name } = fieldInput;

    if (isUndefined(value) && !isUndefined(defaultValue)) {
      setTimeout(() => formMutators.updateField(name, defaultValue), 0);
    }
  }

  onUpdate = val => {
    const { fieldInput, dateFormat, onUpdate } = this.props;
    const date = moment(val);

    const inputVal = date.format(dateFormat || 'DD.MM.YYYY') || undefined;

    if (fieldInput) fieldInput.onChange(inputVal);
    if (onUpdate) onUpdate(new Date(val));
  };

  isError = () => {
    const { fieldMeta, isError } = this.props;
    return isError || fieldMeta.error
  };

  render() {
    const {
      fieldInput,
      fieldMeta,
      formValues,
      formErrors,
      formMutators,
      marginBottom,
      dateFormat,
      placeholder,
      errorPlaceholder,
      location,
      ...passProps
    } = this.props;

    const value = fieldInput ? fieldInput.value : formValues[this.props.name];

    const customStyleIfValid =
      formValues[this.props.name] &&
      !!formValues[this.props.name].length &&
      !formErrors[this.props.name]
        ? true
        : false;

    return (
      <MuiThemeProvider theme={countryCodeFlags.isKZ ? MaterialUiThemeKZ : MaterialUiThemeUZ}>
        <Wrap
          onBlur={fieldInput && fieldInput.onBlur}
          marginBottom={marginBottom}
          location={location}
          customStyleIfValid={customStyleIfValid}
        >
          <PureDatePickerInput
            savePlaceholder
            {...passProps}
            label={placeholder}
            error={this.isError()}
            displayFormat={dateFormat || 'dd.MM.yyyy'}
            onChange={this.onUpdate}
            onKeyDown={onKeyDown}
            inline={false}
            value={value}
          />
        </Wrap>
      </MuiThemeProvider>
    );
  }
}

export default DatePickerAdapter;
