import { actionTypes } from '../../constants/';

export function CISList(state = [], action = {}) {
  switch (action.type) {
    case actionTypes.DOCUMENT_DETAILS.CIS_LIST_PAGE_LOADED:
    case actionTypes.DOCUMENT_DETAILS.CIS_LIST_LOADED:
      return action.value.results.map(v => ({
        ...v,
        producerId: {
          name: v.producerName,
          inn: v.producerInn,
          id: v.producerId,
        },
      }));
    default:
      return state;
  }
}
