import React from 'react';
import { Translate } from '../../../common_components/Translate/Translate';

export const getBreadcrumbs = ({ documentId, titleCardInfo }) => [
  {
    href: '/gray-area',
    title: 'Серая Зона',
    id: 1,
  },
  {
    href: `/gray-area/${documentId}`,
    title: (
      <span style={{ fontWeight: 600 }}>
        {titleCardInfo} {documentId}
      </span>
    ),
    id: 2,
  },
];

export const tabs = [
  {
    value: 0,
    label: Translate('Общая информация'),
  },
  {
    value: 1,
    label: Translate('Производственные линии'),
  },
  {
    value: 2,
    label: 'GTIN',
  },
];
