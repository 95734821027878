import { toddleSelectValues } from '../utils';
export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'open-options-panel':
      return {
        ...state,
        isOpen: true,
        isFocused: true,
      };

    case 'close-options-panel':
      return {
        ...state,
        isOpen: false,
        isFocused: payload,
        inputValue: '',
      };
    case 'set-default-select-values':
      return {
        ...state,
        selectValues: payload,
      };
    case 'clear-select-value':
      return {
        ...state,
        selectValues: [],
      };
    case 'set-input-value':
      return {
        ...state,
        inputValue: payload,
      };
    case 'toggle-select-values':
      return {
        ...state,
        selectValues: [...toddleSelectValues(payload, state.selectValues)],
      };
    default:
      return state;
  }
};
