import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@crpt-ui/menu'
import { MenuItemWrapper } from './MenuItem.styled';
import * as Icons from '@crpt-ui/icons';

export const MenuItemView = memo(({ item, active, onItemClick }) => {
  const onMenuItemClick = () => onItemClick(item.testRoute || item.route);

  return (
    <MenuItemWrapper to={item.testRoute || item.route}>
      <MenuItem onClick={onMenuItemClick} active={active} icon={typeof item.icon === 'string' ? React.createElement(Icons[item.icon]): item.icon}>
        {item.title}
      </MenuItem>
    </MenuItemWrapper>
  )
});


MenuItemView.propTypes = {
  item: PropTypes.shape({
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleKey: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    testRoute: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired
};