import { createAction } from 'redux-actions';

export const subject = '[AcceptanceNotificationOfGoodsByTheEAES]';

export const fetchShippingDocuments = createAction(`${subject} fetchShippingDocuments`);
export const setShippingDocuments = createAction(`${subject} setShippingDocuments`);
export const loaded = createAction(`${subject} loaded`);
export const unmounted = createAction(`${subject} unmounted`);
export const setError = createAction(`${subject} setErrorInfo`);
export const submit = createAction(`${subject} submit`);

