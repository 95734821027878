import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  js-display: flex;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const Wrapper = ({ children, onSelect }) => (
  <StyledWrapper onSelect={onSelect}>
    {children}
  </StyledWrapper>
);

Wrapper.propTypes = {
  children: PropTypes.elementType,
  onSelect: PropTypes.func.isRequired,
};
