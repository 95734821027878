export const preparePersisted = (stateVals, persistedVals) => {
    if (Array.isArray(stateVals)) {
        if (Array.isArray(persistedVals)) {
            const currentStateVals = stateVals.filter(stateVal => {
                return !persistedVals.some(persistVal => persistVal.column === stateVal.column)
            })
            return [...persistedVals, ...currentStateVals];
        }
        return stateVals;
    }
    return persistedVals || [];
}