import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  editorWrap: {
    minHeight: 400,
    '& .ql-container': {
      height: '640px',
      overflow: 'auto',
    }
  },
  previewContainer: {
    padding:'20px 12px 4px 12px',
    borderRadius:' 16px',
    backgroundColor: '#F8F9FA',
    border: '8px solid #0000001F',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    minHeight: '650px',
    overflow: 'auto',
    '& h2': {
      margin: '10px 0',
    },
    '& img': {
      width: '100%',
      objectFit: 'cover',
      borderRadius: 16,
      overflow: 'hidden',
    }
  },
  switch: {
    marginBottom: 10,
    '& span': {
      margin: 0,
      fontSize: '14px',
    }
  },

 PreviewWarningContainer: {
  display: 'flex',
  'justify-content': 'center',
  'flex-direction': 'column',
  'align-items': 'center',
  'text-align': 'center',
  width: '100%',
  height: '630px',
  'font-size': '28px',
  'font-style': 'normal',
  'font-weight': 400,
  'line-height': '36px',
 },
});
