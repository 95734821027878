import { handleActions } from 'redux-actions';
import * as actions from './ExportNotificationToTheEaes.actions';

const data = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload,
    [actions.unmounted]: () => ({}),
  },
  {}
);

const loaded = handleActions(
  {
    [actions.loaded]: () => true,
    [actions.submit]: () => true,
    [actions.mounted]: () => false,
    [actions.unmounted]: () => false,
    [actions.openDraft]: () => false,
    [actions.reset]: () => false,
  },
  false
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  ''
);

const docType = handleActions(
  {
    [actions.setDocType]: (state, { payload = '' }) => payload,
  },
  ''
);

export default {
  data,
  loaded,
  errorInfo,
  docType
};
