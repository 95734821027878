import { call, put, takeLatest } from 'redux-saga/effects';
import * as _ from 'lodash';

import Api from 'src/common_components/Api/Api';
import { Toast } from 'src/utils/Toast';

import * as grayAreaUsersActions from './GrayAreaUsers.actions';

function* requestUsers({ payload }) {
  const requestUserList = {
    url: `/api/v3/facade/user/list/active`,
    method: 'GET',
  };
  const [success, error] = yield call(Api.request, requestUserList, {
    preloading: false,
  });

  if (error) {
    const message = _.get(error, 'message', 'error');
    yield call(Toast.showError, { content: message });
  }

  let users = [];
  if (_.get(success, 'data')) {
    users = success.data
      .map(({ user }) => ({
        label: user.fullName,
        value: user.id,
        email: user.email,
      }))
      .filter(item => item.email);
  }

  yield put(
    grayAreaUsersActions.loaded({ result: users, total: users.length }),
  );
}

export default function* watch() {
  yield takeLatest(grayAreaUsersActions.requestUsers, requestUsers);
}
