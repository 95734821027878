import { handleActions } from 'redux-actions';
import * as actions from './ParticipantList.actions';

const results = handleActions(
  {
    [actions.loaded]: (state, { payload }) => payload.results || [],
    [actions.unmounted]: () => [],
  },
  []
);

const evadersResults = handleActions(
  {
    [actions.evadersLoaded]: (state, { payload }) => payload.results || [],
    [actions.unmountedEvaders]: () => [],
  },
  []
);

const loaded = handleActions(
  {
    [actions.getRequest]: () => false,
    [actions.loaded]: () => true,
    [actions.unmounted]: () => false,
  },
  false
);

const evadersLoaded = handleActions(
  {
    [actions.getRequestEvaders]: () => false,
    [actions.evadersLoaded]: () => true,
    [actions.unmountedEvaders]: () => false,
  },
  false
);

const total = handleActions(
  {
    [actions.loaded]: (state, { payload = {} }) => payload.total !== -1 ? payload.total : 0,
  },
  0,
);

const evadersTotal = handleActions(
  {
    [actions.evadersLoaded]: (state, { payload = {} }) => payload.total !== -1 ? payload.total : 0,
  },
  0,
);

const page = handleActions(
  {
    [actions.updatePage]: (state, { payload }) => payload,
    [actions.unmounted]: () => 1,
  },
  1,
);

const evadersPage = handleActions(
  {
    [actions.updatePageEvaders]: (state, { payload }) => payload,
    [actions.unmountedEvaders]: () => 1,
  },
  1,
);

export default {
  results,
  total,
  page,
  loaded,
  evadersPage,
  evadersTotal,
  evadersLoaded,
  evadersResults
};
