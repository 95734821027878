export default {
  title: 'Табличное представление:',
  amountOfTable: 1,
  tables: [
    {
      headers: [
        {
          Header: 'ИНН владельца',
          accessor: 'col1',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 144,
          subheaders: []
        },
        {
          Header: 'Юр. Адрес владельца',
          accessor: 'col2',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 144,
          subheaders: []
        },
        {
          Header: 'GTIN',
          accessor: 'col3',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 96,
          subheaders: []
        },
        {
          Header: 'Наименование товара',
          accessor: 'col4',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 175,
          subheaders: []
        },
        {
          Header: 'Производитель',
          accessor: 'col5',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 175,
          subheaders: []
        },
        {
          Header: 'Оборачиваемость (в днях)',
          accessor: 'col6',
          style: {
            textAlign: 'center',
            justifyContent: 'center',
          },
          minWidth: 196,
          subheaders: []
        },    
      ],
      data: [
        {
          'col1': [1],
          'col2': [2],
          'col3': [3],
          'col4': [4],
          'col5': [5],
          'col6': [6],
        }
      ]
    }
  ]
}