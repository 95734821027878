import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from '@material-ui/core';
import {
  ActionButton,
  HeaderWrapper,
  ScanningTop,
  ScanningWrapper,
  TextHeaderWrapper,
  ActionWrap,
  ReconciliationError,
} from './ReconciliationDialog.styled';
import Typography from '@material-ui/core/Typography';
import Icon from '@crpt/react-icon';
import { columns } from './ReconciliationDialog.constants';
import { ReconciliationResults } from './components/ReconciliationResults';
import ReconciliationActions from './components/ReconciliationActions';
import DialogContent from '@material-ui/core/DialogContent';
import * as actions from './ducks/Reconciliation.actions';
import * as actionsDiscrepancy from '../../DiscrepancyNotice/ducks/DiscrepancyNotice.actions';
import { connect } from 'react-redux';

import * as selectorsAuth from 'src/selectors/Auth';
import * as selectors from './ducks/Reconciliation.selectors';
import * as selectorsDocument from '../../../../../components/DocumentDetails/ducks/DocumentDetails.selectors';

import Preloader from '../../../../../common_components/Preloader/Preloader';
import List from '../../../../../common_components/List/List';
import { deletingCryptoEnd } from './Reconciliation.utils';
import { ScanFiled } from '../../../../../common_components/ScanDialog/components/ScanField';

const ReconciliationDialog = ({
  id,
  open,
  onClose,
  ownerInn,
  supplierInn,
  showingData,
  reconciliationDocument,
  data,
  clearReconciliationDocument,
  isLoading,
  createDraft,
  draft,
  createDiscrepancyDraft,
  actData,
  userInfo,
}) => {
  const [codes, setCodes] = useState('');
  const [codesWithError, setCodesWithError] = useState('');

  useEffect(() => {
    if (codesWithError !== '' && codes === '') {
      setCodesWithError("");
    }
  }, [codes]);

  const onChange = value => {
    setCodes(value);
  };

  useEffect(() => {
    return () => {
      clearReconciliationDocument();
    };
  }, []);

  const dataTable = useMemo(
    () =>
      showingData
        ? showingData.map(item =>
            item.reconciliationStatus
              ? { ...item, userINN: userInfo.inn }
              : {
                  ...item,
                  reconciliationStatus: 'NOT_FOUND',
                  userINN: userInfo.inn,
                },
          )
        : [],
    [showingData],
  );

  const showPagination = useMemo(
    () => (data && data.length > 5) || (dataTable && dataTable.length > 5),
    [dataTable, data],
  );

  const onStartCheck = () => {
    const redidCodes = deletingCryptoEnd(codes);

    reconciliationDocument({
      scanned: redidCodes,
      id,
      codes: data || dataTable,
      ownerInn,
      supplierInn,
      onFinish: unknown => onFinish(unknown),
    });
  };

  const onFinish = unknown => {
    if (unknown) {
      const data = unknown.map(item => item.cis);
      setCodes(data.join(' '));
      setCodesWithError(`[${data.join(', ')}]`);
    } else {
      setCodes('');
      setCodesWithError('');
    }
  };

  const closeDialog = () => {
    onClose();
    clearReconciliationDocument();
    setCodes('');
  };

  const onCreateDraft = () => {
    createDraft({
      id,
      body: data || dataTable,
      method: draft ? 'put' : 'post',
    });
  };

  const createDiscrepancyNotice = () => {
    createDiscrepancyDraft({ id, data: actData, list: data || dataTable });
  };

  const extendedData = useMemo(() => {
    if (!data) return dataTable;
    return data.map(item => ({ ...item, userINN: userInfo.inn }));
  }, [data, dataTable]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
      scroll="paper"
    >
      <Preloader text="Выполнение сверки" isOpened={isLoading} />
      {!isLoading && (
        <React.Fragment>
          <HeaderWrapper>
            <TextHeaderWrapper>
              <Typography variant="h6">Сверка акта</Typography>
            </TextHeaderWrapper>
            <Icon type={'cross'} onClick={closeDialog} />
          </HeaderWrapper>
          <DialogContent dividers={scroll === 'paper'}>
            <ScanningWrapper>
              <ScanningTop>
                <Icon
                  type="warning"
                  withoutMask
                  spacingRight={16}
                  size={24}
                  fill="#52535a"
                />
                <div>
                  <div>
                    Для начала сканирования поместите курсор ввода в текстовое
                    поле ниже и начинайте сканировать коды маркировки,
                  </div>
                  <div>Раскладка клавиатуры должна быть ENG (Английская)</div>
                </div>
              </ScanningTop>
              <div>
                <ScanFiled
                  value={codes}
                  onChange={onChange}
                  errors={codesWithError}
                />
              </div>
              <ActionWrap>
                <ActionButton disabled={codes === ''} onClick={onStartCheck}>
                  Проверить
                </ActionButton>
                {codesWithError === "" && (
                  <ReconciliationError>
                    Внимание! В отсканированном списке КМ есть некорректные
                    коды. Удалите их и попробуйте снова
                  </ReconciliationError>
                )}
              </ActionWrap>
              <List
                columns={columns}
                data={extendedData}
                sortable={false}
                defaultPageSize={5}
                showPagination={showPagination}
              />
              {/*<TableWrap>*/}
              {/*  <Datatable*/}
              {/*    columns={columns}*/}
              {/*    useSort={false}*/}
              {/*    data={data || dataTable}*/}
              {/*  />*/}
              {/*</TableWrap>*/}
              <ReconciliationResults list={data || dataTable} />
              <ReconciliationActions
                onClose={closeDialog}
                id={id}
                createDraft={onCreateDraft}
                createDiscrepancyDraft={createDiscrepancyNotice}
                list={data || dataTable}
              />
            </ScanningWrapper>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
};

const mapState = state => ({
  data: selectors.list(state),
  errors: selectors.errors(state),
  isLoading: selectors.isReconciliationLoading(state),
  draft: selectors.draft(state),
  actData: selectorsDocument.getData(state),
  userInfo: selectorsAuth.commonUserInfo(state),
});
const mapDispatch = dispatch => ({
  reconciliationDocument: data =>
    dispatch(actions.reconciliationDocument(data)),
  clearReconciliationDocument: () =>
    dispatch(actions.clearReconciliationDocument()),
  createDraft: data => dispatch(actions.createReconciliationDraft(data)),
  createDiscrepancyDraft: data =>
    dispatch(actionsDiscrepancy.createDiscrepancyNotice(data)),
});

export default connect(mapState, mapDispatch)(ReconciliationDialog);
