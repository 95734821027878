import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableHeaderCell from './TableHeaderCell';
import TableInput from './TableInput';
import TableLoading from './TableLoading';
import TableNoData from './TableNoData';
import TablePagination from './TablePagination';
import TableResizer from './TableResizer';
import TableRoot from './TableRoot';
import TableRow from './TableRow';
import TableRowGroup from './TableRowGroup';
import TableSelect from './TableSelect';
import TableWrapper from './TableWrapper';

export const defaultTheme = {
  Body: TableBody,
  Cell: TableCell,
  Footer: TableFooter,
  Header: TableHeader,
  HeaderCell: TableHeaderCell,
  Input: TableInput,
  Loading: TableLoading,
  NoData: TableNoData,
  Pagination: TablePagination,
  Resizer: TableResizer,
  Root: TableRoot,
  Row: TableRow,
  RowGroup: TableRowGroup,
  Select: TableSelect,
  Wrapper: TableWrapper,
};
