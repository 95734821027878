import React from 'react';
import { CisCodeWrap } from '../../ImportDocument/Cis/Cis.styled';
import styled from 'styled-components';
import { Value } from 'src/fragments/Table/Cell/value';
import {
  IconWrapper,
  NoWrap,
  OptionWrap,
} from 'src/components/Grid/Grid.styled';
import { getIconNameByPackType } from 'src/components/TableCells/CIS/utils';
import { Icon } from '@crpt/react-icon';
import { packTrivialTypesKZ } from 'src/constants/packTypes';
import { Translate } from 'src/common_components/Translate/Translate';

export const actionSelectOptions = [
  {
    value: 1,
    label: Translate('Удалить'),
  },
];

export const PRELOAD = 0;
export const LOADING = 1;
export const ERROR = 4;
export const STEPS = {
  PRELOAD,
  LOADING,
  ERROR,
};

export const errorCodes = {
  validate: 'invalidateMK',
};

export const columns = [
  {
    Header: Translate('№ п/п'),
    id: 'idx',
    accessor: 'idx',
    width: 100,
    disableSortBy: true,
  },
  {
    id: 'gtin',
    accessor: row => {
      return <Value>{row.gtin || '-'}</Value>;
    },
    Header: Translate('Код товара (GTIN)'),
    width: 'auto',
  },
  {
    id: 'name',
    accessor: 'name',
    disableFilters: true,
    Header: Translate('Наименование товара'),
    width: 'auto',
  },
  {
    id: 'packageType',
    accessor: row => {
      const CODE = row.cisPackageType ? row.cisPackageType.toLowerCase() : '';
      return (
        <OptionWrap>
          <IconWrapper type={getIconNameByPackType(CODE)}>
            <Icon
              type={getIconNameByPackType(CODE)}
              fill="rgb(82, 83, 90)"
              size={16}
              spacingRight={12}
            />{' '}
          </IconWrapper>
          <NoWrap>{packTrivialTypesKZ[CODE]}</NoWrap>
        </OptionWrap>
      );
    },
    Header: Translate('Тип упаковки'),
    disableFilters: true,
    width: 'auto',
  },
  {
    Header: Translate('Код идентификации'),
    id: 'cis',
    accessor: d => (
      <CisCodeWrap
        target={'_blank'}
        href={`/cis/list/${encodeURIComponent(d.cis)}`}
      >
        {d.cis}
      </CisCodeWrap>
    ),
    width: 'auto',
  },
];

export const ContentErrorLoading = styled.div`
  margin: auto;
`;
