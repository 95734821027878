import styled from 'styled-components';

export const UploadArea = styled.div`
  display: ${props => (props.hide ? 'none' : 'flex')};
  height: 100px;
  outline: 0;
  align-items: center;
  justify-content: center;
  background: ${props => (props.isDragActive ? 'rgba(85, 181, 231, 0.1)' : '')};
  border: 1px
    ${props => (props.isDragActive ? 'solid #55B5E7' : 'dashed #d9d9d9')};
  box-sizing: border-box;
  border-radius: 3px;
`;

export const ErrorZone = styled.div`
  height: 68px;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  padding-left: 41px;
  padding-right: 41px;
  margin-left: -41px;
  background: #ff4b4b;
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
  width: calc(100% + 82px);
`;

export const PointerArea = styled.div`
  cursor: pointer;
`;
