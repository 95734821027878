import { handleActions } from 'redux-actions';
import * as actions from '../ducks/Reconciliation.actions';

const isReconciliationLoading = handleActions(
  {
    [actions.reconciliationLoading]: (state, { payload }) => payload,
  },
  false
);

const list = handleActions(
  {
    [actions.setReconciliationDocument]: (state, { payload }) => payload,
  },
  null
);

const errors = handleActions(
  {
    [actions.setReconciliationDocumentErrors]: (state, { payload }) => payload,
  },
  null
);

const draft = handleActions(
  {
    [actions.setReconciliationDraft]: (state, { payload }) => payload,
  },
  null
);

export default {
  isReconciliationLoading,
  list,
  errors,
  draft,
};
