import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { InlineWrap, Layout } from '@crpt/react-page-layout';
import {
  BottomInfo,
  ContentWrap,
  EnterWrap,
  IconWrap,
  Inner,
  Outer,
  SelectWrap,
  StyledLink,
  Button,
  WrapCheckStatusButton,
  StyledA, LanguageWrap,
  // ErorText,
} from './LoginFormKep.styled';
import ErrorPlugin from './ErrorPlugin/ErrorPlugin';
import Sidebar from '../../common_components/Sidebar/Sidebar';
import HeaderLogoWithText from './header-logo.svg';
import LogoWithText from './logo.svg';
import Fields from './Fields/Fields';
import Form from '../../common_components/Form/Form';
import CheckStatus from './checkStatus/CheckStatus';
import LanguageMenu from '../Language/Menu';
// import Notifications from 'react-notify-toast';

const LoginFormKepView = ({ theme, onSubmit, onClick, step }) => (
  <ThemeProvider theme={theme}>
    <Layout filled>
      {/* <Notifications options={{ zIndex: 20000, top: '20px' }} /> */}
      <Outer>
        <Inner>
          <ErrorPlugin displayTimeError={5000} />
          <Sidebar />
          <InlineWrap
            flex
            height="360px"
            width="100%"
            background="#F8EC31"
            justifyContent="center"
          >
            <LanguageWrap>
              <LanguageMenu/>
            </LanguageWrap>
            <IconWrap>
              <HeaderLogoWithText />
              <LogoWithText />
            </IconWrap>
          </InlineWrap>

          <ContentWrap>
            <InlineWrap flex justifyContent="center" width="618px">
              <InlineWrap
                flex
                flexDirection="column"
                alignItems="stretch"
                width="100%"
              >
                <EnterWrap>Вход</EnterWrap>
                <SelectWrap>
                  {step === 'register' && (
                    <Form onSubmit={onSubmit}>
                      <Fields />
                    </Form>
                  )}
                  {step === 'checkStatus' && (
                    <CheckStatus>
                      {({ submut, block /* error */ }) => (
                        <Fragment>
                          {/* {Boolean(error) && <ErorText>{error}</ErorText>} */}
                          <WrapCheckStatusButton>
                            <Button onClick={submut} block={block}>
                              Проверить
                            </Button>
                            <StyledLink to="/login-kep">
                              Вернуться ко входу
                            </StyledLink>
                          </WrapCheckStatusButton>
                        </Fragment>
                      )}
                    </CheckStatus>
                  )}
                </SelectWrap>
              </InlineWrap>
            </InlineWrap>

            <BottomInfo>
              <div>
                Подробную информацию о проекте маркировки
              </div>
              <div>вы можете узнать на сайте <StyledA href="https://честныйзнак.рф/">ЧестныйЗНАК.рф</StyledA></div>
            </BottomInfo>
          </ContentWrap>
        </Inner>
      </Outer>
    </Layout>
  </ThemeProvider>
);

LoginFormKepView.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  step: PropTypes.string,
};

export default LoginFormKepView;
