import { actionTypes, strings } from '../../constants';

export const certEditable = (state = true, action = {}) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.TOGGLE_CERT_EDIT:
      return !state;
    case actionTypes.REGISTRATION.SHOW_CONTACTS:
      return false;
    default:
      return state;
  }
};
