import { handleActions } from 'redux-actions';
import * as actions from './Aik.actions';


const data = handleActions(
  {
    [actions.documentLoaded]: (state, { payload = null }) => payload,
    [actions.unmounted]: () => null,
  },
  null,
);

const tnvedAddUnits = handleActions(
  {
    [actions.loadTnvedAddUnits]: (state, { payload = null }) => payload
  },
  null,
);

const errorInfo = handleActions(
  {
    [actions.setError]: (state, { payload = '' }) => payload,
    [actions.unmounted]: () => '',
  },
  ''
);

export default {
  errorInfo,
  data,
  tnvedAddUnits
};
