import { selector } from '../../../utils/selector';

export const loaded = state => selector(state, 'ParticipantList.loaded');
export const evadersLoaded = state => selector(state, 'ParticipantList.evadersLoaded');
export const results = state => selector(state, 'ParticipantList.results');
export const evadersResults = state => selector(state, 'ParticipantList.evadersResults');
export const last = state => selector(state, 'ParticipantList.last');
export const total = state => selector(state, 'ParticipantList.total');
export const evadersTotal = state => selector(state, 'ParticipantList.evadersTotal');
export const page = state => selector(state, 'ParticipantList.page');
export const evadersPage = state => selector(state, 'ParticipantList.evadersPage');
export const pagesCount = state => Math.ceil(total(state) / 10);
export const pagesCountEvaders = state => Math.ceil(evadersTotal(state) / 10);
export const error = state => selector(state, 'ParticipantList.error');
