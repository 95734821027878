import { ReportsService } from '../../services/ReportsService';

export const tabs = [
  {
    id: 1,
    title: 'Аналитика',
    url: '/reports/analytics',
    getList: ReportsService.getAnalytics,
  },
  {
    id: 2,
    title: 'Справочники',
    url: '/reports/glossary',
    getList: ReportsService.getGlossary,
  },
  {
    id: 3,
    title: 'Финансовая и налоговая отчетность',
    url: '/reports/finance-and-taxes',
    getList: ReportsService.getFinanceAndTaxes,
  },
  {
    id: 4,
    title: 'Участники/товары/документы',
    url: '/reports/companies-products-and-docs',
    getList: ReportsService.getCompaniesProductsAndDocs
  },
  {
    id: 5,
    title: 'Контрольные отчеты и нарушения',
    url: '/reports/control-reports-and-defects',
  },
];
