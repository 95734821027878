import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Documents from '../../components/ParticipantDetails/Documents';
import ConnectParts from '../ConnectParts';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

class DocumentsBound extends Component {
  render() {
    return (
      <ConnectParts
        component={Documents}
        actions={[actions.ParticipantDetails]}
        selectors={[selectors.DocumentList]}
        {...this.props}
      />
    );
  }
}

export default withRouter(DocumentsBound);
