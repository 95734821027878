import { get } from 'lodash';
import { Toast } from './Toast';
import { history } from '../store';
import {deleteAllCookies, getCookie} from './cookieHelpers';

export function showAnyError(err, prevMsg) {
  if (err === 'error while signing') {
    return null
  }

  if(err.constructor.name === 'Cancel') {
    return null;
  }

  if (!err.response) {

    console.log('unknown error:', {err});

    return Toast.showError({
      content: 'Неизвестная ошибка/запрос на другой домен.',
    });

  }

  const msg = err.response.data.errors
    ? err.response.data.errors.join(', ')
    : '';
  if (msg)
    return Toast.showError({
      content: `${prevMsg ? prevMsg : ''} ${msg}`,
    });

  const errors = get(err, 'response.data.errors', []);
  const message = errors.join('\n');
  if (message)
    return Toast.showError({
      content: `${prevMsg ? prevMsg : ''}${message}`,
    });
  const otherMessage = get(err, 'response.data.message', null);
  if (otherMessage)
    return Toast.showError({
      content: `${prevMsg ? prevMsg : ''}${otherMessage}`,
    });
  const errorMessage = get(err, 'response.data.error_message', null);
  if (errorMessage) {
    return Toast.showError({
      content: `${prevMsg ? prevMsg : ''}${errorMessage}`,
    });
  }

  if (err.message) {
    return Toast.showError({
      content: `${prevMsg ? prevMsg: ''}${err.message}`,
    });
  }
}

const SKIP_URLS = ['/api/v3/lk/registration/app-user/add/send', 'image', '/api/v3/facade/doc/signed'];
const SKIP_URLS_FOR_ERROR_400 = ['/api/v3/org/suggestions?name=', '/stat/', '/api/v3/gray-zone/gray-zone-document'];

export const errorHandlers = {
  [400]: function(err) {
    if (~window.location.pathname.indexOf('documents/list/') &&
        ~err.response.request.responseURL.indexOf(
          'private-office-api/private/v2/participants'
        )) return;

    if (isStringInArray(err.request.responseURL, SKIP_URLS_FOR_ERROR_400)) return

    showAnyError(err);
  },
  [401]: function(err) {
    const pointOfEnter = getCookie('pointOfEnter') || '/login-kep';
    localStorage.clear();
    deleteAllCookies();
    console.log('401!', err);
    history.push(pointOfEnter);
  },
  [404]: function(err) {
    if (
      ~err.response.request.responseURL.indexOf(
        'private-office-api/private/v2/balance'
      )
    )
      return;
    if (
      ~window.location.pathname.indexOf('documents/list/') &&
      ~err.response.request.responseURL.indexOf(
        'private-office-api/private/v2/participants'
      )
    )
      return;

    if (
      ~err.response.request.responseURL.indexOf(
        'private-office-api/private/v2/participants'
      ) &&
      ~err.response.request.responseURL.indexOf('image')
    )
      return;

    if (
      !~err.response.request.responseURL.indexOf('/products/images/') &&
      !~err.response.request.responseURL.indexOf('/api/v3/facade/participants/') &&
      !~err.response.request.responseURL.indexOf('/reconciliations')
    ) {
      Toast.showError({
        content: `Сервер вернул 404 ошибку. ${err.response.data.errors || err.response.data.errors === 'undefined' ? 
          err.response.data.errors.join('\r\n') : ''}`,
      });
    }
  },
  [405]: function(err) {
    if (isStringInArray(err.request.responseURL, SKIP_URLS)) return

    Toast.showError({
      content: `Сервер вернул 405 ошибку (${
        err.response.request.responseURL
      }). ${err.response.data.errors &&
        err.response.data.errors.join('\\r\\n')}`,
    });
  },

  [408]: function(err) {
    if (isStringInArray(err.request.responseURL, SKIP_URLS)) return

    Toast.showError({
      content: `Превышено время ожидания ответа от сервера: ${err.config.url}`,
    });
  },
  [409]: function(err) {
    showAnyError(
      err ? err : 'Сервер сообщил о конфликте'
    );
  },
  [500]: function(err) {
    if (isStringInArray(err.request.responseURL, SKIP_URLS)) return

    showAnyError(
      err,
      `Сервер вернул 500 ошибку (${err.response.request.responseURL}).`
    );
  },
};

// Проверяет, входит ли строка str в массив строк arr
// Используется для исключения из обработки ошибок по заданным url
const isStringInArray = (str, arr) => arr.some(item => ~str.indexOf(item));
