import { EimzoService } from '../common_components/Eimzo/Eimzo';
import { prepareAllCertificateList } from '../common_components/Eimzo/utils';

export class AuthServiceUZ {

  static enumerateCertificates() {
    return EimzoService.init()
      .then(async () => {
        try {
          const certkey = await EimzoService.enumerateCertificates('list_all_certificates', 'certkey', null);
          const pfx = await EimzoService.enumerateCertificates('list_all_certificates', 'pfx', null);
          const ftjc = await EimzoService.enumerateCertificates('list_all_keys', 'ftjc', ['']);
          const certList = prepareAllCertificateList(certkey, pfx, ftjc);
          return certList;
          // eslint-disable-next-line no-empty
        } catch (e) {

        }
      });
  }

  static async sign(certificate, data, isConvertered) {
    return EimzoService.init()
      .then(async () => {
        const key = await EimzoService.loadKey(certificate);
        const sign = await EimzoService.sign(key.keyId, data, isConvertered);
        const withTimestamp = await EimzoService.getTimestampSign(
          sign.pkcs7_64
        );
        return withTimestamp.pkcs7b64;
      });
  }
}
