import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumbs } from '@crpt/react-breadcrumbs';
import Steps from '@crpt-ui/core/lib/Steps/Steps';
import { MuiThemeProvider } from '@material-ui/core';
import { documentTypeNames } from '../../../constants';
import { steps, tabs, StepWrapper } from './ExportEAES.constants';
import * as preloaderSelectors from '../../../common_components/Preloader/ducks/Preloader.selectors';
import { countryCode } from '../../../common_components/App/App.selectors';
import * as selectors from './ducks/ExportEaes.selectors';
import { Translate } from '../../../common_components/Translate/Translate';
import { MaterialUiThemeKZ } from '../../../common_components/_MaterialUi/ThemeKZ';
import { MaterialUiThemeUZ } from '../../../common_components/_MaterialUi/ThemeUZ';
import * as actions from './ducks/ExportEaes.actions';
import Info from './Info';
import Goods from './Goods';
import { get } from 'lodash';
import Tabs from '@crpt-ui/core/lib/Tabs/Tabs';
import { TabsWrap } from '../DocumentDetails.styled';
import { ContentWrapper } from '../../common/styled';
import Preloader from '../../../common_components/Preloader/Preloader';
import CisList from './Cis';
import { useTranslation } from 'react-i18next';


const ExportEaes = (props) => {
  const { countryCode, exportDoc, isLoading } = props;
  const { match, onMount, onUnmount } = props;
  const productList = get(exportDoc, 'productList', []);
  const { t } = useTranslation();
  const id = match.params.id;

  const [step, onSetStep] = useState(steps.info);

  useEffect(() => {
    onMount({ id });
    return onUnmount;
  }, [id]);

  const breadcrumbs = [
    {
      href: '/documents/export',
      title: Translate('Документы'),
      id: 1,
    },
    {
      href: `/documents/export/${id}`,
      title: t(`Уведомление об экспорте в ЕАЭС`) + ` ${id}`,
      id: 2,
    },
  ];

  const theme = useMemo(() =>
      countryCode === 'KZ' ? MaterialUiThemeKZ : MaterialUiThemeUZ[countryCode],
    [countryCode]);


  const currentTabs = useMemo(() => tabs
      .map(tab => ({
          ...tab,
          onClick: () => {
            onSetStep(tab.id);
          },
        }),
      ),
    [tabs]);

  const onNavClick = useCallback(loc => {
    props.history.push(loc);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Breadcrumbs
        withCustomLinks
        onClick={val => onNavClick(val.href)}
        breadcrumbs={breadcrumbs}
        paddingTop="0"
        paddingBottom="17px"
      />
      <TabsWrap>
        <Tabs
          value={step}
          items={currentTabs}
          variant={'fullWidth'}
          textColor="inherit"
        />
      </TabsWrap>

      <ContentWrapper>
        <Preloader isOpened={isLoading} text="Загрузка документа">
          {step === steps.info && (
            <Info
              id={id}
              exportDoc={exportDoc}
            />
          )}
          {step === steps.goods && (
            <Goods
              list={productList}
            />
          )}
          {step === steps.cis && (
            <CisList
              list={productList}
            />
          )}
        </Preloader>
      </ContentWrapper>
    </MuiThemeProvider>
  );
};

ExportEaes.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

const mapState = state => {
  return ({
    loaded: selectors.loaded(state),
    exportDoc: selectors.selectedDocument(state),
    isLoading: preloaderSelectors.loading(state),
    countryCode: countryCode(state),
  });
};

const mapDispatch = dispatch => ({
  onMount: id => dispatch(actions.mounted(id)),
  onUnmount: () => dispatch(actions.unmounted()),
  onDownloadJSON: id => dispatch(actions.downloadJSON(id)),
});

export default connect(mapState, mapDispatch)(ExportEaes);
