/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { getParentByDataRole, getParentByRole } from './ListFieldCenteredPanelWrapper.utils';

const margins = { margin: '0 -10px' };


class ListFieldFullWidthPanelWrapper extends React.Component {
  static propTypes = {
    fillWidth: PropTypes.bool,
  };

  static defaultProps = {
    fillWidth: false,
  };

  wrapRef = React.createRef();

  placeholder = null;

  flag = false;

  componentDidMount() {
    this.panel = get(this.field, 'optionsPanel');
    if (!this.panel) return;

    this.parent = this.panel.parentNode;
    this.row = getParentByRole({ elem: this.panel, role: 'row' });
    this.rowGroup = this.row.parentNode;

    window.addEventListener('resize', this.setPosition);
    window.addEventListener('scroll', this.setPosition);
    const scroller = getParentByRole({ elem: this.panel, role: 'tablescrooller' });
    scroller.addEventListener('resize', this.setPosition);
    scroller.addEventListener('scroll', this.setPosition);
  }

  componentWillUnmount() {
    if (!this.panel) return;


    window.removeEventListener('resize', this.setPosition);
    window.removeEventListener('scroll', this.setPosition);
    const scroller = getParentByRole({ elem: this.panel, role: 'tablescrooller' });
    scroller.removeEventListener('resize', this.setPosition);
    scroller.removeEventListener('scroll', this.setPosition);

    this.clearPosition();
    this.flag = false;
  }

  onRef = (ref) => {
    const { onRef } = this.props;

    this.field = ref;
    if (onRef) onRef(ref);
  };

  onTogglePanel = (flag) => {
    if (flag) {
      this.flag = true;
      this.setPosition();
    } else {
      this.clearPosition();
      this.flag = false;
    }
  };

  setPosition = () => {
    if (!this.flag) return;

    this.panel.style.position = 'fixed';
    const parentRect = this.wrapRef.current.getBoundingClientRect();
    const { top } = parentRect;

    const scroller = getParentByDataRole({ elem: this.panel, role: 'content-wrapper' });
    const scrollerStyle = scroller.getBoundingClientRect();
    const { left: scLeft, width: scWidth } = scrollerStyle;

    this.panel.style.top = `${top - 6}px`;
    this.panel.style.left = `${scLeft - 4}px`;
    this.panel.style.maxWidth = `${scWidth + 8}px`;
    this.panel.style.minWidth = `${scWidth + 8}px`;
    this.panel.style.zIndex = '1';
    this.row.style.zIndex = '2';
    this.row.style.backgroundColor = '#ffffff';
    this.row.style.boxShadow = 'none';

    this.rowGroup.insertBefore(this.panel, this.row);
  };

  clearPosition = () => {
    if (!this.flag) return;

    this.panel.style.position = '';
    this.panel.style.top = '';
    this.panel.style.left = '';
    this.panel.style.minWidth = '';
    this.panel.style.maxWidth = '';
    this.panel.style.zIndex = '';

    this.parent.appendChild(this.panel);
    this.row.style.zIndex = '';
    this.row.style.backgroundColor = '';
    this.row.style.boxShadow = '';
  };

  render() {
    const { children } = this.props;

    return (
      <div ref={this.wrapRef} style={margins}>
        {React.cloneElement(children, {
          onRef: this.onRef,
          onTogglePanel: this.onTogglePanel,
          showPointer: false,
        })}
      </div>
    );
  }
}

export default ListFieldFullWidthPanelWrapper;
