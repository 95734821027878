import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys } from 'recompact';
import { Input, HintedEmailInput, Button } from '../../Fields';
import { isPhone, required } from '../../utils/validations';
import {
  Card,
  ContentWrap,
  Title,
  Wrap,
  CertPreview,
  CertCompany,
  CertName,
} from '../styled';
import { Icon } from '@crpt/react-icon';
import { RegistrationSteps } from '../../constants';
import { buttonWhiteTheme } from '../../../../theme/ru-tabaco/button';

const CurrentStep = ({
  name,
  email,
  phone,
  canNext,
  onNext,
  contactsVisible,
  contactsEditable,
  step,
  toggleContacts,
  toggleContactsEdit,
  showData,
}) => {
  const canToggle =
    step === RegistrationSteps.DATA || step === RegistrationSteps.CONTACTS;

  return (
    <Wrap>
      <Card>
        <Title onClick={canToggle ? e => toggleContacts() : null}>
          Контакты{' '}
          {canToggle && (
            <Icon
              spacingLeft={12}
              type={contactsVisible ? 'up-arrow' : 'down-arrow'}
            />
          )}
        </Title>
        {contactsVisible &&
          (contactsEditable ? (
            <ContentWrap>
              <Input
                disabled
                placeholder="ФИО*"
                defaultValue={name}
                validate={required}
              />
              <HintedEmailInput
                name="email"
                placeholder="E-mail*"
                errorPlaceholder="Введите корректный адрес e-mail"
                defaultValue={email}
              />
              <Input
                name="phone"
                placeholder="Номер телефона"
                mask="+7 (999) 999-99-99"
                defaultValue={phone}
                validate={isPhone}
              />
            </ContentWrap>
          ) : (
            <ContentWrap>
              <CertPreview>
                <CertCompany>ФИО</CertCompany>
                <CertName>{name}</CertName>
              </CertPreview>
              <CertPreview>
                <CertCompany>E-mail</CertCompany>
                <CertName>{email}</CertName>
              </CertPreview>
              <CertPreview>
                <CertCompany>Номер телефона</CertCompany>
                <CertName>{phone || '-'}</CertName>
              </CertPreview>
              <Button
                theme={{ Button: buttonWhiteTheme }}
                onClick={e => {
                  toggleContactsEdit();
                }}
              >
                Изменить
              </Button>
            </ContentWrap>
          ))}
        {contactsVisible &&
          contactsEditable &&
          (step === RegistrationSteps.CONTACTS ? (
            <Button
              disabled={!canNext}
              onClick={e => {
                onNext();
                toggleContacts();
                showData();
              }}
            >
              Продолжить
            </Button>
          ) : (
            <Button
              disabled={!canNext}
              onClick={e => {
                toggleContactsEdit();
                toggleContacts();
              }}
            >
              Сохранить
            </Button>
          ))}
      </Card>
    </Wrap>
  );
};

CurrentStep.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  canNext: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default onlyUpdateForKeys([
  'name',
  'email',
  'phone',
  'canNext',
  'step',
  'contactsVisible',
  'contactsEditable'
])(CurrentStep);
