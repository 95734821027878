import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CrossborderWrap = styled.div`
  padding: ${props => (props.withoutPadding ? '' : '34px')};
  min-height: calc(100vh - 190px);
`;

export const StyledLink = styled(Link)`
  color: #55b5e7;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1001;
`;
