import {
  makeCell,
  makeCheckboxGroupFilter,
  makeInputFilter,
} from '@crpt-ui/datatable';
import { RangeDatepickerFilter } from '@crpt-ui/datatable/lib/Filters/RangeDatepickerFilter';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import {
  statusColorsMap,
  statusIconsMap,
  statusValuesMap,
} from '../constants';
import { getOrgRolesStr } from '../_utils/utils';

export const useColumns = ({
  allOrgRoles,
  currentStatusFilterOptions,
  isOperator,
  showForUser,
  systemNameFilterOptions,
}) =>
  useMemo(
    () =>
      [
        {
          accessor: 'orgInn',
          Filter: makeInputFilter({
            label: 'Организация',
          }),
          Header: 'Организация',
          id: 'orgInn',
        },
        {
          accessor: row => getOrgRolesStr(row.roles, allOrgRoles),
          filter: (rows, key, value) =>
            _.chain(rows)
              .filter(row =>
                value.find(valueItem =>
                  _.get(row, `original[${key}]`, [])
                    .map(item => item.role)
                    .includes(valueItem),
                ),
              )
              .value(),
          Filter: makeCheckboxGroupFilter({
            options: allOrgRoles.map(item => ({
              label: item.name,
              value: item.code,
            })),
          }),
          Header: 'Роли',
          id: 'roles',
          sortType: 'alphabetSort',
        },
        {
          accessor: 'name',
          Filter: makeInputFilter({
            label: 'Наименование',
          }),
          Header: 'Наименование',
          id: 'name',
          sortType: 'alphabetSort',
          minWidth: 260,
        },
        {
          accessor: 'systemName',
          filter: (rows, key, value) =>
            rows.filter(row => value.includes(row.values[key])),
          Filter: makeCheckboxGroupFilter({
            options: systemNameFilterOptions,
          }),
          Header: 'Системное название',
          id: 'systemName',
          sortType: 'alphabetSort',
          minWidth: 300,
        },
        {
          accessor: 'createDate',
          Cell: ({ cell }) =>
            cell.value ? moment(cell.value).format('DD.MM.YYYY') : '—',
          filter: 'rangeDateFilter',
          Filter: RangeDatepickerFilter,
          Header: 'Дата регистрации',
          id: 'createDate',
          width: 250,
        },
        {
          accessor: row => _.get(row, 'creator.fullName', '—'),
          Filter: makeInputFilter({
            label: 'Зарегистрировал',
          }),
          Header: 'Зарегистрировал',
          id: 'creator',
          sortType: 'alphabetSort',
        },
        {
          accessor: 'updateDate',
          Cell: ({ cell }) =>
            cell.value ? moment(cell.value).format('DD.MM.YYYY') : '—',
          filter: 'rangeDateFilter',
          Filter: RangeDatepickerFilter,
          Header: 'Дата изменения',
          id: 'updateDate',
        },
        {
          accessor: row => _.get(row, 'updater.fullName', '—'),
          Filter: makeInputFilter({
            label: 'Изменил',
          }),
          Header: 'Изменил',
          id: 'updater',
          sortType: 'alphabetSort',
        },
        {
          accessor: 'currentStatus',
          Cell: makeCell({
            colors: statusColorsMap,
            icons: statusIconsMap,
            values: statusValuesMap,
          }),
          filter: (rows, key, value) =>
            rows.filter(row => value.includes(row.original[key])),
          Filter: makeCheckboxGroupFilter({
            options: currentStatusFilterOptions,
          }),
          Header: 'Статус',
          id: 'currentStatus',
          width: 180,
        },
      ].filter(column => (isOperator ? true : showForUser.includes(column.id))),
    [
      allOrgRoles,
      currentStatusFilterOptions,
      isOperator,
      showForUser,
      systemNameFilterOptions,
    ],
  );
